import { useNavigate } from 'react-router-dom';
import {
    useGetAllCostCodesQuery,
    useUpdateListCostCodesMutation,
} from '../../../services/fixedAssets/fixedAssets.service';
import { PermissionsUtil } from '../../../utils/permissions/permissionsUtil';
import { PERMISSIONS } from '../../../constants/permissions/Permissions.constants';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { RoutingValues } from '../../../types/Shared.types';
import { FA_AssetCostCode } from '../../../types/FixedAsset.types';
import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { getMaxSortOrderValue } from '../../../store/fixedAssets';
import { isNilOrEmpty } from '../../../utils/objectUtils';
import useTranslation from '../../../components/i18n/hooks/useTranslation';
import { ACCOUNTING_DEFS } from '../../../constants/i18n/translations/termSetDefinitions/accounting';
import { ColDef } from 'ag-grid-community';
import DefaultColumnTypes from '../../../components/grids/columns/Column.constants';
import CustomHeader from '../../../components/grids/CustomHeader';
import { AssetCostCodesGridDefs } from '../../../constants/i18n/translations/termDefinitions/accounting';

const UseFixedAssetsCostCodeGrid = () => {
    const dispatch = useDispatch();
    const user = useSelector((state: RootState) => state.user);
    const navigate = useNavigate();
    const { data: costCodeList, isLoading: isLoadingCostCodes } =
        useGetAllCostCodesQuery();
    const [updateCostCodes] = useUpdateListCostCodesMutation();

    const canCreateAssetCostCode = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.FIXED_ASSETS.ASSET_COST_CODES.CREATE
    );
    const canViewAssetCostCode = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.FIXED_ASSETS.ASSET_COST_CODES.VIEW
    );
    const canUpdate = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.FIXED_ASSETS.ASSET_COST_CODES.EDIT
    );

    const { termSet } = useTranslation(ACCOUNTING_DEFS.ASSET_COST_CODES_GRID);

    const [colDefs, setColDefs] = useState([]);
    const [rowData, setRowData] = useState([]);

    const handleGridEdits = (editedRows: FA_AssetCostCode[]) => {
        setRowData(editedRows);
        updateCostCodes(editedRows);
    };

    useEffect(() => {
        if (!isNilOrEmpty(costCodeList)) {
            setRowData(costCodeList);
        }
    }, [costCodeList]);

    const redirectToForm = (row: any) => {
        if (row.id) {
            navigate(`/accounting/fixedAssets/costCode/${row.id}`);
        } else {
            navigate(`/accounting/fixedAssets/costCode/${RoutingValues.newId}`);
        }
    };

    useEffect(() => {
        let maxValue = 1;
        if (costCodeList && costCodeList.length > 0) {
            const data = _.maxBy(
                costCodeList,
                (costCode: FA_AssetCostCode) => costCode.sortOrder
            );
            maxValue = data.sortOrder + 1;
        }
        dispatch(
            getMaxSortOrderValue({ isLoading: false, maxValue: maxValue })
        );
    }, costCodeList);

    useEffect(() => {
        if (!isNilOrEmpty(rowData) && isNilOrEmpty(colDefs)) {
            const costCodeColumnDefs: ColDef[] = [
                {
                    field: 'code',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={AssetCostCodesGridDefs.Code}
                            />
                        );
                    },
                    ...DefaultColumnTypes.ShortText,
                },
                {
                    field: 'isAdd',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={AssetCostCodesGridDefs.Is_Add}
                            />
                        );
                    },
                    ...DefaultColumnTypes.Boolean,
                },
            ];
            setColDefs(costCodeColumnDefs);
        }
    }, [rowData]);

    return {
        costCodeList,
        isLoadingCostCodes,
        canCreateAssetCostCode,
        canViewAssetCostCode,
        canUpdate,
        redirectToForm,
        handleGridEdits,
        rowData,
        termSet,
        colDefs,
    };
};

export default UseFixedAssetsCostCodeGrid;
