import { PhoneNumberData } from '../../../../../types/Shared.types';
import { PhoneNumberInputProps } from '../PhoneNumberInput';
import React, { useEffect } from 'react';
import { RequiredValidator } from '../../../../../validators/required.validator';
import { isNil } from '../../../../../utils/objectUtils';
import { MinPhoneDigitsValidator } from '../../../../../validators/minPhoneDigits.validator';
import _ from 'lodash';

const usePhoneNumberInput = ({
    formMethods,
    minPhoneDigits,
    ...props
}: PhoneNumberInputProps) => {
    const handleOnChange = (data: PhoneNumberData): void => {
        if (data.phoneNumber.length === 1) {
            props.onChange({
                target: {
                    id: props.id,
                    value: null,
                },
            });
        } else {
            props.onChange({
                target: {
                    id: props.id,
                    value: {
                        phoneNumber: data.phoneNumber,
                        countryCode: data.countryCode,
                    },
                },
            });
        }
    };

    useEffect(() => {
        if (props.required) {
            formMethods?.addValidator(
                props.id,
                _.isObject(props.label) ? (
                    <>{props.label}</>
                ) : (
                    props.label.toString()
                ),
                RequiredValidator
            );
        } else {
            formMethods?.removeValidator(props.id, RequiredValidator);
        }
    }, [props.required]);

    useEffect(() => {
        if (minPhoneDigits) {
            formMethods?.addValidator(
                props.id,
                _.isObject(props.label) ? (
                    <>{props.label}</>
                ) : (
                    props.label.toString()
                ),
                MinPhoneDigitsValidator(minPhoneDigits)
            );
        }
    }, []);

    const getHelperTextElements = (validationErrors: any[]) => {
        if (!validationErrors) {
            return;
        }

        return validationErrors.reduce((result, item) => (
            <>
                {result}
                <br></br>
                {item}
            </>
        ));
    };

    const helperTextValidation = () => {
        return formMethods?.isFormSubmitted
            ? getHelperTextElements(
                  formMethods?.fieldValidationErrors?.get(props.id)
              )
            : null;
    };

    const getError = () => {
        return (
            formMethods?.isFormSubmitted &&
            !isNil(formMethods?.fieldValidationErrors?.get(props.id))
        );
    };

    return {
        handleOnChange,
        getError,
        helperTextValidation,
    };
};

export default usePhoneNumberInput;
