import React from 'react';
import useFixedAssetDepreciationMethodGrid from './hooks/useFixedAssetDepreciationMethodGrid';
import BaseFormGrid from '../../components/grids/BaseFormGrid';
import { cloneDeep } from 'lodash';
import { isNilOrEmpty } from '../../utils/objectUtils';
import { DepreciationMethodsGridDefs } from '../../constants/i18n/translations/termDefinitions/accounting';
import TranslatableText from '../../components/i18n/TranslatableText';

const FixedAssetDepreciationMethodGrid = () => {
    const {
        rowData,
        colDefs,
        canCreate,
        canView,
        canUpdate,
        redirectToForm,
        CostCodeColumn,
        handleGridEdits,
        termSet,
    } = useFixedAssetDepreciationMethodGrid();

    return (
        <BaseFormGrid
            title={
                <TranslatableText
                    termSet={termSet}
                    termKey={DepreciationMethodsGridDefs.Depreciation_Methods}
                />
            }
            displayGrid={canView}
            isLoading={isNilOrEmpty(colDefs)}
            rowData={cloneDeep(rowData)}
            useRowDrag={canUpdate}
            sortKey={'sortOrder'}
            columnDefs={colDefs}
            gridClass="full-size-item"
            displayToolbar={true}
            displayCreateNewButton={canCreate}
            displayExportDataButton={true}
            displayResetStateButton={true}
            onCreateNew={redirectToForm}
            onRowClicked={({ data }) => redirectToForm(data)}
            onHandleGridEdits={handleGridEdits}
            frameworkComponents={{
                costCodeColumn: CostCodeColumn,
            }}
        />
    );
};

export default FixedAssetDepreciationMethodGrid;
