import {
    FormulaProperty,
    SdsHeader,
    SdsProperty,
} from '../../../../../../../../../types/formulation';
import React, { useContext, useEffect, useState } from 'react';
import { useListFormulaPropertyQuery } from '../../../../../../../../../services/formulation/formulaProperty/formulaProperty.service';
import { useListUnitOfMeasureDropdownQuery } from '../../../../../../../../../services/organizations/organizations.service';
import { PermissionsUtil } from '../../../../../../../../../utils/permissions/permissionsUtil';
import { PERMISSIONS } from '../../../../../../../../../constants/permissions/Permissions.constants';
import { GridOptions } from '../../../../../../../../../components/grids/Grid.constants';
import { isNilOrEmpty } from '../../../../../../../../../utils/objectUtils';
import { RowStatus } from '../../../../../../../../../components/grids/hooks/useBaseGridEditable';
import _ from 'lodash';
import { ColumnMaxValueValidator } from '../../../../../../../../grid/validators/columnMaxValue.validator';
import { ColumnNumericValueValidator } from '../../../../../../../../grid/validators/columnNumericValue.validator';
import { ColumnMinValueValidator } from '../../../../../../../../grid/validators/columnMinValue.validator';
import applyEditableGridValidations from '../../../../../../../../grid/utils/applyEditableGridValidations';
import { SelectedDropdownOption } from '../../../../../../../../../types/Shared.types';
import SettingsContext from '../../../../../../../../../contexts/settings.context';
import { useGetTermSetQuery } from '../../../../../../../../../services/i18n/i18n.service';
import { FORMULATION_DEFS } from '../../../../../../../../../constants/i18n/translations/termSetDefinitions/formulation';
import { skipToken } from '@reduxjs/toolkit/query';
import CustomHeader from '../../../../../../../../../components/grids/CustomHeader';
import { SDSFormDefs } from '../../../../../../../../../constants/i18n/translations/termDefinitions/formulation';
import TranslatableText from '../../../../../../../../../components/i18n/TranslatableText';
import { ChildEditableGridWithCopyFunctionProps } from '../../../../../../../../grid/utils/editableGrid/ChildEditableGridWithCopyFunctionProps';

const useSdsPropertyGrid = (
    props: ChildEditableGridWithCopyFunctionProps<SdsHeader, SdsProperty>
) => {
    const { currentParentRecord, user } = props.parentData;

    const { showDeleteButton, isGridEditable } = props.displayGridButtons;

    const { handleChildrenRecords } = props.helpers;

    const [maxRowId, setMaxRowId] = useState(0);

    const { rowsData, setRowsData } = props.copyMethods;

    const [formulaPropertyList, setFormulaPropertyList] = useState(
        [] as SdsProperty[]
    );

    const {
        data: formulaPropertyOptions,
        isLoading: isLoadingFormulaProperties,
    } = useListFormulaPropertyQuery();

    const { data: unitOfMeasureOptions, isLoading: isLoadingUnitOfMeasure } =
        useListUnitOfMeasureDropdownQuery();

    const [showMultiSelectGridModal, setShowMultiSelectGridModal] =
        useState(false);

    const [sdsPropertiesColDef, setSdsPropertiesColDef] = useState(null);

    const [formulaPropertyColumnDefs, setFormulaPropertyColumnDefs] =
        useState(null);

    const [unitOfMeasureColumnDefs, setUnitOfMeasureColumnDefs] =
        useState(null);

    const canViewUOM = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.SETUP.UNITS_OF_MEASURE.VIEW
    );

    const canViewProperties = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.FORMULATION.FORMULA_PROPERTIES.VIEW
    );

    const colDefOptions = {
        ...GridOptions.sortFilterAndWrapColumns,
        floatingFilter: true,
    };

    const { settings } = useContext(SettingsContext);
    const { data: termSet } = useGetTermSetQuery(
        settings?.userSettings
            ? {
                  languageId: settings?.userSettings?.languageId,
                  code: FORMULATION_DEFS.SDS_FORM,
              }
            : skipToken
    );

    useEffect(() => {
        const includeEditedRows = currentParentRecord?.sdsProperties
            ? !isNilOrEmpty(
                  currentParentRecord.sdsProperties.find(
                      (currentPropertyRecord: SdsProperty) =>
                          currentPropertyRecord.rowStatus
                  )
              )
            : false;
        if (
            currentParentRecord?.sdsProperties &&
            !isLoadingUnitOfMeasure &&
            !isNilOrEmpty(unitOfMeasureOptions) &&
            !includeEditedRows
        ) {
            const properties: SdsProperty[] = [];
            let index = maxRowId;

            currentParentRecord?.sdsProperties.forEach(
                (sdsProperty: SdsProperty) => {
                    index = index + 1;
                    setMaxRowId((maxRowId) => maxRowId + 1);

                    if (sdsProperty.rowStatus !== RowStatus.DELETED) {
                        const formulaProperty: FormulaProperty =
                            sdsProperty.property;
                        const newSdsProperty = {
                            ...sdsProperty,
                            propertyCode: formulaProperty
                                ? formulaProperty.propertyCode
                                : sdsProperty.propertyCode,
                            description: formulaProperty
                                ? formulaProperty.description
                                : sdsProperty.description,
                            unitCode: getUnitCode(sdsProperty.propertyUnitId),
                            rowId: sdsProperty.rowId
                                ? sdsProperty.rowId
                                : index,
                        };
                        delete newSdsProperty.property;
                        properties.push(newSdsProperty);
                    }
                }
            );
            sortSdsProperties(properties);
        }
    }, [
        currentParentRecord?.sdsProperties,
        isLoadingUnitOfMeasure,
        unitOfMeasureOptions,
    ]);

    useEffect(() => {
        if (
            !isLoadingFormulaProperties &&
            !isNilOrEmpty(formulaPropertyOptions)
        ) {
            setFormulaPropertyColumnDefs([
                {
                    field: 'propertyCode',
                    headerComponentFramework: (props: any) => {
                        return (
                            <TranslatableText
                                termSet={termSet}
                                termKey={
                                    SDSFormDefs.Properties_Selection_Property_Code
                                }
                            />
                        );
                    },
                    cellClass: 'ag-left-aligned-cell',
                },
                {
                    field: 'description',
                    headerComponentFramework: (props: any) => {
                        return (
                            <TranslatableText
                                termSet={termSet}
                                termKey={
                                    SDSFormDefs.Properties_Selection_Property_Name
                                }
                            />
                        );
                    },
                    cellClass: 'ag-left-aligned-cell',
                },
                {
                    field: 'defaultValue',
                    headerComponentFramework: (props: any) => {
                        return (
                            <TranslatableText
                                termSet={termSet}
                                termKey={
                                    SDSFormDefs.Properties_Selection_Default_Value
                                }
                            />
                        );
                    },
                    cellClass: 'ag-left-aligned-cell',
                },
            ]);
        }
    }, [isLoadingFormulaProperties, formulaPropertyOptions]);

    useEffect(() => {
        if (!isLoadingUnitOfMeasure && !isNilOrEmpty(unitOfMeasureOptions)) {
            setUnitOfMeasureColumnDefs([
                {
                    field: 'unitCode',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={
                                    SDSFormDefs.Properties_UOM_Selection_UOM
                                }
                            />
                        );
                    },
                    cellClass: 'ag-left-aligned-cell',
                },
                {
                    field: 'description',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={
                                    SDSFormDefs.Properties_UOM_Selection_Description
                                }
                            />
                        );
                    },
                    cellClass: 'ag-left-aligned-cell',
                },
            ]);
        }
    }, [isLoadingUnitOfMeasure, unitOfMeasureOptions]);

    useEffect(() => {
        if (
            !isNilOrEmpty(formulaPropertyColumnDefs) &&
            !isLoadingFormulaProperties &&
            !isNilOrEmpty(formulaPropertyOptions) &&
            !isLoadingUnitOfMeasure &&
            !isNilOrEmpty(unitOfMeasureOptions) &&
            !isNilOrEmpty(unitOfMeasureColumnDefs)
        ) {
            setSdsPropertiesColDef([
                {
                    field: 'deleteColumn',
                    minWidth: 50,
                    hide: !showDeleteButton || !isGridEditable,
                    filter: false,
                },
                {
                    field: 'propertyCode',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={SDSFormDefs.Properties_Property_Code}
                            />
                        );
                    },
                    editable: false,
                    filter: 'agTextColumnFilter',
                },
                {
                    field: 'description',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={SDSFormDefs.Properties_Property_Name}
                            />
                        );
                    },
                    editable: false,
                    filter: 'agTextColumnFilter',
                },
                {
                    field: 'propertyValue',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={SDSFormDefs.Properties_Value}
                            />
                        );
                    },
                    editable: isGridEditable,
                    filter: 'agTextColumnFilter',
                },
                {
                    field: 'unitCode',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={SDSFormDefs.Properties_UOM}
                            />
                        );
                    },
                    editable: canViewUOM && isGridEditable,
                    cellRenderer: (params: any) => params.value.display,
                    useGridPopup: true,
                    gridPopupParameters: {
                        isLoading: isLoadingUnitOfMeasure,
                        displayGrid: true,
                        rowData: canViewUOM ? unitOfMeasureOptions : [],
                        defaultColDef: colDefOptions,
                        columnDefs: unitOfMeasureColumnDefs,
                        displayField: 'label',
                    },
                    filter: 'agTextColumnFilter',
                },
            ]);
        }
    }, [
        formulaPropertyColumnDefs,
        isLoadingUnitOfMeasure,
        unitOfMeasureOptions,
        unitOfMeasureColumnDefs,
        isLoadingFormulaProperties,
        formulaPropertyOptions,
    ]);

    useEffect(() => {
        setFilteredFormulaPropertyList();
    }, [isLoadingFormulaProperties, formulaPropertyOptions, rowsData]);

    useEffect(() => {
        if (
            !isLoadingFormulaProperties &&
            isNilOrEmpty(formulaPropertyOptions)
        ) {
            if (currentParentRecord?.sdsProperties) {
                setFilteredFormulaPropertyList();
                setRowsData(currentParentRecord?.sdsProperties);
            }
        }
    }, [
        isLoadingFormulaProperties,
        formulaPropertyOptions,
        currentParentRecord?.sdsProperties,
        rowsData,
    ]);

    const getUnitCode = (unitId: bigint): SelectedDropdownOption => {
        let unitCode: SelectedDropdownOption = { id: null, display: '' };
        if (!isNilOrEmpty(unitId)) {
            unitOfMeasureOptions.find((current: any) => {
                if (current.id === unitId) {
                    unitCode = {
                        id: current.id,
                        display: current.label,
                    };
                }
            });
        }
        return unitCode;
    };

    const sortSdsProperties = (sdsProperties: SdsProperty[]) => {
        setRowsData(
            _.orderBy(sdsProperties, ['sortBy', 'propertyCode'], ['asc'])
        );
    };

    const setFilteredFormulaPropertyList = () => {
        if (canViewProperties) {
            let filteredList: SdsProperty[] = formulaPropertyOptions;

            _.forEach(rowsData, function (propertyRecord: SdsProperty) {
                const matchingRecord = _.find(
                    formulaPropertyOptions,
                    (currentRecord: SdsProperty) => {
                        return (
                            currentRecord.propertyCode ===
                                propertyRecord.propertyCode &&
                            propertyRecord.rowStatus !== RowStatus.DELETED
                        );
                    }
                );

                if (matchingRecord) {
                    filteredList = _.without(filteredList, matchingRecord);
                }
            });

            setFormulaPropertyList(filteredList);
        } else {
            setFormulaPropertyList([]);
        }
    };

    const handleSelectedRecords = (selectedRecords: FormulaProperty[]) => {
        const newRows: SdsProperty[] = [];
        let index: number = maxRowId;

        selectedRecords?.forEach((record: FormulaProperty) => {
            index = index + 1;
            setMaxRowId((maxRowId) => maxRowId + 1);

            const updatedRecord: SdsProperty = {
                ...record,
                rowId: index,
                rowStatus: RowStatus.ADDED,
                propertyId: record.id,
                propertyValue: record.defaultValue ? record.defaultValue : '',
                unitCode: { id: null, display: '' },
                propertyUnitId: null,
                sortBy: null,
            };

            delete updatedRecord.id;
            delete updatedRecord.sortOrder;

            newRows.push(updatedRecord);
        });

        setRowsData((previousValues: SdsProperty[]) => {
            const filteredPreviousList = previousValues.filter(
                (current: SdsProperty) =>
                    current.rowStatus !== RowStatus.DELETED
            );
            return [...filteredPreviousList, ...newRows];
        });
        handleRowValidations(newRows);
        handleGridEdits(newRows);
    };

    const handleRowValidations = (editedRows: SdsProperty[]) => {
        editedRows?.forEach((row: SdsProperty) => {
            row.validationRules = [
                ColumnMaxValueValidator(
                    'Property Value',
                    50,
                    row.propertyValue
                ),
                ColumnNumericValueValidator('Sort By', 0, row.sortBy),
                ColumnMinValueValidator('Sort By', 0, Number(row.sortBy)),
            ];
            applyEditableGridValidations(row, `Property ${row.propertyCode}`);
        });

        return editedRows;
    };

    const handleGridEdits = (editedRows: SdsProperty[]) => {
        const updatedFields: SdsProperty[] = [...rowsData];

        editedRows?.forEach((sdsProperty: SdsProperty) => {
            const index = rowsData?.findIndex((row: SdsProperty) => {
                return (
                    row.propertyCode === sdsProperty.propertyCode &&
                    row.rowId === sdsProperty.rowId
                );
            });

            if (!isNilOrEmpty(sdsProperty.unitCode)) {
                sdsProperty.propertyUnitId = sdsProperty.unitCode?.id;
            }

            if (isNilOrEmpty(sdsProperty.sortBy)) {
                sdsProperty.sortBy = null;
            }

            if (index > -1) {
                updatedFields[index] = sdsProperty;
            } else {
                updatedFields.push(sdsProperty);
            }
        });
        sortSdsProperties(updatedFields);
        handleChildrenRecords('sdsProperties', updatedFields);
    };

    return {
        handleGridEdits,
        rowsData,
        showMultiSelectGridModal,
        setShowMultiSelectGridModal,
        handleSelectedRecords,
        sdsPropertiesColDef,
        formulaPropertyColumnDefs,
        handleRowValidations,
        colDefOptions,
        formulaPropertyList,
        setFilteredFormulaPropertyList,
        termSet,
    };
};

export default useSdsPropertyGrid;
