import { ToxicSpecies } from '../../../types/formulation';
import {
    BaseCreateEntityArgs,
    BaseUpdateEntityArgs,
} from '../../serviceInterfaces';
import { datacorAppApi } from '../../datacorAppApi';

const serviceUrl: string = 'formulation/toxic-species';

export const toxicSpeciesApi = datacorAppApi.injectEndpoints({
    endpoints: (build) => ({
        createToxicSpecies: build.mutation<
            ToxicSpecies,
            BaseCreateEntityArgs<ToxicSpecies>
        >({
            query: (args) => ({
                url: serviceUrl,
                method: 'POST',
                body: args.postBody,
            }),
            invalidatesTags: ['ToxicSpecies'],
        }),
        updateToxicSpecies: build.mutation<
            ToxicSpecies,
            BaseUpdateEntityArgs<ToxicSpecies>
        >({
            query: (args) => ({
                url: `${serviceUrl}/${args.id}`,
                method: 'PUT',
                body: args.postBody,
            }),
            invalidatesTags: ['ToxicSpecies', 'ActiveToxicSpecies'],
        }),
        deleteToxicSpecies: build.mutation<ToxicSpecies, string>({
            query: (id) => ({
                url: `${serviceUrl}/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['ToxicSpecies'],
        }),
        getToxicSpecies: build.query<ToxicSpecies, string>({
            query: (id) => {
                return {
                    url: `${serviceUrl}/${id}`,
                };
            },
            providesTags: ['ActiveToxicSpecies'],
        }),
        getToxicSpeciesDropdown: build.query<
            { label: string; value: bigint }[],
            void
        >({
            query: () => {
                return {
                    url: serviceUrl,
                };
            },
            transformResponse: (response: ToxicSpecies[]) => {
                return response.map((toxicSpecies) => {
                    return {
                        label: toxicSpecies.code,
                        value: toxicSpecies.id,
                        description: toxicSpecies.description,
                        id: toxicSpecies.id,
                        toxicSpeciesCode: toxicSpecies.code,
                    };
                });
            },
            providesTags: ['ToxicSpecies'],
        }),
        listToxicSpeciesDropdown: build.query<any, void>({
            query: () => {
                return {
                    url: serviceUrl,
                };
            },
            providesTags: ['ToxicSpecies'],
        }),
        listToxicSpecies: build.query<ToxicSpecies[], void>({
            query: () => {
                return {
                    url: serviceUrl,
                };
            },
            providesTags: ['ToxicSpecies'],
        }),
    }),
});

export const {
    useCreateToxicSpeciesMutation,
    useUpdateToxicSpeciesMutation,
    useDeleteToxicSpeciesMutation,
    useGetToxicSpeciesQuery,
    useGetToxicSpeciesDropdownQuery,
    useListToxicSpeciesDropdownQuery,
    useListToxicSpeciesQuery,
} = toxicSpeciesApi;
