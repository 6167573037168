import React, { useContext, useEffect, useState } from 'react';
import {
    SdsGhsHazardPhrase,
    SdsHeader,
    GhsHazardPhrase,
} from '../../../../../../../../../../types/formulation';
import { isNilOrEmpty } from '../../../../../../../../../../utils/objectUtils';
import { RowStatus } from '../../../../../../../../../../components/grids/hooks/useBaseGridEditable';
import { ColDef } from 'ag-grid-community';
import { editableGridCellSelect } from '../../../../../../../../../../components/grids/columns/editableGridCellSelect';
import {
    Language,
    useGetLanguagesQuery,
} from '../../../../../../../../../../services/organizations/organizations.service';
import { PermissionsUtil } from '../../../../../../../../../../utils/permissions/permissionsUtil';
import _, { cloneDeep } from 'lodash';
import { PERMISSIONS } from '../../../../../../../../../../constants/permissions/Permissions.constants';
import { useListHazardTypesQuery } from '../../../../../../../../../../services/formulation/ghsHazardPhrases/hazardTypes/hazardTypes.service';
import { useListGhsHazardPhrasesQuery } from '../../../../../../../../../../services/formulation/ghsHazardPhrases/ghsHazardPhrases.service';
import { ColumnRequiredValueValidator } from '../../../../../../../../../grid/validators/columnRequiredValue.validator';
import { ColumnMaxValueValidator } from '../../../../../../../../../grid/validators/columnMaxValue.validator';
import { ColumnNumericValueValidator } from '../../../../../../../../../grid/validators/columnNumericValue.validator';
import { ColumnMinValueValidator } from '../../../../../../../../../grid/validators/columnMinValue.validator';
import applyEditableGridValidations from '../../../../../../../../../grid/utils/applyEditableGridValidations';
import SettingsContext from '../../../../../../../../../../contexts/settings.context';
import { useGetTermSetQuery } from '../../../../../../../../../../services/i18n/i18n.service';
import { FORMULATION_DEFS } from '../../../../../../../../../../constants/i18n/translations/termSetDefinitions/formulation';
import { skipToken } from '@reduxjs/toolkit/query';
import CustomHeader from '../../../../../../../../../../components/grids/CustomHeader';
import { SDSFormDefs } from '../../../../../../../../../../constants/i18n/translations/termDefinitions/formulation';
import { ChildEditableGridWithCopyFunctionProps } from '../../../../../../../../../grid/utils/editableGrid/ChildEditableGridWithCopyFunctionProps';

const useSdsGhsHazardPhrasesGrid = (
    props: ChildEditableGridWithCopyFunctionProps<SdsHeader, SdsGhsHazardPhrase>
) => {
    const { currentParentRecord, user } = props.parentData;

    const { showDeleteButton, isGridEditable } = props.displayGridButtons;

    const { handleChildrenRecords } = props.helpers;

    const { rowsData, setRowsData } = props.copyMethods;

    const [hazardPhraseList, setHazardPhraseList] = useState(
        null as GhsHazardPhrase[]
    );

    const [columnDefs, setColumnDefs] = useState(null as ColDef[]);

    const [showMultiSelectGridModal, setShowMultiSelectGridModal] =
        useState(false);

    const [maxRowId, setMaxRowId] = useState(0);

    const [showVerbiageMessage, setShowVerbiageMessage] = useState(false);

    const { data: languagesList } = useGetLanguagesQuery();

    const canViewGhsHazardPhrase = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.FORMULATION.GHS_HAZARD_PHRASE.VIEW
    );

    const { data: hazardTypeOptionsList, isLoading: isLoadingHazardTypes } =
        useListHazardTypesQuery();

    const { data: rawHazardPhraseList, isLoading: isLoadingHazardPhraseList } =
        useListGhsHazardPhrasesQuery();

    let hazardPhraseListByLanguage = _.filter(rawHazardPhraseList, {
        langId: currentParentRecord.langId as unknown as bigint,
    });

    const { settings } = useContext(SettingsContext);
    const { data: termSet } = useGetTermSetQuery(
        settings?.userSettings
            ? {
                  languageId: settings?.userSettings?.languageId,
                  code: FORMULATION_DEFS.SDS_FORM,
              }
            : skipToken
    );

    /**
     * sorts records in sdsGhsHazard grid according to sdsSortOrder, then phraseId
     * @param sdsGhsHazardPhraseRecords
     */
    const sortSdsGhsHazardPhraseRecords = (
        sdsGhsHazardPhraseRecords: SdsGhsHazardPhrase[]
    ) => {
        setRowsData(
            _.orderBy(
                sdsGhsHazardPhraseRecords,
                ['sdsSortOrder', 'phraseId'],
                ['asc']
            )
        );
    };

    /**
     * removes select options from multiselect modal data
     * if phrase is already present in sdsGhsHazards grid
     */
    const removeSdsGhsHazardsFromHazardsList = (): GhsHazardPhrase[] => {
        // identifies hazard phrases that are in sdsGhsHazard grid
        // finds their matching record in list used for multiselect
        _.forEach(rowsData, function (sdsGhsRecord: SdsGhsHazardPhrase) {
            const matchingGhsRecord = _.find(
                hazardPhraseListByLanguage,
                (current: GhsHazardPhrase) => {
                    // rows that are marked deleted we don't filter out
                    // so that they can be added again via multiselect
                    return (
                        current.phraseId === sdsGhsRecord.phraseId &&
                        sdsGhsRecord.rowStatus !== RowStatus.DELETED
                    );
                }
            );

            // if there are matching records, we remove them from filtered list
            if (matchingGhsRecord) {
                hazardPhraseListByLanguage = _.without(
                    hazardPhraseListByLanguage,
                    matchingGhsRecord
                );
            }
        });
        // returns hazardPhrase list sorted and filtered
        return _.sortBy(hazardPhraseListByLanguage, ['phraseId']);
    };

    /**
     * filters out records found in sdsGhsHazards table for multiselect modal
     * then sets them for the multiselect modal to use
     */
    const setFilteredHazardPhraseList = () => {
        if (canViewGhsHazardPhrase) {
            const filteredGhsHazardsList = removeSdsGhsHazardsFromHazardsList();
            setHazardPhraseList(filteredGhsHazardsList);
        } else {
            setHazardPhraseList([]);
        }
    };

    /**
     * Gets language code for display in sdsGhsHazard grid
     * @param langId
     */
    const getLanguageCodeById = (langId: bigint): string => {
        let language = languagesList
            ? languagesList.find((current: Language) => current.id === langId)
            : null;
        return language?.code;
    };

    /**
     * Builds list of selected Ghs Hazard Phrases in format
     * required for SDS Ghs Hazard Phrases grid
     * @param selectedRecords
     * @param index
     */
    const buildHazardListFromSelectedRecords = (
        selectedRecords: GhsHazardPhrase[],
        index: number
    ) => {
        return selectedRecords.map((current) => {
            index = index + 1;
            const newRecord: SdsGhsHazardPhrase = {
                phraseId: current.phraseId,
                phraseText: current.phraseText,
                hazardTypeId: current.hazardTypeId,
                businessEntityId: current.businessEntityId,
                langId: current.langId,
                rowStatus: RowStatus.ADDED,
                rowId: index,
            };
            return newRecord;
        });
    };

    /**
     * Adds selected records to SDS GHS Hazard Phrases
     * @param selectedRecords
     */
    const handleSelectedRecords = (selectedRecords: GhsHazardPhrase[]) => {
        let index = maxRowId;
        //build hazard list from selected records and assign rowIds
        const sdsHazardList = buildHazardListFromSelectedRecords(
            selectedRecords,
            index
        );

        // get the new max row id from the hazard list
        index = _.maxBy(sdsHazardList, 'rowId').rowId;

        setMaxRowId((maxRowId) => maxRowId + index);

        //add hazard list to the existing SDS GHS hazard phrase records
        updateHazardPhraseRecords(sdsHazardList);

        //show verbiage message if user input is needed
        userInputNeededCheck(selectedRecords);
    };

    /**
     * Concatenates any non-deleted rows with the new added hazard list
     * @param hazardList
     */
    const updateHazardPhraseRecords = (hazardList: SdsGhsHazardPhrase[]) => {
        const filteredList = rowsData.filter(
            (current) => current.rowStatus !== RowStatus.DELETED
        );
        setRowsData(
            filteredList ? filteredList.concat(hazardList) : hazardList
        );
        handleRowValidations(hazardList);
        handleSaveGridEdits(hazardList);
    };

    /**
     * This triggers when 'save edits' is clicked
     * pushes sdsGhsHazardPhrase object to SDS master record
     * @param editedRows
     */
    const handleSaveGridEdits = (editedRows: SdsGhsHazardPhrase[]) => {
        const updatedFields: SdsGhsHazardPhrase[] = [...rowsData];

        editedRows?.forEach((sdsGhsHazardPhrase) => {
            const index = rowsData?.findIndex(
                (row) => row.rowId === sdsGhsHazardPhrase.rowId
            );

            if (isNilOrEmpty(sdsGhsHazardPhrase.sdsSortOrder)) {
                sdsGhsHazardPhrase.sdsSortOrder = null;
            }

            if (index > -1) {
                updatedFields[index] = sdsGhsHazardPhrase;
            } else {
                updatedFields.push(sdsGhsHazardPhrase);
            }
        });
        sortSdsGhsHazardPhraseRecords(updatedFields);
        handleChildrenRecords('sdsGhsHazardPhrases', updatedFields);
    };

    /**
     * consumes sdsGhsHazardPhraseList from parent SDSRecord
     * adds rowId to each row based on index if none is found
     * sets maxRowId based on last items index
     * @param currentList
     */
    const setSdsHazardPhraseListData = (currentList: SdsGhsHazardPhrase[]) => {
        const newList = cloneDeep(
            currentList.filter(
                (current) => current.rowStatus !== RowStatus.DELETED
            )
        );
        let currentRowId = maxRowId;
        newList.forEach((current) => {
            if (!current.rowId) {
                current.rowId = ++currentRowId;
            }
        });
        setMaxRowId(currentRowId);
        return newList;
    };

    /**
     * sets validations on each of row fields in sdsGhsHazardPhrase grid
     * @param editedRows
     */
    const handleRowValidations = (editedRows: SdsGhsHazardPhrase[]) => {
        editedRows?.forEach((current) => {
            current.validationRules = [
                ColumnRequiredValueValidator('Phrase ID', current.phraseId),
                ColumnRequiredValueValidator('Phrase Text', current.phraseText),
                ColumnMaxValueValidator('Phrase Text', 255, current.phraseText),
                ColumnRequiredValueValidator('Language', current.langId),
                ColumnRequiredValueValidator(
                    'Hazard Type',
                    current.hazardTypeId
                ),
                ColumnNumericValueValidator(
                    'SDS Sort Order',
                    0,
                    current.sdsSortOrder
                ),
                ColumnMinValueValidator(
                    'SDS Sort Order',
                    0,
                    Number(current.sdsSortOrder)
                ),
            ];
            applyEditableGridValidations(current, `Phrase ${current.phraseId}`);
        });
        return editedRows;
    };

    /**
     * used to manage multiselect modal state
     * filters hazard phrase list here to guarantee the list in the modal is accurate
     * @param showModal
     */
    const handleSetShowMultiSelectGridModal = (showModal: boolean) => {
        if (showModal) {
            setFilteredHazardPhraseList();
        }
        setShowMultiSelectGridModal(showModal);
    };

    /**
     * Show the verbiage message if any of the selected records is company specific
     * @param selectedRecords
     */
    const userInputNeededCheck = (selectedRecords: GhsHazardPhrase[]) => {
        const userInputNeededRecord = selectedRecords?.find(
            (current) => current.companySpecific === true
        );
        if (userInputNeededRecord) {
            setShowVerbiageMessage(true);
        }
    };

    /**
     * used for displaying message when record is selected from multiselect
     * message will display when record has 'companySpecific' marked true
     * @param showMessage
     */
    const handleShowVerbiageMessage = (showMessage: boolean) => {
        setShowVerbiageMessage(showMessage);
    };

    /**
     * triggers on load of hazard phrase list
     * filters hazardPhraseList for multiselect
     * conditionally sets sdsGhsHazardPhraseRecords and SDSHazardPhraseListData
     */
    useEffect(() => {
        if (!isLoadingHazardPhraseList && isNilOrEmpty(hazardPhraseList)) {
            setFilteredHazardPhraseList();
            const includeEditedRows = currentParentRecord?.sdsGhsHazardPhrases
                ? !isNilOrEmpty(
                      currentParentRecord.sdsGhsHazardPhrases.find(
                          (current) => current.rowStatus
                      )
                  )
                : false;
            if (
                currentParentRecord?.sdsGhsHazardPhrases &&
                !includeEditedRows
            ) {
                sortSdsGhsHazardPhraseRecords(
                    setSdsHazardPhraseListData(
                        currentParentRecord.sdsGhsHazardPhrases
                    )
                );
            }
        }
    }, [isLoadingHazardPhraseList]);

    /**
     * triggers when sdsGhsHazardPhraseRecords is set/altered
     * sets filtered Hazard Phrase List, used in multiselect modal
     */
    useEffect(() => {
        setFilteredHazardPhraseList();
    }, [rowsData]);

    /**
     * triggers when SDS record with data is present/altered
     * sorts and sets records from parent SDS record to sdsGhsHazards grid
     */
    useEffect(() => {
        const includeEditedRows = currentParentRecord?.sdsGhsHazardPhrases
            ? !isNilOrEmpty(
                  currentParentRecord.sdsGhsHazardPhrases.find(
                      (current) => current.rowStatus
                  )
              )
            : false;
        if (
            currentParentRecord?.id &&
            currentParentRecord?.sdsGhsHazardPhrases &&
            !includeEditedRows
        ) {
            sortSdsGhsHazardPhraseRecords(
                setSdsHazardPhraseListData(
                    currentParentRecord.sdsGhsHazardPhrases
                )
            );
        }
    }, [currentParentRecord?.sdsGhsHazardPhrases]);

    /**
     * triggers when data is loaded, sets columns for sdsGhsHazards grid
     */
    useEffect(() => {
        if (hazardTypeOptionsList && !isLoadingHazardTypes && languagesList) {
            const currentColDefList: ColDef[] = [
                {
                    field: 'deleteColumn',
                    minWidth: 50,
                    hide: !showDeleteButton || !isGridEditable,
                    filter: false,
                },
                {
                    field: 'phraseId',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={SDSFormDefs.GHS_Hazard_Phrases_Code}
                            />
                        );
                    },
                    minWidth: 250,
                    filter: 'agTextColumnFilter',
                    editable: false,
                },
                {
                    field: 'phraseText',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={SDSFormDefs.GHS_Hazard_Phrases_Phrase}
                            />
                        );
                    },
                    minWidth: 500,
                    filter: 'agTextColumnFilter',
                    editable: true,
                },
                {
                    field: 'hazardTypeId',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={
                                    SDSFormDefs.GHS_Hazard_Phrases_Hazard_Type
                                }
                            />
                        );
                    },
                    width: 300,
                    ...editableGridCellSelect(hazardTypeOptionsList),
                    editable: isGridEditable,
                },
                {
                    field: 'langId',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={
                                    SDSFormDefs.GHS_Hazard_Phrases_Language
                                }
                            />
                        );
                    },
                    minWidth: 200,
                    filter: 'agTextColumnFilter',
                    valueGetter: (params) =>
                        getLanguageCodeById(params.data.langId),
                    filterParams: {
                        valueGetter: (params: any) =>
                            getLanguageCodeById(params.data['langId']),
                    },
                },
            ];
            setColumnDefs(currentColDefList);
        }
    }, [languagesList, hazardTypeOptionsList]);

    return {
        rowsData,
        hazardPhraseList,
        showMultiSelectGridModal,
        columnDefs,
        handleSetShowMultiSelectGridModal,
        handleSelectedRecords,
        handleSaveGridEdits,
        handleRowValidations,
        showVerbiageMessage,
        handleShowVerbiageMessage,
        termSet,
    };
};

export default useSdsGhsHazardPhrasesGrid;
