import { useForm } from '../../../libs/hooksLib';
import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Group,
    useCreateGroupMutation,
    useGetGroupQuery,
    useGetUsersForDropdownQuery,
    useUpdateGroupMutation,
} from '../../../services/organizations/organizations.service';
import {
    BaseCreateEntityArgs,
    BaseUpdateEntityArgs,
} from '../../../services/serviceInterfaces';
import { skipToken } from '@reduxjs/toolkit/query';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store';
import useGetBusinessEntities from './useGetBusinessEntities';
import { showNotificationError } from '../../../libs/errorLib';
import SettingsContext from '../../../contexts/settings.context';
import { useGetTermSetQuery } from '../../../services/i18n/i18n.service';
import { PLATFORM_DEFS } from '../../../constants/i18n/translations/termSetDefinitions/platform';

const useGroupsForm = (id: string) => {
    const dispatch = useDispatch();
    const user = useSelector((state: RootState) => state.user);
    const navigate = useNavigate();
    const { fields, handleFieldChange, setValues, formMethods, isFormValid } =
        useForm({});
    const { data: group, isLoading: isLoadingGroups } = useGetGroupQuery(
        id ? { groupId: id, full: true } : skipToken
    );

    const { businessEntities: businessEntityOptions, isLoading } =
        useGetBusinessEntities({
            businessEntityId: user?.activeBusinessEntityId?.toString(),
            subscriberId: user?.subscriber?.id?.toString(),
        });

    const { data: users } = useGetUsersForDropdownQuery();
    const [createGroup] = useCreateGroupMutation();
    const [updateGroup] = useUpdateGroupMutation();

    const defaultGroup: Group = {
        name: '',
        description: '',
        businessEntityId: user?.businessEntity?.id,
        users: [],
        businessEntity: user?.businessEntity,
    };
    const { settings } = useContext(SettingsContext);
    const { data: termSet } = useGetTermSetQuery(
        settings?.userSettings
            ? {
                  languageId: settings?.userSettings?.languageId,
                  code: PLATFORM_DEFS.GROUPS_FORM,
              }
            : skipToken
    );

    useEffect(() => {
        setValues(group || defaultGroup);
    }, [group, user]);

    const handleCreateGroup = async () => {
        if (!isFormValid) {
            return;
        }

        const body: BaseCreateEntityArgs<Group> = {
            postBody: fields,
        };

        try {
            await createGroup(body).unwrap();
            handleClose();
        } catch (e) {
            return showNotificationError(
                (e.data && e?.data[0]?.message) || 'An Error has Occurred.',
                'error',
                dispatch
            );
        }
    };

    const handleUpdateGroup = async () => {
        if (!isFormValid) {
            return;
        }

        let userIds;

        //check if users have changeds
        if (fields.users === group.users) {
            userIds = null;
        } else {
            userIds = fields?.users?.map((user: any) => {
                return { id: user };
            });
        }

        const body: BaseUpdateEntityArgs<Group> = {
            id: id as unknown as number,
            postBody: { ...fields, users: userIds ? [...userIds] : null },
        };

        try {
            await updateGroup(body).unwrap();
            handleClose();
        } catch (e) {
            return showNotificationError(
                (e.data && e?.data[0]?.message) || 'An Error has Occurred.',
                'error',
                dispatch
            );
        }
    };

    const handleClose = () => {
        navigate('/administration/organizations/groups');
    };

    return {
        fields,
        user,
        users,
        group,
        isLoading,
        handleCreateGroup,
        handleUpdateGroup,
        handleFieldChange,
        handleClose,
        setValues,
        businessEntityOptions,
        formMethods,
        termSet,
    };
};

export default useGroupsForm;
