import { RootStateOrAny, useSelector, useDispatch } from 'react-redux';
import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { GridReadyEvent } from 'ag-grid-community';
import {
    organizationsApi,
    useGetSubscribersQuery,
    useGetUserQuery,
    User,
    useUpdateUserMutation,
} from '../../../services/organizations/organizations.service';
import LaunchIcon from '@mui/icons-material/Launch';
import { Tooltip, IconButton } from '@mui/material';
import { handleUpdateUserSubscriber } from '../../../store/user';
import { BaseUpdateEntityArgs } from '../../../services/serviceInterfaces';
import { skipToken } from '@reduxjs/toolkit/query';
import { openSnackbarBasicWithMessage } from '../../../store/uiElements';
import { updateGridModels } from '../../../store/grids';
import { RootState } from '../../../store';
import config from '../../../config';
import SettingsContext from '../../../contexts/settings.context';
import { useGetTermSetQuery } from '../../../services/i18n/i18n.service';
import TranslatableText from '../../../components/i18n/TranslatableText';
import { PLATFORM_DEFS } from '../../../constants/i18n/translations/termSetDefinitions/platform';
import { SubscriberGridDefs } from '../../../constants/i18n/translations/termDefinitions/platform';
import { showNotificationError } from '../../../libs/errorLib';

const useSubscribersGrid = () => {
    const { administration } = useSelector((state: RootState) => state.grids);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const user = useSelector((state: RootStateOrAny) => state.user);
    const [gridApi, setGridApi] = useState(null);
    const [subscriberName, setSubscriberName] = useState('');
    const { data: subscribers, isLoading: isLoading } =
        useGetSubscribersQuery();
    const { settings } = useContext(SettingsContext);
    const { data: termSet } = useGetTermSetQuery(
        settings
            ? {
                  languageId: settings?.userSettings?.languageId,
                  code: PLATFORM_DEFS.SUBSCRIBER_GRID,
              }
            : skipToken
    );

    const [updateUser] = useUpdateUserMutation();
    const [gridColumnApi, setGridColumnApi] = useState(null);
    const { data: userData, isLoading: isLoadingUser } = useGetUserQuery(
        user.userId
            ? {
                  userId: user.userId,
                  full: true,
              }
            : skipToken
    );
    const onGridReady = (params: GridReadyEvent) => {
        setGridApi(params.api);
        setGridColumnApi(params.columnApi);
    };

    const redirectToForm = (row: any) => {
        if (row.id) {
            navigate(`/administration/organizations/subscribers/${row.id}`);
        } else {
            navigate('/administration/organizations/subscribers/new');
        }
    };

    const redirectToBusinessEntities = async (subscriber: any, event: any) => {
        event.stopPropagation();
        navigate(
            `/administration/organizations/business-entities?subscriberIdFilter=${subscriber.id}`
        );
        await handleSwitchSubscriber(subscriber, event);
    };

    const handleSwitchSubscriber = async (subscriber: any, event: any) => {
        event.stopPropagation();
        if (!userData) {
            return;
        }

        // get the list of business entities based on subscriber id
        const businessEnts: any = await dispatch(
            organizationsApi.endpoints.getBusinessEntities.initiate(
                subscriber.id
            )
        );

        const { data } = businessEnts;
        // if the subsriberid has business entities
        // update the user with the first one in the list
        if (data.length > 0) {
            const body: BaseUpdateEntityArgs<User> = {
                id: userData.id as unknown as number,
                ignoreRevision: 'true',
                postBody: {
                    activeWebBusinessEntityId: data[0].id,
                    userPoolId: config.cognito.USER_POOL_ID,
                },
            };

            await updateUser(body);
        }
        await dispatch(
            organizationsApi.util.invalidateTags(['BusinessEntities'])
        );
        // update the redux store with the updated selected subscriber
        dispatch(handleUpdateUserSubscriber(subscriber, data[0]));
        setSubscriberName(subscriber.name || '');

        await showNotificationError(
            `Switched to Subscriber: ${subscriber?.name}`,
            'success',
            dispatch
        );
    };

    const viewEntitiesColumn = (row: any) => {
        return (
            <Tooltip
                title={
                    <TranslatableText
                        termKey={SubscriberGridDefs.View_Business_Entities}
                        termSet={termSet}
                    />
                }
                placement="top">
                <IconButton
                    size="small"
                    color="primary"
                    ref={(ref) => {
                        if (!ref) {
                            return;
                        }

                        ref.onclick = (event) => {
                            redirectToBusinessEntities(row.data, event);
                        };
                    }}>
                    <LaunchIcon />
                </IconButton>
            </Tooltip>
        );
    };

    const onFirstDataRendered = () => {
        gridColumnApi?.applyColumnState({
            state: administration.subscribersList.column,
            applyOrder: true,
        });

        gridApi?.setFilterModel(administration.subscribersList.filter);
    };

    const onSortChanged = () => {
        dispatch(
            updateGridModels({
                gridLocation: 'administration',
                gridName: 'subscribersList',
                type: 'column',
                model: gridColumnApi.getColumnState(),
            })
        );
    };

    return {
        user,
        onGridReady,
        subscribers,
        isLoading,
        isLoadingUser,
        redirectToForm,
        viewEntitiesColumn,
        subscriberName,
        onSortChanged,
        onFirstDataRendered,
        termSet,
    };
};

export default useSubscribersGrid;
