import { FA_Asset } from '../../../../types/FixedAsset.types';
import useFixedAssetCreateDisposalEntriesModal from './hooks/useFixedAssetCreateDisposalEntriesModal';
import {
    Box,
    Checkbox,
    Dialog,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    IconButton,
    Stack,
    Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { LoadingButton } from '@mui/lab';
import React from 'react';
import CurrencyInput from '../../../../components/form/formInputs/CurrencyInput/CurrencyInput';
import { NumberMaxValues } from '../../../../types/Shared.types';
import DateInput from '../../../../components/form/formInputs/DateInput/DateInput';

export interface CreateDisposalEntriesProps {
    open: boolean;
    ids: bigint[];
    singleRecord: boolean;
    onClose: () => void;
    onDisposalCreated: (asset: FA_Asset) => void;
}

const FixedAssetCreateDisposalEntriesModal = (
    props: CreateDisposalEntriesProps
) => {
    const {
        fields,
        handleFieldChange,
        canSubmitEntry,
        isCreatingDisposalEntries,
        onCreateDisposalEntries,
        canLockDisposalEntry,
    } = useFixedAssetCreateDisposalEntriesModal(props);

    return (
        <Dialog open={props.open} fullWidth maxWidth={'sm'}>
            <DialogTitle>
                <Box display="flex" alignItems="center">
                    <Box flexGrow={1}>Generate Disposal Entry(s)</Box>
                    <Box>
                        <IconButton onClick={props.onClose}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </Box>
            </DialogTitle>
            <DialogContent>
                <Stack spacing={2}>
                    <DateInput
                        label="Disposal Date"
                        id="disposalDate"
                        value={fields.disposalDate}
                        onChange={handleFieldChange}
                    />
                    <CurrencyInput
                        fullWidth
                        label="Cash Received For Fixed Asset"
                        id="cashReceived"
                        value={fields.cashReceived}
                        onChange={handleFieldChange}
                        maxValue={NumberMaxValues.decimal192}
                    />
                    {canLockDisposalEntry && !props.singleRecord ? (
                        <React.Fragment>
                            <Typography>
                                Do you wish to lock all entries that were
                                created?
                            </Typography>
                            <FormControlLabel
                                label="Lock Entries"
                                control={
                                    <Checkbox
                                        id="lockEntries"
                                        checked={fields.lockEntries}
                                        onChange={handleFieldChange}
                                    />
                                }
                            />
                        </React.Fragment>
                    ) : null}
                    <LoadingButton
                        fullWidth
                        variant="contained"
                        type="submit"
                        disabled={!canSubmitEntry()}
                        loading={isCreatingDisposalEntries}
                        onClick={onCreateDisposalEntries}>
                        Generate Disposal Entry(s)
                    </LoadingButton>
                </Stack>
            </DialogContent>
        </Dialog>
    );
};

export default FixedAssetCreateDisposalEntriesModal;
