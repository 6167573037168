import { Button } from '@mui/material';
import useContractCoverageGrid from './hooks/useContractCoverageGrid';
import React, { useMemo } from 'react';
import ListIcon from '@mui/icons-material/List';
import DefaultColumnTypes from '../../../components/grids/columns/Column.constants';
import { ColDef, GetDetailRowDataParams } from 'ag-grid-community';
import BaseFormGrid from '../../../components/grids/BaseFormGrid';
import AssetContractsOptionsModal from './AssetContractsOptionsModal';
import { ContractCoverageGridDefs } from '../../../constants/i18n/translations/termDefinitions/accounting';
import CustomHeader from '../../../components/grids/CustomHeader';
import TranslatableText from '../../../components/i18n/TranslatableText';

const ContractCoverageGrid = () => {
    const {
        showOptionsModal,
        setShowOptionsModal,
        contactCoverageList,
        setAssetList,
        canViewContractCoverage,
        termSet,
    } = useContractCoverageGrid();

    const contractColumnDefs: ColDef[] = [
        {
            field: 'contract.contractType.code',
            headerComponentFramework: (props: any) => {
                return (
                    <CustomHeader
                        {...props}
                        termSet={termSet}
                        termKey={ContractCoverageGridDefs.Contract_Type}
                    />
                );
            },
            cellRenderer: 'agGroupCellRenderer',
            ...DefaultColumnTypes.ShortText,
        },
        {
            field: 'contract.number',
            headerComponentFramework: (props: any) => {
                return (
                    <CustomHeader
                        {...props}
                        termSet={termSet}
                        termKey={ContractCoverageGridDefs.Contract_Number}
                    />
                );
            },
            ...DefaultColumnTypes.MediumText,
        },
        {
            field: 'contract.name',
            headerComponentFramework: (props: any) => {
                return (
                    <CustomHeader
                        {...props}
                        termSet={termSet}
                        termKey={ContractCoverageGridDefs.Contract_Name}
                    />
                );
            },
            ...DefaultColumnTypes.MediumText,
        },
        {
            field: 'contract.supplier',
            headerComponentFramework: (props: any) => {
                return (
                    <CustomHeader
                        {...props}
                        termSet={termSet}
                        termKey={ContractCoverageGridDefs.Provider}
                    />
                );
            },
            ...DefaultColumnTypes.MediumText,
        },
        {
            field: 'contract.startDate',
            headerComponentFramework: (props: any) => {
                return (
                    <CustomHeader
                        {...props}
                        termSet={termSet}
                        termKey={ContractCoverageGridDefs.Start_Date}
                    />
                );
            },
            ...DefaultColumnTypes.DateOnly,
        },
        {
            field: 'contract.endDate',
            headerComponentFramework: (props: any) => {
                return (
                    <CustomHeader
                        {...props}
                        termSet={termSet}
                        termKey={ContractCoverageGridDefs.End_Date}
                    />
                );
            },
            ...DefaultColumnTypes.DateOnly,
        },
        {
            field: 'contract.amount',
            headerComponentFramework: (props: any) => {
                return (
                    <CustomHeader
                        {...props}
                        termSet={termSet}
                        termKey={ContractCoverageGridDefs.Contract_Amount}
                    />
                );
            },
            ...DefaultColumnTypes.Currency,
        },
        {
            field: 'totalAssetCost',
            headerComponentFramework: (props: any) => {
                return (
                    <CustomHeader
                        {...props}
                        termSet={termSet}
                        termKey={ContractCoverageGridDefs.Asset_Cost}
                    />
                );
            },
            ...DefaultColumnTypes.Currency,
        },
        {
            field: 'totalRemainingValue',
            headerComponentFramework: (props: any) => {
                return (
                    <CustomHeader
                        {...props}
                        termSet={termSet}
                        termKey={ContractCoverageGridDefs.Remaining_Value}
                    />
                );
            },
            ...DefaultColumnTypes.Currency,
        },
    ];

    const detailCellRendererParams = useMemo(() => {
        return {
            detailGridOptions: {
                columnDefs: [
                    {
                        field: 'code',
                        headerComponentFramework: (props: any) => {
                            return (
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={
                                        ContractCoverageGridDefs.Asset_Code
                                    }
                                />
                            );
                        },
                        ...DefaultColumnTypes.ShortText,
                    },
                    {
                        field: 'name',
                        headerComponentFramework: (props: any) => {
                            return (
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={
                                        ContractCoverageGridDefs.Asset_Name
                                    }
                                />
                            );
                        },
                        ...DefaultColumnTypes.ShortText,
                    },
                    {
                        field: 'assetStatus.code',
                        headerComponentFramework: (props: any) => {
                            return (
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={
                                        ContractCoverageGridDefs.Asset_Status
                                    }
                                />
                            );
                        },
                        ...DefaultColumnTypes.ShortText,
                    },
                    {
                        field: 'ledgerAssetType.code',
                        headerComponentFramework: (props: any) => {
                            return (
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={
                                        ContractCoverageGridDefs.Asset_Type
                                    }
                                />
                            );
                        },
                        ...DefaultColumnTypes.ShortText,
                    },
                    {
                        field: 'acquireDate',
                        headerComponentFramework: (props: any) => {
                            return (
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={
                                        ContractCoverageGridDefs.Acquire_Date
                                    }
                                />
                            );
                        },
                        ...DefaultColumnTypes.DateOnly,
                    },
                    {
                        field: 'inServiceDate',
                        headerComponentFramework: (props: any) => {
                            return (
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={
                                        ContractCoverageGridDefs.In_Service_Date
                                    }
                                />
                            );
                        },
                        ...DefaultColumnTypes.DateOnly,
                    },
                    {
                        field: 'disposalDate',
                        headerComponentFramework: (props: any) => {
                            return (
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={
                                        ContractCoverageGridDefs.Disposal_Date
                                    }
                                />
                            );
                        },
                        ...DefaultColumnTypes.DateOnly,
                    },
                    {
                        field: 'totalCost',
                        headerComponentFramework: (props: any) => {
                            return (
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={
                                        ContractCoverageGridDefs.Asset_Cost
                                    }
                                />
                            );
                        },
                        ...DefaultColumnTypes.Currency,
                    },
                    {
                        field: 'remainingValue',
                        headerComponentFramework: (props: any) => {
                            return (
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={
                                        ContractCoverageGridDefs.Remaining_Value
                                    }
                                />
                            );
                        },
                        ...DefaultColumnTypes.Currency,
                    },
                ],
            },
            getDetailRowData: (params: GetDetailRowDataParams) => {
                params.successCallback(params.data.assets);
            },
        };
    }, [termSet]);

    return (
        <React.Fragment>
            <BaseFormGrid
                title="Contract Coverage"
                displayGrid={canViewContractCoverage}
                masterDetail={true}
                isLoading={false}
                rowData={contactCoverageList}
                columnDefs={contractColumnDefs}
                gridClass="full-size-item"
                rowSelection="multiple"
                suppressRowClickSelection={true}
                detailRowAutoHeight={true}
                detailCellRendererParams={detailCellRendererParams}
                displayToolbar={true}
                toolbarChildren={
                    <Button
                        variant="text"
                        size="small"
                        onClick={() => setShowOptionsModal(true)}
                        startIcon={<ListIcon />}>
                        <TranslatableText
                            termSet={termSet}
                            termKey={ContractCoverageGridDefs.Toolbar_Options}
                        />
                    </Button>
                }></BaseFormGrid>
            <AssetContractsOptionsModal
                open={showOptionsModal}
                onContractsLoaded={(assets) => setAssetList(assets)}
                onClose={() => setShowOptionsModal(false)}
            />
        </React.Fragment>
    );
};
export default ContractCoverageGrid;
