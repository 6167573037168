import BatchScheduler from '../containers/batchTicketScheduler/BatchScheduler';
import UserPortal from '../containers/userPortal/UserPortal';
import ApiDocumentation from '../containers/apiDocumentation/ApiDocumentation';
import AppStreaming from '../containers/appStreaming/AppStreaming';
import MyApps from '../containers/myApps/MyApps';
import { RouteProps } from './routesConfig';
import DocumentManagement from '../containers/documentManagement/DocumentManagement';
import BusinessIntelligence from '../containers/businessIntelligence/BusinessIntelligence';
import DevArea from '../containers/devArea/DevArea';
import DocumentDesigner from '../components/documentDesigner/DocumentDesigner';
import DocumentViewer from '../components/documentDesigner/DocumentViewer';
import DocDefinitionGrid from '../containers/documentDesigner/DocDefinitionGrid';
import DocDefinitionForm from '../containers/documentDesigner/DocDefinitionForm';

const GeneralRoutes: RouteProps[] = [
    {
        path: '/scheduler',
        component: BatchScheduler,
        featureFlag: 'dashboard.nav.apps.scheduler',
    },
    {
        path: '/userPortal',
        component: UserPortal,
        featureFlag: 'dashboard.nav.apps.administration',
    },
    {
        path: '/apiDocumentation',
        component: ApiDocumentation,
        featureFlag: 'dashboard.nav.apps.apiDocumentation',
    },
    {
        path: '/erpAppStreaming',
        component: AppStreaming,
        featureFlag: 'dashboard.nav.apps.erpAppStreaming',
    },
    {
        path: '/myApps',
        component: MyApps,
    },
    {
        path: '/documentManagement',
        component: DocumentManagement,
        featureFlag: 'dashboard.nav.apps.documentManagement',
    },
    {
        path: '/businessIntelligence',
        component: BusinessIntelligence,
        featureFlag: 'dashboard.nav.apps.businessIntelligence',
    },
    {
        path: '/dev-area',
        component: DevArea,
        featureFlag: 'dashboard.nav.apps.administration',
        permissionKey: 'Datacor.developer',
        datacorAdminOnly: true,
    },
    {
        path: '/document-designer/doc-definitions/new',
        component: DocDefinitionForm,
        featureFlag: 'dashboard.nav.apps.administration',
        permissionKey: 'Datacor.developer',
        datacorAdminOnly: true,
    },
    {
        path: '/document-designer/doc-definitions/:id',
        component: DocDefinitionForm,
        featureFlag: 'dashboard.nav.apps.administration',
        permissionKey: 'Datacor.developer',
        datacorAdminOnly: true,
    },
    {
        path: '/document-designer/doc-definitions',
        component: DocDefinitionGrid,
        featureFlag: 'dashboard.nav.apps.administration',
        permissionKey: 'Datacor.developer',
        datacorAdminOnly: true,
    },
    {
        path: '/dev-area/document-designer',
        component: DocumentDesigner,
        featureFlag: 'dashboard.nav.apps.administration',
        permissionKey: 'Datacor.developer',
        datacorAdminOnly: true,
    },
    {
        path: '/dev-area/document-viewer',
        component: DocumentViewer,
        featureFlag: 'dashboard.nav.apps.administration',
        permissionKey: 'Datacor.developer',
        datacorAdminOnly: true,
    },
];

export default GeneralRoutes;
