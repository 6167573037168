import { FA_DepreciationSchedule } from '../../../../types/FixedAsset.types';
import React from 'react';
import FixedAssetCreateScheduleStepper from '../modals/FixedAssetCreateScheduleStepper';
import { isNilOrEmpty } from '../../../../utils/objectUtils';
import { Button, Grid } from '@mui/material';
import DepreciationScheduleGrid from './DepreciationScheduleGrid';
import useDepreciationSchedule from './hooks/useDepreciationSchedule';
import AddIcon from '@mui/icons-material/Add';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import { LoadingButton } from '@mui/lab';
import DeleteIcon from '@mui/icons-material/Delete';

export interface DepreciationScheduleProps {
    assetId: bigint;
    ledgerDepreciationSchedule: FA_DepreciationSchedule[];
    taxDepreciationSchedule: FA_DepreciationSchedule[];
    headerName: any;
    totalPercentage: any;
    inServiceDate: Date;
}

const DepreciationSchedule = React.forwardRef(
    (props: DepreciationScheduleProps, ref) => {
        const {
            showCreateScheduleModal,
            setShowCreateScheduleModal,
            createSchedulesDisabled,
            canCreateDepreciationSchedules,
            canUpdateDepreciationSchedules,
            canDeleteDepreciationSchedules,
            isDeletingDepreciationSchedules,
            handleDeleteDepreciationSchedules,
        } = useDepreciationSchedule(props, ref);

        const depreciationSchedulesToolbar = () => {
            return (
                <React.Fragment>
                    {isNilOrEmpty(props.ledgerDepreciationSchedule) &&
                    isNilOrEmpty(props.taxDepreciationSchedule) &&
                    canCreateDepreciationSchedules ? (
                        <Button
                            variant="text"
                            size="small"
                            disabled={createSchedulesDisabled}
                            onClick={() => setShowCreateScheduleModal(true)}
                            startIcon={
                                <AddIcon style={{ color: '#00A84E' }} />
                            }>
                            Add Depreciation Schedules
                        </Button>
                    ) : null}
                    {(!isNilOrEmpty(props.ledgerDepreciationSchedule) ||
                        !isNilOrEmpty(props.taxDepreciationSchedule)) &&
                    canUpdateDepreciationSchedules ? (
                        <Button
                            variant="text"
                            size="small"
                            onClick={() => setShowCreateScheduleModal(true)}
                            startIcon={
                                <PlayCircleIcon style={{ color: '#00A84E' }} />
                            }>
                            Update Unlocked Schedules
                        </Button>
                    ) : null}
                    {(!isNilOrEmpty(props.ledgerDepreciationSchedule) ||
                        !isNilOrEmpty(props.taxDepreciationSchedule)) &&
                    canDeleteDepreciationSchedules ? (
                        <LoadingButton
                            variant="text"
                            size="small"
                            loading={isDeletingDepreciationSchedules}
                            onClick={handleDeleteDepreciationSchedules}
                            startIcon={
                                <DeleteIcon style={{ color: '#FF0000' }} />
                            }>
                            Delete Unlocked Schedules
                        </LoadingButton>
                    ) : null}
                </React.Fragment>
            );
        };

        return (
            <React.Fragment>
                {depreciationSchedulesToolbar()}
                <Grid item xs={12}>
                    <DepreciationScheduleGrid
                        depreciationSchedule={props.ledgerDepreciationSchedule}
                        totalPercentage={
                            props.totalPercentage?.ledgerTotalPercentage
                        }
                        headerName={props.headerName.book}
                    />
                </Grid>
                <Grid item xs={12}>
                    <DepreciationScheduleGrid
                        depreciationSchedule={props.taxDepreciationSchedule}
                        totalPercentage={
                            props.totalPercentage?.taxTotalPercentage
                        }
                        headerName={props.headerName.tax}
                    />
                </Grid>

                <FixedAssetCreateScheduleStepper
                    open={showCreateScheduleModal}
                    onClose={() => setShowCreateScheduleModal(false)}
                    ids={[props.assetId as unknown as bigint]}
                    startDate={props.inServiceDate}
                    isUpdate={
                        !isNilOrEmpty(props.ledgerDepreciationSchedule) ||
                        !isNilOrEmpty(props.taxDepreciationSchedule)
                    }
                    onLedgerScheduleUpdated={() =>
                        setShowCreateScheduleModal(false)
                    }
                    onTaxScheduleUpdated={() =>
                        setShowCreateScheduleModal(false)
                    }
                />
            </React.Fragment>
        );
    }
);

export default DepreciationSchedule;
