import React from 'react';
import { useParams } from 'react-router-dom';
import { DialogTitle, Grid } from '@mui/material';
import BaseForm from '../../../components/form/baseForm/BaseForm';
import FormPartial from '../../../components/form/formContainer/FormPartial';
import SelectInput from '../../../components/form/formInputs/SelectInput/SelectInput';
import NumericInput from '../../../components/form/formInputs/NumericInput/NumericInput';
import useUnitOfMeasureConversionForm from '../hooks/useUnitOfMeasureConversionForm';

type ParamTypes = { id: string };

const UnitOfMeasureConversionForm = () => {
    const { id } = useParams<ParamTypes>();
    const {
        fields,
        handleFieldChange,
        formMethods,
        onCreate,
        onUpdate,
        onDelete,
        closeForm,
        isLoadingUnitOfMeasureConversion,
        isLoadingUnitOfMeasureList,
        unitOfMeasureOptions,
        canCreateUnitOfMeasureConversion,
        canUpdateUnitOfMeasureConversion,
        canDeleteUnitOfMeasureConversion,
    } = useUnitOfMeasureConversionForm(id);

    return (
        <BaseForm
            title={'Units Of Measure Conversion'}
            formMethods={formMethods}
            isLoading={isLoadingUnitOfMeasureConversion}
            isNew={!id}
            onCreate={onCreate}
            onUpdate={onUpdate}
            onDelete={onDelete}
            onCancel={closeForm}
            displayCreateButton={canCreateUnitOfMeasureConversion}
            displayUpdateButton={canUpdateUnitOfMeasureConversion}
            displayDeleteButton={canDeleteUnitOfMeasureConversion}
            readOnly={id && !canUpdateUnitOfMeasureConversion}>
            <FormPartial>
                <Grid container spacing={2}>
                    <Grid item xs={2}>
                        <SelectInput
                            fullWidth
                            label="Unit of Measure"
                            id="sourceUnitOfMeasureId"
                            formMethods={formMethods}
                            value={fields.sourceUnitOfMeasureId}
                            onChange={handleFieldChange}
                            options={unitOfMeasureOptions}
                            disabled={isLoadingUnitOfMeasureList}
                            required={true}
                            SelectProps={{
                                readOnly:
                                    id && !canUpdateUnitOfMeasureConversion,
                            }}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <NumericInput
                            fullWidth
                            label="Quantity"
                            id="sourceQuantity"
                            formMethods={formMethods}
                            value={fields.sourceQuantity}
                            onChange={handleFieldChange}
                            required={true}
                            disabled={true}
                            numDecimals={10}
                        />
                    </Grid>
                    <Grid item xs={1}>
                        <DialogTitle>to</DialogTitle>
                    </Grid>
                    <Grid item xs={2}>
                        <SelectInput
                            fullWidth
                            label="Unit of Measure"
                            id="destinationUnitOfMeasureId"
                            formMethods={formMethods}
                            value={fields.destinationUnitOfMeasureId}
                            onChange={handleFieldChange}
                            options={unitOfMeasureOptions}
                            disabled={isLoadingUnitOfMeasureList}
                            required={true}
                            SelectProps={{
                                readOnly:
                                    id && !canUpdateUnitOfMeasureConversion,
                            }}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <NumericInput
                            fullWidth
                            label="Quantity"
                            id="destinationQuantity"
                            formMethods={formMethods}
                            value={fields.destinationQuantity}
                            onChange={handleFieldChange}
                            required={true}
                            numDecimals={10}
                        />
                    </Grid>
                </Grid>
            </FormPartial>
        </BaseForm>
    );
};

export default UnitOfMeasureConversionForm;
