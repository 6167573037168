import { useDispatch, useSelector } from 'react-redux';
import {
    useDeleteUserLegacyMutation,
    useGetAllUsersQuery,
    useGetCompaniesByUserIdQuery,
} from '../../../services/users/users.service';
import React, { useState } from 'react';
import config from '../../../config';
import { useGetRolesByBatchCompaniesQuery } from '../../../services/permissionsAndPreferences/permissionsAndPreferences.service';
import { skipToken } from '@reduxjs/toolkit/query';
import { GridReadyEvent, RowClickedEvent } from 'ag-grid-community';
import { IconButton } from '@mui/material';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { Company, CompanyRole, RowType } from '../../../types/User.types';
import { RootState } from '../../../store';
import { showNotificationError } from '../../../libs/errorLib';

const useUsersGrid = () => {
    const user = useSelector((state: RootState) => state.user);
    const dispatch = useDispatch();
    const { data: usersList, isLoading: isLoadingUsers } = useGetAllUsersQuery(
        user.userId
    );
    const { data: companies } = useGetCompaniesByUserIdQuery(user.userId);
    const { data: companiesRoles, isLoading: isLoadingRoles } =
        useGetRolesByBatchCompaniesQuery(
            companies
                ? companies.filter(
                      (company) => company.companyID === user.companyId
                  )
                : skipToken
        );
    const [deleteUser] = useDeleteUserLegacyMutation();
    const [showCreateEditUserModal, setShowCreateEditUserModal] =
        useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [selectedUsername, setSelectedUsername] = useState('');

    const handleCreateUserModalOpen = () => {
        setIsEdit(false);
        setShowCreateEditUserModal(true);
    };

    const handleEditUserModalClose = () => {
        setIsEdit(false);
        setShowCreateEditUserModal(false);
    };

    const onGridReady = (params: GridReadyEvent) => {
        params.api.sizeColumnsToFit();
    };

    const getRolesForUsers = (roleId: string) => {
        const roles = companiesRoles
            ?.filter((role: any) => role.roleId === roleId)
            .map((role: any) => role.name);
        return roles?.join(', ');
    };

    const DeleteUserColumn = (props: any) => {
        return (
            <IconButton
                size="small"
                color="error"
                onClick={($event) => handleDeleteUser(props.data, $event)}>
                <DeleteForeverIcon />
            </IconButton>
        );
    };

    const RolesColumn = (props: any) => {
        if (props.data.isDatacorAdmin) {
            return <span>All</span>;
        } else {
            const roles =
                companiesRoles &&
                companiesRoles
                    .filter((role: CompanyRole) =>
                        props.data.roles.includes(role.roleId)
                    )
                    .map((role: CompanyRole) => role.name);
            return <span>{roles?.join(', ')}</span>;
        }
    };

    const CompaniesColumn = (props: any) => {
        if (props.data.isDatacorAdmin) {
            return <span>All</span>;
        } else {
            const companyNames = props.data.companies.map(
                (company: Company) => company.companyName
            );
            return <span>{companyNames.join(', ')}</span>;
        }
    };

    const editUser = async (event: RowClickedEvent) => {
        if (!event.event.defaultPrevented) {
            setSelectedUsername(event.data.username);
            setIsEdit(true);
            setShowCreateEditUserModal(true);
        }
    };

    const handleDeleteUser = async (row: RowType, event: React.MouseEvent) => {
        event.preventDefault();
        const confirmed = window.confirm(
            `Are you sure you want to delete ${row.firstName} ${row.lastName}?`
        );

        if (!confirmed) {
            return false;
        }
        const body = {
            username: row.username,
            userPoolId: config.cognito.USER_POOL_ID,
        };

        try {
            await deleteUser(body);
        } catch (e) {
            return showNotificationError(e.message, 'error', dispatch);
        }
    };
    return {
        usersList,
        editUser,
        CompaniesColumn,
        RolesColumn,
        DeleteUserColumn,
        onGridReady,
        handleEditUserModalClose,
        handleCreateUserModalOpen,
        selectedUsername,
        isEdit,
        showCreateEditUserModal,
        isLoadingUsers,
        isLoadingRoles,
        user,
        getRolesForUsers,
    };
};
export default useUsersGrid;
