import {
    useCreateSupplierStatusMutation,
    useGetSupplierStatusQuery,
    useUpdateSupplierStatusMutation,
    useDeleteSupplierStatusMutation,
} from '../../../services/supplier/supplier.service';
import { skipToken } from '@reduxjs/toolkit/query';
import { SupplierStatus } from '../../../types/Supplier.types';
import useBaseForm from '../../form/hooks/useBaseForm';
import { PermissionsUtil } from '../../../utils/permissions/permissionsUtil';
import { PERMISSIONS } from '../../../constants/permissions/Permissions.constants';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { RoutingValues } from '../../../types/Shared.types';
import { useGetTermSetQuery } from '../../../services/i18n/i18n.service';
import { SUPPLY_CHAIN_DEFS } from '../../../constants/i18n/translations/termSetDefinitions/supply-chain';
import { useContext, useEffect, useState } from 'react';
import SettingsContext from '../../../contexts/settings.context';

const useSupplierStatusForm = (id: string) => {
    const user = useSelector((state: RootState) => state.user);
    const sortOrderState = useSelector((state: RootState) => state.suppliers);
    const { data: entity, isLoading: isLoadingData } =
        useGetSupplierStatusQuery(id === RoutingValues.newId ? skipToken : id);
    const [isLoadingForm, setIsLoadingForm] = useState(true);

    const canCreate = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.SUPPLIERS.SETUP.SUPPLIER_STATUSES.CREATE
    );

    const canUpdate = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.SUPPLIERS.SETUP.SUPPLIER_STATUSES.EDIT
    );

    const canDelete = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.SUPPLIERS.SETUP.SUPPLIER_STATUSES.DELETE
    );

    const [createMutation] = useCreateSupplierStatusMutation();
    const [updateMutation] = useUpdateSupplierStatusMutation();
    const [deleteMutation] = useDeleteSupplierStatusMutation();

    const { settings } = useContext(SettingsContext);
    const { data: termSet, isLoading: isLoadingTermSet } = useGetTermSetQuery(
        settings?.userSettings
            ? {
                  languageId: settings?.userSettings?.languageId,
                  code: SUPPLY_CHAIN_DEFS.SUPPLIER_STATUS_FORM,
              }
            : skipToken
    );

    const blankEntity: SupplierStatus = {
        id: null,
        code: '',
        description: '',
        sortOrder: sortOrderState.sortOrderSet.maxValue,
        businessEntityId: null,
        statusActive: false,
        supplierActive: false,
        default: false,
        createdAt: null,
        createdBy: null,
        updatedAt: null,
        updatedBy: null,
    };

    const {
        fields,
        handleFieldChange,
        formMethods,
        onCreate,
        onUpdate,
        onDelete,
        closeForm,
        auditableFields,
    } = useBaseForm({
        closePath: '/suppliers/setup/status',
        blankEntity: blankEntity,
        activeEntity: entity,
        getDescription: () => {
            return `Supplier Status ${fields.code}`;
        },
        createEntity: () => {
            return createMutation({ postBody: fields });
        },
        updateEntity: () => {
            return updateMutation({
                id: id as unknown as number,
                postBody: fields,
            });
        },
        deleteEntity: () => {
            return deleteMutation(id);
        },
    });

    useEffect(() => {
        if (
            (!isLoadingData || id === RoutingValues.newId) &&
            !isLoadingTermSet
        ) {
            setIsLoadingForm(false);
        }
    }, [entity, termSet]);

    return {
        fields,
        termSet,
        auditableFields,
        formMethods,
        onCreate,
        onUpdate,
        onDelete,
        closeForm,
        handleFieldChange,
        isLoadingForm,
        canCreate,
        canUpdate,
        canDelete,
    };
};

export default useSupplierStatusForm;
