import React, { useEffect, useState } from 'react';
import { ColDef } from 'ag-grid-community';
import { VALUE_TYPES } from '../../../constants/attributes/attributes.constants';
import DefaultColumnTypes from '../../../components/grids/columns/Column.constants';
import { isNilOrEmpty } from '../../../utils/objectUtils';
import { cloneDeep, isArray } from 'lodash';
import { v4 as uuidv4 } from 'uuid';

export interface AttributeDefinitionsListOptionsGridProps {
    attributeDefinition: any;
    updateOptionList: (childName: string, childList: any[]) => void;
}

const useAttributeDefinitionsListOptionsGrid = ({
    attributeDefinition,
    updateOptionList,
}: AttributeDefinitionsListOptionsGridProps) => {
    const [gridOptions, setGridOptions] = useState([]);

    const [pickListColDefs, setPickListColDefs] = useState(null as ColDef[]);

    useEffect(() => {
        if (
            attributeDefinition.attrValueTypeId?.toString() === VALUE_TYPES.LIST
        ) {
            setPickListColDefs([
                {
                    field: 'option',
                    headerName: 'Option',
                    ...DefaultColumnTypes.LongText,
                    editable: true,
                },
            ]);

            const includeEditedRows =
                isArray(attributeDefinition.options) &&
                attributeDefinition.options
                    ? !isNilOrEmpty(
                          attributeDefinition.options.find(
                              (current: any) => current.rowStatus
                          )
                      )
                    : false;

            if (
                isArray(attributeDefinition.options) &&
                attributeDefinition.id &&
                !includeEditedRows
            ) {
                setGridOptions(cloneDeep(attributeDefinition.options));
            }
        }
    }, [attributeDefinition.options]);

    const handleGridEdits = (records: any) => {
        const currOpts = isArray(gridOptions) ? gridOptions : [];
        const updatedFields = [...currOpts];

        records?.forEach((field: any) => {
            const index = currOpts?.findIndex((row: any) => {
                return row.id === field.id;
            });

            if (index > -1) {
                updatedFields[index] = field;
            } else {
                updatedFields.push(field);
            }
        });

        const optionsForGrid = updatedFields.map((field: any) => {
            return {
                id: field.id || uuidv4(),
                option: field.option,
                rowStatus: field.rowStatus,
            };
        });

        updateOptionList('options', optionsForGrid);

        updateOptionList(
            'optionsForDropdown',
            updatedFields.map((field: any) => {
                return {
                    value: field.id || uuidv4(),
                    label: field.option,
                };
            })
        );
    };

    return {
        gridOptions,
        pickListColDefs,
        handleGridEdits,
    };
};

export default useAttributeDefinitionsListOptionsGrid;
