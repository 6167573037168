//TODO: this will need to be translated - just an initial implementation
//2d array of sections and subsections for the SDS form
export const DISPLAY_FORM_SECTIONS: any[][] = [
    [
        {
            id: 1,
            name: 'Identification',
            link: 'identification',
            subSections: [
                {
                    name: 'Product Identifier',
                    link: 'productIdentifier',
                },
                {
                    name: 'Relevant Identified Uses',
                    link: 'relevantIdentifiedUses',
                },
                {
                    name: 'Details of the Supplier',
                    link: 'detailsOfTheSupplier',
                },
                {
                    name: 'Emergency Telephone Information',
                    link: 'emergencyTelephoneInformation',
                },
            ],
        },
        {
            id: 6,
            name: 'Accidental Release',
            link: 'accRelease',
            subSections: [
                {
                    name: 'Personal Precautions',
                    link: 'personalPrecautions',
                },
                {
                    name: 'Environmental Precautions',
                    link: 'environmentalPrecautions',
                },
                {
                    name: 'Methods and Materials for Containment',
                    link: 'methodsAndMaterialsForContainment',
                },
                {
                    name: 'Reference to Other Sections',
                    link: 'referenceToOtherSections',
                },
            ],
        },
        {
            id: 10,
            name: 'Stability & Reactivity',
            link: 'stability',
            subSections: [
                {
                    name: 'Reactivity',
                    link: 'reactivity',
                },
                {
                    name: 'Chemical Stability',
                    link: 'chemicalStability',
                },
                {
                    name: 'Possibility of Hazardous Reactions',
                    link: 'possibilityOfHazardousReactions',
                },
                {
                    name: 'Conditions to Avoid',
                    link: 'conditionsToAvoid',
                },
                {
                    name: 'Incompatible Materials',
                    link: 'incompatibleMaterials',
                },
                {
                    name: 'Hazardous Decomposition Products',
                    link: 'hazardousDecompositionProducts',
                },
            ],
        },
        {
            id: 13,
            name: 'Disposal Considerations',
            link: 'disposal',
            subSections: [
                {
                    name: 'Waste Treatment Methods',
                    link: 'wasteTreatmentMethods',
                },
            ],
        },
    ],
    [
        {
            id: 2,
            name: 'Hazard Information',
            link: 'hazardInfo',
            subSections: [
                {
                    name: 'Classification of the Substance or Mixtures',
                    link: 'classification',
                },
                {
                    name: 'Label Elements',
                    link: 'labelElements',
                },
                {
                    name: 'Other Hazards',
                    link: 'otherHazards',
                },
                {
                    name: 'Unknown Acute Toxicity',
                    link: 'unknownAcuteToxicity',
                },
            ],
        },
        {
            id: 7,
            name: 'Handling & Storage',
            link: 'handling',
            subSections: [
                {
                    name: 'Precautions for Safe Handling',
                    link: 'precautionsForSafeHandling',
                },
                {
                    name: 'Conditions for Safe Storage',
                    link: 'conditionsForSafeStorage',
                },
                {
                    name: 'Specific End Use(s)',
                    link: 'specificEndUses',
                },
            ],
        },
        {
            id: 11,
            name: 'Toxicological Information',
            link: 'toxicological',
            subSections: [
                {
                    name: 'Information on Toxicological Effects',
                    link: 'informationOnToxicologicalEffects',
                },
                {
                    name: 'Symptoms',
                    link: 'symptoms',
                },
                {
                    name: 'Delayed and Chronic Effects',
                    link: 'delayedAndChronicEffects',
                },
            ],
        },
        {
            id: 14,
            name: 'Transport',
            link: 'transport',
            subSections: [
                {
                    name: 'UN Number',
                    link: 'unNumber',
                },
                {
                    name: 'Proper Shipping Name',
                    link: 'properShippingName',
                },
                {
                    name: 'Class',
                    link: 'class',
                },
                {
                    name: 'Packing Group',
                    link: 'packingGroup',
                },
                {
                    name: 'Environmental Hazards',
                    link: 'environmentalHazards',
                },
                {
                    name: 'Special Precautions for User',
                    link: 'specialPrecautionsForUser',
                },
                {
                    name: 'Transport in Bulk',
                    link: 'transportInBulk',
                },
            ],
        },
    ],
    [
        {
            id: 3,
            name: 'Cas Composition',
            link: 'casComp',
            subSections: [
                {
                    name: 'Substances',
                    link: 'substances',
                },
                {
                    name: 'Mixtures',
                    link: 'mixtures',
                },
            ],
        },
        {
            id: 8,
            name: 'Exposure Controls',
            link: 'exposure',
            subSections: [
                {
                    name: 'Control Parameters',
                    link: 'controlParameters',
                },
                {
                    name: 'Engineering Controls',
                    link: 'engineeringControls',
                },
                {
                    name: 'Protective Measures',
                    link: 'protectiveMeasures',
                },
            ],
        },
        {
            id: 12,
            name: 'Ecological Information',
            link: 'eco',
            subSections: [
                {
                    name: 'Toxicity',
                    link: 'toxicity',
                },
                {
                    name: 'Persistence and Degradability',
                    link: 'persistenceAndDegradability',
                },
                {
                    name: 'Bioaccumulative Potential',
                    link: 'bioaccumulativePotential',
                },
                {
                    name: 'Mobility in Soil',
                    link: 'mobilityInSoil',
                },
                {
                    name: 'Results of PBT and vPvB Assessment',
                    link: 'resultsOfPBTAndVPvBAssessment',
                },
                {
                    name: 'Other Adverse Effects',
                    link: 'otherAdverseEffects',
                },
                {
                    name: 'Additional Information',
                    link: 'additionalInformation',
                },
            ],
        },
        {
            id: 15,
            name: 'Regulatory Information',
            link: 'reg',
            subSections: [
                {
                    name: 'Safety, Health and Environmental',
                    link: 'safetyHealthAndEnvironmental',
                },
                {
                    name: 'Chemical Safety Assessment',
                    link: 'chemicalSafetyAssessment',
                },
            ],
        },
    ],
    [
        {
            id: 4,
            name: 'First Aid',
            link: 'firstAid',
            subSections: [
                {
                    name: 'Description of First Aid Measures',
                    link: 'description',
                },
                {
                    name: 'Most Important Symptoms',
                    link: 'mostImportantSymptoms',
                },
                {
                    name: 'Indication of Immediate Medical Attention',
                    link: 'indicationOfImmediateMedicalAttention',
                },
            ],
        },
        {
            id: 9,
            name: 'Physical & Chemical Proper',
            link: 'physChem',
            subSections: [
                {
                    name: 'Information on Basic Physical',
                    link: 'informationOnBasicPhysical',
                },
                {
                    name: 'Other Information',
                    link: 'otherInformation',
                },
            ],
        },
        {
            id: null,
            name: null,
            link: null,
        },
        {
            id: 16,
            name: 'Other Information',
            link: 'otherInfo',
        },
    ],
    [
        {
            id: 5,
            name: 'Firefighting',
            link: 'fireFighting',
            subSections: [
                {
                    name: 'Extinguishing Media',
                    link: 'extinguishingMedia',
                },
                {
                    name: 'Special Hazards Arising',
                    link: 'specialHazardsArising',
                },
                {
                    name: 'Advice for Firefighters',
                    link: 'adviceForFirefighters',
                },
            ],
        },
    ],
];
