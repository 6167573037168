import React, { useContext } from 'react';
import MultiSelectGridModal from '../../../../../components/modals/MultiSelectGridModal';
import DefaultColumnTypes from '../../../../../components/grids/columns/Column.constants';
import { StateCodeDisplay } from '../../utilities/formulation.utils';
import { Regulation } from '../../../../../types/formulation';
import CustomHeader from '../../../../../components/grids/CustomHeader';
import { CasMasterFormDefs } from '../../../../../constants/i18n/translations/termDefinitions/formulation';
import SettingsContext from '../../../../../contexts/settings.context';
import { useGetTermSetQuery } from '../../../../../services/i18n/i18n.service';
import { FORMULATION_DEFS } from '../../../../../constants/i18n/translations/termSetDefinitions/formulation';
import { skipToken } from '@reduxjs/toolkit/query';
import TranslatableText from '../../../../../components/i18n/TranslatableText';

interface RegulationsSelectionModalProps {
    openModal: boolean;
    onClose: () => void;
    onSelected: (selectedRows: Regulation[]) => void;
    getStateCode: (stateCodeId: bigint) => StateCodeDisplay;
    regulationList: Regulation[];
}

const RegulationsSelectionModal = (props: RegulationsSelectionModalProps) => {
    const { settings } = useContext(SettingsContext);
    const { data: termSet } = useGetTermSetQuery(
        settings?.userSettings
            ? {
                  languageId: settings?.userSettings?.languageId,
                  code: FORMULATION_DEFS.CAS_MASTER_FORM,
              }
            : skipToken
    );
    const columnDefs = [
        {
            field: 'code',
            headerComponentFramework: (props: any) => {
                return (
                    <TranslatableText
                        termSet={termSet}
                        termKey={CasMasterFormDefs.Regulations_Selection_Code}
                    />
                );
            },
            cellClass: 'ag-left-aligned-cell',
        },
        {
            field: 'title',
            headerComponentFramework: (props: any) => {
                return (
                    <TranslatableText
                        termSet={termSet}
                        termKey={CasMasterFormDefs.Regulations_Selection_Title}
                    />
                );
            },
            cellClass: 'ag-left-aligned-cell',
        },
        {
            field: 'regulationBody.description',
            headerComponentFramework: (props: any) => {
                return (
                    <TranslatableText
                        termSet={termSet}
                        termKey={
                            CasMasterFormDefs.Regulations_Selection_Regulation_Body
                        }
                    />
                );
            },
            cellClass: 'ag-left-aligned-cell',
        },
        {
            field: 'regulationSection.section',
            headerComponentFramework: (props: any) => {
                return (
                    <TranslatableText
                        termSet={termSet}
                        termKey={
                            CasMasterFormDefs.Regulations_Selection_Section
                        }
                    />
                );
            },
            cellClass: 'ag-left-aligned-cell',
        },
        {
            field: 'stateCodeId',
            headerComponentFramework: (props: any) => {
                return (
                    <TranslatableText
                        termSet={termSet}
                        termKey={
                            CasMasterFormDefs.Regulations_Selection_State_Province
                        }
                    />
                );
            },
            valueFormatter: (val: any) => props.getStateCode(val.value).name,
            filterParams: {
                valueGetter: (params: any) =>
                    props.getStateCode(params.data['stateCodeId']),
            },
            ...DefaultColumnTypes.MediumText,
        },
    ];

    return (
        <MultiSelectGridModal
            title={
                (
                    <TranslatableText
                        termSet={termSet}
                        termKey={CasMasterFormDefs.Regulations_Selection_Text}
                    />
                ) as unknown as string
            }
            open={props.openModal}
            onClose={props.onClose}
            onSelected={props.onSelected}
            rowData={props.regulationList}
            columnDefs={columnDefs}
            preSelectedIds={() => []}
        />
    );
};

export default RegulationsSelectionModal;
