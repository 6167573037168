import DefaultColumnTypes from '../../../../../../../components/grids/columns/Column.constants';
import { useGetApprovalTypesForDropdownQuery } from '../../../../../../../services/formulation/rdFormula/rdFormulaApprovalType/rdFormulaApprovalType.service';
import { useGetFormulaStatusForDropdownQuery } from '../../../../../../../services/formulation/rdFormula/rdFormulaStatus/rdFormulaStatus.service';
import React, { useContext, useEffect, useState } from 'react';
import { useListItemsMasterDropdownQuery } from '../../../../../../../services/formulation/itemMaster/item/itemMaster.service';
import {
    useGetUsersWithEmailForDropdownQuery,
    useListUnitOfMeasureDropdownQuery,
} from '../../../../../../../services/organizations/organizations.service';
import { PermissionsUtil } from '../../../../../../../utils/permissions/permissionsUtil';
import { PERMISSIONS } from '../../../../../../../constants/permissions/Permissions.constants';
import { RDFormula } from '../../../../../../../types/formulation';
import { isNilOrEmpty } from '../../../../../../../utils/objectUtils';
import { skipToken } from '@reduxjs/toolkit/query';
import { ExtendedFormProps } from '../../../../../../form/helpers/ExtendedFormProps';
import SettingsContext from '../../../../../../../contexts/settings.context';
import { useGetTermSetQuery } from '../../../../../../../services/i18n/i18n.service';
import { FORMULATION_DEFS } from '../../../../../../../constants/i18n/translations/termSetDefinitions/formulation';
import CustomHeader from '../../../../../../../components/grids/CustomHeader';
import { RDFormulasFormDefs } from '../../../../../../../constants/i18n/translations/termDefinitions/formulation';

const useRDFormulaHeaderForm = (props: ExtendedFormProps<RDFormula>) => {
    const { id, currentParentRecord, user } = props.parentData;

    const { handleFieldChange } = props.helpers;

    const { data: approvalTypeOptions, isLoading: isLoadingApprovalTypes } =
        useGetApprovalTypesForDropdownQuery();

    const { data: formulaStatusOptions, isLoading: isLoadingFormulaStatus } =
        useGetFormulaStatusForDropdownQuery();

    const { data: itemMasterOptions, isLoading: isLoadingItemMasterOptions } =
        useListItemsMasterDropdownQuery();

    const { data: unitOfMeasureOptions, isLoading: isLoadingUnitOfMeasure } =
        useListUnitOfMeasureDropdownQuery();

    const { data: userList, isLoading: isLoadingUserOptions } =
        useGetUsersWithEmailForDropdownQuery(
            user?.businessEntity?.id
                ? user?.businessEntity?.id?.toString()
                : skipToken
        );

    const initialState: any = {
        formulaUnitInputValue: '',
        selectedFormulaUnit: null,
        selectedItem: null,
        selectedSupersedesItem: null,
        selectedSupersededByItem: null,
        selectedApprovedByUser: null,
    };

    const [dropdownOptions, setDropdownOptions] = useState(initialState);

    const headerPermissions = {
        canViewUnitOfMeasure: PermissionsUtil.isPermissionEnabled(
            user.permissions,
            PERMISSIONS.SETUP.UNITS_OF_MEASURE.VIEW
        ),
        canViewItemMaster: PermissionsUtil.isPermissionEnabled(
            user.permissions,
            PERMISSIONS.FORMULATION.ITEM_MASTER.VIEW
        ),
    };

    const { settings } = useContext(SettingsContext);
    const { data: termSet } = useGetTermSetQuery(
        settings?.userSettings
            ? {
                  languageId: settings?.userSettings?.languageId,
                  code: FORMULATION_DEFS.RD_FORMULAS_FORM,
              }
            : skipToken
    );

    /**
     * Get the default status for FormulaStatus field
     */
    const getDefaultFormulaStatus = (): any => {
        return formulaStatusOptions?.find((formulaStatus: any) =>
            (formulaStatus.label as string).includes('Development')
        );
    };

    /**
     * Set the labels on all the dropdowns
     */
    useEffect(() => {
        if (
            !isLoadingUnitOfMeasure &&
            unitOfMeasureOptions &&
            !isLoadingUserOptions &&
            userList
        ) {
            setDropdownOptions((previousValues: any) => ({
                ...previousValues,
                selectedFormulaUnit: unitOfMeasureOptions?.find(
                    (current: any) =>
                        current.value === currentParentRecord.unitId
                ),
                selectedItem: itemMasterOptions?.find(
                    (current: any) =>
                        current.value === currentParentRecord.itemId
                ),
                selectedSupersedesItem: itemMasterOptions?.find(
                    (current: any) =>
                        current.value === currentParentRecord.supersedesItemId
                ),
                selectedSupersededByItem: itemMasterOptions?.find(
                    (current: any) =>
                        current.value === currentParentRecord.supersededByItemId
                ),
                selectedApprovedByUser: userList?.find(
                    (current: any) =>
                        current.value === currentParentRecord.approvedByUserId
                ),
            }));
        }
    }, [
        unitOfMeasureOptions,
        isLoadingUnitOfMeasure,
        currentParentRecord,
        itemMasterOptions,
        isLoadingItemMasterOptions,
        isLoadingUserOptions,
        userList,
    ]);

    const productCodeTextPopupColDefs = [
        {
            field: 'label',
            headerComponentFramework: (props: any) => {
                return (
                    <CustomHeader
                        {...props}
                        termSet={termSet}
                        termKey={
                            RDFormulasFormDefs.Product_Selection_Product_Code
                        }
                    />
                );
            },
            ...DefaultColumnTypes.LongText,
        },
        {
            field: 'description',
            headerComponentFramework: (props: any) => {
                return (
                    <CustomHeader
                        {...props}
                        termSet={termSet}
                        termKey={
                            RDFormulasFormDefs.Product_Selection_Product_Name
                        }
                    />
                );
            },
            ...DefaultColumnTypes.LongText,
        },
    ];

    const uomTextPopupColDefs = [
        {
            field: 'label',
            headerComponentFramework: (props: any) => {
                return (
                    <CustomHeader
                        {...props}
                        termSet={termSet}
                        termKey={RDFormulasFormDefs.UOM_Selection_Unit_Code}
                    />
                );
            },
            ...DefaultColumnTypes.LongText,
        },
        {
            field: 'description',
            headerComponentFramework: (props: any) => {
                return (
                    <CustomHeader
                        {...props}
                        termSet={termSet}
                        termKey={RDFormulasFormDefs.UOM_Selection_Description}
                    />
                );
            },
            ...DefaultColumnTypes.LongText,
        },
    ];

    const userTextPopupColDefs = [
        {
            field: 'label',
            headerComponentFramework: (props: any) => {
                return (
                    <CustomHeader
                        {...props}
                        termSet={termSet}
                        termKey={RDFormulasFormDefs.Approved_By_Selection_User}
                    />
                );
            },
            ...DefaultColumnTypes.MediumText,
        },
        {
            field: 'email',
            headerComponentFramework: (props: any) => {
                return (
                    <CustomHeader
                        {...props}
                        termSet={termSet}
                        termKey={RDFormulasFormDefs.Approved_By_Selection_Email}
                    />
                );
            },
            ...DefaultColumnTypes.LongText,
        },
    ];

    /**
     * Set the default status for FormulaStatus field
     */
    useEffect(() => {
        if (
            !isLoadingFormulaStatus &&
            !isNilOrEmpty(formulaStatusOptions) &&
            isNilOrEmpty(id)
        ) {
            const defaultFormulaStatusId = getDefaultFormulaStatus()?.value;
            if (
                defaultFormulaStatusId &&
                isNilOrEmpty(currentParentRecord.formulaStatusId)
            ) {
                handleFieldChange({
                    target: {
                        id: 'formulaStatusId',
                        value: defaultFormulaStatusId,
                    },
                });
            }
        }
    }, [currentParentRecord, isLoadingFormulaStatus, formulaStatusOptions]);

    return {
        productCodeTextPopupColDefs,
        isLoadingFields: {
            isLoadingApprovalTypes,
            isLoadingFormulaStatus,
            isLoadingItemMasterOptions,
            isLoadingUnitOfMeasure,
            isLoadingUserOptions,
        },
        headerPermissions,
        formulaStatusOptions,
        approvalTypeOptions,
        unitOfMeasureOptions,
        itemMasterOptions,
        userList,
        setDropdownOptions,
        dropdownOptions,
        getDefaultFormulaStatus,
        uomTextPopupColDefs,
        userTextPopupColDefs,
        termSet,
    };
};
export default useRDFormulaHeaderForm;
