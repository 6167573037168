import React from 'react';
import useFixedAssetDepreciationEntriesGrid, {
    AssetEntryGridMode,
    FixedAssetEntryAssetRow,
    FixedAssetEntryRow,
} from './hooks/useFixedAssetDepreciationEntriesGrid';
import { Card, CardContent, CardHeader } from '@mui/material';
import BaseGrid from '../../components/grids/BaseGrid';
import { AgGridColumn } from 'ag-grid-react';
import DefaultColumnTypes from '../../components/grids/columns/Column.constants';
import FixedAssetDepreciationEntriesOptionsModal from './fixedAssetDepreciationEntries/FixedAssetDepreciationEntriesOptionsModal';
import { AgGridColumnProps } from 'ag-grid-react/lib/shared/agGridColumn';
import FixedAssetDepreciationEntriesActionsModal from './fixedAssetDepreciationEntries/FixedAssetDepreciationEntriesActionsModal';
import FixedAssetExportDepreciationEntriesModal from './fixedAssetDepreciationEntries/FixedAssetExportDepreciationEntriesModal';
import { TabContext, TabPanel } from '@mui/lab';
import DatacorLogoSpinner from '../../components/datacorLogoSpinner/DatacorLogoSpinner';
import {
    ExcelStyle,
    GetDetailRowDataParams,
    RowHeightParams,
    ValueGetterParams,
} from 'ag-grid-community';
import { DepreciationEntriesGridDefs } from '../../constants/i18n/translations/termDefinitions/accounting';
import CustomHeader from '../../components/grids/CustomHeader';
import TranslatableText from '../../components/i18n/TranslatableText';

const FixedAssetDepreciationEntriesGrid = () => {
    const {
        depreciationEntries,
        setDepreciationEntryList,
        showOptionsModal,
        setShowOptionsModal,
        showActionsModal,
        setShowActionsModal,
        showExportModal,
        setShowExportModal,
        entryToolbar,
        assetTypeColumnRenderer,
        dateColumnRenderer,
        accountColumnRenderer,
        assetAccountColumnRenderer,
        entryDescriptionColumnRenderer,
        assetAccountNameColumnRenderer,
        debitColumnRenderer,
        assetDebitColumnRenderer,
        creditColumnRenderer,
        assetCreditColumnRenderer,
        onGridReady,
        onSelectionChanged,
        selectedEntries,
        selectedAccounts,
        handleUpdatedEntriesList,
        currentTab,
        currentTabTitle,
        currentGridMode,
        isLoadingSubGrid,
        subGridToolbar,
        subGridEntries,
        onSubGridReady,
        onSubGridSelectionChanged,
        canDownloadDepreciationEntries,
        canLockDepreciationEntries,
        canViewDepreciationEntries,
        termSet,
    } = useFixedAssetDepreciationEntriesGrid();
    const StackedColumn: AgGridColumnProps = {
        filter: false,
        sortable: false,
    };

    const StackedCurrencyColumn: AgGridColumnProps = {
        ...StackedColumn,
        width: 150,
        minWidth: 100,
        maxWidth: 200,
        headerClass: 'ag-right-aligned-header',
        cellClass: 'ag-right-aligned-cell',
    };

    const excelStyles: ExcelStyle[] = [
        {
            id: 'cell',
            alignment: {
                wrapText: true,
            },
        },
    ];

    const getDetailRowId = (data: any) => {
        return data.id;
    };

    const detailCellRendererParams = {
        detailGridOptions: {
            immutableData: true,
            getRowNodeId: getDetailRowId,
            getRowHeight: (params: RowHeightParams) => {
                return (
                    (params.data as FixedAssetEntryAssetRow).entries.length * 30
                );
            },
            defaultColDef: {
                resizable: true,
            },
            frameworkComponents: {
                AccountColumnRenderer: assetAccountColumnRenderer,
                AccountNameColumnRender: assetAccountNameColumnRenderer,
                DebitColumnRenderer: assetDebitColumnRenderer,
                CreditColumnRenderer: assetCreditColumnRenderer,
            },
            excelStyles: excelStyles,
            columnDefs: [
                //Depreciation Method Type Grid
                {
                    field: 'fixedAsset.code',
                    headerComponentFramework: (props: any) => {
                        return (
                            <TranslatableText
                                termSet={termSet}
                                termKey={
                                    DepreciationEntriesGridDefs.Depreciation_Method_Type_Asset_Code
                                }
                            />
                        );
                    },
                    ...DefaultColumnTypes.ShortText,
                },
                {
                    field: 'interval',
                    headerComponentFramework: (props: any) => {
                        return (
                            <TranslatableText
                                termSet={termSet}
                                termKey={
                                    DepreciationEntriesGridDefs.Depreciation_Method_Type_Interval
                                }
                            />
                        );
                    },
                    ...DefaultColumnTypes.NumberOnly,
                },
                {
                    field: 'lockedScheduleSummary',
                    headerComponentFramework: (props: any) => {
                        return (
                            <TranslatableText
                                termSet={termSet}
                                termKey={
                                    DepreciationEntriesGridDefs.Depreciation_Method_Type_Locked
                                }
                            />
                        );
                    },
                    ...DefaultColumnTypes.LockedInformationColumn,
                },
                {
                    headerComponentFramework: (props: any) => {
                        return (
                            <TranslatableText
                                termSet={termSet}
                                termKey={
                                    DepreciationEntriesGridDefs.Depreciation_Method_Type_Account_Number
                                }
                            />
                        );
                    },
                    cellRenderer: 'AccountColumnRenderer',
                    valueGetter: (params: ValueGetterParams) => {
                        return (params.data as FixedAssetEntryAssetRow)
                            .accountNumberList;
                    },
                    width: 125,
                    minWidth: 50,
                    maxWidth: 150,
                    ...StackedColumn,
                },
                {
                    headerComponentFramework: (props: any) => {
                        return (
                            <TranslatableText
                                termSet={termSet}
                                termKey={
                                    DepreciationEntriesGridDefs.Depreciation_Method_Type_Account_Name
                                }
                            />
                        );
                    },
                    cellRenderer: 'AccountNameColumnRender',
                    valueGetter: (params: ValueGetterParams) => {
                        return (params.data as FixedAssetEntryAssetRow)
                            .accountNameList;
                    },
                    width: 300,
                    minWidth: 200,
                    maxWidth: 400,
                    ...StackedColumn,
                },
                {
                    headerComponentFramework: (props: any) => {
                        return (
                            <TranslatableText
                                termSet={termSet}
                                termKey={
                                    DepreciationEntriesGridDefs.Depreciation_Method_Type_Debit
                                }
                            />
                        );
                    },
                    cellRenderer: 'DebitColumnRenderer',
                    valueGetter: (params: ValueGetterParams) => {
                        return (params.data as FixedAssetEntryAssetRow)
                            .debitList;
                    },
                    ...StackedCurrencyColumn,
                },
                {
                    headerComponentFramework: (props: any) => {
                        return (
                            <TranslatableText
                                termSet={termSet}
                                termKey={
                                    DepreciationEntriesGridDefs.Depreciation_Method_Type_Credit
                                }
                            />
                        );
                    },
                    cellRenderer: 'CreditColumnRenderer',
                    valueGetter: (params: ValueGetterParams) => {
                        return (params.data as FixedAssetEntryAssetRow)
                            .creditList;
                    },
                    ...StackedCurrencyColumn,
                },
            ],
        },
        getDetailRowData: (params: GetDetailRowDataParams) => {
            params.successCallback(params.data.entriesByAsset);
        },
    };
    const subGridDetailCellRendererParams = {
        detailGridOptions: {
            immutableData: true,
            getRowNodeId: getDetailRowId,
            getRowHeight: (params: RowHeightParams) => {
                return (
                    (params.data as FixedAssetEntryAssetRow).entries.length * 30
                );
            },
            defaultColDef: {
                resizable: true,
            },
            frameworkComponents: {
                AccountColumnRenderer: assetAccountColumnRenderer,
                AccountNameColumnRender: assetAccountNameColumnRenderer,
                DebitColumnRenderer: assetDebitColumnRenderer,
                CreditColumnRenderer: assetCreditColumnRenderer,
            },
            rowSelection: 'multiple',
            rowMultiSelectWithClick: true,
            onSelectionChanged: onSubGridSelectionChanged,
            excelStyles: excelStyles,
            columnDefs: [
                //MULTI GRID
                {
                    headerComponentFramework: (props: any) => {
                        return (
                            <TranslatableText
                                termSet={termSet}
                                termKey={
                                    DepreciationEntriesGridDefs.Multi_Grid_Selected
                                }
                            />
                        );
                    },
                    ...DefaultColumnTypes.CheckboxSelection,
                },
                {
                    field: 'fixedAsset.code',
                    headerComponentFramework: (props: any) => {
                        return (
                            <TranslatableText
                                termSet={termSet}
                                termKey={
                                    DepreciationEntriesGridDefs.Multi_Grid_Asset_Code
                                }
                            />
                        );
                    },
                    ...DefaultColumnTypes.ShortText,
                },
                {
                    field: 'interval',
                    headerComponentFramework: (props: any) => {
                        return (
                            <TranslatableText
                                termSet={termSet}
                                termKey={
                                    DepreciationEntriesGridDefs.Multi_Grid_Interval
                                }
                            />
                        );
                    },
                    ...DefaultColumnTypes.NumberOnly,
                },
                {
                    field: 'lockedScheduleSummary',
                    headerComponentFramework: (props: any) => {
                        return (
                            <TranslatableText
                                termSet={termSet}
                                termKey={
                                    DepreciationEntriesGridDefs.Multi_Grid_Locked
                                }
                            />
                        );
                    },
                    ...DefaultColumnTypes.LockedInformationColumn,
                },
                {
                    headerComponentFramework: (props: any) => {
                        return (
                            <TranslatableText
                                termSet={termSet}
                                termKey={
                                    DepreciationEntriesGridDefs.Multi_Grid_Account_Number
                                }
                            />
                        );
                    },
                    cellRenderer: 'AccountColumnRenderer',
                    valueGetter: (params: ValueGetterParams) => {
                        return (params.data as FixedAssetEntryAssetRow)
                            .accountNumberList;
                    },
                    width: 125,
                    minWidth: 50,
                    maxWidth: 150,
                    ...StackedColumn,
                },
                {
                    headerComponentFramework: (props: any) => {
                        return (
                            <TranslatableText
                                termSet={termSet}
                                termKey={
                                    DepreciationEntriesGridDefs.Multi_Grid_Account_Name
                                }
                            />
                        );
                    },
                    cellRenderer: 'AccountNameColumnRender',
                    valueGetter: (params: ValueGetterParams) => {
                        return (params.data as FixedAssetEntryAssetRow)
                            .accountNameList;
                    },
                    width: 300,
                    minWidth: 200,
                    maxWidth: 400,
                    ...StackedColumn,
                },
                {
                    headerComponentFramework: (props: any) => {
                        return (
                            <TranslatableText
                                termSet={termSet}
                                termKey={
                                    DepreciationEntriesGridDefs.Multi_Grid_Debit
                                }
                            />
                        );
                    },
                    cellRenderer: 'DebitColumnRenderer',
                    valueGetter: (params: ValueGetterParams) => {
                        return (params.data as FixedAssetEntryAssetRow)
                            .debitList;
                    },
                    ...StackedCurrencyColumn,
                },
                {
                    headerComponentFramework: (props: any) => {
                        return (
                            <TranslatableText
                                termSet={termSet}
                                termKey={
                                    DepreciationEntriesGridDefs.Multi_Grid_Credit
                                }
                            />
                        );
                    },
                    cellRenderer: 'CreditColumnRenderer',
                    valueGetter: (params: ValueGetterParams) => {
                        return (params.data as FixedAssetEntryAssetRow)
                            .creditList;
                    },
                    ...StackedCurrencyColumn,
                },
            ],
        },
        getDetailRowData: (params: GetDetailRowDataParams) => {
            params.successCallback(params.data.entriesByAsset);
        },
    };

    return (
        <>
            {canViewDepreciationEntries ? (
                <Card>
                    <CardHeader title={currentTabTitle} />
                    <CardContent className={'full-size-item'}>
                        <FixedAssetDepreciationEntriesOptionsModal
                            open={showOptionsModal}
                            onEntriesLoaded={(entries) =>
                                setDepreciationEntryList(entries)
                            }
                            onClose={() => setShowOptionsModal(false)}
                        />
                        {canLockDepreciationEntries ? (
                            <FixedAssetDepreciationEntriesActionsModal
                                open={showActionsModal}
                                onClose={() => setShowActionsModal(false)}
                                selectedEntries={selectedEntries}
                                onEntriesUpdated={(entries) =>
                                    handleUpdatedEntriesList(entries)
                                }
                            />
                        ) : null}

                        {canDownloadDepreciationEntries ? (
                            <FixedAssetExportDepreciationEntriesModal
                                open={showExportModal}
                                onClose={() => setShowExportModal(false)}
                                depreciationEntries={selectedAccounts}
                            />
                        ) : null}

                        <TabContext value={currentTab}>
                            <TabPanel value="0">
                                <BaseGrid
                                    paginationPageSize={30}
                                    dataKey="id"
                                    immutableData={true}
                                    getRowHeight={(params) => {
                                        return (
                                            (params.data as FixedAssetEntryRow)
                                                .entriesByAccount.length * 30
                                        );
                                    }}
                                    rowData={depreciationEntries}
                                    displayGrid={true}
                                    rowSelection="multiple"
                                    suppressRowClickSelection={true}
                                    defaultColDef={{
                                        resizable: true,
                                        sortable: true,
                                        unSortIcon: true,
                                        filter: true,
                                        wrapText: true,
                                        floatingFilter: true,
                                    }}
                                    frameworkComponents={{
                                        AssetTypeColumnRenderer:
                                            assetTypeColumnRenderer,
                                        DateColumnRenderer: dateColumnRenderer,
                                        AccountColumnRenderer:
                                            accountColumnRenderer,
                                        EntryDescriptionColumnRenderer:
                                            entryDescriptionColumnRenderer,
                                        DebitColumnRenderer:
                                            debitColumnRenderer,
                                        CreditColumnRenderer:
                                            creditColumnRenderer,
                                    }}
                                    excelStyles={excelStyles}
                                    onGridReady={onGridReady}
                                    onSelectionChanged={onSelectionChanged}
                                    masterDetail={true}
                                    detailCellRendererParams={
                                        detailCellRendererParams
                                    }
                                    detailRowAutoHeight={true}
                                    displayToolbar={true}
                                    toolbarChildren={entryToolbar()}>
                                    <AgGridColumn
                                        //MAIN GRID
                                        headerComponentFramework={(
                                            props: any
                                        ) => {
                                            return (
                                                <CustomHeader
                                                    {...props}
                                                    termSet={termSet}
                                                    termKey={
                                                        DepreciationEntriesGridDefs.Selected
                                                    }
                                                />
                                            );
                                        }}
                                        headerCheckboxSelection={true}
                                        {...DefaultColumnTypes.CheckboxSelection}
                                    />
                                    <AgGridColumn
                                        field="assetType.depreciationMethod.depreciationMethodType.code"
                                        headerComponentFramework={(
                                            props: any
                                        ) => {
                                            return (
                                                <CustomHeader
                                                    {...props}
                                                    termSet={termSet}
                                                    termKey={
                                                        DepreciationEntriesGridDefs.Depreciation_Method_Type
                                                    }
                                                />
                                            );
                                        }}
                                        cellRenderer="agGroupCellRenderer"
                                        {...DefaultColumnTypes.ShortText}
                                    />
                                    <AgGridColumn
                                        field="assetType.code"
                                        headerComponentFramework={(
                                            props: any
                                        ) => {
                                            return (
                                                <CustomHeader
                                                    {...props}
                                                    termSet={termSet}
                                                    termKey={
                                                        DepreciationEntriesGridDefs.Fixed_Asset_Type
                                                    }
                                                />
                                            );
                                        }}
                                        cellRenderer="AssetTypeColumnRenderer"
                                        {...DefaultColumnTypes.ShortText}
                                    />
                                    <AgGridColumn
                                        field="intervalDate"
                                        headerComponentFramework={(
                                            props: any
                                        ) => {
                                            return (
                                                <CustomHeader
                                                    {...props}
                                                    termSet={termSet}
                                                    termKey={
                                                        DepreciationEntriesGridDefs.Date
                                                    }
                                                />
                                            );
                                        }}
                                        sort="asc"
                                        cellRenderer="DateColumnRenderer"
                                        {...DefaultColumnTypes.DateOnly}
                                    />
                                    <AgGridColumn
                                        width={230}
                                        minWidth={180}
                                        maxWidth={280}
                                        field="lockedScheduleSummary"
                                        headerComponentFramework={(
                                            props: any
                                        ) => {
                                            return (
                                                <TranslatableText
                                                    termSet={termSet}
                                                    termKey={
                                                        DepreciationEntriesGridDefs.Locked
                                                    }
                                                />
                                            );
                                        }}
                                        {...DefaultColumnTypes.StackedColumn}
                                    />
                                    <AgGridColumn
                                        headerComponentFramework={(
                                            props: any
                                        ) => {
                                            return (
                                                <TranslatableText
                                                    termSet={termSet}
                                                    termKey={
                                                        DepreciationEntriesGridDefs.Account_Number
                                                    }
                                                />
                                            );
                                        }}
                                        cellRenderer="AccountColumnRenderer"
                                        valueGetter={(params) => {
                                            return (
                                                params.data as FixedAssetEntryRow
                                            ).accountList;
                                        }}
                                        width={125}
                                        minWidth={50}
                                        maxWidth={150}
                                        {...StackedColumn}
                                    />
                                    <AgGridColumn
                                        headerComponentFramework={(
                                            props: any
                                        ) => {
                                            return (
                                                <TranslatableText
                                                    termSet={termSet}
                                                    termKey={
                                                        DepreciationEntriesGridDefs.Entry_Description
                                                    }
                                                />
                                            );
                                        }}
                                        cellRenderer="EntryDescriptionColumnRenderer"
                                        valueGetter={(params) => {
                                            return (
                                                params.data as FixedAssetEntryRow
                                            ).entryDescriptionList;
                                        }}
                                        width={300}
                                        minWidth={200}
                                        maxWidth={400}
                                        {...StackedColumn}
                                    />
                                    <AgGridColumn
                                        headerComponentFramework={(
                                            props: any
                                        ) => {
                                            return (
                                                <TranslatableText
                                                    termSet={termSet}
                                                    termKey={
                                                        DepreciationEntriesGridDefs.Debit
                                                    }
                                                />
                                            );
                                        }}
                                        cellRenderer="DebitColumnRenderer"
                                        valueGetter={(params) => {
                                            return (
                                                params.data as FixedAssetEntryRow
                                            ).debitList;
                                        }}
                                        {...StackedCurrencyColumn}
                                    />
                                    <AgGridColumn
                                        headerComponentFramework={(
                                            props: any
                                        ) => {
                                            return (
                                                <TranslatableText
                                                    termSet={termSet}
                                                    termKey={
                                                        DepreciationEntriesGridDefs.Credit
                                                    }
                                                />
                                            );
                                        }}
                                        cellRenderer="CreditColumnRenderer"
                                        valueGetter={(params) => {
                                            return (
                                                params.data as FixedAssetEntryRow
                                            ).creditList;
                                        }}
                                        {...StackedCurrencyColumn}
                                    />
                                </BaseGrid>
                            </TabPanel>
                            <TabPanel value="1">
                                {isLoadingSubGrid ? (
                                    <DatacorLogoSpinner />
                                ) : (
                                    <BaseGrid
                                        paginationPageSize={30}
                                        dataKey="id"
                                        immutableData={true}
                                        rowHeight={30}
                                        rowData={subGridEntries}
                                        displayGrid={true}
                                        getRowHeight={(params) => {
                                            return (
                                                (
                                                    params.data as FixedAssetEntryRow
                                                ).entriesByAccount.length * 30
                                            );
                                        }}
                                        defaultColDef={{
                                            resizable: true,
                                            sortable: true,
                                            unSortIcon: true,
                                            filter: true,
                                            wrapText: true,
                                            floatingFilter: true,
                                        }}
                                        frameworkComponents={{
                                            AccountColumnRenderer:
                                                accountColumnRenderer,
                                            EntryDescriptionColumnRenderer:
                                                entryDescriptionColumnRenderer,
                                            DebitColumnRenderer:
                                                debitColumnRenderer,
                                            CreditColumnRenderer:
                                                creditColumnRenderer,
                                        }}
                                        excelStyles={excelStyles}
                                        onGridReady={onSubGridReady}
                                        masterDetail={true}
                                        detailCellRendererParams={
                                            subGridDetailCellRendererParams
                                        }
                                        detailRowAutoHeight={true}
                                        displayToolbar={true}
                                        toolbarChildren={subGridToolbar()}>
                                        <AgGridColumn
                                            field="assetType.code"
                                            headerComponentFramework={(
                                                props: any
                                            ) => {
                                                return (
                                                    <CustomHeader
                                                        {...props}
                                                        termSet={termSet}
                                                        termKey={
                                                            DepreciationEntriesGridDefs.Date_Fixed_Asset_Type
                                                        }
                                                    />
                                                );
                                            }}
                                            cellRenderer="agGroupCellRenderer"
                                            hide={
                                                currentGridMode ===
                                                AssetEntryGridMode.ByAssetType
                                            }
                                            {...DefaultColumnTypes.ShortText}
                                        />
                                        <AgGridColumn
                                            field="intervalDate"
                                            headerComponentFramework={(
                                                props: any
                                            ) => {
                                                return (
                                                    <CustomHeader
                                                        {...props}
                                                        termSet={termSet}
                                                        termKey={
                                                            DepreciationEntriesGridDefs.Fixed_Asset_Type_Date
                                                        }
                                                    />
                                                );
                                            }}
                                            sort="asc"
                                            cellRenderer="agGroupCellRenderer"
                                            hide={
                                                currentGridMode ===
                                                AssetEntryGridMode.ByDate
                                            }
                                            {...DefaultColumnTypes.DateOnly}
                                        />
                                        <AgGridColumn
                                            width={230}
                                            minWidth={180}
                                            maxWidth={280}
                                            field="lockedScheduleSummary"
                                            headerComponentFramework={(
                                                props: any
                                            ) => {
                                                return (
                                                    <TranslatableText
                                                        {...props}
                                                        termSet={termSet}
                                                        termKey={
                                                            DepreciationEntriesGridDefs.Fixed_Asset_Type_Locked
                                                        }
                                                    />
                                                );
                                            }}
                                            {...DefaultColumnTypes.StackedColumn}
                                        />
                                        <AgGridColumn
                                            headerComponentFramework={(
                                                props: any
                                            ) => {
                                                return (
                                                    <TranslatableText
                                                        {...props}
                                                        termSet={termSet}
                                                        termKey={
                                                            DepreciationEntriesGridDefs.Fixed_Asset_Type_Account_Number
                                                        }
                                                    />
                                                );
                                            }}
                                            cellRenderer="AccountColumnRenderer"
                                            valueGetter={(params) => {
                                                return (
                                                    params.data as FixedAssetEntryRow
                                                ).accountList;
                                            }}
                                            width={125}
                                            minWidth={100}
                                            maxWidth={150}
                                            {...StackedColumn}
                                        />
                                        <AgGridColumn
                                            headerComponentFramework={(
                                                props: any
                                            ) => {
                                                return (
                                                    <TranslatableText
                                                        {...props}
                                                        termSet={termSet}
                                                        termKey={
                                                            DepreciationEntriesGridDefs.Fixed_Asset_Type_Entry_Description
                                                        }
                                                    />
                                                );
                                            }}
                                            cellRenderer="EntryDescriptionColumnRenderer"
                                            valueGetter={(params) => {
                                                return (
                                                    params.data as FixedAssetEntryRow
                                                ).entryDescriptionList;
                                            }}
                                            width={300}
                                            minWidth={200}
                                            maxWidth={400}
                                            {...StackedColumn}
                                        />
                                        <AgGridColumn
                                            headerComponentFramework={(
                                                props: any
                                            ) => {
                                                return (
                                                    <TranslatableText
                                                        {...props}
                                                        termSet={termSet}
                                                        termKey={
                                                            DepreciationEntriesGridDefs.Fixed_Asset_Type_Debit
                                                        }
                                                    />
                                                );
                                            }}
                                            cellRenderer="DebitColumnRenderer"
                                            valueGetter={(params) => {
                                                return (
                                                    params.data as FixedAssetEntryRow
                                                ).debitList;
                                            }}
                                            {...StackedCurrencyColumn}
                                        />
                                        <AgGridColumn
                                            headerComponentFramework={(
                                                props: any
                                            ) => {
                                                return (
                                                    <TranslatableText
                                                        {...props}
                                                        termSet={termSet}
                                                        termKey={
                                                            DepreciationEntriesGridDefs.Fixed_Asset_Type_Credit
                                                        }
                                                    />
                                                );
                                            }}
                                            cellRenderer="CreditColumnRenderer"
                                            valueGetter={(params) => {
                                                return (
                                                    params.data as FixedAssetEntryRow
                                                ).creditList;
                                            }}
                                            {...StackedCurrencyColumn}
                                        />
                                    </BaseGrid>
                                )}
                            </TabPanel>
                        </TabContext>
                    </CardContent>
                </Card>
            ) : null}
        </>
    );
};

export default FixedAssetDepreciationEntriesGrid;
