import { ChildEditableGridWithCopyFunctionProps } from '../../../../../grid/utils/editableGrid/ChildEditableGridWithCopyFunctionProps';
import {
    CasMaster,
    CasHazardClassificationMapping,
} from '../../../../../../types/formulation';
import { GridOptions } from '../../../../../../components/grids/Grid.constants';
import DatacorLogoSpinner from '../../../../../../components/datacorLogoSpinner/DatacorLogoSpinner';
import TranslatableText from '../../../../../../components/i18n/TranslatableText';
import { CasMasterFormDefs } from '../../../../../../constants/i18n/translations/termDefinitions/formulation';
import BaseGrid from '../../../../../../components/grids/BaseGrid';
import React from 'react';
import FormPartial from '../../../../../../components/form/formContainer/FormPartial';
import useCasHazardClassificationMappingGrid from './hooks/useCasHazardClassificationMappingGrid';

const CasHazardClassificationMappingGrid = (
    props: ChildEditableGridWithCopyFunctionProps<
        CasMaster,
        CasHazardClassificationMapping
    >
) => {
    const {
        columnDefs,
        termSet,
        canViewHazardClassMappings,
        handleSaveGridEdits,
        handleRowValidations,
    } = useCasHazardClassificationMappingGrid(props);

    const { isParentLoading } = props.parentData;

    const { isGridEditable } = props.displayGridButtons;

    const { rowsData } = props.copyMethods;

    const defaultColumnDefinitionOptions = {
        ...GridOptions.sortFilterAndWrapColumns,
        floatingFilter: true,
    };

    return !columnDefs && canViewHazardClassMappings ? (
        <DatacorLogoSpinner />
    ) : (
        <>
            <FormPartial
                title={
                    (
                        <TranslatableText
                            termSet={termSet}
                            termKey={
                                CasMasterFormDefs.Hazard_Classification_Mapping
                            }
                        />
                    ) as unknown as string
                }>
                {!columnDefs && !canViewHazardClassMappings ? (
                    <div className="spinnerContainer">
                        Setup Data Unavailable
                    </div>
                ) : (
                    <BaseGrid
                        autoSize={true}
                        displayGrid={!isParentLoading}
                        dataKey={'hazardClasificationId'}
                        useRowDrag={false}
                        displaySortButton={false}
                        rowData={rowsData}
                        columnDefs={columnDefs}
                        gridClass="full-size-item"
                        displayToolbar={true}
                        displayCreateNewButton={false}
                        displayExportDataButton={false}
                        displayResetStateButton={false}
                        defaultColDef={defaultColumnDefinitionOptions}
                        sizeColumnsToFit={true}
                        isEditable={isGridEditable}
                        onHandleGridEdits={handleSaveGridEdits}
                        onHandleRowValidations={handleRowValidations}
                        createRow={false}
                        gridButtonTitle={'Hazard Classification'}></BaseGrid>
                )}
            </FormPartial>
        </>
    );
};

export default CasHazardClassificationMappingGrid;
