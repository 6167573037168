import React, { useContext, useEffect, useState } from 'react';
import { skipToken } from '@reduxjs/toolkit/query';
import { HazardClassificationRule } from '../../../../../types/formulation';
import useBaseForm from '../../../../form/hooks/useBaseForm';
import { RootStateOrAny, useSelector } from 'react-redux';
import { PermissionsUtil } from '../../../../../utils/permissions/permissionsUtil';
import { PERMISSIONS } from '../../../../../constants/permissions/Permissions.constants';
import {
    useCreateHazardClassificationRulesMutation,
    useDeleteHazardClassificationRulesMutation,
    useGetHazardClassificationRulesQuery,
    useUpdateHazardClassificationRulesMutation,
} from '../../../../../services/formulation/hazardClassificationRules/hazardClassificationRules.service';
import { useGetFormTypesForDropdownQuery } from '../../../../../services/formulation/hazardClassificationRules/formType/formType.service';
import { useGetHazardClassificationStepForDropdownQuery } from '../../../../../services/formulation/hazardClassificationRules/hazardClassificationStep/hazardClassificationStep.service';
import SettingsContext from '../../../../../contexts/settings.context';
import { useGetTermSetQuery } from '../../../../../services/i18n/i18n.service';
import { FORMULATION_DEFS } from '../../../../../constants/i18n/translations/termSetDefinitions/formulation';
import { useGetCasCompositionOperatorsForDropdownQuery } from '../../../../../services/formulation/casCompositionOperator/casCompositionOperator.service';
import { useListLocaleGroupDropDownQuery } from '../../../../../services/formulation/localeGroup/localeGroup.service';
import { useListHazardClassificationsQuery } from '../../../../../services/formulation/hazardClassification/hazardClassification.service';
import { ColDef } from 'ag-grid-community';
import CustomHeader from '../../../../../components/grids/CustomHeader';
import { HazardClassRulesFormDefs } from '../../../../../constants/i18n/translations/termDefinitions/formulation';
import DefaultColumnTypes from '../../../../../components/grids/columns/Column.constants';
import { isNilOrEmpty } from '../../../../../utils/objectUtils';

const useHazardClassificationRuleForm = (id: string) => {
    const user = useSelector((state: RootStateOrAny) => state.user);

    const {
        data: activeHazardClassificationRule,
        isLoading: isLoadingHazardClassificationRule,
    } = useGetHazardClassificationRulesQuery(id ? id : skipToken);

    const [createHazardClassificationRule] =
        useCreateHazardClassificationRulesMutation();
    const [updateHazardClassificationRule] =
        useUpdateHazardClassificationRulesMutation();
    const [deleteHazardClassificationRule] =
        useDeleteHazardClassificationRulesMutation();

    const { data: formTypeOptions, isLoading: isLoadingFormTypes } =
        useGetFormTypesForDropdownQuery();

    const {
        data: hazardClassificationSteps,
        isLoading: isLoadingHazardClassificationSteps,
    } = useGetHazardClassificationStepForDropdownQuery();

    const { data: lowerOperatorsOptions, isLoading: isLoadingLowerOperators } =
        useGetCasCompositionOperatorsForDropdownQuery(1);
    const { data: upperOperatorsOptions, isLoading: isLoadingUpperOperators } =
        useGetCasCompositionOperatorsForDropdownQuery(2);

    const { data: localeGroupOptions, isLoading: isLoadingLocaleGroups } =
        useListLocaleGroupDropDownQuery();

    const {
        data: hazardClassOptions,
        isLoading: isLoadingHazardClassification,
    } = useListHazardClassificationsQuery();

    const canViewLocaleGroup = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.FORMULATION.LOCALE_GROUPS.VIEW
    );

    const canCreateHazardClassificationRule =
        PermissionsUtil.isPermissionEnabled(
            user.permissions,
            PERMISSIONS.FORMULATION.HAZARD_CLASS_RULES.CREATE
        );
    const canUpdateHazardClassificationRule =
        PermissionsUtil.isPermissionEnabled(
            user.permissions,
            PERMISSIONS.FORMULATION.HAZARD_CLASS_RULES.EDIT
        );
    const canDeleteHazardClassificationRule =
        PermissionsUtil.isPermissionEnabled(
            user.permissions,
            PERMISSIONS.FORMULATION.HAZARD_CLASS_RULES.DELETE
        );

    const blankHazardClassificationRule: HazardClassificationRule = {
        formTypeId: null,
        hazardClassId: null,
        localeGroupId: null,
        sequence: null,
        notes: '',
        category: '',
        basisCategory: '',
        individualOperatorId: null,
        individualPercent: null,
        nextStepId: null,
        lowerOperatorId: null,
        lowerValue: null,
        upperOperatorId: null,
        upperValue: null,
    };

    const { settings } = useContext(SettingsContext);
    const { data: termSet } = useGetTermSetQuery(
        settings?.userSettings
            ? {
                  languageId: settings?.userSettings?.languageId,
                  code: FORMULATION_DEFS.HAZARD_CLASS_RULES_FORM,
              }
            : skipToken
    );

    const dropdownsInitialState: any = {
        localeGroupValue: '',
        selectedLocaleGroup: null,
        hazardClassValue: '',
        selectedHazardClass: null,
    };

    const [dropdownOptions, setDropdownOptions] = useState(
        dropdownsInitialState
    );

    const localeGroupTextPopupColDefs: ColDef[] = [
        {
            field: 'label',
            headerComponentFramework: (props: any) => {
                return (
                    <CustomHeader
                        {...props}
                        termSet={termSet}
                        termKey={
                            HazardClassRulesFormDefs.Locale_Group_Selection
                        }
                    />
                );
            },
            ...DefaultColumnTypes.ShortText,
        },
        {
            field: 'description',
            headerComponentFramework: (props: any) => {
                return (
                    <CustomHeader
                        {...props}
                        termSet={termSet}
                        termKey={
                            HazardClassRulesFormDefs.Locale_Group_Description_Selection
                        }
                    />
                );
            },
            ...DefaultColumnTypes.LongText,
        },
    ];

    const hazardClassTextPopupColDefs: ColDef[] = [
        {
            field: 'code',
            headerComponentFramework: (props: any) => {
                return (
                    <CustomHeader
                        {...props}
                        termSet={termSet}
                        termKey={
                            HazardClassRulesFormDefs.Hazard_Class_Selection
                        }
                    />
                );
            },
            ...DefaultColumnTypes.ShortText,
        },
        {
            field: 'description',
            headerComponentFramework: (props: any) => {
                return (
                    <CustomHeader
                        {...props}
                        termSet={termSet}
                        termKey={
                            HazardClassRulesFormDefs.Hazard_Class_Description_Selection
                        }
                    />
                );
            },
            ...DefaultColumnTypes.LongText,
        },
    ];

    const {
        fields,
        handleFieldChange,
        formMethods,
        onCreate,
        onUpdate,
        onDelete,
        closeForm,
    } = useBaseForm({
        closePath: '/formulation/hazardClassificationRules',
        reroute: true,
        blankEntity: blankHazardClassificationRule,
        activeEntity: activeHazardClassificationRule,
        getDescription: () => {
            return `Hazard Classification Rule with Hazard Class ${dropdownOptions?.selectedHazardClass?.code}`;
        },
        createEntity: () => {
            return createHazardClassificationRule({ postBody: fields });
        },
        updateEntity: () => {
            return updateHazardClassificationRule({
                id: id as unknown as number,
                postBody: fields,
            });
        },
        deleteEntity: () => {
            return deleteHazardClassificationRule(id);
        },
    });

    useEffect(() => {
        if (
            !isLoadingLocaleGroups &&
            !isNilOrEmpty(localeGroupOptions) &&
            !isLoadingHazardClassificationRule &&
            !isNilOrEmpty(hazardClassOptions) &&
            !isLoadingHazardClassification
        ) {
            setDropdownOptions((previousValues: any) => {
                return {
                    ...previousValues,
                    selectedLocaleGroup: localeGroupOptions?.find(
                        (current: any) => current.value === fields.localeGroupId
                    ),
                    selectedHazardClass: hazardClassOptions?.find(
                        (current: any) => current.id === fields.hazardClassId
                    ),
                };
            });

            //Validate lower and upper values
            if (
                !isNilOrEmpty(fields.lowerValue) &&
                !isNilOrEmpty(fields.upperValue) &&
                Number(fields.lowerValue) > Number(fields.upperValue)
            ) {
                const fieldName = 'upperValue';
                const fieldValue = [
                    'Upper Value cannot be less than Lower Value',
                ];
                formMethods.setIsFormValid(false);
                formMethods.setFieldValidationErrors((prevState) => {
                    return prevState
                        ? prevState.set(fieldName, fieldValue)
                        : new Map().set(fieldName, fieldValue);
                });
            }
        }
    }, [
        isLoadingLocaleGroups,
        localeGroupOptions,
        isLoadingHazardClassificationRule,
        hazardClassOptions,
        isLoadingHazardClassification,
        fields,
    ]);

    return {
        fields,
        handleFieldChange,
        formMethods,
        onCreate,
        onUpdate,
        onDelete,
        closeForm,
        isLoadingHazardClassificationRule,
        isLoadingFormTypes,
        isLoadingHazardClassificationSteps,
        isLoadingLowerOperators,
        isLoadingUpperOperators,
        isLoadingHazardClassification,
        isLoadingLocaleGroups,
        formTypeOptions,
        hazardClassificationSteps,
        lowerOperatorsOptions,
        upperOperatorsOptions,
        localeGroupOptions,
        hazardClassOptions,
        canCreateHazardClassificationRule,
        canUpdateHazardClassificationRule,
        canDeleteHazardClassificationRule,
        canViewLocaleGroup,
        localeGroupTextPopupColDefs,
        hazardClassTextPopupColDefs,
        termSet,
        dropdownOptions,
    };
};

export default useHazardClassificationRuleForm;
