import React from 'react';
import HazardClassificationMappingGrid from './HazardClassificationMappingGrid';

const HazardClassificationMapping = () => {
    return (
        <>
            <HazardClassificationMappingGrid />
        </>
    );
};

export default HazardClassificationMapping;
