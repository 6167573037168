import React from 'react';
import FixedAssetGrid from './FixedAssetGrid';

const FixedAssets = () => {
    return (
        <React.Fragment>
            <FixedAssetGrid />
        </React.Fragment>
    );
};

export default FixedAssets;
