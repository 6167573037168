import {
    useDeleteDepreciationEntriesMutation,
    useLockDepreciationEntriesMutation,
    useUnlockDepreciationEntriesMutation,
} from '../../../../../services/fixedAssets/fixedAssets.service';
import { FA_DepreciationEntry } from '../../../../../types/FixedAsset.types';
import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../store';
import { PermissionsUtil } from '../../../../../utils/permissions/permissionsUtil';
import { PERMISSIONS } from '../../../../../constants/permissions/Permissions.constants';
import { DepreciationEntriesGridProps } from '../DepreciationEntryGrid';
import {
    currencyFormatter,
    dateFormatter,
} from '../../../../../utils/formattingUtils';
import { useGetTermSetQuery } from '../../../../../services/i18n/i18n.service';
import SettingsContext from '../../../../../contexts/settings.context';
import { ACCOUNTING_DEFS } from '../../../../../constants/i18n/translations/termSetDefinitions/accounting';
import { skipToken } from '@reduxjs/toolkit/query';

export class DepreciationEntryRow {
    public readonly id: string;
    public readonly interval: number;
    public readonly intervalDate: Date;
    public readonly lockedSummary: string;
    public readonly locked: boolean;
    public readonly depreciationEntries: FA_DepreciationEntry[] = [];

    constructor(depreciationEntry: FA_DepreciationEntry) {
        this.id = `${depreciationEntry.interval}`;
        this.interval = depreciationEntry.interval;
        this.intervalDate = depreciationEntry.intervalDate;
        this.locked = depreciationEntry.locked;
        this.lockedSummary = depreciationEntry.locked
            ? `${dateFormatter(depreciationEntry.lockedDate)} (${
                  depreciationEntry.lockedUserName
              })`
            : '';
        this.addEntry(depreciationEntry);
    }

    public addEntry = (entry: FA_DepreciationEntry) => {
        this.depreciationEntries.push(entry);
    };

    public get accountNo(): string {
        return this.depreciationEntries
            .sort((a, b) => Number(a.accountId) - Number(b.accountId))
            .map((row) => row.account.number)
            .join('\r\n');
    }

    public get accountName(): string {
        return this.depreciationEntries
            .sort((a, b) => Number(a.accountId) - Number(b.accountId))
            .map((row) => row.account.name)
            .join('\r\n');
    }

    public get debit(): string {
        return this.depreciationEntries
            .sort((a, b) => Number(a.accountId) - Number(b.accountId))
            .map((row) => currencyFormatter(row.debit))
            .join('\r\n');
    }

    public get credit(): string {
        return this.depreciationEntries
            .sort((a, b) => Number(a.accountId) - Number(b.accountId))
            .map((row) => currencyFormatter(row.credit))
            .join('\r\n');
    }
}

export const useDepreciationEntryGrid = (
    props: DepreciationEntriesGridProps
) => {
    const user = useSelector((state: RootState) => state.user);
    const [showCreateEntriesModal, setShowCreateEntriesModal] =
        useState<boolean>(false);
    const [depreciationEntryRows, setDepreciationEntryRows] = useState<
        DepreciationEntryRow[]
    >([]);

    const { settings } = useContext(SettingsContext);
    const { data: termSet } = useGetTermSetQuery(
        settings?.userSettings
            ? {
                  languageId: settings?.userSettings?.languageId,
                  code: ACCOUNTING_DEFS.FIXED_ASSET_REGISTER_FORM,
              }
            : skipToken
    );
    const canCreateDepreciationEntries = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.FIXED_ASSETS.DEPRECIATION_ENTRIES.CREATE
    );
    const canUpdateDepreciationEntries = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.FIXED_ASSETS.DEPRECIATION_ENTRIES.EDIT
    );
    const canDeleteDepreciationEntries = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.FIXED_ASSETS.DEPRECIATION_ENTRIES.DELETE
    );
    const canLockDepreciationEntries = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.FIXED_ASSETS.DEPRECIATION_ENTRIES.LOCK
    );
    const [lockEntries, { isLoading: isLockingEntries }] =
        useLockDepreciationEntriesMutation();
    const [unlockEntries, { isLoading: isUnlockingEntries }] =
        useUnlockDepreciationEntriesMutation();
    const [deleteEntries, { isLoading: isDeletingEntries }] =
        useDeleteDepreciationEntriesMutation();

    const _createRowsFromList = (
        list: FA_DepreciationEntry[]
    ): DepreciationEntryRow[] => {
        const rowMap: Map<string, DepreciationEntryRow> = new Map<
            string,
            DepreciationEntryRow
        >();

        list?.forEach((entry) => {
            //establish the row key for uniqueness
            const rowKey: string = `${entry.interval}`;

            if (!rowMap.has(rowKey)) {
                rowMap.set(rowKey, new DepreciationEntryRow(entry));
            } else {
                rowMap.get(rowKey).addEntry(entry);
            }
        });

        return Array.from(rowMap.values());
    };

    const handleLock = async (data: DepreciationEntryRow) => {
        try {
            await lockEntries({
                ids: data.depreciationEntries.map((entry) => entry.id),
            });
        } catch (e) {
            console.log(e);
        }
    };

    const handleUnlock = async (data: DepreciationEntryRow) => {
        try {
            await unlockEntries({
                ids: data.depreciationEntries.map((entry) => entry.id),
            });
        } catch (e) {
            console.log(e);
        }
    };

    const handleDelete = async (event: any) => {
        event.preventDefault();

        try {
            await deleteEntries({
                fixedAssetId: props.fixedAssetId,
            });
        } catch (e) {
            console.log(e);
        }
    };

    const pinnedBottomRowData =
        props.entries?.length > 0
            ? [
                  {
                      debit: currencyFormatter(props.entriesTotals?.debit),
                      credit: currencyFormatter(props.entriesTotals?.credit),
                      id: null,
                  } as any,
              ]
            : [];

    useEffect(() => {
        setDepreciationEntryRows(_createRowsFromList(props.entries));
    }, [props.entries]);

    return {
        handleUnlock,
        handleLock,
        handleDelete,
        setShowCreateEntriesModal,
        depreciationEntryRows,
        isDeletingEntries,
        isLockingEntries,
        isUnlockingEntries,
        showCreateEntriesModal,
        pinnedBottomRowData,
        canCreateDepreciationEntries,
        canUpdateDepreciationEntries,
        canDeleteDepreciationEntries,
        canLockDepreciationEntries,
        termSet,
    };
};

export default useDepreciationEntryGrid;
