import React from 'react';
import { GridOptions } from '../../../../../../../../components/grids/Grid.constants';
import BaseGrid from '../../../../../../../../components/grids/BaseGrid';
import DatacorLogoSpinner from '../../../../../../../../components/datacorLogoSpinner/DatacorLogoSpinner';
import useCasGhsPrecautionaryPhrasesGrid from './hooks/useCasGhsPrecautionaryPhrasesGrid';
import PhraseVerbiageMessageModal from '../../../../../../shared/phraseVerbiageMessageModal/PhraseVerbiageMessageModal';
import FormPartial from '../../../../../../../../components/form/formContainer/FormPartial';
import { ChildEditableGridWithCopyFunctionProps } from '../../../../../../../grid/utils/editableGrid/ChildEditableGridWithCopyFunctionProps';
import {
    CasGhsPrecautionaryPhrase,
    CasMaster,
} from '../../../../../../../../types/formulation';
import GhsPrecautionaryPhrasesSelectionModal from '../../../../../../shared/multiSelectModals/ghsPrecautionaryPhrases/GhsPrecautionaryPhrasesSelectionModal';
import { CasMasterFormDefs } from '../../../../../../../../constants/i18n/translations/termDefinitions/formulation';
import TranslatableText from '../../../../../../../../components/i18n/TranslatableText';

const CasGhsPrecautionaryPhrasesGrid = (
    props: ChildEditableGridWithCopyFunctionProps<
        CasMaster,
        CasGhsPrecautionaryPhrase
    >
) => {
    const {
        columnDefs,
        ghsPrecautionaryPhraseList,
        showMultiSelectGridModal,
        isLoadingPrecautionaryPhraseList,
        showVerbiageMessage,
        handleSetShowMultiSelectGridModal,
        handleSelectedRecords,
        handleSaveGridEdits,
        handleRowValidations,
        handleShowVerbiageMessage,
        termSet,
    } = useCasGhsPrecautionaryPhrasesGrid(props);

    const { isParentLoading } = props.parentData;

    const { showAddButton, showSortButton, isGridEditable } =
        props.displayGridButtons;

    const { rowsData } = props.copyMethods;

    const defaultColumnDefinitionOptions = {
        ...GridOptions.sortFilterAndWrapColumns,
        floatingFilter: true,
    };

    return !columnDefs || isLoadingPrecautionaryPhraseList ? (
        <DatacorLogoSpinner />
    ) : (
        <>
            <FormPartial
                title={
                    (
                        <TranslatableText
                            termSet={termSet}
                            termKey={
                                CasMasterFormDefs.Ghs_Precautionary_Phrases
                            }
                        />
                    ) as unknown as string
                }>
                <BaseGrid
                    autoSize={true}
                    displayGrid={!isParentLoading}
                    dataKey={'phraseId'}
                    useRowDrag={showSortButton}
                    displaySortButton={showSortButton}
                    sortKey={'sdsSortOrder'}
                    rowData={rowsData}
                    columnDefs={columnDefs}
                    gridClass="full-size-item"
                    displayToolbar={true}
                    displayCreateNewButton={showAddButton}
                    displayExportDataButton={false}
                    displayResetStateButton={false}
                    defaultColDef={defaultColumnDefinitionOptions}
                    sizeColumnsToFit={true}
                    isEditable={isGridEditable}
                    onCreateNew={() => handleSetShowMultiSelectGridModal(true)}
                    onHandleGridEdits={handleSaveGridEdits}
                    onHandleRowValidations={handleRowValidations}
                    createRow={false}
                    gridButtonTitle={'GHS Precautionary Phrases'}></BaseGrid>
                <GhsPrecautionaryPhrasesSelectionModal
                    openModal={showMultiSelectGridModal}
                    onClose={() => handleSetShowMultiSelectGridModal(false)}
                    onSelected={handleSelectedRecords}
                    precautionaryPhraseList={ghsPrecautionaryPhraseList}
                />
                <PhraseVerbiageMessageModal
                    showModal={showVerbiageMessage}
                    handleClose={() => handleShowVerbiageMessage(false)}
                    messageText={
                        (
                            <TranslatableText
                                termSet={termSet}
                                termKey={
                                    CasMasterFormDefs.Ghs_Precautionary_Phrases_Product_Specific_Verbiage
                                }
                            />
                        ) as unknown as string
                    }
                />
            </FormPartial>
        </>
    );
};

export default CasGhsPrecautionaryPhrasesGrid;
