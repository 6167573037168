import { Grid } from '@mui/material';
import FormPartial from '../../../../../../components/form/formContainer/FormPartial';
import React from 'react';
import useItemUnitsOfMeasureForm from './hooks/useItemUnitsOfMeasureForm';
import DatacorLogoSpinner from '../../../../../../components/datacorLogoSpinner/DatacorLogoSpinner';
import { ChildFormProps } from '../../../../../form/helpers/ChildFormProps';
import { ItemMaster } from '../../../../../../types/formulation';
import NumericInput from '../../../../../../components/form/formInputs/NumericInput/NumericInput';
import AutoCompleteInput from '../../../../../../components/form/formInputs/AutoCompleteInput/AutoCompleteInput';
import { ItemMasterFormDefs } from '../../../../../../constants/i18n/translations/termDefinitions/formulation';
import TranslatableText from '../../../../../../components/i18n/TranslatableText';

const ItemUnitsOfMeasureForm = (props: ChildFormProps<ItemMaster>) => {
    const {
        fields,
        handlePropertyChanged,
        dropdownOptions,
        setDropdownOptions,
        isLoadingUnitOfMeasure,
        formMethods,
        isLoadingUnitTypes,
        canViewUnitOfMeasure,
        termSet,
    } = useItemUnitsOfMeasureForm(props);

    const { isParentLoading } = props.parentData;

    const { disableDropdown } = props.helpers;

    return (
        <FormPartial
            title={
                (
                    <TranslatableText
                        termSet={termSet}
                        termKey={ItemMasterFormDefs.Unit_Of_Measure}
                    />
                ) as unknown as string
            }>
            {isParentLoading || isLoadingUnitTypes || isLoadingUnitOfMeasure ? (
                <DatacorLogoSpinner />
            ) : (
                <>
                    <Grid container spacing={2}>
                        <Grid item xs={4}>
                            <AutoCompleteInput
                                id="stockUnitId"
                                inputFieldName="stockInputValue"
                                label={
                                    <TranslatableText
                                        termSet={termSet}
                                        termKey={ItemMasterFormDefs.Stock_Unit}
                                    />
                                }
                                required={true}
                                onHandlePropertyChanged={handlePropertyChanged}
                                options={dropdownOptions.stockUnitOptions}
                                setDropdownOptions={setDropdownOptions}
                                value={dropdownOptions?.selectedStockUnit}
                                inputValue={dropdownOptions?.stockInputValue}
                                isLoading={isLoadingUnitOfMeasure}
                                formMethods={formMethods}
                                disabled={disableDropdown(canViewUnitOfMeasure)}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <NumericInput
                                fullWidth
                                label={
                                    <TranslatableText
                                        termSet={termSet}
                                        termKey={
                                            ItemMasterFormDefs.Specific_Gravity
                                        }
                                    />
                                }
                                id="specificGravity"
                                value={fields.specificGravity}
                                onChange={handlePropertyChanged}
                                formMethods={formMethods}
                                disabled={false}
                                numDecimals={6}
                                greaterThan={0}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <NumericInput
                                fullWidth
                                label={
                                    <TranslatableText
                                        termSet={termSet}
                                        termKey={ItemMasterFormDefs.Density}
                                    />
                                }
                                id="density"
                                value={fields.density}
                                onChange={handlePropertyChanged}
                                formMethods={formMethods}
                                disabled={false}
                                numDecimals={6}
                                greaterThan={0}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <AutoCompleteInput
                                id="weightUnitId"
                                inputFieldName="weightInputValue"
                                label={
                                    <TranslatableText
                                        termSet={termSet}
                                        termKey={ItemMasterFormDefs.Weight_Unit}
                                    />
                                }
                                onHandlePropertyChanged={handlePropertyChanged}
                                options={dropdownOptions.weightUnitOptions}
                                setDropdownOptions={setDropdownOptions}
                                value={dropdownOptions?.selectedWeightUnit}
                                inputValue={dropdownOptions?.weightInputValue}
                                isLoading={isLoadingUnitOfMeasure}
                                formMethods={formMethods}
                                disabled={disableDropdown(canViewUnitOfMeasure)}
                            />
                        </Grid>
                        <Grid item xs={5}>
                            <NumericInput
                                fullWidth
                                label={
                                    <TranslatableText
                                        termSet={termSet}
                                        termKey={
                                            ItemMasterFormDefs.Stock_Weight
                                        }
                                    />
                                }
                                id="stockWeight"
                                value={fields.stockWeight}
                                onChange={handlePropertyChanged}
                                formMethods={formMethods}
                                disabled={false}
                                numDecimals={3}
                                greaterThan={0}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <AutoCompleteInput
                                id="volumeUnitId"
                                inputFieldName="volumeInputValue"
                                label={
                                    <TranslatableText
                                        termSet={termSet}
                                        termKey={ItemMasterFormDefs.Volume_Unit}
                                    />
                                }
                                onHandlePropertyChanged={handlePropertyChanged}
                                options={dropdownOptions.volumeUnitOptions}
                                setDropdownOptions={setDropdownOptions}
                                value={dropdownOptions?.selectedVolumeUnit}
                                inputValue={dropdownOptions?.volumeInputValue}
                                isLoading={isLoadingUnitOfMeasure}
                                formMethods={formMethods}
                                disabled={disableDropdown(canViewUnitOfMeasure)}
                            />
                        </Grid>
                        <Grid item xs={5}>
                            <NumericInput
                                fullWidth
                                label={
                                    <TranslatableText
                                        termSet={termSet}
                                        termKey={
                                            ItemMasterFormDefs.Stock_Volume
                                        }
                                    />
                                }
                                id="stockVolume"
                                value={fields.stockVolume}
                                onChange={handlePropertyChanged}
                                formMethods={formMethods}
                                disabled={false}
                                numDecimals={3}
                                greaterThan={0}
                            />
                        </Grid>
                    </Grid>
                </>
            )}
        </FormPartial>
    );
};

export default ItemUnitsOfMeasureForm;
