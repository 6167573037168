import React from 'react';
import { FA_DepreciationSchedule } from '../../../../types/FixedAsset.types';
import {
    Button,
    Checkbox,
    Dialog,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    Grid,
    IconButton,
    Step,
    StepLabel,
    Stepper,
    Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DateInput from '../../../../components/form/formInputs/DateInput/DateInput';
import { isNil } from '../../../../utils/objectUtils';
import LoaderButton from '../../../../components/LoaderButton';
import { useFixedAssetCreateScheduleStepper } from './hooks/useFixedAssetCreateScheduleStepper';

export interface FixedAssetCreateScheduleProps {
    open: boolean;
    onClose: () => void;
    ids: bigint[];
    startDate: Date;
    isUpdate?: boolean;
    onLedgerScheduleUpdated: (list: FA_DepreciationSchedule[]) => void;
    onTaxScheduleUpdated: (list: FA_DepreciationSchedule[]) => void;
}

const FixedAssetCreateScheduleStepper = (
    props: FixedAssetCreateScheduleProps
) => {
    const {
        activeStep,
        fields,
        handleFieldChange,
        handleNext,
        handlePrevious,
        isLoading,
        handleGenerateSchedules,
        canLockDepreciationSchedules,
    } = useFixedAssetCreateScheduleStepper(props);

    return (
        <Dialog
            open={props.open}
            onClose={() => props.onClose()}
            fullWidth
            maxWidth={'md'}>
            <DialogTitle>
                <Grid container spacing={2}>
                    <Grid item xs={11}>
                        Generate Fixed Asset Schedule(s)
                    </Grid>
                    <Grid item xs={1}>
                        <IconButton
                            aria-label="close"
                            onClick={() => props.onClose()}
                            sx={{
                                position: 'absolute',
                                right: 8,
                                top: 8,
                            }}>
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent>
                <Stepper activeStep={activeStep}>
                    <Step key={0} completed={activeStep > 0}>
                        <StepLabel>Select Asset Types</StepLabel>
                    </Step>
                    <Step key={1} completed={activeStep > 1}>
                        <StepLabel>Input Start Date</StepLabel>
                    </Step>
                    <Step key={2} completed={false}>
                        <StepLabel>Generate Schedule(s)</StepLabel>
                    </Step>
                </Stepper>
                <>
                    {activeStep === 0 && (
                        <>
                            <Grid container spacing={0}>
                                <Grid item xs={12}>
                                    <Typography>
                                        Select which Asset Types to generate
                                        schedules for
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControlLabel
                                        label="Book"
                                        control={
                                            <Checkbox
                                                id="ledgerSchedule"
                                                checked={fields.ledgerSchedule}
                                                onChange={handleFieldChange}
                                            />
                                        }
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControlLabel
                                        label="Tax"
                                        control={
                                            <Checkbox
                                                id="taxSchedule"
                                                checked={fields.taxSchedule}
                                                onChange={handleFieldChange}
                                            />
                                        }
                                    />
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={11} />
                                <Grid item xs={1}>
                                    <Button
                                        onClick={handleNext}
                                        disabled={
                                            !fields.ledgerSchedule &&
                                            !fields.taxSchedule
                                        }>
                                        Next
                                    </Button>
                                </Grid>
                            </Grid>
                        </>
                    )}
                    {activeStep === 1 && (
                        <>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography>
                                        Input A Start Date For This Schedule
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <DateInput
                                        id="startDate"
                                        value={fields.startDate}
                                        onChange={handleFieldChange}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={1}>
                                    <Button onClick={handlePrevious}>
                                        Back
                                    </Button>
                                </Grid>
                                <Grid item xs={10} />
                                <Grid item xs={1}>
                                    <Button
                                        onClick={handleNext}
                                        disabled={isNil(fields.startDate)}>
                                        Next
                                    </Button>
                                </Grid>
                            </Grid>
                        </>
                    )}
                    {activeStep === 2 ? (
                        <React.Fragment>
                            {canLockDepreciationSchedules ? (
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Typography>
                                            Do you wish to lock all intervals
                                            that were created?
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControlLabel
                                            label="Lock Created Intervals"
                                            control={
                                                <Checkbox
                                                    id="lockIntervals"
                                                    checked={
                                                        fields.lockIntervals
                                                    }
                                                    onChange={handleFieldChange}
                                                />
                                            }
                                        />
                                    </Grid>
                                </Grid>
                            ) : null}
                            <Grid container spacing={2}>
                                <Grid item xs={1}>
                                    <Button onClick={handlePrevious}>
                                        Back
                                    </Button>
                                </Grid>
                                <Grid item xs={9} />
                                <Grid item xs={2}>
                                    <LoaderButton
                                        isLoading={isLoading()}
                                        onClick={handleGenerateSchedules}>
                                        Generate Schedule(s)
                                    </LoaderButton>
                                </Grid>
                            </Grid>
                        </React.Fragment>
                    ) : null}
                </>
            </DialogContent>
        </Dialog>
    );
};

export default FixedAssetCreateScheduleStepper;
