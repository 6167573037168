import React from 'react';
import {
    useCreateUnitOfMeasureConversionMutation,
    useUpdateUnitOfMeasureConversionMutation,
    useDeleteUnitOfMeasureConversionMutation,
    useGetUnitOfMeasureConversionQuery,
    useListUnitOfMeasureDropdownQuery,
} from '../../../services/organizations/organizations.service';
import { skipToken } from '@reduxjs/toolkit/query';
import { UnitOfMeasureConversion } from '../../../types/Setup.types';
import useBaseForm from '../../form/hooks/useBaseForm';
import { RootStateOrAny, useSelector } from 'react-redux';
import _ from 'lodash';
import { PermissionsUtil } from '../../../utils/permissions/permissionsUtil';
import { PERMISSIONS } from '../../../constants/permissions/Permissions.constants';

const UseUnitOfMeasureConversionForm = (id: string) => {
    const user = useSelector((state: RootStateOrAny) => state.user);

    const {
        data: unitOfMeasureOptions,
        isLoading: isLoadingUnitOfMeasureList,
    } = useListUnitOfMeasureDropdownQuery(
        user?.businessEntity?.id
            ? user?.businessEntity?.id.toString()
            : skipToken
    );

    const canCreateUnitOfMeasureConversion =
        PermissionsUtil.isPermissionEnabled(
            user.permissions,
            PERMISSIONS.SETUP.UNIT_OF_MEASURE_CONVERSION.CREATE
        );
    const canUpdateUnitOfMeasureConversion =
        PermissionsUtil.isPermissionEnabled(
            user.permissions,
            PERMISSIONS.SETUP.UNIT_OF_MEASURE_CONVERSION.EDIT
        );
    const canDeleteUnitOfMeasureConversion =
        PermissionsUtil.isPermissionEnabled(
            user.permissions,
            PERMISSIONS.SETUP.UNIT_OF_MEASURE_CONVERSION.DELETE
        );

    const {
        data: activeUnitOfMeasureConversion,
        isLoading: isLoadingUnitOfMeasureConversion,
    } = useGetUnitOfMeasureConversionQuery(id ? id : skipToken);
    const [createUnitOfMeasureConversion] =
        useCreateUnitOfMeasureConversionMutation();
    const [updateUnitOfMeasureConversion] =
        useUpdateUnitOfMeasureConversionMutation();
    const [deleteUnitOfMeasureConversion] =
        useDeleteUnitOfMeasureConversionMutation();

    const blankUnitOfMeasureConversion: UnitOfMeasureConversion = {
        sourceUnitOfMeasureId: null,
        destinationUnitOfMeasureId: null,
        sourceQuantity: 1 as unknown as bigint,
        destinationQuantity: null,
        description: 'test',
        businessEntityId: user?.businessEntity?.id,
    };

    const {
        fields,
        handleFieldChange,
        formMethods,
        onCreate,
        onUpdate,
        onDelete,
        closeForm,
    } = useBaseForm({
        closePath: '/setup/unitOfMeasureConversion',
        reroute: true,
        blankEntity: blankUnitOfMeasureConversion,
        activeEntity: activeUnitOfMeasureConversion,
        getDescription: () => {
            return `Unit of Measure Conversion ${fields.description}`;
        },
        createEntity: () => {
            setDescriptionField();
            return createUnitOfMeasureConversion({ postBody: fields });
        },
        updateEntity: () => {
            setDescriptionField();
            return updateUnitOfMeasureConversion({
                id: id as unknown as number,
                postBody: fields,
            });
        },
        deleteEntity: () => {
            return deleteUnitOfMeasureConversion(id);
        },
    });

    const setDescriptionField = () => {
        const sourceUnitOfMeasure = _.find(unitOfMeasureOptions, {
            value: fields.sourceUnitOfMeasureId,
        });
        const destination = _.find(unitOfMeasureOptions, {
            value: fields.destinationUnitOfMeasureId,
        });
        fields.description =
            sourceUnitOfMeasure?.description +
            ' to ' +
            destination?.description;
    };

    return {
        fields,
        handleFieldChange,
        formMethods,
        onCreate,
        onUpdate,
        onDelete,
        closeForm,
        unitOfMeasureOptions,
        isLoadingUnitOfMeasureList,
        isLoadingUnitOfMeasureConversion,
        canCreateUnitOfMeasureConversion,
        canUpdateUnitOfMeasureConversion,
        canDeleteUnitOfMeasureConversion,
    };
};

export default UseUnitOfMeasureConversionForm;
