import React from 'react';
import useCasToxicityGrid from './hooks/useCasToxicityGrid';
import { isNilOrEmpty } from '../../../../../../utils/objectUtils';
import DatacorLogoSpinner from '../../../../../../components/datacorLogoSpinner/DatacorLogoSpinner';
import BaseGrid from '../../../../../../components/grids/BaseGrid';
import FormPartial from '../../../../../../components/form/formContainer/FormPartial';
import { ChildEditableGridWithCopyFunctionProps } from '../../../../../grid/utils/editableGrid/ChildEditableGridWithCopyFunctionProps';
import { CasMaster, CasToxicity } from '../../../../../../types/formulation';
import { CasMasterFormDefs } from '../../../../../../constants/i18n/translations/termDefinitions/formulation';
import TranslatableText from '../../../../../../components/i18n/TranslatableText';

const CasToxicityGrid = (
    props: ChildEditableGridWithCopyFunctionProps<CasMaster, CasToxicity>
) => {
    const {
        isLoadingToxicTests,
        isLoadingToxicSpecies,
        toxicityEntityColumnDefs,
        handleGridEdits,
        handleRowValidations,
        colDefOptions,
        termSet,
    } = useCasToxicityGrid(props);

    const { isParentLoading } = props.parentData;

    const { showAddButton, showDeleteButton, showSortButton, isGridEditable } =
        props.displayGridButtons;

    const { rowsData } = props.copyMethods;

    return isParentLoading ||
        isLoadingToxicTests ||
        isLoadingToxicSpecies ||
        isNilOrEmpty(toxicityEntityColumnDefs) ? (
        <DatacorLogoSpinner />
    ) : (
        <FormPartial
            title={
                (
                    <TranslatableText
                        termSet={termSet}
                        termKey={CasMasterFormDefs.Toxicity_Text}
                    />
                ) as unknown as string
            }>
            <BaseGrid
                autoSize={true}
                displayGrid={!isParentLoading}
                displayCreateNewButton={showAddButton}
                displayDeleteButton={showDeleteButton}
                useRowDrag={showSortButton}
                displaySortButton={showSortButton}
                sortKey={'sortOrder'}
                rowData={rowsData || []}
                dataKey={'rowId'}
                defaultColDef={colDefOptions}
                gridClass="full-size-item"
                displayToolbar={true}
                sizeColumnsToFit={true}
                onHandleGridEdits={handleGridEdits}
                isEditable={isGridEditable}
                onHandleRowValidations={handleRowValidations}
                columnDefs={toxicityEntityColumnDefs}
                tooltipShowDelay={0}
                //TODO: determine best way to translate
                newRowPlaceholderValues={{
                    routeId: 'Enter a Route Type...',
                    toxicTestCode: 'Enter a Test Type...',
                    toxicSpeciesCode: 'Enter a Toxic Species...',
                    effectiveDose: 'Enter an Effective Dose...',
                    exposureTime: 'Enter an Exposure Time...',
                    notes: 'Enter Notes...',
                }}></BaseGrid>
        </FormPartial>
    );
};
export default CasToxicityGrid;
