import React, { useContext, useState } from 'react';
import { GridReadyEvent } from 'ag-grid-community';
import { useGetLanguagesQuery } from '../../../services/organizations/organizations.service';
import { updateGridModels } from '../../../store/grids';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store';
import SettingsContext from '../../../contexts/settings.context';
import { useGetTermSetQuery } from '../../../services/i18n/i18n.service';
import { PLATFORM_DEFS } from '../../../constants/i18n/translations/termSetDefinitions/platform';
import { skipToken } from '@reduxjs/toolkit/query';

const useLanguagesGrid = () => {
    const dispatch = useDispatch();
    const { administration } = useSelector((state: RootState) => state.grids);
    const {
        data: languages,
        isLoading: isLoadingLanguages,
        isFetching,
    } = useGetLanguagesQuery();
    const [gridApi, setGridApi] = useState(null);
    const [gridColumnApi, setGridColumnApi] = useState(null);
    const [selectedRow, setSelectedRole] = useState(null);

    const { settings } = useContext(SettingsContext);
    const { data: termSet } = useGetTermSetQuery(
        settings?.userSettings
            ? {
                  languageId: settings?.userSettings?.languageId,
                  code: PLATFORM_DEFS.LANGUAGES_GRID,
              }
            : skipToken
    );

    const onGridReady = (params: GridReadyEvent) => {
        setGridApi(params.api);
        setGridColumnApi(params.columnApi);
        params.api.sizeColumnsToFit();
    };

    const onSelectionChanged = () => {
        const selectedRows = gridApi.getSelectedRows();
        setSelectedRole(selectedRows[0]);
    };

    const onFirstDataRendered = () => {
        gridColumnApi?.applyColumnState({
            state: administration.languagesList.column,
            applyOrder: true,
        });

        gridApi?.setFilterModel(administration.languagesList.filter);
    };

    const onSortChanged = () => {
        dispatch(
            updateGridModels({
                gridLocation: 'administration',
                gridName: 'languagesList',
                type: 'column',
                model: gridColumnApi.getColumnState(),
            })
        );
    };

    const onFilterChanged = () => {
        dispatch(
            updateGridModels({
                gridLocation: 'administration',
                gridName: 'languagesList',
                type: 'filter',
                model: gridApi.getFilterModel(),
            })
        );
    };

    return {
        isLoadingLanguages,
        isFetching,
        languages,
        onGridReady,
        onSelectionChanged,
        selectedRow,
        onSortChanged,
        onFilterChanged,
        onFirstDataRendered,
        termSet,
    };
};

export default useLanguagesGrid;
