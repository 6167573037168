import React from 'react';
import useHazardClassificationRuleGrid from './hooks/useHazardClassificationRuleGrid';
import BaseFormGrid from '../../../components/grids/BaseFormGrid';
import TranslatableText from '../../../components/i18n/TranslatableText';
import { HazardClassRulesGridDefs } from '../../../constants/i18n/translations/termDefinitions/formulation';

const HazardClassificationRuleGrid = () => {
    const {
        isLoadingHazardClassRules,
        columnDefs,
        redirectToForm,
        defaultColumnDefinitionOptions,
        hazardClassRulesList,
        canCreateHazardClassRules,
        canViewHazardClassRules,
        termSet,
    } = useHazardClassificationRuleGrid();

    return (
        <BaseFormGrid
            title={
                (
                    <TranslatableText
                        termSet={termSet}
                        termKey={HazardClassRulesGridDefs.Hazard_Class_Rules}
                    />
                ) as unknown as string
            }
            displayGrid={canViewHazardClassRules}
            isLoading={isLoadingHazardClassRules}
            rowData={hazardClassRulesList}
            columnDefs={columnDefs}
            gridClass="full-size-item"
            displayToolbar={true}
            displayCreateNewButton={canCreateHazardClassRules}
            displayExportDataButton={false}
            displayResetStateButton={false}
            onCreateNew={redirectToForm}
            onRowClicked={({ data }) => redirectToForm(data)}
            defaultColDef={defaultColumnDefinitionOptions}
            sizeColumnsToFit={false}></BaseFormGrid>
    );
};

export default HazardClassificationRuleGrid;
