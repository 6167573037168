import React from 'react';
import { ColDef } from 'ag-grid-community';
import useToxicTestGrid from './hooks/useToxicTestGrid';
import BaseFormGrid from '../../../components/grids/BaseFormGrid';
import CustomHeader from '../../../components/grids/CustomHeader';
import { ToxicTestGridDefs } from '../../../constants/i18n/translations/termDefinitions/formulation';
import TranslatableText from '../../../components/i18n/TranslatableText';

const ToxicTestGrid = () => {
    const {
        toxicTestList,
        redirectToForm,
        isLoadingToxicTest,
        canCreateToxicTest,
        canViewToxicTest,
        termSet,
    } = useToxicTestGrid();

    const toxicTestColumnDefs: ColDef[] = [
        {
            field: 'code',
            headerComponentFramework: (props: any) => {
                return (
                    <CustomHeader
                        {...props}
                        termSet={termSet}
                        termKey={ToxicTestGridDefs.Code}
                    />
                );
            },
            minWidth: 200,
            filter: 'agTextColumnFilter',
        },
        {
            field: 'description',
            headerComponentFramework: (props: any) => {
                return (
                    <CustomHeader
                        {...props}
                        termSet={termSet}
                        termKey={ToxicTestGridDefs.Description}
                    />
                );
            },
            minWidth: 500,
            filter: 'agTextColumnFilter',
        },
    ];

    return (
        <BaseFormGrid
            title={
                <>
                    <TranslatableText
                        termKey={ToxicTestGridDefs.Title}
                        termSet={termSet}
                    />
                </>
            }
            displayGrid={canViewToxicTest}
            isLoading={isLoadingToxicTest}
            rowData={toxicTestList}
            columnDefs={toxicTestColumnDefs}
            gridClass="full-size-item"
            displayToolbar={true}
            displayCreateNewButton={canCreateToxicTest}
            displayExportDataButton={false}
            displayResetStateButton={false}
            onCreateNew={redirectToForm}
            onRowClicked={({ data }) => redirectToForm(data)}
            sizeColumnsToFit={true}></BaseFormGrid>
    );
};

export default ToxicTestGrid;
