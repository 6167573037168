import { useContext, useEffect, useState } from 'react';
import {
    HandleFieldChangedEvent,
    useForm,
} from '../../../../../../../../../libs/hooksLib';
import { useGetPictogramSignalWordForDropdownQuery } from '../../../../../../../../../services/formulation/casMaster/casPictogramSignalWord/casPictogramSignalWord.service';
import {
    CasMaster,
    CasPictogram,
} from '../../../../../../../../../types/formulation';
import { isNilOrEmpty } from '../../../../../../../../../utils/objectUtils';
import { ChildFormProps } from '../../../../../../../../form/helpers/ChildFormProps';
import SettingsContext from '../../../../../../../../../contexts/settings.context';
import { useGetTermSetQuery } from '../../../../../../../../../services/i18n/i18n.service';
import { FORMULATION_DEFS } from '../../../../../../../../../constants/i18n/translations/termSetDefinitions/formulation';
import { skipToken } from '@reduxjs/toolkit/query';

const useCasPictogramForm = (props: ChildFormProps<CasMaster>) => {
    const { currentParentRecord, isParentLoading, dataFromQuery } =
        props.parentData;

    const { handleChildRecord, registerChildForm } = props.helpers;

    const { data: signalWordOptions, isLoading: isLoadingSignalWord } =
        useGetPictogramSignalWordForDropdownQuery();

    const defaultSignalWordId: number = 1;

    const blankCasPictogram: CasPictogram = {
        casPictogramSignalWordId: 1,
        explosive: false,
        flammable: false,
        oxidizing: false,
        compressedGas: false,
        toxic: false,
        corrosive: false,
        irritant: false,
        healthHazard: false,
        environmentDamaging: false,
    };

    const initialState: any = {
        signalWordInputValue: '',
        signalWordOptions: [],
        selectedSignalWord: null,
    };

    const { settings } = useContext(SettingsContext);
    const { data: termSet } = useGetTermSetQuery(
        settings?.userSettings
            ? {
                  languageId: settings?.userSettings?.languageId,
                  code: FORMULATION_DEFS.CAS_MASTER_FORM,
              }
            : skipToken
    );

    const [dropdownOptions, setDropdownOptions] = useState(initialState);

    const { fields, formMethods, handleFieldChange, setValues, verifyChanges } =
        useForm(blankCasPictogram, true);

    const { childForm } = verifyChanges;

    const { setOriginalEntity, clearChangesHistory, hasCurrentRecordChanged } =
        childForm;

    const handlePropertyChanged = (event: HandleFieldChangedEvent) => {
        handleFieldChange(event);
        handleChildRecord('casPictogram', event);
    };

    useEffect(() => {
        if (!isParentLoading) {
            registerChildForm('casPictogram', {
                formMethods,
                hasEntityChanges: hasCurrentRecordChanged,
                clearChangesHistory,
            });
        }
    }, [fields, isParentLoading]);

    useEffect(() => {
        if (!isParentLoading) {
            setOriginalEntity(dataFromQuery?.casPictogram || blankCasPictogram);
            setValues(currentParentRecord?.casPictogram || blankCasPictogram);
        }
    }, [currentParentRecord?.casPictogram, isParentLoading, dataFromQuery]);

    useEffect(() => {
        if (!isLoadingSignalWord && !isNilOrEmpty(signalWordOptions)) {
            setDropdownOptions((previousValues: any) => ({
                ...previousValues,
                selectedSignalWord: signalWordOptions?.find(
                    (current: any) =>
                        current.value === fields.casPictogramSignalWordId
                ),
            }));
        }
    }, [fields, signalWordOptions, isLoadingSignalWord]);

    return {
        fields,
        formMethods,
        defaultSignalWordId,
        handlePropertyChanged,
        signalWordOptions,
        isLoadingSignalWord,
        dropdownOptions,
        setDropdownOptions,
        termSet,
    };
};
export default useCasPictogramForm;
