import React, { useEffect, useMemo, useState } from 'react';
import { ColDef } from 'ag-grid-community';
import { useGetAllCostCodesQuery } from '../../../../services/fixedAssets/fixedAssets.service';
import {
    FA_Asset,
    FA_AssetCost,
    FA_AssetCostCode,
} from '../../../../types/FixedAsset.types';
import { replace } from 'lodash';
import DefaultColumnTypes from '../../../../components/grids/columns/Column.constants';
import { PermissionsUtil } from '../../../../utils/permissions/permissionsUtil';
import { PERMISSIONS } from '../../../../constants/permissions/Permissions.constants';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import CustomHeader from '../../../../components/grids/CustomHeader';
import { AcquisitionsGridDefs } from '../../../../constants/i18n/translations/termDefinitions/accounting';
import useTranslation from '../../../../components/i18n/hooks/useTranslation';
import { ACCOUNTING_DEFS } from '../../../../constants/i18n/translations/termSetDefinitions/accounting';

const useAcquisitionsGrid = () => {
    const user = useSelector((state: RootState) => state.user);

    const [columnDefs, setColumnDefs] = useState<ColDef[]>([]);
    const [showOptionsModal, setShowOptionsModal] = useState<boolean>(true);
    const [assetList, setAssetList] = useState<FA_Asset[]>([]);
    const [acquisitionRows, setAcquisitionRows] = useState<any[]>([]);

    const { data: costCodeList, isLoading: loadingCostCodeList } =
        useGetAllCostCodesQuery();

    const canViewAcquisitionReport = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.FIXED_ASSETS.REPORTS.ACQUISITIONS.VIEW
    );

    const { termSet } = useTranslation(ACCOUNTING_DEFS.ACQUISITIONS_GRID);

    const reportColumnDefs: ColDef[] = [
        {
            field: 'number',
            headerComponentFramework: (props: any) => {
                return (
                    <CustomHeader
                        {...props}
                        termSet={termSet}
                        termKey={AcquisitionsGridDefs.Asset_No}
                    />
                );
            },
            cellRenderer: 'agGroupCellRenderer',
            ...DefaultColumnTypes.ShortText,
        },
        {
            field: 'code',
            headerComponentFramework: (props: any) => {
                return (
                    <CustomHeader
                        {...props}
                        termSet={termSet}
                        termKey={AcquisitionsGridDefs.Asset_Code}
                    />
                );
            },
            ...DefaultColumnTypes.MediumText,
        },
        {
            field: 'name',
            headerComponentFramework: (props: any) => {
                return (
                    <CustomHeader
                        {...props}
                        termSet={termSet}
                        termKey={AcquisitionsGridDefs.Asset_Name}
                    />
                );
            },
            ...DefaultColumnTypes.MediumText,
        },
        {
            field: 'acquireDate',
            headerComponentFramework: (props: any) => {
                return (
                    <CustomHeader
                        {...props}
                        termSet={termSet}
                        termKey={AcquisitionsGridDefs.Acquire_Date}
                    />
                );
            },
            ...DefaultColumnTypes.DateOnly,
        },
        {
            field: 'inServiceDate',
            headerComponentFramework: (props: any) => {
                return (
                    <CustomHeader
                        {...props}
                        termSet={termSet}
                        termKey={AcquisitionsGridDefs.In_Service_Date}
                    />
                );
            },
            ...DefaultColumnTypes.DateOnly,
        },
    ];

    const dynamicallyColumns = (list: FA_AssetCostCode[]) => {
        let columns: any[] = reportColumnDefs;
        list.forEach((costCode) => {
            const fieldText = `${costCode.code}`;
            const field = replace(fieldText, ' ', '');
            columns.push({
                field: field,
                headerName: fieldText,
                ...DefaultColumnTypes.Currency,
            });
        });

        columns.push({
            field: 'totalCost',
            headerComponentFramework: (props: any) => {
                return (
                    <CustomHeader
                        {...props}
                        termSet={termSet}
                        termKey={AcquisitionsGridDefs.Total}
                    />
                );
            },
            ...DefaultColumnTypes.Currency,
        });

        setColumnDefs(columns);
    };

    const buildField = (field: string, value: any) => {
        return `"${field}":${value}`;
    };

    const columnCosts = (asset: FA_Asset) => {
        return costCodeList.map((costCode: FA_AssetCostCode) => {
            const field = replace(costCode.code, ' ', '');
            const cost = asset.costs?.find(
                (cost: FA_AssetCost) => cost.costCodeId === costCode.id
            );
            return buildField(field, cost ? cost.amount : 0);
        });
    };

    const getRows = () => {
        return assetList.map((asset: FA_Asset) => {
            const costs = columnCosts(asset);
            const row = `{ ${costs.join(',')} }`;
            return { ...asset, ...JSON.parse(row) };
        });
    };

    const acquisitionsSummaryRow = useMemo(() => {
        let row: any = { acquireDate: null, inServiceDate: null };
        if (acquisitionRows.length > 0) {
            let total = acquisitionRows.reduce(
                (prev, curr) => prev + curr.totalCost,
                0
            );
            const totalCosts = costCodeList.map(
                (costCode: FA_AssetCostCode) => {
                    const field: string = replace(costCode.code, ' ', '');
                    const sum: Number = acquisitionRows.reduce(
                        (prev, curr) => prev + curr[field],
                        0
                    );
                    return buildField(field, sum);
                }
            );

            const cost = `{ ${totalCosts.join(',')} }`;
            return [{ ...row, ...JSON.parse(cost), totalCost: total }];
        }
        return [row];
    }, [acquisitionRows]);

    useEffect(() => {
        if (assetList) {
            setAcquisitionRows(getRows());
        }
    }, [assetList]);

    useEffect(() => {
        if (!loadingCostCodeList) {
            dynamicallyColumns(costCodeList);
        }
    }, [costCodeList]);

    return {
        acquisitionRows,
        setAssetList,
        columnDefs,
        setShowOptionsModal,
        showOptionsModal,
        loadingCostCodeList,
        acquisitionsSummaryRow,
        canViewAcquisitionReport,
        termSet,
    };
};

export default useAcquisitionsGrid;
