import React from 'react';
import GhsHazardPhraseContainer from './GhsHazardPhraseContainer';

const GhsHazardPhrases = () => {
    return (
        <>
            <GhsHazardPhraseContainer showForm={false} />
        </>
    );
};

export default GhsHazardPhrases;
