import { useNavigate } from 'react-router-dom';
import {
    useGetAllStatusQuery,
    useUpdateListStatusesMutation,
} from '../../../services/fixedAssets/fixedAssets.service';
import { PermissionsUtil } from '../../../utils/permissions/permissionsUtil';
import { PERMISSIONS } from '../../../constants/permissions/Permissions.constants';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { RoutingValues } from '../../../types/Shared.types';
import { FA_AssetStatus } from '../../../types/FixedAsset.types';
import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { getMaxSortOrderValue } from '../../../store/fixedAssets';
import { isNilOrEmpty } from '../../../utils/objectUtils';
import useTranslation from '../../../components/i18n/hooks/useTranslation';
import { ACCOUNTING_DEFS } from '../../../constants/i18n/translations/termSetDefinitions/accounting';
import { ColDef } from 'ag-grid-community';
import CustomHeader from '../../../components/grids/CustomHeader';
import { AssetStatusesGridDefs } from '../../../constants/i18n/translations/termDefinitions/accounting';
import DefaultColumnTypes from '../../../components/grids/columns/Column.constants';

export const UseFixedAssetsStatusGrid = () => {
    const dispatch = useDispatch();
    const user = useSelector((state: RootState) => state.user);
    const navigate = useNavigate();
    const { data: statusList, isLoading } = useGetAllStatusQuery();
    const [updateAssetStatuses] = useUpdateListStatusesMutation();
    const [rowData, setRowData] = useState([]);
    const [colDefs, setColDefs] = useState([]);

    const { termSet } = useTranslation(ACCOUNTING_DEFS.ASSET_STATUSES_GRID);

    const canCreateAssetStatus = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.FIXED_ASSETS.ASSET_STATUSES.CREATE
    );
    const canViewAssetStatus = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.FIXED_ASSETS.ASSET_STATUSES.VIEW
    );
    const canUpdate = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.FIXED_ASSETS.ASSET_STATUSES.EDIT
    );

    useEffect(() => {
        if (!isNilOrEmpty(statusList)) {
            setRowData(statusList);
        }
    }, [statusList]);

    const handleGridEdits = (editedRows: FA_AssetStatus[]) => {
        setRowData(editedRows);
        updateAssetStatuses(editedRows);
    };

    const redirectToForm = (row: any) => {
        if (row.id) {
            navigate(`/accounting/fixedAssets/status/${row.id}`);
        } else {
            navigate(`/accounting/fixedAssets/status/${RoutingValues.newId}`);
        }
    };

    useEffect(() => {
        let maxValue = 1;
        if (statusList && statusList.length > 0) {
            const data = _.maxBy(
                statusList,
                (assetStatus: FA_AssetStatus) => assetStatus.sortOrder
            );
            maxValue = data.sortOrder + 1;
        }
        dispatch(
            getMaxSortOrderValue({ isLoading: false, maxValue: maxValue })
        );
    }, [statusList]);

    useEffect(() => {
        if (!isNilOrEmpty(rowData) && isNilOrEmpty(colDefs)) {
            const statusColumnDefs: ColDef[] = [
                {
                    field: 'code',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={AssetStatusesGridDefs.Code}
                            />
                        );
                    },
                    ...DefaultColumnTypes.ShortText,
                },
                {
                    field: 'description',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={AssetStatusesGridDefs.Description}
                            />
                        );
                    },
                    ...DefaultColumnTypes.LongText,
                },
                {
                    field: 'isInService',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={AssetStatusesGridDefs.In_Service}
                            />
                        );
                    },
                    ...DefaultColumnTypes.Boolean,
                },
                {
                    field: 'isDisposal',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={AssetStatusesGridDefs.Disposal}
                            />
                        );
                    },
                    ...DefaultColumnTypes.Boolean,
                },
            ];
            setColDefs(statusColumnDefs);
        }
    }, [rowData]);

    return {
        statusList,
        isLoading,
        canCreateAssetStatus,
        canViewAssetStatus,
        canUpdate,
        redirectToForm,
        handleGridEdits,
        rowData,
        colDefs,
        termSet,
    };
};
