import React from 'react';
import SafetyDataSheetSectionHeaderContainer from './SafetyDataSheetSectionHeaderContainer';

const SafetyDataSheetSectionHeader = () => {
    return (
        <>
            <SafetyDataSheetSectionHeaderContainer showForm={false} />
        </>
    );
};

export default SafetyDataSheetSectionHeader;
