import {
    Box,
    Dialog,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import React from 'react';
import DateInput from '../../../components/form/formInputs/DateInput/DateInput';
import { LoadingButton } from '@mui/lab';
import MultiSelectInput from '../../../components/form/formInputs/MultiSelectInput/MutliSelectInput';
import { FA_Asset } from '../../../types/FixedAsset.types';
import useAssetContractOptionsModal from './hooks/useAssetContractOptionsModal';
import { ContractCoverageGridDefs } from '../../../constants/i18n/translations/termDefinitions/accounting';
import TranslatableText from '../../../components/i18n/TranslatableText';

export interface AssetContractsOptionsProps {
    open: boolean;
    onContractsLoaded: (contracts: FA_Asset[]) => void;
    onClose: () => void;
}

const AssetContractsOptionsModal = (props: AssetContractsOptionsProps) => {
    const {
        fields,
        handleFieldChange,
        contractTypeOptions,
        loadingContractTypes,
        handleGetContracts,
        isLoadingContracts,
        disableButton,
        termSet,
    } = useAssetContractOptionsModal(props);

    return (
        <Dialog
            onClose={props.onClose}
            open={props.open}
            fullWidth
            maxWidth={'sm'}>
            <DialogTitle>
                <Box display="flex" alignItems="center">
                    <Box flexGrow={1}>
                        <TranslatableText
                            termSet={termSet}
                            termKey={ContractCoverageGridDefs.Options_Title}
                        />
                    </Box>
                    <Box>
                        <IconButton onClick={props.onClose}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </Box>
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <MultiSelectInput
                            label={
                                (
                                    <TranslatableText
                                        termSet={termSet}
                                        termKey={
                                            ContractCoverageGridDefs.Options_Contract_Types
                                        }
                                    />
                                ) as unknown as string
                            }
                            id="contractTypes"
                            value={fields.contractTypes}
                            onChange={handleFieldChange}
                            disabled={loadingContractTypes}
                            options={contractTypeOptions}
                            disableCloseOnSelect={true}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <DateInput
                            label={
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={
                                        ContractCoverageGridDefs.Options_Contract_Date_Begin
                                    }
                                />
                            }
                            id="startDate"
                            value={fields.startDate}
                            onChange={handleFieldChange}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <DateInput
                            label={
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={
                                        ContractCoverageGridDefs.Options_Contract_Date_End
                                    }
                                />
                            }
                            id="endDate"
                            value={fields.endDate}
                            onChange={handleFieldChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <DateInput
                            label={
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={
                                        ContractCoverageGridDefs.Options_Remaining
                                    }
                                />
                            }
                            id="remainingValueDate"
                            value={fields.remainingValueDate}
                            onChange={handleFieldChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <LoadingButton
                            fullWidth
                            variant="contained"
                            type="submit"
                            disabled={disableButton()}
                            loading={isLoadingContracts}
                            onClick={handleGetContracts}>
                            <TranslatableText
                                termSet={termSet}
                                termKey={
                                    ContractCoverageGridDefs.Options_Get_Contracts
                                }
                            />
                        </LoadingButton>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
};

export default AssetContractsOptionsModal;
