import React from 'react';
import useProperShippingName from '../hooks/useProperShippingNameGrid';
import { ColDef } from 'ag-grid-community';
import BaseFormGrid from '../../../components/grids/BaseFormGrid';

const ProperShippingNameGrid = () => {
    const {
        properShippingNameList,
        redirectToForm,
        isLoadingProperShippingName,
        canCreateProperShippingName,
        canViewProperShippingName,
    } = useProperShippingName();

    const properShippingNameColumnDefs: ColDef[] = [
        {
            field: 'code',
            headerName: 'Code',
            minWidth: 120,
            filter: 'agTextColumnFilter',
        },
        {
            field: 'properShippingName',
            headerName: 'Proper Shipping Name',
            minWidth: 500,
            filter: 'agTextColumnFilter',
        },
        {
            field: 'symbol',
            headerName: 'Symbol',
            minWidth: 120,
            filter: 'agTextColumnFilter',
        },
        {
            field: 'hazardClass',
            headerName: 'Hazard Class',
            minWidth: 180,
            filter: 'agTextColumnFilter',
        },
        {
            field: 'unNumber',
            headerName: 'UN/NA Number',
            minWidth: 180,
            filter: 'agTextColumnFilter',
        },
        {
            field: 'packagingGroup',
            headerName: 'Packaging Group',
            minWidth: 180,
            filter: 'agTextColumnFilter',
        },
        {
            field: 'labelCode',
            headerName: 'Label Code',
            minWidth: 180,
            filter: 'agTextColumnFilter',
        },
        {
            field: 'freightClass',
            headerName: 'Freight Class',
            minWidth: 180,
            filter: 'agTextColumnFilter',
        },
        {
            field: 'nmfcNumber',
            headerName: 'NMFC Number',
            minWidth: 150,
            filter: 'agTextColumnFilter',
        },
        {
            field: 'specialProvisions',
            headerName: 'Special Provisions',
            minWidth: 180,
            filter: 'agTextColumnFilter',
        },
        {
            field: 'technicalName',
            headerName: 'Technical Name',
            minWidth: 200,
            filter: 'agTextColumnFilter',
        },
        {
            field: 'packagingExceptions',
            headerName: 'Packaging Exceptions',
            minWidth: 180,
            filter: 'agTextColumnFilter',
        },
        {
            field: 'packagingNonBulk',
            headerName: 'Packaging Non-Bulk',
            minWidth: 180,
            filter: 'agTextColumnFilter',
        },
        {
            field: 'packagingBulk',
            headerName: 'Packaging Bulk',
            minWidth: 180,
            filter: 'agTextColumnFilter',
        },
        {
            field: 'quantityPassenger',
            headerName: 'Quantity Passenger',
            minWidth: 180,
            filter: 'agTextColumnFilter',
        },
        {
            field: 'quantityCargo',
            headerName: 'Quantity Cargo',
            minWidth: 180,
            filter: 'agTextColumnFilter',
        },
        {
            field: 'vesselStowageLoc',
            headerName: 'Vessel Stowage Loc',
            minWidth: 180,
            filter: 'agTextColumnFilter',
        },
        {
            field: 'vesselStowageOther',
            headerName: 'Vessel Stowage Other',
            minWidth: 200,
            filter: 'agTextColumnFilter',
        },
    ];

    return (
        <BaseFormGrid
            displayGrid={canViewProperShippingName}
            isLoading={isLoadingProperShippingName}
            rowData={properShippingNameList}
            columnDefs={properShippingNameColumnDefs}
            gridClass="full-size-item"
            displayToolbar={true}
            displayCreateNewButton={canCreateProperShippingName}
            displayExportDataButton={false}
            displayResetStateButton={false}
            onCreateNew={redirectToForm}
            onRowClicked={({ data }) => redirectToForm(data)}
            sizeColumnsToFit={true}></BaseFormGrid>
    );
};

export default ProperShippingNameGrid;
