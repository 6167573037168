import {
    Supplier,
    SupplierAddress,
    SupplierAddressStatusForm,
    SupplierAddressStatusGrid,
    SupplierAddressTypeForm,
    SupplierAddressTypeGrid,
    SupplierStatusForm,
    SupplierStatusGrid,
    SupplierTypeForm,
    SupplierTypeGrid,
} from './termDefs.types';

export const SupplierTypeGridDefs: SupplierTypeGrid = {
    Id: 'supplier-type-grid-id',
    BusinessEntityId: 'supplier-type-grid-businessEntityId',
    TypeActive: 'supplier-type-grid-typeActive',
    Default: 'supplier-type-grid-default',
    EmergencyResponse: 'supplier-type-grid-emergencyResponse',
    SortOrder: 'supplier-type-grid-sortOrder',
    Code: 'supplier-type-grid-code',
    Description: 'supplier-type-grid-description',
    CreateAt: 'supplier-type-grid-createAt',
    CreatedBy: 'supplier-type-grid-createdBy',
    UpdatedAt: 'supplier-type-grid-updatedAt',
    UpdatedBy: 'supplier-type-grid-updatedBy',
};

export const SupplierTypeFormDefs: SupplierTypeForm = {
    Title: 'supplier-type-form-title',
    Id: 'supplier-type-form-id',
    BusinessEntityId: 'supplier-type-form-businessEntityId',
    TypeActive: 'supplier-type-form-typeActive',
    Default: 'supplier-type-form-default',
    EmergencyResponse: 'supplier-type-form-emergencyResponse',
    SortOrder: 'supplier-type-form-sortOrder',
    Code: 'supplier-type-form-code',
    Description: 'supplier-type-form-description',
    CreateAt: 'supplier-type-form-createAt',
    CreatedBy: 'supplier-type-form-createdBy',
    UpdatedAt: 'supplier-type-form-updatedAt',
    UpdatedBy: 'supplier-type-form-updatedBy',
};

export const SupplierStatusGridDefs: SupplierStatusGrid = {
    Id: 'supplier-status-grid-id',
    BusinessEntityId: 'supplier-status-grid-business-entity-id',
    SortOrder: 'supplier-status-grid-sort-order',
    Code: 'supplier-status-grid-code',
    Description: 'supplier-status-grid-description',
    CreatedAt: 'supplier-status-grid-created-at',
    CreatedBy: 'supplier-status-grid-created-by',
    UpdatedAt: 'supplier-status-grid-updated-at',
    UpdatedBy: 'supplier-status-grid-updated-by',
};

export const SupplierStatusFormDefs: SupplierStatusForm = {
    Title: 'supplier-status-form-title',
    SortOrder: 'supplier-status-form-sort-order',
    Code: 'supplier-status-form-code',
    Description: 'supplier-status-form-description',
    StatusActive: 'supplier-status-form-status-active',
    SupplierActive: 'supplier-status-form-supplier-active',
    Default: 'supplier-status-form-default',
};

export const SupplierAddressStatusGridDefs: SupplierAddressStatusGrid = {
    Title: 'supplier-address-status-grid-title',
    Id: 'supplier-address-status-grid-id',
    BusinessEntityId: 'supplier-address-status-grid-business-entity-id',
    SortOrder: 'supplier-address-status-grid-sort-order',
    Code: 'supplier-address-status-grid-code',
    Description: 'supplier-address-status-grid-description',
    CreatedAt: 'supplier-address-status-grid-created-at',
    CreatedBy: 'supplier-address-status-grid-created-by',
    UpdatedAt: 'supplier-address-status-grid-updated-at',
    UpdatedBy: 'supplier-address-status-grid-updated-by',
};

export const SupplierAddressStatusFormDefs: SupplierAddressStatusForm = {
    Title: 'supplier-address-status-form-title',
    SortOrder: 'supplier-address-status-form-sort-order',
    Code: 'supplier-address-status-form-code',
    Description: 'supplier-address-status-form-description',
    StatusActive: 'supplier-address-status-form-status-active',
    AddressActive: 'supplier-address-status-form-address-active',
    Default: 'supplier-address-status-form-default',
};

export const SupplierAddressTypeGridDefs: SupplierAddressTypeGrid = {
    Id: 'supplier-address-type-grid-id',
    BusinessEntityId: 'supplier-address-type-grid-businessEntityId',
    TypeActive: 'supplier-address-type-grid-typeActive',
    Default: 'supplier-address-type-grid-default',
    Main: 'supplier-address-type-grid-main',
    RemitPayment: 'supplier-address-type-grid-remitPayment',
    SortOrder: 'supplier-address-type-grid-sortOrder',
    Code: 'supplier-address-type-grid-code',
    Description: 'supplier-address-type-grid-description',
    CreateAt: 'supplier-address-type-grid-createAt',
    CreatedBy: 'supplier-address-type-grid-createdBy',
    UpdatedAt: 'supplier-address-type-grid-updatedAt',
    UpdatedBy: 'supplier-address-type-grid-updatedBy',
};

export const SupplierAddressTypeFormDefs: SupplierAddressTypeForm = {
    Title: 'supplier-address-type-form-title',
    Id: 'supplier-address-type-form-id',
    BusinessEntityId: 'supplier-address-type-form-businessEntityId',
    TypeActive: 'supplier-address-type-form-typeActive',
    Default: 'supplier-address-type-form-default',
    Main: 'supplier-address-type-form-main',
    RemitPayment: 'supplier-address-type-form-remitPayment',
    SortOrder: 'supplier-address-type-form-sortOrder',
    Code: 'supplier-address-type-form-code',
    Description: 'supplier-address-type-form-description',
    CreateAt: 'supplier-address-type-form-createAt',
    CreatedBy: 'supplier-address-type-form-createdBy',
    UpdatedAt: 'supplier-address-type-form-updatedAt',
    UpdatedBy: 'supplier-address-type-form-updatedBy',
};

export const SupplierGridDefs: Supplier = {
    Title: 'not-applicable',
    Status: 'supplier-grid-status',
    Type: 'supplier-grid-type',
    Code: 'supplier-grid-code',
    Name: 'supplier-grid-name',
    TaxNumber: 'supplier-grid-taxNumber',
    Website: 'supplier-grid-website',
    CurrencyCode: 'supplier-grid-currencyCode',
    ExpenseAccount: 'supplier-grid-expenseAccount',
    SupplierAddressSection: 'supplier-grid-address-section',
};

export const SupplierFormDefs: Supplier = {
    Title: 'supplier-form-title',
    Status: 'supplier-form-status',
    Type: 'supplier-form-type',
    Code: 'supplier-form-code',
    Name: 'supplier-form-name',
    TaxNumber: 'supplier-form-taxNumber',
    Website: 'supplier-form-website',
    CurrencyCode: 'supplier-form-currencyCode',
    ExpenseAccount: 'supplier-form-expenseAccount',
    SupplierAddressSection: 'supplier-form-address-section',
};

export const SupplierAddressGridDefs: SupplierAddress = {
    Title: 'supplier-address-grid-title',
    Code: 'supplier-address-grid-code',
    Name: 'supplier-address-grid-name',
    Status: 'supplier-address-grid-status',
    Type: 'supplier-address-grid-type',
    Country: 'supplier-address-grid-country',
    State: 'supplier-address-grid-state',
    Street: 'supplier-address-grid-street',
    City: 'supplier-address-grid-city',
    County: 'supplier-address-grid-county',
    PostalCode: 'supplier-address-grid-postal-code',
    Phone: 'supplier-address-grid-phone',
    Email: 'supplier-address-grid-email',
};

export const SupplierAddressFormDefs: SupplierAddress = {
    Title: 'supplier-address-form-title',
    Code: 'supplier-address-form-code',
    Name: 'supplier-address-form-name',
    Status: 'supplier-address-form-status',
    Type: 'supplier-address-form-type',
    Country: 'supplier-address-form-country',
    State: 'supplier-address-form-state',
    Street: 'supplier-address-form-street',
    City: 'supplier-address-form-city',
    County: 'supplier-address-form-county',
    PostalCode: 'supplier-address-form-postal-code',
    Phone: 'supplier-address-form-phone',
    Email: 'supplier-address-form-email',
};
