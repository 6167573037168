import { useParams } from 'react-router-dom';
import BaseForm, {
    ParamTypes,
} from '../../../components/form/baseForm/BaseForm';
import { NumberMaxValues, RoutingValues } from '../../../types/Shared.types';
import FormPartial from '../../../components/form/formContainer/FormPartial';
import { Grid } from '@mui/material';
import SelectInput from '../../../components/form/formInputs/SelectInput/SelectInput';
import TranslatableText from '../../../components/i18n/TranslatableText';
import { CurrencyExchangeRateFormDefs } from '../../../constants/i18n/translations/termDefinitions/accounting';
import React from 'react';
import useCurrencyExchangeRateForm from './hooks/useCurrencyExchangeRateForm';
import { CURRENCY_EXCHANGE_RATE } from '../../../constants/revisionHistory/revisionHistory.constants';
import DateInput from '../../../components/form/formInputs/DateInput/DateInput';
import TextInput from '../../../components/form/formInputs/TextInput/TextInput';
import CurrencyInput from '../../../components/form/formInputs/CurrencyInput/CurrencyInput';

const CurrencyExchangeRateForm = () => {
    const { id } = useParams<ParamTypes>();
    const {
        fields,
        handleFieldChange,
        formMethods,
        onCreate,
        onUpdate,
        onDelete,
        closeForm,
        isLoading,
        termSet,
        canCreateExchangeRate,
        canUpdateExchangeRate,
        currencyCodes,
        filteredRateTypes,
        auditableFields,
    } = useCurrencyExchangeRateForm(id);

    return (
        <BaseForm
            title={
                <TranslatableText
                    termSet={termSet}
                    termKey={CurrencyExchangeRateFormDefs.Title}
                />
            }
            formMethods={formMethods}
            isNew={id === RoutingValues.newId}
            isLoading={isLoading}
            onCreate={onCreate}
            onUpdate={onUpdate}
            onDelete={onDelete}
            onCancel={closeForm}
            showOnRevision={true}
            fieldMappings={CURRENCY_EXCHANGE_RATE}
            entityUuid={fields?.uuid}
            displayCreateButton={canCreateExchangeRate}
            displayUpdateButton={canUpdateExchangeRate}
            displayDeleteButton={false}
            auditableFields={auditableFields}>
            <FormPartial>
                <Grid container flexDirection="row" spacing={2}>
                    <Grid item xs={6}>
                        <TextInput
                            fullWidth
                            label={
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={
                                        CurrencyExchangeRateFormDefs.SourceCurrencyCode
                                    }
                                />
                            }
                            id="sourceCurrencyCode"
                            value={fields.sourceCurrencyCode}
                            onChange={handleFieldChange}
                            formMethods={formMethods}
                            disabled={true}
                            required={true}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <SelectInput
                            fullWidth
                            label={
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={
                                        CurrencyExchangeRateFormDefs.TargetCurrencyCode
                                    }
                                />
                            }
                            id="targetCurrencyCode"
                            value={fields.targetCurrencyCode}
                            onChange={handleFieldChange}
                            formMethods={formMethods}
                            options={currencyCodes}
                            disabled={false}
                            required={true}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <SelectInput
                            fullWidth
                            label={
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={
                                        CurrencyExchangeRateFormDefs.CurrencyRateTypeId
                                    }
                                />
                            }
                            id="currencyRateTypeId"
                            value={fields.currencyRateTypeId}
                            onChange={handleFieldChange}
                            formMethods={formMethods}
                            options={filteredRateTypes}
                            disabled={false}
                            required={true}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <CurrencyInput
                            fullWidth
                            label={
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={
                                        CurrencyExchangeRateFormDefs.ExchangeRate
                                    }
                                />
                            }
                            id="exchangeRate"
                            value={fields.exchangeRate}
                            onChange={handleFieldChange}
                            formMethods={formMethods}
                            required={true}
                            maxValue={NumberMaxValues.smallint}
                            numDecimals={9}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <DateInput
                            label={
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={
                                        CurrencyExchangeRateFormDefs.EffectiveAt
                                    }
                                />
                            }
                            id="effectiveAt"
                            value={fields.effectiveAt}
                            onChange={handleFieldChange}
                            formMethods={formMethods}
                            required={true}
                        />
                    </Grid>
                </Grid>
            </FormPartial>
        </BaseForm>
    );
};

export default CurrencyExchangeRateForm;
