import React from 'react';
import { GridOptions } from '../../../../components/grids/Grid.constants';
import { ColDef } from 'ag-grid-community';
import DefaultColumnTypes from '../../../../components/grids/columns/Column.constants';
import BaseFormGrid from '../../../../components/grids/BaseFormGrid';
import { GhsHazardPhrase } from '../../../../types/formulation';
import useGhsHazardPhraseGrid, {
    HazardGridProps,
} from './hooks/useGhsHazardPhraseGrid';
import TranslatableText from '../../../../components/i18n/TranslatableText';
import { GhsHazardPhrasesGridDefs } from '../../../../constants/i18n/translations/termDefinitions/formulation';
import CustomHeader from '../../../../components/grids/CustomHeader';

const GhsHazardPhraseGrid = ({
    defaultLanguageId,
    currentPhraseId,
    handleSetPhraseList,
    handlePromptFormDirty,
}: HazardGridProps) => {
    const {
        hazardPhraseList,
        isLoadingData,
        handleSelectedRowChange,
        handleAddNewPhrase,
        onGridReady,
        onFirstDataRendered,
        handleRowDataChanged,
        onSortChanged,
        onFilterChanged,
        canViewGhsHazardPhrase,
        canCreateGhsHazardPhrase,
        termSet,
    } = useGhsHazardPhraseGrid({
        handleSetPhraseList,
        defaultLanguageId,
        currentPhraseId,
        handlePromptFormDirty,
    });

    const methodsColumnDefs: ColDef[] = [
        {
            field: 'phraseId',
            headerComponentFramework: (props: any) => {
                return (
                    <CustomHeader
                        {...props}
                        termSet={termSet}
                        termKey={GhsHazardPhrasesGridDefs.Phrase_ID}
                    />
                );
            },
            filter: 'agTextColumnFilter',
            ...DefaultColumnTypes.MediumText,
        },
        {
            field: 'phraseText',
            headerComponentFramework: (props: any) => {
                return (
                    <CustomHeader
                        {...props}
                        termSet={termSet}
                        termKey={GhsHazardPhrasesGridDefs.Text}
                    />
                );
            },
            filter: 'agTextColumnFilter',
            ...DefaultColumnTypes.LongText,
        },
    ];

    const colDefOptions = {
        ...GridOptions.sortFilterAndWrapColumns,
        floatingFilter: true,
    };

    return (
        <BaseFormGrid
            displayGrid={canViewGhsHazardPhrase}
            title={
                (
                    <TranslatableText
                        termKey={GhsHazardPhrasesGridDefs.Phrase_Library}
                        termSet={termSet}
                    />
                ) as unknown as string
            }
            isLoading={isLoadingData}
            rowData={hazardPhraseList}
            columnDefs={methodsColumnDefs}
            dataKey={'phraseId'}
            gridStyle={{
                maxHeight: '93%',
            }}
            gridClass="full-size-item"
            displayToolbar={true}
            displayCreateNewButton={canCreateGhsHazardPhrase}
            displayExportDataButton={false}
            displayResetStateButton={false}
            onCreateNew={handleAddNewPhrase}
            onRowClicked={({ data }) =>
                handleSelectedRowChange(data as GhsHazardPhrase)
            }
            onFirstDataRendered={onFirstDataRendered}
            onRowDataChanged={handleRowDataChanged}
            defaultColDef={colDefOptions}
            sizeColumnsToFit={true}
            onGridReady={onGridReady}
            onFilterChanged={onFilterChanged}
            onSortChanged={onSortChanged}
            rowSelection={'single'}></BaseFormGrid>
    );
};

export default GhsHazardPhraseGrid;
