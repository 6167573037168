import { CasMaster } from '../../../../../../types/formulation';
import FormPartial from '../../../../../../components/form/formContainer/FormPartial';
import { Checkbox, FormControlLabel, Grid } from '@mui/material';
import TextInput from '../../../../../../components/form/formInputs/TextInput/TextInput';
import DateInput from '../../../../../../components/form/formInputs/DateInput/DateInput';
import React, { useContext } from 'react';
import DatacorLogoSpinner from '../../../../../../components/datacorLogoSpinner/DatacorLogoSpinner';
import { ExtendedFormProps } from '../../../../../form/helpers/ExtendedFormProps';
import { CasMasterFormDefs } from '../../../../../../constants/i18n/translations/termDefinitions/formulation';
import TranslatableText from '../../../../../../components/i18n/TranslatableText';
import SettingsContext from '../../../../../../contexts/settings.context';
import { useGetTermSetQuery } from '../../../../../../services/i18n/i18n.service';
import { FORMULATION_DEFS } from '../../../../../../constants/i18n/translations/termSetDefinitions/formulation';
import { skipToken } from '@reduxjs/toolkit/query';

const CasHeaderForm = (props: ExtendedFormProps<CasMaster>) => {
    const { currentParentRecord, isParentLoading } = props.parentData;

    const { formMethods, handleFieldChange } = props.helpers;

    const { readOnly } = props.permissions;

    const { settings } = useContext(SettingsContext);
    const { data: termSet } = useGetTermSetQuery(
        settings?.userSettings
            ? {
                  languageId: settings?.userSettings?.languageId,
                  code: FORMULATION_DEFS.CAS_MASTER_FORM,
              }
            : skipToken
    );

    return (
        <FormPartial>
            {isParentLoading ? (
                <DatacorLogoSpinner />
            ) : (
                <>
                    <Grid container spacing={2}>
                        <Grid item xs={3}>
                            <TextInput
                                fullWidth
                                id="identifier"
                                value={currentParentRecord.identifier}
                                label={
                                    <TranslatableText
                                        termKey={CasMasterFormDefs.Cas_No}
                                        termSet={termSet}
                                    />
                                }
                                maxLength={20}
                                formMethods={formMethods}
                                required={true}
                                onChange={handleFieldChange}
                                disabled={readOnly}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <TextInput
                                fullWidth
                                id="chemicalName"
                                value={currentParentRecord.chemicalName}
                                label={
                                    <TranslatableText
                                        termKey={
                                            CasMasterFormDefs.Chemical_Name
                                        }
                                        termSet={termSet}
                                    />
                                }
                                maxLength={250}
                                formMethods={formMethods}
                                onChange={handleFieldChange}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <FormControlLabel
                                label={
                                    <TranslatableText
                                        termKey={
                                            CasMasterFormDefs.Chemical_Update
                                        }
                                        termSet={termSet}
                                    />
                                }
                                control={
                                    <Checkbox
                                        id="allowChemicalUpdate"
                                        checked={
                                            currentParentRecord.allowChemicalUpdate
                                        }
                                        onChange={handleFieldChange}
                                    />
                                }
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <DateInput
                                label={
                                    <TranslatableText
                                        termKey={
                                            CasMasterFormDefs.Last_Chemical_Update
                                        }
                                        termSet={termSet}
                                    />
                                }
                                id="lastChemicalUpdate"
                                value={currentParentRecord.lastChemicalUpdate}
                                readOnly={true}
                                onChange={handleFieldChange}
                                formMethods={formMethods}
                            />
                        </Grid>
                    </Grid>
                </>
            )}
        </FormPartial>
    );
};

export default CasHeaderForm;
