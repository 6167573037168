import React from 'react';
import DatacorLogoSpinner from '../../../../../../../../components/datacorLogoSpinner/DatacorLogoSpinner';
import FormPartial from '../../../../../../../../components/form/formContainer/FormPartial';
import { Grid } from '@mui/material';
import { isNilOrEmpty } from '../../../../../../../../utils/objectUtils';

export interface SdsSubSectionFormProps {
    formTitle?: string;
    isFormLoading: boolean;
    content: any;
}
const SdsSubSectionForm = (props: SdsSubSectionFormProps) => {
    const { formTitle, isFormLoading, content } = props;

    const createForm = () => {
        return formTitle && !isNilOrEmpty(formTitle) ? (
            <FormPartial title={formTitle}>
                <Grid container spacing={2}>
                    {content}
                </Grid>
            </FormPartial>
        ) : (
            <>
                <Grid container spacing={2}>
                    {content}
                </Grid>
            </>
        );
    };

    return <>{isFormLoading ? <DatacorLogoSpinner /> : createForm()}</>;
};

export default SdsSubSectionForm;
