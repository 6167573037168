import React, { useContext } from 'react';
import { RootStateOrAny, useSelector } from 'react-redux';
import { skipToken } from '@reduxjs/toolkit/query';
import useBaseForm from '../../../../form/hooks/useBaseForm';
import { LocaleGroup } from '../../../../../types/formulation';
import { PermissionsUtil } from '../../../../../utils/permissions/permissionsUtil';
import { PERMISSIONS } from '../../../../../constants/permissions/Permissions.constants';
import {
    useCreateLocaleGroupMutation,
    useDeleteLocaleGroupMutation,
    useGetLocaleGroupQuery,
    useUpdateLocaleGroupMutation,
} from '../../../../../services/formulation/localeGroup/localeGroup.service';
import SettingsContext from '../../../../../contexts/settings.context';
import { useGetTermSetQuery } from '../../../../../services/i18n/i18n.service';
import { FORMULATION_DEFS } from '../../../../../constants/i18n/translations/termSetDefinitions/formulation';

const useLocaleGroupForm = (id: string) => {
    const user = useSelector((state: RootStateOrAny) => state.user);

    const { isLoading, data: currentLocaleGroup } = useGetLocaleGroupQuery(
        id ? id : skipToken
    );

    const canCreateLocaleGroup = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.FORMULATION.LOCALE_GROUPS.CREATE
    );
    const canUpdateLocaleGroup = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.FORMULATION.LOCALE_GROUPS.EDIT
    );
    const canDeleteLocaleGroup = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.FORMULATION.LOCALE_GROUPS.DELETE
    );

    const [createLocaleGroup] = useCreateLocaleGroupMutation();
    const [updateLocaleGroup] = useUpdateLocaleGroupMutation();
    const [deleteLocaleGroup] = useDeleteLocaleGroupMutation();

    const blankLocaleGroup: LocaleGroup = {
        groupId: '',
        description: '',
        active: null,
        businessEntityId: user?.businessEntity?.id,
    };

    const { settings } = useContext(SettingsContext);
    const { data: termSet } = useGetTermSetQuery(
        settings?.userSettings
            ? {
                  languageId: settings?.userSettings?.languageId,
                  code: FORMULATION_DEFS.LOCALE_GROUPS_FORM,
              }
            : skipToken
    );

    const {
        fields,
        formMethods,
        handleFieldChange,
        onCreate,
        onUpdate,
        onDelete,
        closeForm,
        hasEntityChanges,
    } = useBaseForm({
        closePath: '/formulation/localeGroup',
        reroute: true,
        blankEntity: blankLocaleGroup,
        activeEntity: currentLocaleGroup,
        onlyUpdateEntityWithChanges: true,
        getDescription: () => {
            return `Locale Group ${fields.groupId}`;
        },
        createEntity: () => {
            return createLocaleGroup({ postBody: fields });
        },
        updateEntity: () => {
            return updateLocaleGroup({
                id: id as unknown as number,
                postBody: fields,
            });
        },
        deleteEntity: () => {
            return deleteLocaleGroup(id);
        },
    });

    return {
        isLoading,
        fields,
        formMethods,
        handleFieldChange,
        onCreate,
        onUpdate,
        onDelete,
        closeForm,
        canCreateLocaleGroup,
        canDeleteLocaleGroup,
        canUpdateLocaleGroup,
        termSet,
        hasEntityChanges,
    };
};

export default useLocaleGroupForm;
