import { FA_AssetEntry } from '../../../types/FixedAsset.types';
import {
    Box,
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    Stack,
    Switch,
    Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { LoadingButton } from '@mui/lab';
import React from 'react';
import useAssetEntryActionsModal from './hooks/useAssetEntryActionsModal';
import TranslatableText from '../../../components/i18n/TranslatableText';
import { AssetEntriesGridDefs } from '../../../constants/i18n/translations/termDefinitions/accounting';

export interface AssetEntryActionsProps {
    open: boolean;
    selected: FA_AssetEntry[];
    onClose: () => void;
}

const AssetEntryActionsModal = (props: AssetEntryActionsProps) => {
    const {
        shouldLockEntries,
        setShouldLockEntries,
        canToggleLockUnlock,
        isLockingEntries,
        isUnlockingEntries,
        handleUpdateEntries,
        termSet,
    } = useAssetEntryActionsModal(props);

    return (
        <Dialog
            open={props.open}
            onClose={props.onClose}
            fullWidth
            maxWidth={'sm'}>
            <DialogTitle>
                <Box display="flex" alignItems="center">
                    <Box flexGrow={1}>
                        <TranslatableText
                            termSet={termSet}
                            termKey={AssetEntriesGridDefs.Actions_Title}
                        />
                    </Box>
                    <Box>
                        <IconButton onClick={props.onClose}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </Box>
            </DialogTitle>
            <DialogContent>
                <Stack spacing={2} alignItems={'center'}>
                    <Stack direction="row" spacing={1} alignItems="center">
                        <Typography>
                            <TranslatableText
                                termSet={termSet}
                                termKey={
                                    AssetEntriesGridDefs.Actions_Unlock_Entries
                                }
                            />
                        </Typography>
                        <Switch
                            checked={shouldLockEntries}
                            disabled={!canToggleLockUnlock}
                            onChange={(event) =>
                                setShouldLockEntries(event.target.checked)
                            }
                        />
                        <Typography>
                            <TranslatableText
                                termSet={termSet}
                                termKey={
                                    AssetEntriesGridDefs.Actions_Lock_Entries
                                }
                            />
                        </Typography>
                    </Stack>
                    <LoadingButton
                        fullWidth
                        variant="contained"
                        type="submit"
                        loading={isLockingEntries || isUnlockingEntries}
                        onClick={handleUpdateEntries}>
                        <TranslatableText
                            termSet={termSet}
                            termKey={AssetEntriesGridDefs.Actions_Changes}
                        />
                    </LoadingButton>
                </Stack>
            </DialogContent>
        </Dialog>
    );
};

export default AssetEntryActionsModal;
