import { RegulationSection } from '../../../../types/formulation';
import { SelectionOption } from '../../../../types/Shared.types';
import { datacorAppApi } from '../../../datacorAppApi';

const serviceUrl: string = 'formulation/regulation-sections';

export const regulationSectionApi = datacorAppApi.injectEndpoints({
    endpoints: (build) => ({
        getRegulationSectionsForDropDown: build.query<SelectionOption[], void>({
            query: () => serviceUrl,
            transformResponse: (response: RegulationSection[]) => {
                return response.map((regulationBody: RegulationSection) => {
                    return {
                        label: regulationBody.section,
                        value: regulationBody.id,
                    };
                });
            },
            providesTags: ['RegulationSection'],
        }),
    }),
});

export const { useGetRegulationSectionsForDropDownQuery } =
    regulationSectionApi;
