import React from 'react';
import BaseForm, { ParamTypes } from '../../components/form/baseForm/BaseForm';
import { useParams } from 'react-router-dom';
import { Grid } from '@mui/material';
import TextInput from '../../components/form/formInputs/TextInput/TextInput';
import SelectInput from '../../components/form/formInputs/SelectInput/SelectInput';
import useAttributeTemplatesForm from './hooks/useAttributeTemplatesForm';
import { isNilOrEmpty } from '../../utils/objectUtils';
import FormPartial from '../../components/form/formContainer/FormPartial';
import { ATTRIBUTE_TEMPLATE_FIELDS } from '../../constants/revisionHistory/revisionHistory.constants';
import AttributeTemplateDefinitionsGrid from './AttributeTemplateDefinitionsGrid';

const AttributeTemplatesForm = () => {
    const { id } = useParams<ParamTypes>();

    const {
        fields,
        handleChildrenRecords,
        handleFieldChange,
        formMethods,
        isLoadingTemplate,
        onCreate,
        onUpdate,
        onDelete,
        closeForm,
        attrEntities,
        isLoadingEntities,
        canManageAttribute,
        onLock,
    } = useAttributeTemplatesForm(id);

    return (
        <BaseForm
            title={'Attribute Templates'}
            formMethods={formMethods}
            displayCreateButton={canManageAttribute}
            displayUpdateButton={canManageAttribute && !fields.locked}
            displayDeleteButton={canManageAttribute && !fields.locked}
            displayLockButton={canManageAttribute}
            showOnRevision={true}
            fieldMappings={ATTRIBUTE_TEMPLATE_FIELDS}
            entityUuid={fields?.uuid}
            isLocked={fields.locked}
            isLoading={isLoadingTemplate || isLoadingEntities}
            isNew={!id}
            onCreate={onCreate}
            onUpdate={onUpdate}
            onDelete={onDelete}
            onLock={onLock}
            onCancel={closeForm}
            readOnly={(id && !canManageAttribute) || fields.locked}>
            <Grid container spacing={2}>
                <Grid item xs={3}>
                    <TextInput
                        fullWidth
                        label="Template Code"
                        id="code"
                        formMethods={formMethods}
                        value={fields.code}
                        onChange={handleFieldChange}
                        required={true}
                        maxLength={10}
                        disabled={!isNilOrEmpty(id)}
                        inputProps={{ maxLength: 10 }}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextInput
                        fullWidth
                        label="Name"
                        id="name"
                        formMethods={formMethods}
                        value={fields.name}
                        onChange={handleFieldChange}
                        required={true}
                        maxLength={50}
                    />
                </Grid>
                <Grid item xs={3}>
                    <SelectInput
                        fullWidth
                        label="Entity Code"
                        id="attrValueTypeId"
                        formMethods={formMethods}
                        value={fields.attrEntityId}
                        onChange={handleFieldChange}
                        options={attrEntities}
                        required={true}
                        disabled={!isNilOrEmpty(id)}
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormPartial title={'Attribute Definitions'}>
                        <AttributeTemplateDefinitionsGrid
                            attributeTemplate={fields}
                            canManageAttribute={canManageAttribute}
                            isLoadingTemplate={isLoadingTemplate}
                            updateChildList={handleChildrenRecords}
                        />
                    </FormPartial>
                </Grid>
            </Grid>
        </BaseForm>
    );
};

export default AttributeTemplatesForm;
