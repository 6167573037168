import React, { useEffect, useState } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Box,
    IconButton,
    Button,
    List,
    ListItem,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

export interface BusinessEntityExternalAuthProps {
    appClientId?: string;
    appClientSecret?: string;
    show: boolean;
    onClose?: () => void;
}

const BusinessEntityExternalAuthDialog = (
    props: BusinessEntityExternalAuthProps
) => {
    const [open, setOpen] = useState(false);

    useEffect(() => {
        if (props.show) {
            setOpen(props.show);
        }
    }, [props.show]);

    const handleClose = () => {
        setOpen(false);
        if (props.onClose) {
            props.onClose();
        }
    };

    return (
        <Dialog open={open}>
            <DialogTitle data-testid="approval-workflows-modal-header">
                <Box display="flex" alignItems="center">
                    <Box flexGrow={1}>IMPORTANT Key and Secret</Box>
                    <Box>
                        <IconButton onClick={() => handleClose()}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </Box>
            </DialogTitle>

            <DialogContent>
                <p>
                    Please make note of the values below.{' '}
                    <b>These cannot be generated again</b> and will be needed to
                    access the Datacor API.
                </p>
                <List>
                    <ListItem>
                        <b>Client Key: </b>
                        <br />
                        {props?.appClientId}
                    </ListItem>
                    <ListItem>
                        {' '}
                        <b>Client Secret: </b>
                        <br />
                        {props?.appClientSecret}
                    </ListItem>
                </List>
            </DialogContent>
            <DialogActions>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleClose()}>
                    Understood
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default BusinessEntityExternalAuthDialog;
