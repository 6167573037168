import React from 'react';
import { Checkbox, FormControlLabel, Grid } from '@mui/material';
import FormPartial from '../../../../../../components/form/formContainer/FormPartial';
import NumericInput from '../../../../../../components/form/formInputs/NumericInput/NumericInput';
import DatacorLogoSpinner from '../../../../../../components/datacorLogoSpinner/DatacorLogoSpinner';
import TextInputPopup from '../../../../../../components/form/formInputs/TextInputPopup/TextInputPopup';
import { RDFormula } from '../../../../../../types/formulation';
import useRDFormulaTotalsForm from './hooks/useRDFormulaTotalsForm';
import { ChildFormProps } from '../../../../../form/helpers/ChildFormProps';
import TranslatableText from '../../../../../../components/i18n/TranslatableText';
import { RDFormulasFormDefs } from '../../../../../../constants/i18n/translations/termDefinitions/formulation';

export const RDFormulaTotalsForm = (props: ChildFormProps<RDFormula>) => {
    const {
        headerPermissions,
        unitOfMeasureOptions,
        dropdownOptions,
        uomTextPopupColDefs,
        termSet,
        fields,
        formMethods,
        handlePropertyChanged,
    } = useRDFormulaTotalsForm(props);

    const { isParentLoading } = props.parentData;

    const { canUpdate, canView } = props.permissions;

    const isReadOnly = !canUpdate && canView;

    return isParentLoading ? (
        <DatacorLogoSpinner />
    ) : (
        <FormPartial
            title={
                (
                    <TranslatableText
                        termSet={termSet}
                        termKey={RDFormulasFormDefs.Component_Totals_Text}
                    />
                ) as unknown as string
            }>
            <FormPartial
                title={
                    (
                        <TranslatableText
                            termSet={termSet}
                            termKey={
                                RDFormulasFormDefs.Component_Calculated_Totals_Text
                            }
                        />
                    ) as unknown as string
                }>
                <Grid container spacing={2}>
                    <Grid item xs={3}>
                        <NumericInput
                            fullWidth
                            id="formulaTotal"
                            label={
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={
                                        RDFormulasFormDefs.Component_Totals_Total
                                    }
                                />
                            }
                            value={fields?.formulaTotal}
                            onChange={handlePropertyChanged}
                            formMethods={formMethods}
                            numDecimals={4}
                            greaterThan={0}
                            disabled={true}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextInputPopup
                            fullWidth
                            id="formulaUomId"
                            onChange={handlePropertyChanged}
                            label={
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={RDFormulasFormDefs.UOM}
                                />
                            }
                            formMethods={formMethods}
                            popupOptions={
                                headerPermissions.canViewUnitOfMeasure
                                    ? unitOfMeasureOptions || []
                                    : []
                            }
                            colDefs={uomTextPopupColDefs}
                            value={fields?.formulaUomId}
                            valueFormatter={{
                                optionLabel: 'label',
                                value: 'value',
                            }}
                            optionLabel={
                                dropdownOptions?.selectedFormulaTotalUnit?.label
                            }
                            disabled={isReadOnly}
                            required={true}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <NumericInput
                            fullWidth
                            id="manualYieldPct"
                            label={
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={
                                        RDFormulasFormDefs.Component_Totals_Manual_Yield
                                    }
                                />
                            }
                            value={fields?.manualYieldPct}
                            onChange={handlePropertyChanged}
                            formMethods={formMethods}
                            numDecimals={4}
                            maxValue={999}
                            greaterThan={0}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <FormControlLabel
                            label={
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={
                                        RDFormulasFormDefs.Component_Totals_Yield_Override
                                    }
                                />
                            }
                            control={
                                <Checkbox
                                    id="yieldCalcOverride"
                                    checked={fields?.yieldCalcOverride}
                                    onChange={handlePropertyChanged}
                                />
                            }
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <NumericInput
                            fullWidth
                            id="net"
                            label={
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={
                                        RDFormulasFormDefs.Component_Totals_Net
                                    }
                                />
                            }
                            value={fields?.net}
                            onChange={handlePropertyChanged}
                            formMethods={formMethods}
                            numDecimals={4}
                            greaterThan={0}
                            disabled={true}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <NumericInput
                            fullWidth
                            id="calculatedYieldPct"
                            label={
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={
                                        RDFormulasFormDefs.Component_Totals_Calculated_Yield
                                    }
                                />
                            }
                            value={fields?.calculatedYieldPct}
                            onChange={handlePropertyChanged}
                            formMethods={formMethods}
                            numDecimals={4}
                            maxValue={999}
                            greaterThan={0}
                            disabled={true}
                        />
                    </Grid>
                </Grid>
            </FormPartial>
            <FormPartial
                title={
                    (
                        <TranslatableText
                            termSet={termSet}
                            termKey={
                                RDFormulasFormDefs.Component_Component_Cost_Totals_Text
                            }
                        />
                    ) as unknown as string
                }>
                <Grid container spacing={2}>
                    <Grid item xs={4}>
                        <NumericInput
                            fullWidth
                            id="totalUnitCost"
                            label={
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={
                                        RDFormulasFormDefs.Component_Totals_Unit_Cost
                                    }
                                />
                            }
                            value={fields?.totalUnitCost}
                            onChange={handlePropertyChanged}
                            formMethods={formMethods}
                            numDecimals={4}
                            disabled={true}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <NumericInput
                            fullWidth
                            id="totalNetCost"
                            label={
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={
                                        RDFormulasFormDefs.Component_Totals_Net_Cost
                                    }
                                />
                            }
                            value={fields?.totalNetCost}
                            onChange={handlePropertyChanged}
                            formMethods={formMethods}
                            numDecimals={4}
                            disabled={true}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <NumericInput
                            fullWidth
                            id="totalExtendedCost"
                            label={
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={
                                        RDFormulasFormDefs.Component_Totals_Extended_Cost
                                    }
                                />
                            }
                            value={fields?.totalExtendedCost}
                            onChange={handlePropertyChanged}
                            formMethods={formMethods}
                            numDecimals={4}
                            disabled={true}
                        />
                    </Grid>
                </Grid>
            </FormPartial>
            <FormPartial
                title={
                    (
                        <TranslatableText
                            termSet={termSet}
                            termKey={
                                RDFormulasFormDefs.Component_Totals_Specific_Gravity_Text
                            }
                        />
                    ) as unknown as string
                }>
                <Grid container spacing={2}>
                    <Grid item xs={4}>
                        <NumericInput
                            fullWidth
                            id="calcSpecificGravity"
                            label={
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={
                                        RDFormulasFormDefs.Component_Totals_Specific_Gravity_Text
                                    }
                                />
                            }
                            value={fields?.calcSpecificGravity}
                            onChange={handlePropertyChanged}
                            formMethods={formMethods}
                            numDecimals={4}
                            disabled={true}
                        />
                    </Grid>
                </Grid>
            </FormPartial>
        </FormPartial>
    );
};
