import { RDFormulaStatus } from '../../../../types/formulation';
import { datacorAppApi } from '../../../datacorAppApi';

const serviceUrl: string = 'rdFormulaStatus';

export const rdFormulaStatusApi = datacorAppApi.injectEndpoints({
    endpoints: (build) => ({
        getFormulaStatusForDropdown: build.query<
            { label: string; value: bigint }[],
            void
        >({
            query: () => serviceUrl,
            transformResponse: (response: RDFormulaStatus[]) => {
                return response.map((propType: RDFormulaStatus) => {
                    return {
                        label: propType.status,
                        value: propType.id,
                    };
                });
            },
            providesTags: ['FormulaStatus'],
        }),
    }),
});

export const { useGetFormulaStatusForDropdownQuery } = rdFormulaStatusApi;
