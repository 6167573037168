import {
    Box,
    Dialog,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import React from 'react';
import { DialogProps } from '@mui/material/Dialog/Dialog';
import { LoadingButton } from '@mui/lab';
import useRDFormulaPublishToProductModal from './hooks/useRDFormulaPublishToProductModal';
import { RDFormula } from '../../../../../types/formulation';
import TranslatableText from '../../../../../components/i18n/TranslatableText';
import { RDFormulasFormDefs } from '../../../../../constants/i18n/translations/termDefinitions/formulation';
import TextInputPopup from '../../../../../components/form/formInputs/TextInputPopup/TextInputPopup';
import CheckBoxInput from '../../../../../components/form/formInputs/CheckBoxInput/CheckBoxInput';
import TextInput from '../../../../../components/form/formInputs/TextInput/TextInput';
import SelectInput from '../../../../../components/form/formInputs/SelectInput/SelectInput';
import FormPartial from '../../../../../components/form/formContainer/FormPartial';
import CustomHeader from '../../../../../components/grids/CustomHeader';
import DefaultColumnTypes from '../../../../../components/grids/columns/Column.constants';
import DatacorLogoSpinner from '../../../../../components/datacorLogoSpinner/DatacorLogoSpinner';

export type RdFormulaPublishToProductModalProps = Omit<
    DialogProps,
    'onClose'
> & {
    rdFormulaRecord: RDFormula;
    onClose: () => void;
};

const RDFormulaPublishToProductModal = ({
    onClose,
    rdFormulaRecord,
    ...props
}: RdFormulaPublishToProductModalProps) => {
    const {
        fields,
        handleFieldChange,
        submitForm,
        termSet,
        formMethods,
        dropdownOptions,
        itemMasterOptions,
        statusOptions,
        inventoryTypesOptions,
        itemMasterCodeList,
        closeModal,
        isSavingItemRecord,
    } = useRDFormulaPublishToProductModal(onClose, rdFormulaRecord);

    const productCodeTextPopupColDefs = [
        {
            field: 'label',
            headerComponentFramework: (props: any) => {
                return (
                    <CustomHeader
                        {...props}
                        termSet={termSet}
                        termKey={
                            RDFormulasFormDefs.Product_Selection_Product_Code
                        }
                    />
                );
            },
            ...DefaultColumnTypes.ShortText,
        },
        {
            field: 'description',
            headerComponentFramework: (props: any) => {
                return (
                    <CustomHeader
                        {...props}
                        termSet={termSet}
                        termKey={
                            RDFormulasFormDefs.Product_Selection_Product_Name
                        }
                    />
                );
            },
            ...DefaultColumnTypes.ShortText,
        },
    ];

    return (
        <Dialog {...props} fullWidth maxWidth={'lg'}>
            <>
                {!isSavingItemRecord ? (
                    <>
                        <DialogTitle>
                            <Box display="flex" alignItems="center">
                                <Box flexGrow={1}>
                                    <TranslatableText
                                        termSet={termSet}
                                        termKey={
                                            RDFormulasFormDefs.Publish_To_Product_Title
                                        }
                                    />
                                </Box>
                                <Box>
                                    <IconButton onClick={closeModal}>
                                        <CloseIcon />
                                    </IconButton>
                                </Box>
                            </Box>
                        </DialogTitle>
                        <DialogContent>
                            <FormPartial
                                title={
                                    (
                                        <TranslatableText
                                            termSet={termSet}
                                            termKey={
                                                RDFormulasFormDefs.Publish_To_Product_Existing
                                            }
                                        />
                                    ) as unknown as string
                                }
                                isVisible={true}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <TextInputPopup
                                            fullWidth
                                            id="itemCodeId"
                                            onChange={handleFieldChange}
                                            label={
                                                <TranslatableText
                                                    termSet={termSet}
                                                    termKey={
                                                        RDFormulasFormDefs.Product_Code
                                                    }
                                                />
                                            }
                                            formMethods={formMethods}
                                            popupOptions={itemMasterOptions}
                                            colDefs={
                                                productCodeTextPopupColDefs
                                            }
                                            value={fields.itemCodeId}
                                            valueFormatter={{
                                                optionLabel: 'label',
                                                value: 'value',
                                            }}
                                            optionLabel={
                                                dropdownOptions?.selectedItem
                                                    ?.label
                                            }
                                            disabled={fields.newItem}
                                        />
                                    </Grid>
                                </Grid>
                            </FormPartial>
                            <FormPartial
                                title={
                                    (
                                        <TranslatableText
                                            termSet={termSet}
                                            termKey={
                                                RDFormulasFormDefs.Publish_To_Product_Product_Create
                                            }
                                        />
                                    ) as unknown as string
                                }
                                isVisible={true}>
                                <Grid container spacing={2}>
                                    <Grid item xs={3}>
                                        <CheckBoxInput
                                            id="newItem"
                                            label={
                                                (
                                                    <TranslatableText
                                                        termSet={termSet}
                                                        termKey={
                                                            RDFormulasFormDefs.Publish_To_Product_New
                                                        }
                                                    />
                                                ) as unknown as string
                                            }
                                            checked={fields.newItem}
                                            onChange={handleFieldChange}
                                            disabled={false}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <TextInput
                                            fullWidth
                                            id="newItemCode"
                                            value={fields.newItemCode}
                                            label={
                                                <TranslatableText
                                                    termSet={termSet}
                                                    termKey={
                                                        RDFormulasFormDefs.Publish_To_Product_New_Code
                                                    }
                                                />
                                            }
                                            maxLength={20}
                                            formMethods={formMethods}
                                            required={fields.newItem}
                                            onChange={handleFieldChange}
                                            disabled={!fields.newItem}
                                            isUnique={true}
                                            uniqueList={itemMasterCodeList}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <TextInput
                                            fullWidth
                                            id="itemName"
                                            value={fields.itemName}
                                            label={
                                                <TranslatableText
                                                    termSet={termSet}
                                                    termKey={
                                                        RDFormulasFormDefs.Publish_To_Product_New_Name
                                                    }
                                                />
                                            }
                                            maxLength={20}
                                            formMethods={formMethods}
                                            required={fields.newItem}
                                            onChange={handleFieldChange}
                                            disabled={!fields.newItem}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <SelectInput
                                            fullWidth
                                            label={
                                                <TranslatableText
                                                    termSet={termSet}
                                                    termKey={
                                                        RDFormulasFormDefs.Publish_To_Product_Status
                                                    }
                                                />
                                            }
                                            id="itemStatusId"
                                            value={fields.itemStatusId}
                                            onChange={handleFieldChange}
                                            formMethods={formMethods}
                                            options={statusOptions}
                                            disabled={!fields.newItem}
                                            required={fields.newItem}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <SelectInput
                                            fullWidth
                                            label={
                                                <TranslatableText
                                                    termSet={termSet}
                                                    termKey={
                                                        RDFormulasFormDefs.Publish_To_Product_Inventory
                                                    }
                                                />
                                            }
                                            id="inventoryTypeId"
                                            value={fields.inventoryTypeId}
                                            onChange={handleFieldChange}
                                            formMethods={formMethods}
                                            options={inventoryTypesOptions}
                                            disabled={!fields.newItem}
                                            required={fields.newItem}
                                        />
                                    </Grid>
                                </Grid>
                            </FormPartial>
                            <FormPartial
                                title={
                                    (
                                        <TranslatableText
                                            termSet={termSet}
                                            termKey={
                                                RDFormulasFormDefs.Publish_To_Product_Properties
                                            }
                                        />
                                    ) as unknown as string
                                }
                                isVisible={true}>
                                <Grid container spacing={2}>
                                    <Grid item xs={3}>
                                        <CheckBoxInput
                                            id="specificGravity"
                                            label={
                                                (
                                                    <TranslatableText
                                                        termSet={termSet}
                                                        termKey={
                                                            RDFormulasFormDefs.Publish_To_Product_Gravity
                                                        }
                                                    />
                                                ) as unknown as string
                                            }
                                            checked={fields.specificGravity}
                                            onChange={handleFieldChange}
                                            disabled={false}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <CheckBoxInput
                                            id="chemicalIdentifiers"
                                            label={
                                                (
                                                    <TranslatableText
                                                        termSet={termSet}
                                                        termKey={
                                                            RDFormulasFormDefs.Publish_To_Product_Chemical
                                                        }
                                                    />
                                                ) as unknown as string
                                            }
                                            checked={fields.chemicalIdentifiers}
                                            onChange={handleFieldChange}
                                            disabled={false}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <CheckBoxInput
                                            id="productProperties"
                                            label={
                                                (
                                                    <TranslatableText
                                                        termSet={termSet}
                                                        termKey={
                                                            RDFormulasFormDefs.Publish_To_Product_Product_Properties
                                                        }
                                                    />
                                                ) as unknown as string
                                            }
                                            checked={fields.productProperties}
                                            onChange={handleFieldChange}
                                            disabled={false}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <CheckBoxInput
                                            id="cost"
                                            label={
                                                (
                                                    <TranslatableText
                                                        termSet={termSet}
                                                        termKey={
                                                            RDFormulasFormDefs.Publish_To_Product_Cost
                                                        }
                                                    />
                                                ) as unknown as string
                                            }
                                            checked={fields.cost}
                                            onChange={handleFieldChange}
                                            disabled={false}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <LoadingButton
                                            fullWidth
                                            variant="contained"
                                            type="submit"
                                            loading={false}
                                            disabled={
                                                !fields.newItem &&
                                                !fields.itemCodeId
                                            }
                                            onClick={submitForm}>
                                            <TranslatableText
                                                termSet={termSet}
                                                termKey={
                                                    RDFormulasFormDefs.Publish_To_Product_Submit
                                                }
                                            />
                                        </LoadingButton>
                                    </Grid>
                                </Grid>
                            </FormPartial>
                        </DialogContent>
                    </>
                ) : (
                    <DatacorLogoSpinner />
                )}
            </>
        </Dialog>
    );
};

export default RDFormulaPublishToProductModal;
