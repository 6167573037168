import { Grid } from '@mui/material';
import FormPartial from '../../../../../../components/form/formContainer/FormPartial';
import React from 'react';
import useItemProperShippingNameForm from './hooks/useItemProperShippingNameForm';
import DatacorLogoSpinner from '../../../../../../components/datacorLogoSpinner/DatacorLogoSpinner';
import { ChildFormProps } from '../../../../../form/helpers/ChildFormProps';
import { ItemMaster } from '../../../../../../types/formulation';
import TextInput from '../../../../../../components/form/formInputs/TextInput/TextInput';
import { NumberMaxValues } from '../../../../../../types/Shared.types';
import NumericInput from '../../../../../../components/form/formInputs/NumericInput/NumericInput';
import CheckBoxInput from '../../../../../../components/form/formInputs/CheckBoxInput/CheckBoxInput';
import AutoCompleteInput from '../../../../../../components/form/formInputs/AutoCompleteInput/AutoCompleteInput';
import TranslatableText from '../../../../../../components/i18n/TranslatableText';
import { ItemMasterFormDefs } from '../../../../../../constants/i18n/translations/termDefinitions/formulation';

const ItemProperShippingNameForm = (props: ChildFormProps<ItemMaster>) => {
    const {
        fields,
        handlePropertyChanged,
        properShippingNameOptions,
        isLoadingProperShippingName,
        dropdownOptions,
        setDropdownOptions,
        properShippingNameDescription,
        unitOfMeasureOptions,
        isLoadingUnitOfMeasure,
        formMethods,
        canViewProperShippingName,
        termSet,
    } = useItemProperShippingNameForm(props);

    const { isParentLoading } = props.parentData;

    const { disableDropdown } = props.helpers;

    return (
        <FormPartial
            title={
                (
                    <TranslatableText
                        termSet={termSet}
                        termKey={
                            ItemMasterFormDefs.Dot_Proper_Shipping_Name_Text
                        }
                    />
                ) as unknown as string
            }>
            {isParentLoading ||
            isLoadingProperShippingName ||
            isLoadingUnitOfMeasure ? (
                <DatacorLogoSpinner />
            ) : (
                <>
                    <Grid container spacing={2}>
                        <Grid item xs={3}>
                            <AutoCompleteInput
                                id="properShippingNameId"
                                inputFieldName="properShippingNameInputValue"
                                label={
                                    <TranslatableText
                                        termSet={termSet}
                                        termKey={
                                            ItemMasterFormDefs.Proper_Shipping_Name_Code
                                        }
                                    />
                                }
                                onHandlePropertyChanged={handlePropertyChanged}
                                options={properShippingNameOptions}
                                setDropdownOptions={setDropdownOptions}
                                value={
                                    dropdownOptions?.selectedProperShippingName
                                }
                                inputValue={
                                    dropdownOptions?.properShippingNameInputValue
                                }
                                isLoading={isLoadingProperShippingName}
                                formMethods={formMethods}
                                disabled={disableDropdown(
                                    canViewProperShippingName
                                )}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextInput
                                fullWidth
                                id="properShippingName"
                                value={properShippingNameDescription}
                                label={
                                    <TranslatableText
                                        termSet={termSet}
                                        termKey={
                                            ItemMasterFormDefs.Dot_Proper_Shipping_Name
                                        }
                                    />
                                }
                                maxLength={250}
                                formMethods={formMethods}
                                disabled={true}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <NumericInput
                                fullWidth
                                label={
                                    <TranslatableText
                                        termSet={termSet}
                                        termKey={
                                            ItemMasterFormDefs.RQ_Requirement
                                        }
                                    />
                                }
                                id="rqRequirement"
                                value={fields.rqRequirement}
                                onChange={handlePropertyChanged}
                                formMethods={formMethods}
                                required={false}
                                maxValue={NumberMaxValues.decimal206}
                                numDecimals={6}
                                greaterThan={0}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <AutoCompleteInput
                                id="rqUom"
                                inputFieldName="rqUomInputValue"
                                label={
                                    <TranslatableText
                                        termSet={termSet}
                                        termKey={ItemMasterFormDefs.RQ_UOM}
                                    />
                                }
                                onHandlePropertyChanged={handlePropertyChanged}
                                options={unitOfMeasureOptions}
                                setDropdownOptions={setDropdownOptions}
                                value={dropdownOptions?.selectedRqUom}
                                inputValue={dropdownOptions?.rqUomInputValue}
                                isLoading={isLoadingUnitOfMeasure}
                                formMethods={formMethods}
                                disabled={disableDropdown(
                                    canViewProperShippingName
                                )}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <TextInput
                                fullWidth
                                id="freightClass"
                                value={fields.freightClass}
                                label={
                                    <TranslatableText
                                        termSet={termSet}
                                        termKey={
                                            ItemMasterFormDefs.Freight_Class
                                        }
                                    />
                                }
                                maxLength={10}
                                formMethods={formMethods}
                                onChange={handlePropertyChanged}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <TextInput
                                fullWidth
                                id="nmfc"
                                value={fields.nmfc}
                                label={
                                    <TranslatableText
                                        termSet={termSet}
                                        termKey={ItemMasterFormDefs.NMFC}
                                    />
                                }
                                maxLength={10}
                                formMethods={formMethods}
                                onChange={handlePropertyChanged}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <CheckBoxInput
                                id="marinePollutant"
                                label={
                                    (
                                        <TranslatableText
                                            termSet={termSet}
                                            termKey={
                                                ItemMasterFormDefs.Marine_Pollutant
                                            }
                                        />
                                    ) as unknown as string
                                }
                                checked={fields.marinePollutant}
                                onChange={handlePropertyChanged}
                            />
                        </Grid>
                    </Grid>
                </>
            )}
        </FormPartial>
    );
};

export default ItemProperShippingNameForm;
