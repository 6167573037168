import { Grid } from '@mui/material';
import React from 'react';
import TextInput from '../../../../../../../../components/form/formInputs/TextInput/TextInput';
import {
    BlobData,
    SdsBlobSectionFormProps,
} from '../../../shared/blobs/hooks/SdsBlobSectionFormProps';
import {
    SdsHeader,
    SdsSubSection5_1,
} from '../../../../../../../../types/formulation';
import useSdsBlobSectionForm from '../../../shared/blobs/hooks/useSdsBlobSectionForm';
import SdsSubSectionForm from '../../../shared/blobs/components/SdsSubSectionForm';
import TranslatableText from '../../../../../../../../components/i18n/TranslatableText';
import { SDSFormDefs } from '../../../../../../../../constants/i18n/translations/termDefinitions/formulation';
const SubSection5_1Form = (props: SdsBlobSectionFormProps<SdsHeader>) => {
    const { isParentLoading } = props.parentData;

    const { disabledField } = props.permissions;

    const blobData: BlobData<SdsSubSection5_1> = {
        sectionNumber: 5,
        subSectionNumber: 1,
        blankBlobEntity: {
            suitableExtinguishingMedia: '',
            unsuitableExtinguishingMedia: '',
        },
    };

    const { fields, isBlobLoading, formMethods, handleFieldChange } =
        useSdsBlobSectionForm<SdsHeader, SdsSubSection5_1>({
            ...props,
            blobData,
        });
    return (
        <SdsSubSectionForm
            formTitle={
                (
                    <TranslatableText
                        termSet={props.termSet}
                        termKey={SDSFormDefs.Extinguishing_Media}
                    />
                ) as unknown as string
            }
            isFormLoading={isParentLoading || isBlobLoading}
            content={
                <>
                    <Grid item xs={12}>
                        <TextInput
                            fullWidth
                            label={
                                <TranslatableText
                                    termSet={props.termSet}
                                    termKey={
                                        SDSFormDefs.Suitable_Extinguishing_Media
                                    }
                                />
                            }
                            id="suitableExtinguishingMedia"
                            disabled={disabledField}
                            formMethods={formMethods}
                            value={fields.suitableExtinguishingMedia}
                            onChange={handleFieldChange}
                            maxLength={2000}
                            multiline={true}
                            maxRows={15}
                            minRows={5}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextInput
                            fullWidth
                            label={
                                <TranslatableText
                                    termSet={props.termSet}
                                    termKey={
                                        SDSFormDefs.Unsuitable_Extinguishing_Media
                                    }
                                />
                            }
                            id="unsuitableExtinguishingMedia"
                            disabled={disabledField}
                            formMethods={formMethods}
                            value={fields.unsuitableExtinguishingMedia}
                            onChange={handleFieldChange}
                            maxLength={2000}
                            multiline={true}
                            maxRows={15}
                            minRows={5}
                        />
                    </Grid>
                </>
            }
        />
    );
};
export default SubSection5_1Form;
