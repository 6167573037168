import { GhsHazardPhrase } from '../../../types/formulation';
import {
    BaseCreateEntityArgs,
    BaseUpdateEntityArgs,
} from '../../serviceInterfaces';
import { datacorAppApi } from '../../datacorAppApi';

const serviceUrl: string = 'ghs-hazard-phrase';

export const ghsHazardPhrasesApi = datacorAppApi.injectEndpoints({
    endpoints: (build) => ({
        createGhsHazardPhrase: build.mutation<
            any,
            BaseCreateEntityArgs<GhsHazardPhrase>
        >({
            query: (args) => ({
                url: serviceUrl,
                method: 'POST',
                body: args.postBody,
            }),
            invalidatesTags: ['GhsHazardPhrase'],
        }),
        updateGhsHazardPhrase: build.mutation<
            any,
            BaseUpdateEntityArgs<GhsHazardPhrase>
        >({
            query: (args) => ({
                url: `${serviceUrl}/${args.id}`,
                method: 'PUT',
                body: args.postBody,
            }),
            invalidatesTags: ['GhsHazardPhrase'],
        }),
        deleteGhsHazardPhrase: build.mutation<any, string>({
            query: (id) => ({
                url: `${serviceUrl}/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['GhsHazardPhrase'],
        }),
        getGhsHazardPhrase: build.query<any, string>({
            query: (id) => {
                return {
                    url: `${serviceUrl}/${id}`,
                };
            },
            providesTags: ['GhsHazardPhrase'],
        }),
        listGhsHazardPhrases: build.query<GhsHazardPhrase[], void>({
            query: () => {
                return {
                    url: serviceUrl,
                };
            },
            providesTags: ['GhsHazardPhrase'],
        }),
    }),
});

export const {
    useCreateGhsHazardPhraseMutation,
    useUpdateGhsHazardPhraseMutation,
    useDeleteGhsHazardPhraseMutation,
    useGetGhsHazardPhraseQuery,
    useListGhsHazardPhrasesQuery,
} = ghsHazardPhrasesApi;
