import { useForm } from '../../../../../libs/hooksLib';
import { FA_Asset } from '../../../../../types/FixedAsset.types';
import { useCreateFixedAssetAssetEntriesMutation } from '../../../../../services/fixedAssets/fixedAssets.service';
import { CreateAssetEntriesProps } from '../FixedAssetCreateAssetEntriesModal';
import { PermissionsUtil } from '../../../../../utils/permissions/permissionsUtil';
import { PERMISSIONS } from '../../../../../constants/permissions/Permissions.constants';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../store';

const useFixedAssetCreateAssetEntriesModal = (
    props: CreateAssetEntriesProps
) => {
    const user = useSelector((state: RootState) => state.user);

    const [createAssetEntries, { isLoading: isCreatingAssetEntries }] =
        useCreateFixedAssetAssetEntriesMutation();

    const canLockAssetEntry = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.FIXED_ASSETS.ASSET_ENTRY.LOCK
    );

    const initialFields: any = {
        assetDate: new Date(),
        lockEntries: false,
    };

    const { fields, handleFieldChange } = useForm(initialFields);

    const onCreateAssetEntries = async (event: any) => {
        event.preventDefault();

        try {
            const response: any = await createAssetEntries({
                ids: props.ids,
                assetDate: fields.assetDate,
                lockEntries: fields.lockEntries,
            });
            const responseAsset: FA_Asset = response.data;
            props.onAssetEntriesCreated(responseAsset.assetEntries);
            props.onClose();
        } catch (e) {
            console.log(e);
        }
    };

    return {
        fields,
        handleFieldChange,
        onCreateAssetEntries,
        isCreatingAssetEntries,
        canLockAssetEntry,
    };
};

export default useFixedAssetCreateAssetEntriesModal;
