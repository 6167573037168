import FormPartial from '../../../../../../components/form/formContainer/FormPartial';
import { isNilOrEmpty } from '../../../../../../utils/objectUtils';
import { Grid } from '@mui/material';
import React from 'react';
import RenderAppliedTemplate from './RenderAppliedTemplate';
import RenderUnappliedDefinitions from './RenderUnappliedDefinitions';
import RenderUnappliedTemplateHeader from './RenderUnappliedTemplateHeader';

const ItemAttributesSection = ({
    id,
    fields,
    handleFieldChange,
    formMethods,
    activeTemplates,
    isLoadingTemplates,
    attrFieldsDisabled,
    canUpdateItemMaster,
    handleApplyTemplate,
    formItemDefs,
    itemAttrValues,
    setItemAttrValues,
    setIsAttributeValid,
}: any) => {
    const hasTemplateApplied = !isNilOrEmpty(fields?.itemProfile);
    return (
        <FormPartial
            title={`Item Attributes ${
                hasTemplateApplied
                    ? '- ' + fields?.itemProfile[0]?.attrTemplate?.name
                    : ''
            }`}>
            {!hasTemplateApplied && (
                <RenderUnappliedTemplateHeader
                    id={id}
                    canUpdateItemMaster={canUpdateItemMaster}
                    fields={fields}
                    handleFieldChange={handleFieldChange}
                    formMethods={formMethods}
                    activeTemplates={activeTemplates}
                    isLoadingTemplates={isLoadingTemplates}
                    attrFieldsDisabled={attrFieldsDisabled}
                    handleApplyTemplate={handleApplyTemplate}
                />
            )}
            <div style={{ padding: '10px' }} />
            {fields?.attrTemplateId && !isNilOrEmpty(activeTemplates) && (
                <Grid container spacing={2}>
                    {hasTemplateApplied && formItemDefs ? (
                        <RenderAppliedTemplate
                            formItemDefs={formItemDefs}
                            fields={fields}
                            handleFieldChange={handleFieldChange}
                            itemAttrValues={itemAttrValues}
                            setItemAttrValues={setItemAttrValues}
                            formMethods={formMethods}
                            attrFieldsDisabled={attrFieldsDisabled}
                            setIsAttributeValid={setIsAttributeValid}
                        />
                    ) : (
                        <RenderUnappliedDefinitions
                            activeTemplates={activeTemplates}
                            fields={fields}
                            handleFieldChange={handleFieldChange}
                            itemAttrValues={itemAttrValues}
                            setItemAttrValues={setItemAttrValues}
                            formMethods={formMethods}
                            attrFieldsDisabled={attrFieldsDisabled}
                            setIsAttributeValid={setIsAttributeValid}
                        />
                    )}
                </Grid>
            )}
        </FormPartial>
    );
};

export default ItemAttributesSection;
