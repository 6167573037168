import {
    configureStore,
    combineReducers,
    Middleware,
    Action,
} from '@reduxjs/toolkit';
import user from './user';
import scheduler from './scheduler';
import thunk from 'redux-thunk';
import warehouses from './warehouses';
import batchTickets from './batchTickets';
import workcenters from './workcenters';
import equipment from './equipment';
import pricingAnalysisTool from './pricingAnalysisTool';
import grids from './grids';
import uiElements from './uiElements';
import formulation from './formulation';
import accounting from './accounting';
import fixedAssets from './fixedAssets';
import suppliers from './suppliers';
import { datacorAppApi } from '../services/datacorAppApi';

/* istanbul ignore file */
const reducer = combineReducers({
    [datacorAppApi.reducerPath]: datacorAppApi.reducer,
    user,
    scheduler,
    warehouses,
    batchTickets,
    workcenters,
    equipment,
    pricingAnalysisTool,
    grids,
    formulation,
    uiElements,
    accounting,
    fixedAssets,
    suppliers,
});

const rootReducer = (state: any, action: Action) => {
    if (action.type === 'user/logoutSuccess') {
        state = undefined;
    }
    return reducer(state, action);
};

export type RootState = ReturnType<typeof rootReducer>;

const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        })
            .concat(
                datacorAppApi.middleware as Middleware<
                    (action: Action<'specialAction'>) => number,
                    RootState
                >
            )
            .prepend(thunk),
});

export type AppDispatch = typeof store.dispatch;

export default store;
