import React, { useContext, useEffect, useState } from 'react';
import {
    FA_Asset,
    FA_AssetDisposalEntry,
    FA_AssetType,
} from '../../../../types/FixedAsset.types';
import _ from 'lodash';
import {
    GridReadyEvent,
    ICellRendererParams,
    SelectionChangedEvent,
} from 'ag-grid-community';
import {
    currencyFormatter,
    dateFormatter,
} from '../../../../utils/formattingUtils';
import { PermissionsUtil } from '../../../../utils/permissions/permissionsUtil';
import { PERMISSIONS } from '../../../../constants/permissions/Permissions.constants';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import { GridApi } from 'ag-grid-community/dist/lib/gridApi';
import { Link } from '@mui/material';
import SettingsContext from '../../../../contexts/settings.context';
import { useGetTermSetQuery } from '../../../../services/i18n/i18n.service';
import { ACCOUNTING_DEFS } from '../../../../constants/i18n/translations/termSetDefinitions/accounting';
import { skipToken } from '@reduxjs/toolkit/query';

export enum EntryGridMode {
    Default = '0',
    ByDate = '1',
    ByAssetType = '2',
}

export class MainEntryRow {
    public readonly id: string;
    public readonly disposalEntry: FA_AssetDisposalEntry;
    public readonly ledgerAssetType: FA_AssetType;
    public readonly disposalEntries: FA_AssetDisposalEntry[] = [];
    public readonly detailDisposalEntries: DetailEntryRow[] = [];
    public readonly exportDisposalEntries: ExportEntryRow[] = [];

    constructor(disposalEntry: FA_AssetDisposalEntry) {
        this.id = `${disposalEntry.disposalDate}|${disposalEntry.fixedAsset.ledgerAssetType.id}`;
        this.disposalEntry = disposalEntry;
        this.ledgerAssetType = disposalEntry.fixedAsset.ledgerAssetType;
        this.addEntry(disposalEntry);
    }

    public addEntry = (entry: FA_AssetDisposalEntry) => {
        this.disposalEntries.push(entry);

        const existingEntry = this.detailDisposalEntries.find(
            (row) => row.fixedAsset.id === entry.fixedAssetId
        );

        if (existingEntry) {
            existingEntry.detailDisposalEntries.push(entry);
        } else {
            this.detailDisposalEntries.push(new DetailEntryRow(entry));
        }

        //establish the row key for uniqueness
        const exportKey: string = `${entry.disposalDate}|${entry.accountId}`;
        const existingExport = this.exportDisposalEntries.find(
            (row) => row.id === exportKey
        );
        if (existingExport) {
            existingExport.entries.push(entry);
        } else {
            this.exportDisposalEntries.push(
                new ExportEntryRow(entry, exportKey)
            );
        }
    };

    public get groupEntries(): any {
        return this.disposalEntries
            .sort((a, b) => Number(a.accountId) - Number(b.accountId))
            .reduce((acc: any, obj) => {
                const key = obj.accountId.toString();
                if (!acc[key]) {
                    acc[key] = [];
                }
                acc[key].push(obj);
                return acc;
            }, {});
    }

    public get mainDebitList(): string {
        return Object.keys(this.groupEntries)
            .reduce((acc, key) => {
                const debitSum = this.groupEntries[key].reduce(
                    (sum: any, obj: FA_AssetDisposalEntry) =>
                        sum + Number(obj.debit),
                    0
                );
                acc.push({ debit: debitSum });
                return acc;
            }, [])
            .map((row) => currencyFormatter(row.debit))
            .join('\r\n');
    }

    public get mainCreditList(): string {
        return Object.keys(this.groupEntries)
            .reduce((acc, key) => {
                const creditSum = this.groupEntries[key].reduce(
                    (sum: any, obj: FA_AssetDisposalEntry) =>
                        sum + Number(obj.credit),
                    0
                );
                acc.push({ credit: creditSum });
                return acc;
            }, [])
            .map((row) => currencyFormatter(row.credit))
            .join('\r\n');
    }

    public get mainAccountNumberList(): string {
        return [
            ...new Set(
                this.disposalEntries
                    .sort((a, b) => Number(a.accountId) - Number(b.accountId))
                    .map((row) => row.account.number)
            ),
        ].join('\r\n');
    }

    public get mainAccountNameList(): string {
        return [
            ...new Set(
                this.disposalEntries
                    .sort((a, b) => Number(a.accountId) - Number(b.accountId))
                    .map(
                        (row) =>
                            `${row.account.name} - ${this.ledgerAssetType.code}`
                    )
            ),
        ].join('\r\n');
    }

    public get mainLockedSummary(): string {
        const totalLocked: number = this.detailDisposalEntries.filter(
            (row) => row.locked
        ).length;
        return `${totalLocked} of ${this.detailDisposalEntries.length}`;
    }

    public get hasUnlocked(): boolean {
        return this.disposalEntries.some((entry) => !entry.locked);
    }
}

export class DetailEntryRow {
    public readonly id: string;
    public readonly fixedAsset: FA_Asset;
    public readonly locked: Boolean;
    public readonly lockedSummary: string;
    public readonly disposalEntry: FA_AssetDisposalEntry;
    public readonly detailDisposalEntries: FA_AssetDisposalEntry[] = [];

    constructor(disposalEntry: FA_AssetDisposalEntry) {
        this.id = disposalEntry.fixedAssetId.toString();
        this.fixedAsset = disposalEntry.fixedAsset;
        this.locked = disposalEntry.locked;
        this.lockedSummary = disposalEntry.locked
            ? `${dateFormatter(disposalEntry.lockedDate)} (${
                  disposalEntry.lockedUserName
              })`
            : '';
        this.disposalEntry = disposalEntry;
        this.detailDisposalEntries.push(disposalEntry);
    }

    public get detailDebitList(): string {
        return this.detailDisposalEntries
            .sort((a, b) => Number(a.accountId) - Number(b.accountId))
            .map((row) => currencyFormatter(row.debit))
            .join('\r\n');
    }
    public get detailCreditList(): string {
        return this.detailDisposalEntries
            .sort((a, b) => Number(a.accountId) - Number(b.accountId))
            .map((row) => currencyFormatter(row.credit))
            .join('\r\n');
    }
    public get detailAccountNumberList(): string {
        return [
            ...new Set(
                this.detailDisposalEntries
                    .sort((a, b) => Number(a.accountId) - Number(b.accountId))
                    .map((row) => row.account.number)
            ),
        ].join('\r\n');
    }
    public get detailAccountNameList(): string {
        return [
            ...new Set(
                this.detailDisposalEntries
                    .sort((a, b) => Number(a.accountId) - Number(b.accountId))
                    .map((row) => row.account.name)
            ),
        ].join('\r\n');
    }
}

export class ExportEntryRow {
    public readonly id: string;
    public readonly entryDate: Date;
    public readonly accountNo: string;
    public readonly accountName: string;
    public readonly entries: FA_AssetDisposalEntry[] = [];
    constructor(entry: FA_AssetDisposalEntry, id: string) {
        //grouping for the debit and credit for a given fixed asset
        this.id = id;
        this.entryDate = entry.disposalDate;
        this.accountNo = entry.account.number;
        this.accountName = `${entry.account.name} - ${entry.fixedAsset.ledgerAssetType.code}`;
        this.entries.push(entry);
    }
    public get debit(): string {
        return currencyFormatter(
            this.entries.reduce((total, obj) => total + Number(obj.debit), 0)
        );
    }
    public get credit(): string {
        return currencyFormatter(
            this.entries.reduce((total, obj) => total + Number(obj.credit), 0)
        );
    }
}

const useFixedAssetDisposalEntriesGrid = () => {
    const user = useSelector((state: RootState) => state.user);
    const { settings } = useContext(SettingsContext);
    const { data: termSet } = useGetTermSetQuery(
        settings?.userSettings
            ? {
                  languageId: settings?.userSettings?.languageId,
                  code: ACCOUNTING_DEFS.DISPOSAL_ENTRIES_GRID,
              }
            : skipToken
    );
    const canViewDisposalEntries = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.FIXED_ASSETS.DISPOSAL_ENTRY.VIEW
    );

    const canLockDisposalEntries = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.FIXED_ASSETS.DISPOSAL_ENTRY.LOCK
    );

    const canDownloadDisposalEntries = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.FIXED_ASSETS.DISPOSAL_ENTRY.DOWNLOAD
    );

    const [showOptionsModal, setShowOptionsModal] = useState<boolean>(true);
    const [showActionsModal, setShowActionsModal] = useState<boolean>(false);
    const [showExportModal, setShowExportModal] = useState<boolean>(false);

    const [gridApi, setGridApi] = useState<GridApi>(null);
    const [hasSelectedRows, setHasSelectedRows] = useState<boolean>(false);
    const [hasUnlockedSelected, setHasUnlockedSelected] =
        useState<boolean>(true);

    const [selectedEntries, setSelectedEntries] = useState<
        FA_AssetDisposalEntry[]
    >([]);
    const [exportedEntries, setExportedEntries] = useState<ExportEntryRow[]>(
        []
    );

    const [assetDisposalEntryList, setAssetDisposalEntryList] = useState<
        FA_AssetDisposalEntry[]
    >([]);
    const [mainEntryRows, setMainEntryRows] = useState<MainEntryRow[]>([]);

    const [currentGridMode, setCurrentGridMode] = useState<EntryGridMode>(
        EntryGridMode.Default
    );
    const [currentTitle, setCurrentTitle] =
        useState<string>('Disposal Entries');

    const _createRowsFromList = (
        list: FA_AssetDisposalEntry[]
    ): MainEntryRow[] => {
        const rowMap: Map<string, MainEntryRow> = new Map<
            string,
            MainEntryRow
        >();

        _.orderBy(list, [
            'disposalEntry.disposalDate',
            'disposalEntry.fixedAsset.ledgerAssetType.id',
        ])?.forEach((disposalEntry: FA_AssetDisposalEntry) => {
            //establish the row key for uniqueness
            const rowKey: string = `${disposalEntry.disposalDate}|${disposalEntry.fixedAsset.ledgerAssetType.id}`;

            if (!rowMap.has(rowKey)) {
                rowMap.set(rowKey, new MainEntryRow(disposalEntry));
            } else {
                rowMap.get(rowKey).addEntry(disposalEntry);
            }
        });

        return Array.from(rowMap.values());
    };

    const mainDisposalDateColumnRenderer = (params: ICellRendererParams) => {
        return (
            <Link
                component="button"
                underline="none"
                onClick={() => handleDisposalDateLink(params)}>
                {dateFormatter(params.value)}
            </Link>
        );
    };

    const mainAssetTypeColumnRenderer = (params: ICellRendererParams) => {
        return (
            <Link
                component="button"
                underline="none"
                onClick={() => handleAssetTypeLink(params)}>
                {params.value}
            </Link>
        );
    };

    const mainAccountNumberColumnRenderer = (params: ICellRendererParams) => {
        const row: MainEntryRow = params.data;
        return (
            <div style={{ whiteSpace: 'pre-line' }}>
                {row.mainAccountNumberList ?? ''}
            </div>
        );
    };

    const mainAccountNameColumnRenderer = (params: ICellRendererParams) => {
        const row: MainEntryRow = params.data;
        return (
            <div style={{ whiteSpace: 'pre-line' }}>
                {row.mainAccountNameList ?? ''}
            </div>
        );
    };

    const mainDebitColumnRenderer = (params: ICellRendererParams) => {
        const row: MainEntryRow = params.data;
        return (
            <div style={{ whiteSpace: 'pre-line' }}>
                {row.mainDebitList ?? ''}
            </div>
        );
    };

    const mainCreditColumnRenderer = (params: ICellRendererParams) => {
        const row: MainEntryRow = params.data;
        return (
            <div style={{ whiteSpace: 'pre-line' }}>
                {row.mainCreditList ?? ''}
            </div>
        );
    };

    const detailAccountNumberColumnRenderer = (params: ICellRendererParams) => {
        const row: DetailEntryRow = params.data;
        return (
            <div style={{ whiteSpace: 'pre-line' }}>
                {row.detailAccountNumberList ?? ''}
            </div>
        );
    };

    const detailAccountNameColumnRenderer = (params: ICellRendererParams) => {
        const row: DetailEntryRow = params.data;
        return (
            <div style={{ whiteSpace: 'pre-line' }}>
                {row.detailAccountNameList ?? ''}
            </div>
        );
    };

    const detailDebitColumnRenderer = (params: ICellRendererParams) => {
        const row: DetailEntryRow = params.data;
        return (
            <div style={{ whiteSpace: 'pre-line' }}>
                {row.detailDebitList ?? ''}
            </div>
        );
    };

    const detailCreditColumnRenderer = (params: ICellRendererParams) => {
        const row: DetailEntryRow = params.data;
        return (
            <div style={{ whiteSpace: 'pre-line' }}>
                {row.detailCreditList ?? ''}
            </div>
        );
    };

    const onGridReady = (event: GridReadyEvent) => {
        setGridApi(event.api);
    };

    const handleActionsButton = () => {
        let selectedEntries: FA_AssetDisposalEntry[] = [];

        gridApi.getSelectedRows().map((row: MainEntryRow) => {
            row.disposalEntries.map((entry) => selectedEntries.push(entry));
        });

        setSelectedEntries(selectedEntries);
        setShowActionsModal(true);
    };

    const handleExportButton = () => {
        let exportedEntries: ExportEntryRow[] = [];

        gridApi.getSelectedRows().map((row: MainEntryRow) => {
            row.exportDisposalEntries.map((entry) =>
                exportedEntries.push(entry)
            );
        });

        setExportedEntries(exportedEntries);
        setShowExportModal(true);
    };

    const handleDisposalDateLink = (params: ICellRendererParams) => {
        params.api.deselectAll();
        const row: MainEntryRow = params.data;
        const filterInstance = params.api.getFilterInstance(
            'disposalEntry.disposalDate'
        );
        filterInstance.setModel({
            filterType: 'date',
            type: 'equals',
            dateFrom: row.disposalEntry.disposalDate,
        });
        params.api.onFilterChanged();
        setCurrentTitle(
            `Disposal Entries For ${dateFormatter(
                row.disposalEntry.disposalDate
            )}`
        );
        setCurrentGridMode(EntryGridMode.ByDate);
    };

    const handleAssetTypeLink = (params: ICellRendererParams) => {
        params.api.deselectAll();
        const row: MainEntryRow = params.data;
        const filterInstance = params.api.getFilterInstance(
            'ledgerAssetType.code'
        );
        filterInstance.setModel({
            filterType: 'text',
            type: 'equals',
            filter: row.ledgerAssetType.code,
        });
        params.api.onFilterChanged();
        setCurrentTitle(`Disposal Entries For ${row.ledgerAssetType.code}`);
        setCurrentGridMode(EntryGridMode.ByAssetType);
    };

    const handleReturnToMainGrid = () => {
        gridApi.setFilterModel(null);
        setCurrentTitle('Disposal Entries');
        setCurrentGridMode(EntryGridMode.Default);
    };

    const onSelectionChanged = (event: SelectionChangedEvent) => {
        const selectedRows = event.api.getSelectedRows() as MainEntryRow[];
        setHasSelectedRows(event.api.getSelectedRows().length > 0);
        setHasUnlockedSelected(
            selectedRows.length === 0 ||
                selectedRows.some((row) => row.hasUnlocked)
        );
    };

    useEffect(() => {
        setMainEntryRows(_createRowsFromList(assetDisposalEntryList));
    }, [assetDisposalEntryList]);

    return {
        showOptionsModal,
        setShowOptionsModal,
        showActionsModal,
        setShowActionsModal,
        showExportModal,
        setShowExportModal,
        setAssetDisposalEntryList,
        mainEntryRows,
        mainDisposalDateColumnRenderer,
        mainAssetTypeColumnRenderer,
        mainAccountNumberColumnRenderer,
        mainAccountNameColumnRenderer,
        mainDebitColumnRenderer,
        mainCreditColumnRenderer,
        detailAccountNumberColumnRenderer,
        detailAccountNameColumnRenderer,
        detailDebitColumnRenderer,
        detailCreditColumnRenderer,
        canViewDisposalEntries,
        canLockDisposalEntries,
        canDownloadDisposalEntries,
        handleActionsButton,
        handleExportButton,
        handleReturnToMainGrid,
        selectedEntries,
        exportedEntries,
        hasSelectedRows,
        hasUnlockedSelected,
        onSelectionChanged,
        onGridReady,
        currentGridMode,
        currentTitle,
        termSet,
    };
};

export default useFixedAssetDisposalEntriesGrid;
