import { useDispatch } from 'react-redux';
import { AlertColor } from '@mui/material';
import { MutationActionCreatorResult } from '@reduxjs/toolkit/dist/query/core/buildInitiate';
import { MutationError } from '../../../types/Shared.types';
import { openSnackbarBasicWithMessage } from '../../../store/uiElements';

export interface UseEditableBaseGridProps<T> {
    getDescription: () => string;
    createEntity?: (entities: T) => MutationActionCreatorResult<any>;
    updateEntity?: (entities: T) => MutationActionCreatorResult<any>;
    deleteEntity?: (entities: T) => MutationActionCreatorResult<any>;
}

const useEditableBaseGrid = <T,>(props: UseEditableBaseGridProps<T>) => {
    const dispatch = useDispatch();

    //region Create
    const onCreate = (entities: T) => {
        if (props.createEntity) {
            props
                .createEntity(entities)
                .unwrap()
                .then(() => _onSuccessfulCreate())
                .catch((error: MutationError) => handleError(error));
        }
    };

    const _onSuccessfulCreate = () => {
        showSuccessNotification(`${props.getDescription()} successfully added`);
    };
    //endregion

    //region Update
    const onUpdate = (entities: T) => {
        if (props.updateEntity) {
            props
                .updateEntity(entities)
                .unwrap()
                .then(() => _onSuccessfulUpdate())
                .catch((error: MutationError) => handleError(error));
        }
    };

    const _onSuccessfulUpdate = () => {
        showSuccessNotification(
            `${props.getDescription()} successfully updated`
        );
    };
    //endregion

    //region Delete
    const onDelete = (entities: T) => {
        if (props.deleteEntity) {
            props
                .deleteEntity(entities)
                .unwrap()
                .then(() => _onSuccessfulDelete())
                .catch((error: MutationError) => handleError(error));
        }
    };

    const _onSuccessfulDelete = () => {
        showSuccessNotification(
            `${props.getDescription()} successfully deleted`
        );
    };
    //endregion

    //region Notifications
    const showSuccessNotification = (message: string) => {
        _showNotification(message, 'success');
    };

    const showErrorNotification = (message: string) => {
        _showNotification(message, 'error');
    };

    const _showNotification = (message: string, severity: AlertColor) => {
        dispatch(
            openSnackbarBasicWithMessage({
                message: message,
                severity: severity,
            })
        );
    };
    //endregion

    //region Error Handling
    const handleError = (error: MutationError) => {
        if (error.status === 400) {
            const errorMessage = error.data
                .map((error: any) => {
                    return error.message;
                })
                .join('\r\n');
            showErrorNotification(errorMessage);
        } else {
            showErrorNotification(
                `An unexpected error occurred: ${error.data.toString()}`
            );
        }
    };
    //endregion

    return {
        onCreate,
        onUpdate,
        onDelete,
    };
};

export default useEditableBaseGrid;
