import { Grid } from '@mui/material';
import React from 'react';
import useSdsOtherInformationForm from './hooks/useSdsOtherInformationForm';
import DatacorLogoSpinner from '../../../../../../components/datacorLogoSpinner/DatacorLogoSpinner';
import { SdsHeader } from '../../../../../../types/formulation';
import TextInput from '../../../../../../components/form/formInputs/TextInput/TextInput';
import FormPartial from '../../../../../../components/form/formContainer/FormPartial';
import SubSection16_0Form from './subSections/SubSection16_0Form';
import SubSection16_1Form from './subSections/SubSection16_1Form';
import { SdsBlobSectionFormProps } from '../shared/blobs/hooks/SdsBlobSectionFormProps';
import CheckBoxInput from '../../../../../../components/form/formInputs/CheckBoxInput/CheckBoxInput';
import AutoCompleteInput from '../../../../../../components/form/formInputs/AutoCompleteInput/AutoCompleteInput';
import { SDSFormDefs } from '../../../../../../constants/i18n/translations/termDefinitions/formulation';
import TranslatableText from '../../../../../../components/i18n/TranslatableText';

const SdsOtherInformationForm = (props: SdsBlobSectionFormProps<SdsHeader>) => {
    const { parentData } = props;

    const { isParentLoading } = parentData;

    const { disabledField } = props.permissions;

    const {
        fields,
        handlePropertyChanged,
        dropdownOptions,
        formMethods,
        termSet,
        isLoadingHealthHazards,
        isLoadingPhysicalHazards,
        isLoadingEquipment,
        isLoadingReactivity,
        isLoadingFlammability,
        isLoadingSpecificHazards,
        specificHazardsList,
        flammabilityList,
        reactivityList,
        equipmentList,
        physicalHazardsList,
        healthHazardsList,
        setDropdownOptions,
    } = useSdsOtherInformationForm(props);

    return (
        <>
            {isParentLoading ||
            isLoadingHealthHazards ||
            isLoadingPhysicalHazards ||
            isLoadingEquipment ||
            isLoadingReactivity ||
            isLoadingFlammability ||
            isLoadingSpecificHazards ? (
                <DatacorLogoSpinner />
            ) : (
                <>
                    <FormPartial
                        link={props?.link}
                        title={
                            (
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={
                                        SDSFormDefs.Other_Info_Section_Title
                                    }
                                />
                            ) as unknown as string
                        }>
                        <FormPartial
                            title={
                                (
                                    <TranslatableText
                                        termSet={termSet}
                                        termKey={SDSFormDefs.Other_Info_HMIS}
                                    />
                                ) as unknown as string
                            }>
                            <Grid container spacing={2}>
                                <Grid item xs={2}>
                                    <CheckBoxInput
                                        id="hmisChronic"
                                        label={
                                            (
                                                <TranslatableText
                                                    termSet={props.termSet}
                                                    termKey={
                                                        SDSFormDefs.Other_Info_HMIS_Chronic
                                                    }
                                                />
                                            ) as unknown as string
                                        }
                                        checked={fields.hmisChronic}
                                        onChange={handlePropertyChanged}
                                        disabled={disabledField}
                                    />
                                </Grid>
                                <Grid item xs={5}>
                                    <AutoCompleteInput
                                        id="hmisHealthId"
                                        label={
                                            <TranslatableText
                                                termSet={termSet}
                                                termKey={
                                                    SDSFormDefs.Other_Info_HMIS_Health
                                                }
                                            />
                                        }
                                        onHandlePropertyChanged={
                                            handlePropertyChanged
                                        }
                                        options={healthHazardsList}
                                        setDropdownOptions={setDropdownOptions}
                                        value={
                                            dropdownOptions?.selectedHmisHealth
                                        }
                                        isLoading={isLoadingHealthHazards}
                                        formMethods={formMethods}
                                        required={false}
                                        disabled={disabledField}
                                        inputFieldName={'hmisHealth'}
                                        inputValue={dropdownOptions?.hmisHealth}
                                    />
                                </Grid>
                                <Grid item xs={5}>
                                    <AutoCompleteInput
                                        id="hmisFlammabilityId"
                                        label={
                                            <TranslatableText
                                                termSet={termSet}
                                                termKey={
                                                    SDSFormDefs.Other_Info_HMIS_Flammability
                                                }
                                            />
                                        }
                                        onHandlePropertyChanged={
                                            handlePropertyChanged
                                        }
                                        options={flammabilityList}
                                        setDropdownOptions={setDropdownOptions}
                                        value={
                                            dropdownOptions?.selectedHmisFlammability
                                        }
                                        isLoading={isLoadingFlammability}
                                        formMethods={formMethods}
                                        required={false}
                                        disabled={disabledField}
                                        inputFieldName={'hmisFlame'}
                                        inputValue={dropdownOptions?.hmisFlame}
                                    />
                                </Grid>
                                <Grid item xs={8}>
                                    <AutoCompleteInput
                                        id="hmisPhysicalHazardId"
                                        label={
                                            <TranslatableText
                                                termSet={termSet}
                                                termKey={
                                                    SDSFormDefs.Other_Info_Physical_Hazard
                                                }
                                            />
                                        }
                                        onHandlePropertyChanged={
                                            handlePropertyChanged
                                        }
                                        options={physicalHazardsList}
                                        setDropdownOptions={setDropdownOptions}
                                        value={
                                            dropdownOptions?.selectedHmisPhysicalHazard
                                        }
                                        isLoading={isLoadingPhysicalHazards}
                                        formMethods={formMethods}
                                        required={false}
                                        disabled={disabledField}
                                        inputFieldName={'hmisPhysicalHazard'}
                                        inputValue={
                                            dropdownOptions?.hmisPhysicalHazard
                                        }
                                    />
                                </Grid>
                                <Grid item xs={8}>
                                    <AutoCompleteInput
                                        id="hmisEquipmentId"
                                        label={
                                            <TranslatableText
                                                termSet={termSet}
                                                termKey={
                                                    SDSFormDefs.Other_Info_PPE
                                                }
                                            />
                                        }
                                        onHandlePropertyChanged={
                                            handlePropertyChanged
                                        }
                                        options={equipmentList}
                                        setDropdownOptions={setDropdownOptions}
                                        value={
                                            dropdownOptions?.selectedHmisEquipment
                                        }
                                        isLoading={isLoadingEquipment}
                                        formMethods={formMethods}
                                        required={false}
                                        disabled={disabledField}
                                        inputFieldName={'hmisPPE'}
                                        inputValue={dropdownOptions?.hmisPPE}
                                    />
                                </Grid>
                            </Grid>
                        </FormPartial>
                        <FormPartial
                            title={
                                (
                                    <TranslatableText
                                        termSet={termSet}
                                        termKey={SDSFormDefs.Other_Info_NFPA}
                                    />
                                ) as unknown as string
                            }>
                            <Grid container spacing={2}>
                                <Grid item xs={5}>
                                    <AutoCompleteInput
                                        id="nfpaHealthId"
                                        label={
                                            <TranslatableText
                                                termSet={termSet}
                                                termKey={
                                                    SDSFormDefs.Other_Info_NFPA_Health
                                                }
                                            />
                                        }
                                        onHandlePropertyChanged={
                                            handlePropertyChanged
                                        }
                                        options={healthHazardsList}
                                        setDropdownOptions={setDropdownOptions}
                                        value={
                                            dropdownOptions?.selectedNfpaHealth
                                        }
                                        isLoading={isLoadingHealthHazards}
                                        formMethods={formMethods}
                                        required={false}
                                        disabled={disabledField}
                                        inputFieldName={'nfpaHealth'}
                                        inputValue={dropdownOptions?.nfpaHealth}
                                    />
                                </Grid>
                                <Grid item xs={5}>
                                    <AutoCompleteInput
                                        id="nfpaFlammabilityId"
                                        label={
                                            <TranslatableText
                                                termSet={termSet}
                                                termKey={
                                                    SDSFormDefs.Other_Info_NFPA_Flammability
                                                }
                                            />
                                        }
                                        onHandlePropertyChanged={
                                            handlePropertyChanged
                                        }
                                        options={flammabilityList}
                                        setDropdownOptions={setDropdownOptions}
                                        value={
                                            dropdownOptions?.selectedNfpaFlammability
                                        }
                                        isLoading={isLoadingFlammability}
                                        formMethods={formMethods}
                                        required={false}
                                        disabled={disabledField}
                                        inputFieldName={'nfpaFlame'}
                                        inputValue={dropdownOptions?.nfpaFlame}
                                    />
                                </Grid>
                                <Grid item xs={5}>
                                    <AutoCompleteInput
                                        id="nfpaReactivityId"
                                        label={
                                            <TranslatableText
                                                termSet={termSet}
                                                termKey={
                                                    SDSFormDefs.Other_Info_Reactivity
                                                }
                                            />
                                        }
                                        onHandlePropertyChanged={
                                            handlePropertyChanged
                                        }
                                        options={reactivityList}
                                        setDropdownOptions={setDropdownOptions}
                                        value={
                                            dropdownOptions?.selectedNfpaReactivity
                                        }
                                        isLoading={isLoadingReactivity}
                                        formMethods={formMethods}
                                        required={false}
                                        disabled={disabledField}
                                        inputFieldName={'nfpaReactivity'}
                                        inputValue={
                                            dropdownOptions?.nfpaReactivity
                                        }
                                    />
                                </Grid>
                                <Grid item xs={5}>
                                    <AutoCompleteInput
                                        id="nfpaSpecificHazardId"
                                        label={
                                            <TranslatableText
                                                termSet={termSet}
                                                termKey={
                                                    SDSFormDefs.Other_Info_Specific_Hazard
                                                }
                                            />
                                        }
                                        onHandlePropertyChanged={
                                            handlePropertyChanged
                                        }
                                        options={specificHazardsList}
                                        setDropdownOptions={setDropdownOptions}
                                        value={
                                            dropdownOptions?.selectedNfpaSpecificHazard
                                        }
                                        isLoading={isLoadingSpecificHazards}
                                        formMethods={formMethods}
                                        required={false}
                                        disabled={disabledField}
                                        inputFieldName={'nfpaSpecificHazard'}
                                        inputValue={
                                            dropdownOptions?.nfpaSpecificHazard
                                        }
                                    />
                                </Grid>
                            </Grid>
                        </FormPartial>

                        <Grid>
                            <SubSection16_0Form {...props} termSet={termSet} />
                        </Grid>

                        <FormPartial
                            title={
                                (
                                    <TranslatableText
                                        termSet={termSet}
                                        termKey={
                                            SDSFormDefs.Other_Info_Additional_Disclaimer
                                        }
                                    />
                                ) as unknown as string
                            }>
                            <Grid item xs={6}>
                                <TextInput
                                    fullWidth
                                    id="webAddress"
                                    value={fields.webAddress}
                                    label={
                                        <TranslatableText
                                            termSet={termSet}
                                            termKey={
                                                SDSFormDefs.Other_Info_Web_Address
                                            }
                                        />
                                    }
                                    maxLength={100}
                                    formMethods={formMethods}
                                    required={false}
                                    onChange={handlePropertyChanged}
                                    disabled={disabledField}
                                />
                            </Grid>
                            <br />
                            <Grid>
                                <SubSection16_1Form
                                    {...props}
                                    termSet={termSet}
                                />
                            </Grid>
                        </FormPartial>
                    </FormPartial>
                </>
            )}
        </>
    );
};
export default SdsOtherInformationForm;
