import useFixedAssetDepreciationEntriesOptionsModal from './hooks/useFixedAssetDepreciationEntriesOptionsModal';
import {
    Box,
    Dialog,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import React from 'react';
import { DialogProps } from '@mui/material/Dialog/Dialog';
import DateInput from '../../../components/form/formInputs/DateInput/DateInput';
import { LoadingButton } from '@mui/lab';
import MultiSelectInput from '../../../components/form/formInputs/MultiSelectInput/MutliSelectInput';
import { FA_DepreciationEntry } from '../../../types/FixedAsset.types';
import { DepreciationEntriesGridDefs } from '../../../constants/i18n/translations/termDefinitions/accounting';
import TranslatableText from '../../../components/i18n/TranslatableText';
import { validateDateRange } from '../../../utils/comparisonUtils';

type FixedAssetDepreciationEntriesOptionsProps = Omit<
    DialogProps,
    'onClose'
> & {
    onEntriesLoaded: (entries: FA_DepreciationEntry[]) => void;
    onClose: () => void;
};

const FixedAssetDepreciationEntriesOptionsModal = ({
    onEntriesLoaded,
    onClose,
    ...props
}: FixedAssetDepreciationEntriesOptionsProps) => {
    const {
        fields,
        handleFieldChange,
        methodTypeOptions,
        loadingMethodTypes,
        assetTypeOptions,
        loadingAssetTypes,
        handleGetEntries,
        isLoadingEntries,
        termSet,
    } = useFixedAssetDepreciationEntriesOptionsModal(onEntriesLoaded, onClose);

    return (
        <Dialog {...props} fullWidth maxWidth={'sm'}>
            <DialogTitle>
                <Box display="flex" alignItems="center">
                    <Box flexGrow={1}>
                        <TranslatableText
                            termSet={termSet}
                            termKey={DepreciationEntriesGridDefs.Options_Title}
                        />
                    </Box>
                    <Box>
                        <IconButton onClick={onClose}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </Box>
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <MultiSelectInput
                            label={
                                (
                                    <TranslatableText
                                        termSet={termSet}
                                        termKey={
                                            DepreciationEntriesGridDefs.Options_Depreciation_Method_Type
                                        }
                                    />
                                ) as unknown as string
                            }
                            id="methodTypes"
                            value={fields.methodTypes}
                            onChange={handleFieldChange}
                            disabled={loadingMethodTypes}
                            options={methodTypeOptions}
                            disableCloseOnSelect={true}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <DateInput
                            label={
                                (
                                    <TranslatableText
                                        termSet={termSet}
                                        termKey={
                                            DepreciationEntriesGridDefs.Options_Interval_Date_Begin
                                        }
                                    />
                                ) as unknown as string
                            }
                            id="startDate"
                            value={fields.startDate}
                            onChange={handleFieldChange}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <DateInput
                            label={
                                (
                                    <TranslatableText
                                        termSet={termSet}
                                        termKey={
                                            DepreciationEntriesGridDefs.Options_Interval_Date_End
                                        }
                                    />
                                ) as unknown as string
                            }
                            id="endDate"
                            value={fields.endDate}
                            onChange={handleFieldChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <MultiSelectInput
                            label={
                                (
                                    <TranslatableText
                                        termSet={termSet}
                                        termKey={
                                            DepreciationEntriesGridDefs.Options_Asset_Type
                                        }
                                    />
                                ) as unknown as string
                            }
                            id="assetTypes"
                            value={fields.assetTypes}
                            onChange={handleFieldChange}
                            disabled={loadingAssetTypes}
                            options={assetTypeOptions}
                            disableCloseOnSelect={true}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <LoadingButton
                            fullWidth
                            variant="contained"
                            type="submit"
                            disabled={
                                !validateDateRange(
                                    fields.startDate,
                                    fields.endDate
                                )
                            }
                            loading={isLoadingEntries}
                            onClick={handleGetEntries}>
                            <TranslatableText
                                termSet={termSet}
                                termKey={
                                    DepreciationEntriesGridDefs.Options_Get_Depreciation_Entries
                                }
                            />
                        </LoadingButton>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
};

export default FixedAssetDepreciationEntriesOptionsModal;
