import useFixedAssetSchedulesOptionsModal from './hooks/useFixedAssetSchedulesOptionsModal';
import {
    Box,
    Dialog,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import React from 'react';
import { DialogProps } from '@mui/material/Dialog/Dialog';
import DateInput from '../../../components/form/formInputs/DateInput/DateInput';
import { LoadingButton } from '@mui/lab';
import MultiSelectInput from '../../../components/form/formInputs/MultiSelectInput/MutliSelectInput';
import { FA_DepreciationSchedule } from '../../../types/FixedAsset.types';
import { FixedAssetScheduleGridDefs } from '../../../constants/i18n/translations/termDefinitions/accounting';
import TranslatableText from '../../../components/i18n/TranslatableText';
import { validateDateRange } from '../../../utils/comparisonUtils';

type FixedAssetSchedulesOptionsProps = Omit<DialogProps, 'onClose'> & {
    onSchedulesLoaded: (schedules: FA_DepreciationSchedule[]) => void;
    onClose: () => void;
};

const FixedAssetSchedulesOptionsModal = ({
    onSchedulesLoaded,
    onClose,
    ...props
}: FixedAssetSchedulesOptionsProps) => {
    const {
        fields,
        handleFieldChange,
        methodTypeOptions,
        loadingMethodTypes,
        assetTypeOptions,
        loadingAssetTypes,
        handleGetSchedules,
        isLoadingSchedules,
        termSet,
    } = useFixedAssetSchedulesOptionsModal(onSchedulesLoaded, onClose);

    return (
        <Dialog {...props} fullWidth maxWidth={'sm'}>
            <DialogTitle>
                <Box display="flex" alignItems="center">
                    <Box flexGrow={1}>
                        <TranslatableText
                            termSet={termSet}
                            termKey={FixedAssetScheduleGridDefs.Title}
                        />
                    </Box>
                    <Box>
                        <IconButton onClick={onClose}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </Box>
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <MultiSelectInput
                            label={
                                (
                                    <TranslatableText
                                        termSet={termSet}
                                        termKey={
                                            FixedAssetScheduleGridDefs.PopUpDepreciationMethodTypes
                                        }
                                    />
                                ) as unknown as string
                            }
                            id="methodTypes"
                            value={fields.methodTypes}
                            onChange={handleFieldChange}
                            disabled={loadingMethodTypes}
                            options={methodTypeOptions}
                            disableCloseOnSelect={true}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <DateInput
                            label={
                                (
                                    <TranslatableText
                                        termSet={termSet}
                                        termKey={
                                            FixedAssetScheduleGridDefs.IntervalDateBegin
                                        }
                                    />
                                ) as unknown as string
                            }
                            id="startDate"
                            value={fields.startDate}
                            onChange={handleFieldChange}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <DateInput
                            label={
                                (
                                    <TranslatableText
                                        termSet={termSet}
                                        termKey={
                                            FixedAssetScheduleGridDefs.IntervalDateEnd
                                        }
                                    />
                                ) as unknown as string
                            }
                            id="endDate"
                            value={fields.endDate}
                            onChange={handleFieldChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <MultiSelectInput
                            label={
                                (
                                    <TranslatableText
                                        termSet={termSet}
                                        termKey={
                                            FixedAssetScheduleGridDefs.AssetTypes
                                        }
                                    />
                                ) as unknown as string
                            }
                            id="assetTypes"
                            value={fields.assetTypes}
                            onChange={handleFieldChange}
                            disabled={loadingAssetTypes}
                            options={assetTypeOptions}
                            disableCloseOnSelect={true}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <LoadingButton
                            fullWidth
                            variant="contained"
                            type="submit"
                            disabled={
                                !validateDateRange(
                                    fields.startDate,
                                    fields.endDate
                                )
                            }
                            loading={isLoadingSchedules}
                            onClick={handleGetSchedules}>
                            <TranslatableText
                                termSet={termSet}
                                termKey={
                                    FixedAssetScheduleGridDefs.GetSchedules
                                }
                            />
                        </LoadingButton>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
};

export default FixedAssetSchedulesOptionsModal;
