import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { onError } from '../../../libs/errorLib';
import { dateTimeFormatter } from '../../../utils/formattingUtils';
import { SystemActionsService } from '../../../services/workflow/system/systemActions.service';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import LoaderButton from '../../../components/LoaderButton';
import { Button, Card, CardHeader, CardContent, Grid } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import ListItemButton from '@mui/material/ListItemButton';

const SystemAction = () => {
    const [systemAction, setSystemAction] = useState(null);
    const [runResult, setRunResult] = useState(null);
    const navigate = useNavigate();
    const { id } = useParams();

    useEffect(() => {
        const onLoad = async () => {
            loadData();
        };

        onLoad();
    }, []);

    const loadData = async () => {
        try {
            setSystemAction(await SystemActionsService.getSystemActionFull(id));
        } catch (e) {
            onError(e);
        }
    };

    const handleRun = async (event) => {
        event.bubbles = false;
        event.preventDefault();

        if (systemAction == null) {
            return;
        }

        try {
            setRunResult(
                await SystemActionsService.runSystemAction(systemAction.id)
            );
        } catch (e) {
            return onError(e.message);
        }
    };

    const headerArea = () => {
        return (
            <>
                <h1>
                    System Action: {systemAction?.name}
                    &nbsp;
                    <Link to="/workflow/">
                        <LoaderButton size="lg">Workflow Portal</LoaderButton>
                    </Link>
                </h1>
            </>
        );
    };

    const systemActionArea = () => {
        return (
            <List dense={true}>
                <ListItem>
                    <ListItemText
                        primary={'Name: ' + systemAction?.name}
                        secondary="The human-readable name for this system action."
                    />
                </ListItem>
                <ListItem>
                    <ListItemText
                        primary={'Description: ' + systemAction?.description}
                        secondary="Descriptive text about this system action."
                    />
                </ListItem>
                <ListItem>
                    <Grid container spacing={2}>
                        <Grid item xs={7}>
                            <ListItemText
                                primary={
                                    'System Workflow: ' +
                                    systemAction?.SystemWorkflow?.name
                                }
                                secondary="The system workflow to which this system action
                            belongs."
                            />
                        </Grid>
                        <Grid item xs={5}>
                            <ListItemButton dense>
                                <Link
                                    to={`/workflow/systemWorkflows/${systemAction?.SystemWorkflow?.id}`}>
                                    <Button
                                        disableElevation
                                        disableRipple
                                        size="small"
                                        sx={{
                                            ml: 1,
                                            '&.MuiButtonBase-root:hover': {
                                                bgcolor: 'transparent',
                                            },
                                        }}>
                                        Go To System Workflow
                                    </Button>
                                </Link>
                            </ListItemButton>
                        </Grid>
                    </Grid>
                </ListItem>
                <ListItem>
                    <Grid container spacing={2}>
                        <Grid item xs={7}>
                            <ListItemText
                                primary={
                                    'App: ' +
                                    systemAction?.SystemWorkflow?.App?.name
                                }
                                secondary="The app to which this system action belongs."
                            />
                        </Grid>
                        <Grid item xs={5}>
                            <ListItemButton dense>
                                <Link
                                    to={`/workflow/apps/${systemAction?.SystemWorkflow?.App?.id}`}>
                                    <Button
                                        disableElevation
                                        disableRipple
                                        size="small"
                                        sx={{
                                            ml: 1,
                                            '&.MuiButtonBase-root:hover': {
                                                bgcolor: 'transparent',
                                            },
                                        }}>
                                        Go To System Workflow
                                    </Button>
                                </Link>
                            </ListItemButton>
                        </Grid>
                    </Grid>
                </ListItem>
                <ListSubheader>Activity</ListSubheader>
                <ListItem>
                    <ListItemText
                        primary={
                            'Updated: ' +
                            dateTimeFormatter(systemAction?.updatedAt)
                        }></ListItemText>
                </ListItem>
                <ListItem>
                    <ListItemText
                        primary={
                            'Created: ' +
                            dateTimeFormatter(systemAction?.createdAt)
                        }></ListItemText>
                </ListItem>
            </List>
        );
    };

    const apiArea = () => {
        return (
            <List dense={true}>
                <ListItem>
                    <ListItemText
                        primary={'API Name: ' + systemAction?.ApiCall?.name}
                        secondary="The name of the API call related to this system
                            action."
                    />
                </ListItem>
                <ListItem>
                    <ListItemText
                        primary={
                            'Include Auth Header: ' +
                            systemAction?.ApiCall?.includeAuthHeader
                                ? 'The JWT will be included'
                                : 'The JWT will NOT be included'
                        }
                        secondary="Will the JWT be added to the authorization custom
                            header."
                    />
                </ListItem>
                <ListItem>
                    <ListItemText
                        primary={'URL: ' + systemAction?.ApiCall?.url}
                        secondary="The API URL or relative path."
                    />
                </ListItem>
                <ListItem>
                    <ListItemText
                        primary={
                            'Method: ' +
                            systemAction?.ApiCall?.ApiMethodType?.name
                        }
                        secondary="The HTTP method used for this API Call."
                    />
                </ListItem>
            </List>
        );
    };

    const parametersArea = () => {
        return (
            <>
                <div className="ag-theme-alpine">
                    <AgGridReact
                        rowHeight="35"
                        rowData={systemAction?.ApiCall?.ApiParameters}
                        domLayout={'autoHeight'}
                        suppressCellSelection={true}>
                        <AgGridColumn field="name" flex={1} />
                        <AgGridColumn
                            field="ApiParameterType.name"
                            headerName="Type"
                        />
                        <AgGridColumn field="key" />
                        <AgGridColumn field="value" flex={1} />
                    </AgGridReact>
                </div>
            </>
        );
    };

    const testArea = () => {
        return (
            <>
                <p>
                    <em>Run a test of this system action.</em>
                </p>
                <p className="mb-1">
                    <Button size="lg" onClick={($event) => handleRun($event)}>
                        Run Action
                    </Button>
                </p>
                <div className="mt-3">
                    <p>{runResult ? JSON.stringify(runResult) : ''}</p>
                </div>
            </>
        );
    };

    return (
        <Card>
            <CardHeader>System Action: {systemAction?.name}</CardHeader>
            <CardContent>
                <Grid container spacing={2}>
                    <Grid item xs={4}>
                        <Card>
                            <CardHeader title="Configuration"></CardHeader>
                            <CardContent>{systemActionArea()}</CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={4}>
                        <Card>
                            <CardHeader title="Run"></CardHeader>
                            <CardContent>{testArea()}</CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={4}>
                        <Card>
                            <CardHeader title="API Call"></CardHeader>
                            <CardContent>{apiArea()}</CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12}>
                        <Card>
                            <CardHeader title="API Parameters"></CardHeader>
                            <CardContent>{parametersArea()}</CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

export default SystemAction;
