import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    useGetAvailableFeatureFlagsQuery,
    useUpdateCompanyFeatureFlagsMutation,
} from '../../../services/featureFlag/featureFlag.service';
import { PermissionsUtil } from '../../../utils/permissions/permissionsUtil';
import { PERMISSIONS } from '../../../constants/permissions/Permissions.constants';
import { isNilOrEmpty } from '../../../utils/objectUtils';
import { FeatureFlag, Node } from '../../../types/Settings.types';
// @ts-ignore
import { buildNodeFromPath } from '../../../components/treeView/Utils';
import * as _ from 'lodash';
import { RootState } from '../../../store';
import { openSnackbarBasicWithMessage } from '../../../store/uiElements';

const useFeatureFlags = () => {
    const dispatch = useDispatch();
    const user = useSelector((state: RootState) => state.user);
    const { data: availableFeatureFlags, isLoading } =
        useGetAvailableFeatureFlagsQuery();
    const [updateCompanyFeatureFlags] = useUpdateCompanyFeatureFlagsMutation();
    const [treeData, setTreeData] = useState([]);
    const [updatedFeatures, setUpdatedFeatures] = useState([]);
    const [isUnchanged, setIsUnchanged] = useState(true);
    const canEditFeatureFlags = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.SETTINGS.FEATURE_FLAGS.EDIT
    );

    useEffect(() => {
        if (!isLoading && !isNilOrEmpty(availableFeatureFlags)) {
            buildNodes();
        }
    }, [isLoading]);

    const buildNodes = () => {
        const newTreeNodes: Array<any> = [];
        setUpdatedFeatures([]);

        availableFeatureFlags.forEach((featureFlag: FeatureFlag) => {
            const flagNode: Node = buildNodeFromPath(
                newTreeNodes,
                featureFlag.path
            );
            const isEnabled = user.enabledFeatureFlags.includes(
                featureFlag.flagKey
            );

            flagNode.children.push({
                label: featureFlag.description,
                value: featureFlag.flagKey,
                disabled: !canEditFeatureFlags,
                checked: isEnabled,
                children: [],
            });
        });

        setTreeData(_.orderBy(newTreeNodes, ['label'], ['asc']));
    };

    const onCheckChange = (node: Node) => {
        if (_.some(updatedFeatures, (flag) => flag.flagKey === node.value)) {
            _.remove(updatedFeatures, (flag) => flag.flagKey === node.value);
        } else {
            updatedFeatures.push({
                flagKey: node.value,
                enabled: node.checked,
            });
        }

        setIsUnchanged(isNilOrEmpty(updatedFeatures));
    };

    const updateFeatureFlags = async () => {
        updateCompanyFeatureFlags(updatedFeatures);

        dispatch(
            openSnackbarBasicWithMessage({
                message: `You'll need to log out and log in again for these changes to take effect.`,
            })
        );

        setUpdatedFeatures([]);
        setIsUnchanged(true);
    };

    return {
        isLoading,
        treeData,
        onCheckChange,
        canEditFeatureFlags,
        updateFeatureFlags,
        isUnchanged,
    };
};

export default useFeatureFlags;
