import React, { ChangeEvent, useEffect, useState } from 'react';
import InputBase, { InputBaseProps } from '../InputBase/InputBase';
import {
    Checkbox,
    ListItem,
    ListItemIcon,
    ListItemText,
    MenuItem,
} from '@mui/material';
import { isNilOrEmpty } from '../../../../utils/objectUtils';
import { SelectionOption } from '../../../../types/Shared.types';
import InputAdornment from '@mui/material/InputAdornment';
import Clear from '@mui/icons-material/Clear';
import EditIcon from '@mui/icons-material/Edit';

export type SelectInputProps = {
    options: SelectionOption[];
    showEditButton?: boolean;
    handleEdit?: (_: any, __: any) => void;
    disabledOptions?: number[] | bigint[];
    SelectProps?: {
        multiple?: boolean;
        showClearButton?: boolean;
        readOnly?: boolean;
        renderValue?: any;
    };
} & InputBaseProps;

const SelectInput = (props: SelectInputProps) => {
    const [value, setValue] = useState(props.SelectProps?.multiple ? [] : '');

    useEffect(() => {
        if (!isNilOrEmpty(props.options) && props.value != null) {
            setValue(props.value as any);
        }
    }, [props.value, props.options]);

    const optionSelected = (selectedOption: any) => {
        setValue(selectedOption.target.value);

        const changEvent = {
            target: { id: props.id, value: selectedOption.target.value },
        } as unknown as ChangeEvent<HTMLInputElement>;

        props.onChange(changEvent);
    };

    const getNullStateValue = (): any => {
        if (props.SelectProps?.multiple) {
            return [];
        }

        return '';
    };

    const clearSelectedValue = () => {
        setValue(props.SelectProps?.multiple ? [] : null);
        optionSelected({
            target: {
                value: props.SelectProps?.multiple ? [] : null,
            },
        });
    };

    const getClearButton = () => {
        if (props.SelectProps?.showClearButton && !isNilOrEmpty(value)) {
            return (
                <InputAdornment
                    position="end"
                    sx={{ marginRight: '10px', cursor: 'pointer' }}
                    onClick={() => clearSelectedValue()}>
                    <Clear />
                </InputAdornment>
            );
        }
        return <></>;
    };

    return (
        <InputBase
            {...props}
            select
            InputProps={{
                endAdornment: getClearButton(),
            }}
            value={value || getNullStateValue()}
            onChange={(selectedOption) => optionSelected(selectedOption)}>
            {!isNilOrEmpty(props.options) ? (
                props.options?.map((option: SelectionOption) => (
                    <MenuItem
                        key={option.value}
                        value={option.value}
                        disabled={props.disabledOptions?.some(
                            (disabledOption) => disabledOption === option.value
                        )}>
                        {props.SelectProps?.multiple ? (
                            <Checkbox
                                sx={{ paddingTop: 0, paddingBottom: 0 }}
                                size={'small'}
                                checked={value.indexOf(option.value) > -1}
                                disabled={props.disabledOptions?.some(
                                    (disabledOption) =>
                                        disabledOption === option.value
                                )}
                            />
                        ) : null}
                        {props.showEditButton ? (
                            <ListItem dense>
                                <ListItemText primary={option.label} />
                                <ListItemIcon
                                    onClick={(event: any) =>
                                        props.handleEdit(event, option.value)
                                    }>
                                    <EditIcon />
                                </ListItemIcon>
                            </ListItem>
                        ) : (
                            option.label
                        )}
                    </MenuItem>
                ))
            ) : (
                <MenuItem key="placeholder" value="" />
            )}
        </InputBase>
    );
};

export default SelectInput;
