import { useNavigate } from 'react-router-dom';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import {
    useListPeriodQuartersQuery,
    useUpdateListPeriodQuartersMutation,
} from '../../../../../services/accounting/accounting.service';
import { PERMISSIONS } from '../../../../../constants/permissions/Permissions.constants';
import { PermissionsUtil } from '../../../../../utils/permissions/permissionsUtil';
import { ACCOUNTING_DEFS } from '../../../../../constants/i18n/translations/termSetDefinitions/accounting';
import useTranslation from '../../../../../components/i18n/hooks/useTranslation';
import { PeriodQuarter } from '../../../../../types/Accounting.types';
import { RoutingValues } from '../../../../../types/Shared.types';
import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { getMaxSortOrderValue } from '../../../../../store/accounting';
import { auditableGridColumns } from '../../../../grid/utils/auditableGrid/auditableGridColumns';
import { ColDef } from 'ag-grid-community';
import CustomHeader from '../../../../../components/grids/CustomHeader';
import { PeriodQuartersGridDefs } from '../../../../../constants/i18n/translations/termDefinitions/accounting';
import DefaultColumnTypes from '../../../../../components/grids/columns/Column.constants';

const usePeriodQuartersGrid = () => {
    const navigate = useNavigate();

    const dispatch = useDispatch();

    const user = useSelector((state: RootStateOrAny) => state.user);

    const displayCreateNewButton = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.ACCOUNTING.PERIOD_QUARTER.CREATE
    );

    const canUpdatePeriodQuarters = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.ACCOUNTING.PERIOD_QUARTER.EDIT
    );

    const canViewPeriodQuarters = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.ACCOUNTING.PERIOD_QUARTER.VIEW
    );

    const { termSet, isLoadingTermSet } = useTranslation(
        ACCOUNTING_DEFS.PERIOD_QUARTERS_GRID
    );

    const { data: periodQuartersList, isLoading: isPeriodQuartersListLoading } =
        useListPeriodQuartersQuery();

    const [updatePeriodQuartersList] = useUpdateListPeriodQuartersMutation();

    const [hiddenIdColumns, auditableColumns] = auditableGridColumns();

    const [colDefs, setColDefs] = useState<ColDef[]>([]);

    const redirectToForm = (data: PeriodQuarter) => {
        if (data && data?.id) {
            navigate(`/accounting/setup/periodQuarters/${data.id}`);
        } else {
            navigate(`/accounting/setup/periodQuarters/${RoutingValues.newId}`);
        }
    };

    const handleGridEdits = (editedRows: PeriodQuarter[]) => {
        updatePeriodQuartersList(editedRows);
    };

    useEffect(() => {
        if (!isPeriodQuartersListLoading) {
            let maxValue = 1;

            if (periodQuartersList && periodQuartersList.length > 0) {
                const data = _.maxBy(
                    periodQuartersList,
                    (periodQuarter: PeriodQuarter) => periodQuarter.sortOrder
                );
                maxValue = data.sortOrder + 1;
            }

            dispatch(
                getMaxSortOrderValue({ isLoading: false, maxValue: maxValue })
            );
        }
    }, [isPeriodQuartersListLoading, periodQuartersList]);

    useEffect(() => {
        if (!isLoadingTermSet && termSet) {
            const columnDefs: ColDef[] = [
                ...hiddenIdColumns,
                {
                    field: 'code',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={
                                    PeriodQuartersGridDefs.Period_Quarter_Code
                                }
                            />
                        );
                    },
                    ...DefaultColumnTypes.MediumText,
                },
                {
                    field: 'description',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={PeriodQuartersGridDefs.Description}
                            />
                        );
                    },
                    ...DefaultColumnTypes.LongText,
                },
                ...auditableColumns,
            ];

            setColDefs(columnDefs);
        }
    }, [isLoadingTermSet, termSet]);

    return {
        periodQuartersList,
        termSet,
        isLoading: isPeriodQuartersListLoading || isLoadingTermSet,
        /** All display/permissions **/
        permissions: {
            displayCreateNewButton,
            canUpdate: canUpdatePeriodQuarters,
            canView: canViewPeriodQuarters,
        },
        colDefs,
        redirectToForm,
        handleGridEdits,
    };
};

export default usePeriodQuartersGrid;
