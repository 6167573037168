import React, { useEffect, useState } from 'react';
import { skipToken } from '@reduxjs/toolkit/query';
import {
    useCreateTypeMutation,
    useDeleteTypeMutation,
    useGetDepreciationMethodsForDropdownQuery,
    useGetTypeQuery,
    useUpdateTypeMutation,
} from '../../../services/fixedAssets/fixedAssets.service';
import {
    FA_DepreciationMethod,
    FA_AssetType,
} from '../../../types/FixedAsset.types';
import { RoutingValues, SelectionOption } from '../../../types/Shared.types';
import {
    useGetAllGeneralLedgerAccountFunctionsForDropdownsQuery,
    useGetRecoveryUnitsForDropdownQuery,
} from '../../../services/accounting/accounting.service';
import useBaseForm from '../../form/hooks/useBaseForm';
import { PermissionsUtil } from '../../../utils/permissions/permissionsUtil';
import { PERMISSIONS } from '../../../constants/permissions/Permissions.constants';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import useTranslation from '../../../components/i18n/hooks/useTranslation';
import { ACCOUNTING_DEFS } from '../../../constants/i18n/translations/termSetDefinitions/accounting';

const UseFixedAssetTypeForm = (id: string) => {
    const user = useSelector((state: RootState) => state.user);
    const assetType = useSelector((state: RootState) => state.fixedAssets);
    const [accountsRequired, setAccountsRequired] = useState<boolean>(false);
    const [showAccountsPanel, setShowAccountsPanel] = useState<boolean>(false);

    const { data: activeType, isLoading: isLoadingType } = useGetTypeQuery(
        id === RoutingValues.newId ? skipToken : id
    );
    const [createType] = useCreateTypeMutation();
    const [updateType] = useUpdateTypeMutation();
    const [deleteType] = useDeleteTypeMutation();

    const {
        data: depreciationMethodOptions,
        isLoading: isLoadingDepreciationMethods,
    } = useGetDepreciationMethodsForDropdownQuery();

    const { data: accountDropdowns, isLoading: isLoadingAccountDropdowns } =
        useGetAllGeneralLedgerAccountFunctionsForDropdownsQuery();

    const canCreateAssetType = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.FIXED_ASSETS.ASSET_TYPES.CREATE
    );
    const canUpdateAssetType = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.FIXED_ASSETS.ASSET_TYPES.EDIT
    );
    const canDeleteAssetType = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.FIXED_ASSETS.ASSET_TYPES.DELETE
    );

    const { data: recoveryUnitOptions, isLoading: isLoadingRecoveryUnits } =
        useGetRecoveryUnitsForDropdownQuery();

    const blankType: FA_AssetType = {
        sortOrder: assetType.sortOrderSet.maxValue,
        code: '',
        description: '',
        recoveryTerm: 1,
        recoveryUnitId: null,
        depreciationMethodId: null,
        depreciationExpenseAccountId: null,
        accumulatedDeprecationAccountId: null,
        assetAccountId: null,
        assetControlAccountId: null,
        disposalGainAccountId: null,
        disposalLossAccountId: null,
        disposalSettlementAccountId: null,
    };

    const { termSet } = useTranslation(ACCOUNTING_DEFS.ASSET_TYPES_FORM);

    useEffect(() => {
        setAccountsRequired(
            !!(activeType as FA_AssetType)?.depreciationMethod
                ?.depreciationMethodType?.postsJournalEntries
        );
        if (
            (activeType as FA_AssetType)?.depreciationMethod
                ?.depreciationMethodType?.predefinedSchedule
        ) {
            setValues({
                depreciationExpenseAccountId: null,
                accumulatedDeprecationAccountId: null,
                assetAccountId: null,
                assetControlAccountId: null,
                disposalGainAccountId: null,
                disposalLossAccountId: null,
                disposalSettlementAccountId: null,
            });
        }
    }, [activeType]);

    useEffect(() => {
        setShowAccountsPanel(accountsRequired);
    }, [accountsRequired]);

    const onDepreciationMethodChanged = (event: any) => {
        handleFieldChange(event);

        const depreciationMethod: FA_DepreciationMethod =
            depreciationMethodOptions?.find(
                (optionList: SelectionOption) =>
                    optionList.value === event.target.value
            )?.object;

        setAccountsRequired(
            !!depreciationMethod?.depreciationMethodType?.postsJournalEntries
        );
    };

    const {
        fields,
        handleFieldChange,
        formMethods,
        setValues,
        onCreate,
        onUpdate,
        onDelete,
        closeForm,
    } = useBaseForm({
        closePath: '/accounting/fixedAssets/type',
        blankEntity: blankType,
        activeEntity: activeType,
        getDescription: () => {
            return `Fixed Asset Type ${fields.code}`;
        },
        createEntity: () => {
            return createType({ postBody: fields });
        },
        updateEntity: () => {
            return updateType({
                id: id as unknown as number,
                postBody: fields,
            });
        },
        deleteEntity: () => {
            return deleteType(id);
        },
    });

    return {
        fields,
        handleFieldChange,
        formMethods,
        onCreate,
        onUpdate,
        onDelete,
        closeForm,
        isLoadingType,
        isLoadingDepreciationMethods,
        isLoadingAccountDropdowns,
        depreciationMethodOptions,
        recoveryUnitOptions,
        isLoadingRecoveryUnits,
        accountDropdowns,
        onDepreciationMethodChanged,
        accountsRequired,
        showAccountsPanel,
        canCreateAssetType,
        canUpdateAssetType,
        canDeleteAssetType,
        termSet,
    };
};

export default UseFixedAssetTypeForm;
