import React, { useContext, useEffect, useState } from 'react';
import { skipToken } from '@reduxjs/toolkit/query';
import _, { cloneDeep, forEach } from 'lodash';
import { isNilOrEmpty } from '../../../../../../../../../../utils/objectUtils';
import { RowStatus } from '../../../../../../../../../../components/grids/hooks/useBaseGridEditable';
import { ColDef } from 'ag-grid-community';
import {
    GhsPrecautionaryPhrase,
    SdsGhsPrecautionaryPhrase,
    SdsHeader,
} from '../../../../../../../../../../types/formulation';
import { useGetStatementTypesForDropdownQuery } from '../../../../../../../../../../services/formulation/precautionaryPhrases/statementTypes/statementTypes.service';
import { editableGridCellSelect } from '../../../../../../../../../../components/grids/columns/editableGridCellSelect';
import {
    Language,
    useGetLanguagesQuery,
} from '../../../../../../../../../../services/organizations/organizations.service';
import { useGetPrecautionaryPhrasesByLangIdQuery } from '../../../../../../../../../../services/formulation/precautionaryPhrases/precautionaryPhrases.service';
import { PermissionsUtil } from '../../../../../../../../../../utils/permissions/permissionsUtil';
import { PERMISSIONS } from '../../../../../../../../../../constants/permissions/Permissions.constants';
import { ColumnRequiredValueValidator } from '../../../../../../../../../grid/validators/columnRequiredValue.validator';
import { ColumnMaxValueValidator } from '../../../../../../../../../grid/validators/columnMaxValue.validator';
import { ColumnNumericValueValidator } from '../../../../../../../../../grid/validators/columnNumericValue.validator';
import { ColumnMinValueValidator } from '../../../../../../../../../grid/validators/columnMinValue.validator';
import applyEditableGridValidations from '../../../../../../../../../grid/utils/applyEditableGridValidations';
import CustomHeader from '../../../../../../../../../../components/grids/CustomHeader';
import { SDSFormDefs } from '../../../../../../../../../../constants/i18n/translations/termDefinitions/formulation';
import SettingsContext from '../../../../../../../../../../contexts/settings.context';
import { useGetTermSetQuery } from '../../../../../../../../../../services/i18n/i18n.service';
import { FORMULATION_DEFS } from '../../../../../../../../../../constants/i18n/translations/termSetDefinitions/formulation';
import { ChildEditableGridWithCopyFunctionProps } from '../../../../../../../../../grid/utils/editableGrid/ChildEditableGridWithCopyFunctionProps';

const useSdsGhsPrecautionaryPhrasesGrid = (
    props: ChildEditableGridWithCopyFunctionProps<
        SdsHeader,
        SdsGhsPrecautionaryPhrase
    >
) => {
    const { currentParentRecord, user } = props.parentData;

    const { showDeleteButton, isGridEditable } = props.displayGridButtons;

    const { handleChildrenRecords } = props.helpers;

    const { rowsData, setRowsData } = props.copyMethods;

    const [columnDefs, setColumnDefs] = useState(null as ColDef[]);

    const [maxRowId, setMaxRowId] = useState(0);

    const [showMultiSelectGridModal, setShowMultiSelectGridModal] =
        useState(false);

    const [ghsPrecautionaryPhraseList, setGhsPrecautionaryPhraseList] =
        useState(null as GhsPrecautionaryPhrase[]);

    const [showVerbiageMessage, setShowVerbiageMessage] = useState(false);

    const { data: languagesList, isLoading: isLoadingLanguages } =
        useGetLanguagesQuery();

    const defaultLangId = languagesList?.find(
        (lang: Language) => lang.code === 'ENG'
    )?.id;

    const {
        data: precautionaryPhraseList,
        isLoading: isLoadingPrecautionaryPhraseList,
    } = useGetPrecautionaryPhrasesByLangIdQuery(
        defaultLangId
            ? {
                  langId: defaultLangId.toString(),
              }
            : skipToken
    );

    const {
        data: statementTypeOptionsList,
        isLoading: isLoadingStatementTypes,
    } = useGetStatementTypesForDropdownQuery();

    const canViewPrecautionaryPhrases = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.FORMULATION.GHS_PRECAUTIONARY_PHRASE.VIEW
    );

    const { settings } = useContext(SettingsContext);
    const { data: termSet } = useGetTermSetQuery(
        settings?.userSettings
            ? {
                  languageId: settings?.userSettings?.languageId,
                  code: FORMULATION_DEFS.SDS_FORM,
              }
            : skipToken
    );

    /**
     * Get the language code based on a language ID
     * @param langId
     */
    const getLanguageCodeById = (langId: bigint) => {
        let language = languagesList
            ? languagesList.find((current: Language) => current.id === langId)
            : null;
        return language?.code;
    };

    /**
     * Initialize a list of SdsGhsPrecautionaryPhrases excluding deleted records
     * and set the RowId
     * @param currentList
     */
    const setSdsGhsPrecautionaryPhraseData = (
        currentList: SdsGhsPrecautionaryPhrase[]
    ) => {
        const newList = cloneDeep(
            currentList.filter(
                (current) => current.rowStatus !== RowStatus.DELETED
            )
        );
        let currentRowId = maxRowId;
        newList.forEach((current) => {
            if (!current.rowId) {
                current.rowId = ++currentRowId;
            }
        });
        setMaxRowId(currentRowId);
        return newList;
    };

    /**
     * sorts a given SdsGhsPrecautionaryPhrase list by sdsSortOrder and phraseId
     * @param sdsGhsPrecautionaryPhraseRecords
     */
    const sortSdsGhsPrecautionaryPhraseRecords = (
        sdsGhsPrecautionaryPhraseRecords: SdsGhsPrecautionaryPhrase[]
    ) => {
        setRowsData(
            _.orderBy(
                sdsGhsPrecautionaryPhraseRecords,
                ['sdsSortOrder', 'phraseId'],
                ['asc']
            )
        );
    };

    /**
     * Adds selected records from multiselect to SDS GHS Precautionary Phrases
     * @param selectedRecords
     */
    const handleSelectedRecords = (
        selectedRecords: GhsPrecautionaryPhrase[]
    ) => {
        const sdsPhrasesList =
            buildPrecautionaryListFromSelectedRecords(selectedRecords);
        setPrecautionaryPhraseRecords(sdsPhrasesList);
        userInputNeededCheck(selectedRecords);
    };

    /**
     * Builds list of selected Ghs Precautionary Phrases in format
     * required for SDS Ghs Precautionary Phrases grid
     * @param selectedRecords
     */
    const buildPrecautionaryListFromSelectedRecords = (
        selectedRecords: GhsPrecautionaryPhrase[]
    ) => {
        let index = maxRowId;
        const sdsPhrasesList = selectedRecords?.map((current) => {
            const newRecord: SdsGhsPrecautionaryPhrase = {
                rowId: ++index,
                phraseId: current.phraseId,
                langId: current.langId,
                businessEntityId: current.businessEntityId,
                phraseText: current.phraseText,
                statementTypeId: current.statementTypeId,
                rowStatus: RowStatus.ADDED,
            };
            return newRecord;
        });
        setMaxRowId(index);
        return sdsPhrasesList;
    };

    /**
     * Filter a given SdsGhsPrecautionaryPhrase list to set the
     * sdsPrecautionaryPhrases state value
     * @param precautionaryList
     */
    const setPrecautionaryPhraseRecords = (
        precautionaryList: SdsGhsPrecautionaryPhrase[]
    ) => {
        const filteredList = rowsData?.filter(
            (current) => current.rowStatus !== RowStatus.DELETED
        );
        setRowsData(
            filteredList
                ? filteredList.concat(precautionaryList)
                : precautionaryList
        );
        handleRowValidations(precautionaryList);
        handleSaveGridEdits(precautionaryList);
    };

    /**
     * used to manage multiselect modal state
     * filters phrase list here to guarantee the list in the modal is accurate
     * @param showModal
     */
    const handleSetShowMultiSelectGridModal = (showModal: boolean) => {
        if (showModal) {
            setFilteredPrecautionaryPhraseList();
        }
        setShowMultiSelectGridModal(showModal);
    };

    /**
     * Filter the precautionaryPhraseList list to exclude deleted records and
     * selected record. Set the result list to the GhsPrecautionaryPhraseList state value
     */
    const setFilteredPrecautionaryPhraseList = () => {
        if (
            precautionaryPhraseList &&
            rowsData &&
            canViewPrecautionaryPhrases
        ) {
            const filteredList = precautionaryPhraseList.filter((current) => {
                return isNilOrEmpty(
                    rowsData.find(
                        (sdsGhsRecord) =>
                            sdsGhsRecord.phraseId === current.phraseId &&
                            sdsGhsRecord.rowStatus !== RowStatus.DELETED
                    )
                );
            });
            setGhsPrecautionaryPhraseList(filteredList || []);
        } else {
            setGhsPrecautionaryPhraseList([]);
        }
    };

    /**
     * Save the current SdsGhsPrecautionaryPhrases into the parent SDS Header object
     * @param editedRows
     */
    const handleSaveGridEdits = (editedRows: SdsGhsPrecautionaryPhrase[]) => {
        if (editedRows) {
            const updatedList = rowsData?.filter((current) => {
                return isNilOrEmpty(
                    editedRows.find(
                        (editedRecord) => editedRecord.rowId === current.rowId
                    )
                );
            });

            const newSdsGhsPrecautionaryPhraseList = updatedList
                ? updatedList.concat(editedRows)
                : editedRows;

            forEach(
                newSdsGhsPrecautionaryPhraseList,
                (updatedRecord: SdsGhsPrecautionaryPhrase) => {
                    if (isNilOrEmpty(updatedRecord.sdsSortOrder)) {
                        updatedRecord.sdsSortOrder = null;
                    }
                }
            );
            sortSdsGhsPrecautionaryPhraseRecords(
                newSdsGhsPrecautionaryPhraseList
            );
            handleChildrenRecords(
                'sdsGhsPrecautionaryPhrases',
                newSdsGhsPrecautionaryPhraseList
            );
        }
    };

    /**
     * Show the verbiage message if any of the selected records has userInputNeeded
     * @param selectedRecords
     */
    const userInputNeededCheck = (
        selectedRecords: GhsPrecautionaryPhrase[]
    ) => {
        const userInputNeededRecord = selectedRecords?.find(
            (current) => current.userInputNeeded === true
        );
        if (userInputNeededRecord) {
            setShowVerbiageMessage(true);
        }
    };

    /**
     * used for displaying message when record is selected from multiselect
     * message will display when record has 'isInputNeeded' marked true
     * @param showMessage
     */
    const handleShowVerbiageMessage = (showMessage: boolean) => {
        setShowVerbiageMessage(showMessage);
    };

    /**
     * Add the needed validations to the edited rows.
     * @param editedRows
     */
    const handleRowValidations = (editedRows: SdsGhsPrecautionaryPhrase[]) => {
        editedRows?.forEach((current) => {
            current.validationRules = [
                ColumnRequiredValueValidator('Phrase ID', current.phraseId),
                ColumnRequiredValueValidator('Phrase Text', current.phraseText),
                ColumnMaxValueValidator('Phrase Text', 255, current.phraseText),
                ColumnRequiredValueValidator('Language', current.langId),
                ColumnRequiredValueValidator(
                    'Statement Type',
                    current.statementTypeId
                ),
                ColumnNumericValueValidator(
                    'SDS Sort Order',
                    0,
                    current.sdsSortOrder
                ),
                ColumnMinValueValidator(
                    'SDS Sort Order',
                    0,
                    Number(current.sdsSortOrder)
                ),
            ];
            applyEditableGridValidations(current, `Phrase ${current.phraseId}`);
        });
        return editedRows;
    };

    /**
     * Set column definitions for SdsGhsPrecautionaryPhrases grid
     */
    useEffect(() => {
        if (languagesList && statementTypeOptionsList) {
            const colDefList: ColDef[] = [
                {
                    field: 'deleteColumn',
                    minWidth: 50,
                    hide: !showDeleteButton || !isGridEditable,
                    filter: false,
                },
                {
                    field: 'phraseId',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={
                                    SDSFormDefs.GHS_Precautionary_Phrases_Code
                                }
                            />
                        );
                    },
                    minWidth: 250,
                    filter: 'agTextColumnFilter',
                    editable: false,
                },
                {
                    field: 'phraseText',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={
                                    SDSFormDefs.GHS_Precautionary_Phrases_Phrase_Text
                                }
                            />
                        );
                    },
                    minWidth: 500,
                    filter: 'agTextColumnFilter',
                    editable: true,
                },
                {
                    field: 'statementTypeId',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={
                                    SDSFormDefs.GHS_Precautionary_Phrases_Statement_Type
                                }
                            />
                        );
                    },
                    width: 300,
                    ...editableGridCellSelect(statementTypeOptionsList),
                    editable: isGridEditable,
                },
                {
                    field: 'langId',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={
                                    SDSFormDefs.GHS_Precautionary_Phrases_Language
                                }
                            />
                        );
                    },
                    minWidth: 150,
                    filter: 'agTextColumnFilter',
                    valueGetter: (params) =>
                        getLanguageCodeById(params.data.langId),
                    filterParams: {
                        valueGetter: (params: any) =>
                            getLanguageCodeById(params.data['langId']),
                    },
                },
            ];
            setColumnDefs(colDefList);
        }
    }, [languagesList, statementTypeOptionsList]);

    /**
     * Set the SdsGhsPrecationaryPhrases based on the existings records of the
     * parent SDS Header record.
     */
    useEffect(() => {
        const includeEditedRows =
            currentParentRecord?.sdsGhsPrecautionaryPhrases
                ? !isNilOrEmpty(
                      currentParentRecord.sdsGhsPrecautionaryPhrases.find(
                          (current) => current.rowStatus
                      )
                  )
                : false;
        if (
            currentParentRecord &&
            currentParentRecord.id &&
            currentParentRecord.sdsGhsPrecautionaryPhrases &&
            !includeEditedRows
        ) {
            sortSdsGhsPrecautionaryPhraseRecords(
                setSdsGhsPrecautionaryPhraseData(
                    currentParentRecord.sdsGhsPrecautionaryPhrases
                )
            );
        }
    }, [currentParentRecord?.sdsGhsPrecautionaryPhrases]);

    return {
        columnDefs,
        rowsData,
        ghsPrecautionaryPhraseList,
        showMultiSelectGridModal,
        isLoadingPrecautionaryPhraseList,
        showVerbiageMessage,
        handleSetShowMultiSelectGridModal,
        handleSelectedRecords,
        handleSaveGridEdits,
        handleRowValidations,
        handleShowVerbiageMessage,
        termSet,
    };
};

export default useSdsGhsPrecautionaryPhrasesGrid;
