import { useContext } from 'react';
import { skipToken } from '@reduxjs/toolkit/query';
import { FormulaProperty } from '../../../../../types/formulation';
import useBaseForm from '../../../../form/hooks/useBaseForm';
import { RootStateOrAny, useSelector } from 'react-redux';
import { PermissionsUtil } from '../../../../../utils/permissions/permissionsUtil';
import { PERMISSIONS } from '../../../../../constants/permissions/Permissions.constants';
import {
    useCreateFormulaPropertyMutation,
    useDeleteFormulaPropertyMutation,
    useGetFormulaPropertyQuery,
    useUpdateFormulaPropertyMutation,
} from '../../../../../services/formulation/formulaProperty/formulaProperty.service';
import { useGetPropertyTypesForDropdownQuery } from '../../../../../services/formulation/formulaProperty/propertyType/propertyType.service';
import SettingsContext from '../../../../../contexts/settings.context';
import { useGetTermSetQuery } from '../../../../../services/i18n/i18n.service';
import { FORMULATION_DEFS } from '../../../../../constants/i18n/translations/termSetDefinitions/formulation';

const useFormulaPropertyForm = (id: string) => {
    const user = useSelector((state: RootStateOrAny) => state.user);

    const { data: activeFormulaProperty, isLoading: isLoadingFormulaProperty } =
        useGetFormulaPropertyQuery(id ? id : skipToken);
    const [createFormulaProperty] = useCreateFormulaPropertyMutation();
    const [updateFormulaProperty] = useUpdateFormulaPropertyMutation();
    const [deleteFormulaProperty] = useDeleteFormulaPropertyMutation();

    const { data: propertyTypeOptions, isLoading: isLoadingPropertyTypes } =
        useGetPropertyTypesForDropdownQuery();

    const canCreateFormulaProperty = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.FORMULATION.FORMULA_PROPERTIES.CREATE
    );
    const canUpdateFormulaProperty = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.FORMULATION.FORMULA_PROPERTIES.EDIT
    );
    const canDeleteFormulaProperty = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.FORMULATION.FORMULA_PROPERTIES.DELETE
    );

    const blankFormulaProperty: FormulaProperty = {
        propertyCode: '',
        description: '',
        sortOrder: null,
        defaultValue: '',
        showOnSds: true,
        sdsGeneration: false,
        propertyTypeId: null,
        businessEntityId: user?.businessEntity?.id,
    };

    const { settings } = useContext(SettingsContext);
    const { data: termSet } = useGetTermSetQuery(
        settings?.userSettings
            ? {
                  languageId: settings?.userSettings?.languageId,
                  code: FORMULATION_DEFS.FORMULA_PROPERTIES_FORM,
              }
            : skipToken
    );

    const onBeforeDeleteConfirmation = () => {
        if (fields.sdsGeneration) {
            return 'This property is required in SDS Generation calculations, removing it will result in lower accuracy of automated SDS records. Do you wish to Delete?';
        }
        return null;
    };

    const {
        fields,
        handleFieldChange,
        formMethods,
        onCreate,
        onUpdate,
        onDelete,
        closeForm,
        hasEntityChanges,
    } = useBaseForm({
        closePath: '/formulation/formulaProperties',
        reroute: true,
        blankEntity: blankFormulaProperty,
        activeEntity: activeFormulaProperty,
        onlyUpdateEntityWithChanges: true,
        getDescription: () => {
            return `Formula Property ${fields.propertyCode}`;
        },
        createEntity: () => {
            return createFormulaProperty({ postBody: fields });
        },
        updateEntity: () => {
            return updateFormulaProperty({
                id: id as unknown as number,
                postBody: fields,
            });
        },
        deleteEntity: () => {
            return deleteFormulaProperty(id);
        },
        onBeforeDeleteConfirmation: () => {
            return onBeforeDeleteConfirmation();
        },
    });

    return {
        fields,
        handleFieldChange,
        formMethods,
        onCreate,
        onUpdate,
        onDelete,
        closeForm,
        isLoadingFormulaProperty,
        isLoadingPropertyTypes,
        propertyTypeOptions,
        canCreateFormulaProperty,
        canUpdateFormulaProperty,
        canDeleteFormulaProperty,
        termSet,
        hasEntityChanges,
    };
};

export default useFormulaPropertyForm;
