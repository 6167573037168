import React, { useContext, useEffect, useState } from 'react';
import {
    SdsHeader,
    SdsTransportation,
} from '../../../../../../../types/formulation';
import {
    useListProperShippingNameDropdownQuery,
    useListProperShippingNameQuery,
} from '../../../../../../../services/organizations/organizations.service';
import {
    HandleFieldChangedEvent,
    useForm,
} from '../../../../../../../libs/hooksLib';
import { PermissionsUtil } from '../../../../../../../utils/permissions/permissionsUtil';
import { PERMISSIONS } from '../../../../../../../constants/permissions/Permissions.constants';
import { ChildFormProps } from '../../../../../../form/helpers/ChildFormProps';
import { skipToken } from '@reduxjs/toolkit/query';
import { ProperShippingName } from '../../../../../../../types/Setup.types';
import _ from 'lodash';
import { openModalConfirmBasicWithMessage } from '../../../../../../../store/uiElements';
import { useDispatch } from 'react-redux';
import { SelectionOption } from '../../../../../../../types/Shared.types';
import { isNilOrEmpty } from '../../../../../../../utils/objectUtils';
import DefaultColumnTypes from '../../../../../../../components/grids/columns/Column.constants';
import CustomHeader from '../../../../../../../components/grids/CustomHeader';
import { SDSFormDefs } from '../../../../../../../constants/i18n/translations/termDefinitions/formulation';
import { useGetTermSetQuery } from '../../../../../../../services/i18n/i18n.service';
import { FORMULATION_DEFS } from '../../../../../../../constants/i18n/translations/termSetDefinitions/formulation';
import SettingsContext from '../../../../../../../contexts/settings.context';

const useSdsTransportForm = (props: ChildFormProps<SdsHeader>) => {
    const blankSdsTransportation: SdsTransportation = {
        landProperShippingNameId: null,
        landProperShippingNameDescription: null,
        airProperShippingNameId: null,
        airProperShippingNameDescription: null,
        seaProperShippingNameId: null,
        seaProperShippingNameDescription: null,
    };

    const initialStateCopyDropdowns: any = {
        selectedCopySource: null,
        selectedCopyDestination: null,
        copySourceValue: '',
        copyDestinationValue: '',
    };

    const initialState: any = {
        selectedProperShippingNameLand: null,
        selectedProperShippingNameAir: null,
        selectedProperShippingNameSea: null,
    };

    const initialStateDisplayFields: any = {
        landClass: null,
        landPackaging: null,
        airClass: null,
        airPackaging: null,
        seaClass: null,
        seaPackaging: null,
    };

    const tabValues = {
        landTab: '0',
        airTab: '1',
        seaTab: '2',
    };

    const dispatch = useDispatch();
    const [currentTab, setCurrentTab] = useState<string>('0');
    const { currentParentRecord, isParentLoading, user, dataFromQuery } =
        props.parentData;
    const [isFormSet, setIsFormSet] = useState<boolean>(false);
    const transportationOptions = {
        land: 'Land',
        landPrefix: 'land',
        air: 'Air',
        airPrefix: 'air',
        sea: 'Sea',
        seaPrefix: 'sea',
    };
    const landOption = { value: 1, label: 'Land' };
    const airOption = { value: 2, label: 'Air' };
    const seaOption = { value: 3, label: 'Sea' };
    const initialCopyFunctionalityOptions = [landOption, airOption, seaOption];
    const [copySourceOptions, setCopySourceOptions] = useState<
        SelectionOption[]
    >(initialCopyFunctionalityOptions);
    const [copyDestinationOptions, setCopyDestinationOptions] = useState<
        SelectionOption[]
    >(initialCopyFunctionalityOptions);
    const { handleChildRecord, registerChildForm, updateCompleteChildRecord } =
        props.helpers;
    const [isLandEnabled, setIsLandEnabled] = useState(false);
    const [isAirEnabled, setIsAirEnabled] = useState(false);
    const [isSeaEnabled, setIsSeaEnabled] = useState(false);
    const [isCopyButtonEnabled, setIsCopyButtonEnabled] = useState(false);
    const [isDestinationFieldEnabled, setIsDestinationFieldEnabled] =
        useState(false);
    const [dropdownOptions, setDropdownOptions] = useState(initialState);
    const [copyDropdownOptions, setCopyDropdownOptions] = useState(
        initialStateCopyDropdowns
    );
    const [displayFields, setDisplayFieldValues] = useState<any>(
        initialStateDisplayFields
    );
    const { fields, handleFieldChange, setValues, verifyChanges, formMethods } =
        useForm(blankSdsTransportation, true);
    const { childForm } = verifyChanges;
    const { setOriginalEntity, clearChangesHistory, hasCurrentRecordChanged } =
        childForm;

    const {
        data: properShippingNameOptions,
        isLoading: isLoadingProperShippingNameOptions,
    } = useListProperShippingNameDropdownQuery();

    const {
        data: properShippingNameList,
        isLoading: isLoadingProperShippingName,
    } = useListProperShippingNameQuery(
        user?.businessEntity?.id
            ? user?.businessEntity?.id.toString()
            : skipToken
    );

    const {
        fields: copyFields,
        setValues: setCopyValues,
        formMethods: copyFormMethods,
    } = useForm({ copySourceId: null, copyDestinationId: null }, true);

    const canViewProperShippingName = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.SETUP.PROPER_SHIPPING_NAME.VIEW
    );

    const { settings } = useContext(SettingsContext);
    const { data: termSet } = useGetTermSetQuery(
        settings?.userSettings
            ? {
                  languageId: settings?.userSettings?.languageId,
                  code: FORMULATION_DEFS.SDS_FORM,
              }
            : skipToken
    );

    const properShippingNameColDefs = [
        {
            field: 'label',
            headerComponentFramework: (props: any) => {
                return (
                    <CustomHeader
                        {...props}
                        termSet={termSet}
                        termKey={
                            SDSFormDefs.Transportation_Land_Proper_Shipping_Code
                        }
                    />
                );
            },
            ...DefaultColumnTypes.MediumText,
        },
        {
            field: 'description',
            headerComponentFramework: (props: any) => {
                return (
                    <CustomHeader
                        {...props}
                        termSet={termSet}
                        termKey={
                            SDSFormDefs.Transportation_Proper_Shipping_Name
                        }
                    />
                );
            },
            ...DefaultColumnTypes.MediumText,
        },
    ];

    const findProperShippingNameRecord = (
        properShippingNameId: bigint
    ): ProperShippingName => {
        return _.find(properShippingNameList, { id: properShippingNameId });
    };

    /**
     * happens once on page load, sets checkbox state
     */
    const setInitialCheckboxValues = (sdsTransportation: SdsTransportation) => {
        if (sdsTransportation?.landProperShippingNameId) {
            setIsLandEnabled(true);
        }
        if (sdsTransportation?.airProperShippingNameId) {
            setIsAirEnabled(true);
        }
        if (sdsTransportation?.seaProperShippingNameId) {
            setIsSeaEnabled(true);
        }
    };

    /**
     * triggers on copy dropdown changes
     */
    const onCopyFieldChange = async (event: HandleFieldChangedEvent) => {
        if (event.target.id === 'copySourceId') {
            setCopyValues({ copySourceId: event.target.value });
        }
        if (event.target.id === 'copyDestinationId') {
            setCopyValues({ copyDestinationId: event.target.value });
        }
    };

    /**
     * updates both properShippingNameId and description when proper shipping name dropdown changes
     */
    const setFieldsOnProperShippingNameChange = async (
        event: HandleFieldChangedEvent,
        prefix: string
    ) => {
        const properShippingName = findProperShippingNameRecord(
            event.target.value
        );
        setValues({
            [`${prefix}ProperShippingNameId`]: event.target.value,
            [`${prefix}ProperShippingNameDescription`]:
                properShippingName?.properShippingName || null,
        });
        updateCompleteChildRecord('sdsTransportation', {
            ...fields,
            [`${prefix}ProperShippingNameId`]: event.target.value,
            [`${prefix}ProperShippingNameDescription`]:
                properShippingName?.properShippingName || null,
        });
    };

    /**
     * keeps parent form up to date as form values change
     */
    const handlePropertyChanged = async (event: HandleFieldChangedEvent) => {
        handleFieldChange(event);
        handleChildRecord('sdsTransportation', event);
    };

    /**
     * triggers on Copy button click, display modal, copies values from one tab to another, clear copy dropdowns
     */
    const performCopyAction = () => {
        const copySourceSelectedOption = _.find(copySourceOptions, {
            value: copyFields.copySourceId,
        });
        const copyDestinationSelectedOption = _.find(copyDestinationOptions, {
            value: copyFields.copyDestinationId,
        });
        const message = `Are you sure you would like to copy values from ${copySourceSelectedOption.label} to ${copyDestinationSelectedOption.label} This will overwrite any existing Data.`;
        dispatch(
            openModalConfirmBasicWithMessage({
                message: message,
                title: '',
                onConfirm: async () => {
                    let copySourceProperShippingNameId: BigInt;
                    let copySourceProperShippingNameDescription: string;
                    let destinationFieldUpdates;

                    if (copySourceSelectedOption.label === 'Land') {
                        copySourceProperShippingNameId =
                            fields.landProperShippingNameId;
                        copySourceProperShippingNameDescription =
                            fields.landProperShippingNameDescription;
                    }
                    if (copySourceSelectedOption.label === 'Air') {
                        copySourceProperShippingNameId =
                            fields.airProperShippingNameId;
                        copySourceProperShippingNameDescription =
                            fields.airProperShippingNameDescription;
                    }
                    if (copySourceSelectedOption.label === 'Sea') {
                        copySourceProperShippingNameId =
                            fields.seaProperShippingNameId;
                        copySourceProperShippingNameDescription =
                            fields.seaProperShippingNameDescription;
                    }

                    if (copyDestinationSelectedOption.label === 'Land') {
                        destinationFieldUpdates = {
                            ...fields,
                            landProperShippingNameId:
                                copySourceProperShippingNameId,
                            landProperShippingNameDescription:
                                copySourceProperShippingNameDescription,
                        };
                    }
                    if (copyDestinationSelectedOption.label === 'Air') {
                        destinationFieldUpdates = {
                            ...fields,
                            airProperShippingNameId:
                                copySourceProperShippingNameId,
                            airProperShippingNameDescription:
                                copySourceProperShippingNameDescription,
                        };
                    }
                    if (copyDestinationSelectedOption.label === 'Sea') {
                        destinationFieldUpdates = {
                            ...fields,
                            seaProperShippingNameId:
                                copySourceProperShippingNameId,
                            seaProperShippingNameDescription:
                                copySourceProperShippingNameDescription,
                        };
                    }
                    setValues(destinationFieldUpdates);
                    setCopyValues({
                        copySourceId: null,
                        copyDestinationId: null,
                    });
                    updateCompleteChildRecord(
                        'sdsTransportation',
                        destinationFieldUpdates
                    );
                },
            })
        );
    };
    /**
     * triggers checkbox toggle, displays confirm modal and clears fields if confirmed
     */
    const displayCheckboxModal = async (currentInput: any, prefix: string) => {
        const currentIdFieldName = `${prefix}ProperShippingNameId`;
        const currentDescriptionFieldName = `${prefix}ProperShippingNameDescription`;

        if (
            !isNilOrEmpty(fields[currentIdFieldName]) ||
            !isNilOrEmpty(fields[currentDescriptionFieldName])
        ) {
            const message = `All data from fields on ${currentInput} will be deleted are you sure?`;
            dispatch(
                openModalConfirmBasicWithMessage({
                    message: message,
                    title: '',
                    onConfirm: async () => {
                        setValues({
                            [`${prefix}ProperShippingNameId`]: null,
                            [`${prefix}ProperShippingNameDescription`]: null,
                        });
                        updateCompleteChildRecord('sdsTransportation', {
                            ...fields,
                            [`${prefix}ProperShippingNameId`]: null,
                            [`${prefix}ProperShippingNameDescription`]: null,
                        });
                        disableTabFields(currentInput);
                    },
                })
            );
        } else {
            disableTabFields(currentInput);
        }
    };

    /**
     * Disables the proper shipping name fields after the values were cleared
     */
    const disableTabFields = (currentInput: any) => {
        if (currentInput === transportationOptions.land) {
            setIsLandEnabled(false);
        }
        if (currentInput === transportationOptions.sea) {
            setIsSeaEnabled(false);
        }
        if (currentInput === transportationOptions.air) {
            setIsAirEnabled(false);
        }
    };

    /**
     * on checkbox toggle, sets state of checkbox and triggers modal display
     */
    const onCheckboxToggle = async (
        currentCheckboxState: boolean,
        currentInput: any,
        prefix: string
    ) => {
        if (currentInput === transportationOptions.land) {
            if (currentCheckboxState === false) {
                setIsLandEnabled(true);
            } else {
                await displayCheckboxModal(currentInput, prefix);
            }
        }

        if (currentInput === transportationOptions.sea) {
            if (currentCheckboxState === false) {
                setIsSeaEnabled(true);
            } else {
                await displayCheckboxModal(currentInput, prefix);
            }
        }

        if (currentInput === transportationOptions.air) {
            if (currentCheckboxState === false) {
                setIsAirEnabled(true);
            } else {
                await displayCheckboxModal(currentInput, prefix);
            }
        }
    };

    /**
     * disable/enable Copy button based on if copy dropdowns are populated
     */
    useEffect(() => {
        setIsCopyButtonEnabled(
            !!(copyFields.copySourceId && copyFields.copyDestinationId)
        );
    }, [copyFields.copySourceId, copyFields.copyDestinationId]);

    /**
     * sets the copy source field dropdown values based on current checkbox values
     */
    useEffect(() => {
        const copyDropdowns = [];
        if (isLandEnabled) {
            copyDropdowns.push(landOption);
        }
        if (isAirEnabled) {
            copyDropdowns.push(airOption);
        }
        if (isSeaEnabled) {
            copyDropdowns.push(seaOption);
        }
        setCopySourceOptions(copyDropdowns);

        // clears value in Copy Source if option no longer exists
        const copySourceSelectedOption = _.find(copyDropdowns, {
            value: copyFields.copySourceId,
        });
        if (!copySourceSelectedOption) {
            setCopyValues({ copySourceId: null });
        }
    }, [isAirEnabled, isSeaEnabled, isLandEnabled]);

    /**
     * sets options for copy destination drop down
     */
    useEffect(() => {
        const destinationDropdowns = [];
        const copySourceSelectedOption = _.find(copySourceOptions, {
            value: copyFields.copySourceId,
        });
        if (
            isLandEnabled &&
            copySourceSelectedOption?.value !== landOption.value
        ) {
            destinationDropdowns.push(landOption);
        }
        if (
            isAirEnabled &&
            copySourceSelectedOption?.value !== airOption.value
        ) {
            destinationDropdowns.push(airOption);
        }
        if (
            isSeaEnabled &&
            copySourceSelectedOption?.value !== seaOption.value
        ) {
            destinationDropdowns.push(seaOption);
        }

        const selectedCopyDestinationOption = _.find(destinationDropdowns, {
            value: copyFields.copyDestinationId,
        });
        if (!selectedCopyDestinationOption || !copySourceSelectedOption) {
            setCopyValues({ copyDestinationId: null });
        }
        setCopyDestinationOptions(destinationDropdowns);
        setIsDestinationFieldEnabled(!!copyFields.copySourceId);
    }, [copyFields.copySourceId, isAirEnabled, isSeaEnabled, isLandEnabled]);

    useEffect(() => {
        if (!isParentLoading) {
            setOriginalEntity(
                dataFromQuery?.sdsTransportation || blankSdsTransportation
            );
            setValues(
                currentParentRecord?.sdsTransportation || blankSdsTransportation
            );
        }
    }, [
        currentParentRecord?.sdsTransportation,
        isParentLoading,
        dataFromQuery,
    ]);
    /**
     * only happens once, sets form up on page load
     */
    useEffect(() => {
        if (!isParentLoading && isFormSet === false && properShippingNameList) {
            setIsFormSet(true);
            setInitialCheckboxValues(dataFromQuery?.sdsTransportation);
        }
    }, [
        currentParentRecord?.sdsTransportation,
        isParentLoading,
        dataFromQuery,
        properShippingNameList,
    ]);

    useEffect(() => {
        if (!isParentLoading) {
            registerChildForm('sdsTransportation', {
                formMethods,
                clearChangesHistory,
                hasEntityChanges: hasCurrentRecordChanged,
            });
        }
    }, [fields, isParentLoading, isFormSet]);

    /**
     * sets display fields (Hazard and Class) for all tabs
     */
    useEffect(() => {
        if (isFormSet) {
            const valuesToSet: any = {};
            const properShippingNameAir = findProperShippingNameRecord(
                fields.airProperShippingNameId
            );
            const properShippingNameLand = findProperShippingNameRecord(
                fields.landProperShippingNameId
            );
            const properShippingNameSea = findProperShippingNameRecord(
                fields.seaProperShippingNameId
            );
            valuesToSet.airClass = properShippingNameAir?.hazardClass || null;
            valuesToSet.airPackaging =
                properShippingNameAir?.packagingGroup || null;
            valuesToSet.landClass = properShippingNameLand?.hazardClass || null;
            valuesToSet.landPackaging =
                properShippingNameLand?.packagingGroup || null;
            valuesToSet.seaClass = properShippingNameSea?.hazardClass || null;
            valuesToSet.seaPackaging =
                properShippingNameSea?.packagingGroup || null;
            setDisplayFieldValues(valuesToSet);
        }
    }, [
        dropdownOptions?.selectedProperShippingNameLand,
        dropdownOptions?.selectedProperShippingNameSea,
        dropdownOptions?.selectedProperShippingNameAir,
        isFormSet,
    ]);

    useEffect(() => {
        if (isLandEnabled) {
            setCurrentTab(tabValues.landTab);
        } else {
            setCurrentTab(tabValues.airTab);
        }
    }, [isLandEnabled]);

    useEffect(() => {
        if (isAirEnabled) {
            setCurrentTab(tabValues.airTab);
        } else {
            setCurrentTab(tabValues.seaTab);
        }
    }, [isAirEnabled]);

    useEffect(() => {
        if (isSeaEnabled) {
            setCurrentTab(tabValues.seaTab);
        } else {
            setCurrentTab(tabValues.landTab);
        }
    }, [isSeaEnabled]);

    /**
     * Set the labels on all the dropdowns
     */
    useEffect(() => {
        if (
            !isParentLoading &&
            !isLoadingProperShippingNameOptions &&
            properShippingNameOptions &&
            !isLoadingProperShippingName &&
            properShippingNameList
        ) {
            setDropdownOptions((previousValues: any) => ({
                ...previousValues,
                selectedProperShippingNameLand: properShippingNameOptions?.find(
                    (current: any) =>
                        current.value === fields.landProperShippingNameId
                ),
                selectedProperShippingNameSea: properShippingNameOptions?.find(
                    (current: any) =>
                        current.value === fields.seaProperShippingNameId
                ),
                selectedProperShippingNameAir: properShippingNameOptions?.find(
                    (current: any) =>
                        current.value === fields.airProperShippingNameId
                ),
                selectedCopySource: properShippingNameOptions?.find(
                    (current: any) =>
                        current.value === fields.airProperShippingNameId
                ),
                selectedCopyDestination: properShippingNameOptions?.find(
                    (current: any) =>
                        current.value === fields.airProperShippingNameId
                ),
            }));
            setCopyDropdownOptions((previousValues: any) => ({
                ...previousValues,
                selectedCopySource: copySourceOptions?.find(
                    (current: any) => current.value === copyFields.copySourceId
                ),
                selectedCopyDestination: copySourceOptions?.find(
                    (current: any) =>
                        current.value === copyFields.copyDestinationId
                ),
            }));
        }
    }, [
        isParentLoading,
        fields,
        properShippingNameOptions,
        isLoadingProperShippingNameOptions,
        properShippingNameList,
        isLoadingProperShippingName,
        copyFields,
    ]);

    return {
        fields,
        handlePropertyChanged,
        dropdownOptions,
        formMethods,
        currentTab,
        setCurrentTab,
        setDropdownOptions,
        properShippingNameOptions,
        isLoadingProperShippingNameOptions,
        setFieldsOnProperShippingNameChange,
        onCopyFieldChange,
        isAirEnabled,
        isLandEnabled,
        isSeaEnabled,
        isCopyButtonEnabled,
        isDestinationFieldEnabled,
        onCheckboxToggle,
        transportationOptions,
        displayFields,
        copySourceOptions,
        copyDestinationOptions,
        setCopyDropdownOptions,
        copyDropdownOptions,
        copyFormMethods,
        performCopyAction,
        canViewProperShippingName,
        properShippingNameColDefs,
        tabValues,
    };
};
export default useSdsTransportForm;
