import React, { ReactNode } from 'react';
import { Tooltip } from '@mui/material';
import useTruncatedTooltip from './hooks/useTruncatedTooltip';

export interface TruncatedTooltipProps {
    children: ReactNode;
    text: string;
    maxWidth: Number;
}

const TruncatedTooltip = (props: TruncatedTooltipProps) => {
    const { displayTooltip } = useTruncatedTooltip(props);
    return (
        <>
            {displayTooltip ? (
                <Tooltip title={props.children} placement="top">
                    <span>{props.children}</span>
                </Tooltip>
            ) : (
                <span>{props.children}</span>
            )}
        </>
    );
};
export default TruncatedTooltip;
