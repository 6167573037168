import { useListSupplierAddressByQuery } from '../../../../services/supplier/supplier.service';
import React, { useContext, useEffect, useState } from 'react';
import { SUPPLY_CHAIN_DEFS } from '../../../../constants/i18n/translations/termSetDefinitions/supply-chain';
import { isNilOrEmpty } from '../../../../utils/objectUtils';
import { ColDef } from 'ag-grid-community';
import DefaultColumnTypes from '../../../../components/grids/columns/Column.constants';
import CustomHeader from '../../../../components/grids/CustomHeader';
import { SupplierAddressGridDefs } from '../../../../constants/i18n/translations/termDefinitions/supply-chain';
import { auditableGridColumns } from '../../../grid/utils/auditableGrid/auditableGridColumns';
import useTranslation from '../../../../components/i18n/hooks/useTranslation';
import { skipToken } from '@reduxjs/toolkit/query';
import { SupplierAddressByParams } from '../../../../types/Supplier.types';
import { RoutingValues } from '../../../../types/Shared.types';
import { useNavigate } from 'react-router-dom';
import NavFormContext from '../../../../contexts/navForm.context';
import { GridOptions } from '../../../../components/grids/Grid.constants';

const useSectionSupplierAddressGrid = (supplierId: string) => {
    //#region Params
    const params: SupplierAddressByParams = {
        supplierId: supplierId,
    };
    const navigate = useNavigate();
    const { supplierNav } = useContext(NavFormContext);
    //#endregion

    //#region Translation
    const { termSet, isLoadingTermSet } = useTranslation(
        SUPPLY_CHAIN_DEFS.SUPPLIER_ADDRESS_GRID
    );
    //#endregion

    //#region Columns and Data Definitions
    const [colDefs, setColDefs] = useState([]);
    const [rowData, setRowData] = useState([]);
    const [hiddenIdColumns, auditableColumns] = auditableGridColumns();

    const { data: entityList, isLoading } = useListSupplierAddressByQuery(
        params ? params : skipToken
    );
    const defaultColumnDefinitionOptions = {
        ...GridOptions.sortFilterAndWrapColumns,
        floatingFilter: true,
    };
    //#endregion

    //#region Effects
    useEffect(() => {
        if (!isNilOrEmpty(termSet)) {
            const columnDefs: ColDef[] = [
                ...hiddenIdColumns,
                {
                    field: 'code',
                    ...DefaultColumnTypes.ShortText,
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={SupplierAddressGridDefs.Code}
                            />
                        );
                    },
                },
                {
                    field: 'addressType.code',
                    ...DefaultColumnTypes.ShortText,
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={SupplierAddressGridDefs.Type}
                            />
                        );
                    },
                },
                {
                    field: 'addressStatus.code',
                    ...DefaultColumnTypes.ShortText,
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={SupplierAddressGridDefs.Status}
                            />
                        );
                    },
                },
                {
                    field: 'name',
                    ...DefaultColumnTypes.MediumText,
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={SupplierAddressGridDefs.Name}
                            />
                        );
                    },
                },
                {
                    field: 'street',
                    ...DefaultColumnTypes.LongText,
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={SupplierAddressGridDefs.Street}
                            />
                        );
                    },
                },
                {
                    field: 'city',
                    ...DefaultColumnTypes.MediumText,
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={SupplierAddressGridDefs.City}
                            />
                        );
                    },
                },
                {
                    field: 'county',
                    ...DefaultColumnTypes.MediumText,
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={SupplierAddressGridDefs.County}
                            />
                        );
                    },
                },
                {
                    field: 'stateCode.name',
                    ...DefaultColumnTypes.ShortText,
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={SupplierAddressGridDefs.State}
                            />
                        );
                    },
                },
                {
                    field: 'postalCode',
                    ...DefaultColumnTypes.ShortText,
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={SupplierAddressGridDefs.PostalCode}
                            />
                        );
                    },
                },
                {
                    field: 'countryCode.name',
                    ...DefaultColumnTypes.ShortText,
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={SupplierAddressGridDefs.Country}
                            />
                        );
                    },
                },
                {
                    field: 'phone',
                    ...DefaultColumnTypes.ShortText,
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={SupplierAddressGridDefs.Phone}
                            />
                        );
                    },
                },
                {
                    field: 'email',
                    ...DefaultColumnTypes.ShortText,
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={SupplierAddressGridDefs.Email}
                            />
                        );
                    },
                },
                ...auditableColumns,
            ];

            setColDefs(columnDefs);
        }
    }, [termSet]);

    useEffect(() => {
        if (!isNilOrEmpty(entityList)) {
            setRowData(entityList);
            supplierNav.supplierId = null;
        }
    }, [entityList]);
    //#endregion

    const redirectToForm = (row: any) => {
        supplierNav.supplierId = Number(supplierId);
        if (row.id) {
            navigate(`/suppliers/supplierAddress/${row.id}`);
        } else {
            navigate(`/suppliers/supplierAddress/${RoutingValues.newId}`);
        }
    };

    /**
     * Check if it is loading the row data and the term set definition
     */
    const isLoadingData = (): boolean => isLoading || isLoadingTermSet;

    return {
        rowData,
        colDefs,
        defaultColumnDefinitionOptions,
        isLoadingData,
        redirectToForm,
    };
};

export default useSectionSupplierAddressGrid;
