import React from 'react';
import {
    RDFormula,
    RDFormulaHazardClassificationMapping,
} from '../../../../../../types/formulation';
import useRDFormulaHazardClassificationMappingGrid from './hook/useRDFormulaHazardClassificationMappingGrid';
import { GridOptions } from '../../../../../../components/grids/Grid.constants';
import DatacorLogoSpinner from '../../../../../../components/datacorLogoSpinner/DatacorLogoSpinner';
import { isNilOrEmpty } from '../../../../../../utils/objectUtils';
import FormPartial from '../../../../../../components/form/formContainer/FormPartial';
import BaseGrid from '../../../../../../components/grids/BaseGrid';
import TranslatableText from '../../../../../../components/i18n/TranslatableText';
import { RDFormulaHazardClassificationMappingDefs } from '../../../../../../constants/i18n/translations/termDefinitions/formulation';
import { ChildEditableGridWithCopyFunctionProps } from '../../../../../grid/utils/editableGrid/ChildEditableGridWithCopyFunctionProps';

const RDFormulaHazardClassificationMappingGrid = (
    props: ChildEditableGridWithCopyFunctionProps<
        RDFormula,
        RDFormulaHazardClassificationMapping
    >
) => {
    const {
        columnDefs,
        handleRowValidations,
        handleGridEdits,
        isLocaleGroupsLoading,
        isHazardClassificationSourceDropdownLoading,
        isDefaultHazardClassMapsLoading,
        rowsData,
        termSet,
        isLoadingTermSet,
    } = useRDFormulaHazardClassificationMappingGrid(props);

    const { parentData, displayGridButtons } = props;

    const { isParentLoading } = parentData;

    const { isGridEditable } = displayGridButtons;

    const defaultColumnDefinitionsOptions = {
        ...GridOptions.sortFilterAndWrapColumns,
        floatingFilter: true,
    };

    const isGridLoading =
        isParentLoading ||
        isLocaleGroupsLoading ||
        isHazardClassificationSourceDropdownLoading ||
        isDefaultHazardClassMapsLoading ||
        isLoadingTermSet;

    return (
        <>
            {isGridLoading ? (
                <DatacorLogoSpinner />
            ) : !isParentLoading && isNilOrEmpty(columnDefs) ? (
                <div className="spinnerContainer">Setup Data Unavailable</div>
            ) : (
                <>
                    <FormPartial
                        title={
                            (
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={
                                        RDFormulaHazardClassificationMappingDefs.title
                                    }
                                />
                            ) as unknown as string
                        }>
                        <BaseGrid
                            autoSize={true}
                            displayGrid={true}
                            displayCreateNewButton={false}
                            rowData={rowsData}
                            dataKey={'rowId'}
                            gridClass="full-width-grid"
                            displayToolbar={true}
                            sizeColumnsToFit={true}
                            isEditable={isGridEditable}
                            columnDefs={columnDefs}
                            createRow={false}
                            onHandleRowValidations={handleRowValidations}
                            onHandleGridEdits={handleGridEdits}
                            defaultColDef={defaultColumnDefinitionsOptions}
                            paginationPageSize={20}
                        />
                    </FormPartial>
                </>
            )}
        </>
    );
};

export default RDFormulaHazardClassificationMappingGrid;
