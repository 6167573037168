import React from 'react';
import { Grid } from '@mui/material';
import { useParams } from 'react-router-dom';
import FormPartial from '../../components/form/formContainer/FormPartial';
import TextInput from '../../components/form/formInputs/TextInput/TextInput';
import useContractForm from './hooks/useContractForm';
import DateInput from '../../components/form/formInputs/DateInput/DateInput';
import CurrencyInput from '../../components/form/formInputs/CurrencyInput/CurrencyInput';
import SelectInput from '../../components/form/formInputs/SelectInput/SelectInput';
import BaseForm, { ParamTypes } from '../../components/form/baseForm/BaseForm';
import { NumberMaxValues, RoutingValues } from '../../types/Shared.types';
import { CONTRACT_FIELDS } from '../../constants/revisionHistory/revisionHistory.constants';
import TranslatableText from '../../components/i18n/TranslatableText';
import { ContractsFormDefs } from '../../constants/i18n/translations/termDefinitions/accounting';

const ContractForm = () => {
    const { id } = useParams<ParamTypes>();
    const {
        fields,
        handleFieldChange,
        formMethods,
        onCreate,
        onUpdate,
        onDelete,
        closeForm,
        isLoadingContract,
        isLoadingContractTypes,
        contractTypeOptions,
        canCreateContract,
        canUpdateContract,
        canDeleteContract,
        termSet,
    } = useContractForm(id);

    return (
        <BaseForm
            title={
                <TranslatableText
                    termSet={termSet}
                    termKey={ContractsFormDefs.Title}
                />
            }
            formMethods={formMethods}
            isLoading={isLoadingContract}
            isNew={id === RoutingValues.newId}
            onCreate={onCreate}
            onUpdate={onUpdate}
            onDelete={onDelete}
            onCancel={closeForm}
            displayCreateButton={canCreateContract}
            displayUpdateButton={canUpdateContract}
            displayDeleteButton={canDeleteContract}
            showOnRevision={true}
            fieldMappings={CONTRACT_FIELDS}
            entityUuid={fields?.uuid}>
            <FormPartial>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <TextInput
                            fullWidth
                            label={
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={ContractsFormDefs.Number}
                                />
                            }
                            id="number"
                            formMethods={formMethods}
                            value={fields.number}
                            onChange={handleFieldChange}
                            required={true}
                            maxLength={50}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextInput
                            fullWidth
                            label="Contract Name"
                            id="name"
                            formMethods={formMethods}
                            value={fields.name}
                            onChange={handleFieldChange}
                            maxLength={90}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextInput
                            fullWidth
                            label="Description"
                            id="description"
                            formMethods={formMethods}
                            value={fields.description}
                            onChange={handleFieldChange}
                            maxLength={255}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextInput
                            fullWidth
                            label="Provider"
                            id="supplier"
                            formMethods={formMethods}
                            value={fields.supplier}
                            onChange={handleFieldChange}
                            maxLength={90}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <DateInput
                            label="Start Date"
                            id="startDate"
                            value={fields.startDate}
                            onChange={handleFieldChange}
                            formMethods={formMethods}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <DateInput
                            label="End Date"
                            id="endDate"
                            value={fields.endDate}
                            onChange={handleFieldChange}
                            formMethods={formMethods}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <CurrencyInput
                            fullWidth
                            label="Contract Amount"
                            id="amount"
                            formMethods={formMethods}
                            value={fields.amount}
                            onChange={handleFieldChange}
                            maxValue={NumberMaxValues.decimal192}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <SelectInput
                            fullWidth
                            label="Contract Type"
                            id="contractTypeId"
                            formMethods={formMethods}
                            value={fields.contractTypeId}
                            onChange={handleFieldChange}
                            options={contractTypeOptions}
                            disabled={isLoadingContractTypes}
                            required={true}
                        />
                    </Grid>
                </Grid>
            </FormPartial>
        </BaseForm>
    );
};

export default ContractForm;
