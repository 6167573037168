import React, { useEffect, useState } from 'react';
import useBaseForm from '../../form/hooks/useBaseForm';
import {
    AttributeDefinition,
    useCreateAttributeDefinitionMutation,
    useDeleteAttributeDefinitionMutation,
    useGetAttributeDefinitionQuery,
    useGetAttributeValueTypesForDropdownQuery,
    useUpdateAttributeDefinitionMutation,
} from '../../../services/attributes/attributes.service';
import { skipToken } from '@reduxjs/toolkit/query';
import { isArray, isObject } from 'lodash';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { useLocation } from 'react-router-dom';
import { PermissionsUtil } from '../../../utils/permissions/permissionsUtil';
import { PERMISSIONS } from '../../../constants/permissions/Permissions.constants';
import * as _ from 'lodash';
import { isJsonString } from '../../../utils/objectUtils';
import { VALUE_TYPES } from '../../../constants/attributes/attributes.constants';

const useAttributeDefinitionsForm = (id: string) => {
    const user = useSelector((state: RootState) => state.user);
    let location = useLocation();
    const { data: attributeDefinition, isLoading: isLoadingDefinition } =
        useGetAttributeDefinitionQuery(id ? id : skipToken);

    const { data: attributeValueTypes, isLoading: isLoadingValueTypes } =
        useGetAttributeValueTypesForDropdownQuery();

    const [createAttributeDefinition] = useCreateAttributeDefinitionMutation();
    const [updateAttributeDefinition] = useUpdateAttributeDefinitionMutation();
    const [deleteAttributeDefintion] = useDeleteAttributeDefinitionMutation();

    const [isAttributeValid, setIsAttributeValid] = useState(null);
    const canManageAttribute = PermissionsUtil.isPermissionEnabled(
        user?.permissions,
        PERMISSIONS.SETUP.ATTRIBUTES.MANAGE
    );

    const blankAttributeDefinition: AttributeDefinition = {
        code: '',
        name: '',
        locked: false,
        options: '',
        attrValueTypeId: null,
        businessEntityId: user?.businessEntity?.id,
        attrEntityId: location?.state?.attrEntityId,
        defaultValue: false,
    };

    const {
        fields,
        handleFieldChange,
        handleChildrenRecords,
        formMethods,
        onCreate,
        onUpdate,
        onDelete,
        closeForm,
    } = useBaseForm({
        closePath: `/setup/attributes/definitions?${
            attributeDefinition?.attrEntityId || location?.state?.attrEntityId
        }`,
        blankEntity: blankAttributeDefinition,
        activeEntity: attributeDefinition,
        getDescription: () => {
            return `Attribute ${fields.code}`;
        },
        createEntity: () => {
            if (isArray(fields.defaultValue)) {
                fields.defaultValue = JSON.stringify(fields.defaultValue);
            }
            if (isArray(fields.options)) {
                fields.options = JSON.stringify(fields.options);
            }

            if (isArray(fields.optionsForDropdown)) {
                fields.options = JSON.stringify(
                    fields.optionsForDropdown.map((option: any) => {
                        return { id: option.value, option: option.label };
                    })
                );
            }

            return createAttributeDefinition({ postBody: fields });
        },
        updateEntity: () => {
            if (isArray(fields.defaultValue) || isObject(fields.defaultValue)) {
                fields.defaultValue = JSON.stringify(fields.defaultValue);
            }
            if (isArray(fields.options)) {
                fields.options = JSON.stringify(fields.options);
            }
            if (isArray(fields.optionsForDropdown)) {
                fields.options = JSON.stringify(
                    fields.optionsForDropdown.map((option: any) => {
                        return { id: option.value, option: option.label };
                    })
                );
            }

            return updateAttributeDefinition({
                id: id as unknown as number,
                postBody: fields,
            });
        },
        deleteEntity: () => {
            return deleteAttributeDefintion(id);
        },
    });

    /*
     * helper function to check the values of ranges and tolerances to make
     * sure they meet the requirements
     *  */
    const checkRangeTolValidity = () => {
        const { defaultValue, attrValueTypeId } = fields;

        const valueToCheck = isJsonString(defaultValue)
            ? JSON.parse(defaultValue)
            : defaultValue;
        const isRangeType = attrValueTypeId?.toString() === VALUE_TYPES.RANGE;
        const isTolType = attrValueTypeId?.toString() === VALUE_TYPES.TOL;

        if (isRangeType && !isValidRange(valueToCheck)) {
            formMethods.setIsFormValid(false);
            return;
        }

        if (isTolType && !isValidTol(valueToCheck)) {
            formMethods.setIsFormValid(false);
            return;
        }

        formMethods.setIsFormValid(true);
    };

    const isValidRange = (value: any) => {
        const lowRange = parseInt(value?.low);
        const highRange = parseInt(value?.high);
        return lowRange <= highRange;
    };

    const isValidTol = (value: any) => {
        const lowTRange = parseInt(value?.lowT);
        const highTRange = parseInt(value?.highT);
        return lowTRange <= highTRange;
    };

    useEffect(() => {
        checkRangeTolValidity();
    }, [fields]);

    return {
        fields,
        handleFieldChange,
        formMethods,
        isLoadingDefinition,
        onCreate,
        onUpdate,
        onDelete,
        closeForm,
        attributeValueTypes,
        isLoadingValueTypes,
        canManageAttribute,
        handleChildrenRecords,
        setIsAttributeValid,
    };
};

export default useAttributeDefinitionsForm;
