import React from 'react';
import { ColDef } from 'ag-grid-community';
import DefaultColumnTypes from '../../../components/grids/columns/Column.constants';
import { GridOptions } from '../../../components/grids/Grid.constants';
import BaseFormGrid from '../../../components/grids/BaseFormGrid';
import UseBoilerPlateNoteGrid from '../hooks/useBoilerPlateNoteGrid';

const BoilerPlateNoteGrid = () => {
    const {
        isLoading,
        boilerPlateNoteList,
        redirectToForm,
        canCreateBoilerPlateNote,
        canViewBoilerPlateNote,
    } = UseBoilerPlateNoteGrid();

    const columnDefs: ColDef[] = [
        {
            field: 'code',
            headerName: 'Code',
            minWidth: DefaultColumnTypes.ShortText.minWidth,
            filter: DefaultColumnTypes.ShortText.filter,
        },
        {
            field: 'description',
            headerName: 'Description',
            minWidth: DefaultColumnTypes.LongText.minWidth,
            filter: DefaultColumnTypes.LongText.filter,
            ...DefaultColumnTypes.AutoHeight,
        },
    ];

    const defaultColumnDefinitionOptions = {
        ...GridOptions.sortFilterAndWrapColumns,
        floatingFilter: true,
    };

    return (
        <BaseFormGrid
            displayGrid={canViewBoilerPlateNote}
            isLoading={isLoading}
            rowData={boilerPlateNoteList}
            columnDefs={columnDefs}
            gridClass="full-size-item"
            displayToolbar={true}
            displayCreateNewButton={canCreateBoilerPlateNote}
            displayExportDataButton={false}
            displayResetStateButton={false}
            onCreateNew={() => redirectToForm(null)}
            onRowClicked={({ data }) => redirectToForm(data)}
            defaultColDef={defaultColumnDefinitionOptions}
            sizeColumnsToFit={true}></BaseFormGrid>
    );
};
export default BoilerPlateNoteGrid;
