import {
    BaseCreateEntityArgs,
    BaseUpdateEntityArgs,
} from '../../../serviceInterfaces';
import { CasMaster } from '../../../../types/formulation';
import { datacorAppApi } from '../../../datacorAppApi';

const serviceUrl: string = 'casMaster/cas';

export const casMasterApi = datacorAppApi.injectEndpoints({
    endpoints: (build) => ({
        createCas: build.mutation<CasMaster, BaseCreateEntityArgs<CasMaster>>({
            query: (args) => ({
                url: serviceUrl,
                method: 'POST',
                body: args.postBody,
            }),
            invalidatesTags: ['Cas', 'ActiveCas'],
        }),
        updateCas: build.mutation<CasMaster, BaseUpdateEntityArgs<CasMaster>>({
            query: (args) => ({
                url: `${serviceUrl}/${args.id}`,
                method: 'PUT',
                body: args.postBody,
            }),
            invalidatesTags: ['Cas', 'ActiveCas'],
        }),
        deleteCas: build.mutation<CasMaster, string>({
            query: (id) => ({
                url: `${serviceUrl}/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Cas'],
        }),
        getCas: build.query<CasMaster, string>({
            query: (id) => {
                return {
                    url: `${serviceUrl}/${id}`,
                    params: { full: 'true' },
                };
            },
            providesTags: ['ActiveCas'],
        }),
        listCas: build.query<CasMaster[], void>({
            query: () => {
                return {
                    url: serviceUrl,
                };
            },
            providesTags: ['Cas'],
        }),
        listCasForPopup: build.query<any, void>({
            query: () => {
                return {
                    url: serviceUrl,
                };
            },
            transformResponse: (response: CasMaster[], meta: any, arg: any) => {
                return response.map((cas: CasMaster) => {
                    return {
                        value: cas.id,
                        label: cas.identifier,
                        chemicalName: cas.chemicalName,
                    };
                });
            },
            providesTags: ['Cas'],
        }),
    }),
});

export const {
    useCreateCasMutation,
    useUpdateCasMutation,
    useDeleteCasMutation,
    useGetCasQuery,
    useListCasQuery,
    useListCasForPopupQuery,
} = casMasterApi;
