import { isNil, isNilOrEmpty } from '../../../utils/objectUtils';

export const ColumnMinValueValidator = (
    columnLabel: string,
    minValue: number,
    columnData: number | string
) => {
    let isValid = true;
    let getErrorMessage = '';

    if (
        !isNilOrEmpty(columnLabel) &&
        !isNilOrEmpty(minValue) &&
        !isNilOrEmpty(columnData)
    ) {
        if (typeof columnData === 'number') {
            isValid = isNaN(columnData) || (columnData as number) >= minValue;

            if (!isValid) {
                getErrorMessage = `${columnLabel} must be greater than or equal to ${minValue}`;
            }
        } else {
            if (columnData.toString().length < minValue) {
                getErrorMessage = `${columnLabel} has a minimum length of ${minValue} characters`;
                isValid = false;
            }
        }
    }

    return {
        getErrorMessage,
        isValid,
    };
};
