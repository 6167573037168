import { FA_Asset } from '../../../types/FixedAsset.types';
import useDepreciationSummaryModal from './hooks/useDepreciationSummaryModal';
import React from 'react';
import {
    Box,
    Dialog,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import MultiSelectInput from '../../../components/form/formInputs/MultiSelectInput/MutliSelectInput';
import DateInput from '../../../components/form/formInputs/DateInput/DateInput';
import { LoadingButton } from '@mui/lab';
import { validateDateRange } from '../../../utils/comparisonUtils';
import TranslatableText from '../../../components/i18n/TranslatableText';
import { DepreciationSummaryGridDefs } from '../../../constants/i18n/translations/termDefinitions/accounting';

export interface DepreciationSummaryProps {
    open: boolean;
    onDepreciationSummaryLoaded: (entries: FA_Asset[]) => void;
    onClose: () => void;
    termSet?: any;
}

const DepreciationSummaryModal = (props: DepreciationSummaryProps) => {
    const {
        fields,
        handleFieldChange,
        assetTypeOptions,
        isLoadingAssetTypes,
        handleGetReportEntries,
        isLoadingReportEntries,
    } = useDepreciationSummaryModal(props);

    return (
        <Dialog
            onClose={props.onClose}
            open={props.open}
            fullWidth
            maxWidth={'sm'}>
            <DialogTitle>
                <Box display="flex" alignItems="center">
                    <Box flexGrow={1}>
                        <TranslatableText
                            termSet={props.termSet}
                            termKey={
                                DepreciationSummaryGridDefs.Depreciation_Summary_Options
                            }
                        />
                    </Box>
                    <Box>
                        <IconButton onClick={props.onClose}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </Box>
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <MultiSelectInput
                            label={
                                (
                                    <TranslatableText
                                        termSet={props.termSet}
                                        termKey={
                                            DepreciationSummaryGridDefs.Selection_Asset_Types
                                        }
                                    />
                                ) as unknown as string
                            }
                            id="assetTypes"
                            value={fields.assetTypes}
                            onChange={handleFieldChange}
                            disabled={isLoadingAssetTypes}
                            options={assetTypeOptions}
                            disableCloseOnSelect={true}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <DateInput
                            label={
                                <TranslatableText
                                    termSet={props.termSet}
                                    termKey={
                                        DepreciationSummaryGridDefs.Depreciation_Date_Begin
                                    }
                                />
                            }
                            id="startDate"
                            value={fields.startDate}
                            onChange={handleFieldChange}
                            required={true}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <DateInput
                            label={
                                <TranslatableText
                                    termSet={props.termSet}
                                    termKey={
                                        DepreciationSummaryGridDefs.Depreciation_Date_End
                                    }
                                />
                            }
                            id="endDate"
                            value={fields.endDate}
                            onChange={handleFieldChange}
                            required={true}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <LoadingButton
                            fullWidth
                            variant="contained"
                            type="submit"
                            disabled={
                                !validateDateRange(
                                    fields.startDate,
                                    fields.endDate
                                )
                            }
                            loading={isLoadingReportEntries}
                            onClick={handleGetReportEntries}>
                            <TranslatableText
                                termSet={props.termSet}
                                termKey={
                                    DepreciationSummaryGridDefs.Get_Depreciation_Summary
                                }
                            />
                        </LoadingButton>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
};

export default DepreciationSummaryModal;
