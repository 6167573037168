import useCurrencyRateTypeGrid from './hooks/useCurrencyRateTypeGrid';
import BaseFormGrid from '../../../components/grids/BaseFormGrid';
import React from 'react';
import { isNilOrEmpty } from '../../../utils/objectUtils';
import { cloneDeep } from 'lodash';

const CurrencyRateTypeGrid = () => {
    const {
        rateTypeList,
        isLoadingRateTypes,
        canView,
        canCreate,
        canUpdate,
        redirectToForm,
        handleGridEdits,
        colDefs,
    } = useCurrencyRateTypeGrid();

    return (
        !isNilOrEmpty(colDefs) && (
            <BaseFormGrid
                title="Exchange Rate Types"
                displayGrid={canView}
                isLoading={isLoadingRateTypes}
                rowData={cloneDeep(rateTypeList)}
                useRowDrag={canUpdate}
                sortKey={'sortOrder'}
                columnDefs={colDefs}
                gridClass="full-size-item"
                displayToolbar={true}
                displayCreateNewButton={canCreate}
                displayExportDataButton={true}
                displayResetStateButton={true}
                onCreateNew={redirectToForm}
                onRowClicked={({ data }) => redirectToForm(data)}
                onHandleGridEdits={handleGridEdits}
            />
        )
    );
};

export default CurrencyRateTypeGrid;
