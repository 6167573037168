import { CasRoute } from '../../../../types/formulation';
import { datacorAppApi } from '../../../datacorAppApi';

const serviceUrl: string = 'cas-route';

export const casToxicRoutesApi = datacorAppApi.injectEndpoints({
    endpoints: (build) => ({
        listToxicRouteDropdown: build.query<
            { label: string; value: bigint }[],
            void
        >({
            query: () => serviceUrl,
            transformResponse: (response: CasRoute[]) => {
                return response.map((casRoute) => {
                    return {
                        label: casRoute.type,
                        value: casRoute.id,
                    };
                });
            },
            providesTags: ['CasToxicRoutes'],
        }),
    }),
});

export const { useListToxicRouteDropdownQuery } = casToxicRoutesApi;
