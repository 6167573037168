import React from 'react';
import { useParams } from 'react-router-dom';
import {
    Card,
    CardContent,
    Grid,
    FormControlLabel,
    FormGroup,
    Checkbox,
} from '@mui/material';
import useSubscriberForm from './hooks/useSubscriberForm';
import TextInput from '../../components/form/formInputs/TextInput/TextInput';
import FormPartial from '../../components/form/formContainer/FormPartial';
import DatacorLogoSpinner from '../../components/datacorLogoSpinner/DatacorLogoSpinner';
import AgGrid from '../../components/grids/Grid';
import { AgGridColumn } from 'ag-grid-react';
import TreeView from '../../components/treeView/TreeView';
import { SUBSCRIBER_FIELDS } from '../../constants/revisionHistory/revisionHistory.constants';
import BaseForm from '../../components/form/baseForm/BaseForm';
import TranslatableText from '../../components/i18n/TranslatableText';
import { SubscriberFormDefs } from '../../constants/i18n/translations/termDefinitions/platform';

type ParamTypes = { id: string };

const SubscriberForm = () => {
    const { id } = useParams<ParamTypes>();
    const {
        user,
        fields,
        subscriber,
        businessEntities,
        handleClose,
        handleFieldChange,
        handleUpdateSubscriber,
        handleCreateSubscriber,
        departmentCodes,
        isDepartmentCodesLoading,
        onGridReady,
        isLoading,
        formMethods,
        termSet,
    } = useSubscriberForm(id);

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xl={9} lg={9} md={12} sm={12} xs={12}>
                    <BaseForm
                        title={
                            id ? (
                                fields.name
                            ) : (
                                <TranslatableText
                                    termKey={
                                        SubscriberFormDefs.Create_New_Subscriber
                                    }
                                    termSet={termSet}
                                />
                            )
                        }
                        formMethods={formMethods}
                        isLoading={isLoading}
                        isNew={!id}
                        onCancel={handleClose}
                        onCreate={handleCreateSubscriber}
                        onUpdate={handleUpdateSubscriber}
                        displayCreateButton={true}
                        displayUpdateButton={true}
                        displayDeleteButton={false}
                        showOnRevision={true}
                        fieldMappings={SUBSCRIBER_FIELDS}
                        entityUuid={subscriber?.uuid}
                        termSet={termSet}>
                        <FormPartial>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <TextInput
                                        fullWidth
                                        label={
                                            <TranslatableText
                                                termKey={
                                                    SubscriberFormDefs.Name
                                                }
                                                termSet={termSet}
                                            />
                                        }
                                        id="name"
                                        value={fields.name}
                                        formMethods={formMethods}
                                        onChange={handleFieldChange}
                                        required={true}
                                        maxLength={90}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextInput
                                        fullWidth
                                        label="Description"
                                        id="description"
                                        value={fields.description}
                                        onChange={handleFieldChange}
                                        formMethods={formMethods}
                                        maxLength={255}
                                    />
                                </Grid>
                                {user.isDatacorAdmin ? (
                                    <Grid item xs={6}>
                                        <FormGroup
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                            }}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        key={Math.random()}
                                                        id="isDatacor"
                                                        checked={
                                                            fields.isDatacor
                                                        }
                                                        onChange={
                                                            handleFieldChange
                                                        }
                                                        disabled={!!id}
                                                    />
                                                }
                                                label={
                                                    <TranslatableText
                                                        termKey={
                                                            SubscriberFormDefs.Is_Datacor
                                                        }
                                                        termSet={termSet}
                                                    />
                                                }
                                            />
                                        </FormGroup>
                                    </Grid>
                                ) : null}
                            </Grid>
                        </FormPartial>
                        <FormPartial
                            title={
                                (
                                    <TranslatableText
                                        termKey={
                                            SubscriberFormDefs.Department_Codes
                                        }
                                        termSet={termSet}
                                    />
                                ) as unknown as string
                            }>
                            {isDepartmentCodesLoading ? (
                                <DatacorLogoSpinner />
                            ) : (
                                <AgGrid
                                    rowData={departmentCodes || []}
                                    onGridReady={onGridReady}
                                    gridClass="full-width-grid">
                                    <AgGridColumn
                                        minWidth={150}
                                        field="name"
                                        headerComponentFramework={(
                                            props: any
                                        ) => {
                                            return (
                                                <TranslatableText
                                                    termSet={termSet}
                                                    termKey={
                                                        SubscriberFormDefs.Department_Name
                                                    }
                                                />
                                            );
                                        }}
                                    />
                                    <AgGridColumn
                                        minWidth={150}
                                        field="code"
                                        headerComponentFramework={(
                                            props: any
                                        ) => {
                                            return (
                                                <TranslatableText
                                                    termSet={termSet}
                                                    termKey={
                                                        SubscriberFormDefs.Department_Code
                                                    }
                                                />
                                            );
                                        }}
                                    />
                                </AgGrid>
                            )}
                        </FormPartial>
                    </BaseForm>
                </Grid>
                <Grid item xl={3} lg={3} md={12} sm={12} xs={12}>
                    <Card style={{ height: '100%' }}>
                        <CardContent>
                            {businessEntities && (
                                <div style={{ paddingLeft: '30px' }}>
                                    <h3>
                                        <TranslatableText
                                            termKey={
                                                SubscriberFormDefs.Business_Entity
                                            }
                                            termSet={termSet}
                                        />
                                    </h3>
                                    <TreeView
                                        dataSource={businessEntities}
                                        businessEntity
                                    />
                                </div>
                            )}
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </>
    );
};

export default SubscriberForm;
