import { BusinessEntity } from '../organizations/organizations.service';
import {
    BaseCreateEntityArgs,
    BaseUpdateEntityArgs,
} from '../serviceInterfaces';
import { isJsonString } from '../../utils/objectUtils';
import { datacorAppApi } from '../datacorAppApi';

export const attributesAPI = datacorAppApi.injectEndpoints({
    endpoints: (build) => ({
        getAttributeEntities: build.query<AttributeEntity[], void>({
            query: () => `attributes/entities`,
            providesTags: ['AttributeEntities'],
        }),
        getAttributeEntitiesForDropdown: build.query<any, void>({
            query: () => `attributes/entities`,
            transformResponse: (response: any) => {
                //TODO: update types
                return response.map((item: any) => {
                    return {
                        value: item.id,
                        label: item.name,
                    };
                });
            },
            providesTags: ['AttributeEntities'],
        }),
        getAttributeDefinitions: build.query<AttributeDefinition[], any>({
            query: (attrEntityId) => {
                return {
                    url: `attributes/definitions`,
                    params: { attrEntityId: attrEntityId },
                };
            },
            providesTags: ['AttributeDefinitions'],
        }),
        getAttributeDefinition: build.query<AttributeDefinition, any>({
            query: (id) => {
                return {
                    url: `attributes/definitions/${id}`,
                    params: { id: id },
                };
            },
            transformResponse: (response: any) => {
                return {
                    ...response,
                    options: isJsonString(response.options)
                        ? JSON.parse(response.options)
                        : [],
                    defaultValue: isJsonString(response.defaultValue)
                        ? JSON.parse(response.defaultValue)
                        : response.defaultValue,
                    optionsForDropdown: isJsonString(response.options)
                        ? JSON.parse(response.options).map((item: any) => {
                              return { label: item.option, value: item.id };
                          })
                        : [],
                };
            },
            providesTags: ['AttributeDefinitions'],
        }),
        getAttributeValueTypes: build.query<AttributeValueType[], void>({
            query: () => {
                return {
                    url: `attributes/value-types`,
                };
            },
            providesTags: ['AttributeEntities'],
        }),
        getAttributeValueTypesForDropdown: build.query<any, void>({
            query: () => {
                return {
                    url: `attributes/value-types`,
                };
            },
            transformResponse: (response: any) => {
                return response.map((item: any) => {
                    return { value: item.id, label: item.name };
                });
            },
            providesTags: ['AttributeEntities'],
        }),
        createAttributeDefinition: build.mutation<
            any,
            BaseCreateEntityArgs<AttributeDefinition>
        >({
            query: (args) => {
                return {
                    url: `attributes/definitions`,
                    method: 'POST',
                    body: args.postBody,
                };
            },
            invalidatesTags: ['AttributeDefinitions'],
        }),
        updateAttributeDefinition: build.mutation<
            any,
            BaseUpdateEntityArgs<AttributeDefinition>
        >({
            query: (args) => {
                return {
                    url: `attributes/definitions/${args.id}`,
                    method: 'PUT',
                    body: args.postBody,
                };
            },
            invalidatesTags: ['AttributeDefinitions'],
        }),
        deleteAttributeDefinition: build.mutation<any, string>({
            query: (id) => {
                return {
                    url: `attributes/definitions/${id}`,
                    method: 'DELETE',
                };
            },
            invalidatesTags: ['AttributeDefinitions'],
        }),

        getAttributeTemplates: build.query<AttributeTemplate[], any>({
            query: (attrEntityId) => {
                return {
                    url: `attributes/templates`,
                    params: { attrEntityId: attrEntityId },
                };
            },
            providesTags: ['AttributeTemplates'],
        }),
        getActiveAttributeTemplates: build.query<AttributeTemplate[], any>({
            query: (attrEntityId) => {
                return {
                    url: `attributes/templates/active`,
                    params: { attrEntityId: attrEntityId },
                };
            },
            providesTags: ['AttributeTemplates'],
        }),
        getActiveAttributeTemplatesForDropdown: build.query<any, any>({
            query: (attrEntityId) => {
                return {
                    url: `attributes/templates/active`,
                    params: { attrEntityId: attrEntityId },
                };
            },
            transformResponse: (response: any) => {
                return response.map((item: any) => {
                    return { value: item.id, label: item.name };
                });
            },
            providesTags: ['AttributeTemplates'],
        }),
        getAttributeTemplate: build.query<AttributeTemplate, any>({
            query: (id) => {
                return {
                    url: `attributes/templates/${id}`,
                    params: { id: id },
                };
            },
            providesTags: ['AttributeTemplates'],
        }),
        createAttributeTemplate: build.mutation<
            any,
            BaseCreateEntityArgs<AttributeTemplate>
        >({
            query: (args) => {
                return {
                    url: `attributes/templates`,
                    method: 'POST',
                    body: args.postBody,
                };
            },
            invalidatesTags: ['AttributeTemplates'],
        }),
        updateAttributeTemplate: build.mutation<
            any,
            BaseUpdateEntityArgs<AttributeTemplate>
        >({
            query: (args) => {
                return {
                    url: `attributes/templates/${args.id}`,
                    method: 'PUT',
                    body: args.postBody,
                };
            },
            invalidatesTags: ['AttributeTemplates'],
        }),
        deleteAttributeTemplate: build.mutation<any, any>({
            query: (id) => {
                return {
                    url: `attributes/templates/${id}`,
                    method: 'DELETE',
                };
            },
            invalidatesTags: ['AttributeTemplates'],
        }),
        lockAttributeTemplate: build.mutation<any, any>({
            query: (id) => {
                return {
                    url: `attributes/templates/${id}/lock`,
                    method: 'GET',
                };
            },
            invalidatesTags: ['AttributeTemplates', 'AttributeDefinitions'],
        }),
    }),
});

export const {
    useGetAttributeEntitiesQuery,
    useGetAttributeEntitiesForDropdownQuery,
    useGetAttributeDefinitionsQuery,
    useGetAttributeDefinitionQuery,
    useGetAttributeValueTypesQuery,
    useGetAttributeValueTypesForDropdownQuery,
    useCreateAttributeDefinitionMutation,
    useUpdateAttributeDefinitionMutation,
    useGetAttributeTemplatesQuery,
    useGetActiveAttributeTemplatesQuery,
    useGetActiveAttributeTemplatesForDropdownQuery,
    useDeleteAttributeDefinitionMutation,
    useGetAttributeTemplateQuery,
    useCreateAttributeTemplateMutation,
    useUpdateAttributeTemplateMutation,
    useDeleteAttributeTemplateMutation,
    useLockAttributeTemplateMutation,
} = attributesAPI;

export interface AttributeDefinition {
    id?: bigint;
    code: string;
    name: string;
    locked: boolean;
    options: string;
    attrValueTypeId: bigint;
    attrValueType?: AttributeValueType;
    businessEntityId: bigint;
    attrEntityId: bigint;
    attrEntity?: AttributeEntity;
    attrBusinessEntity?: BusinessEntity;
    defaultValue: string | boolean | number;
}

export interface AttributeValueType {
    id: bigint;
    code: string;
    name: string;
    updatedAt: number;
    createdAt: number;
}

export interface AttributeEntity {
    id: bigint;
    applicationId: bigint;
    code: string;
    name: string;
    createdAt: number;
    updatedAt: number;
}

export interface AttributeTemplate {
    id?: bigint;
    code: string;
    name: string;
    locked: boolean;
    attrDefinitions: AttributeDefinition[];
    businessEntityId: bigint;
    attrEntityId: bigint;
}
