import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormFields } from '../../../libs/hooksLib';
import { isNilOrEmpty } from '../../../utils/objectUtils';
import { Auth } from 'aws-amplify';
import { showNotificationError } from '../../../libs/errorLib';
import { useDispatch } from 'react-redux';

const useChangeEmail = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [fields, handleFieldChange] = useFormFields({
        email: '',
    });
    const [isSendingCode, setIsSendingCode] = useState(false);

    function validateEmailForm() {
        return !isNilOrEmpty(fields.email);
    }

    async function handleUpdateClick(
        event: React.MouseEvent<HTMLButtonElement>
    ) {
        event.preventDefault();

        setIsSendingCode(true);

        try {
            const user = await Auth.currentAuthenticatedUser();
            await Auth.updateUserAttributes(user, {
                email: fields.email.toLowerCase(),
            });
            navigate(`/settings/emailConfirmation/${fields.email}`);
        } catch (error) {
            showNotificationError(error, 'error', dispatch);
            setIsSendingCode(false);
        }
    }

    return {
        fields,
        handleFieldChange,
        handleUpdateClick,
        isSendingCode,
        validateEmailForm,
    };
};

export default useChangeEmail;
