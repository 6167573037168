import { useEffect } from 'react';
import { HandleFieldChangedEvent } from '../../../../../libs/hooksLib';

export interface ItemAttrVerificationHelperProps {
    isParentLoading: boolean;
    handleFieldChange: (event: HandleFieldChangedEvent) => void;
    itemAttributes: any;
}
const useItemAttrVerificationHelper = (
    props: ItemAttrVerificationHelperProps
) => {
    const { isParentLoading, handleFieldChange, itemAttributes } = props;

    useEffect(() => {
        if (!isParentLoading) {
            handleFieldChange({
                target: {
                    id: 'verifyItemAttributes',
                    value: Array.isArray(itemAttributes)
                        ? itemAttributes
                        : [itemAttributes],
                },
            });
        }
    }, [isParentLoading, itemAttributes]);
};

export default useItemAttrVerificationHelper;
