import React from 'react';
import SequenceBase from '../../../../components/sequences/SequenceBase';
import {
    useGetSdsHeaderSequenceParametersQuery,
    useGetSdsHeaderSequenceQuery,
    useListSdsHeaderSequenceParentsQuery,
    useUpdateSdsHeaderSequenceMutation,
} from '../../../../services/formulation/sds/header/sdsHeader.service';
import { SequencesFormDefs } from '../../../../constants/i18n/translations/termDefinitions/formulation';
import TranslatableText from '../../../../components/i18n/TranslatableText';

const SdsSequences = ({ termSet }: any) => {
    return (
        <SequenceBase
            sequenceQuery={useGetSdsHeaderSequenceQuery}
            sequenceMutation={useUpdateSdsHeaderSequenceMutation}
            parameterQuery={useGetSdsHeaderSequenceParametersQuery}
            useParent={true}
            parentQuery={useListSdsHeaderSequenceParentsQuery}
            title={
                <TranslatableText
                    termSet={termSet}
                    termKey={SequencesFormDefs.SDS_Title}
                />
            }
            closePath={`/formulation/sds`}
        />
    );
};

export default SdsSequences;
