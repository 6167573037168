import React from 'react';
import BaseGrid from '../../components/grids/BaseGrid';
import DatacorLogoSpinner from '../../components/datacorLogoSpinner/DatacorLogoSpinner';
import MultiSelectGridModal from '../../components/modals/MultiSelectGridModal';
import useAttributeTemplateDefinitionsGrid, {
    AttributeTemplateDefinitionsGridProps,
} from './hooks/useAttributeTemplateDefinitionsGrid';

const AttributeTemplateDefinitionsGrid = ({
    canManageAttribute,
    isLoadingTemplate,
    attributeTemplate,
    updateChildList,
}: AttributeTemplateDefinitionsGridProps) => {
    const {
        gridOptions,
        attributeDefintionsColDef,
        filteredRowData,
        parentDefinitionsColDef,
        showMultiSelectGridModal,
        handleGridEdits,
        handleSelected,
        setShowMultiSelectGridModal,
    } = useAttributeTemplateDefinitionsGrid({
        attributeTemplate,
        updateChildList,
        isLoadingTemplate,
        canManageAttribute,
    });

    const MultiSelectModal = () => {
        return (
            <>
                <MultiSelectGridModal
                    title="Attribute Definition Selection"
                    open={showMultiSelectGridModal}
                    onClose={() => setShowMultiSelectGridModal(false)}
                    onSelected={(selectedRecords: any) =>
                        handleSelected(selectedRecords)
                    }
                    rowData={filteredRowData || []}
                    columnDefs={attributeDefintionsColDef}
                    preSelectedIds={() => {
                        return gridOptions?.map((def: any) => def.id);
                    }}
                />
            </>
        );
    };

    return !parentDefinitionsColDef && !isLoadingTemplate ? (
        <DatacorLogoSpinner />
    ) : (
        <>
            <BaseGrid
                isEditable={canManageAttribute}
                displayToolbar={true}
                displayCreateNewButton={
                    canManageAttribute && !attributeTemplate.locked
                }
                displayDeleteButton={
                    canManageAttribute && !attributeTemplate.locked
                }
                onCreateNew={() => setShowMultiSelectGridModal(true)}
                displayGrid={true}
                dataKey={'code'}
                createRow={false}
                rowData={gridOptions}
                columnDefs={parentDefinitionsColDef}
                onHandleGridEdits={(records: any) => handleGridEdits(records)}
                newRowPlaceholderValues={{
                    id: 'id',
                    code: 'Select Definition...',
                    name: 'Definition Name',
                    attrValueTypeId: 'Definition Value Type',
                }}
            />
            <MultiSelectModal />
        </>
    );
};

export default AttributeTemplateDefinitionsGrid;
