import React, { useEffect } from 'react';
import { isJsonString, isNilOrEmpty } from '../../../utils/objectUtils';
import RenderDefaultValue from '../../attributes/RenderDefaultValue';

const RenderDefinition = (props: any) => {
    const def = props.definition;

    useEffect(() => {
        if (!isNilOrEmpty(props.fields?.itemAttributes)) {
            const itemAttrObj = {};
            for (const attr of props.fields?.itemAttributes) {
                //@ts-ignore
                itemAttrObj[attr.attrDefinitionId] = isJsonString(attr.data)
                    ? JSON.parse(attr.data)
                    : attr.data;
            }
            props.setItemAttrValues(itemAttrObj);
        }
    }, [def, props.fields]);

    useEffect(() => {
        props.setItemAttrValues((prev: any) => ({
            ...prev,
            [def.id]: { data: def.defaultValue, code: def.code },
        }));
    }, [def]);

    return (
        <RenderDefaultValue
            handleFieldChange={(event: any) =>
                props.setItemAttrValues((prev: any) => ({
                    ...prev,
                    [def.id]: {
                        ...props.itemAttrValues[def.id],
                        data:
                            event.target.type === 'checkbox'
                                ? event.target.checked
                                : event.target.value,
                    },
                }))
            }
            fieldLabel={def.name}
            formMethods={props.formMethods}
            value={props.itemAttrValues[def.id]?.data || ''}
            attrValueTypeId={def.attrValueTypeId?.toString()}
            optionsForDropdown={
                isJsonString(def.options)
                    ? JSON.parse(def.options).map((item: any) => {
                          return { label: item.option, value: item.id };
                      })
                    : []
            }
            isNew={false}
            disabled={props.disabled}
            setIsAttributeValid={props.setIsAttributeValid}
        />
    );
};

export default RenderDefinition;
