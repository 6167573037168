import React, { ChangeEvent, useEffect, useState } from 'react';
import {
    useCreateDepreciationMethodMutation,
    useDeleteDepreciationMethodMutation,
    useGetAllCostCodesQuery,
    useGetDepreciationMethodQuery,
    useGetDepreciationMethodStatusesForDropdownQuery,
    useGetDepreciationMethodTypesForDropdownQuery,
    useUpdateDepreciationMethodMutation,
} from '../../../services/fixedAssets/fixedAssets.service';
import { skipToken } from '@reduxjs/toolkit/query';
import {
    DepreciationBasis,
    FA_AssetCostCode,
    FA_DepreciationMethod,
    FA_DepreciationMethodCost,
    FA_DepreciationMethodSchedule,
    FA_DepreciationMethodType,
} from '../../../types/FixedAsset.types';
import useBaseForm from '../../form/hooks/useBaseForm';
import { GridReadyEvent, RowNode } from 'ag-grid-community';
import { isNil, isNilOrEmpty } from '../../../utils/objectUtils';
import { GridApi } from 'ag-grid-community/dist/lib/gridApi';
import { RoutingValues, SelectionOption } from '../../../types/Shared.types';
import _ from 'lodash';
import { PermissionsUtil } from '../../../utils/permissions/permissionsUtil';
import { PERMISSIONS } from '../../../constants/permissions/Permissions.constants';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import useTranslation from '../../../components/i18n/hooks/useTranslation';
import { ACCOUNTING_DEFS } from '../../../constants/i18n/translations/termSetDefinitions/accounting';
import TranslatableText from '../../../components/i18n/TranslatableText';
import { DepreciationMethodsFormDefs } from '../../../constants/i18n/translations/termDefinitions/accounting';

const UseFixedAssetDepreciationMethodForm = (id: string) => {
    const user = useSelector((state: RootState) => state.user);
    const depreciationMethod = useSelector(
        (state: RootState) => state.fixedAssets
    );
    const [gridApi, setGridApi] = useState<GridApi>(null);
    const [depreciationScheduleWorksheet, setDepreciationScheduleWorksheet] =
        useState<FA_DepreciationMethodSchedule[]>([]);
    const [depreciationBasis, setDepreciationBasis] =
        useState<DepreciationBasis>(DepreciationBasis.None);
    const [numberOfRecoveryIntervals, setNumberOfRecoveryIntervals] =
        useState<number>(1);
    const [totalAmount, setTotalAmount] = useState<string>(' ');

    const { data: activeMethod, isLoading: isLoadingMethod } =
        useGetDepreciationMethodQuery(
            id === RoutingValues.newId ? skipToken : id
        );
    const [createMethod] = useCreateDepreciationMethodMutation();
    const [updateMethod] = useUpdateDepreciationMethodMutation();
    const [deleteMethod] = useDeleteDepreciationMethodMutation();

    const { termSet } = useTranslation(
        ACCOUNTING_DEFS.DEPRECIATION_METHODS_FORM
    );

    const { data: statusOptions, isLoading: isLoadingStatuses } =
        useGetDepreciationMethodStatusesForDropdownQuery();
    const { data: typeOptions, isLoading: isLoadingTypes } =
        useGetDepreciationMethodTypesForDropdownQuery();
    const { data: costCodeList, isLoading: isLoadingCostCodes } =
        useGetAllCostCodesQuery();

    const canCreateDepreciationMethod = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.FIXED_ASSETS.DEPRECIATION_METHODS.CREATE
    );
    const canUpdateDepreciationMethod = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.FIXED_ASSETS.DEPRECIATION_METHODS.EDIT
    );
    const canDeleteDepreciationMethod = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.FIXED_ASSETS.DEPRECIATION_METHODS.DELETE
    );

    const formulaOptions: SelectionOption[] = [
        {
            label: (
                <TranslatableText
                    termSet={termSet}
                    termKey={DepreciationMethodsFormDefs.Straight_Line}
                />
            ) as unknown as string,
            value: 'Total Amount / Recovery Term',
        },
        {
            label: (
                <TranslatableText
                    termSet={termSet}
                    termKey={DepreciationMethodsFormDefs.Double_Declining}
                />
            ) as unknown as string,
            value: '((Total Amount - Accumulated Depreciation)  / Recovery Term) * 2',
        },
        {
            label: (
                <TranslatableText
                    termSet={termSet}
                    termKey={DepreciationMethodsFormDefs.Sum_Of_Years_Digits}
                />
            ) as unknown as string,
            value: 'Total Amount / ((Max Interval - (Current Interval - 1)) / Sum of Intervals)',
        },
    ];

    const blankMethod: FA_DepreciationMethod = {
        sortOrder: depreciationMethod.sortOrderSet.maxValue,
        code: '',
        name: '',
        description: '',
        depreciationMethodStatusId: null,
        depreciationMethodTypeId: null,
        depreciationFormula: '',
        costs: [],
        schedule: [],
    };

    const onGridReady = (params: GridReadyEvent) => {
        setGridApi(params.api);
    };

    const onRowSelected = () => {
        updateTotalAmount();
    };

    useEffect(() => {
        if (activeMethod && gridApi) {
            gridApi.forEachNode((node: RowNode) => {
                node.setSelected(
                    activeMethod.costs?.some(
                        (cost: FA_DepreciationMethodCost) =>
                            cost.costCodeId === node.data.id
                    )
                );
            });

            updateTotalAmount();
        }
    }, [activeMethod, gridApi]);

    useEffect(() => {
        updateTotalAmount();

        if (!isNilOrEmpty(activeMethod?.schedule)) {
            setNumberOfRecoveryIntervals(activeMethod.schedule.length);
            setDepreciationScheduleWorksheet(
                _.sortBy(
                    activeMethod?.schedule,
                    (dmSchedule: FA_DepreciationMethodSchedule) =>
                        dmSchedule.interval
                ).map((dmMethod: FA_DepreciationMethodSchedule) => {
                    return {
                        interval: dmMethod.interval,
                        percentage: dmMethod.percentage * 100,
                    };
                })
            );
        } else {
            setNumberOfRecoveryIntervals(1);
            setDepreciationScheduleWorksheet([{ interval: 1, percentage: 0 }]);
        }
    }, [activeMethod]);

    const updateTotalAmount = () => {
        if (!isNilOrEmpty(gridApi?.getSelectedRows())) {
            let newTotalAmount = '';

            gridApi.getSelectedRows().forEach((costCode: FA_AssetCostCode) => {
                newTotalAmount = isNil(newTotalAmount)
                    ? ''
                    : `${newTotalAmount} ${costCode.isAdd ? '+' : '-'}`;
                newTotalAmount = `${newTotalAmount} ${costCode.code}`;
            });

            setTotalAmount(`( ${newTotalAmount} )`);
        } else {
            setTotalAmount(' ');
        }
    };

    const buildPostBody = (): FA_DepreciationMethod => {
        const selectedCosts: FA_DepreciationMethodCost[] = gridApi
            ?.getSelectedRows()
            .map((costCode: FA_AssetCostCode) => {
                return { costCodeId: costCode.id };
            });

        const actualSchedule: FA_DepreciationMethodSchedule[] =
            depreciationScheduleWorksheet?.map(
                (dmSchedule: FA_DepreciationMethodSchedule) => {
                    return {
                        interval: dmSchedule.interval,
                        percentage: dmSchedule.percentage / 100,
                    };
                }
            );

        return {
            ...fields,
            costs: selectedCosts,
            schedule: actualSchedule,
        };
    };

    const {
        fields,
        handleFieldChange,
        formMethods,
        onCreate,
        onUpdate,
        onDelete,
        closeForm,
    } = useBaseForm({
        closePath: '/accounting/fixedAssets/depreciationMethod',
        blankEntity: blankMethod,
        activeEntity: activeMethod,
        getDescription: () => {
            return `Depreciation Method ${fields.code}`;
        },
        createEntity: () => {
            return createMethod({ postBody: buildPostBody() });
        },
        updateEntity: () => {
            return updateMethod({
                id: id as unknown as number,
                postBody: buildPostBody(),
            });
        },
        deleteEntity: () => {
            return deleteMethod(id);
        },
    });

    useEffect(() => {
        if (
            typeOptions &&
            !isNilOrEmpty(typeOptions) &&
            fields.depreciationMethodTypeId
        ) {
            const typeSelectionOption: SelectionOption = typeOptions.find(
                (option: SelectionOption) =>
                    option.value === fields.depreciationMethodTypeId
            );
            if (typeSelectionOption) {
                const dmType: FA_DepreciationMethodType =
                    typeSelectionOption.object;

                if (dmType.predefinedSchedule) {
                    setDepreciationBasis(DepreciationBasis.Schedule);
                    formMethods.updateField('depreciationFormula', '');
                } else {
                    setDepreciationBasis(DepreciationBasis.Formula);
                }

                return;
            }
        }

        setDepreciationBasis(DepreciationBasis.None);
    }, [fields.depreciationMethodTypeId, typeOptions]);

    const handleRecoveryIntervalChange = (
        event: ChangeEvent<HTMLInputElement>
    ) => {
        const newInterval: number = event.target.value as unknown as number;
        setNumberOfRecoveryIntervals(newInterval);

        let recoveryIntervalArray: FA_DepreciationMethodSchedule[] = [];

        for (let i = 1; i <= newInterval; i++) {
            recoveryIntervalArray.push({ interval: i, percentage: 0 });
        }

        setDepreciationScheduleWorksheet(recoveryIntervalArray);
    };

    return {
        fields,
        handleFieldChange,
        formMethods,
        onCreate,
        onUpdate,
        onDelete,
        closeForm,
        isLoadingMethod,
        isLoadingStatuses,
        isLoadingTypes,
        isLoadingCostCodes,
        statusOptions,
        typeOptions,
        formulaOptions,
        costCodeList,
        onGridReady,
        onRowSelected,
        updateTotalAmount,
        buildPostBody,
        depreciationScheduleWorksheet,
        depreciationBasis,
        totalAmount,
        numberOfRecoveryIntervals,
        handleRecoveryIntervalChange,
        canCreateDepreciationMethod,
        canUpdateDepreciationMethod,
        canDeleteDepreciationMethod,
        termSet,
    };
};

export default UseFixedAssetDepreciationMethodForm;
