import React, { useContext, useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import TextInput from '../TextInput/TextInput';
import TranslatableText from '../../../i18n/TranslatableText';
import { BuildingsFormDefs } from '../../../../constants/i18n/translations/termDefinitions/platform';
import SelectInput from '../SelectInput/SelectInput';
import {
    useGetCountriesForDropdownQuery,
    useGetCountriesQuery,
    useGetStatesQuery,
} from '../../../../services/organizations/organizations.service';
import SettingsContext from '../../../../contexts/settings.context';
import { isNilOrEmpty } from '../../../../utils/objectUtils';

interface AddressInputProps {
    fields: any;
    formMethods: any;
    handleFieldChange: (_: any) => void;
    termSet: any;
    id: string;
    setValues: (_: any) => void;
}
const AddressInput = ({
    fields,
    formMethods,
    handleFieldChange,
    termSet,
    id,
    setValues,
}: AddressInputProps) => {
    const { settings } = useContext(SettingsContext);
    const { businessEntitySettings } = settings;
    const { data: countries } = useGetCountriesQuery();
    const { data: countryOptions } = useGetCountriesForDropdownQuery();
    const { data: states } = useGetStatesQuery();
    const [stateSelectOptions, setStateSelectOptions] = useState([]);

    useEffect(() => {
        setOptionsByCountry();
    }, [fields?.countryCodeId, states]);

    /**
     * we can't update the default countries from
     * the BE setting until the fields have been
     * initialized - this is why we have so many checks
     */
    useEffect(() => {
        if (
            !isNilOrEmpty(countries) &&
            !isNilOrEmpty(countryOptions) &&
            businessEntitySettings?.defaultCountry &&
            Object.keys(fields).length !== 0
        ) {
            handleSetDefaultCountryAndState();
        }
    }, [
        countries,
        businessEntitySettings,
        states,
        countryOptions,
        Object.keys(fields).length,
    ]);

    /**
     * when we switch countries we need to update
     * the state list
     */
    const setOptionsByCountry = () => {
        if (!states && !fields?.countryCodeId) {
            return;
        }

        const statesFilteredByCountry = states
            ?.filter((state: any) => {
                return state.countryCodeId === fields?.countryCodeId;
            })
            .map((state: any) => {
                return {
                    value: state.id,
                    label: state.code + ' - ' + state.name,
                };
            });

        setStateSelectOptions(statesFilteredByCountry);
    };
    /**
     * if we are creating a new building
     * we need to set the country to
     * the default in our BE settings
     */
    const handleSetDefaultCountryAndState = () => {
        if (!id) {
            const defaultId = countries?.find(
                (country: any) =>
                    country.code2 === businessEntitySettings?.defaultCountry
            )?.id;

            setValues({
                countryCodeId: defaultId || '',
                stateCodeId: businessEntitySettings?.defaultState || '',
            });
        }
    };

    return (
        <>
            {id ? (
                <>
                    <Grid item xs={6}>
                        <TextInput
                            fullWidth
                            label={
                                <TranslatableText
                                    termKey={BuildingsFormDefs.Street}
                                    termSet={termSet}
                                />
                            }
                            id="street"
                            value={fields.street}
                            formMethods={formMethods}
                            onChange={handleFieldChange}
                            required={true}
                            multiline
                            rows={4}
                            maxLength={300}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextInput
                            fullWidth
                            label={
                                <TranslatableText
                                    termKey={BuildingsFormDefs.City}
                                    termSet={termSet}
                                />
                            }
                            id="city"
                            value={fields.city}
                            formMethods={formMethods}
                            onChange={handleFieldChange}
                            required={true}
                            maxLength={90}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextInput
                            fullWidth
                            label={
                                <TranslatableText
                                    termKey={BuildingsFormDefs.County}
                                    termSet={termSet}
                                />
                            }
                            id="county"
                            value={fields.county}
                            formMethods={formMethods}
                            onChange={handleFieldChange}
                            required={true}
                            maxLength={90}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <SelectInput
                            fullWidth
                            label={
                                <TranslatableText
                                    termKey={BuildingsFormDefs.State}
                                    termSet={termSet}
                                />
                            }
                            id="stateCodeId"
                            value={fields?.stateCodeId}
                            onChange={handleFieldChange}
                            options={stateSelectOptions || []}
                            formMethods={formMethods}
                            required={true}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextInput
                            fullWidth
                            label={
                                <TranslatableText
                                    termKey={BuildingsFormDefs.Zip}
                                    termSet={termSet}
                                />
                            }
                            id="postalCode"
                            value={fields?.postalCode}
                            onChange={handleFieldChange}
                            required={true}
                            formMethods={formMethods}
                            maxLength={90}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <SelectInput
                            fullWidth
                            label={
                                <TranslatableText
                                    termKey={BuildingsFormDefs.Country}
                                    termSet={termSet}
                                />
                            }
                            id="countryCodeId"
                            value={fields?.countryCodeId}
                            onChange={handleFieldChange}
                            options={countryOptions || []}
                            formMethods={formMethods}
                            required={true}
                        />
                    </Grid>
                </>
            ) : (
                <>
                    <Grid item xs={6}>
                        <SelectInput
                            fullWidth
                            label={
                                <TranslatableText
                                    termKey={BuildingsFormDefs.Country}
                                    termSet={termSet}
                                />
                            }
                            id="countryCodeId"
                            value={fields?.countryCodeId}
                            onChange={handleFieldChange}
                            options={countryOptions || []}
                            formMethods={formMethods}
                            required={true}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextInput
                            fullWidth
                            label={
                                <TranslatableText
                                    termKey={BuildingsFormDefs.Zip}
                                    termSet={termSet}
                                />
                            }
                            id="postalCode"
                            value={fields?.postalCode}
                            onChange={handleFieldChange}
                            required={true}
                            formMethods={formMethods}
                            maxLength={90}
                            disabled={fields?.countryCodeId?.length <= 0}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <SelectInput
                            fullWidth
                            label={
                                <TranslatableText
                                    termKey={BuildingsFormDefs.State}
                                    termSet={termSet}
                                />
                            }
                            id="stateCodeId"
                            value={fields?.stateCodeId}
                            onChange={handleFieldChange}
                            options={stateSelectOptions || []}
                            formMethods={formMethods}
                            required={true}
                            disabled={fields?.postalCode?.length <= 0}
                        />
                        <div style={{ padding: '10px' }} />
                        <TextInput
                            fullWidth
                            label={
                                <TranslatableText
                                    termKey={BuildingsFormDefs.County}
                                    termSet={termSet}
                                />
                            }
                            id="county"
                            value={fields.county}
                            formMethods={formMethods}
                            onChange={handleFieldChange}
                            required={true}
                            maxLength={90}
                            disabled={fields?.street?.length <= 0}
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <TextInput
                            fullWidth
                            label={
                                <TranslatableText
                                    termKey={BuildingsFormDefs.Street}
                                    termSet={termSet}
                                />
                            }
                            id="street"
                            value={fields.street}
                            formMethods={formMethods}
                            onChange={handleFieldChange}
                            required={true}
                            multiline
                            rows={4}
                            maxLength={300}
                            disabled={fields?.stateCodeId?.length <= 0}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextInput
                            fullWidth
                            label={
                                <TranslatableText
                                    termKey={BuildingsFormDefs.City}
                                    termSet={termSet}
                                />
                            }
                            id="city"
                            value={fields.city}
                            formMethods={formMethods}
                            onChange={handleFieldChange}
                            required={true}
                            maxLength={90}
                            disabled={fields?.county?.length <= 0}
                        />
                    </Grid>
                </>
            )}
        </>
    );
};

export default AddressInput;
