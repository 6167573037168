import { RootStateOrAny, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useListBoilerPlateNoteQuery } from '../../../services/organizations/organizations.service';
import { skipToken } from '@reduxjs/toolkit/query';
import { PermissionsUtil } from '../../../utils/permissions/permissionsUtil';
import { PERMISSIONS } from '../../../constants/permissions/Permissions.constants';

const UseBoilerPlateNoteGrid = () => {
    const navigate = useNavigate();

    const user = useSelector((state: RootStateOrAny) => state.user);

    const { isLoading, data: boilerPlateNoteList } =
        useListBoilerPlateNoteQuery(
            user?.businessEntity?.id
                ? user?.businessEntity?.id.toString()
                : skipToken
        );

    const canCreateBoilerPlateNote = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.SETUP.BOILER_PLATE_NOTE.CREATE
    );
    const canUpdateBoilerPlateNote = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.SETUP.BOILER_PLATE_NOTE.EDIT
    );
    const canViewBoilerPlateNote = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.SETUP.BOILER_PLATE_NOTE.VIEW
    );

    const redirectToForm = (row: any) => {
        if (row?.id) {
            navigate(`/setup/boilerPlateNote/${row.id}`);
        } else {
            navigate('/setup/boilerPlateNote/new');
        }
    };

    return {
        isLoading,
        boilerPlateNoteList,
        redirectToForm,
        canCreateBoilerPlateNote,
        canUpdateBoilerPlateNote,
        canViewBoilerPlateNote,
    };
};

export default UseBoilerPlateNoteGrid;
