import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useListDocTemplatesQuery } from '../../../services/documentDesigner/documentDesigner.service';
import DefaultColumnTypes from '../../../components/grids/columns/Column.constants';
import LockedCellRenderer from '../../../components/grids/cellRenderers/lockedCell.renderer';
import BooleanCellRenderer from '../../../components/grids/cellRenderers/booleanCell.renderer';
import CustomHeader from '../../../components/grids/CustomHeader';
import { PLATFORM_DEFS } from '../../../constants/i18n/translations/termSetDefinitions/platform';
import useTranslation from '../../../components/i18n/hooks/useTranslation';
import { DocTemplateGridDefs } from '../../../constants/i18n/translations/termDefinitions/platform';
import { RootState } from '../../../store';
import { useSelector } from 'react-redux';
import { PERMISSIONS } from '../../../constants/permissions/Permissions.constants';
import { PermissionsUtil } from '../../../utils/permissions/permissionsUtil';

//TODO: add tests
const useDocumentTemplatesGrid = () => {
    let navigate = useNavigate();
    const user = useSelector((state: RootState) => state.user);
    const { data: docTemplates, isLoading } = useListDocTemplatesQuery();
    const { termSet, isLoadingTermSet } = useTranslation(
        PLATFORM_DEFS.DOC_TEMPLATE_GRID
    );

    const canCreateDocumentTemplate = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.DOCUMENT_DESIGNER.DOC_TEMPLATE.CREATE
    );

    const canViewDocumentTemplate = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.DOCUMENT_DESIGNER.DOC_TEMPLATE.VIEW
    );

    //TODO: add translations for colDef headers
    const colDefs = [
        {
            headerName: 'Code',
            field: 'code',
            headerComponentFramework: (params: any) => {
                return (
                    <CustomHeader
                        {...params}
                        termSet={termSet}
                        termKey={DocTemplateGridDefs.Code}
                    />
                );
            },
        },
        {
            headerName: 'Name',
            field: 'name',
            headerComponentFramework: (params: any) => {
                return (
                    <CustomHeader
                        {...params}
                        termSet={termSet}
                        termKey={DocTemplateGridDefs.Name}
                    />
                );
            },
        },
        {
            headerName: 'Default',
            field: 'default',
            headerComponentFramework: (params: any) => {
                return (
                    <CustomHeader
                        {...params}
                        termSet={termSet}
                        termKey={DocTemplateGridDefs.Default}
                    />
                );
            },
            ...DefaultColumnTypes.Boolean,
        },
        {
            headerName: 'Locked',
            field: 'locked',
            headerComponentFramework: (params: any) => {
                return (
                    <CustomHeader
                        {...params}
                        termSet={termSet}
                        termKey={DocTemplateGridDefs.Locked}
                    />
                );
            },
            ...DefaultColumnTypes.Locked,
        },
        {
            headerName: 'Output File Name',
            field: 'outputFileName',
            headerComponentFramework: (params: any) => {
                return (
                    <CustomHeader
                        {...params}
                        termSet={termSet}
                        termKey={DocTemplateGridDefs.OutputFileName}
                    />
                );
            },
        },
    ];

    const frameworkComponents = {
        lockedCellRenderer: LockedCellRenderer,
        booleanCellRenderer: BooleanCellRenderer,
    };

    const redirectToForm = (row: any) => {
        if (row?.id) {
            navigate(`/setup/document-designer/templates/${row.id}`);
        } else {
            navigate('/setup/document-designer/templates/new');
        }
    };

    return {
        docTemplates,
        isLoading,
        colDefs,
        frameworkComponents,
        redirectToForm,
        termSet,
        isLoadingTermSet,
        canCreateDocumentTemplate,
        canViewDocumentTemplate,
    };
};

export default useDocumentTemplatesGrid;
