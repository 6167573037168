import { datacorAppApi } from '../datacorAppApi';

export const systemApi = datacorAppApi.injectEndpoints({
    endpoints: (build) => ({
        listApplications: build.query<Array<Application>, void>({
            query: () => ({
                url: `/platform-system/applications`,
                method: 'GET',
            }),
            providesTags: ['Applications'],
        }),
    }),
});

export const { useListApplicationsQuery } = systemApi;

export interface Application {
    id: bigint;
    code: string;
    name: string;
    description?: string;
}
