import { useContext, useEffect, useState } from 'react';
import { RootStateOrAny, useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { GridReadyEvent, GridApi, ColumnApi } from 'ag-grid-community';
import { RootState } from '../../../../../store';
import { updateGridModels } from '../../../../../store/grids';
import { GhsHazardPhrase } from '../../../../../types/formulation';
import { PermissionsUtil } from '../../../../../utils/permissions/permissionsUtil';
import { PERMISSIONS } from '../../../../../constants/permissions/Permissions.constants';
import _ from 'lodash';
import { useListGhsHazardPhrasesQuery } from '../../../../../services/formulation/ghsHazardPhrases/ghsHazardPhrases.service';
import SettingsContext from '../../../../../contexts/settings.context';
import { useGetTermSetQuery } from '../../../../../services/i18n/i18n.service';
import { FORMULATION_DEFS } from '../../../../../constants/i18n/translations/termSetDefinitions/formulation';
import { skipToken } from '@reduxjs/toolkit/query';

export interface HazardGridProps {
    handleSetPhraseList(phraseList: Array<GhsHazardPhrase>): void;
    defaultLanguageId: bigint;
    currentPhraseId: string;
    handlePromptFormDirty(onConfirm: () => void, onCancel?: () => void): void;
}

const useGhsHazardPhraseGrid = ({
    handleSetPhraseList,
    defaultLanguageId,
    currentPhraseId,
    handlePromptFormDirty,
}: HazardGridProps) => {
    const user = useSelector((state: RootStateOrAny) => state.user);
    const { formulation } = useSelector((state: RootState) => state.grids);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isLoadingData, setIsLoadingData] = useState(true);
    const [gridApi, setGridApi] = useState(null as GridApi);
    const [gridColumnApi, setGridColumnApi] = useState(null as ColumnApi);

    const canViewGhsHazardPhrase = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.FORMULATION.GHS_HAZARD_PHRASE.VIEW
    );

    const canCreateGhsHazardPhrase = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.FORMULATION.GHS_HAZARD_PHRASE.CREATE
    );

    const { data: rawHazardPhraseList, isLoading: isLoadingHazardPhraseList } =
        useListGhsHazardPhrasesQuery();

    const filteredHazardPhraseList = _.filter(rawHazardPhraseList, {
        langId: defaultLanguageId,
    });

    const { settings } = useContext(SettingsContext);
    const { data: termSet } = useGetTermSetQuery(
        settings?.userSettings
            ? {
                  languageId: settings?.userSettings?.languageId,
                  code: FORMULATION_DEFS.GHS_HAZARD_PHRASES_GRID,
              }
            : skipToken
    );

    const hazardPhraseList = _.sortBy(filteredHazardPhraseList, ['phraseId']);

    useEffect(() => {
        setIsLoadingData(isLoadingHazardPhraseList);
        if (
            rawHazardPhraseList &&
            defaultLanguageId &&
            filteredHazardPhraseList
        ) {
            handleSetPhraseList(filteredHazardPhraseList);
        }
    }, [rawHazardPhraseList, isLoadingHazardPhraseList, defaultLanguageId]);

    useEffect(() => {
        selectRowNode(currentPhraseId);
    }, [currentPhraseId, gridApi]);

    const selectRowNode = (phraseId: string) => {
        if (gridApi && phraseId) {
            const node = gridApi.getRowNode(phraseId);
            if (node && !node.isSelected()) {
                node.setSelected(true);
            }
        }
    };

    const handleSelectedRowChange = (selectedPhrase: GhsHazardPhrase) => {
        if (selectedPhrase) {
            const onConfirm = () => {
                navigate(
                    `/formulation/ghsHazardPhrases/${selectedPhrase.phraseId}`
                );
            };
            const onCancel = () => {
                if (currentPhraseId) {
                    selectRowNode(currentPhraseId);
                } else if (gridApi) {
                    gridApi.deselectAll();
                }
            };
            handlePromptFormDirty(onConfirm, onCancel);
        }
    };

    const handleAddNewPhrase = () => {
        handlePromptFormDirty(() => {
            navigate('/formulation/ghsHazardPhrases/new');
            if (gridApi) {
                gridApi.deselectAll();
            }
        });
    };

    const onGridReady = (params: GridReadyEvent) => {
        setGridApi(params.api);
        setGridColumnApi(params.columnApi);
    };

    const handleRowDataChanged = () => {
        selectRowNode(currentPhraseId);
    };

    const onFirstDataRendered = () => {
        applyGridState();
    };

    const applyGridState = () => {
        if (formulation.ghsHazardPhrases.column && gridColumnApi) {
            gridColumnApi.applyColumnState({
                state: formulation.ghsHazardPhrases.column,
                applyOrder: true,
            });
        }
        if (formulation.ghsHazardPhrases.filter && gridApi) {
            gridApi.setFilterModel(formulation.ghsHazardPhrases.filter);
        }
        if (currentPhraseId) {
            selectRowNode(currentPhraseId);
        }
    };

    const onSortChanged = () => {
        dispatch(
            updateGridModels({
                gridLocation: 'formulation',
                gridName: 'ghsHazardPhrases',
                type: 'column',
                model: gridColumnApi.getColumnState(),
            })
        );
    };

    const onFilterChanged = () => {
        dispatch(
            updateGridModels({
                gridLocation: 'formulation',
                gridName: 'ghsHazardPhrases',
                type: 'filter',
                model: gridApi.getFilterModel(),
            })
        );
    };

    return {
        hazardPhraseList,
        isLoadingData,
        handleSelectedRowChange,
        handleAddNewPhrase,
        onGridReady,
        onFirstDataRendered,
        handleRowDataChanged,
        onFilterChanged,
        onSortChanged,
        canViewGhsHazardPhrase,
        canCreateGhsHazardPhrase,
        termSet,
    };
};

export default useGhsHazardPhraseGrid;
