import React from 'react';
import FormPartial from '../../../../components/form/formContainer/FormPartial';
import { Checkbox, FormControlLabel, Grid } from '@mui/material';
import TextInput from '../../../../components/form/formInputs/TextInput/TextInput';
import BaseForm from '../../../../components/form/baseForm/BaseForm';
import SelectInput from '../../../../components/form/formInputs/SelectInput/SelectInput';
import useGhsHazardPhraseForm, {
    HazardPhraseFormProps,
} from './hooks/useGhsHazardPhraseForm';
import { GhsHazardPhrasesFormDefs } from '../../../../constants/i18n/translations/termDefinitions/formulation';
import TranslatableText from '../../../../components/i18n/TranslatableText';

const GhsHazardPhraseForm = ({
    id,
    currentLangId,
    phraseIdList,
    allowDelete,
    currentPhraseId,
    currentHazardType,
    currentUserInputNeeded,
    defaultLangId,
    handleSetPreviousTab,
    handleSetIsFormDirty,
    cancelNewLanguage,
    setDisableLangButton,
}: HazardPhraseFormProps) => {
    const {
        fields,
        formMethods,
        hazardTypeOptionsList,
        isLoadingHazardTypes,
        isLoadingPhraseRecord,
        handleFieldChange,
        handlePhraseIdOnBlur,
        handleCancelForm,
        onCreate,
        onUpdate,
        onDelete,
        canCreateHazardPhrase,
        canUpdateHazardPhrase,
        canDeleteHazardPhrase,
        termSet,
        hasEntityChanges,
    } = useGhsHazardPhraseForm({
        id,
        currentLangId,
        currentPhraseId,
        currentHazardType,
        allowDelete,
        phraseIdList,
        currentUserInputNeeded,
        defaultLangId,
        handleSetPreviousTab,
        handleSetIsFormDirty,
        cancelNewLanguage,
        setDisableLangButton,
    });

    return (
        <BaseForm
            title={''}
            formMethods={formMethods}
            isLoading={isLoadingPhraseRecord || isLoadingHazardTypes}
            isNew={!id}
            onCreate={onCreate}
            onUpdate={onUpdate}
            onDelete={allowDelete ? onDelete : undefined}
            onCancel={handleCancelForm}
            hasEntityChanges={hasEntityChanges}
            displayCreateButton={canCreateHazardPhrase}
            displayUpdateButton={canUpdateHazardPhrase}
            displayDeleteButton={canDeleteHazardPhrase && allowDelete}
            readOnly={id && !canUpdateHazardPhrase}>
            <FormPartial>
                <Grid container flexDirection="row" spacing={2}>
                    <Grid item xs={6}>
                        <TextInput
                            fullWidth
                            label={
                                <TranslatableText
                                    termKey={GhsHazardPhrasesFormDefs.Phrase_ID}
                                    termSet={termSet}
                                />
                            }
                            id="phraseId"
                            value={fields.phraseId}
                            disabled={!!id || !!currentPhraseId}
                            required={true}
                            formMethods={formMethods}
                            onChange={handleFieldChange}
                            maxLength={20}
                            onBlur={handlePhraseIdOnBlur}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <FormControlLabel
                            label={
                                <TranslatableText
                                    termKey={
                                        GhsHazardPhrasesFormDefs.User_Input_Needed
                                    }
                                    termSet={termSet}
                                />
                            }
                            control={
                                <Checkbox
                                    id="companySpecific"
                                    checked={fields.companySpecific}
                                    onChange={handleFieldChange}
                                />
                            }
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextInput
                            fullWidth
                            id="phraseText"
                            value={fields.phraseText}
                            label={
                                <TranslatableText
                                    termKey={GhsHazardPhrasesFormDefs.Text}
                                    termSet={termSet}
                                />
                            }
                            maxLength={250}
                            multiline={true}
                            rows={3}
                            formMethods={formMethods}
                            onChange={handleFieldChange}
                            required={true}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <SelectInput
                            fullWidth
                            label={
                                <TranslatableText
                                    termKey={
                                        GhsHazardPhrasesFormDefs.Hazard_Type
                                    }
                                    termSet={termSet}
                                />
                            }
                            id="hazardTypeId"
                            required={true}
                            value={
                                fields.hazardTypeId == null
                                    ? ''
                                    : fields.hazardTypeId
                            }
                            onChange={handleFieldChange}
                            formMethods={formMethods}
                            options={hazardTypeOptionsList}
                            disabled={isLoadingHazardTypes}
                            SelectProps={{
                                readOnly: id && !canUpdateHazardPhrase,
                            }}
                        />
                    </Grid>
                </Grid>
            </FormPartial>
        </BaseForm>
    );
};

export default GhsHazardPhraseForm;
