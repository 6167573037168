import { useSelector } from 'react-redux';
import { RootState } from '../../../../../store';
import { DepreciationScheduleProps } from '../DepreciationSchedule';
import React, { useImperativeHandle, useState } from 'react';
import { useDeleteDepreciationSchedulesMutation } from '../../../../../services/fixedAssets/fixedAssets.service';
import { PermissionsUtil } from '../../../../../utils/permissions/permissionsUtil';
import { PERMISSIONS } from '../../../../../constants/permissions/Permissions.constants';

export const useDepreciationSchedule = (
    props: DepreciationScheduleProps,
    ref: any
) => {
    const user = useSelector((state: RootState) => state.user);

    const [showCreateScheduleModal, setShowCreateScheduleModal] =
        useState<boolean>(false);
    const [createSchedulesDisabled, setCreateSchedulesDisabled] =
        useState<boolean>(false);
    const [
        deleteDepreciationSchedules,
        { isLoading: isDeletingDepreciationSchedules },
    ] = useDeleteDepreciationSchedulesMutation();

    const canCreateDepreciationSchedules = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.FIXED_ASSETS.DEPRECIATION_SCHEDULES.CREATE
    );

    const canUpdateDepreciationSchedules = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.FIXED_ASSETS.DEPRECIATION_SCHEDULES.EDIT
    );
    const canDeleteDepreciationSchedules = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.FIXED_ASSETS.DEPRECIATION_SCHEDULES.DELETE
    );

    const handleDeleteDepreciationSchedules = async (event: any) => {
        event.preventDefault();

        try {
            await deleteDepreciationSchedules({
                fixedAssetId: props.assetId,
            });
        } catch (e) {
            console.log(e);
        }
    };

    //called from Fixed Asset Form
    useImperativeHandle(ref, () => ({
        disableCreateSchedules(disable: boolean) {
            setCreateSchedulesDisabled(disable);
        },
    }));

    return {
        showCreateScheduleModal,
        setShowCreateScheduleModal,
        createSchedulesDisabled,
        canCreateDepreciationSchedules,
        canUpdateDepreciationSchedules,
        canDeleteDepreciationSchedules,
        isDeletingDepreciationSchedules,
        handleDeleteDepreciationSchedules,
    };
};

export default useDepreciationSchedule;
