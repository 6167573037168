import React from 'react';
import { Checkbox, FormControlLabel, Grid } from '@mui/material';
import TextInput from '../form/formInputs/TextInput/TextInput';
import LoaderButton from '../LoaderButton';
import SelectInput from '../form/formInputs/SelectInput/SelectInput';
import BaseGrid from '../grids/BaseGrid';
import useSequenceBase from './hooks/useSequenceBase';
import { isNilOrEmpty } from '../../utils/objectUtils';
import ExpandableDropdown from '../form/expandableDropdown/ExpandableDropdown';
import FormPartial from '../form/formContainer/FormPartial';
import DatacorLogoSpinner from '../datacorLogoSpinner/DatacorLogoSpinner';
import NumericInput from '../form/formInputs/NumericInput/NumericInput';
import { SequencesFormDefs } from '../../constants/i18n/translations/termDefinitions/formulation';
import TranslatableText from '../i18n/TranslatableText';
import BaseForm from '../form/baseForm/BaseForm';

const SequenceBase = ({
    sequenceQuery,
    sequenceMutation,
    parameterQuery,
    useParent,
    parentQuery,
    title,
    closePath,
}: any) => {
    const {
        fields,
        handlePrefixGridEdits,
        handleSuffixGridEdits,
        prefixColDefs,
        handleFieldChange,
        sequencePreview,
        handlePreviewNext,
        onUpdate,
        closeForm,
        parsedPrefixes,
        parsedSuffixes,
        handleRowDrag,
        businessEntities,
        setValues,
        parents,
        termSet,
        hasEntityChanges,
        formMethods,
        isLoading,
    } = useSequenceBase({
        sequenceQuery,
        sequenceMutation,
        parameterQuery,
        useParent,
        parentQuery,
        closePath,
    });

    return (
        <BaseForm
            title={title}
            isLoading={isLoading}
            hasEntityChanges={hasEntityChanges}
            formMethods={formMethods}
            isNew={false}
            onUpdate={onUpdate}
            onCancel={closeForm}
            displayUpdateButton={true}
            readOnly={false}>
            <FormPartial
                title={
                    (
                        <TranslatableText
                            termSet={termSet}
                            termKey={SequencesFormDefs.Use_Parent_Text}
                        />
                    ) as unknown as string
                }>
                <FormControlLabel
                    control={
                        <Checkbox
                            key={Math.random()}
                            id={'useParent'}
                            checked={fields?.useParent}
                            onChange={handleFieldChange}
                        />
                    }
                    label={
                        <TranslatableText
                            termSet={termSet}
                            termKey={SequencesFormDefs.Use_Parent}
                        />
                    }
                />
                {fields?.useParent && (
                    <ExpandableDropdown
                        valueSelected={(value) =>
                            setValues({
                                parentId: parents.find(
                                    (parent: any) =>
                                        parent?.businessEntityId === value
                                )?.id,
                            })
                        }
                        items={businessEntities.filter((be: any) => {
                            return parents?.some(
                                (parent: any) =>
                                    parent.businessEntityId === be.id
                            );
                        })}
                        defaultValue={
                            parents?.find((parent: any) => {
                                return parent.id === fields?.parentId;
                            })?.businessEntityId
                        }
                        idKey="id"
                        labelKey="name"
                        label={
                            (
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={
                                        SequencesFormDefs.Parent_Business_Entity
                                    }
                                />
                            ) as unknown as string
                        }
                        id="parentId"
                    />
                )}
            </FormPartial>
            {!fields?.useParent && (
                <>
                    <FormPartial
                        title={
                            (
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={SequencesFormDefs.Prefixes}
                                />
                            ) as unknown as string
                        }>
                        {!isNilOrEmpty(fields?.prefixSegments) &&
                        !isNilOrEmpty(prefixColDefs) ? (
                            <BaseGrid
                                displayGrid={true}
                                onRowDragEnd={(params: any) =>
                                    handleRowDrag(params, 'prefixSegments')
                                }
                                rowDragManaged={true}
                                animateRows={true}
                                displayCreateNewButton={true}
                                rowData={parsedPrefixes}
                                gridClass="full-size-item"
                                displayToolbar={true}
                                sizeColumnsToFit={true}
                                onHandleGridEdits={handlePrefixGridEdits}
                                isEditable={true}
                                pagination={false}
                                columnDefs={prefixColDefs}
                                newRowPlaceholderValues={{
                                    type: 'Select a type...',
                                    value: 'Enter Value...',
                                }}></BaseGrid>
                        ) : (
                            <DatacorLogoSpinner />
                        )}
                    </FormPartial>
                    <FormPartial
                        title={
                            (
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={SequencesFormDefs.Sequence_Number}
                                />
                            ) as unknown as string
                        }>
                        <div style={{ width: '50%' }}>
                            <Grid container spacing={2}>
                                <Grid item xs={4}>
                                    <NumericInput
                                        id="nextNumber"
                                        label={
                                            <TranslatableText
                                                termSet={termSet}
                                                termKey={
                                                    SequencesFormDefs.Next_Number
                                                }
                                            />
                                        }
                                        value={fields.nextNumber}
                                        onChange={handleFieldChange}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <NumericInput
                                        id="paddingLength"
                                        label={
                                            <TranslatableText
                                                termSet={termSet}
                                                termKey={
                                                    SequencesFormDefs.Padding_Length
                                                }
                                            />
                                        }
                                        value={fields.paddingLength}
                                        onChange={handleFieldChange}
                                    />
                                </Grid>

                                <Grid item xs={4}>
                                    <TextInput
                                        id="paddingChar"
                                        label={
                                            <TranslatableText
                                                termSet={termSet}
                                                termKey={
                                                    SequencesFormDefs.Padding_Character
                                                }
                                            />
                                        }
                                        value={fields.paddingChar}
                                        onChange={handleFieldChange}
                                        inputProps={{ maxLength: 1 }}
                                    />
                                </Grid>

                                <Grid item xs={4}>
                                    <NumericInput
                                        id="step"
                                        label={
                                            <TranslatableText
                                                termSet={termSet}
                                                termKey={
                                                    SequencesFormDefs.Step_Increment_By
                                                }
                                            />
                                        }
                                        value={fields.step}
                                        onChange={handleFieldChange}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <SelectInput
                                        id={'resetPeriod'}
                                        label={
                                            <TranslatableText
                                                termSet={termSet}
                                                termKey={
                                                    SequencesFormDefs.Auto_Reset
                                                }
                                            />
                                        }
                                        options={[
                                            {
                                                label: (
                                                    <TranslatableText
                                                        termSet={termSet}
                                                        termKey={
                                                            SequencesFormDefs.Auto_Reset_None
                                                        }
                                                    />
                                                ) as unknown as string,
                                                value: 'None',
                                            },
                                            {
                                                label: (
                                                    <TranslatableText
                                                        termSet={termSet}
                                                        termKey={
                                                            SequencesFormDefs.Auto_Reset_Month
                                                        }
                                                    />
                                                ) as unknown as string,
                                                value: 'Month',
                                            },
                                            {
                                                label: (
                                                    <TranslatableText
                                                        termSet={termSet}
                                                        termKey={
                                                            SequencesFormDefs.Auto_Reset_Year
                                                        }
                                                    />
                                                ) as unknown as string,
                                                value: 'Year',
                                            },
                                        ]}
                                        value={fields.resetPeriod}
                                        onChange={handleFieldChange}
                                    />
                                </Grid>
                            </Grid>

                            <FormPartial
                                title={
                                    (
                                        <TranslatableText
                                            termSet={termSet}
                                            termKey={SequencesFormDefs.Preview}
                                        />
                                    ) as unknown as string
                                }>
                                <h2>{sequencePreview}</h2>
                                <LoaderButton
                                    variant={'contained'}
                                    onClick={handlePreviewNext}>
                                    <TranslatableText
                                        termSet={termSet}
                                        termKey={SequencesFormDefs.Preview_Next}
                                    />
                                </LoaderButton>
                            </FormPartial>
                        </div>
                    </FormPartial>
                    <FormPartial
                        title={
                            (
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={SequencesFormDefs.Suffixes}
                                />
                            ) as unknown as string
                        }>
                        {!isNilOrEmpty(fields?.suffixSegments) &&
                        !isNilOrEmpty(prefixColDefs) ? (
                            <BaseGrid
                                displayGrid={true}
                                displayCreateNewButton={true}
                                onRowDragEnd={(params: any) =>
                                    handleRowDrag(params, 'suffixSegments')
                                }
                                rowDragManaged={true}
                                animateRows={true}
                                rowData={parsedSuffixes}
                                gridClass="full-size-item"
                                displayToolbar={true}
                                sizeColumnsToFit={true}
                                onHandleGridEdits={handleSuffixGridEdits}
                                isEditable={true}
                                pagination={false}
                                columnDefs={prefixColDefs}
                                newRowPlaceholderValues={{
                                    type: 'Select a type...',
                                    value: 'Enter Value...',
                                }}></BaseGrid>
                        ) : (
                            <DatacorLogoSpinner />
                        )}
                    </FormPartial>
                </>
            )}
        </BaseForm>
    );
};

export default SequenceBase;
