import { SafetyDataSheetSectionHeader } from '../../../types/formulation';
import {
    BaseCreateEntityArgs,
    BaseUpdateEntityArgs,
} from '../../serviceInterfaces';
import { datacorAppApi } from '../../datacorAppApi';

const serviceUrl: string = 'formulation/safety-data-sheet-section-header';

export const safetyDataSheetSectionHeadersApi = datacorAppApi.injectEndpoints({
    endpoints: (build) => ({
        createSafetyDataSheetSectionHeader: build.mutation<
            SafetyDataSheetSectionHeader,
            BaseCreateEntityArgs<SafetyDataSheetSectionHeader>
        >({
            query: (args) => ({
                url: serviceUrl,
                method: 'POST',
                body: args.postBody,
            }),
            invalidatesTags: ['SafetyDataSheetSectionHeader'],
        }),
        updateSafetyDataSheetSectionHeader: build.mutation<
            SafetyDataSheetSectionHeader,
            BaseUpdateEntityArgs<SafetyDataSheetSectionHeader>
        >({
            query: (args) => ({
                url: `${serviceUrl}/${args.id}`,
                method: 'PUT',
                body: args.postBody,
            }),
            invalidatesTags: [
                'SafetyDataSheetSectionHeader',
                'ActiveSafetyDataSheetSectionHeader',
            ],
        }),
        deleteSafetyDataSheetSectionHeader: build.mutation<
            SafetyDataSheetSectionHeader,
            string
        >({
            query: (id) => ({
                url: `${serviceUrl}/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['SafetyDataSheetSectionHeader'],
        }),
        getSafetyDataSheetSectionHeader: build.query<
            SafetyDataSheetSectionHeader,
            string
        >({
            query: (id) => `${serviceUrl}/${id}`,
            providesTags: ['ActiveSafetyDataSheetSectionHeader'],
        }),
        listSafetyDataSheetSectionHeadersByLangId: build.query<
            SafetyDataSheetSectionHeader[],
            { langId: string }
        >({
            query: ({ langId }) => {
                return {
                    url: serviceUrl,
                    params: {
                        langId: langId,
                    },
                };
            },
            transformResponse: (response: SafetyDataSheetSectionHeader[]) => {
                return response.map((sdsHeader) => {
                    sdsHeader.headerIdLocaleGrpId = `${sdsHeader.headerId}${sdsHeader.localeGroupId}`;
                    return sdsHeader;
                });
            },
            providesTags: ['SafetyDataSheetSectionHeader'],
        }),
        listSafetyDataSheetSectionHeadersByHeaderIdLocaleGrpId: build.query<
            SafetyDataSheetSectionHeader[],
            {
                headerId: string;
                localeGroupId: string;
            }
        >({
            query: ({ headerId, localeGroupId }) => {
                return {
                    url: serviceUrl,
                    params: {
                        headerId: headerId,
                        localeGroupId: localeGroupId,
                    },
                };
            },
            providesTags: ['SafetyDataSheetSectionHeader'],
        }),
    }),
});

export const {
    useCreateSafetyDataSheetSectionHeaderMutation,
    useUpdateSafetyDataSheetSectionHeaderMutation,
    useDeleteSafetyDataSheetSectionHeaderMutation,
    useGetSafetyDataSheetSectionHeaderQuery,
    useListSafetyDataSheetSectionHeadersByLangIdQuery,
    useListSafetyDataSheetSectionHeadersByHeaderIdLocaleGrpIdQuery,
} = safetyDataSheetSectionHeadersApi;
