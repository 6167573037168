import { useNavigate } from 'react-router-dom';
import { PermissionsUtil } from '../../../utils/permissions/permissionsUtil';
import { PERMISSIONS } from '../../../constants/permissions/Permissions.constants';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { RoutingValues } from '../../../types/Shared.types';
import {
    useListSupplierStatusQuery,
    useUpdateListSupplierStatusMutation,
} from '../../../services/supplier/supplier.service';
import { SupplierStatus } from '../../../types/Supplier.types';
import React, { useContext, useEffect, useState } from 'react';
import SettingsContext from '../../../contexts/settings.context';
import { SUPPLY_CHAIN_DEFS } from '../../../constants/i18n/translations/termSetDefinitions/supply-chain';
import { isNilOrEmpty } from '../../../utils/objectUtils';
import { ColDef } from 'ag-grid-community';
import DefaultColumnTypes from '../../../components/grids/columns/Column.constants';
import CustomHeader from '../../../components/grids/CustomHeader';
import { SupplierStatusGridDefs } from '../../../constants/i18n/translations/termDefinitions/supply-chain';
import _ from 'lodash';
import { getMaxSortOrderValue } from '../../../store/suppliers';
import { auditableGridColumns } from '../../grid/utils/auditableGrid/auditableGridColumns';
import useTranslation from '../../../components/i18n/hooks/useTranslation';

const useStatusGrid = () => {
    const user = useSelector((state: RootState) => state.user);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { data: statusList, isLoading } = useListSupplierStatusQuery();
    const [updateList] = useUpdateListSupplierStatusMutation();
    const [colDefs, setColDefs] = useState([]);
    const [rowData, setRowData] = useState([]);

    const { settings } = useContext(SettingsContext);
    const { termSet } = useTranslation(SUPPLY_CHAIN_DEFS.SUPPLIER_STATUS_GRID);

    const [hiddenIdColumns, auditableColumns] = auditableGridColumns();

    const canView = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.SUPPLIERS.SETUP.SUPPLIER_STATUSES.VIEW
    );

    const canCreate = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.SUPPLIERS.SETUP.SUPPLIER_STATUSES.CREATE
    );

    const canUpdate = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.SUPPLIERS.SETUP.SUPPLIER_STATUSES.EDIT
    );

    const handleGridEdits = (editedRows: SupplierStatus[]) => {
        updateList(editedRows);
    };

    const redirectToForm = (row: any) => {
        if (row.id) {
            navigate(`/suppliers/setup/status/${row.id}`);
        } else {
            navigate(`/suppliers/setup/status/${RoutingValues.newId}`);
        }
    };

    useEffect(() => {
        if (!isNilOrEmpty(termSet)) {
            const columnDefs: ColDef[] = [
                ...hiddenIdColumns,
                {
                    field: 'sortOrder',
                    ...DefaultColumnTypes.NumberOnly,
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={SupplierStatusGridDefs.SortOrder}
                            />
                        );
                    },
                },
                {
                    field: 'code',
                    ...DefaultColumnTypes.ShortText,
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={SupplierStatusGridDefs.Code}
                            />
                        );
                    },
                },
                {
                    field: 'description',
                    ...DefaultColumnTypes.LongText,
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={SupplierStatusGridDefs.Description}
                            />
                        );
                    },
                },
                ...auditableColumns,
            ];

            setColDefs(columnDefs);
        }
    }, [termSet]);

    useEffect(() => {
        let maxValue = 1;
        if (statusList && statusList.length > 0) {
            const list = _.maxBy(
                statusList,
                (typeObj: SupplierStatus) => typeObj.sortOrder
            );
            maxValue = list.sortOrder + 1;
        }
        dispatch(
            getMaxSortOrderValue({ isLoading: false, maxValue: maxValue })
        );
    }, statusList);

    useEffect(() => {
        if (!isNilOrEmpty(statusList)) {
            setRowData(statusList);
        }
    }, [statusList]);

    return {
        rowData,
        colDefs,
        isLoading,
        canView,
        canCreate,
        canUpdate,
        redirectToForm,
        handleGridEdits,
    };
};

export default useStatusGrid;
