import React, { useContext, useEffect, useState } from 'react';
import { RootStateOrAny, useSelector } from 'react-redux';
import { skipToken } from '@reduxjs/toolkit/query';
import { PERMISSIONS } from '../../../../../constants/permissions/Permissions.constants';
import { PermissionsUtil } from '../../../../../utils/permissions/permissionsUtil';
import { useListLanguagesDropdownQuery } from '../../../../../services/organizations/organizations.service';
import useBaseForm from '../../../../form/hooks/useBaseForm';
import { isNilOrEmpty } from '../../../../../utils/objectUtils';
import {
    SdsCasComposition,
    SdsEcoToxicity,
    SdsExposure,
    SdsGhsHazardPhrase,
    SdsGhsPrecautionaryPhrase,
    SdsHeader,
    SdsProperty,
    SdsRegulation,
    SdsToxicity,
} from '../../../../../types/formulation';
import {
    useCreateSdsHeaderMutation,
    useDeleteSdsHeaderMutation,
    useGetSdsHeaderQuery,
    useGetSdsHeaderStatusForDropdownQuery,
    useUpdateSdsHeaderMutation,
} from '../../../../../services/formulation/sds/header/sdsHeader.service';
import { useListLocaleGroupDropDownQuery } from '../../../../../services/formulation/localeGroup/localeGroup.service';
import { useListItemsMasterDropdownQuery } from '../../../../../services/formulation/itemMaster/item/itemMaster.service';
import DefaultColumnTypes from '../../../../../components/grids/columns/Column.constants';
import {
    RoutingValues,
    SelectionOption,
} from '../../../../../types/Shared.types';
import { ColDef } from 'ag-grid-community';
import { ChildEditableGridProps } from '../../../../grid/utils/editableGrid/ChildEditableGridProps';
import { ChildFormProps } from '../../../../form/helpers/ChildFormProps';
import useChildFormHelper from '../../../../form/hooks/useChildFormHelper';
import SettingsContext from '../../../../../contexts/settings.context';
import { useGetTermSetQuery } from '../../../../../services/i18n/i18n.service';
import { FORMULATION_DEFS } from '../../../../../constants/i18n/translations/termSetDefinitions/formulation';
import CustomHeader from '../../../../../components/grids/CustomHeader';
import { SDSFormDefs } from '../../../../../constants/i18n/translations/termDefinitions/formulation';
import { HandleFieldChangedEvent } from '../../../../../libs/hooksLib';
import useAutoPopulateItemData, {
    AutoPopulateItemDataProps,
} from './useAutoPopulateItemData';
import useCopySdsForm from './useCopySdsForm';
import { DISPLAY_FORM_SECTIONS } from '../../../../../constants/menus/sdsSections.constants';

const useSdsForm = (id: string) => {
    const user = useSelector((state: RootStateOrAny) => state.user);

    const dropdownsInitialState: any = {
        languageValue: '',
        selectedLanguage: null,
        localeGroupValue: '',
        selectedLocaleGroup: null,
        sdsHeaderStatusValue: '',
        selectedSdsHeaderStatusStatus: null,
        itemCodeValue: '',
        selectedItemCode: null,
    };

    const [dropdownOptions, setDropdownOptions] = useState(
        dropdownsInitialState
    );

    const blankSdsHeader: SdsHeader = {
        sdsCode: '',
        sdsDescription: '',
        itemCodeId: null as bigint,
        status: null as bigint,
        template: false,
        versionNumber: 1,
        versionDate: null as Date,
        langId: null as bigint,
        localeGroupId: null,
        substance: null,
        businessEntityId: user?.businessEntity?.id.toString(),
        sdsIdentification: {
            casId: null,
            productName: null,
            registrationNumber: '',
            regulatoryAddress: null,
            additionalCode: null,
            synonyms: null,
            productUseDescription: null,
            certified: null,
            certificationDate: null,
            certificationUserId: null,
            formulaId: null,
            contactName: null,
            contactPhone1: null,
            contactPhoneDescription1: null,
            contactPhone2: null,
            contactPhoneDescription2: null,
        },
        sdsPictogram: {
            sdsPictogramSignalWordId: 1,
            explosive: false,
            flammable: false,
            oxidizing: false,
            compressedGas: false,
            toxic: false,
            corrosive: false,
            irritant: false,
            healthHazard: false,
            environmentDamaging: false,
        },
        sdsGhsHazardPhrases: [] as SdsGhsHazardPhrase[],
        sdsGhsPrecautionaryPhrases: [] as SdsGhsPrecautionaryPhrase[],
        sdsExposures: [] as SdsExposure[],
        sdsProperties: [] as SdsProperty[],
        sdsCasComposition: [] as SdsCasComposition[],
        sdsRegulations: [] as SdsRegulation[],
        sdsEcoToxicities: [] as SdsEcoToxicity[],
        sdsToxicities: [] as SdsToxicity[],
        sdsTransportation: {
            landProperShippingNameId: null,
            landProperShippingNameDescription: null,
            airProperShippingNameId: null,
            airProperShippingNameDescription: null,
            seaProperShippingNameId: null,
            seaProperShippingNameDescription: null,
        },
        sdsOtherInformation: {
            hmisChronic: null,
            webAddress: null,
            hmisHealthId: null,
            hmisFlammabilityId: null,
            hmisPhysicalHazardId: null,
            hmisEquipmentId: null,
            nfpaHealthId: null,
            nfpaFlammabilityId: null,
            nfpaReactivityId: null,
            nfpaSpecificHazardId: null,
        },
    };

    const { data: currentSdsHeader, isLoading: isLoadingSdsHeader } =
        useGetSdsHeaderQuery(isNilOrEmpty(id) ? skipToken : id);

    const displayCreateNewButton = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.FORMULATION.SDS_HEADER.CREATE
    );

    const displayUpdateButton = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.FORMULATION.SDS_HEADER.EDIT
    );

    const displayDeleteButton = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.FORMULATION.SDS_HEADER.DELETE
    );

    const displayCopyButton = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.FORMULATION.SDS_HEADER.CREATE
    );

    const canViewItemMaster = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.FORMULATION.ITEM_MASTER.VIEW
    );

    const canViewLocaleGroup = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.FORMULATION.LOCALE_GROUPS.VIEW
    );

    const { data: languagesOptions, isLoading: isLoadingLanguagesOptions } =
        useListLanguagesDropdownQuery();

    const { data: localeGroupOptions, isLoading: isLoadingLocaleGroupOptions } =
        useListLocaleGroupDropDownQuery();

    const {
        data: sdsHeaderStatusOptions,
        isLoading: isLoadingSdsHeaderStatusOptions,
    } = useGetSdsHeaderStatusForDropdownQuery();

    const { data: itemMasterOptions, isLoading: isLoadingItemMasterOptions } =
        useListItemsMasterDropdownQuery();

    const { settings } = useContext(SettingsContext);
    const { data: termSet } = useGetTermSetQuery(
        settings?.userSettings
            ? {
                  languageId: settings?.userSettings?.languageId,
                  code: FORMULATION_DEFS.SDS_FORM,
              }
            : skipToken
    );

    const [createSdsHeader] = useCreateSdsHeaderMutation();

    const [updateSdsHeader] = useUpdateSdsHeaderMutation();

    const [deleteSdsHeader] = useDeleteSdsHeaderMutation();

    const { childrenFormHooks, allChildrenFormMethods, registerChildForm } =
        useChildFormHelper();

    const {
        fields,
        hasEntityChanges,
        formMethods,
        handleFieldChange,
        onCreate,
        onUpdate,
        onDelete,
        closeForm,
        setValues,
        handleChildrenRecords,
        handleChildRecord,
        childForms,
        updateCompleteChildRecord,
        copyForm,
        formType,
    } = useBaseForm({
        closePath: '/formulation/sds',
        copyPath: `/formulation/sds/${RoutingValues.newId}`,
        blankEntity: blankSdsHeader,
        activeEntity: currentSdsHeader,
        childForms: childrenFormHooks,
        onlyUpdateEntityWithChanges: true,
        getDescription: () => {
            return `Safety Data Sheet ${fields.sdsCode}`;
        },
        createEntity: () => {
            return createSdsHeader({
                postBody: fields,
            });
        },
        updateEntity: () => {
            return updateSdsHeader({
                id: id as unknown as number,
                postBody: fields,
            });
        },
        deleteEntity: () => {
            return deleteSdsHeader(id);
        },
    });

    const autoPopulateItemDataProps: AutoPopulateItemDataProps = {
        currentSdsHeader: fields as SdsHeader,
        helpers: {
            handleParentFieldChanged: handleFieldChange,
            handleChildRecord,
            handleChildrenRecords,
        },
    };

    const { autoPopulateSectionsWithItemData } = useAutoPopulateItemData(
        autoPopulateItemDataProps
    );

    const { copyMethods, blobCopyMethods } = useCopySdsForm({
        fields,
        dataFromQuery: currentSdsHeader,
        setValues,
        formType,
    });

    const handleItemCodeChanged = (event: HandleFieldChangedEvent): void => {
        autoPopulateSectionsWithItemData(event.target.value.toString()).then(
            (_) => handleFieldChange(event)
        );
    };

    const getDefaultLanguage = (): any => {
        return languagesOptions?.find((language: any) =>
            (language.label as string).includes('ENG')
        );
    };

    const getDefaultLocaleGroup = (): SelectionOption => {
        return localeGroupOptions?.find((localeGroup: any) =>
            (localeGroup.label as string).includes('US')
        );
    };

    useEffect(() => {
        if (
            !isLoadingLanguagesOptions &&
            languagesOptions &&
            !isLoadingLocaleGroupOptions &&
            localeGroupOptions &&
            !id &&
            formType !== RoutingValues.copy
        ) {
            const defaultLangId = getDefaultLanguage()?.value;
            const defaultLocaleGroupId = getDefaultLocaleGroup()?.value;
            const today = new Date(new Date().getTime());

            if (defaultLangId || defaultLocaleGroupId) {
                setValues({
                    versionDate: today,
                    localeGroupId: defaultLocaleGroupId,
                    langId: defaultLangId,
                });
            }
        }
    }, [
        id,
        formType,
        isLoadingLanguagesOptions,
        languagesOptions,
        isLoadingLocaleGroupOptions,
        localeGroupOptions,
    ]);

    useEffect(() => {
        if (
            !isLoadingLocaleGroupOptions &&
            !isNilOrEmpty(localeGroupOptions) &&
            !isLoadingLanguagesOptions &&
            !isNilOrEmpty(languagesOptions) &&
            !isLoadingSdsHeaderStatusOptions &&
            !isNilOrEmpty(sdsHeaderStatusOptions) &&
            !isLoadingItemMasterOptions
        ) {
            setDropdownOptions((previousValues: any) => ({
                ...previousValues,
                selectedLocaleGroup: localeGroupOptions?.find(
                    (current: any) => current.value === fields.localeGroupId
                ),
                selectedLanguage: languagesOptions?.find(
                    (current: any) => current.value === fields.langId
                ),
                selectedItemCode: itemMasterOptions?.find(
                    (current: any) => current.value === fields.itemCodeId
                ),
                selectedSdsHeaderStatus: sdsHeaderStatusOptions?.find(
                    (current: any) => current.value === fields.status
                ),
            }));
        }
    }, [
        isLoadingLocaleGroupOptions,
        localeGroupOptions,
        isLoadingLanguagesOptions,
        languagesOptions,
        isLoadingSdsHeaderStatusOptions,
        sdsHeaderStatusOptions,
        isLoadingItemMasterOptions,
        itemMasterOptions,
        fields,
    ]);

    const localeGroupTextPopupColDefs: ColDef[] = [
        {
            field: 'label',
            headerComponentFramework: (props: any) => {
                return (
                    <CustomHeader
                        {...props}
                        termSet={termSet}
                        termKey={SDSFormDefs.Locale_Group_Popup_Locale_Group_ID}
                    />
                );
            },
            ...DefaultColumnTypes.LongText,
        },
        {
            field: 'description',
            headerComponentFramework: (props: any) => {
                return (
                    <CustomHeader
                        {...props}
                        termSet={termSet}
                        termKey={SDSFormDefs.Locale_Group_Popup_Description}
                    />
                );
            },
            ...DefaultColumnTypes.LongText,
        },
    ];

    const itemCodeTextPopupColDefs: ColDef[] = [
        {
            field: 'label',
            headerComponentFramework: (props: any) => {
                return (
                    <CustomHeader
                        {...props}
                        termSet={termSet}
                        termKey={SDSFormDefs.Item_Code_Popup_Item_Code}
                    />
                );
            },
            ...DefaultColumnTypes.LongText,
        },
        {
            field: 'description',
            headerComponentFramework: (props: any) => {
                return (
                    <CustomHeader
                        {...props}
                        termSet={termSet}
                        termKey={SDSFormDefs.Item_Code_Popup_Item_Name}
                    />
                );
            },
            ...DefaultColumnTypes.LongText,
        },
    ];

    const parentData = {
        currentParentRecord: fields as SdsHeader,
        isParentLoading: isLoadingSdsHeader,
        user,
        handleFieldChange,
    };

    const editableGridProps: ChildEditableGridProps<SdsHeader> = {
        parentData,
        displayGridButtons: {
            showAddButton:
                (displayCreateNewButton && displayUpdateButton) ||
                (displayCreateNewButton && !id),
            showDeleteButton: displayDeleteButton,
            showSortButton:
                (displayCreateNewButton && !id) ||
                (displayUpdateButton && !!id),
            isGridEditable:
                (displayCreateNewButton && !id) ||
                (displayUpdateButton && !!id),
        },
        helpers: {
            handleChildrenRecords,
        },
    };

    const childFormProps: ChildFormProps<SdsHeader> = {
        parentData: {
            ...parentData,
            dataFromQuery: currentSdsHeader,
        },
        permissions: {
            canCreate: displayCreateNewButton,
            canUpdate: displayUpdateButton,
            disabledField:
                (!id && !displayCreateNewButton) ||
                (id && !displayUpdateButton),
        },
        helpers: {
            handleChildRecord,
            registerChildForm,
            updateCompleteChildRecord,
        },
    };

    const getSectionName = (name: string) => {
        return DISPLAY_FORM_SECTIONS.flat().find(
            (section: any) => section.name === name
        )?.link;
    };

    return {
        fields,
        setValues,
        hasEntityChanges,
        isLoadingSdsHeader,
        handleFieldChange,
        onCreate,
        onDelete,
        onUpdate,
        closeForm,
        childForms,
        termSet,
        getSectionName,
        allFormMethods: [formMethods, ...allChildrenFormMethods],
        display: {
            displayCreateNewButton,
            displayUpdateButton,
            displayDeleteButton,
            displayCopyButton,
            readOnly: id && !displayUpdateButton,
        },
        /** editable grids, child form and extended form methods **/
        children: {
            editableGridProps,
            childFormProps,
            blobMethods: {
                registerBlob: registerChildForm,
                blobCopyMethods,
            },
        },
        /** Copy functionality**/
        copyForm,
        copyMethods,
        headerHook: {
            sdsId: id,
            currentSdsRecord: fields,
            formMethods,
            handleFieldChange,
            handleItemCodeChanged,
            canUpdateSdsHeader: displayUpdateButton,
            canViewItemMaster,
            canViewLocaleGroup,
            isLoadingSdsHeaderStatusOptions,
            isLoadingLanguagesOptions,
            setDropdownOptions,
            getDefaultLanguage,
            dropdownOptions,
            localeGroupOptions,
            languagesOptions,
            itemMasterOptions,
            localeGroupTextPopupColDefs,
            itemCodeTextPopupColDefs,
            sdsHeaderStatusOptions,
            isLoadingSdsRecord:
                isLoadingSdsHeader ||
                isLoadingLanguagesOptions ||
                isLoadingLocaleGroupOptions ||
                isLoadingSdsHeaderStatusOptions ||
                isLoadingItemMasterOptions,
            permissions: {
                canCreate: displayCreateNewButton,
                canUpdate: displayUpdateButton,
                disabledField:
                    (!id && !displayCreateNewButton) ||
                    (id && !displayUpdateButton),
            },
        },
    };
};

export default useSdsForm;
