import { CasPictogramSignalWord } from '../../../../types/formulation';
import { datacorAppApi } from '../../../datacorAppApi';

const serviceUrl: string = 'casMaster/casPictogramSignalWord';

export const casPictogramSignalWordApi = datacorAppApi.injectEndpoints({
    endpoints: (build) => ({
        getPictogramSignalWordForDropdown: build.query<
            { label: string; value: bigint }[],
            void
        >({
            query: () => serviceUrl,
            transformResponse: (response: CasPictogramSignalWord[]) => {
                return response.map((signalWords) => {
                    return {
                        label: signalWords.type,
                        value: signalWords.id,
                    };
                });
            },
            providesTags: ['CasPictogramSignalWord'],
        }),
    }),
});

export const { useGetPictogramSignalWordForDropdownQuery } =
    casPictogramSignalWordApi;
