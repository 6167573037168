import React, { FocusEventHandler, useEffect, useState } from 'react';
import { TextField, TextFieldProps } from '@mui/material';
import { FormMethods } from '../../../../libs/hooksLib';
import { RequiredValidator } from '../../../../validators/required.validator';
import { isNil } from '../../../../utils/objectUtils';
import _ from 'lodash';

export type InputBaseProps = {
    formMethods?: FormMethods;
    stateful?: string;
} & TextFieldProps;

const InputBase = ({ formMethods, onBlur, ...props }: InputBaseProps) => {
    const [value, setValue] = useState(props.value || '');

    useEffect(() => {
        setValue(props.value);
    }, [props.value]);

    const onChange = (e: any) => {
        if (props.stateful) {
            setValue(e.target.value);
        } else {
            if (props.onChange) {
                props.onChange(e);
            }
        }
    };

    useEffect(() => {
        if (props.required) {
            formMethods?.addValidator(
                props.id,
                _.isObject(props.label) ? (
                    <>{props.label}</>
                ) : (
                    props.label.toString()
                ),
                RequiredValidator
            );
        } else {
            formMethods?.removeValidator(props.id, RequiredValidator);
        }
    }, [props.required]);

    const getHelperTextElements = (validationErrors: any[]) => {
        if (!validationErrors) {
            return;
        }

        return validationErrors.reduce((result, item) => (
            <>
                {result}
                <br></br>
                {item}
            </>
        ));
    };

    const handlerOnBlur = (onBlur: FocusEventHandler, event: any) => {
        if (props.stateful) {
            event.target.value = event.target.value.trim();
            if (props.onChange) {
                props.onChange(event);
            }

            if (onBlur) {
                onBlur(event);
            }
        }
    };

    return (
        <TextField
            {...props}
            onBlur={(event) => handlerOnBlur(onBlur, event)}
            value={_.isNil(value) ? '' : value}
            onChange={onChange}
            error={
                formMethods?.isFormSubmitted &&
                !isNil(formMethods?.fieldValidationErrors?.get(props.id))
            }
            helperText={
                formMethods?.isFormSubmitted ? (
                    <>
                        {getHelperTextElements(
                            formMethods?.fieldValidationErrors?.get(props.id)
                        )}
                    </>
                ) : null
            }
        />
    );
};

export default InputBase;
