import DatacorLogoSpinner from '../../../../../../components/datacorLogoSpinner/DatacorLogoSpinner';
import React from 'react';
import FormPartial from '../../../../../../components/form/formContainer/FormPartial';
import SubSection7_1Form from './subSections/subSection7_1/SubSection7_1Form';
import SubSection7_2Form from './subSections/subSection7_2/SubSection7_2Form';
import SubSection7_3Form from './subSections/subSection7_3/SubSection7_3Form';
import { SdsBlobSectionFormProps } from '../shared/blobs/hooks/SdsBlobSectionFormProps';
import { SdsHeader } from '../../../../../../types/formulation';
import TranslatableText from '../../../../../../components/i18n/TranslatableText';
import { SDSFormDefs } from '../../../../../../constants/i18n/translations/termDefinitions/formulation';

const SdsHandlingStorageForm = (props: SdsBlobSectionFormProps<SdsHeader>) => {
    const { isParentLoading } = props.parentData;

    return (
        <>
            {isParentLoading ? (
                <DatacorLogoSpinner />
            ) : (
                <>
                    <FormPartial
                        link={props?.link}
                        title={
                            (
                                <TranslatableText
                                    termSet={props.termSet}
                                    termKey={SDSFormDefs.Handling_Storage}
                                />
                            ) as unknown as string
                        }>
                        <SubSection7_1Form {...props} termSet={props.termSet} />
                        <SubSection7_2Form {...props} termSet={props.termSet} />
                        <SubSection7_3Form {...props} termSet={props.termSet} />
                    </FormPartial>
                </>
            )}
        </>
    );
};

export default SdsHandlingStorageForm;
