import React, { useCallback, useRef, useState } from 'react';
import {
    DetailGridInfo,
    GridReadyEvent,
    RowNode,
    ColDef,
    ColGroupDef,
} from 'ag-grid-community';
import { read, utils } from 'xlsx';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { Tooltip } from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { convertToDateFormat } from '../../../utils/formattingUtils';

export interface useUploadGridModalProps {
    mainExportSchema: (ColDef | ColGroupDef)[];
    detailExportSchema: (ColDef | ColGroupDef)[];
    onUploadImport?: (data: any) => void;
}

const useUploadGridModal = (props: useUploadGridModalProps) => {
    const gridRef = useRef<any>(null);
    const [selectedRows, setSelectedRows] = useState<any>([]);

    const onGridReady = useCallback((params: GridReadyEvent) => {
        gridRef.current!.api.forEachNode((node: RowNode) => {
            node.setExpanded(true);
        });
        params.columnApi.autoSizeAllColumns();
    }, []);

    const onSelectionChanged = useCallback(() => {
        const selectedRows = gridRef.current!.api.getSelectedRows();
        setSelectedRows(selectedRows);
    }, []);

    const exportSchema = useCallback(() => {
        let spreadsheets = [];
        let excelExportParams = {
            exportMode: 'xlsx',
            sheetName: 'Main',
        };
        const mainSheet =
            gridRef.current!.api.getSheetDataForExcel(excelExportParams);
        if (mainSheet) {
            spreadsheets.push(mainSheet);
        }
        gridRef.current!.api.forEachDetailGridInfo((node: DetailGridInfo) => {
            const sheet = node.api!.getSheetDataForExcel({
                sheetName: 'Details',
            });
            if (sheet) {
                spreadsheets.push(sheet);
            }
        });

        gridRef.current!.api.exportMultipleSheetsAsExcel({
            data: spreadsheets,
        });
    }, []);

    const uploadData = useCallback(async () => {
        const opts = {
            types: [
                {
                    description: 'Excel Workbook',
                    accept: { 'text/plain': ['.xlsx'] },
                },
            ],
        };
        const fileHandle = await window.showOpenFilePicker(opts);
        const fileData = await fileHandle[0].getFile();
        const fileContent = await fileData.arrayBuffer();
        processFile(fileContent);
    }, []);

    const processFile = (fileContent: any) => {
        const excelFile = read(fileContent);
        const mainSheet = excelFile.Sheets[excelFile.SheetNames[0]];
        const mainData = utils.sheet_to_json(mainSheet);
        if (excelFile.SheetNames.length > 1) {
            Object.keys(excelFile.Sheets).forEach((detail) => {
                if (excelFile.Sheets[detail] !== mainSheet) {
                    mainData.push(
                        utils.sheet_to_json(excelFile.Sheets[detail])
                    );
                }
            });
        }
        populateGrid(mainData);
    };

    const populateGrid = (mainData: any[]) => {
        let rowData: any[] = [];

        Object.keys(mainData).forEach((index) => {
            let row: any = {};
            let mainRecord = mainData[Number(index)];
            if (!Array.isArray(mainRecord)) {
                row = buildRows(mainData, props.mainExportSchema, index);
                row.detailRecords = [];
                rowData.push(row);
            } else {
                Object.keys(mainRecord).forEach((indexDetail) => {
                    let rowDetail = buildRows(
                        mainRecord,
                        props.detailExportSchema,
                        indexDetail
                    );

                    rowData.map((item) => {
                        if (item.code === rowDetail.code) {
                            item.detailRecords.push(rowDetail);
                        }
                    });
                });
            }
        });
        props.onUploadImport(rowData);
    };

    const buildRows = (data: any, dataColumns: any, index: string): any => {
        let row: any = {};
        let record = data[Number(index)];
        Object.keys(dataColumns).forEach((column) => {
            let currentColumn = dataColumns[Number(column)] as ColDef;
            if (currentColumn.field) {
                if (!currentColumn.colId) {
                    if (currentColumn.type === 'dateColumn') {
                        record[currentColumn.headerName] = convertToDateFormat(
                            record[currentColumn.headerName],
                            true
                        );
                    }
                    row[currentColumn.field] = record[currentColumn.headerName];
                    return;
                }
                row[currentColumn.colId] = {
                    code: record[currentColumn.headerName],
                };
            }
        });
        return row;
    };

    function validationStatus(params: any) {
        let valid = (
            <CheckCircleOutlineIcon color={'success'}></CheckCircleOutlineIcon>
        );
        let error = (
            <Tooltip
                title={
                    <span style={{ whiteSpace: 'pre-line' }}>
                        {params.data.errorMessage ?? ''}
                    </span>
                }>
                <ErrorOutlineIcon color={'error'}></ErrorOutlineIcon>
            </Tooltip>
        );
        return params.value === true
            ? valid
            : params.value === false
            ? error
            : '';
    }

    return {
        gridRef,
        onGridReady,
        exportSchema,
        uploadData,
        validationStatus,
        onSelectionChanged,
        selectedRows,
    };
};

export default useUploadGridModal;
