import { Grid } from '@mui/material';
import TextInput from '../../../../../../../../components/form/formInputs/TextInput/TextInput';
import React from 'react';
import {
    BlobData,
    SdsBlobSectionFormProps,
} from '../../../shared/blobs/hooks/SdsBlobSectionFormProps';
import {
    SdsHeader,
    SdsSubSection11_5,
} from '../../../../../../../../types/formulation';
import useSdsBlobSectionForm from '../../../shared/blobs/hooks/useSdsBlobSectionForm';
import SdsSubSectionForm from '../../../shared/blobs/components/SdsSubSectionForm';
import TranslatableText from '../../../../../../../../components/i18n/TranslatableText';
import { SDSFormDefs } from '../../../../../../../../constants/i18n/translations/termDefinitions/formulation';

const SubSection11_5Form = (props: SdsBlobSectionFormProps<SdsHeader>) => {
    const { isParentLoading } = props.parentData;

    const { disabledField } = props.permissions;

    const blobData: BlobData<SdsSubSection11_5> = {
        sectionNumber: 11,
        subSectionNumber: 5,
        blankBlobEntity: {
            additionalText: '',
            aspirationHazard: '',
            germCellMutagenicity: '',
            reproductiveToxicity: '',
            specificTargetOrganToxicitySingleExposure: '',
            specificTargetOrganToxicityRepeatedExposure: '',
            carcinogenicity: '',
        },
    };

    const { fields, isBlobLoading, formMethods, handleFieldChange } =
        useSdsBlobSectionForm<SdsHeader, SdsSubSection11_5>({
            ...props,
            blobData,
        });

    return (
        <SdsSubSectionForm
            formTitle={
                (
                    <TranslatableText
                        termSet={props.termSet}
                        termKey={SDSFormDefs.Hazardous_Chemical_Listing_In_NTP}
                    />
                ) as unknown as string
            }
            isFormLoading={isParentLoading || isBlobLoading}
            content={
                <>
                    <Grid item xs={12}>
                        <TextInput
                            fullWidth
                            label={
                                <TranslatableText
                                    termSet={props.termSet}
                                    termKey={SDSFormDefs.Carcinogenicity}
                                />
                            }
                            id="carcinogenicity"
                            disabled={disabledField}
                            formMethods={formMethods}
                            value={fields.carcinogenicity}
                            onChange={handleFieldChange}
                            maxLength={2000}
                            multiline={true}
                            maxRows={15}
                            minRows={5}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextInput
                            fullWidth
                            label={
                                <TranslatableText
                                    termSet={props.termSet}
                                    termKey={SDSFormDefs.Germ_Cell_Mutagenicity}
                                />
                            }
                            id="germCellMutagenicity"
                            disabled={disabledField}
                            formMethods={formMethods}
                            value={fields.germCellMutagenicity}
                            onChange={handleFieldChange}
                            maxLength={2000}
                            multiline={true}
                            maxRows={15}
                            minRows={5}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextInput
                            fullWidth
                            label={
                                <TranslatableText
                                    termSet={props.termSet}
                                    termKey={SDSFormDefs.Reproductive_Toxicity}
                                />
                            }
                            id="reproductiveToxicity"
                            disabled={disabledField}
                            formMethods={formMethods}
                            value={fields.reproductiveToxicity}
                            onChange={handleFieldChange}
                            maxLength={2000}
                            multiline={true}
                            maxRows={15}
                            minRows={5}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextInput
                            fullWidth
                            label={
                                <TranslatableText
                                    termSet={props.termSet}
                                    termKey={
                                        SDSFormDefs.Specific_Target_Organ_Toxicity_Single_Exposure
                                    }
                                />
                            }
                            id="specificTargetOrganToxicitySingleExposure"
                            disabled={disabledField}
                            formMethods={formMethods}
                            value={
                                fields.specificTargetOrganToxicitySingleExposure
                            }
                            onChange={handleFieldChange}
                            maxLength={2000}
                            multiline={true}
                            maxRows={15}
                            minRows={5}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextInput
                            fullWidth
                            label={
                                <TranslatableText
                                    termSet={props.termSet}
                                    termKey={
                                        SDSFormDefs.Specific_Target_Organ_Toxicity_Repeated_Exposure
                                    }
                                />
                            }
                            id="specificTargetOrganToxicityRepeatedExposure"
                            disabled={disabledField}
                            formMethods={formMethods}
                            value={
                                fields.specificTargetOrganToxicityRepeatedExposure
                            }
                            onChange={handleFieldChange}
                            maxLength={2000}
                            multiline={true}
                            maxRows={15}
                            minRows={5}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextInput
                            fullWidth
                            label={
                                <TranslatableText
                                    termSet={props.termSet}
                                    termKey={SDSFormDefs.Aspiration_Hazard}
                                />
                            }
                            id="aspirationHazard"
                            disabled={disabledField}
                            formMethods={formMethods}
                            value={fields.aspirationHazard}
                            onChange={handleFieldChange}
                            maxLength={2000}
                            multiline={true}
                            maxRows={15}
                            minRows={5}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextInput
                            fullWidth
                            label={
                                <TranslatableText
                                    termSet={props.termSet}
                                    termKey={SDSFormDefs.Additional_Text_Eleven}
                                />
                            }
                            id="additionalText"
                            disabled={disabledField}
                            formMethods={formMethods}
                            value={fields.additionalText}
                            onChange={handleFieldChange}
                            maxLength={2000}
                            multiline={true}
                            maxRows={15}
                            minRows={5}
                        />
                    </Grid>
                </>
            }
        />
    );
};
export default SubSection11_5Form;
