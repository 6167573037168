import React from 'react';
import { Card, CardContent, CardHeader, Grid } from '@mui/material';
import { useParams } from 'react-router-dom';
import DatacorLogoSpinner from '../../../components/datacorLogoSpinner/DatacorLogoSpinner';
import useSafetyDataSheetSectionHeaderContainer from './hooks/useSafetyDataSheetSectionHeaderContainer';
import SafetyDataSheetSectionHeaderGrid from './grid/SafetyDataSheetSectionHeaderGrid';
import SafetyDataSheetSectionHeaderFormContainer from './form/SafetyDataSheetSectionHeaderFormContainer';
import { SdsSectionHeadersGridDefs } from '../../../constants/i18n/translations/termDefinitions/formulation';
import TranslatableText from '../../../components/i18n/TranslatableText';

export interface SdsSectionHeaderContainerProps {
    showForm: boolean;
}

type ParamTypes = { headerId: string; localeGroupId: string };

const SafetyDataSheetSectionHeaderContainer = ({
    showForm,
}: SdsSectionHeaderContainerProps) => {
    const { headerId, localeGroupId } = useParams<ParamTypes>();

    const headerIdLocaleGrpId =
        headerId && localeGroupId ? `${headerId}${localeGroupId}` : undefined;

    const {
        languagesList,
        defaultLangId,
        isLoadingLanguages,
        safetyDataSheetSectionHeaderKeyList,
        handleSetHeaderKeyList,
        handleSetIsFormDirty,
        handlePromptFormDirty,
        termSet,
    } = useSafetyDataSheetSectionHeaderContainer();

    return (
        <>
            {isLoadingLanguages ? (
                <DatacorLogoSpinner />
            ) : (
                <Card sx={{ height: '855px' }}>
                    <CardContent sx={{ minHeight: '850px', padding: '0' }}>
                        <CardHeader
                            title={
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={
                                        SdsSectionHeadersGridDefs.SDS_Section_Headers
                                    }
                                />
                            }
                        />
                        <Grid
                            container
                            spacing={1}
                            minHeight={'850px'}
                            paddingLeft={'1em'}
                            paddingRight={'1em'}
                            paddingBottom={'4em'}>
                            <Grid item xs={12} xl={6} minHeight={'400px'}>
                                <SafetyDataSheetSectionHeaderGrid
                                    defaultLanguageId={defaultLangId}
                                    currentheaderIdLocaleGrpId={
                                        headerIdLocaleGrpId
                                    }
                                    handleSetHeaderKeyList={
                                        handleSetHeaderKeyList
                                    }
                                    handlePromptFormDirty={
                                        handlePromptFormDirty
                                    }
                                />
                            </Grid>
                            <Grid item xs={12} xl={6} minHeight={'400px'}>
                                {!showForm ? (
                                    <></>
                                ) : (
                                    <SafetyDataSheetSectionHeaderFormContainer
                                        headerId={headerId}
                                        localeGroupId={localeGroupId}
                                        defaultLangId={defaultLangId}
                                        languageList={languagesList}
                                        safetyDataSheetSectionHeaderKeyList={
                                            safetyDataSheetSectionHeaderKeyList
                                        }
                                        handlePromptFormDirty={
                                            handlePromptFormDirty
                                        }
                                        handleSetIsFormDirty={
                                            handleSetIsFormDirty
                                        }
                                    />
                                )}
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            )}
        </>
    );
};

export default SafetyDataSheetSectionHeaderContainer;
