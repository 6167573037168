import React from 'react';
import { Checkbox, FormControlLabel, Grid } from '@mui/material';
import { useParams } from 'react-router-dom';
import useFixedAssetsCostCodeForm from './hooks/useFixedAssetsCostCodeForm';
import FormPartial from '../../components/form/formContainer/FormPartial';
import TextInput from '../../components/form/formInputs/TextInput/TextInput';
import NumericInput from '../../components/form/formInputs/NumericInput/NumericInput';
import BaseForm, { ParamTypes } from '../../components/form/baseForm/BaseForm';
import { NumberMaxValues, RoutingValues } from '../../types/Shared.types';
import { ASSET_COST_CODE_FIELDS } from '../../constants/revisionHistory/revisionHistory.constants';
import { AssetCostCodesFormDefs } from '../../constants/i18n/translations/termDefinitions/accounting';
import TranslatableText from '../../components/i18n/TranslatableText';

const FixedAssetsCostCodeForm = () => {
    const { id } = useParams<ParamTypes>();
    const {
        fields,
        handleFieldChange,
        formMethods,
        onCreate,
        onUpdate,
        onDelete,
        closeForm,
        isLoadingCostCode,
        canCreateAssetCostCode,
        canUpdateAssetCostCode,
        canDeleteAssetCostCode,
        termSet,
    } = useFixedAssetsCostCodeForm(id);

    return (
        <BaseForm
            title={
                <TranslatableText
                    termSet={termSet}
                    termKey={AssetCostCodesFormDefs.Fixed_Asset_Cost_Code}
                />
            }
            formMethods={formMethods}
            isLoading={isLoadingCostCode}
            isNew={id === RoutingValues.newId}
            onCreate={onCreate}
            onUpdate={onUpdate}
            onDelete={onDelete}
            onCancel={closeForm}
            showOnRevision={true}
            fieldMappings={ASSET_COST_CODE_FIELDS}
            entityUuid={fields?.uuid}
            displayCreateButton={canCreateAssetCostCode}
            displayUpdateButton={canUpdateAssetCostCode}
            displayDeleteButton={canDeleteAssetCostCode}>
            <FormPartial>
                <Grid container flexDirection="row" spacing={2}>
                    <Grid item xs={6}>
                        <TextInput
                            fullWidth
                            label={
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={AssetCostCodesFormDefs.Cost_Code}
                                />
                            }
                            id="code"
                            value={fields.code}
                            onChange={handleFieldChange}
                            formMethods={formMethods}
                            required={true}
                            maxLength={30}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <NumericInput
                            fullWidth
                            label={
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={AssetCostCodesFormDefs.Sort_Order}
                                />
                            }
                            id="sortOrder"
                            value={fields.sortOrder}
                            onChange={handleFieldChange}
                            formMethods={formMethods}
                            required={true}
                            maxValue={NumberMaxValues.smallint}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <FormControlLabel
                            label={
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={AssetCostCodesFormDefs.Is_Add}
                                />
                            }
                            control={
                                <Checkbox
                                    id="isAdd"
                                    checked={fields.isAdd}
                                    onChange={handleFieldChange}
                                />
                            }
                        />
                    </Grid>
                </Grid>
            </FormPartial>
        </BaseForm>
    );
};

export default FixedAssetsCostCodeForm;
