import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { RootStateOrAny, useSelector, useDispatch } from 'react-redux';
import { PermissionsUtil } from '../../../../utils/permissions/permissionsUtil';
import { PERMISSIONS } from '../../../../constants/permissions/Permissions.constants';
import { FirstDataRenderedEvent, GridReadyEvent } from 'ag-grid-community';
import { openSnackbarBasicWithMessage } from '../../../../store/uiElements';
import { updateGridModels } from '../../../../store/grids';
import { RootState } from '../../../../store';
import {
    useListLocaleGroupQuery,
    useUpdateAllLocaleGroupMutation,
} from '../../../../services/formulation/localeGroup/localeGroup.service';
import SettingsContext from '../../../../contexts/settings.context';
import { useGetTermSetQuery } from '../../../../services/i18n/i18n.service';
import { FORMULATION_DEFS } from '../../../../constants/i18n/translations/termSetDefinitions/formulation';
import { skipToken } from '@reduxjs/toolkit/query';

const useLocaleGroupGrid = () => {
    const navigate = useNavigate();
    const user = useSelector((state: RootStateOrAny) => state.user);
    const { formulation } = useSelector((state: RootState) => state.grids);
    const dispatch = useDispatch();

    const { isLoading, data: localeGroupList } = useListLocaleGroupQuery();

    const [selectedRows, setSelectedRows] = useState([]);
    const [gridColumnApi, setGridColumnApi] = useState(null);
    const [gridApi, setGridApi] = useState(null);

    const canCreateLocaleGroup = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.FORMULATION.LOCALE_GROUPS.CREATE
    );
    const canViewLocaleGroup = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.FORMULATION.LOCALE_GROUPS.VIEW
    );

    const canUpdateLocaleGroup = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.FORMULATION.LOCALE_GROUPS.EDIT
    );

    const { settings } = useContext(SettingsContext);
    const { data: termSet } = useGetTermSetQuery(
        settings?.userSettings
            ? {
                  languageId: settings?.userSettings?.languageId,
                  code: FORMULATION_DEFS.LOCALE_GROUPS_GRID,
              }
            : skipToken
    );

    const redirectToForm = (row: any) => {
        if (row && row.id) {
            navigate(`/formulation/localeGroup/${row.id}`);
        } else {
            navigate('/formulation/localeGroup/new');
        }
    };

    const [updateAll] = useUpdateAllLocaleGroupMutation();

    const onGridReady = (params: GridReadyEvent) => {
        setGridApi(params.api);
        setGridColumnApi(params.columnApi);
    };

    const onFirstDataRendered = (params: FirstDataRenderedEvent) => {
        gridColumnApi?.applyColumnState({
            state: formulation.localeGroupList.column,
            applyOrder: true,
        });

        gridApi?.setFilterModel(formulation.localeGroupList.filter);
    };

    const updateSelectedRows = (event: any) => {
        const rowsSelected = event.api.getSelectedNodes();
        setSelectedRows(rowsSelected);
    };

    const handleUpdateLocaleGroups = async (event: any) => {
        try {
            if (selectedRows.length > 5) {
                throw Error('5 Locales can be active');
            } else if (selectedRows.length < 1) {
                throw Error('At least one locale should be active');
            } else {
                const rowsToUpdate: any = [];

                selectedRows.forEach((row) => {
                    rowsToUpdate.push(row.data.id);
                });

                const args = {
                    businessEntityId: user?.businessEntity?.id?.toString(),
                    postBody: { idList: rowsToUpdate },
                };

                await updateAll(args);

                dispatch(
                    openSnackbarBasicWithMessage({
                        message: 'Locale Groups have been updated',
                        severity: 'success',
                    })
                );
            }
        } catch (e) {
            dispatch(
                openSnackbarBasicWithMessage({
                    message: e.message,
                    severity: 'error',
                })
            );
        }
    };

    const onSortChanged = () => {
        dispatch(
            updateGridModels({
                gridLocation: 'formulation',
                gridName: 'localeGroupList',
                type: 'column',
                model: gridColumnApi.getColumnState(),
            })
        );
    };

    const onFilterChanged = () => {
        dispatch(
            updateGridModels({
                gridLocation: 'formulation',
                gridName: 'localeGroupList',
                type: 'filter',
                model: gridApi.getFilterModel(),
            })
        );
    };

    return {
        isLoading,
        localeGroupList,
        redirectToForm,
        onFirstDataRendered,
        handleUpdateLocaleGroups,
        updateSelectedRows,
        canCreateLocaleGroup,
        canViewLocaleGroup,
        canUpdateLocaleGroup,
        onGridReady,
        onSortChanged,
        onFilterChanged,
        termSet,
    };
};

export default useLocaleGroupGrid;
