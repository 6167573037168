import React, { CSSProperties } from 'react';
import { Card, CardHeader, Grid } from '@mui/material';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Button from '@mui/material/Button';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import DatacorLogoSpinner from '../../../../components/datacorLogoSpinner/DatacorLogoSpinner';
import GhsHazardPhraseForm from './GhsHazardPhraseForm';
import useGhsHazardPhraseFormContainer, {
    HazardFormContainerProps,
} from './hooks/useGhsHazardPhraseFormContainer';
import './GhsHazardPhraseFormContainer.css';
import LanguageSelectionModal from '../../shared/languageSelectionModal/LanguageSelectionModal';
import TranslatableText from '../../../../components/i18n/TranslatableText';
import { GhsHazardPhrasesFormDefs } from '../../../../constants/i18n/translations/termDefinitions/formulation';

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
    style?: CSSProperties;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
    return (
        <div
            style={props.style}
            role="tabpanel"
            className={'phrase-tabpanel'}
            hidden={value !== index}
            id={`phrase-tabpanel-${index}`}
            aria-labelledby={`phrase-tab-${index}`}
            {...other}>
            {value === index && <Box sx={{ p: 0 }}>{children}</Box>}
        </div>
    );
}

const GhsHazardPhraseFormContainer = ({
    phraseId,
    defaultLangId,
    languageList,
    phraseIdList,
    handleSetIsFormDirty,
    handlePromptFormDirty,
}: HazardFormContainerProps) => {
    const {
        phraseLangList,
        isLoadingHazardPhraseList,
        langTabValue,
        languageOptionList,
        showModal,
        disableLangButton,
        handleTabChange,
        addNewLanguage,
        handleOpenModal,
        handleSetPreviousTab,
        canCreateGhsHazardPhrase,
        cancelNewLanguage,
        handleSetDisableLangButton,
        termSet,
    } = useGhsHazardPhraseFormContainer({
        phraseId,
        defaultLangId,
        languageList,
        phraseIdList,
        handleSetIsFormDirty,
        handlePromptFormDirty,
    });
    return (
        <>
            {isLoadingHazardPhraseList ? (
                <DatacorLogoSpinner />
            ) : (
                <Card sx={{ height: '100%' }}>
                    <CardHeader
                        sx={{ paddingBottom: 0 }}
                        title={
                            <Grid container flexDirection="row" spacing={2}>
                                <Grid item xs={6} style={{ paddingTop: '0px' }}>
                                    <p>
                                        <TranslatableText
                                            termSet={termSet}
                                            termKey={
                                                !phraseId
                                                    ? GhsHazardPhrasesFormDefs.Create_Phrases
                                                    : GhsHazardPhrasesFormDefs.Edit_Phrases
                                            }
                                        />
                                    </p>
                                </Grid>
                                <Grid
                                    item
                                    xs={6}
                                    container
                                    justifyContent="flex-end"
                                    style={{ paddingTop: '0px' }}>
                                    <Button
                                        variant="text"
                                        size="small"
                                        onClick={() => handleOpenModal()}
                                        data-testid="create-new-btn"
                                        endIcon={<ManageSearchIcon />}
                                        sx={{
                                            marginBottom: '5%',
                                            marginTop: '5%',
                                        }}
                                        disabled={
                                            !phraseId ||
                                            !canCreateGhsHazardPhrase ||
                                            disableLangButton
                                        }>
                                        <TranslatableText
                                            termSet={termSet}
                                            termKey={
                                                GhsHazardPhrasesFormDefs.Add_Language
                                            }
                                        />
                                    </Button>
                                </Grid>
                            </Grid>
                        }
                    />
                    <Grid
                        container
                        flexDirection="row"
                        spacing={1}
                        height={'100%'}>
                        <Grid item xs={2}>
                            <Tabs
                                orientation="vertical"
                                variant="scrollable"
                                value={langTabValue}
                                onChange={handleTabChange}
                                textColor="inherit"
                                indicatorColor="secondary"
                                sx={{
                                    borderRight: 1,
                                    borderColor: 'divider',
                                    height: {
                                        //breakpoints, apply style based on screen width
                                        xl: '90%',
                                        md: '85%',
                                        sm: '70%',
                                    },
                                    width: '100%',
                                }}>
                                {phraseLangList &&
                                    phraseLangList.map((phraseLang) => {
                                        return (
                                            <Tab
                                                key={phraseLang.langId.toString()}
                                                label={phraseLang.langText}
                                            />
                                        );
                                    })}
                            </Tabs>
                        </Grid>
                        <Grid item xs={10}>
                            {phraseLangList &&
                                phraseLangList.map((phraseLang, index) => {
                                    return (
                                        <TabPanel
                                            key={phraseLang.langId.toString()}
                                            value={langTabValue}
                                            index={index}
                                            style={{ height: '90%' }}>
                                            <GhsHazardPhraseForm
                                                id={phraseLang.id}
                                                currentPhraseId={
                                                    phraseLang.phraseId
                                                }
                                                currentHazardType={
                                                    phraseLang.hazardTypeId
                                                }
                                                currentUserInputNeeded={
                                                    phraseLang.companySpecific
                                                }
                                                defaultLangId={defaultLangId}
                                                currentLangId={
                                                    phraseLang.langId
                                                }
                                                phraseIdList={phraseIdList}
                                                allowDelete={
                                                    phraseLangList.length ===
                                                        1 ||
                                                    (phraseLangList.length >
                                                        1 &&
                                                        phraseLang.langId !==
                                                            defaultLangId)
                                                }
                                                handleSetPreviousTab={
                                                    handleSetPreviousTab
                                                }
                                                handleSetIsFormDirty={
                                                    handleSetIsFormDirty
                                                }
                                                cancelNewLanguage={
                                                    cancelNewLanguage
                                                }
                                                setDisableLangButton={
                                                    handleSetDisableLangButton
                                                }
                                            />
                                        </TabPanel>
                                    );
                                })}
                        </Grid>
                    </Grid>
                    <LanguageSelectionModal
                        showModal={showModal}
                        handleModalClose={addNewLanguage}
                        languageList={languageOptionList}
                    />
                </Card>
            )}
        </>
    );
};

export default GhsHazardPhraseFormContainer;
