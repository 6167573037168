import useTranslation from '../../../../components/i18n/hooks/useTranslation';
import { ACCOUNTING_DEFS } from '../../../../constants/i18n/translations/termSetDefinitions/accounting';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import { PermissionsUtil } from '../../../../utils/permissions/permissionsUtil';
import { PERMISSIONS } from '../../../../constants/permissions/Permissions.constants';
import React, { useEffect, useState } from 'react';
import { auditableGridColumns } from '../../../grid/utils/auditableGrid/auditableGridColumns';
import {
    useListPeriodStatusQuery,
    useUpdateListPeriodStatusMutation,
} from '../../../../services/accounting/accounting.service';
import { isNilOrEmpty } from '../../../../utils/objectUtils';
import { ColDef } from 'ag-grid-community';
import DefaultColumnTypes from '../../../../components/grids/columns/Column.constants';
import CustomHeader from '../../../../components/grids/CustomHeader';
import { PeriodStatusGridDefs } from '../../../../constants/i18n/translations/termDefinitions/accounting';
import _ from 'lodash';
import { getMaxSortOrderValue } from '../../../../store/accounting';
import { PeriodStatus } from '../../../../types/Accounting.types';
import { useNavigate } from 'react-router-dom';
import { RoutingValues } from '../../../../types/Shared.types';

const usePeriodStatusGrid = () => {
    //translation
    const { termSet, isLoadingTermSet } = useTranslation(
        ACCOUNTING_DEFS.PERIOD_STATUS_GRID
    );

    //permissions
    const user = useSelector((state: RootState) => state.user);
    const canView = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.ACCOUNTING.PERIOD_STATUSES.VIEW
    );
    const canCreate = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.ACCOUNTING.PERIOD_STATUSES.CREATE
    );

    const canUpdate = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.ACCOUNTING.PERIOD_STATUSES.EDIT
    );

    //column and data definitions
    const [colDefs, setColDefs] = useState([]);
    const [rowData, setRowData] = useState([]);
    const [hiddenIdColumns, auditableColumns] = auditableGridColumns();

    const { data: entityList, isLoading: isLoading } =
        useListPeriodStatusQuery();
    const [updateList] = useUpdateListPeriodStatusMutation();

    useEffect(() => {
        if (!isNilOrEmpty(termSet)) {
            const columnDefs: ColDef[] = [
                ...hiddenIdColumns,
                {
                    field: 'sortOrder',
                    sort: 'asc',
                    ...DefaultColumnTypes.NumberOnly,
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={PeriodStatusGridDefs.SortOrder}
                            />
                        );
                    },
                },
                {
                    field: 'code',
                    ...DefaultColumnTypes.ShortText,
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={PeriodStatusGridDefs.Code}
                            />
                        );
                    },
                },
                {
                    field: 'description',
                    ...DefaultColumnTypes.LongText,
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={PeriodStatusGridDefs.Description}
                            />
                        );
                    },
                },
                {
                    field: 'closed',
                    ...DefaultColumnTypes.Boolean,
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={PeriodStatusGridDefs.Closed}
                            />
                        );
                    },
                },
                ...auditableColumns,
            ];
            setColDefs(columnDefs);
        }
    }, [termSet]);

    useEffect(() => {
        if (!isNilOrEmpty(entityList)) {
            setRowData(entityList);
        }
    }, [entityList]);

    //set sortOrder
    const dispatch = useDispatch();
    const handleGridEdits = (editedRows: PeriodStatus[]) => {
        updateList(editedRows);
    };
    useEffect(() => {
        let maxValue = 1;
        if (entityList && entityList.length > 0) {
            const data = _.maxBy(
                entityList,
                (periodStatus: PeriodStatus) => periodStatus.sortOrder
            );
            maxValue = data.sortOrder + 1;
        }
        dispatch(
            getMaxSortOrderValue({ isLoading: false, maxValue: maxValue })
        );
    }, entityList);

    //redirect
    const navigate = useNavigate();
    const redirectToForm = (row: any) => {
        if (row.id) {
            navigate(`/accounting/setup/periodStatus/${row.id}`);
        } else {
            navigate(`/accounting/setup/periodStatus/${RoutingValues.newId}`);
        }
    };

    return {
        canView,
        canCreate,
        canUpdate,
        termSet,
        rowData,
        colDefs,
        handleGridEdits,
        isLoadingData: isLoading || isLoadingTermSet,
        redirectToForm,
    };
};

export default usePeriodStatusGrid;
