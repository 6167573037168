import {
    FA_Asset,
    FA_AssetCostCode,
    FA_AssetType,
    FA_DepreciationMethod,
    FA_DepreciationMethodStatus,
    FA_DepreciationMethodType,
    FA_AssetStatus,
    FA_AssetContract,
    FixedAssetCreateScheduleArgs,
    FixedAssetScheduleListParams,
    FixedAssetDepreciationEntryListParams,
    FixedAssetContractParams,
    FixedAssetDisposalEntryListParams,
    AssetEntryListParams,
    AcquisitionsParams,
    DisposalsParams,
    DepreciationSummaryReportParams,
    DepreciationDetailParams,
} from '../../types/FixedAsset.types';
import {
    BaseCreateEntityArgs,
    BaseUpdateEntityArgs,
} from '../serviceInterfaces';
import { datacorAppApi } from '../datacorAppApi';

export const fixedAssetsAPI = datacorAppApi.injectEndpoints({
    endpoints: (build) => ({
        //region Fixed Asset
        createFixedAsset: build.mutation<any, BaseCreateEntityArgs<FA_Asset>>({
            query: (args) => ({
                url: 'fixedAsset',
                method: 'POST',
                body: args.postBody,
            }),
            invalidatesTags: ['AllFixedAssets', 'AcquisitionReport'],
        }),

        updateFixedAsset: build.mutation<any, BaseUpdateEntityArgs<FA_Asset>>({
            query: (args) => ({
                url: `fixedAsset/${args.id}`,
                method: 'PATCH',
                body: args.postBody,
            }),
            invalidatesTags: [
                'AllFixedAssets',
                'ActiveFixedAsset',
                'AcquisitionReport',
            ],
        }),

        getFixedAsset: build.query<any, string>({
            query: (id) => `fixedAsset/${id}?includeAll=true`,
            providesTags: ['ActiveFixedAsset'],
            transformResponse: (asset: FA_Asset) => {
                //create an array of the underlying assetContracts
                asset.contractList = asset.contracts?.map(
                    (assetContract: FA_AssetContract) => {
                        return assetContract.contract;
                    }
                );

                return asset;
            },
        }),

        getAllFixedAssets: build.query<any, void>({
            query: () => `fixedAsset/getAll?includeCosts=true`,
            providesTags: ['AllFixedAssets'],
        }),

        importFixedAssets: build.mutation<boolean, FA_Asset[]>({
            query: (args) => ({
                url: 'fixedAsset/importAssets',
                method: 'POST',
                body: args,
            }),
            invalidatesTags: ['AllFixedAssets'],
        }),

        validateFixedAssets: build.mutation<boolean, FA_Asset[]>({
            query: (args) => ({
                url: 'fixedAsset/validateAssets',
                method: 'POST',
                body: args,
            }),
        }),

        //endregion

        //region Fixed Asset Status
        createStatus: build.mutation<any, BaseCreateEntityArgs<FA_AssetStatus>>(
            {
                query: (args) => ({
                    url: 'fixedAsset/status',
                    method: 'POST',
                    body: args.postBody,
                }),
                invalidatesTags: ['AllStatuses'],
            }
        ),

        updateStatus: build.mutation<any, BaseUpdateEntityArgs<FA_AssetStatus>>(
            {
                query: (args) => ({
                    url: `fixedAsset/status/${args.id}`,
                    method: 'PATCH',
                    body: args.postBody,
                }),
                invalidatesTags: ['AllStatuses', 'ActiveStatus'],
            }
        ),

        getStatus: build.query<any, string>({
            query: (id) => `fixedAsset/status/${id}`,
            providesTags: ['ActiveStatus'],
        }),

        getAllStatus: build.query<any, void>({
            query: () => `fixedAsset/status/getAll`,
            providesTags: ['AllStatuses'],
        }),

        getStatusesMap: build.query<any, void>({
            query: () => `fixedAsset/status/getAll`,
            transformResponse: (response: FA_AssetStatus[]) => {
                return new Map(
                    response.map((status: FA_AssetStatus) => [
                        status.id,
                        status,
                    ])
                );
            },
            providesTags: ['AllStatuses'],
        }),

        getStatusesForDropdown: build.query<any, void>({
            query: () => `fixedAsset/status/getAll`,
            transformResponse: (response: FA_AssetStatus[]) => {
                return response.map((status: FA_AssetStatus) => {
                    return { value: status.id, label: status.code };
                });
            },
            providesTags: ['AllStatuses'],
        }),

        deleteStatus: build.mutation<any, string>({
            query: (id) => ({
                url: `fixedAsset/status/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['AllStatuses'],
        }),

        updateListStatuses: build.mutation<boolean, FA_AssetStatus[]>({
            query: (args) => ({
                url: `fixedAsset/status/updateList`,
                method: 'POST',
                body: args,
            }),
            invalidatesTags: ['AllStatuses', 'ActiveStatus'],
        }),
        //endregion

        //region Fixed Asset Cost Code
        createCostCode: build.mutation<
            any,
            BaseCreateEntityArgs<FA_AssetCostCode>
        >({
            query: (args) => ({
                url: 'fixedAsset/fixedAssetCostCode',
                method: 'POST',
                body: args.postBody,
            }),
            invalidatesTags: ['AllCostCodes'],
        }),

        updateCostCode: build.mutation<
            any,
            BaseUpdateEntityArgs<FA_AssetCostCode>
        >({
            query: (args) => ({
                url: `fixedAsset/fixedAssetCostCode/${args.id}`,
                method: 'PATCH',
                body: args.postBody,
            }),
            invalidatesTags: ['AllCostCodes', 'ActiveCostCode'],
        }),

        getCostCode: build.query<any, string>({
            query: (id) => `fixedAsset/fixedAssetCostCode/${id}`,
            providesTags: ['ActiveCostCode'],
        }),

        getAllCostCodes: build.query<any, void>({
            query: () => `fixedAsset/fixedAssetCostCode/getAll`,
            providesTags: ['AllCostCodes'],
        }),

        deleteCostCode: build.mutation<any, string>({
            query: (id) => ({
                url: `fixedAsset/fixedAssetCostCode/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['AllCostCodes'],
        }),

        updateListCostCodes: build.mutation<boolean, FA_AssetCostCode[]>({
            query: (args) => ({
                url: `fixedAsset/fixedAssetCostCode/updateList`,
                method: 'POST',
                body: args,
            }),
            invalidatesTags: ['AllCostCodes', 'ActiveCostCode'],
        }),
        //endregion

        //region Asset Types
        createType: build.mutation<any, BaseCreateEntityArgs<FA_AssetType>>({
            query: (args) => ({
                url: 'fixedAsset/type',
                method: 'POST',
                body: args.postBody,
            }),
            invalidatesTags: ['AllTypes'],
        }),

        updateType: build.mutation<any, BaseUpdateEntityArgs<FA_AssetType>>({
            query: (args) => ({
                url: `fixedAsset/type/${args.id}`,
                method: 'PATCH',
                body: args.postBody,
            }),
            invalidatesTags: ['AllTypes', 'ActiveType'],
        }),

        getType: build.query<any, string>({
            query: (typeId) => `fixedAsset/type/${typeId}?full=true`,
            providesTags: ['ActiveType'],
        }),

        getAllTypes: build.query<any, void>({
            query: () => `fixedAsset/type/getAll`,
            providesTags: ['AllTypes'],
        }),

        getTypesMap: build.query<any, void>({
            query: () => `fixedAsset/type/getAll`,
            transformResponse: (response: FA_AssetType[]) => {
                return new Map(
                    response.map((type: FA_AssetType) => [type.id, type])
                );
            },
            providesTags: ['AllTypes'],
        }),

        getTypesForDropdown: build.query<any, void>({
            query: () => `fixedAsset/type/getAll`,
            transformResponse: (response: FA_AssetType[]) => {
                return response.map((type: FA_AssetType) => {
                    return { value: type.id, label: type.code };
                });
            },
            providesTags: ['AllTypes'],
        }),

        deleteType: build.mutation<any, string>({
            query: (typeId) => ({
                url: `fixedAsset/type/${typeId}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['AllTypes'],
        }),

        updateListTypes: build.mutation<boolean, FA_AssetType[]>({
            query: (args) => ({
                url: `fixedAsset/type/updateList`,
                method: 'POST',
                body: args,
            }),
            invalidatesTags: ['AllTypes', 'ActiveType'],
        }),
        //endregion

        //region Depreciation Method Statuses
        createDepreciationMethodStatus: build.mutation<
            any,
            BaseCreateEntityArgs<FA_DepreciationMethodStatus>
        >({
            query: (args) => ({
                url: 'fixedAsset/depreciationMethodStatus',
                method: 'POST',
                body: args.postBody,
            }),
            invalidatesTags: ['AllDepreciationMethodStatuses'],
        }),

        updateDepreciationMethodStatus: build.mutation<
            any,
            BaseUpdateEntityArgs<FA_DepreciationMethodStatus>
        >({
            query: (args) => ({
                url: `fixedAsset/depreciationMethodStatus/${args.id}`,
                method: 'PATCH',
                body: args.postBody,
            }),
            invalidatesTags: [
                'AllDepreciationMethodStatuses',
                'ActiveDepreciationMethodStatus',
            ],
        }),

        getDepreciationMethodStatus: build.query<any, string>({
            query: (typeId) => `fixedAsset/depreciationMethodStatus/${typeId}`,
            providesTags: ['ActiveDepreciationMethodStatus'],
        }),

        getAllDepreciationMethodStatuses: build.query<any, void>({
            query: () => `fixedAsset/depreciationMethodStatus/getAll`,
            providesTags: ['AllDepreciationMethodStatuses'],
        }),

        getDepreciationMethodStatusesMap: build.query<any, void>({
            query: () => `fixedAsset/depreciationMethodStatus/getAll`,
            transformResponse: (response: FA_DepreciationMethodStatus[]) => {
                return new Map(
                    response.map((status: FA_DepreciationMethodStatus) => [
                        status.id,
                        status,
                    ])
                );
            },
            providesTags: ['AllDepreciationMethodStatuses'],
        }),

        getDepreciationMethodStatusesForDropdown: build.query<any, void>({
            query: () => `fixedAsset/depreciationMethodStatus/getAll`,
            transformResponse: (response: FA_DepreciationMethodStatus[]) => {
                return response.map((status: FA_DepreciationMethodStatus) => {
                    return { value: status.id, label: status.code };
                });
            },
            providesTags: ['AllDepreciationMethodStatuses'],
        }),

        deleteDepreciationMethodStatus: build.mutation<any, string>({
            query: (typeId) => ({
                url: `fixedAsset/depreciationMethodStatus/${typeId}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['AllDepreciationMethodStatuses'],
        }),

        updateListDepreciationMethodStatuses: build.mutation<
            boolean,
            FA_DepreciationMethodStatus[]
        >({
            query: (args) => ({
                url: `fixedAsset/depreciationMethodStatus/updateList`,
                method: 'POST',
                body: args,
            }),
            invalidatesTags: [
                'AllDepreciationMethodStatuses',
                'ActiveDepreciationMethodStatus',
            ],
        }),
        //endregion

        //region Depreciation Method Types
        createDepreciationMethodType: build.mutation<
            any,
            BaseCreateEntityArgs<FA_DepreciationMethodType>
        >({
            query: (args) => ({
                url: 'fixedAsset/depreciationMethodType',
                method: 'POST',
                body: args.postBody,
            }),
            invalidatesTags: ['AllDepreciationMethodTypes'],
        }),

        updateDepreciationMethodType: build.mutation<
            any,
            BaseUpdateEntityArgs<FA_DepreciationMethodType>
        >({
            query: (args) => ({
                url: `fixedAsset/depreciationMethodType/${args.id}`,
                method: 'PATCH',
                body: args.postBody,
            }),
            invalidatesTags: [
                'AllDepreciationMethodTypes',
                'ActiveDepreciationMethodType',
            ],
        }),

        getDepreciationMethodType: build.query<any, string>({
            query: (id) => `fixedAsset/depreciationMethodType/${id}`,
            providesTags: ['ActiveDepreciationMethodType'],
        }),

        getAllDepreciationMethodTypes: build.query<any, void>({
            query: () => `fixedAsset/depreciationMethodType/getAll`,
            providesTags: ['AllDepreciationMethodTypes'],
        }),

        getDepreciationMethodTypesMap: build.query<any, void>({
            query: () => `fixedAsset/depreciationMethodType/getAll`,
            transformResponse: (response: FA_DepreciationMethodType[]) => {
                return new Map(
                    response.map((type: FA_DepreciationMethodType) => [
                        type.id,
                        type,
                    ])
                );
            },
            providesTags: ['AllDepreciationMethodTypes'],
        }),

        getDepreciationMethodTypesForDropdown: build.query<any, void>({
            query: () => `fixedAsset/depreciationMethodType/getAll`,
            transformResponse: (response: FA_DepreciationMethodType[]) => {
                return response.map((type: FA_DepreciationMethodType) => {
                    return {
                        value: type.id,
                        label: type.code,
                        object: type,
                    };
                });
            },
            providesTags: ['AllDepreciationMethodTypes'],
        }),

        getDepreciationMethodTypesForEntries: build.query<any, void>({
            query: () => `fixedAsset/depreciationMethodType/getAll`,
            transformResponse: (response: FA_DepreciationMethodType[]) => {
                return response
                    .filter((type) => type.postsJournalEntries)
                    .map((type: FA_DepreciationMethodType) => {
                        return {
                            value: type.id,
                            label: type.code,
                        };
                    });
            },
            providesTags: ['AllDepreciationMethodTypes'],
        }),

        deleteDepreciationMethodType: build.mutation<any, string>({
            query: (id) => ({
                url: `fixedAsset/depreciationMethodType/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['AllDepreciationMethodTypes'],
        }),

        updateListDepreciationMethodTypes: build.mutation<
            boolean,
            FA_DepreciationMethodType[]
        >({
            query: (args) => ({
                url: `fixedAsset/depreciationMethodType/updateList`,
                method: 'POST',
                body: args,
            }),
            invalidatesTags: [
                'AllDepreciationMethodTypes',
                'ActiveDepreciationMethodType',
            ],
        }),
        //endregion

        //region Depreciation Methods
        createDepreciationMethod: build.mutation<
            any,
            BaseCreateEntityArgs<FA_DepreciationMethod>
        >({
            query: (args) => ({
                url: 'fixedAsset/depreciationMethod',
                method: 'POST',
                body: args.postBody,
            }),
            invalidatesTags: ['AllDepreciationMethods'],
        }),

        updateDepreciationMethod: build.mutation<
            any,
            BaseUpdateEntityArgs<FA_DepreciationMethod>
        >({
            query: (args) => ({
                url: `fixedAsset/depreciationMethod/${args.id}`,
                method: 'PATCH',
                body: args.postBody,
            }),
            invalidatesTags: [
                'AllDepreciationMethods',
                'ActiveDepreciationMethod',
            ],
        }),

        getDepreciationMethod: build.query<any, string>({
            query: (id) => `fixedAsset/depreciationMethod/${id}?full=true`,
            providesTags: ['ActiveDepreciationMethod'],
        }),

        getAllDepreciationMethods: build.query<any, void>({
            query: () => `fixedAsset/depreciationMethod/getAll?full=true`,
            providesTags: ['AllDepreciationMethods'],
        }),

        getDepreciationMethodsMap: build.query<any, void>({
            query: () => `fixedAsset/depreciationMethod/getAll`,
            transformResponse: (response: FA_DepreciationMethod[]) => {
                return new Map(
                    response.map((method: FA_DepreciationMethod) => [
                        method.id,
                        method,
                    ])
                );
            },
            providesTags: ['AllDepreciationMethods'],
        }),

        getDepreciationMethodsForDropdown: build.query<any, void>({
            query: () => `fixedAsset/depreciationMethod/getAll?full=true`,
            transformResponse: (response: FA_DepreciationMethod[]) => {
                return response.map((method: FA_DepreciationMethod) => {
                    return {
                        value: method.id,
                        label: method.code,
                        object: method,
                    };
                });
            },
            providesTags: ['AllDepreciationMethods'],
        }),

        deleteDepreciationMethod: build.mutation<any, string>({
            query: (id) => ({
                url: `fixedAsset/depreciationMethod/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['AllDepreciationMethods'],
        }),

        updateListDepreciationMethods: build.mutation<
            boolean,
            FA_DepreciationMethod[]
        >({
            query: (args) => ({
                url: `fixedAsset/depreciationMethod/updateList`,
                method: 'POST',
                body: args,
            }),
            invalidatesTags: [
                'AllDepreciationMethods',
                'ActiveDepreciationMethod',
            ],
        }),
        //endregion

        //region Depreciation Schedules
        createFixedAssetSchedule: build.mutation<
            any,
            FixedAssetCreateScheduleArgs
        >({
            query: (args) => ({
                url: `fixedAsset/depreciationSchedule`,
                method: 'POST',
                body: { ...args },
            }),
            invalidatesTags: [
                'FA_DepreciationSchedules',
                'AllFixedAssets',
                'ActiveFixedAsset',
            ],
        }),

        updateDepreciationSchedules: build.mutation<any, any>({
            query: (args) => ({
                url: `fixedAsset/depreciationSchedule/update`,
                method: 'POST',
                body: { ...args },
            }),
            invalidatesTags: [
                'ActiveFixedAsset',
                'FA_DepreciationSchedules',
                'AllFixedAssets',
            ],
        }),

        deleteDepreciationSchedules: build.mutation<any, any>({
            query: (args) => ({
                url: `fixedAsset/depreciationSchedule`,
                method: 'DELETE',
                body: { ...args },
            }),
            invalidatesTags: [
                'ActiveFixedAsset',
                'FA_DepreciationSchedules',
                'AllFixedAssets',
            ],
        }),

        lockDepreciationSchedules: build.mutation<any, any>({
            query: (args) => ({
                url: `fixedAsset/depreciationSchedule/lock`,
                method: 'POST',
                body: { ...args },
            }),
            invalidatesTags: [
                'ActiveFixedAsset',
                'FA_DepreciationSchedules',
                'AllFixedAssets',
            ],
        }),

        unlockDepreciationSchedules: build.mutation<any, any>({
            query: (args) => ({
                url: `fixedAsset/depreciationSchedule/unlock`,
                method: 'POST',
                body: { ...args },
            }),
            invalidatesTags: [
                'ActiveFixedAsset',
                'FA_DepreciationSchedules',
                'AllFixedAssets',
            ],
        }),

        getAllFixedAssetDepreciationSchedules: build.query<
            any,
            FixedAssetScheduleListParams
        >({
            query: (params: FixedAssetScheduleListParams) => {
                return {
                    url: `fixedAsset/depreciationSchedule/getAll`,
                    params: params,
                };
            },
            providesTags: ['FA_DepreciationSchedules'],
        }),
        //endregion

        //region Depreciation Entries
        createFixedAssetDepreciationEntries: build.mutation<any, any>({
            query: (args) => ({
                url: `fixedAsset/depreciationEntry`,
                method: 'POST',
                body: { ...args },
            }),
            invalidatesTags: [
                'FA_DepreciationEntries',
                'ActiveFixedAsset',
                'DepreciationDetailReport',
                'FA_DepreciationSchedules',
            ],
        }),

        lockDepreciationEntries: build.mutation<any, any>({
            query: (args) => ({
                url: `fixedAsset/depreciationEntry/lock`,
                method: 'POST',
                body: { ...args },
            }),
            invalidatesTags: [
                'ActiveFixedAsset',
                'FA_DepreciationEntries',
                'FA_DepreciationSchedules',
            ],
        }),

        unlockDepreciationEntries: build.mutation<any, any>({
            query: (args) => ({
                url: `fixedAsset/depreciationEntry/unlock`,
                method: 'POST',
                body: { ...args },
            }),
            invalidatesTags: ['ActiveFixedAsset', 'FA_DepreciationEntries'],
        }),

        updateDepreciationEntries: build.mutation<any, any>({
            query: (args) => ({
                url: `fixedAsset/depreciationEntry/update`,
                method: 'POST',
                body: { ...args },
            }),
            invalidatesTags: [
                'ActiveFixedAsset',
                'FA_DepreciationEntries',
                'DepreciationDetailReport',
            ],
        }),

        deleteDepreciationEntries: build.mutation<any, any>({
            query: (args) => ({
                url: `fixedAsset/depreciationEntry`,
                method: 'DELETE',
                body: { ...args },
            }),
            invalidatesTags: [
                'ActiveFixedAsset',
                'FA_DepreciationEntries',
                'DepreciationDetailReport',
            ],
        }),

        getAllFixedAssetDepreciationEntries: build.query<
            any,
            FixedAssetDepreciationEntryListParams
        >({
            query: (params: FixedAssetDepreciationEntryListParams) => {
                return {
                    url: `fixedAsset/depreciationEntry/getAll`,
                    params: params,
                };
            },
            providesTags: ['FA_DepreciationEntries'],
        }),
        //endregion

        //region Disposal Entries
        createFixedAssetDisposalEntries: build.mutation<any, any>({
            query: (args) => ({
                url: `fixedAsset/disposalEntry`,
                method: 'POST',
                body: { ...args },
            }),
            invalidatesTags: [
                'AllFixedAssets',
                'ActiveFixedAsset',
                'DisposalReport',
            ],
        }),

        lockFixedAssetDisposalEntries: build.mutation<any, any>({
            query: (args) => ({
                url: `fixedAsset/disposalEntry/lock`,
                method: 'POST',
                body: { ...args },
            }),
            invalidatesTags: [
                'ActiveFixedAsset',
                'FA_DisposalEntries',
                'AllFixedAssets',
            ],
        }),

        unlockFixedAssetDisposalEntries: build.mutation<any, any>({
            query: (args) => ({
                url: `fixedAsset/disposalEntry/unlock`,
                method: 'POST',
                body: { ...args },
            }),
            invalidatesTags: [
                'ActiveFixedAsset',
                'FA_DisposalEntries',
                'AllFixedAssets',
            ],
        }),

        deleteFixedAssetDisposalEntries: build.mutation<any, any>({
            query: (args) => ({
                url: `fixedAsset/disposalEntry`,
                method: 'DELETE',
                body: { ...args },
            }),
            invalidatesTags: ['ActiveFixedAsset', 'DisposalReport'],
        }),

        getAllFixedAssetDisposalEntries: build.query<
            any,
            FixedAssetDisposalEntryListParams
        >({
            query: (params: FixedAssetDisposalEntryListParams) => {
                return {
                    url: `fixedAsset/disposalEntry/getAll`,
                    params: params,
                };
            },
            providesTags: ['FA_DisposalEntries'],
        }),
        //endregion

        //region Asset Entries
        createFixedAssetAssetEntries: build.mutation<any, any>({
            query: (args) => ({
                url: `fixedAsset/assetEntry`,
                method: 'POST',
                body: { ...args },
            }),
            invalidatesTags: ['AllFixedAssets', 'ActiveFixedAsset'],
        }),

        lockFixedAssetAssetEntries: build.mutation<any, any>({
            query: (args) => ({
                url: `fixedAsset/assetEntry/lock`,
                method: 'POST',
                body: { ...args },
            }),
            invalidatesTags: [
                'ActiveFixedAsset',
                'AllFixedAssets',
                'AssetEntries',
            ],
        }),

        unlockFixedAssetAssetEntries: build.mutation<any, any>({
            query: (args) => ({
                url: `fixedAsset/assetEntry/unlock`,
                method: 'POST',
                body: { ...args },
            }),
            invalidatesTags: [
                'ActiveFixedAsset',
                'AllFixedAssets',
                'AssetEntries',
            ],
        }),

        deleteFixedAssetAssetEntries: build.mutation<any, any>({
            query: (args) => ({
                url: `fixedAsset/assetEntry`,
                method: 'DELETE',
                body: { ...args },
            }),
            invalidatesTags: ['ActiveFixedAsset'],
        }),

        listAssetEntries: build.query<any, AssetEntryListParams>({
            query: (params: AssetEntryListParams) => {
                return {
                    url: `fixedAsset/assetEntry`,
                    params: params,
                };
            },
            providesTags: ['AssetEntries'],
        }),
        //endregion

        //region Asset Contract
        getAllFixedAssetContracts: build.query<any, FixedAssetContractParams>({
            query: (params: FixedAssetContractParams) => {
                return {
                    url: `fixedAsset/assetContract/getAll`,
                    params: params,
                };
            },
            providesTags: ['FA_AssetContract'],
        }),
        //endregion Asset Contract

        //region Reports
        AcquisitionReport: build.query<any, AcquisitionsParams>({
            query: (params: AcquisitionsParams) => {
                return {
                    url: `fixedAsset/reports/acquisitions`,
                    params: params,
                };
            },
            providesTags: ['AcquisitionReport'],
        }),

        DisposalReport: build.query<any, DisposalsParams>({
            query: (params: DisposalsParams) => {
                return {
                    url: `fixedAsset/reports/disposals`,
                    params: params,
                };
            },
            providesTags: ['DisposalReport'],
        }),

        DepreciationSummaryReport: build.query<
            any,
            DepreciationSummaryReportParams
        >({
            query: (params: DepreciationSummaryReportParams) => {
                return {
                    url: `fixedAsset/reports/depreciationSummary`,
                    params: params,
                };
            },
        }),

        DepreciationDetailReport: build.query<any, DepreciationDetailParams>({
            query: (params: DepreciationDetailParams) => {
                return {
                    url: `fixedAsset/reports/depreciationDetail`,
                    params: params,
                };
            },
            providesTags: ['DepreciationDetailReport'],
        }),
        //endregion Reports
    }),
});

export const {
    useGetFixedAssetQuery,
    useGetAllFixedAssetsQuery,
    useCreateFixedAssetMutation,
    useUpdateFixedAssetMutation,
    useCreateFixedAssetScheduleMutation,
    useGetAllStatusQuery,
    useGetStatusesForDropdownQuery,
    useGetStatusQuery,
    useDeleteStatusMutation,
    useCreateStatusMutation,
    useUpdateStatusMutation,
    useUpdateListStatusesMutation,
    useGetAllCostCodesQuery,
    useGetCostCodeQuery,
    useDeleteCostCodeMutation,
    useCreateCostCodeMutation,
    useUpdateCostCodeMutation,
    useUpdateListCostCodesMutation,
    useGetAllTypesQuery,
    useGetTypesForDropdownQuery,
    useGetTypeQuery,
    useDeleteTypeMutation,
    useUpdateListTypesMutation,
    useCreateTypeMutation,
    useUpdateTypeMutation,
    useGetAllDepreciationMethodStatusesQuery,
    useGetDepreciationMethodStatusesForDropdownQuery,
    useGetDepreciationMethodStatusQuery,
    useCreateDepreciationMethodStatusMutation,
    useUpdateDepreciationMethodStatusMutation,
    useDeleteDepreciationMethodStatusMutation,
    useUpdateListDepreciationMethodStatusesMutation,
    useGetAllDepreciationMethodTypesQuery,
    useGetDepreciationMethodTypesForDropdownQuery,
    useGetDepreciationMethodTypesForEntriesQuery,
    useGetDepreciationMethodTypeQuery,
    useCreateDepreciationMethodTypeMutation,
    useUpdateDepreciationMethodTypeMutation,
    useDeleteDepreciationMethodTypeMutation,
    useUpdateListDepreciationMethodTypesMutation,
    useGetAllDepreciationMethodsQuery,
    useGetDepreciationMethodsForDropdownQuery,
    useGetDepreciationMethodQuery,
    useUpdateListDepreciationMethodsMutation,
    useCreateDepreciationMethodMutation,
    useUpdateDepreciationMethodMutation,
    useDeleteDepreciationMethodMutation,
    useUpdateDepreciationSchedulesMutation,
    useDeleteDepreciationSchedulesMutation,
    useLockDepreciationSchedulesMutation,
    useUnlockDepreciationSchedulesMutation,
    useGetAllFixedAssetDepreciationSchedulesQuery,
    useCreateFixedAssetDepreciationEntriesMutation,
    useLockDepreciationEntriesMutation,
    useUnlockDepreciationEntriesMutation,
    useUpdateDepreciationEntriesMutation,
    useDeleteDepreciationEntriesMutation,
    useGetAllFixedAssetDepreciationEntriesQuery,
    useCreateFixedAssetDisposalEntriesMutation,
    useLockFixedAssetDisposalEntriesMutation,
    useUnlockFixedAssetDisposalEntriesMutation,
    useDeleteFixedAssetDisposalEntriesMutation,
    useGetAllFixedAssetDisposalEntriesQuery,
    useCreateFixedAssetAssetEntriesMutation,
    useLockFixedAssetAssetEntriesMutation,
    useUnlockFixedAssetAssetEntriesMutation,
    useDeleteFixedAssetAssetEntriesMutation,
    useImportFixedAssetsMutation,
    useValidateFixedAssetsMutation,
    useGetAllFixedAssetContractsQuery,
    useListAssetEntriesQuery,
    useAcquisitionReportQuery,
    useDisposalReportQuery,
    useDepreciationSummaryReportQuery,
    useDepreciationDetailReportQuery,
} = fixedAssetsAPI;
