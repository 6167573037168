import React from 'react';
import useItemChemicalCompositionGrid from './hooks/useItemChemicalCompositionGrid';
import { isNilOrEmpty } from '../../../../../../utils/objectUtils';
import DatacorLogoSpinner from '../../../../../../components/datacorLogoSpinner/DatacorLogoSpinner';
import BaseGrid from '../../../../../../components/grids/BaseGrid';
import { GridPopups } from '../../../../../../components/grids/Grid.constants';
import FormPartial from '../../../../../../components/form/formContainer/FormPartial';
import { ChildEditableGridWithCopyFunctionProps } from '../../../../../grid/utils/editableGrid/ChildEditableGridWithCopyFunctionProps';
import {
    ItemChemicalComposition,
    ItemMaster,
} from '../../../../../../types/formulation';
import TranslatableText from '../../../../../../components/i18n/TranslatableText';
import { ItemMasterFormDefs } from '../../../../../../constants/i18n/translations/termDefinitions/formulation';

const ItemChemicalCompositionGrid = (
    props: ChildEditableGridWithCopyFunctionProps<
        ItemMaster,
        ItemChemicalComposition
    >
) => {
    const {
        isLoadingCas,
        chemicalCompositionColumnDefs,
        handleGridEdits,
        handleRowValidations,
        colDefOptions,
        placeholderValues,
        termSet,
    } = useItemChemicalCompositionGrid(props);

    const { isParentLoading } = props.parentData;

    const { showAddButton, showDeleteButton, isGridEditable } =
        props.displayGridButtons;

    const { rowsData } = props.copyMethods;

    return (
        <>
            <FormPartial
                title={
                    (
                        <TranslatableText
                            termSet={termSet}
                            termKey={
                                ItemMasterFormDefs.Chemical_Composition_Text
                            }
                        />
                    ) as unknown as string
                }>
                {isParentLoading || isLoadingCas ? (
                    <DatacorLogoSpinner />
                ) : !isLoadingCas &&
                  isNilOrEmpty(chemicalCompositionColumnDefs) ? (
                    <div className="spinnerContainer">
                        Setup Data Unavailable
                    </div>
                ) : (
                    <BaseGrid
                        autoSize={true}
                        displayGrid={!isParentLoading}
                        displayCreateNewButton={showAddButton}
                        useRowDrag={isGridEditable}
                        displaySortButton={isGridEditable}
                        sortKey={'sortOrder'}
                        rowData={rowsData || []}
                        dataKey={'rowId'}
                        defaultColDef={colDefOptions}
                        gridClass="full-size-item"
                        displayToolbar={true}
                        sizeColumnsToFit={true}
                        onHandleGridEdits={handleGridEdits}
                        isEditable={isGridEditable}
                        context={GridPopups['ITEM_CHEMICAL_COMPOSITION']}
                        onHandleRowValidations={handleRowValidations}
                        columnDefs={chemicalCompositionColumnDefs}
                        newRowPlaceholderValues={{
                            casNumber: placeholderValues.casNumberPlaceholder,
                            lowerValue: placeholderValues.lowerValuePlaceholder,
                            upperValue: placeholderValues.upperValuePlaceholder,
                        }}></BaseGrid>
                )}
            </FormPartial>
        </>
    );
};

export default ItemChemicalCompositionGrid;
