import { ToxicTest } from '../../../types/formulation';
import {
    BaseCreateEntityArgs,
    BaseUpdateEntityArgs,
} from '../../serviceInterfaces';
import { datacorAppApi } from '../../datacorAppApi';

const serviceUrl: string = 'formulation/toxicTest';

export const toxicTestApi = datacorAppApi.injectEndpoints({
    endpoints: (build) => ({
        createToxicTest: build.mutation<any, BaseCreateEntityArgs<ToxicTest>>({
            query: (args) => ({
                url: serviceUrl,
                method: 'POST',
                body: args.postBody,
            }),
            invalidatesTags: ['ToxicTest'],
        }),

        updateToxicTest: build.mutation<any, BaseUpdateEntityArgs<ToxicTest>>({
            query: (args) => ({
                url: `${serviceUrl}/${args.id}`,
                method: 'PUT',
                body: args.postBody,
            }),
            invalidatesTags: ['ToxicTest', 'ActiveToxicTest'],
        }),
        deleteToxicTest: build.mutation<ToxicTest, string>({
            query: (id) => ({
                url: `${serviceUrl}/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['ToxicTest'],
        }),
        getToxicTest: build.query<any, string>({
            query: (id) => {
                return {
                    url: `${serviceUrl}/${id}`,
                };
            },
            providesTags: ['ActiveToxicTest'],
        }),
        getToxicTestDropdown: build.query<
            { label: string; value: bigint }[],
            void
        >({
            query: () => {
                return {
                    url: serviceUrl,
                };
            },
            transformResponse: (response: ToxicTest[]) => {
                return response.map((toxicTest) => {
                    return {
                        label: toxicTest.code,
                        value: toxicTest.id,
                        description: toxicTest.description,
                        id: toxicTest.id,
                        toxicTestCode: toxicTest.code,
                    };
                });
            },
            providesTags: ['ToxicTest'],
        }),
        listToxicTest: build.query<any, void>({
            query: () => {
                return {
                    url: serviceUrl,
                };
            },
            providesTags: ['ToxicTest'],
        }),
    }),
});

export const {
    useCreateToxicTestMutation,
    useUpdateToxicTestMutation,
    useDeleteToxicTestMutation,
    useGetToxicTestQuery,
    useGetToxicTestDropdownQuery,
    useListToxicTestQuery,
} = toxicTestApi;
