import React from 'react';
import UnitOfMeasureConversionGrid from './pages/UnitOfMeasureConversionGrid';

const UnitOfMeasureConversion = () => {
    return (
        <>
            <UnitOfMeasureConversionGrid />
        </>
    );
};

export default UnitOfMeasureConversion;
