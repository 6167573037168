import { Grid } from '@mui/material';
import React from 'react';
import TextInput from '../../../../../../../../components/form/formInputs/TextInput/TextInput';
import useSubSection15_2 from './hooks/useSubSection15_2';
import { SdsBlobSectionFormProps } from '../../../shared/blobs/hooks/SdsBlobSectionFormProps';
import { SdsHeader } from '../../../../../../../../types/formulation';
import SdsSubSectionForm from '../../../shared/blobs/components/SdsSubSectionForm';
import { SDSFormDefs } from '../../../../../../../../constants/i18n/translations/termDefinitions/formulation';
import TranslatableText from '../../../../../../../../components/i18n/TranslatableText';

const SubSection15_2Form = (props: SdsBlobSectionFormProps<SdsHeader>) => {
    const {
        fields,
        isBlobLoading,
        handleFieldChange,
        formMethods,
        isLoadingRegulationOptions,
    } = useSubSection15_2(props);

    const { isParentLoading } = props.parentData;

    const { disabledField } = props.permissions;

    return (
        <SdsSubSectionForm
            formTitle={
                (
                    <TranslatableText
                        termSet={props.termSet}
                        termKey={SDSFormDefs.Chemical_Safety_Assessment}
                    />
                ) as unknown as string
            }
            isFormLoading={
                isParentLoading || isBlobLoading || isLoadingRegulationOptions
            }
            content={
                <>
                    <Grid item xs={12}>
                        <TextInput
                            fullWidth
                            id="chemicalSafety"
                            onChange={handleFieldChange}
                            label={
                                <TranslatableText
                                    termSet={props.termSet}
                                    termKey={SDSFormDefs.Chemical_Safety}
                                />
                            }
                            disabled={disabledField}
                            formMethods={formMethods}
                            value={fields.chemicalSafety}
                            maxLength={2000}
                            multiline={true}
                            maxRows={15}
                            minRows={5}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextInput
                            fullWidth
                            label={
                                <TranslatableText
                                    termSet={props.termSet}
                                    termKey={
                                        SDSFormDefs.Additional_Text_Fifteen
                                    }
                                />
                            }
                            id="additionalText"
                            disabled={disabledField}
                            formMethods={formMethods}
                            value={fields.additionalText}
                            onChange={handleFieldChange}
                            maxLength={2000}
                            multiline={true}
                            maxRows={15}
                            minRows={5}
                        />
                    </Grid>
                </>
            }
        />
    );
};
export default SubSection15_2Form;
