import React from 'react';
import {
    useCreateProperShippingNameMutation,
    useUpdateProperShippingNameMutation,
    useDeleteProperShippingNameMutation,
    useGetProperShippingNameQuery,
} from '../../../services/organizations/organizations.service';
import { skipToken } from '@reduxjs/toolkit/query';
import { ProperShippingName } from '../../../types/Setup.types';
import useBaseForm from '../../form/hooks/useBaseForm';
import { RootStateOrAny, useSelector } from 'react-redux';
import { PermissionsUtil } from '../../../utils/permissions/permissionsUtil';
import { PERMISSIONS } from '../../../constants/permissions/Permissions.constants';

const UseProperShippingNameForm = (id: string) => {
    const user = useSelector((state: RootStateOrAny) => state.user);

    const canCreateProperShippingName = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.SETUP.PROPER_SHIPPING_NAME.CREATE
    );
    const canUpdateProperShippingName = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.SETUP.PROPER_SHIPPING_NAME.EDIT
    );
    const canDeleteProperShippingName = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.SETUP.PROPER_SHIPPING_NAME.DELETE
    );

    const {
        data: activeProperShippingName,
        isLoading: isLoadingProperShippingName,
    } = useGetProperShippingNameQuery(id ? id : skipToken);
    const [createProperShippingName] = useCreateProperShippingNameMutation();
    const [updateProperShippingName] = useUpdateProperShippingNameMutation();
    const [deleteProperShippingName] = useDeleteProperShippingNameMutation();

    const blankProperShippingName: ProperShippingName = {
        code: null,
        properShippingName: null,
        businessEntityId: user?.businessEntity?.id,
    };

    const {
        fields,
        handleFieldChange,
        formMethods,
        onCreate,
        onUpdate,
        onDelete,
        closeForm,
    } = useBaseForm({
        closePath: '/setup/properShippingName',
        reroute: true,
        blankEntity: blankProperShippingName,
        activeEntity: activeProperShippingName,
        getDescription: () => {
            return `DOT Proper Shipping Name ${fields.properShippingName}`;
        },
        createEntity: () => {
            return createProperShippingName({ postBody: fields });
        },
        updateEntity: () => {
            return updateProperShippingName({
                id: id as unknown as number,
                postBody: fields,
            });
        },
        deleteEntity: () => {
            return deleteProperShippingName(id);
        },
    });

    return {
        fields,
        handleFieldChange,
        formMethods,
        onCreate,
        onUpdate,
        onDelete,
        closeForm,
        isLoadingProperShippingName,
        canCreateProperShippingName,
        canUpdateProperShippingName,
        canDeleteProperShippingName,
    };
};

export default UseProperShippingNameForm;
