import {
    BaseCreateEntityArgs,
    BaseUpdateEntityArgs,
} from '../../../serviceInterfaces';
import { SdsHeader, SdsHeaderStatus } from '../../../../types/formulation';
import { datacorAppApi } from '../../../datacorAppApi';

const serviceUrl: string = 'sds/sdsHeader';
const statusServiceUrl: string = 'sds/sdsHeaderStatus';

export const sdsHeaderApi = datacorAppApi.injectEndpoints({
    endpoints: (build) => ({
        createSdsHeader: build.mutation<
            SdsHeader,
            BaseCreateEntityArgs<SdsHeader>
        >({
            query: (args) => ({
                url: serviceUrl,
                method: 'POST',
                body: args.postBody,
            }),
            invalidatesTags: ['SdsHeader', 'ActiveSdsHeader'],
        }),
        updateSdsHeader: build.mutation<
            SdsHeader,
            BaseUpdateEntityArgs<SdsHeader>
        >({
            query: (args) => ({
                url: `${serviceUrl}/${args.id}`,
                method: 'PUT',
                body: args.postBody,
            }),
            invalidatesTags: ['SdsHeader', 'ActiveSdsHeader'],
        }),
        deleteSdsHeader: build.mutation<SdsHeader, string>({
            query: (id) => ({
                url: `${serviceUrl}/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['SdsHeader'],
        }),
        getSdsHeader: build.query<SdsHeader, string>({
            query: (id) => {
                return {
                    url: `${serviceUrl}/${id}`,
                    params: { full: 'true' },
                };
            },
            providesTags: ['ActiveSdsHeader'],
        }),
        listSdsHeader: build.query<SdsHeader[], void>({
            query: () => {
                return {
                    url: serviceUrl,
                };
            },
            providesTags: ['SdsHeader'],
        }),
        getSdsHeaderSequence: build.query<any, void>({
            query: () => {
                return {
                    url: `${serviceUrl}/sdsSequences`,
                };
            },
            providesTags: ['SdsHeaderSequence'],
        }),
        getSdsHeaderSequenceParameters: build.query<any, void>({
            query: () => {
                return {
                    url: `${serviceUrl}/sdsSequences/parameters`,
                };
            },
            providesTags: ['SdsHeaderSequence'],
        }),
        updateSdsHeaderSequence: build.mutation<any, any>({
            query: (args: any) => {
                console.log(args);
                return {
                    url: `${serviceUrl}/sdsSequences/${args.id}`,
                    method: 'PUT',
                    body: args.postBody,
                };
            },
            invalidatesTags: ['SdsHeaderSequence'],
        }),
        getSdsHeaderStatusForDropdown: build.query<
            { label: string; value: bigint }[],
            void
        >({
            query: () => statusServiceUrl,
            transformResponse: (response: SdsHeaderStatus[]) => {
                return response.map((status) => {
                    return {
                        label: status.type,
                        value: status.id,
                    };
                });
            },
            providesTags: ['SdsHeaderStatus'],
        }),
        listSdsHeaderSequenceParents: build.query<any, void>({
            query: () => {
                return {
                    url: `${serviceUrl}/sdsSequences/parents`,
                };
            },
            providesTags: ['SdsHeaderSequence'],
        }),
    }),
});

export const {
    useCreateSdsHeaderMutation,
    useUpdateSdsHeaderMutation,
    useDeleteSdsHeaderMutation,
    useGetSdsHeaderQuery,
    useListSdsHeaderQuery,
    useGetSdsHeaderStatusForDropdownQuery,
    useGetSdsHeaderSequenceQuery,
    useUpdateSdsHeaderSequenceMutation,
    useGetSdsHeaderSequenceParametersQuery,
    useListSdsHeaderSequenceParentsQuery,
} = sdsHeaderApi;
