import {
    RDFormula,
    RDFormulaComponent,
    RDFormulaTotal,
} from '../../../../../../../types/formulation';
import { isNilOrEmpty } from '../../../../../../../utils/objectUtils';
import { GenericEditableRow } from '../../../../../../../components/grids/hooks/useBaseGridEditable';
import { SelectedDropdownOption } from '../../../../../../../types/Shared.types';
import { useContext, useState } from 'react';
import SettingsContext from '../../../../../../../contexts/settings.context';
import { useGetTermSetQuery } from '../../../../../../../services/i18n/i18n.service';
import { FORMULATION_DEFS } from '../../../../../../../constants/i18n/translations/termSetDefinitions/formulation';
import { skipToken } from '@reduxjs/toolkit/query';
import { useGetComponentTypesForDropdownQuery } from '../../../../../../../services/formulation/rdFormula/componentType/componentType.service';
import { PermissionsUtil } from '../../../../../../../utils/permissions/permissionsUtil';
import { PERMISSIONS } from '../../../../../../../constants/permissions/Permissions.constants';
import { useGetAllUsableItemsMasterQuery } from '../../../../../../../services/formulation/itemMaster/item/itemMaster.service';
import {
    useListBoilerPlateNoteQuery,
    useListUnitOfMeasureDropdownQuery,
} from '../../../../../../../services/organizations/organizations.service';
import _ from 'lodash';
import { UniqueColumnValueValidator } from '../../../../../../grid/validators/uniqueColumnValue.validator';
import { ColumnRequiredValueValidator } from '../../../../../../grid/validators/columnRequiredValue.validator';
import { ColumnNumericValueValidator } from '../../../../../../grid/validators/columnNumericValue.validator';
import { ColumnMinValueValidator } from '../../../../../../grid/validators/columnMinValue.validator';
import { ColumnMaxValueValidator } from '../../../../../../grid/validators/columnMaxValue.validator';
import applyEditableGridValidations from '../../../../../../grid/utils/applyEditableGridValidations';
import { equationsApi } from '../../../../../../../services/equations/equations.service';
import { useDispatch } from 'react-redux';
import { ChildEditableGridWithCopyFunctionProps } from '../../../../../../grid/utils/editableGrid/ChildEditableGridWithCopyFunctionProps';
import { RdFormulaTotalsUtil } from '../../rdFormulaTotals/RdFormulaTotals.util';
import NavFormContext from '../../../../../../../contexts/navForm.context';

const useRDFormulaComponentsFunctions = (
    props: ChildEditableGridWithCopyFunctionProps<RDFormula, RDFormulaComponent>
) => {
    const { user, currentParentRecord } = props.parentData;
    const { handleChildrenRecords, updateCompleteChildRecord } = props.helpers;
    const { settings } = useContext(SettingsContext);
    const dispatch = useDispatch();
    const { data: termSet } = useGetTermSetQuery(
        settings?.userSettings
            ? {
                  languageId: settings?.userSettings?.languageId,
                  code: FORMULATION_DEFS.RD_FORMULAS_FORM,
              }
            : skipToken
    );

    const { formulationNav } = useContext(NavFormContext);
    formulationNav.formulaTotals = currentParentRecord?.formulaTotals;

    const { data: componentTypeOptions, isLoading: isLoadingComponentTypes } =
        useGetComponentTypesForDropdownQuery();

    const canViewItem = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.FORMULATION.ITEM_MASTER.VIEW
    );

    const { data: itemList, isLoading: isLoadingItems } =
        useGetAllUsableItemsMasterQuery();

    const canViewUOM = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.SETUP.UNITS_OF_MEASURE.VIEW
    );

    const { data: unitOfMeasureOptions, isLoading: isLoadingUnitOfMeasure } =
        useListUnitOfMeasureDropdownQuery();

    const canViewBoilerPlateNotes = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.SETUP.BOILER_PLATE_NOTE.VIEW
    );

    const {
        data: boilerPlateNoteOptions,
        isLoading: isLoadingBoilerPlateNotes,
    } = useListBoilerPlateNoteQuery();

    const [formulaComponentColumnDefs, setFormulaComponentColumnDefs] =
        useState(null);
    const { rowsData, setRowsData } = props.copyMethods;
    const [unitOfMeasureColumnDefs, setUnitOfMeasureColumnDefs] =
        useState(null);
    const [itemColumnDefs, setItemColumnDefs] = useState(null);
    const [boilerPlateColumnDefs, setBoilerPlateColumnDefs] = useState(null);

    /**
     * Generate the next step number value
     */
    const getNextStepNumber = (rowData: RDFormulaComponent[]) => {
        let maxStep = 0;
        let defaultSettingValue = Number(
            settings?.formulationSettings?.componentStepNo
        );
        let defaultStep =
            isNaN(defaultSettingValue) || defaultSettingValue <= 0
                ? 10
                : defaultSettingValue;

        if (!isNilOrEmpty(rowData)) {
            maxStep = Math.max(
                ...rowData.map((component) =>
                    isNaN(Number(component.stepNo))
                        ? 0
                        : Number(component.stepNo)
                ),
                0
            );
            if (maxStep > 0) {
                maxStep = maxStep - (maxStep % defaultStep);
                return maxStep + defaultStep;
            }
        }

        return defaultStep;
    };

    /**
     * Clears and refreshes row with updated values
     */
    const clearRowValues = (currentEditingRow: any) => {
        currentEditingRow.componentCode = {
            id: null,
            display: '',
        };
        currentEditingRow.componentDescription = '';
        currentEditingRow.stockUomCode = {
            id: null,
            display: '',
        };
        currentEditingRow.componentUomCode = {
            id: null,
            display: '',
        };
        currentEditingRow.boilerPlateNoteCode = {
            id: null,
            display: '',
        };
        currentEditingRow.unitCost = 0;
        currentEditingRow.componentId = null;
        currentEditingRow.density = 0;
        currentEditingRow.errors = [];
        currentEditingRow.uomId = null;
        currentEditingRow.stockUomId = null;
        currentEditingRow.formulaQty = 0;
        currentEditingRow.hasError = false;
        currentEditingRow.lockQty = 2;
        currentEditingRow.scrapPercent = 0;
        currentEditingRow.specificGravity = 0;
        currentEditingRow.stockCost = 0;
        currentEditingRow.unitCost = 0;
        currentEditingRow.uomCost = 0;
        currentEditingRow.stockWeight = 0;
        currentEditingRow.stockVolume = 0;
        currentEditingRow.stockVolumeUnit = {
            id: null,
            display: '',
        };
        currentEditingRow.stockWeightUnit = {
            id: null,
            display: '',
        };
        currentEditingRow.weightPercent = 0;
        currentEditingRow.volumePercent = 0;
        currentEditingRow.extendedCost = 0;
        currentEditingRow.weightKg = 0;
        currentEditingRow.volumeLitres = 0;
        delete currentEditingRow.componentType;
    };

    /**
     * Get the default value for Component Type field
     */
    const getDefaultComponentType = (): any => {
        return componentTypeOptions?.find((componentType: any) =>
            (componentType.label as string).includes('Normal')
        ).value;
    };

    /**
     * Get the component value in the display format
     * @param componentId desired item ID
     */
    const getComponentCode = (componentId: bigint): SelectedDropdownOption => {
        let componentCode: SelectedDropdownOption = {
            id: null,
            display: '',
        };
        if (!isNilOrEmpty(componentId)) {
            itemList?.find((current: any) => {
                if (current.id === componentId) {
                    componentCode = {
                        id: current.id,
                        display: current.itemId,
                    };
                }
            });
        }
        return componentCode;
    };

    /**
     * Get all the values from item master for the selected component
     * @param id desired item ID
     * @param options List of items to look from
     */
    const getComponentDetails = (id: bigint, options: any) => {
        let detailRecord: any;
        options?.find((current: any) => {
            if (current.id === id) {
                detailRecord = current;
            }
        });
        return detailRecord;
    };

    /**
     * Get the Boiler Plate Note value in the display format
     * @param boilerPlateNoteId desired boilerplate ID
     */
    const getBoilerPlateNoteCode = (
        boilerPlateNoteId: bigint
    ): SelectedDropdownOption => {
        let boilerPlateCode: SelectedDropdownOption = {
            id: null,
            display: '',
        };
        if (!isNilOrEmpty(boilerPlateNoteId)) {
            boilerPlateNoteOptions?.find((current: any) => {
                if (current.id === boilerPlateNoteId) {
                    boilerPlateCode = {
                        id: current.id,
                        display: current.code,
                    };
                }
            });
        }
        return boilerPlateCode;
    };

    /**
     * Get the UOM value in the display format
     * @param unitId desired UOM ID
     */
    const getUOMCode = (unitId: bigint): SelectedDropdownOption => {
        let uomCode: SelectedDropdownOption = {
            id: null,
            display: '',
        };
        if (!isNilOrEmpty(unitId)) {
            unitOfMeasureOptions?.find((current: any) => {
                if (current.id === unitId) {
                    uomCode = {
                        id: current.id,
                        display: current.unitCode,
                    };
                }
            });
        }
        return uomCode;
    };

    /**
     * Updates the current row with the values from itemmaster using component id
     */
    const populateFromItem = (currentComponentRow: any) => {
        const details = getComponentDetails(
            currentComponentRow.componentCode?.id,
            itemList
        );

        if (details) {
            currentComponentRow.unitCost = details?.cost || 0;
            currentComponentRow.stockCost = details?.cost || 0;
            currentComponentRow.density =
                details?.itemUnitOfMeasure?.density || 0;
            currentComponentRow.specificGravity =
                details?.itemUnitOfMeasure?.specificGravity || 0;
            currentComponentRow.stockUomCode = getUOMCode(
                details?.itemUnitOfMeasure?.stockUnitId
            );
            currentComponentRow.componentUomCode = getUOMCode(
                details?.itemUnitOfMeasure?.stockUnitId
            );
            currentComponentRow.stockWeight =
                details?.itemUnitOfMeasure?.stockWeight || 0;
            currentComponentRow.stockVolume =
                details?.itemUnitOfMeasure?.stockVolume || 0;
            currentComponentRow.stockWeightUnit = getUOMCode(
                details?.itemUnitOfMeasure?.weightUnitId
            );
            currentComponentRow.stockVolumeUnit = getUOMCode(
                details?.itemUnitOfMeasure?.volumeUnitId
            );
        }
    };

    const calculateFormulaValues = async (currentComponent: any) => {
        const result: any = await dispatch(
            equationsApi.endpoints.calculateFormulaValues.initiate({
                postBody: {
                    stockUOM: currentComponent.stockUomCode?.display,
                    componentUOM: currentComponent.componentUomCode?.display,
                    stockWeightType:
                        settings?.formulationSettings?.itemWeight?.toUpperCase(),
                    stockWeight: currentComponent.stockWeight,
                    stockVolume: currentComponent.stockVolume,
                    stockWeightUnit: currentComponent.stockWeightUnit?.display,
                    stockVolumeUnit: currentComponent.stockVolumeUnit?.display,
                    density: currentComponent.density,
                    unitCost: currentComponent.unitCost,
                    formulaQty: isNilOrEmpty(currentComponent.formulaQty)
                        ? 0
                        : Number(currentComponent.formulaQty),
                    lockQty: currentComponent.lockQty,
                    scrapPercent: currentComponent.scrapPercent,
                    specificGravity: currentComponent.specificGravity,
                    stockCost: currentComponent.stockCost,
                    uomCost: currentComponent.uomCost,
                    weightPercent: currentComponent.weightPercent,
                    volumePercent: currentComponent.volumePercent,
                    extendedCost: currentComponent.extendedCost,
                    weightKg: currentComponent.weightKg,
                    volumeLitres: currentComponent.volumeLitres,
                },
            })
        );
        const { data } = result;
        return data;
    };

    const calculateTotals = async (rowData: any) => {
        const formulaUOM = await getUOMCode(
            formulationNav.formulaTotals.formulaUomId
        )?.display;

        if (!isNilOrEmpty(formulaUOM)) {
            for (const component of rowData) {
                const request = {
                    stockUOM: component.stockUomCode?.display,
                    componentUOM: formulaUOM,
                    stockWeightType:
                        settings?.formulationSettings?.itemWeight?.toUpperCase(),
                    stockWeight: component.stockWeight,
                    stockVolume: component.stockVolume,
                    stockWeightUnit: component.stockWeightUnit?.display,
                    stockVolumeUnit: component.stockVolumeUnit?.display,
                    density: component.density,
                    unitCost: component.unitCost,
                    formulaQty: isNilOrEmpty(component.formulaQty)
                        ? 0
                        : Number(component.formulaQty),
                    lockQty: component.lockQty,
                    scrapPercent: component.scrapPercent,
                    specificGravity: component.specificGravity,
                    stockCost: component.stockCost,
                    uomCost: component.uomCost,
                    weightPercent: component.weightPercent,
                    volumePercent: component.volumePercent,
                    extendedCost: component.extendedCost,
                    weightKg: component.weightKg,
                    volumeLitres: component.volumeLitres,
                };

                const result: any = await dispatch(
                    equationsApi.endpoints.calculateFormulaValues.initiate({
                        postBody: request,
                    })
                );

                const conversionFactor = result?.data?.conversionFactor || 1;
                component['componentToTotalConversionFactor'] = Number(
                    1 / conversionFactor
                );

                if (isNaN(Number(component.stockQty))) {
                    component.stockQty = 0;
                }

                if (isNaN(Number(component.scrapPercent))) {
                    component.scrapPercent = 0;
                }
            }
        }
        //Calculate the values for the Calculated Totals section
        const totals = RdFormulaTotalsUtil.getTotals(
            formulationNav.formulaTotals,
            rowData
        );

        const updatedTotals: any = {
            ...formulationNav.formulaTotals,
            formulaTotal: Number(totals.totalQty.toFixed(4)) || null,
            calculatedYieldPct:
                Number(totals.totalYieldPercentage.toFixed(4)) || null,
            net: Number(totals.totalNet.toFixed(4)) || null,
            manualYieldPct: Number(totals.manualYieldPct.toFixed(4)) || null,
        };

        formulationNav.formulaTotals = updatedTotals;
        return updatedTotals;
    };

    //Calculate the values for the Component Costs section
    const calculateTotalCosts = async (
        rowData: any,
        calculatedTotals: RDFormulaTotal
    ) => {
        const totalCosts = RdFormulaTotalsUtil.getTotalCosts(
            rowData,
            calculatedTotals
        );

        const updatedTotals = {
            ...calculatedTotals,
            totalExtendedCost: Number(totalCosts.totalExtendedCost.toFixed(4)),
            totalUnitCost: Number(totalCosts.totalUnitCost.toFixed(4)),
            totalNetCost: Number(totalCosts.totalNetCost.toFixed(4)),
        };

        return updatedTotals;
    };

    /**
     * Enables or disables cells on a row depending on component type
     */
    const enableDisableRowCell = (params: any) => {
        const componentType =
            params.data.componentType?.type ||
            componentTypeOptions?.find(
                (componentType: any) =>
                    (componentType.value as bigint) ===
                    params.data.componentTypeId
            ).label;

        const manualTypeFieldArray = [
            'boilerPlateNoteCode',
            'formulaQty',
            'scrapPercent',
            'componentUomCode',
            'uomCost',
            'lockQty',
            'stockCost',
        ];
        const textStageTypeFieldArray = ['boilerPlateNoteCode'];
        const normalTypeFieldArray = [...manualTypeFieldArray, 'componentCode'];
        const costingTypeFieldArray = ['stockCost', 'uomCost'];

        const isNormalType =
            componentType === 'Normal' &&
            normalTypeFieldArray.includes(params.colDef.field);
        const isManualType =
            componentType === 'Manual' &&
            manualTypeFieldArray.includes(params.colDef.field);
        const isTextType =
            componentType === 'Text' &&
            textStageTypeFieldArray.includes(params.colDef.field);
        const isStagingType =
            componentType === 'Stage' &&
            textStageTypeFieldArray.includes(params.colDef.field);
        const isCostingType =
            componentType === 'Costing' &&
            costingTypeFieldArray.includes(params.colDef.field);

        return (
            isNormalType ||
            isManualType ||
            isTextType ||
            isStagingType ||
            isCostingType
        );
    };

    /**
     * Returns all rows on the components grid
     */
    const getAllGridRows = (editedRows: RDFormulaComponent[]) => {
        const updatedFields: RDFormulaComponent[] = [...rowsData];

        editedRows?.forEach((row: any) => {
            const index = rowsData.findIndex(
                (formulaComponent: RDFormulaComponent) =>
                    formulaComponent.rowId === row.rowId
            );

            if (
                !isNilOrEmpty(row.componentCode) &&
                !isNilOrEmpty(row.componentCode?.id)
            ) {
                row.componentId = row.componentCode?.id;
            }

            if (
                !isNilOrEmpty(row.boilerPlateNoteCode) &&
                !isNilOrEmpty(row.boilerPlateNoteCode?.id)
            ) {
                row.boilerPlateNoteId = row.boilerPlateNoteCode?.id;
            }

            if (
                !isNilOrEmpty(row.stockUomCode) &&
                !isNilOrEmpty(row.stockUomCode?.id)
            ) {
                row.stockUomId = row.stockUomCode?.id;
            }

            if (
                !isNilOrEmpty(row.componentUomCode) &&
                !isNilOrEmpty(row.componentUomCode?.id)
            ) {
                row.uomId = row.componentUomCode?.id;
            }

            if (index > -1) {
                updatedFields[index] = row;
            } else {
                updatedFields.push(row);
            }
        });
        return updatedFields;
    };

    /**
     * Sort the components based on sequence numbers
     */
    const sortFormulaComponents = (componentsList: RDFormulaComponent[]) => {
        setRowsData(_.orderBy(componentsList, ['stepNo'], ['asc']));
    };

    /**
     * Handle edited records and push them to the parent record.
     * @param editedRows current edited rows
     */
    const handleGridEdits = (editedRows: RDFormulaComponent[]) => {
        const updatedFields = getAllGridRows(editedRows);
        sortFormulaComponents(updatedFields);
        handleChildrenRecords('components', updatedFields);
    };

    /**
     * Validations for the Component Row
     * @param editedRows edited Component records
     */
    const handleRowValidations = (editedRows: RDFormulaComponent[]) => {
        const updatedFields = getAllGridRows(editedRows);

        editedRows?.forEach((current) => {
            current.validationRules = [
                UniqueColumnValueValidator(
                    updatedFields,
                    current,
                    ['stepNo', 'componentTypeId', 'componentId'],
                    `Duplicate step No ${current.stepNo}.`
                ),
                ColumnRequiredValueValidator('Step No.', current.stepNo),
                ColumnRequiredValueValidator('Type', current.componentTypeId),
                ColumnNumericValueValidator(
                    'Step No.',
                    0,
                    Number(current.stepNo)
                ),
                ColumnMinValueValidator('Step No.', 0, Number(current.stepNo)),
                ColumnNumericValueValidator(
                    'Formula Qty',
                    8,
                    current.formulaQty
                ),
                ColumnMaxValueValidator(
                    'Formula Qty',
                    999999999,
                    Number(current.formulaQty)
                ),
                ColumnMinValueValidator(
                    'Formula Qty',
                    0,
                    Number(current.formulaQty)
                ),
                ColumnNumericValueValidator(
                    'UOM Cost',
                    6,
                    Number(current.uomCost)
                ),
                ColumnMaxValueValidator(
                    'UOM Cost',
                    999999999999999999,
                    Number(current.uomCost)
                ),
                ColumnMinValueValidator('UOM Cost', 0, Number(current.uomCost)),
                ColumnNumericValueValidator(
                    'Scrap Percentage',
                    0,
                    Number(current.scrapPercent)
                ),
                ColumnMaxValueValidator(
                    'Scrap Percentage',
                    100,
                    Number(current.scrapPercent)
                ),
                ColumnMinValueValidator(
                    'Scrap Percentage',
                    0,
                    Number(current.scrapPercent)
                ),
                ColumnNumericValueValidator(
                    'Stock Cost',
                    6,
                    Number(current.stockCost)
                ),
                ColumnMaxValueValidator(
                    'Stock Cost',
                    999999999999999999,
                    Number(current.stockCost)
                ),
                ColumnMinValueValidator(
                    'Stock Cost',
                    0,
                    Number(current.stockCost)
                ),
            ];
            const message = current.stepNo
                ? `Step No. ${current.stepNo}`
                : null;
            applyEditableGridValidations(current, message);
        });
        return editedRows;
    };

    /**
     * Actions on cell value changed
     */
    const handleColumnChanged = async (
        columnName: string,
        currentEditingRow: GenericEditableRow,
        rowData?: any[]
    ) => {
        if (columnName === 'stepNo') {
            if (isNilOrEmpty(currentEditingRow['stepNo'])) {
                currentEditingRow['stepNo'] = getNextStepNumber(rowData);
            }
        }
        if (columnName === 'componentCode') {
            populateFromItem(currentEditingRow);
        }
        if (columnName === 'componentTypeId') {
            clearRowValues(currentEditingRow);
        }
        if (columnName === 'uomCost') {
            let uomCost = Number(currentEditingRow.uomCost);
            if (isNaN(uomCost)) {
                uomCost = 0;
            }

            if (
                !isNaN(currentEditingRow.stockUomConversion) &&
                Number(currentEditingRow.stockUomConversion) > 0
            ) {
                currentEditingRow.stockCost = Number(
                    (uomCost / currentEditingRow.stockUomConversion).toFixed(6)
                );
                currentEditingRow.extendedCost = Number(
                    (
                        currentEditingRow.stockCost * currentEditingRow.stockQty
                    ).toFixed(4)
                );
            } else {
                currentEditingRow.stockCost = 0;
                currentEditingRow.extendedCost = 0;
            }

            //Calculate Total Costs -> Unit Cost, Net Cost, Extended Cost
            const totalCosts = await calculateTotalCosts(
                rowData,
                formulationNav.formulaTotals
            );

            formulationNav.formulaTotals = totalCosts;
            updateCompleteChildRecord('formulaTotals', totalCosts);
        }
        if (columnName === 'formulaQty' || columnName === 'componentUomCode') {
            const data = await calculateFormulaValues(currentEditingRow);

            currentEditingRow.conversionMethod = data?.method || '';
            currentEditingRow.stockUomConversion = data?.conversionFactor || 0;
            currentEditingRow.stockQty = data?.componentTotals?.stockQty || 0;
            currentEditingRow.extendedCost =
                data?.componentTotals?.extendedCost || 0;
            currentEditingRow.uomCost = data?.componentTotals?.unitCost || 0;
            currentEditingRow.weightKg =
                data?.componentTotals?.uomComponentWeight || 0;
            currentEditingRow.volumeLitres =
                data?.componentTotals?.uomComponentVolume || 0;

            //Calculate Total KG
            const totalKG = RdFormulaTotalsUtil.getTotalKG(rowData);

            //Calculate the values for the Calculated Totals section
            const totalLiters = RdFormulaTotalsUtil.getTotalLiters(rowData);

            //Using Total KG and Total Liters, calculate weight% and volume% for each component
            rowData.forEach((component: any) => {
                if (totalKG > 0) {
                    let componentWeight = Number(component.weightKg);
                    if (isNaN(componentWeight)) {
                        componentWeight = 0;
                    }
                    component.weightPercent = Number(
                        (componentWeight / totalKG) * 100
                    ).toFixed(4);
                }

                if (totalLiters > 0) {
                    let componentVol = Number(component.volumeLitres);
                    if (isNaN(componentVol)) {
                        componentVol = 0;
                    }
                    component.volumePercent = Number(
                        (componentVol / totalLiters) * 100
                    ).toFixed(4);
                }
            });
        }

        if (
            columnName === 'formulaQty' ||
            columnName === 'componentUomCode' ||
            columnName === 'componentCode'
        ) {
            if (isNilOrEmpty(currentEditingRow.formulaQty)) {
                currentEditingRow.formulaQty = 0;
            }

            const calculatedTotals = await calculateTotals(rowData);

            const updatedTotals = await calculateTotalCosts(
                rowData,
                calculatedTotals
            );

            formulationNav.formulaTotals = updatedTotals;
            updateCompleteChildRecord('formulaTotals', updatedTotals);
        }
    };

    const handleNewRow = (newRow: any, rowData?: any[]): any => {
        newRow['stepNo'] = getNextStepNumber(rowData);
        newRow['componentTypeId'] = getDefaultComponentType();
    };

    return {
        getNextStepNumber,
        getComponentCode,
        getComponentDetails,
        getBoilerPlateNoteCode,
        getUOMCode,
        getDefaultComponentType,
        enableDisableRowCell,
        termSet,
        canViewItem,
        canViewBoilerPlateNotes,
        canViewUOM,
        isLoadingItems,
        isLoadingBoilerPlateNotes,
        isLoadingUnitOfMeasure,
        isLoadingComponentTypes,
        componentTypeOptions,
        itemList,
        unitOfMeasureOptions,
        boilerPlateNoteOptions,
        formulaComponentColumnDefs,
        setFormulaComponentColumnDefs,
        rowsData,
        setRowsData,
        unitOfMeasureColumnDefs,
        setUnitOfMeasureColumnDefs,
        itemColumnDefs,
        setItemColumnDefs,
        boilerPlateColumnDefs,
        setBoilerPlateColumnDefs,
        handleGridEdits,
        handleRowValidations,
        handleColumnChanged,
        handleNewRow,
        sortFormulaComponents,
    };
};

export default useRDFormulaComponentsFunctions;
