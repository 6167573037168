import React from 'react';
import FormulaPropertyGrid from './FormulaPropertyGrid';

const FormulaProperty = () => {
    return (
        <>
            <FormulaPropertyGrid />
        </>
    );
};

export default FormulaProperty;
