import { datacorAppApi } from '../datacorAppApi';

export const featureFlagAPI = datacorAppApi.injectEndpoints({
    endpoints: (build) => ({
        getAvailableFeatureFlags: build.query<any, void>({
            query: () => `/featureFlags/getAvailableFlags`,
            providesTags: ['FeatureFlags'],
        }),
        getEnabledFeatureFlags: build.query<any, void>({
            query: () => `/featureFlags/getEnabledFlags`,
            providesTags: ['FeatureFlags'],
        }),
        updateCompanyFeatureFlags: build.mutation<any, any>({
            query: (featureFlag) => ({
                url: '/featureFlags/updateCompanyFeatureFlags',
                method: 'POST',
                body: featureFlag,
            }),
            invalidatesTags: ['FeatureFlags'],
        }),
    }),
});

export const {
    useGetAvailableFeatureFlagsQuery,
    useGetEnabledFeatureFlagsQuery,
    useUpdateCompanyFeatureFlagsMutation,
} = featureFlagAPI;
