import React from 'react';
import useFixedAssetTypeGrid from './hooks/useFixedAssetTypeGrid';
import BaseFormGrid from '../../components/grids/BaseFormGrid';
import { cloneDeep } from 'lodash';
import { AssetTypesGridDefs } from '../../constants/i18n/translations/termDefinitions/accounting';
import TranslatableText from '../../components/i18n/TranslatableText';
import { isNilOrEmpty } from '../../utils/objectUtils';

const FixedAssetTypeGrid = () => {
    const {
        typeList,
        isLoadingTypes,
        canCreateAssetType,
        canViewAssetType,
        canUpdate,
        redirectToForm,
        handleGridEdits,
        rowData,
        colDefs,
        termSet,
    } = useFixedAssetTypeGrid();

    return (
        <BaseFormGrid
            title={
                <TranslatableText
                    termSet={termSet}
                    termKey={AssetTypesGridDefs.Asset_Types}
                />
            }
            displayGrid={canViewAssetType}
            isLoading={isNilOrEmpty(colDefs)}
            rowData={cloneDeep(rowData)}
            useRowDrag={canUpdate}
            sortKey={'sortOrder'}
            columnDefs={colDefs}
            gridClass="full-size-item"
            displayToolbar={true}
            displayCreateNewButton={canCreateAssetType}
            displayExportDataButton={true}
            displayResetStateButton={true}
            onCreateNew={redirectToForm}
            onRowClicked={({ data }) => redirectToForm(data)}
            onHandleGridEdits={handleGridEdits}
        />
    );
};

export default FixedAssetTypeGrid;
