import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { onError } from '../../../libs/errorLib';
import { AppsService } from '../../../services/workflow/system/apps.service';
import { AgGridColumn } from 'ag-grid-react';
import AddEditApp from './AddEditApp';
import LoaderButton from '../../../components/LoaderButton';
import AgGrid from '../../../components/grids/Grid';
import { Card, CardHeader, CardContent, IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

const WorkflowAppList = () => {
    const [apps, setApps] = useState(null);
    const [showEditAppModal, setShowEditAppModal] = useState(false);
    const [selectedId, setSelectedId] = useState(null);
    const navigate = useNavigate();
    useEffect(() => {
        const onLoad = async () => {
            loadData();
        };

        onLoad();
    }, []);

    const loadData = async () => {
        try {
            setApps(await AppsService.list());
        } catch (e) {
            onError(e);
        }
    };

    const editApp = (row, event) => {
        if (!event.defaultPrevented) {
            setSelectedId(row.id);
            setShowEditAppModal(true);
        }
    };

    const handleModalClose = (refresh) => {
        setSelectedId(null);
        setShowEditAppModal(false);
        loadData();
    };

    const addApp = () => {
        setShowEditAppModal(true);
    };

    const EditAppColumn = (props) => {
        return (
            <IconButton
                size="small"
                color="primary"
                onClick={($event) => editApp(props.data, $event)}>
                <EditIcon />
            </IconButton>
        );
    };

    const OpenAppColumn = (props) => {
        return (
            <IconButton
                size="small"
                color="primary"
                onClick={() => navigate(`/workflow/apps/${props.data.id}`)}>
                <OpenInNewIcon />
            </IconButton>
        );
    };

    return (
        <>
            {showEditAppModal && (
                <AddEditApp
                    handleClose={handleModalClose}
                    id={selectedId}></AddEditApp>
            )}
            <Card>
                <CardHeader
                    style={{ height: '50px' }}
                    title="Workflow Portal"
                    action={
                        <Link to="/workflow/">
                            <LoaderButton variant="primary" size="lg">
                                Workflow Portal
                            </LoaderButton>
                        </Link>
                    }></CardHeader>
                <CardContent>
                    <AgGrid
                        rowHeight="35"
                        rowData={apps}
                        domLayout={'autoHeight'}
                        shouldAutofit={false}
                        suppressCellSelection={true}
                        displayToolbar={true}
                        displayCreateNewButton={true}
                        onCreateNew={addApp}
                        frameworkComponents={{
                            editAppColumn: EditAppColumn,
                            openAppColumn: OpenAppColumn,
                        }}>
                        <AgGridColumn
                            filter={false}
                            resizable={false}
                            sortable={false}
                            width={50}
                            minWidth={50}
                            field="editAppColumn"
                            headerName=""
                            cellRenderer="editAppColumn"
                        />
                        <AgGridColumn
                            filter={false}
                            resizable={false}
                            sortable={false}
                            width={50}
                            minWidth={50}
                            field="openAppColumn"
                            headerName=""
                            cellRenderer="openAppColumn"
                        />
                        <AgGridColumn field="name" />
                        <AgGridColumn field="description" flex={1} />
                    </AgGrid>
                </CardContent>
            </Card>
        </>
    );
};

export default WorkflowAppList;
