import React from 'react';
import FormPartial from '../../../../../../components/form/formContainer/FormPartial';
import DatacorLogoSpinner from '../../../../../../components/datacorLogoSpinner/DatacorLogoSpinner';
import SubSection13_1Form from './subSections/subSection13_1/SubSection13_1Form';
import { SdsBlobSectionFormProps } from '../shared/blobs/hooks/SdsBlobSectionFormProps';
import { SdsHeader } from '../../../../../../types/formulation';
import TranslatableText from '../../../../../../components/i18n/TranslatableText';
import { SDSFormDefs } from '../../../../../../constants/i18n/translations/termDefinitions/formulation';

const SdsDisposalConsiderationsForm = (
    props: SdsBlobSectionFormProps<SdsHeader>
) => {
    const { isParentLoading } = props.parentData;

    return (
        <>
            {isParentLoading ? (
                <DatacorLogoSpinner />
            ) : (
                <>
                    <FormPartial
                        link={props?.link}
                        title={
                            (
                                <TranslatableText
                                    termSet={props.termSet}
                                    termKey={
                                        SDSFormDefs.Disposal_Considerations
                                    }
                                />
                            ) as unknown as string
                        }>
                        <SubSection13_1Form
                            {...props}
                            termSet={props.termSet}
                        />
                    </FormPartial>
                </>
            )}
        </>
    );
};

export default SdsDisposalConsiderationsForm;
