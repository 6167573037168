import React from 'react';
import { Card, CardContent, CardHeader, Grid } from '@mui/material';
import { useParams } from 'react-router-dom';
import DatacorLogoSpinner from '../../../components/datacorLogoSpinner/DatacorLogoSpinner';
import useGhsHazardPhraseContainer from './hooks/useGhsHazardPhraseContainer';
import GhsHazardPhraseGrid from './grid/GhsHazardPhraseGrid';
import GhsHazardPhraseFormContainer from './form/GhsHazardPhraseFormContainer';
import TranslatableText from '../../../components/i18n/TranslatableText';
import { GhsHazardPhrasesGridDefs } from '../../../constants/i18n/translations/termDefinitions/formulation';

type ParamTypes = { phraseId: string };

export interface GhsHazardPhraseContainerProps {
    showForm: boolean;
}

const GhsHazardPhraseContainer = ({
    showForm,
}: GhsHazardPhraseContainerProps) => {
    const { phraseId } = useParams<ParamTypes>();

    const {
        handleSetPhraseList,
        handleSetIsFormDirty,
        handlePromptFormDirty,
        languagesList,
        defaultLangId,
        phraseIdList,
        isLoadingLanguages,
        canViewGhsHazardPhrase,
        termSet,
    } = useGhsHazardPhraseContainer(phraseId);

    return (
        <>
            {isLoadingLanguages ? (
                <DatacorLogoSpinner />
            ) : canViewGhsHazardPhrase ? (
                <Card sx={{ minHeight: '850px' }}>
                    <CardContent sx={{ minHeight: '850px', padding: '0' }}>
                        <CardHeader
                            title={
                                <TranslatableText
                                    termKey={
                                        GhsHazardPhrasesGridDefs.Hazard_Phrases
                                    }
                                    termSet={termSet}
                                />
                            }
                        />
                        <Grid
                            container
                            spacing={1}
                            minHeight={'850px'}
                            paddingLeft={'1em'}
                            paddingRight={'1em'}
                            paddingBottom={'4em'}>
                            <Grid item xs={12} xl={6} minHeight={'425px'}>
                                <GhsHazardPhraseGrid
                                    handleSetPhraseList={handleSetPhraseList}
                                    defaultLanguageId={defaultLangId}
                                    currentPhraseId={phraseId}
                                    handlePromptFormDirty={
                                        handlePromptFormDirty
                                    }
                                />
                            </Grid>
                            <Grid item xs={12} xl={6} minHeight={'425px'}>
                                {!showForm || !defaultLangId ? (
                                    <></>
                                ) : (
                                    <GhsHazardPhraseFormContainer
                                        phraseId={phraseId}
                                        defaultLangId={defaultLangId}
                                        languageList={languagesList}
                                        phraseIdList={phraseIdList}
                                        handleSetIsFormDirty={
                                            handleSetIsFormDirty
                                        }
                                        handlePromptFormDirty={
                                            handlePromptFormDirty
                                        }
                                    />
                                )}
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            ) : (
                <Card sx={{ height: '100%' }}></Card>
            )}
        </>
    );
};

export default GhsHazardPhraseContainer;
