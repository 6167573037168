import Scheduling_MRP_Production from '../libs/images/myApps/Scheduling_MRP_Production.png';
import Order_management from '../libs/images/myApps/Order_management.png';
import cloud_storage from '../libs/images/myApps/cloud_storage.png';
import ico02 from '../libs/images/myApps/ico02.png';
import PricingAnalysisTool from './images/myApps/PricingAnalysisTool.png';
import Implementation_Icons_Cleanup_Documentation from '../libs/images/myApps/Implementation_Icons_Cleanup_Documentation.png';
import CRM_demo_link from '../libs/images/myApps/CRM_demo_link.png';
import Business_intelligence_links from './images/myApps/Business_intelligence_links.png';
import Document_management from './images/myApps/Document_management.png';
import ApAutomation from './images/myApps/ApAutomation.png';
import { PERMISSIONS } from '../constants/permissions/Permissions.constants';
import Analytics from './images/myApps/Analytics.png';
import Workflow from './images/myApps/Workflow.png';
import FixedAssets_211 from './images/myApps/FixedAssets_211.png';
import Lab_Management from './images/myApps/Lab_Management.png';
import SetupApp from './images/myApps/Comprehensive_services_01.png';
import NavLink from '../utils/navigation/navLink';
import { FunctionGroupValues } from '../types/Shared.types';

//Need to use CORS Proxy for now until we have custom domain established and setup as accepted origin in Azure API Manager
//export const DATACOR_MULTIPLEXER_URL = 'https://cors-anywhere.herokuapp.com/https://datacor-secure-multiplexer.azure-api.net/api/';
export const DATACOR_MULTIPLEXER_URL =
    'https://datacor-secure-multiplexer.azure-api.net/api/';
export const DATACOR_MULTIPLEXER_URL_DOCUMENTATION =
    'https://datacor-secure-multiplexer.azure-api.net/swagger/docs/v1';

//region My Apps
const myApps = new NavLink({
    url: '/myApps',
    name: 'My Applications',
    about: '',
    featureFlag: 'dashboard.nav.myApps',
    appImage: { Implementation_Icons_Cleanup_Documentation },
    imageName: 'Implementation_Icons_Cleanup_Documentation',
});
//endregion

//region Scheduler
const scheduler = new NavLink({
    url: '/scheduler',
    name: 'Scheduler',
    about: 'Schedule batch ticket steps within the set parameters.',
    appImage: { Scheduling_MRP_Production },
    imageName: 'Scheduling_MRP_Production',
    featureFlag: 'dashboard.nav.apps.scheduler',
});
//endregion

//region App Streaming
const appStreaming = new NavLink({
    url: '/erpAppStreaming',
    name: 'App Streaming',
    about: 'Open your desktop applications in a browser.',
    appImage: { cloud_storage },
    imageName: 'cloud_storage',
    featureFlag: 'dashboard.nav.apps.erpAppStreaming',
});
//endregion

//region Pricing Analysis
const pricingAnalysis = new NavLink({
    url: '/pricingAnalysisTool',
    name: 'Pricing Analysis Tool',
    about: 'Customer product pricing details with highlighted pricing assessment and concerns.',
    appImage: { PricingAnalysisTool },
    imageName: 'PricingAnalysisTool',
    featureFlag: 'dashboard.nav.apps.pricingAnalysisTool',
});
//endregion

//region User Portal
const userPortal = new NavLink({
    url: '/administration/organizations/users',
    name: 'My User Portal',
    about: 'Add users to the shared user pool.',
    appImage: { ico02 },
    imageName: 'ico02',
    featureFlag: 'dashboard.nav.apps.administration',
    permissionKey: PERMISSIONS.USER_PORTAL.VIEW,
});
//endregion

//region API Documentation
const apiDocumentation = new NavLink({
    url: '/apiDocumentation',
    name: 'API Documentation',
    about: 'VB ERP API Documentation.',
    appImage: { Order_management },
    imageName: 'Order_management',
    featureFlag: 'dashboard.nav.apps.apiDocumentation',
});
//endregion

//region AP Automation
const apAutomation = new NavLink({
    url: '/apAutomation',
    name: 'AP Invoice Automation',
    about: 'Automate your invoice processing',
    appImage: { ApAutomation },
    imageName: 'ApAutomation',
    featureFlag: 'dashboard.nav.apps.apAutomation',
    permissionKey: PERMISSIONS.AP_AUTOMATION.VIEW,
});
//endregion

//region Fixed Assets
const fixedAssetsModule = new NavLink({
    url: '/accounting/fixedAssets',
    name: 'Fixed Assets',
    about: 'Asset Value Tracking',
    appImage: { FixedAssets_211 },
    imageName: 'FixedAssets_211',
    featureFlag: 'dashboard.nav.apps.fixedAssets',
});

const fixedAssets = new NavLink({
    url: '/accounting/fixedAssets/fixedAsset',
    name: 'Fixed Asset Register',
    about: 'View/Edit Fixed Assets',
    featureFlag: 'dashboard.nav.apps.fixedAssets',
    permissionKey: PERMISSIONS.FIXED_ASSETS.FIXED_ASSET_REGISTER.VIEW,
});

const fixedAssetSchedules = new NavLink({
    url: '/accounting/fixedAssets/assetSchedules',
    name: 'Fixed Asset Schedules',
    about: 'View/Update Fixed Asset Schedules',
    featureFlag: 'dashboard.nav.apps.fixedAssets',
    permissionKey: PERMISSIONS.FIXED_ASSETS.DEPRECIATION_SCHEDULES.VIEW,
});

const fixedAssetDepreciationEntries = new NavLink({
    url: '/accounting/fixedAssets/depreciationEntries',
    name: 'Depreciation Entries',
    about: 'View/Update Fixed Asset Depreciation Entries',
    featureFlag: 'dashboard.nav.apps.fixedAssets',
    permissionKey: PERMISSIONS.FIXED_ASSETS.DEPRECIATION_ENTRIES.VIEW,
});

const fixedAssetDisposalEntries = new NavLink({
    url: '/accounting/fixedAssets/disposalEntries',
    name: 'Disposal Entries',
    about: 'View Fixed Asset Disposal Entries',
    featureFlag: 'dashboard.nav.apps.fixedAssets',
    permissionKey: PERMISSIONS.FIXED_ASSETS.DISPOSAL_ENTRY.VIEW,
});

const assetEntries = new NavLink({
    url: '/accounting/fixedAssets/assetEntries',
    name: 'Asset Entries',
    about: 'View/Update Fixed Asset Entries',
    featureFlag: 'dashboard.nav.apps.fixedAssets',
    permissionKey: PERMISSIONS.FIXED_ASSETS.ASSET_ENTRY.VIEW,
});

const contracts = new NavLink({
    url: '/accounting/contract',
    name: 'Contracts',
    about: 'View/Edit Contracts',
    featureFlag: 'dashboard.nav.apps.fixedAssets',
    permissionKey: PERMISSIONS.FIXED_ASSETS.CONTRACTS.VIEW,
});

const contractCoverage = new NavLink({
    url: '/accounting/contractCoverage',
    name: 'Contract Coverage',
    about: 'View Contracts',
    featureFlag: 'dashboard.nav.apps.fixedAssets',
    permissionKey: PERMISSIONS.FIXED_ASSETS.CONTRACTS.VIEW_CONTRACT_COVERAGE,
});

const fixedAssetSetup = new NavLink({
    url: null,
    name: 'Setup',
    about: null,
    featureFlag: null,
});

const fixedAssetFunctionCodes = new NavLink({
    url: `/accounting/fixedAssets/functionCode/${FunctionGroupValues.fixedAssets}`,
    name: 'Function Codes',
    about: 'View/Edit Fixed Asset Function Codes',
    featureFlag: 'dashboard.nav.apps.fixedAssets',
    permissionKey: PERMISSIONS.FIXED_ASSETS.FUNCTION_CODES.VIEW,
});

const fixedAssetCostCodes = new NavLink({
    url: '/accounting/fixedAssets/costCode',
    name: 'Asset Cost Codes',
    about: 'View/Edit Fixed Asset Cost Codes',
    featureFlag: 'dashboard.nav.apps.fixedAssets',
    permissionKey: PERMISSIONS.FIXED_ASSETS.ASSET_COST_CODES.VIEW,
});

const fixedAssetStatuses = new NavLink({
    url: '/accounting/fixedAssets/status',
    name: 'Asset Statuses',
    about: 'View/Edit Fixed Asset Statuses',
    featureFlag: 'dashboard.nav.apps.fixedAssets',
    permissionKey: PERMISSIONS.FIXED_ASSETS.ASSET_STATUSES.VIEW,
});

const fixedAssetTypes = new NavLink({
    url: '/accounting/fixedAssets/type',
    name: 'Asset Types',
    about: 'View/Edit Fixed Asset Types',
    featureFlag: 'dashboard.nav.apps.fixedAssets',
    permissionKey: PERMISSIONS.FIXED_ASSETS.ASSET_TYPES.VIEW,
});

const fixedAssetDepreciationMethodStatuses = new NavLink({
    url: '/accounting/fixedAssets/depreciationMethodStatus',
    name: 'Depreciation Method Statuses',
    about: 'View/Edit Fixed Asset Depreciation Method Statuses',
    featureFlag: 'dashboard.nav.apps.fixedAssets',
    permissionKey: PERMISSIONS.FIXED_ASSETS.DEPRECIATION_METHOD_STATUSES.VIEW,
});

const fixedAssetDepreciationMethodTypes = new NavLink({
    url: '/accounting/fixedAssets/depreciationMethodType',
    name: 'Depreciation Method Types',
    about: 'View/Edit Fixed Asset Depreciation Method Types',
    featureFlag: 'dashboard.nav.apps.fixedAssets',
    permissionKey: PERMISSIONS.FIXED_ASSETS.DEPRECIATION_METHOD_TYPES.VIEW,
});

const fixedAssetDepreciationMethods = new NavLink({
    url: '/accounting/fixedAssets/depreciationMethod',
    name: 'Depreciation Methods',
    about: 'View/Edit Fixed Asset Depreciation Methods',
    featureFlag: 'dashboard.nav.apps.fixedAssets',
    permissionKey: PERMISSIONS.FIXED_ASSETS.DEPRECIATION_METHODS.VIEW,
});

const contractTypes = new NavLink({
    url: '/accounting/contractType',
    name: 'Contract Types',
    about: 'View/Edit Contract Types',
    featureFlag: 'dashboard.nav.apps.fixedAssets',
    permissionKey: PERMISSIONS.FIXED_ASSETS.CONTRACT_TYPES.VIEW,
});

const chartOfAccounts = new NavLink({
    url: '/accounting/generalLedgerAccount',
    name: 'Chart Of Accounts',
    about: 'View/Edit Chart Of accounts',
    featureFlag: 'dashboard.nav.apps.fixedAssets',
    permissionKey: PERMISSIONS.FIXED_ASSETS.CHART_OF_ACCOUNTS.VIEW,
});

const fixedAssetReport = new NavLink({
    url: null,
    name: 'Reports',
    about: null,
    featureFlag: null,
});

const acquisitions = new NavLink({
    url: '/accounting/fixedAssets/reports/acquisitions',
    name: 'Acquisitions',
    about: 'Acquisitions Report',
    featureFlag: 'dashboard.nav.apps.fixedAssets',
    permissionKey: PERMISSIONS.FIXED_ASSETS.REPORTS.ACQUISITIONS.VIEW,
});

const depreciationSummary = new NavLink({
    url: '/accounting/fixedAssets/reports/depreciationSummary',
    name: 'Depreciation Summary',
    about: 'Depreciation Summary Report',
    featureFlag: 'dashboard.nav.apps.fixedAssets',
    permissionKey: PERMISSIONS.FIXED_ASSETS.REPORTS.DEPRECIATION_SUMMARY.VIEW,
});

const depreciationDetail = new NavLink({
    url: '/accounting/fixedAssets/reports/depreciationDetail',
    name: 'Depreciation Detail',
    about: 'Depreciation Detail Report',
    featureFlag: 'dashboard.nav.apps.fixedAssets',
    permissionKey: PERMISSIONS.FIXED_ASSETS.REPORTS.DEPRECIATION_DETAIL.VIEW,
});

const disposals = new NavLink({
    url: '/accounting/fixedAssets/reports/disposals',
    name: 'Disposals',
    about: 'Disposals Report',
    featureFlag: 'dashboard.nav.apps.fixedAssets',
    permissionKey: PERMISSIONS.FIXED_ASSETS.REPORTS.DISPOSALS.VIEW,
});

fixedAssetSetup.children = [
    fixedAssetFunctionCodes,
    fixedAssetCostCodes,
    fixedAssetDepreciationMethodStatuses,
    fixedAssetDepreciationMethodTypes,
    fixedAssetDepreciationMethods,
    fixedAssetStatuses,
    fixedAssetTypes,
    contractTypes,
    chartOfAccounts,
];

fixedAssetReport.children = [
    acquisitions,
    depreciationSummary,
    depreciationDetail,
    disposals,
];

fixedAssetsModule.children = [
    fixedAssets,
    fixedAssetSchedules,
    fixedAssetDepreciationEntries,
    fixedAssetDisposalEntries,
    assetEntries,
    contracts,
    contractCoverage,
    fixedAssetSetup,
    fixedAssetReport,
];

//endregion

//region Accounting
const accountingModule = new NavLink({
    url: '/accounting',
    name: 'Accounting',
    about: 'Accounting',
    appImage: { FixedAssets_211 },
    imageName: 'FixedAssets_211',
    featureFlag: 'dashboard.nav.apps.accounting',
});

const accountingSetup = new NavLink({
    url: null,
    name: 'Setup',
    about: null,
    featureFlag: null,
});

const accountingFunctionCodes = new NavLink({
    url: `/accounting/functionCode/${FunctionGroupValues.accounting}`,
    name: 'Function Codes',
    about: 'View/Edit Accounting Function Codes',
    featureFlag: 'dashboard.nav.apps.accounting',
    permissionKey: PERMISSIONS.ACCOUNTING.FUNCTION_CODES.VIEW,
});

const currency = new NavLink({
    url: `/accounting/currency`,
    name: 'Currencies',
    about: 'View/Edit Accounting Currencies',
    featureFlag: 'dashboard.nav.apps.accounting',
    permissionKey: PERMISSIONS.ACCOUNTING.CURRENCIES.VIEW,
});

const exchangeRateTypes = new NavLink({
    url: `/accounting/currencyRateType`,
    name: 'Exchange Rate Types',
    about: 'View/Edit Accounting Exchange Rate Types',
    featureFlag: 'dashboard.nav.apps.accounting',
    permissionKey: PERMISSIONS.ACCOUNTING.CURRENCY_RATE_TYPES.VIEW,
});

const exchangeRates = new NavLink({
    url: `/accounting/currencyExchangeRate`,
    name: 'Exchange Rates',
    about: 'View/Edit Accounting Exchange Rates',
    featureFlag: 'dashboard.nav.apps.accounting',
    permissionKey: PERMISSIONS.ACCOUNTING.CURRENCY_EXCHANGE_RATES.VIEW,
});

const fiscalYearStatuses = new NavLink({
    url: `/accounting/setup/fiscalYearStatus`,
    name: 'Fiscal Year Statuses',
    about: 'View/Edit Accounting Fiscal Year Statuses',
    featureFlag: 'dashboard.nav.apps.accounting',
    permissionKey: PERMISSIONS.ACCOUNTING.FISCAL_YEAR_STATUSES.VIEW,
});

const fiscalYears = new NavLink({
    url: `/accounting/setup/fiscalYears`,
    name: 'Fiscal Years',
    about: 'View/Edit Accounting Fiscal Years',
    featureFlag: 'dashboard.nav.apps.accounting',
    permissionKey: PERMISSIONS.ACCOUNTING.FISCAL_YEARS.VIEW,
});

const periodStatuses = new NavLink({
    url: `/accounting/setup/periodStatus`,
    name: 'Period Statuses',
    about: 'View/Edit Accounting Period Statuses',
    featureFlag: 'dashboard.nav.apps.accounting',
    permissionKey: PERMISSIONS.ACCOUNTING.PERIOD_STATUSES.VIEW,
});

const periodQuarters = new NavLink({
    url: `/accounting/setup/periodQuarters`,
    name: 'Period Quarters',
    about: 'View/Edit Accounting Period Quarters',
    featureFlag: 'dashboard.nav.apps.accounting',
    permissionKey: PERMISSIONS.ACCOUNTING.PERIOD_QUARTER.VIEW,
});

accountingSetup.children = [
    accountingFunctionCodes,
    currency,
    exchangeRateTypes,
    exchangeRates,
    fiscalYearStatuses,
    fiscalYears,
    periodStatuses,
    periodQuarters,
];

accountingModule.children = [accountingSetup];

//endregion

//region Suppliers
const suppliersModule = new NavLink({
    url: '/suppliers',
    name: 'Suppliers',
    about: 'Suppliers',
    appImage: { FixedAssets_211 },
    imageName: 'FixedAssets_211',
    featureFlag: 'dashboard.nav.apps.suppliers',
});

const suppliers = new NavLink({
    url: '/suppliers/supplier',
    name: 'Suppliers',
    about: 'View/Edit Suppliers',
    featureFlag: 'dashboard.nav.apps.suppliers',
    permissionKey: PERMISSIONS.SUPPLIERS.SUPPLIER.VIEW,
});

const suppliersSetup = new NavLink({
    url: null,
    name: 'Setup',
    about: null,
    featureFlag: null,
});

const supplierTypes = new NavLink({
    url: '/suppliers/setup/type',
    name: 'Supplier Types',
    about: 'View/Edit Supplier Types',
    featureFlag: 'dashboard.nav.apps.suppliers',
    permissionKey: PERMISSIONS.SUPPLIERS.SETUP.SUPPLIER_TYPES.VIEW,
});

const supplierStatus = new NavLink({
    url: `/suppliers/setup/status`,
    name: 'Supplier Statuses',
    about: 'View/Edit Supplier Statuses',
    featureFlag: 'dashboard.nav.apps.suppliers',
    permissionKey: PERMISSIONS.SUPPLIERS.SETUP.SUPPLIER_STATUSES.VIEW,
});

const supplierAddressStatus = new NavLink({
    url: `/suppliers/setup/addressStatus`,
    name: 'Supplier Address Statuses',
    about: 'View/Edit Supplier Address Statuses',
    featureFlag: 'dashboard.nav.apps.suppliers',
    permissionKey: PERMISSIONS.SUPPLIERS.SETUP.SUPPLIER_ADDRESS_STATUSES.VIEW,
});

const supplierAddressType = new NavLink({
    url: `/suppliers/setup/addressType`,
    name: 'Supplier Address Types',
    about: 'View/Edit Supplier Address Types',
    featureFlag: 'dashboard.nav.apps.suppliers',
    permissionKey: PERMISSIONS.SUPPLIERS.SETUP.SUPPLIER_ADDRESS_TYPES.VIEW,
});

suppliersSetup.children = [
    supplierTypes,
    supplierStatus,
    supplierAddressStatus,
    supplierAddressType,
];

suppliersModule.children = [suppliers, suppliersSetup];

//endregion

//region Formulation
const formulation = new NavLink({
    url: '/formulation/RDFormula',
    name: 'Formulation',
    about: 'Formulation',
    featureFlag: 'dashboard.nav.apps.formulation',
    appImage: { Lab_Management },
    imageName: 'Lab_Management',
});

const formulationSetup = new NavLink({
    url: null,
    name: 'Setup',
    about: null,
    featureFlag: 'dashboard.nav.apps.formulation',
});

const formulationRegulatory = new NavLink({
    url: null,
    name: 'Regulatory',
    about: null,
    featureFlag: 'dashboard.nav.apps.formulation',
});

const formulationGhsHazardPhrases = new NavLink({
    url: '/formulation/ghsHazardPhrases',
    name: 'GHS Hazard Phrases',
    about: 'GHS Hazard Phrases',
    featureFlag: 'dashboard.nav.apps.formulation',
    permissionKey: PERMISSIONS.FORMULATION.GHS_HAZARD_PHRASE.VIEW,
});

const formulationProperties = new NavLink({
    url: '/formulation/formulaProperties',
    name: 'Formula Properties',
    about: 'View/Edit/Create formula properties',
    featureFlag: 'dashboard.nav.apps.formulation',
    permissionKey: PERMISSIONS.FORMULATION.FORMULA_PROPERTIES.VIEW,
});

const casMaster = new NavLink({
    url: '/formulation/casMaster',
    name: 'CAS Master',
    about: 'View/Edit/Create Cas Numbers',
    featureFlag: 'dashboard.nav.apps.formulation',
    permissionKey: PERMISSIONS.FORMULATION.CAS_MASTER.VIEW,
});

const itemMaster = new NavLink({
    url: '/formulation/itemMaster',
    name: 'Item Master',
    about: 'View/Edit/Create Items',
    featureFlag: 'dashboard.nav.apps.formulation',
    permissionKey: PERMISSIONS.FORMULATION.ITEM_MASTER.VIEW,
});

const rndFormula = new NavLink({
    url: '/formulation/rndFormula',
    name: 'R&D Formulas',
    about: 'View/Edit/Create Formulas',
    featureFlag: 'dashboard.nav.apps.formulation',
    permissionKey: PERMISSIONS.FORMULATION.RD_FORMULA.VIEW,
});

const formulationGhsPrecautionaryPhrases = new NavLink({
    url: '/formulation/ghsPrecautionaryPhrases',
    name: 'GHS Precautionary Phrases',
    about: 'GHS Precautionary Phrases',
    featureFlag: 'dashboard.nav.apps.formulation',
    permissionKey: PERMISSIONS.FORMULATION.GHS_PRECAUTIONARY_PHRASE.VIEW,
});

const formulationRegulations = new NavLink({
    url: '/formulation/regulations',
    name: 'Regulations',
    about: 'View/Edit/Create Regulations',
    featureFlag: 'dashboard.nav.apps.formulation',
    permissionKey: PERMISSIONS.FORMULATION.REGULATIONS.VIEW,
});

const formulationToxicTest = new NavLink({
    url: '/formulation/toxicTest',
    name: 'Toxic Test',
    about: 'View/Edit/Create Toxic Tests',
    featureFlag: 'dashboard.nav.apps.formulation',
    permissionKey: PERMISSIONS.FORMULATION.TOXIC_TEST.VIEW,
});

const formulationToxicSpecies = new NavLink({
    url: '/formulation/toxicSpecies',
    name: 'Toxic Species',
    about: 'View/Edit/Create Toxic Species',
    featureFlag: 'dashboard.nav.apps.formulation',
    permissionKey: PERMISSIONS.FORMULATION.TOXIC_SPECIES.VIEW,
});

const formulationExposureAgency = new NavLink({
    url: '/formulation/exposureAgency',
    name: 'Exposure Agencies',
    about: 'View/Edit/Create Exposure Agencies',
    featureFlag: 'dashboard.nav.apps.formulation',
    permissionKey: PERMISSIONS.FORMULATION.EXPOSURE_AGENCIES.VIEW,
});

const formulationSdsHeader = new NavLink({
    url: '/formulation/sdsSectionHeader',
    name: 'SDS Section Headers',
    about: 'View/Edit/Create SDS Section Headers',
    featureFlag: 'dashboard.nav.apps.formulation',
    permissionKey:
        PERMISSIONS.FORMULATION.SAFETY_DATA_SHEET_SECTION_HEADER.VIEW,
});

const formulationLocaleGroup = new NavLink({
    url: '/formulation/localeGroup',
    name: 'Locale Groups',
    about: 'View/Edit/Create Locale Groups',
    featureFlag: 'dashboard.nav.apps.formulation',
    permissionKey: PERMISSIONS.FORMULATION.LOCALE_GROUPS.VIEW,
});

const formulationLocale = new NavLink({
    url: '/formulation/locale',
    name: 'Locales',
    about: 'View/Edit/Create Locales',
    featureFlag: 'dashboard.nav.apps.formulation',
    permissionKey: PERMISSIONS.FORMULATION.LOCALE.VIEW,
});

const safetyDataSheet = new NavLink({
    url: '/formulation/sds',
    name: 'Safety Data Sheet',
    about: 'View/Edit/Create Safety Data Sheet Numbers',
    featureFlag: 'dashboard.nav.apps.formulation',
    permissionKey: PERMISSIONS.FORMULATION.SDS_HEADER.VIEW,
});

const formulationSequences = new NavLink({
    url: '/formulation/sequences',
    name: 'Sequences',
    about: 'View/Edit Safety Data Sheet Sequence Numbers',
    featureFlag: 'dashboard.nav.apps.formulation',
    permissionKey: PERMISSIONS.FORMULATION.SDS_HEADER.VIEW,
});

const formulationHazardClassificationMapping = new NavLink({
    url: '/formulation/hazardClassMapping',
    name: 'Hazard Class Mapping ',
    about: 'View/Edit Hazard Class Mapping',
    featureFlag: 'dashboard.nav.apps.formulation',
    permissionKey: PERMISSIONS.FORMULATION.HAZARD_CLASS_MAPPING.VIEW,
});

const formulationHazardRating = new NavLink({
    url: '/formulation/hazardRating',
    name: 'Hazard Rating',
    about: 'View/Edit Hazard Ratings',
    featureFlag: 'dashboard.nav.apps.formulation',
    permissionKey: PERMISSIONS.FORMULATION.HAZARD_RATINGS.VIEW,
});

const formulationHazardClassificationRules = new NavLink({
    url: '/formulation/hazardClassificationRules',
    name: 'Hazard Classification Rules',
    about: 'View/Edit Hazard Classification Rules',
    featureFlag: 'dashboard.nav.apps.formulation',
    permissionKey: PERMISSIONS.FORMULATION.HAZARD_CLASS_RULES.VIEW,
});

formulationRegulatory.children = [
    formulationGhsHazardPhrases,
    formulationGhsPrecautionaryPhrases,
    formulationRegulations,
    formulationProperties,
    formulationToxicSpecies,
    formulationToxicTest,
    formulationExposureAgency,
    formulationSdsHeader,
    formulationLocaleGroup,
    formulationLocale,
    formulationHazardClassificationMapping,
    formulationHazardRating,
    formulationHazardClassificationRules,
];

formulationSetup.children = [formulationRegulatory, formulationSequences];

formulation.children = [
    casMaster,
    itemMaster,
    rndFormula,
    safetyDataSheet,
    formulationSetup,
];
//endregion

//region Datacor CRM
const datacorCRM = new NavLink({
    url: 'https://www.echempax.com/demo/dsp/pub/Login.dsp;ECHEMPAXID=_UNvkIE4BoiMBQmLAZkICQ',
    name: 'CRM',
    about: 'Customer Relationship Manager',
    appImage: { CRM_demo_link },
    imageName: 'CRM_demo_link',
    featureFlag: 'dashboard.nav.apps.crmDemo',
});
//endregion

//region Business Intelligence
const businessIntelligence = new NavLink({
    url: '/businessIntelligence',
    name: 'Business Intelligence',
    about: 'Business Intelligence Links',
    appImage: { Business_intelligence_links },
    imageName: 'Business_intelligence_links',
    featureFlag: 'dashboard.nav.apps.businessIntelligence',
});
//endregion

//region Document Management
const documentManagement = new NavLink({
    url: '/documentManagement',
    name: 'Document Management',
    about: 'Securely send, store and retrieve your business workflow documents.',
    appImage: { Document_management },
    imageName: 'Document_management',
    featureFlag: 'dashboard.nav.apps.documentManagement',
});
//endregion

//region Analytics
const analytics = new NavLink({
    url: '/analytics',
    name: 'Analytics',
    about: 'Track real time information for your Datacor products.',
    appImage: { Analytics },
    imageName: 'Analytics',
    featureFlag: 'dashboard.nav.apps.analytics',
});
//endregion

//region Workflow
const workflow = new NavLink({
    url: '/workflow',
    name: 'Workflow',
    about: 'Workflow Designer and Engine.',
    appImage: { Workflow },
    imageName: 'Workflow',
    featureFlag: 'dashboard.nav.apps.workflow',
});
//endregion

//region Administration
const administrationModule = new NavLink({
    url: '/administration',
    name: 'Administration',
    about: 'Administration Options.',
    appImage: { Workflow },
    imageName: 'Workflow',
    featureFlag: 'dashboard.nav.apps.administration',
    permissionKey: PERMISSIONS.USER_PORTAL.VIEW,
    displayCard: false,
});

const subscribers = new NavLink({
    url: '/administration/organizations/subscribers',
    name: 'Subscribers',
    about: 'Administration Options.',
    featureFlag: 'dashboard.nav.apps.administration',
    datacorAdminOnly: true,
});

const businessEntities = new NavLink({
    url: '/administration/organizations/business-entities',
    name: 'Business Entities',
    about: 'Administration Options.',
    featureFlag: 'dashboard.nav.apps.administration',
    permissionKey: PERMISSIONS.ADMINISTRATION.BUSINESS_ENTITIES.VIEW,
});

const users = new NavLink({
    url: '/administration/organizations/users',
    name: 'Users',
    about: 'Administration Options.',
    featureFlag: 'dashboard.nav.apps.administration',
    permissionKey: PERMISSIONS.ADMINISTRATION.USERS.VIEW,
});

const groups = new NavLink({
    url: '/administration/organizations/groups',
    name: 'Groups',
    about: 'Administration Options.',
    featureFlag: 'dashboard.nav.apps.administration',
});

const roles = new NavLink({
    url: '/administration/organizations/roles',
    name: 'Roles',
    about: 'Administration Options.',
    featureFlag: 'dashboard.nav.apps.administration',
});

const permissions = new NavLink({
    url: '/administration/organizations/permissions',
    name: 'Permissions',
    about: 'Administration Options.',
    featureFlag: 'dashboard.nav.apps.administration',
});

const departmentCodes = new NavLink({
    url: '/administration/organizations/departmentCodes',
    name: 'Department Codes',
    about: 'Department Codes',
    featureFlag: 'dashboard.nav.apps.administration',
});

const buildings = new NavLink({
    url: '/administration/organizations/buildings',
    name: 'Buildings',
    about: 'Buildings',
    featureFlag: 'dashboard.nav.apps.administration',
});

const administrationSetup = new NavLink({
    url: null,
    name: 'Setup',
    about: null,
    featureFlag: null,
});

const languages = new NavLink({
    url: '/administration/setup/languages',
    name: 'Languages',
    about: 'Languages',
    featureFlag: 'dashboard.nav.apps.administration',
});

const revisions = new NavLink({
    url: '/administration/revisions',
    name: 'Revision History',
    about: 'Revision History',
    featureFlag: 'dashboard.nav.apps.administration',
});

const featureFlags = new NavLink({
    url: '/administration/feature-flags',
    name: 'Feature Flags',
    about: 'Feature Flags',
    datacorAdminOnly: true,
    featureFlag: 'dashboard.nav.apps.administration',
    permissionKey: PERMISSIONS.SETTINGS.FEATURE_FLAGS.VIEW,
});

administrationSetup.children = [languages];

administrationModule.children = [
    subscribers,
    businessEntities,
    buildings,
    users,
    groups,
    roles,
    departmentCodes,
    permissions,
    administrationSetup,
    revisions,
    featureFlags,
];
//endregion

//region Settings
const setupModule = new NavLink({
    url: null,
    name: 'Setup',
    about: 'Setup',
    featureFlag: 'dashboard.nav.apps.setup',
    appImage: { SetupApp },
    imageName: 'SetupApp',
});

const setupGeneral = new NavLink({
    url: null,
    name: 'General',
    about: null,
    featureFlag: 'dashboard.nav.apps.setup',
});

const setupUnitOfMeasure = new NavLink({
    url: '/setup/unitOfMeasure',
    name: 'Units of Measure',
    about: 'Units of Measure',
    featureFlag: 'dashboard.nav.apps.setup',
    permissionKey: PERMISSIONS.SETUP.UNITS_OF_MEASURE.VIEW,
});

const setupUnitOfMeasureConversion = new NavLink({
    url: '/setup/unitOfMeasureConversion',
    name: 'Units of Measure Conversion',
    about: 'Units of Measure Conversion',
    featureFlag: 'dashboard.nav.apps.setup',
    permissionKey: PERMISSIONS.SETUP.UNIT_OF_MEASURE_CONVERSION.VIEW,
});

const setupProperShippingName = new NavLink({
    url: '/setup/properShippingName',
    name: 'DOT Proper Shipping Name',
    about: 'DOT Proper Shipping Name',
    featureFlag: 'dashboard.nav.apps.setup',
    permissionKey: PERMISSIONS.SETUP.PROPER_SHIPPING_NAME.VIEW,
});

const setupBoilerPlateNote = new NavLink({
    url: '/setup/boilerPlateNote',
    name: 'Boiler Plate Notes',
    about: 'Boiler Plate Notes',
    featureFlag: 'dashboard.nav.apps.setup',
    permissionKey: PERMISSIONS.SETUP.BOILER_PLATE_NOTE.VIEW,
});

const setupAttributes = new NavLink({
    url: '/setup/attributes',
    name: 'Attributes',
    about: 'Attributes',
    featureFlag: 'dashboard.nav.apps.setup',
    permissionKey: PERMISSIONS.SETUP.ATTRIBUTES.VIEW,
});

/* TODO: the navlinks for document designer are set to be datacorAdmin only for now
  assuming at some point this will no longer be the case and we will
  need to remove this */
const setupDocuments = new NavLink({
    url: null,
    name: 'Documents',
    about: null,
    featureFlag: 'dashboard.nav.apps.documentDesigner',
    permissionKey: 'Datacor.developer',
    datacorAdminOnly: true,
});

const setupDocDefinitions = new NavLink({
    url: '/document-designer/doc-definitions',
    name: 'DocDefinitions',
    about: 'DocDefinitions',
    featureFlag: 'dashboard.nav.apps.documentDesigner',
    permissionKey: 'Datacor.developer',
    datacorAdminOnly: true,
});

const setupDocumentTemplate = new NavLink({
    url: '/setup/document-designer/templates',
    name: 'Document Templates',
    about: 'Document Designer Templates',
    featureFlag: 'dashboard.nav.apps.documentDesigner',
    permissionKey: 'Datacor.developer',
    datacorAdminOnly: true,
});

setupDocuments.children = [setupDocumentTemplate, setupDocDefinitions];

setupGeneral.children = [
    setupUnitOfMeasure,
    setupUnitOfMeasureConversion,
    setupBoilerPlateNote,
    setupProperShippingName,
    setupAttributes,
    setupDocuments,
];

setupModule.children = [setupGeneral];
//endregion

const devArea = new NavLink({
    url: '/dev-area',
    name: 'DEV AREA',
    about: 'area for development',
    featureFlag: 'dashboard.nav.apps.administration',
    appImage: { Implementation_Icons_Cleanup_Documentation },
    imageName: 'Implementation_Icons_Cleanup_Documentation',
    permissionKey: 'Datacor.developer',
    datacorAdminOnly: true,
});

//we should change these to pull from a DB, but for now this works.
export const AVAILABLE_NAV_LINKS_AND_INCLUSION_CRITERIA: NavLink[] = [
    myApps,
    scheduler,
    appStreaming,
    pricingAnalysis,
    userPortal,
    apiDocumentation,
    apAutomation,
    fixedAssetsModule,
    accountingModule,
    suppliersModule,
    formulation,
    datacorCRM,
    businessIntelligence,
    documentManagement,
    analytics,
    workflow,
    administrationModule,
    setupModule,
    devArea,
];
