import { DialogProps } from '@mui/material/Dialog/Dialog';
import { FA_DepreciationSchedule } from '../../../types/FixedAsset.types';
import useFixedAssetSchedulesActionsModal from './hooks/useFixedAssetSchedulesActionsModal';
import {
    Box,
    Dialog,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    IconButton,
    Stack,
    Switch,
    Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { LoadingButton } from '@mui/lab';
import React from 'react';

type FixedAssetSchedulesActionsProps = Omit<DialogProps, 'onClose'> & {
    selectedSchedules: FA_DepreciationSchedule[];
    onSchedulesUpdated: (schedules: FA_DepreciationSchedule[]) => void;
    onClose: () => void;
};

const FixedAssetSchedulesActionsModal = ({
    selectedSchedules,
    onSchedulesUpdated,
    onClose,
    ...props
}: FixedAssetSchedulesActionsProps) => {
    const {
        shouldLockSchedules,
        setShouldLockSchedules,
        canToggleLockUnlock,
        shouldCreateEntries,
        setShouldCreateEntries,
        canCreateEntries,
        isSavingSchedules,
        handleUpdateSchedules,
        canCreateSchedules,
        canLockSchedules,
    } = useFixedAssetSchedulesActionsModal(
        selectedSchedules,
        onSchedulesUpdated,
        onClose
    );

    return (
        <Dialog {...props} fullWidth maxWidth={'sm'}>
            <DialogTitle>
                <Box display="flex" alignItems="center">
                    <Box flexGrow={1}>Asset Schedule Actions</Box>
                    <Box>
                        <IconButton onClick={onClose}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </Box>
            </DialogTitle>
            <DialogContent>
                <Stack spacing={2} alignItems={'center'}>
                    {canLockSchedules ? (
                        <Stack direction="row" spacing={1} alignItems="center">
                            <Typography>Unlock Schedules</Typography>
                            <Switch
                                checked={shouldLockSchedules}
                                disabled={!canToggleLockUnlock}
                                onChange={(event) =>
                                    setShouldLockSchedules(event.target.checked)
                                }
                            />
                            <Typography>Lock Schedules</Typography>
                        </Stack>
                    ) : null}

                    {canCreateSchedules ? (
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={shouldCreateEntries}
                                    disabled={!canCreateEntries}
                                    onChange={(event) =>
                                        setShouldCreateEntries(
                                            event.target.checked
                                        )
                                    }
                                />
                            }
                            label="Create Depreciation Entries"
                        />
                    ) : null}

                    <LoadingButton
                        fullWidth
                        variant="contained"
                        type="submit"
                        loading={isSavingSchedules}
                        onClick={handleUpdateSchedules}>
                        Process Changes
                    </LoadingButton>
                </Stack>
            </DialogContent>
        </Dialog>
    );
};

export default FixedAssetSchedulesActionsModal;
