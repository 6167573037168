import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import AttributeDefinitionsForm from './AttributeDefinitionsForm';
import AttributeTemplatesForm from './AttributeTemplatesForm';

//since we don't have exact routes for our attribute forms
//(they are being determined dynamically based on the tab selected)
// we need this middle layer to determine which form to load
//based on which tab we're in
const AttributeFormHandler = () => {
    const params = useParams();
    const navigate = useNavigate();

    switch (params?.tab) {
        case 'definitions':
            return <AttributeDefinitionsForm />;
        case 'templates':
            return <AttributeTemplatesForm />;
        default:
            //if we don't have a tab selected, we should just redirect to the grid
            navigate('/setup/attributes');
    }
};

export default AttributeFormHandler;
