import React from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    Box,
    IconButton,
    Grid,
    FormControlLabel,
    Checkbox,
    Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import useFixedAssetCreateDepreciationEntriesModal from './hooks/useFixedAssetCreateDepreciationEntriesModal';
import { LoadingButton } from '@mui/lab';
import { FA_DepreciationEntry } from '../../../../types/FixedAsset.types';

export interface CreateDepreciationEntriesProps {
    open: boolean;
    ids: bigint[];
    isUpdate?: boolean;
    onClose: () => void;
    onEntriesCreated: (entries: FA_DepreciationEntry[]) => void;
}

const FixedAssetCreateDepreciationEntriesModal = (
    props: CreateDepreciationEntriesProps
) => {
    const {
        lockEntries,
        setLockEntries,
        isLoading,
        handleGenerateEntries,
        canLockDepreciationEntries,
    } = useFixedAssetCreateDepreciationEntriesModal(props);

    return (
        <Dialog open={props.open} fullWidth maxWidth={'md'}>
            <DialogTitle>
                <Box display="flex" alignItems="center">
                    <Box flexGrow={1}>Generate Depreciation Entries</Box>
                    <Box>
                        <IconButton onClick={props.onClose}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </Box>
            </DialogTitle>
            <DialogContent>
                {canLockDepreciationEntries ? (
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography>
                                Do you wish to lock all created entries?
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControlLabel
                                label="Lock Entries"
                                control={
                                    <Checkbox
                                        id="taxSchedule"
                                        checked={lockEntries}
                                        onChange={(event: any) =>
                                            setLockEntries(event.target.checked)
                                        }
                                    />
                                }
                            />
                        </Grid>
                    </Grid>
                ) : null}
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <LoadingButton
                            fullWidth
                            variant="contained"
                            type="submit"
                            loading={isLoading()}
                            onClick={handleGenerateEntries}>
                            Generate Depreciation Entries
                        </LoadingButton>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
};

export default FixedAssetCreateDepreciationEntriesModal;
