import React, { useEffect, useState } from 'react';
import {
    ItemChemicalComposition,
    ItemMaster,
    ItemProperShippingName,
    ItemProperty,
    ItemUnitOfMeasure,
} from '../../../../../types/formulation';
import { RoutingValues } from '../../../../../types/Shared.types';
import { RowStatus } from '../../../../../components/grids/hooks/useBaseGridEditable';
import { v4 as uuidv4 } from 'uuid';
import { CopyFormProps } from '../../../../form/helpers/CopyFormProps';

const useCopyItemMasterForm = (props: CopyFormProps<ItemMaster>) => {
    const { fields, dataFromQuery, setValues, formType } = props;

    const [attrFieldsDisabled, setAttrFieldsDisabled] = useState(true);
    const [formItemDefs, setFormItemDefs] = useState(null);
    const [itemProperties, setItemProperties] = useState([] as ItemProperty[]);
    const [chemicalComposition, setChemicalComposition] = useState(
        [] as ItemChemicalComposition[]
    );

    const createItemPropertiesCopy = (today: Date): ItemProperty[] => {
        const itemPropertiesCopy: ItemProperty[] = itemProperties?.map(
            (itemProps: ItemProperty, index: number) => {
                return {
                    propertyId: itemProps.propertyId,
                    propertyValue: itemProps.propertyValue,
                    propertyUnitId: itemProps.propertyUnitId,
                    propertyCode: itemProps.propertyCode,
                    description: itemProps.description,
                    unitCode: itemProps.unitCode,
                    rowStatus: RowStatus.ADDED,
                    rowId: index + 1,
                    createdAt: today,
                    updatedAt: today,
                };
            }
        );
        setItemProperties(itemPropertiesCopy);
        return itemPropertiesCopy;
    };

    const createItemChemicalCompositionCopy = (
        today: Date
    ): ItemChemicalComposition[] => {
        const itemChemicalCopy: ItemChemicalComposition[] =
            chemicalComposition?.map(
                (chemicalRecord: ItemChemicalComposition, index: number) => {
                    return {
                        casId: chemicalRecord.casId,
                        lowerValue: chemicalRecord.lowerValue,
                        upperValue: chemicalRecord.upperValue,
                        casIdentifier: chemicalRecord.casIdentifier,
                        casNumber: chemicalRecord.casNumber,
                        chemicalName: chemicalRecord.chemicalName,
                        classification: chemicalRecord.classification,
                        ecIdentifier: chemicalRecord.ecIdentifier,
                        molecularFormula: chemicalRecord.molecularFormula,
                        reach: chemicalRecord.reach,
                        rowStatus: RowStatus.ADDED,
                        rowId: index + 1,
                        createdAt: today,
                        updatedAt: today,
                    };
                }
            );
        setChemicalComposition(itemChemicalCopy);
        return itemChemicalCopy;
    };

    const createItemAttributesCopy = (today: Date): any => {
        const itemAttributesCopy = fields.itemAttributes?.map(
            (itemAttr: any) => {
                return {
                    attrDefinitionId: itemAttr.attrDefinitionId,
                    data: itemAttr.data,
                    code: fields?.itemProfile[0].attrTemplate.attrDefinitions.find(
                        (def: any) => def.id === itemAttr.attrDefinitionId
                    )?.code,
                    createdAt: today,
                    updatedAt: today,
                };
            }
        );
        setAttrFieldsDisabled(false);
        return itemAttributesCopy;
    };

    const createItemProfileCopy = (today: Date): any => {
        const itemProfileCopy = fields.itemProfile?.map(
            (itemProfileRecord: any) => {
                return {
                    attrTemplateId: itemProfileRecord.attrTemplateId,
                    attrTemplate: itemProfileRecord.attrTemplate,
                    createdAt: today,
                    updatedAt: today,
                };
            }
        );
        setFormItemDefs(
            itemProfileCopy
                ? itemProfileCopy[0]?.attrTemplate?.attrDefinitions
                : []
        );
        return itemProfileCopy;
    };

    const createItemUnitOfMeasureCopy = (today: Date): ItemUnitOfMeasure => {
        const itemUOM = fields?.itemUnitOfMeasure;
        return {
            stockUnitId: itemUOM?.stockUnitId,
            weightUnitId: itemUOM?.weightUnitId,
            volumeUnitId: itemUOM?.volumeUnitId,
            specificGravity: itemUOM?.specificGravity,
            density: itemUOM?.density,
            stockWeight: itemUOM?.stockWeight,
            stockVolume: itemUOM?.stockVolume,
            createdAt: today,
            updatedAt: today,
        };
    };

    const createItemProperShippingNameCopy = (
        today: Date
    ): ItemProperShippingName => {
        const itemShippingName = fields?.itemProperShippingName;
        return {
            properShippingNameId: itemShippingName?.properShippingNameId,
            rqRequirement: itemShippingName?.rqRequirement,
            rqUom: itemShippingName?.rqUom,
            marinePollutant: itemShippingName?.marinePollutant,
            freightClass: itemShippingName?.freightClass,
            nmfc: itemShippingName?.nmfc,
            createdAt: today,
            updatedAt: today,
        };
    };

    useEffect(() => {
        if (formType === RoutingValues.copy) {
            const today: Date = new Date(new Date().getTime());

            const itemPropertiesCopy: ItemProperty[] =
                createItemPropertiesCopy(today);

            const itemChemicalCopy: ItemProperty[] =
                createItemChemicalCompositionCopy(today);

            const itemAttributesCopy = createItemAttributesCopy(today);
            const itemProfileCopy = createItemProfileCopy(today);
            const itemProperShippingName =
                createItemProperShippingNameCopy(today);
            const itemUOM = createItemUnitOfMeasureCopy(today);

            setValues({
                ...dataFromQuery,
                id: null,
                itemId: null,
                itemProperties: itemPropertiesCopy || ([] as ItemProperty[]),
                chemicalComposition:
                    itemChemicalCopy || ([] as ItemChemicalComposition[]),
                itemProfile: itemProfileCopy || [],
                itemAttributes: itemAttributesCopy || [],
                attrTemplateId: fields.attrTemplateId,
                itemProperShippingName:
                    itemProperShippingName || (null as ItemProperShippingName),
                itemUnitOfMeasure: itemUOM || (null as ItemUnitOfMeasure),
                createdAt: today,
                updatedAt: today,
                uuid: uuidv4(),
            });
        }
    }, [formType, dataFromQuery]);

    return {
        copyMethods: {
            itemProperties,
            setItemProperties,
            chemicalComposition,
            setChemicalComposition,
            attrFieldsDisabled,
            setAttrFieldsDisabled,
            formItemDefs,
            setFormItemDefs,
        },
    };
};

export default useCopyItemMasterForm;
