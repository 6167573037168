import { IconButton } from '@mui/material';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import React from 'react';

const DeleteColumn = (baseGridParams: any) => {
    const handleDeleted = () => {
        baseGridParams.colDef.onDelete(baseGridParams.data);
    };

    return (
        <IconButton size="small" color="error" onClick={handleDeleted}>
            <DeleteForeverIcon />
        </IconButton>
    );
};

export default DeleteColumn;
