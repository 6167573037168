import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import { PermissionsUtil } from '../../../../utils/permissions/permissionsUtil';
import { PERMISSIONS } from '../../../../constants/permissions/Permissions.constants';
import {
    useGetTermSetQuery,
    useListCurrencyQuery,
    useUpdateListCurrencyMutation,
} from '../../../../services/i18n/i18n.service';
import React, { useContext, useEffect, useState } from 'react';
import { ACCOUNTING_DEFS } from '../../../../constants/i18n/translations/termSetDefinitions/accounting';
import { skipToken } from '@reduxjs/toolkit/query';
import SettingsContext from '../../../../contexts/settings.context';
import { useNavigate } from 'react-router-dom';
import { Currency } from '../../../../types/platform/i18n/Currency.type';
import { RoutingValues } from '../../../../types/Shared.types';
import _ from 'lodash';
import { getMaxSortOrderValue } from '../../../../store/accounting';
import { ColDef } from 'ag-grid-community';
import DefaultColumnTypes from '../../../../components/grids/columns/Column.constants';
import CustomHeader from '../../../../components/grids/CustomHeader';
import { CurrencyGridDefs } from '../../../../constants/i18n/translations/termDefinitions/accounting';
import { isNilOrEmpty } from '../../../../utils/objectUtils';

export const useCurrencyGrid = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector((state: RootState) => state.user);
    const { settings } = useContext(SettingsContext);
    const { data: termSet } = useGetTermSetQuery(
        settings?.userSettings
            ? {
                  languageId: settings?.userSettings?.languageId,
                  code: ACCOUNTING_DEFS.CURRENCY_GRID,
              }
            : skipToken
    );

    const canViewCurrencies = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.ACCOUNTING.CURRENCIES.VIEW
    );

    const canCreateCurrencies = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.ACCOUNTING.CURRENCIES.CREATE
    );

    const canUpdateCurrencies = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.ACCOUNTING.CURRENCIES.EDIT
    );

    const { data: currencyList, isLoading: isLoadingCurrencies } =
        useListCurrencyQuery();

    const [updateCurrencies] = useUpdateListCurrencyMutation();

    const [colDefs, setColDefs] = useState([]);

    const handleGridEdits = (editedRows: Currency[]) => {
        updateCurrencies(editedRows);
    };

    useEffect(() => {
        if (!isNilOrEmpty(termSet)) {
            const currencyColumnDefs: ColDef[] = [
                {
                    field: 'id',
                    hide: true,
                    ...DefaultColumnTypes.NumberOnly,
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={CurrencyGridDefs.Id}
                            />
                        );
                    },
                },
                {
                    field: 'businessEntityId',
                    hide: true,
                    ...DefaultColumnTypes.NumberOnly,
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={CurrencyGridDefs.BusinessEntity}
                            />
                        );
                    },
                },
                {
                    field: 'sortOrder',
                    sort: 'asc',
                    ...DefaultColumnTypes.NumberOnly,
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={CurrencyGridDefs.SortOrder}
                            />
                        );
                    },
                },
                {
                    field: 'currencyCode',
                    ...DefaultColumnTypes.ShortText,
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={CurrencyGridDefs.CurrencyCode}
                            />
                        );
                    },
                },
                {
                    field: 'currentCurrencyCode.number',
                    ...DefaultColumnTypes.NumberOnly,
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={CurrencyGridDefs.Number}
                            />
                        );
                    },
                },
                {
                    field: 'fullName',
                    ...DefaultColumnTypes.ShortText,
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={CurrencyGridDefs.FullName}
                            />
                        );
                    },
                },
                {
                    field: 'shortName',
                    ...DefaultColumnTypes.ShortText,
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={CurrencyGridDefs.ShortName}
                            />
                        );
                    },
                },
                {
                    field: 'symbol',
                    ...DefaultColumnTypes.ShortText,
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={CurrencyGridDefs.Symbol}
                            />
                        );
                    },
                },
                {
                    field: 'decimalDisplay',
                    ...DefaultColumnTypes.NumberOnly,
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={CurrencyGridDefs.DecimalDisplay}
                            />
                        );
                    },
                },
                {
                    field: 'createdAt',
                    hide: true,
                    ...DefaultColumnTypes.DateOnly,
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={CurrencyGridDefs.CreatedAt}
                            />
                        );
                    },
                },
                {
                    field: 'createdByUser.email',
                    hide: true,
                    ...DefaultColumnTypes.ShortText,
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={CurrencyGridDefs.CreatedBy}
                            />
                        );
                    },
                },
                {
                    field: 'updatedAt',
                    hide: true,
                    ...DefaultColumnTypes.DateOnly,
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={CurrencyGridDefs.UpdatedAt}
                            />
                        );
                    },
                },
                {
                    field: 'updatedByUser.email',
                    hide: true,
                    ...DefaultColumnTypes.ShortText,
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={CurrencyGridDefs.UpdatedBy}
                            />
                        );
                    },
                },
            ];
            setColDefs(currencyColumnDefs);
        }
    }, [termSet]);

    useEffect(() => {
        let maxValue = 1;
        if (currencyList && currencyList.length > 0) {
            const data = _.maxBy(
                currencyList,
                (assetType: Currency) => assetType.sortOrder
            );
            maxValue = data.sortOrder + 1;
        }
        dispatch(
            getMaxSortOrderValue({ isLoading: false, maxValue: maxValue })
        );
    }, currencyList);

    const redirectToForm = (row: any) => {
        if (row.id) {
            navigate(`/accounting/currency/${row.id}`);
        } else {
            navigate(`/accounting/currency/${RoutingValues.newId}`);
        }
    };

    return {
        canViewCurrencies,
        canCreateCurrencies,
        canUpdateCurrencies,
        termSet,
        currencyList,
        isLoadingCurrencies,
        redirectToForm,
        handleGridEdits,
        colDefs,
    };
};

export default useCurrencyGrid;
