import {
    Building,
    Contract,
    GL_Account,
    RecoveryUnit,
} from './Accounting.types';

export interface FA_Asset {
    id?: bigint;
    number: number;
    code: string;
    name: string;
    description: string;
    statusId: bigint;
    acquireDate: Date;
    inServiceDate: Date;
    disposalDate: Date;
    ledgerTypeId: bigint;
    taxTypeId: bigint;
    buildingId: bigint;
    manufacturerSerialNumber: string;
    purchaseOrderNumber: string;
    supplierInvoiceNumber: string;
    capitalProjectNumber: string;
    building?: Building;
    assetStatus?: FA_AssetStatus;
    ledgerAssetType?: FA_AssetType;
    taxAssetType?: FA_AssetType;
    costs?: FA_AssetCost[];
    contracts?: FA_AssetContract[];
    contractList?: Contract[];
    ledgerDepreciationSchedule?: FA_DepreciationSchedule[];
    taxDepreciationSchedule?: FA_DepreciationSchedule[];
    depreciationEntries?: FA_DepreciationEntry[];
    disposalEntries?: FA_AssetDisposalEntry[];
    assetEntries?: FA_AssetEntry[];
    totalCost?: number;
    isValid?: boolean;
    errorMessage?: string;
    remainingValue?: number;
    depreciationEntriesTotals?: any;
    disposalEntriesTotals?: any;
    ledgerTotalPercentage?: number;
    taxTotalPercentage?: number;
}

export interface FA_AssetCost {
    id?: bigint;
    fixedAssetId: bigint;
    costCodeId: bigint;
    amount: number;
    actualAmount: number;
    costCode?: FA_AssetCostCode;
}

export interface FA_AssetContract {
    id?: bigint;
    fixedAssetId: bigint;
    contractId: bigint;
    contract?: Contract;
    fixedAsset?: FA_Asset;
}

export interface FA_AssetStatus {
    id?: bigint;
    sortOrder: number;
    code: string;
    description: string;
    isInService: boolean;
    isDisposal: boolean;
}

export interface FA_AssetCostCode {
    id?: bigint;
    code: string;
    sortOrder: number;
    isAdd: boolean;
}

export interface FA_AssetType {
    id?: bigint;
    sortOrder: number;
    code: string;
    description: string;
    recoveryTerm: number;
    recoveryUnitId: bigint;
    recoveryUnit?: RecoveryUnit;
    depreciationMethodId: bigint;
    depreciationExpenseAccountId: null;
    accumulatedDeprecationAccountId: null;
    assetAccountId: null;
    assetControlAccountId: null;
    disposalGainAccountId: null;
    disposalLossAccountId: null;
    disposalSettlementAccountId: null;
    depreciationMethod?: FA_DepreciationMethod;
}

export interface FA_DepreciationSchedule {
    id?: bigint;
    fixedAssetId: bigint;
    assetTypeId: bigint;
    interval: number;
    intervalDate: Date;
    assetCost: number;
    accumulatedToDate: number;
    percentage: number;
    amount: number;
    remainingValue?: number;
    locked: boolean;
    lockedUserId: bigint;
    lockedUserName: string;
    lockedDate: Date;
    fixedAsset?: FA_Asset;
    assetType?: FA_AssetType;
    accumulatedDepreciation: number;
}

export interface FA_DepreciationEntry {
    id?: bigint;
    fixedAssetId: bigint;
    assetTypeId: bigint;
    interval: number;
    intervalDate: Date;
    accountId: bigint;
    debit: number;
    credit: number;
    locked: boolean;
    lockedUserId: bigint;
    lockedUserName: string;
    lockedDate: Date;
    fixedAsset?: FA_Asset;
    assetType?: FA_AssetType;
    account?: GL_Account;
}

export interface FA_AssetDisposalEntry {
    id?: bigint;
    fixedAssetId: bigint;
    disposalDate: Date;
    accountId: bigint;
    debit: number;
    credit: number;
    locked: boolean;
    lockedUserId: bigint;
    lockedUserName: string;
    lockedDate: Date;
    account?: GL_Account;
    fixedAsset?: FA_Asset;
}

export interface FA_AssetEntry {
    id?: bigint;
    fixedAssetId: bigint;
    entryDate: Date;
    accountId: bigint;
    debit: number;
    credit: number;
    locked: boolean;
    lockedUserId: bigint;
    lockedUserName: string;
    lockedDate: Date;
    account?: GL_Account;
    fixedAsset?: FA_Asset;
}

export interface FA_DepreciationMethodStatus {
    id?: bigint;
    code: string;
    description: string;
    sortOrder: number;
    isActive: boolean;
}

export interface FA_DepreciationMethodType {
    id?: bigint;
    sortOrder: number;
    code: string;
    description: string;
    postsJournalEntries: boolean;
    predefinedSchedule: boolean;
}

export interface FA_DepreciationMethod {
    id?: bigint;
    sortOrder: number;
    code: string;
    name: string;
    description: string;
    depreciationMethodStatusId: bigint;
    depreciationMethodTypeId: bigint;
    depreciationFormula: '';
    depreciationMethodStatus?: FA_DepreciationMethodStatus;
    depreciationMethodType?: FA_DepreciationMethodType;
    costs?: FA_DepreciationMethodCost[];
    schedule?: FA_DepreciationMethodSchedule[];
}

export interface FA_DepreciationMethodCost {
    id?: bigint;
    depreciationMethodId?: bigint;
    costCodeId: bigint;
    costCode?: FA_AssetCostCode;
}

export interface FA_DepreciationMethodSchedule {
    id?: bigint;
    depreciationMethodId?: bigint;
    interval: number;
    percentage: number;
}

export enum DepreciationBasis {
    None = 0,
    Formula = 1,
    Schedule = 2,
}

export interface FixedAssetCreateScheduleArgs {
    ids: bigint[];
    book: boolean;
    tax: boolean;
    startDate: Date;
    lockIntervals: boolean;
}

export interface FixedAssetScheduleListParams {
    methodTypeIds?: bigint[];
    assetTypeIds?: bigint[];
    startDate?: Date;
    endDate?: Date;
}

export interface FixedAssetDepreciationEntryListParams {
    methodTypeIds?: bigint[];
    assetTypeIds?: bigint[];
    startDate?: Date;
    endDate?: Date;
}

export interface FixedAssetContractParams {
    contractTypeIds?: bigint[];
    startDate?: Date;
    endDate?: Date;
    remainingValueDate?: Date;
}

export interface FixedAssetDisposalEntryListParams {
    assetTypeIds?: bigint[];
    startDate?: Date;
    endDate?: Date;
}

export interface AssetEntryListParams {
    assetTypeIds?: bigint[];
    startDate?: Date;
    endDate?: Date;
}

export interface AcquisitionsParams {
    acquireBeginDate?: Date;
    acquireEndDate?: Date;
}

export interface DisposalsParams {
    disposalBeginDate?: Date;
    disposalEndDate?: Date;
}

export interface DepreciationSummaryReportParams {
    assetTypeIds?: bigint[];
    startDate?: Date;
    endDate?: Date;
}

export interface DepreciationDetailParams {
    assetTypeId?: bigint;
    startDate?: Date;
    endDate?: Date;
}
