import React from 'react';
import { GridOptions } from '../../../../components/grids/Grid.constants';
import { ColDef } from 'ag-grid-community';
import DefaultColumnTypes from '../../../../components/grids/columns/Column.constants';
import BaseFormGrid from '../../../../components/grids/BaseFormGrid';
import { GhsPrecautionaryPhrase } from '../../../../types/formulation';
import useGhsPrecautionaryPhraseGrid, {
    PrecautionaryGridProps,
} from './hooks/useGhsPrecautionaryPhraseGrid';
import CustomHeader from '../../../../components/grids/CustomHeader';
import { GhsPrecautionaryPhrasesGridDefs } from '../../../../constants/i18n/translations/termDefinitions/formulation';
import TranslatableText from '../../../../components/i18n/TranslatableText';

const GhsPrecautionaryPhraseGrid = ({
    defaultLanguageId,
    currentPhraseId,
    handleSetPhraseList,
    handlePromptFormDirty,
}: PrecautionaryGridProps) => {
    const {
        precautionaryPhraseList,
        isLoadingData,
        handleSelectedRowChange,
        handleAddNewPhrase,
        onGridReady,
        onFirstDataRendered,
        handleRowDataChanged,
        onSortChanged,
        onFilterChanged,
        canViewGhsPrecautionaryPhrase,
        canCreateGhsPrecautionaryPhrase,
        termSet,
    } = useGhsPrecautionaryPhraseGrid({
        handleSetPhraseList,
        defaultLanguageId,
        currentPhraseId,
        handlePromptFormDirty,
    });

    const methodsColumnDefs: ColDef[] = [
        {
            field: 'phraseId',
            headerComponentFramework: (props: any) => {
                return (
                    <CustomHeader
                        {...props}
                        termSet={termSet}
                        termKey={GhsPrecautionaryPhrasesGridDefs.Phrase_ID}
                    />
                );
            },
            filter: 'agTextColumnFilter',
            ...DefaultColumnTypes.MediumText,
        },
        {
            field: 'phraseText',
            headerComponentFramework: (props: any) => {
                return (
                    <CustomHeader
                        {...props}
                        termSet={termSet}
                        termKey={GhsPrecautionaryPhrasesGridDefs.Text}
                    />
                );
            },
            filter: 'agTextColumnFilter',
            ...DefaultColumnTypes.LongText,
        },
    ];

    const colDefOptions = {
        ...GridOptions.sortFilterAndWrapColumns,
        floatingFilter: true,
    };

    return (
        <BaseFormGrid
            displayGrid={canViewGhsPrecautionaryPhrase}
            title={
                (
                    <TranslatableText
                        termSet={termSet}
                        termKey={GhsPrecautionaryPhrasesGridDefs.Phrase_Library}
                    />
                ) as unknown as string
            }
            isLoading={isLoadingData}
            rowData={precautionaryPhraseList}
            columnDefs={methodsColumnDefs}
            dataKey={'phraseId'}
            gridStyle={{
                maxHeight: '93%',
            }}
            gridClass="full-size-item"
            displayToolbar={true}
            displayCreateNewButton={canCreateGhsPrecautionaryPhrase}
            displayExportDataButton={false}
            displayResetStateButton={false}
            onCreateNew={handleAddNewPhrase}
            onRowClicked={({ data }) =>
                handleSelectedRowChange(data as GhsPrecautionaryPhrase)
            }
            onFirstDataRendered={onFirstDataRendered}
            onRowDataChanged={handleRowDataChanged}
            defaultColDef={colDefOptions}
            sizeColumnsToFit={true}
            onGridReady={onGridReady}
            onFilterChanged={onFilterChanged}
            onSortChanged={onSortChanged}
            rowSelection={'single'}></BaseFormGrid>
    );
};

export default GhsPrecautionaryPhraseGrid;
