import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import { PermissionsUtil } from '../../../../utils/permissions/permissionsUtil';
import { PERMISSIONS } from '../../../../constants/permissions/Permissions.constants';
import { useContext, useEffect, useState } from 'react';
import SettingsContext from '../../../../contexts/settings.context';
import {
    useCreateCurrencyExchangeRateMutation,
    useGetCurrencyExchangeRateQuery,
    useGetTermSetQuery,
    useListCurrencyCodesQuery,
    useListCurrencyRateTypesForDropdownQuery,
    useUpdateCurrencyExchangeRateMutation,
} from '../../../../services/i18n/i18n.service';
import { ACCOUNTING_DEFS } from '../../../../constants/i18n/translations/termSetDefinitions/accounting';
import { skipToken } from '@reduxjs/toolkit/query';
import { CurrencyExchangeRate } from '../../../../types/platform/i18n/Currency.type';
import useBaseForm from '../../../form/hooks/useBaseForm';
import { RoutingValues, SelectionOption } from '../../../../types/Shared.types';
import { isNilOrEmpty } from '../../../../utils/objectUtils';
import NavFormContext from '../../../../contexts/navForm.context';

export const useCurrencyExchangeRateForm = (id: string) => {
    const { accountingNav } = useContext(NavFormContext);
    const user = useSelector((state: RootState) => state.user);
    const [filteredRateTypes, setFilteredRateTypes] = useState<
        SelectionOption[]
    >([]);

    const canCreateExchangeRate = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.ACCOUNTING.CURRENCY_EXCHANGE_RATES.CREATE
    );

    const canUpdateExchangeRate = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.ACCOUNTING.CURRENCY_EXCHANGE_RATES.EDIT
    );

    const { settings } = useContext(SettingsContext);
    const { data: termSet, isLoading: isLoadingTermSet } = useGetTermSetQuery(
        settings?.userSettings
            ? {
                  languageId: settings?.userSettings?.languageId,
                  code: ACCOUNTING_DEFS.CURRENCY_EXCHANGE_RATE_FORM,
              }
            : skipToken
    );

    const { data: currencyCodes, isLoading: isLoadingCurrencyCodes } =
        useListCurrencyCodesQuery();

    const { data: rateTypes, isLoading: isLoadingRateTypeCodes } =
        useListCurrencyRateTypesForDropdownQuery();

    const {
        data: activeCurrencyExchangeRate,
        isLoading: isLoadingExchangeRate,
    } = useGetCurrencyExchangeRateQuery(
        id === RoutingValues.newId ? skipToken : id
    );

    const [createExchangeRate] = useCreateCurrencyExchangeRateMutation();
    const [updateExchangeRate] = useUpdateCurrencyExchangeRateMutation();

    const blankExchangeRate: CurrencyExchangeRate = {
        id: null,
        businessEntityId: null,
        sourceCurrencyCode: accountingNav
            ? accountingNav?.sourceCurrencyCode
            : null,
        targetCurrencyCode: null,
        currencyRateTypeId: null,
        exchangeRate: null,
        effectiveAt: null,
        createdAt: null,
        createdBy: null,
        updatedAt: null,
        updatedBy: null,
    };

    const {
        fields,
        handleFieldChange,
        formMethods,
        onCreate,
        onUpdate,
        onDelete,
        closeForm,
        auditableFields,
    } = useBaseForm({
        closePath: `/accounting/currency/${accountingNav.currencyId}`,
        blankEntity: blankExchangeRate,
        activeEntity: activeCurrencyExchangeRate,
        getDescription: () => {
            return `Currency Exchange Rate ${fields.sourceCurrencyCode}`;
        },
        createEntity: () => {
            return createExchangeRate({ postBody: fields });
        },
        updateEntity: () => {
            return updateExchangeRate({
                id: id as unknown as number,
                postBody: fields,
            });
        },
        deleteEntity: () => {
            return null;
        },
    });

    useEffect(() => {
        if (
            !isLoadingRateTypeCodes &&
            accountingNav &&
            !isNilOrEmpty(accountingNav.rateTypeCode)
        ) {
            const listRateTypes = rateTypes.filter(
                (rateType: SelectionOption) =>
                    rateType.object.currencyRateTypeCode ===
                    accountingNav.rateTypeCode
            );
            setFilteredRateTypes(listRateTypes);
        }
    }, [isLoadingRateTypeCodes]);

    return {
        fields,
        handleFieldChange,
        formMethods,
        onCreate,
        onUpdate,
        onDelete,
        closeForm,
        isLoading:
            isLoadingCurrencyCodes ||
            isLoadingRateTypeCodes ||
            isLoadingTermSet ||
            isLoadingExchangeRate,
        termSet,
        canCreateExchangeRate,
        canUpdateExchangeRate,
        currencyCodes,
        filteredRateTypes,
        auditableFields,
    };
};

export default useCurrencyExchangeRateForm;
