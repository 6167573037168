import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import { PermissionsUtil } from '../../../../utils/permissions/permissionsUtil';
import { PERMISSIONS } from '../../../../constants/permissions/Permissions.constants';
import React, { useEffect, useState } from 'react';
import { ACCOUNTING_DEFS } from '../../../../constants/i18n/translations/termSetDefinitions/accounting';
import { ColDef } from 'ag-grid-community';
import DefaultColumnTypes from '../../../../components/grids/columns/Column.constants';
import CustomHeader from '../../../../components/grids/CustomHeader';
import { FiscalYearGridDefs } from '../../../../constants/i18n/translations/termDefinitions/accounting';
import { isNilOrEmpty } from '../../../../utils/objectUtils';
import { auditableGridColumns } from '../../../grid/utils/auditableGrid/auditableGridColumns';
import { useListFiscalYearQuery } from '../../../../services/accounting/accounting.service';
import useTranslation from '../../../../components/i18n/hooks/useTranslation';
import { RoutingValues } from '../../../../types/Shared.types';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';
import { FiscalYear } from '../../../../types/Accounting.types';
import { getNextFiscalYearValue } from '../../../../store/accounting';

export const useFiscalYearGrid = () => {
    const dispatch = useDispatch();
    //translation
    const { termSet, isLoadingTermSet } = useTranslation(
        ACCOUNTING_DEFS.FISCAL_YEAR_GRID
    );

    //permissions
    const user = useSelector((state: RootState) => state.user);
    const [canView, canCreate, canUpdate] =
        PermissionsUtil.checkPermissionGroup(
            user.permissions,
            PERMISSIONS.ACCOUNTING.FISCAL_YEARS
        );

    //column and data definitions
    const [colDefs, setColDefs] = useState([]);
    const [rowData, setRowData] = useState([]);
    const [hiddenIdColumns, auditableColumns] = auditableGridColumns();

    const { data: entityList, isLoading: isLoading } = useListFiscalYearQuery();

    const navigate = useNavigate();
    const redirectToForm = (row: any) => {
        if (row.id) {
            navigate(`/accounting/setup/fiscalYears/${row.id}`);
        } else {
            navigate(`/accounting/setup/fiscalYears/${RoutingValues.newId}`);
        }
    };

    useEffect(() => {
        let nextFiscalYear = new Date().getFullYear();
        let nextStartDate = new Date(new Date().getFullYear(), 0, 1);
        let nextEndDate = new Date(new Date().getFullYear(), 11, 31);
        let canAdjustStartDate = true;
        if (rowData && rowData.length > 0) {
            const data = _.maxBy(rowData, (row: FiscalYear) => row.number);
            nextFiscalYear = data.number + 1;

            // Figure out the next starting date, aka last end date + 1
            nextStartDate = new Date(data.endDate);
            nextStartDate.setDate(nextStartDate.getDate() + 1);

            // Figure out the next end date, aka next start date + 1 year - 1 day
            nextEndDate = new Date(nextStartDate);
            nextEndDate.setFullYear(nextStartDate.getFullYear() + 1);
            nextEndDate.setDate(nextEndDate.getDate() - 1);

            // One or more fiscal years exist so we can't adjust the start date
            canAdjustStartDate = false;
        }
        dispatch(
            getNextFiscalYearValue({
                isLoading: false,
                nextFiscalYear: nextFiscalYear,
                nextStartDate: nextStartDate,
                nextEndDate: nextEndDate,
                canAdjustStartDate: canAdjustStartDate,
            })
        );
    }, [rowData]);

    useEffect(() => {
        if (!isNilOrEmpty(termSet)) {
            const columnDefs: ColDef[] = [
                ...hiddenIdColumns,
                {
                    field: 'number',
                    hide: true,
                    ...DefaultColumnTypes.NumberOnly,
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={FiscalYearGridDefs.Number}
                            />
                        );
                    },
                },
                {
                    field: 'code',
                    ...DefaultColumnTypes.ShortText,
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={FiscalYearGridDefs.Code}
                            />
                        );
                    },
                },
                {
                    field: 'fiscalYearStatus.code',
                    ...DefaultColumnTypes.LongText,
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={FiscalYearGridDefs.Status}
                            />
                        );
                    },
                },
                {
                    field: 'startDate',
                    ...DefaultColumnTypes.DateOnly,
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={FiscalYearGridDefs.StartDate}
                            />
                        );
                    },
                },
                {
                    field: 'endDate',
                    ...DefaultColumnTypes.DateOnly,
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={FiscalYearGridDefs.EndDate}
                            />
                        );
                    },
                },
                ...auditableColumns,
            ];
            setColDefs(columnDefs);
        }
    }, [termSet]);

    useEffect(() => {
        if (!isNilOrEmpty(entityList)) {
            setRowData(entityList);
        }
    }, [entityList]);

    return {
        canView,
        canCreate,
        canUpdate,
        termSet,
        rowData,
        colDefs,
        isLoading,
        isLoadingTermSet,
        redirectToForm,
    };
};

export default useFiscalYearGrid;
