import React from 'react';
import { Grid } from '@mui/material';
import { AgGridColumn } from 'ag-grid-react';
import CreateRole from './CreateRole';
import PermissionTree from '../permissions/PermissionTree';
import AgGrid from '../../../components/grids/Grid';
import DatacorLogoSpinner from '../../../components/datacorLogoSpinner/DatacorLogoSpinner';
import useRolesGrid from '../hooks/useRolesGrid';
let moment = require('moment');

const RolesGrid = () => {
    const {
        showCreateRoleModal,
        handleCreateRoleModalClose,
        isLoadingRoles,
        isFetching,
        handleCreateRoleModalOpen,
        companiesRoles,
        onGridReady,
        onSelectionChanged,
        actionButtons,
        selectedRow,
    } = useRolesGrid();
    return (
        <>
            {showCreateRoleModal ? (
                <CreateRole handleClose={handleCreateRoleModalClose} />
            ) : null}

            <Grid container spacing={3}>
                <Grid item xs={7}>
                    {!isLoadingRoles && !isFetching ? (
                        <AgGrid
                            onCreateNew={handleCreateRoleModalOpen}
                            displayToolbar={true}
                            displayCreateNewButton={true}
                            testId="roles-list-grid"
                            paginationPageSize={10}
                            rowData={companiesRoles}
                            onGridReady={onGridReady}
                            rowSelection={'single'}
                            onRowSelected={onSelectionChanged}
                            frameworkComponents={{
                                actionButtons: actionButtons,
                            }}
                            dataKey={'name'}
                            immutableData={true}
                            defaultColDef={{
                                resizable: true,
                                sortable: true,
                                unSortIcon: true,
                                filter: true,
                                wrapText: true,
                            }}>
                            <AgGridColumn
                                field="actionButtons"
                                suppressMenu={true}
                                resizable={false}
                                sortable={false}
                                headerName=""
                                minWidth={190}
                                cellRenderer="actionButtons"
                            />
                            <AgGridColumn
                                flex={2}
                                field="name"
                                headerName="Role Name"
                            />
                            <AgGridColumn
                                flex={1}
                                field="note"
                                headerName="Note"
                            />
                            <AgGridColumn
                                width={140}
                                field="lastUpdatedDate"
                                headerName="Last Updated On"
                                valueFormatter={(val) =>
                                    moment(val.value).isValid()
                                        ? moment(val.value).format('MM/DD/YYYY')
                                        : ''
                                }
                            />
                            <AgGridColumn
                                width={140}
                                field="dateCreated"
                                headerName="Created On"
                                valueFormatter={(val) =>
                                    moment(val.value).isValid()
                                        ? moment(val.value).format('MM/DD/YYYY')
                                        : ''
                                }
                            />
                        </AgGrid>
                    ) : (
                        <DatacorLogoSpinner />
                    )}
                </Grid>
                <Grid item xs={5}>
                    <PermissionTree roleId={selectedRow?.roleId} />
                </Grid>
            </Grid>
        </>
    );
};

export default RolesGrid;
