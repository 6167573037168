import React, { useContext, useEffect, useState } from 'react';
import { isNilOrEmpty } from '../../../../../../../../../utils/objectUtils';
import {
    SdsHeader,
    SdsSubSection15_2,
} from '../../../../../../../../../types/formulation';
import { useListRegulationsDropdownQuery } from '../../../../../../../../../services/formulation/regulations/regulations.service';
import { PermissionsUtil } from '../../../../../../../../../utils/permissions/permissionsUtil';
import { PERMISSIONS } from '../../../../../../../../../constants/permissions/Permissions.constants';
import DefaultColumnTypes from '../../../../../../../../../components/grids/columns/Column.constants';
import {
    BlobData,
    SdsBlobSectionFormProps,
} from '../../../../shared/blobs/hooks/SdsBlobSectionFormProps';
import useSdsBlobSectionForm from '../../../../shared/blobs/hooks/useSdsBlobSectionForm';
import CustomHeader from '../../../../../../../../../components/grids/CustomHeader';
import { SDSFormDefs } from '../../../../../../../../../constants/i18n/translations/termDefinitions/formulation';
import SettingsContext from '../../../../../../../../../contexts/settings.context';
import { useGetTermSetQuery } from '../../../../../../../../../services/i18n/i18n.service';
import { FORMULATION_DEFS } from '../../../../../../../../../constants/i18n/translations/termSetDefinitions/formulation';
import { skipToken } from '@reduxjs/toolkit/query';

const useSubSection15_2 = (props: SdsBlobSectionFormProps<SdsHeader>) => {
    const { user } = props.parentData;

    const blobData: BlobData<SdsSubSection15_2> = {
        sectionNumber: 15,
        subSectionNumber: 2,
        blankBlobEntity: { chemicalSafety: '', additionalText: '' },
    };

    const { fields, isBlobLoading, formMethods, handleFieldChange } =
        useSdsBlobSectionForm<SdsHeader, SdsSubSection15_2>({
            ...props,
            blobData,
        });

    const { data: regulationOptions, isLoading: isLoadingRegulationOptions } =
        useListRegulationsDropdownQuery();

    const canViewRegulation = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.FORMULATION.REGULATIONS.VIEW
    );

    const [dropdownOptions, setDropdownOptions] = useState({
        chemicalSafety: '',
        selectedChemicalSafety: null,
    });

    const { settings } = useContext(SettingsContext);
    const { data: termSet } = useGetTermSetQuery(
        settings?.userSettings
            ? {
                  languageId: settings?.userSettings?.languageId,
                  code: FORMULATION_DEFS.SDS_FORM,
              }
            : skipToken
    );

    const regulatoryCodeTextPopupColDefs = [
        {
            field: 'label',
            headerComponentFramework: (props: any) => {
                return (
                    <CustomHeader
                        {...props}
                        termSet={termSet}
                        termKey={
                            SDSFormDefs.Regulatory_Code_Popup_Regulatory_Code
                        }
                    />
                );
            },
            ...DefaultColumnTypes.LongText,
        },
        {
            field: 'title',
            headerComponentFramework: (props: any) => {
                return (
                    <CustomHeader
                        {...props}
                        termSet={termSet}
                        termKey={
                            SDSFormDefs.Regulatory_Code_Popup_Regulatory_Title
                        }
                    />
                );
            },
            ...DefaultColumnTypes.LongText,
        },
    ];

    useEffect(() => {
        if (!isNilOrEmpty(regulationOptions) && !isLoadingRegulationOptions) {
            setDropdownOptions((previousValues: any) => ({
                ...previousValues,
                selectedRegulatoryCode: regulationOptions?.find(
                    (current: any) => current.value === fields.chemicalSafety
                ),
            }));
        }
    }, [isLoadingRegulationOptions, regulationOptions, fields]);

    return {
        fields,
        isBlobLoading,
        handleFieldChange,
        formMethods,
        isLoadingRegulationOptions,
        regulationOptions,
        canViewRegulation,
        dropdownOptions,
        regulatoryCodeTextPopupColDefs,
        termSet,
    };
};
export default useSubSection15_2;
