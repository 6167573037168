import { createSlice } from '@reduxjs/toolkit';
import { SliceState } from '../types/store/UiElementsStore.types';

const initialState: SliceState = {
    snackbarBasic: {
        message: '',
        isOpen: false,
        autoHideDuration: null,
        anchorOrigin: null,
        severity: null,
    },
    modalConfirmBasic: {
        title: null,
        message: '',
        onConfirm: null,
        isOpen: false,
        onCancel: null,
    },
};

const slice = createSlice({
    name: 'uiElements',
    initialState: initialState,
    reducers: {
        clearSnackbarBasic: (state: any) => {
            state.snackbarBasic.isOpen = false;
            state.snackbarBasic.message = '';
            state.snackbarBasic.autoHideDuration = null;
            state.snackbarBasic.anchorOrigin = null;
            state.snackbarBasic.severity = null;
        },
        openSnackbarBasicWithMessage: (state: any, action) => {
            state.snackbarBasic.isOpen = true;
            state.snackbarBasic.message = action.payload.message;
            state.snackbarBasic.autoHideDuration =
                action.payload.autoHideDuration;
            state.snackbarBasic.anchorOrigin = action.payload.anchorOrigin;
            state.snackbarBasic.severity = action.payload.severity;
        },
        clearModalConfirmBasic: (state: any) => {
            state.modalConfirmBasic.isOpen = false;
            state.modalConfirmBasic.title = '';
            state.modalConfirmBasic.message = '';
            state.modalConfirmBasic.onConfirm = null;
        },
        openModalConfirmBasicWithMessage: (state: any, action) => {
            state.modalConfirmBasic.isOpen = true;
            state.modalConfirmBasic.title = action.payload.title;
            state.modalConfirmBasic.message = action.payload.message;
            state.modalConfirmBasic.onConfirm = action.payload.onConfirm;
            state.modalConfirmBasic.onCancel = action.payload.onCancel;
            state.modalConfirmBasic.hideCancelButton =
                action.payload.hideCancelButton;
            state.modalConfirmBasic.confirmButtonText =
                action.payload.confirmButtonText;
            state.modalConfirmBasic.declineButtonText =
                action.payload.declineButtonText;
        },
    },
});

export default slice.reducer;

// Actions
export const {
    clearSnackbarBasic,
    openSnackbarBasicWithMessage,
    clearModalConfirmBasic,
    openModalConfirmBasicWithMessage,
} = slice.actions;
