import BaseForm, {
    ParamTypes,
} from '../../../components/form/baseForm/BaseForm';
import {
    NumberMaxValues,
    RoutingValues,
    SelectionOption,
} from '../../../types/Shared.types';
import { CURRENCY } from '../../../constants/revisionHistory/revisionHistory.constants';
import FormPartial from '../../../components/form/formContainer/FormPartial';
import { Grid, Tab } from '@mui/material';
import TextInput from '../../../components/form/formInputs/TextInput/TextInput';
import NumericInput from '../../../components/form/formInputs/NumericInput/NumericInput';
import React from 'react';
import TranslatableText from '../../../components/i18n/TranslatableText';
import { CurrencyFormDefs } from '../../../constants/i18n/translations/termDefinitions/accounting';
import useCurrencyForm from './hooks/useCurrencyForm';
import { useParams } from 'react-router-dom';
import SelectInput from '../../../components/form/formInputs/SelectInput/SelectInput';
import DateInput from '../../../components/form/formInputs/DateInput/DateInput';
import Box from '@mui/material/Box';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import SectionExchangeRateGrid from './SectionExchangeRateGrid';
import { ExchangeRateGridProps } from './hooks/useSectionExchangeRateGrid';
import { Currency } from '../../../types/platform/i18n/Currency.type';

const CurrencyForm = () => {
    const { id } = useParams<ParamTypes>();
    const {
        fields,
        handleFieldChange,
        formMethods,
        onCreate,
        onUpdate,
        onDelete,
        closeForm,
        termSet,
        canCreateCurrency,
        canUpdateCurrency,
        canDeleteCurrency,
        currencyCodes,
        isLoadingCurrencyCodes,
        currentTab,
        setCurrentTab,
        rateTypeCodes,
        hasEntityChanges,
        parentDataProps,
        isLoadingData,
    } = useCurrencyForm(id);

    return (
        <BaseForm
            title={
                <>
                    <TranslatableText
                        termKey={CurrencyFormDefs.Title}
                        termSet={termSet}
                    />{' '}
                    {fields?.currencyCode}
                </>
            }
            formMethods={formMethods}
            isLoading={isLoadingData()}
            isNew={id === RoutingValues.newId}
            hasEntityChanges={hasEntityChanges}
            onCreate={onCreate}
            onUpdate={onUpdate}
            onDelete={onDelete}
            onCancel={closeForm}
            showOnRevision={true}
            fieldMappings={CURRENCY}
            entityUuid={fields?.uuid}
            displayCreateButton={canCreateCurrency}
            displayUpdateButton={canUpdateCurrency}
            displayDeleteButton={canDeleteCurrency}>
            <FormPartial>
                <Grid container flexDirection="row" spacing={2}>
                    <Grid item xs={6}>
                        <SelectInput
                            fullWidth
                            label={
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={CurrencyFormDefs.CurrencyCode}
                                />
                            }
                            id="currencyCode"
                            value={fields.currencyCode}
                            onChange={handleFieldChange}
                            formMethods={formMethods}
                            options={currencyCodes}
                            disabled={
                                isLoadingCurrencyCodes ||
                                id !== RoutingValues.newId
                            }
                            required={true}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <NumericInput
                            fullWidth
                            label={
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={CurrencyFormDefs.Number}
                                />
                            }
                            id="number"
                            value={fields.currentCurrencyCode?.number}
                            onChange={handleFieldChange}
                            formMethods={formMethods}
                            required={true}
                            disabled={true}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextInput
                            fullWidth
                            label={
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={CurrencyFormDefs.FullName}
                                />
                            }
                            id="fullName"
                            value={fields.fullName}
                            onChange={handleFieldChange}
                            formMethods={formMethods}
                            required={true}
                            maxLength={30}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextInput
                            fullWidth
                            label={
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={CurrencyFormDefs.ShortName}
                                />
                            }
                            id="shortName"
                            value={fields.shortName}
                            onChange={handleFieldChange}
                            formMethods={formMethods}
                            required={true}
                            maxLength={30}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextInput
                            fullWidth
                            label={
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={CurrencyFormDefs.ShortHandSymbol}
                                />
                            }
                            id="symbol"
                            value={fields.symbol}
                            onChange={handleFieldChange}
                            formMethods={formMethods}
                            required={true}
                            maxLength={30}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <NumericInput
                            fullWidth
                            label={
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={CurrencyFormDefs.DecimalDisplay}
                                />
                            }
                            id="decimalDisplay"
                            value={fields.decimalDisplay}
                            onChange={handleFieldChange}
                            formMethods={formMethods}
                            required={true}
                            maxValue={NumberMaxValues.smallint}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <NumericInput
                            fullWidth
                            label={
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={CurrencyFormDefs.SortOrder}
                                />
                            }
                            id="sortOrder"
                            value={fields.sortOrder}
                            onChange={handleFieldChange}
                            formMethods={formMethods}
                            required={true}
                            maxValue={NumberMaxValues.smallint}
                        />
                    </Grid>
                    <Grid item xs={2} hidden={id === RoutingValues.newId}>
                        <DateInput
                            label={
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={CurrencyFormDefs.CreatedAt}
                                />
                            }
                            id="createdAt"
                            value={fields.createdAt}
                            onChange={handleFieldChange}
                            formMethods={formMethods}
                            disabled={true}
                        />
                    </Grid>
                    <Grid item xs={4} hidden={id === RoutingValues.newId}>
                        <TextInput
                            fullWidth
                            label={
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={CurrencyFormDefs.CreatedBy}
                                />
                            }
                            id="createdBy"
                            value={fields.createdByUser?.email}
                            onChange={handleFieldChange}
                            formMethods={formMethods}
                            maxLength={30}
                            disabled={true}
                        />
                    </Grid>
                    <Grid item xs={2} hidden={id === RoutingValues.newId}>
                        <DateInput
                            label={
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={CurrencyFormDefs.UpdatedAt}
                                />
                            }
                            id="updatedAt"
                            value={fields.updatedAt}
                            onChange={handleFieldChange}
                            formMethods={formMethods}
                            disabled={true}
                        />
                    </Grid>
                    <Grid item xs={4} hidden={id === RoutingValues.newId}>
                        <TextInput
                            fullWidth
                            label={
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={CurrencyFormDefs.UpdatedBy}
                                />
                            }
                            id="updatedBy"
                            value={fields.updatedByUser?.email}
                            onChange={handleFieldChange}
                            formMethods={formMethods}
                            maxLength={30}
                            disabled={true}
                        />
                    </Grid>
                </Grid>
            </FormPartial>
            <FormPartial
                title={
                    (
                        <TranslatableText
                            termKey={CurrencyFormDefs.ExchangeRateSection}
                            termSet={termSet}
                        />
                    ) as unknown as string
                }
                isVisible={id !== RoutingValues.newId}>
                <TabContext value={currentTab as string}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList
                            onChange={(event, value: string) =>
                                setCurrentTab(value)
                            }>
                            {rateTypeCodes?.map(
                                (rateTypeCode: SelectionOption) => {
                                    return (
                                        <Tab
                                            value={rateTypeCode.object.id.toString()}
                                            label={rateTypeCode.label}
                                            key={rateTypeCode.value}
                                        />
                                    );
                                }
                            )}
                        </TabList>
                    </Box>
                    {rateTypeCodes?.map((rateTypeCode: SelectionOption) => {
                        const exchangeRateProps: ExchangeRateGridProps<Currency> =
                            {
                                ...parentDataProps,
                                rateTypeCode: rateTypeCode.value,
                                tabIndex: rateTypeCode.object.id.toString(),
                            };
                        return (
                            <TabPanel
                                value={rateTypeCode.object.id.toString()}
                                key={rateTypeCode.value}>
                                <SectionExchangeRateGrid
                                    {...exchangeRateProps}
                                />
                            </TabPanel>
                        );
                    })}
                </TabContext>
            </FormPartial>
        </BaseForm>
    );
};

export default CurrencyForm;
