import { HazardType } from '../../../../types/formulation';
import { datacorAppApi } from '../../../datacorAppApi';

const serviceUrl: string = 'hazard-types';

export const hazardTypesApi = datacorAppApi.injectEndpoints({
    endpoints: (build) => ({
        listHazardTypes: build.query<{ label: string; value: bigint }[], void>({
            query: () => {
                return {
                    url: serviceUrl,
                    method: 'GET',
                };
            },
            transformResponse: (response: HazardType[]) => {
                return response.map((hazardType: HazardType) => {
                    return {
                        label: hazardType.description,
                        value: hazardType.id,
                    };
                });
            },
            providesTags: ['HazardType'],
        }),
    }),
});

export const { useListHazardTypesQuery } = hazardTypesApi;
