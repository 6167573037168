import React from 'react';
import AccountFunctionCodeGrid from './AccountFunctionCodeGrid';

const Accounting = () => {
    return (
        <React.Fragment>
            <AccountFunctionCodeGrid />
        </React.Fragment>
    );
};

export default Accounting;
