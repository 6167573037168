import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { GridReadyEvent } from 'ag-grid-community';
import { useGetGroupsQuery } from '../../../services/organizations/organizations.service';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { skipToken } from '@reduxjs/toolkit/query';
import { updateGridModels } from '../../../store/grids';
import SettingsContext from '../../../contexts/settings.context';
import { useGetTermSetQuery } from '../../../services/i18n/i18n.service';
import { PLATFORM_DEFS } from '../../../constants/i18n/translations/termSetDefinitions/platform';

const useGroupsGrid = () => {
    const user = useSelector((state: RootState) => state.user);
    const dispatch = useDispatch();
    const { administration } = useSelector((state: RootState) => state.grids);
    const navigate = useNavigate();
    const [gridApi, setGridApi] = useState(null);
    const { data: rowData, isLoading: isLoading } = useGetGroupsQuery();

    const [gridColumnApi, setGridColumnApi] = useState(null);

    const { settings } = useContext(SettingsContext);
    const { data: termSet } = useGetTermSetQuery(
        settings
            ? {
                  languageId: settings?.userSettings?.languageId,
                  code: PLATFORM_DEFS.GROUPS_GRID,
              }
            : skipToken
    );

    const onGridReady = (params: GridReadyEvent) => {
        setGridApi(params.api);
        setGridColumnApi(params.columnApi);
    };

    const redirectToForm = (row: any) => {
        if (row.id) {
            navigate(`/administration/organizations/groups/${row.id}`);
        } else {
            navigate('/administration/organizations/groups/new');
        }
    };

    const onFirstDataRendered = () => {
        gridColumnApi?.applyColumnState({
            state: administration.groupsList.column,
            applyOrder: true,
        });

        gridApi?.setFilterModel(administration.groupsList.filter);
    };

    const onSortChanged = () => {
        dispatch(
            updateGridModels({
                gridLocation: 'administration',
                gridName: 'groupsList',
                type: 'column',
                model: gridColumnApi.getColumnState(),
            })
        );
    };

    return {
        onGridReady,
        rowData,
        isLoading,
        redirectToForm,
        onSortChanged,
        onFirstDataRendered,
        termSet,
    };
};

export default useGroupsGrid;
