import { RDFormulaApprovalType } from '../../../../types/formulation';
import { datacorAppApi } from '../../../datacorAppApi';

const serviceUrl: string = 'rdFormulaApprovalType';

export const rdFormulaApprovalTypeApi = datacorAppApi.injectEndpoints({
    endpoints: (build) => ({
        getApprovalTypesForDropdown: build.query<
            { label: string; value: bigint }[],
            void
        >({
            query: () => serviceUrl,
            transformResponse: (response: RDFormulaApprovalType[]) => {
                return response.map((propType: RDFormulaApprovalType) => {
                    return {
                        label: propType.type,
                        value: propType.id,
                    };
                });
            },
            providesTags: ['ApprovalType'],
        }),
    }),
});

export const { useGetApprovalTypesForDropdownQuery } = rdFormulaApprovalTypeApi;
