import {
    CasGhsHazardPhrase,
    CasGhsPrecautionaryPhrase,
    CasMaster,
} from '../../../../../../types/formulation';
import FormPartial from '../../../../../../components/form/formContainer/FormPartial';
import CasPictogramForm from './subSections/casPictogram/CasPictogramForm';
import CasGhsHazardPhrasesGrid from './subSections/casGhsHazardPhrases/CasGhsHazardPhrasesGrid';
import CasGhsPrecautionaryPhrasesGrid from './subSections/casGhsPrecautionaryPhrases/CasGhsPrecautionaryPhrasesGrid';
import React, { useContext } from 'react';
import DatacorLogoSpinner from '../../../../../../components/datacorLogoSpinner/DatacorLogoSpinner';
import { ChildEditableGridProps } from '../../../../../grid/utils/editableGrid/ChildEditableGridProps';
import { ChildFormProps } from '../../../../../form/helpers/ChildFormProps';
import { CasMasterFormDefs } from '../../../../../../constants/i18n/translations/termDefinitions/formulation';
import TranslatableText from '../../../../../../components/i18n/TranslatableText';
import SettingsContext from '../../../../../../contexts/settings.context';
import { useGetTermSetQuery } from '../../../../../../services/i18n/i18n.service';
import { FORMULATION_DEFS } from '../../../../../../constants/i18n/translations/termSetDefinitions/formulation';
import { skipToken } from '@reduxjs/toolkit/query';

export interface CasGlobalHarmonizationFormProps
    extends ChildEditableGridProps<CasMaster> {
    ghsHazardPhraseCopyMethods: {
        rowsData: CasGhsHazardPhrase[];
        setRowsData: React.Dispatch<React.SetStateAction<CasGhsHazardPhrase[]>>;
    };
    ghsPrecautionaryPhraseCopyMethods: {
        rowsData: CasGhsPrecautionaryPhrase[];
        setRowsData: React.Dispatch<
            React.SetStateAction<CasGhsPrecautionaryPhrase[]>
        >;
    };
}

const CasGlobalHarmonizationForm = ({
    parentData,
    displayGridButtons,
    helpers,
    permissions,
    ghsHazardPhraseCopyMethods,
    ghsPrecautionaryPhraseCopyMethods,
}: CasGlobalHarmonizationFormProps & ChildFormProps<CasMaster>) => {
    const { isParentLoading } = parentData;

    const { settings } = useContext(SettingsContext);
    const { data: termSet } = useGetTermSetQuery(
        settings?.userSettings
            ? {
                  languageId: settings?.userSettings?.languageId,
                  code: FORMULATION_DEFS.CAS_MASTER_FORM,
              }
            : skipToken
    );

    return isParentLoading ? (
        <DatacorLogoSpinner />
    ) : (
        <FormPartial
            title={
                (
                    <TranslatableText
                        termKey={CasMasterFormDefs.Global_Harmonization}
                        termSet={termSet}
                    />
                ) as unknown as string
            }>
            <CasPictogramForm
                parentData={parentData}
                helpers={helpers}
                permissions={permissions}
            />
            <CasGhsHazardPhrasesGrid
                parentData={parentData}
                displayGridButtons={displayGridButtons}
                helpers={helpers}
                copyMethods={ghsHazardPhraseCopyMethods}
            />
            <CasGhsPrecautionaryPhrasesGrid
                parentData={parentData}
                displayGridButtons={displayGridButtons}
                helpers={helpers}
                copyMethods={ghsPrecautionaryPhraseCopyMethods}
            />
        </FormPartial>
    );
};

export default CasGlobalHarmonizationForm;
