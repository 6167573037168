import { ParameterizedValidator, Validator } from './validator.interface';
import { isNil } from '../utils/objectUtils';
import _ from 'lodash';
import React from 'react';

export const MaxValueValidator: ParameterizedValidator = (maxValue: number) => {
    return {
        key: 'maxValue',
        getErrorMessage: (fieldLabel: string) => {
            if (_.isObject(fieldLabel)) {
                return (
                    <>
                        {fieldLabel} has a maximum value of {maxValue}
                    </>
                );
            }
            return `${fieldLabel} has a maximum value of ${maxValue}`;
        },
        isValid: (value: any) => {
            const validNumber =
                isNil(value) || isNaN(Number(value)) || (value as number);
            if (value === '-') {
                return validNumber;
            } else {
                return validNumber && Number(value) <= maxValue;
            }
        },
    } as Validator;
};
