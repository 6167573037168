import { useContext, useEffect, useState } from 'react';
import { FA_Asset, FA_AssetContract } from '../../../../types/FixedAsset.types';
import _ from 'lodash';
import { Contract } from '../../../../types/Accounting.types';
import { PermissionsUtil } from '../../../../utils/permissions/permissionsUtil';
import { PERMISSIONS } from '../../../../constants/permissions/Permissions.constants';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import SettingsContext from '../../../../contexts/settings.context';
import { ACCOUNTING_DEFS } from '../../../../constants/i18n/translations/termSetDefinitions/accounting';
import { skipToken } from '@reduxjs/toolkit/query';
import { useGetTermSetQuery } from '../../../../services/i18n/i18n.service';

export class FA_CoverageRow {
    public readonly id: string;
    public readonly contract: Contract;
    public readonly assets: FA_Asset[];

    constructor(assetContract: FA_AssetContract) {
        this.id = `${assetContract.contractId}`;
        this.contract = assetContract.contract;
        this.assets = [];
    }

    public get totalAssetCost(): number {
        return _.sumBy(this.assets, (asset) => Number(asset?.totalCost));
    }

    public get totalRemainingValue(): number {
        return _.sumBy(this.assets, (asset) => Number(asset.remainingValue));
    }
}

const useContractCoverageGrid = () => {
    const user = useSelector((state: RootState) => state.user);
    const [showOptionsModal, setShowOptionsModal] = useState<boolean>(true);
    const [assetList, setAssetList] = useState<FA_Asset[]>([]);
    const { settings } = useContext(SettingsContext);
    const { data: termSet } = useGetTermSetQuery(
        settings?.userSettings
            ? {
                  languageId: settings?.userSettings?.languageId,
                  code: ACCOUNTING_DEFS.CONTRACT_COVERAGE_GRID,
              }
            : skipToken
    );
    const [contactCoverageList, setContactCoverageList] = useState<
        FA_CoverageRow[]
    >([]);

    const canViewContractCoverage = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.FIXED_ASSETS.CONTRACTS.VIEW_CONTRACT_COVERAGE
    );

    const _createRowsFromList = (list: FA_Asset[]): FA_CoverageRow[] => {
        const rowMap: Map<string, FA_CoverageRow> = new Map<
            string,
            FA_CoverageRow
        >();

        list.map((asset: FA_Asset) => {
            asset.contracts.forEach((contract) => {
                //establish the row key for uniqueness
                const rowKey: string = `${contract.contractId}`;

                if (!rowMap.has(rowKey)) {
                    rowMap.set(rowKey, new FA_CoverageRow(contract));
                    rowMap.get(rowKey).assets.push(asset);
                } else {
                    rowMap.get(rowKey).assets.push(asset);
                }
            });
        });

        return _.orderBy(Array.from(rowMap.values()), [
            'contract.contractType.code',
            'contract.number',
        ]);
    };

    useEffect(() => {
        setContactCoverageList(_createRowsFromList(assetList));
    }, [assetList]);

    return {
        showOptionsModal,
        setShowOptionsModal,
        contactCoverageList,
        setAssetList,
        canViewContractCoverage,
        termSet,
    };
};

export default useContractCoverageGrid;
