import React from 'react';
import LocaleGrid from './LocaleGrid';

const Locale = () => {
    return (
        <>
            <LocaleGrid />
        </>
    );
};

export default Locale;
