import React, { useState } from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import { useNavigate } from 'react-router-dom';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Collapse, List, ListItemText } from '@mui/material';
import { isNil } from '../../../utils/objectUtils';
import NavLink from '../../../utils/navigation/navLink';
import { RootState } from '../../../store';
import { useSelector } from 'react-redux';
import { PermissionsUtil } from '../../../utils/permissions/permissionsUtil';

interface SideItemMenuProps {
    navLink: NavLink;
    selectedKey: string;
    setSelectedKey: (key: string) => void;
}

const SideMenuItem = (props: SideItemMenuProps) => {
    const user = useSelector((state: RootState) => state.user);
    let shouldShowLink = true;

    if (props.navLink.permissionKey) {
        shouldShowLink = PermissionsUtil.isPermissionEnabled(
            user.permissions,
            props.navLink.permissionKey
        );
    }

    return (
        <React.Fragment>
            {' '}
            {shouldShowLink ? (
                <div key={props.navLink.name}>
                    {props.navLink.isExternalURL() ? (
                        <ExternalLinkMenuItem {...props} />
                    ) : (
                        <MenuItem {...props} />
                    )}
                </div>
            ) : null}
        </React.Fragment>
    );
};

const ExternalLinkMenuItem = (props: SideItemMenuProps) => {
    return (
        <a
            target="_blank"
            href={props.navLink.url}
            style={{ color: 'black', textDecoration: 'none' }}
            rel="noreferrer">
            <ListItemButton
                sx={{ height: '40px' }}
                selected={props.selectedKey === props.navLink.key}
                onClick={() => props.setSelectedKey(props.navLink.key)}>
                <ListItemAvatar style={{ padding: '5px' }}>
                    <Avatar
                        variant="circular"
                        src={props.navLink.appImage[props.navLink.imageName]}
                    />
                </ListItemAvatar>
                <ListItemText primary={props.navLink.name} />
            </ListItemButton>
            <Divider />
        </a>
    );
};

const MenuItem = (props: SideItemMenuProps) => {
    const user = useSelector((state: RootState) => state.user);
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();
    const handleClick = (
        event: React.MouseEvent<HTMLDivElement, MouseEvent>
    ) => {
        event.preventDefault();
        setOpen(!open);

        if (!props.navLink.hasChildren()) {
            navigate(props.navLink.url);
        }

        props.setSelectedKey(props.navLink.key);
    };

    return (
        <div>
            <ListItemButton
                sx={{ height: '40px' }}
                selected={props.selectedKey === props.navLink.key}
                onClick={(event) => handleClick(event)}>
                {!isNil(props.navLink.appImage) ? (
                    <ListItemAvatar style={{ padding: '5px' }}>
                        <Avatar
                            variant="circular"
                            src={
                                props.navLink.appImage[props.navLink.imageName]
                            }
                        />
                    </ListItemAvatar>
                ) : null}
                <ListItemText primary={props.navLink.name} />
                {props.navLink.hasChildren() ? (
                    open ? (
                        <ExpandLess />
                    ) : (
                        <ExpandMore />
                    )
                ) : null}
            </ListItemButton>

            {props.navLink.hasChildren() ? (
                <Collapse in={open} timeout="auto" unmountOnExit>
                    <List
                        component="div"
                        disablePadding
                        style={{ paddingLeft: '10px' }}>
                        {props.navLink.children
                            ?.filter((navLink: NavLink) => {
                                return !(
                                    navLink.datacorAdminOnly &&
                                    !user.isDatacorAdmin
                                );
                            })
                            .map((child: NavLink) => {
                                return (
                                    <SideMenuItem
                                        key={child.key}
                                        navLink={child}
                                        selectedKey={props.selectedKey}
                                        setSelectedKey={props.setSelectedKey}
                                    />
                                );
                            })}
                    </List>
                </Collapse>
            ) : null}

            <Divider />
        </div>
    );
};

export default SideMenuItem;
