import { useParams } from 'react-router-dom';
import UseBoilerPlateNoteForm from '../hooks/useBoilerPlateNoteForm';
import BaseForm from '../../../components/form/baseForm/BaseForm';
import FormPartial from '../../../components/form/formContainer/FormPartial';
import { Grid } from '@mui/material';
import React from 'react';
import TextInput from '../../../components/form/formInputs/TextInput/TextInput';

type ParamTypes = {
    id: string;
};

const BoilerPlateNoteForm = () => {
    const { id } = useParams<ParamTypes>();

    const {
        fields,
        handleFieldChange,
        formMethods,
        onCreate,
        onUpdate,
        onDelete,
        closeForm,
        isLoadingBoilerPlateNote,
        canCreateBoilerPlateNote,
        canUpdateBoilerPlateNote,
        canDeleteBoilerPlateNote,
    } = UseBoilerPlateNoteForm(id);

    return (
        <BaseForm
            title={'Boiler Plate Note'}
            formMethods={formMethods}
            isLoading={isLoadingBoilerPlateNote}
            isNew={!id}
            onCreate={onCreate}
            onUpdate={onUpdate}
            onDelete={onDelete}
            onCancel={closeForm}
            displayCreateButton={canCreateBoilerPlateNote}
            displayUpdateButton={canUpdateBoilerPlateNote}
            displayDeleteButton={canDeleteBoilerPlateNote}
            readOnly={id && !canUpdateBoilerPlateNote}>
            <FormPartial>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <TextInput
                            fullWidth
                            label="Note Code"
                            id="code"
                            formMethods={formMethods}
                            value={fields.code}
                            onChange={handleFieldChange}
                            required={true}
                            maxLength={30}
                            disabled={!!id}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextInput
                            fullWidth
                            label="Description"
                            id="description"
                            formMethods={formMethods}
                            value={fields.description}
                            onChange={handleFieldChange}
                            required={true}
                            maxLength={50}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextInput
                            fullWidth
                            label="Note"
                            id="note"
                            formMethods={formMethods}
                            value={fields.note}
                            onChange={handleFieldChange}
                            multiline={true}
                            maxRows={15}
                            minRows={5}
                        />
                    </Grid>
                </Grid>
            </FormPartial>
        </BaseForm>
    );
};

export default BoilerPlateNoteForm;
