import React from 'react';
import { Grid } from '@mui/material';
import FormPartial from '../../components/form/formContainer/FormPartial';
import TextInput from '../../components/form/formInputs/TextInput/TextInput';
import SelectInput from '../../components/form/formInputs/SelectInput/SelectInput';
import useBuildingsForm from './hooks/useBuildingsForm';
import { useParams } from 'react-router-dom';
import NumericInput from '../../components/form/formInputs/NumericInput/NumericInput';
import BaseForm from '../../components/form/baseForm/BaseForm';
import { BuildingsFormDefs } from '../../constants/i18n/translations/termDefinitions/platform';
import TranslatableText from '../../components/i18n/TranslatableText';
import AddressInput from '../../components/form/formInputs/AddressInput/AddressInput';

type ParamTypes = { id: string };

const BuildingsForm = () => {
    const { id } = useParams<ParamTypes>();
    const {
        fields,
        addressFields,
        isLoadingBuilding,
        handleCreateBuilding,
        handleUpdateBuilding,
        handleFieldChange,
        handleAddressFieldChange,
        handleClose,
        formMethods,
        addressFormMethods,
        countryOptions,
        stateSelectOptions,
        termSet,
        setAddressValues,
    } = useBuildingsForm(id);

    return (
        <BaseForm
            title={
                id ? (
                    fields.name
                ) : (
                    <TranslatableText
                        termKey={BuildingsFormDefs.Create_New_Building}
                        termSet={termSet}
                    />
                )
            }
            formMethods={[formMethods, addressFormMethods]}
            isLoading={isLoadingBuilding}
            isNew={!id}
            onCancel={handleClose}
            onDelete={handleClose}
            displayCreateButton={true}
            displayUpdateButton={true}
            displayDeleteButton={false}
            onCreate={handleCreateBuilding}
            onUpdate={handleUpdateBuilding}>
            <FormPartial>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <TextInput
                            fullWidth
                            label={
                                <TranslatableText
                                    termKey={BuildingsFormDefs.Building_Code}
                                    termSet={termSet}
                                />
                            }
                            id="code"
                            value={fields.code}
                            formMethods={formMethods}
                            onChange={handleFieldChange}
                            required={true}
                            maxLength={90}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextInput
                            fullWidth
                            label={
                                <TranslatableText
                                    termKey={BuildingsFormDefs.Building_Name}
                                    termSet={termSet}
                                />
                            }
                            id="name"
                            value={fields.name}
                            formMethods={formMethods}
                            onChange={handleFieldChange}
                            maxLength={90}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <NumericInput
                            fullWidth
                            label={
                                <TranslatableText
                                    termKey={BuildingsFormDefs.Supplier_Code}
                                    termSet={termSet}
                                />
                            }
                            id="supplierId"
                            value={fields.supplierId || ''}
                            formMethods={formMethods}
                            onChange={handleFieldChange}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <NumericInput
                            fullWidth
                            label={
                                <TranslatableText
                                    termKey={BuildingsFormDefs.Customer_Code}
                                    termSet={termSet}
                                />
                            }
                            id="customerId"
                            value={fields.customerId || ''}
                            formMethods={formMethods}
                            onChange={handleFieldChange}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextInput
                            fullWidth
                            label={
                                <TranslatableText
                                    termKey={BuildingsFormDefs.Note}
                                    termSet={termSet}
                                />
                            }
                            id="note"
                            value={fields.note}
                            onChange={handleFieldChange}
                            maxLength={300}
                            multiline
                            rows={4}
                        />
                    </Grid>
                </Grid>
            </FormPartial>
            <FormPartial
                title={
                    (
                        <TranslatableText
                            termKey={BuildingsFormDefs.Address_Information}
                            termSet={termSet}
                        />
                    ) as unknown as string
                }>
                <Grid container spacing={2}>
                    <AddressInput
                        fields={addressFields}
                        formMethods={addressFormMethods}
                        termSet={termSet}
                        setValues={setAddressValues}
                        handleFieldChange={handleAddressFieldChange}
                        id={id}
                    />
                </Grid>
            </FormPartial>
        </BaseForm>
    );
};

export default BuildingsForm;
