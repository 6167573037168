import CreateZone from './CreateZone';
import React from 'react';
import { PermissionsUtil } from '../../utils/permissions/permissionsUtil';
import { PERMISSIONS } from '../../constants/permissions/Permissions.constants';
import AgGrid from '../../components/grids/Grid';
import { AgGridColumn } from 'ag-grid-react';
import DatacorLogoSpinner from '../../components/datacorLogoSpinner/DatacorLogoSpinner';
import useZonesGrid from './hooks/useZonesGrid';

const ZonesGrid = () => {
    const {
        onGridReady,
        onColumnResize,
        handleCreateZoneModalOpen,
        handleCreateZoneModalClose,
        showCreateZoneModal,
        zones,
        isLoadingZones,
        user,
        TruncatedTooltipColumn,
    } = useZonesGrid();

    return (
        <>
            <CreateZone
                show={showCreateZoneModal}
                handleClose={handleCreateZoneModalClose}
            />
            {!isLoadingZones ? (
                <AgGrid
                    testId="zone-list-grid"
                    pagination={true}
                    paginationPageSize={10}
                    rowData={zones}
                    immutableData={true}
                    dataKey="zoneId"
                    domLayout={'autoHeight'}
                    onCreateNew={handleCreateZoneModalOpen}
                    displayCreateNewButton={PermissionsUtil.isPermissionEnabled(
                        user.permissions,
                        PERMISSIONS.USER_PORTAL.COMPANIES.CREATE
                    )}
                    displayToolbar={true}
                    onGridReady={onGridReady}
                    onColumnResized={onColumnResize}
                    frameworkComponents={{
                        truncatedTooltipColumn: TruncatedTooltipColumn,
                    }}
                    defaultColDef={{
                        resizable: true,
                        sortable: true,
                        unSortIcon: true,
                        filter: true,
                        wrapText: true,
                    }}>
                    <AgGridColumn
                        flex={1}
                        field="zoneName"
                        headerName="Zone Name"
                        cellRenderer="truncatedTooltipColumn"
                    />
                    <AgGridColumn
                        flex={2}
                        field="zoneId"
                        headerName="Zone ID"
                        cellRenderer="truncatedTooltipColumn"
                    />
                    <AgGridColumn
                        flex={3}
                        field="companyID"
                        headerName="Company ID"
                        cellRenderer="truncatedTooltipColumn"
                    />
                    <AgGridColumn minWidth={150} field="url" headerName="Url" />
                    <AgGridColumn
                        minWidth={250}
                        field="port"
                        headerName="Port"
                    />
                </AgGrid>
            ) : (
                <DatacorLogoSpinner />
            )}
        </>
    );
};

export default ZonesGrid;
