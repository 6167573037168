import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import { useNavigate } from 'react-router-dom';
import { PermissionsUtil } from '../../../../utils/permissions/permissionsUtil';
import { PERMISSIONS } from '../../../../constants/permissions/Permissions.constants';
import {
    useGetTermSetQuery,
    useListCurrencyRateTypesQuery,
    useUpdateListCurrencyRateTypesMutation,
} from '../../../../services/i18n/i18n.service';
import React, { useContext, useEffect, useState } from 'react';
import SettingsContext from '../../../../contexts/settings.context';
import { ACCOUNTING_DEFS } from '../../../../constants/i18n/translations/termSetDefinitions/accounting';
import { skipToken } from '@reduxjs/toolkit/query';
import { isNilOrEmpty } from '../../../../utils/objectUtils';
import { ColDef } from 'ag-grid-community';
import DefaultColumnTypes from '../../../../components/grids/columns/Column.constants';
import CustomHeader from '../../../../components/grids/CustomHeader';
import { CurrencyRateTypeGridDefs } from '../../../../constants/i18n/translations/termDefinitions/accounting';
import { RoutingValues } from '../../../../types/Shared.types';
import { CurrencyRateType } from '../../../../types/platform/i18n/Currency.type';
import _ from 'lodash';
import { getMaxSortOrderValue } from '../../../../store/accounting';
import { auditableGridColumns } from '../../../grid/utils/auditableGrid/auditableGridColumns';

export const UseCurrencyRateTypeGrid = () => {
    const dispatch = useDispatch();
    const user = useSelector((state: RootState) => state.user);
    const navigate = useNavigate();

    const { data: rateTypeList, isLoading: isLoadingRateTypes } =
        useListCurrencyRateTypesQuery();
    const [updateRateType] = useUpdateListCurrencyRateTypesMutation();

    const [colDefs, setColDefs] = useState([]);

    const { settings } = useContext(SettingsContext);
    const { data: termSet, isLoading: isLoadingTermSet } = useGetTermSetQuery(
        settings?.userSettings
            ? {
                  languageId: settings?.userSettings?.languageId,
                  code: ACCOUNTING_DEFS.CURRENCY_RATE_TYPE_GRID,
              }
            : skipToken
    );

    const [hiddenIdColumns, auditableColumns] = auditableGridColumns();

    const canView = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.ACCOUNTING.CURRENCY_RATE_TYPES.VIEW
    );

    const canCreate = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.ACCOUNTING.CURRENCY_RATE_TYPES.CREATE
    );

    const canUpdate = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.ACCOUNTING.CURRENCY_RATE_TYPES.EDIT
    );

    const handleGridEdits = (editedRows: CurrencyRateType[]) => {
        updateRateType(editedRows);
    };

    useEffect(() => {
        if (!isNilOrEmpty(termSet)) {
            const rateTypeColumnDefs: ColDef[] = [
                ...hiddenIdColumns,
                {
                    field: 'sortOrder',
                    ...DefaultColumnTypes.NumberOnly,
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={CurrencyRateTypeGridDefs.SortOrder}
                            />
                        );
                    },
                },
                {
                    field: 'code',
                    ...DefaultColumnTypes.ShortText,
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={CurrencyRateTypeGridDefs.Code}
                            />
                        );
                    },
                },
                {
                    field: 'description',
                    ...DefaultColumnTypes.LongText,
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={CurrencyRateTypeGridDefs.Description}
                            />
                        );
                    },
                },
                ...auditableColumns,
            ];
            setColDefs(rateTypeColumnDefs);
        }
    }, [termSet]);

    useEffect(() => {
        let maxValue = 1;
        if (rateTypeList && rateTypeList.length > 0) {
            const data = _.maxBy(
                rateTypeList,
                (rateType: CurrencyRateType) => rateType.sortOrder
            );
            maxValue = data.sortOrder + 1;
        }
        dispatch(
            getMaxSortOrderValue({ isLoading: false, maxValue: maxValue })
        );
    }, rateTypeList);

    const redirectToForm = (row: any) => {
        if (row.id) {
            navigate(`/accounting/currencyRateType/${row.id}`);
        } else {
            navigate(`/accounting/currencyRateType/${RoutingValues.newId}`);
        }
    };

    return {
        rateTypeList,
        isLoadingRateTypes,
        canView,
        canCreate,
        canUpdate,
        redirectToForm,
        handleGridEdits,
        colDefs,
    };
};

export default UseCurrencyRateTypeGrid;
