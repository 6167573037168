import FormPartial from '../form/formContainer/FormPartial';
import { isNilOrEmpty } from '../../utils/objectUtils';
import { Grid } from '@mui/material';
import React from 'react';
import RenderAppliedTemplate from './RenderAppliedTemplate';
import RenderUnappliedDefinitions from './RenderUnappliedDefinitions';
import RenderUnappliedTemplateHeader from './RenderUnappliedTemplateHeader';

const EntityAttributesSection = ({
    id,
    fields,
    handleFieldChange,
    formMethods,
    activeTemplates,
    isLoadingTemplates,
    attrFieldsDisabled,
    canUpdateParent,
    handleApplyTemplate,
    formDefs,
    attrValues,
    setAttrValues,
    setIsAttributeValid,
    entityAttributes,
    title,
    profiles,
}: any) => {
    const hasTemplateApplied = !isNilOrEmpty(profiles);
    return (
        <FormPartial
            title={`${title} ${
                hasTemplateApplied ? '- ' + profiles[0]?.attrTemplate?.name : ''
            }`}>
            {!hasTemplateApplied && (
                <RenderUnappliedTemplateHeader
                    id={id}
                    canUpdateParent={canUpdateParent}
                    fields={fields}
                    handleFieldChange={handleFieldChange}
                    formMethods={formMethods}
                    activeTemplates={activeTemplates}
                    isLoadingTemplates={isLoadingTemplates}
                    attrFieldsDisabled={attrFieldsDisabled}
                    handleApplyTemplate={handleApplyTemplate}
                />
            )}
            <div style={{ padding: '10px' }} />
            {fields?.attrTemplateId && !isNilOrEmpty(activeTemplates) && (
                <Grid container spacing={2}>
                    {hasTemplateApplied && formDefs ? (
                        <RenderAppliedTemplate
                            formDefs={formDefs}
                            fields={fields}
                            handleFieldChange={handleFieldChange}
                            attrValues={attrValues}
                            setAttrValues={setAttrValues}
                            formMethods={formMethods}
                            attrFieldsDisabled={attrFieldsDisabled}
                            setIsAttributeValid={setIsAttributeValid}
                            entityAttributes={entityAttributes}
                        />
                    ) : (
                        <RenderUnappliedDefinitions
                            activeTemplates={activeTemplates}
                            fields={fields}
                            handleFieldChange={handleFieldChange}
                            attrValues={attrValues}
                            setAttrValues={setAttrValues}
                            formMethods={formMethods}
                            attrFieldsDisabled={attrFieldsDisabled}
                            setIsAttributeValid={setIsAttributeValid}
                        />
                    )}
                </Grid>
            )}
        </FormPartial>
    );
};

export default EntityAttributesSection;
