import React from 'react';
import useAccountFunctionCodeGrid from './hooks/useAccountFunctionCodeGrid';
import BaseFormGrid from '../../components/grids/BaseFormGrid';
import { useParams } from 'react-router-dom';
import { ParamTypes } from '../../components/form/baseForm/BaseForm';
import { FunctionGroupValues } from '../../types/Shared.types';

const AccountFunctionCodeGrid = () => {
    const { group } = useParams<ParamTypes>();
    const {
        rowData,
        colDefs,
        isLoadingGrid,
        canView,
        redirectToForm,
        AccountNumberColumn,
        AccountNameColumn,
    } = useAccountFunctionCodeGrid(
        group ? group : FunctionGroupValues.accounting
    );

    return (
        <BaseFormGrid
            displayGrid={canView}
            isLoading={isLoadingGrid}
            rowData={rowData}
            columnDefs={colDefs}
            gridClass="full-size-item"
            displayToolbar={true}
            displayCreateNewButton={false}
            displayExportDataButton={true}
            displayResetStateButton={true}
            onRowClicked={(row) => redirectToForm(row.data.id)}
            defaultColDef={{
                resizable: true,
                sortable: false,
                filter: true,
                wrapText: true,
                floatingFilter: true,
            }}
            frameworkComponents={{
                accountNumberColumn: AccountNumberColumn,
                accountNameColumn: AccountNameColumn,
            }}
        />
    );
};

export default AccountFunctionCodeGrid;
