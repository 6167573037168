import React, { useState, useEffect } from 'react';
import { useFormFields } from '../../../libs/hooksLib';
import {
    useCreateZoneMutation,
    useGetCompaniesByUserIdQuery,
} from '../../../services/users/users.service';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { showNotificationError } from '../../../libs/errorLib';

const useCreateZone = ({ handleClose }: any) => {
    const dispatch = useDispatch();
    const user = useSelector((state: RootState) => state.user);
    const [createZone] = useCreateZoneMutation();
    const { data: companyList } = useGetCompaniesByUserIdQuery(user.userId);
    const [createZoneState, setCreateZoneState] = useState({
        selectedCompany: '',
        companyId: '',
        isLoading: false,
        isSubmitting: false,
    });
    const { isSubmitting: isFormSubmitting } = createZoneState;
    const [fields, handleFieldChange] = useFormFields({
        id: '',
        name: '',
        url: '',
        port: '',
    });

    useEffect(() => {
        setCreateZoneState((prevState) => ({
            ...prevState,
            selectedCompany: user.companyId,
            companyId: user.companyId,
        }));
    }, [user]);

    useEffect(() => {
        if (isFormSubmitting) {
            if (validateForm()) {
                handleCreateZone();
            }
            setCreateZoneState((prevState) => ({
                ...prevState,
                isSubmitting: false,
            }));
        }
    }, [isFormSubmitting]);

    const handleCreateZone = async () => {
        setCreateZoneState((prevState) => ({
            ...prevState,
            isLoading: true,
        }));
        try {
            const request = {
                zone: {
                    zoneId: fields.id,
                    zoneName: fields.name,
                    companyID: createZoneState.companyId,
                    port: fields.port,
                    url: fields.url,
                },
                companyId: createZoneState.companyId,
            };

            createZone(request);

            handleReset();
            handleClose(true);
        } catch (e) {
            showNotificationError(e, 'error', dispatch);
        }
        setCreateZoneState((prevState) => ({
            ...prevState,
            isLoading: false,
        }));
    };

    const handleReset = () => {
        fields.id = '';
        fields.name = '';
        fields.url = '';
        fields.port = '';
    };

    const setZoneId = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.target.value = event.target.value.replace(/ /g, '-');
        handleFieldChange(event);
    };

    const validateForm = () => {
        return (
            fields.id.length > 0 &&
            fields.name.length > 0 &&
            fields.url.length > 0 &&
            fields.port.length > 0
        );
    };
    return {
        fields,
        createZoneState,
        companyList,
        handleFieldChange,
        setCreateZoneState,
        validateForm,
        setZoneId,
    };
};

export default useCreateZone;
