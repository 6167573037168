import React, { useContext, useEffect, useState } from 'react';
import {
    SdsHeader,
    SdsOtherInformation,
} from '../../../../../../../types/formulation';
import { skipToken } from '@reduxjs/toolkit/query';
import { useForm } from '../../../../../../../libs/hooksLib';
import { ChildFormProps } from '../../../../../../form/helpers/ChildFormProps';
import SettingsContext from '../../../../../../../contexts/settings.context';
import { useGetTermSetQuery } from '../../../../../../../services/i18n/i18n.service';
import { FORMULATION_DEFS } from '../../../../../../../constants/i18n/translations/termSetDefinitions/formulation';
import {
    useGetFlammabilityForDropdownQuery,
    useGetHealthHazardForDropdownQuery,
    useGetPhysicalHazardForDropdownQuery,
    useGetProtectiveEquipmentForDropdownQuery,
    useGetReactivityForDropdownQuery,
    useGetSpecificHazardForDropdownQuery,
} from '../../../../../../../services/formulation/sds/sections/sdsOtherInformation.service';
import { isNilOrEmpty } from '../../../../../../../utils/objectUtils';

const useSdsOtherInformationForm = (props: ChildFormProps<SdsHeader>) => {
    const { currentParentRecord, isParentLoading, dataFromQuery } =
        props.parentData;

    const { handleChildRecord, registerChildForm } = props.helpers;

    const blankSdsOtherInformation: SdsOtherInformation = {
        sdsId: null,
        hmisChronic: null,
        webAddress: null,
        hmisHealthId: null,
        hmisFlammabilityId: null,
        hmisPhysicalHazardId: null,
        hmisEquipmentId: null,
        nfpaHealthId: null,
        nfpaFlammabilityId: null,
        nfpaReactivityId: null,
        nfpaSpecificHazardId: null,
    };

    const initialState: any = {
        selectedHmisHealth: null,
        selectedHmisFlammability: null,
        selectedHmisPhysicalHazard: null,
        selectedHmisEquipment: null,
        selectedNfpaHealth: null,
        selectedNfpaFlammability: null,
        selectedNfpaReactivity: null,
        selectedNfpaSpecificHazard: null,
    };

    const [dropdownOptions, setDropdownOptions] = useState(initialState);

    const { fields, handleFieldChange, setValues, verifyChanges, formMethods } =
        useForm(blankSdsOtherInformation, true);

    const { childForm } = verifyChanges;

    const { setOriginalEntity, clearChangesHistory, hasCurrentRecordChanged } =
        childForm;

    const { data: healthHazardsList, isLoading: isLoadingHealthHazards } =
        useGetHealthHazardForDropdownQuery();
    const { data: physicalHazardsList, isLoading: isLoadingPhysicalHazards } =
        useGetPhysicalHazardForDropdownQuery();
    const { data: flammabilityList, isLoading: isLoadingFlammability } =
        useGetFlammabilityForDropdownQuery();
    const { data: equipmentList, isLoading: isLoadingEquipment } =
        useGetProtectiveEquipmentForDropdownQuery();
    const { data: reactivityList, isLoading: isLoadingReactivity } =
        useGetReactivityForDropdownQuery();
    const { data: specificHazardsList, isLoading: isLoadingSpecificHazards } =
        useGetSpecificHazardForDropdownQuery();

    const { settings } = useContext(SettingsContext);
    const { data: termSet } = useGetTermSetQuery(
        settings?.userSettings
            ? {
                  languageId: settings?.userSettings?.languageId,
                  code: FORMULATION_DEFS.SDS_FORM,
              }
            : skipToken
    );

    const handlePropertyChanged = (event: any) => {
        handleFieldChange(event);
        handleChildRecord('sdsOtherInformation', event);
    };

    useEffect(() => {
        if (
            !isParentLoading &&
            !isLoadingHealthHazards &&
            !isLoadingPhysicalHazards &&
            !isLoadingEquipment &&
            !isLoadingReactivity &&
            !isLoadingFlammability &&
            !isLoadingSpecificHazards
        ) {
            setOriginalEntity(
                dataFromQuery?.sdsOtherInformation || blankSdsOtherInformation
            );
            setValues(
                currentParentRecord?.sdsOtherInformation ||
                    blankSdsOtherInformation
            );
        }
    }, [
        currentParentRecord?.sdsOtherInformation,
        isParentLoading,
        dataFromQuery,
        isLoadingHealthHazards,
        isLoadingPhysicalHazards,
        isLoadingEquipment,
        isLoadingReactivity,
        isLoadingFlammability,
        isLoadingSpecificHazards,
    ]);

    useEffect(() => {
        if (!isParentLoading) {
            registerChildForm('sdsOtherInformation', {
                formMethods,
                clearChangesHistory,
                hasEntityChanges: hasCurrentRecordChanged,
            });
        }
    }, [fields, isParentLoading]);

    /**
     * Set the labels on all the dropdowns
     */
    useEffect(() => {
        if (
            !isParentLoading &&
            !isLoadingHealthHazards &&
            !isLoadingPhysicalHazards &&
            !isLoadingEquipment &&
            !isLoadingReactivity &&
            !isLoadingFlammability &&
            !isLoadingSpecificHazards &&
            !isNilOrEmpty(specificHazardsList) &&
            !isNilOrEmpty(flammabilityList) &&
            !isNilOrEmpty(reactivityList) &&
            !isNilOrEmpty(equipmentList) &&
            !isNilOrEmpty(physicalHazardsList) &&
            !isNilOrEmpty(healthHazardsList)
        ) {
            setDropdownOptions((previousValues: any) => ({
                ...previousValues,
                selectedHmisHealth: healthHazardsList?.find(
                    (current: any) => current.value === fields.hmisHealthId
                ),
                selectedNfpaHealth: healthHazardsList?.find(
                    (current: any) => current.value === fields.nfpaHealthId
                ),
                selectedHmisPhysicalHazard: physicalHazardsList?.find(
                    (current: any) =>
                        current.value === fields.hmisPhysicalHazardId
                ),
                selectedNfpaSpecificHazard: specificHazardsList?.find(
                    (current: any) =>
                        current.value === fields.nfpaSpecificHazardId
                ),
                selectedHmisFlammability: flammabilityList?.find(
                    (current: any) =>
                        current.value === fields.hmisFlammabilityId
                ),
                selectedNfpaFlammability: flammabilityList?.find(
                    (current: any) =>
                        current.value === fields.nfpaFlammabilityId
                ),
                selectedHmisEquipment: equipmentList?.find(
                    (current: any) => current.value === fields.hmisEquipmentId
                ),
                selectedNfpaReactivity: reactivityList?.find(
                    (current: any) => current.value === fields.nfpaReactivityId
                ),
            }));
        }
    }, [
        isParentLoading,
        fields,
        isLoadingHealthHazards,
        isLoadingPhysicalHazards,
        isLoadingEquipment,
        isLoadingReactivity,
        isLoadingFlammability,
        isLoadingSpecificHazards,
    ]);

    return {
        fields,
        handlePropertyChanged,
        dropdownOptions,
        formMethods,
        termSet,
        isLoadingHealthHazards,
        isLoadingPhysicalHazards,
        isLoadingEquipment,
        isLoadingReactivity,
        isLoadingFlammability,
        isLoadingSpecificHazards,
        specificHazardsList,
        flammabilityList,
        reactivityList,
        equipmentList,
        physicalHazardsList,
        healthHazardsList,
        setDropdownOptions,
    };
};
export default useSdsOtherInformationForm;
