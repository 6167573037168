import {
    BaseCreateEntityArgs,
    BaseUpdateEntityArgs,
} from '../../../serviceInterfaces';
import { RDFormula } from '../../../../types/formulation';
import { datacorAppApi } from '../../../datacorAppApi';

const serviceUrl: string = 'rdFormula';
const sequenceServiceUrl: string = `${serviceUrl}/rdFormulaHeader/rdFormulaSequences`;

export const rdFormulaApi = datacorAppApi.injectEndpoints({
    endpoints: (build) => ({
        createFormula: build.mutation<
            RDFormula,
            BaseCreateEntityArgs<RDFormula>
        >({
            query: (args) => ({
                url: serviceUrl,
                method: 'POST',
                body: args.postBody,
            }),
            invalidatesTags: ['Formula'],
        }),
        updateFormula: build.mutation<
            RDFormula,
            BaseUpdateEntityArgs<RDFormula>
        >({
            query: (args) => ({
                url: `${serviceUrl}/${args.id}`,
                method: 'PUT',
                body: args.postBody,
            }),
            invalidatesTags: ['Formula', 'ActiveFormula'],
        }),
        deleteFormula: build.mutation<RDFormula, string>({
            query: (id) => ({
                url: `${serviceUrl}/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Formula'],
        }),
        getFormula: build.query<RDFormula, string>({
            query: (id) => {
                return {
                    url: `${serviceUrl}/${id}`,
                    params: { full: 'true' },
                };
            },
            providesTags: ['ActiveFormula'],
        }),
        listFormula: build.query<RDFormula[], void>({
            query: () => {
                return {
                    url: serviceUrl,
                };
            },
            providesTags: ['Formula'],
        }),
        listFormulaForPopup: build.query<any, void>({
            query: () => {
                return {
                    url: serviceUrl,
                };
            },
            transformResponse: (response: RDFormula[], meta: any, arg: any) => {
                return response.map((rdFormula: RDFormula) => {
                    return {
                        value: rdFormula.id,
                        label: rdFormula.formulaCode,
                        formulaDescription: rdFormula.formulaDescription,
                    };
                });
            },
            providesTags: ['Formula'],
        }),
        getRDFormulaHeaderSequenceParameters: build.query<any, void>({
            query: () => {
                return {
                    url: `${sequenceServiceUrl}/parameters`,
                };
            },
            providesTags: ['RDFormulaSequence'],
        }),
        getRDFormulaHeaderSequence: build.query<any, void>({
            query: () => {
                return {
                    url: `${sequenceServiceUrl}`,
                };
            },
            providesTags: ['RDFormulaSequence'],
        }),
        updateRDFormulaHeaderSequence: build.mutation<any, any>({
            query: (args: any) => {
                console.log(args);
                return {
                    url: `${sequenceServiceUrl}/${args.id}`,
                    method: 'PUT',
                    body: args.postBody,
                };
            },
            invalidatesTags: ['RDFormulaSequence'],
        }),
        listRDFormulaHeaderSequenceParents: build.query<any, void>({
            query: () => {
                return {
                    url: `${sequenceServiceUrl}/parents`,
                };
            },
            providesTags: ['RDFormulaSequence'],
        }),
    }),
});

export const {
    useCreateFormulaMutation,
    useDeleteFormulaMutation,
    useUpdateFormulaMutation,
    useGetFormulaQuery,
    useListFormulaQuery,
    useListFormulaForPopupQuery,
    useUpdateRDFormulaHeaderSequenceMutation,
    useGetRDFormulaHeaderSequenceParametersQuery,
    useGetRDFormulaHeaderSequenceQuery,
    useListRDFormulaHeaderSequenceParentsQuery,
} = rdFormulaApi;
