import { ItemMaster } from '../../../../../../types/formulation';
import FormPartial from '../../../../../../components/form/formContainer/FormPartial';
import { Grid } from '@mui/material';
import TextInput from '../../../../../../components/form/formInputs/TextInput/TextInput';
import React from 'react';
import DatacorLogoSpinner from '../../../../../../components/datacorLogoSpinner/DatacorLogoSpinner';
import { ExtendedFormProps } from '../../../../../form/helpers/ExtendedFormProps';
import NumericInput from '../../../../../../components/form/formInputs/NumericInput/NumericInput';
import SelectInput from '../../../../../../components/form/formInputs/SelectInput/SelectInput';
import { ItemMasterFormDefs } from '../../../../../../constants/i18n/translations/termDefinitions/formulation';
import TranslatableText from '../../../../../../components/i18n/TranslatableText';

export interface itemHeaderFormProps extends ExtendedFormProps<ItemMaster> {
    statusOptions: any;
    isLoadingStatuses: boolean;
    inventoryTypesOptions: any;
    isLoadingInventoryTypes: boolean;
    termSet?: any;
}

const ItemHeaderForm = (props: itemHeaderFormProps) => {
    const { currentParentRecord, isParentLoading } = props.parentData;
    const { formMethods, handleFieldChange } = props.helpers;
    const { readOnly } = props.permissions;

    return (
        <FormPartial>
            {isParentLoading ? (
                <DatacorLogoSpinner />
            ) : (
                <>
                    <Grid container spacing={2}>
                        <Grid item xs={3}>
                            <TextInput
                                fullWidth
                                id="itemId"
                                value={currentParentRecord.itemId}
                                label={
                                    <TranslatableText
                                        termSet={props.termSet}
                                        termKey={ItemMasterFormDefs.Item_ID}
                                    />
                                }
                                maxLength={20}
                                formMethods={formMethods}
                                required={true}
                                onChange={handleFieldChange}
                                disabled={readOnly}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextInput
                                fullWidth
                                id="itemName"
                                value={currentParentRecord.itemName}
                                label={
                                    <TranslatableText
                                        termSet={props.termSet}
                                        termKey={ItemMasterFormDefs.Item_Name}
                                    />
                                }
                                maxLength={20}
                                formMethods={formMethods}
                                required={true}
                                onChange={handleFieldChange}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <NumericInput
                                fullWidth
                                id="version"
                                label={
                                    <TranslatableText
                                        termSet={props.termSet}
                                        termKey={ItemMasterFormDefs.Version}
                                    />
                                }
                                value={currentParentRecord.version}
                                formMethods={formMethods}
                                onChange={handleFieldChange}
                                greaterThan={0}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextInput
                                fullWidth
                                id="description1"
                                value={currentParentRecord.description1}
                                label={
                                    <TranslatableText
                                        termSet={props.termSet}
                                        termKey={
                                            ItemMasterFormDefs.Description_one
                                        }
                                    />
                                }
                                maxLength={50}
                                formMethods={formMethods}
                                onChange={handleFieldChange}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextInput
                                fullWidth
                                id="description2"
                                value={currentParentRecord.description2}
                                label={
                                    <TranslatableText
                                        termSet={props.termSet}
                                        termKey={
                                            ItemMasterFormDefs.Description_two
                                        }
                                    />
                                }
                                maxLength={50}
                                formMethods={formMethods}
                                onChange={handleFieldChange}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <SelectInput
                                fullWidth
                                label={
                                    <TranslatableText
                                        termKey={ItemMasterFormDefs.Status}
                                        termSet={props.termSet}
                                    />
                                }
                                id="itemStatusId"
                                value={currentParentRecord.itemStatusId}
                                onChange={handleFieldChange}
                                formMethods={formMethods}
                                options={props.statusOptions}
                                disabled={props.isLoadingStatuses}
                                required={true}
                                SelectProps={{
                                    readOnly: readOnly,
                                }}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <SelectInput
                                fullWidth
                                label={
                                    <TranslatableText
                                        termSet={props.termSet}
                                        termKey={
                                            ItemMasterFormDefs.Inventory_Type
                                        }
                                    />
                                }
                                id="inventoryTypeId"
                                value={currentParentRecord.inventoryTypeId}
                                onChange={handleFieldChange}
                                formMethods={formMethods}
                                options={props.inventoryTypesOptions}
                                disabled={props.isLoadingInventoryTypes}
                                required={true}
                                SelectProps={{
                                    readOnly: readOnly,
                                }}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TextInput
                                fullWidth
                                id="upc"
                                value={currentParentRecord.upc}
                                label={
                                    <TranslatableText
                                        termSet={props.termSet}
                                        termKey={ItemMasterFormDefs.UPC}
                                    />
                                }
                                maxLength={12}
                                formMethods={formMethods}
                                onChange={handleFieldChange}
                            />
                        </Grid>
                        <Grid item xs={3}></Grid>
                    </Grid>
                </>
            )}
        </FormPartial>
    );
};

export default ItemHeaderForm;
