import { useSelector } from 'react-redux';
import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { GridReadyEvent } from 'ag-grid-community';
import {
    useGetUsersBySubscriberQuery,
    useGetUsersQuery,
} from '../../../services/organizations/organizations.service';
import { RootState } from '../../../store';
import { PermissionsUtil } from '../../../utils/permissions/permissionsUtil';
import { PERMISSIONS } from '../../../constants/permissions/Permissions.constants';
import SettingsContext from '../../../contexts/settings.context';
import { useGetTermSetQuery } from '../../../services/i18n/i18n.service';
import { skipToken } from '@reduxjs/toolkit/query';
import { PLATFORM_DEFS } from '../../../constants/i18n/translations/termSetDefinitions/platform';

const useUsersGrid = () => {
    const navigate = useNavigate();
    const { administration } = useSelector((state: RootState) => state.grids);
    const user = useSelector((state: RootState) => state.user);
    const [gridApi, setGridApi] = useState(null);
    const { data: rowData, isLoading: isLoading } = useGetUsersQuery();
    const { settings } = useContext(SettingsContext);
    const [sortColumn, setSortColumn] = useState(null);

    const { data: termSet } = useGetTermSetQuery(
        settings?.userSettings
            ? {
                  languageId: settings?.userSettings?.languageId,
                  code: PLATFORM_DEFS.USER_GRID,
              }
            : skipToken
    );
    const { data: subscriberUsers, isLoading: isLoadingSubscriberUsers } =
        useGetUsersBySubscriberQuery();

    const [gridColumnApi, setGridColumnApi] = useState(null);

    const canCreateUser = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.ADMINISTRATION.USERS.CREATE
    );

    const onSortChanged = (event: any) => {
        const sortModel = event.api.getSortModel();
        if (sortModel.length > 0) {
            setSortColumn(sortModel[0].colId);
        } else {
            setSortColumn(null);
        }
    };

    const onGridReady = (params: GridReadyEvent) => {
        setGridApi(params.api);
        setGridColumnApi(params.columnApi);
    };

    const redirectToForm = (row: any) => {
        if (row.id) {
            navigate(`/administration/organizations/users/${row.id}`);
        } else {
            navigate('/administration/organizations/users/new');
        }
    };

    const onFirstDataRendered = () => {
        gridColumnApi?.applyColumnState({
            state: administration.usersList.column,
            applyOrder: true,
        });

        gridApi?.setFilterModel(administration.usersList.filter);
    };

    return {
        onGridReady,
        rowData,
        subscriberUsers,
        isLoading,
        isLoadingSubscriberUsers,
        redirectToForm,
        onFirstDataRendered,
        canCreateUser,
        termSet,
        onSortChanged,
    };
};

export default useUsersGrid;
