import React from 'react';
import ExposureAgencyGrid from './ExposureAgencyGrid';

const ExposureAgency = () => {
    return (
        <>
            <ExposureAgencyGrid />
        </>
    );
};

export default ExposureAgency;
