import React from 'react';
import usePermissionTree from './hooks/usePermissionTree';
import TreeView from '../../components/treeView/TreeView';
import DatacorLogoSpinner from '../../components/datacorLogoSpinner/DatacorLogoSpinner';
import { useParams } from 'react-router-dom';
import BaseForm from '../../components/form/baseForm/BaseForm';
import FormPartial from '../../components/form/formContainer/FormPartial';
import {
    PERMISSION_FIELDS,
    REVISION_ENTITY_TYPES,
    USER_FIELDS,
} from '../../constants/revisionHistory/revisionHistory.constants';

type ParamTypes = { id: string };

const PermissionTree = () => {
    const { id } = useParams<ParamTypes>();
    const {
        permissions,
        role,
        treeData,
        isLoading,
        formMethods,
        onUpdate,
        closeForm,
        onCheckChange,
    } = usePermissionTree(id);

    return (
        <>
            {!isLoading ? (
                <BaseForm
                    title={'Permissions'}
                    formMethods={formMethods}
                    isLoading={isLoading}
                    showOnRevision={true}
                    entityUuid={role?.uuid}
                    fieldMappings={PERMISSION_FIELDS}
                    revisionEntityTypeId={REVISION_ENTITY_TYPES.PERMISSIONS}
                    isNew={false}
                    onCreate={null}
                    onUpdate={onUpdate}
                    onDelete={null}
                    displayUpdateButton={true}
                    displayDeleteButton={false}
                    onCancel={closeForm}>
                    <FormPartial>
                        <TreeView
                            dataSource={treeData}
                            onCheckChange={onCheckChange}
                            type="org"
                        />
                    </FormPartial>
                </BaseForm>
            ) : (
                <DatacorLogoSpinner />
            )}
        </>
    );
};

export default PermissionTree;
