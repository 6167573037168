import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    sortOrderSet: {
        isLoading: false,
        maxValue: 0,
    },
    fiscalYear: {
        isLoading: false,
        nextFiscalYear: new Date().getFullYear(),
        nextStartDate: new Date(new Date().getFullYear(), 0, 1),
        nextEndDate: new Date(new Date().getFullYear(), 11, 31),
        canAdjustStartDate: true,
    },
};

const slice = createSlice({
    name: 'accounting',
    initialState: initialState,
    reducers: {
        getMaxSortOrderValue: (state, action) => {
            state.sortOrderSet.maxValue = action.payload.maxValue;
            state.sortOrderSet.isLoading = action.payload.isLoading;
        },
        getNextFiscalYearValue: (state, action) => {
            state.fiscalYear.nextFiscalYear = action.payload.nextFiscalYear;
            state.fiscalYear.nextStartDate = action.payload.nextStartDate;
            state.fiscalYear.nextEndDate = action.payload.nextEndDate;
            state.fiscalYear.canAdjustStartDate =
                action.payload.canAdjustStartDate;
            state.fiscalYear.isLoading = action.payload.isLoading;
        },
    },
});

export default slice.reducer;

//TODO: specify actions for export
export const { getMaxSortOrderValue, getNextFiscalYearValue } = slice.actions;
export const actions = slice.actions;
