import React, { useContext, useEffect, useState } from 'react';
import { isNilOrEmpty } from '../../../../../../../utils/objectUtils';
import { ValueGetterParams } from 'ag-grid-community';
import { GridOptions } from '../../../../../../../components/grids/Grid.constants';
import { PermissionsUtil } from '../../../../../../../utils/permissions/permissionsUtil';
import { PERMISSIONS } from '../../../../../../../constants/permissions/Permissions.constants';
import {
    CasMaster,
    CasExposure,
    ExposureAgency,
} from '../../../../../../../types/formulation';
import _ from 'lodash';
import DefaultColumnTypes from '../../../../../../../components/grids/columns/Column.constants';
import { ColumnMaxValueValidator } from '../../../../../../grid/validators/columnMaxValue.validator';
import applyEditableGridValidations from '../../../../../../grid/utils/applyEditableGridValidations';
import { ColumnRequiredValueValidator } from '../../../../../../grid/validators/columnRequiredValue.validator';
import { ColumnNumericValueValidator } from '../../../../../../grid/validators/columnNumericValue.validator';
import { RowStatus } from '../../../../../../../components/grids/hooks/useBaseGridEditable';
import { ColumnMinValueValidator } from '../../../../../../grid/validators/columnMinValue.validator';
import { useListExposureAgencyQuery } from '../../../../../../../services/formulation/exposureAgency/exposureAgency.service';
import { SelectedDropdownOption } from '../../../../../../../types/Shared.types';
import { ChildEditableGridWithCopyFunctionProps } from '../../../../../../grid/utils/editableGrid/ChildEditableGridWithCopyFunctionProps';
import SettingsContext from '../../../../../../../contexts/settings.context';
import { useGetTermSetQuery } from '../../../../../../../services/i18n/i18n.service';
import { FORMULATION_DEFS } from '../../../../../../../constants/i18n/translations/termSetDefinitions/formulation';
import { skipToken } from '@reduxjs/toolkit/query';
import CustomHeader from '../../../../../../../components/grids/CustomHeader';
import { CasMasterFormDefs } from '../../../../../../../constants/i18n/translations/termDefinitions/formulation';

const useCasExposureGrid = (
    props: ChildEditableGridWithCopyFunctionProps<CasMaster, CasExposure>
) => {
    const { currentParentRecord, user } = props.parentData;

    const { showDeleteButton, isGridEditable } = props.displayGridButtons;

    const { handleChildrenRecords } = props.helpers;

    const { rowsData, setRowsData } = props.copyMethods;

    const canViewExposureAgency = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.FORMULATION.EXPOSURE_AGENCIES.VIEW
    );

    const {
        data: exposureAgencyOptions,
        isLoading: isLoadingExposureAgencies,
    } = useListExposureAgencyQuery();

    const [casExposuresColumnDefs, setCasExposuresColumnDefs] = useState(null);
    const [exposureAgencyColumnDefs, setExposureAgencyColumnDefs] =
        useState(null);
    const [maxRowId, setMaxRowId] = useState(0);

    const colDefOptions = {
        ...GridOptions.sortFilterAndWrapColumns,
        floatingFilter: true,
    };

    const { settings } = useContext(SettingsContext);
    const { data: termSet } = useGetTermSetQuery(
        settings?.userSettings
            ? {
                  languageId: settings?.userSettings?.languageId,
                  code: FORMULATION_DEFS.CAS_MASTER_FORM,
              }
            : skipToken
    );

    const getExposureAgencyCode = (
        exposureAgencyId: bigint
    ): SelectedDropdownOption => {
        let exposureAgencyCode: SelectedDropdownOption = {
            id: null,
            display: '',
        };
        if (!isNilOrEmpty(exposureAgencyId)) {
            exposureAgencyOptions?.find((current: ExposureAgency) => {
                if (current.id === exposureAgencyId) {
                    exposureAgencyCode = {
                        id: current.id,
                        display: current.code,
                    };
                }
            });
        }
        return exposureAgencyCode;
    };

    const getExposureDescription = (
        id: bigint,
        options: ExposureAgency[]
    ): string => {
        let description: any;
        options?.find((current: any) => {
            if (current.id === id) {
                description = current.description;
            }
        });
        return description;
    };

    const sortExposures = (casExposureRows: CasExposure[]) => {
        setRowsData(
            _.orderBy(
                casExposureRows,
                ['sortOrder', 'exposureAgency.code'],
                ['asc']
            )
        );
    };

    useEffect(() => {
        const includeEditedRows = currentParentRecord?.casExposures
            ? !isNilOrEmpty(
                  currentParentRecord.casExposures.find(
                      (current) => current.rowStatus
                  )
              )
            : false;
        if (
            currentParentRecord?.casExposures &&
            !isNilOrEmpty(exposureAgencyOptions) &&
            !isLoadingExposureAgencies &&
            !includeEditedRows
        ) {
            const casExposureRows: CasExposure[] = [];
            let index = maxRowId;
            currentParentRecord?.casExposures.forEach((record: CasExposure) => {
                index = index + 1;
                setMaxRowId((maxRowId) => maxRowId + 1);
                if (record.rowStatus !== RowStatus.DELETED) {
                    const exposureRecord = {
                        ...record,
                        exposureAgencyCode: getExposureAgencyCode(
                            record.exposureAgencyId
                        ),
                        rowId: record.rowId ? record.rowId : index,
                    };
                    casExposureRows.push(exposureRecord);
                }
            });
            sortExposures(casExposureRows);
        }
    }, [
        currentParentRecord?.casExposures,
        exposureAgencyOptions,
        isLoadingExposureAgencies,
    ]);

    useEffect(() => {
        if (
            !isLoadingExposureAgencies &&
            !isNilOrEmpty(exposureAgencyOptions)
        ) {
            setExposureAgencyColumnDefs([
                {
                    field: 'code',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={
                                    CasMasterFormDefs.Exposure_Limits_Agency_Selection_Exposure_Agency_Code
                                }
                            />
                        );
                    },
                    cellClass: 'ag-left-aligned-cell',
                    filter: 'agTextColumnFilter',
                },
                {
                    field: 'description',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={
                                    CasMasterFormDefs.Exposure_Limits_Agency_Selection_Description
                                }
                            />
                        );
                    },
                    cellClass: 'ag-left-aligned-cell',
                    filter: 'agTextColumnFilter',
                },
            ]);
        }
    }, [isLoadingExposureAgencies, exposureAgencyOptions]);

    useEffect(() => {
        if (
            !isNilOrEmpty(exposureAgencyColumnDefs) &&
            !isLoadingExposureAgencies &&
            !isNilOrEmpty(exposureAgencyOptions)
        ) {
            setCasExposuresColumnDefs([
                {
                    field: 'deleteColumn',
                    minWidth: 50,
                    hide: !showDeleteButton || !isGridEditable,
                    filter: false,
                },
                {
                    field: 'exposureAgencyCode',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={
                                    CasMasterFormDefs.Exposure_Limits_Agency_Code
                                }
                            />
                        );
                    },
                    minWidth: 200,
                    editable: canViewExposureAgency && isGridEditable,
                    cellRenderer: (params: any) => params.value.display,
                    useGridPopup: true,
                    gridPopupParameters: {
                        isLoading: isLoadingExposureAgencies,
                        displayGrid: true,
                        rowData: canViewExposureAgency
                            ? exposureAgencyOptions
                            : [],
                        defaultColDef: colDefOptions,
                        columnDefs: exposureAgencyColumnDefs,
                        displayField: 'code',
                    },
                    ...GridOptions.sortFilterAndWrapColumns,
                },
                {
                    field: 'exposureAgencyDescription',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={
                                    CasMasterFormDefs.Exposure_Limits_Description
                                }
                            />
                        );
                    },
                    minWidth: 200,
                    filter: 'agTextColumnFilter',
                    editable: false,
                    valueGetter: (params: ValueGetterParams) => {
                        params.data.description = getExposureDescription(
                            params.data.exposureAgencyCode?.id,
                            exposureAgencyOptions
                        );
                        return params.data.description;
                    },
                },
                {
                    field: 'exposureValue',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={
                                    CasMasterFormDefs.Exposure_Limits_Value
                                }
                            />
                        );
                    },
                    minWidth: 200,
                    filter: 'agTextColumnFilter',
                    editable: isGridEditable,
                },
                {
                    field: 'exposureForm',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={CasMasterFormDefs.Exposure_Limits_Form}
                            />
                        );
                    },
                    minWidth: 200,
                    filter: 'agTextColumnFilter',
                    editable: isGridEditable,
                },
                {
                    field: 'notes',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={
                                    CasMasterFormDefs.Exposure_Limits_Notes
                                }
                            />
                        );
                    },
                    filter: 'agTextColumnFilter',
                    editable: isGridEditable,
                    ...DefaultColumnTypes.LargeTextEditor,
                    minWidth: 200,
                },
            ]);
        }
    }, [
        exposureAgencyColumnDefs,
        isLoadingExposureAgencies,
        exposureAgencyOptions,
    ]);

    const handleRowValidations = (editedRows: CasExposure[]) => {
        editedRows?.forEach((row: CasExposure) => {
            row.validationRules = [
                ColumnRequiredValueValidator(
                    'Exposure Agency Code',
                    row.exposureAgencyCode
                ),
                ColumnMaxValueValidator(
                    'Exposure Value',
                    50,
                    row.exposureValue
                ),
                ColumnMaxValueValidator('Notes', 255, row.notes),
                ColumnMaxValueValidator('Exposure Form', 50, row.exposureForm),
                ColumnNumericValueValidator(
                    'Sort Order',
                    0,
                    Number(row.sortOrder)
                ),
                ColumnMinValueValidator('Sort Order', 0, Number(row.sortOrder)),
            ];
            const additionalMessage = row.exposureAgencyCode?.display
                ? `Exposure ${row.exposureAgencyCode?.display}`
                : null;
            applyEditableGridValidations(row, additionalMessage);
        });
        return editedRows;
    };

    const handleGridEdits = (editedRows: CasExposure[]) => {
        const updatedFields: CasExposure[] = [...rowsData];

        editedRows?.forEach((row: CasExposure) => {
            if (
                !isNilOrEmpty(row.exposureAgencyCode) &&
                !isNilOrEmpty(row.exposureAgencyCode?.id)
            ) {
                row.exposureAgencyId = row.exposureAgencyCode?.id;
            }

            const index = rowsData.findIndex(
                (casExposures: CasExposure) => casExposures.rowId === row.rowId
            );

            if (isNilOrEmpty(row.sortOrder)) {
                row.sortOrder = null;
            }

            if (index > -1) {
                updatedFields[index] = row;
            } else {
                updatedFields.push(row);
            }

            sortExposures(updatedFields);
            handleChildrenRecords('casExposures', updatedFields);
        });
    };

    return {
        isLoadingExposureAgencies,
        casExposuresColumnDefs,
        rowsData,
        handleGridEdits,
        handleRowValidations,
        colDefOptions,
        termSet,
    };
};

export default useCasExposureGrid;
