import React from 'react';
import BaseForm, { ParamTypes } from '../../components/form/baseForm/BaseForm';
import useAttributeDefinitionsForm from './hooks/useAttributeDefinitionsForm';
import { useParams } from 'react-router-dom';
import { Grid } from '@mui/material';
import TextInput from '../../components/form/formInputs/TextInput/TextInput';
import SelectInput from '../../components/form/formInputs/SelectInput/SelectInput';
import RenderDefaultValue from '../../components/attributes/RenderDefaultValue';
import { VALUE_TYPES } from '../../constants/attributes/attributes.constants';
import { ATTRIBUTE_DEFINITION_FIELDS } from '../../constants/revisionHistory/revisionHistory.constants';
import AttributeDefinitionsListOptionsGrid from './AttributeDefinitionsListOptionsGrid';

const AttributeDefinitionsForm = () => {
    const { id } = useParams<ParamTypes>();

    const {
        fields,
        handleFieldChange,
        formMethods,
        isLoadingDefinition,
        onCreate,
        onUpdate,
        onDelete,
        closeForm,
        handleChildrenRecords,
        attributeValueTypes,
        isLoadingValueTypes,
        canManageAttribute,
    } = useAttributeDefinitionsForm(id);

    return (
        <BaseForm
            title={'Attribute Definitions'}
            formMethods={formMethods}
            displayCreateButton={canManageAttribute}
            displayUpdateButton={canManageAttribute && !fields.locked}
            displayDeleteButton={canManageAttribute && !fields.locked}
            isLoading={isLoadingDefinition}
            isNew={!id}
            onCreate={onCreate}
            onUpdate={onUpdate}
            onDelete={onDelete}
            onCancel={closeForm}
            showOnRevision={true}
            fieldMappings={ATTRIBUTE_DEFINITION_FIELDS}
            entityUuid={fields?.uuid}
            readOnly={(id && !canManageAttribute) || fields.locked}>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <TextInput
                        fullWidth
                        label="Code"
                        id="code"
                        formMethods={formMethods}
                        value={fields.code}
                        onChange={handleFieldChange}
                        required={true}
                        maxLength={50}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextInput
                        fullWidth
                        label="Name"
                        id="name"
                        formMethods={formMethods}
                        value={fields.name}
                        onChange={handleFieldChange}
                        required={true}
                        maxLength={50}
                    />
                </Grid>
                <Grid item xs={6}>
                    <SelectInput
                        fullWidth
                        label="Value Type"
                        id="attrValueTypeId"
                        formMethods={formMethods}
                        value={fields.attrValueTypeId}
                        onChange={handleFieldChange}
                        options={attributeValueTypes}
                        disabled={isLoadingValueTypes || fields.locked}
                        required={true}
                    />
                </Grid>
                <Grid item xs={6}>
                    <RenderDefaultValue
                        handleFieldChange={handleFieldChange}
                        formMethods={formMethods}
                        value={fields.defaultValue}
                        attrValueTypeId={fields?.attrValueTypeId?.toString()}
                        optionsForDropdown={fields.optionsForDropdown}
                        isNew={!id}
                    />
                </Grid>
                {fields?.attrValueTypeId?.toString() === VALUE_TYPES.LIST && (
                    <Grid item xs={6}>
                        <AttributeDefinitionsListOptionsGrid
                            attributeDefinition={fields}
                            updateOptionList={handleChildrenRecords}
                        />
                    </Grid>
                )}
            </Grid>
        </BaseForm>
    );
};

export default AttributeDefinitionsForm;
