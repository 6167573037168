import React from 'react';
import { Grid } from '@mui/material';
import FormPartial from '../../../../../../components/form/formContainer/FormPartial';
import TextInput from '../../../../../../components/form/formInputs/TextInput/TextInput';
import DateInput from '../../../../../../components/form/formInputs/DateInput/DateInput';
import SelectInput from '../../../../../../components/form/formInputs/SelectInput/SelectInput';
import NumericInput from '../../../../../../components/form/formInputs/NumericInput/NumericInput';
import DatacorLogoSpinner from '../../../../../../components/datacorLogoSpinner/DatacorLogoSpinner';
import TextInputPopup from '../../../../../../components/form/formInputs/TextInputPopup/TextInputPopup';
import useRDFormulaHeaderForm from './hook/useRDFormulaHeaderForm';
import { RDFormula } from '../../../../../../types/formulation';
import { isNilOrEmpty } from '../../../../../../utils/objectUtils';
import { ExtendedFormProps } from '../../../../../form/helpers/ExtendedFormProps';
import TranslatableText from '../../../../../../components/i18n/TranslatableText';
import { RDFormulasFormDefs } from '../../../../../../constants/i18n/translations/termDefinitions/formulation';

export const RDFormulaHeaderForm = (props: ExtendedFormProps<RDFormula>) => {
    const {
        formulaStatusOptions,
        isLoadingFields,
        headerPermissions,
        itemMasterOptions,
        unitOfMeasureOptions,
        dropdownOptions,
        productCodeTextPopupColDefs,
        approvalTypeOptions,
        userList,
        getDefaultFormulaStatus,
        uomTextPopupColDefs,
        userTextPopupColDefs,
        termSet,
    } = useRDFormulaHeaderForm(props);

    const { currentParentRecord, isParentLoading } = props.parentData;

    const { formMethods, handleFieldChange } = props.helpers;

    const { canUpdate, canView } = props.permissions;

    const {
        isLoadingApprovalTypes,
        isLoadingFormulaStatus,
        isLoadingUserOptions,
        isLoadingUnitOfMeasure,
        isLoadingItemMasterOptions,
    } = isLoadingFields;

    const { canViewItemMaster, canViewUnitOfMeasure } = headerPermissions;

    /**
     * Remove the email address from the selected 'Approved By' value and return the label
     */
    const isReadOnly = !canUpdate && canView;

    return isParentLoading ||
        isLoadingUnitOfMeasure ||
        isLoadingApprovalTypes ||
        isLoadingItemMasterOptions ||
        isLoadingFormulaStatus ||
        isLoadingUserOptions ? (
        <DatacorLogoSpinner />
    ) : (
        <>
            <FormPartial>
                <Grid container spacing={2}>
                    <Grid item xs={4}>
                        <TextInput
                            fullWidth
                            label={
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={RDFormulasFormDefs.Formula_Code}
                                />
                            }
                            id="formulaCode"
                            formMethods={formMethods}
                            value={currentParentRecord.formulaCode}
                            onChange={handleFieldChange}
                            required={false}
                            maxLength={25}
                            disabled={
                                !isNilOrEmpty(currentParentRecord.id) ||
                                isReadOnly
                            }
                        />
                    </Grid>
                    <Grid item xs={8}>
                        <TextInput
                            fullWidth
                            label={
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={
                                        RDFormulasFormDefs.Formula_Description
                                    }
                                />
                            }
                            id="formulaDescription"
                            formMethods={formMethods}
                            value={currentParentRecord.formulaDescription}
                            onChange={handleFieldChange}
                            required={true}
                            maxLength={50}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <SelectInput
                            fullWidth
                            label={
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={RDFormulasFormDefs.Formula_Status}
                                />
                            }
                            id="formulaStatusId"
                            required={true}
                            value={
                                isNilOrEmpty(
                                    currentParentRecord.formulaStatusId
                                )
                                    ? getDefaultFormulaStatus()
                                    : currentParentRecord.formulaStatusId
                            }
                            onChange={handleFieldChange}
                            formMethods={formMethods}
                            options={formulaStatusOptions}
                            disabled={isReadOnly}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextInputPopup
                            fullWidth
                            id="itemId"
                            onChange={handleFieldChange}
                            label={
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={RDFormulasFormDefs.Product_Code}
                                />
                            }
                            formMethods={formMethods}
                            popupOptions={
                                canViewItemMaster ? itemMasterOptions || [] : []
                            }
                            colDefs={productCodeTextPopupColDefs}
                            value={currentParentRecord.itemId}
                            valueFormatter={{
                                optionLabel: 'label',
                                value: 'value',
                            }}
                            optionLabel={dropdownOptions?.selectedItem?.label}
                            disabled={isReadOnly}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextInput
                            fullWidth
                            label={
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={RDFormulasFormDefs.Template}
                                />
                            }
                            id="template"
                            formMethods={formMethods}
                            value={currentParentRecord.template}
                            onChange={handleFieldChange}
                            maxLength={10}
                            disabled={
                                !isNilOrEmpty(currentParentRecord.id) ||
                                isReadOnly
                            }
                        />
                    </Grid>
                </Grid>
            </FormPartial>
            <FormPartial
                title={
                    (
                        <TranslatableText
                            termSet={termSet}
                            termKey={RDFormulasFormDefs.Record_Publishing_Dates}
                        />
                    ) as unknown as string
                }>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <DateInput
                            label={
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={RDFormulasFormDefs.Product_Date}
                                />
                            }
                            id="productDate"
                            value={currentParentRecord.productDate || null}
                            readOnly={
                                !isNilOrEmpty(currentParentRecord.id) ||
                                isReadOnly
                            }
                            onChange={handleFieldChange}
                            formMethods={formMethods}
                            disabled={
                                !isNilOrEmpty(currentParentRecord.id) ||
                                isReadOnly
                            }
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <DateInput
                            label={
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={RDFormulasFormDefs.Properties_Date}
                                />
                            }
                            id="propertiesDate"
                            value={currentParentRecord.propertiesDate || null}
                            readOnly={
                                !isNilOrEmpty(currentParentRecord.id) ||
                                isReadOnly
                            }
                            onChange={handleFieldChange}
                            formMethods={formMethods}
                            disabled={
                                !isNilOrEmpty(currentParentRecord.id) ||
                                isReadOnly
                            }
                        />
                    </Grid>
                </Grid>
            </FormPartial>
            <FormPartial
                title={
                    (
                        <TranslatableText
                            termSet={termSet}
                            termKey={RDFormulasFormDefs.Formula_Configuration}
                        />
                    ) as unknown as string
                }>
                <Grid container spacing={2}>
                    <Grid item xs={4}>
                        <NumericInput
                            fullWidth
                            id="maxPilotQty"
                            value={currentParentRecord.maxPilotQty}
                            label={
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={RDFormulasFormDefs.Max_Pilot_Qty}
                                />
                            }
                            formMethods={formMethods}
                            greaterThan={0}
                            onChange={handleFieldChange}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextInput
                            fullWidth
                            label={
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={RDFormulasFormDefs.Trade_Name}
                                />
                            }
                            id="tradeName"
                            formMethods={formMethods}
                            value={currentParentRecord.tradeName}
                            onChange={handleFieldChange}
                            maxLength={50}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextInputPopup
                            fullWidth
                            id="unitId"
                            onChange={handleFieldChange}
                            label={
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={RDFormulasFormDefs.UOM}
                                />
                            }
                            formMethods={formMethods}
                            popupOptions={
                                canViewUnitOfMeasure
                                    ? unitOfMeasureOptions || []
                                    : []
                            }
                            colDefs={uomTextPopupColDefs}
                            value={currentParentRecord.unitId}
                            valueFormatter={{
                                optionLabel: 'label',
                                value: 'value',
                            }}
                            optionLabel={
                                dropdownOptions?.selectedFormulaUnit?.label
                            }
                            disabled={isReadOnly}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <SelectInput
                            fullWidth
                            label={
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={RDFormulasFormDefs.Approval_Type}
                                />
                            }
                            id="approvalTypeId"
                            value={
                                currentParentRecord.approvalTypeId == null
                                    ? ''
                                    : currentParentRecord.approvalTypeId
                            }
                            onChange={handleFieldChange}
                            formMethods={formMethods}
                            options={approvalTypeOptions}
                            SelectProps={{
                                showClearButton: true,
                            }}
                            disabled={isReadOnly}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <DateInput
                            label={
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={RDFormulasFormDefs.Approved_Date}
                                />
                            }
                            id="approvedDate"
                            value={currentParentRecord.approvedDate || null}
                            onChange={handleFieldChange}
                            formMethods={formMethods}
                            disabled={isReadOnly}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextInputPopup
                            fullWidth
                            id="approvedByUserId"
                            onChange={handleFieldChange}
                            label={
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={RDFormulasFormDefs.Approved_By}
                                />
                            }
                            formMethods={formMethods}
                            popupOptions={userList}
                            colDefs={userTextPopupColDefs}
                            value={currentParentRecord.approvedByUserId}
                            valueFormatter={{
                                optionLabel: 'label',
                                value: 'value',
                            }}
                            optionLabel={
                                dropdownOptions?.selectedApprovedByUser?.label
                            }
                            disabled={isReadOnly}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextInputPopup
                            fullWidth
                            id="supersedesItemId"
                            onChange={handleFieldChange}
                            label={
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={RDFormulasFormDefs.Supersedes}
                                />
                            }
                            formMethods={formMethods}
                            popupOptions={
                                canViewItemMaster ? itemMasterOptions || [] : []
                            }
                            colDefs={productCodeTextPopupColDefs}
                            value={currentParentRecord.supersedesItemId}
                            valueFormatter={{
                                optionLabel: 'label',
                                value: 'value',
                            }}
                            optionLabel={
                                dropdownOptions?.selectedSupersedesItem?.label
                            }
                            disabled={isReadOnly}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextInputPopup
                            fullWidth
                            id="supersededByItemId"
                            onChange={handleFieldChange}
                            label={
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={RDFormulasFormDefs.Superseded_By}
                                />
                            }
                            formMethods={formMethods}
                            popupOptions={
                                canViewItemMaster ? itemMasterOptions || [] : []
                            }
                            colDefs={productCodeTextPopupColDefs}
                            value={currentParentRecord.supersededByItemId}
                            valueFormatter={{
                                optionLabel: 'label',
                                value: 'value',
                            }}
                            optionLabel={
                                dropdownOptions?.selectedSupersededByItem?.label
                            }
                            disabled={isReadOnly}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextInput
                            fullWidth
                            label={
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={RDFormulasFormDefs.Additional_Text}
                                />
                            }
                            id="additionalText"
                            formMethods={formMethods}
                            value={currentParentRecord.additionalText}
                            onChange={handleFieldChange}
                            multiline={true}
                            maxRows={15}
                            minRows={5}
                            maxLength={2000}
                        />
                    </Grid>
                </Grid>
            </FormPartial>
        </>
    );
};
