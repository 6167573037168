import React, { useContext } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import MenuIcons from './MenuIcons';
import datacorLogo from '../../libs/images/Datacor_Products_Logo_Selected_Outlined_RGB_Light.png';
import { useSelector } from 'react-redux';
import ProductDropdown from './ProductDropdown';
import Divider from '@mui/material/Divider';
import { Link } from 'react-router-dom';
import CompanyZoneSelect from '../companyZoneSelect/CompanyZoneSelect';
import { RootState } from '../../store';
import BusinessEntitySelect from './BusinessEntitySelect';
import SettingsContext from '../../contexts/settings.context';

const displayName = (user: any) => {
    if (user) {
        return `${user?.firstName} ${user?.lastName}`;
    }
    return '';
};

const Navbar = () => {
    const user = useSelector((state: RootState) => state.user);
    const { settings } = useContext(SettingsContext);
    const shouldShowSubscriberDropdown = !user.isLegacyUser;
    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar
                position="sticky"
                sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
                <Toolbar>
                    <Grid container alignItems={'center'} spacing={2}>
                        <Grid item>
                            <Typography
                                variant="h6"
                                noWrap
                                component="div"
                                sx={{
                                    flexGrow: 1,
                                    display: { xs: 'none', sm: 'block' },
                                }}>
                                <Link to="/myApps">
                                    <img
                                        src={datacorLogo}
                                        width="165px"
                                        style={{ paddingRight: '20px' }}
                                        alt={'logo'}
                                    />
                                </Link>
                            </Typography>
                        </Grid>
                        <Divider
                            orientation="vertical"
                            variant="middle"
                            sx={{ borderRightWidth: 2, height: '50px' }}
                        />
                        <Grid item xl={4} lg={3} md={2}>
                            <ProductDropdown />
                        </Grid>

                        <Grid item xl={6} lg={6} md={7} xs={7}>
                            <Grid
                                container
                                spacing={0}
                                justifyContent="flex-end"
                                alignItems="center">
                                <Grid item xs={10} md={10} lg={9}>
                                    {user.isLegacyUser && <CompanyZoneSelect />}
                                    {shouldShowSubscriberDropdown && (
                                        <BusinessEntitySelect />
                                    )}
                                </Grid>
                                <Grid
                                    item
                                    xs={2}
                                    md={2}
                                    lg={3}
                                    container
                                    spacing={0}
                                    justifyContent="flex-end">
                                    <p
                                        style={{
                                            //@ts-ignore
                                            color: settings.userSettings
                                                ?.darkMode
                                                ? 'white'
                                                : 'black',
                                            fontWeight: 600,
                                            textAlign: 'right',
                                            fontSize: '12px',
                                        }}>
                                        {displayName(user)}
                                    </p>
                                    <MenuIcons />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>
        </Box>
    );
};

export default Navbar;
