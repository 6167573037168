import { DialogProps } from '@mui/material/Dialog/Dialog';
import { FA_DepreciationEntry } from '../../../types/FixedAsset.types';
import useFixedAssetDepreciationEntriesActionsModal from './hooks/useFixedAssetDepreciationEntriesActionsModal';
import {
    Box,
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    Stack,
    Switch,
    Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { LoadingButton } from '@mui/lab';
import React from 'react';
import { DepreciationEntriesGridDefs } from '../../../constants/i18n/translations/termDefinitions/accounting';
import TranslatableText from '../../../components/i18n/TranslatableText';

type FixedAssetSchedulesActionsProps = Omit<DialogProps, 'onClose'> & {
    selectedEntries: FA_DepreciationEntry[];
    onEntriesUpdated: (entries: FA_DepreciationEntry[]) => void;
    onClose: () => void;
};

const FixedAssetSchedulesActionsModal = ({
    selectedEntries,
    onEntriesUpdated,
    onClose,
    ...props
}: FixedAssetSchedulesActionsProps) => {
    const {
        shouldLockEntries,
        setShouldLockEntries,
        canToggleLockUnlock,
        isLockingEntries,
        isUnlockingEntries,
        handleUpdateEntries,
        canLockDepreciationEntries,
        termSet,
    } = useFixedAssetDepreciationEntriesActionsModal(
        selectedEntries,
        onEntriesUpdated,
        onClose
    );

    return (
        <Dialog {...props} fullWidth maxWidth={'sm'}>
            <DialogTitle>
                <Box display="flex" alignItems="center">
                    <Box flexGrow={1}>
                        <TranslatableText
                            termSet={termSet}
                            termKey={DepreciationEntriesGridDefs.Actions_Title}
                        />
                    </Box>
                    <Box>
                        <IconButton onClick={onClose}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </Box>
            </DialogTitle>
            <DialogContent>
                <Stack spacing={2} alignItems={'center'}>
                    {canLockDepreciationEntries ? (
                        <Stack direction="row" spacing={1} alignItems="center">
                            <Typography>
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={
                                        DepreciationEntriesGridDefs.Actions_Unlock_Entries
                                    }
                                />
                            </Typography>
                            <Switch
                                checked={shouldLockEntries}
                                disabled={!canToggleLockUnlock}
                                onChange={(event) =>
                                    setShouldLockEntries(event.target.checked)
                                }
                            />
                            <Typography>
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={
                                        DepreciationEntriesGridDefs.Actions_Lock_Entries
                                    }
                                />
                            </Typography>
                        </Stack>
                    ) : null}
                    <LoadingButton
                        fullWidth
                        variant="contained"
                        type="submit"
                        loading={isLockingEntries || isUnlockingEntries}
                        onClick={handleUpdateEntries}>
                        <TranslatableText
                            termSet={termSet}
                            termKey={
                                DepreciationEntriesGridDefs.Actions_Process_Changes
                            }
                        />
                    </LoadingButton>
                </Stack>
            </DialogContent>
        </Dialog>
    );
};

export default FixedAssetSchedulesActionsModal;
