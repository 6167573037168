import React, { useContext } from 'react';
import NumericInput, { NumericInputProps } from '../NumericInput/NumericInput';
import SettingsContext from '../../../../contexts/settings.context';

export type CurrencyInputProps = {
    currency?: string;
} & NumericInputProps;

const CurrencyInput = ({ numDecimals, ...props }: CurrencyInputProps) => {
    const { settings } = useContext(SettingsContext);

    const { currency } = settings;
    return (
        <NumericInput
            {...props}
            currency={currency}
            numDecimals={currency ? currency?.decimalDisplay : numDecimals || 2}
        />
    );
};

export default CurrencyInput;
