import { useContext, useEffect } from 'react';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { skipToken } from '@reduxjs/toolkit/query';
import { GhsPrecautionaryPhrase } from '../../../../../types/formulation';
import useBaseForm from '../../../../form/hooks/useBaseForm';
import { PermissionsUtil } from '../../../../../utils/permissions/permissionsUtil';
import { PERMISSIONS } from '../../../../../constants/permissions/Permissions.constants';
import { useGetStatementTypesForDropdownQuery } from '../../../../../services/formulation/precautionaryPhrases/statementTypes/statementTypes.service';
import {
    useCreatePrecautionaryPhraseMutation,
    useDeletePrecautionaryPhraseMutation,
    useGetPrecautionaryPhraseQuery,
    useUpdatePrecautionaryPhraseMutation,
} from '../../../../../services/formulation/precautionaryPhrases/precautionaryPhrases.service';
import { openModalConfirmBasicWithMessage } from '../../../../../store/uiElements';
import SettingsContext from '../../../../../contexts/settings.context';
import { useGetTermSetQuery } from '../../../../../services/i18n/i18n.service';
import { FORMULATION_DEFS } from '../../../../../constants/i18n/translations/termSetDefinitions/formulation';

export interface PrecautionaryPhraseFormProps {
    id: bigint;
    currentLangId: bigint;
    phraseIdList: Array<string>;
    allowDelete: boolean;
    currentPhraseId?: string;
    currentStatementType?: bigint;
    currentUserInputNeeded?: boolean;
    defaultLangId: bigint;

    handleSetPreviousTab(): void;

    handleSetIsFormDirty(formDirty: boolean): void;

    cancelNewLanguage(closeForm: boolean): void;

    setDisableLangButton(disableButton: boolean): void;
}

const useGhsPrecautionaryPhraseForm = ({
    id,
    defaultLangId,
    currentLangId,
    phraseIdList,
    currentPhraseId,
    currentStatementType,
    currentUserInputNeeded,
    handleSetPreviousTab,
    handleSetIsFormDirty,
    cancelNewLanguage,
    setDisableLangButton,
}: PrecautionaryPhraseFormProps) => {
    const dispatch = useDispatch();
    const user = useSelector((state: RootStateOrAny) => state.user);
    const navigate = useNavigate();
    const {
        data: statementTypeOptionsList,
        isLoading: isLoadingStatementTypes,
    } = useGetStatementTypesForDropdownQuery();
    const {
        data: currentPrecautionaryPhrase,
        isFetching: isLoadingPhraseRecord,
    } = useGetPrecautionaryPhraseQuery(id ? id.toString() : skipToken);

    const [createPrecautionaryPhrase] = useCreatePrecautionaryPhraseMutation();
    const [updatePrecautionaryPhrase] = useUpdatePrecautionaryPhraseMutation();
    const [deletePrecautionaryPhrase] = useDeletePrecautionaryPhraseMutation();

    const canCreatePrecautionaryPhrase = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.FORMULATION.GHS_PRECAUTIONARY_PHRASE.CREATE
    );
    const canUpdatePrecautionaryPhrase = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.FORMULATION.GHS_PRECAUTIONARY_PHRASE.EDIT
    );
    const canDeletePrecautionaryPhrase = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.FORMULATION.GHS_PRECAUTIONARY_PHRASE.DELETE
    );

    const blankPrecautionaryPhrase: GhsPrecautionaryPhrase = {
        phraseId: !currentPhraseId ? '' : currentPhraseId,
        langId: currentLangId,
        phraseText: '',
        statementTypeId: !currentStatementType ? null : currentStatementType,
        userInputNeeded:
            currentUserInputNeeded == null ? false : currentUserInputNeeded,
        businessEntityId: user?.businessEntity?.id,
    };

    const { settings } = useContext(SettingsContext);
    const { data: termSet } = useGetTermSetQuery(
        settings?.userSettings
            ? {
                  languageId: settings?.userSettings?.languageId,
                  code: FORMULATION_DEFS.GHS_PRECAUTIONARY_PHRASES_FORM,
              }
            : skipToken
    );

    useEffect(() => {
        setDisableLangButton(!id);
        if (
            !isLoadingStatementTypes &&
            statementTypeOptionsList &&
            !id &&
            !currentStatementType
        ) {
            blankPrecautionaryPhrase.statementTypeId =
                statementTypeOptionsList[0].value;
            setValues(blankPrecautionaryPhrase);
        }
    }, [
        id,
        currentStatementType,
        statementTypeOptionsList,
        isLoadingStatementTypes,
    ]);

    const afterCreateRecord = () => {
        handleSetIsFormDirty(false);
        navigate(`/formulation/ghsPrecautionaryPhrases/${fields.phraseId}`);
    };

    const afterUpdateRecord = () => {
        handleSetIsFormDirty(false);
    };

    const afterDeleteRecord = () => {
        handleSetIsFormDirty(false);
        if (defaultLangId === currentLangId) {
            navigate(`/formulation/ghsPrecautionaryPhrases`);
        } else {
            handleSetPreviousTab();
        }
    };

    const {
        fields,
        formMethods,
        setValues,
        handleFieldChange,
        onCreate,
        onUpdate,
        onDelete,
        hasEntityChanges,
    } = useBaseForm({
        blankEntity: blankPrecautionaryPhrase,
        activeEntity: !id ? undefined : currentPrecautionaryPhrase,
        getDescription: () => {
            return `GHS Precautionary Phrase ${fields.phraseId}`;
        },
        onlyUpdateEntityWithChanges: true,
        createEntity: () => {
            const postBody: GhsPrecautionaryPhrase = {
                phraseId: fields.phraseId,
                langId: fields.langId,
                businessEntityId: fields.businessEntityId,
                phraseText: fields.phraseText,
                statementTypeId: fields.statementTypeId,
                userInputNeeded: fields.userInputNeeded,
            };
            return createPrecautionaryPhrase({ postBody });
        },
        updateEntity: () => {
            return updatePrecautionaryPhrase({
                id: id as unknown as number,
                postBody: fields,
            });
        },
        deleteEntity: () => {
            return deletePrecautionaryPhrase(id.toString());
        },
        afterCreate: afterCreateRecord,
        afterDelete: afterDeleteRecord,
        afterUpdate: afterUpdateRecord,
    });

    useEffect(() => {
        if (
            id &&
            currentPrecautionaryPhrase &&
            id === currentPrecautionaryPhrase.id
        ) {
            if (getFieldsChanged(currentPrecautionaryPhrase)) {
                handleSetIsFormDirty(true);
            } else {
                handleSetIsFormDirty(false);
            }
        } else if (!id) {
            handleSetIsFormDirty(true);
        }
    }, [fields]);

    const getFieldsChanged = (
        currentEntity: GhsPrecautionaryPhrase
    ): boolean => {
        if (fields && currentEntity && currentEntity.id === fields.id) {
            return (
                fields.phraseId !== currentEntity.phraseId ||
                fields.phraseText !== currentEntity.phraseText ||
                fields.userInputNeeded !== currentEntity.userInputNeeded ||
                fields.statementTypeId !== currentEntity.statementTypeId
            );
        }
    };

    const handleCancelForm = () => {
        if (!id) {
            cancelNewLanguage(currentLangId === defaultLangId);
        } else {
            setValues(currentPrecautionaryPhrase);
        }
    };

    const showExistingPhrase = (currentPhraseId: string) => {
        handleSetIsFormDirty(false);
        navigate(`/formulation/ghsPrecautionaryPhrases/${currentPhraseId}`);
    };

    const handlePhraseIdOnBlur = () => {
        if (!id && currentLangId && phraseIdList) {
            const currentPhraseId = phraseIdList.find(
                (current) => current === fields.phraseId
            );
            if (currentPhraseId) {
                const message = `GHS Precautionary Phrase with Phrase ID ${currentPhraseId} already exists.\nDo you want to show it?`;
                dispatch(
                    openModalConfirmBasicWithMessage({
                        message: message,
                        title: '',
                        onConfirm: () => {
                            showExistingPhrase(currentPhraseId);
                        },
                    })
                );
            }
        }
    };

    return {
        fields,
        formMethods,
        statementTypeOptionsList,
        isLoadingPhraseRecord,
        isLoadingStatementTypes,
        handleFieldChange,
        handlePhraseIdOnBlur,
        handleCancelForm,
        onCreate,
        onUpdate,
        onDelete,
        canCreatePrecautionaryPhrase,
        canUpdatePrecautionaryPhrase,
        canDeletePrecautionaryPhrase,
        termSet,
        hasEntityChanges,
    };
};

export default useGhsPrecautionaryPhraseForm;
