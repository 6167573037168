import React from 'react';
import {
    useCreateDepreciationMethodTypeMutation,
    useDeleteDepreciationMethodTypeMutation,
    useGetDepreciationMethodTypeQuery,
    useUpdateDepreciationMethodTypeMutation,
} from '../../../services/fixedAssets/fixedAssets.service';
import { skipToken } from '@reduxjs/toolkit/query';
import { FA_DepreciationMethodType } from '../../../types/FixedAsset.types';
import useBaseForm from '../../form/hooks/useBaseForm';
import { PermissionsUtil } from '../../../utils/permissions/permissionsUtil';
import { PERMISSIONS } from '../../../constants/permissions/Permissions.constants';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { RoutingValues } from '../../../types/Shared.types';
import useTranslation from '../../../components/i18n/hooks/useTranslation';
import { ACCOUNTING_DEFS } from '../../../constants/i18n/translations/termSetDefinitions/accounting';

const UseFixedAssetDepreciationMethodTypeForm = (id: string) => {
    const user = useSelector((state: RootState) => state.user);
    const depreciationType = useSelector(
        (state: RootState) => state.fixedAssets
    );
    const { data: activeType, isLoading: isLoadingType } =
        useGetDepreciationMethodTypeQuery(
            id === RoutingValues.newId ? skipToken : id
        );
    const [createType] = useCreateDepreciationMethodTypeMutation();
    const [updateType] = useUpdateDepreciationMethodTypeMutation();
    const [deleteType] = useDeleteDepreciationMethodTypeMutation();

    const canCreateDepreciationMethodType = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.FIXED_ASSETS.DEPRECIATION_METHOD_TYPES.CREATE
    );
    const canUpdateDepreciationMethodType = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.FIXED_ASSETS.DEPRECIATION_METHOD_TYPES.EDIT
    );
    const canDeleteDepreciationMethodType = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.FIXED_ASSETS.DEPRECIATION_METHOD_TYPES.DELETE
    );

    const blankType: FA_DepreciationMethodType = {
        sortOrder: depreciationType.sortOrderSet.maxValue,
        code: '',
        description: '',
        postsJournalEntries: false,
        predefinedSchedule: false,
    };

    const { termSet } = useTranslation(
        ACCOUNTING_DEFS.DEPRECIATION_METHOD_TYPES_FORM
    );

    const {
        fields,
        handleFieldChange,
        setValues,
        formMethods,
        onCreate,
        onUpdate,
        onDelete,
        closeForm,
    } = useBaseForm({
        closePath: '/accounting/fixedAssets/depreciationMethodType',
        blankEntity: blankType,
        activeEntity: activeType,
        getDescription: () => {
            return `Depreciation Method Type ${fields.code}`;
        },
        createEntity: () => {
            return createType({ postBody: fields });
        },
        updateEntity: () => {
            return updateType({
                id: id as unknown as number,
                postBody: fields,
            });
        },
        deleteEntity: () => {
            return deleteType(id);
        },
    });

    return {
        fields,
        handleFieldChange,
        setValues,
        formMethods,
        onCreate,
        onUpdate,
        onDelete,
        closeForm,
        isLoadingType,
        canCreateDepreciationMethodType,
        canUpdateDepreciationMethodType,
        canDeleteDepreciationMethodType,
        termSet,
    };
};

export default UseFixedAssetDepreciationMethodTypeForm;
