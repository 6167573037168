import { useContext, useEffect, useState } from 'react';
import { isNilOrEmpty } from '../../../../utils/objectUtils';
import { PermissionsUtil } from '../../../../utils/permissions/permissionsUtil';
import { PERMISSIONS } from '../../../../constants/permissions/Permissions.constants';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import { DisposalEntriesActionsProps } from '../FixedAssetDisposalEntriesActionsModal';
import {
    useLockFixedAssetDisposalEntriesMutation,
    useUnlockFixedAssetDisposalEntriesMutation,
} from '../../../../services/fixedAssets/fixedAssets.service';
import { AlertColor } from '@mui/material';
import { openSnackbarBasicWithMessage } from '../../../../store/uiElements';
import SettingsContext from '../../../../contexts/settings.context';
import { useGetTermSetQuery } from '../../../../services/i18n/i18n.service';
import { ACCOUNTING_DEFS } from '../../../../constants/i18n/translations/termSetDefinitions/accounting';
import { skipToken } from '@reduxjs/toolkit/query';

const useFixedAssetDisposalEntriesActionsModal = (
    props: DisposalEntriesActionsProps
) => {
    const dispatch = useDispatch();
    const user = useSelector((state: RootState) => state.user);
    const [shouldLockEntries, setShouldLockEntries] = useState<boolean>(false);
    const [canToggleLockUnlock, setCanToggleLockUnlock] =
        useState<boolean>(false);

    const { settings } = useContext(SettingsContext);
    const { data: termSet } = useGetTermSetQuery(
        settings?.userSettings
            ? {
                  languageId: settings?.userSettings?.languageId,
                  code: ACCOUNTING_DEFS.DISPOSAL_ENTRIES_GRID,
              }
            : skipToken
    );
    const [lockEntries, { isLoading: isLockingEntries }] =
        useLockFixedAssetDisposalEntriesMutation();
    const [unlockEntries, { isLoading: isUnlockingEntries }] =
        useUnlockFixedAssetDisposalEntriesMutation();

    const canLockDisposalEntries = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.FIXED_ASSETS.DISPOSAL_ENTRY.LOCK
    );

    useEffect(() => {
        if (!isNilOrEmpty(props.selected)) {
            const hasUnlockedEntries: boolean = props.selected.some(
                (entry) => !entry.locked
            );
            const hasLockedEntries: boolean = props.selected.some(
                (entry) => entry.locked
            );

            setShouldLockEntries(hasUnlockedEntries);
            setCanToggleLockUnlock(hasUnlockedEntries && hasLockedEntries);
        } else {
            setShouldLockEntries(false);
            setCanToggleLockUnlock(false);
        }
    }, [props.selected]);

    const handleUpdateEntries = async (event: any) => {
        event.preventDefault();

        if (shouldLockEntries) {
            const idList: bigint[] = props.selected
                .filter((entry) => !entry.locked)
                .map((entry) => entry.id);

            try {
                await lockEntries({ ids: idList });
            } catch (e) {
                _showNotification(e.message, 'error');
            }
        } else {
            const idList: bigint[] = props.selected
                .filter((entry) => entry.locked)
                .map((entry) => entry.id);

            try {
                await unlockEntries({ ids: idList });
            } catch (e) {
                _showNotification(e.message, 'error');
            }
        }

        props.onClose();
    };

    const _showNotification = (message: string, severity: AlertColor) => {
        dispatch(
            openSnackbarBasicWithMessage({
                message: message,
                severity: severity,
            })
        );
    };

    return {
        shouldLockEntries,
        setShouldLockEntries,
        canToggleLockUnlock,
        isLockingEntries,
        isUnlockingEntries,
        handleUpdateEntries,
        canLockDisposalEntries,
        termSet,
    };
};

export default useFixedAssetDisposalEntriesActionsModal;
