import {
    Box,
    Checkbox,
    Dialog,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    Grid,
    IconButton,
    Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { isNil } from '../../../../utils/objectUtils';
import React from 'react';
import { FA_AssetEntry } from '../../../../types/FixedAsset.types';
import useFixedAssetCreateAssetEntriesModal from './hooks/useFixedAssetCreateAssetEntriesModal';
import moment from 'moment';
import DateInput from '../../../../components/form/formInputs/DateInput/DateInput';
import { LoadingButton } from '@mui/lab';

export interface CreateAssetEntriesProps {
    open: boolean;
    ids: bigint[];
    onClose: () => void;
    singleRecord: boolean;
    onAssetEntriesCreated: (entries: FA_AssetEntry[]) => void;
}

const FixedAssetCreateAssetEntriesModal = (props: CreateAssetEntriesProps) => {
    const {
        fields,
        handleFieldChange,
        onCreateAssetEntries,
        isCreatingAssetEntries,
        canLockAssetEntry,
    } = useFixedAssetCreateAssetEntriesModal(props);

    return (
        <Dialog open={props.open} fullWidth maxWidth={'md'}>
            <DialogTitle>
                <Box display="flex" alignItems="center">
                    <Box flexGrow={1}>Generate Asset Entry(s)</Box>
                    <Box>
                        <IconButton onClick={props.onClose}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </Box>
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={2} marginBottom={2}>
                    <Grid item xs={12}>
                        <DateInput
                            label="Asset Date"
                            id="assetDate"
                            value={fields.assetDate}
                            onChange={handleFieldChange}
                        />
                    </Grid>
                </Grid>
                {canLockAssetEntry && !props.singleRecord ? (
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography>
                                Do you wish to lock all entries that were
                                created?
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControlLabel
                                label="Lock Entries"
                                control={
                                    <Checkbox
                                        id="lockEntries"
                                        checked={fields.lockEntries}
                                        onChange={handleFieldChange}
                                    />
                                }
                            />
                        </Grid>
                    </Grid>
                ) : null}
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <LoadingButton
                            fullWidth
                            variant="contained"
                            type="submit"
                            disabled={
                                isNil(fields.assetDate) ||
                                !moment(fields.assetDate).isValid()
                            }
                            loading={isCreatingAssetEntries}
                            onClick={onCreateAssetEntries}>
                            Generate Asset Entry(s)
                        </LoadingButton>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
};

export default FixedAssetCreateAssetEntriesModal;
