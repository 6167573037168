import React from 'react';
import { isNilOrEmpty } from '../../utils/objectUtils';
import TextInput from '../form/formInputs/TextInput/TextInput';
import { Checkbox, FormControlLabel, Grid, TextField } from '@mui/material';
import { isArray } from 'lodash';
import SelectInput from '../form/formInputs/SelectInput/SelectInput';
import useRenderDefaultValue from './hooks/useRenderDefaultValue';
import { VALUE_TYPES } from '../../constants/attributes/attributes.constants';
import FormPartial from '../form/formContainer/FormPartial';

interface RenderDefaultValueProps {
    handleFieldChange: (event: any) => void;
    formMethods: any;
    value: any;
    attrValueTypeId: string;
    optionsForDropdown: any[];
    isNew: boolean;
    fieldLabel?: string;
    disabled?: boolean;
    errorMessage?: boolean;
    setIsAttributeValid?: boolean;
}
const RenderDefaultValue = ({
    handleFieldChange,
    formMethods,
    value,
    attrValueTypeId,
    optionsForDropdown,
    fieldLabel,
    disabled,
    setIsAttributeValid,
}: RenderDefaultValueProps) => {
    const { inputValue, errorMessage } = useRenderDefaultValue({
        value,
        attrValueTypeId,
        optionsForDropdown,
        setIsAttributeValid,
        formMethods,
    });

    const RenderJSX = () => {
        //handles rendering the JSX depending on which type is selected
        switch (attrValueTypeId) {
            case VALUE_TYPES.TEXT:
                return (
                    <TextInput
                        fullWidth
                        label={fieldLabel ? fieldLabel : 'Default Value'}
                        placeholder={'Text Value'}
                        id="defaultValue"
                        value={inputValue}
                        onChange={handleFieldChange}
                        formMethods={formMethods}
                        disabled={disabled}
                    />
                );

            case VALUE_TYPES.NUM:
                return (
                    <TextInput
                        fullWidth
                        type={'number'}
                        label={fieldLabel ? fieldLabel : 'Default Value'}
                        placeholder={'Number Value'}
                        id="defaultValue"
                        value={inputValue}
                        onChange={handleFieldChange}
                        formMethods={formMethods}
                        disabled={disabled}
                    />
                );
            case VALUE_TYPES.BOOL:
                return (
                    <FormControlLabel
                        control={
                            <Checkbox
                                key={Math.random()}
                                id="defaultValue"
                                checked={
                                    value === '1' ||
                                    value === true ||
                                    value === 1
                                }
                                onChange={handleFieldChange}
                                disabled={disabled}
                            />
                        }
                        label={fieldLabel ? fieldLabel : 'Default Value'}
                    />
                );
            case VALUE_TYPES.RANGE:
                return (
                    <FormPartial title={'Range'}>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <TextField
                                    error={!!errorMessage}
                                    helperText={errorMessage}
                                    fullWidth
                                    label={
                                        fieldLabel
                                            ? `${fieldLabel}-Low`
                                            : 'Low Default'
                                    }
                                    placeholder={'Low Range'}
                                    id="defaultValue"
                                    type={'number'}
                                    value={inputValue?.low || ''}
                                    onChange={(event: any) =>
                                        handleFieldChange({
                                            target: {
                                                id: 'defaultValue',
                                                value: JSON.stringify({
                                                    low: event.target.value,
                                                    high: inputValue.high,
                                                }),
                                            },
                                        })
                                    }
                                    disabled={disabled}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    fullWidth
                                    label={
                                        fieldLabel
                                            ? `${fieldLabel}-High`
                                            : 'High Default'
                                    }
                                    placeholder={'High Value'}
                                    id="defaultValue"
                                    type={'number'}
                                    value={inputValue?.high || ''}
                                    onChange={(event: any) =>
                                        handleFieldChange({
                                            target: {
                                                id: 'defaultValue',
                                                value: JSON.stringify({
                                                    low: inputValue.low,
                                                    high: event.target.value,
                                                }),
                                            },
                                        })
                                    }
                                    disabled={disabled}
                                />
                            </Grid>
                        </Grid>
                    </FormPartial>
                );
            case VALUE_TYPES.LIST:
                return (
                    <>
                        {isArray(inputValue) && (
                            <SelectInput
                                fullWidth
                                SelectProps={{
                                    multiple: true,
                                }}
                                label={
                                    fieldLabel
                                        ? `${fieldLabel} Options`
                                        : isNilOrEmpty(optionsForDropdown)
                                        ? 'Please create options and save before selecting'
                                        : 'Default Value'
                                }
                                id="defaultValue"
                                value={inputValue}
                                onChange={handleFieldChange}
                                options={optionsForDropdown}
                                formMethods={formMethods}
                                disabled={
                                    isNilOrEmpty(optionsForDropdown) || disabled
                                }
                            />
                        )}
                    </>
                );

            case VALUE_TYPES.TOL:
                return (
                    <FormPartial title={'Tolerance'}>
                        <Grid container spacing={2}>
                            <Grid item xs={4}>
                                <TextInput
                                    fullWidth
                                    type={'number'}
                                    label={
                                        fieldLabel
                                            ? `${fieldLabel}-Input`
                                            : 'Input Default'
                                    }
                                    placeholder={'Input'}
                                    id="defaultValue"
                                    value={inputValue?.input || ''}
                                    onChange={(event: any) =>
                                        handleFieldChange({
                                            target: {
                                                id: 'defaultValue',
                                                value: JSON.stringify({
                                                    input: event.target.value,
                                                    lowT: inputValue.lowT,
                                                    highT: inputValue.highT,
                                                }),
                                            },
                                        })
                                    }
                                    formMethods={formMethods}
                                    disabled={disabled}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    fullWidth
                                    error={!!errorMessage}
                                    helperText={errorMessage}
                                    type={'number'}
                                    label={
                                        fieldLabel
                                            ? `${fieldLabel}-Low`
                                            : 'Low Default'
                                    }
                                    placeholder={'Low Range'}
                                    id="defaultValue"
                                    value={inputValue?.lowT || ''}
                                    onChange={(event: any) =>
                                        handleFieldChange({
                                            target: {
                                                id: 'defaultValue',
                                                value: JSON.stringify({
                                                    input: inputValue.input,
                                                    lowT: event.target.value,
                                                    highT: inputValue.highT,
                                                }),
                                            },
                                        })
                                    }
                                    disabled={disabled}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextInput
                                    fullWidth
                                    type={'number'}
                                    label={
                                        fieldLabel
                                            ? `${fieldLabel}-High`
                                            : 'High Default'
                                    }
                                    placeholder={'High Range'}
                                    id="defaultValue"
                                    value={inputValue?.highT || ''}
                                    onChange={(event: any) =>
                                        handleFieldChange({
                                            target: {
                                                id: 'defaultValue',
                                                value: JSON.stringify({
                                                    input: inputValue.input,
                                                    lowT: inputValue.lowT,
                                                    highT: event.target.value,
                                                }),
                                            },
                                        })
                                    }
                                    disabled={disabled}
                                    formMethods={formMethods}
                                />
                            </Grid>
                        </Grid>
                    </FormPartial>
                );
            default:
                return <></>;
        }
    };

    return <>{RenderJSX()}</>;
};

export default RenderDefaultValue;
