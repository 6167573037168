import React from 'react';
import { Checkbox, FormControlLabel, Grid } from '@mui/material';
import { useParams } from 'react-router-dom';
import FormPartial from '../../components/form/formContainer/FormPartial';
import TextInput from '../../components/form/formInputs/TextInput/TextInput';
import NumericInput from '../../components/form/formInputs/NumericInput/NumericInput';
import useFixedAssetDepreciationMethodStatusForm from './hooks/useFixedAssetDepreciationMethodStatusForm';
import BaseForm, { ParamTypes } from '../../components/form/baseForm/BaseForm';
import { NumberMaxValues, RoutingValues } from '../../types/Shared.types';
import { DEPRECIATION_METHOD_STATUS_FIELDS } from '../../constants/revisionHistory/revisionHistory.constants';
import { DepreciationMethodStatusesFormDefs } from '../../constants/i18n/translations/termDefinitions/accounting';
import TranslatableText from '../../components/i18n/TranslatableText';

const FixedAssetDepreciationMethodStatusForm = () => {
    const { id } = useParams<ParamTypes>();
    const {
        fields,
        handleFieldChange,
        formMethods,
        onCreate,
        onUpdate,
        onDelete,
        closeForm,
        isLoadingStatus,
        canCreateDepreciationMethodStatus,
        canUpdateDepreciationMethodStatus,
        canDeleteDepreciationMethodStatus,
        termSet,
    } = useFixedAssetDepreciationMethodStatusForm(id);

    return (
        <BaseForm
            title={
                <TranslatableText
                    termSet={termSet}
                    termKey={
                        DepreciationMethodStatusesFormDefs.Fixed_Asset_Depreciation_Method_Statuses
                    }
                />
            }
            formMethods={formMethods}
            isLoading={isLoadingStatus}
            isNew={id === RoutingValues.newId}
            onCreate={onCreate}
            onUpdate={onUpdate}
            onDelete={onDelete}
            onCancel={closeForm}
            displayCreateButton={canCreateDepreciationMethodStatus}
            displayUpdateButton={canUpdateDepreciationMethodStatus}
            displayDeleteButton={canDeleteDepreciationMethodStatus}
            showOnRevision={true}
            fieldMappings={DEPRECIATION_METHOD_STATUS_FIELDS}
            entityUuid={fields?.uuid}>
            <FormPartial>
                <Grid container flexDirection="row" spacing={2}>
                    <Grid item xs={6}>
                        <TextInput
                            fullWidth
                            label={
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={
                                        DepreciationMethodStatusesFormDefs.Code
                                    }
                                />
                            }
                            id="code"
                            value={fields.code}
                            onChange={handleFieldChange}
                            formMethods={formMethods}
                            required={true}
                            maxLength={30}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <NumericInput
                            fullWidth
                            label={
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={
                                        DepreciationMethodStatusesFormDefs.Sort_Order
                                    }
                                />
                            }
                            id="sortOrder"
                            value={fields.sortOrder}
                            onChange={handleFieldChange}
                            formMethods={formMethods}
                            required={true}
                            maxValue={NumberMaxValues.smallint}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <FormControlLabel
                            label={
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={
                                        DepreciationMethodStatusesFormDefs.Active
                                    }
                                />
                            }
                            control={
                                <Checkbox
                                    id="isActive"
                                    checked={fields.isActive}
                                    onChange={handleFieldChange}
                                />
                            }
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextInput
                            fullWidth
                            label={
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={
                                        DepreciationMethodStatusesFormDefs.Description
                                    }
                                />
                            }
                            id="description"
                            value={fields.description}
                            onChange={handleFieldChange}
                            formMethods={formMethods}
                            maxLength={255}
                        />
                    </Grid>
                </Grid>
            </FormPartial>
        </BaseForm>
    );
};

export default FixedAssetDepreciationMethodStatusForm;
