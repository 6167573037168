import { RouteProps } from './routesConfig';
import { PERMISSIONS } from '../constants/permissions/Permissions.constants';
import SupplierTypeGrid from '../containers/supplier/SupplierTypeGrid';
import SupplierStatusGrid from '../containers/supplier/StatusGrid';
import SupplierTypeForm from '../containers/supplier/SupplierTypeForm';
import SupplierAddressStatusGrid from '../containers/supplier/SupplierAddressStatusGrid';
import SupplierStatusForm from '../containers/supplier/SupplierStatusForm';
import SupplierAddressStatusForm from '../containers/supplier/SupplierAddressStatusForm';
import SupplierAddressTypeGrid from '../containers/supplier/SupplierAddressTypeGrid';
import SupplierAddressTypeForm from '../containers/supplier/SupplierAddressTypeForm';
import SupplierGrid from '../containers/supplier/SupplierGrid';
import SupplierForm from '../containers/supplier/SupplierForm';
import SupplierAddressForm from '../containers/supplier/SupplierAddressForm';

const SupplierRoutes: RouteProps[] = [
    {
        path: '/suppliers/',
        component: SupplierGrid,
        featureFlag: 'dashboard.nav.apps.suppliers',
    },
    {
        path: '/suppliers/supplier',
        component: SupplierGrid,
        permissionKey: PERMISSIONS.SUPPLIERS.SUPPLIER.VIEW,
        featureFlag: 'dashboard.nav.apps.suppliers',
    },
    {
        path: '/suppliers/supplier/:id',
        component: SupplierForm,
        permissionKey: PERMISSIONS.SUPPLIERS.SUPPLIER.VIEW,
        featureFlag: 'dashboard.nav.apps.suppliers',
    },
    {
        path: '/suppliers/supplierAddress/:id',
        component: SupplierAddressForm,
        permissionKey: PERMISSIONS.SUPPLIERS.SUPPLIER.VIEW,
        featureFlag: 'dashboard.nav.apps.suppliers',
    },
    {
        path: '/suppliers/setup/type',
        component: SupplierTypeGrid,
        permissionKey: PERMISSIONS.SUPPLIERS.SETUP.SUPPLIER_TYPES.VIEW,
        featureFlag: 'dashboard.nav.apps.suppliers',
    },
    {
        path: '/suppliers/setup/type/:id',
        component: SupplierTypeForm,
        permissionKey: PERMISSIONS.SUPPLIERS.SETUP.SUPPLIER_TYPES.VIEW,
        featureFlag: 'dashboard.nav.apps.suppliers',
    },
    {
        path: '/suppliers/setup/status',
        component: SupplierStatusGrid,
        permissionKey: PERMISSIONS.SUPPLIERS.SETUP.SUPPLIER_STATUSES.VIEW,
        featureFlag: 'dashboard.nav.apps.suppliers',
    },
    {
        path: '/suppliers/setup/status/:id',
        component: SupplierStatusForm,
        permissionKey: PERMISSIONS.SUPPLIERS.SETUP.SUPPLIER_STATUSES.VIEW,
        featureFlag: 'dashboard.nav.apps.suppliers',
    },
    {
        path: '/suppliers/setup/addressStatus',
        component: SupplierAddressStatusGrid,
        permissionKey:
            PERMISSIONS.SUPPLIERS.SETUP.SUPPLIER_ADDRESS_STATUSES.VIEW,
        featureFlag: 'dashboard.nav.apps.suppliers',
    },
    {
        path: '/suppliers/setup/addressStatus/:id',
        component: SupplierAddressStatusForm,
        permissionKey:
            PERMISSIONS.SUPPLIERS.SETUP.SUPPLIER_ADDRESS_STATUSES.VIEW,
        featureFlag: 'dashboard.nav.apps.suppliers',
    },
    {
        path: '/suppliers/setup/addressType',
        component: SupplierAddressTypeGrid,
        permissionKey: PERMISSIONS.SUPPLIERS.SETUP.SUPPLIER_ADDRESS_TYPES.VIEW,
        featureFlag: 'dashboard.nav.apps.suppliers',
    },
    {
        path: '/suppliers/setup/addressType/:id',
        component: SupplierAddressTypeForm,
        permissionKey: PERMISSIONS.SUPPLIERS.SETUP.SUPPLIER_ADDRESS_TYPES.VIEW,
        featureFlag: 'dashboard.nav.apps.suppliers',
    },
];

export default SupplierRoutes;
