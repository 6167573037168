import React from 'react';
import { useParams } from 'react-router-dom';
import BaseForm from '../../../../components/form/baseForm/BaseForm';
import useSdsForm from './hooks/useSdsForm';
import SdsHeaderForm from './sections/sdsHeader/SdsHeaderForm';
import SdsIdentificationForm from './sections/sdsIdentification/SdsIdentificationForm';
import SdsHazardInformationForm from './sections/sdsHazardInformation/SdsHazardInformationForm';
import SdsCasCompositionForm from './sections/sdsCasComposition/SdsCasCompositionForm';
import SdsExposureControlsForm from './sections/sdsExposures/subSections/SdsExposureControlsForm';
import SdsPhysicalAndChemicalProperForm from './sections/sdsPhysicalAndChemicalProper/SdsPhysicalAndChemicalProperForm';
import SdsToxicologicalForm from './sections/sdsToxicological/SdsToxicologicalForm';
import SdsEcologicalInformationForm from './sections/sdsEcologicalInformation/SdsEcologicalInformationForm';
import SdsRegulatoryInformationForm from './sections/sdsRegulatoryInformation/SdsRegulatoryInformationForm';
import SdsFirstAidMeasuresForm from './sections/sdsFirstAidMeasures/SdsFirstAidMeasuresForm';
import SdsFirefightingMeasuresForm from './sections/sdsFirefighting/SdsFirefightingMeasuresForm';
import SdsAccidentalReleaseForm from './sections/sdsAccidentalRelease/SdsAccidentalReleaseForm';
import SdsHandlingStorageForm from './sections/sdsHandlingStorage/SdsHandlingStorageForm';
import SdsStabilityReactivity from './sections/sdsStabilityReactivity/SdsStabilityReactivity';
import SdsDisposalConsiderationsForm from './sections/sdsDisposalConsiderations/SdsDisposalConsiderationsForm';
import SdsTransportForm from './sections/sdsTransport/SdsTransportForm';
import { SDSFormDefs } from '../../../../constants/i18n/translations/termDefinitions/formulation';
import TranslatableText from '../../../../components/i18n/TranslatableText';
import SdsOtherInformationForm from './sections/sdsOtherInformation/SdsOtherInformationForm';
import { DISPLAY_FORM_SECTIONS } from '../../../../constants/menus/sdsSections.constants';
import { useGetSdsReportDataQuery } from '../../../../services/documentDesigner/documentDesigner.service';

type ParamTypes = { id: string };

const SdsForm = () => {
    const { id } = useParams<ParamTypes>();

    const {
        fields,
        hasEntityChanges,
        headerHook,
        onCreate,
        onDelete,
        onUpdate,
        closeForm,
        childForms,
        allFormMethods,
        display,
        children,
        termSet,
        getSectionName,
        copyForm,
        copyMethods,
    } = useSdsForm(id);

    const {
        displayCreateNewButton,
        displayUpdateButton,
        displayDeleteButton,
        displayCopyButton,
    } = display;

    const { editableGridProps, childFormProps, blobMethods } = children;

    const blobProps = {
        permissions: childFormProps.permissions,
        blobMethods,
    };

    const editableGridAndBlobProps = {
        ...editableGridProps,
        ...blobProps,
    };

    const childFormAndBlobProps = {
        ...childFormProps,
        ...blobProps,
    };

    const editableGridChildFormsBlobProps = {
        ...editableGridProps,
        ...blobProps,
        helpers: { ...editableGridProps.helpers, ...childFormProps.helpers },
    };

    const {
        sdsGhsHazardPhraseList,
        setSdsGhsHazardPhraseList,
        sdsGhsPrecautionaryPhraseList,
        setSdsGhsPrecautionaryPhraseList,
        sdsExposureList,
        setSdsExposureList,
        sdsPropertyList,
        setSdsPropertyList,
        sdsCasCompositionList,
        setSdsCasCompositionList,
        sdsEcoToxicityList,
        setSdsEcoToxicityList,
        sdsToxicityList,
        setSdsToxicityList,
        sdsRegulationList,
        setSdsRegulationList,
    } = copyMethods;

    return (
        <BaseForm
            sections={DISPLAY_FORM_SECTIONS.flat()}
            title={
                <TranslatableText
                    termSet={termSet}
                    termKey={SDSFormDefs.Safety_Data_Sheet_Maintenance}
                />
            }
            formMethods={allFormMethods}
            isLoading={headerHook.isLoadingSdsRecord}
            isNew={!id}
            hasEntityChanges={hasEntityChanges}
            onCreate={onCreate}
            onUpdate={onUpdate}
            onDelete={onDelete}
            onCancel={closeForm}
            onCopy={copyForm}
            entityType={'SDS'}
            documentQuery={useGetSdsReportDataQuery}
            displayCreateButton={displayCreateNewButton}
            displayUpdateButton={displayUpdateButton}
            displayAttachmentButton={false}
            displayDocumentButtons={false}
            displayDeleteButton={displayDeleteButton}
            displayCopyButton={displayCopyButton}
            showOnRevision={true}
            entityUuid={fields?.uuid}
            childForms={childForms}>
            {/*Primary Information*/}
            <SdsHeaderForm
                {...headerHook}
                termSet={termSet}
                link={getSectionName('Header')}
            />
            {/*Section 1*/}
            <SdsIdentificationForm
                {...childFormAndBlobProps}
                link={getSectionName('Identification')}
                termSet={termSet}
            />
            {/*Section 2*/}
            <SdsHazardInformationForm
                link={getSectionName('Hazard Information')}
                {...editableGridChildFormsBlobProps}
                termSet={termSet}
                copyMethods={{
                    sdsGhsHazardPhraseList,
                    setSdsGhsHazardPhraseList,
                    sdsGhsPrecautionaryPhraseList,
                    setSdsGhsPrecautionaryPhraseList,
                }}
            />
            {/*Section 3*/}
            <SdsCasCompositionForm
                link={getSectionName('Cas Composition')}
                {...editableGridAndBlobProps}
                termSet={termSet}
                copyMethods={{
                    rowsData: sdsCasCompositionList,
                    setRowsData: setSdsCasCompositionList,
                }}
            />
            {/*Section 4*/}
            <SdsFirstAidMeasuresForm
                link={getSectionName('First Aid')}
                {...childFormAndBlobProps}
                termSet={termSet}
            />
            {/*Section 5*/}
            <SdsFirefightingMeasuresForm
                link={getSectionName('Firefighting')}
                {...childFormAndBlobProps}
                termSet={termSet}
            />
            {/*Section 6*/}
            <SdsAccidentalReleaseForm
                link={getSectionName('Accidental Release')}
                {...childFormAndBlobProps}
                termSet={termSet}
            />
            {/*Section 7*/}
            <SdsHandlingStorageForm
                {...childFormAndBlobProps}
                link={getSectionName('Handling & Storage')}
                termSet={termSet}
            />
            {/*Section 8*/}
            <SdsExposureControlsForm
                link={getSectionName('Exposure Controls')}
                {...editableGridAndBlobProps}
                termSet={termSet}
                copyMethods={{
                    rowsData: sdsExposureList,
                    setRowsData: setSdsExposureList,
                }}
            />
            {/*Section 9*/}
            <SdsPhysicalAndChemicalProperForm
                link={getSectionName('Physical & Chemical Proper')}
                {...editableGridAndBlobProps}
                termSet={termSet}
                copyMethods={{
                    rowsData: sdsPropertyList,
                    setRowsData: setSdsPropertyList,
                }}
            />
            {/*Section 10*/}
            <SdsStabilityReactivity
                link={getSectionName('Stability & Reactivity')}
                {...childFormAndBlobProps}
                termSet={termSet}
            />
            {/*Section 11*/}
            <SdsToxicologicalForm
                link={getSectionName('Toxicological Information')}
                {...editableGridAndBlobProps}
                termSet={termSet}
                copyMethods={{
                    rowsData: sdsToxicityList,
                    setRowsData: setSdsToxicityList,
                }}
            />
            {/*Section 12*/}
            <SdsEcologicalInformationForm
                link={getSectionName('Ecological Information')}
                {...editableGridAndBlobProps}
                termSet={termSet}
                copyMethods={{
                    rowsData: sdsEcoToxicityList,
                    setRowsData: setSdsEcoToxicityList,
                }}
            />
            {/*Section 13*/}
            <SdsDisposalConsiderationsForm
                link={getSectionName('Disposal Considerations')}
                {...childFormAndBlobProps}
                termSet={termSet}
            />
            {/*Section 14*/}
            <SdsTransportForm
                {...childFormAndBlobProps}
                termSet={termSet}
                link={getSectionName('Transport')}
            />
            {/*Section 15*/}
            <SdsRegulatoryInformationForm
                {...editableGridAndBlobProps}
                termSet={termSet}
                link={getSectionName('Regulatory Information')}
                copyMethods={{
                    rowsData: sdsRegulationList,
                    setRowsData: setSdsRegulationList,
                }}
            />
            {/*Section 16*/}
            <SdsOtherInformationForm
                {...childFormAndBlobProps}
                termSet={termSet}
                link={getSectionName('Other Information')}
            />
        </BaseForm>
    );
};
export default SdsForm;
