import React from 'react';
import SequenceBase from '../../../../components/sequences/SequenceBase';
import {
    useGetRDFormulaHeaderSequenceParametersQuery,
    useGetRDFormulaHeaderSequenceQuery,
    useListRDFormulaHeaderSequenceParentsQuery,
    useUpdateRDFormulaHeaderSequenceMutation,
} from '../../../../services/formulation/rdFormula/rdFormulaHeader/rdFormula.service';
import { SequencesFormDefs } from '../../../../constants/i18n/translations/termDefinitions/formulation';
import TranslatableText from '../../../../components/i18n/TranslatableText';

const RDFormulaSequences = ({ termSet }: any) => {
    return (
        <SequenceBase
            sequenceQuery={useGetRDFormulaHeaderSequenceQuery}
            sequenceMutation={useUpdateRDFormulaHeaderSequenceMutation}
            parameterQuery={useGetRDFormulaHeaderSequenceParametersQuery}
            useParent={true}
            parentQuery={useListRDFormulaHeaderSequenceParentsQuery}
            title={
                <TranslatableText
                    termSet={termSet}
                    termKey={SequencesFormDefs.RD_Formula_Title}
                />
            }
            closePath={`/formulation/rndFormula`}
        />
    );
};

export default RDFormulaSequences;
