import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { RootStateOrAny, useSelector } from 'react-redux';
import { ItemMaster } from '../../../../types/formulation';
import { useListUnitOfMeasureQuery } from '../../../../services/organizations/organizations.service';
import { UnitOfMeasure } from '../../../../types/Setup.types';
import { PermissionsUtil } from '../../../../utils/permissions/permissionsUtil';
import { PERMISSIONS } from '../../../../constants/permissions/Permissions.constants';
import { useGetAllItemsMasterQuery } from '../../../../services/formulation/itemMaster/item/itemMaster.service';
import SettingsContext from '../../../../contexts/settings.context';
import { useGetTermSetQuery } from '../../../../services/i18n/i18n.service';
import { FORMULATION_DEFS } from '../../../../constants/i18n/translations/termSetDefinitions/formulation';
import { skipToken } from '@reduxjs/toolkit/query';

const useItemMasterGrid = () => {
    const navigate = useNavigate();
    const user = useSelector((state: RootStateOrAny) => state.user);

    const { data: itemMasterList, isLoading: isLoadingItemMasterList } =
        useGetAllItemsMasterQuery();

    const { data: unitOfMeasureList, isLoading: isLoadingUnitOfMeasure } =
        useListUnitOfMeasureQuery();

    const canCreateItemMaster = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.FORMULATION.ITEM_MASTER.CREATE
    );
    const canViewItemMaster = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.FORMULATION.ITEM_MASTER.VIEW
    );
    const { settings } = useContext(SettingsContext);
    const { data: termSet } = useGetTermSetQuery(
        settings?.userSettings
            ? {
                  languageId: settings?.userSettings?.languageId,
                  code: FORMULATION_DEFS.ITEM_MASTER_GRID,
              }
            : skipToken
    );

    const redirectToForm = (data: ItemMaster) => {
        if (data && data?.id) {
            navigate(`/formulation/itemMaster/${data.id}`);
        } else {
            navigate('/formulation/itemMaster/new');
        }
    };

    const getUOMDescryId = (uomId: bigint) => {
        if (!isLoadingUnitOfMeasure && unitOfMeasureList && uomId) {
            const unitOfMeasure: UnitOfMeasure = unitOfMeasureList.find(
                (current: UnitOfMeasure) => current.id === uomId
            );
            return unitOfMeasure?.description;
        }
        return '';
    };

    return {
        itemMasterList,
        isLoadingItemMasterList,
        isLoadingUnitOfMeasure,
        redirectToForm,
        getUOMDescryId,
        canCreateItemMaster,
        canViewItemMaster,
        termSet,
    };
};

export default useItemMasterGrid;
