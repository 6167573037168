import { Grid } from '@mui/material';
import React from 'react';
import RenderAttribute from './RenderAttribute';

const RenderAppliedTemplate = ({
    formDefs,
    fields,
    handleFieldChange,
    attrValues,
    setAttrValues,
    formMethods,
    attrFieldsDisabled,
    setIsAttributeValid,
    entityAttributes,
}: any) => {
    return (
        <>
            {[...formDefs]
                ?.sort((a: any, b: any) => a.id - b.id)
                .map((def: any) => {
                    return (
                        <Grid item xs={6} key={def.id}>
                            <RenderAttribute
                                key={def.id}
                                definition={def}
                                fields={fields}
                                handleFieldChange={handleFieldChange}
                                attrValues={attrValues}
                                setAttrValues={setAttrValues}
                                formMethods={formMethods}
                                disabled={attrFieldsDisabled}
                                setIsAttributeValid={setIsAttributeValid}
                                entityAttributes={entityAttributes}
                            />
                        </Grid>
                    );
                })}
        </>
    );
};

export default RenderAppliedTemplate;
