import React from 'react';
import { useSelector } from 'react-redux';
import UsersGrid from './UsersGrid';
import ZonesGrid from './ZonesGrid';
import { PermissionsUtil } from '../../utils/permissions/permissionsUtil';
import { PERMISSIONS } from '../../constants/permissions/Permissions.constants';
import CompaniesGrid from './CompaniesGrid';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { Card } from '@mui/material';
import { RootState } from '../../store';

interface TabPanelProps {
    children?: React.ReactNode;
    index?: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}>
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    );
}

const handleInitialTabLoad = (
    canViewUserPortal: boolean,
    canViewCompanies: boolean
) => {
    if (!canViewUserPortal && !canViewCompanies) {
        return 2;
    }
    if (!canViewUserPortal) {
        return 1;
    }

    return 0;
};

const UserPortal = () => {
    const user = useSelector((state: RootState) => state.user);
    const canViewUserPortal = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.USER_PORTAL.USERS.VIEW
    );
    const canViewCompanies = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.USER_PORTAL.COMPANIES.VIEW
    );
    const [value, setValue] = React.useState(
        handleInitialTabLoad(canViewUserPortal, canViewCompanies)
    );
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    return (
        <Card>
            <Box sx={{ width: '100%' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs
                        value={value}
                        textColor="inherit"
                        indicatorColor="secondary"
                        onChange={handleChange}
                        aria-label="user portal tabs">
                        {canViewUserPortal && <Tab label="Users" value={0} />}
                        {canViewCompanies && (
                            <Tab label="Companies" value={1} />
                        )}
                        <Tab label="Zones" value={2} />
                    </Tabs>
                </Box>
                <TabPanel value={value} index={0}>
                    <UsersGrid />
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <CompaniesGrid />
                </TabPanel>
                <TabPanel value={value} index={2}>
                    <ZonesGrid />
                </TabPanel>
            </Box>
        </Card>
    );
};

export default UserPortal;
