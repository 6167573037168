import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    useGetAllPermissionsQuery,
    useGetRoleDetailsQuery,
    useUpdateRolePermissionsMutation,
} from '../../../services/permissionsAndPreferences/permissionsAndPreferences.service';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { isFeatureFlagEnabled } from '../../../utils/featureFlag/featureFlagUtil';
import { isNil } from '../../../utils/objectUtils';
import { Node } from '../../../types/Settings.types';
import _ from 'lodash';
import { RootState } from '../../../store';
import { openSnackbarBasicWithMessage } from '../../../store/uiElements';

interface PermissionTreeProps {
    roleId: string;
}

interface NodeMapChild {
    path: Path;
}

interface Path {
    label: string;
    isCategory: boolean;
    children: Array<NodeMapChild>;
}

const usePermissionTree = (props: PermissionTreeProps) => {
    const dispatch = useDispatch();
    const user = useSelector((state: RootState) => state.user);
    const { data: permissions } = useGetAllPermissionsQuery();
    const {
        data: roleDetails,
        isLoading: isLoadingRoleDetails,
        isFetching: isFetchingRole,
    } = useGetRoleDetailsQuery(props.roleId);
    const [updateRolePermissions] = useUpdateRolePermissionsMutation();
    const isRoleSelected = props.roleId;
    const schema = yup
        .object()
        .shape({
            enabledPermissions: yup.array().notRequired(),
        })
        .required();

    const { handleSubmit, register, setValue, getValues } = useForm({
        resolver: yupResolver(schema),
        mode: 'all',
        defaultValues: {
            enabledPermissions: [],
        },
    });

    const [treeData, setTreeData] = useState(null);
    const [savingPermissionChanges, setSavingPermissionChanges] =
        useState(false);

    useEffect(() => {
        register('enabledPermissions');
    }, []);

    useEffect(() => {
        if (isLoadingRoleDetails || isFetchingRole) {
            return;
        }
        async function onLoad() {
            if (props.roleId) {
                setValue('enabledPermissions', [], { shouldValidate: true });
                buildNodes();
            }
        }

        onLoad();
    }, [props.roleId, isLoadingRoleDetails, isFetchingRole]);

    const buildNodes = () => {
        let data: any = [];
        const nodeMap: any = {};
        setTreeData([]);
        permissions.forEach((permission) => {
            if (
                isFeatureFlagEnabled(
                    user.enabledFeatureFlags,
                    permission.featureFlagKey
                )
            ) {
                const pathArray = permission.path.split('.');
                let currentChildrenReference: any = null;
                pathArray.forEach((path, index) => {
                    if (!currentChildrenReference) {
                        if (nodeMap[path]) {
                            currentChildrenReference = nodeMap[path];
                        } else {
                            const nodeReference = nodeMap[path];
                            if (!nodeReference) {
                                nodeMap[path] = {
                                    label: path,
                                    isCategory: true,
                                    children: [],
                                };
                                currentChildrenReference = nodeMap[path];
                            }
                        }
                    } else {
                        const child = currentChildrenReference.children.find(
                            (child: Path) => child.label === path
                        );
                        if (!child) {
                            currentChildrenReference.children.push({
                                label: path,
                                isCategory: true,
                                children: [],
                            });
                        }

                        currentChildrenReference =
                            currentChildrenReference.children.find(
                                (child: Path) => child.label === path
                            );
                    }

                    if (index === pathArray.length - 1) {
                        const isChecked = !isNil(roleDetails?.actions)
                            ? roleDetails.actions.some(
                                  (action) =>
                                      action === permission.permissionKey
                              )
                            : false;
                        currentChildrenReference.children.push({
                            label: permission.description,
                            value: permission.permissionKey,
                            disabled: false,
                            checked: isChecked,
                            children: [],
                        });

                        if (isChecked) {
                            const permissions = getValues('enabledPermissions');
                            permissions.push(permission.permissionKey);
                            setValue('enabledPermissions', permissions, {
                                shouldValidate: true,
                            });
                        }
                    }
                });
            }
        });

        Object.keys(nodeMap).forEach((key) => {
            data.push(nodeMap[key]);
        });

        setTreeData(() => data);
    };

    const onSubmit = async (form: any) => {
        setSavingPermissionChanges(true);

        await updateRolePermissions({
            roleId: props.roleId,
            actions: form.enabledPermissions,
        });

        dispatch(
            openSnackbarBasicWithMessage({
                message: `You'll need to log out and log in again for these changes to take effect.`,
            })
        );

        setSavingPermissionChanges(false);
    };

    const onCheckChange = (node: Node) => {
        let enabledPermissions = getValues('enabledPermissions');

        if (node.checked) {
            enabledPermissions.push(node.value);
            setValue('enabledPermissions', enabledPermissions, {
                shouldValidate: true,
            });
        } else {
            _.remove(
                enabledPermissions,
                (permission) => permission === node.value
            );
            setValue('enabledPermissions', enabledPermissions, {
                shouldValidate: true,
            });
        }
    };

    return {
        roleDetails,
        treeData,
        onCheckChange,
        savingPermissionChanges,
        handleSubmit,
        onSubmit,
        isRoleSelected,
    };
};

export default usePermissionTree;
