import React, { useContext } from 'react';
import {
    useGetContractQuery,
    useCreateContractMutation,
    useUpdateContractMutation,
    useDeleteContractMutation,
    useGetContractTypesForDropdownQuery,
} from '../../../services/accounting/accounting.service';
import { skipToken } from '@reduxjs/toolkit/query';
import { Contract } from '../../../types/Accounting.types';
import useBaseForm from '../../form/hooks/useBaseForm';
import { PermissionsUtil } from '../../../utils/permissions/permissionsUtil';
import { PERMISSIONS } from '../../../constants/permissions/Permissions.constants';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { RoutingValues } from '../../../types/Shared.types';
import SettingsContext from '../../../contexts/settings.context';
import { useGetTermSetQuery } from '../../../services/i18n/i18n.service';
import { ACCOUNTING_DEFS } from '../../../constants/i18n/translations/termSetDefinitions/accounting';

const UseContractForm = (id: string) => {
    const user = useSelector((state: RootState) => state.user);
    const { settings } = useContext(SettingsContext);
    const { data: termSet } = useGetTermSetQuery(
        settings?.userSettings
            ? {
                  languageId: settings?.userSettings?.languageId,
                  code: ACCOUNTING_DEFS.CONTRACTS_FORM,
              }
            : skipToken
    );
    const { data: activeContract, isLoading: isLoadingContract } =
        useGetContractQuery(id === RoutingValues.newId ? skipToken : id);
    const [createContract] = useCreateContractMutation();
    const [updateContract] = useUpdateContractMutation();
    const [deleteContract] = useDeleteContractMutation();

    const { data: contractTypeOptions, isLoading: isLoadingContractTypes } =
        useGetContractTypesForDropdownQuery();

    const canCreateContract = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.FIXED_ASSETS.CONTRACTS.CREATE
    );
    const canUpdateContract = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.FIXED_ASSETS.CONTRACTS.EDIT
    );
    const canDeleteContract = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.FIXED_ASSETS.CONTRACTS.DELETE
    );

    const blankContract: Contract = {
        number: '',
        name: '',
        description: '',
        supplier: '',
        startDate: null,
        endDate: null,
        amount: null,
        contractTypeId: null,
    };

    const {
        fields,
        handleFieldChange,
        formMethods,
        onCreate,
        onUpdate,
        onDelete,
        closeForm,
    } = useBaseForm({
        closePath: '/accounting/contract',
        blankEntity: blankContract,
        activeEntity: activeContract,
        getDescription: () => {
            return `Contract ${fields.number}`;
        },
        createEntity: () => {
            return createContract({ postBody: fields });
        },
        updateEntity: () => {
            return updateContract({
                id: id as unknown as number,
                postBody: fields,
            });
        },
        deleteEntity: () => {
            return deleteContract(id);
        },
    });

    return {
        fields,
        handleFieldChange,
        formMethods,
        onCreate,
        onUpdate,
        onDelete,
        closeForm,
        isLoadingContract,
        isLoadingContractTypes,
        contractTypeOptions,
        canCreateContract,
        canUpdateContract,
        canDeleteContract,
        termSet,
    };
};

export default UseContractForm;
