import {
    Box,
    Dialog,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import React from 'react';
import DateInput from '../../../components/form/formInputs/DateInput/DateInput';
import { LoadingButton } from '@mui/lab';
import MultiSelectInput from '../../../components/form/formInputs/MultiSelectInput/MutliSelectInput';
import { FA_AssetEntry } from '../../../types/FixedAsset.types';
import useAssetEntryOptionsModal from './hooks/useAssetEntryOptionsModal';
import { validateDateRange } from '../../../utils/comparisonUtils';
import { AssetEntriesGridDefs } from '../../../constants/i18n/translations/termDefinitions/accounting';
import TranslatableText from '../../../components/i18n/TranslatableText';

export interface AssetEntryOptionsProps {
    open: boolean;
    onAssetEntriesLoaded: (assetEntries: FA_AssetEntry[]) => void;
    onClose: () => void;
}

const AssetEntryOptionsModal = (props: AssetEntryOptionsProps) => {
    const {
        fields,
        handleFieldChange,
        assetTypeOptions,
        loadingAssetTypes,
        handleGetAssetEntries,
        isLoadingEntries,
        termSet,
    } = useAssetEntryOptionsModal(props);

    return (
        <Dialog
            onClose={props.onClose}
            open={props.open}
            fullWidth
            maxWidth={'sm'}>
            <DialogTitle>
                <Box display="flex" alignItems="center">
                    <Box flexGrow={1}>
                        <TranslatableText
                            termSet={termSet}
                            termKey={AssetEntriesGridDefs.Options_Title}
                        />
                    </Box>
                    <Box>
                        <IconButton onClick={props.onClose}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </Box>
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <MultiSelectInput
                            label={
                                (
                                    <TranslatableText
                                        termSet={termSet}
                                        termKey={
                                            AssetEntriesGridDefs.Options_Asset_Types
                                        }
                                    />
                                ) as unknown as string
                            }
                            id="assetTypes"
                            value={fields.assetTypes}
                            onChange={handleFieldChange}
                            disabled={loadingAssetTypes}
                            options={assetTypeOptions}
                            disableCloseOnSelect={true}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <DateInput
                            label={
                                (
                                    <TranslatableText
                                        termSet={termSet}
                                        termKey={
                                            AssetEntriesGridDefs.Options_Interval_Date_Begin
                                        }
                                    />
                                ) as unknown as string
                            }
                            id="startDate"
                            value={fields.startDate}
                            onChange={handleFieldChange}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <DateInput
                            label={
                                (
                                    <TranslatableText
                                        termSet={termSet}
                                        termKey={
                                            AssetEntriesGridDefs.Options_Interval_Date_End
                                        }
                                    />
                                ) as unknown as string
                            }
                            id="endDate"
                            value={fields.endDate}
                            onChange={handleFieldChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <LoadingButton
                            fullWidth
                            variant="contained"
                            type="submit"
                            disabled={
                                !validateDateRange(
                                    fields.startDate,
                                    fields.endDate
                                )
                            }
                            loading={isLoadingEntries}
                            onClick={handleGetAssetEntries}>
                            <TranslatableText
                                termSet={termSet}
                                termKey={
                                    AssetEntriesGridDefs.Options_Get_Disposal_Entries
                                }
                            />
                        </LoadingButton>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
};

export default AssetEntryOptionsModal;
