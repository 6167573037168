import useCasPropertiesGrid from './hooks/useCasPropertiesGrid';
import React from 'react';
import {
    CasMaster,
    CasProperty,
    FormulaProperty,
} from '../../../../../../types/formulation';
import { isNilOrEmpty } from '../../../../../../utils/objectUtils';
import DatacorLogoSpinner from '../../../../../../components/datacorLogoSpinner/DatacorLogoSpinner';
import BaseGrid from '../../../../../../components/grids/BaseGrid';
import FormPartial from '../../../../../../components/form/formContainer/FormPartial';
import { ChildEditableGridWithCopyFunctionProps } from '../../../../../grid/utils/editableGrid/ChildEditableGridWithCopyFunctionProps';
import FormulaPropertiesSelectionModal from '../../../../shared/multiSelectModals/formulaProperties/FormulaPropertiesSelectionModal';
import { CasMasterFormDefs } from '../../../../../../constants/i18n/translations/termDefinitions/formulation';
import TranslatableText from '../../../../../../components/i18n/TranslatableText';

const CasPropertiesGrid = (
    props: ChildEditableGridWithCopyFunctionProps<CasMaster, CasProperty>
) => {
    const {
        handleGridEdits,
        formulaPropertyList,
        showMultiSelectGridModal,
        setShowMultiSelectGridModal,
        handleSelectedRecords,
        casPropertiesColDef,
        handleRowValidations,
        colDefOptions,
        setFilteredFormulaPropertyList,
        termSet,
    } = useCasPropertiesGrid(props);

    const { isParentLoading } = props.parentData;

    const { showAddButton, showDeleteButton, showSortButton, isGridEditable } =
        props.displayGridButtons;

    const { rowsData } = props.copyMethods;

    return (
        <React.Fragment>
            {isParentLoading || isNilOrEmpty(casPropertiesColDef) ? (
                <DatacorLogoSpinner />
            ) : (
                <>
                    <FormPartial
                        title={
                            (
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={CasMasterFormDefs.Properties_Text}
                                />
                            ) as unknown as string
                        }>
                        <FormulaPropertiesSelectionModal
                            openModal={showMultiSelectGridModal}
                            onClose={() => setShowMultiSelectGridModal(false)}
                            onSelected={(selectedRecords: FormulaProperty[]) =>
                                handleSelectedRecords(selectedRecords)
                            }
                            formulaPropertyList={formulaPropertyList || []}
                        />
                        <BaseGrid
                            autoSize={true}
                            displayGrid={true}
                            displayCreateNewButton={showAddButton}
                            useRowDrag={showSortButton}
                            displaySortButton={showSortButton}
                            sortKey={'sortBy'}
                            rowData={rowsData || []}
                            dataKey={'rowId'}
                            gridClass="full-width-grid"
                            displayToolbar={true}
                            sizeColumnsToFit={true}
                            onHandleGridEdits={handleGridEdits}
                            onCreateNew={() => {
                                setFilteredFormulaPropertyList();
                                setShowMultiSelectGridModal(true);
                            }}
                            isEditable={isGridEditable}
                            columnDefs={casPropertiesColDef}
                            displayDeleteButton={showDeleteButton}
                            gridButtonTitle={'Properties'}
                            newRowPlaceholderValues={{
                                propertyValue: 'Enter Property Value...',
                                propertyCode: 'Enter Property Code...',
                                propertyUnitId: 'Enter Unit of Measure...',
                                sortBy: 'Enter Sort Code',
                            }}
                            createRow={false}
                            onHandleRowValidations={handleRowValidations}
                            defaultColDef={colDefOptions}></BaseGrid>
                    </FormPartial>
                </>
            )}
        </React.Fragment>
    );
};

export default CasPropertiesGrid;
