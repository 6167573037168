import React, { useMemo } from 'react';
import { DialogProps } from '@mui/material/Dialog/Dialog';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import {
    ColDef,
    ColGroupDef,
} from 'ag-grid-community/dist/lib/entities/colDef';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import {
    IDetailCellRendererParams,
    ValueGetterParams,
} from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { GridDefaults } from '../grids/Grid.constants';
import { LoadingButton } from '@mui/lab';
import useUploadGridModal from './hooks/useUploadGridModal';

export type UploadGridModalProps = Omit<DialogProps, 'onClose' | 'children'> & {
    title: string;
    onClose: any;
    rowData: any[];
    mainExportSchema: (ColDef | ColGroupDef)[];
    detailExportSchema: (ColDef | ColGroupDef)[];
    onImportReview?: (event: any, data: any[]) => void;
    onImportSave?: (event: any, data: any[]) => void;
    onUploadImport?: (data: any) => void;
    onImportSelected?: (data: any) => void;
    isImporting?: boolean;
    isValidating?: boolean;
};

const UploadGridModal = (props: UploadGridModalProps) => {
    const {
        gridRef,
        onGridReady,
        exportSchema,
        uploadData,
        validationStatus,
        onSelectionChanged,
        selectedRows,
    } = useUploadGridModal({
        mainExportSchema: props.mainExportSchema,
        detailExportSchema: props.detailExportSchema,
        onUploadImport: props.onUploadImport,
    });

    const detailCellRendererParams = useMemo<any>(() => {
        return {
            detailGridOptions: {
                columnDefs: props.detailExportSchema,
                defaultColDef: {
                    flex: 1,
                },
            },
            getDetailRowData: (params) => {
                params.successCallback(params.data.detailRecords);
            },
        } as IDetailCellRendererParams;
    }, []);

    return (
        <Dialog
            open={props.open}
            fullWidth
            maxWidth={'lg'}
            onClose={props.onClose}>
            <DialogTitle>
                <Grid container spacing={2}>
                    <Grid item xs={11}>
                        {props.title}
                    </Grid>
                    <Grid item xs={1}>
                        <IconButton
                            aria-label="close"
                            onClick={props.onClose}
                            sx={{
                                position: 'absolute',
                                right: 8,
                                top: 8,
                            }}>
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                </Grid>
            </DialogTitle>

            <DialogContent className={'full-size-item'}>
                <div className={'ag-theme-alpine'} data-testid={'upload-modal'}>
                    <div className="action-bar">
                        <Button
                            variant="text"
                            size="small"
                            data-testid="download-schema-btn"
                            onClick={exportSchema}
                            startIcon={<FileDownloadIcon />}>
                            Download Template
                        </Button>
                        <Button
                            variant="text"
                            size="small"
                            data-testid="upload-data-btn"
                            onClick={uploadData}
                            startIcon={<FileUploadIcon />}>
                            Upload Data
                        </Button>
                    </div>
                    <AgGridReact
                        domLayout={GridDefaults.domLayout}
                        ref={gridRef}
                        tooltipShowDelay={GridDefaults.tooltipShowDelay}
                        pagination={GridDefaults.pagination}
                        paginationPageSize={GridDefaults.paginationPageSize}
                        onGridReady={onGridReady}
                        rowData={props.rowData}
                        columnDefs={[
                            {
                                headerName: 'Selected',
                                checkboxSelection: true,
                                headerCheckboxSelection: true,
                                maxWidth: 125,
                                minWidth: 100,
                            },
                            {
                                colId: 'validation',
                                suppressMenu: true,
                                minWidth: 10,
                                valueGetter: (params: ValueGetterParams) => {
                                    return params.data.isValid;
                                },
                                cellRenderer: 'customStatus',
                            },
                            ...(props.mainExportSchema || []),
                        ]}
                        rowMultiSelectWithClick={true}
                        rowStyle={{ cursor: 'pointer' }}
                        rowSelection={'multiple'}
                        onSelectionChanged={onSelectionChanged}
                        masterDetail={true}
                        detailCellRendererParams={detailCellRendererParams}
                        frameworkComponents={{ customStatus: validationStatus }}
                        detailRowHeight={145}></AgGridReact>
                </div>
            </DialogContent>

            <DialogActions>
                <LoadingButton
                    loading={props.isValidating}
                    variant={'contained'}
                    size={'medium'}
                    color={'primary'}
                    disabled={selectedRows.length === 0}
                    onClick={(event) => {
                        props.onImportReview(event, selectedRows);
                    }}
                    data-testid="importReviewBtn">
                    Review
                </LoadingButton>
                <LoadingButton
                    loading={props.isImporting}
                    variant={'contained'}
                    size={'medium'}
                    color={'secondary'}
                    disabled={selectedRows.length === 0}
                    onClick={(event) => {
                        props.onImportSave(event, selectedRows);
                    }}
                    data-testid="importSaveBtn">
                    Save Records
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
};

export default UploadGridModal;
