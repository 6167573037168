import React, { useEffect } from 'react';
import InputBase, { InputBaseProps } from '../InputBase/InputBase';
import { MaxLengthValidator } from '../../../../validators/maxLength.validator';
import { MinLengthValidator } from '../../../../validators/minLength.validator';
import { IsUniqueValidator } from '../../../../validators/isUnique.validator';
import _ from 'lodash';

export type TextInputProps = {
    minLength?: number;
    maxLength?: number;
    isUnique?: boolean;
    uniqueList?: any[];
} & InputBaseProps;
const TextInput = ({
    onBlur,
    isUnique,
    uniqueList,
    ...props
}: TextInputProps) => {
    useEffect(() => {
        if (props.formMethods) {
            addValidators({ onBlur, isUnique, uniqueList, ...props });
        }
    }, []);

    return <InputBase onBlur={onBlur} {...props} stateful="true" />;
};

const addValidators = (props: TextInputProps) => {
    if (props.maxLength) {
        props.formMethods?.addValidator(
            props.id,
            _.isObject(props.label) ? (
                <>{props.label}</>
            ) : (
                props.label.toString()
            ),
            MaxLengthValidator(props.maxLength)
        );
    }

    if (props.minLength) {
        props.formMethods?.addValidator(
            props.id,
            _.isObject(props.label) ? (
                <>{props.label}</>
            ) : (
                props.label.toString()
            ),
            MinLengthValidator(props.minLength)
        );
    }

    if (props.isUnique && props.uniqueList) {
        props.formMethods?.addValidator(
            props.id,
            _.isObject(props.label) ? (
                <>{props.label}</>
            ) : (
                props.label.toString()
            ),
            IsUniqueValidator(props.uniqueList)
        );
    }
};

export default TextInput;
