import { datacorAppApi } from '../../../datacorAppApi';
import { CasHazardClassificationMapping } from '../../../../types/formulation';

const serviceUrl: string = 'casMaster/casHazardClassMappings';

export const casHazardClassMappingApi = datacorAppApi.injectEndpoints({
    endpoints: (build) => ({
        listCasHazardClassMappings: build.query<
            CasHazardClassificationMapping[],
            void
        >({
            query: () => {
                return {
                    url: serviceUrl,
                };
            },
            providesTags: ['casHazardClassMappings'],
        }),
    }),
});

export const { useListCasHazardClassMappingsQuery } = casHazardClassMappingApi;
