import { useNavigate } from 'react-router-dom';
import { useListUnitOfMeasureQuery } from '../../../services/organizations/organizations.service';
import { skipToken } from '@reduxjs/toolkit/query';
import { RootStateOrAny, useSelector } from 'react-redux';
import { PermissionsUtil } from '../../../utils/permissions/permissionsUtil';
import { PERMISSIONS } from '../../../constants/permissions/Permissions.constants';

const UseUnitOfMeasureGrid = () => {
    const navigate = useNavigate();
    const user = useSelector((state: RootStateOrAny) => state.user);

    const { data: unitOfMeasureList, isLoading: isLoadingUnitsOfMeasure } =
        useListUnitOfMeasureQuery(
            user?.businessEntity?.id
                ? user?.businessEntity?.id.toString()
                : skipToken
        );

    const canCreateUnitOfMeasure = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.SETUP.UNITS_OF_MEASURE.CREATE
    );
    const canViewUnitOfMeasure = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.SETUP.UNITS_OF_MEASURE.VIEW
    );

    const redirectToForm = (row: any) => {
        if (row.id) {
            navigate(`/setup/unitOfMeasure/${row.id}`);
        } else {
            navigate(`/setup/unitOfMeasure/new`);
        }
    };

    return {
        unitOfMeasureList,
        redirectToForm,
        isLoadingUnitsOfMeasure,
        canCreateUnitOfMeasure,
        canViewUnitOfMeasure,
    };
};

export default UseUnitOfMeasureGrid;
