import { Grid } from '@mui/material';
import RenderItemAttribute from '../../../../../../components/form/itemAttributes/RenderItemAttribute';
import React from 'react';

const RenderAppliedTemplate = ({
    formItemDefs,
    fields,
    handleFieldChange,
    itemAttrValues,
    setItemAttrValues,
    formMethods,
    attrFieldsDisabled,
    setIsAttributeValid,
}: any) => {
    return (
        <>
            {[...formItemDefs]
                ?.sort((a: any, b: any) => a.id - b.id)
                .map((def: any) => {
                    return (
                        <Grid item xs={6} key={def.id}>
                            <RenderItemAttribute
                                key={def.id}
                                definition={def}
                                fields={fields}
                                handleFieldChange={handleFieldChange}
                                itemAttrValues={itemAttrValues}
                                setItemAttrValues={setItemAttrValues}
                                formMethods={formMethods}
                                disabled={attrFieldsDisabled}
                                setIsAttributeValid={setIsAttributeValid}
                            />
                        </Grid>
                    );
                })}
        </>
    );
};

export default RenderAppliedTemplate;
