import React, { useEffect, useState } from 'react';
import {
    CasEcoToxicity,
    CasExposure,
    CasGhsHazardPhrase,
    CasGhsPrecautionaryPhrase,
    CasHazardClassificationMapping,
    CasMaster,
    CasPictogram,
    CasProperty,
    CasRegulation,
    CasToxicity,
} from '../../../../../types/formulation';
import { RoutingValues } from '../../../../../types/Shared.types';
import { RowStatus } from '../../../../../components/grids/hooks/useBaseGridEditable';
import { v4 as uuidv4 } from 'uuid';
import { CopyFormProps } from '../../../../form/helpers/CopyFormProps';

const useCopyCasForm = (props: CopyFormProps<CasMaster>) => {
    const { fields, dataFromQuery, setValues, formType } = props;

    const [casProperties, setCasProperties] = useState([] as CasProperty[]);
    const [casExposures, setCasExposures] = useState([] as CasExposure[]);
    const [casToxicities, setCasToxicities] = useState([] as CasToxicity[]);
    const [casEcoToxicities, setCasEcoToxicities] = useState(
        [] as CasEcoToxicity[]
    );
    const [casRegulations, setCasRegulations] = useState([] as CasRegulation[]);
    const [casGhsHazardPhraseRecords, setCasGhsHazardPhraseRecords] = useState(
        [] as CasGhsHazardPhrase[]
    );
    const [
        casGhsPrecautionaryPhraseRecords,
        setCasGhsPrecautionaryPhraseRecords,
    ] = useState([] as CasGhsPrecautionaryPhrase[]);
    const [
        casHazardClassificationMappings,
        setCasHazardClassificationMappings,
    ] = useState([] as CasHazardClassificationMapping[]);

    const createCasPictogramCopy = (today: Date): CasPictogram => {
        return {
            casPictogramSignalWord:
                fields?.casPictogram?.casPictogramSignalWord,
            casPictogramSignalWordId:
                fields?.casPictogram?.casPictogramSignalWordId,
            compressedGas: fields?.casPictogram?.compressedGas,
            corrosive: fields?.casPictogram?.corrosive,
            environmentDamaging: fields?.casPictogram?.environmentDamaging,
            explosive: fields?.casPictogram?.explosive,
            flammable: fields?.casPictogram?.flammable,
            healthHazard: fields?.casPictogram?.healthHazard,
            irritant: fields?.casPictogram?.irritant,
            oxidizing: fields?.casPictogram?.oxidizing,
            toxic: fields?.casPictogram?.toxic,
            createdAt: today,
            updatedAt: today,
        };
    };

    const createCasPropertiesCopy = (today: Date): CasProperty[] => {
        const casPropertiesCopy: CasProperty[] = casProperties?.map(
            (casProps: any, index: number) => {
                return {
                    propertyId: casProps.propertyId,
                    propertyValue: casProps.propertyValue,
                    propertyUnitId: casProps.propertyUnitId,
                    propertyCode: casProps.propertyCode,
                    description: casProps.description,
                    unitCode: casProps.unitCode,
                    sortBy: casProps.sortBy,
                    rowStatus: RowStatus.ADDED,
                    rowId: index + 1,
                    createdAt: today,
                    updatedAt: today,
                };
            }
        );
        setCasProperties(casPropertiesCopy);
        return casPropertiesCopy;
    };

    const createCasExposuresCopy = (today: Date): CasExposure[] => {
        const casExposuresCopy: CasExposure[] = casExposures?.map(
            (casExposureRecord: CasExposure, index: number) => {
                return {
                    description: casExposureRecord.description,
                    exposureAgency: casExposureRecord.exposureAgency,
                    exposureAgencyCode: casExposureRecord.exposureAgencyCode,
                    exposureAgencyId: casExposureRecord.exposureAgencyId,
                    exposureValue: casExposureRecord.exposureValue,
                    exposureForm: casExposureRecord.exposureForm,
                    notes: casExposureRecord.notes,
                    sortOrder: casExposureRecord.sortOrder,
                    rowStatus: RowStatus.ADDED,
                    rowId: index + 1,
                    createdAt: today,
                    updatedAt: today,
                };
            }
        );
        setCasExposures(casExposuresCopy);
        return casExposuresCopy;
    };

    const createCasToxicityCopy = (today: Date): CasToxicity[] => {
        const casToxicityCopy: CasToxicity[] = casToxicities?.map(
            (casTox: any, index: number) => {
                return {
                    description: casTox.description,
                    effectiveDose: casTox.effectiveDose,
                    exposureTime: casTox.exposureTime,
                    notes: casTox.notes,
                    route: casTox.route,
                    routeId: casTox.routeId,
                    toxicSpecies: casTox.toxicSpecies,
                    toxicSpeciesCode: casTox.toxicSpeciesCode,
                    toxicSpeciesId: casTox.toxicSpeciesId,
                    toxicTest: casTox.toxicTest,
                    toxicTestCode: casTox.toxicTestCode,
                    toxicTestId: casTox.toxicTestId,
                    rowStatus: RowStatus.ADDED,
                    rowId: index + 1,
                    createdAt: today,
                    updatedAt: today,
                };
            }
        );
        setCasToxicities(casToxicityCopy);
        return casToxicityCopy;
    };

    const createCasEcoToxicityCopy = (today: Date): CasEcoToxicity[] => {
        const casEcoToxicityCopy: CasEcoToxicity[] = casEcoToxicities?.map(
            (casEcoTox: any, index: number) => {
                return {
                    description: casEcoTox.description,
                    effectiveDose: casEcoTox.effectiveDose,
                    exposureTime: casEcoTox.exposureTime,
                    notes: casEcoTox.notes,
                    method: casEcoTox.method,
                    source: casEcoTox.source,
                    speciesClass: casEcoTox.speciesClass,
                    speciesClassId: casEcoTox.speciesClassId,
                    toxicSpecies: casEcoTox.toxicSpecies,
                    toxicSpeciesCode: casEcoTox.toxicSpeciesCode,
                    toxicSpeciesId: casEcoTox.toxicSpeciesId,
                    toxicTest: casEcoTox.toxicTest,
                    toxicTestCode: casEcoTox.toxicTestCode,
                    toxicTestId: casEcoTox.toxicTestId,
                    rowStatus: RowStatus.ADDED,
                    rowId: index + 1,
                    createdAt: today,
                    updatedAt: today,
                };
            }
        );
        setCasEcoToxicities(casEcoToxicityCopy);
        return casEcoToxicityCopy;
    };

    const createCasRegulatoryCopy = (today: Date): CasRegulation[] => {
        const casRegulatoryCopy: CasRegulation[] = casRegulations?.map(
            (casReg: any, index: number) => {
                return {
                    code: casReg.code,
                    countryCode: casReg.countryCode,
                    countryCodeId: casReg.countryCodeId,
                    importManufactureLimit: casReg.importManufactureLimit,
                    notes: casReg.notes,
                    onList: casReg.onList,
                    onListDisplay: casReg.onListDisplay,
                    regulationBodyId: casReg.regulationBodyId,
                    regulationBodyDescription: casReg.regulationBodyDescription,
                    regulationSection: casReg.regulationSection,
                    regulationSectionId: casReg.regulationSectionId,
                    sortBy: casReg.sortBy,
                    stateCode: casReg.stateCode,
                    stateCodeId: casReg.stateCodeId,
                    threshold: casReg.threshold,
                    title: casReg.title,
                    rowStatus: RowStatus.ADDED,
                    rowId: index + 1,
                    createdAt: today,
                    updatedAt: today,
                };
            }
        );
        setCasRegulations(casRegulatoryCopy);
        return casRegulatoryCopy;
    };

    const createCasGhsPrecautionaryPhraseCopy = (
        today: Date
    ): CasGhsPrecautionaryPhrase[] => {
        const casGhsPrecautionaryPhraseCopy: CasGhsPrecautionaryPhrase[] =
            casGhsPrecautionaryPhraseRecords?.map(
                (casPrecautionaryPhrase: any, index: number) => {
                    return {
                        statementType: casPrecautionaryPhrase.statementType,
                        statementTypeId: casPrecautionaryPhrase.statementTypeId,
                        langId: casPrecautionaryPhrase.langId,
                        phraseId: casPrecautionaryPhrase.phraseId,
                        phraseText: casPrecautionaryPhrase.phraseText,
                        sdsSortOrder: casPrecautionaryPhrase.sdsSortOrder,
                        businessEntityId:
                            casPrecautionaryPhrase.businessEntityId,
                        rowStatus: RowStatus.ADDED,
                        rowId: index + 1,
                        createdAt: today,
                        updatedAt: today,
                    };
                }
            );
        setCasGhsPrecautionaryPhraseRecords(casGhsPrecautionaryPhraseCopy);
        return casGhsPrecautionaryPhraseCopy;
    };
    const createCasGhsHazardPhraseCopy = (
        today: Date
    ): CasGhsHazardPhrase[] => {
        const casGhsHazardPhraseCopy: CasGhsHazardPhrase[] =
            casGhsHazardPhraseRecords?.map(
                (casHazardPhrase: any, index: number) => {
                    return {
                        ghsHazardPhrase: casHazardPhrase.ghsHazardPhrase,
                        hazardType: casHazardPhrase.hazardType,
                        hazardTypeId: casHazardPhrase.hazardTypeId,
                        langId: casHazardPhrase.langId,
                        phraseId: casHazardPhrase.phraseId,
                        phraseText: casHazardPhrase.phraseText,
                        sdsSortOrder: casHazardPhrase.sdsSortOrder,
                        businessEntityId: casHazardPhrase.businessEntityId,
                        rowStatus: RowStatus.ADDED,
                        rowId: index + 1,
                        createdAt: today,
                        updatedAt: today,
                    };
                }
            );
        setCasGhsHazardPhraseRecords(casGhsHazardPhraseCopy);
        return casGhsHazardPhraseCopy;
    };

    useEffect(() => {
        if (formType === RoutingValues.copy) {
            const today: Date = new Date(new Date().getTime());

            const casPictogramCopy: CasPictogram =
                createCasPictogramCopy(today);

            const casPropertiesCopy: CasProperty[] =
                createCasPropertiesCopy(today);

            const casExposuresCopy: CasExposure[] =
                createCasExposuresCopy(today);

            const casToxicityCopy: CasToxicity[] = createCasToxicityCopy(today);

            const casEcoToxicityCopy: CasEcoToxicity[] =
                createCasEcoToxicityCopy(today);

            const casRegulationsCopy: CasRegulation[] =
                createCasRegulatoryCopy(today);

            const casHazardsCopy: CasGhsHazardPhrase[] =
                createCasGhsHazardPhraseCopy(today);

            const casPrecautionaryCopy: CasGhsPrecautionaryPhrase[] =
                createCasGhsPrecautionaryPhraseCopy(today);

            setValues({
                ...dataFromQuery,
                id: null,
                identifier: null,
                casPictogram: casPictogramCopy || ({} as CasPictogram),
                casProperties: casPropertiesCopy || [],
                casExposures: casExposuresCopy || [],
                casToxicities: casToxicityCopy || [],
                casEcoToxicities: casEcoToxicityCopy || [],
                casRegulations: casRegulationsCopy || [],
                casGhsHazardPhrases: casHazardsCopy || [],
                casGhsPrecautionaryPhrases: casPrecautionaryCopy || [],
                createdAt: today,
                updatedAt: today,
                uuid: uuidv4(),
            });
        }
    }, [formType, dataFromQuery]);

    return {
        copyMethods: {
            casProperties,
            setCasProperties,
            casExposures,
            setCasExposures,
            casToxicities,
            setCasToxicities,
            casEcoToxicities,
            setCasEcoToxicities,
            casRegulations,
            setCasRegulations,
            casGhsHazardPhraseRecords,
            setCasGhsHazardPhraseRecords,
            casGhsPrecautionaryPhraseRecords,
            setCasGhsPrecautionaryPhraseRecords,
            casHazardClassificationMappings,
            setCasHazardClassificationMappings,
        },
    };
};

export default useCopyCasForm;
