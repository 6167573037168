import React from 'react';
import GhsPrecautionaryPhraseContainer from './GhsPrecautionaryPhraseContainer';

const GhsPrecautionaryPhrase = () => {
    return (
        <>
            <GhsPrecautionaryPhraseContainer showForm={false} />
        </>
    );
};

export default GhsPrecautionaryPhrase;
