import * as Sentry from '@sentry/browser';
import { StringUtils } from '../utils/objectUtils';
import { AlertColor } from '@mui/material';
import { openSnackbarBasicWithMessage } from '../store/uiElements';

const isLocal = StringUtils.equals(process.env.NODE_ENV, 'development');

export function initSentry() {
    if (isLocal) {
        return;
    }

    Sentry.init({
        dsn: 'https://68619c3660014b4d8e73e3ab10ecc7c9@o410909.ingest.sentry.io/5285423',
        environment: process.env.REACT_APP_STAGE,
    });
}

export function logError(error: Error, errorInfo: any = null) {
    if (isLocal) {
        return;
    }

    Sentry.withScope((scope) => {
        errorInfo && scope.setExtras(errorInfo);
        Sentry.captureException(error);
    });
}

export function onError(error: any, doAlertError = true) {
    let errorInfo: any = {};
    let message = error.toString();

    // Auth errors
    if (!(error instanceof Error) && error.message) {
        errorInfo = error;
        message = error.message;
        error = new Error(message);
        // API errors
    } else if (error.config && error.config.url) {
        errorInfo.url = error.config.url;
    }

    logError(error, errorInfo);

    if (doAlertError) {
        alert(message);
    }
}

export function showNotificationError(
    message: string,
    severity: AlertColor,
    dispatch: any
) {
    dispatch(
        openSnackbarBasicWithMessage({ message: message, severity: severity })
    );
    onError(message, false);
}
