import { useContext } from 'react';
import { RootStateOrAny, useSelector } from 'react-redux';
import { skipToken } from '@reduxjs/toolkit/query';
import useBaseForm from '../../../../form/hooks/useBaseForm';
import { ToxicSpecies } from '../../../../../types/formulation';
import { PermissionsUtil } from '../../../../../utils/permissions/permissionsUtil';
import { PERMISSIONS } from '../../../../../constants/permissions/Permissions.constants';
import {
    useCreateToxicSpeciesMutation,
    useDeleteToxicSpeciesMutation,
    useGetToxicSpeciesQuery,
    useUpdateToxicSpeciesMutation,
} from '../../../../../services/formulation/toxicSpecies/toxicSpecies.service';
import SettingsContext from '../../../../../contexts/settings.context';
import { useGetTermSetQuery } from '../../../../../services/i18n/i18n.service';
import { FORMULATION_DEFS } from '../../../../../constants/i18n/translations/termSetDefinitions/formulation';

const useToxicSpeciesForm = (id: string) => {
    const user = useSelector((state: RootStateOrAny) => state.user);

    const { isLoading, data: currentToxicSpecies } = useGetToxicSpeciesQuery(
        id ? id : skipToken
    );

    const canCreateToxicSpecies = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.FORMULATION.TOXIC_SPECIES.CREATE
    );
    const canUpdateToxicSpecies = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.FORMULATION.TOXIC_SPECIES.EDIT
    );
    const canDeleteToxicSpecies = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.FORMULATION.TOXIC_SPECIES.DELETE
    );

    const [createToxicSpecies] = useCreateToxicSpeciesMutation();
    const [updateToxicSpecies] = useUpdateToxicSpeciesMutation();
    const [deleteToxicSpecies] = useDeleteToxicSpeciesMutation();

    const blankToxicSpecies: ToxicSpecies = {
        code: '',
        description: '',
        businessEntityId: user?.businessEntity?.id,
    };

    const { settings } = useContext(SettingsContext);
    const { data: termSet } = useGetTermSetQuery(
        settings?.userSettings
            ? {
                  languageId: settings?.userSettings?.languageId,
                  code: FORMULATION_DEFS.TOXIC_SPECIES_FORM,
              }
            : skipToken
    );

    const {
        fields,
        formMethods,
        handleFieldChange,
        onCreate,
        onUpdate,
        onDelete,
        closeForm,
        hasEntityChanges,
    } = useBaseForm({
        closePath: '/formulation/toxicSpecies',
        reroute: true,
        blankEntity: blankToxicSpecies,
        activeEntity: currentToxicSpecies,
        getDescription: () => {
            return `Toxic Species ${fields.code}`;
        },
        onlyUpdateEntityWithChanges: true,
        createEntity: () => {
            return createToxicSpecies({ postBody: fields });
        },
        updateEntity: () => {
            return updateToxicSpecies({
                id: id as unknown as number,
                postBody: fields,
            });
        },
        deleteEntity: () => {
            return deleteToxicSpecies(id);
        },
    });

    return {
        isLoading,
        fields,
        formMethods,
        handleFieldChange,
        onCreate,
        onUpdate,
        onDelete,
        closeForm,
        canCreateToxicSpecies,
        canDeleteToxicSpecies,
        canUpdateToxicSpecies,
        termSet,
        hasEntityChanges,
    };
};

export default useToxicSpeciesForm;
