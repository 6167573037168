import { RDFormulaHazardClassificationMapping } from '../../../../types/formulation';
import { datacorAppApi } from '../../../datacorAppApi';

const serviceUrl: string = 'rdFormula/rdFormulaHazardClassificationMapping';

export const rdFormulaHazardClassificationMappingApi =
    datacorAppApi.injectEndpoints({
        endpoints: (build) => ({
            getDefaultHazardClassificationMapping: build.query<
                RDFormulaHazardClassificationMapping[],
                void
            >({
                query: () => {
                    return {
                        url: serviceUrl,
                    };
                },
                providesTags: ['RDFormulaHazardClassificationMapping'],
            }),
        }),
    });

export const { useGetDefaultHazardClassificationMappingQuery } =
    rdFormulaHazardClassificationMappingApi;
