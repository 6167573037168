import { FA_AssetEntry } from '../../../../types/FixedAsset.types';
import React from 'react';
import BaseGrid from '../../../../components/grids/BaseGrid';
import DefaultColumnTypes from '../../../../components/grids/columns/Column.constants';
import { ColDef } from 'ag-grid-community';
import { isNilOrEmpty } from '../../../../utils/objectUtils';
import { Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { LoadingButton } from '@mui/lab';
import DeleteIcon from '@mui/icons-material/Delete';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import { dateTimeFormatter } from '../../../../utils/formattingUtils';
import FixedAssetCreateAssetEntriesModal from '../modals/FixedAssetCreateAssetEntriesModal';
import useAssetEntryGrid from './hooks/useAssetEntryGrid';
import { FixedAssetRegisterFormDefs } from '../../../../constants/i18n/translations/termDefinitions/accounting';
import TranslatableText from '../../../../components/i18n/TranslatableText';

export interface AssetEntryGridProps {
    entries: FA_AssetEntry[];
    fixedAssetId: string;
}

const AssetEntryGrid = (props: AssetEntryGridProps) => {
    const {
        handleUnlock,
        handleLock,
        handleDelete,
        setShowCreateAssetEntryModal,
        isDeletingEntry,
        isLockingEntry,
        isUnlockingEntry,
        showCreateAssetEntryModal,
        assetEntryLocked,
        canCreateAssetEntry,
        canDeleteAssetEntry,
        canLockAssetEntry,
        termSet,
    } = useAssetEntryGrid(props);

    const entriesColumnDefs: ColDef[] = [
        {
            field: 'entryDate',
            headerComponentFramework: (props: any) => {
                return (
                    <TranslatableText
                        termSet={termSet}
                        termKey={FixedAssetRegisterFormDefs.AssetEntryDate}
                    />
                );
            },
            ...DefaultColumnTypes.DateOnly,
        },
        {
            field: 'account.number',
            headerComponentFramework: (props: any) => {
                return (
                    <TranslatableText
                        termSet={termSet}
                        termKey={
                            FixedAssetRegisterFormDefs.AssetEntryAccountNumber
                        }
                    />
                );
            },
            ...DefaultColumnTypes.ShortText,
        },
        {
            field: 'account.name',
            headerComponentFramework: (props: any) => {
                return (
                    <TranslatableText
                        termSet={termSet}
                        termKey={
                            FixedAssetRegisterFormDefs.AssetEntryDescription
                        }
                    />
                );
            },
            ...DefaultColumnTypes.MediumText,
        },
        {
            field: 'debit',
            headerComponentFramework: (props: any) => {
                return (
                    <TranslatableText
                        termSet={termSet}
                        termKey={FixedAssetRegisterFormDefs.AssetEntryDebit}
                    />
                );
            },
            ...DefaultColumnTypes.Currency,
        },
        {
            field: 'credit',
            headerComponentFramework: (props: any) => {
                return (
                    <TranslatableText
                        termSet={termSet}
                        termKey={FixedAssetRegisterFormDefs.AssetEntryCredit}
                    />
                );
            },
            ...DefaultColumnTypes.Currency,
        },
        {
            field: 'lockedSummary',
            headerComponentFramework: (props: any) => {
                return (
                    <TranslatableText
                        termSet={termSet}
                        termKey={FixedAssetRegisterFormDefs.AssetEntryLocked}
                    />
                );
            },
            valueGetter: (params) => {
                const entry: FA_AssetEntry = params.data;
                return entry.locked
                    ? `${dateTimeFormatter(entry.lockedDate)} (${
                          entry.lockedUserName
                      })`
                    : undefined;
            },
            ...DefaultColumnTypes.LockedInformationColumn,
        },
    ];

    const entryToolbar = () => {
        return (
            <>
                {isNilOrEmpty(props.entries) &&
                    (canCreateAssetEntry ? (
                        <Button
                            variant="text"
                            size="small"
                            onClick={() => setShowCreateAssetEntryModal(true)}
                            startIcon={
                                <AddIcon style={{ color: '#00A84E' }} />
                            }>
                            Add Asset Entry
                        </Button>
                    ) : null)}
                {!isNilOrEmpty(props.entries) &&
                    !assetEntryLocked &&
                    (canDeleteAssetEntry ? (
                        <LoadingButton
                            variant="text"
                            size="small"
                            loading={isDeletingEntry}
                            onClick={handleDelete}
                            startIcon={
                                <DeleteIcon style={{ color: '#FF0000' }} />
                            }>
                            Delete Asset Entry
                        </LoadingButton>
                    ) : null)}
                {!isNilOrEmpty(props.entries) &&
                    !assetEntryLocked &&
                    (canLockAssetEntry ? (
                        <LoadingButton
                            variant="text"
                            size="small"
                            loading={isLockingEntry}
                            onClick={handleLock}
                            startIcon={
                                <LockIcon style={{ color: '#FFD700' }} />
                            }>
                            Lock Asset Entry
                        </LoadingButton>
                    ) : null)}
                {!isNilOrEmpty(props.entries) &&
                    assetEntryLocked &&
                    (canLockAssetEntry ? (
                        <LoadingButton
                            variant="text"
                            size="small"
                            loading={isUnlockingEntry}
                            onClick={handleUnlock}
                            startIcon={
                                <LockOpenIcon style={{ color: '#FFD700' }} />
                            }>
                            Unlock Asset Entry
                        </LoadingButton>
                    ) : null)}
            </>
        );
    };

    return (
        <React.Fragment>
            <BaseGrid
                rowData={props.entries || []}
                defaultColDef={{ flex: 1 }}
                displayGrid={true}
                displayToolbar={true}
                toolbarChildren={entryToolbar()}
                columnDefs={entriesColumnDefs}
            />

            <FixedAssetCreateAssetEntriesModal
                open={showCreateAssetEntryModal}
                onClose={() => setShowCreateAssetEntryModal(false)}
                ids={[props.fixedAssetId as unknown as bigint]}
                singleRecord={true}
                onAssetEntriesCreated={() => {}}
            />
        </React.Fragment>
    );
};

export default AssetEntryGrid;
