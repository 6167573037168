import React from 'react';
import useUnitOfMeasure from '../hooks/useUnitOfMeasureGrid';
import { ColDef } from 'ag-grid-community';
import BaseFormGrid from '../../../components/grids/BaseFormGrid';

const UnitOfMeasureGrid = () => {
    const {
        unitOfMeasureList,
        redirectToForm,
        isLoadingUnitsOfMeasure,
        canCreateUnitOfMeasure,
        canViewUnitOfMeasure,
    } = useUnitOfMeasure();

    const unitOfMeasureColumnDefs: ColDef[] = [
        {
            field: 'unitCode',
            headerName: 'Unit Code',
            minWidth: 200,
            filter: 'agTextColumnFilter',
        },
        {
            field: 'description',
            headerName: 'Description',
            minWidth: 500,
            filter: 'agTextColumnFilter',
        },
        {
            field: 'decimals',
            headerName: 'Decimals',
            minWidth: 100,
            filter: 'agNumberColumnFilter',
            cellClass: 'ag-right-aligned-cell',
        },
        {
            field: 'unitType.type',
            headerName: 'Unit Type',
            minWidth: 200,
            filter: 'agTextColumnFilter',
        },
    ];

    return (
        <BaseFormGrid
            displayGrid={canViewUnitOfMeasure}
            isLoading={isLoadingUnitsOfMeasure}
            rowData={unitOfMeasureList}
            columnDefs={unitOfMeasureColumnDefs}
            gridClass="full-size-item"
            displayToolbar={true}
            displayCreateNewButton={canCreateUnitOfMeasure}
            displayExportDataButton={false}
            displayResetStateButton={false}
            onCreateNew={redirectToForm}
            onRowClicked={({ data }) => redirectToForm(data)}
            sizeColumnsToFit={true}></BaseFormGrid>
    );
};

export default UnitOfMeasureGrid;
