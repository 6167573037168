import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { isFeatureFlagEnabled } from '../../utils/featureFlag/featureFlagUtil';
import { useLocation } from 'react-router-dom';
import { PermissionsUtil } from '../../utils/permissions/permissionsUtil';

const useRoutes = () => {
    /* istanbul ignore file */
    const user = useSelector((state: RootState) => state.user);
    const { pathname, search } = useLocation();

    const querystring = (name: string, url = window.location.href) => {
        name = name.replace(/[[]]/g, '\\$&');

        const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)', 'i');
        const results = regex.exec(url);

        if (!results) {
            return null;
        }
        if (!results[2]) {
            return '';
        }

        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    };

    const shouldLoadRoute = (
        featureFlagKey: string,
        permissionKey: string,
        datacorAdminOnly: boolean
    ): boolean => {
        let shouldLoadRoute = true;
        if (featureFlagKey) {
            shouldLoadRoute = isFeatureFlagEnabled(
                user.enabledFeatureFlags,
                featureFlagKey
            );
        }

        if (permissionKey) {
            shouldLoadRoute = PermissionsUtil.isPermissionEnabled(
                user.permissions,
                permissionKey
            );
        }

        if (datacorAdminOnly) {
            shouldLoadRoute = user.isDatacorAdmin;
        }

        return shouldLoadRoute;
    };

    const redirect = querystring('redirect');

    return {
        user,
        redirect,
        pathname,
        search,
        shouldLoadRoute,
    };
};

export default useRoutes;
