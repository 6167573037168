import { Grid } from '@mui/material';
import FormPartial from '../../../../../../../../components/form/formContainer/FormPartial';
import React from 'react';
import useCasPictogramForm from './hooks/useCasPictogramForm';
import DatacorLogoSpinner from '../../../../../../../../components/datacorLogoSpinner/DatacorLogoSpinner';
import { CasMaster } from '../../../../../../../../types/formulation';
import { ChildFormProps } from '../../../../../../../form/helpers/ChildFormProps';
import CheckBoxInput from '../../../../../../../../components/form/formInputs/CheckBoxInput/CheckBoxInput';
import AutoCompleteInput from '../../../../../../../../components/form/formInputs/AutoCompleteInput/AutoCompleteInput';
import { CasMasterFormDefs } from '../../../../../../../../constants/i18n/translations/termDefinitions/formulation';
import TranslatableText from '../../../../../../../../components/i18n/TranslatableText';

const CasPictogramForm = (props: ChildFormProps<CasMaster>) => {
    const {
        fields,
        formMethods,
        defaultSignalWordId,
        handlePropertyChanged,
        signalWordOptions,
        isLoadingSignalWord,
        dropdownOptions,
        setDropdownOptions,
        termSet,
    } = useCasPictogramForm(props);

    const { isParentLoading } = props.parentData;

    const { disabledField } = props.permissions;

    return (
        <FormPartial
            title={
                (
                    <TranslatableText
                        termKey={CasMasterFormDefs.Ghs_Pictograms}
                        termSet={termSet}
                    />
                ) as unknown as string
            }>
            {isParentLoading || isLoadingSignalWord ? (
                <DatacorLogoSpinner />
            ) : (
                <>
                    <Grid container spacing={2}>
                        <Grid item xs={2}>
                            <CheckBoxInput
                                id="explosive"
                                label={
                                    (
                                        <TranslatableText
                                            termKey={
                                                CasMasterFormDefs.Explosive
                                            }
                                            termSet={termSet}
                                        />
                                    ) as unknown as string
                                }
                                checked={fields.explosive}
                                onChange={handlePropertyChanged}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <CheckBoxInput
                                id="flammable"
                                label={
                                    (
                                        <TranslatableText
                                            termKey={
                                                CasMasterFormDefs.Flammable
                                            }
                                            termSet={termSet}
                                        />
                                    ) as unknown as string
                                }
                                checked={fields.flammable}
                                onChange={handlePropertyChanged}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <CheckBoxInput
                                id="oxidizing"
                                label={
                                    (
                                        <TranslatableText
                                            termKey={
                                                CasMasterFormDefs.Oxidizing
                                            }
                                            termSet={termSet}
                                        />
                                    ) as unknown as string
                                }
                                checked={fields.oxidizing}
                                onChange={handlePropertyChanged}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <CheckBoxInput
                                id="compressedGas"
                                label={
                                    (
                                        <TranslatableText
                                            termKey={
                                                CasMasterFormDefs.Compressed_Gas
                                            }
                                            termSet={termSet}
                                        />
                                    ) as unknown as string
                                }
                                checked={fields.compressedGas}
                                onChange={handlePropertyChanged}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <CheckBoxInput
                                id="toxic"
                                label={
                                    (
                                        <TranslatableText
                                            termKey={CasMasterFormDefs.Toxic}
                                            termSet={termSet}
                                        />
                                    ) as unknown as string
                                }
                                checked={fields.toxic}
                                onChange={handlePropertyChanged}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <CheckBoxInput
                                id="corrosive"
                                label={
                                    (
                                        <TranslatableText
                                            termKey={
                                                CasMasterFormDefs.Corrosive
                                            }
                                            termSet={termSet}
                                        />
                                    ) as unknown as string
                                }
                                checked={fields.corrosive}
                                onChange={handlePropertyChanged}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <CheckBoxInput
                                id="irritant"
                                label={
                                    (
                                        <TranslatableText
                                            termKey={CasMasterFormDefs.Irritant}
                                            termSet={termSet}
                                        />
                                    ) as unknown as string
                                }
                                checked={fields.irritant}
                                onChange={handlePropertyChanged}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <CheckBoxInput
                                id="healthHazard"
                                label={
                                    (
                                        <TranslatableText
                                            termKey={
                                                CasMasterFormDefs.Health_Hazard
                                            }
                                            termSet={termSet}
                                        />
                                    ) as unknown as string
                                }
                                checked={fields.healthHazard}
                                onChange={handlePropertyChanged}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <CheckBoxInput
                                id="environmentDamaging"
                                label={
                                    (
                                        <TranslatableText
                                            termKey={
                                                CasMasterFormDefs.Environment_Damaging
                                            }
                                            termSet={termSet}
                                        />
                                    ) as unknown as string
                                }
                                checked={fields.environmentDamaging}
                                onChange={handlePropertyChanged}
                            />
                        </Grid>
                    </Grid>
                    <br />
                    <Grid container spacing={2}>
                        <Grid item xs={2}>
                            <AutoCompleteInput
                                id="casPictogramSignalWordId"
                                inputFieldName="signalWordInputValue"
                                label={
                                    <TranslatableText
                                        termKey={CasMasterFormDefs.Signal_Word}
                                        termSet={termSet}
                                    />
                                }
                                defaultOption={defaultSignalWordId}
                                onHandlePropertyChanged={handlePropertyChanged}
                                options={signalWordOptions}
                                setDropdownOptions={setDropdownOptions}
                                value={dropdownOptions?.selectedSignalWord}
                                inputValue={
                                    dropdownOptions?.signalWordInputValue
                                }
                                isLoading={isLoadingSignalWord}
                                formMethods={formMethods}
                                required={true}
                                disabled={disabledField}
                            />
                        </Grid>
                    </Grid>
                </>
            )}
        </FormPartial>
    );
};
export default CasPictogramForm;
