import { AgGridColumn } from 'ag-grid-react';
import React from 'react';
import moment from 'moment';
import DatacorLogoSpinner from '../../components/datacorLogoSpinner/DatacorLogoSpinner';
import usePermissionRolesGrid from './hooks/usePermissionRolesGrid';
import BaseGrid from '../../components/grids/BaseGrid';
import { PermissionsGridDefs } from '../../constants/i18n/translations/termDefinitions/platform';
import CustomHeader from '../../components/grids/CustomHeader';
import { dateValueComparator } from '../../utils/comparisonUtils';
import { dateFormatter } from '../../utils/formattingUtils';

const PermissionRoleGrid = () => {
    const {
        onGridReady,
        rowData,
        isLoading,
        redirectToForm,
        onSortChanged,
        onFirstDataRendered,
        termSet,
    } = usePermissionRolesGrid();

    return (
        <>
            {!isLoading ? (
                <BaseGrid
                    testId="permission-role-grid"
                    dataKey="id"
                    displayGrid={true}
                    immutableData={true}
                    rowData={rowData}
                    displayToolbar={false}
                    onRowClicked={({ data }) => redirectToForm(data)}
                    onFirstDataRendered={onFirstDataRendered}
                    onSortChanged={onSortChanged}
                    onGridReady={onGridReady}
                    defaultColDef={{
                        resizable: true,
                        sortable: true,
                        unSortIcon: true,
                        filter: true,
                        floatingFilter: true,
                        wrapText: true,
                    }}>
                    <AgGridColumn
                        flex={1}
                        minWidth={250}
                        filter={'agTextColumnFilter'}
                        field="name"
                        headerComponentFramework={(props: any) => {
                            return (
                                <CustomHeader
                                    {...props}
                                    termSet={termSet}
                                    termKey={PermissionsGridDefs.Name}
                                />
                            );
                        }}
                    />
                    <AgGridColumn
                        minWidth={200}
                        filter={'agTextColumnFilter'}
                        field="description"
                        headerComponentFramework={(props: any) => {
                            return (
                                <CustomHeader
                                    {...props}
                                    termSet={termSet}
                                    termKey={PermissionsGridDefs.Description}
                                />
                            );
                        }}
                    />
                    <AgGridColumn
                        minWidth={200}
                        filter={'agTextColumnFilter'}
                        field="businessEntity.name"
                        headerComponentFramework={(props: any) => {
                            return (
                                <CustomHeader
                                    {...props}
                                    termSet={termSet}
                                    termKey={
                                        PermissionsGridDefs.Business_Entity
                                    }
                                />
                            );
                        }}
                    />
                    <AgGridColumn
                        minWidth={200}
                        filter={'agDateColumnFilter'}
                        filterParams={dateValueComparator}
                        field="createdAt"
                        headerComponentFramework={(props: any) => {
                            return (
                                <CustomHeader
                                    {...props}
                                    termSet={termSet}
                                    termKey={PermissionsGridDefs.Date_Created}
                                />
                            );
                        }}
                        valueFormatter={dateFormatter}
                    />
                    <AgGridColumn
                        minWidth={200}
                        filter={'agDateColumnFilter'}
                        filterParams={dateValueComparator}
                        field="updatedAt"
                        headerComponentFramework={(props: any) => {
                            return (
                                <CustomHeader
                                    {...props}
                                    termSet={termSet}
                                    termKey={
                                        PermissionsGridDefs.Last_Update_Date
                                    }
                                />
                            );
                        }}
                        valueFormatter={dateFormatter}
                    />
                </BaseGrid>
            ) : (
                <DatacorLogoSpinner />
            )}
        </>
    );
};

export default PermissionRoleGrid;
