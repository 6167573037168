import React, { useContext, useState } from 'react';
import { FormMethods } from '../../../libs/hooksLib';
import {
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Grid,
} from '@mui/material';
import BaseFormToolbar, { BaseFormToolbarProps } from './BaseFormToolbar';
import DatacorLogoSpinner from '../../datacorLogoSpinner/DatacorLogoSpinner';
import FormContainer from '../formContainer/FormContainer';
import _ from 'lodash';
import TranslatableText from '../../i18n/TranslatableText';
import { SharedComponentsDefs } from '../../../constants/i18n/translations/termDefinitions/platform';
import { usePrompt } from './usePrompt';
import NameDisplay from '../../display/NameDisplay';
import { dateTimeFormatter } from '../../../utils/formattingUtils';
import SharedTranslationsContext from '../../../contexts/translations.context';
import NavFormContext from '../../../contexts/navForm.context';

export interface BaseFormProps extends BaseFormToolbarProps {
    title: any;
    containerTitle?: string;
    formMethods: FormMethods | FormMethods[];
    children: React.ReactNode;
    isLoading: boolean;
    revisionEntityTypeId?: number;
    readOnly?: boolean;
    termSet?: any;
    auditableFields?: any;
    sections?: any[];
    entityType?: string;
    documentQuery?: any;
}

export type ParamTypes = {
    id: string;
    type?: string;
    group?: string;
};

const BaseForm = (props: BaseFormProps) => {
    const { sharedNav } = useContext(NavFormContext);

    const [showConfirm, setShowConfirm] = useState(true);
    usePrompt(
        'Unsaved Changes will be lost. Do you wish to proceed?',
        props?.hasEntityChanges
            ? sharedNav.isCloseFormValid && props?.hasEntityChanges()
            : false,
        showConfirm
    );

    /**
     * global context for shared translations
     * doesn't refetch on page load
     */
    const { sharedTranslations } = useContext(SharedTranslationsContext);
    return (
        <Card data-testid="formModule">
            <CardHeader title={<>{props.title}</>} />

            {props.isLoading ? (
                <DatacorLogoSpinner />
            ) : (
                <>
                    <CardContent>
                        <Grid container spacing={0}>
                            <Grid item xs={12}>
                                <FormContainer
                                    sections={props?.sections}
                                    headerTitle={
                                        props.containerTitle || (
                                            <TranslatableText
                                                termSet={sharedTranslations}
                                                termKey={
                                                    SharedComponentsDefs.Primary_Information
                                                }
                                            />
                                        )
                                    }
                                    onSubmit={() => {
                                        if (_.isArray(props.formMethods)) {
                                            props.formMethods.forEach(
                                                (formMethod: FormMethods) => {
                                                    formMethod.setIsFormSubmitted(
                                                        true
                                                    );
                                                }
                                            );
                                        } else {
                                            props.formMethods.setIsFormSubmitted(
                                                true
                                            );
                                        }
                                    }}
                                    headerComponent={
                                        <BaseFormToolbar
                                            {...props}
                                            setShowConfirm={setShowConfirm}
                                            termSet={sharedTranslations}
                                        />
                                    }>
                                    <div
                                        style={{
                                            paddingTop: '10px',
                                        }}>
                                        {props.readOnly ? (
                                            <fieldset disabled>
                                                {props.children}
                                            </fieldset>
                                        ) : (
                                            <div
                                                style={{
                                                    overflowY: 'scroll',
                                                    overflowX: 'hidden',
                                                    maxHeight:
                                                        props?.auditableFields
                                                            ? '55vh'
                                                            : '60vh',
                                                    paddingTop: '10px',
                                                }}>
                                                {props.children}
                                            </div>
                                        )}
                                    </div>
                                </FormContainer>
                            </Grid>
                        </Grid>
                    </CardContent>
                    {props.auditableFields && (
                        <CardActions>
                            <div
                                style={{
                                    color: 'rgba(0, 0, 0, 0.6)',
                                    fontFamily:
                                        'Roboto,Helvetica,Arial,sans-serif',
                                    fontWeight: '400',
                                    fontSize: '12px',
                                    position: 'relative',
                                    display: 'block',
                                    transformOrigin: 'top left',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    maxWidth: 'calc(133% - 24px)',
                                }}>
                                Added by:{' '}
                                <NameDisplay
                                    fields={props.auditableFields.createdByUser}
                                />{' '}
                                [
                                {dateTimeFormatter(
                                    props.auditableFields.createdAt
                                )}
                                ]<br />
                                Updated last by:{' '}
                                <NameDisplay
                                    fields={props.auditableFields.updatedByUser}
                                />{' '}
                                [
                                {dateTimeFormatter(
                                    props.auditableFields.updatedAt
                                )}
                                ]
                            </div>
                        </CardActions>
                    )}
                </>
            )}
        </Card>
    );
};

export default BaseForm;
