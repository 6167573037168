import { InventoryType } from '../../../../types/formulation';
import { datacorAppApi } from '../../../datacorAppApi';

const serviceUrl: string = 'inventory-types';

export const inventoryTypeApi = datacorAppApi.injectEndpoints({
    endpoints: (build) => ({
        getAllInventoryTypes: build.query<InventoryType[], void>({
            query: () => serviceUrl,
            providesTags: ['InventoryType'],
        }),
        getInventoryTypesForDropdown: build.query<
            { label: string; value: bigint }[],
            void
        >({
            query: () => serviceUrl,
            transformResponse: (response: InventoryType[]) => {
                return response.map((inventoryTypes) => {
                    return {
                        label: inventoryTypes.type,
                        value: inventoryTypes.id,
                    };
                });
            },
            providesTags: ['InventoryType'],
        }),
    }),
});

export const {
    useGetAllInventoryTypesQuery,
    useGetInventoryTypesForDropdownQuery,
} = inventoryTypeApi;
