import React from 'react';
import { isNilOrEmpty } from '../../../../../../../../utils/objectUtils';
import DatacorLogoSpinner from '../../../../../../../../components/datacorLogoSpinner/DatacorLogoSpinner';
import BaseGrid from '../../../../../../../../components/grids/BaseGrid';
import useSdsEcoToxicityGrid from './hooks/useSdsEcoToxicityGrid';
import FormPartial from '../../../../../../../../components/form/formContainer/FormPartial';
import {
    SdsEcoToxicity,
    SdsHeader,
} from '../../../../../../../../types/formulation';
import { ChildEditableGridWithCopyFunctionProps } from '../../../../../../../grid/utils/editableGrid/ChildEditableGridWithCopyFunctionProps';

const SdsEcoToxicityGrid = (
    props: ChildEditableGridWithCopyFunctionProps<SdsHeader, SdsEcoToxicity>
) => {
    const {
        isLoadingToxicTests,
        isLoadingToxicSpecies,
        isLoadingSpeciesClasses,
        isLoadingCas,
        ecoToxicityEntityColumnDefs,
        handleGridEdits,
        handleRowValidations,
        colDefOptions,
        rowsData,
    } = useSdsEcoToxicityGrid(props);

    const { isParentLoading } = props.parentData;

    const { showAddButton, showDeleteButton, isGridEditable } =
        props.displayGridButtons;

    return isLoadingToxicTests ||
        isLoadingToxicSpecies ||
        isLoadingSpeciesClasses ||
        isLoadingCas ||
        isNilOrEmpty(ecoToxicityEntityColumnDefs) ? (
        <DatacorLogoSpinner />
    ) : (
        <FormPartial>
            <br />
            <BaseGrid
                autoSize={true}
                displayGrid={!isParentLoading}
                displayCreateNewButton={showAddButton}
                displayDeleteButton={showDeleteButton}
                useRowDrag={isGridEditable}
                displaySortButton={isGridEditable}
                sortKey={'sortOrder'}
                rowData={rowsData || []}
                dataKey={'rowId'}
                defaultColDef={colDefOptions}
                gridClass="full-size-item"
                displayToolbar={true}
                sizeColumnsToFit={true}
                onHandleGridEdits={handleGridEdits}
                isEditable={isGridEditable}
                onHandleRowValidations={handleRowValidations}
                columnDefs={ecoToxicityEntityColumnDefs}
                newRowPlaceholderValues={{
                    casNumber: 'Enter a CAS Number...',
                    toxicTestCode: 'Enter a Test Type...',
                    toxicSpeciesCode: 'Enter a Toxic Species...',
                    effectiveDose: 'Enter an Effective Dose...',
                    exposureTime: 'Enter an Exposure Time...',
                    notes: 'Enter Note...',
                    source: 'Enter a Source....',
                    method: 'Enter a Method...',
                    speciesClassId: 'Enter a Species Class...',
                }}></BaseGrid>
        </FormPartial>
    );
};
export default SdsEcoToxicityGrid;
