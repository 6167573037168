import React from 'react';
import LoaderButton from '../../../../components/LoaderButton';
import { Inventory2 } from '@mui/icons-material';
import TranslatableText from '../../../../components/i18n/TranslatableText';
import { RDFormulasFormDefs } from '../../../../constants/i18n/translations/termDefinitions/formulation';

export interface rdFormulaPublishToProductButtonProps {
    displayPublishProductButton: boolean;
    setShowPublishToProductModal: (value: boolean) => void;
    isLoadingFormulas: boolean;
    termSet: any;
}

const RDFormulaPublishToProductButton = (
    props: rdFormulaPublishToProductButtonProps
) => {
    return (
        <LoaderButton
            className="form-header-button"
            variant="text"
            style={{ minWidth: '125px' }}
            onClick={() => props.setShowPublishToProductModal(true)}
            disabled={!props.displayPublishProductButton}
            isLoading={props.isLoadingFormulas}>
            <Inventory2 />
            <div className="form-header-button-text">
                <TranslatableText
                    termSet={props.termSet}
                    termKey={RDFormulasFormDefs.Publish_To_Product_Button}
                />
            </div>
        </LoaderButton>
    );
};

export default RDFormulaPublishToProductButton;
