import {
    useDeleteFixedAssetAssetEntriesMutation,
    useLockFixedAssetAssetEntriesMutation,
    useUnlockFixedAssetAssetEntriesMutation,
} from '../../../../../services/fixedAssets/fixedAssets.service';
import React, { useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../store';
import { PermissionsUtil } from '../../../../../utils/permissions/permissionsUtil';
import { PERMISSIONS } from '../../../../../constants/permissions/Permissions.constants';
import { AssetEntryGridProps } from '../AssetEntryGrid';
import SettingsContext from '../../../../../contexts/settings.context';
import { useGetTermSetQuery } from '../../../../../services/i18n/i18n.service';
import { ACCOUNTING_DEFS } from '../../../../../constants/i18n/translations/termSetDefinitions/accounting';
import { skipToken } from '@reduxjs/toolkit/query';

export const useAssetEntryGrid = (props: AssetEntryGridProps) => {
    const user = useSelector((state: RootState) => state.user);
    const [showCreateAssetEntryModal, setShowCreateAssetEntryModal] =
        useState<boolean>(false);
    const { settings } = useContext(SettingsContext);
    const { data: termSet } = useGetTermSetQuery(
        settings?.userSettings
            ? {
                  languageId: settings?.userSettings?.languageId,
                  code: ACCOUNTING_DEFS.FIXED_ASSET_REGISTER_FORM,
              }
            : skipToken
    );
    const assetEntryLocked =
        props.entries.length > 0 && props.entries.some((entry) => entry.locked);

    const canCreateAssetEntry = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.FIXED_ASSETS.ASSET_ENTRY.CREATE
    );
    const canLockAssetEntry = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.FIXED_ASSETS.ASSET_ENTRY.LOCK
    );
    const canDeleteAssetEntry = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.FIXED_ASSETS.ASSET_ENTRY.DELETE
    );
    const [lockAssetEntry, { isLoading: isLockingEntry }] =
        useLockFixedAssetAssetEntriesMutation();
    const [unlockAssetEntry, { isLoading: isUnlockingEntry }] =
        useUnlockFixedAssetAssetEntriesMutation();
    const [deleteAssetEntry, { isLoading: isDeletingEntry }] =
        useDeleteFixedAssetAssetEntriesMutation();

    const handleDelete = async (event: any) => {
        event.preventDefault();

        try {
            await deleteAssetEntry({
                fixedAssetId: props.fixedAssetId,
            });
        } catch (e) {
            console.log(e);
        }
    };

    const handleLock = async (event: any) => {
        event.preventDefault();

        try {
            await lockAssetEntry({
                ids: props.entries.map((entry) => entry.id),
            });
        } catch (e) {
            console.log(e);
        }
    };

    const handleUnlock = async (event: any) => {
        event.preventDefault();

        try {
            await unlockAssetEntry({
                ids: props.entries.map((entry) => entry.id),
            });
        } catch (e) {
            console.log(e);
        }
    };

    return {
        handleUnlock,
        handleLock,
        handleDelete,
        setShowCreateAssetEntryModal,
        isDeletingEntry,
        isLockingEntry,
        isUnlockingEntry,
        showCreateAssetEntryModal,
        assetEntryLocked,
        canCreateAssetEntry,
        canDeleteAssetEntry,
        canLockAssetEntry,
        termSet,
    };
};

export default useAssetEntryGrid;
