import React from 'react';
import UseFixedAssetDepreciationMethodStatusGrid from './hooks/useFixedAssetDepreciationMethodStatusGrid';
import BaseFormGrid from '../../components/grids/BaseFormGrid';
import { cloneDeep } from 'lodash';
import { DepreciationMethodStatusesGridDefs } from '../../constants/i18n/translations/termDefinitions/accounting';
import TranslatableText from '../../components/i18n/TranslatableText';
import { isNilOrEmpty } from '../../utils/objectUtils';

const FixedAssetDepreciationMethodStatusGrid = () => {
    const {
        statusList,
        redirectToForm,
        isLoadingStatuses,
        canCreateDepreciationMethodStatus,
        canViewDepreciationMethodStatus,
        canUpdate,
        handleGridEdits,
        rowData,
        colDefs,
        termSet,
    } = UseFixedAssetDepreciationMethodStatusGrid();

    return (
        <BaseFormGrid
            title={
                (
                    <TranslatableText
                        termSet={termSet}
                        termKey={
                            DepreciationMethodStatusesGridDefs.Depreciation_Method_Statuses
                        }
                    />
                ) as unknown as string
            }
            displayGrid={canViewDepreciationMethodStatus}
            isLoading={isNilOrEmpty(colDefs)}
            rowData={cloneDeep(rowData)}
            useRowDrag={canUpdate}
            sortKey={'sortOrder'}
            columnDefs={colDefs}
            gridClass="full-size-item"
            displayToolbar={true}
            displayCreateNewButton={canCreateDepreciationMethodStatus}
            displayExportDataButton={true}
            displayResetStateButton={true}
            onCreateNew={redirectToForm}
            onRowClicked={({ data }) => redirectToForm(data)}
            onHandleGridEdits={handleGridEdits}
        />
    );
};

export default FixedAssetDepreciationMethodStatusGrid;
