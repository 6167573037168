import { FixedAssetDisposalEntriesProps } from '../FixedAssetDisposalEntriesOptionsModal';
import { useForm } from '../../../../libs/hooksLib';
import {
    useGetAllFixedAssetDisposalEntriesQuery,
    useGetTypesForDropdownQuery,
} from '../../../../services/fixedAssets/fixedAssets.service';
import { skipToken } from '@reduxjs/toolkit/query';
import { useContext, useEffect, useState } from 'react';
import { FixedAssetDisposalEntryListParams } from '../../../../types/FixedAsset.types';
import { isNil, isNilOrEmpty } from '../../../../utils/objectUtils';
import { SelectionOption } from '../../../../types/Shared.types';
import SettingsContext from '../../../../contexts/settings.context';
import { useGetTermSetQuery } from '../../../../services/i18n/i18n.service';
import { ACCOUNTING_DEFS } from '../../../../constants/i18n/translations/termSetDefinitions/accounting';

const useFixedAssetDisposalEntriesOptionsModal = (
    props: FixedAssetDisposalEntriesProps
) => {
    const inicialFields: any = {
        assetTypes: [],
        startDate: null,
        endDate: null,
    };

    const { settings } = useContext(SettingsContext);
    const { data: termSet } = useGetTermSetQuery(
        settings?.userSettings
            ? {
                  languageId: settings?.userSettings?.languageId,
                  code: ACCOUNTING_DEFS.DISPOSAL_ENTRIES_GRID,
              }
            : skipToken
    );
    const { fields, handleFieldChange } = useForm(inicialFields);
    const [queryParams, setQueryParams] =
        useState<FixedAssetDisposalEntryListParams>(null);

    const { data: assetTypeOptions, isLoading: loadingAssetTypes } =
        useGetTypesForDropdownQuery();

    const { data: disposalEntryList, isFetching: isLoadingDisposalEntries } =
        useGetAllFixedAssetDisposalEntriesQuery(
            queryParams ? queryParams : skipToken
        );

    const handleGetDisposalEntries = (event: any) => {
        event.preventDefault();

        const listParams: FixedAssetDisposalEntryListParams = {};

        if (!isNilOrEmpty(fields.assetTypes)) {
            listParams['assetTypeIds'] = fields.assetTypes.map(
                (option: SelectionOption) => option.value
            );
        }
        if (!isNil(fields.startDate)) {
            listParams['startDate'] = fields.startDate;
        }
        if (!isNil(fields.endDate)) {
            listParams['endDate'] = fields.endDate;
        }

        setQueryParams(listParams);
    };

    useEffect(() => {
        if (disposalEntryList) {
            props.onDisposalEntriesLoaded(disposalEntryList);
            props.onClose();
        }
    }, [disposalEntryList]);

    return {
        queryParams,
        fields,
        handleFieldChange,
        assetTypeOptions,
        loadingAssetTypes,
        handleGetDisposalEntries,
        isLoadingDisposalEntries,
        termSet,
    };
};

export default useFixedAssetDisposalEntriesOptionsModal;
