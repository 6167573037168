import React, { useContext, useEffect, useState } from 'react';
import { isNilOrEmpty } from '../../../../../../../utils/objectUtils';
import { ValueGetterParams } from 'ag-grid-community';
import { editableGridCellSelect } from '../../../../../../../components/grids/columns/editableGridCellSelect';
import { GridOptions } from '../../../../../../../components/grids/Grid.constants';
import { PermissionsUtil } from '../../../../../../../utils/permissions/permissionsUtil';
import { PERMISSIONS } from '../../../../../../../constants/permissions/Permissions.constants';
import { CasMaster, CasToxicity } from '../../../../../../../types/formulation';
import { ColumnMaxValueValidator } from '../../../../../../grid/validators/columnMaxValue.validator';
import _ from 'lodash';
import { ColumnRequiredValueValidator } from '../../../../../../grid/validators/columnRequiredValue.validator';
import applyEditableGridValidations from '../../../../../../grid/utils/applyEditableGridValidations';
import DefaultColumnTypes from '../../../../../../../components/grids/columns/Column.constants';
import { RowStatus } from '../../../../../../../components/grids/hooks/useBaseGridEditable';
import { useListToxicRouteDropdownQuery } from '../../../../../../../services/formulation/casMaster/casToxicRoutes/casToxicRoutes.service';
import { useGetToxicTestDropdownQuery } from '../../../../../../../services/formulation/toxicTest/toxicTest.service';
import { useGetToxicSpeciesDropdownQuery } from '../../../../../../../services/formulation/toxicSpecies/toxicSpecies.service';
import { SelectedDropdownOption } from '../../../../../../../types/Shared.types';
import { ChildEditableGridWithCopyFunctionProps } from '../../../../../../grid/utils/editableGrid/ChildEditableGridWithCopyFunctionProps';
import SettingsContext from '../../../../../../../contexts/settings.context';
import { useGetTermSetQuery } from '../../../../../../../services/i18n/i18n.service';
import { FORMULATION_DEFS } from '../../../../../../../constants/i18n/translations/termSetDefinitions/formulation';
import { skipToken } from '@reduxjs/toolkit/query';
import CustomHeader from '../../../../../../../components/grids/CustomHeader';
import { CasMasterFormDefs } from '../../../../../../../constants/i18n/translations/termDefinitions/formulation';

const useCasToxicityGrid = (
    props: ChildEditableGridWithCopyFunctionProps<CasMaster, CasToxicity>
) => {
    const { currentParentRecord, user } = props.parentData;

    const { showDeleteButton, isGridEditable } = props.displayGridButtons;

    const { handleChildrenRecords } = props.helpers;

    const { rowsData, setRowsData } = props.copyMethods;

    const [maxRowId, setMaxRowId] = useState(0);

    const canViewToxicTest = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.FORMULATION.TOXIC_TEST.VIEW
    );

    const canViewToxicSpecies = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.FORMULATION.TOXIC_SPECIES.VIEW
    );

    const { data: casRouteOptions, isLoading: isLoadingCasRoutes } =
        useListToxicRouteDropdownQuery();

    const { data: toxicTestOptions, isLoading: isLoadingToxicTests } =
        useGetToxicTestDropdownQuery();

    const { data: toxicSpeciesOptions, isLoading: isLoadingToxicSpecies } =
        useGetToxicSpeciesDropdownQuery();

    const [toxicityEntityColumnDefs, setToxicityEntityColumnDefs] =
        useState(null);

    const [toxicTestColumnDefs, setToxicTestColumnDefs] = useState(null);

    const [toxicSpeciesColumnDefs, setToxicSpeciesColumnDefs] = useState(null);

    const colDefOptions = {
        ...GridOptions.sortFilterAndWrapColumns,
        floatingFilter: true,
    };

    const { settings } = useContext(SettingsContext);
    const { data: termSet } = useGetTermSetQuery(
        settings?.userSettings
            ? {
                  languageId: settings?.userSettings?.languageId,
                  code: FORMULATION_DEFS.CAS_MASTER_FORM,
              }
            : skipToken
    );

    const getToxicTestCode = (toxicTestId: bigint): SelectedDropdownOption => {
        let toxicTestCode: SelectedDropdownOption = { id: null, display: '' };
        if (!isNilOrEmpty(toxicTestId)) {
            toxicTestOptions?.find((current: any) => {
                if (current.id === toxicTestId) {
                    toxicTestCode = {
                        id: current.id,
                        display: current.toxicTestCode,
                    };
                }
            });
        }
        return toxicTestCode;
    };

    const getToxicSpeciesCode = (
        toxicSpeciesId: bigint
    ): SelectedDropdownOption => {
        let toxicSpeciesCode: SelectedDropdownOption = {
            id: null,
            display: '',
        };
        if (!isNilOrEmpty(toxicSpeciesId)) {
            toxicSpeciesOptions?.find((current: any) => {
                if (current.id === toxicSpeciesId) {
                    toxicSpeciesCode = {
                        id: current.id,
                        display: current.toxicSpeciesCode,
                    };
                }
            });
        }
        return toxicSpeciesCode;
    };

    const findFieldDescription = (id: bigint, options: any): string => {
        let description: string;
        options?.find((current: any) => {
            if (current.id === id) {
                description = current.description;
            }
        });
        return description;
    };

    const getRouteDescriptionById = (routeId: bigint): string => {
        if (routeId && casRouteOptions) {
            return casRouteOptions.find((route) => route.value === routeId)
                ?.label;
        }
        return null;
    };

    const sortCasToxicities = (casToxicities: CasToxicity[]) => {
        setRowsData(
            _.orderBy(
                casToxicities,
                ['sortOrder', 'routeId', 'toxicTest.code', 'toxicSpecies.code'],
                ['asc']
            )
        );
    };

    useEffect(() => {
        if (!isLoadingToxicTests && !isNilOrEmpty(toxicTestOptions)) {
            setToxicTestColumnDefs([
                {
                    field: 'toxicTestCode',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={
                                    CasMasterFormDefs.Toxicity_Test_Type_Form_Toxic_Test_Code
                                }
                            />
                        );
                    },
                    cellClass: 'ag-left-aligned-cell',
                },
                {
                    field: 'description',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={
                                    CasMasterFormDefs.Toxicity_Test_Type_Form_Description
                                }
                            />
                        );
                    },
                    cellClass: 'ag-left-aligned-cell',
                },
            ]);
        }
    }, [isLoadingToxicTests, toxicTestOptions]);

    useEffect(() => {
        if (!isLoadingToxicSpecies && !isNilOrEmpty(toxicSpeciesOptions)) {
            setToxicSpeciesColumnDefs([
                {
                    field: 'toxicSpeciesCode',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={
                                    CasMasterFormDefs.Toxicity_Species_Form_Toxic_Specie_Code
                                }
                            />
                        );
                    },
                    cellClass: 'ag-left-aligned-cell',
                },
                {
                    field: 'description',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={
                                    CasMasterFormDefs.Toxicity_Species_Form_Description
                                }
                            />
                        );
                    },
                    cellClass: 'ag-left-aligned-cell',
                },
            ]);
        }
    }, [isLoadingToxicSpecies, toxicSpeciesOptions]);

    useEffect(() => {
        const includeEditedRows = currentParentRecord?.casToxicities
            ? !isNilOrEmpty(
                  currentParentRecord.casToxicities.find(
                      (current) => current.rowStatus
                  )
              )
            : false;
        if (
            currentParentRecord?.casToxicities &&
            !isNilOrEmpty(toxicTestOptions) &&
            !isNilOrEmpty(toxicSpeciesOptions) &&
            !includeEditedRows
        ) {
            const casToxicities: CasToxicity[] = [];
            let index = maxRowId;
            currentParentRecord?.casToxicities.forEach(
                (record: CasToxicity) => {
                    index = index + 1;
                    setMaxRowId((maxRowId) => maxRowId + 1);
                    if (record.rowStatus !== RowStatus.DELETED) {
                        const casToxicity: CasToxicity = {
                            ...record,
                            toxicTestCode: getToxicTestCode(record.toxicTestId),
                            toxicSpeciesCode: getToxicSpeciesCode(
                                record.toxicSpeciesId
                            ),
                            rowId: record.rowId ? record.rowId : index,
                        };
                        casToxicities.push(casToxicity);
                    }
                }
            );
            sortCasToxicities(casToxicities);
        }
    }, [
        currentParentRecord?.casToxicities,
        toxicTestOptions,
        toxicSpeciesOptions,
    ]);

    useEffect(() => {
        if (
            !isLoadingCasRoutes &&
            !isNilOrEmpty(casRouteOptions) &&
            !isLoadingToxicTests &&
            !isNilOrEmpty(toxicTestOptions) &&
            !isNilOrEmpty(toxicTestColumnDefs) &&
            !isLoadingToxicSpecies &&
            !isNilOrEmpty(toxicSpeciesOptions) &&
            !isNilOrEmpty(toxicSpeciesColumnDefs) &&
            isNilOrEmpty(toxicityEntityColumnDefs)
        ) {
            setToxicityEntityColumnDefs([
                {
                    field: 'deleteColumn',
                    minWidth: 50,
                    hide: !showDeleteButton || !isGridEditable,
                    filter: false,
                },
                {
                    field: 'routeId',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={CasMasterFormDefs.Toxicity_Route}
                            />
                        );
                    },
                    minWidth: 100,
                    filter: 'agTextColumnFilter',
                    ...editableGridCellSelect(casRouteOptions),
                    editable: isGridEditable,
                },
                {
                    field: 'toxicTestCode',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={CasMasterFormDefs.Toxicity_Test_Type}
                            />
                        );
                    },
                    minWidth: 150,
                    editable: canViewToxicTest && isGridEditable,
                    cellRenderer: (params: any) => params.value.display,
                    useGridPopup: true,
                    gridPopupParameters: {
                        isLoading: isLoadingToxicTests,
                        displayGrid: true,
                        rowData: toxicTestOptions,
                        defaultColDef: colDefOptions,
                        columnDefs: toxicTestColumnDefs,
                        displayField: 'toxicTestCode',
                    },
                    ...GridOptions.sortFilterAndWrapColumns,
                },
                {
                    field: 'toxicTestDescription',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={
                                    CasMasterFormDefs.Toxicity_Test_Description
                                }
                            />
                        );
                    },
                    minWidth: 200,
                    editable: false,
                    valueGetter: (params: ValueGetterParams) => {
                        params.data.description = findFieldDescription(
                            params.data.toxicTestCode?.id,
                            toxicTestOptions
                        );
                        return params.data.description;
                    },
                    ...GridOptions.sortFilterAndWrapColumns,
                    filter: 'agTextColumnFilter',
                },
                {
                    field: 'toxicSpeciesCode',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={CasMasterFormDefs.Toxicity_Species}
                            />
                        );
                    },
                    minWidth: 150,
                    editable: canViewToxicSpecies && isGridEditable,
                    cellRenderer: (params: any) => params.value.display,
                    useGridPopup: true,
                    gridPopupParameters: {
                        isLoading: isLoadingToxicSpecies,
                        displayGrid: true,
                        rowData: toxicSpeciesOptions,
                        defaultColDef: colDefOptions,
                        columnDefs: toxicSpeciesColumnDefs,
                        displayField: 'toxicSpeciesCode',
                    },
                    ...GridOptions.sortFilterAndWrapColumns,
                },
                {
                    field: 'toxicSpeciesDescription',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={
                                    CasMasterFormDefs.Toxicity_Species_Description
                                }
                            />
                        );
                    },
                    minWidth: 200,
                    editable: false,
                    valueGetter: (params: ValueGetterParams) => {
                        params.data.description = findFieldDescription(
                            params.data.toxicSpeciesCode?.id,
                            toxicSpeciesOptions
                        );
                        return params.data.description;
                    },
                    ...GridOptions.sortFilterAndWrapColumns,
                    filter: 'agTextColumnFilter',
                },
                {
                    field: 'effectiveDose',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={
                                    CasMasterFormDefs.Toxicity_Effective_Dose
                                }
                            />
                        );
                    },
                    minWidth: 150,
                    filter: 'agTextColumnFilter',
                    editable: isGridEditable,
                },
                {
                    field: 'exposureTime',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={
                                    CasMasterFormDefs.Toxicity_Exposure_Time
                                }
                            />
                        );
                    },
                    minWidth: 190,
                    filter: 'agTextColumnFilter',
                    editable: isGridEditable,
                },
                {
                    field: 'notes',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={CasMasterFormDefs.Toxicity_Notes}
                            />
                        );
                    },
                    filter: 'agTextColumnFilter',
                    editable: isGridEditable,
                    ...DefaultColumnTypes.LargeTextEditor,
                    minWidth: 200,
                },
            ]);
        }
    }, [
        isLoadingCasRoutes,
        casRouteOptions,
        isLoadingToxicTests,
        toxicTestOptions,
        toxicTestColumnDefs,
        isLoadingToxicSpecies,
        toxicSpeciesOptions,
        toxicSpeciesColumnDefs,
        toxicityEntityColumnDefs,
    ]);

    const handleRowValidations = (editedRows: CasToxicity[]) => {
        editedRows?.forEach((row: CasToxicity) => {
            row.validationRules = [
                ColumnRequiredValueValidator('Route ID', row.routeId),
                ColumnMaxValueValidator(
                    'Effective Dose',
                    50,
                    row.effectiveDose
                ),
                ColumnMaxValueValidator('Exposure Time', 50, row.exposureTime),
                ColumnMaxValueValidator('Notes', 255, row.notes),
            ];
            const routeName = getRouteDescriptionById(row.routeId);
            const additionalMessage = routeName
                ? `Toxicity ${routeName}`
                : null;
            applyEditableGridValidations(row, additionalMessage);
        });
        return editedRows;
    };

    const handleGridEdits = (editedRows: CasToxicity[]) => {
        const updatedFields: CasToxicity[] = [...rowsData];

        editedRows?.forEach((row: CasToxicity) => {
            if (
                !isNilOrEmpty(row.toxicTestCode) &&
                !isNilOrEmpty(row.toxicTestCode?.id)
            ) {
                row.toxicTestId = row.toxicTestCode?.id;
            }

            if (
                !isNilOrEmpty(row.toxicSpeciesCode) &&
                !isNilOrEmpty(row.toxicSpeciesCode?.id)
            ) {
                row.toxicSpeciesId = row.toxicSpeciesCode?.id;
            }

            const index = rowsData.findIndex(
                (casToxicity: CasToxicity) => casToxicity.rowId === row.rowId
            );

            if (index > -1) {
                updatedFields[index] = row;
            } else {
                updatedFields.push(row);
            }
            sortCasToxicities(updatedFields);
            handleChildrenRecords('casToxicities', updatedFields);
        });
    };

    return {
        isLoadingToxicTests,
        isLoadingToxicSpecies,
        toxicityEntityColumnDefs,
        rowsData,
        handleGridEdits,
        handleRowValidations,
        colDefOptions,
        termSet,
    };
};

export default useCasToxicityGrid;
