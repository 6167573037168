import { AVAILABLE_NAV_LINKS_AND_INCLUSION_CRITERIA } from '../../libs/constantsLib';
import { isFeatureFlagEnabled } from '../featureFlag/featureFlagUtil';
import { PermissionsUtil } from '../permissions/permissionsUtil';
import NavLink from './navLink';
import { isNilOrEmpty } from '../objectUtils';

export interface NavLinkOptions {
    url: string;
    name: string;
    about: string;
    appImage?: any;
    imageName?: string;
    featureFlag: string;
    permissionKey?: string;
    children?: NavLink[];
    datacorAdminOnly?: boolean;
    displayCard?: boolean;
}

export const getAvailableNavLinks = (
    enabledFeatureFlags: any,
    enabledPermissions: any
): NavLink[] => {
    let availableNavLinks: NavLink[] = [];

    AVAILABLE_NAV_LINKS_AND_INCLUSION_CRITERIA.forEach((navLink: NavLink) => {
        const hasPermissionKey = !isNilOrEmpty(navLink.permissionKey);
        const permissionCheck =
            isFeatureFlagEnabled(enabledFeatureFlags, navLink.featureFlag) &&
            (hasPermissionKey
                ? PermissionsUtil.isPermissionEnabled(
                      enabledPermissions,
                      navLink.permissionKey
                  )
                : true);

        if (permissionCheck) {
            availableNavLinks.push(navLink);
        }
    });

    return availableNavLinks;
};
