import { FormMethods } from '../../../../libs/hooksLib';
import MuiPhoneNumber, { MuiPhoneNumberProps } from 'material-ui-phone-number';
import React, { useContext, useEffect, useState } from 'react';
import { InputBaseProps } from '../InputBase/InputBase';
import { CountryData, PhoneNumberData } from '../../../../types/Shared.types';
import usePhoneNumberInput from './hook/usePhoneNumberInput';
import SettingsContext from '../../../../contexts/settings.context';

export type PhoneNumberInputProps = {
    id: string;
    onChange: (event: any) => void;
    formMethods?: FormMethods;
    required?: boolean;
    value?: PhoneNumberData;
    minPhoneDigits?: number;
} & InputBaseProps &
    MuiPhoneNumberProps;

/**
 * The mui-phone-number component auto-formats the phone number field based on country
 * selected, does this change the way we want to handle phone numbers?
 * **/
const PhoneNumberInput = ({
    formMethods,
    minPhoneDigits,
    ...props
}: PhoneNumberInputProps) => {
    const { handleOnChange, helperTextValidation, getError } =
        usePhoneNumberInput({ formMethods, minPhoneDigits, ...props });

    /**
     * List of all the countries we want to show in the dropdown
     * ideally it would be consistent with the countries dropdown in business entity
     * settings
     * **/
    const onlyCountries = ['us', 'ca'];

    const { settings } = useContext(SettingsContext);
    const { businessEntitySettings } = settings;

    const [countryCode, setCountryCode] = useState(null);

    useEffect(() => {
        if (
            businessEntitySettings?.defaultCountry &&
            onlyCountries.includes(
                businessEntitySettings?.defaultCountry?.toLowerCase()
            )
        ) {
            setCountryCode(
                businessEntitySettings?.defaultCountry?.toLowerCase()
            );
        } else if (props?.value?.countryCode) {
            setCountryCode(props?.value?.countryCode);
        } else {
            setCountryCode('us');
        }
    }, [businessEntitySettings, props]);

    return (
        <>
            {countryCode && (
                <MuiPhoneNumber
                    {...props}
                    /**
                     * Auto format will handle the proper format (based on regex)
                     for each country
                     is this something we want to leave up to this library or handle on our own?
                      **/
                    autoFormat={true}
                    onlyCountries={props.onlyCountries || onlyCountries}
                    defaultCountry={countryCode}
                    /**
                     * The area code must be enabled to tell the difference
                     between US and Canada since they both have a +1 country code
                     (this is limited to this 3rd party library)
                     do we want to consider using something else?
                      **/
                    disableAreaCodes={props.disableAreaCodes || false}
                    countryCodeEditable={props.countryCodeEditable || true}
                    variant={'outlined'}
                    value={props.value?.phoneNumber || ''}
                    // @ts-ignore
                    onChange={(value: string, country: CountryData) => {
                        handleOnChange({
                            phoneNumber: value,
                            countryCode: country.countryCode,
                        });
                    }}
                    error={getError()}
                    helperText={helperTextValidation()}
                />
            )}
        </>
    );
};

export default PhoneNumberInput;
