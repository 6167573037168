import { isNil } from '../objectUtils';
import { Permission } from '../../types/Settings.types';
import store from '../../store';

export const PermissionsUtil = {
    isPermissionEnabled: (
        userPermissions: Array<Permission>,
        permission: any
    ) => {
        const user = store.getState()?.user;
        if (isNil(permission) || user?.isDatacorAdmin || user?.isDatacorUser) {
            return true;
        }

        return userPermissions ? userPermissions.includes(permission) : false;
    },
    hasPermission: (permission: any) => {
        const user = store.getState()?.user;
        if (isNil(permission) || user?.isDatacorAdmin || user?.isDatacorUser) {
            return true;
        }

        return user ? user.permissions.includes(permission) : false;
    },
    checkPermissionGroup: (permissions: any, permissionGroup: any) => {
        const canView = PermissionsUtil.isPermissionEnabled(
            permissions,
            permissionGroup.VIEW
        );
        const canCreate = PermissionsUtil.isPermissionEnabled(
            permissions,
            permissionGroup.CREATE
        );
        const canUpdate = PermissionsUtil.isPermissionEnabled(
            permissions,
            permissionGroup.EDIT
        );
        const canDelete = PermissionsUtil.isPermissionEnabled(
            permissions,
            permissionGroup.DELETE
        );
        return [canView, canCreate, canUpdate, canDelete];
    },
};
