import { Grid } from '@mui/material';
import React from 'react';
import TextInput from '../../../../../../../../components/form/formInputs/TextInput/TextInput';
import {
    BlobData,
    SdsBlobSectionFormProps,
} from '../../../shared/blobs/hooks/SdsBlobSectionFormProps';
import {
    SdsHeader,
    SdsSubSection13_1,
} from '../../../../../../../../types/formulation';
import useSdsBlobSectionForm from '../../../shared/blobs/hooks/useSdsBlobSectionForm';
import SdsSubSectionForm from '../../../shared/blobs/components/SdsSubSectionForm';
import TranslatableText from '../../../../../../../../components/i18n/TranslatableText';
import { SDSFormDefs } from '../../../../../../../../constants/i18n/translations/termDefinitions/formulation';

const SubSection13_1Form = (props: SdsBlobSectionFormProps<SdsHeader>) => {
    const { isParentLoading } = props.parentData;

    const { disabledField } = props.permissions;

    const blobData: BlobData<SdsSubSection13_1> = {
        sectionNumber: 13,
        subSectionNumber: 1,
        blankBlobEntity: {
            handlingForDisposal: '',
            additionalText: '',
            contaminatedPackaging: '',
            methodsOfDisposal: '',
        },
    };

    const { fields, isBlobLoading, formMethods, handleFieldChange } =
        useSdsBlobSectionForm<SdsHeader, SdsSubSection13_1>({
            ...props,
            blobData,
        });

    return (
        <SdsSubSectionForm
            formTitle={
                (
                    <TranslatableText
                        termSet={props.termSet}
                        termKey={SDSFormDefs.Waste_Treatment_Methods}
                    />
                ) as unknown as string
            }
            isFormLoading={isParentLoading || isBlobLoading}
            content={
                <>
                    <Grid item xs={12}>
                        <TextInput
                            fullWidth
                            label={
                                <TranslatableText
                                    termSet={props.termSet}
                                    termKey={SDSFormDefs.Handling_For_Disposal}
                                />
                            }
                            id="handlingForDisposal"
                            disabled={disabledField}
                            formMethods={formMethods}
                            value={fields.handlingForDisposal}
                            onChange={handleFieldChange}
                            maxLength={2000}
                            multiline={true}
                            maxRows={15}
                            minRows={5}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextInput
                            fullWidth
                            label={
                                <TranslatableText
                                    termSet={props.termSet}
                                    termKey={SDSFormDefs.Methods_Of_Disposal}
                                />
                            }
                            id="methodsOfDisposal"
                            disabled={disabledField}
                            formMethods={formMethods}
                            value={fields.methodsOfDisposal}
                            onChange={handleFieldChange}
                            maxLength={2000}
                            multiline={true}
                            maxRows={15}
                            minRows={5}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextInput
                            fullWidth
                            label={
                                <TranslatableText
                                    termSet={props.termSet}
                                    termKey={SDSFormDefs.Contaminated_Packaging}
                                />
                            }
                            id="contaminatedPackaging"
                            disabled={disabledField}
                            formMethods={formMethods}
                            value={fields.contaminatedPackaging}
                            onChange={handleFieldChange}
                            maxLength={2000}
                            multiline={true}
                            maxRows={15}
                            minRows={5}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextInput
                            fullWidth
                            label={
                                <TranslatableText
                                    termSet={props.termSet}
                                    termKey={
                                        SDSFormDefs.Additional_Text_Thirteen
                                    }
                                />
                            }
                            id="additionalText"
                            disabled={disabledField}
                            formMethods={formMethods}
                            value={fields.additionalText}
                            onChange={handleFieldChange}
                            maxLength={2000}
                            multiline={true}
                            maxRows={15}
                            minRows={5}
                        />
                    </Grid>
                </>
            }
        />
    );
};

export default SubSection13_1Form;
