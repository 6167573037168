import { useEffect, useState } from 'react';
import { useForm } from '../../../../../../../../../../libs/hooksLib';
import { useGetPictogramSignalWordForDropdownQuery } from '../../../../../../../../../../services/formulation/casMaster/casPictogramSignalWord/casPictogramSignalWord.service';
import {
    SdsHeader,
    SdsPictogram,
} from '../../../../../../../../../../types/formulation';
import { ChildFormProps } from '../../../../../../../../../form/helpers/ChildFormProps';

const useSdsPictogramForm = (props: ChildFormProps<SdsHeader>) => {
    const { currentParentRecord, isParentLoading } = props.parentData;

    const { handleChildRecord, registerChildForm } = props.helpers;

    const { data: signalWordOptions, isLoading: isLoadingSignalWord } =
        useGetPictogramSignalWordForDropdownQuery();

    const defaultSignalWordId: number = 1;

    const blankSdsPictogram: SdsPictogram = {
        sdsPictogramSignalWordId: 1,
        explosive: false,
        flammable: false,
        oxidizing: false,
        compressedGas: false,
        toxic: false,
        corrosive: false,
        irritant: false,
        healthHazard: false,
        environmentDamaging: false,
    };

    const initialState: any = {
        signalWordInputValue: '',
        signalWordOptions: [],
        selectedSignalWord: null,
    };

    const [dropdownOptions, setDropdownOptions] = useState(initialState);

    const { fields, formMethods, handleFieldChange, setValues } =
        useForm(blankSdsPictogram);

    const handlePropertyChanged = (event: any) => {
        handleFieldChange(event);
        handleChildRecord('sdsPictogram', event);
    };

    useEffect(() => {
        if (!isParentLoading) {
            registerChildForm('sdsPictogram', {
                formMethods,
            });
        }
    }, [fields, isParentLoading]);

    useEffect(() => {
        if (!isParentLoading) {
            setValues(currentParentRecord.sdsPictogram || blankSdsPictogram);
        }
    }, [currentParentRecord.sdsPictogram, isParentLoading]);

    useEffect(() => {
        if (!isLoadingSignalWord && signalWordOptions) {
            setDropdownOptions((previousValues: any) => ({
                ...previousValues,
                selectedSignalWord: signalWordOptions?.find(
                    (current: any) =>
                        current.value === fields.sdsPictogramSignalWordId
                ),
            }));
        }
    }, [signalWordOptions, isLoadingSignalWord, fields]);

    return {
        fields,
        formMethods,
        defaultSignalWordId,
        handlePropertyChanged,
        signalWordOptions,
        isLoadingSignalWord,
        dropdownOptions,
        setDropdownOptions,
    };
};
export default useSdsPictogramForm;
