import React from 'react';
import BaseGrid from '../../../../../../components/grids/BaseGrid';
import useItemPropertiesGrid, {
    ItemPropertiesGridProps,
} from './hooks/useItemPropertiesGrid';
import { FormulaProperty } from '../../../../../../types/formulation';
import { GridOptions } from '../../../../../../components/grids/Grid.constants';
import { isNilOrEmpty } from '../../../../../../utils/objectUtils';
import DatacorLogoSpinner from '../../../../../../components/datacorLogoSpinner/DatacorLogoSpinner';
import FormulaPropertiesSelectionModal from '../../../../shared/multiSelectModals/formulaProperties/FormulaPropertiesSelectionModal';
import FormPartial from '../../../../../../components/form/formContainer/FormPartial';
import TranslatableText from '../../../../../../components/i18n/TranslatableText';
import { ItemMasterFormDefs } from '../../../../../../constants/i18n/translations/termDefinitions/formulation';

const ItemMasterPropertiesGrid = (props: ItemPropertiesGridProps) => {
    const {
        handleGridEdits,
        formulaPropertyList,
        showMultiSelectGridModal,
        setShowMultiSelectGridModal,
        handleSelectedRecords,
        itemPropertyColDef,
        handleRowValidations,
        setFilteredFormulaPropertyList,
        itemProperties,
        termSet,
    } = useItemPropertiesGrid(props);

    const { isParentLoading } = props.parentData;

    const { showAddButton, showDeleteButton, isGridEditable } =
        props.displayGridButtons;

    const colDefOptions = {
        ...GridOptions.sortFilterAndWrapColumns,
        floatingFilter: true,
    };

    return (
        <React.Fragment>
            <FormPartial
                title={
                    (
                        <TranslatableText
                            termSet={termSet}
                            termKey={ItemMasterFormDefs.Properties_Text}
                        />
                    ) as unknown as string
                }>
                {isParentLoading || isNilOrEmpty(itemPropertyColDef) ? (
                    <DatacorLogoSpinner />
                ) : (
                    <>
                        <FormulaPropertiesSelectionModal
                            openModal={showMultiSelectGridModal}
                            onClose={() => setShowMultiSelectGridModal(false)}
                            onSelected={(selectedRecords: FormulaProperty[]) =>
                                handleSelectedRecords(selectedRecords)
                            }
                            formulaPropertyList={formulaPropertyList || []}
                        />
                        <BaseGrid
                            autoSize={true}
                            displayGrid={true}
                            displayCreateNewButton={showAddButton}
                            useRowDrag={isGridEditable}
                            displaySortButton={isGridEditable}
                            sortKey={'sortOrder'}
                            rowData={itemProperties || []}
                            dataKey={'rowId'}
                            gridClass="full-width-grid"
                            displayToolbar={true}
                            sizeColumnsToFit={true}
                            onHandleGridEdits={handleGridEdits}
                            onCreateNew={() => {
                                setFilteredFormulaPropertyList();
                                setShowMultiSelectGridModal(true);
                            }}
                            isEditable={isGridEditable}
                            columnDefs={itemPropertyColDef}
                            displayDeleteButton={showDeleteButton}
                            gridButtonTitle={'Properties'}
                            newRowPlaceholderValues={{
                                propertyValue: 'Enter Value...',
                                propertyCode: 'Enter Code...',
                                propertyUnitId: 'Enter Unit...',
                            }}
                            createRow={false}
                            onHandleRowValidations={handleRowValidations}
                            defaultColDef={colDefOptions}></BaseGrid>
                    </>
                )}
            </FormPartial>
        </React.Fragment>
    );
};

export default ItemMasterPropertiesGrid;
