import { BaseCreateEntityArgs } from '../../serviceInterfaces';
import { datacorAppApi } from '../../datacorAppApi';
import { HazardRating } from '../../../types/formulation';

const serviceUrl: string = 'formulation/hazardRatings';

export const hazardClassMappingApi = datacorAppApi.injectEndpoints({
    endpoints: (build) => ({
        updateHazardRatings: build.mutation<
            HazardRating[],
            BaseCreateEntityArgs<HazardRating[]>
        >({
            query: (args) => ({
                url: serviceUrl,
                method: 'POST',
                body: args.postBody,
            }),
            invalidatesTags: ['hazardRatings'],
        }),
        listHazardRatings: build.query<HazardRating[], void>({
            query: () => {
                return {
                    url: serviceUrl,
                };
            },
            providesTags: ['hazardRatings'],
        }),
    }),
});

export const { useListHazardRatingsQuery, useUpdateHazardRatingsMutation } =
    hazardClassMappingApi;
