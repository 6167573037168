import React, { useContext, useEffect, useState } from 'react';
import {
    ColDef,
    ICellRendererParams,
    ValueGetterParams,
} from 'ag-grid-community';
import _, { cloneDeep } from 'lodash';
import { editableGridCellSelect } from '../../../../../../../components/grids/columns/editableGridCellSelect';
import DefaultColumnTypes from '../../../../../../../components/grids/columns/Column.constants';
import {
    GridOptions,
    GridPopups,
} from '../../../../../../../components/grids/Grid.constants';
import { PermissionsUtil } from '../../../../../../../utils/permissions/permissionsUtil';
import { PERMISSIONS } from '../../../../../../../constants/permissions/Permissions.constants';
import { isNilOrEmpty } from '../../../../../../../utils/objectUtils';
import SingleSelectPopupsContext from '../../../../../../../contexts/singleSelectPopups.context';
import applyEditableGridValidations from '../../../../../../grid/utils/applyEditableGridValidations';
import { ColumnRequiredValueValidator } from '../../../../../../grid/validators/columnRequiredValue.validator';
import { ColumnGreaterThanValidator } from '../../../../../../grid/validators/columnGraterThan.validator';
import { ColumnNumericValueValidator } from '../../../../../../grid/validators/columnNumericValue.validator';
import { RowStatus } from '../../../../../../../components/grids/hooks/useBaseGridEditable';
import {
    CasMaster,
    ItemMaster,
    RDFormula,
    RDFormulaCasComposition,
} from '../../../../../../../types/formulation';
import { SelectionOption } from '../../../../../../../types/Shared.types';
import { useListCasQuery } from '../../../../../../../services/formulation/casMaster/cas/casMaster.service';
import { useGetAllItemsMasterQuery } from '../../../../../../../services/formulation/itemMaster/item/itemMaster.service';
import { useGetCasCompositionOperatorsForDropdownQuery } from '../../../../../../../services/formulation/casCompositionOperator/casCompositionOperator.service';
import SettingsContext from '../../../../../../../contexts/settings.context';
import { useGetTermSetQuery } from '../../../../../../../services/i18n/i18n.service';
import { FORMULATION_DEFS } from '../../../../../../../constants/i18n/translations/termSetDefinitions/formulation';
import { skipToken } from '@reduxjs/toolkit/query';
import CustomHeader from '../../../../../../../components/grids/CustomHeader';
import { RDFormulasFormDefs } from '../../../../../../../constants/i18n/translations/termDefinitions/formulation';
import { ChildEditableGridWithCopyFunctionProps } from '../../../../../../grid/utils/editableGrid/ChildEditableGridWithCopyFunctionProps';

const colDefOptions = {
    ...GridOptions.sortFilterAndWrapColumns,
    floatingFilter: true,
};

const yesOption: SelectionOption = {
    value: 1 as unknown as bigint,
    label: 'Yes',
};

const noOption: SelectionOption = {
    value: 2 as unknown as bigint,
    label: 'No',
};

const yesNoOptions = [yesOption, noOption];

const useRDFormulaCasCompositionGrid = (
    props: ChildEditableGridWithCopyFunctionProps<
        RDFormula,
        RDFormulaCasComposition
    >
) => {
    const { currentParentRecord, user } = props.parentData;

    const { showDeleteButton, isGridEditable } = props.displayGridButtons;

    const { handleChildrenRecords } = props.helpers;

    const { settings } = useContext(SettingsContext);
    const { data: termSet } = useGetTermSetQuery(
        settings?.userSettings
            ? {
                  languageId: settings?.userSettings?.languageId,
                  code: FORMULATION_DEFS.RD_FORMULAS_FORM,
              }
            : skipToken
    );

    const canViewCas = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.FORMULATION.CAS_MASTER.VIEW
    );

    const canViewItem = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.FORMULATION.ITEM_MASTER.VIEW
    );

    const { data: casList, isLoading: isLoadingCas } = useListCasQuery();

    const { data: itemList, isLoading: isLoadingItems } =
        useGetAllItemsMasterQuery();

    const { data: lowerOperatorsOpts, isLoading: isLoadingLowerOperators } =
        useGetCasCompositionOperatorsForDropdownQuery(1);
    const { data: upperOperatorsOpts, isLoading: isLoadingUpperOperators } =
        useGetCasCompositionOperatorsForDropdownQuery(2);

    const [maxRowId, setMaxRowId] = useState(0);

    const [casCompositionColDefs, setCasCompositionColDefs] = useState(null);
    const { rowsData, setRowsData } = props.copyMethods;
    const [filteredCasList, setFilteredCasList] = useState(null as CasMaster[]);
    const [isInitialDataLoaded, setIsInitialDataLoaded] = useState(false);
    const [deletedRecordsCount, setDeletedRecordsCount] = useState(0);

    const { gridPopups, setGridPopups } = useContext(SingleSelectPopupsContext);
    const casColumnDefs: ColDef[] = [
        {
            field: 'identifier',
            headerComponentFramework: (props: any) => {
                return (
                    <CustomHeader
                        {...props}
                        termSet={termSet}
                        termKey={RDFormulasFormDefs.CAS_Selection_CAS_Number}
                    />
                );
            },
            cellClass: 'ag-left-aligned-cell',
            filter: 'agTextColumnFilter',
        },
        {
            field: 'chemicalName',
            headerComponentFramework: (props: any) => {
                return (
                    <CustomHeader
                        {...props}
                        termSet={termSet}
                        termKey={
                            RDFormulasFormDefs.CAS_Selection_CAS_Description
                        }
                    />
                );
            },
            cellClass: 'ag-left-aligned-cell',
            filter: 'agTextColumnFilter',
        },
        {
            field: 'ecIdentifier',
            headerComponentFramework: (props: any) => {
                return (
                    <CustomHeader
                        {...props}
                        termSet={termSet}
                        termKey={RDFormulasFormDefs.CAS_Selection_EC_No}
                    />
                );
            },
            cellClass: 'ag-left-aligned-cell',
            filter: 'agTextColumnFilter',
        },
        {
            field: 'reach',
            headerComponentFramework: (props: any) => {
                return (
                    <CustomHeader
                        {...props}
                        termSet={termSet}
                        termKey={RDFormulasFormDefs.CAS_Selection_Reach_No}
                    />
                );
            },
            cellClass: 'ag-left-aligned-cell',
            filter: 'agTextColumnFilter',
        },
        {
            field: 'classification',
            headerComponentFramework: (props: any) => {
                return (
                    <CustomHeader
                        {...props}
                        termSet={termSet}
                        termKey={
                            RDFormulasFormDefs.CAS_Selection_Classification
                        }
                    />
                );
            },
            cellClass: 'ag-left-aligned-cell',
            filter: 'agTextColumnFilter',
        },
        {
            field: 'molecularFormula',
            headerComponentFramework: (props: any) => {
                return (
                    <CustomHeader
                        {...props}
                        termSet={termSet}
                        termKey={
                            RDFormulasFormDefs.CAS_Selection_Molecular_Formula
                        }
                    />
                );
            },
            cellClass: 'ag-left-aligned-cell',
            filter: 'agTextColumnFilter',
        },
    ];
    const itemColDefs: ColDef[] = [
        {
            field: 'itemId',
            headerComponentFramework: (props: any) => {
                return (
                    <CustomHeader
                        {...props}
                        termSet={termSet}
                        termKey={RDFormulasFormDefs.Component_Selection_Item_ID}
                    />
                );
            },
            ...DefaultColumnTypes.ShortText,
        },
        {
            field: 'itemName',
            headerComponentFramework: (props: any) => {
                return (
                    <CustomHeader
                        {...props}
                        termSet={termSet}
                        termKey={
                            RDFormulasFormDefs.Component_Selection_Item_Name
                        }
                    />
                );
            },
            ...DefaultColumnTypes.MediumText,
        },
        {
            field: 'version',
            headerComponentFramework: (props: any) => {
                return (
                    <CustomHeader
                        {...props}
                        termSet={termSet}
                        termKey={RDFormulasFormDefs.Component_Selection_Version}
                    />
                );
            },
            ...DefaultColumnTypes.NumberOnly,
        },
        {
            field: 'description1',
            headerComponentFramework: (props: any) => {
                return (
                    <CustomHeader
                        {...props}
                        termSet={termSet}
                        termKey={
                            RDFormulasFormDefs.Component_Selection_Description_One
                        }
                    />
                );
            },
            ...DefaultColumnTypes.MediumText,
        },
        {
            field: 'description2',
            headerComponentFramework: (props: any) => {
                return (
                    <CustomHeader
                        {...props}
                        termSet={termSet}
                        termKey={
                            RDFormulasFormDefs.Component_Selection_Description_Two
                        }
                    />
                );
            },
            ...DefaultColumnTypes.MediumText,
        },
        {
            field: 'itemStatus.status',
            headerComponentFramework: (props: any) => {
                return (
                    <CustomHeader
                        {...props}
                        termSet={termSet}
                        termKey={RDFormulasFormDefs.Component_Selection_Status}
                    />
                );
            },
            ...DefaultColumnTypes.ShortText,
        },
        {
            field: 'inventoryType.type',
            headerComponentFramework: (props: any) => {
                return (
                    <CustomHeader
                        {...props}
                        termSet={termSet}
                        termKey={
                            RDFormulasFormDefs.Component_Selection_Inventory_Type
                        }
                    />
                );
            },
            ...DefaultColumnTypes.ShortText,
        },
        {
            field: 'upc',
            headerComponentFramework: (props: any) => {
                return (
                    <CustomHeader
                        {...props}
                        termSet={termSet}
                        termKey={RDFormulasFormDefs.Component_Selection_UPC}
                    />
                );
            },
            ...DefaultColumnTypes.ShortText,
        },
        {
            field: 'cost',
            headerComponentFramework: (props: any) => {
                return (
                    <CustomHeader
                        {...props}
                        termSet={termSet}
                        termKey={RDFormulasFormDefs.Component_Selection_Cost}
                    />
                );
            },
            ...DefaultColumnTypes.Currency,
        },
    ];
    /**
     * Filter CAS Records based on existing CAS Composition records
     * @param currentCasCompositionList current CAS Composition records already added.
     */
    const filterCasList = (
        currentCasCompositionList: RDFormulaCasComposition[]
    ) => {
        if (casList && currentCasCompositionList) {
            const filteredList = (casList as CasMaster[]).filter((currentCas) =>
                isNilOrEmpty(
                    currentCasCompositionList.find(
                        (current) => current.casId === currentCas.id
                    )
                )
            );
            setFilteredCasList(filteredList);
        }
    };

    /**
     * Get a default value based on current value.
     * @param currentValue current value
     * @param defaultValue desired default value
     */
    const setDefaultNullValue = <T,>(
        currentValue: T,
        defaultValue: null | undefined = null
    ): T => {
        if ((currentValue as unknown) === '') {
            return defaultValue;
        }
        return currentValue;
    };

    const sortCasComposition = (
        casCompositionList: RDFormulaCasComposition[]
    ) => {
        setRowsData(_.orderBy(casCompositionList, ['sortOrder'], ['asc']));
    };

    /**
     * Set CAS, Item and other data to a CAS Composition record
     * @param composition current CAS Composition record
     */
    const setCompositionCasItemData = (
        composition: RDFormulaCasComposition
    ) => {
        if (composition.cas?.id !== composition.casId) {
            composition.casId = composition.cas?.id;
            composition.tradeSecret = composition.cas?.tradeSecret;
            composition.tradeSecretValue =
                composition.tradeSecret !== null
                    ? composition.tradeSecret
                        ? yesOption.value
                        : noOption.value
                    : null;
            composition.chemicalName = composition.cas?.chemicalName;
            composition.reachNumber = composition.cas?.reach;
            composition.ecIdentifier = composition.cas?.ecIdentifier;
            composition.classification = composition.cas?.classification;
        } else if (composition.tradeSecretValue) {
            composition.tradeSecret =
                composition.tradeSecretValue === yesOption.value;
        }
        composition.itemId = composition.item?.id;
        composition.upperPercentage = setDefaultNullValue(
            composition.upperPercentage,
            undefined
        );
        composition.lowerPercentage = setDefaultNullValue(
            composition.lowerPercentage,
            undefined
        );
        composition.lowerOperatorId = setDefaultNullValue(
            composition.lowerOperatorId,
            undefined
        );
        composition.upperOperatorId = setDefaultNullValue(
            composition.upperOperatorId,
            undefined
        );
    };

    /**
     * Handle edited records and push them to the parent record.
     * @param editedRows current edited rows
     */
    const handleGridEdits = (editedRows: RDFormulaCasComposition[]) => {
        const updatedList = rowsData?.filter((current) => {
            return isNilOrEmpty(
                editedRows.find(
                    (editedRecord) => editedRecord.rowId === current.rowId
                )
            );
        });

        const newCasCompositionList = updatedList
            ? editedRows.concat(updatedList)
            : editedRows;

        const updatedCasIdRecord = newCasCompositionList.find(
            (current) => current.casValue.id !== current.casId
        );
        const deletedRecords = editedRows.filter(
            (current) => current.rowStatus === RowStatus.DELETED
        );
        for (const currentRow of newCasCompositionList) {
            currentRow.cas = getCasById(currentRow.casValue.id);
            currentRow.item = getItemById(currentRow.itemValue.id);
            setCompositionCasItemData(currentRow);
        }

        if (
            updatedCasIdRecord ||
            deletedRecords.length !== deletedRecordsCount
        ) {
            filterCasList(
                newCasCompositionList.filter(
                    (current) => current.rowStatus !== RowStatus.DELETED
                )
            );
            setDeletedRecordsCount(deletedRecords.length);
        }
        sortCasComposition(newCasCompositionList);
        handleChildrenRecords('casCompositions', newCasCompositionList);
    };

    /**
     * Add validations to CAS Composition records
     * @param editedRows edited Cas composition records
     */
    const handleRowValidations = (editedRows: RDFormulaCasComposition[]) => {
        editedRows?.forEach((current) => {
            current.validationRules = [
                ColumnRequiredValueValidator('CAS Number', current.casValue),
                ColumnRequiredValueValidator(
                    'Lower Operator',
                    current.lowerOperatorId
                ),
                ColumnRequiredValueValidator(
                    'Lower %',
                    current.lowerPercentage
                ),
                ColumnNumericValueValidator(
                    'Lower %',
                    4,
                    current.lowerPercentage
                ),
                ColumnNumericValueValidator(
                    'Upper %',
                    4,
                    current.upperPercentage
                ),
                ColumnGreaterThanValidator(
                    'Upper %',
                    'Lower %',
                    current.upperPercentage,
                    current.lowerPercentage
                ),
            ];
            const message = current.casValue?.display
                ? `CAS Number ${current.casValue?.display}`
                : null;
            applyEditableGridValidations(current, message);
        });
        return editedRows;
    };

    /**
     * Get CAS record based on the ID
     * @param casId desired CAS ID to be found
     */
    const getCasById = (casId: bigint): CasMaster => {
        if (casList && casId) {
            return (casList as CasMaster[]).find(
                (current) => current.id === casId
            );
        }
        return null;
    };

    /**
     * Get CAS Item record based on the ID
     * @param itemId desired Item ID to be found
     */
    const getItemById = (itemId: bigint): ItemMaster => {
        if (itemList && itemId) {
            return itemList.find((current) => current.id === itemId);
        }
        return null;
    };

    /**
     * Set initial data for a CAS Composition
     * @param currentCasComposition current CAS composition
     */
    const setCasCompositionData = (
        currentCasComposition: RDFormulaCasComposition
    ) => {
        if (currentCasComposition) {
            const cas = getCasById(currentCasComposition.casId);
            const item = getItemById(currentCasComposition.itemId);
            currentCasComposition.cas = cas;
            currentCasComposition.casValue = {
                display: cas?.identifier,
                id: cas?.id,
            };
            currentCasComposition.item = item;
            currentCasComposition.itemValue = {
                display: item?.itemId,
                id: item?.id,
            };
            currentCasComposition.tradeSecretValue =
                currentCasComposition.tradeSecret !== null
                    ? currentCasComposition.tradeSecret
                        ? yesOption.value
                        : noOption.value
                    : null;
        }
    };

    useEffect(() => {
        if (filteredCasList) {
            let tempArr: any = [...gridPopups];
            const updateIndex = tempArr.findIndex(
                (grids: any) =>
                    grids.gridName ===
                        GridPopups['FORMULA_CAS_COMPOSITION'].gridName &&
                    grids.fieldName ===
                        GridPopups['FORMULA_CAS_COMPOSITION'].fieldName
            );

            if (updateIndex !== -1) {
                tempArr.splice(updateIndex, 1);

                tempArr.push({
                    gridName: GridPopups['FORMULA_CAS_COMPOSITION'].gridName,
                    fieldName: GridPopups['FORMULA_CAS_COMPOSITION'].fieldName,
                    value: canViewCas ? filteredCasList : [],
                });
            }

            setGridPopups(tempArr);
        }
    }, [filteredCasList]);

    useEffect(() => {
        const includeEditedRows = currentParentRecord?.casCompositions
            ? !isNilOrEmpty(
                  currentParentRecord?.casCompositions.find(
                      (current) => current.rowStatus
                  )
              )
            : false;
        if (
            !isLoadingCas &&
            currentParentRecord?.casCompositions &&
            casList &&
            !isLoadingItems &&
            itemList &&
            !includeEditedRows
        ) {
            const casCompositionList = cloneDeep(
                currentParentRecord.casCompositions
            );
            let currentMaxRowId = maxRowId;
            casCompositionList.forEach((current) => {
                current.rowId = current.rowId
                    ? current.rowId
                    : ++currentMaxRowId;
                setCasCompositionData(current);
            });
            filterCasList(casCompositionList);
            sortCasComposition(casCompositionList);
            setMaxRowId(currentMaxRowId);
            setIsInitialDataLoaded(true);
        }
    }, [currentParentRecord?.casCompositions, casList, itemList]);

    useEffect(() => {
        if (
            !isLoadingCas &&
            isInitialDataLoaded &&
            !isLoadingLowerOperators &&
            !isLoadingUpperOperators &&
            lowerOperatorsOpts &&
            upperOperatorsOpts &&
            !isLoadingItems &&
            itemList &&
            rowsData
        ) {
            setCasCompositionColDefs([
                {
                    field: 'deleteColumn',
                    minWidth: 50,
                    hide: !showDeleteButton || !isGridEditable,
                    filter: false,
                },
                {
                    field: 'casValue',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={RDFormulasFormDefs.CAS_No}
                            />
                        );
                    },
                    minWidth: 250,
                    editable: canViewCas && isGridEditable,
                    useGridPopup: true,
                    filter: 'agTextColumnFilter',
                    valueGetter: (params: any) => {
                        const casDisplay = params?.data?.casValue?.display;
                        return casDisplay
                            ? casDisplay
                            : 'Enter a CAS Number...';
                    },
                    filterParams: {
                        valueGetter: (params: any) => {
                            return params?.data?.casValue?.display;
                        },
                    },
                    gridPopupParameters: {
                        isLoading: isLoadingCas,
                        displayGrid: true,
                        rowData: canViewCas ? casList : [],
                        defaultColDef: colDefOptions,
                        columnDefs: casColumnDefs,
                        displayField: 'identifier',
                    },
                },
                {
                    field: 'chemicalName',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={RDFormulasFormDefs.CAS_Description}
                            />
                        );
                    },
                    ...DefaultColumnTypes.MediumText,
                    minWidth: 160,
                    editable: false,
                },
                {
                    field: 'itemValue',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={RDFormulasFormDefs.Component}
                            />
                        );
                    },
                    minWidth: 150,
                    editable: canViewItem && isGridEditable,
                    cellRenderer: (params: ICellRendererParams) =>
                        params.value.display,
                    useGridPopup: true,
                    filter: 'agTextColumnFilter',
                    gridPopupParameters: {
                        isLoading: isLoadingItems,
                        displayGrid: true,
                        rowData: canViewItem ? itemList : [],
                        defaultColDef: colDefOptions,
                        columnDefs: itemColDefs,
                        displayField: 'itemId',
                    },
                },
                {
                    field: 'lowerOperatorId',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={RDFormulasFormDefs.Lower_Operator}
                            />
                        );
                    },
                    minWidth: 50,
                    ...editableGridCellSelect(lowerOperatorsOpts),
                },
                {
                    field: 'lowerPercentage',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={RDFormulasFormDefs.Lower_Percent}
                            />
                        );
                    },
                    minWidth: 150,
                    editable: isGridEditable,
                    valueGetter: (params: ValueGetterParams) => {
                        params.data.lowerPercentage =
                            params.data.lowerPercentage === 'Enter a Lower %...'
                                ? 0
                                : params.data.lowerPercentage;
                        return params.data.lowerPercentage;
                    },
                    isNumeric: true,
                },
                {
                    field: 'upperOperatorId',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={RDFormulasFormDefs.Upper_Operator}
                            />
                        );
                    },
                    minWidth: 50,
                    ...editableGridCellSelect(upperOperatorsOpts),
                },
                {
                    field: 'upperPercentage',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={RDFormulasFormDefs.Upper_Percent}
                            />
                        );
                    },
                    minWidth: 150,
                    editable: isGridEditable,
                    isNumeric: true,
                },
                {
                    field: 'ecIdentifier',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={RDFormulasFormDefs.EC_No}
                            />
                        );
                    },
                    ...DefaultColumnTypes.MediumText,
                    editable: false,
                },
                {
                    field: 'reachNumber',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={RDFormulasFormDefs.Reach_No}
                            />
                        );
                    },
                    ...DefaultColumnTypes.MediumText,
                    minWidth: 175,
                    editable: false,
                },
                {
                    field: 'classification',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={RDFormulasFormDefs.Classification}
                            />
                        );
                    },
                    ...DefaultColumnTypes.MediumText,
                    editable: false,
                },
                {
                    field: 'tradeSecretValue',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={RDFormulasFormDefs.Trade_Secret}
                            />
                        );
                    },
                    minWidth: 50,
                    ...editableGridCellSelect(yesNoOptions),
                },
            ]);
        }
    }, [
        isLoadingCas,
        isLoadingItems,
        isLoadingUpperOperators,
        isLoadingLowerOperators,
        isInitialDataLoaded,
    ]);

    return {
        casCompositionColDefs,
        rowsData,
        handleGridEdits,
        handleRowValidations,
        termSet,
    };
};

export default useRDFormulaCasCompositionGrid;
