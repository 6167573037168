import React from 'react';
import ProperShippingNameGrid from './pages/ProperShippingNameGrid';

const ProperShippingName = () => {
    return (
        <>
            <ProperShippingNameGrid />
        </>
    );
};

export default ProperShippingName;
