import React from 'react';
import { Grid } from '@mui/material';
import { useParams } from 'react-router-dom';
import FormPartial from '../../components/form/formContainer/FormPartial';
import TextInput from '../../components/form/formInputs/TextInput/TextInput';
import SelectInput from '../../components/form/formInputs/SelectInput/SelectInput';
import useFixedAssetDepreciationMethodForm from './hooks/useFixedAssetDepreciationMethodForm';
import NumericInput from '../../components/form/formInputs/NumericInput/NumericInput';
import { AgGridColumn } from 'ag-grid-react';
import BaseGrid from '../../components/grids/BaseGrid';
import { ValueFormatterParams } from 'ag-grid-community';
import { IAggFuncParams } from 'ag-grid-community/dist/lib/entities/colDef';
import _ from 'lodash';
import { DepreciationBasis } from '../../types/FixedAsset.types';
import BaseForm, { ParamTypes } from '../../components/form/baseForm/BaseForm';
import { NumberMaxValues, RoutingValues } from '../../types/Shared.types';
import DefaultColumnTypes from '../../components/grids/columns/Column.constants';
import { percentFormatter } from '../../utils/formattingUtils';
import { DEPRECIATION_METHOD_FIELDS } from '../../constants/revisionHistory/revisionHistory.constants';
import TranslatableText from '../../components/i18n/TranslatableText';
import { DepreciationMethodsFormDefs } from '../../constants/i18n/translations/termDefinitions/accounting';

const FixedAssetDepreciationMethodForm = () => {
    const { id } = useParams<ParamTypes>();
    const {
        fields,
        handleFieldChange,
        formMethods,
        onCreate,
        onUpdate,
        onDelete,
        closeForm,
        isLoadingMethod,
        isLoadingStatuses,
        isLoadingTypes,
        isLoadingCostCodes,
        statusOptions,
        typeOptions,
        formulaOptions,
        costCodeList,
        onGridReady,
        onRowSelected,
        depreciationScheduleWorksheet,
        depreciationBasis,
        totalAmount,
        numberOfRecoveryIntervals,
        handleRecoveryIntervalChange,
        canCreateDepreciationMethod,
        canUpdateDepreciationMethod,
        canDeleteDepreciationMethod,
        termSet,
    } = useFixedAssetDepreciationMethodForm(id);

    return (
        <BaseForm
            title={
                <TranslatableText
                    termSet={termSet}
                    termKey={
                        DepreciationMethodsFormDefs.Fixed_Asset_Depreciation_Methods
                    }
                />
            }
            formMethods={formMethods}
            isLoading={isLoadingMethod}
            isNew={id === RoutingValues.newId}
            onCreate={onCreate}
            onUpdate={onUpdate}
            onDelete={onDelete}
            onCancel={closeForm}
            showOnRevision={true}
            fieldMappings={DEPRECIATION_METHOD_FIELDS}
            entityUuid={fields?.uuid}
            displayCreateButton={canCreateDepreciationMethod}
            displayUpdateButton={canUpdateDepreciationMethod}
            displayDeleteButton={canDeleteDepreciationMethod}>
            <FormPartial>
                <Grid container spacing={2}>
                    <Grid item xs={4}>
                        <TextInput
                            fullWidth
                            label={
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={
                                        DepreciationMethodsFormDefs.Depreciation_Method_Code
                                    }
                                />
                            }
                            id="code"
                            value={fields.code}
                            onChange={handleFieldChange}
                            formMethods={formMethods}
                            required={true}
                            maxLength={30}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextInput
                            fullWidth
                            label={
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={
                                        DepreciationMethodsFormDefs.Depreciation_Method_Name
                                    }
                                />
                            }
                            id="name"
                            value={fields.name}
                            onChange={handleFieldChange}
                            formMethods={formMethods}
                            maxLength={90}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <NumericInput
                            fullWidth
                            label={
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={
                                        DepreciationMethodsFormDefs.Sort_Order
                                    }
                                />
                            }
                            id="sortOrder"
                            value={fields.sortOrder}
                            onChange={handleFieldChange}
                            formMethods={formMethods}
                            required={true}
                            maxValue={NumberMaxValues.smallint}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextInput
                            fullWidth
                            label={
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={
                                        DepreciationMethodsFormDefs.Description
                                    }
                                />
                            }
                            id="description"
                            value={fields.description}
                            onChange={handleFieldChange}
                            formMethods={formMethods}
                            maxLength={255}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <SelectInput
                            fullWidth
                            label={
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={
                                        DepreciationMethodsFormDefs.Depreciation_Method_Status
                                    }
                                />
                            }
                            id="depreciationMethodStatusId"
                            value={fields.depreciationMethodStatusId}
                            onChange={handleFieldChange}
                            formMethods={formMethods}
                            options={statusOptions}
                            disabled={isLoadingStatuses}
                            required={true}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <SelectInput
                            fullWidth
                            label={
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={
                                        DepreciationMethodsFormDefs.Depreciation_Method_Type
                                    }
                                />
                            }
                            id="depreciationMethodTypeId"
                            value={fields.depreciationMethodTypeId}
                            onChange={handleFieldChange}
                            formMethods={formMethods}
                            options={typeOptions}
                            disabled={isLoadingTypes}
                            required={true}
                        />
                    </Grid>
                </Grid>
            </FormPartial>
            <FormPartial
                title={
                    (
                        <TranslatableText
                            termSet={termSet}
                            termKey={
                                DepreciationMethodsFormDefs.Cost_Codes_Title
                            }
                        />
                    ) as unknown as string
                }
                isVisible={!isLoadingCostCodes}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <BaseGrid
                            rowData={costCodeList || []}
                            displayGrid={true}
                            onGridReady={onGridReady}
                            onRowSelected={onRowSelected}
                            rowMultiSelectWithClick={true}
                            rowStyle={{ cursor: 'pointer' }}
                            rowSelection={'multiple'}
                            defaultColDef={{
                                resizable: true,
                                wrapText: true,
                            }}>
                            <AgGridColumn
                                headerComponentFramework={(props: any) => {
                                    return (
                                        <TranslatableText
                                            termSet={termSet}
                                            termKey={
                                                DepreciationMethodsFormDefs.Selected
                                            }
                                        />
                                    );
                                }}
                                {...DefaultColumnTypes.CheckboxSelection}
                            />
                            <AgGridColumn
                                field="code"
                                headerComponentFramework={(props: any) => {
                                    return (
                                        <TranslatableText
                                            termSet={termSet}
                                            termKey={
                                                DepreciationMethodsFormDefs.Cost_Code
                                            }
                                        />
                                    );
                                }}
                                {...DefaultColumnTypes.ShortText}
                            />
                        </BaseGrid>
                    </Grid>
                    <Grid item xs={12}>
                        <TextInput
                            fullWidth
                            label={
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={
                                        DepreciationMethodsFormDefs.Total_Amount
                                    }
                                />
                            }
                            id="totalAmount"
                            value={totalAmount}
                            disabled={true}
                        />
                    </Grid>
                </Grid>
            </FormPartial>
            <FormPartial
                title={
                    (
                        <TranslatableText
                            termSet={termSet}
                            termKey={
                                DepreciationMethodsFormDefs.Depreciation_Basis
                            }
                        />
                    ) as unknown as string
                }
                isVisible={depreciationBasis !== DepreciationBasis.None}>
                <Grid container spacing={2}>
                    <Grid
                        item
                        xs={2}
                        hidden={
                            depreciationBasis !== DepreciationBasis.Formula
                        }>
                        <SelectInput
                            fullWidth
                            label={
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={
                                        DepreciationMethodsFormDefs.Depreciation_Option
                                    }
                                />
                            }
                            id="depreciationFormula"
                            value={fields.depreciationFormula}
                            onChange={handleFieldChange}
                            formMethods={formMethods}
                            options={formulaOptions}
                            required={fields.depreciationBasis === 'Formula'}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={10}
                        hidden={
                            depreciationBasis !== DepreciationBasis.Formula
                        }>
                        <TextInput
                            fullWidth
                            id="depreciationFormula"
                            value={fields.depreciationFormula}
                            disabled={true}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={2}
                        hidden={
                            depreciationBasis !== DepreciationBasis.Schedule
                        }>
                        <NumericInput
                            fullWidth
                            label={
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={
                                        DepreciationMethodsFormDefs.Number_Of_Recovery_Intervals
                                    }
                                />
                            }
                            id="numberOfRecoveryIntervals"
                            value={numberOfRecoveryIntervals}
                            onChange={handleRecoveryIntervalChange}
                            formMethods={formMethods}
                            required={
                                depreciationBasis === DepreciationBasis.Schedule
                            }
                        />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        hidden={
                            depreciationBasis !== DepreciationBasis.Schedule
                        }>
                        <BaseGrid
                            domLayout={'normal'}
                            pagination={false}
                            gridStyle={{
                                height: '400px',
                                width: '100%',
                                overflow: 'scroll',
                            }}
                            rowData={depreciationScheduleWorksheet}
                            displayGrid={true}
                            groupIncludeTotalFooter={true}
                            defaultColDef={{
                                resizable: true,
                                wrapText: true,
                            }}>
                            <AgGridColumn
                                field="interval"
                                headerComponentFramework={(props: any) => {
                                    return (
                                        <TranslatableText
                                            termSet={termSet}
                                            termKey={
                                                DepreciationMethodsFormDefs.Recovery_Interval
                                            }
                                        />
                                    );
                                }}
                                {...DefaultColumnTypes.NumberOnly}
                            />
                            <AgGridColumn
                                field="percentage"
                                headerComponentFramework={(props: any) => {
                                    return (
                                        <TranslatableText
                                            termSet={termSet}
                                            termKey={
                                                DepreciationMethodsFormDefs.Percentage
                                            }
                                        />
                                    );
                                }}
                                {...DefaultColumnTypes.Percent}
                                valueFormatter={(
                                    params: ValueFormatterParams
                                ) => {
                                    return percentFormatter(params.value, 3);
                                }}
                                editable={true}
                                aggFunc={(params: IAggFuncParams) => {
                                    return _.sumBy(
                                        params.values,
                                        (value: any) =>
                                            isNaN(value) ? 0 : Number(value)
                                    );
                                }}
                            />
                        </BaseGrid>
                    </Grid>
                </Grid>
            </FormPartial>
        </BaseForm>
    );
};

export default FixedAssetDepreciationMethodForm;
