import React, { useContext, useEffect } from 'react';
import { isFeatureFlagEnabled } from '../../../utils/featureFlag/featureFlagUtil';
import {
    Card,
    CardContent,
    CardHeader,
    FormControlLabel,
    Switch,
} from '@mui/material';
import SelectInput from '../../../components/form/formInputs/SelectInput/SelectInput';
import SettingsContext from '../../../contexts/settings.context';
import { getAvailableNavLinks } from '../../../utils/navigation/navigationUtils';
import FormPartial from '../../../components/form/formContainer/FormPartial';
import BaseFormToolbar from '../../../components/form/baseForm/BaseFormToolbar';
import useSettings from '../hooks/useSettings';
import LoaderButton from '../../../components/LoaderButton';
import { useGetLanguagesQuery } from '../../../services/organizations/organizations.service';
import TranslatableText from '../../../components/i18n/TranslatableText';
import { PLATFORM_DEFS } from '../../../constants/i18n/translations/termSetDefinitions/platform';
import { UserSettingsDefs } from '../../../constants/i18n/translations/termDefinitions/platform';

const UserSettings = () => {
    const { settings } = useContext(SettingsContext);

    const { data: languages } = useGetLanguagesQuery();

    const {
        handleChange,
        handleSaveSettings,
        navigate,
        user,
        sharedTermSet,
        termSet,
    } = useSettings({ termSetCode: PLATFORM_DEFS.USER_SETTINGS });

    const [navigationLinks, setNavigationLinks] = React.useState([]);

    useEffect(() => {
        const allNavigationLinks = getAvailableNavLinks(
            user.enabledFeatureFlags,
            user.permissions
        );

        setNavigationLinks(allNavigationLinks);
    }, [user]);

    return (
        <Card>
            <CardHeader
                sx={{ paddingLeft: '0px', paddingRight: '0px' }}
                title={
                    <>
                        <BaseFormToolbar
                            isNew={false}
                            onCancel={() => navigate(-1)}
                            displayUpdateButton={true}
                            onUpdate={handleSaveSettings}
                            termSet={sharedTermSet}
                        />
                    </>
                }
            />
            <CardContent>
                <FormPartial
                    title={
                        (
                            <TranslatableText
                                termKey={UserSettingsDefs.SETTINGS_HEADER}
                                termSet={termSet}
                            />
                        ) as unknown as string
                    }>
                    {isFeatureFlagEnabled(
                        user?.enabledFeatureFlags,
                        'settings.entitySettings.userSettings.darkMode'
                    ) && (
                        <>
                            <FormControlLabel
                                control={
                                    <Switch
                                        id="darkMode"
                                        checked={
                                            settings.userSettings?.darkMode
                                        }
                                        onChange={(event: any) =>
                                            handleChange(event, 'userSettings')
                                        }
                                    />
                                }
                                label={
                                    <TranslatableText
                                        termKey={UserSettingsDefs.DARK_MODE}
                                        termSet={termSet}
                                    />
                                }
                            />
                            <br />
                            <br />
                        </>
                    )}

                    {isFeatureFlagEnabled(
                        user?.enabledFeatureFlags,
                        'settings.entitySettings.userSettings.defaultPage'
                    ) && (
                        <>
                            <SelectInput
                                id={'defaultPage'}
                                onChange={(event: any) =>
                                    handleChange(event, 'userSettings')
                                }
                                fullWidth
                                value={settings.userSettings?.defaultPage}
                                label={
                                    <TranslatableText
                                        termKey={UserSettingsDefs.DEFAULT_PAGE}
                                        termSet={termSet}
                                    />
                                }
                                options={navigationLinks.map((link: any) => {
                                    return {
                                        label: link.name,
                                        value: link.url,
                                    };
                                })}
                            />

                            <br />
                            <br />
                            <SelectInput
                                id={'languageId'}
                                onChange={(event: any) =>
                                    handleChange(event, 'userSettings')
                                }
                                fullWidth
                                value={settings.userSettings?.languageId}
                                label={
                                    <TranslatableText
                                        termKey={UserSettingsDefs.LANGUAGE}
                                        termSet={termSet}
                                    />
                                }
                                options={
                                    languages &&
                                    languages?.map((lang: any) => {
                                        return {
                                            label: lang.name,
                                            value: lang.id,
                                        };
                                    })
                                }
                            />
                        </>
                    )}
                    <FormPartial
                        title={
                            (
                                <TranslatableText
                                    termKey={UserSettingsDefs.LOGIN_HEADER}
                                    termSet={termSet}
                                />
                            ) as unknown as string
                        }>
                        <LoaderButton
                            variant="contained"
                            onClick={() => navigate('/settings/password')}>
                            <TranslatableText
                                termKey={UserSettingsDefs.CHANGE_PASSWORD}
                                termSet={termSet}
                            />
                        </LoaderButton>
                        <br />
                        <br />
                        <LoaderButton
                            variant="contained"
                            onClick={() => navigate('/settings/email')}>
                            <TranslatableText
                                termKey={UserSettingsDefs.CHANGE_EMAIL}
                                termSet={termSet}
                            />
                        </LoaderButton>
                    </FormPartial>
                </FormPartial>
            </CardContent>
        </Card>
    );
};

export default UserSettings;
