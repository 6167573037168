import React from 'react';
import BaseFormGrid from '../../components/grids/BaseFormGrid';
import { cloneDeep } from 'lodash';
import { isNilOrEmpty } from '../../utils/objectUtils';
import booleanCellRenderer from '../../components/grids/cellRenderers/booleanCell.renderer';
import useSupplierAddressTypeGrid from './hooks/useSupplierAddressTypeGrid';

const SupplierAddressTypeGrid = () => {
    const {
        rowData,
        colDefs,
        canView,
        canCreate,
        canUpdate,
        redirectToForm,
        handleGridEdits,
    } = useSupplierAddressTypeGrid();

    return (
        <BaseFormGrid
            title="Supplier Address Types"
            displayGrid={canView}
            isLoading={isNilOrEmpty(colDefs)}
            rowData={cloneDeep(rowData)}
            useRowDrag={canUpdate}
            sortKey={'sortOrder'}
            columnDefs={colDefs}
            gridClass="full-size-item"
            displayToolbar={true}
            displayCreateNewButton={canCreate}
            displayExportDataButton={false}
            displayResetStateButton={true}
            onCreateNew={redirectToForm}
            onRowClicked={({ data }) => redirectToForm(data)}
            onHandleGridEdits={handleGridEdits}
            frameworkComponents={{
                booleanCellRenderer: booleanCellRenderer,
            }}
        />
    );
};

export default SupplierAddressTypeGrid;
