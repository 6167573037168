import { GridReadyEvent, ValueParserParams } from 'ag-grid-community';
import { CostProps } from '../CostGrid';
import { useContext, useEffect, useImperativeHandle, useState } from 'react';
import {
    FA_AssetCost,
    FA_AssetCostCode,
} from '../../../../../types/FixedAsset.types';
import _ from 'lodash';
import { useGetAllCostCodesQuery } from '../../../../../services/fixedAssets/fixedAssets.service';
import { GridApi } from 'ag-grid-community/dist/lib/gridApi';
import SettingsContext from '../../../../../contexts/settings.context';
import { useGetTermSetQuery } from '../../../../../services/i18n/i18n.service';
import { ACCOUNTING_DEFS } from '../../../../../constants/i18n/translations/termSetDefinitions/accounting';
import { skipToken } from '@reduxjs/toolkit/query';

const useCostGrid = (props: CostProps, ref: any) => {
    const { settings } = useContext(SettingsContext);
    const { data: termSet } = useGetTermSetQuery(
        settings?.userSettings
            ? {
                  languageId: settings?.userSettings?.languageId,
                  code: ACCOUNTING_DEFS.FIXED_ASSET_REGISTER_FORM,
              }
            : skipToken
    );
    const [gridApi, setGridApi] = useState<GridApi>(null);
    const [costWorksheet, setCostWorksheet] = useState<FA_AssetCost[]>([]);

    const { data: costCodeList, isLoading: isLoadingCostCodes } =
        useGetAllCostCodesQuery();

    const onGridReady = (event: GridReadyEvent) => {
        setGridApi(event.api);
    };

    useEffect(() => {
        if (!isLoadingCostCodes && costCodeList) {
            const costWorksheet: FA_AssetCost[] = _.sortBy(costCodeList, [
                'sortOrder',
            ]).map((costCode: FA_AssetCostCode) => {
                const existingAssetCost: FA_AssetCost = props.costs?.find(
                    (cost: FA_AssetCost) => cost.costCodeId === costCode.id
                );

                return existingAssetCost
                    ? { ...existingAssetCost }
                    : {
                          fixedAssetId: props.assetId,
                          costCodeId: costCode.id,
                          amount: 0,
                          actualAmount: 0,
                          costCode: costCode,
                      };
            });

            setCostWorksheet(costWorksheet);
        }
    }, [costCodeList, props.costs]);

    const amountValidation = (params: ValueParserParams) =>
        Number.isNaN(Number(params.newValue))
            ? params.oldValue
            : Math.abs(Number(params.newValue));

    const costsPinnedBottomRowData =
        props.costs?.length > 0 ? [{ amount: props.totalCost }] : [];

    //called from Fixed Asset Form
    useImperativeHandle(ref, () => ({
        setCostValues() {
            const copyCost = costWorksheet.map((cost) => {
                return {
                    amount: cost.amount,
                    costCodeId: cost.costCodeId,
                    costCode: cost.costCode,
                    actualAmount: cost.actualAmount,
                    fixedAssetId: null,
                };
            });
            setCostWorksheet(copyCost);
        },
        getAllRows() {
            let rowData: any = [];
            gridApi?.forEachNode((node: any) => rowData.push(node.data));
            return rowData;
        },
    }));

    return {
        onGridReady,
        costWorksheet,
        amountValidation,
        costsPinnedBottomRowData,
        termSet,
    };
};

export default useCostGrid;
