import React from 'react';
import BaseForm from '../../../components/form/baseForm/BaseForm';
import SelectInput from '../../../components/form/formInputs/SelectInput/SelectInput';
import TextInput from '../../../components/form/formInputs/TextInput/TextInput';
import { Grid } from '@mui/material';
import useNewDocumentTemplate from '../hooks/useNewDocumentTemplate';
import DocumentDesigner from '../../../components/documentDesigner/DocumentDesigner';
import { isNilOrEmpty } from '../../../utils/objectUtils';
import TranslatableText from '../../../components/i18n/TranslatableText';
import { DocTemplateFormDefs } from '../../../constants/i18n/translations/termDefinitions/platform';

const DocumentTemplateForm = ({ documentId }: any) => {
    const {
        fields,
        handleFieldChange,
        formMethods,
        dataSources,
        closeForm,
        designerRef,
        onDelete,
        isLoadingDataSources,
        onLock,
        handleSave,
        isLoadingDocumentTemplate,
        termSet,
        isLoadingTermSet,
        canCreateDocumentTemplate,
        canEditDocumentTemplate,
        canDeleteDocumentTemplate,
    } = useNewDocumentTemplate(documentId);

    return (
        <BaseForm
            title={
                <TranslatableText
                    termSet={termSet}
                    termKey={
                        !documentId
                            ? DocTemplateFormDefs.New
                            : DocTemplateFormDefs.Edit
                    }
                />
            }
            formMethods={formMethods}
            isLoading={
                isLoadingDataSources ||
                isLoadingDocumentTemplate ||
                isLoadingTermSet
            }
            onCancel={closeForm}
            onCreate={handleSave}
            onUpdate={handleSave}
            onDelete={onDelete}
            isNew={!documentId}
            displayLockButton={documentId && canEditDocumentTemplate}
            onLock={onLock}
            isLocked={fields?.locked}
            displayCreateButton={!fields?.locked && canCreateDocumentTemplate}
            displayUpdateButton={!fields?.locked && canEditDocumentTemplate}
            displayDeleteButton={!fields?.locked && canDeleteDocumentTemplate}
            readOnly={fields?.locked || !canEditDocumentTemplate}>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <SelectInput
                        fullWidth
                        id={'docDataSourceId'}
                        value={fields.docDataSourceId}
                        options={dataSources}
                        label={
                            <TranslatableText
                                termSet={termSet}
                                termKey={DocTemplateFormDefs.DataSource}
                            />
                        }
                        onChange={handleFieldChange}
                        disabled={!!documentId}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextInput
                        id={'code'}
                        value={fields.code}
                        fullWidth
                        label={
                            <TranslatableText
                                termSet={termSet}
                                termKey={DocTemplateFormDefs.Code}
                            />
                        }
                        onChange={handleFieldChange}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextInput
                        id={'name'}
                        value={fields.name}
                        fullWidth
                        label={
                            <TranslatableText
                                termSet={termSet}
                                termKey={DocTemplateFormDefs.Name}
                            />
                        }
                        onChange={handleFieldChange}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextInput
                        id={'outputFileName'}
                        value={fields.outputFileName}
                        fullWidth
                        label={
                            <TranslatableText
                                termSet={termSet}
                                termKey={DocTemplateFormDefs.OutputFileName}
                            />
                        }
                        onChange={handleFieldChange}
                    />
                </Grid>
                <Grid item xs={12}>
                    {!isNilOrEmpty(fields?.docDataSourceId) && (
                        <DocumentDesigner
                            documentId={documentId}
                            dataSourceId={fields.docDataSourceId}
                            designerRef={designerRef}
                        />
                    )}
                </Grid>
            </Grid>
        </BaseForm>
    );
};

export default DocumentTemplateForm;
