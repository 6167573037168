export const SUPPLY_CHAIN_DEFS = {
    SUPPLIER_GRID: 'supplier-grid',
    SUPPLIER_FORM: 'supplier-form',
    SUPPLIER_ADDRESS_GRID: 'supplier-address-grid',
    SUPPLIER_ADDRESS_FORM: 'supplier-address-form',
    SUPPLIER_TYPE_GRID: 'supplier-type-grid',
    SUPPLIER_TYPE_FORM: 'supplier-type-form',
    SUPPLIER_STATUS_GRID: 'supplier-status-grid',
    SUPPLIER_STATUS_FORM: 'supplier-status-form',
    SUPPLIER_ADDRESS_STATUS_GRID: 'supplier-address-status-grid',
    SUPPLIER_ADDRESS_STATUS_FORM: 'supplier-address-status-form',
    SUPPLIER_ADDRESS_TYPE_GRID: 'supplier-address-type-grid',
    SUPPLIER_ADDRESS_TYPE_FORM: 'supplier-address-type-form',
};
