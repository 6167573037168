import React from 'react';
import useCasForm from './hooks/useCasForm';
import { useParams } from 'react-router-dom';
import BaseForm from '../../../../components/form/baseForm/BaseForm';
import CasToxicityGrid from './sections/casToxicity/CasToxicityGrid';
import CasExposureGrid from './sections/casExposure/CasExposureGrid';
import CasEcoToxicityGrid from './sections/casEcoToxicity/CasEcoToxicityGrid';
import CasPropertiesGrid from './sections/casProperties/CasPropertiesGrid';
import CasRegulationsGrid from './sections/casRegulations/CasRegulationsGrid';
import CasHeaderForm from './sections/casHeader/CasHeaderForm';
import CasAdditionalIdentificationForm from './sections/casAdditionalIdentification/CasAdditionalIdentificationForm';
import CasGlobalHarmonizationForm from './sections/casGlobalHarmonization/CasGlobalHarmonizationForm';
import { CAS_FIELDS } from '../../../../constants/revisionHistory/revisionHistory.constants';
import { CasMasterFormDefs } from '../../../../constants/i18n/translations/termDefinitions/formulation';
import TranslatableText from '../../../../components/i18n/TranslatableText';
import CasHazardClassificationMappingGrid from './sections/casHazardClassificationMapping/CasHazardClassificationMappingGrid';

type ParamTypes = { id: string };

const CasForm = () => {
    const { id } = useParams<ParamTypes>();
    const {
        fields,
        hasEntityChanges,
        childForms,
        allFormMethods,
        isLoadingCasRecord,
        closeForm,
        onCreate,
        onDelete,
        onUpdate,
        copyForm,
        copyMethods,
        display,
        children,
        termSet,
    } = useCasForm(id);

    const {
        displayCreateNewButton,
        displayUpdateButton,
        displayDeleteButton,
        displayCopyButton,
        readOnly,
    } = display;

    const { editableGridProps, childFormProps, extendedFormProps } = children;

    const editableGridAndChildFormProps = {
        ...editableGridProps,
        permissions: childFormProps.permissions,
        helpers: {
            ...editableGridProps.helpers,
            ...childFormProps.helpers,
        },
    };

    return (
        <BaseForm
            title={
                <TranslatableText
                    termSet={termSet}
                    termKey={CasMasterFormDefs.Cas_Maintenance}
                />
            }
            formMethods={allFormMethods}
            isLoading={isLoadingCasRecord}
            isNew={!id}
            hasEntityChanges={hasEntityChanges}
            onCreate={onCreate}
            onUpdate={onUpdate}
            onDelete={onDelete}
            onCancel={closeForm}
            onCopy={copyForm}
            displayCreateButton={displayCreateNewButton}
            displayUpdateButton={displayUpdateButton}
            displayDeleteButton={displayDeleteButton}
            displayCopyButton={displayCopyButton}
            readOnly={readOnly}
            showOnRevision={true}
            fieldMappings={CAS_FIELDS}
            entityUuid={fields?.uuid}
            childForms={childForms}>
            {/* Primary Information */}
            <CasHeaderForm {...extendedFormProps} />
            {/* Additional Identification */}
            <CasAdditionalIdentificationForm {...extendedFormProps} />
            {/* Global Harmonization */}
            <CasGlobalHarmonizationForm
                {...editableGridAndChildFormProps}
                ghsHazardPhraseCopyMethods={{
                    rowsData: copyMethods.casGhsHazardPhraseRecords,
                    setRowsData: copyMethods.setCasGhsHazardPhraseRecords,
                }}
                ghsPrecautionaryPhraseCopyMethods={{
                    rowsData: copyMethods.casGhsPrecautionaryPhraseRecords,
                    setRowsData:
                        copyMethods.setCasGhsPrecautionaryPhraseRecords,
                }}
            />
            {/* Exposure Limits */}
            <CasExposureGrid
                {...editableGridProps}
                copyMethods={{
                    rowsData: copyMethods.casExposures,
                    setRowsData: copyMethods.setCasExposures,
                }}
            />
            {/* Properties */}
            <CasPropertiesGrid
                {...editableGridProps}
                copyMethods={{
                    rowsData: copyMethods.casProperties,
                    setRowsData: copyMethods.setCasProperties,
                }}
            />
            {/* Toxicity */}
            <CasToxicityGrid
                {...editableGridProps}
                copyMethods={{
                    rowsData: copyMethods.casToxicities,
                    setRowsData: copyMethods.setCasToxicities,
                }}
            />
            {/* Eco-Toxicity */}
            <CasEcoToxicityGrid
                {...editableGridProps}
                copyMethods={{
                    rowsData: copyMethods.casEcoToxicities,
                    setRowsData: copyMethods.setCasEcoToxicities,
                }}
            />
            {/* Regulations */}
            <CasRegulationsGrid
                {...editableGridProps}
                copyMethods={{
                    rowsData: copyMethods.casRegulations,
                    setRowsData: copyMethods.setCasRegulations,
                }}
            />
            {/* Hazard Classification */}
            <CasHazardClassificationMappingGrid
                {...editableGridProps}
                copyMethods={{
                    rowsData: copyMethods.casHazardClassificationMappings,
                    setRowsData: copyMethods.setCasHazardClassificationMappings,
                }}
            />
        </BaseForm>
    );
};

export default CasForm;
