import BaseFormGrid from '../../components/grids/BaseFormGrid';
import React from 'react';
import useAttributeTemplatesGrid from './hooks/useAttributeTemplatesGrid';
import { isNilOrEmpty } from '../../utils/objectUtils';

interface AttributeTemplatesGridProps {
    attrEntityId: string;
}
const AttributeTemplatesGrid = ({
    attrEntityId,
}: AttributeTemplatesGridProps) => {
    const {
        attributeTemplatesColDef,
        canManageAttribute,
        redirectToForm,
        attributeTemplates,
        isLoadingTemplates,
        frameworkComponents,
    } = useAttributeTemplatesGrid(attrEntityId);

    return (
        <>
            {!isNilOrEmpty(attrEntityId) ? (
                <BaseFormGrid
                    displayGrid={!isNilOrEmpty(attrEntityId)}
                    isLoading={isLoadingTemplates}
                    rowData={attributeTemplates}
                    columnDefs={attributeTemplatesColDef}
                    frameworkComponents={frameworkComponents}
                    gridClass="full-size-item"
                    displayToolbar={true}
                    displayCreateNewButton={canManageAttribute}
                    onCreateNew={redirectToForm}
                    onRowClicked={({ data }) => redirectToForm(data)}
                />
            ) : (
                <div>Please select an Entity from the dropdown above. </div>
            )}
        </>
    );
};

export default AttributeTemplatesGrid;
