import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { useNavigate } from 'react-router-dom';
import { useContext, useState } from 'react';
import {
    DocDefinition,
    useListDocDefinitionsQuery,
} from '../../../services/documentDesigner/documentDesigner.service';
import SettingsContext from '../../../contexts/settings.context';
import { useGetTermSetQuery } from '../../../services/i18n/i18n.service';
import { PLATFORM_DEFS } from '../../../constants/i18n/translations/termSetDefinitions/platform';
import { skipToken } from '@reduxjs/toolkit/query';
import { GridReadyEvent, ValueGetterParams } from 'ag-grid-community';
import { updateGridModels } from '../../../store/grids';
import { PermissionsUtil } from '../../../utils/permissions/permissionsUtil';
import { PERMISSIONS } from '../../../constants/permissions/Permissions.constants';
import { useListApplicationsQuery } from '../../../services/system/application.service';

const useDocDefinitionGrid = () => {
    const dispatch = useDispatch();
    const user = useSelector((state: RootState) => state.user);
    const { docDefinitionsList } = useSelector(
        (state: RootState) => state.grids?.documentDesigner
    );
    const navigate = useNavigate();
    const [gridApi, setGridApi] = useState(null);
    const { data: rowData, isLoading: isDocDefinitionsLoading } =
        useListDocDefinitionsQuery();
    const { data: applications, isLoading: isApplicationsLoading } =
        useListApplicationsQuery();
    const [gridColumnApi, setGridColumnApi] = useState(null);
    const { settings } = useContext(SettingsContext);
    const { data: termSet } = useGetTermSetQuery(
        settings?.userSettings
            ? {
                  languageId: settings?.userSettings?.languageId,
                  code: PLATFORM_DEFS.DOC_DEFINITION_GRID,
              }
            : skipToken
    );
    const onGridReady = (params: GridReadyEvent) => {
        setGridApi(params.api);
        setGridColumnApi(params.columnApi);
    };

    const redirectToForm = (row: any) => {
        if (row.id) {
            navigate(`/document-designer/doc-definitions/${row.id}`);
        } else {
            navigate('/document-designer/doc-definitions/new');
        }
    };

    const onFirstDataRendered = () => {
        gridColumnApi?.applyColumnState({
            state: docDefinitionsList.column,
            applyOrder: true,
        });

        gridApi?.setFilterModel(docDefinitionsList.filter);
    };

    const onSortChanged = () => {
        dispatch(
            updateGridModels({
                gridLocation: 'documentDesigner',
                gridName: 'docDefinitionsList',
                type: 'column',
                model: gridColumnApi.getColumnState(),
            })
        );
    };

    const canCreateDocDefinition = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.DOCUMENT_DESIGNER.DOC_DEFINITION.CREATE
    );

    const isLoading = isDocDefinitionsLoading || isApplicationsLoading;

    const getApplicationNameByApplicationId = (
        params: ValueGetterParams
    ): string => {
        const docDefinition: DocDefinition = params.data as DocDefinition;
        return applications.find(
            (app) => app.id === docDefinition.applicationId
        )?.name;
    };

    return {
        onGridReady,
        rowData,
        isLoading,
        redirectToForm,
        onSortChanged,
        onFirstDataRendered,
        termSet,
        canCreateDocDefinition,
        getApplicationNameByApplicationId,
    };
};

export default useDocDefinitionGrid;
