import React from 'react';
import { GridOptions } from '../../../components/grids/Grid.constants';
import useCasGrid from './hooks/useCasGrid';
import DefaultColumnTypes from '../../../components/grids/columns/Column.constants';
import { ColDef } from 'ag-grid-community';
import BaseFormGrid from '../../../components/grids/BaseFormGrid';
import CustomHeader from '../../../components/grids/CustomHeader';
import { CasMasterGridDefs } from '../../../constants/i18n/translations/termDefinitions/formulation';
import TranslatableText from '../../../components/i18n/TranslatableText';

const CasGrid = () => {
    const {
        onGridReady,
        rowData,
        isLoading,
        redirectToForm,
        canViewCAS,
        canCreateCAS,
        termSet,
    } = useCasGrid();

    const columnDefs: ColDef[] = [
        {
            field: 'identifier',
            headerComponentFramework: (props: any) => {
                return (
                    <CustomHeader
                        {...props}
                        termSet={termSet}
                        termKey={CasMasterGridDefs.CAS_No}
                    />
                );
            },
            ...DefaultColumnTypes.ShortText,
        },
        {
            field: 'chemicalName',
            headerComponentFramework: (props: any) => {
                return (
                    <CustomHeader
                        {...props}
                        termSet={termSet}
                        termKey={CasMasterGridDefs.Description}
                    />
                );
            },
            ...DefaultColumnTypes.MediumText,
        },
        {
            field: 'allowChemicalUpdate',
            headerComponentFramework: (props: any) => {
                return (
                    <CustomHeader
                        {...props}
                        termSet={termSet}
                        termKey={CasMasterGridDefs.Chemical_Update}
                    />
                );
            },
            ...DefaultColumnTypes.Boolean,
        },
        {
            field: 'lastChemicalUpdate',
            headerComponentFramework: (props: any) => {
                return (
                    <CustomHeader
                        {...props}
                        termSet={termSet}
                        termKey={CasMasterGridDefs.Last_Chemical_Update}
                    />
                );
            },
            ...DefaultColumnTypes.DateOnly,
        },
        {
            field: 'index',
            headerComponentFramework: (props: any) => {
                return (
                    <CustomHeader
                        {...props}
                        termSet={termSet}
                        termKey={CasMasterGridDefs.Index_No}
                    />
                );
            },
            ...DefaultColumnTypes.ShortText,
        },
        {
            field: 'ecIdentifier',
            headerComponentFramework: (props: any) => {
                return (
                    <CustomHeader
                        {...props}
                        termSet={termSet}
                        termKey={CasMasterGridDefs.EC_No}
                    />
                );
            },
            ...DefaultColumnTypes.ShortText,
        },
        {
            field: 'reach',
            headerComponentFramework: (props: any) => {
                return (
                    <CustomHeader
                        {...props}
                        termSet={termSet}
                        termKey={CasMasterGridDefs.Reach_No}
                    />
                );
            },
            ...DefaultColumnTypes.ShortText,
        },
        {
            field: 'tradeSecret',
            headerComponentFramework: (props: any) => {
                return (
                    <CustomHeader
                        {...props}
                        termSet={termSet}
                        termKey={CasMasterGridDefs.Trade_Secret}
                    />
                );
            },
            ...DefaultColumnTypes.Boolean,
        },
    ];

    const defaultColumnDefinitionOptions = {
        ...GridOptions.sortFilterAndWrapColumns,
        floatingFilter: true,
    };

    return (
        <BaseFormGrid
            title={
                <>
                    <TranslatableText
                        termKey={CasMasterGridDefs.Title}
                        termSet={termSet}
                    />
                </>
            }
            displayGrid={canViewCAS}
            isLoading={isLoading}
            rowData={rowData}
            columnDefs={columnDefs}
            gridClass="full-size-item"
            displayToolbar={true}
            displayCreateNewButton={canCreateCAS}
            displayExportDataButton={false}
            displayResetStateButton={false}
            onCreateNew={() => redirectToForm(null)}
            onRowClicked={({ data }) => redirectToForm(data)}
            onGridReady={onGridReady}
            defaultColDef={defaultColumnDefinitionOptions}
            sizeColumnsToFit={true}></BaseFormGrid>
    );
};

export default CasGrid;
