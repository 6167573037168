import { Regulation } from '../../../types/formulation';
import {
    BaseCreateEntityArgs,
    BaseUpdateEntityArgs,
} from '../../serviceInterfaces';
import { datacorAppApi } from '../../datacorAppApi';

const serviceUrl: string = 'formulation/regulation';

export const regulationsApi = datacorAppApi.injectEndpoints({
    endpoints: (build) => ({
        createRegulation: build.mutation<
            Regulation,
            BaseCreateEntityArgs<Regulation>
        >({
            query: (args) => ({
                url: serviceUrl,
                method: 'POST',
                body: args.postBody,
            }),
            invalidatesTags: ['Regulation'],
        }),
        updateRegulation: build.mutation<
            Regulation,
            BaseUpdateEntityArgs<Regulation>
        >({
            query: (args) => ({
                url: `${serviceUrl}/${args.id}`,
                method: 'PUT',
                body: args.postBody,
            }),
            invalidatesTags: ['Regulation', 'ActiveRegulation'],
        }),
        deleteRegulation: build.mutation<Regulation, string>({
            query: (id) => ({
                url: `${serviceUrl}/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Regulation'],
        }),
        getRegulation: build.query<Regulation, string>({
            query: (id) => {
                return {
                    url: `${serviceUrl}/${id}`,
                };
            },
            providesTags: ['ActiveRegulation'],
        }),
        listRegulations: build.query<Regulation[], void>({
            query: () => {
                return {
                    url: serviceUrl,
                };
            },
            providesTags: ['Regulation'],
        }),
        listRegulationsDropdown: build.query<
            { label: string; value: bigint }[],
            void
        >({
            query: () => {
                return {
                    url: serviceUrl,
                };
            },
            transformResponse: (response: Regulation[]) => {
                return response.map((regulation: Regulation) => {
                    return {
                        label: regulation.code,
                        value: regulation.id,
                        title: regulation.title,
                    };
                });
            },
            providesTags: ['Regulation'],
        }),
    }),
});

export const {
    useCreateRegulationMutation,
    useUpdateRegulationMutation,
    useDeleteRegulationMutation,
    useGetRegulationQuery,
    useListRegulationsQuery,
    useListRegulationsDropdownQuery,
} = regulationsApi;
