import React from 'react';
import SdsGrid from './SdsGrid';
const SafetyDataSheet = () => {
    return (
        <>
            <SdsGrid />
        </>
    );
};

export default SafetyDataSheet;
