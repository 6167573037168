import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
    Permission,
    useGetPermissionsQuery,
    useGetRoleQuery,
    useUpdateRoleMutation,
} from '../../../services/organizations/organizations.service';
import useBaseForm from '../../form/hooks/useBaseForm';
import { Node } from '../../../types/Settings.types';
import _ from 'lodash';
import { isNil, isNilOrEmpty } from '../../../utils/objectUtils';
import { isFeatureFlagEnabled } from '../../../utils/featureFlag/featureFlagUtil';
import { RootState } from '../../../store';

interface NodeMapChild {
    path: Path;
}

interface Path {
    label: string;
    isCategory: boolean;
    children: Array<NodeMapChild>;
}

const usePermissionTree = (id: string) => {
    const user = useSelector((state: RootState) => state.user);
    const { data: role, isLoading: isLoadingRole } = useGetRoleQuery({
        roleId: id,
        full: true,
    });
    const { data: permissionData, isLoading: isLoadingPermission } =
        useGetPermissionsQuery();
    const [permissions, setPermissions] = useState(null);
    const blankRolePermissions: any = {
        permissions: [],
    };
    const [treeData, setTreeData] = useState([]);
    const [updateRolePermission] = useUpdateRoleMutation();
    const [permissionFields, setPermissionFields] =
        useState(blankRolePermissions);

    const onUpdateRolePermissions = () => {
        return updateRolePermission({
            id: role.id,
            postBody: {
                permissions: fields.permissions.map((permission: string) => {
                    const lookupPermission = permissionData.find(
                        (fullPermission: Permission) =>
                            fullPermission.code === permission
                    );
                    return { id: lookupPermission.id };
                }),
            },
        } as any);
    };

    const {
        fields,
        handleFieldChange,
        setValues,
        formMethods,
        onCreate,
        onUpdate,
        onDelete,
        closeForm,
    } = useBaseForm({
        closePath: '/administration/organizations/permissions',
        blankEntity: blankRolePermissions,
        activeEntity: permissionFields,
        getDescription: () => {
            return `${role.name} Permissions`;
        },
        createEntity: null,
        updateEntity: onUpdateRolePermissions,
        deleteEntity: null,
    });

    const onCheckChange = (node: Node) => {
        let enabledPermissions = _.cloneDeep(fields['permissions']);

        if (node.checked) {
            enabledPermissions.push(node.value);
            setValues({ permissions: enabledPermissions });
        } else {
            _.remove(
                enabledPermissions,
                (permission) => permission === node.value
            );
            setValues({ permissions: enabledPermissions });
        }
    };

    const buildNodes = () => {
        let data: any = [];
        const nodeMap: any = {};
        setTreeData([]);
        permissions.forEach((permission: Permission) => {
            if (
                isNilOrEmpty(permission.featureFlags) ||
                _.every(permission.featureFlags, (featureFlag) => {
                    return isFeatureFlagEnabled(
                        user.enabledFeatureFlags,
                        featureFlag.code
                    );
                })
            ) {
                const pathArray = permission.code.split('.');
                //remove last item from the array, as it's not needed for the path
                pathArray.pop();

                let currentChildrenReference: any = null;
                pathArray.forEach((path, index) => {
                    if (!currentChildrenReference) {
                        if (nodeMap[path]) {
                            currentChildrenReference = nodeMap[path];
                        } else {
                            const nodeReference = nodeMap[path];
                            if (!nodeReference) {
                                nodeMap[path] = {
                                    label: path,
                                    isCategory: true,
                                    children: [],
                                };
                                currentChildrenReference = nodeMap[path];
                            }
                        }
                    } else {
                        const child = currentChildrenReference.children.find(
                            (child: Path) => child.label === path
                        );
                        if (!child) {
                            currentChildrenReference.children.push({
                                label: path,
                                isCategory: true,
                                children: [],
                            });
                        }

                        currentChildrenReference =
                            currentChildrenReference.children.find(
                                (child: Path) => child.label === path
                            );
                    }

                    if (index === pathArray.length - 1) {
                        const isChecked = (permission as any).checked;
                        currentChildrenReference.children.push({
                            label: permission.name,
                            value: permission.code,
                            disabled: false,
                            checked: isChecked,
                            children: [],
                        });
                    }
                });
            }
        });

        Object.keys(nodeMap).forEach((key) => {
            data.push(nodeMap[key]);
        });

        setTreeData(() => data);
    };

    useEffect(() => {
        if (!isNil(role) && !isNil(permissionData)) {
            const updatedPermissions = permissionData.map(
                (permission: Permission) => {
                    const updatedPermission = { ...permission };
                    if (
                        role.permissions.find(
                            (rolePermissionid: bigint) =>
                                rolePermissionid === permission.id
                        )
                    ) {
                        (updatedPermission as any).checked = true;
                        return updatedPermission;
                    }

                    return permission;
                }
            );

            setPermissionFields({
                permissions: updatedPermissions
                    .filter(
                        (updatedPermission: any) => updatedPermission.checked
                    )
                    .map((updatedPermission: any) => updatedPermission.code),
            });

            setPermissions(updatedPermissions);
        }
    }, [role, permissionData]);

    useEffect(() => {
        if (permissions) {
            buildNodes();
        }
    }, [permissions]);

    return {
        permissions,
        role,
        treeData,
        isLoading: isLoadingPermission,
        fields,
        onCheckChange,
        handleFieldChange,
        formMethods,
        onCreate,
        onUpdate,
        onDelete,
        closeForm,
    };
};

export default usePermissionTree;
