import { IAccountingNav } from '../../types/Accounting.types';

export const AccountingNavValues = {
    accountingNav: {
        sourceCurrencyCode: '',
        rateTypeCode: '',
        currencyId: '',
        tabIndex: '1',
        isFormRedirect: false,
    } as IAccountingNav,
};
