import {
    RDFormulaComponent,
    RDFormulaTotal,
} from '../../../../../../types/formulation';

export class RdFormulaTotalsUtil {
    static getTotals = (
        formulaTotals: RDFormulaTotal,
        componentList: RDFormulaComponent[]
    ) => {
        const manualYield = formulaTotals?.yieldCalcOverride
            ? formulaTotals?.manualYieldPct
            : null;
        const totalQty = RdFormulaTotalsUtil.getTotalValue(componentList);
        const totalYieldPercentage = RdFormulaTotalsUtil.getTotalYieldValue(
            componentList,
            totalQty
        );
        const totalNet = RdFormulaTotalsUtil.getNetValue(
            componentList,
            totalQty,
            manualYield ? manualYield : totalYieldPercentage
        );

        const manualYieldPct = formulaTotals?.yieldCalcOverride
            ? Number(formulaTotals?.manualYieldPct)
            : totalYieldPercentage;

        return {
            totalQty,
            totalYieldPercentage,
            totalNet,
            manualYieldPct,
        };
    };

    static getTotalValue = (componentList: RDFormulaComponent[]) => {
        let total = 0;
        componentList.forEach((component: any) => {
            total +=
                component.stockQty * component.componentToTotalConversionFactor;
        });

        return total;
    };

    static getTotalYieldValue = (componentList: any[], totalQty: number) => {
        let totalYieldPercentage = 0;
        let scrapTotalQty = 0;
        componentList.forEach((component: any) => {
            const convertedQty =
                component.stockQty * component.componentToTotalConversionFactor;

            //(Formula Qty * (Scrap %/100), add to Running total(Scrap Total Qty)
            scrapTotalQty += convertedQty * (component.scrapPercent / 100);
        });

        totalYieldPercentage = ((totalQty - scrapTotalQty) / totalQty) * 100;

        return totalYieldPercentage;
    };

    static getNetValue = (
        componentList: any[],
        totalQty: number,
        totalYieldPercentage: number
    ) => {
        //Total Net = (Total Qty * (Calculated Yield%/100)
        let totalNet = totalQty * (totalYieldPercentage / 100);

        let totalNetScrapPercentage = 0;

        componentList.forEach((component) => {
            //Total Net = (Total Qty * (1/ Component   Scrap %) * (Total Scrap %/100)
            totalNetScrapPercentage +=
                (1 / component.scrapPercent) * (totalYieldPercentage / 100);
        });

        return totalNet;
    };

    static getTotalCosts = (
        componentList: RDFormulaComponent[],
        formulaCalculatedTotals: RDFormulaTotal
    ) => {
        const manualYield = formulaCalculatedTotals?.yieldCalcOverride
            ? formulaCalculatedTotals?.manualYieldPct
            : null;
        const yieldValue = manualYield
            ? manualYield
            : formulaCalculatedTotals?.calculatedYieldPct;
        const formulaQty = formulaCalculatedTotals?.formulaTotal || 0;
        let totalExtendedCost =
            RdFormulaTotalsUtil.getTotalExtendedCost(componentList);
        const totalUnitCost =
            Number((totalExtendedCost / formulaQty).toFixed(4)) || 0;
        const totalNetCost =
            Number((totalUnitCost / (yieldValue / 100)).toFixed(4)) || 0;

        totalExtendedCost = Number(totalExtendedCost.toFixed(4));

        return {
            totalExtendedCost,
            totalUnitCost,
            totalNetCost,
        };
    };

    static getTotalExtendedCost = (componentList: RDFormulaComponent[]) => {
        let total = 0;
        componentList.forEach((component: any) => {
            let extCost = Number(component.extendedCost);
            if (isNaN(extCost)) {
                extCost = 0;
            }
            total += extCost;
        });

        return total;
    };

    static getTotalKG = (componentList: RDFormulaComponent[]) => {
        let total = 0;

        componentList.forEach((component: any) => {
            let componentWeight = Number(component.weightKg);
            if (isNaN(componentWeight)) {
                componentWeight = 0;
            }
            total += componentWeight;
        });

        return total;
    };

    static getTotalLiters = (componentList: RDFormulaComponent[]) => {
        let total = 0;

        componentList.forEach((component: any) => {
            let componentVol = Number(component.volumeLitres);
            if (isNaN(componentVol)) {
                componentVol = 0;
            }
            total += componentVol;
        });

        return total;
    };
}
