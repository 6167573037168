import React from 'react';
import { Grid } from '@mui/material';
import { AgGridColumn } from 'ag-grid-react';
import AgGrid from '../../components/grids/Grid';
import DatacorLogoSpinner from '../../components/datacorLogoSpinner/DatacorLogoSpinner';
import useLanguagesGrid from './hooks/useLanguagesGrid';
import { LanguagesGridDefs } from '../../constants/i18n/translations/termDefinitions/platform';
import CustomHeader from '../../components/grids/CustomHeader';

const LanguagesGrid = () => {
    const {
        isLoadingLanguages,
        isFetching,
        languages,
        onGridReady,
        onSelectionChanged,
        onSortChanged,
        onFilterChanged,
        onFirstDataRendered,
        termSet,
    } = useLanguagesGrid();
    return (
        <>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    {!isLoadingLanguages && !isFetching ? (
                        <AgGrid
                            displayToolbar={true}
                            testId="languages-list-grid"
                            paginationPageSize={10}
                            rowData={languages}
                            onFirstDataRendered={onFirstDataRendered}
                            onSortChanged={onSortChanged}
                            onFilterChanged={onFilterChanged}
                            onGridReady={onGridReady}
                            rowSelection={'single'}
                            onRowSelected={onSelectionChanged}
                            dataKey={'name'}
                            immutableData={true}
                            defaultColDef={{
                                resizable: true,
                                sortable: true,
                                unSortIcon: true,
                                filter: true,
                                floatingFilter: true,
                                wrapText: true,
                            }}>
                            <AgGridColumn
                                flex={1}
                                field="code"
                                filter={'agTextColumnFilter'}
                                headerComponentFramework={(props: any) => {
                                    return (
                                        <CustomHeader
                                            {...props}
                                            termSet={termSet}
                                            termKey={LanguagesGridDefs.Code}
                                        />
                                    );
                                }}
                            />
                            <AgGridColumn
                                flex={2}
                                field="name"
                                filter={'agTextColumnFilter'}
                                headerComponentFramework={(props: any) => {
                                    return (
                                        <CustomHeader
                                            {...props}
                                            termSet={termSet}
                                            termKey={LanguagesGridDefs.Name}
                                        />
                                    );
                                }}
                            />
                            <AgGridColumn
                                flex={1}
                                field="iso3"
                                filter={'agTextColumnFilter'}
                                headerComponentFramework={(props: any) => {
                                    return (
                                        <CustomHeader
                                            {...props}
                                            termSet={termSet}
                                            termKey={LanguagesGridDefs.ISO3}
                                        />
                                    );
                                }}
                            />
                            <AgGridColumn
                                flex={1}
                                filter={'agTextColumnFilter'}
                                field="iso2"
                                headerComponentFramework={(props: any) => {
                                    return (
                                        <CustomHeader
                                            {...props}
                                            termSet={termSet}
                                            termKey={LanguagesGridDefs.ISO2}
                                        />
                                    );
                                }}
                            />
                        </AgGrid>
                    ) : (
                        <DatacorLogoSpinner />
                    )}
                </Grid>
            </Grid>
        </>
    );
};

export default LanguagesGrid;
