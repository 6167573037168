import { useContext } from 'react';
import { skipToken } from '@reduxjs/toolkit/query';
import { RootStateOrAny, useSelector } from 'react-redux';
import { PermissionsUtil } from '../../../../../utils/permissions/permissionsUtil';
import { PERMISSIONS } from '../../../../../constants/permissions/Permissions.constants';
import useBaseForm from '../../../../form/hooks/useBaseForm';
import {
    CasEcoToxicity,
    CasExposure,
    CasGhsHazardPhrase,
    CasGhsPrecautionaryPhrase,
    CasHazardClassificationMapping,
    CasMaster,
    CasPictogram,
    CasProperty,
    CasRegulation,
    CasToxicity,
} from '../../../../../types/formulation';
import {
    useCreateCasMutation,
    useDeleteCasMutation,
    useGetCasQuery,
    useUpdateCasMutation,
} from '../../../../../services/formulation/casMaster/cas/casMaster.service';
import { RoutingValues } from '../../../../../types/Shared.types';
import useCopyCasForm from './useCopyCasForm';
import { ChildEditableGridProps } from '../../../../grid/utils/editableGrid/ChildEditableGridProps';
import { ChildFormProps } from '../../../../form/helpers/ChildFormProps';
import { ExtendedFormProps } from '../../../../form/helpers/ExtendedFormProps';
import SettingsContext from '../../../../../contexts/settings.context';
import { useGetTermSetQuery } from '../../../../../services/i18n/i18n.service';
import { FORMULATION_DEFS } from '../../../../../constants/i18n/translations/termSetDefinitions/formulation';
import useChildFormHelper from '../../../../form/hooks/useChildFormHelper';
import { isNilOrEmpty } from '../../../../../utils/objectUtils';

const useCasForm = (id: string) => {
    const user = useSelector((state: RootStateOrAny) => state.user);

    const { data: currentCasRecord, isLoading: isLoadingCasRecord } =
        useGetCasQuery(isNilOrEmpty(id) ? skipToken : id);

    const blankCasMaster: CasMaster = {
        identifier: '',
        chemicalName: '',
        allowChemicalUpdate: false,
        lastChemicalUpdate: null as Date,
        index: '',
        ecIdentifier: '',
        reach: '',
        classification: '',
        molecularFormula: '',
        displayOnSds: false,
        weightThreshold: null as number,
        tradeSecret: false,
        casPictogram: {
            casPictogramSignalWordId: 1,
            explosive: false,
            flammable: false,
            oxidizing: false,
            compressedGas: false,
            toxic: false,
            corrosive: false,
            irritant: false,
            healthHazard: false,
            environmentDamaging: false,
        } as CasPictogram,
        casGhsHazardPhrases: [] as CasGhsHazardPhrase[],
        casGhsPrecautionaryPhrases: [] as CasGhsPrecautionaryPhrase[],
        casToxicities: [] as CasToxicity[],
        casEcoToxicities: [] as CasEcoToxicity[],
        casExposures: [] as CasExposure[],
        casProperties: [] as CasProperty[],
        casRegulations: [] as CasRegulation[],
        casHazardClassificationMaps: [] as CasHazardClassificationMapping[],
        businessEntityId: user?.businessEntity?.id,
    };

    const [createCas] = useCreateCasMutation();
    const [updateCas] = useUpdateCasMutation();
    const [deleteCas] = useDeleteCasMutation();

    const displayCreateNewButton = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.FORMULATION.CAS_MASTER.CREATE
    );
    const displayDeleteButton = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.FORMULATION.CAS_MASTER.DELETE
    );
    const displayUpdateButton = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.FORMULATION.CAS_MASTER.EDIT
    );
    const displayCopyButton = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.FORMULATION.CAS_MASTER.CREATE
    );

    const { settings } = useContext(SettingsContext);
    const { data: termSet } = useGetTermSetQuery(
        settings?.userSettings
            ? {
                  languageId: settings?.userSettings?.languageId,
                  code: FORMULATION_DEFS.CAS_MASTER_FORM,
              }
            : skipToken
    );

    const { childrenFormHooks, allChildrenFormMethods, registerChildForm } =
        useChildFormHelper();

    const {
        fields,
        formMethods,
        handleFieldChange,
        onCreate,
        onUpdate,
        onDelete,
        closeForm,
        handleChildrenRecords,
        handleChildRecord,
        copyForm,
        formType,
        setValues,
        childForms,
        hasEntityChanges,
    } = useBaseForm({
        closePath: '/formulation/casMaster',
        copyPath: `/formulation/casMaster/${RoutingValues.newId}`,
        reroute: true,
        blankEntity: blankCasMaster,
        activeEntity: currentCasRecord,
        childForms: childrenFormHooks,
        onlyUpdateEntityWithChanges: true,
        getDescription: () => {
            return `CAS Master ${fields.identifier}`;
        },
        createEntity: () => {
            return createCas({ postBody: fields });
        },
        updateEntity: () => {
            return updateCas({
                id: id as unknown as number,
                postBody: fields,
            });
        },
        deleteEntity: () => {
            return deleteCas(id);
        },
    });

    const parentData = {
        currentParentRecord: fields as CasMaster,
        isParentLoading: isLoadingCasRecord,
        user: user,
    };

    const editableGridProps: ChildEditableGridProps<CasMaster> = {
        parentData,
        displayGridButtons: {
            showAddButton:
                (displayCreateNewButton && displayUpdateButton) ||
                (displayCreateNewButton && !id),
            showDeleteButton: displayDeleteButton,
            showSortButton:
                (displayCreateNewButton && !id) ||
                (displayUpdateButton && !!id),
            isGridEditable:
                (displayCreateNewButton && !id) ||
                (displayUpdateButton && !!id),
        },
        helpers: {
            handleChildrenRecords,
        },
    };

    const childFormProps: ChildFormProps<CasMaster> = {
        parentData: {
            ...parentData,
            dataFromQuery: currentCasRecord,
        },
        permissions: {
            canCreate: displayCreateNewButton,
            canUpdate: displayUpdateButton,
            disabledField:
                parentData.currentParentRecord.id && !displayUpdateButton,
        },
        helpers: {
            handleChildRecord,
            registerChildForm,
        },
    };

    const extendedFormProps: ExtendedFormProps<CasMaster> = {
        parentData,
        helpers: {
            formMethods,
            handleFieldChange,
        },
        permissions: {
            readOnly: !!id,
        },
    };

    const { copyMethods } = useCopyCasForm({
        fields,
        dataFromQuery: currentCasRecord,
        setValues,
        formType,
    });

    return {
        fields,
        isLoadingCasRecord,
        hasEntityChanges,
        childForms,
        allFormMethods: [formMethods, ...allChildrenFormMethods],
        onCreate,
        onDelete,
        onUpdate,
        closeForm,
        termSet,
        /** Copy functionality**/
        copyForm,
        copyMethods,
        /** All display/permissions **/
        display: {
            displayCreateNewButton,
            displayUpdateButton,
            displayDeleteButton,
            displayCopyButton,
            readOnly: id && !displayUpdateButton,
        },
        /** editable grids, child form and extended form methods **/
        children: {
            editableGridProps,
            childFormProps,
            extendedFormProps,
        },
    };
};

export default useCasForm;
