import React from 'react';
import BaseFormGrid from '../../../components/grids/BaseFormGrid';
import { FiscalYearStatusGridDefs } from '../../../constants/i18n/translations/termDefinitions/accounting';
import TranslatableText from '../../../components/i18n/TranslatableText';
import { cloneDeep } from 'lodash';
import useFiscalYearStatusGrid from './hooks/useFiscalYearStatusGrid';
import booleanCellRenderer from '../../../components/grids/cellRenderers/booleanCell.renderer';

const FiscalYearStatusGrid = () => {
    const {
        canView,
        canUpdate,
        canCreate,
        termSet,
        rowData,
        colDefs,
        handleGridEdits,
        isLoadingData,
        redirectToForm,
    } = useFiscalYearStatusGrid();

    return (
        <BaseFormGrid
            title={
                (
                    <TranslatableText
                        termKey={FiscalYearStatusGridDefs.Title}
                        termSet={termSet}
                    />
                ) as unknown as string
            }
            displayGrid={canView}
            isLoading={isLoadingData()}
            rowData={cloneDeep(rowData)}
            useRowDrag={canUpdate}
            sortKey={'sortOrder'}
            columnDefs={colDefs}
            gridClass="full-size-item"
            displayToolbar={true}
            displayCreateNewButton={canCreate}
            displayExportDataButton={true}
            displayResetStateButton={true}
            onCreateNew={redirectToForm}
            onRowClicked={({ data }) => redirectToForm(data)}
            onHandleGridEdits={handleGridEdits}
            frameworkComponents={{
                booleanCellRenderer: booleanCellRenderer,
            }}
        />
    );
};

export default FiscalYearStatusGrid;
