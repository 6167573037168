import React, { useContext, useEffect, useState } from 'react';
import { skipToken } from '@reduxjs/toolkit/query';
import { ColDef } from 'ag-grid-community';
import { cloneDeep } from 'lodash';
import SettingsContext from '../../../../../../../contexts/settings.context';
import { useGetTermSetQuery } from '../../../../../../../services/i18n/i18n.service';
import { FORMULATION_DEFS } from '../../../../../../../constants/i18n/translations/termSetDefinitions/formulation';
import { ColumnMaxValueValidator } from '../../../../../../grid/validators/columnMaxValue.validator';
import applyEditableGridValidations from '../../../../../../grid/utils/applyEditableGridValidations';
import CustomHeader from '../../../../../../../components/grids/CustomHeader';
import DefaultColumnTypes from '../../../../../../../components/grids/columns/Column.constants';
import { useListLocaleGroupByActiveQuery } from '../../../../../../../services/formulation/localeGroup/localeGroup.service';
import { useListCasHazardClassMappingsQuery } from '../../../../../../../services/formulation/casMaster/casHazardClassificationMapping/casHazardClassificationMapping.service';
import {
    CasHazardClassificationMapping,
    CasMaster,
} from '../../../../../../../types/formulation';
import { LocaleGroupMapping } from '../../../../../../../types/formulation/shared/HazardClassificationMapping.type';
import { CasMasterFormDefs } from '../../../../../../../constants/i18n/translations/termDefinitions/formulation';
import { ChildEditableGridWithCopyFunctionProps } from '../../../../../../grid/utils/editableGrid/ChildEditableGridWithCopyFunctionProps';
import { isNilOrEmpty } from '../../../../../../../utils/objectUtils';
import { PermissionsUtil } from '../../../../../../../utils/permissions/permissionsUtil';
import { PERMISSIONS } from '../../../../../../../constants/permissions/Permissions.constants';

const useCasHazardClassificationMappingGrid = (
    props: ChildEditableGridWithCopyFunctionProps<
        CasMaster,
        CasHazardClassificationMapping
    >
) => {
    const { currentParentRecord, user } = props.parentData;

    const { isGridEditable } = props.displayGridButtons;

    const { handleChildrenRecords } = props.helpers;

    const {
        rowsData: hazardClassMappingData,
        setRowsData: setHazardClassMappingData,
    } = props.copyMethods;

    const {
        isLoading: isLoadingHazardClassMaps,
        data: hazardClassMappingList,
    } = useListCasHazardClassMappingsQuery(
        currentParentRecord.id ? skipToken : null
    );

    const { isLoading: isLoadingLocaleGrps, data: localeGrpList } =
        useListLocaleGroupByActiveQuery(true);

    const { settings } = useContext(SettingsContext);
    const { data: termSet } = useGetTermSetQuery(
        settings?.userSettings
            ? {
                  languageId: settings?.userSettings?.languageId,
                  code: FORMULATION_DEFS.CAS_MASTER_FORM,
              }
            : skipToken
    );
    const [columnDefs, setColumnDefs] = useState<ColDef[]>(null);

    const canViewHazardClassMappings = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.FORMULATION.HAZARD_CLASS_MAPPING.VIEW
    );

    const staticColumnDefs = [
        {
            field: 'code',
            headerComponentFramework: (props: any) => {
                return (
                    <CustomHeader
                        {...props}
                        termSet={termSet}
                        termKey={
                            CasMasterFormDefs.Hazard_Classification_Mapping_Hazard_Code
                        }
                    />
                );
            },
            minWidth: DefaultColumnTypes.ShortText.minWidth,
            filter: DefaultColumnTypes.ShortText.filter,
            ...DefaultColumnTypes.AutoHeight,
        },
        {
            field: 'description',
            headerComponentFramework: (props: any) => {
                return (
                    <CustomHeader
                        {...props}
                        termSet={termSet}
                        termKey={
                            CasMasterFormDefs.Hazard_Classification_Mapping_Description
                        }
                    />
                );
            },
            minWidth: DefaultColumnTypes.MediumText.minWidth,
            filter: DefaultColumnTypes.MediumText.filter,
            ...DefaultColumnTypes.AutoHeight,
        },
    ];

    const buildLocaleGrpColumns = (): ColDef[] => {
        const localGrpColums: ColDef[] = [];
        localeGrpList
            .filter((current) => current.active === true)
            .forEach((current) => {
                const colDef: ColDef = {
                    field: `${current.groupId}${current.id}Value`,
                    headerName: current.groupId,
                    minWidth: DefaultColumnTypes.MediumText.minWidth,
                    filter: DefaultColumnTypes.MediumText.filter,
                    editable: isGridEditable,
                };
                localGrpColums.push(colDef);
            });
        return localGrpColums;
    };

    const getGroupIdById = (id: bigint): string => {
        if (localeGrpList && id) {
            const localeGrp = localeGrpList.find(
                (current) => current.id === id
            );
            return localeGrp?.groupId;
        }
        return null;
    };

    const handleSaveGridEdits = (
        editedRows: CasHazardClassificationMapping[]
    ) => {
        if (editedRows) {
            const updatedList = hazardClassMappingData?.filter((current) => {
                return isNilOrEmpty(
                    editedRows.find(
                        (editedRecord) => editedRecord.rowId === current.rowId
                    )
                );
            });

            const newList = updatedList
                ? updatedList.concat(editedRows)
                : editedRows;

            handleChildrenRecords('casHazardClassificationMaps', newList);
        }
    };

    const handleRowValidations = (
        editedRows: CasHazardClassificationMapping[]
    ) => {
        editedRows.forEach((current) => {
            current.validationRules = [];
            current.localeGroupIdList.forEach((groupId) => {
                const valueProp = (current[groupId] as LocaleGroupMapping)
                    .valuePropName;
                const localeGrpId = (current[groupId] as LocaleGroupMapping)
                    .localeGroupId;
                const localeGrpCode = getGroupIdById(localeGrpId);
                current.validationRules.push(
                    ColumnMaxValueValidator(
                        localeGrpCode,
                        20,
                        current[valueProp] as string
                    )
                );
            });
            const message = `Hazard Code ${current.code}`;
            applyEditableGridValidations(current, message);
        });
        return editedRows;
    };

    useEffect(() => {
        if (localeGrpList) {
            const localeGrpColums = buildLocaleGrpColumns();
            setColumnDefs([...staticColumnDefs, ...localeGrpColums]);
        }
    }, [isLoadingLocaleGrps, termSet]);

    useEffect(() => {
        if (hazardClassMappingList) {
            currentParentRecord.casHazardClassificationMaps =
                hazardClassMappingList;
        }
    }, [hazardClassMappingList]);

    useEffect(() => {
        if (currentParentRecord.casHazardClassificationMaps) {
            const list = cloneDeep(
                currentParentRecord.casHazardClassificationMaps
            ).map((current, index) => {
                if (!current.rowId) {
                    current.rowId = index + 1;
                }
                return current;
            });
            setHazardClassMappingData(list);
        }
    }, [currentParentRecord.casHazardClassificationMaps]);

    return {
        columnDefs,
        termSet,
        canViewHazardClassMappings,
        handleSaveGridEdits,
        handleRowValidations,
    };
};

export default useCasHazardClassificationMappingGrid;
