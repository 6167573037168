import { useForm } from '../../../libs/hooksLib';
import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    DepartmentCode,
    useCreateDepartmentCodeMutation,
    useGetDepartmentCodeQuery,
    useUpdateDepartmentCodeMutation,
} from '../../../services/organizations/organizations.service';
import {
    BaseCreateEntityArgs,
    BaseUpdateEntityArgs,
} from '../../../services/serviceInterfaces';
import { skipToken } from '@reduxjs/toolkit/query';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { showNotificationError } from '../../../libs/errorLib';
import SettingsContext from '../../../contexts/settings.context';
import { useGetTermSetQuery } from '../../../services/i18n/i18n.service';
import { PLATFORM_DEFS } from '../../../constants/i18n/translations/termSetDefinitions/platform';

const useDepartmentCodeForm = (id: string) => {
    const dispatch = useDispatch();
    const user = useSelector((state: RootState) => state.user);
    const navigate = useNavigate();
    const { fields, handleFieldChange, setValues, formMethods, isFormValid } =
        useForm({});
    const { data: departmentCode, isLoading: isLoading } =
        useGetDepartmentCodeQuery(id ? { departmentCodeId: id } : skipToken);
    const [createDepartmentCode] = useCreateDepartmentCodeMutation();
    const [updateDepartmentCode] = useUpdateDepartmentCodeMutation();

    const defaultDepartmentCode: DepartmentCode = {
        name: '',
        code: '',
        subscriberId: user?.subscriber?.id,
    };

    const { settings } = useContext(SettingsContext);
    const { data: termSet } = useGetTermSetQuery(
        settings?.userSettings
            ? {
                  languageId: settings?.userSettings?.languageId,
                  code: PLATFORM_DEFS.DEPARTMENT_CODES_FORM,
              }
            : skipToken
    );

    useEffect(() => {
        setValues(departmentCode || defaultDepartmentCode);
    }, [departmentCode]);

    const handleCreateDepartmentCode = async () => {
        if (!isFormValid) {
            return;
        }

        const body: BaseCreateEntityArgs<DepartmentCode> = {
            postBody: fields,
        };

        try {
            await createDepartmentCode(body);
            handleClose();
        } catch (e) {
            return showNotificationError(e.message, 'error', dispatch);
        }
    };

    const handleUpdateDepartmentCode = async () => {
        if (!isFormValid) {
            return;
        }

        const body: BaseUpdateEntityArgs<DepartmentCode> = {
            id: id as unknown as number,
            postBody: fields,
        };

        try {
            await updateDepartmentCode(body);
            handleClose();
        } catch (e) {
            return showNotificationError(e.message, 'error', dispatch);
        }
    };

    const handleClose = () => {
        navigate('/administration/organizations/departmentCodes');
    };

    return {
        fields,
        departmentCode,
        isLoading,
        handleCreateDepartmentCode,
        handleUpdateDepartmentCode,
        handleFieldChange,
        handleClose,
        setValues,
        formMethods,
        termSet,
    };
};

export default useDepartmentCodeForm;
