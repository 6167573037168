import React, { useContext, useEffect, useState } from 'react';
import { isNilOrEmpty } from '../../../../../../../utils/objectUtils';
import { GridOptions } from '../../../../../../../components/grids/Grid.constants';
import {
    FormulaProperty,
    ItemMaster,
    ItemProperty,
} from '../../../../../../../types/formulation';
import _ from 'lodash';
import { RowStatus } from '../../../../../../../components/grids/hooks/useBaseGridEditable';
import { PermissionsUtil } from '../../../../../../../utils/permissions/permissionsUtil';
import { PERMISSIONS } from '../../../../../../../constants/permissions/Permissions.constants';
import { ColumnMaxValueValidator } from '../../../../../../grid/validators/columnMaxValue.validator';
import { useListFormulaPropertyQuery } from '../../../../../../../services/formulation/formulaProperty/formulaProperty.service';
import applyEditableGridValidations from '../../../../../../grid/utils/applyEditableGridValidations';
import {
    SelectedDropdownOption,
    SelectionOption,
} from '../../../../../../../types/Shared.types';
import { ChildEditableGridWithCopyFunctionProps } from '../../../../../../grid/utils/editableGrid/ChildEditableGridWithCopyFunctionProps';
import SettingsContext from '../../../../../../../contexts/settings.context';
import { useGetTermSetQuery } from '../../../../../../../services/i18n/i18n.service';
import { FORMULATION_DEFS } from '../../../../../../../constants/i18n/translations/termSetDefinitions/formulation';
import { skipToken } from '@reduxjs/toolkit/query';
import CustomHeader from '../../../../../../../components/grids/CustomHeader';
import { ItemMasterFormDefs } from '../../../../../../../constants/i18n/translations/termDefinitions/formulation';

export interface ItemPropertiesGridProps
    extends ChildEditableGridWithCopyFunctionProps<ItemMaster, ItemProperty> {
    unitOfMeasureOptions: SelectionOption[];
    isLoadingUnitOfMeasure: boolean;
}

const useItemPropertiesGrid = (props: ItemPropertiesGridProps) => {
    const { currentParentRecord, user } = props.parentData;

    const { showDeleteButton, isGridEditable } = props.displayGridButtons;

    const { handleChildrenRecords } = props.helpers;

    const { rowsData: itemProperties, setRowsData: setItemProperties } =
        props.copyMethods;

    const {
        data: formulaPropertyOptions,
        isLoading: isLoadingFormulaProperties,
    } = useListFormulaPropertyQuery();

    const [showMultiSelectGridModal, setShowMultiSelectGridModal] =
        useState(false);

    const [maxRowId, setMaxRowId] = useState(0);
    const [formulaPropertyList, setFilteredPropertyList] = useState(
        [] as FormulaProperty[]
    );

    const [itemPropertyColDef, setItemPropertyColDef] = useState(null);

    const [unitOfMeasureColumnDefs, setUnitOfMeasureColumnDefs] =
        useState(null);

    const canViewUOM = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.SETUP.UNITS_OF_MEASURE.VIEW
    );

    const canViewProperties = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.FORMULATION.FORMULA_PROPERTIES.VIEW
    );

    const colDefOptions = {
        ...GridOptions.sortFilterAndWrapColumns,
        floatingFilter: true,
    };

    const { settings } = useContext(SettingsContext);
    const { data: termSet } = useGetTermSetQuery(
        settings?.userSettings
            ? {
                  languageId: settings?.userSettings?.languageId,
                  code: FORMULATION_DEFS.ITEM_MASTER_FORM,
              }
            : skipToken
    );

    useEffect(() => {
        if (
            !props.isLoadingUnitOfMeasure &&
            !isNilOrEmpty(props.unitOfMeasureOptions)
        ) {
            setUnitOfMeasureColumnDefs([
                {
                    field: 'unitCode',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={
                                    ItemMasterFormDefs.Properties_UOM_Form_UOM
                                }
                            />
                        );
                    },
                    cellClass: 'ag-left-aligned-cell',
                    filter: 'agTextColumnFilter',
                },
                {
                    field: 'description',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={
                                    ItemMasterFormDefs.Properties_UOM_Form_Description
                                }
                            />
                        );
                    },
                    cellClass: 'ag-left-aligned-cell',
                    filter: 'agTextColumnFilter',
                },
            ]);
        }
    }, [props.isLoadingUnitOfMeasure, props.unitOfMeasureOptions]);

    useEffect(() => {
        if (
            !isLoadingFormulaProperties &&
            !isNilOrEmpty(formulaPropertyOptions) &&
            !props.isLoadingUnitOfMeasure &&
            !isNilOrEmpty(props.unitOfMeasureOptions) &&
            !isNilOrEmpty(unitOfMeasureColumnDefs)
        ) {
            setItemPropertyColDef([
                {
                    field: 'deleteColumn',
                    minWidth: 50,
                    hide: !showDeleteButton || !isGridEditable,
                    filter: false,
                },
                {
                    field: 'propertyCode',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={ItemMasterFormDefs.Property_ID}
                            />
                        );
                    },
                    editable: false,
                    filter: 'agTextColumnFilter',
                },
                {
                    field: 'description',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={ItemMasterFormDefs.Property_Name}
                            />
                        );
                    },
                    editable: false,
                    filter: 'agTextColumnFilter',
                },
                {
                    field: 'propertyValue',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={ItemMasterFormDefs.Property_Value}
                            />
                        );
                    },
                    editable: isGridEditable,
                    filter: 'agTextColumnFilter',
                },
                {
                    field: 'unitCode',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={ItemMasterFormDefs.Property_UOM}
                            />
                        );
                    },
                    editable: isGridEditable,
                    cellRenderer: (params: any) => params.value.display,
                    useGridPopup: true,
                    gridPopupParameters: {
                        isLoading: props.isLoadingUnitOfMeasure,
                        displayGrid: true,
                        rowData: canViewUOM ? props.unitOfMeasureOptions : [],
                        defaultColDef: colDefOptions,
                        columnDefs: unitOfMeasureColumnDefs,
                        displayField: 'label',
                    },
                    ...GridOptions.sortFilterAndWrapColumns,
                },
            ]);
        }
    }, [
        props.isLoadingUnitOfMeasure,
        props.unitOfMeasureOptions,
        unitOfMeasureColumnDefs,
        isLoadingFormulaProperties,
        formulaPropertyOptions,
    ]);

    const handleGridEdits = (editedRows: ItemProperty[]) => {
        const updatedFields: ItemProperty[] = [...itemProperties];

        editedRows?.forEach((record: ItemProperty) => {
            const index = itemProperties?.findIndex(
                (row: any) =>
                    row.propertyCode === record.propertyCode &&
                    row.rowId === record.rowId
            );

            if (!isNilOrEmpty(record.unitCode)) {
                record.propertyUnitId = record.unitCode?.id;
            }

            if (index > -1) {
                updatedFields[index] = record;
            } else {
                updatedFields.push(record);
            }
        });

        /**
         * we must call the sort function again once we've set the rows to actually update the sort order
         * in the grid
         * **/
        sortItemProperties(updatedFields);

        handleChildrenRecords('itemProperties', updatedFields);
    };

    const handleRowValidations = (editedRows: ItemProperty[]) => {
        editedRows?.forEach((row: ItemProperty) => {
            row.validationRules = [
                ColumnMaxValueValidator(
                    'Property Value',
                    25,
                    row.propertyValue
                ),
            ];
            applyEditableGridValidations(row, `Property ${row.propertyCode}`);
        });
        return editedRows;
    };

    const getUnitCode = (unitId: bigint): SelectedDropdownOption => {
        let unitCode: SelectedDropdownOption = { id: null, display: '' };
        if (!isNilOrEmpty(unitId)) {
            props.unitOfMeasureOptions.find((current: any) => {
                if (current.id === unitId) {
                    unitCode = {
                        id: current.id,
                        display: current.label,
                    };
                }
            });
        }
        return unitCode;
    };

    const sortItemProperties = (itemProperties: ItemProperty[]) => {
        const filterList = itemProperties.filter(
            (current) => current.rowStatus !== RowStatus.DELETED
        );
        setItemProperties(
            _.orderBy(filterList, ['sortOrder', 'propertyCode'], ['asc'])
        );
    };

    useEffect(() => {
        const includeEditedRows = currentParentRecord?.itemProperties
            ? !isNilOrEmpty(
                  currentParentRecord?.itemProperties.find(
                      (current) => current.rowStatus
                  )
              )
            : false;
        if (
            currentParentRecord?.itemProperties &&
            !props.isLoadingUnitOfMeasure &&
            !isNilOrEmpty(props.unitOfMeasureOptions) &&
            !includeEditedRows
        ) {
            const properties: ItemProperty[] = [];
            let index = maxRowId;

            currentParentRecord?.itemProperties.forEach(
                (record: ItemProperty) => {
                    index = index + 1;
                    setMaxRowId((maxRowId) => maxRowId + 1);

                    if (record.rowStatus !== RowStatus.DELETED) {
                        const itemProperty: ItemProperty = {
                            ...record,
                            propertyCode: record.property
                                ? record.property.propertyCode
                                : record.propertyCode,
                            description: record.property
                                ? record.property.description
                                : record.description,
                            unitCode: getUnitCode(record.propertyUnitId),
                            rowId: record.rowId ? record.rowId : index,
                        };
                        delete itemProperty.property;
                        properties.push(itemProperty);
                    }
                }
            );

            sortItemProperties(properties);
        }
    }, [
        currentParentRecord?.itemProperties,
        props.isLoadingUnitOfMeasure,
        props.unitOfMeasureOptions,
    ]);

    useEffect(() => {
        setFilteredFormulaPropertyList();
    }, [isLoadingFormulaProperties, formulaPropertyOptions, itemProperties]);

    const setFilteredFormulaPropertyList = () => {
        if (canViewProperties) {
            let filteredList: FormulaProperty[] = formulaPropertyOptions;

            _.forEach(itemProperties, function (propertyRecord: ItemProperty) {
                const matchingRecord = _.find(
                    formulaPropertyOptions,
                    (current: ItemProperty) => {
                        return (
                            current.propertyCode ===
                                propertyRecord.propertyCode &&
                            propertyRecord.rowStatus !== RowStatus.DELETED
                        );
                    }
                );

                if (matchingRecord) {
                    filteredList = _.without(filteredList, matchingRecord);
                }
            });

            setFilteredPropertyList(filteredList);
        } else {
            setFilteredPropertyList([]);
        }
    };

    const handleSelectedRecords = (selectedRecords: FormulaProperty[]) => {
        const newRows: ItemProperty[] = [];
        let index = maxRowId;

        selectedRecords?.forEach((record: FormulaProperty) => {
            const updatedRecord: ItemProperty = { ...record };
            index = index + 1;
            setMaxRowId((maxRowId) => maxRowId + 1);

            updatedRecord.rowId = index;
            updatedRecord.rowStatus = RowStatus.ADDED;
            updatedRecord.propertyId = record.id;
            updatedRecord.propertyValue = record.defaultValue
                ? record.defaultValue
                : '';
            updatedRecord.unitCode = { id: null, display: '' };
            updatedRecord.propertyUnitId = null;
            delete updatedRecord.id;

            newRows.push(updatedRecord);
        });

        handleRowValidations(newRows);
        handleGridEdits(newRows);
    };

    useEffect(() => {
        if (isLoadingFormulaProperties === false) {
            if (isNilOrEmpty(formulaPropertyOptions)) {
                setFilteredFormulaPropertyList();
                if (
                    currentParentRecord &&
                    currentParentRecord?.itemProperties
                ) {
                    setItemProperties(currentParentRecord.itemProperties);
                }
            }
        }
    }, [
        isLoadingFormulaProperties,
        formulaPropertyOptions,
        currentParentRecord,
        itemProperties,
    ]);

    return {
        handleGridEdits,
        itemProperties,
        formulaPropertyList,
        showMultiSelectGridModal,
        setShowMultiSelectGridModal,
        handleSelectedRecords,
        itemPropertyColDef,
        handleRowValidations,
        setFilteredFormulaPropertyList,
        termSet,
    };
};

export default useItemPropertiesGrid;
