import React, { useContext, useEffect, useState } from 'react';
import { RootStateOrAny, useSelector } from 'react-redux';
import { skipToken } from '@reduxjs/toolkit/query';
import { useNavigate } from 'react-router-dom';
import { Language } from '../../../../../services/organizations/organizations.service';
import { SelectionOption } from '../../../../../types/Shared.types';
import {
    SafetyDataSheetHeaderLang,
    SafetyDataSheetSectionHeader,
} from '../../../../../types/formulation';
import { PermissionsUtil } from '../../../../../utils/permissions/permissionsUtil';
import { PERMISSIONS } from '../../../../../constants/permissions/Permissions.constants';
import { useListLocaleGroupDropDownQuery } from '../../../../../services/formulation/localeGroup/localeGroup.service';
import { useListSafetyDataSheetSectionHeadersByHeaderIdLocaleGrpIdQuery } from '../../../../../services/formulation/safetyDataSheetSectionHeaders/safetyDataSheetSectionHeaders.service';
import SettingsContext from '../../../../../contexts/settings.context';
import { useGetTermSetQuery } from '../../../../../services/i18n/i18n.service';
import { FORMULATION_DEFS } from '../../../../../constants/i18n/translations/termSetDefinitions/formulation';

export interface SafetyDataSheetHeaderFormContainerProps {
    headerId: string;
    localeGroupId: string;
    defaultLangId: bigint;
    languageList: Array<Language>;
    safetyDataSheetSectionHeaderKeyList: Array<string>;
    handleSetIsFormDirty(formDirty: boolean): void;
    handlePromptFormDirty(onConfirm: () => void, onCancel?: () => void): void;
}

const useSafetyDataSheetSectionHeaderFormContainer = ({
    headerId,
    localeGroupId,
    defaultLangId,
    languageList,
    handlePromptFormDirty,
}: SafetyDataSheetHeaderFormContainerProps) => {
    const navigate = useNavigate();
    const user = useSelector((state: RootStateOrAny) => state.user);

    const [langTabValue, setLangTabValue] = useState(0);
    const [safetyDataSheetHeaderLangList, setSafetyDataSheetHeaderLangList] =
        useState(null as Array<SafetyDataSheetHeaderLang>);
    const [languageOptionList, setLanguageOptionList] = useState(
        null as SelectionOption[]
    );
    const [showModal, setShowModal] = useState(false);
    const [disableLangButton, setDisableLangButton] = useState(false);

    const { data: localeGroupOptions, isLoading: isLoadingLocaleGroupOpts } =
        useListLocaleGroupDropDownQuery();

    const {
        data: safetyDataSheetHeaderList,
        isFetching: isLoadingSafetyDataSheetHeaderList,
    } = useListSafetyDataSheetSectionHeadersByHeaderIdLocaleGrpIdQuery(
        headerId && localeGroupId
            ? {
                  headerId,
                  localeGroupId,
              }
            : skipToken
    );

    const canCreateSafetyDataSheetHeader = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.FORMULATION.SAFETY_DATA_SHEET_SECTION_HEADER.CREATE
    );

    const { settings } = useContext(SettingsContext);
    const { data: termSet } = useGetTermSetQuery(
        settings?.userSettings
            ? {
                  languageId: settings?.userSettings?.languageId,
                  code: FORMULATION_DEFS.SDS_SECTION_HEADERS_FORM,
              }
            : skipToken
    );

    useEffect(() => {
        if (
            headerId &&
            localeGroupId &&
            !isLoadingSafetyDataSheetHeaderList &&
            safetyDataSheetHeaderList &&
            safetyDataSheetHeaderList.length > 0
        ) {
            setSafetyDataSheetHeaderLangListData(safetyDataSheetHeaderList);
            buildLanguageSelectionOption();
        } else if (defaultLangId && !headerId && !localeGroupId) {
            const newSafetyDataSheetHeader: SafetyDataSheetSectionHeader = {
                id: undefined,
                headerId: null,
                localeGroupId: null,
                langId: defaultLangId,
                businessEntityId: user?.businessEntity?.id,
                section: 0,
                subSection: 0,
                sectionText: null,
            };
            setSafetyDataSheetHeaderLangListData([newSafetyDataSheetHeader]);
        }
    }, [headerId, localeGroupId, defaultLangId, safetyDataSheetHeaderList]);

    useEffect(() => {
        setLangTabValue(0);
    }, [headerId, localeGroupId]);

    const setSafetyDataSheetHeaderLangListData = (
        SafetyDataSheetHeaderList: SafetyDataSheetSectionHeader[]
    ) => {
        const SafetyDataSheetHeaderLangList: Array<SafetyDataSheetHeaderLang> =
            [];
        SafetyDataSheetHeaderList.forEach((currentSafetyDataSheetHeader) => {
            const currentSafetyDataSheetHeaderLang =
                createSafetyDataSheetHeaderLang(currentSafetyDataSheetHeader);
            if (currentSafetyDataSheetHeader.langId === defaultLangId) {
                SafetyDataSheetHeaderLangList.unshift(
                    currentSafetyDataSheetHeaderLang
                );
            } else {
                SafetyDataSheetHeaderLangList.push(
                    currentSafetyDataSheetHeaderLang
                );
            }
        });
        setSafetyDataSheetHeaderLangList(SafetyDataSheetHeaderLangList);
    };

    const createSafetyDataSheetHeaderLang = (
        SafetyDataSheetHeader: SafetyDataSheetSectionHeader
    ): SafetyDataSheetHeaderLang => {
        return {
            id: SafetyDataSheetHeader.id,
            langId: SafetyDataSheetHeader.langId,
            headerId: SafetyDataSheetHeader.headerId,
            localeGroupId: SafetyDataSheetHeader.localeGroupId,
            langText: languageList?.find(
                (lang) => lang.id === SafetyDataSheetHeader.langId
            )?.name,
            section: SafetyDataSheetHeader.section,
            subSection: SafetyDataSheetHeader.subSection,
            subSectionLetter: SafetyDataSheetHeader.subSectionLetter,
            showOnSds: SafetyDataSheetHeader.showOnSds,
            isDefaultLangId: SafetyDataSheetHeader.langId === defaultLangId,
        };
    };

    const resetTabData = () => {
        const newHeader = safetyDataSheetHeaderLangList?.find(
            (current) => !current.id
        );
        if (safetyDataSheetHeaderList && newHeader) {
            setSafetyDataSheetHeaderLangListData(safetyDataSheetHeaderList);
        }
    };

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        handlePromptFormDirty(() => {
            resetTabData();
            setLangTabValue(newValue);
        });
    };

    const buildLanguageSelectionOption = () => {
        if (languageList) {
            const langOptionList: SelectionOption[] = [];
            languageList.forEach((currentLang) => {
                const headerRecord = safetyDataSheetHeaderList.find(
                    (currentheader) => currentheader.langId === currentLang.id
                );
                if (!headerRecord) {
                    langOptionList.push({
                        value: currentLang.id,
                        label: currentLang.name,
                    });
                }
            });
            setLanguageOptionList(langOptionList);
        }
    };

    const handleOpenModal = () => {
        handlePromptFormDirty(() => {
            setShowModal(true);
        });
    };

    const addNewLanguage = (selectedLangId: bigint) => {
        if (selectedLangId) {
            const defaultHeader = safetyDataSheetHeaderList.find(
                (current) => current.langId === defaultLangId
            );
            const headerRecord: SafetyDataSheetSectionHeader = {
                id: undefined,
                langId: selectedLangId,
                headerId: defaultHeader.headerId,
                localeGroupId: defaultHeader.localeGroupId,
                businessEntityId: defaultHeader.businessEntityId,
                section: defaultHeader.section,
                subSection: defaultHeader.subSection,
                subSectionLetter: defaultHeader.subSectionLetter,
                showOnSds: defaultHeader.showOnSds,
                sectionText: null,
            };
            const headerList = Array.from(safetyDataSheetHeaderList);
            headerList.push(headerRecord);
            setSafetyDataSheetHeaderLangListData(headerList);
            setLangTabValue(headerList.length - 1);
        }
        setShowModal(false);
    };

    const handleSetPreviousTab = () => {
        if (langTabValue > 0) {
            setLangTabValue(langTabValue - 1);
        }
    };

    const cancelNewLanguage = (closeForm: boolean) => {
        handlePromptFormDirty(() => {
            resetTabData();
            if (closeForm) {
                navigate(`/formulation/sdsSectionHeader`);
            } else {
                setLangTabValue(0);
            }
        });
    };

    const handleSetDisableLangButton = (disableButton: boolean) => {
        if (disableButton !== disableLangButton) {
            setDisableLangButton(disableButton);
        }
    };

    return {
        safetyDataSheetHeaderLangList,
        isLoadingSafetyDataSheetHeaderList,
        isLoadingLocaleGroupOpts,
        canCreateSafetyDataSheetHeader,
        safetyDataSheetHeaderList,
        langTabValue,
        localeGroupOptions,
        languageOptionList,
        showModal,
        disableLangButton,
        handleTabChange,
        handleOpenModal,
        addNewLanguage,
        handleSetPreviousTab,
        cancelNewLanguage,
        handleSetDisableLangButton,
        termSet,
    };
};

export default useSafetyDataSheetSectionHeaderFormContainer;
