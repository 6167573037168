import React from 'react';
import RegulationGrid from './RegulationGrid';

const Regulation = () => {
    return (
        <>
            <RegulationGrid />
        </>
    );
};

export default Regulation;
