import { DialogProps } from '@mui/material/Dialog/Dialog';
import { FixedAssetEntryAccountRow } from '../hooks/useFixedAssetDepreciationEntriesGrid';
import {
    Box,
    Dialog,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import React, { useContext } from 'react';
import BaseGrid from '../../../components/grids/BaseGrid';
import { AgGridColumn } from 'ag-grid-react';
import DefaultColumnTypes from '../../../components/grids/columns/Column.constants';
import { DepreciationEntriesGridDefs } from '../../../constants/i18n/translations/termDefinitions/accounting';
import TranslatableText from '../../../components/i18n/TranslatableText';
import { useGetTermSetQuery } from '../../../services/i18n/i18n.service';
import { ACCOUNTING_DEFS } from '../../../constants/i18n/translations/termSetDefinitions/accounting';
import { skipToken } from '@reduxjs/toolkit/query';
import SettingsContext from '../../../contexts/settings.context';

type FixedAssetExportEntriesProps = Omit<DialogProps, 'onClose'> & {
    depreciationEntries: FixedAssetEntryAccountRow[];
    onClose: () => void;
};

const FixedAssetExportDepreciationEntriesModal = ({
    depreciationEntries,
    onClose,
    ...props
}: FixedAssetExportEntriesProps) => {
    const { settings } = useContext(SettingsContext);
    const { data: termSet } = useGetTermSetQuery(
        settings?.userSettings
            ? {
                  languageId: settings?.userSettings?.languageId,
                  code: ACCOUNTING_DEFS.DEPRECIATION_ENTRIES_GRID,
              }
            : skipToken
    );
    return (
        <Dialog {...props} fullWidth maxWidth={'lg'}>
            <DialogTitle>
                <Box display="flex" alignItems="center">
                    <Box flexGrow={1}>
                        <TranslatableText
                            termSet={termSet}
                            termKey={
                                DepreciationEntriesGridDefs.Download_Entries_Title
                            }
                        />
                    </Box>
                    <Box>
                        <IconButton onClick={onClose}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </Box>
            </DialogTitle>
            <DialogContent>
                <BaseGrid
                    paginationPageSize={30}
                    rowData={depreciationEntries}
                    displayGrid={true}
                    displayToolbar={true}
                    displayExportDataButton={true}>
                    <AgGridColumn
                        field="entryDate"
                        headerComponentFramework={(props: any) => {
                            return (
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={
                                        DepreciationEntriesGridDefs.Download_Entries_Date
                                    }
                                />
                            );
                        }}
                        sort="asc"
                        {...DefaultColumnTypes.DateOnly}
                    />
                    <AgGridColumn
                        field="account.number"
                        headerComponentFramework={(props: any) => {
                            return (
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={
                                        DepreciationEntriesGridDefs.Download_Entries_Account_Number
                                    }
                                />
                            );
                        }}
                        {...DefaultColumnTypes.ShortText}
                    />
                    <AgGridColumn
                        field="entryDescription"
                        headerComponentFramework={(props: any) => {
                            return (
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={
                                        DepreciationEntriesGridDefs.Download_Entries_Entry_Description
                                    }
                                />
                            );
                        }}
                        {...DefaultColumnTypes.MediumText}
                    />
                    <AgGridColumn
                        field="totalDebit"
                        headerComponentFramework={(props: any) => {
                            return (
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={
                                        DepreciationEntriesGridDefs.Download_Entries_Debit
                                    }
                                />
                            );
                        }}
                        {...DefaultColumnTypes.Currency}
                    />
                    <AgGridColumn
                        field="totalCredit"
                        headerComponentFramework={(props: any) => {
                            return (
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={
                                        DepreciationEntriesGridDefs.Download_Entries_Credit
                                    }
                                />
                            );
                        }}
                        {...DefaultColumnTypes.Currency}
                    />
                </BaseGrid>
            </DialogContent>
        </Dialog>
    );
};

export default FixedAssetExportDepreciationEntriesModal;
