import {
    Box,
    Dialog,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import React from 'react';
import DateInput from '../../../components/form/formInputs/DateInput/DateInput';
import { LoadingButton } from '@mui/lab';
import { FA_Asset } from '../../../types/FixedAsset.types';
import useDisposalOptionsModal from './hooks/useDisposalOptionsModal';
import { validateDateRange } from '../../../utils/comparisonUtils';
import TranslatableText from '../../../components/i18n/TranslatableText';
import { DisposalsGridDefs } from '../../../constants/i18n/translations/termDefinitions/accounting';

export interface ReportDisposalProps {
    open: boolean;
    onAssetDisposalsLoaded: (contracts: FA_Asset[]) => void;
    onClose: () => void;
    termSet?: any;
}

const DisposalOptionsModal = (props: ReportDisposalProps) => {
    const {
        fields,
        handleFieldChange,
        handleGetAssetDisposals,
        isLoadingDisposals,
    } = useDisposalOptionsModal(props);

    return (
        <Dialog
            onClose={props.onClose}
            open={props.open}
            fullWidth
            maxWidth={'sm'}>
            <DialogTitle>
                <Box display="flex" alignItems="center">
                    <Box flexGrow={1}>
                        {' '}
                        <TranslatableText
                            termSet={props.termSet}
                            termKey={DisposalsGridDefs.Disposal_Options}
                        />{' '}
                    </Box>
                    <Box>
                        <IconButton onClick={props.onClose}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </Box>
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <DateInput
                            label={
                                <TranslatableText
                                    termSet={props.termSet}
                                    termKey={
                                        DisposalsGridDefs.Disposal_Date_Begin
                                    }
                                />
                            }
                            id="disposalBeginDate"
                            value={fields.disposalBeginDate}
                            onChange={handleFieldChange}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <DateInput
                            label={
                                <TranslatableText
                                    termSet={props.termSet}
                                    termKey={
                                        DisposalsGridDefs.Disposal_Date_End
                                    }
                                />
                            }
                            id="disposalEndDate"
                            value={fields.disposalEndDate}
                            onChange={handleFieldChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <LoadingButton
                            fullWidth
                            variant="contained"
                            type="submit"
                            disabled={
                                !validateDateRange(
                                    fields.disposalBeginDate,
                                    fields.disposalEndDate
                                )
                            }
                            loading={isLoadingDisposals}
                            onClick={handleGetAssetDisposals}>
                            <TranslatableText
                                termSet={props.termSet}
                                termKey={DisposalsGridDefs.Get_Disposals}
                            />
                        </LoadingButton>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
};

export default DisposalOptionsModal;
