import {
    Box,
    Dialog,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import React from 'react';
import DateInput from '../../../components/form/formInputs/DateInput/DateInput';
import { LoadingButton } from '@mui/lab';
import { FA_DepreciationEntry } from '../../../types/FixedAsset.types';
import { isNilOrEmpty } from '../../../utils/objectUtils';
import SelectInput from '../../../components/form/formInputs/SelectInput/SelectInput';
import useDepreciationDetailOptionsModal from './hooks/useDepreciationDetailOptionsModal';
import { validateDateRange } from '../../../utils/comparisonUtils';
import { DepreciationDetailGridDefs } from '../../../constants/i18n/translations/termDefinitions/accounting';
import TranslatableText from '../../../components/i18n/TranslatableText';

export interface ReportDepreciationDetailsProps {
    open: boolean;
    onDepreciationDetailsLoaded: (
        assetContracts: FA_DepreciationEntry[]
    ) => void;
    onClose: () => void;
    termSet?: any;
}

const DepreciationDetailOptionsModal = (
    props: ReportDepreciationDetailsProps
) => {
    const {
        fields,
        handleFieldChange,
        assetTypeOptions,
        loadingAssetTypes,
        handleGetDepreciationDetails,
        isLoadingDepreciationDetails,
    } = useDepreciationDetailOptionsModal(props);

    return (
        <Dialog
            onClose={props.onClose}
            open={props.open}
            fullWidth
            maxWidth={'sm'}>
            <DialogTitle>
                <Box display="flex" alignItems="center">
                    <Box flexGrow={1}>
                        <TranslatableText
                            termSet={props.termSet}
                            termKey={
                                DepreciationDetailGridDefs.Depreciation_Detail_Options
                            }
                        />
                    </Box>
                    <Box>
                        <IconButton onClick={props.onClose}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </Box>
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <SelectInput
                            label={
                                <TranslatableText
                                    termSet={props.termSet}
                                    termKey={
                                        DepreciationDetailGridDefs.Asset_Type
                                    }
                                />
                            }
                            id="assetTypeId"
                            fullWidth
                            value={fields.assetTypeId}
                            onChange={handleFieldChange}
                            disabled={loadingAssetTypes}
                            options={assetTypeOptions}
                            required={true}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <DateInput
                            label={
                                <TranslatableText
                                    termSet={props.termSet}
                                    termKey={
                                        DepreciationDetailGridDefs.Depreciation_Date_Begin
                                    }
                                />
                            }
                            id="startDate"
                            value={fields.startDate}
                            onChange={handleFieldChange}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <DateInput
                            label={
                                <TranslatableText
                                    termSet={props.termSet}
                                    termKey={
                                        DepreciationDetailGridDefs.Depreciation_Date_End
                                    }
                                />
                            }
                            id="endDate"
                            value={fields.endDate}
                            onChange={handleFieldChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <LoadingButton
                            fullWidth
                            variant="contained"
                            type="submit"
                            disabled={
                                isNilOrEmpty(fields.assetTypeId) ||
                                !validateDateRange(
                                    fields.startDate,
                                    fields.endDate
                                )
                            }
                            loading={isLoadingDepreciationDetails}
                            onClick={handleGetDepreciationDetails}>
                            <TranslatableText
                                termSet={props.termSet}
                                termKey={
                                    DepreciationDetailGridDefs.Get_Depreciation_Details
                                }
                            />
                        </LoadingButton>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
};

export default DepreciationDetailOptionsModal;
