import { ColDef } from 'ag-grid-community';
import DefaultColumnTypes from '../../../../components/grids/columns/Column.constants';
import React from 'react';
import BaseGrid from '../../../../components/grids/BaseGrid';
import {
    FA_Asset,
    FA_AssetDisposalEntry,
} from '../../../../types/FixedAsset.types';
import FixedAssetCreateDisposalEntriesModal from '../modals/FixedAssetCreateDisposalEntriesModal';
import useDisposalEntryGrid from './hooks/useDisposalEntryGrid';
import { FixedAssetRegisterFormDefs } from '../../../../constants/i18n/translations/termDefinitions/accounting';
import TranslatableText from '../../../../components/i18n/TranslatableText';

export interface DisposalEntryProps {
    assetId: bigint;
    disposalEntries: FA_AssetDisposalEntry[];
    disposalEntriesTotals: any;
}

const DisposalEntryGrid = (props: DisposalEntryProps) => {
    const {
        disposalEntries,
        disposalToolbar,
        disposalEntriesPinnedBottomRowData,
        showCreateDisposalModal,
        setShowCreateDisposalModal,
        handleDisposalUpdated,
        lockedScheduleInformation,
        termSet,
    } = useDisposalEntryGrid(props);

    const columnDefs: ColDef[] = [
        {
            field: 'disposalDate',
            headerComponentFramework: (props: any) => {
                return (
                    <TranslatableText
                        termSet={termSet}
                        termKey={
                            FixedAssetRegisterFormDefs.DisposalEntryDisposalDate
                        }
                    />
                );
            },
            ...DefaultColumnTypes.DateOnly,
        },
        {
            field: 'account.number',
            headerComponentFramework: (props: any) => {
                return (
                    <TranslatableText
                        termSet={termSet}
                        termKey={
                            FixedAssetRegisterFormDefs.DisposalEntryAccountNumber
                        }
                    />
                );
            },
            ...DefaultColumnTypes.ShortText,
        },
        {
            field: 'account.name',
            headerComponentFramework: (props: any) => {
                return (
                    <TranslatableText
                        termSet={termSet}
                        termKey={
                            FixedAssetRegisterFormDefs.DisposalEntryEntryDescription
                        }
                    />
                );
            },
            ...DefaultColumnTypes.MediumText,
        },
        {
            field: 'debit',
            headerComponentFramework: (props: any) => {
                return (
                    <TranslatableText
                        termSet={termSet}
                        termKey={FixedAssetRegisterFormDefs.DisposalEntryDebit}
                    />
                );
            },
            ...DefaultColumnTypes.Currency,
        },
        {
            field: 'credit',
            headerComponentFramework: (props: any) => {
                return (
                    <TranslatableText
                        termSet={termSet}
                        termKey={FixedAssetRegisterFormDefs.DisposalEntryCredit}
                    />
                );
            },
            ...DefaultColumnTypes.Currency,
        },
        {
            headerComponentFramework: (props: any) => {
                return (
                    <TranslatableText
                        termSet={termSet}
                        termKey={FixedAssetRegisterFormDefs.DisposalEntryLocked}
                    />
                );
            },
            ...DefaultColumnTypes.LockedInformationColumn,
            valueGetter: lockedScheduleInformation,
        },
    ];

    return (
        <React.Fragment>
            <BaseGrid
                rowData={disposalEntries}
                displayGrid={true}
                displayToolbar={true}
                columnDefs={columnDefs}
                toolbarChildren={disposalToolbar()}
                defaultColDef={{
                    resizable: true,
                    wrapText: true,
                }}
                displaySummaryRow={true}
                pinnedBottomRowData={
                    disposalEntriesPinnedBottomRowData
                }></BaseGrid>
            <FixedAssetCreateDisposalEntriesModal
                open={showCreateDisposalModal}
                onClose={() => setShowCreateDisposalModal(false)}
                ids={[props.assetId as unknown as bigint]}
                singleRecord={true}
                onDisposalCreated={(asset: FA_Asset) =>
                    handleDisposalUpdated(asset)
                }
            />
        </React.Fragment>
    );
};
export default DisposalEntryGrid;
