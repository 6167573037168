import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { RootStateOrAny, useSelector } from 'react-redux';
import {
    StateCode,
    useGetStatesQuery,
} from '../../../../services/organizations/organizations.service';
import { PermissionsUtil } from '../../../../utils/permissions/permissionsUtil';
import { PERMISSIONS } from '../../../../constants/permissions/Permissions.constants';
import { useListRegulationsQuery } from '../../../../services/formulation/regulations/regulations.service';
import SettingsContext from '../../../../contexts/settings.context';
import { useGetTermSetQuery } from '../../../../services/i18n/i18n.service';
import { FORMULATION_DEFS } from '../../../../constants/i18n/translations/termSetDefinitions/formulation';
import { skipToken } from '@reduxjs/toolkit/query';

const useRegulationGrid = () => {
    const navigate = useNavigate();
    const user = useSelector((state: RootStateOrAny) => state.user);

    const { isLoading: isLoadingRegulationList, data: regulationList } =
        useListRegulationsQuery();

    const { isLoading: isLoadingStates, data: statesList } =
        useGetStatesQuery();

    const canCreateRegulation = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.FORMULATION.REGULATIONS.CREATE
    );
    const canViewRegulation = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.FORMULATION.REGULATIONS.VIEW
    );

    const { settings } = useContext(SettingsContext);
    const { data: termSet } = useGetTermSetQuery(
        settings?.userSettings
            ? {
                  languageId: settings?.userSettings?.languageId,
                  code: FORMULATION_DEFS.REGULATIONS_GRID,
              }
            : skipToken
    );

    const redirectToForm = (row: any) => {
        if (row && row.id) {
            navigate(`/formulation/regulations/${row.id}`);
        } else {
            navigate('/formulation/regulations/new');
        }
    };

    const getStateById = (stateCodeId: bigint): string => {
        if (!isLoadingStates && statesList) {
            return (statesList as StateCode[]).find(
                (state) => state.id === stateCodeId
            )?.name;
        }
        return '';
    };

    return {
        isLoadingRegulationList,
        isLoadingStates,
        canCreateRegulation,
        canViewRegulation,
        regulationList,
        redirectToForm,
        getStateById,
        termSet,
    };
};

export default useRegulationGrid;
