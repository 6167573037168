import { datacorAppApi } from '../datacorAppApi';

export const entityTypesApi = datacorAppApi.injectEndpoints({
    endpoints: (build) => ({
        listEntityTypes: build.query<Array<EntityType>, void>({
            query: () => ({
                url: `/platform-system/entity-types`,
                method: 'GET',
            }),
            providesTags: ['EntityTypes'],
        }),
    }),
});

export const { useListEntityTypesQuery } = entityTypesApi;

export interface EntityType {
    id: bigint;
    code: string;
    name: string;
    description?: string;
}
