import React from 'react';
import { Card, CardContent, CardHeader } from '@mui/material';
import AccountingSettings from './AccountingSettings';
import FormulationSettings from './FormulationSettings';
import BaseFormToolbar from '../../../components/form/baseForm/BaseFormToolbar';
import useSettings from '../hooks/useSettings';
import { isFeatureFlagEnabled } from '../../../utils/featureFlag/featureFlagUtil';
import { PLATFORM_DEFS } from '../../../constants/i18n/translations/termSetDefinitions/platform';

const ModuleSettings = () => {
    const { handleChange, handleSaveSettings, navigate, user, sharedTermSet } =
        useSettings({ termSetCode: PLATFORM_DEFS.SHARED_COMPONENTS });

    return (
        <Card>
            <CardHeader
                sx={{ paddingLeft: '0px', paddingRight: '0px' }}
                title={
                    <>
                        <BaseFormToolbar
                            isNew={false}
                            onCancel={() => navigate(-1)}
                            displayUpdateButton={true}
                            onUpdate={handleSaveSettings}
                            termSet={sharedTermSet}
                        />
                    </>
                }
            />
            <CardContent>
                {isFeatureFlagEnabled(
                    user?.enabledFeatureFlags,
                    'dashboard.nav.apps.fixedAssets'
                ) && <AccountingSettings onChange={handleChange} />}
                {isFeatureFlagEnabled(
                    user?.enabledFeatureFlags,
                    'dashboard.nav.apps.formulation'
                ) && <FormulationSettings onChange={handleChange} />}
            </CardContent>
        </Card>
    );
};

export default ModuleSettings;
