import { useContext } from 'react';
import { RootStateOrAny, useSelector } from 'react-redux';
import { skipToken } from '@reduxjs/toolkit/query';
import useBaseForm from '../../../../form/hooks/useBaseForm';
import { ExposureAgency } from '../../../../../types/formulation';
import { PermissionsUtil } from '../../../../../utils/permissions/permissionsUtil';
import { PERMISSIONS } from '../../../../../constants/permissions/Permissions.constants';
import {
    useCreateExposureAgencyMutation,
    useDeleteExposureAgencyMutation,
    useGetExposureAgencyQuery,
    useUpdateExposureAgencyMutation,
} from '../../../../../services/formulation/exposureAgency/exposureAgency.service';
import SettingsContext from '../../../../../contexts/settings.context';
import { useGetTermSetQuery } from '../../../../../services/i18n/i18n.service';
import { FORMULATION_DEFS } from '../../../../../constants/i18n/translations/termSetDefinitions/formulation';

const useExposureAgencyForm = (id: string) => {
    const user = useSelector((state: RootStateOrAny) => state.user);

    const { isLoading, data: currentExposureAgency } =
        useGetExposureAgencyQuery(id ? id : skipToken);

    const canCreateExposureAgency = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.FORMULATION.EXPOSURE_AGENCIES.CREATE
    );
    const canUpdateExposureAgency = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.FORMULATION.EXPOSURE_AGENCIES.EDIT
    );
    const canDeleteExposureAgency = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.FORMULATION.EXPOSURE_AGENCIES.DELETE
    );

    const [createExposureAgency] = useCreateExposureAgencyMutation();
    const [updateExposureAgency] = useUpdateExposureAgencyMutation();
    const [deleteExposureAgency] = useDeleteExposureAgencyMutation();

    const blankExposureAgency: ExposureAgency = {
        code: '',
        description: '',
        businessEntityId: user?.businessEntity?.id,
    };

    const { settings } = useContext(SettingsContext);
    const { data: termSet } = useGetTermSetQuery(
        settings?.userSettings
            ? {
                  languageId: settings?.userSettings?.languageId,
                  code: FORMULATION_DEFS.EXPOSURE_AGENCIES_FORM,
              }
            : skipToken
    );

    const {
        fields,
        formMethods,
        handleFieldChange,
        onCreate,
        onUpdate,
        onDelete,
        closeForm,
        hasEntityChanges,
    } = useBaseForm({
        closePath: '/formulation/exposureAgency',
        reroute: true,
        blankEntity: blankExposureAgency,
        activeEntity: currentExposureAgency,
        onlyUpdateEntityWithChanges: true,
        getDescription: () => {
            return `Exposure Agency ${fields.code}`;
        },
        createEntity: () => {
            return createExposureAgency({ postBody: fields });
        },
        updateEntity: () => {
            return updateExposureAgency({
                id: id as unknown as number,
                postBody: fields,
            });
        },
        deleteEntity: () => {
            return deleteExposureAgency(id);
        },
    });

    return {
        isLoading,
        fields,
        formMethods,
        handleFieldChange,
        onCreate,
        onUpdate,
        onDelete,
        closeForm,
        canCreateExposureAgency,
        canDeleteExposureAgency,
        canUpdateExposureAgency,
        termSet,
        hasEntityChanges,
    };
};

export default useExposureAgencyForm;
