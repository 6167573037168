import { Grid } from '@mui/material';
import React, { useContext } from 'react';
import TextInput from '../../../../../../../components/form/formInputs/TextInput/TextInput';
import {
    BlobData,
    SdsBlobSectionFormProps,
} from '../../shared/blobs/hooks/SdsBlobSectionFormProps';
import {
    SdsHeader,
    SdsSubSection16_0,
} from '../../../../../../../types/formulation';
import useSdsBlobSectionForm from '../../shared/blobs/hooks/useSdsBlobSectionForm';
import SdsSubSectionForm from '../../shared/blobs/components/SdsSubSectionForm';
import TranslatableText from '../../../../../../../components/i18n/TranslatableText';
import { SDSFormDefs } from '../../../../../../../constants/i18n/translations/termDefinitions/formulation';

const SubSection16_0Form = (props: SdsBlobSectionFormProps<SdsHeader>) => {
    const { isParentLoading } = props.parentData;

    const { disabledField } = props.permissions;

    const blobData: BlobData<SdsSubSection16_0> = {
        sectionNumber: 16,
        subSectionNumber: 0,
        blankBlobEntity: { healthHazard: '' },
    };

    const { fields, isBlobLoading, formMethods, handleFieldChange } =
        useSdsBlobSectionForm<SdsHeader, SdsSubSection16_0>({
            ...props,
            blobData,
        });

    return (
        <SdsSubSectionForm
            formTitle={
                (
                    <TranslatableText
                        termSet={props.termSet}
                        termKey={SDSFormDefs.Other_Info_Other_Text}
                    />
                ) as unknown as string
            }
            isFormLoading={isParentLoading || isBlobLoading}
            content={
                <>
                    <Grid item xs={12}>
                        <TextInput
                            fullWidth
                            label={
                                <TranslatableText
                                    termSet={props.termSet}
                                    termKey={
                                        SDSFormDefs.Other_Info_Health_Hazard
                                    }
                                />
                            }
                            id="healthHazard"
                            disabled={disabledField}
                            formMethods={formMethods}
                            value={fields.healthHazard}
                            onChange={handleFieldChange}
                            maxLength={2000}
                            multiline={true}
                            maxRows={15}
                            minRows={5}
                        />
                    </Grid>
                </>
            }
        />
    );
};
export default SubSection16_0Form;
