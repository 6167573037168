import { useNavigate } from 'react-router-dom';
import {
    useGetAllTypesQuery,
    useUpdateListTypesMutation,
} from '../../../services/fixedAssets/fixedAssets.service';
import { PermissionsUtil } from '../../../utils/permissions/permissionsUtil';
import { PERMISSIONS } from '../../../constants/permissions/Permissions.constants';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { RoutingValues } from '../../../types/Shared.types';
import { FA_AssetType } from '../../../types/FixedAsset.types';
import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { getMaxSortOrderValue } from '../../../store/fixedAssets';
import { isNilOrEmpty } from '../../../utils/objectUtils';
import { openSnackbarBasicWithMessage } from '../../../store/uiElements';
import useTranslation from '../../../components/i18n/hooks/useTranslation';
import { ACCOUNTING_DEFS } from '../../../constants/i18n/translations/termSetDefinitions/accounting';
import { ColDef } from 'ag-grid-community';
import CustomHeader from '../../../components/grids/CustomHeader';
import { AssetTypesGridDefs } from '../../../constants/i18n/translations/termDefinitions/accounting';
import DefaultColumnTypes from '../../../components/grids/columns/Column.constants';

const UseFixedAssetTypeGrid = () => {
    const dispatch = useDispatch();
    const user = useSelector((state: RootState) => state.user);
    const navigate = useNavigate();
    let {
        data: typeList,
        isLoading: isLoadingTypes,
        error,
    } = useGetAllTypesQuery();
    const [updateAssetTypes] = useUpdateListTypesMutation();
    const [rowData, setRowData] = useState([]);
    const [colDefs, setColDefs] = useState([]);

    const { termSet } = useTranslation(ACCOUNTING_DEFS.ASSET_TYPES_GRID);

    const canCreateAssetType = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.FIXED_ASSETS.ASSET_TYPES.CREATE
    );
    const canViewAssetType = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.FIXED_ASSETS.ASSET_TYPES.VIEW
    );
    const canUpdate = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.FIXED_ASSETS.ASSET_TYPES.EDIT
    );

    useEffect(() => {
        if (!isNilOrEmpty(error) && 'data' in error) {
            typeList = [];
            setRowData([]);
            dispatch(
                openSnackbarBasicWithMessage({
                    message: _.get(error, 'data.error', 'Unable to load data.'),
                    severity: 'error',
                })
            );
        }
    }, [error]);

    useEffect(() => {
        if (!isNilOrEmpty(typeList)) {
            setRowData(typeList);
        }
    }, [typeList]);

    const handleGridEdits = (editedRows: FA_AssetType[]) => {
        setRowData(editedRows);
        updateAssetTypes(editedRows);
    };

    const redirectToForm = (row: any) => {
        if (row.id) {
            navigate(`/accounting/fixedAssets/type/${row.id}`);
        } else {
            navigate(`/accounting/fixedAssets/type/${RoutingValues.newId}`);
        }
    };

    useEffect(() => {
        let maxValue = 1;
        if (typeList && typeList.length > 0) {
            const data = _.maxBy(
                typeList,
                (assetType: FA_AssetType) => assetType.sortOrder
            );
            maxValue = data.sortOrder + 1;
        }
        dispatch(
            getMaxSortOrderValue({ isLoading: false, maxValue: maxValue })
        );
    }, [typeList]);

    useEffect(() => {
        if (!isNilOrEmpty(rowData) && isNilOrEmpty(colDefs)) {
            const typeColumnDefs: ColDef[] = [
                {
                    field: 'code',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={AssetTypesGridDefs.Code}
                            />
                        );
                    },
                    ...DefaultColumnTypes.ShortText,
                },
                {
                    field: 'description',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={AssetTypesGridDefs.Description}
                            />
                        );
                    },
                    ...DefaultColumnTypes.LongText,
                },
                {
                    field: 'recoveryTerm',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={AssetTypesGridDefs.Recovery_Term}
                            />
                        );
                    },
                    ...DefaultColumnTypes.NumberOnly,
                },
                {
                    field: 'recoveryUnit.unit',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={AssetTypesGridDefs.Recovery_Unit}
                            />
                        );
                    },
                    ...DefaultColumnTypes.ShortText,
                },
                {
                    field: 'depreciationMethod.code',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={AssetTypesGridDefs.Depreciation_Method}
                            />
                        );
                    },
                    ...DefaultColumnTypes.ShortText,
                },
            ];
            setColDefs(typeColumnDefs);
        }
    }, [rowData]);

    return {
        typeList,
        isLoadingTypes,
        canCreateAssetType,
        canViewAssetType,
        canUpdate,
        redirectToForm,
        handleGridEdits,
        rowData,
        colDefs,
        termSet,
    };
};

export default UseFixedAssetTypeGrid;
