import { ItemStatus } from '../../../../types/formulation';
import { datacorAppApi } from '../../../datacorAppApi';

const serviceUrl: string = 'item-statuses';

export const itemStatusApi = datacorAppApi.injectEndpoints({
    endpoints: (build) => ({
        getAllItemStatuses: build.query<ItemStatus[], void>({
            query: () => serviceUrl,
            providesTags: ['ItemStatus'],
        }),
        getItemStatusesForDropdown: build.query<
            { label: string; value: bigint }[],
            void
        >({
            query: () => serviceUrl,
            transformResponse: (response: ItemStatus[]) => {
                return response.map((itemStatus) => {
                    return {
                        label: itemStatus.status,
                        value: itemStatus.id,
                    };
                });
            },
            providesTags: ['ItemStatus'],
        }),
    }),
});

export const {
    useGetAllItemStatusesQuery,
    useGetItemStatusesForDropdownQuery,
} = itemStatusApi;
