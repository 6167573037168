import { GhsPrecautionaryPhrase } from '../../../types/formulation';
import {
    BaseCreateEntityArgs,
    BaseUpdateEntityArgs,
} from '../../serviceInterfaces';
import { datacorAppApi } from '../../datacorAppApi';

const serviceUrl: string = 'ghs-precautionary-phrase';

export const precautionaryPhrasesApi = datacorAppApi.injectEndpoints({
    endpoints: (build) => ({
        createPrecautionaryPhrase: build.mutation<
            GhsPrecautionaryPhrase,
            BaseCreateEntityArgs<GhsPrecautionaryPhrase>
        >({
            query: (args) => ({
                url: serviceUrl,
                method: 'POST',
                body: args.postBody,
            }),
            invalidatesTags: ['PrecautionaryPhrases'],
        }),
        deletePrecautionaryPhrase: build.mutation<
            GhsPrecautionaryPhrase,
            string
        >({
            query: (id) => ({
                url: `${serviceUrl}/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['PrecautionaryPhrases'],
        }),
        updatePrecautionaryPhrase: build.mutation<
            GhsPrecautionaryPhrase,
            BaseUpdateEntityArgs<GhsPrecautionaryPhrase>
        >({
            query: (args) => ({
                url: `${serviceUrl}/${args.id}`,
                method: 'PUT',
                body: args.postBody,
            }),
            invalidatesTags: [
                'PrecautionaryPhrases',
                'ActivePrecautionaryPhrase',
            ],
        }),
        getPrecautionaryPhrase: build.query<GhsPrecautionaryPhrase, string>({
            query: (id) => `${serviceUrl}/${id}`,
            providesTags: ['ActivePrecautionaryPhrase'],
        }),
        getPrecautionaryPhrasesByLangId: build.query<
            GhsPrecautionaryPhrase[],
            { langId: string }
        >({
            query: ({ langId }) => {
                return {
                    url: serviceUrl,
                    params: {
                        langId: langId,
                    },
                };
            },
            providesTags: ['PrecautionaryPhrases'],
        }),
        getPrecautionaryPhrasesByPhraseId: build.query<
            GhsPrecautionaryPhrase[],
            { phraseId: string }
        >({
            query: ({ phraseId }) => {
                return {
                    url: serviceUrl,
                    params: {
                        phraseId: phraseId,
                    },
                };
            },
            providesTags: ['PrecautionaryPhrases'],
        }),
    }),
});

export const {
    useCreatePrecautionaryPhraseMutation,
    useUpdatePrecautionaryPhraseMutation,
    useDeletePrecautionaryPhraseMutation,
    useGetPrecautionaryPhraseQuery,
    useGetPrecautionaryPhrasesByLangIdQuery,
    useGetPrecautionaryPhrasesByPhraseIdQuery,
} = precautionaryPhrasesApi;
