import React, { useEffect, useState } from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import PersonIcon from '@mui/icons-material/Person';
import {
    organizationsApi,
    Revision,
    useGetRevisionQuery,
} from '../../services/organizations/organizations.service';
import { skipToken } from '@reduxjs/toolkit/query';
import { DialogContent, Grid, Typography } from '@mui/material';
import BusinessIcon from '@mui/icons-material/Business';
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DatacorLogoSpinner from '../datacorLogoSpinner/DatacorLogoSpinner';
import { isNilOrEmpty } from '../../utils/objectUtils';
import RevisionValueFormatter from './RevisionValueFormatter';
import { useDispatch } from 'react-redux';

interface RevisionItemModalProps {
    item: Revision;
    onClose: () => void;
    open: boolean;
    fieldMappings: any;
}

const RevisionItemModal = ({
    item,
    onClose,
    open,
    fieldMappings,
}: RevisionItemModalProps) => {
    const dispatch = useDispatch();
    const { data: revision, isLoading: isLoadingRevision } =
        useGetRevisionQuery(item?.id ? item?.id?.toString() : skipToken);

    const [delta, setDelta] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    const handleClose = () => {
        onClose();
    };

    useEffect(() => {
        if (!isLoadingRevision && revision?.delta) {
            const parsedRevision = JSON.parse(revision?.delta);
            const checkDelta =
                Object.keys(parsedRevision).length === 0
                    ? null
                    : JSON.parse(revision?.delta);

            setDelta(
                !isNilOrEmpty(checkDelta?.dataValues)
                    ? checkDelta?.dataValues
                    : checkDelta
            );
        } else {
            setDelta(null);
        }
    }, [revision, isLoadingRevision]);

    const shouldDisplayField = (key: any, delta: any) => {
        const formatter = new RevisionValueFormatter().format(key, delta);
        if (isNilOrEmpty(formatter.before) && isNilOrEmpty(formatter.after)) {
            return false;
        } else {
            return true;
        }
    };

    const DisplayData = (props: any) => {
        const formatter = new RevisionValueFormatter();
        const { deltaKey, delta, type, isLoading, setIsLoading } = props;

        const [changesText, setChangesText] = useState({
            before: [],
            after: [],
        });

        useEffect(() => {
            handleSetupData();
        }, [deltaKey, delta, type]);

        const handleSetupData = async () => {
            if (!isNilOrEmpty(delta?.permissions?.before)) {
                for (const item of delta?.permissions?.before) {
                    const data = await dispatch(
                        organizationsApi.endpoints.getPermission.initiate({
                            permissionId: item.permissionId,
                            full: true,
                        })
                    );

                    setChangesText((prev) => ({
                        ...prev,
                        //@ts-ignore
                        before: [...prev.before, data?.data?.name],
                    }));
                }
            }

            if (!isNilOrEmpty(delta?.permissions?.after)) {
                for (const item of delta?.permissions?.after) {
                    const data = await dispatch(
                        organizationsApi.endpoints.getPermission.initiate({
                            permissionId: item.permissionId,
                            full: true,
                        })
                    );

                    setChangesText((prev) => ({
                        ...prev,
                        //@ts-ignore
                        after: [...prev.after, data?.data?.name],
                    }));
                }
            }

            setIsLoading(false);
        };

        if (deltaKey === 'permissions') {
            return type === 'before' ? (
                <>
                    {changesText.before.map((name: any) => (
                        <>{!isLoading && <div key={name}>{name}</div>}</>
                    ))}
                </>
            ) : (
                <>
                    {changesText.after.map((name: any) => (
                        <>{!isLoading && <div key={name}>{name}</div>}</>
                    ))}
                </>
            );
        } else if (!isNilOrEmpty(deltaKey)) {
            const result = formatter.format(deltaKey, delta);

            return type === 'before'
                ? result?.before || ''
                : result?.after || '';
        } else {
            return null;
        }
    };

    const DeltaListItem = ({ delta }: any) => {
        return (
            <>
                {delta &&
                    Object.keys(delta)?.map((key) => (
                        <div key={key}>
                            <List dense={true} disablePadding>
                                <ListItem>
                                    {
                                        // @ts-ignore
                                        fieldMappings[[key]] &&
                                        shouldDisplayField(key, delta) ? (
                                            <>
                                                <Typography noWrap>
                                                    {`${
                                                        // @ts-ignore
                                                        fieldMappings[[key]]
                                                    }:`}
                                                </Typography>
                                                <Typography
                                                    style={{
                                                        textDecoration:
                                                            'line-through',
                                                        paddingRight: '10px',
                                                        paddingLeft: '10px',
                                                        whiteSpace: 'pre-line',
                                                    }}>
                                                    <DisplayData
                                                        deltaKey={key}
                                                        delta={delta}
                                                        type={'before'}
                                                        isLoading={isLoading}
                                                        setIsLoading={
                                                            setIsLoading
                                                        }
                                                    />
                                                </Typography>
                                                <Typography
                                                    style={{
                                                        whiteSpace: 'pre-line',
                                                    }}>
                                                    <DisplayData
                                                        deltaKey={key}
                                                        delta={delta}
                                                        type={'after'}
                                                        isLoading={isLoading}
                                                        setIsLoading={
                                                            setIsLoading
                                                        }
                                                    />
                                                </Typography>
                                            </>
                                        ) : null
                                    }
                                </ListItem>
                            </List>
                        </div>
                    ))}
            </>
        );
    };

    return (
        <Dialog onClose={handleClose} open={open} fullWidth maxWidth={'lg'}>
            <DialogTitle>
                Revision Details -{' '}
                {revision && new Date(revision?.updatedAt).toLocaleString()}
            </DialogTitle>
            <DialogContent>
                {!isLoadingRevision ? (
                    <Grid container spacing={1}>
                        <Grid item xs={6}>
                            <List
                                sx={{
                                    width: '100%',
                                    bgcolor: 'background.paper',
                                }}>
                                <ListItem>
                                    <ListItemAvatar>
                                        <Avatar>
                                            <PersonIcon />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={`${revision?.user?.firstName} ${revision?.user?.lastName}`}
                                        secondary={`${revision?.user?.email}`}
                                    />
                                </ListItem>
                                <ListItem>
                                    <ListItemAvatar>
                                        <Avatar>
                                            <BusinessIcon />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary="Source"
                                        secondary={`${revision?.revisionSource?.name}`}
                                    />
                                </ListItem>
                                <ListItem>
                                    <ListItemAvatar>
                                        <Avatar>
                                            <SyncAltIcon />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary="Action"
                                        secondary={`${revision?.revisionAction?.name}`}
                                    />
                                </ListItem>
                            </List>
                        </Grid>
                        <Grid item xs={6}>
                            <List
                                sx={{
                                    width: '100%',
                                    bgcolor: 'background.paper',
                                }}>
                                <ListItem>
                                    <ListItemAvatar>
                                        <Avatar>
                                            <ArrowBackIcon />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={`Changes`}
                                        secondary={
                                            delta ? (
                                                <div
                                                    style={{
                                                        marginLeft: '-15px',
                                                    }}>
                                                    <DeltaListItem
                                                        delta={delta}
                                                    />
                                                </div>
                                            ) : (
                                                'No changes found...'
                                            )
                                        }
                                    />
                                </ListItem>
                            </List>
                        </Grid>
                    </Grid>
                ) : (
                    <DatacorLogoSpinner />
                )}
            </DialogContent>
        </Dialog>
    );
};

export default RevisionItemModal;
