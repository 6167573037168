import React from 'react';
import { ColDef } from 'ag-grid-community';
import BaseFormGrid from '../../components/grids/BaseFormGrid';
import DefaultColumnTypes from '../../components/grids/columns/Column.constants';
import { UseGeneralLedgerAccountGrid } from './hooks/useGeneralLedgerAccountGrid';
import CustomHeader from '../../components/grids/CustomHeader';
import { GeneralLedgerAccountsGridDefs } from '../../constants/i18n/translations/termDefinitions/accounting';

const GeneralLedgerAccountGrid = () => {
    const {
        generalLedgerAccountList,
        isLoading,
        redirectToForm,
        canCreateChartOfAccounts,
        canViewChartOfAccounts,
        termSet,
    } = UseGeneralLedgerAccountGrid();

    const accountsColumnDefs: ColDef[] = [
        {
            field: 'number',
            headerComponentFramework: (props: any) => {
                return (
                    <CustomHeader
                        {...props}
                        termSet={termSet}
                        termKey={GeneralLedgerAccountsGridDefs.Account_Number}
                    />
                );
            },
            ...DefaultColumnTypes.ShortText,
        },
        {
            field: 'name',
            headerComponentFramework: (props: any) => {
                return (
                    <CustomHeader
                        {...props}
                        termSet={termSet}
                        termKey={GeneralLedgerAccountsGridDefs.Account_Name}
                    />
                );
            },
            ...DefaultColumnTypes.MediumText,
        },
        {
            field: 'description',
            headerComponentFramework: (props: any) => {
                return (
                    <CustomHeader
                        {...props}
                        termSet={termSet}
                        termKey={GeneralLedgerAccountsGridDefs.Description}
                    />
                );
            },
            ...DefaultColumnTypes.LongText,
        },
        {
            field: 'accountStatus.status',
            headerComponentFramework: (props: any) => {
                return (
                    <CustomHeader
                        {...props}
                        termSet={termSet}
                        termKey={GeneralLedgerAccountsGridDefs.Account_Status}
                    />
                );
            },
            ...DefaultColumnTypes.ShortText,
        },
        {
            field: 'balanceType.type',
            headerComponentFramework: (props: any) => {
                return (
                    <CustomHeader
                        {...props}
                        termSet={termSet}
                        termKey={GeneralLedgerAccountsGridDefs.Balance_Type}
                    />
                );
            },
            ...DefaultColumnTypes.ShortText,
        },
        {
            field: 'accountType.type',
            headerComponentFramework: (props: any) => {
                return (
                    <CustomHeader
                        {...props}
                        termSet={termSet}
                        termKey={GeneralLedgerAccountsGridDefs.Account_Type}
                    />
                );
            },
            ...DefaultColumnTypes.ShortText,
        },
    ];

    return (
        <BaseFormGrid
            displayGrid={canViewChartOfAccounts}
            isLoading={isLoading}
            rowData={generalLedgerAccountList}
            columnDefs={accountsColumnDefs}
            gridClass="full-size-item"
            displayToolbar={true}
            displayCreateNewButton={canCreateChartOfAccounts}
            displayExportDataButton={true}
            displayResetStateButton={true}
            onCreateNew={redirectToForm}
            onRowClicked={({ data }) => redirectToForm(data)}
        />
    );
};

export default GeneralLedgerAccountGrid;
