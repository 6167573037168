import React, { useEffect, useState } from 'react';
import { DialogActions, Popover } from '@mui/material';
import SelectInput from '../form/formInputs/SelectInput/SelectInput';
import { useParams } from 'react-router-dom';
import LoaderButton from '../LoaderButton';
import {
    documentDesignerApi,
    useGenerateDocumentMutation,
    useListDocTemplatesQuery,
} from '../../services/documentDesigner/documentDesigner.service';
import PreviewDocumentModal from './PreviewDocumentModal';
import VisibilityIcon from '@mui/icons-material/Visibility';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { skipToken } from '@reduxjs/toolkit/query';
import { isNilOrEmpty } from '../../utils/objectUtils';
import { useDispatch } from 'react-redux';
import { useForm } from '../../libs/hooksLib';

const PrintDocumentPopover = ({
    anchorEl,
    open,
    onClose,
    documentType,
    documentQuery,
}: any) => {
    /**The entityId of the entity we're generating the report for */
    const { id } = useParams();
    const dispatch = useDispatch();
    const [openModal, setOpenModal] = useState(false);
    const [renderedDocument, setRenderedDocument] = useState(null);

    const { fields, handleFieldChange, formMethods } = useForm({
        selectedDocument: null,
    });

    //this will be the dataSource we use for the actual rendered document
    const { data: documentData, refetch: refetchDocData } = documentQuery(
        id ? id : skipToken
    );

    //TODO: get by entity type - documentType ? documentType : skipToken
    const { data: documentTemplates, refetch: refetchTemplates } =
        useListDocTemplatesQuery();

    const [generateDocument] = useGenerateDocumentMutation();

    //TODO: get attchment from id from the DocFile returned
    //const { data: attachment } = useGetAttachmentQuery(docFile ? docFile?.id : skipToken);

    /**
     * when the click generate document
     * we get back a docFile
     * we can use the docFile to get the blob of the pdf
     * @param docFile
     */
    const handleDownload = async (docFile: any) => {
        if (!isNilOrEmpty(docFile?.data?.id)) {
            //we're using the attachmentId for now to get the devonly document blob
            const reportPdf: any = await dispatch(
                documentDesignerApi.endpoints.downloadReport.initiate(
                    docFile?.data?.attachmentId
                )
            );
            const selectedDocument = documentTemplates.find(
                (document: any) => document?.id === fields?.selectedDocument
            );

            const blob = reportPdf?.data;
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);

            link.download = `${selectedDocument.outputFileName}.pdf`;
            link.target = '_blank';
            link.click();

            window.URL.revokeObjectURL(link.href);
        }
    };

    /**
     * when we select a document template
     * we need to replace the template data
     * with real data from the entity
     */
    useEffect(() => {
        if (fields?.selectedDocument && documentData) {
            getDocumentData();
        }
    }, [fields?.selectedDocument, documentData, openModal, documentTemplates]);

    const handleGeneratePdf = async () => {
        const args = {
            id: fields?.selectedDocument,
            entityUUID: documentData?.UUID?.value,
            documentType: documentType,
            data: documentData,
        };

        const response = await generateDocument(args);

        await handleDownload(response);
    };

    const getDocumentData = async () => {
        //add a new dataset to the report
        const dataSet = new (window as any).Stimulsoft.System.Data.DataSet(
            'sdsDataSet'
        );
        const report = new (window as any).Stimulsoft.Report.StiReport();

        //add the real data to the dataset
        dataSet.readJson(JSON.stringify(documentData));

        report.regData(dataSet.dataSetName, '', dataSet);

        //sync the dictionary
        report.dictionary.synchronize();

        const selectedDocument = documentTemplates.find(
            (document: any) => document?.id === fields?.selectedDocument
        );
        //load the report with the template structure - only with the real data from the dataset
        report.load(selectedDocument?.templateData);

        //set the report for the preview modal
        setRenderedDocument(report);
    };

    const handlePreview = () => {
        refetchTemplates();
        setOpenModal(true);
    };

    return (
        <>
            <PreviewDocumentModal
                open={openModal}
                onClose={() => setOpenModal(false)}
                document={renderedDocument}
            />
            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={onClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                PaperProps={{
                    style: { width: '20%', padding: '20px' },
                }}>
                <SelectInput
                    fullWidth
                    id={'selectedDocument'}
                    label="Select Document"
                    value={fields?.selectedDocument}
                    onChange={handleFieldChange}
                    options={
                        documentTemplates &&
                        documentTemplates?.map((document: any) => {
                            return {
                                label: document.name,
                                value: document.id,
                            };
                        })
                    }
                />
                <DialogActions>
                    <LoaderButton
                        endIcon={<PictureAsPdfIcon />}
                        disabled={!fields?.selectedDocument}
                        variant="contained"
                        onClick={handleGeneratePdf}>
                        Generate
                    </LoaderButton>
                    <LoaderButton
                        endIcon={<VisibilityIcon />}
                        disabled={!fields?.selectedDocument}
                        variant="contained"
                        onClick={handlePreview}>
                        Preview
                    </LoaderButton>
                </DialogActions>
            </Popover>
        </>
    );
};

export default PrintDocumentPopover;
