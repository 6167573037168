import { datacorAppApi } from '../../services/datacorAppApi';
import {
    BaseCreateEntityArgs,
    BaseUpdateEntityArgs,
} from '../../services/serviceInterfaces';

// Intened to wrap and automate the injection of endpoints with a few parameters
// See README.md for details on usage
export const simpleEndpoints = <T>(
    name: string,
    basePath: string,
    dropdownLabel: string = null,
    queryStringFilters: string = null,
    useUpdateListRequest: boolean = false
) => {
    injectList<T>(name, `list${name}`, basePath);
    injectUpdateList<T>(
        name,
        `updateList${name}`,
        basePath,
        useUpdateListRequest
    );
    injectGet<T>(name, `get${name}`, basePath);
    injectCreate<T>(name, `create${name}`, basePath);
    injectUpdate<T>(name, `update${name}`, basePath);
    injectDelete<T>(name, `delete${name}`, basePath);

    if (dropdownLabel) {
        injectDropdown<T>(
            name,
            `dropdown${name}`,
            basePath,
            dropdownLabel,
            queryStringFilters
        );
    }
};

export const injectGet = <T>(
    model: string,
    service: string,
    basePath: string,
    overrideExisting: boolean = false
) => {
    datacorAppApi.enhanceEndpoints({
        addTagTypes: [`Active_${model}`],
    });

    datacorAppApi.injectEndpoints({
        endpoints: (build) => ({
            [service]: build.query<T, string>({
                query: (id) => `${basePath}/${id}`,
                providesTags: [
                    'Subscribers',
                    'BusinessEntities',
                    'Users',
                    `Active_${model}`,
                ],
            }),
        }),
        overrideExisting,
    });
};

export const injectCreate = <T>(
    model: string,
    service: string,
    basePath: string,
    overrideExisting: boolean = false
) =>
    datacorAppApi.injectEndpoints({
        endpoints: (build) => ({
            [service]: build.mutation<any, BaseCreateEntityArgs<any>>({
                query: (args: BaseCreateEntityArgs<T>) => ({
                    url: basePath,
                    method: 'POST',
                    body: args.postBody,
                }),
                invalidatesTags: [
                    `List_${model}`,
                    `Active_${model}`,
                    `Dropdown_${model}`,
                ],
            }),
        }),
        overrideExisting,
    });

export const injectDelete = <T>(
    model: string,
    service: string,
    basePath: string,
    overrideExisting: boolean = false
) =>
    datacorAppApi.injectEndpoints({
        endpoints: (build) => ({
            [service]: build.mutation<any, string>({
                query: (id) => ({
                    url: `${basePath}/${id}`,
                    method: 'DELETE',
                }),
                invalidatesTags: [`List_${model}`, `Dropdown_${model}`],
            }),
        }),
        overrideExisting,
    });

export const injectUpdate = <T>(
    model: string,
    service: string,
    basePath: string,
    overrideExisting: boolean = false
) =>
    datacorAppApi.injectEndpoints({
        endpoints: (build) => ({
            [service]: build.mutation<any, BaseUpdateEntityArgs<any>>({
                query: (args) => ({
                    url: `${basePath}/${args.id}`,
                    method: 'PATCH',
                    body: args.postBody,
                }),
                invalidatesTags: [
                    `List_${model}`,
                    `Active_${model}`,
                    `Dropdown_${model}`,
                ],
            }),
        }),
        overrideExisting,
    });

export const injectList = <T>(
    model: string,
    service: string,
    basePath: string,
    overrideExisting: boolean = false
) => {
    datacorAppApi.enhanceEndpoints({
        addTagTypes: [`List_${model}`],
    });

    datacorAppApi.injectEndpoints({
        endpoints: (build) => ({
            [service]: build.query<T[], void>({
                query: () => basePath,
                providesTags: [
                    'Subscribers',
                    'BusinessEntities',
                    'Users',
                    `List_${model}`,
                ],
            }),
        }),
        overrideExisting,
    });
};

export const injectDropdown = <T>(
    model: string,
    service: string,
    basePath: string,
    label: string,
    queryStringFilters: string = null,
    overrideExisting: boolean = false
) => {
    datacorAppApi.enhanceEndpoints({
        addTagTypes: [`Dropdown_${model}`],
    });

    datacorAppApi.injectEndpoints({
        endpoints: (build) => ({
            [service]: build.query<T[], void>({
                query: () => `${basePath}?${queryStringFilters ?? ''}`,
                // @ts-ignore
                transformResponse: (response: T[]) => {
                    return response.map((item: any) => {
                        return { value: item.id, label: item[label] };
                    });
                },
                providesTags: [
                    'Subscribers',
                    'BusinessEntities',
                    'Users',
                    `Dropdown_${model}`,
                ],
            }),
        }),
        overrideExisting,
    });
};

export const injectUpdateList = <T>(
    model: string,
    service: string,
    basePath: string,
    useUpdateListRequest: boolean = false,
    overrideExisting: boolean = false
) => {
    datacorAppApi.injectEndpoints({
        endpoints: (build) => ({
            [service]: build.mutation<boolean, any>({
                query: (args) => ({
                    url: `${basePath}/updateList`,
                    method: 'POST',
                    body: useUpdateListRequest ? { items: args } : args,
                }),
                invalidatesTags: [
                    `List_${model}`,
                    `Active_${model}`,
                    `Dropdown_${model}`,
                ],
            }),
        }),
        overrideExisting,
    });
};
