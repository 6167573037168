export const ColumnGreaterThanValidator = (
    greaterThanColumnLabel: string,
    lessThanColumnLabel: string,
    greaterValue: number,
    lessValue: number
) => {
    let isValid = true;
    let getErrorMessage: string = null;
    if (greaterValue && lessValue && greaterValue < lessValue) {
        isValid = false;
        getErrorMessage = `${lessThanColumnLabel} cannot be greater than ${greaterThanColumnLabel}`;
    }
    return {
        getErrorMessage,
        isValid,
    };
};
