import { useNavigate } from 'react-router-dom';
import { useListUnitOfMeasureConversionQuery } from '../../../services/organizations/organizations.service';
import { skipToken } from '@reduxjs/toolkit/query';
import { RootStateOrAny, useSelector } from 'react-redux';
import React from 'react';
import { PermissionsUtil } from '../../../utils/permissions/permissionsUtil';
import { PERMISSIONS } from '../../../constants/permissions/Permissions.constants';

const UseUnitOfMeasureConversionGrid = () => {
    const navigate = useNavigate();
    const user = useSelector((state: RootStateOrAny) => state.user);

    const {
        data: unitOfMeasureConversionList,
        isLoading: isLoadingUnitOfMeasureConversion,
    } = useListUnitOfMeasureConversionQuery(
        user?.businessEntity?.id
            ? user?.businessEntity?.id.toString()
            : skipToken
    );

    const canCreateUnitOfMeasureConversion =
        PermissionsUtil.isPermissionEnabled(
            user.permissions,
            PERMISSIONS.SETUP.UNIT_OF_MEASURE_CONVERSION.CREATE
        );

    const canViewUnitOfMeasureConversion = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.SETUP.UNIT_OF_MEASURE_CONVERSION.VIEW
    );

    const redirectToForm = (row: any) => {
        if (row?.id) {
            navigate(`/setup/unitOfMeasureConversion/${row.id}`);
        } else {
            navigate(`/setup/unitOfMeasureConversion/new`);
        }
    };

    const EqualsColumn = () => {
        return <span>=</span>;
    };

    return {
        redirectToForm,
        unitOfMeasureConversionList,
        isLoadingUnitOfMeasureConversion,
        EqualsColumn,
        canCreateUnitOfMeasureConversion,
        canViewUnitOfMeasureConversion,
    };
};

export default UseUnitOfMeasureConversionGrid;
