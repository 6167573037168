import React from 'react';
import {
    CasMaster,
    CasRegulation,
    Regulation,
} from '../../../../../../types/formulation';
import { isNilOrEmpty } from '../../../../../../utils/objectUtils';
import DatacorLogoSpinner from '../../../../../../components/datacorLogoSpinner/DatacorLogoSpinner';
import BaseGrid from '../../../../../../components/grids/BaseGrid';
import useCasRegulationsGrid from './hooks/useCasRegulationsGrid';
import FormPartial from '../../../../../../components/form/formContainer/FormPartial';
import { ChildEditableGridWithCopyFunctionProps } from '../../../../../grid/utils/editableGrid/ChildEditableGridWithCopyFunctionProps';
import RegulationsSelectionModal from '../../../../shared/multiSelectModals/regulations/RegulationsSelectionModal';
import { CasMasterFormDefs } from '../../../../../../constants/i18n/translations/termDefinitions/formulation';
import CustomHeader from '../../../../../../components/grids/CustomHeader';
import TranslatableText from '../../../../../../components/i18n/TranslatableText';

const CasRegulationsGrid = (
    props: ChildEditableGridWithCopyFunctionProps<CasMaster, CasRegulation>
) => {
    const {
        handleSaveGridEdits,
        regulationList,
        showMultiSelectGridModal,
        handleSelectedRecords,
        getStateById,
        casRegulationsColumnDefs,
        handleRowValidations,
        colDefOptions,
        handleSetShowMultiSelectGridModal,
        termSet,
    } = useCasRegulationsGrid(props);

    const { isParentLoading } = props.parentData;

    const { showAddButton, showDeleteButton, showSortButton, isGridEditable } =
        props.displayGridButtons;

    const { rowsData } = props.copyMethods;

    return (
        <React.Fragment>
            {isParentLoading ? (
                <DatacorLogoSpinner />
            ) : !isParentLoading && isNilOrEmpty(casRegulationsColumnDefs) ? (
                <div className="spinnerContainer">Setup Data Unavailable</div>
            ) : (
                <>
                    <FormPartial
                        title={
                            (
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={CasMasterFormDefs.Regulations_Text}
                                />
                            ) as unknown as string
                        }>
                        <RegulationsSelectionModal
                            openModal={showMultiSelectGridModal}
                            onClose={() =>
                                handleSetShowMultiSelectGridModal(false)
                            }
                            onSelected={(selectedRecords: Regulation[]) =>
                                handleSelectedRecords(selectedRecords)
                            }
                            getStateCode={getStateById}
                            regulationList={regulationList || []}
                        />
                        <BaseGrid
                            autoSize={true}
                            displayGrid={true}
                            displayCreateNewButton={showAddButton}
                            useRowDrag={showSortButton}
                            displaySortButton={showSortButton}
                            sortKey={'sortBy'}
                            rowData={rowsData}
                            dataKey={'rowId'}
                            gridClass="full-width-grid"
                            displayToolbar={true}
                            sizeColumnsToFit={true}
                            onHandleGridEdits={handleSaveGridEdits}
                            onCreateNew={() =>
                                handleSetShowMultiSelectGridModal(true)
                            }
                            isEditable={isGridEditable}
                            columnDefs={casRegulationsColumnDefs}
                            displayDeleteButton={showDeleteButton}
                            gridButtonTitle={'Regulations'}
                            createRow={false}
                            onHandleRowValidations={handleRowValidations}
                            defaultColDef={colDefOptions}></BaseGrid>
                    </FormPartial>
                </>
            )}
        </React.Fragment>
    );
};

export default CasRegulationsGrid;
