import React from 'react';
import { useParams } from 'react-router-dom';
import { Grid, FormControlLabel, Checkbox } from '@mui/material';
import TextInput from '../../components/form/formInputs/TextInput/TextInput';
import FormPartial from '../../components/form/formContainer/FormPartial';
import useBusinessEntityForm from './hooks/useBusinessEntityForm';
import ExpandableDropdown from '../../components/form/expandableDropdown/ExpandableDropdown';
import SelectInput from '../../components/form/formInputs/SelectInput/SelectInput';
import { BUSINESS_ENTITY_FIELDS } from '../../constants/revisionHistory/revisionHistory.constants';
import BusinessEntityExternalAuthDialog from './BusinessEntityExternalAuthDialog';
import BaseForm from '../../components/form/baseForm/BaseForm';
import TranslatableText from '../../components/i18n/TranslatableText';
import { BusinessEntityFormDefs } from '../../constants/i18n/translations/termDefinitions/platform';

type ParamTypes = { id: string };

const BusinessEntityForm = () => {
    const { id } = useParams<ParamTypes>();
    const {
        fields,
        zoneFields,
        isLoadingBusinessEntityTypeOptions,
        businessEntity,
        businessEntityTypeOptions,
        businessEntityOptions,
        handleClose,
        handleFieldChange,
        handleZoneFieldChange,
        handleUpdateBusinessEntity,
        handleCreateBusinessEntity,
        isLoading,
        setValues,
        formMethods,
        zoneFormMethods,
        appClientSecret,
        appClientId,
        showAuthDialog,
        canEditBusinessEntity,
        canDeleteBusinessEntity,
        canCreateBusinessEntity,
        termSet,
    } = useBusinessEntityForm(id);

    return (
        <>
            <BusinessEntityExternalAuthDialog
                appClientSecret={appClientSecret}
                appClientId={appClientId}
                show={showAuthDialog}
                onClose={handleClose}></BusinessEntityExternalAuthDialog>
            <BaseForm
                title={
                    id ? (
                        fields.name
                    ) : (
                        <TranslatableText
                            termKey={
                                BusinessEntityFormDefs.Create_New_Business_Entity
                            }
                            termSet={termSet}
                        />
                    )
                }
                formMethods={formMethods}
                isLoading={isLoading}
                isNew={!id}
                onCancel={handleClose}
                showOnRevision={true}
                entityUuid={businessEntity?.uuid}
                fieldMappings={BUSINESS_ENTITY_FIELDS}
                displayDeleteButton={false}
                displayCreateButton={canCreateBusinessEntity}
                displayUpdateButton={canEditBusinessEntity}
                onCreate={handleCreateBusinessEntity}
                onUpdate={handleUpdateBusinessEntity}
                readOnly={id && !canEditBusinessEntity}>
                <FormPartial>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <TextInput
                                fullWidth
                                label={
                                    <TranslatableText
                                        termKey={BusinessEntityFormDefs.Name}
                                        termSet={termSet}
                                    />
                                }
                                id="name"
                                value={fields.name}
                                formMethods={formMethods}
                                onChange={handleFieldChange}
                                required={true}
                                maxLength={90}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextInput
                                fullWidth
                                label={
                                    <TranslatableText
                                        termKey={
                                            BusinessEntityFormDefs.Description
                                        }
                                        termSet={termSet}
                                    />
                                }
                                id="description"
                                value={fields.description}
                                onChange={handleFieldChange}
                                maxLength={300}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <SelectInput
                                fullWidth
                                label={
                                    <TranslatableText
                                        termKey={BusinessEntityFormDefs.Type}
                                        termSet={termSet}
                                    />
                                }
                                required={true}
                                formMethods={formMethods}
                                id="businessEntityTypeId"
                                value={fields.businessEntityTypeId}
                                options={businessEntityTypeOptions}
                                disabled={isLoadingBusinessEntityTypeOptions}
                                onChange={handleFieldChange}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <FormControlLabel
                                label={
                                    <TranslatableText
                                        termKey={
                                            BusinessEntityFormDefs.Enable_External_API_Access
                                        }
                                        termSet={termSet}
                                    />
                                }
                                control={
                                    <Checkbox
                                        id="externalAuthorization"
                                        checked={fields.externalAuthorization}
                                        onChange={handleFieldChange}
                                        disabled={!!id}
                                    />
                                }
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <ExpandableDropdown
                                valueSelected={(value) =>
                                    setValues({
                                        parentId: value,
                                    })
                                }
                                defaultValue={fields.parentId}
                                items={businessEntityOptions}
                                idKey="id"
                                labelKey="name"
                                label={
                                    (
                                        <TranslatableText
                                            termKey={
                                                BusinessEntityFormDefs.Parent_Business_Entity
                                            }
                                            termSet={termSet}
                                        />
                                    ) as unknown as string
                                }
                                id="parentId"
                                formMethods={formMethods}
                                disabled={!!id}
                            />
                        </Grid>
                    </Grid>
                </FormPartial>
                {fields.parentId ? (
                    <FormPartial
                        title={
                            (
                                <TranslatableText
                                    termKey={
                                        BusinessEntityFormDefs.Zone_Information
                                    }
                                    termSet={termSet}
                                />
                            ) as unknown as string
                        }>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <TextInput
                                    fullWidth
                                    label={
                                        <TranslatableText
                                            termKey={
                                                BusinessEntityFormDefs.Platform
                                            }
                                            termSet={termSet}
                                        />
                                    }
                                    id="platform"
                                    value={zoneFields.platform}
                                    onChange={handleZoneFieldChange}
                                    maxLength={90}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextInput
                                    fullWidth
                                    label={
                                        <TranslatableText
                                            termKey={BusinessEntityFormDefs.URL}
                                            termSet={termSet}
                                        />
                                    }
                                    id="url"
                                    value={zoneFields.url}
                                    onChange={handleZoneFieldChange}
                                    maxLength={90}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextInput
                                    fullWidth
                                    label={
                                        <TranslatableText
                                            termKey={
                                                BusinessEntityFormDefs.Port
                                            }
                                            termSet={termSet}
                                        />
                                    }
                                    id="port"
                                    value={zoneFields.port}
                                    onChange={handleZoneFieldChange}
                                    maxLength={90}
                                />
                            </Grid>
                        </Grid>
                    </FormPartial>
                ) : null}
            </BaseForm>
        </>
    );
};

export default BusinessEntityForm;
