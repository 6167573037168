import React from 'react';
import { useParams } from 'react-router-dom';
import { Grid } from '@mui/material';
import TextInput from '../../components/form/formInputs/TextInput/TextInput';
import FormPartial from '../../components/form/formContainer/FormPartial';
import useGroupsForm from './hooks/useGroupForm';
import SelectInput from '../../components/form/formInputs/SelectInput/SelectInput';
import BaseForm from '../../components/form/baseForm/BaseForm';
import { GroupsFormDefs } from '../../constants/i18n/translations/termDefinitions/platform';
import TranslatableText from '../../components/i18n/TranslatableText';

type ParamTypes = { id: string };

const GroupsForm = () => {
    const { id } = useParams<ParamTypes>();
    const {
        fields,
        user,
        users,
        setValues,
        handleClose,
        handleFieldChange,
        handleUpdateGroup,
        handleCreateGroup,
        isLoading,
        businessEntityOptions,
        formMethods,
        termSet,
    } = useGroupsForm(id);

    return (
        <BaseForm
            title={
                <>
                    {id ? (
                        fields.name + ` (${fields?.businessEntity?.name})`
                    ) : (
                        <>
                            <TranslatableText
                                termKey={GroupsFormDefs.Create_New_Group}
                                termSet={termSet}
                            />{' '}
                            ({fields?.businessEntity?.name})
                        </>
                    )}
                </>
            }
            formMethods={formMethods}
            isLoading={isLoading || !termSet}
            isNew={!id}
            onCancel={handleClose}
            onUpdate={handleUpdateGroup}
            onCreate={handleCreateGroup}
            displayCreateButton={true}
            displayUpdateButton={true}
            displayDeleteButton={false}>
            <FormPartial>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <TextInput
                            fullWidth
                            label={
                                <TranslatableText
                                    termKey={GroupsFormDefs.Name}
                                    termSet={termSet}
                                />
                            }
                            id="name"
                            value={fields.name}
                            onChange={handleFieldChange}
                            required={true}
                            maxLength={90}
                            formMethods={formMethods}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextInput
                            fullWidth
                            label={
                                <TranslatableText
                                    termKey={GroupsFormDefs.Description}
                                    termSet={termSet}
                                />
                            }
                            id="description"
                            value={fields.description}
                            onChange={handleFieldChange}
                            maxLength={90}
                            formMethods={formMethods}
                        />
                    </Grid>
                    {!!id && (
                        <>
                            <Grid item xs={6}>
                                <SelectInput
                                    fullWidth
                                    SelectProps={{
                                        multiple: true,
                                    }}
                                    label={
                                        <TranslatableText
                                            termKey={GroupsFormDefs.Users}
                                            termSet={termSet}
                                        />
                                    }
                                    id="users"
                                    value={fields?.users}
                                    onChange={handleFieldChange}
                                    options={users}
                                    formMethods={formMethods}
                                />
                            </Grid>
                        </>
                    )}
                </Grid>
            </FormPartial>
        </BaseForm>
    );
};

export default GroupsForm;
