import React, { useState } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Button, Typography } from '@mui/material';
import {
    Timeline,
    TimelineConnector,
    TimelineContent,
    TimelineDot,
    TimelineItem,
    TimelineOppositeContent,
    TimelineSeparator,
} from '@mui/lab';
import { dateFormatter } from '../../../utils/formattingUtils';
import AttachmentIcon from '@mui/icons-material/Attachment';
import { useParams } from 'react-router-dom';
import AttachmentItemModal from './AttachmentItemModal';

const drawerWidth = 400;

interface themeTypes {
    theme: any;
}

const DrawerHeader = styled('div')(({ theme }: themeTypes) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(10, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-start',
}));

interface AttachmentsDrawerProps {
    open: boolean;
    setOpen: (_: boolean) => void;
    /** The uuid of the entity you're getting attachment data for */
    entityUuid: string;
}

export default function AttachmentsDrawer({
    open,
    setOpen,
    entityUuid,
}: AttachmentsDrawerProps) {
    //id of the entity you're getting attachments for
    const { id } = useParams<any>();
    const [openAttachmentItem, setOpenAttachmentItem] = useState(false);
    const [selectedAttachment, setSelectedAttachment] = useState(null);
    //TODO: get list of attachments from entityUuid
    const theme = useTheme();

    const attachments = [
        {
            id: 1,
            name: 'Test Attachment 1',
            createdAt: '2021-10-01T00:00:00.000Z',
            createdBy: 'Test User',
            extension: 'pdf',
            notes: 'Test Notes',
        },
        {
            id: 2,
            name: 'Test Attachment 2',
            createdAt: '2021-10-02T00:00:00.000Z',
            createdBy: 'Another Test User',
            extension: '.docx',
            notes: 'More Notes',
        },
    ];

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const handleOpen = (item: any) => {
        setSelectedAttachment(item);
        setOpenAttachmentItem(true);
    };

    return (
        <Box sx={{ display: 'flex' }}>
            <AttachmentItemModal
                item={selectedAttachment}
                open={openAttachmentItem}
                onClose={() => setOpenAttachmentItem(false)}
            />
            <Drawer
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                    },
                }}
                variant="persistent"
                anchor="right"
                open={open}>
                <DrawerHeader>
                    <Typography variant="h6">Attachments</Typography>
                    <IconButton onClick={handleDrawerClose}>
                        {theme.direction === 'rtl' ? (
                            <ChevronLeftIcon />
                        ) : (
                            <ChevronRightIcon />
                        )}
                    </IconButton>
                </DrawerHeader>
                <Divider />
                <Timeline position="left">
                    {attachments.length > 0 ? (
                        attachments.map((attachment: any, index: number) => (
                            <Button
                                style={{ textTransform: 'none' }}
                                variant="text"
                                size="small"
                                key={attachment.id}
                                onClick={(e) => handleOpen(attachment)}>
                                <TimelineItem sx={{ width: '100%' }}>
                                    <TimelineOppositeContent
                                        sx={{ py: '18px', px: 2 }}>
                                        <Typography>
                                            {dateFormatter(
                                                attachment.createdAt
                                            )}
                                            <br />
                                            {attachment?.createdBy}
                                        </Typography>
                                    </TimelineOppositeContent>
                                    <TimelineSeparator>
                                        <TimelineDot>
                                            <AttachmentIcon />
                                        </TimelineDot>
                                        {index !== attachments.length - 1 && (
                                            <TimelineConnector />
                                        )}
                                    </TimelineSeparator>
                                    <TimelineContent sx={{ py: '18px', px: 2 }}>
                                        <Typography>
                                            {attachment?.name}
                                        </Typography>
                                    </TimelineContent>
                                </TimelineItem>
                            </Button>
                        ))
                    ) : (
                        <>No Attachments Available...</>
                    )}
                </Timeline>
            </Drawer>
        </Box>
    );
}
