import { Locale } from '../../../types/formulation';
import { BaseCreateEntityArgs } from '../../serviceInterfaces';
import { datacorAppApi } from '../../datacorAppApi';

const serviceUrl: string = 'formulation/locale';

export const localeApi = datacorAppApi.injectEndpoints({
    endpoints: (build) => ({
        updateLocales: build.mutation<Locale, BaseCreateEntityArgs<Locale[]>>({
            query: (args) => ({
                url: serviceUrl,
                method: 'PUT',
                body: args.postBody,
            }),
            invalidatesTags: ['Locale'],
        }),
        listLocales: build.query<Locale[], void>({
            query: () => {
                return {
                    url: serviceUrl,
                };
            },
            transformResponse: (response: Locale[]) => {
                return response.map((current) => {
                    current.groupId = {
                        id: current.localeGroupId,
                        display: current.localeGroup?.groupId,
                    };
                    return current;
                });
            },
            providesTags: ['Locale'],
        }),
    }),
});

export const { useUpdateLocalesMutation, useListLocalesQuery } = localeApi;
