import React, { useContext, useEffect, useState } from 'react';
import { RootStateOrAny, useSelector } from 'react-redux';
import {
    ColDef,
    EditableCallbackParams,
    ICellRendererParams,
} from 'ag-grid-community';
import _, { cloneDeep } from 'lodash';
import { HazardRating } from '../../../../types/formulation';
import useEditableBaseGrid from '../../../grid/hooks/useEditableBaseGrid';
import { PermissionsUtil } from '../../../../utils/permissions/permissionsUtil';
import { PERMISSIONS } from '../../../../constants/permissions/Permissions.constants';
import DefaultColumnTypes from '../../../../components/grids/columns/Column.constants';
import { GridOptions } from '../../../../components/grids/Grid.constants';
import {
    useListHazardRatingsQuery,
    useUpdateHazardRatingsMutation,
} from '../../../../services/formulation/hazardRating/hazardRating.service';
import SettingsContext from '../../../../contexts/settings.context';
import { useGetTermSetQuery } from '../../../../services/i18n/i18n.service';
import { FORMULATION_DEFS } from '../../../../constants/i18n/translations/termSetDefinitions/formulation';
import { skipToken } from '@reduxjs/toolkit/query';
import CustomHeader from '../../../../components/grids/CustomHeader';
import { RowStatus } from '../../../../components/grids/hooks/useBaseGridEditable';
import { useListUnitOfMeasureDropdownQuery } from '../../../../services/organizations/organizations.service';
import { isNilOrEmpty } from '../../../../utils/objectUtils';
import { SelectedDropdownOption } from '../../../../types/Shared.types';
import applyEditableGridValidations from '../../../grid/utils/applyEditableGridValidations';
import { ColumnRequiredValueValidator } from '../../../grid/validators/columnRequiredValue.validator';
import { UniqueColumnValueValidator } from '../../../grid/validators/uniqueColumnValue.validator';
import { ColumnMaxValueValidator } from '../../../grid/validators/columnMaxValue.validator';
import {
    CasMasterFormDefs,
    HazardRatingsGridDefs,
} from '../../../../constants/i18n/translations/termDefinitions/formulation';

const colDefOptions = {
    ...GridOptions.sortFilterAndWrapColumns,
    floatingFilter: true,
};

const placeHolders = {
    code: 'Enter a Code...',
    description: 'Enter a Description...',
    uomCode: 'Enter an UOM...',
};

const useHazardRatingGrid = () => {
    const user = useSelector((state: RootStateOrAny) => state.user);

    const { settings } = useContext(SettingsContext);
    const { data: termSet } = useGetTermSetQuery(
        settings?.userSettings
            ? {
                  languageId: settings?.userSettings?.languageId,
                  code: FORMULATION_DEFS.HAZARD_RATINGS_GRID,
              }
            : skipToken
    );

    const { data: casTermSet } = useGetTermSetQuery(
        settings?.userSettings
            ? {
                  languageId: settings?.userSettings?.languageId,
                  code: FORMULATION_DEFS.CAS_MASTER_FORM,
              }
            : skipToken
    );

    const canViewHazardRatings = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.FORMULATION.HAZARD_RATINGS.VIEW
    );

    const canCreateHazardRatings = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.FORMULATION.HAZARD_RATINGS.CREATE
    );

    const canUpdateHazardRatings = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.FORMULATION.HAZARD_RATINGS.EDIT
    );

    const canDeleteHazardRatings = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.FORMULATION.HAZARD_RATINGS.DELETE
    );

    const { data: unitOfMeasureOptions, isLoading: isLoadingUnitOfMeasure } =
        useListUnitOfMeasureDropdownQuery();

    const { data: hazardRatingData, isLoading } = useListHazardRatingsQuery();

    const [updateHazardRatings] = useUpdateHazardRatingsMutation();

    const [columnDefs, setColumnDefs] = useState(null as ColDef[]);

    const [unitOfMeasureColumnDefs, setUnitOfMeasureColumnDefs] = useState(
        null as ColDef[]
    );

    const [hazardRatingList, setHazardRatingList] = useState<HazardRating[]>(
        []
    );

    const [maxRowId, setMaxRowId] = useState(0);

    const { onUpdate } = useEditableBaseGrid<HazardRating[]>({
        getDescription: () => {
            return `Hazard Ratings`;
        },
        updateEntity: (hazardRatings) => {
            const postBody = hazardRatings;
            return updateHazardRatings({ postBody });
        },
    });

    const getUomCode = (uomId: bigint): SelectedDropdownOption => {
        let unitCode: SelectedDropdownOption = { id: null, display: '' };
        if (!isNilOrEmpty(uomId)) {
            unitOfMeasureOptions.find((current: any) => {
                if (current.id === uomId) {
                    unitCode = {
                        id: current.id,
                        display: current.label,
                    };
                }
            });
        }
        return unitCode;
    };

    const handleUpdate = (hazardRatings: HazardRating[]) => {
        const filteredList = hazardRatings?.filter(
            (current) =>
                current.rowStatus !== RowStatus.DELETED ||
                (current.rowStatus === RowStatus.DELETED && current.id)
        );
        if (filteredList && filteredList.length > 0) {
            filteredList.forEach((current) => {
                if (current.uomCode) {
                    current.uomId = current.uomCode?.id;
                }
            });
            onUpdate(filteredList);
        }
    };

    const handleRowValidations = (hazardRatings: HazardRating[]) => {
        const completeList = [...hazardRatings, ...hazardRatingList];
        const filteredList = completeList.filter(
            (current) => current.rowStatus !== RowStatus.DELETED
        );
        hazardRatings.forEach((current) => {
            current.validationRules = [
                ColumnRequiredValueValidator('Code', current.code),
                ColumnRequiredValueValidator(
                    'Description',
                    current.description
                ),
                UniqueColumnValueValidator(
                    filteredList,
                    current,
                    ['code'],
                    'Code must be unique'
                ),
                ColumnMaxValueValidator('Code', 10, current.code),
                ColumnMaxValueValidator('Description', 50, current.description),
            ];
            const message = current.code
                ? `Hazard Rating ${current.code}`
                : null;
            applyEditableGridValidations(current, message);
        });
        return hazardRatings;
    };

    const sortRecords = (hazardRatings: HazardRating[]) => {
        const sortedList = _.orderBy(
            hazardRatings,
            ['sortOrder', 'code'],
            ['asc']
        );
        return sortedList;
    };

    useEffect(() => {
        if (hazardRatingData && unitOfMeasureOptions) {
            let currentRowId = maxRowId;
            const list = cloneDeep(hazardRatingData).map((current) => {
                if (!current.rowId) {
                    current.rowId = ++currentRowId;
                    current.uomCode = getUomCode(current.uomId);
                }
                return current;
            });
            setMaxRowId(currentRowId);
            setHazardRatingList(sortRecords(list));
        }
    }, [hazardRatingData, isLoadingUnitOfMeasure, unitOfMeasureOptions]);

    useEffect(() => {
        if (
            !isLoadingUnitOfMeasure &&
            !isNilOrEmpty(unitOfMeasureOptions) &&
            !unitOfMeasureColumnDefs
        ) {
            setUnitOfMeasureColumnDefs([
                {
                    field: 'unitCode',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={casTermSet}
                                termKey={
                                    CasMasterFormDefs.Property_Selection_UOM
                                }
                            />
                        );
                    },
                    cellClass: 'ag-left-aligned-cell',
                },
                {
                    field: 'description',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={casTermSet}
                                termKey={
                                    CasMasterFormDefs.Property_Selection_Description
                                }
                            />
                        );
                    },
                    cellClass: 'ag-left-aligned-cell',
                },
            ] as ColDef[]);
        }
    }, [isLoadingUnitOfMeasure, unitOfMeasureOptions]);

    useEffect(() => {
        if (!columnDefs && unitOfMeasureColumnDefs) {
            setColumnDefs([
                {
                    field: 'deleteColumn',
                    minWidth: 50,
                    hide: !canDeleteHazardRatings || !canUpdateHazardRatings,
                    filter: false,
                },
                {
                    field: 'code',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={HazardRatingsGridDefs.Code}
                            />
                        );
                    },
                    editable: (params) =>
                        canCreateHazardRatings && isNilOrEmpty(params.data.id),
                    minWidth: DefaultColumnTypes.ShortText.minWidth,
                    filter: DefaultColumnTypes.ShortText.filter,
                },
                {
                    field: 'description',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={HazardRatingsGridDefs.Description}
                            />
                        );
                    },
                    editable: (params) =>
                        (canCreateHazardRatings &&
                            isNilOrEmpty(params.data.id)) ||
                        canUpdateHazardRatings,
                    minWidth: DefaultColumnTypes.LongText.minWidth,
                    filter: DefaultColumnTypes.LongText.filter,
                },
                {
                    field: 'uomCode',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={HazardRatingsGridDefs.Uom}
                            />
                        );
                    },
                    editable: (params: EditableCallbackParams) =>
                        (canCreateHazardRatings &&
                            isNilOrEmpty(params.data.id)) ||
                        canUpdateHazardRatings,
                    cellRenderer: (params: ICellRendererParams) =>
                        params.value.display,
                    useGridPopup: true,
                    gridPopupParameters: {
                        isLoading: isLoadingUnitOfMeasure,
                        displayGrid: true,
                        rowData: unitOfMeasureOptions,
                        defaultColDef: colDefOptions,
                        columnDefs: unitOfMeasureColumnDefs,
                        displayField: 'label',
                    },
                } as unknown as ColDef,
            ]);
        }
    }, [hazardRatingList, unitOfMeasureColumnDefs]);

    return {
        hazardRatingList,
        columnDefs,
        isLoading,
        isLoadingUnitOfMeasure,
        handleUpdate,
        handleRowValidations,
        termSet,
        placeHolders,
        canUpdateHazardRatings,
        canCreateHazardRatings,
        canViewHazardRatings,
    };
};

export default useHazardRatingGrid;
