import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import { PermissionsUtil } from '../../../../utils/permissions/permissionsUtil';
import { PERMISSIONS } from '../../../../constants/permissions/Permissions.constants';
import {
    useGetTermSetQuery,
    useCreateCurrencyRateTypeMutation,
    useListCurrencyRateTypeCodesQuery,
    useGetCurrencyRateTypeQuery,
    useUpdateCurrencyRateTypeMutation,
    useDeleteCurrencyRateTypeMutation,
} from '../../../../services/i18n/i18n.service';
import { ACCOUNTING_DEFS } from '../../../../constants/i18n/translations/termSetDefinitions/accounting';
import { skipToken } from '@reduxjs/toolkit/query';
import { useContext, useEffect } from 'react';
import SettingsContext from '../../../../contexts/settings.context';
import useBaseForm from '../../../form/hooks/useBaseForm';
import { RoutingValues, SelectionOption } from '../../../../types/Shared.types';
import {
    CurrencyRateType,
    CurrencyRateTypeCode,
} from '../../../../types/platform/i18n/Currency.type';
import { isNil } from '../../../../utils/objectUtils';

export const useCurrencyRateTypeForm = (id: string) => {
    const user = useSelector((state: RootState) => state.user);
    const rateTypeState = useSelector((state: RootState) => state.accounting);

    const canCreateRateType = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.ACCOUNTING.CURRENCY_RATE_TYPES.CREATE
    );

    const canUpdateRateType = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.ACCOUNTING.CURRENCY_RATE_TYPES.EDIT
    );

    const canDeleteRateType = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.ACCOUNTING.CURRENCY_RATE_TYPES.DELETE
    );

    const { settings } = useContext(SettingsContext);
    const { data: termSet } = useGetTermSetQuery(
        settings?.userSettings
            ? {
                  languageId: settings?.userSettings?.languageId,
                  code: ACCOUNTING_DEFS.CURRENCY_RATE_TYPE_FORM,
              }
            : skipToken
    );

    const { data: activeRateType, isLoading: isLoading } =
        useGetCurrencyRateTypeQuery(
            id === RoutingValues.newId ? skipToken : id
        );

    const { data: rateTypeCodes, isLoading: isLoadingRateTypeCodes } =
        useListCurrencyRateTypeCodesQuery();

    const [createRateType] = useCreateCurrencyRateTypeMutation();
    const [updateRateType] = useUpdateCurrencyRateTypeMutation();
    const [deleteRateType] = useDeleteCurrencyRateTypeMutation();

    const blankRateType: CurrencyRateType = {
        id: null,
        businessEntityId: null,
        sortOrder: rateTypeState.sortOrderSet.maxValue,
        currencyRateTypeCode: '',
        code: '',
        description: '',
        createdAt: null,
        createdBy: null,
        updatedAt: null,
        updatedBy: null,
    };

    const {
        fields,
        handleFieldChange,
        formMethods,
        setValues,
        onCreate,
        onUpdate,
        onDelete,
        closeForm,
        auditableFields,
    } = useBaseForm({
        closePath: '/accounting/currencyRateType',
        blankEntity: blankRateType,
        activeEntity: activeRateType,
        getDescription: () => {
            return `Currency Rate Type ${fields.code}`;
        },
        createEntity: () => {
            return createRateType({ postBody: fields });
        },
        updateEntity: () => {
            return updateRateType({
                id: id as unknown as number,
                postBody: fields,
            });
        },
        deleteEntity: () => {
            return deleteRateType(id);
        },
    });

    useEffect(() => {
        if (fields.code) {
            const code: CurrencyRateTypeCode = rateTypeCodes?.find(
                (optionList: SelectionOption) =>
                    optionList.value === fields.code
            )?.object;
            if (!isNil(code)) {
                setValues({
                    code: code.code,
                });
            }
        }
    }, [fields.currencyRateTypeCode]);

    return {
        fields,
        handleFieldChange,
        formMethods,
        onCreate,
        onUpdate,
        onDelete,
        closeForm,
        isLoading,
        termSet,
        canCreateRateType,
        canUpdateRateType,
        canDeleteRateType,
        rateTypeCodes,
        isLoadingRateTypeCodes,
        auditableFields,
    };
};

export default useCurrencyRateTypeForm;
