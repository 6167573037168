import { PermissionsUtil } from '../../../../utils/permissions/permissionsUtil';
import { PERMISSIONS } from '../../../../constants/permissions/Permissions.constants';
import { RootState } from '../../../../store';
import { useSelector } from 'react-redux';
import useBaseForm from '../../../form/hooks/useBaseForm';
import useTranslation from '../../../../components/i18n/hooks/useTranslation';
import { ACCOUNTING_DEFS } from '../../../../constants/i18n/translations/termSetDefinitions/accounting';
import {
    useCreatePeriodStatusMutation,
    useDeletePeriodStatusMutation,
    useGetPeriodStatusQuery,
    useUpdatePeriodStatusMutation,
} from '../../../../services/accounting/accounting.service';
import { RoutingValues } from '../../../../types/Shared.types';
import { PeriodStatus } from '../../../../types/Accounting.types';
import { skipToken } from '@reduxjs/toolkit/query';

const usePeriodStatusForm = (id: string) => {
    //translation
    const { termSet, isLoadingTermSet } = useTranslation(
        ACCOUNTING_DEFS.PERIOD_STATUS_FORM
    );

    //permissions
    const canCreate = PermissionsUtil.hasPermission(
        PERMISSIONS.ACCOUNTING.PERIOD_STATUSES.CREATE
    );
    const canUpdate = PermissionsUtil.hasPermission(
        PERMISSIONS.ACCOUNTING.PERIOD_STATUSES.EDIT
    );
    const canDelete = PermissionsUtil.hasPermission(
        PERMISSIONS.ACCOUNTING.PERIOD_STATUSES.DELETE
    );

    //request
    const { data, isLoading } = useGetPeriodStatusQuery(
        id === RoutingValues.newId ? skipToken : id
    );
    const [createMutation] = useCreatePeriodStatusMutation();
    const [updateMutation] = useUpdatePeriodStatusMutation();
    const [deleteMutation] = useDeletePeriodStatusMutation();

    //set default values
    const accountingState = useSelector((state: RootState) => state.accounting);
    const blankEntity: PeriodStatus = {
        id: null,
        code: '',
        description: '',
        sortOrder: accountingState.sortOrderSet.maxValue,
        businessEntityId: null,
        closed: false,
        createdAt: null,
        createdBy: null,
        updatedAt: null,
        updatedBy: null,
    };

    const {
        fields,
        handleFieldChange,
        formMethods,
        onCreate,
        onUpdate,
        onDelete,
        closeForm,
        auditableFields,
    } = useBaseForm({
        closePath: '/accounting/setup/periodStatus',
        blankEntity: blankEntity,
        activeEntity: data,
        getDescription: () => {
            return `Period Status ${fields.code}`;
        },
        createEntity: () => {
            return createMutation({ postBody: fields });
        },
        updateEntity: () => {
            return updateMutation({
                id: id as unknown as number,
                postBody: fields,
            });
        },
        deleteEntity: () => {
            return deleteMutation(id as unknown as number);
        },
    });

    return {
        fields,
        formMethods,
        handleFieldChange,
        onCreate,
        onUpdate,
        onDelete,
        closeForm,
        isLoadingData: isLoadingTermSet || isLoading,
        termSet,
        canCreate,
        canUpdate,
        canDelete,
        auditableFields,
    };
};

export default usePeriodStatusForm;
