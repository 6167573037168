import { useNavigate } from 'react-router-dom';
import { PermissionsUtil } from '../../../utils/permissions/permissionsUtil';
import { PERMISSIONS } from '../../../constants/permissions/Permissions.constants';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { RoutingValues } from '../../../types/Shared.types';
import React, { useContext, useEffect, useState } from 'react';
import { useGetTermSetQuery } from '../../../services/i18n/i18n.service';
import { SUPPLY_CHAIN_DEFS } from '../../../constants/i18n/translations/termSetDefinitions/supply-chain';
import { skipToken } from '@reduxjs/toolkit/query';
import SettingsContext from '../../../contexts/settings.context';
import { ColDef } from 'ag-grid-community';
import DefaultColumnTypes from '../../../components/grids/columns/Column.constants';
import CustomHeader from '../../../components/grids/CustomHeader';
import { isNilOrEmpty } from '../../../utils/objectUtils';
import { SupplierGridDefs } from '../../../constants/i18n/translations/termDefinitions/supply-chain';
import { useListSupplierQuery } from '../../../services/supplier/supplier.service';
import { auditableGridColumns } from '../../grid/utils/auditableGrid/auditableGridColumns';

const UseSupplierGrid = () => {
    const user = useSelector((state: RootState) => state.user);
    const navigate = useNavigate();

    const { data, isLoading } = useListSupplierQuery();

    const [colDefs, setColDefs] = useState([]);
    const [rowData, setRowData] = useState([]);

    const { settings } = useContext(SettingsContext);
    const { data: termSet, isLoading: isLoadingTermSet } = useGetTermSetQuery(
        settings?.userSettings
            ? {
                  languageId: settings?.userSettings?.languageId,
                  code: SUPPLY_CHAIN_DEFS.SUPPLIER_GRID,
              }
            : skipToken
    );

    const canCreate = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.SUPPLIERS.SUPPLIER.CREATE
    );
    const canView = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.SUPPLIERS.SUPPLIER.VIEW
    );

    const [hiddenIdColumns, auditableColumns] = auditableGridColumns();

    const redirectToForm = (row: any) => {
        if (row.id) {
            navigate(`/suppliers/supplier/${row.id}`);
        } else {
            navigate(`/suppliers/supplier/${RoutingValues.newId}`);
        }
    };

    useEffect(() => {
        if (!isNilOrEmpty(termSet)) {
            const typeColumnDefs: ColDef[] = [
                ...hiddenIdColumns,
                {
                    field: 'code',
                    ...DefaultColumnTypes.ShortText,
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={SupplierGridDefs.Code}
                            />
                        );
                    },
                },
                {
                    field: 'name',
                    ...DefaultColumnTypes.LongText,
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={SupplierGridDefs.Name}
                            />
                        );
                    },
                },
                {
                    field: 'supplierStatus.code',
                    ...DefaultColumnTypes.ShortText,
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={SupplierGridDefs.Status}
                            />
                        );
                    },
                },
                {
                    field: 'supplierType.code',
                    ...DefaultColumnTypes.ShortText,
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={SupplierGridDefs.Type}
                            />
                        );
                    },
                },
                {
                    field: 'taxNumber',
                    ...DefaultColumnTypes.ShortText,
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={SupplierGridDefs.TaxNumber}
                            />
                        );
                    },
                },
                {
                    field: 'website',
                    hide: true,
                    ...DefaultColumnTypes.ShortText,
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={SupplierGridDefs.Website}
                            />
                        );
                    },
                },
                {
                    field: 'currencyCode',
                    ...DefaultColumnTypes.ShortText,
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={SupplierGridDefs.CurrencyCode}
                            />
                        );
                    },
                },
                {
                    field: 'expenseAccount.number',
                    ...DefaultColumnTypes.ShortText,
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={SupplierGridDefs.ExpenseAccount}
                            />
                        );
                    },
                },
                ...auditableColumns,
            ];
            setColDefs(typeColumnDefs);
        }
    }, [termSet]);

    useEffect(() => {
        if (!isNilOrEmpty(data)) {
            setRowData(data);
        }
    }, [data]);

    return {
        rowData,
        isLoading,
        canCreate,
        canView,
        redirectToForm,
        termSet,
        colDefs,
        isLoadingTermSet,
    };
};

export default UseSupplierGrid;
