import { GridReadyEvent, ValueGetterParams } from 'ag-grid-community';
import { ColDef } from 'ag-grid-enterprise';
import React, { useContext, useEffect, useState } from 'react';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { GridOptions } from '../../../../../components/grids/Grid.constants';
import { useListFormulaQuery } from '../../../../../services/formulation/rdFormula/rdFormulaHeader/rdFormula.service';
import DefaultColumnTypes from '../../../../../components/grids/columns/Column.constants';
import { useListItemsMasterDropdownQuery } from '../../../../../services/formulation/itemMaster/item/itemMaster.service';
import { useGetUsersForDropdownQuery } from '../../../../../services/organizations/organizations.service';
import { PermissionsUtil } from '../../../../../utils/permissions/permissionsUtil';
import { PERMISSIONS } from '../../../../../constants/permissions/Permissions.constants';
import { RDFormula } from '../../../../../types/formulation';
import SettingsContext from '../../../../../contexts/settings.context';
import { useGetTermSetQuery } from '../../../../../services/i18n/i18n.service';
import { FORMULATION_DEFS } from '../../../../../constants/i18n/translations/termSetDefinitions/formulation';
import { skipToken } from '@reduxjs/toolkit/query';
import CustomHeader from '../../../../../components/grids/CustomHeader';
import { RDFormulasGridDefs } from '../../../../../constants/i18n/translations/termDefinitions/formulation';

const useRDFormulaGrid = () => {
    const navigate = useNavigate();
    const user = useSelector((state: RootStateOrAny) => state.user);
    const dispatch = useDispatch();
    const [columnDefs, setColumnDefs] = useState<ColDef[]>([]);
    const [gridApi, setGridApi] = useState(null);
    const [gridColumnApi, setGridColumnApi] = useState(null);

    const { data: formulaList, isLoading: isLoadingFormulaList } =
        useListFormulaQuery();

    const { data: itemMasterOptions, isLoading: isLoadingItemMasterOptions } =
        useListItemsMasterDropdownQuery();

    const { data: userList, isLoading: isLoadingUserOptions } =
        useGetUsersForDropdownQuery();

    const defaultColumnDefinitionOptions = {
        ...GridOptions.sortFilterAndWrapColumns,
        floatingFilter: true,
    };

    const canCreateFormula = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.FORMULATION.RD_FORMULA.CREATE
    );
    const canViewFormula = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.FORMULATION.RD_FORMULA.VIEW
    );

    const { settings } = useContext(SettingsContext);
    const { data: termSet } = useGetTermSetQuery(
        settings?.userSettings
            ? {
                  languageId: settings?.userSettings?.languageId,
                  code: FORMULATION_DEFS.RD_FORMULAS_GRID,
              }
            : skipToken
    );

    /**
     * Get the item name using the item id
     */
    const getItemById = (itemId: bigint) => {
        if (!isLoadingItemMasterOptions && itemMasterOptions && itemId) {
            const itemOption: any = itemMasterOptions.find(
                (currentItemMaster: any) => currentItemMaster.value === itemId
            );
            return itemOption?.label;
        }
        return '';
    };

    /**
     * Get the username using the user id
     */
    const getUserById = (userId: bigint) => {
        if (!isLoadingUserOptions && userList && userId) {
            const userValue: any = userList.find(
                (currentUser: any) => currentUser.value === userId
            );
            return userValue?.label;
        }
        return '';
    };

    /**
     * Create the column definitions for the grid
     */
    useEffect(() => {
        if (!isLoadingItemMasterOptions && !isLoadingUserOptions) {
            setColumnDefs([
                {
                    field: 'formulaCode',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={RDFormulasGridDefs.Formula_Code}
                            />
                        );
                    },
                    cellClass: 'ag-left-aligned-cell',
                    filter: 'agTextColumnFilter',
                    minWidth: 150,
                },
                {
                    field: 'formulaDescription',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={RDFormulasGridDefs.Formula_Description}
                            />
                        );
                    },
                    cellClass: 'ag-left-aligned-cell',
                    filter: 'agTextColumnFilter',
                    minWidth: 200,
                },
                {
                    field: 'itemId',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={RDFormulasGridDefs.Product_Code}
                            />
                        );
                    },
                    filter: 'agTextColumnFilter',
                    valueGetter: (params: ValueGetterParams) =>
                        getItemById(params.data.itemId),
                    filterParams: {
                        valueGetter: (params: ValueGetterParams) =>
                            getItemById(params.data.itemId),
                    },
                    ...DefaultColumnTypes.MediumText,
                    minWidth: 150,
                },
                {
                    field: 'formulaStatus.status',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={RDFormulasGridDefs.Formula_Status}
                            />
                        );
                    },
                    cellClass: 'ag-left-aligned-cell',
                    filter: 'agTextColumnFilter',
                    minWidth: 150,
                },
                {
                    field: 'template',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={RDFormulasGridDefs.Template}
                            />
                        );
                    },
                    cellClass: 'ag-left-aligned-cell',
                    filter: 'agTextColumnFilter',
                    minWidth: 150,
                },
                {
                    field: 'approvedByUserId',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={RDFormulasGridDefs.Approved_By}
                            />
                        );
                    },
                    cellClass: 'ag-left-aligned-cell',
                    filter: 'agTextColumnFilter',
                    valueGetter: (params: ValueGetterParams) =>
                        getUserById(params.data.approvedByUserId),
                    filterParams: {
                        valueGetter: (params: ValueGetterParams) =>
                            getUserById(params.data.approvedByUserId),
                    },
                    ...DefaultColumnTypes.MediumText,
                },
                {
                    field: 'approvedDate',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={RDFormulasGridDefs.Approved_Date}
                            />
                        );
                    },
                    filter: 'agTextColumnFilter',
                    ...DefaultColumnTypes.DateOnly,
                    minWidth: 150,
                },
            ]);
        }
    }, [
        itemMasterOptions,
        isLoadingItemMasterOptions,
        isLoadingUserOptions,
        userList,
    ]);

    const redirectToForm = (row: RDFormula) => {
        if (row && row.id) {
            navigate(`/formulation/rndFormula/${row.id}`);
        } else {
            navigate('/formulation/rndFormula/new');
        }
    };

    const onGridReady = (params: GridReadyEvent) => {
        setGridApi(params.api);
        setGridColumnApi(params.columnApi);
    };

    return {
        isLoadingFormulaList,
        columnDefs,
        redirectToForm,
        onGridReady,
        defaultColumnDefinitionOptions,
        formulaList,
        isLoadingItemMasterOptions,
        isLoadingUserOptions,
        canCreateFormula,
        canViewFormula,
        termSet,
    };
};

export default useRDFormulaGrid;
