import React from 'react';
import useSectionSupplierAddressGrid from './hooks/useSectionSupplierAddressGrid';
import BaseGrid from '../../../components/grids/BaseGrid';
import DatacorLogoSpinner from '../../../components/datacorLogoSpinner/DatacorLogoSpinner';

export interface AddressSectionGridProps {
    supplierId: string;
    createPermission: boolean;
}

const SectionSupplierAddressGrid = (props: AddressSectionGridProps) => {
    const {
        rowData,
        colDefs,
        defaultColumnDefinitionOptions,
        isLoadingData,
        redirectToForm,
    } = useSectionSupplierAddressGrid(props.supplierId);

    return (
        <React.Fragment>
            {isLoadingData() ? (
                <DatacorLogoSpinner />
            ) : (
                <BaseGrid
                    autoSize={true}
                    displayGrid={true}
                    rowData={rowData}
                    columnDefs={colDefs}
                    gridClass="full-size-item"
                    displayToolbar={true}
                    defaultColDef={defaultColumnDefinitionOptions}
                    displayResetStateButton={false}
                    displayExportDataButton={false}
                    displayCreateNewButton={props.createPermission}
                    onCreateNew={redirectToForm}
                    onRowClicked={({ data }) => redirectToForm(data)}
                />
            )}
        </React.Fragment>
    );
};

export default SectionSupplierAddressGrid;
