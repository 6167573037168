const auditableFields = {
    id: 'Id',
    businessEntityId: 'Business Entity Id',
    createdAt: 'Created At',
    createdBy: 'Created By',
    updatedAt: 'Updated At',
    updatedBy: 'Updated By',
};
export const SupplierTypeRevisionHistoryFields = {
    ...auditableFields,
    sortOrder: 'Sort Order',
    typeActive: 'Type Active',
    default: 'Default',
    emergencyResponse: 'Emergency Response',
    code: 'Code',
    description: 'Description',
};

export const SupplierRevisionHistoryFields = {
    ...auditableFields,
    status: 'Status',
    type: 'Type',
    code: 'Code',
    name: 'Name',
    taxNumber: 'Tax Number',
    website: 'Website',
    currencyCode: 'Currency Code',
    expenseAccount: 'Expense Account',
};

export const SupplierStatusRevisionHistoryFields = {
    ...auditableFields,
    sortOrder: 'Sort Order',
    code: 'Code',
    description: 'Description',
};
export const SupplierAddressStatusRevisionHistoryFields = {
    ...auditableFields,
    sortOrder: 'Sort Order',
    code: 'Code',
    description: 'Description',
    statusActive: 'Active Status',
    addressActive: 'Active Address',
    default: 'Default',
};

export const SupplierAddressTypeRevisionHistoryFields = {
    ...auditableFields,
    sortOrder: 'Sort Order',
    typeActive: 'Active',
    default: 'Default',
    main: 'Main',
    remitPayment: 'Remit Payment',
    code: 'Code',
    description: 'Description',
};

export const SUPPLIER_ADDRESS_FIELDS = {
    ...auditableFields,
    code: 'Code',
    name: 'Name',
    status: 'Status',
    type: 'Type',
    country: 'Country',
    state: 'State',
    street: 'Street',
    city: 'City',
    county: 'County',
    postalCode: 'Postal Code',
    phone: 'Phone',
    email: 'Email',
};

export const supplierApplicationRevisionHistory = {
    supplier: SupplierRevisionHistoryFields,
    'supplier-status': SupplierStatusRevisionHistoryFields,
    'supplier-type': SupplierTypeRevisionHistoryFields,
    'supplier-address-status': SupplierAddressStatusRevisionHistoryFields,
    'supplier-address-type': SupplierAddressTypeRevisionHistoryFields,
    'supplier-address': SUPPLIER_ADDRESS_FIELDS,
};
