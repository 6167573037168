import React from 'react';
import BaseFormGrid from '../../../components/grids/BaseFormGrid';
import useUnitOfMeasureConversionGrid from '../hooks/useUnitOfMeasureConversionGrid';
import { GridOptions } from '../../../components/grids/Grid.constants';
import { AgGridColumn } from 'ag-grid-react';

const UnitOfMeasureConversionGrid = () => {
    const {
        unitOfMeasureConversionList,
        redirectToForm,
        isLoadingUnitOfMeasureConversion,
        EqualsColumn,
        canCreateUnitOfMeasureConversion,
        canViewUnitOfMeasureConversion,
    } = useUnitOfMeasureConversionGrid();

    const colDefOptions = {
        ...GridOptions.sortFilterAndWrapColumns,
        floatingFilter: true,
    };
    return (
        <BaseFormGrid
            displayGrid={canViewUnitOfMeasureConversion}
            isLoading={isLoadingUnitOfMeasureConversion}
            rowData={unitOfMeasureConversionList}
            defaultColDef={colDefOptions}
            gridClass="full-size-item"
            displayToolbar={true}
            displayCreateNewButton={canCreateUnitOfMeasureConversion}
            displayExportDataButton={false}
            displayResetStateButton={false}
            onCreateNew={redirectToForm}
            onRowClicked={({ data }) => redirectToForm(data)}
            frameworkComponents={{
                equalsColumn: EqualsColumn,
            }}
            sizeColumnsToFit={true}>
            <AgGridColumn headerName="From" headerClass="grid-group-1">
                <AgGridColumn
                    field="source.unitCode"
                    headerName="Unit Code"
                    cellClass="ag-right-aligned-cell"
                />
                <AgGridColumn
                    field="source.description"
                    headerName="Description"
                    cellClass="ag-right-aligned-cell"
                />
                <AgGridColumn
                    field="sourceQuantity"
                    headerName="Quantity"
                    cellClass="ag-right-aligned-cell"
                />
            </AgGridColumn>
            <AgGridColumn headerName="=" headerClass="grid-group-3">
                <AgGridColumn
                    minWidth={100}
                    filter={false}
                    resizable={false}
                    sortable={false}
                    field="equalsColumn"
                    headerName=""
                    cellRenderer="equalsColumn"
                    cellClass="ag-right-aligned-cell"
                />
            </AgGridColumn>
            <AgGridColumn headerName="To" headerClass="grid-group-2">
                <AgGridColumn
                    field="destination.unitCode"
                    headerName="Unit Code"
                    cellClass="ag-right-aligned-cell"
                />
                <AgGridColumn
                    field="destination.description"
                    headerName="Description"
                    cellClass="ag-right-aligned-cell"
                />
                <AgGridColumn
                    field="destinationQuantity"
                    headerName="Quantity"
                    cellClass="ag-right-aligned-cell"
                />
            </AgGridColumn>
        </BaseFormGrid>
    );
};

export default UnitOfMeasureConversionGrid;
