import React, { useContext, useEffect, useState } from 'react';
import { isNilOrEmpty } from '../../../../../../../../../utils/objectUtils';
import { ValueGetterParams } from 'ag-grid-community';
import { GridOptions } from '../../../../../../../../../components/grids/Grid.constants';
import { PermissionsUtil } from '../../../../../../../../../utils/permissions/permissionsUtil';
import { PERMISSIONS } from '../../../../../../../../../constants/permissions/Permissions.constants';
import {
    CasMaster,
    ExposureAgency,
    SdsExposure,
    SdsHeader,
} from '../../../../../../../../../types/formulation';
import _ from 'lodash';
import DefaultColumnTypes from '../../../../../../../../../components/grids/columns/Column.constants';
import { RowStatus } from '../../../../../../../../../components/grids/hooks/useBaseGridEditable';
import { useListExposureAgencyQuery } from '../../../../../../../../../services/formulation/exposureAgency/exposureAgency.service';
import { useListCasQuery } from '../../../../../../../../../services/formulation/casMaster/cas/casMaster.service';
import { ColumnRequiredValueValidator } from '../../../../../../../../grid/validators/columnRequiredValue.validator';
import { ColumnMaxValueValidator } from '../../../../../../../../grid/validators/columnMaxValue.validator';
import applyEditableGridValidations from '../../../../../../../../grid/utils/applyEditableGridValidations';
import { SelectedDropdownOption } from '../../../../../../../../../types/Shared.types';
import SettingsContext from '../../../../../../../../../contexts/settings.context';
import { useGetTermSetQuery } from '../../../../../../../../../services/i18n/i18n.service';
import { FORMULATION_DEFS } from '../../../../../../../../../constants/i18n/translations/termSetDefinitions/formulation';
import { skipToken } from '@reduxjs/toolkit/query';
import CustomHeader from '../../../../../../../../../components/grids/CustomHeader';
import { SDSFormDefs } from '../../../../../../../../../constants/i18n/translations/termDefinitions/formulation';
import { ChildEditableGridWithCopyFunctionProps } from '../../../../../../../../grid/utils/editableGrid/ChildEditableGridWithCopyFunctionProps';

const useSdsExposureGrid = (
    props: ChildEditableGridWithCopyFunctionProps<SdsHeader, SdsExposure>
) => {
    const { currentParentRecord, user } = props.parentData;

    const { showDeleteButton, isGridEditable } = props.displayGridButtons;

    const { handleChildrenRecords } = props.helpers;

    const canViewCas = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.FORMULATION.CAS_MASTER.VIEW
    );

    const { data: casOptions, isLoading: isLoadingCas } = useListCasQuery(
        user?.businessEntity?.id.toString()
    );

    const canViewExposureAgency = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.FORMULATION.EXPOSURE_AGENCIES.VIEW
    );

    const {
        data: exposureAgencyOptions,
        isLoading: isLoadingExposureAgencies,
    } = useListExposureAgencyQuery(user?.businessEntity?.id.toString());

    const { rowsData, setRowsData } = props.copyMethods;

    const [sdsExposuresColumnDefs, setSdsExposuresColumnDefs] = useState(null);

    const [exposureAgencyColumnDefs, setExposureAgencyColumnDefs] =
        useState(null);

    const [maxRowId, setMaxRowId] = useState(0);

    const colDefOptions = {
        ...GridOptions.sortFilterAndWrapColumns,
        floatingFilter: true,
    };

    const { settings } = useContext(SettingsContext);
    const { data: termSet } = useGetTermSetQuery(
        settings?.userSettings
            ? {
                  languageId: settings?.userSettings?.languageId,
                  code: FORMULATION_DEFS.SDS_FORM,
              }
            : skipToken
    );

    const [casColumnDefs, setCasColumnDefs] = useState(null);

    const getExposureAgencyCode = (
        exposureAgencyId: bigint
    ): SelectedDropdownOption => {
        let exposureAgencyCode: SelectedDropdownOption = {
            id: null,
            display: '',
        };
        if (!isNilOrEmpty(exposureAgencyId)) {
            exposureAgencyOptions?.find((current: any) => {
                if (current.id === exposureAgencyId) {
                    exposureAgencyCode = {
                        id: current.id,
                        display: current.code,
                    };
                }
            });
        }
        return exposureAgencyCode;
    };

    const getExposureDescription = (
        id: bigint,
        options: ExposureAgency[]
    ): string => {
        let description: string;
        options?.find((current: any) => {
            if (current.id === id) {
                description = current.description;
            }
        });
        return description;
    };

    const getCasNumber = (casId: any): SelectedDropdownOption => {
        let casNumber: SelectedDropdownOption = { id: null, display: '' };
        if (!isNilOrEmpty(casId)) {
            casOptions?.find((current: CasMaster) => {
                if (current.id === casId) {
                    casNumber = {
                        id: current.id,
                        display: current.identifier,
                    };
                }
            });
        }
        return casNumber;
    };

    const getCasDetails = (id: bigint, options: CasMaster[]) => {
        let detailRecord: CasMaster;
        options?.find((current: CasMaster) => {
            if (current.id === id) {
                detailRecord = current;
            }
        });
        return detailRecord;
    };

    useEffect(() => {
        const includeEditedRows = currentParentRecord?.sdsExposures
            ? !isNilOrEmpty(
                  currentParentRecord?.sdsExposures.find(
                      (current) =>
                          current.rowStatus &&
                          (current?.autoPopulated
                              ? !current?.autoPopulated
                              : true)
                  )
              )
            : false;
        if (
            currentParentRecord?.sdsExposures &&
            !isLoadingExposureAgencies &&
            !isNilOrEmpty(exposureAgencyOptions) &&
            !isLoadingCas &&
            !isNilOrEmpty(casOptions) &&
            !includeEditedRows
        ) {
            const sdsExposureRows: SdsExposure[] = [];
            let index = maxRowId;
            currentParentRecord?.sdsExposures.forEach(
                (sdsExposure: SdsExposure) => {
                    index = index + 1;
                    setMaxRowId((maxRowId) => maxRowId + 1);
                    if (sdsExposure.rowStatus !== RowStatus.DELETED) {
                        const exposureRecord: SdsExposure = {
                            ...sdsExposure,
                            casNumber: getCasNumber(sdsExposure.casId),
                            exposureAgencyCode: getExposureAgencyCode(
                                sdsExposure.exposureAgencyId
                            ),
                            rowId: sdsExposure.rowId
                                ? sdsExposure.rowId
                                : index,
                        };
                        sdsExposureRows.push(exposureRecord);
                    }
                }
            );

            sortSdsExposures(sdsExposureRows);
        }
    }, [
        currentParentRecord?.sdsExposures,
        isLoadingExposureAgencies,
        exposureAgencyOptions,
        isLoadingCas,
        casOptions,
    ]);

    const sortSdsExposures = (sdsExposuresList: SdsExposure[]) => {
        setRowsData(_.orderBy(sdsExposuresList, ['sortOrder', 'id'], ['asc']));
    };

    useEffect(() => {
        if (!isLoadingCas) {
            setCasColumnDefs([
                {
                    field: 'identifier',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={
                                    SDSFormDefs.Control_Parameters_CAS_Selection_CAS_No
                                }
                            />
                        );
                    },
                    cellClass: 'ag-left-aligned-cell',
                    filter: 'agTextColumnFilter',
                },
                {
                    field: 'chemicalName',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={
                                    SDSFormDefs.Control_Parameters_CAS_Selection_Chemical_Name
                                }
                            />
                        );
                    },
                    cellClass: 'ag-left-aligned-cell',
                    filter: 'agTextColumnFilter',
                },
                {
                    field: 'ecIdentifier',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={
                                    SDSFormDefs.Control_Parameters_CAS_Selection_EC_No
                                }
                            />
                        );
                    },
                    cellClass: 'ag-left-aligned-cell',
                    filter: 'agTextColumnFilter',
                },
                {
                    field: 'reach',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={
                                    SDSFormDefs.Control_Parameters_CAS_Selection_Reach_No
                                }
                            />
                        );
                    },
                    cellClass: 'ag-left-aligned-cell',
                    filter: 'agTextColumnFilter',
                },
                {
                    field: 'classification',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={
                                    SDSFormDefs.Control_Parameters_CAS_Selection_Classification
                                }
                            />
                        );
                    },
                    cellClass: 'ag-left-aligned-cell',
                    filter: 'agTextColumnFilter',
                },
                {
                    field: 'molecularFormula',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={
                                    SDSFormDefs.Control_Parameters_CAS_Selection_Molecular_Formula
                                }
                            />
                        );
                    },
                    cellClass: 'ag-left-aligned-cell',
                    filter: 'agTextColumnFilter',
                },
            ]);
        }
    }, [isLoadingCas, casOptions]);

    useEffect(() => {
        if (
            !isLoadingExposureAgencies &&
            !isNilOrEmpty(exposureAgencyOptions)
        ) {
            setExposureAgencyColumnDefs([
                {
                    field: 'code',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={
                                    SDSFormDefs.Control_Parameters_Exposure_Agency_Selection_Exposure_Agency_Code
                                }
                            />
                        );
                    },
                    cellClass: 'ag-left-aligned-cell',
                    filter: 'agTextColumnFilter',
                },
                {
                    field: 'description',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={
                                    SDSFormDefs.Control_Parameters_Exposure_Agency_Selection_Description
                                }
                            />
                        );
                    },
                    cellClass: 'ag-left-aligned-cell',
                    filter: 'agTextColumnFilter',
                },
            ]);
        }
    }, [isLoadingExposureAgencies, exposureAgencyOptions]);

    useEffect(() => {
        if (
            !isNilOrEmpty(exposureAgencyColumnDefs) &&
            !isLoadingExposureAgencies &&
            !isNilOrEmpty(exposureAgencyOptions) &&
            !isLoadingCas &&
            !isNilOrEmpty(casColumnDefs)
        ) {
            setSdsExposuresColumnDefs([
                {
                    field: 'deleteColumn',
                    minWidth: 50,
                    hide: !showDeleteButton || !isGridEditable,
                    filter: false,
                },
                {
                    field: 'casNumber',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={SDSFormDefs.Control_Parameters_CAS_No}
                            />
                        );
                    },
                    minWidth: 200,
                    editable: canViewCas && isGridEditable,
                    cellRenderer: (params: any) => params.value.display,
                    useGridPopup: true,
                    filter: 'agTextColumnFilter',
                    gridPopupParameters: {
                        isLoading: isLoadingCas,
                        displayGrid: true,
                        rowData: canViewCas ? casOptions : [],
                        defaultColDef: colDefOptions,
                        columnDefs: casColumnDefs,
                        displayField: 'identifier',
                    },
                },
                {
                    field: 'casDescription',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={
                                    SDSFormDefs.Control_Parameters_Chemical_Name
                                }
                            />
                        );
                    },
                    minWidth: 200,
                    filter: 'agTextColumnFilter',
                    editable: false,
                    valueGetter: (params: ValueGetterParams) => {
                        const details = getCasDetails(
                            params.data.casNumber?.id,
                            casOptions
                        );
                        return details?.chemicalName;
                    },
                },
                {
                    field: 'exposureAgencyCode',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={
                                    SDSFormDefs.Control_Parameters_Exposure_Agency_Code
                                }
                            />
                        );
                    },
                    minWidth: 300,
                    editable: canViewExposureAgency && isGridEditable,
                    cellRenderer: (params: any) => params.value.display,
                    useGridPopup: true,
                    gridPopupParameters: {
                        isLoading: isLoadingExposureAgencies,
                        displayGrid: true,
                        rowData: canViewExposureAgency
                            ? exposureAgencyOptions
                            : [],
                        defaultColDef: colDefOptions,
                        columnDefs: exposureAgencyColumnDefs,
                        displayField: 'code',
                    },
                    ...GridOptions.sortFilterAndWrapColumns,
                },
                {
                    field: 'exposureAgencyDescription',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={
                                    SDSFormDefs.Control_Parameters_Exposure_Agencies_Description
                                }
                            />
                        );
                    },
                    minWidth: 300,
                    filter: 'agTextColumnFilter',
                    editable: false,
                    valueGetter: (params: ValueGetterParams) => {
                        params.data.description = getExposureDescription(
                            params.data.exposureAgencyCode?.id,
                            exposureAgencyOptions
                        );
                        return params.data.description;
                    },
                },
                {
                    field: 'exposureValue',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={
                                    SDSFormDefs.Control_Parameters_Exposure_Value
                                }
                            />
                        );
                    },
                    minWidth: 200,
                    filter: 'agTextColumnFilter',
                    editable: isGridEditable,
                },
                {
                    field: 'exposureForm',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={
                                    SDSFormDefs.Control_Parameters_Exposure_Form
                                }
                            />
                        );
                    },
                    minWidth: 200,
                    filter: 'agTextColumnFilter',
                    editable: isGridEditable,
                },
                {
                    field: 'notes',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={
                                    SDSFormDefs.Control_Parameters_Exposure_Notes
                                }
                            />
                        );
                    },
                    filter: 'agTextColumnFilter',
                    editable: isGridEditable,
                    ...DefaultColumnTypes.LargeTextEditor,
                    cellEditorParams: {
                        ...DefaultColumnTypes.LargeTextEditor.cellEditorParams,
                        maxLength: 50,
                    },
                    minWidth: 200,
                },
            ]);
        }
    }, [
        exposureAgencyColumnDefs,
        isLoadingExposureAgencies,
        exposureAgencyOptions,
        isLoadingCas,
        casColumnDefs,
    ]);

    const handleRowValidations = (editedRows: SdsExposure[]) => {
        editedRows?.forEach((row: SdsExposure) => {
            row.validationRules = [
                ColumnRequiredValueValidator(
                    'Exposure Agency Code',
                    row.exposureAgencyCode
                ),
                ColumnMaxValueValidator(
                    'Exposure Value',
                    30,
                    row.exposureValue
                ),
                ColumnMaxValueValidator('Exposure Notes', 50, row.notes),
                ColumnMaxValueValidator('Exposure Form', 30, row.exposureForm),
            ];
            const message = `Control Parameter ${row.casNumber.display}`;
            applyEditableGridValidations(row, message);
        });
        return editedRows;
    };

    const handleGridEdits = (editedRows: SdsExposure[]) => {
        const updatedFields: SdsExposure[] = [...rowsData];

        editedRows?.forEach((row: SdsExposure) => {
            if (
                !isNilOrEmpty(row.casNumber) &&
                !isNilOrEmpty(row.casNumber?.id)
            ) {
                row.casId = row.casNumber?.id;
            }

            if (
                !isNilOrEmpty(row.exposureAgencyCode) &&
                !isNilOrEmpty(row.exposureAgencyCode?.id)
            ) {
                row.exposureAgencyId = row.exposureAgencyCode?.id;
            }

            const index = rowsData.findIndex(
                (sdsExposure: SdsExposure) => sdsExposure.rowId === row.rowId
            );

            if (index > -1) {
                updatedFields[index] = row;
            } else {
                updatedFields.push(row);
            }
            sortSdsExposures(updatedFields);
            handleChildrenRecords('sdsExposures', updatedFields);
        });
    };

    return {
        isLoadingExposureAgencies,
        sdsExposuresColumnDefs,
        rowsData,
        handleGridEdits,
        handleRowValidations,
        colDefOptions,
        termSet,
    };
};

export default useSdsExposureGrid;
