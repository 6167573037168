import { isNilOrEmpty } from '../../../utils/objectUtils';

export const ColumnNumericValueValidator = (
    columnLabel: string,
    numDecimals: number,
    columnData: number | string
) => {
    let isValid = true;
    let getErrorMessage = '';
    const currentData = columnData;

    if (!isNilOrEmpty(columnLabel) && !isNilOrEmpty(currentData)) {
        if (numDecimals > 0) {
            //validate if the value is numeric (allowing any number of decimals)
            isValid = RegExp(`^$|^-?\\d+(?:[\\.]\\d+)?$`, 'g').test(
                String(currentData).trim()
            );
        } else {
            //not specifying the number of decimals will be treated as zero decimals
            isValid = new RegExp(`^-?\\d+$`, 'g').test(
                String(currentData).trim()
            );
        }

        if (!isValid) {
            getErrorMessage = `${columnLabel} should be numeric`;
        } else {
            //validate the number of decimals
            if (numDecimals > 0) {
                if (!Number.isInteger(currentData)) {
                    const numberPair = currentData.toString().split('.');
                    if ((numberPair[1]?.length || 0) > numDecimals) {
                        isValid = false;
                        getErrorMessage = `${columnLabel} can have upto ${numDecimals} decimals`;
                    }
                }
            }
        }
    }

    return {
        getErrorMessage,
        isValid,
    };
};
