import React from 'react';
import { Grid, Tab } from '@mui/material';
import { useParams } from 'react-router-dom';
import FormPartial from '../../../components/form/formContainer/FormPartial';
import TextInput from '../../../components/form/formInputs/TextInput/TextInput';
import useFixedAssetForm from './hooks/useFixedAssetForm';
import DateInput from '../../../components/form/formInputs/DateInput/DateInput';
import SelectInput from '../../../components/form/formInputs/SelectInput/SelectInput';
import BaseForm, {
    ParamTypes,
} from '../../../components/form/baseForm/BaseForm';
import Box from '@mui/material/Box';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { RoutingValues } from '../../../types/Shared.types';
import { FIXED_ASSET_FIELDS } from '../../../constants/revisionHistory/revisionHistory.constants';
import CostGrid from './sections/CostGrid';
import ContractGrid from './sections/ContractGrid';
import DisposalEntryGrid from './sections/DisposalEntryGrid';
import DepreciationEntryGrid from './sections/DepreciationEntryGrid';
import AssetEntryGrid from './sections/AssetEntryGrid';
import DepreciationSchedule from './sections/DepreciationSchedule';
import { isNilOrEmpty } from '../../../utils/objectUtils';
import { FixedAssetRegisterFormDefs } from '../../../constants/i18n/translations/termDefinitions/accounting';
import TranslatableText from '../../../components/i18n/TranslatableText';

const FixedAssetForm = () => {
    const { id } = useParams<ParamTypes>();
    const {
        fields,
        handleFieldChange,
        formMethods,
        onCreate,
        onUpdate,
        closeForm,
        copyForm,
        bookHeader,
        taxHeader,
        isLoadingAsset,
        isLoadingStatuses,
        isLoadingTypes,
        isLoadingBuildings,
        statusOptions,
        buildingOptions,
        bookAssetTypes,
        taxAssetTypes,
        currentTab,
        setCurrentTab,
        onAssetTypeChanged,
        canCreateAssetRecords,
        canUpdateAssetRecords,
        hasLedgerDepreciationSchedule,
        hasTaxDepreciationSchedule,
        activeAsset,
        refCostGrid,
        refContractGrid,
        refScheduleDepreciationGrid,
        termSet,
        formType,
    } = useFixedAssetForm(id);

    return (
        <BaseForm
            title={
                <>
                    <TranslatableText
                        termKey={FixedAssetRegisterFormDefs.FixedAssetCode}
                        termSet={termSet}
                    />{' '}
                    {fields?.code}
                </>
            }
            formMethods={formMethods}
            isLoading={isLoadingAsset}
            isNew={
                id === RoutingValues.newId || formType === RoutingValues.copy
            }
            onCreate={onCreate}
            onUpdate={onUpdate}
            onCopy={copyForm}
            onCancel={closeForm}
            showOnRevision={true}
            fieldMappings={FIXED_ASSET_FIELDS}
            entityUuid={fields?.uuid}
            displayCreateButton={canCreateAssetRecords}
            displayUpdateButton={canUpdateAssetRecords}
            displayCopyButton={canCreateAssetRecords}
            displayDeleteButton={false}>
            <FormPartial>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <TextInput
                            fullWidth
                            label={
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={FixedAssetRegisterFormDefs.Code}
                                />
                            }
                            id="code"
                            value={fields.code}
                            onChange={handleFieldChange}
                            formMethods={formMethods}
                            required={true}
                            maxLength={30}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextInput
                            fullWidth
                            label={
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={FixedAssetRegisterFormDefs.Name}
                                />
                            }
                            id="name"
                            value={fields.name}
                            onChange={handleFieldChange}
                            formMethods={formMethods}
                            maxLength={90}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextInput
                            fullWidth
                            label={
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={
                                        FixedAssetRegisterFormDefs.Description
                                    }
                                />
                            }
                            id="description"
                            value={fields.description}
                            onChange={handleFieldChange}
                            formMethods={formMethods}
                            maxLength={255}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <SelectInput
                            fullWidth
                            label={
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={FixedAssetRegisterFormDefs.Status}
                                />
                            }
                            id="statusId"
                            value={fields.statusId}
                            onChange={handleFieldChange}
                            formMethods={formMethods}
                            options={statusOptions}
                            disabled={isLoadingStatuses}
                            required={true}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <DateInput
                            label={
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={
                                        FixedAssetRegisterFormDefs.AcquireDate
                                    }
                                />
                            }
                            id="acquireDate"
                            value={fields.acquireDate}
                            onChange={handleFieldChange}
                            formMethods={formMethods}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <DateInput
                            label={
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={
                                        FixedAssetRegisterFormDefs.InServiceDate
                                    }
                                />
                            }
                            id="inServiceDate"
                            value={fields.inServiceDate}
                            onChange={handleFieldChange}
                            formMethods={formMethods}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <DateInput
                            label={
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={
                                        FixedAssetRegisterFormDefs.DisposalDate
                                    }
                                />
                            }
                            id="disposalDate"
                            disabled={true}
                            value={fields.disposalDate}
                            onChange={handleFieldChange}
                            formMethods={formMethods}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <SelectInput
                            fullWidth
                            label={
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={
                                        FixedAssetRegisterFormDefs.AssetTypeBook
                                    }
                                />
                            }
                            id="ledgerTypeId"
                            value={fields.ledgerTypeId}
                            onChange={onAssetTypeChanged}
                            formMethods={formMethods}
                            options={bookAssetTypes}
                            disabled={
                                hasLedgerDepreciationSchedule || isLoadingTypes
                            }
                            required={true}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <SelectInput
                            fullWidth
                            label={
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={
                                        FixedAssetRegisterFormDefs.AssetTypeTax
                                    }
                                />
                            }
                            id="taxTypeId"
                            value={fields.taxTypeId}
                            onChange={onAssetTypeChanged}
                            formMethods={formMethods}
                            options={taxAssetTypes}
                            disabled={
                                hasTaxDepreciationSchedule || isLoadingTypes
                            }
                            required={true}
                        />
                    </Grid>
                </Grid>
            </FormPartial>
            <FormPartial
                title={
                    (
                        <TranslatableText
                            termSet={termSet}
                            termKey={FixedAssetRegisterFormDefs.References}
                        />
                    ) as unknown as string
                }>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <SelectInput
                            fullWidth
                            label={
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={
                                        FixedAssetRegisterFormDefs.Building
                                    }
                                />
                            }
                            id="buildingId"
                            value={fields.buildingId}
                            onChange={handleFieldChange}
                            formMethods={formMethods}
                            options={buildingOptions}
                            disabled={isLoadingBuildings}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextInput
                            fullWidth
                            label={
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={
                                        FixedAssetRegisterFormDefs.ManufacturerSerial
                                    }
                                />
                            }
                            id="manufacturerSerialNumber"
                            value={fields.manufacturerSerialNumber}
                            onChange={handleFieldChange}
                            formMethods={formMethods}
                            maxLength={90}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextInput
                            fullWidth
                            label={
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={
                                        FixedAssetRegisterFormDefs.PurchaseOrder
                                    }
                                />
                            }
                            id="purchaseOrderNumber"
                            value={fields.purchaseOrderNumber}
                            onChange={handleFieldChange}
                            formMethods={formMethods}
                            maxLength={30}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextInput
                            fullWidth
                            label={
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={
                                        FixedAssetRegisterFormDefs.SupplierInvoice
                                    }
                                />
                            }
                            id="supplierInvoiceNumber"
                            value={fields.supplierInvoiceNumber}
                            onChange={handleFieldChange}
                            formMethods={formMethods}
                            maxLength={30}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextInput
                            fullWidth
                            label={
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={
                                        FixedAssetRegisterFormDefs.CapitalProject
                                    }
                                />
                            }
                            id="capitalProjectNumber"
                            value={fields.capitalProjectNumber}
                            onChange={handleFieldChange}
                            formMethods={formMethods}
                            maxLength={30}
                        />
                    </Grid>
                </Grid>
            </FormPartial>
            <FormPartial
                title={
                    (
                        <TranslatableText
                            termSet={termSet}
                            termKey={FixedAssetRegisterFormDefs.Costs}
                        />
                    ) as unknown as string
                }>
                <CostGrid
                    ref={refCostGrid}
                    assetId={activeAsset?.id}
                    costs={activeAsset?.costs}
                    totalCost={activeAsset?.totalCost}
                />
            </FormPartial>
            <FormPartial
                title={
                    (
                        <TranslatableText
                            termSet={termSet}
                            termKey={FixedAssetRegisterFormDefs.Contracts}
                        />
                    ) as unknown as string
                }>
                <ContractGrid
                    ref={refContractGrid}
                    contracts={activeAsset?.contractList || []}
                />
            </FormPartial>
            <FormPartial
                title={
                    (
                        <TranslatableText
                            termSet={termSet}
                            termKey={
                                FixedAssetRegisterFormDefs.SchedulesAndEntries
                            }
                        />
                    ) as unknown as string
                }
                isVisible={id !== RoutingValues.newId}>
                <TabContext value={currentTab}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList
                            onChange={(event, value: string) =>
                                setCurrentTab(value)
                            }>
                            <Tab
                                label={
                                    <TranslatableText
                                        termSet={termSet}
                                        termKey={
                                            FixedAssetRegisterFormDefs.DepreciationSchedules
                                        }
                                    />
                                }
                                value="0"
                            />
                            <Tab
                                label={
                                    <TranslatableText
                                        termSet={termSet}
                                        termKey={
                                            FixedAssetRegisterFormDefs.DepreciationEntries
                                        }
                                    />
                                }
                                value="1"
                            />
                            <Tab
                                label={
                                    <TranslatableText
                                        termSet={termSet}
                                        termKey={
                                            FixedAssetRegisterFormDefs.DisposalEntry
                                        }
                                    />
                                }
                                value="2"
                            />
                            <Tab
                                label={
                                    <TranslatableText
                                        termSet={termSet}
                                        termKey={
                                            FixedAssetRegisterFormDefs.AssetEntry
                                        }
                                    />
                                }
                                value="3"
                            />
                        </TabList>
                    </Box>
                    <TabPanel value="0">
                        <Grid container spacing={2}>
                            <DepreciationSchedule
                                ref={refScheduleDepreciationGrid}
                                assetId={activeAsset?.id}
                                headerName={{
                                    book: bookHeader.current,
                                    tax: taxHeader.current,
                                }}
                                ledgerDepreciationSchedule={
                                    activeAsset?.ledgerDepreciationSchedule ||
                                    []
                                }
                                taxDepreciationSchedule={
                                    activeAsset?.taxDepreciationSchedule || []
                                }
                                totalPercentage={{
                                    ledgerTotalPercentage:
                                        activeAsset?.ledgerTotalPercentage,
                                    taxTotalPercentage:
                                        activeAsset?.taxTotalPercentage,
                                }}
                                inServiceDate={activeAsset?.inServiceDate}
                            />
                        </Grid>
                    </TabPanel>
                    <TabPanel value="1">
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <DepreciationEntryGrid
                                    entries={activeAsset?.depreciationEntries}
                                    disable={isNilOrEmpty(
                                        activeAsset?.ledgerDepreciationSchedule
                                    )}
                                    fixedAssetId={id}
                                    entriesTotals={
                                        activeAsset?.depreciationEntriesTotals
                                    }
                                />
                            </Grid>
                        </Grid>
                    </TabPanel>
                    <TabPanel value="2">
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <DisposalEntryGrid
                                    assetId={activeAsset?.id}
                                    disposalEntries={
                                        activeAsset?.disposalEntries || []
                                    }
                                    disposalEntriesTotals={
                                        activeAsset?.disposalEntriesTotals
                                    }
                                />
                            </Grid>
                        </Grid>
                    </TabPanel>
                    <TabPanel value="3">
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <AssetEntryGrid
                                    entries={activeAsset?.assetEntries}
                                    fixedAssetId={id}
                                />
                            </Grid>
                        </Grid>
                    </TabPanel>
                </TabContext>
            </FormPartial>
        </BaseForm>
    );
};

export default FixedAssetForm;
