import React, { useContext } from 'react';
import { isFeatureFlagEnabled } from '../../../utils/featureFlag/featureFlagUtil';
import FormPartial from '../../../components/form/formContainer/FormPartial';
import { Card, CardContent, CardHeader, Grid } from '@mui/material';
import SelectInput from '../../../components/form/formInputs/SelectInput/SelectInput';
import SettingsContext from '../../../contexts/settings.context';
import TextInput from '../../../components/form/formInputs/TextInput/TextInput';
import BaseFormToolbar from '../../../components/form/baseForm/BaseFormToolbar';
import useSettings from '../hooks/useSettings';
import {
    useGetCountriesForSettingsQuery,
    useGetStatesForDropdownQuery,
} from '../../../services/organizations/organizations.service';
import { PLATFORM_DEFS } from '../../../constants/i18n/translations/termSetDefinitions/platform';
import TranslatableText from '../../../components/i18n/TranslatableText';
import { BusinessEntitySettingDefs } from '../../../constants/i18n/translations/termDefinitions/platform';
import { useListCurrencyCodesQuery } from '../../../services/i18n/i18n.service';

const BusinessEntitySettings = () => {
    const {
        handleChange,
        handleSaveSettings,
        navigate,
        user,
        sharedTermSet,
        termSet,
    } = useSettings({ termSetCode: PLATFORM_DEFS.BUSINESS_ENTITY_SETTINGS });

    const { settings } = useContext(SettingsContext);

    const { data: countries } = useGetCountriesForSettingsQuery();
    const { data: states } = useGetStatesForDropdownQuery();
    const { data: currencyCodes } = useListCurrencyCodesQuery();

    return (
        <Card>
            <CardHeader
                sx={{ paddingLeft: '0px', paddingRight: '0px' }}
                title={
                    <>
                        <BaseFormToolbar
                            isNew={false}
                            onCancel={() => navigate(-1)}
                            displayUpdateButton={true}
                            onUpdate={handleSaveSettings}
                            termSet={sharedTermSet}
                        />
                    </>
                }
            />
            <CardContent>
                {isFeatureFlagEnabled(
                    user?.enabledFeatureFlags,
                    'settings.entitySettings.businessEntitySettings'
                ) && (
                    <>
                        <FormPartial
                            title={
                                (
                                    <TranslatableText
                                        termKey={
                                            BusinessEntitySettingDefs.TITLE
                                        }
                                        termSet={termSet}
                                    />
                                ) as unknown as string
                            }>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <SelectInput
                                        id={'defaultLanguage'}
                                        onChange={(event: any) =>
                                            handleChange(
                                                event,
                                                'businessEntitySettings'
                                            )
                                        }
                                        fullWidth
                                        value={
                                            settings.businessEntitySettings
                                                ?.defaultLanguage
                                        }
                                        label={
                                            <TranslatableText
                                                termKey={
                                                    BusinessEntitySettingDefs.DEFAULT_LANGUAGE
                                                }
                                                termSet={termSet}
                                            />
                                        }
                                        options={[
                                            { label: 'English', value: 'eng' },
                                            { label: 'Spanish', value: 'esp' },
                                        ]}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <SelectInput
                                        id={'timeZone'}
                                        onChange={(event: any) =>
                                            handleChange(
                                                event,
                                                'businessEntitySettings'
                                            )
                                        }
                                        fullWidth
                                        value={
                                            settings.businessEntitySettings
                                                ?.timeZone
                                        }
                                        label={
                                            <TranslatableText
                                                termKey={
                                                    BusinessEntitySettingDefs.TIME_ZONE
                                                }
                                                termSet={termSet}
                                            />
                                        }
                                        options={[
                                            { label: 'EST', value: 'est' },
                                            { label: 'PST', value: 'pst' },
                                        ]}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <SelectInput
                                        id={'dateFormat'}
                                        onChange={(event: any) =>
                                            handleChange(
                                                event,
                                                'businessEntitySettings'
                                            )
                                        }
                                        fullWidth
                                        value={
                                            settings.businessEntitySettings
                                                ?.dateFormat
                                        }
                                        label={
                                            <TranslatableText
                                                termKey={
                                                    BusinessEntitySettingDefs.DATE_FORMAT
                                                }
                                                termSet={termSet}
                                            />
                                        }
                                        options={[
                                            {
                                                label: 'MM/DD/YYYY',
                                                value: 'MM/DD/YYYY',
                                            },
                                            {
                                                label: 'YYYY/MM/DD',
                                                value: 'YYYY/MM/DD',
                                            },
                                        ]}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <SelectInput
                                        id={'numberFormat'}
                                        onChange={(event: any) =>
                                            handleChange(
                                                event,
                                                'businessEntitySettings'
                                            )
                                        }
                                        fullWidth
                                        value={
                                            settings.businessEntitySettings
                                                ?.numberFormat
                                        }
                                        label={
                                            <TranslatableText
                                                termKey={
                                                    BusinessEntitySettingDefs.NUMBER_FORMAT
                                                }
                                                termSet={termSet}
                                            />
                                        }
                                        options={[
                                            { label: '0.00', value: '0.00' },
                                            { label: '0,00', value: '0,00' },
                                        ]}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <SelectInput
                                        id={'displayPreference'}
                                        onChange={(event: any) =>
                                            handleChange(
                                                event,
                                                'businessEntitySettings'
                                            )
                                        }
                                        fullWidth
                                        value={
                                            settings.businessEntitySettings
                                                ?.displayPreference
                                        }
                                        label={
                                            <TranslatableText
                                                termKey={
                                                    BusinessEntitySettingDefs.DISPLAY_PREFERENCE
                                                }
                                                termSet={termSet}
                                            />
                                        }
                                        options={[
                                            {
                                                label: 'First Last',
                                                value: 'firstLast',
                                            },
                                            {
                                                label: 'Preferred Last',
                                                value: 'prefLast',
                                            },
                                            {
                                                label: 'Last, First',
                                                value: 'lastFirst',
                                            },
                                            {
                                                label: 'Prefix First Middle Last Suffix, “Preferred"',
                                                value: 'prefixFMLSuffix',
                                            },
                                        ]}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextInput
                                        id={'erpStartDate'}
                                        onChange={(event: any) =>
                                            handleChange(
                                                event,
                                                'businessEntitySettings'
                                            )
                                        }
                                        fullWidth
                                        value={
                                            settings.businessEntitySettings
                                                ?.erpStartDate
                                        }
                                        label={
                                            <TranslatableText
                                                termKey={
                                                    BusinessEntitySettingDefs.ERP_START
                                                }
                                                termSet={termSet}
                                            />
                                        }
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <SelectInput
                                        id={'defaultCountry'}
                                        onChange={(event: any) =>
                                            handleChange(
                                                event,
                                                'businessEntitySettings'
                                            )
                                        }
                                        fullWidth
                                        value={
                                            settings.businessEntitySettings
                                                ?.defaultCountry
                                        }
                                        label={
                                            <TranslatableText
                                                termKey={
                                                    BusinessEntitySettingDefs.DEFAULT_COUNTRY
                                                }
                                                termSet={termSet}
                                            />
                                        }
                                        options={countries}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <SelectInput
                                        id={'defaultState'}
                                        onChange={(event: any) =>
                                            handleChange(
                                                event,
                                                'businessEntitySettings'
                                            )
                                        }
                                        fullWidth
                                        value={
                                            settings.businessEntitySettings
                                                ?.defaultState
                                        }
                                        label={
                                            <TranslatableText
                                                termKey={
                                                    BusinessEntitySettingDefs.DEFAULT_STATE
                                                }
                                                termSet={termSet}
                                            />
                                        }
                                        options={states}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <SelectInput
                                        id={'functionalCurrencyCode'}
                                        onChange={(event: any) =>
                                            handleChange(
                                                event,
                                                'businessEntitySettings'
                                            )
                                        }
                                        fullWidth
                                        value={
                                            settings.businessEntitySettings
                                                ?.functionalCurrencyCode
                                        }
                                        label={
                                            <TranslatableText
                                                termKey={
                                                    BusinessEntitySettingDefs.FUNCTIONAL_CURRENCY_CODE
                                                }
                                                termSet={termSet}
                                            />
                                        }
                                        options={currencyCodes}
                                    />
                                </Grid>
                            </Grid>
                        </FormPartial>
                    </>
                )}
            </CardContent>
        </Card>
    );
};

export default BusinessEntitySettings;
