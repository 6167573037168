import { SdsOtherInfoDropdown } from '../../../../types/formulation';
import { datacorAppApi } from '../../../datacorAppApi';

export const otherInformationApi = datacorAppApi.injectEndpoints({
    endpoints: (build) => ({
        getHealthHazardForDropdown: build.query<
            { label: string; value: bigint }[],
            void
        >({
            query: () => 'healthHazards',
            transformResponse: (response: SdsOtherInfoDropdown[]) => {
                return response.map((propType: SdsOtherInfoDropdown) => {
                    return {
                        label: propType.prefix + '-' + propType.type,
                        value: propType.id,
                    };
                });
            },
            providesTags: ['HealthHazard'],
        }),
        getPhysicalHazardForDropdown: build.query<
            { label: string; value: bigint }[],
            void
        >({
            query: () => 'physicalHazards',
            transformResponse: (response: SdsOtherInfoDropdown[]) => {
                return response.map((propType: SdsOtherInfoDropdown) => {
                    return {
                        label: propType.prefix + '-' + propType.type,
                        value: propType.id,
                    };
                });
            },
            providesTags: ['PhysicalHazard'],
        }),
        getSpecificHazardForDropdown: build.query<
            { label: string; value: bigint }[],
            void
        >({
            query: () => 'specificHazards',
            transformResponse: (response: SdsOtherInfoDropdown[]) => {
                return response.map((propType: SdsOtherInfoDropdown) => {
                    return {
                        label: propType.prefix + '-' + propType.type,
                        value: propType.id,
                    };
                });
            },
            providesTags: ['SpecificHazard'],
        }),
        getFlammabilityForDropdown: build.query<
            { label: string; value: bigint }[],
            void
        >({
            query: () => 'flammability',
            transformResponse: (response: SdsOtherInfoDropdown[]) => {
                return response.map((propType: SdsOtherInfoDropdown) => {
                    return {
                        label: propType.prefix + '-' + propType.type,
                        value: propType.id,
                    };
                });
            },
            providesTags: ['Flammability'],
        }),
        getReactivityForDropdown: build.query<
            { label: string; value: bigint }[],
            void
        >({
            query: () => 'reactivity',
            transformResponse: (response: SdsOtherInfoDropdown[]) => {
                return response.map((propType: SdsOtherInfoDropdown) => {
                    return {
                        label: propType.prefix + '-' + propType.type,
                        value: propType.id,
                    };
                });
            },
            providesTags: ['Reactivity'],
        }),
        getProtectiveEquipmentForDropdown: build.query<
            { label: string; value: bigint }[],
            void
        >({
            query: () => 'protectiveEquipment',
            transformResponse: (response: SdsOtherInfoDropdown[]) => {
                return response.map((propType: SdsOtherInfoDropdown) => {
                    return {
                        label: propType.prefix + '-' + propType.type,
                        value: propType.id,
                    };
                });
            },
            providesTags: ['ProtectiveEquipment'],
        }),
    }),
});

export const {
    useGetHealthHazardForDropdownQuery,
    useGetPhysicalHazardForDropdownQuery,
    useGetFlammabilityForDropdownQuery,
    useGetProtectiveEquipmentForDropdownQuery,
    useGetReactivityForDropdownQuery,
    useGetSpecificHazardForDropdownQuery,
} = otherInformationApi;
