import WorkflowPortal from '../containers/workflow/WorkflowPortal';
import WorkflowApps from '../containers/workflow/system/WorkflowAppList';
import WorkflowApp from '../containers/workflow/system/WorkflowApp';
import SystemWorkflow from '../containers/workflow/system/SystemWorkflow';
import SystemAction from '../containers/workflow/system/SystemAction';
import WorkflowTestHarnessPortal from '../containers/workflow/testHarness/TestHarnessPortal';
import { RouteProps } from './routesConfig';

const WorkflowRoutes: RouteProps[] = [
    {
        path: '/workflow',
        component: WorkflowPortal,
        featureFlag: 'dashboard.nav.apps.workflow',
    },
    {
        path: '/workflow/apps',
        component: WorkflowApps,
        featureFlag: 'dashboard.nav.apps.workflow',
    },
    {
        path: '/workflow/apps/:id',
        component: WorkflowApp,
        featureFlag: 'dashboard.nav.apps.workflow',
    },
    {
        path: '/workflow/systemWorkflows/:id',
        component: SystemWorkflow,
        featureFlag: 'dashboard.nav.apps.workflow',
    },
    {
        path: '/workflow/systemActions/:id',
        component: SystemAction,
        featureFlag: 'dashboard.nav.apps.workflow',
    },
    {
        path: '/workflow-test-harness',
        component: WorkflowTestHarnessPortal,
        featureFlag: 'dashboard.nav.apps.workflow',
    },
];

export default WorkflowRoutes;
