import * as React from 'react';
import { OptionsMenuListProps } from '../OptionsMenuList';

export interface OptionItem {
    id: string;
    labelOption: string;
    canDisplay: boolean;
}

const useOptionsMenuList = (props: OptionsMenuListProps) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const onClick = (event: any) => {
        props.onClickOption(event);
        handleClose();
    };

    return {
        handleClose,
        handleClick,
        onClick,
        anchorEl,
        open,
    };
};

export default useOptionsMenuList;
