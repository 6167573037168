import {
    Box,
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import React, { useContext } from 'react';
import BaseGrid from '../../../components/grids/BaseGrid';
import { AgGridColumn } from 'ag-grid-react';
import DefaultColumnTypes from '../../../components/grids/columns/Column.constants';
import { ExportEntryRow } from './hooks/useAssetEntriesGrid';
import { ACCOUNTING_DEFS } from '../../../constants/i18n/translations/termSetDefinitions/accounting';
import SettingsContext from '../../../contexts/settings.context';
import { useGetTermSetQuery } from '../../../services/i18n/i18n.service';
import { skipToken } from '@reduxjs/toolkit/query';
import TranslatableText from '../../../components/i18n/TranslatableText';
import { AssetEntriesGridDefs } from '../../../constants/i18n/translations/termDefinitions/accounting';

export interface AssetEntryExportProps {
    open: boolean;
    selected: ExportEntryRow[];
    onClose: () => void;
}

const AssetEntryExportModal = (props: AssetEntryExportProps) => {
    const { settings } = useContext(SettingsContext);
    const { data: termSet } = useGetTermSetQuery(
        settings?.userSettings
            ? {
                  languageId: settings?.userSettings?.languageId,
                  code: ACCOUNTING_DEFS.ASSET_ENTRIES_GRID,
              }
            : skipToken
    );
    return (
        <Dialog
            open={props.open}
            onClose={props.onClose}
            fullWidth
            maxWidth={'lg'}>
            <DialogTitle>
                <Box display="flex" alignItems="center">
                    <Box flexGrow={1}>
                        <TranslatableText
                            termSet={termSet}
                            termKey={
                                AssetEntriesGridDefs.Download_Entries_Title
                            }
                        />
                    </Box>
                    <Box>
                        <IconButton onClick={props.onClose}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </Box>
            </DialogTitle>
            <DialogContent>
                <BaseGrid
                    paginationPageSize={30}
                    rowData={props.selected}
                    displayGrid={true}
                    displayToolbar={true}
                    defaultColDef={{ flex: 1 }}
                    displayExportDataButton={true}>
                    <AgGridColumn
                        field="entryDate"
                        headerComponentFramework={(props: any) => {
                            return (
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={AssetEntriesGridDefs.Date}
                                />
                            );
                        }}
                        sort="asc"
                        {...DefaultColumnTypes.DateOnly}
                    />
                    <AgGridColumn
                        field="accountNo"
                        headerComponentFramework={(props: any) => {
                            return (
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={
                                        AssetEntriesGridDefs.Account_Number
                                    }
                                />
                            );
                        }}
                    />
                    <AgGridColumn
                        field="accountName"
                        headerComponentFramework={(props: any) => {
                            return (
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={
                                        AssetEntriesGridDefs.Entry_Description
                                    }
                                />
                            );
                        }}
                    />
                    <AgGridColumn
                        field="debit"
                        headerComponentFramework={(props: any) => {
                            return (
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={AssetEntriesGridDefs.Debit}
                                />
                            );
                        }}
                        {...DefaultColumnTypes.Currency}
                    />
                    <AgGridColumn
                        field="credit"
                        headerComponentFramework={(props: any) => {
                            return (
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={AssetEntriesGridDefs.Credit}
                                />
                            );
                        }}
                        {...DefaultColumnTypes.Currency}
                    />
                </BaseGrid>
            </DialogContent>
        </Dialog>
    );
};

export default AssetEntryExportModal;
