import React, { useContext, useState } from 'react';
import { GridReadyEvent } from 'ag-grid-community';
import {
    useGetBusinessEntityFeatureFlagsQuery,
    useGetGlobalFeatureFlagsQuery,
    useUpdateBusinessEntityFeatureFlagMutation,
    useUpdateGlobalFeatureFlagMutation,
} from '../../../services/access/access.service';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { updateGridModels } from '../../../store/grids';
import DefaultColumnTypes from '../../../components/grids/columns/Column.constants';
import { skipToken } from '@reduxjs/toolkit/query';
import BooleanCellRenderer from '../../../components/grids/cellRenderers/booleanCell.renderer';
import { PermissionsUtil } from '../../../utils/permissions/permissionsUtil';
import { PERMISSIONS } from '../../../constants/permissions/Permissions.constants';
import { openModalConfirmBasicWithMessage } from '../../../store/uiElements';
import SettingsContext from '../../../contexts/settings.context';
import { useGetTermSetQuery } from '../../../services/i18n/i18n.service';
import { PLATFORM_DEFS } from '../../../constants/i18n/translations/termSetDefinitions/platform';
import { FeatureFlagsGridDefs } from '../../../constants/i18n/translations/termDefinitions/platform';
import CustomHeader from '../../../components/grids/CustomHeader';

const useFeatureFlagsGrid = () => {
    const dispatch = useDispatch();
    const user = useSelector((state: RootState) => state.user);
    const { administration } = useSelector((state: RootState) => state.grids);
    const canUpdateFeatureFlags = PermissionsUtil.isPermissionEnabled(
        user?.permissions,
        PERMISSIONS.SETTINGS.FEATURE_FLAGS.EDIT
    );
    const columns = [
        {
            field: 'enabled',
            headerComponentFramework: (props: any) => {
                return (
                    <CustomHeader
                        {...props}
                        termSet={termSet}
                        termKey={FeatureFlagsGridDefs.Enabled}
                    />
                );
            },
            ...DefaultColumnTypes.CheckboxSelection,
        },
        {
            field: 'name',
            headerComponentFramework: (props: any) => {
                return (
                    <CustomHeader
                        {...props}
                        termSet={termSet}
                        termKey={FeatureFlagsGridDefs.Name}
                    />
                );
            },
            ...DefaultColumnTypes.MediumText,
            flex: 2,
        },
        {
            field: 'description',
            headerComponentFramework: (props: any) => {
                return (
                    <CustomHeader
                        {...props}
                        termSet={termSet}
                        termKey={FeatureFlagsGridDefs.Description}
                    />
                );
            },
            flex: 1,
        },
        {
            field: 'locked',
            headerComponentFramework: (props: any) => {
                return (
                    <CustomHeader
                        {...props}
                        termSet={termSet}
                        termKey={FeatureFlagsGridDefs.Locked}
                    />
                );
            },
            ...DefaultColumnTypes.Boolean,
        },
    ];

    const frameworkComponents = {
        booleanCellRenderer: BooleanCellRenderer,
    };

    const [gridApi, setGridApi] = useState(null);
    const [globalGridApi, setGlobalGridApi] = useState(null);
    const { data: rowData, isLoading: isLoading } =
        useGetBusinessEntityFeatureFlagsQuery(
            user?.businessEntity?.id
                ? user?.businessEntity?.id.toString()
                : skipToken
        );

    const [selectedTab, setSelectedTab] = useState(0);
    const [gridColumnApi, setGridColumnApi] = useState(null);
    const [globalGridColumnApi, setGlobalGridColumnApi] = useState(null);

    const { data: globalRowData, isLoading: isLoadingGlobal } =
        useGetGlobalFeatureFlagsQuery();
    const [update] = useUpdateBusinessEntityFeatureFlagMutation();
    const [updateGlobal] = useUpdateGlobalFeatureFlagMutation();
    const { settings } = useContext(SettingsContext);
    const { data: termSet } = useGetTermSetQuery(
        settings?.userSettings
            ? {
                  languageId: settings?.userSettings?.languageId,
                  code: PLATFORM_DEFS.FEATURE_FLAGS_GRID,
              }
            : skipToken
    );
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setSelectedTab(newValue);
    };

    const onGridReady = (params: GridReadyEvent) => {
        setGridApi(params.api);
        setGridColumnApi(params.columnApi);
        params.api.sizeColumnsToFit();
    };

    const onGlobalGridReady = (params: GridReadyEvent) => {
        setGlobalGridApi(params.api);
        setGlobalGridColumnApi(params.columnApi);
    };

    const onFirstDataRendered = () => {
        gridColumnApi?.applyColumnState({
            state: administration.featureFlagsList.column,
            applyOrder: true,
        });

        gridApi?.setFilterModel(administration.featureFlagsList.filter);
    };

    const onSortChanged = () => {
        dispatch(
            updateGridModels({
                gridLocation: 'administration',
                gridName: 'featureFlagsList',
                type: 'column',
                model: gridColumnApi.getColumnState(),
            })
        );
    };

    const handleUpdateFeatureFlags = (event: any) => {
        rowData.forEach((row: any) => {
            let rowNode = gridApi.getRowNode(row.id);

            if (rowNode.selected !== row.active) {
                const args = {
                    id: row.id,
                    businessEntityId: user?.businessEntity?.id?.toString(),
                    postBody: { ...row, active: rowNode.selected },
                };

                if (!rowNode.data.locked) {
                    update(args);
                }
            }
        });
    };

    const handleUpdateGlobalFeatureFlags = (event: any) => {
        globalRowData.forEach((row: any) => {
            let rowNode = globalGridApi.getRowNode(row.id);

            if (rowNode.selected !== row.active) {
                const args = {
                    id: row.id,
                    postBody: { ...row, active: rowNode.selected },
                };

                if (!rowNode.data.locked) {
                    updateGlobal(args);
                }
            }
        });
    };

    const showUpdateWillImpactAllConfirmModal = async () => {
        const message = `The enable/disable selections will impact ALL Subscriber Business Entities. 
            To adjust the Feature Flag for the current Business Entity only please use the Business Entity tab.
            Are you sure you want to proceed?`;

        dispatch(
            openModalConfirmBasicWithMessage({
                message: message,
                title: '',
                onConfirm: handleUpdateGlobalFeatureFlags,
            })
        );
    };

    return {
        onGridReady,
        rowData,
        globalRowData,
        isLoading,
        isLoadingGlobal,
        columns,
        frameworkComponents,
        onSortChanged,
        onFirstDataRendered,
        selectedTab,
        handleChange,
        handleUpdateFeatureFlags,
        onGlobalGridReady,
        handleUpdateGlobalFeatureFlags,
        canUpdateFeatureFlags,
        showUpdateWillImpactAllConfirmModal,
        termSet,
    };
};

export default useFeatureFlagsGrid;
