import { ColDef } from 'ag-grid-community';
import DefaultColumnTypes from '../../../../components/grids/columns/Column.constants';
import React from 'react';
import BaseGrid from '../../../../components/grids/BaseGrid';
import useCostGrid from './hooks/useCostGrid';
import { FA_AssetCost } from '../../../../types/FixedAsset.types';
import TranslatableText from '../../../../components/i18n/TranslatableText';
import { FixedAssetRegisterFormDefs } from '../../../../constants/i18n/translations/termDefinitions/accounting';

export interface CostProps {
    assetId: bigint;
    costs: FA_AssetCost[];
    totalCost: number;
}

const CostGrid = React.forwardRef((props: CostProps, ref) => {
    const {
        onGridReady,
        costWorksheet,
        amountValidation,
        costsPinnedBottomRowData,
        termSet,
    } = useCostGrid(props, ref);

    const columnDefs: ColDef[] = [
        {
            field: 'costCode.code',
            headerComponentFramework: (props: any) => {
                return (
                    <TranslatableText
                        termSet={termSet}
                        termKey={FixedAssetRegisterFormDefs.CostsCode}
                    />
                );
            },
            ...DefaultColumnTypes.MediumText,
        },
        {
            field: 'amount',
            headerComponentFramework: (props: any) => {
                return (
                    <TranslatableText
                        termSet={termSet}
                        termKey={FixedAssetRegisterFormDefs.CostsAmount}
                    />
                );
            },
            headerClass: 'ag-right-aligned-header',
            editable: true,
            valueParser: amountValidation,
            ...DefaultColumnTypes.Currency,
        },
    ];

    return (
        <BaseGrid
            onGridReady={onGridReady}
            rowData={costWorksheet}
            columnDefs={columnDefs}
            displayGrid={true}
            displaySummaryRow={true}
            pinnedBottomRowData={costsPinnedBottomRowData}
            defaultColDef={{
                resizable: true,
                wrapText: true,
            }}></BaseGrid>
    );
});

export default CostGrid;
