const auditableFields = {
    id: 'Id',
    businessEntityId: 'Business Entity Id',
    createdAt: 'Created At',
    createdBy: 'Created By',
    updatedAt: 'Updated At',
    updatedBy: 'Updated By',
};
//common
export const FiscalYearStatusRevisionHistoryFields = {
    ...auditableFields,
    code: 'Code',
    sortOrder: 'Sort Order',
    description: 'Description',
    closed: 'Closed',
};

export const FiscalYearRevisionHistoryFields = {
    ...auditableFields,
    number: 'Number',
    code: 'Code',
    startDate: 'Start Date',
    endDate: 'End Date',
    status: 'Status',
};

export const PeriodStatusRevisionHistoryFields = {
    ...auditableFields,
    code: 'Code',
    sortOrder: 'Sort Order',
    description: 'Description',
    closed: 'Closed',
};

export const AccountingRevisionHistory = {
    'fiscal-year-status': FiscalYearStatusRevisionHistoryFields,
    'fiscal-year': FiscalYearRevisionHistoryFields,
};
