import {
    SdsBlobSubSection,
    SdsCasComposition,
    SdsEcoToxicity,
    SdsExposure,
    SdsGhsHazardPhrase,
    SdsGhsPrecautionaryPhrase,
    SdsHeader,
    SdsIdentification,
    SdsOtherInformation,
    SdsPictogram,
    SdsProperty,
    SdsRegulation,
    SdsToxicity,
    SdsTransportation,
} from '../../../../../types/formulation';
import { CopyFormProps } from '../../../../form/helpers/CopyFormProps';
import { useEffect, useState } from 'react';
import { RoutingValues } from '../../../../../types/Shared.types';
import { v4 as uuidv4 } from 'uuid';
import { RowStatus } from '../../../../../components/grids/hooks/useBaseGridEditable';

const useCopySdsForm = (props: CopyFormProps<SdsHeader>) => {
    const { fields, dataFromQuery, setValues, formType } = props;

    const [sdsGhsHazardPhraseList, setSdsGhsHazardPhraseList] = useState<
        SdsGhsHazardPhrase[]
    >([]);

    const [sdsGhsPrecautionaryPhraseList, setSdsGhsPrecautionaryPhraseList] =
        useState<SdsGhsPrecautionaryPhrase[]>([]);

    const [sdsExposureList, setSdsExposureList] = useState<SdsExposure[]>([]);

    const [sdsPropertyList, setSdsPropertyList] = useState<SdsProperty[]>([]);

    const [sdsCasCompositionList, setSdsCasCompositionList] = useState<
        SdsCasComposition[]
    >([]);

    const [sdsEcoToxicityList, setSdsEcoToxicityList] = useState<
        SdsEcoToxicity[]
    >([]);

    const [sdsToxicityList, setSdsToxicityList] = useState<SdsToxicity[]>([]);

    const [sdsRegulationList, setSdsRegulationList] = useState<SdsRegulation[]>(
        []
    );

    const [blobMapping, setBlobMapping] = useState(
        new Map<string, SdsBlobSubSection>()
    );

    const copyBlob = (childFormName: string, blobData: SdsBlobSubSection) => {
        blobMapping.set(childFormName, blobData);
        setBlobMapping(blobMapping);
    };

    const getCopyBlob = (childFormName: string): SdsBlobSubSection => {
        const blobData = blobMapping.get(childFormName);
        if (blobData) {
            return blobData;
        }
        return null;
    };

    const createSdsGhsHazardPhraseListCopy = (
        today: Date
    ): SdsGhsHazardPhrase[] => {
        const sdsGhsHazardPhraseListCopy: SdsGhsHazardPhrase[] =
            sdsGhsHazardPhraseList?.map(
                (sdsGhsHazardPhrase: any, index: number) => {
                    const newRecord = {
                        ...sdsGhsHazardPhrase,
                        rowStatus: RowStatus.ADDED,
                        rowId: index + 1,
                        createdAt: today,
                        updatedAt: today,
                    } as SdsGhsHazardPhrase;

                    delete newRecord.id;
                    delete newRecord.sdsId;

                    return newRecord;
                }
            );

        setSdsGhsHazardPhraseList(sdsGhsHazardPhraseListCopy);
        return sdsGhsHazardPhraseListCopy;
    };

    const createSdsGhsPrecautionaryPhraseListCopy = (
        today: Date
    ): SdsGhsPrecautionaryPhrase[] => {
        const sdsGhsPrecautionaryPhraseListCopy: SdsGhsPrecautionaryPhrase[] =
            sdsGhsPrecautionaryPhraseList?.map(
                (sdsGhsPrecautionaryPhrase: any, index: number) => {
                    const newRecord = {
                        ...sdsGhsPrecautionaryPhrase,
                        rowStatus: RowStatus.ADDED,
                        rowId: index + 1,
                        createdAt: today,
                        updatedAt: today,
                    } as SdsGhsPrecautionaryPhrase;

                    delete newRecord.id;
                    delete newRecord.sdsId;

                    return newRecord;
                }
            );

        setSdsGhsPrecautionaryPhraseList(sdsGhsPrecautionaryPhraseListCopy);
        return sdsGhsPrecautionaryPhraseListCopy;
    };

    const createSdsExposureListCopy = (today: Date): SdsExposure[] => {
        const sdsExposureListCopy: SdsExposure[] = sdsExposureList?.map(
            (sdsExposure: any, index: number) => {
                const newRecord = {
                    ...sdsExposure,
                    rowStatus: RowStatus.ADDED,
                    rowId: index + 1,
                    createdAt: today,
                    updatedAt: today,
                } as SdsExposure;

                delete newRecord.id;
                delete newRecord.sdsId;

                return newRecord;
            }
        );

        setSdsExposureList(sdsExposureListCopy);
        return sdsExposureListCopy;
    };

    const createSdsPropertyListCopy = (today: Date): SdsProperty[] => {
        const sdsPropertyListCopy: SdsProperty[] = sdsPropertyList?.map(
            (sdsProperty: any, index: number) => {
                const newRecord = {
                    ...sdsProperty,
                    rowStatus: RowStatus.ADDED,
                    rowId: index + 1,
                    createdAt: today,
                    updatedAt: today,
                } as SdsProperty;

                delete newRecord.id;
                delete newRecord.sdsId;

                return newRecord;
            }
        );

        setSdsPropertyList(sdsPropertyListCopy);
        return sdsPropertyListCopy;
    };

    const createSdsCasCompositionListCopy = (
        today: Date
    ): SdsCasComposition[] => {
        const sdsCasCompositionListCopy: SdsCasComposition[] =
            sdsCasCompositionList?.map(
                (sdsCasComposition: any, index: number) => {
                    const newRecord = {
                        ...sdsCasComposition,
                        rowStatus: RowStatus.ADDED,
                        rowId: index + 1,
                        createdAt: today,
                        updatedAt: today,
                    } as SdsCasComposition;

                    delete newRecord.id;
                    delete newRecord.sdsId;

                    return newRecord;
                }
            );

        setSdsCasCompositionList(sdsCasCompositionListCopy);
        return sdsCasCompositionListCopy;
    };

    const createSdsEcoToxicityListCopy = (today: Date): SdsEcoToxicity[] => {
        const sdsEcoToxicityListCopy: SdsEcoToxicity[] =
            sdsEcoToxicityList?.map((sdsEcoToxicity: any, index: number) => {
                const newRecord = {
                    ...sdsEcoToxicity,
                    rowStatus: RowStatus.ADDED,
                    rowId: index + 1,
                    createdAt: today,
                    updatedAt: today,
                } as SdsEcoToxicity;

                delete newRecord.id;
                delete newRecord.sdsId;

                return newRecord;
            });

        setSdsEcoToxicityList(sdsEcoToxicityListCopy);
        return sdsEcoToxicityListCopy;
    };

    const createSdsToxicityListCopy = (today: Date): SdsToxicity[] => {
        const sdsToxicityListCopy: SdsToxicity[] = sdsToxicityList?.map(
            (sdsToxicity: any, index: number) => {
                const newRecord = {
                    ...sdsToxicity,
                    rowStatus: RowStatus.ADDED,
                    rowId: index + 1,
                    createdAt: today,
                    updatedAt: today,
                } as SdsToxicity;

                delete newRecord.id;
                delete newRecord.sdsId;

                return newRecord;
            }
        );

        setSdsToxicityList(sdsToxicityListCopy);
        return sdsToxicityListCopy;
    };

    const createSdsRegulationListCopy = (today: Date): SdsRegulation[] => {
        const sdsRegulationListCopy: SdsRegulation[] = sdsRegulationList?.map(
            (sdsRegulation: any, index: number) => {
                const newRecord = {
                    ...sdsRegulation,
                    rowStatus: RowStatus.ADDED,
                    rowId: index + 1,
                    createdAt: today,
                    updatedAt: today,
                } as SdsRegulation;

                delete newRecord.id;
                delete newRecord.sdsId;

                return newRecord;
            }
        );

        setSdsRegulationList(sdsRegulationListCopy);
        return sdsRegulationListCopy;
    };

    const createSdsPictogramCopy = (today: Date): SdsPictogram => {
        const newRecord: SdsPictogram = {
            ...fields?.sdsPictogram,
            createdAt: today,
            updatedAt: today,
        };
        delete newRecord.id;
        delete newRecord.sdsId;

        return newRecord;
    };

    const createSdsIdentificationCopy = (today: Date): SdsIdentification => {
        const newRecord: SdsIdentification = {
            ...fields?.sdsIdentification,
            createdAt: today,
            updatedAt: today,
        };
        delete newRecord.id;
        delete newRecord.sdsId;

        return newRecord;
    };

    const createSdsTransportationCopy = (today: Date): SdsTransportation => {
        const newRecord: SdsTransportation = {
            ...fields?.sdsTransportation,
            createdAt: today,
            updatedAt: today,
        };
        delete newRecord.id;
        delete newRecord.sdsId;

        return newRecord;
    };

    const createSdsOtherInformationCopy = (
        today: Date
    ): SdsOtherInformation => {
        const newRecord: SdsOtherInformation = {
            ...fields?.sdsOtherInformation,
            createdAt: today,
            updatedAt: today,
        };
        delete newRecord.id;
        delete newRecord.sdsId;

        return newRecord;
    };

    useEffect(() => {
        if (formType === RoutingValues.copy) {
            const today: Date = new Date(new Date().getTime());

            const sdsGhsHazardPhraseListCopy =
                createSdsGhsHazardPhraseListCopy(today);

            const sdsGhsPrecautionaryPhraseListCopy =
                createSdsGhsPrecautionaryPhraseListCopy(today);

            const sdsExposureListCopy = createSdsExposureListCopy(today);

            const sdsPropertyListCopy = createSdsPropertyListCopy(today);

            const sdsCasCompositionListCopy =
                createSdsCasCompositionListCopy(today);

            const sdsEcoToxicityListCopy = createSdsEcoToxicityListCopy(today);

            const sdsToxicityListCopy = createSdsToxicityListCopy(today);

            const sdsRegulationListCopy = createSdsRegulationListCopy(today);

            const sdsPictogramCopy = createSdsPictogramCopy(today);

            const sdsIdentificationCopy = createSdsIdentificationCopy(today);

            const sdsTransportationCopy = createSdsTransportationCopy(today);

            const sdsOtherInformationCopy =
                createSdsOtherInformationCopy(today);

            setValues({
                ...dataFromQuery,
                id: null as unknown as bigint,
                sdsCode: null as unknown as string,
                sdsNumber: null as unknown as string,
                itemCodeId: null as unknown as bigint,
                template: false,
                versionDate: today,
                sdsGhsHazardPhrases:
                    sdsGhsHazardPhraseListCopy || ([] as SdsGhsHazardPhrase[]),
                sdsGhsPrecautionaryPhrases:
                    sdsGhsPrecautionaryPhraseListCopy ||
                    ([] as SdsGhsPrecautionaryPhrase[]),
                sdsExposures: sdsExposureListCopy || ([] as SdsExposure[]),
                sdsProperties: sdsPropertyListCopy || ([] as SdsProperty[]),
                sdsCasComposition:
                    sdsCasCompositionListCopy || ([] as SdsCasComposition[]),
                sdsEcoToxicities:
                    sdsEcoToxicityListCopy || ([] as SdsEcoToxicity[]),
                sdsToxicities: sdsToxicityListCopy || ([] as SdsToxicity[]),
                sdsRegulations:
                    sdsRegulationListCopy || ([] as SdsRegulation[]),
                sdsPictogram: sdsPictogramCopy || (null as SdsPictogram),
                sdsIdentification:
                    sdsIdentificationCopy || (null as SdsIdentification),
                sdsTransportation:
                    sdsTransportationCopy || (null as SdsTransportation),
                sdsOtherInformation:
                    sdsOtherInformationCopy || (null as SdsOtherInformation),
                createdAt: today,
                updatedAt: today,
                uuid: uuidv4(),
            });
        }
    }, [formType, dataFromQuery]);

    return {
        copyMethods: {
            sdsGhsHazardPhraseList,
            setSdsGhsHazardPhraseList,
            sdsGhsPrecautionaryPhraseList,
            setSdsGhsPrecautionaryPhraseList,
            sdsExposureList,
            setSdsExposureList,
            sdsPropertyList,
            setSdsPropertyList,
            sdsCasCompositionList,
            setSdsCasCompositionList,
            sdsEcoToxicityList,
            setSdsEcoToxicityList,
            sdsToxicityList,
            setSdsToxicityList,
            sdsRegulationList,
            setSdsRegulationList,
        },
        blobCopyMethods: {
            formType,
            copyBlob,
            getCopyBlob,
        },
    };
};

export default useCopySdsForm;
