import React from 'react';
import BaseFormGrid from '../../../components/grids/BaseFormGrid';
import useDocumentTemplatesGrid from '../hooks/useDocumentTemplatesGrid';
import { DocTemplateGridDefs } from '../../../constants/i18n/translations/termDefinitions/platform';
import TranslatableText from '../../../components/i18n/TranslatableText';

const DocumentTemplatesGrid = () => {
    const {
        docTemplates,
        isLoading,
        redirectToForm,
        frameworkComponents,
        colDefs,
        termSet,
        isLoadingTermSet,
        canCreateDocumentTemplate,
        canViewDocumentTemplate,
    } = useDocumentTemplatesGrid();

    return (
        <BaseFormGrid
            title={
                <TranslatableText
                    termKey={DocTemplateGridDefs.Title}
                    termSet={termSet}
                />
            }
            onCreateNew={redirectToForm}
            gridClass="full-size-item"
            displayToolbar={true}
            frameworkComponents={frameworkComponents}
            onRowClicked={(row: any) => redirectToForm(row?.data)}
            displayCreateNewButton={canCreateDocumentTemplate}
            columnDefs={colDefs}
            displayGrid={canViewDocumentTemplate}
            isLoading={isLoading || isLoadingTermSet}
            rowData={docTemplates}
        />
    );
};

export default DocumentTemplatesGrid;
