import { useEffect, useState, useContext } from 'react';
import {
    Subscriber,
    useCreateSubscriberMutation,
    useGetDepartmentCodesForSubscriberQuery,
    useGetSubscriberQuery,
    useUpdateSubscriberMutation,
} from '../../../services/organizations/organizations.service';
import { skipToken } from '@reduxjs/toolkit/query';
import { useNavigate } from 'react-router-dom';
import { useForm } from '../../../libs/hooksLib';
import {
    BaseCreateEntityArgs,
    BaseUpdateEntityArgs,
} from '../../../services/serviceInterfaces';
import { GridReadyEvent } from 'ag-grid-community';
import useGetBusinessEntities from './useGetBusinessEntities';
import { useDispatch, useSelector } from 'react-redux';
import { openSnackbarBasicWithMessage } from '../../../store/uiElements';
import config from '../../../config';
import { RootState } from '../../../store';
import SettingsContext from '../../../contexts/settings.context';
import { useGetTermSetQuery } from '../../../services/i18n/i18n.service';
import { PLATFORM_DEFS } from '../../../constants/i18n/translations/termSetDefinitions/platform';

const useSubscriberForm = (id: string) => {
    const user = useSelector((state: RootState) => state.user);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { fields, handleFieldChange, setValues, formMethods, isFormValid } =
        useForm({});
    const { data: subscriber, isLoading: isLoading } = useGetSubscriberQuery(
        id ? id : skipToken
    );
    const { businessEntities, isLoading: isLoadingBusinessEntities } =
        useGetBusinessEntities({
            businessEntityId: null,
            subscriberId: id ? id : null,
        });

    const { data: departmentCodes, isLoading: isDepartmentCodesLoading } =
        useGetDepartmentCodesForSubscriberQuery(id ? id : skipToken);
    const [createSubscriber] = useCreateSubscriberMutation();
    const [updateSubscriber] = useUpdateSubscriberMutation();
    const [gridApi, setGridApi] = useState(null);
    const { settings } = useContext(SettingsContext);

    const { data: termSet } = useGetTermSetQuery(
        settings?.userSettings
            ? {
                  languageId: settings?.userSettings?.languageId,
                  code: PLATFORM_DEFS.SUBSCRIBER_FORM,
              }
            : skipToken
    );
    const defaultSubscriber = {
        name: '',
        description: '',
    };
    useEffect(() => {
        setValues(subscriber || defaultSubscriber);
    }, [subscriber]);

    const handleCreateSubscriber = async () => {
        if (!isFormValid) {
            return;
        }
        const body: BaseCreateEntityArgs<Subscriber> = {
            postBody: { ...fields, userPoolId: config.cognito.USER_POOL_ID },
        };

        try {
            await createSubscriber(body).unwrap();
            handleClose();
        } catch (e) {
            dispatch(
                openSnackbarBasicWithMessage({
                    message: e?.data?.error,
                    severity: 'error',
                })
            );
        }
    };
    const handleUpdateSubscriber = async () => {
        if (!isFormValid) {
            return;
        }
        const body: BaseUpdateEntityArgs<Subscriber> = {
            id: id as unknown as number,
            postBody: fields,
        };

        try {
            await updateSubscriber(body).unwrap();
            handleClose();
        } catch (e) {
            dispatch(
                openSnackbarBasicWithMessage({
                    message: e?.data?.error,
                    severity: 'error',
                })
            );
        }
    };

    const handleClose = () => {
        navigate('/administration/organizations/subscribers');
    };

    const onGridReady = (params: GridReadyEvent) => {
        setGridApi(params.api);
    };

    return {
        user,
        fields,
        subscriber,
        businessEntities,
        isLoading,
        handleCreateSubscriber,
        handleUpdateSubscriber,
        handleFieldChange,
        handleClose,
        setValues,
        onGridReady,
        departmentCodes,
        isDepartmentCodesLoading,
        formMethods,
        termSet,
    };
};

export default useSubscriberForm;
