import React from 'react';
import { ParameterizedValidator, Validator } from './validator.interface';
import { isNil } from '../utils/objectUtils';
import _ from 'lodash';

export const GreaterThanValidator: ParameterizedValidator = (
    greaterThan: number
) => {
    return {
        key: 'greaterThan',
        getErrorMessage: (fieldLabel: string) => {
            if (_.isObject(fieldLabel)) {
                return (
                    <>
                        {fieldLabel} must be greater than or equal to{' '}
                        {greaterThan}
                    </>
                );
            }
            return `${fieldLabel} must be greater than or equal to ${greaterThan}`;
        },
        isValid: (value: any) => {
            return isNil(value) || Number(value) >= greaterThan;
        },
    } as Validator;
};
