import { IAccountingNav } from './Accounting.types';
import { ISupplierNav } from './Supplier.types';
import { IFormulationNav } from './formulation/shared/FormulationNav.type';

export interface StorageResponse {
    key?: string;
}

export interface SelectionOption {
    label: string;
    value: any;
    object?: any;
}

export interface MutationError {
    status: number;
    data: any;
}

export const NumberMaxValues = {
    smallint: 32767,
    integer: 2147483647,
    decimal192: 99999999999999999.99,
    decimal206: 999999999999999999.999999,
};

export enum RoutingValues {
    newId = 'new',
    copy = 'copy',
}

export enum FunctionGroupValues {
    accounting = 'Accounting',
    fixedAssets = 'Fixed Assets',
}

export interface ValidationRuleResult {
    isValid: boolean;
    getErrorMessage: string;
    fieldId?: string;
}

export interface EntityBase {
    id?: bigint;
    uuid?: string;
    createdAt?: Date;
    updatedAt?: Date;
    createdBy?: bigint;
    updatedBy?: bigint;
    businessEntityId?: bigint;
}

export interface EntityError {
    errorMessage: string;
    errorFieldId?: string;
    additionalErrorMessage?: string;
}

export interface ErrorEntity {
    hasError?: boolean;
    errors?: EntityError[];
    validationRules?: ValidationRuleResult[];
}

export interface EditableGridEntity extends ErrorEntity, EntityBase {
    rowStatus?: string;
    rowId?: number;
    autoPopulated?: boolean;
}

export interface SelectedDropdownOption {
    id: bigint;
    display?: string;
    name?: string;
}

export interface CountryData {
    name?: string;
    dialCode?: string;
    countryCode?: string;
}

export interface PhoneNumberData {
    phoneNumber?: string;
    countryCode?: string;
}

export interface INavForm {
    sharedNav: {
        isCloseFormValid?: boolean;
    };
    accountingNav?: IAccountingNav;
    supplierNav?: ISupplierNav;
    formulationNav?: IFormulationNav;
}
