import { v4 as uuidv4 } from 'uuid';
import { isNil, isNilOrEmpty } from '../objectUtils';
import { NavLinkOptions } from './navigationUtils';

export default class NavLink {
    url: string;
    name: string;
    about: string;
    appImage: any;
    imageName: string;
    featureFlag: string;
    permissionKey: string;
    children: NavLink[];
    key: string;
    datacorAdminOnly: boolean;
    displayCard: boolean = true;

    constructor(navLinkOptions: NavLinkOptions) {
        this.url = navLinkOptions.url;
        this.name = navLinkOptions.name;
        this.about = navLinkOptions.about;
        this.appImage = navLinkOptions?.appImage;
        this.imageName = navLinkOptions?.imageName;
        this.featureFlag = navLinkOptions.featureFlag;
        this.permissionKey = navLinkOptions?.permissionKey;
        this.children = navLinkOptions.children;
        this.datacorAdminOnly = !!navLinkOptions.datacorAdminOnly;
        if (navLinkOptions.displayCard === false) {
            this.displayCard = false;
        }

        this.key = uuidv4();
    }

    isExternalURL = (): boolean => {
        return !isNil(this.url) && this.url.includes('http');
    };

    hasChildren = (): boolean => {
        return !isNilOrEmpty(this.children);
    };

    isVisible = (): boolean => {
        if (this.hasChildren()) {
            //look for visible in the children
            return !isNil(
                this.children.find((childLink: NavLink) =>
                    childLink.isVisible()
                )
            );
        } else {
            //check feature flag and permissions flag
        }
    };
}
