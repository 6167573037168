import { FA_DepreciationSchedule } from '../../../../types/FixedAsset.types';
import React, { useContext } from 'react';
import BaseGrid from '../../../../components/grids/BaseGrid';
import DefaultColumnTypes from '../../../../components/grids/columns/Column.constants';
import {
    dateTimeFormatter,
    percentFromDecimalFormatter,
} from '../../../../utils/formattingUtils';
import useDepreciationScheduleGrid from './hooks/useDepreciationScheduleGrid';
import { ValueFormatterParams, ColGroupDef } from 'ag-grid-community';
import { FixedAssetRegisterFormDefs } from '../../../../constants/i18n/translations/termDefinitions/accounting';
import TranslatableText from '../../../../components/i18n/TranslatableText';

export interface DepreciationScheduleGridProps {
    depreciationSchedule: FA_DepreciationSchedule[];
    headerName?: string;
    totalPercentage: any;
}

const DepreciationScheduleGrid = (props: DepreciationScheduleGridProps) => {
    const { lockUnlockColumn, pinnedBottomRowData, termSet } =
        useDepreciationScheduleGrid(props);

    const depreciationScheduleColumnDefs: ColGroupDef[] = [
        {
            headerName: props.headerName,
            children: [
                {
                    field: 'interval',
                    headerComponentFramework: (props: any) => {
                        return (
                            <TranslatableText
                                termSet={termSet}
                                termKey={
                                    FixedAssetRegisterFormDefs.DepreciationSchedulesRecoveryInterval
                                }
                            />
                        );
                    },
                    ...DefaultColumnTypes.NumberOnly,
                },
                {
                    field: 'intervalDate',
                    headerName: 'Date',
                    ...DefaultColumnTypes.DateOnly,
                },
                {
                    field: 'percentage',
                    headerName: 'Percentage',
                    valueFormatter: (params: ValueFormatterParams) => {
                        return percentFromDecimalFormatter(params.value, 3);
                    },
                    ...DefaultColumnTypes.PercentFromDecimal,
                },
                {
                    field: 'assetCost',
                    headerName: 'Asset Cost',
                    ...DefaultColumnTypes.Currency,
                },
                {
                    field: 'accumulatedDepreciation',
                    headerName: 'Accumulated Depreciation',
                    ...DefaultColumnTypes.Currency,
                },
                {
                    field: 'remainingValue',
                    headerName: 'Remaining Value',
                    ...DefaultColumnTypes.Currency,
                },
                {
                    width: 130,
                    minWidth: 130,
                    maxWidth: 150,
                    cellRenderer: 'lockUnlockColumn',
                    cellRendererParams: {
                        scheduleList: props.depreciationSchedule,
                    },
                    ...DefaultColumnTypes.StackedColumn,
                },
                {
                    headerName: 'Locked',
                    valueGetter: (params) => {
                        const schedule: FA_DepreciationSchedule = params.data;
                        return schedule.locked
                            ? `${dateTimeFormatter(schedule.lockedDate)} (${
                                  schedule.lockedUserName
                              })`
                            : undefined;
                    },
                    ...DefaultColumnTypes.LockedInformationColumn,
                },
            ],
        },
    ];

    return (
        <BaseGrid
            rowData={props.depreciationSchedule || []}
            displayGrid={true}
            frameworkComponents={{ lockUnlockColumn: lockUnlockColumn }}
            defaultColDef={{ resizable: true, wrapText: true }}
            displaySummaryRow={true}
            columnDefs={depreciationScheduleColumnDefs}
            pinnedBottomRowData={pinnedBottomRowData}></BaseGrid>
    );
};

export default DepreciationScheduleGrid;
