import React, { useContext, useEffect, useState } from 'react';
import {
    Currency,
    CurrencyExchangeRateByTypeParams,
} from '../../../../types/platform/i18n/Currency.type';
import SettingsContext from '../../../../contexts/settings.context';
import {
    useGetTermSetQuery,
    useListCurrencyExchangeRatesByTypeQuery,
} from '../../../../services/i18n/i18n.service';
import { ACCOUNTING_DEFS } from '../../../../constants/i18n/translations/termSetDefinitions/accounting';
import { skipToken } from '@reduxjs/toolkit/query';
import { PermissionsUtil } from '../../../../utils/permissions/permissionsUtil';
import { PERMISSIONS } from '../../../../constants/permissions/Permissions.constants';
import { auditableGridColumns } from '../../../grid/utils/auditableGrid/auditableGridColumns';
import { isNilOrEmpty } from '../../../../utils/objectUtils';
import { ColDef } from 'ag-grid-community';
import DefaultColumnTypes from '../../../../components/grids/columns/Column.constants';
import CustomHeader from '../../../../components/grids/CustomHeader';
import { CurrencyExchangeRateGridDefs } from '../../../../constants/i18n/translations/termDefinitions/accounting';
import { EntityBase, RoutingValues } from '../../../../types/Shared.types';
import { useNavigate } from 'react-router-dom';
import { GridOptions } from '../../../../components/grids/Grid.constants';
import NavFormContext from '../../../../contexts/navForm.context';

export interface ExchangeRateGridProps<Entity extends EntityBase> {
    currentEntity: Entity;
    isCurrentEntityLoading: boolean;
    user?: any;
    rateTypeCode: string;
    tabIndex?: string;
}

const useSectionExchangeRateGrid = (props: ExchangeRateGridProps<Currency>) => {
    const { currentEntity, user, rateTypeCode, tabIndex } = props;
    const { accountingNav } = useContext(NavFormContext);
    const navigate = useNavigate();

    const serviceParams: CurrencyExchangeRateByTypeParams = {
        sourceCurrencyCode: currentEntity?.currencyCode,
        rateTypeCode,
    };

    const [colDefs, setColDefs] = useState([]);
    const [rowData, setRowData] = useState([]);

    const { settings } = useContext(SettingsContext);
    const { data: termSet } = useGetTermSetQuery(
        settings?.userSettings
            ? {
                  languageId: settings?.userSettings?.languageId,
                  code: ACCOUNTING_DEFS.CURRENCY_EXCHANGE_RATE_GRID,
              }
            : skipToken
    );

    const canViewExchangeRate = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.ACCOUNTING.CURRENCY_EXCHANGE_RATES.VIEW
    );

    const canCreateExchangeRate = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.ACCOUNTING.CURRENCY_EXCHANGE_RATES.CREATE
    );

    const { data: exchangeRateList, isLoading: isLoadingExchangeRates } =
        useListCurrencyExchangeRatesByTypeQuery(
            serviceParams ? serviceParams : skipToken
        );

    const [hiddenIdColumns, auditableColumns] = auditableGridColumns();

    const defaultColumnDefinitionOptions = {
        ...GridOptions.sortFilterAndWrapColumns,
        floatingFilter: true,
    };

    useEffect(() => {
        if (!isNilOrEmpty(termSet)) {
            const exchangeRateColumnDefs: ColDef[] = [
                ...hiddenIdColumns,
                {
                    field: 'sourceCurrencyCode',
                    ...DefaultColumnTypes.ShortText,
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={
                                    CurrencyExchangeRateGridDefs.SourceCurrencyCode
                                }
                            />
                        );
                    },
                },
                {
                    field: 'targetCurrencyCode',
                    ...DefaultColumnTypes.ShortText,
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={
                                    CurrencyExchangeRateGridDefs.TargetCurrencyCode
                                }
                            />
                        );
                    },
                },
                {
                    field: 'currencyRateTypeId',
                    hide: true,
                    ...DefaultColumnTypes.NumberOnly,
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={
                                    CurrencyExchangeRateGridDefs.CurrencyRateTypeId
                                }
                            />
                        );
                    },
                },
                {
                    field: 'currencyRateType.currencyRateTypeCode',
                    ...DefaultColumnTypes.ShortText,
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={
                                    CurrencyExchangeRateGridDefs.CurrencyRateTypeCode
                                }
                            />
                        );
                    },
                },
                {
                    field: 'exchangeRate',
                    ...DefaultColumnTypes.NumberOnly,
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={
                                    CurrencyExchangeRateGridDefs.ExchangeRate
                                }
                            />
                        );
                    },
                },
                {
                    field: 'effectiveAt',
                    ...DefaultColumnTypes.DateOnly,
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={
                                    CurrencyExchangeRateGridDefs.EffectiveAt
                                }
                            />
                        );
                    },
                },
                ...auditableColumns,
            ];
            setColDefs(exchangeRateColumnDefs);
        }
    }, [termSet]);

    useEffect(() => {
        if (!isLoadingExchangeRates && !isNilOrEmpty(exchangeRateList)) {
            setRowData(exchangeRateList);
            accountingNav.sourceCurrencyCode = '';
            accountingNav.rateTypeCode = '';
            accountingNav.currencyId = '';
        }
    }, [isLoadingExchangeRates]);

    const redirectToForm = (row: any) => {
        if (serviceParams) {
            accountingNav.sourceCurrencyCode = serviceParams.sourceCurrencyCode;
            accountingNav.rateTypeCode = serviceParams.rateTypeCode;
            accountingNav.currencyId = currentEntity.id.toString();
            accountingNav.tabIndex = tabIndex;
            accountingNav.isFormRedirect = true;
        }

        if (row && row.id) {
            navigate(`/accounting/currencyExchangeRate/${row.id}`);
        } else {
            navigate(`/accounting/currencyExchangeRate/${RoutingValues.newId}`);
        }
    };

    return {
        rowData,
        canViewExchangeRate,
        canCreateExchangeRate,
        colDefs,
        isLoadingExchangeRates,
        redirectToForm,
        defaultColumnDefinitionOptions,
    };
};

export default useSectionExchangeRateGrid;
