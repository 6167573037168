import { useForm } from '../../../libs/hooksLib';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Permission,
    Role,
    useCreateRoleMutation,
    useGetPermissionsForDropdownQuery,
    useGetPermissionsQuery,
    useGetRoleQuery,
    useGetUsersForDropdownQuery,
    useUpdateRoleMutation,
} from '../../../services/organizations/organizations.service';
import {
    BaseCreateEntityArgs,
    BaseUpdateEntityArgs,
} from '../../../services/serviceInterfaces';
import { skipToken } from '@reduxjs/toolkit/query';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store';
import useGetBusinessEntities from './useGetBusinessEntities';
import { showNotificationError } from '../../../libs/errorLib';
import { isNilOrEmpty } from '../../../utils/objectUtils';
import _ from 'lodash';
import { isFeatureFlagEnabled } from '../../../utils/featureFlag/featureFlagUtil';
import SettingsContext from '../../../contexts/settings.context';
import { useGetTermSetQuery } from '../../../services/i18n/i18n.service';
import { PLATFORM_DEFS } from '../../../constants/i18n/translations/termSetDefinitions/platform';

const useRoleForm = (id: string) => {
    const dispatch = useDispatch();
    const user = useSelector((state: RootState) => state.user);
    const navigate = useNavigate();
    const { fields, handleFieldChange, setValues, formMethods, isFormValid } =
        useForm({});
    const { data: role, isLoading: isLoading } = useGetRoleQuery(
        id ? { roleId: id, full: true } : skipToken
    );
    const { settings } = useContext(SettingsContext);
    const { data: termSet } = useGetTermSetQuery(
        settings?.userSettings
            ? {
                  languageId: settings?.userSettings?.languageId,
                  code: PLATFORM_DEFS.ROLES_FORM,
              }
            : skipToken
    );
    const [userPermissions, setUserPermissions] = useState([]);

    const { data: users, isLoading: isLoadingUsers } =
        useGetUsersForDropdownQuery();

    const {
        businessEntities: businessEntityOptions,
        isLoading: isLoadingBusinessEnts,
    } = useGetBusinessEntities({
        businessEntityId: user?.activeBusinessEntityId?.toString(),
        subscriberId: user?.subscriber?.id?.toString(),
    });

    const { data: permissions } = useGetPermissionsQuery();

    const [createRole] = useCreateRoleMutation();
    const [updateRole] = useUpdateRoleMutation();

    const defaultRole: Role = {
        name: '',
        description: '',
        businessEntityId: user?.businessEntity?.id,
        businessEntity: user?.businessEntity,
        permissions: [],
        users: [],
    };

    useEffect(() => {
        buildPermissions();
    }, [permissions]);

    /**
     * Get all permissions and then map the ones the user currently has feature flags for
     * **/
    const buildPermissions = () => {
        let tempPermissions: any[] = [];
        permissions?.forEach((permission: Permission) => {
            if (
                isNilOrEmpty(permission.featureFlags) ||
                _.every(permission.featureFlags, (featureFlag) => {
                    return isFeatureFlagEnabled(
                        user.enabledFeatureFlags,
                        featureFlag.code
                    );
                })
            ) {
                const dropdownPermission = {
                    label: permission.name,
                    value: permission.id,
                };
                tempPermissions.push(dropdownPermission);
            }
        });

        setUserPermissions(tempPermissions);
    };

    useEffect(() => {
        setValues(role || defaultRole);
    }, [role, user]);

    const handleCreateRole = async () => {
        if (!isFormValid) {
            return;
        }

        const body: BaseCreateEntityArgs<Role> = {
            postBody: fields,
        };

        try {
            const response: { data: any } | { error: any } = await createRole(
                body
            );
            if ('error' in response) {
                throw new Error(response.error.data?.error);
            }
            handleClose();
        } catch (e) {
            return showNotificationError(e.message, 'error', dispatch);
        }
    };

    const handleUpdateRole = async () => {
        if (!isFormValid) {
            return;
        }

        let userIds;

        //check if users have changeds
        if (fields.users === role.users) {
            userIds = null;
        } else {
            userIds = fields?.users?.map((user: any) => {
                return { id: user };
            });
        }

        const permissionIds = fields?.permissions?.map((permission: any) => {
            return { id: permission };
        });
        const body: BaseUpdateEntityArgs<Role> = {
            id: id as unknown as number,
            postBody: {
                ...fields,
                users: userIds ? [...userIds] : null,
                permissions: [...permissionIds],
            },
        };

        try {
            const response: { data: any } | { error: any } = await updateRole(
                body
            );
            if ('error' in response) {
                throw new Error(response.error.data?.error);
            }
            handleClose();
        } catch (e) {
            return showNotificationError(e.message, 'error', dispatch);
        }
    };

    const handleClose = () => {
        navigate('/administration/organizations/roles');
    };

    return {
        fields,
        user,
        role,
        users,
        permissions,
        userPermissions,
        isLoading,
        businessEntityOptions,
        handleCreateRole,
        handleUpdateRole,
        handleFieldChange,
        handleClose,
        setValues,
        formMethods,
        termSet,
    };
};

export default useRoleForm;
