import React from 'react';
import { useParams } from 'react-router-dom';
import BaseForm from '../../../../components/form/baseForm/BaseForm';
import useRDFormulaForm from './hooks/useRDFormulaForm';
import { RDFormulaHeaderForm } from './sections/rdHeader/RDFormulaHeaderForm';
import FormPartial from '../../../../components/form/formContainer/FormPartial';
import RDFormulaCasCompositionGrid from './sections/rdFormulaCasComposition/RDFormulaCasCompositionGrid';
import { RDFormulasFormDefs } from '../../../../constants/i18n/translations/termDefinitions/formulation';
import TranslatableText from '../../../../components/i18n/TranslatableText';
import RDFormulaComponentsGrid from './sections/rdFormulaComponents/RDFormulaComponentsGrid';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import Box from '@mui/material/Box';
import { Grid, Tab } from '@mui/material';
import { RDFormulaTotalsForm } from './sections/rdFormulaTotals/RDFormulaTotalsForm';
import { RD_FORMULA_FIELDS } from '../../../../constants/revisionHistory/revisionHistory.constants';
import RDFormulaHazardClassificationMappingGrid from './sections/rdFormulaHazardClassificationMapping/RDFormulaHazardClassificationMappingGrid';
import RDFormulaPublishToProductModal from './modals/RDFormulaPublishToProductModal';
import RDFormulaPublishToProductButton from './RDFormulaPublishToProductButton';

type ParamTypes = { id: string };

const RDFormulaForm = () => {
    const { id } = useParams<ParamTypes>();
    const {
        fields,
        allFormMethods,
        hasEntityChanges,
        isLoadingFormulas,
        onCreate,
        onUpdate,
        onDelete,
        closeForm,
        copyForm,
        copyMethods,
        display,
        children,
        termSet,
        currentTab,
        setCurrentTab,
        displayPublishProductButton,
        showPublishToProductModal,
        setShowPublishToProductModal,
        launchPublishToProductModal,
    } = useRDFormulaForm(id);

    const {
        displayCreateNewButton,
        displayUpdateButton,
        displayDeleteButton,
        displayCopyButton,
        readOnly,
    } = display;

    const { editableGridProps, extendedFormProps, childFormProps } = children;

    const {
        rdFormulaComponents,
        setRDFormulaComponents,
        setRDFormulaCasCompositions,
        rdFormulaCasCompositions,
        rdFormulaHazardClassificationMappings,
        setRdFormulaHazardClassificationMappings,
    } = copyMethods;

    const publishToProductButton = () => {
        return (
            <RDFormulaPublishToProductButton
                displayPublishProductButton={displayPublishProductButton}
                setShowPublishToProductModal={launchPublishToProductModal}
                isLoadingFormulas={isLoadingFormulas}
                termSet={termSet}
            />
        );
    };

    return (
        <BaseForm
            title={
                <TranslatableText
                    termSet={termSet}
                    termKey={RDFormulasFormDefs.RD_Formula_Detail_Text}
                />
            }
            formMethods={allFormMethods}
            isLoading={isLoadingFormulas}
            isNew={!id}
            hasEntityChanges={hasEntityChanges}
            onCreate={onCreate}
            onUpdate={onUpdate}
            onDelete={onDelete}
            onCancel={closeForm}
            onCopy={copyForm}
            displayCreateButton={displayCreateNewButton}
            displayUpdateButton={displayUpdateButton}
            displayDeleteButton={displayDeleteButton}
            displayCopyButton={displayCopyButton}
            showOnRevision={true}
            fieldMappings={RD_FORMULA_FIELDS}
            entityUuid={fields?.uuid}
            customToolbarButtons={publishToProductButton()}
            readOnly={readOnly}>
            <RDFormulaPublishToProductModal
                rdFormulaRecord={fields}
                open={showPublishToProductModal}
                onClose={() => setShowPublishToProductModal(false)}
            />
            <RDFormulaHeaderForm {...extendedFormProps} />
            <FormPartial
                title={
                    (
                        <TranslatableText
                            termSet={termSet}
                            termKey={RDFormulasFormDefs.Component_Text}
                        />
                    ) as unknown as string
                }
                isVisible={true}>
                <TabContext value={currentTab}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList
                            onChange={(event, value: string) =>
                                setCurrentTab(value)
                            }>
                            <Tab
                                label={
                                    (
                                        <TranslatableText
                                            termSet={termSet}
                                            termKey={
                                                RDFormulasFormDefs.Component_Text
                                            }
                                        />
                                    ) as unknown as string
                                }
                                value="0"
                            />
                            <Tab
                                label={
                                    (
                                        <TranslatableText
                                            termSet={termSet}
                                            termKey={
                                                RDFormulasFormDefs.Equation_Text
                                            }
                                        />
                                    ) as unknown as string
                                }
                                value="1"
                            />
                        </TabList>
                    </Box>
                    <TabPanel value="0">
                        <Grid container spacing={2}>
                            <RDFormulaComponentsGrid
                                {...editableGridProps}
                                copyMethods={{
                                    rowsData: rdFormulaComponents,
                                    setRowsData: setRDFormulaComponents,
                                }}
                            />
                        </Grid>
                    </TabPanel>
                </TabContext>
            </FormPartial>
            <RDFormulaTotalsForm {...childFormProps} />
            <FormPartial
                title={
                    (
                        <TranslatableText
                            termSet={termSet}
                            termKey={RDFormulasFormDefs.CAS_Compositions_Text}
                        />
                    ) as unknown as string
                }>
                <RDFormulaCasCompositionGrid
                    {...editableGridProps}
                    copyMethods={{
                        rowsData: rdFormulaCasCompositions,
                        setRowsData: setRDFormulaCasCompositions,
                    }}
                />
            </FormPartial>
            <RDFormulaHazardClassificationMappingGrid
                {...editableGridProps}
                copyMethods={{
                    rowsData: rdFormulaHazardClassificationMappings,
                    setRowsData: setRdFormulaHazardClassificationMappings,
                }}
            />
        </BaseForm>
    );
};

export default RDFormulaForm;
