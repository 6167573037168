import DatacorLogoSpinner from '../../../../../../components/datacorLogoSpinner/DatacorLogoSpinner';
import React from 'react';
import FormPartial from '../../../../../../components/form/formContainer/FormPartial';
import SubSection5_1Form from './subSections/subSection5_1/SubSection5_1Form';
import SubSection5_2Form from './subSections/subSection5_2/SubSection5_2Form';
import SubSection5_3Form from './subSections/subSection5_3/SubSection5_3Form';
import { SdsBlobSectionFormProps } from '../shared/blobs/hooks/SdsBlobSectionFormProps';
import { SdsHeader } from '../../../../../../types/formulation';
import TranslatableText from '../../../../../../components/i18n/TranslatableText';
import { SDSFormDefs } from '../../../../../../constants/i18n/translations/termDefinitions/formulation';

const SdsFirefightingMeasuresForm = (
    props: SdsBlobSectionFormProps<SdsHeader>
) => {
    const { isParentLoading } = props.parentData;

    return (
        <>
            {isParentLoading ? (
                <DatacorLogoSpinner />
            ) : (
                <>
                    <FormPartial
                        link={props?.link}
                        title={
                            (
                                <TranslatableText
                                    termSet={props.termSet}
                                    termKey={SDSFormDefs.Firefighting_Measures}
                                />
                            ) as unknown as string
                        }>
                        <SubSection5_1Form {...props} termSet={props.termSet} />
                        <SubSection5_2Form {...props} termSet={props.termSet} />
                        <SubSection5_3Form {...props} termSet={props.termSet} />
                    </FormPartial>
                </>
            )}
        </>
    );
};

export default SdsFirefightingMeasuresForm;
