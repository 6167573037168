import { AgGridColumn } from 'ag-grid-react';
import React from 'react';
import AgGrid from '../../components/grids/Grid';
import DatacorLogoSpinner from '../../components/datacorLogoSpinner/DatacorLogoSpinner';
import useUsersGrid from './hooks/useUsersGrid';
import { GRID_SETTINGS } from '../../constants/entitySettings/entitySettings.constants';
import { UserGridDefs } from '../../constants/i18n/translations/termDefinitions/platform';
import CustomHeader from '../../components/grids/CustomHeader';
import { dateValueComparator } from '../../utils/comparisonUtils';
import { dateFormatter } from '../../utils/formattingUtils';

const UsersGrid = () => {
    const {
        onGridReady,
        subscriberUsers,
        isLoading,
        isLoadingSubscriberUsers,
        redirectToForm,
        onFirstDataRendered,
        canCreateUser,
        termSet,
        onSortChanged,
    } = useUsersGrid();

    return (
        <>
            {!isLoading && !isLoadingSubscriberUsers && termSet ? (
                <AgGrid
                    displayEntitySettings={true}
                    settingKey={GRID_SETTINGS.USER_GRID}
                    testId="user-list-grid"
                    onSortChanged={onSortChanged}
                    dataKey="id"
                    immutableData={true}
                    rowData={subscriberUsers}
                    displayToolbar={true}
                    onCreateNew={(row: any) => redirectToForm(row)}
                    displayCreateNewButton={canCreateUser}
                    onRowClicked={({ data }: any) => redirectToForm(data)}
                    onFirstDataRendered={onFirstDataRendered}
                    onGridReady={onGridReady}
                    defaultColDef={{
                        resizable: true,
                        sortable: true,
                        unSortIcon: true,
                        filter: true,
                        wrapText: true,
                        floatingFilter: true,
                    }}>
                    <AgGridColumn
                        flex={1}
                        filter={'agTextColumnFilter'}
                        minWidth={250}
                        field="firstName"
                        sortable={true}
                        headerComponentFramework={(props: any) => {
                            return (
                                <CustomHeader
                                    {...props}
                                    termSet={termSet}
                                    termKey={UserGridDefs.First_Name}
                                />
                            );
                        }}
                    />
                    <AgGridColumn
                        minWidth={200}
                        filter={'agTextColumnFilter'}
                        field="lastName"
                        headerComponentFramework={(props: any) => {
                            return (
                                <CustomHeader
                                    {...props}
                                    termSet={termSet}
                                    termKey={'user-grid-last-name'}
                                />
                            );
                        }}
                    />
                    <AgGridColumn
                        minWidth={200}
                        filter={'agTextColumnFilter'}
                        field="externalUser"
                        headerComponentFramework={(props: any) => {
                            return (
                                <CustomHeader
                                    {...props}
                                    termSet={termSet}
                                    termKey={'user-grid-external-username'}
                                />
                            );
                        }}
                    />
                    <AgGridColumn
                        minWidth={200}
                        filter={'agTextColumnFilter'}
                        field="email"
                        headerComponentFramework={(props: any) => {
                            return (
                                <CustomHeader
                                    {...props}
                                    termSet={termSet}
                                    termKey={'user-grid-email'}
                                />
                            );
                        }}
                    />
                    <AgGridColumn
                        minWidth={250}
                        filter={'agTextColumnFilter'}
                        field="mobilePhone"
                        headerComponentFramework={(props: any) => {
                            return (
                                <CustomHeader
                                    {...props}
                                    termSet={termSet}
                                    termKey={'user-grid-phone'}
                                />
                            );
                        }}
                    />
                    <AgGridColumn
                        minWidth={250}
                        field="active"
                        filterParams={{
                            valueGetter: (params: any) =>
                                params.data.active ? 'Active' : 'Inactive',
                            cellRenderer: 'agAnimateShowChangeCellRenderer',
                            cellRendererParams: {
                                flashDelay: 1000,
                                suppressFlash: false,
                                fadeDelay: 0,
                                initialValue: true,
                                suppressInitialFade: true,
                                template: '{{value}}',
                            },
                        }}
                        headerComponentFramework={(props: any) => {
                            return (
                                <CustomHeader
                                    {...props}
                                    termSet={termSet}
                                    termKey={'user-grid-active'}
                                />
                            );
                        }}
                    />
                    <AgGridColumn
                        minWidth={250}
                        filter={'agTextColumnFilter'}
                        field="businessEntity.name"
                        headerComponentFramework={(props: any) => {
                            return (
                                <CustomHeader
                                    {...props}
                                    termSet={termSet}
                                    termKey={'user-grid-business-entity'}
                                />
                            );
                        }}
                    />
                    <AgGridColumn
                        minWidth={200}
                        filter={'agDateColumnFilter'}
                        field="createdAt"
                        filterParams={dateValueComparator}
                        headerComponentFramework={(props: any) => {
                            return (
                                <CustomHeader
                                    {...props}
                                    termSet={termSet}
                                    termKey={'user-grid-date-created'}
                                />
                            );
                        }}
                        valueFormatter={dateFormatter}
                    />
                    <AgGridColumn
                        minWidth={200}
                        filter={'agDateColumnFilter'}
                        filterParams={dateValueComparator}
                        field="updatedAt"
                        headerComponentFramework={(props: any) => {
                            return (
                                <CustomHeader
                                    {...props}
                                    termSet={termSet}
                                    termKey={'user-grid-last-update-date'}
                                />
                            );
                        }}
                        valueFormatter={dateFormatter}
                    />
                </AgGrid>
            ) : (
                <DatacorLogoSpinner />
            )}
        </>
    );
};

export default UsersGrid;
