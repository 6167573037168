import { useContext, useEffect, useState } from 'react';
import { useForm } from '../../../../../../../libs/hooksLib';
import {
    ItemMaster,
    ItemUnitOfMeasure,
} from '../../../../../../../types/formulation';
import { ChildFormProps } from '../../../../../../form/helpers/ChildFormProps';
import {
    useGetUnitTypesForDropdownQuery,
    useListUnitOfMeasureDropdownQuery,
} from '../../../../../../../services/organizations/organizations.service';
import { PermissionsUtil } from '../../../../../../../utils/permissions/permissionsUtil';
import { PERMISSIONS } from '../../../../../../../constants/permissions/Permissions.constants';
import SettingsContext from '../../../../../../../contexts/settings.context';
import { useGetTermSetQuery } from '../../../../../../../services/i18n/i18n.service';
import { FORMULATION_DEFS } from '../../../../../../../constants/i18n/translations/termSetDefinitions/formulation';
import { skipToken } from '@reduxjs/toolkit/query';

const useItemUnitsOfMeasureForm = (props: ChildFormProps<ItemMaster>) => {
    const { currentParentRecord, isParentLoading, user, dataFromQuery } =
        props.parentData;

    const { handleChildRecord, registerChildForm } = props.helpers;

    const { data: unitOfMeasureOptions, isLoading: isLoadingUnitOfMeasure } =
        useListUnitOfMeasureDropdownQuery();
    const { data: unitTypeOptions, isLoading: isLoadingUnitTypes } =
        useGetUnitTypesForDropdownQuery();

    const canViewUnitOfMeasure = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.SETUP.UNITS_OF_MEASURE.VIEW
    );

    const initialState: any = {
        stockInputValue: '',
        weightInputValue: '',
        volumeInputValue: '',
        stockUnitOptions: [],
        weightUnitOptions: [],
        volumeUnitOptions: [],
        unitOfMeasureOptions: [],
        selectedStockUnit: null,
        selectedWeightUnit: null,
        selectedVolumeUnit: null,
    };

    const [dropdownOptions, setDropdownOptions] = useState(initialState);

    const blankItemUnitOfMeasure: ItemUnitOfMeasure = {
        stockUnitId: null as bigint,
        weightUnitId: null as bigint,
        volumeUnitId: null as bigint,
        specificGravity: null as number,
        density: null as number,
        stockWeight: null as number,
        stockVolume: null as number,
    };

    const { fields, handleFieldChange, setValues, formMethods, verifyChanges } =
        useForm(blankItemUnitOfMeasure, true);

    const { setOriginalEntity, clearChangesHistory, hasCurrentRecordChanged } =
        verifyChanges.childForm;

    const { settings } = useContext(SettingsContext);

    const { data: termSet } = useGetTermSetQuery(
        settings?.userSettings
            ? {
                  languageId: settings?.userSettings?.languageId,
                  code: FORMULATION_DEFS.ITEM_MASTER_FORM,
              }
            : skipToken
    );

    const handlePropertyChanged = (event: any) => {
        handleFieldChange(event);
        handleChildRecord('itemUnitOfMeasure', event);
    };

    useEffect(() => {
        if (!isParentLoading) {
            registerChildForm('itemUnitOfMeasure', {
                formMethods,
                hasEntityChanges: hasCurrentRecordChanged,
                clearChangesHistory,
            });
        }
    }, [fields, isParentLoading, formMethods.isFormValid]);

    useEffect(() => {
        if (!isParentLoading) {
            setOriginalEntity(
                dataFromQuery?.itemUnitOfMeasure || blankItemUnitOfMeasure
            );
            setValues(
                currentParentRecord?.itemUnitOfMeasure || blankItemUnitOfMeasure
            );
        }
    }, [
        currentParentRecord?.itemUnitOfMeasure,
        isParentLoading,
        dataFromQuery,
    ]);

    useEffect(() => {
        if (
            !isLoadingUnitOfMeasure &&
            !isLoadingUnitTypes &&
            unitTypeOptions &&
            unitOfMeasureOptions
        ) {
            const unitTypeOther = unitTypeOptions.find(
                (current: any) => current.label === 'Other'
            ).value;

            const unitTypeWeight = unitTypeOptions.find(
                (current: any) => current.label === 'Weight'
            ).value;

            const unitTypeVolume = unitTypeOptions.find(
                (current: any) => current.label === 'Volume'
            ).value;

            setDropdownOptions((previousValues: any) => ({
                ...previousValues,
                stockUnitOptions: unitOfMeasureOptions.filter(
                    (current: any) => current.unitTypeId !== unitTypeOther
                ),
                volumeUnitOptions: unitOfMeasureOptions.filter(
                    (current: any) => current.unitTypeId === unitTypeVolume
                ),
                weightUnitOptions: unitOfMeasureOptions.filter(
                    (current: any) => current.unitTypeId === unitTypeWeight
                ),
                selectedStockUnit: unitOfMeasureOptions?.find(
                    (current: any) => current.value === fields.stockUnitId
                ),
                selectedWeightUnit: unitOfMeasureOptions?.find(
                    (current: any) => current.value === fields.weightUnitId
                ),
                selectedVolumeUnit: unitOfMeasureOptions?.find(
                    (current: any) => current.value === fields.volumeUnitId
                ),
            }));
        }
    }, [
        unitOfMeasureOptions,
        isLoadingUnitOfMeasure,
        isLoadingUnitTypes,
        unitTypeOptions,
        fields,
    ]);

    return {
        fields,
        handlePropertyChanged,
        dropdownOptions,
        setDropdownOptions,
        isLoadingUnitOfMeasure,
        formMethods,
        isLoadingUnitTypes,
        canViewUnitOfMeasure,
        termSet,
    };
};

export default useItemUnitsOfMeasureForm;
