import { InputBaseProps } from '../InputBase/InputBase';
import TextInput from '../TextInput/TextInput';
import Autocomplete from '@mui/material/Autocomplete';
import React from 'react';

export type AutoCompleteInputProps = {
    inputFieldName: string;
    inputValue: any;
    isLoading: boolean;
    onHandlePropertyChanged: (event: any) => void;
    options?: any[];
    setDropdownOptions: React.Dispatch<React.SetStateAction<any>>;
    defaultOption?: any;
} & InputBaseProps;

const AutoCompleteInput = (props: AutoCompleteInputProps) => {
    return (
        <Autocomplete
            className="basic-select"
            id={props.id}
            options={props.options || []}
            getOptionLabel={(option: any) => `${option.label}`}
            onChange={(event: any, value: any) => {
                props.onHandlePropertyChanged({
                    target: {
                        id: props.id,
                        value: value?.value || props.defaultOption || null,
                    },
                });
            }}
            onInputChange={(event, newInput) => {
                props.setDropdownOptions((previousValues: any) => ({
                    ...previousValues,
                    [props.inputFieldName]: newInput,
                }));
            }}
            inputValue={props.inputValue}
            loading={props.isLoading}
            value={(props.value as any) || null}
            renderInput={(params) => (
                <TextInput
                    {...params}
                    formMethods={props.formMethods}
                    label={props.label}
                    required={props.required}
                />
            )}
            disabled={props.disabled}
        />
    );
};

export default AutoCompleteInput;
