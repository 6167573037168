import React from 'react';
import { Navigate, Route, Routes as ReactRoutes } from 'react-router-dom';
import Home from '../containers/Home';
import { AUTHENTICATED_ROUTES, UNAUTHENTICATED_ROUTES } from './routesConfig';
import NotFound from '../containers/NotFound';
import useRoutes from './hooks/useRoutes';
import { isNil } from '../utils/objectUtils';
const Routes = () => {
    const { user, redirect, pathname, search, shouldLoadRoute } = useRoutes();
    return (
        <ReactRoutes>
            <Route path="/" element={<Home />} />
            {/* ensure that the user is not authenticated before we render component.*/}
            {UNAUTHENTICATED_ROUTES.map((props) => (
                <Route
                    path={`${props.path}/*`}
                    key={props.path}
                    element={
                        !user.isSignedIn ? (
                            <props.component />
                        ) : (
                            <Navigate
                                to={isNil(redirect) ? '/' : redirect}
                                replace
                            />
                        )
                    }
                />
            ))}
            {/* creates a Route where its children are rendered only if the user is authenticated.*/}
            {/* If the user is not authenticated, then it redirects to the login page.*/}
            {AUTHENTICATED_ROUTES.map((props) => (
                <Route
                    path={`${props.path}/*`}
                    key={props.path}
                    element={
                        shouldLoadRoute(
                            props.featureFlag,
                            props.permissionKey,
                            props.datacorAdminOnly
                        ) ? (
                            user.isSignedIn ? (
                                <props.component />
                            ) : (
                                <Navigate
                                    to={`/login?redirect=${pathname}${search}`}
                                    replace
                                />
                            )
                        ) : (
                            <NotFound />
                        )
                    }
                />
            ))}
            {/* Finally, catch all unmatched routes */}
            <Route path="*" element={<NotFound />} />
        </ReactRoutes>
    );
};
export default Routes;
