import React, { useContext, useEffect, useState } from 'react';
import {
    FA_Asset,
    FA_DepreciationEntry,
    FixedAssetDepreciationEntryListParams,
    FA_AssetType,
} from '../../../types/FixedAsset.types';
import _ from 'lodash';
import {
    GridReadyEvent,
    ICellRendererParams,
    RowNode,
    SelectionChangedEvent,
} from 'ag-grid-community';
import { DetailGridInfo, GridApi } from 'ag-grid-community/dist/lib/gridApi';
import { Button, Link } from '@mui/material';
import ListIcon from '@mui/icons-material/List';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import {
    currencyFormatter,
    dateFormatter,
} from '../../../utils/formattingUtils';
import { AssetScheduleGridMode } from './useFixedAssetSchedulesGrid';
import { useGetAllFixedAssetDepreciationEntriesQuery } from '../../../services/fixedAssets/fixedAssets.service';
import { skipToken } from '@reduxjs/toolkit/query';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { GL_Account } from '../../../types/Accounting.types';
import { PermissionsUtil } from '../../../utils/permissions/permissionsUtil';
import { PERMISSIONS } from '../../../constants/permissions/Permissions.constants';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import SettingsContext from '../../../contexts/settings.context';
import { useGetTermSetQuery } from '../../../services/i18n/i18n.service';
import { ACCOUNTING_DEFS } from '../../../constants/i18n/translations/termSetDefinitions/accounting';
import TranslatableText from '../../../components/i18n/TranslatableText';
import { DepreciationEntriesGridDefs } from '../../../constants/i18n/translations/termDefinitions/accounting';

export enum AssetEntryGridMode {
    Default = '0',
    ByDate = '1',
    ByAssetType = '2',
}

export class FixedAssetEntryRow {
    public readonly id: string;
    public readonly assetType: FA_AssetType;
    public readonly intervalDate: Date;
    public readonly entriesByAccount: FixedAssetEntryAccountRow[] = [];
    public readonly entriesByAsset: FixedAssetEntryAssetRow[] = [];

    constructor(entry: FA_DepreciationEntry) {
        this.id = `${entry.assetTypeId}|${entry.intervalDate}`;
        this.assetType = entry.assetType;
        this.intervalDate = entry.intervalDate;
        this.addEntry(entry);
    }

    public addEntry = (entry: FA_DepreciationEntry) => {
        const existingEntry = this.entriesByAccount.find(
            (accountRow) => accountRow.account.id === entry.accountId
        );

        if (existingEntry) {
            existingEntry.entries.push(entry);
        } else {
            this.entriesByAccount.push(new FixedAssetEntryAccountRow(entry));
        }

        const existingAsset = this.entriesByAsset.find(
            (assetRow) => assetRow.fixedAsset.id === entry.fixedAssetId
        );

        if (existingAsset) {
            existingAsset.entries.push(entry);
        } else {
            this.entriesByAsset.push(new FixedAssetEntryAssetRow(entry));
        }
    };

    public get accountList(): string {
        return this.entriesByAccount
            .map((accountRow) => accountRow.account.number)
            .join('\r\n');
    }

    public get entryDescriptionList(): string {
        return this.entriesByAccount
            .map(
                (accountRow) =>
                    `${accountRow.account.name} - ${this.assetType.code}`
            )
            .join('\r\n');
    }

    public get debitList(): string {
        return this.entriesByAccount
            .map((accountRow) => currencyFormatter(accountRow.totalDebit))
            .join('\r\n');
    }

    public get creditList(): string {
        return this.entriesByAccount
            .map((accountRow) => currencyFormatter(accountRow.totalCredit))
            .join('\r\n');
    }

    public get allEntries(): FA_DepreciationEntry[] {
        return _.flatten(
            this.entriesByAccount.map((accountRow) => accountRow.entries)
        );
    }

    public get hasUnlocked(): boolean {
        return this.allEntries.some((entry) => !entry.locked);
    }

    public get lockedScheduleSummary(): string {
        const totalLocked: number = this.entriesByAsset.filter(
            (assetRow) => assetRow.locked
        ).length;
        return `${totalLocked} of ${this.entriesByAsset.length}`;
    }
}

export class FixedAssetEntryAccountRow {
    public readonly account: GL_Account;
    public readonly entryDate: Date;
    public readonly entryDescription: string;
    public readonly entries: FA_DepreciationEntry[];

    constructor(entry: FA_DepreciationEntry) {
        this.account = entry.account;
        this.entryDate = entry.intervalDate;
        this.entryDescription = `${entry.account.name} - ${entry.assetType.code}`;
        this.entries = [entry];
    }

    public get totalDebit(): number {
        return _.sumBy(this.entries, (entry) => Number(entry.debit));
    }

    public get totalCredit(): number {
        return _.sumBy(this.entries, (entry) => Number(entry.credit));
    }
}

export class FixedAssetEntryAssetRow {
    public readonly id: string;
    public readonly fixedAsset: FA_Asset;
    public readonly interval: number;
    public readonly entryDate: Date;
    public readonly entries: FA_DepreciationEntry[];

    constructor(entry: FA_DepreciationEntry) {
        //grouping for the debit and credit for a given fixed asset
        this.id = entry.fixedAssetId.toString();
        this.fixedAsset = entry.fixedAsset;
        this.interval = entry.interval;
        this.entryDate = entry.intervalDate;
        this.entries = [entry];
    }

    private get firstEntry(): FA_DepreciationEntry {
        return _.head(this.entries);
    }

    public get locked(): boolean {
        return this.firstEntry.locked;
    }

    public get lockedDate(): Date {
        return this.firstEntry.lockedDate;
    }

    public get lockedUserName(): string {
        return this.firstEntry.lockedUserName;
    }

    public get lockedScheduleSummary(): string {
        if (this.locked) {
            return `${dateFormatter(this.lockedDate)} (${this.lockedUserName})`;
        } else {
            return '';
        }
    }

    public get accountNumberList(): string {
        return this.entries.map((entry) => entry.account.number).join('\r\n');
    }

    public get accountNameList(): string {
        return this.entries.map((entry) => entry.account.name).join('\r\n');
    }

    public get debitList(): string {
        return this.entries
            .map((entry) => currencyFormatter(entry.debit))
            .join('\r\n');
    }

    public get creditList(): string {
        return this.entries
            .map((entry) => currencyFormatter(entry.credit))
            .join('\r\n');
    }
}

const useFixedAssetDepreciationEntriesGrid = () => {
    const user = useSelector((state: RootState) => state.user);
    const { settings } = useContext(SettingsContext);
    const { data: termSet } = useGetTermSetQuery(
        settings?.userSettings
            ? {
                  languageId: settings?.userSettings?.languageId,
                  code: ACCOUNTING_DEFS.DEPRECIATION_ENTRIES_GRID,
              }
            : skipToken
    );
    const canDownloadDepreciationEntries = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.FIXED_ASSETS.DEPRECIATION_ENTRIES.DOWNLOAD
    );
    const canLockDepreciationEntries = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.FIXED_ASSETS.DEPRECIATION_ENTRIES.LOCK
    );

    const canViewDepreciationEntries = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.FIXED_ASSETS.DEPRECIATION_ENTRIES.VIEW
    );

    const [showOptionsModal, setShowOptionsModal] = useState<boolean>(true);
    const [showActionsModal, setShowActionsModal] = useState<boolean>(false);
    const [showExportModal, setShowExportModal] = useState<boolean>(false);

    const [depreciationEntryList, setDepreciationEntryList] = useState<
        FA_DepreciationEntry[]
    >([]);
    const [depreciationEntries, setDepreciationEntries] = useState<
        FixedAssetEntryRow[]
    >([]);
    const [gridApi, setGridApi] = useState<GridApi>(null);
    const [hasSelectedRows, setHasSelectedRows] = useState<boolean>(false);
    const [selectedEntries, setSelectedEntries] = useState<
        FA_DepreciationEntry[]
    >([]);

    const [hasUnlockedSelected, setHasUnlockedSelected] =
        useState<boolean>(true);
    const [selectedAccounts, setSelectedAccounts] = useState<
        FixedAssetEntryAccountRow[]
    >([]);

    const [currentGridMode, setCurrentGridMode] = useState<AssetEntryGridMode>(
        AssetEntryGridMode.Default
    );
    const [currentTab, setCurrentTab] = useState<string>(
        AssetScheduleGridMode.Default
    );
    const [currentTabTitle, setCurrentTabTitle] = useState<string>(
        'Fixed Asset Depreciation Entries'
    );

    const [subGridParams, setSubGridParams] =
        useState<FixedAssetDepreciationEntryListParams>(null);
    const [subGridEntries, setSubGridEntries] = useState<FixedAssetEntryRow[]>(
        []
    );
    const [subGridApi, setSubGridApi] = useState<GridApi>(null);
    const [subGridHasSelectedRows, setSubGridHasSelectedRows] =
        useState<boolean>(false);
    const { data: subGridEntryList, isFetching: isLoadingSubGrid } =
        useGetAllFixedAssetDepreciationEntriesQuery(
            subGridParams ? subGridParams : skipToken
        );

    useEffect(() => {
        setDepreciationEntries(_createRowsFromList(depreciationEntryList));
    }, [depreciationEntryList]);

    useEffect(() => {
        setSubGridEntries(_createRowsFromList(subGridEntryList));
    }, [subGridEntryList]);

    useEffect(() => {
        if (currentGridMode === AssetEntryGridMode.Default) {
            setCurrentTab('0');
            setSubGridApi(null);
        } else {
            setCurrentTab('1');
            setGridApi(null);
        }
    }, [currentGridMode]);

    const _createRowsFromList = (
        list: FA_DepreciationEntry[]
    ): FixedAssetEntryRow[] => {
        const rowMap: Map<string, FixedAssetEntryRow> = new Map<
            string,
            FixedAssetEntryRow
        >();

        list?.forEach((entry) => {
            //establish the row key for uniqueness
            const rowKey: string = `${entry.assetTypeId}|${entry.intervalDate}`;

            if (!rowMap.has(rowKey)) {
                rowMap.set(rowKey, new FixedAssetEntryRow(entry));
            } else {
                rowMap.get(rowKey).addEntry(entry);
            }
        });

        return Array.from(rowMap.values());
    };

    const entryToolbar = () => {
        return (
            <>
                <Button
                    variant="text"
                    size="small"
                    onClick={() => setShowOptionsModal(true)}
                    startIcon={<ListIcon />}>
                    <TranslatableText
                        termSet={termSet}
                        termKey={DepreciationEntriesGridDefs.Toolbar_Options}
                    />
                </Button>
                <Button
                    variant="text"
                    size="small"
                    disabled={!hasSelectedRows}
                    onClick={() => _handleActionsButtonClicked()}
                    startIcon={
                        <ArrowForwardIcon style={{ color: '#00A84E' }} />
                    }>
                    <TranslatableText
                        termSet={termSet}
                        termKey={DepreciationEntriesGridDefs.Toolbar_Actions}
                    />
                </Button>
                <Button
                    variant="text"
                    size="small"
                    disabled={hasUnlockedSelected}
                    onClick={() => _handleExportButtonClicked()}
                    startIcon={<FileDownloadIcon />}>
                    <TranslatableText
                        termSet={termSet}
                        termKey={
                            DepreciationEntriesGridDefs.Toolbar_Download_Entries
                        }
                    />
                </Button>
            </>
        );
    };

    const _handleActionsButtonClicked = () => {
        let selectedEntries: FA_DepreciationEntry[] = [];

        gridApi.getSelectedRows().forEach((row: FixedAssetEntryRow) => {
            row.allEntries.forEach((entry) => selectedEntries.push(entry));
        });

        setSelectedEntries(selectedEntries);
        setShowActionsModal(true);
    };

    const _handleExportButtonClicked = () => {
        let selectedAccounts: FixedAssetEntryAccountRow[] = [];

        gridApi.getSelectedRows().forEach((row: FixedAssetEntryRow) => {
            row.entriesByAccount.forEach((account) =>
                selectedAccounts.push(account)
            );
        });

        setSelectedAccounts(selectedAccounts);
        setShowExportModal(true);
    };

    const handleUpdatedEntriesList = (list: FA_DepreciationEntry[]) => {
        _handleUpdateEntries(list, depreciationEntries, gridApi);
        _handleUpdateEntries(list, subGridEntries, subGridApi);
    };

    const _handleUpdateEntries = (
        updatedList: FA_DepreciationEntry[],
        originalList: FixedAssetEntryRow[],
        api: GridApi
    ) => {
        updatedList?.forEach((entry) => {
            const row: FixedAssetEntryRow = originalList.find(
                (row) =>
                    row.assetType.id === entry.assetTypeId &&
                    row.intervalDate === entry.intervalDate
            );

            if (row) {
                const accountRow: FixedAssetEntryAccountRow =
                    row.entriesByAccount.find(
                        (accountRow) =>
                            accountRow.account.id === entry.accountId
                    );
                const entryIndex: number = _.findIndex(
                    accountRow.entries,
                    (rowEntry) => rowEntry.id === entry.id
                );
                accountRow.entries[entryIndex] = entry;

                const assetRow: FixedAssetEntryAssetRow =
                    row.entriesByAsset.find(
                        (assetRow) =>
                            assetRow.fixedAsset.id === entry.fixedAssetId
                    );
                const assetEntryIndex: number = _.findIndex(
                    assetRow.entries,
                    (rowEntry) => rowEntry.id === entry.id
                );
                assetRow.entries[assetEntryIndex] = entry;

                if (api) {
                    //need to update the detail grid manually if showing...?
                    const detailGridInfo: DetailGridInfo =
                        api.getDetailGridInfo(`detail_${row.id}`);
                    if (detailGridInfo) {
                        const detailRowNode: RowNode =
                            detailGridInfo.api.getRowNode(assetRow.id);
                        detailRowNode && detailRowNode.updateData(assetRow);
                    }
                }
            }
        });

        _checkForUnlockedSelected();
    };

    const onGridReady = (event: GridReadyEvent) => {
        setGridApi(event.api);
    };

    const onSelectionChanged = (event: SelectionChangedEvent) => {
        setHasSelectedRows(event.api.getSelectedRows().length > 0);
        _checkForUnlockedSelected();
    };

    const _checkForUnlockedSelected = () => {
        const selectedRows: FixedAssetEntryRow[] =
            gridApi?.getSelectedRows() || [];
        setHasUnlockedSelected(
            selectedRows.length === 0 ||
                selectedRows.some((row) => row.hasUnlocked)
        );
    };

    const dateColumnRenderer = (params: ICellRendererParams) => {
        return (
            <Link
                component="button"
                underline="none"
                onClick={() => _handleDateLinkClicked(params.data)}>
                {dateFormatter(params.value)}
            </Link>
        );
    };

    const _handleDateLinkClicked = (row: FixedAssetEntryRow) => {
        setSubGridParams({
            startDate: row.intervalDate,
            endDate: row.intervalDate,
        });
        setCurrentTabTitle(
            `Depreciation Entries For ${dateFormatter(row.intervalDate)}`
        );
        setCurrentGridMode(AssetEntryGridMode.ByDate);
    };

    const assetTypeColumnRenderer = (params: ICellRendererParams) => {
        return (
            <Link
                component="button"
                underline="none"
                onClick={() => _handleAssetTypeLinkClicked(params.data)}>
                {params.value}
            </Link>
        );
    };

    const _handleAssetTypeLinkClicked = (row: FixedAssetEntryRow) => {
        setSubGridParams({ assetTypeIds: [row.assetType.id] });
        setCurrentTabTitle(`Depreciation Entries For ${row.assetType.code}`);
        setCurrentGridMode(AssetEntryGridMode.ByAssetType);
    };

    const truncatedDiv = (key: string, text: string) => {
        return (
            <div
                key={key}
                style={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                }}>
                {text}
            </div>
        );
    };

    const accountColumnRenderer = (params: ICellRendererParams) => {
        const row: FixedAssetEntryRow = params.data;

        return (
            <div>
                {row.entriesByAccount.map((accountRow) => {
                    return truncatedDiv(
                        accountRow.account.id.toString(),
                        accountRow.account.number
                    );
                })}
            </div>
        );
    };

    const assetAccountColumnRenderer = (params: ICellRendererParams) => {
        const row: FixedAssetEntryAssetRow = params.data;

        return (
            <div>
                {row.entries.map((entry) => {
                    return truncatedDiv(
                        entry.account.id.toString(),
                        entry.account.number
                    );
                })}
            </div>
        );
    };

    const entryDescriptionColumnRenderer = (params: ICellRendererParams) => {
        const row: FixedAssetEntryRow = params.data;

        return (
            <div>
                {row.entriesByAccount.map((accountRow) => {
                    return truncatedDiv(
                        accountRow.account.id.toString(),
                        accountRow.entryDescription
                    );
                })}
            </div>
        );
    };

    const assetAccountNameColumnRenderer = (params: ICellRendererParams) => {
        const row: FixedAssetEntryAssetRow = params.data;

        return (
            <div>
                {row.entries.map((entry) => {
                    return truncatedDiv(
                        entry.account.id.toString(),
                        entry.account.name
                    );
                })}
            </div>
        );
    };

    const debitColumnRenderer = (params: ICellRendererParams) => {
        const row: FixedAssetEntryRow = params.data;

        return (
            <div>
                {row.entriesByAccount.map((accountRow) => {
                    return truncatedDiv(
                        accountRow.account.id.toString(),
                        currencyFormatter(accountRow.totalDebit)
                    );
                })}
            </div>
        );
    };

    const assetDebitColumnRenderer = (params: ICellRendererParams) => {
        const row: FixedAssetEntryAssetRow = params.data;

        return (
            <div>
                {row.entries.map((entry) => {
                    return truncatedDiv(
                        entry.account.id.toString(),
                        currencyFormatter(entry.debit)
                    );
                })}
            </div>
        );
    };

    const creditColumnRenderer = (params: ICellRendererParams) => {
        const row: FixedAssetEntryRow = params.data;

        return (
            <div>
                {row.entriesByAccount.map((accountRow) => {
                    return truncatedDiv(
                        accountRow.account.id.toString(),
                        currencyFormatter(accountRow.totalCredit)
                    );
                })}
            </div>
        );
    };

    const assetCreditColumnRenderer = (params: ICellRendererParams) => {
        const row: FixedAssetEntryAssetRow = params.data;

        return (
            <div>
                {row.entries.map((entry) => {
                    return truncatedDiv(
                        entry.account.id.toString(),
                        currencyFormatter(entry.credit)
                    );
                })}
            </div>
        );
    };

    const subGridToolbar = () => {
        return (
            <>
                <Button
                    variant="text"
                    size="small"
                    onClick={() => _handleReturnToMainGrid()}
                    startIcon={<ArrowBackIcon />}>
                    Back
                </Button>
                <Button
                    variant="text"
                    size="small"
                    disabled={!subGridHasSelectedRows}
                    onClick={() => _handleSubGridActionsButtonClicked()}
                    startIcon={
                        <ArrowForwardIcon style={{ color: '#00A84E' }} />
                    }>
                    Actions
                </Button>
            </>
        );
    };

    const _handleReturnToMainGrid = () => {
        setSubGridParams(null);
        setCurrentTabTitle('Fixed Asset Depreciation Entries');
        setCurrentGridMode(AssetEntryGridMode.Default);
    };

    const _handleSubGridActionsButtonClicked = () => {
        let selectedEntries: FA_DepreciationEntry[] = [];

        subGridApi.forEachDetailGridInfo((detailGrid) => {
            detailGrid.api
                .getSelectedRows()
                .forEach((assetRow: FixedAssetEntryAssetRow) => {
                    assetRow.entries.forEach((entry: FA_DepreciationEntry) =>
                        selectedEntries.push(entry)
                    );
                });
        });

        setSelectedEntries(selectedEntries);
        setShowActionsModal(true);
    };

    const onSubGridReady = (event: GridReadyEvent) => {
        setSubGridApi(event.api);
    };

    const onSubGridSelectionChanged = () => {
        let hasSelected: boolean = false;

        subGridApi.forEachDetailGridInfo((detailGrid) => {
            if (detailGrid.api.getSelectedNodes().length > 0) {
                hasSelected = true;
            }
        });

        setSubGridHasSelectedRows(hasSelected);
    };

    return {
        depreciationEntryList,
        depreciationEntries,
        setDepreciationEntryList,
        showOptionsModal,
        setShowOptionsModal,
        showActionsModal,
        setShowActionsModal,
        showExportModal,
        setShowExportModal,
        entryToolbar,
        assetTypeColumnRenderer,
        dateColumnRenderer,
        accountColumnRenderer,
        assetAccountColumnRenderer,
        entryDescriptionColumnRenderer,
        assetAccountNameColumnRenderer,
        debitColumnRenderer,
        assetDebitColumnRenderer,
        creditColumnRenderer,
        assetCreditColumnRenderer,
        onGridReady,
        onSelectionChanged,
        selectedEntries,
        selectedAccounts,
        handleUpdatedEntriesList,
        setGridApi,
        _checkForUnlockedSelected,
        hasUnlockedSelected,
        _handleActionsButtonClicked,
        _handleExportButtonClicked,
        currentTab,
        currentTabTitle,
        currentGridMode,
        isLoadingSubGrid,
        subGridToolbar,
        subGridEntries,
        onSubGridReady,
        onSubGridSelectionChanged,
        subGridParams,
        _handleDateLinkClicked,
        _handleAssetTypeLinkClicked,
        _handleReturnToMainGrid,
        _handleSubGridActionsButtonClicked,
        canDownloadDepreciationEntries,
        canLockDepreciationEntries,
        canViewDepreciationEntries,
        termSet,
    };
};

export default useFixedAssetDepreciationEntriesGrid;
