import React from 'react';
import ItemMasterGrid from './ItemMasterGrid';

const ItemMaster = () => {
    return (
        <>
            <ItemMasterGrid />
        </>
    );
};

export default ItemMaster;
