import React, { useContext, useEffect, useState } from 'react';
import { RootStateOrAny, useSelector } from 'react-redux';
import { ColDef, ValueGetterParams } from 'ag-grid-community';
import { cloneDeep } from 'lodash';
import { Locale, LocaleGroup } from '../../../../types/formulation';
import useEditableBaseGrid from '../../../grid/hooks/useEditableBaseGrid';
import { PermissionsUtil } from '../../../../utils/permissions/permissionsUtil';
import { PERMISSIONS } from '../../../../constants/permissions/Permissions.constants';
import DefaultColumnTypes from '../../../../components/grids/columns/Column.constants';
import { GridOptions } from '../../../../components/grids/Grid.constants';
import { useListLocaleGroupQuery } from '../../../../services/formulation/localeGroup/localeGroup.service';
import {
    useListLocalesQuery,
    useUpdateLocalesMutation,
} from '../../../../services/formulation/locale/locale.service';
import { isNilOrEmpty } from '../../../../utils/objectUtils';
import SettingsContext from '../../../../contexts/settings.context';
import { useGetTermSetQuery } from '../../../../services/i18n/i18n.service';
import { FORMULATION_DEFS } from '../../../../constants/i18n/translations/termSetDefinitions/formulation';
import { skipToken } from '@reduxjs/toolkit/query';
import CustomHeader from '../../../../components/grids/CustomHeader';
import { LocalesGridDefs } from '../../../../constants/i18n/translations/termDefinitions/formulation';

const useLocaleGrid = () => {
    const user = useSelector((state: RootStateOrAny) => state.user);

    const { isLoading: isLoadingLocaleGrp, data: localeGroupList } =
        useListLocaleGroupQuery();

    const { isLoading: isLoadingLocales, data: localesListData } =
        useListLocalesQuery();

    const [localesList, setLocalesList] = useState(cloneDeep(localesListData));

    const [updateLocales] = useUpdateLocalesMutation();

    const canViewLocales = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.FORMULATION.LOCALE.VIEW
    );

    const canUpdateLocales = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.FORMULATION.LOCALE.EDIT
    );

    const canViewLocaleGroups = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.FORMULATION.LOCALE_GROUPS.VIEW
    );

    const { settings } = useContext(SettingsContext);
    const { data: termSet } = useGetTermSetQuery(
        settings?.userSettings
            ? {
                  languageId: settings?.userSettings?.languageId,
                  code: FORMULATION_DEFS.LOCALES_GRID,
              }
            : skipToken
    );

    const [columnDefs, setColumnDefs] = useState([] as ColDef[]);

    const [localeGridColumns, setLocaleGridColumns] = useState([] as ColDef[]);

    const getLocaleGroupById = (localeGroupId: bigint) => {
        if (localeGroupList && localeGroupList.length > 0) {
            return localeGroupList.find(
                (current) => current.id === localeGroupId
            );
        }
        return null as LocaleGroup;
    };

    useEffect(() => {
        setLocalesList(cloneDeep(localesListData));
    }, [localesListData]);

    useEffect(() => {
        if (localeGroupList) {
            setLocaleGridColumns([
                {
                    field: 'groupId',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={LocalesGridDefs.Locale_Group_ID}
                            />
                        );
                    },
                    ...DefaultColumnTypes.LongText,
                },
                {
                    field: 'description',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={
                                    LocalesGridDefs.Locale_Group_Description
                                }
                            />
                        );
                    },
                    minWidth: DefaultColumnTypes.LongText.minWidth,
                    filter: DefaultColumnTypes.LongText.filter,
                    ...DefaultColumnTypes.AutoHeight,
                },
            ]);
        }
    }, [localeGroupList]);

    useEffect(() => {
        if (
            localeGroupList &&
            !isLoadingLocaleGrp &&
            !isNilOrEmpty(localeGridColumns)
        ) {
            const enableEditableCol = canViewLocaleGroups && canUpdateLocales;
            const currentColDefList = [
                {
                    field: 'localeId',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={LocalesGridDefs.Locale_ID}
                            />
                        );
                    },
                    minWidth: DefaultColumnTypes.MediumText.minWidth,
                    filter: DefaultColumnTypes.MediumText.filter,
                    ...DefaultColumnTypes.AutoHeight,
                },
                {
                    field: 'description',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={LocalesGridDefs.Description}
                            />
                        );
                    },
                    minWidth: DefaultColumnTypes.LongText.minWidth,
                    filter: DefaultColumnTypes.LongText.filter,
                    ...DefaultColumnTypes.AutoHeight,
                },
                {
                    field: 'groupId',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={LocalesGridDefs.Group}
                            />
                        );
                    },
                    filter: 'agTextColumnFilter',
                    minWidth: 250,
                    editable: enableEditableCol,
                    useGridPopup: true,
                    cellRenderer: (params: any) => params.value.display,
                    gridPopupParameters: {
                        isLoading: isLoadingLocaleGrp,
                        displayGrid: canViewLocaleGroups,
                        rowData: localeGroupList,
                        defaultColDef: {
                            ...GridOptions.sortFilterAndWrapColumns,
                            floatingFilter: true,
                        },
                        columnDefs: localeGridColumns,
                        displayField: 'groupId',
                    },
                },
                {
                    field: 'localeGroup.description',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={LocalesGridDefs.Group_Description}
                            />
                        );
                    },
                    minWidth: DefaultColumnTypes.MediumText.minWidth,
                    filter: DefaultColumnTypes.MediumText.filter,
                    ...DefaultColumnTypes.AutoHeight,
                    ...(!enableEditableCol
                        ? {}
                        : {
                              valueGetter: (params: ValueGetterParams) => {
                                  params.data.localeGroup = getLocaleGroupById(
                                      params.data.groupId
                                          ? params.data.groupId.id
                                          : params.data.localeGroupId
                                  );
                                  params.data.localeGroupId =
                                      params.data.localeGroup?.id;
                                  return params.data.localeGroup?.description;
                              },
                          }),
                },
            ];
            setColumnDefs(currentColDefList);
        }
    }, [localeGroupList, isLoadingLocaleGrp, localeGridColumns]);

    const { onUpdate } = useEditableBaseGrid<Locale[]>({
        getDescription: () => {
            return `Locales`;
        },
        updateEntity: (locales) => {
            return updateLocales({
                postBody: locales.map((current) => {
                    return {
                        id: current.id,
                        localeId: current.localeId,
                        description: current.description,
                        businessEntityId: current.businessEntityId,
                        localeGroupId: current.localeGroupId,
                    };
                }),
            });
        },
    });

    const handleUpdate = (locales: Locale[]) => {
        if (locales) {
            onUpdate(locales);
        }
    };

    return {
        isLoadingLocales,
        isLoadingLocaleGrp,
        localesList,
        columnDefs,
        handleUpdate,
        canViewLocales,
        termSet,
    };
};

export default useLocaleGrid;
