import React from 'react';
import { isNilOrEmpty } from '../../../../../../../../utils/objectUtils';
import DatacorLogoSpinner from '../../../../../../../../components/datacorLogoSpinner/DatacorLogoSpinner';
import BaseGrid from '../../../../../../../../components/grids/BaseGrid';
import FormPartial from '../../../../../../../../components/form/formContainer/FormPartial';
import useSdsToxicityGrid from './hooks/useSdsToxicityGrid';
import {
    SdsHeader,
    SdsToxicity,
} from '../../../../../../../../types/formulation';
import TranslatableText from '../../../../../../../../components/i18n/TranslatableText';
import { SDSFormDefs } from '../../../../../../../../constants/i18n/translations/termDefinitions/formulation';
import { ChildEditableGridWithCopyFunctionProps } from '../../../../../../../grid/utils/editableGrid/ChildEditableGridWithCopyFunctionProps';

const SdsToxicityGrid = (
    props: ChildEditableGridWithCopyFunctionProps<SdsHeader, SdsToxicity>
) => {
    const {
        isLoadingToxicTests,
        isLoadingToxicSpecies,
        isLoadingSpeciesClasses,
        isLoadingCas,
        toxicityEntityColumnDefs,
        handleGridEdits,
        handleRowValidations,
        colDefOptions,
        rowsData,
        termSet,
    } = useSdsToxicityGrid(props);

    const { isParentLoading } = props.parentData;

    const { showAddButton, showDeleteButton, showSortButton, isGridEditable } =
        props.displayGridButtons;

    return isLoadingToxicTests ||
        isLoadingToxicSpecies ||
        isLoadingSpeciesClasses ||
        isLoadingCas ||
        isNilOrEmpty(toxicityEntityColumnDefs) ? (
        <DatacorLogoSpinner />
    ) : (
        // <FormPartial>
        <FormPartial
            title={
                (
                    <TranslatableText
                        termSet={termSet}
                        termKey={SDSFormDefs.Numerical_Measure_Of_Toxicity}
                    />
                ) as unknown as string
            }>
            <BaseGrid
                autoSize={true}
                displayGrid={!isParentLoading}
                displayCreateNewButton={showAddButton}
                displayDeleteButton={showDeleteButton}
                useRowDrag={showSortButton}
                displaySortButton={showSortButton}
                sortKey={'sortOrder'}
                rowData={rowsData || []}
                dataKey={'rowId'}
                defaultColDef={colDefOptions}
                gridClass="full-size-item"
                displayToolbar={true}
                sizeColumnsToFit={true}
                onHandleGridEdits={handleGridEdits}
                isEditable={isGridEditable}
                onHandleRowValidations={handleRowValidations}
                columnDefs={toxicityEntityColumnDefs}
                newRowPlaceholderValues={{
                    casNumber: 'Enter a CAS Number...',
                    routeId: 'Enter a Route Type...',
                    toxicTestCode: 'Enter a Test Type...',
                    toxicSpeciesCode: 'Enter a Toxic Species...',
                    effectiveDose: 'Enter an Effective Dose...',
                    exposureTime: 'Enter an Exposure Time...',
                    notes: 'Enter Note...',
                    source: 'Enter a Source....',
                    method: 'Enter a Method...',
                    speciesClassId: 'Enter a Species Class...',
                }}></BaseGrid>
        </FormPartial>
    );
};
export default SdsToxicityGrid;
