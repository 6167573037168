import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import {
    useGetCompaniesByUserIdQuery,
    useGetUserByIdQuery,
    useGetZonesForCompaniesMutation,
} from '../../../services/users/users.service';
import { useGetRolesByBatchCompaniesQuery } from '../../../services/permissionsAndPreferences/permissionsAndPreferences.service';
import { skipToken } from '@reduxjs/toolkit/query';
import { Company, CompanyRole, Zone } from '../../../types/User.types';
import { isNilOrEmpty } from '../../../utils/objectUtils';
import _ from 'lodash';
import config from '../../../config';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { RootState } from '../../../store';
import { showNotificationError } from '../../../libs/errorLib';

interface CreateEditUserForm {
    companyIds: Array<Company>;
    zones: Array<Zone>;
    email: string;
    firstName: string;
    lastName: string;
    localUser: string;
    isAppStreamUser: boolean;
    isDatacorAdmin: boolean;
    roles: Array<string>;
    userPoolId?: string;
    invoiceAutomationCompanyTarget: string;
}

const schema = yup
    .object()
    .shape({
        firstName: yup.string().required(),
        lastName: yup.string().required(),
        email: yup.string().required(),
        username: yup.string().notRequired(),
        localUser: yup.string().required(),
        roles: yup.array().notRequired(),
        isAppStreamUser: yup.boolean().notRequired(),
        isDatacorAdmin: yup.boolean().notRequired(),
    })
    .required();

const UseCreateEditUser = ({ username, isEdit, handleClose }: any) => {
    const dispatch = useDispatch();
    const user = useSelector((state: RootState) => state.user);

    const { control, register, handleSubmit, setValue, formState, getValues } =
        useForm({
            resolver: yupResolver(schema),
            mode: 'all',
            defaultValues: {
                firstName: '',
                lastName: '',
                email: '',
                username: '',
                localUser: '',
                companyIds: [],
                zones: [],
                roles: [],
                isAppStreamUser: false,
                isDatacorAdmin: false,
                invoiceAutomationCompanyTarget: '',
            },
        });

    register('isAppStreamUser');
    register('isDatacorAdmin');

    const [selectedUser, setSelectedUser] = useState(null);
    const [selectedCompanies, setSelectedCompanies] = useState([]);
    const { data: companies } = useGetCompaniesByUserIdQuery(user.userId);
    const { data: companiesRoles } = useGetRolesByBatchCompaniesQuery(
        selectedCompanies.length > 0
            ? selectedCompanies.map((company) => {
                  return {
                      companyID: company.value,
                  };
              })
            : skipToken
    );
    const { data: userData } = useGetUserByIdQuery(username);
    const [companyList, setCompanyList] = useState<Company[] | []>([]);
    const [isLoading, setIsLoading] = useState(false);

    const [selectedApAutomationInvoiceTarget, setApAutomationInvoiceTarget] =
        useState('');
    const [selectedZones, setSelectedZones] = useState([]);
    const [selectedRoles, setSelectedRoles] = useState([]);
    const [zoneOptions, setZoneOptions] = useState([]);
    const [roleOptions, setRoleOptions] = useState([]);
    const [isRolesDisabled, setIsRolesDisabled] = useState(false);
    const [userRoles, setUserRoles] = useState([]);
    const [isAdmin, setIsAdmin] = useState(false);
    const [isAppStream, setIsAppStream] = useState(false);
    const [getZonesForCompanies] = useGetZonesForCompaniesMutation();
    let shouldLoadNewZones = true;

    useEffect(() => {
        async function onLoad() {
            try {
                if (username && isEdit) {
                    setSelectedUser(userData);
                    setCompanyList(companies);
                }
            } catch (e) {
                showNotificationError(e, 'error', dispatch);
            }
        }

        onLoad();
    }, [username, companies, userData]);

    useEffect(() => {
        const onLoad = () => {
            try {
                if (!isEdit && user.userId) {
                    setCompanyList(companies);
                }
            } catch (e) {
                showNotificationError(e, 'error', dispatch);
            }
        };

        onLoad();
    }, [companies]);

    useEffect(() => {
        if (!companiesRoles) {
            return;
        }
        shouldLoadNewZones = true;
        setRoleOptions([]);
        setSelectedRoles([]);
        const rolesPromise = selectedCompanies.map(async (company) => {
            return companiesRoles?.filter(
                (role: CompanyRole) =>
                    role.companyId === company.value && role.status === 'ACTIVE'
            );
        });
        Promise.all(rolesPromise).then((roles) => {
            if (!isNilOrEmpty(roles)) {
                setRoleOptions(getRoleOptions(_.flatten(roles)));
            }
        });
    }, [selectedCompanies, companiesRoles]);

    useEffect(() => {
        if (selectedUser) {
            setIsAdmin(selectedUser.isDatacorAdmin);
            setIsAppStream(selectedUser.isAppStreamUser);
            updateFields();
        }
    }, [selectedUser]);

    useEffect(() => {
        if (!isNilOrEmpty(roleOptions)) {
            setSelectedRoles(userRoles);
        }
    }, [roleOptions]);

    const getRoleOptions = (roles: Array<CompanyRole>) => {
        return roles.map((role: CompanyRole) => {
            return {
                label: `${role.name} (${role.companyId})`,
                value: role.roleId,
            };
        });
    };
    const updateFields = async () => {
        const userIsDatacorAdmin = selectedUser.isDatacorAdmin
            ? selectedUser.isDatacorAdmin
            : false;

        register('username');

        setValue('firstName', selectedUser.firstName, { shouldValidate: true });
        setValue('lastName', selectedUser.lastName, { shouldValidate: true });
        setValue('localUser', selectedUser.localUser, { shouldValidate: true });
        setValue('email', selectedUser.email, { shouldValidate: true });
        setValue('username', selectedUser.username, { shouldValidate: true });
        setValue(
            'companyIds',
            selectedUser.companies.map((company: Company) => company.companyID),
            { shouldValidate: true }
        );
        setValue(
            'isAppStreamUser',
            selectedUser.isAppStreamUser ? selectedUser.isAppStreamUser : false,
            { shouldValidate: true }
        );
        setValue('isDatacorAdmin', userIsDatacorAdmin, {
            shouldValidate: true,
        });
        setUserRoles(selectedUser.roles);
        setValue(
            'zones',
            selectedUser.zones.map((zone: Zone) => zone.zoneId),
            { shouldValidate: true }
        );
        setSelectedCompanies(
            selectedUser.companies.map((company: Company) => {
                return {
                    label: company.companyID,
                    value: company.companyID,
                };
            })
        );
        setApAutomationInvoiceTarget(
            selectedUser.invoiceAutomationCompanyTarget
        );

        const zones = await getZones(
            selectedUser.companies.map((company: Company) => company.companyID)
        );
        setZoneOptions(
            zones?.map((zone: Zone) => {
                return {
                    label: zone.zoneId,
                    value: zone.zoneId,
                };
            })
        );

        setSelectedZones(
            selectedUser.zones.map((zone: Zone) => {
                return {
                    label: zone.zoneId,
                    value: zone.zoneId,
                };
            })
        );

        setIsRolesDisabled(userIsDatacorAdmin);
    };

    const onSubmit = async (form: CreateEditUserForm) => {
        setIsLoading(true);

        isEdit ? handleUpdateUser(form) : handleCreateUser(form);
    };

    const handleCreateUser = async (form: CreateEditUserForm) => {
        const body = {
            companyIds: selectedCompanies.map(
                (selectedCompany) => selectedCompany.value
            ),
            zones: selectedZones.map((selectedZone) => selectedZone.value),
            email: form.email.toLowerCase(),
            firstName: form.firstName,
            lastName: form.lastName,
            localUser: form.localUser,
            isAppStreamUser: form.isAppStreamUser,
            isDatacorAdmin: form.isDatacorAdmin,
            roles: selectedRoles,
            userPoolId: config.cognito.USER_POOL_ID,
            invoiceAutomationCompanyTarget: selectedApAutomationInvoiceTarget,
        };

        try {
            setIsLoading(false);
            handleClose(true);
        } catch (e) {
            setIsLoading(false);
            return showNotificationError(e.message, 'error', dispatch);
        }
    };

    const handleUpdateUser = async (form: CreateEditUserForm) => {
        const body = {
            companyIds: selectedCompanies.map(
                (selectedCompany) => selectedCompany.value
            ),
            zones: selectedZones.map((selectedZone) => selectedZone.value),
            firstName: getValues('firstName'),
            lastName: getValues('lastName'),
            localUser: getValues('localUser'),
            isAppStreamUser: getValues('isAppStreamUser'),
            isDatacorAdmin: getValues('isDatacorAdmin'),
            roles: selectedRoles,
            username: getValues('username'),
            invoiceAutomationCompanyTarget: selectedApAutomationInvoiceTarget,
        };

        try {
            setIsLoading(false);
            handleClose(true);
        } catch (e) {
            setIsLoading(false);
            return showNotificationError(e.message, 'error', dispatch);
        }
    };

    const companySelectionChanged = (selectedValues: any) => {
        setSelectedCompanies(
            selectedValues.map((selectedValue: any) => {
                return {
                    label: selectedValue.value,
                    value: selectedValue.value,
                };
            })
        );

        //make sure all selected zones fit the selected companies
        const companySelectionInfo = companyList.filter(
            (company) =>
                !!selectedValues.find(
                    (selectedValue: any) =>
                        selectedValue.value === company.companyID
                )
        );
        const newZones = selectedZones.filter((selectedZone) =>
            companySelectionInfo.find(
                (company) =>
                    !!company.zones.find(
                        (zone: Zone) => zone.zoneId === selectedZone.value
                    )
            )
        );
        setSelectedZones(newZones);
        setUserRoles([]);
    };

    const selectedApAutomationInvoiceTargetChanged = (companyValue: string) => {
        setApAutomationInvoiceTarget(companyValue);
    };

    const onCompanyMenuClose = async () => {
        if (shouldLoadNewZones) {
            const companyIds = selectedCompanies.map(
                (selectedCompany) => selectedCompany.value
            );
            const zones = await getZones(companyIds);
            setZoneOptions(
                zones?.map((zone: Zone) => {
                    return {
                        label: zone.zoneId,
                        value: zone.zoneId,
                    };
                })
            );
        }
    };

    const getZones = async (companyIds: any) => {
        if (companyIds && companyIds.length !== 0) {
            return await getZonesForCompanies(companyIds).unwrap();
        }
        return [];
    };

    const isFormValid = () => {
        return (
            formState.isValid &&
            (!isNilOrEmpty(selectedRoles) || isRolesDisabled)
        );
    };

    const setIsAppStreamUserValue = (event: any) => {
        setValue('isAppStreamUser', event.target.checked, {
            shouldValidate: true,
        });
        setIsAppStream((prev) => !prev);
    };

    const setIsDatacorAdminValue = (event: any) => {
        const checked = event.target.checked;

        setValue('isDatacorAdmin', checked, { shouldValidate: true });
        setIsRolesDisabled(checked);
        setIsAdmin((prev) => !prev);
    };
    return {
        control,
        user,
        selectedApAutomationInvoiceTarget,
        selectedApAutomationInvoiceTargetChanged,
        companyList,
        onCompanyMenuClose,
        selectedCompanies,
        companySelectionChanged,
        zoneOptions,
        selectedZones,
        setSelectedZones,
        selectedRoles,
        roleOptions,
        setSelectedRoles,
        isRolesDisabled,
        isAppStream,
        setIsAppStreamUserValue,
        isAdmin,
        setIsDatacorAdminValue,
        handleSubmit,
        onSubmit,
        isLoading,
        isFormValid,
    };
};

export default UseCreateEditUser;
