import { FA_DepreciationEntry } from '../../../../types/FixedAsset.types';
import React from 'react';
import BaseGrid from '../../../../components/grids/BaseGrid';
import DefaultColumnTypes from '../../../../components/grids/columns/Column.constants';
import { ColDef, ICellRendererParams } from 'ag-grid-community';
import { isNilOrEmpty } from '../../../../utils/objectUtils';
import { Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import { LoadingButton } from '@mui/lab';
import DeleteIcon from '@mui/icons-material/Delete';
import useDepreciationEntryGrid, {
    DepreciationEntryRow,
} from './hooks/useDepreciationEntryGrid';
import FixedAssetCreateDepreciationEntriesModal from '../modals/FixedAssetCreateDepreciationEntriesModal';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import { FixedAssetRegisterFormDefs } from '../../../../constants/i18n/translations/termDefinitions/accounting';
import TranslatableText from '../../../../components/i18n/TranslatableText';

export interface DepreciationEntriesGridProps {
    entries: FA_DepreciationEntry[];
    disable: boolean;
    fixedAssetId: string;
    entriesTotals: any;
}

const DepreciationEntryGrid = (props: DepreciationEntriesGridProps) => {
    const {
        handleUnlock,
        handleLock,
        handleDelete,
        setShowCreateEntriesModal,
        depreciationEntryRows,
        isDeletingEntries,
        isLockingEntries,
        isUnlockingEntries,
        showCreateEntriesModal,
        pinnedBottomRowData,
        canCreateDepreciationEntries,
        canUpdateDepreciationEntries,
        canDeleteDepreciationEntries,
        canLockDepreciationEntries,
        termSet,
    } = useDepreciationEntryGrid(props);

    const entriesColumnDefs: ColDef[] = [
        {
            field: 'interval',
            headerComponentFramework: (props: any) => {
                return (
                    <TranslatableText
                        termSet={termSet}
                        termKey={
                            FixedAssetRegisterFormDefs.DepreciationEntriesRecoveryInterval
                        }
                    />
                );
            },
            ...DefaultColumnTypes.NumberOnly,
        },
        {
            field: 'intervalDate',
            headerComponentFramework: (props: any) => {
                return (
                    <TranslatableText
                        termSet={termSet}
                        termKey={
                            FixedAssetRegisterFormDefs.DepreciationEntriesDate
                        }
                    />
                );
            },
            ...DefaultColumnTypes.DateOnly,
        },
        {
            field: 'accountNo',
            headerComponentFramework: (props: any) => {
                return (
                    <TranslatableText
                        termSet={termSet}
                        termKey={
                            FixedAssetRegisterFormDefs.DepreciationEntriesAccountNumber
                        }
                    />
                );
            },
            cellRenderer: 'accountNoCellRenderer',
            ...DefaultColumnTypes.ShortText,
            ...DefaultColumnTypes.AutoHeight,
        },
        {
            field: 'accountName',
            headerComponentFramework: (props: any) => {
                return (
                    <TranslatableText
                        termSet={termSet}
                        termKey={
                            FixedAssetRegisterFormDefs.DepreciationEntriesEntryDescription
                        }
                    />
                );
            },
            cellRenderer: 'descriptionCellRenderer',
            ...DefaultColumnTypes.MediumText,
            ...DefaultColumnTypes.AutoHeight,
        },
        {
            field: 'debit',
            headerComponentFramework: (props: any) => {
                return (
                    <TranslatableText
                        termSet={termSet}
                        termKey={
                            FixedAssetRegisterFormDefs.DepreciationEntriesDebit
                        }
                    />
                );
            },
            cellRenderer: 'debitCellRenderer',
            ...DefaultColumnTypes.Currency,
            ...DefaultColumnTypes.AutoHeight,
        },
        {
            field: 'credit',
            headerComponentFramework: (props: any) => {
                return (
                    <TranslatableText
                        termSet={termSet}
                        termKey={
                            FixedAssetRegisterFormDefs.DepreciationEntriesCredit
                        }
                    />
                );
            },
            cellRenderer: 'creditCellRenderer',
            ...DefaultColumnTypes.Currency,
            ...DefaultColumnTypes.AutoHeight,
        },
        {
            width: 130,
            minWidth: 130,
            maxWidth: 150,
            cellStyle: { display: 'flex', 'align-items': 'center' },
            cellRenderer: 'lockUnlockColumn',
            cellRendererParams: { depreciationList: props.entries },
            ...DefaultColumnTypes.StackedColumn,
        },
        {
            field: 'lockedSummary',
            headerComponentFramework: (props: any) => {
                return (
                    <TranslatableText
                        termSet={termSet}
                        termKey={
                            FixedAssetRegisterFormDefs.DepreciationEntriesLocked
                        }
                    />
                );
            },
            ...DefaultColumnTypes.LockedInformationColumn,
        },
    ];

    const entryToolbar = () => {
        return (
            <React.Fragment>
                {isNilOrEmpty(props.entries) &&
                    (canCreateDepreciationEntries ? (
                        <Button
                            variant="text"
                            size="small"
                            disabled={props.disable}
                            onClick={() => setShowCreateEntriesModal(true)}
                            startIcon={
                                <AddIcon style={{ color: '#00A84E' }} />
                            }>
                            Add Depreciation Entry
                        </Button>
                    ) : null)}
                {!isNilOrEmpty(props.entries) &&
                    (canUpdateDepreciationEntries ? (
                        <Button
                            variant="text"
                            size="small"
                            onClick={() => setShowCreateEntriesModal(true)}
                            startIcon={
                                <PlayCircleIcon style={{ color: '#00A84E' }} />
                            }>
                            Update Unlocked Entries
                        </Button>
                    ) : null)}
                {!isNilOrEmpty(props.entries) &&
                    (canDeleteDepreciationEntries ? (
                        <LoadingButton
                            variant="text"
                            size="small"
                            loading={isDeletingEntries}
                            onClick={handleDelete}
                            startIcon={
                                <DeleteIcon style={{ color: '#FF0000' }} />
                            }>
                            Delete Unlocked Entries
                        </LoadingButton>
                    ) : null)}
            </React.Fragment>
        );
    };

    const accountColumn = (params: ICellRendererParams) => {
        const row: DepreciationEntryRow = params.data;

        return (
            <div style={{ whiteSpace: 'pre-line' }}>{row.accountNo ?? ''}</div>
        );
    };

    const descriptionColumn = (params: ICellRendererParams) => {
        const row: DepreciationEntryRow = params.data;

        return (
            <div style={{ whiteSpace: 'pre-line' }}>
                {row.accountName ?? ''}
            </div>
        );
    };

    const creditColumn = (params: ICellRendererParams) => {
        const row: DepreciationEntryRow = params.data;

        return <div style={{ whiteSpace: 'pre-line' }}>{row.credit ?? ''}</div>;
    };

    const debitColumn = (params: ICellRendererParams) => {
        const row: DepreciationEntryRow = params.data;

        return <div style={{ whiteSpace: 'pre-line' }}>{row.debit ?? ''}</div>;
    };

    const entryLockUnlockColum = (params: ICellRendererParams) => {
        return canLockDepreciationEntries && params.data.id !== null ? (
            params.data.locked ? (
                <LoadingButton
                    id={params.data.id?.toString()}
                    variant="outlined"
                    color="error"
                    size={'small'}
                    style={{ maxHeight: '20px', display: 'flex' }}
                    loading={isUnlockingEntries || isLockingEntries}
                    onClick={() => handleUnlock(params.data)}
                    startIcon={<LockIcon />}>
                    Locked
                </LoadingButton>
            ) : (
                <LoadingButton
                    id={params.data.id?.toString()}
                    variant="outlined"
                    color="success"
                    size={'small'}
                    style={{ maxHeight: '20px', display: 'flex' }}
                    loading={isLockingEntries || isUnlockingEntries}
                    onClick={() => handleLock(params.data)}
                    startIcon={<LockOpenIcon />}>
                    Unlocked
                </LoadingButton>
            )
        ) : null;
    };

    return (
        <React.Fragment>
            <BaseGrid
                rowData={depreciationEntryRows || []}
                defaultColDef={{ flex: 1 }}
                displayGrid={true}
                displayToolbar={true}
                toolbarChildren={entryToolbar()}
                displaySummaryRow={true}
                columnDefs={entriesColumnDefs}
                pinnedBottomRowData={pinnedBottomRowData}
                frameworkComponents={{
                    lockUnlockColumn: entryLockUnlockColum,
                    accountNoCellRenderer: accountColumn,
                    descriptionCellRenderer: descriptionColumn,
                    debitCellRenderer: debitColumn,
                    creditCellRenderer: creditColumn,
                }}
            />

            <FixedAssetCreateDepreciationEntriesModal
                open={showCreateEntriesModal}
                onClose={() => setShowCreateEntriesModal(false)}
                ids={[props.fixedAssetId as unknown as bigint]}
                isUpdate={!isNilOrEmpty(props.entries)}
                onEntriesCreated={() => {}}
            />
        </React.Fragment>
    );
};

export default DepreciationEntryGrid;
