import { Card, CardContent, Grid, TextField } from '@mui/material';
import React from 'react';
import useRevisionSearch from './hooks/useRevisionSearch';
import LoaderButton from '../../components/LoaderButton';
import MultiSelectInput from '../../components/form/formInputs/MultiSelectInput/MutliSelectInput';
import DatacorLogoSpinner from '../../components/datacorLogoSpinner/DatacorLogoSpinner';
import AgGrid from '../../components/grids/Grid';
import { AgGridColumn } from 'ag-grid-react';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import RevisionItemModal from '../../components/administration/RevisionItemModal';
import { MODULE_MAPPINGS } from '../../constants/revisionHistory/revisionHistory.constants';
import { dateFormatter } from '../../utils/formattingUtils';
import { RevisionHistoryFormDefs } from '../../constants/i18n/translations/termDefinitions/platform';
import TranslatableText from '../../components/i18n/TranslatableText';
import CustomHeader from '../../components/grids/CustomHeader';
import { dateValueComparator } from '../../utils/comparisonUtils';

const RevisionSearch = () => {
    const {
        searchParams,
        filterOptions,
        users,
        isLoadingUsers,
        isLoadingSearchParams,
        isSearching,
        results,
        handleSearch,
        handleChange,
        handleDateChange,
        goToItem,
        handleOpenDetails,
        open,
        setOpen,
        selectedItem,
        onGridReady,
        onSortChanged,
        onFirstDataRendered,
        termSet,
    } = useRevisionSearch();

    return (
        <Card>
            <CardContent>
                {!isLoadingSearchParams && !isLoadingUsers ? (
                    <Grid container spacing={2}>
                        <Grid item xs={4}>
                            <MultiSelectInput
                                label={
                                    (
                                        <TranslatableText
                                            termKey={
                                                RevisionHistoryFormDefs.Users
                                            }
                                            termSet={termSet}
                                        />
                                    ) as unknown as string
                                }
                                id={'users'}
                                options={users}
                                onChange={handleChange}
                                size="small"
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <DesktopDatePicker
                                        label={
                                            <TranslatableText
                                                termKey={
                                                    RevisionHistoryFormDefs.Start_Date
                                                }
                                                termSet={termSet}
                                            />
                                        }
                                        inputFormat="MM/DD/yyyy"
                                        value={filterOptions.dates[0].startDate}
                                        onChange={(e) =>
                                            handleDateChange('startDate', e)
                                        }
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                size="small"
                                                fullWidth
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <DesktopDatePicker
                                        label={
                                            <TranslatableText
                                                termKey={
                                                    RevisionHistoryFormDefs.End_Date
                                                }
                                                termSet={termSet}
                                            />
                                        }
                                        inputFormat="MM/DD/yyyy"
                                        value={filterOptions.dates[0].endDate}
                                        onChange={(e) =>
                                            handleDateChange('endDate', e)
                                        }
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                size="small"
                                                fullWidth
                                            />
                                        )}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={4}>
                            <MultiSelectInput
                                label={
                                    (
                                        <TranslatableText
                                            termKey={
                                                RevisionHistoryFormDefs.Actions
                                            }
                                            termSet={termSet}
                                        />
                                    ) as unknown as string
                                }
                                size="small"
                                id={'actions'}
                                options={searchParams.actions}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <MultiSelectInput
                                label={
                                    (
                                        <TranslatableText
                                            termKey={
                                                RevisionHistoryFormDefs.Sources
                                            }
                                            termSet={termSet}
                                        />
                                    ) as unknown as string
                                }
                                size="small"
                                id={'sources'}
                                options={searchParams.sources}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <MultiSelectInput
                                label={
                                    (
                                        <TranslatableText
                                            termKey={
                                                RevisionHistoryFormDefs.Modules
                                            }
                                            termSet={termSet}
                                        />
                                    ) as unknown as string
                                }
                                size="small"
                                id={'modules'}
                                options={searchParams.modules}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            {/*TODO: remove this loader button if we decide auto-search is the path forward */}
                            <LoaderButton
                                isLoading={isSearching}
                                onClick={handleSearch}
                                variant="contained">
                                <TranslatableText
                                    termKey={RevisionHistoryFormDefs.Search}
                                    termSet={termSet}
                                />
                            </LoaderButton>
                        </Grid>
                        {results && (
                            <Grid item xs={12}>
                                <AgGrid
                                    testId="user-list-grid"
                                    dataKey="id"
                                    immutableData={true}
                                    rowData={results}
                                    onFirstDataRendered={onFirstDataRendered}
                                    onSortChanged={onSortChanged}
                                    onGridReady={onGridReady}
                                    frameworkComponents={{
                                        goToItem: goToItem,
                                    }}
                                    onRowClicked={({ data }: any) =>
                                        handleOpenDetails(data)
                                    }
                                    defaultColDef={{
                                        resizable: true,
                                        sortable: true,
                                        unSortIcon: true,
                                        floatingFilter: true,
                                        filter: true,
                                        wrapText: true,
                                    }}>
                                    <AgGridColumn
                                        field="action"
                                        filter={false}
                                        sortable={false}
                                        resizable={false}
                                        headerName=""
                                        width={70}
                                        cellRenderer="goToItem"
                                    />
                                    <AgGridColumn
                                        minWidth={200}
                                        field="createdAt"
                                        filter={'agDateColumnFilter'}
                                        filterParams={dateValueComparator}
                                        headerComponentFramework={(
                                            props: any
                                        ) => {
                                            return (
                                                <CustomHeader
                                                    {...props}
                                                    termSet={termSet}
                                                    termKey={
                                                        RevisionHistoryFormDefs.Date_Created
                                                    }
                                                />
                                            );
                                        }}
                                        valueFormatter={(val) =>
                                            dateFormatter(val.value)
                                        }
                                    />
                                    <AgGridColumn
                                        flex={1}
                                        minWidth={250}
                                        filter={'agTextColumnFilter'}
                                        field="user.firstName"
                                        headerComponentFramework={(
                                            props: any
                                        ) => {
                                            return (
                                                <CustomHeader
                                                    {...props}
                                                    termSet={termSet}
                                                    termKey={
                                                        RevisionHistoryFormDefs.User
                                                    }
                                                />
                                            );
                                        }}
                                    />
                                    <AgGridColumn
                                        flex={1}
                                        minWidth={250}
                                        filter={'agTextColumnFilter'}
                                        field="revisionAction.name"
                                        headerComponentFramework={(
                                            props: any
                                        ) => {
                                            return (
                                                <CustomHeader
                                                    {...props}
                                                    termSet={termSet}
                                                    termKey={
                                                        RevisionHistoryFormDefs.Revision_Action
                                                    }
                                                />
                                            );
                                        }}
                                    />
                                    <AgGridColumn
                                        flex={1}
                                        minWidth={250}
                                        filter={'agTextColumnFilter'}
                                        field="revisionEntityType.name"
                                        headerComponentFramework={(
                                            props: any
                                        ) => {
                                            return (
                                                <CustomHeader
                                                    {...props}
                                                    termSet={termSet}
                                                    termKey={
                                                        RevisionHistoryFormDefs.Entity
                                                    }
                                                />
                                            );
                                        }}
                                    />
                                    <AgGridColumn
                                        flex={1}
                                        minWidth={250}
                                        filter={'agTextColumnFilter'}
                                        field="revisionEntityType.module"
                                        headerComponentFramework={(
                                            props: any
                                        ) => {
                                            return (
                                                <CustomHeader
                                                    {...props}
                                                    termSet={termSet}
                                                    termKey={
                                                        RevisionHistoryFormDefs.Module
                                                    }
                                                />
                                            );
                                        }}
                                    />
                                    <AgGridColumn
                                        flex={1}
                                        minWidth={250}
                                        filter={'agTextColumnFilter'}
                                        field="revisionSource.name"
                                        headerComponentFramework={(
                                            props: any
                                        ) => {
                                            return (
                                                <CustomHeader
                                                    {...props}
                                                    termSet={termSet}
                                                    termKey={
                                                        RevisionHistoryFormDefs.Revision_Source
                                                    }
                                                />
                                            );
                                        }}
                                    />
                                </AgGrid>
                            </Grid>
                        )}
                        <RevisionItemModal
                            item={selectedItem}
                            onClose={() => setOpen(false)}
                            open={open}
                            fieldMappings={
                                MODULE_MAPPINGS[
                                    selectedItem?.revisionEntityType?.code
                                ]
                            }
                        />
                    </Grid>
                ) : (
                    <DatacorLogoSpinner />
                )}
            </CardContent>
        </Card>
    );
};

export default RevisionSearch;
