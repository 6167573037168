import React, { useEffect, useState } from 'react';
import { TestHarnessService } from '../../../services/workflow/testHarness/testHarness.service';
import { onError } from '../../../libs/errorLib';
import CheckIcon from '@mui/icons-material/Check';
import AddEditContact from './AddEditContact';
import { AgGridColumn } from 'ag-grid-react';
import LoaderButton from '../../../components/LoaderButton';
import SingleSelectGrid from '../../../components/grids/SingleSelectGrid';

const ContactList = () => {
    const [contacts, setContacts] = useState(null);
    const [showEditContactModal, setShowEditContactModal] = useState(false);
    const [selectedId, setSelectedId] = useState(null);

    useEffect(() => {
        const onLoad = async () => {
            loadData();
        };

        onLoad();
    }, []);

    const loadData = async () => {
        try {
            setContacts(await TestHarnessService.getTHContacts());
        } catch (e) {
            onError(e);
        }
    };

    const verified = (param) => {
        return param.value !== null && param.value !== '' ? (
            <CheckIcon />
        ) : (
            <LoaderButton
                variant="primary"
                size="lg"
                onClick={($event) => handleVerifyContact(param.data, $event)}>
                Verify
            </LoaderButton>
        );
    };

    const handleVerifyContact = async (row, event) => {
        event.bubbles = false;
        event.preventDefault();

        const confirmed = window.confirm(
            `You are confirming that you've verified the email address (${row.email}) for ${row.firstName} ${row.lastName}?`
        );

        if (!confirmed) {
            return false;
        }

        try {
            await TestHarnessService.verifyContact(row.id);
            loadData();
        } catch (e) {
            return onError(e.message);
        }
    };

    const editContact = (event) => {
        if (!event.event.defaultPrevented) {
            setSelectedId(event.data.id);
            setShowEditContactModal(true);
        }
    };

    const handleModalClose = (refresh) => {
        setSelectedId(null);
        setShowEditContactModal(false);
        loadData();
    };

    const addContact = () => {
        setShowEditContactModal(true);
    };

    return (
        <>
            {showEditContactModal && (
                <AddEditContact
                    handleClose={handleModalClose}
                    id={selectedId}></AddEditContact>
            )}

            <SingleSelectGrid
                rowHeight="35"
                rowData={contacts}
                domLayout={'autoHeight'}
                shouldAutofit={true}
                displayToolbar={true}
                displayCreateNewButton={true}
                onCreateNew={addContact}
                onRowClicked={editContact}
                suppressCellSelection={true}
                frameworkComponents={{
                    verified: verified,
                }}>
                <AgGridColumn field="firstName" />
                <AgGridColumn field="lastName" />
                <AgGridColumn field="email" />
                <AgGridColumn
                    field="verifiedBy"
                    cellRenderer="verified"
                    headerName="Verified"
                    width={80}
                    minWidth={80}
                    cellStyle={{ 'text-align': 'left' }}
                />
            </SingleSelectGrid>
        </>
    );
};

export default ContactList;
