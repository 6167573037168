export const UniqueColumnValueValidator = (
    arrayToSearch: any[],
    currentRow: any,
    columnsToCheck: string[],
    errorMessage: string
) => {
    let isValid = true;
    let getErrorMessage = '';

    const result = arrayToSearch.filter((arrayItem) =>
        columnsToCheck.every((columnName) => {
            return (
                currentRow[columnName]?.toString() ===
                    arrayItem[columnName]?.toString() &&
                currentRow.rowId !== arrayItem.rowId
            );
        })
    );

    if (result.length > 0) {
        getErrorMessage = errorMessage;
        isValid = false;
    }

    return {
        getErrorMessage,
        isValid,
    };
};
