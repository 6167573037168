import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import React, { useContext, useEffect, useState } from 'react';
import SettingsContext from '../../../../contexts/settings.context';
import { useGetTermSetQuery } from '../../../../services/i18n/i18n.service';
import { ACCOUNTING_DEFS } from '../../../../constants/i18n/translations/termSetDefinitions/accounting';
import { skipToken } from '@reduxjs/toolkit/query';
import { PermissionsUtil } from '../../../../utils/permissions/permissionsUtil';
import { PERMISSIONS } from '../../../../constants/permissions/Permissions.constants';
import { isNilOrEmpty } from '../../../../utils/objectUtils';
import { ColDef } from 'ag-grid-community';
import DefaultColumnTypes from '../../../../components/grids/columns/Column.constants';
import CustomHeader from '../../../../components/grids/CustomHeader';
import { CurrencyExchangeRateGridDefs } from '../../../../constants/i18n/translations/termDefinitions/accounting';
import { CurrencyExchangeRate } from '../../../../types/platform/i18n/Currency.type';
import { auditableGridColumns } from '../../../grid/utils/auditableGrid/auditableGridColumns';

const useCurrencyExchangeRateGrid = () => {
    const user = useSelector((state: RootState) => state.user);
    const [currencyExchangeRateList, setCurrencyExchangeRateList] = useState<
        CurrencyExchangeRate[]
    >([]);
    const [colDefs, setColDefs] = useState([]);
    const [rowData, setRowData] = useState([]);

    const { settings } = useContext(SettingsContext);
    const { data: termSet } = useGetTermSetQuery(
        settings?.userSettings
            ? {
                  languageId: settings?.userSettings?.languageId,
                  code: ACCOUNTING_DEFS.CURRENCY_EXCHANGE_RATE_GRID,
              }
            : skipToken
    );

    const canViewExchangeRate = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.ACCOUNTING.CURRENCY_EXCHANGE_RATES.VIEW
    );

    const [hiddenIdColumns, auditableColumns] = auditableGridColumns();

    useEffect(() => {
        if (!isNilOrEmpty(termSet)) {
            const exchangeRateColumnDefs: ColDef[] = [
                ...hiddenIdColumns,
                {
                    field: 'sourceCurrencyCode',
                    ...DefaultColumnTypes.ShortText,
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={
                                    CurrencyExchangeRateGridDefs.SourceCurrencyCode
                                }
                            />
                        );
                    },
                },
                {
                    field: 'targetCurrencyCode',
                    ...DefaultColumnTypes.ShortText,
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={
                                    CurrencyExchangeRateGridDefs.TargetCurrencyCode
                                }
                            />
                        );
                    },
                },
                {
                    field: 'currencyRateTypeId',
                    hide: true,
                    ...DefaultColumnTypes.NumberOnly,
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={
                                    CurrencyExchangeRateGridDefs.CurrencyRateTypeId
                                }
                            />
                        );
                    },
                },
                {
                    field: 'currencyRateType.currencyRateTypeCode',
                    ...DefaultColumnTypes.ShortText,
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={
                                    CurrencyExchangeRateGridDefs.CurrencyRateTypeCode
                                }
                            />
                        );
                    },
                },
                {
                    field: 'exchangeRate',
                    ...DefaultColumnTypes.NumberOnly,
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={
                                    CurrencyExchangeRateGridDefs.ExchangeRate
                                }
                            />
                        );
                    },
                },
                {
                    field: 'effectiveAt',
                    ...DefaultColumnTypes.DateOnly,
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={
                                    CurrencyExchangeRateGridDefs.EffectiveAt
                                }
                            />
                        );
                    },
                },
                ...auditableColumns,
            ];
            setColDefs(exchangeRateColumnDefs);
        }
    }, [termSet]);

    const [showOptionsModal, setShowOptionsModal] = useState<boolean>(true);

    useEffect(() => {
        if (!isNilOrEmpty(currencyExchangeRateList)) {
            setRowData(currencyExchangeRateList);
        }
    }, [currencyExchangeRateList]);

    return {
        rowData,
        showOptionsModal,
        setShowOptionsModal,
        canViewExchangeRate,
        colDefs,
        setCurrencyExchangeRateList,
        termSet,
    };
};

export default useCurrencyExchangeRateGrid;
