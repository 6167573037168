import { isNil, isNilOrEmpty } from '../../../utils/objectUtils';

export const ColumnRequiredValueValidator = (
    columnLabel: string,
    columnData: any
) => {
    let isValid = true;
    let getErrorMessage = '';

    if (!isNilOrEmpty(columnLabel)) {
        if (
            (typeof columnData === 'object' &&
                columnData &&
                (isNil(columnData.id) || isNil(columnData.display))) ||
            isNil(columnData)
        ) {
            getErrorMessage = `${columnLabel} is required.`;
            isValid = false;
        }
    }

    return {
        getErrorMessage,
        isValid,
    };
};
