import { EntityError, ErrorEntity } from '../../../types/Shared.types';

const applyEditableGridValidations = (
    rowData: ErrorEntity,
    additionalErrorMessage?: string
) => {
    if (rowData) {
        rowData.errors = [];
        rowData.validationRules?.forEach((validationRule: any) => {
            const { getErrorMessage: errorMessage, isValid: isFieldValid } =
                validationRule;
            if (!isFieldValid) {
                const error: EntityError = {
                    errorMessage: errorMessage,
                    additionalErrorMessage: additionalErrorMessage,
                };
                rowData.errors.push(error);
            }
        });
        rowData.hasError = rowData.errors.length !== 0;
    }
};

export default applyEditableGridValidations;
