import { ColDef, IDetailCellRendererParams } from 'ag-grid-community';
import DefaultColumnTypes from '../../../components/grids/columns/Column.constants';
import React, { useMemo } from 'react';
import BaseFormGrid from '../../../components/grids/BaseFormGrid';
import useAssetEntriesGrid, {
    EntryGridMode,
} from './hooks/useAssetEntriesGrid';
import AssetEntryOptionsModal from './AssetEntryOptionsModal';
import { Button } from '@mui/material';
import ListIcon from '@mui/icons-material/List';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import AssetEntryActionsModal from './AssetEntryActionsModal';
import AssetEntryExportModal from './AssetEntryExportModal';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { AssetEntriesGridDefs } from '../../../constants/i18n/translations/termDefinitions/accounting';
import TranslatableText from '../../../components/i18n/TranslatableText';

const AssetEntriesGrid = () => {
    const {
        showOptionsModal,
        showActionsModal,
        showExportModal,
        setShowOptionsModal,
        setShowActionsModal,
        setShowExportModal,
        mainEntryRows,
        hasSelectedRows,
        hasUnlockedSelected,
        selectedEntries,
        exportedEntries,
        canLockEntries,
        canViewEntries,
        canDownloadEntries,
        currentGridMode,
        currentTitle,
        setAssetEntryList,
        mainAccountColumn,
        mainDescriptionColumn,
        mainCreditColumn,
        mainDebitColumn,
        detailAccountNoColumn,
        detailAccountNameColumn,
        detailCreditColumn,
        detailDebitColumn,
        assetTypeColumn,
        entryDateColumn,
        onSelectionChanged,
        handleActionsButton,
        handleExportButton,
        handleReturnToMainGrid,
        onGridReady,
        termSet,
    } = useAssetEntriesGrid();

    const assetEntriesColumnDefs: ColDef[] = [
        {
            headerComponentFramework: (props: any) => {
                return (
                    <TranslatableText
                        termSet={termSet}
                        termKey={AssetEntriesGridDefs.Selected}
                    />
                );
            },
            headerCheckboxSelection: true,
            hide: currentGridMode !== EntryGridMode.Default,
            ...DefaultColumnTypes.CheckboxSelection,
        },
        {
            field: 'entryDate',
            headerComponentFramework: (props: any) => {
                return (
                    <TranslatableText
                        termSet={termSet}
                        termKey={AssetEntriesGridDefs.Date}
                    />
                );
            },
            sort: 'asc',
            cellRenderer: 'agGroupCellRenderer',
            cellRendererParams: {
                innerRenderer: 'entryDateCellRenderer',
            },
            hide: currentGridMode !== EntryGridMode.Default,
            ...DefaultColumnTypes.DateOnly,
        },
        {
            field: 'ledgerAssetType.code',
            headerComponentFramework: (props: any) => {
                return (
                    <TranslatableText
                        termSet={termSet}
                        termKey={AssetEntriesGridDefs.Fixed_Asset_Type}
                    />
                );
            },
            cellRenderer: 'assetTypeCellRenderer',
            hide: currentGridMode !== EntryGridMode.Default,
            minWidth: 150,
            maxWidth: 200,
        },
        {
            field: 'entryDate',
            headerComponentFramework: (props: any) => {
                return (
                    <TranslatableText
                        termSet={termSet}
                        termKey={AssetEntriesGridDefs.Entry_Date}
                    />
                );
            },
            cellRenderer: 'agGroupCellRenderer',
            hide: currentGridMode !== EntryGridMode.ByAssetType,
            ...DefaultColumnTypes.DateOnly,
        },
        {
            field: 'ledgerAssetType.code',
            headerComponentFramework: (props: any) => {
                return (
                    <TranslatableText
                        termSet={termSet}
                        termKey={AssetEntriesGridDefs.Asset_Type}
                    />
                );
            },
            cellRenderer: 'agGroupCellRenderer',
            hide: currentGridMode !== EntryGridMode.ByDate,
            ...DefaultColumnTypes.ShortText,
        },
        {
            width: 120,
            minWidth: 100,
            maxWidth: 150,
            field: 'mainLockedSummary',
            headerComponentFramework: (props: any) => {
                return (
                    <TranslatableText
                        termSet={termSet}
                        termKey={AssetEntriesGridDefs.Locked}
                    />
                );
            },
            ...DefaultColumnTypes.StackedColumn,
        },
        {
            headerComponentFramework: (props: any) => {
                return (
                    <TranslatableText
                        termSet={termSet}
                        termKey={AssetEntriesGridDefs.Account_Number}
                    />
                );
            },
            field: 'mainAccountNo',
            cellRenderer: 'mainAccountNoCellRenderer',
            width: 150,
            minWidth: 120,
            maxWidth: 180,
            ...DefaultColumnTypes.AutoHeight,
            ...DefaultColumnTypes.StackedColumn,
        },
        {
            headerComponentFramework: (props: any) => {
                return (
                    <TranslatableText
                        termSet={termSet}
                        termKey={AssetEntriesGridDefs.Entry_Description}
                    />
                );
            },
            field: 'mainDescription',
            cellRenderer: 'mainDescriptionCellRenderer',
            ...DefaultColumnTypes.AutoHeight,
            ...DefaultColumnTypes.StackedColumn,
            minWidth: 250,
            maxWidth: 600,
        },
        {
            headerComponentFramework: (props: any) => {
                return (
                    <TranslatableText
                        termSet={termSet}
                        termKey={AssetEntriesGridDefs.Debit}
                    />
                );
            },
            field: 'mainDebit',
            cellRenderer: 'mainDebitCellRenderer',
            ...DefaultColumnTypes.AutoHeight,
            ...DefaultColumnTypes.StackedCurrencyColumn,
        },
        {
            headerComponentFramework: (props: any) => {
                return (
                    <TranslatableText
                        termSet={termSet}
                        termKey={AssetEntriesGridDefs.Credit}
                    />
                );
            },
            field: 'mainCredit',
            cellRenderer: 'mainCreditCellRenderer',
            ...DefaultColumnTypes.AutoHeight,
            ...DefaultColumnTypes.StackedCurrencyColumn,
        },
    ];

    const detailCellRendererParams = useMemo<any>(() => {
        return {
            detailGridOptions: {
                frameworkComponents: {
                    detailAccountNoCellRenderer: detailAccountNoColumn,
                    detailAccountNameCellRenderer: detailAccountNameColumn,
                    detailCreditCellRenderer: detailCreditColumn,
                    detailDebitCellRenderer: detailDebitColumn,
                },
                columnDefs: [
                    {
                        field: 'fixedAsset.code',
                        headerComponentFramework: (props: any) => {
                            return (
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={AssetEntriesGridDefs.Asset_Code}
                                />
                            );
                        },
                        ...DefaultColumnTypes.ShortText,
                    },
                    {
                        field: 'lockedSummary',
                        headerComponentFramework: (props: any) => {
                            return (
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={AssetEntriesGridDefs.Locked}
                                />
                            );
                        },
                        ...DefaultColumnTypes.LockedInformationColumn,
                    },
                    {
                        headerComponentFramework: (props: any) => {
                            return (
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={
                                        AssetEntriesGridDefs.Account_Number
                                    }
                                />
                            );
                        },
                        field: 'detailAccountNumber',
                        cellRenderer: 'detailAccountNoCellRenderer',
                        width: 125,
                        minWidth: 50,
                        maxWidth: 150,
                        ...DefaultColumnTypes.AutoHeight,
                        ...DefaultColumnTypes.StackedColumn,
                    },
                    {
                        headerComponentFramework: (props: any) => {
                            return (
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={AssetEntriesGridDefs.Account_Name}
                                />
                            );
                        },
                        field: 'detailAccountName',
                        cellRenderer: 'detailAccountNameCellRenderer',
                        width: 300,
                        minWidth: 200,
                        maxWidth: 400,
                        ...DefaultColumnTypes.AutoHeight,
                        ...DefaultColumnTypes.StackedColumn,
                    },
                    {
                        headerComponentFramework: (props: any) => {
                            return (
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={AssetEntriesGridDefs.Debit}
                                />
                            );
                        },
                        field: 'detailDebit',
                        cellRenderer: 'detailDebitCellRenderer',
                        ...DefaultColumnTypes.AutoHeight,
                        ...DefaultColumnTypes.StackedCurrencyColumn,
                    },
                    {
                        headerComponentFramework: (props: any) => {
                            return (
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={AssetEntriesGridDefs.Credit}
                                />
                            );
                        },
                        field: 'detailCredit',
                        cellRenderer: 'detailCreditCellRenderer',
                        ...DefaultColumnTypes.AutoHeight,
                        ...DefaultColumnTypes.StackedCurrencyColumn,
                    },
                ],
                defaultColDef: {
                    flex: 1,
                },
            },
            getDetailRowData: (params) => {
                params.successCallback(params.data.detailAssetEntries);
            },
        } as IDetailCellRendererParams;
    }, [termSet]);

    const mainGridToolbar = () => {
        return (
            <React.Fragment>
                {currentGridMode !== EntryGridMode.Default ? (
                    <Button
                        variant="text"
                        size="small"
                        onClick={() => handleReturnToMainGrid()}
                        startIcon={<ArrowBackIcon />}>
                        <TranslatableText
                            termSet={termSet}
                            termKey={AssetEntriesGridDefs.Toolbar_Back}
                        />
                    </Button>
                ) : null}
                {currentGridMode === EntryGridMode.Default ? (
                    <Button
                        variant="text"
                        size="small"
                        onClick={() => setShowOptionsModal(true)}
                        startIcon={<ListIcon />}>
                        <TranslatableText
                            termSet={termSet}
                            termKey={AssetEntriesGridDefs.Toolbar_Options}
                        />
                    </Button>
                ) : null}
                {canLockEntries ? (
                    <Button
                        variant="text"
                        size="small"
                        disabled={!hasSelectedRows}
                        onClick={() => handleActionsButton()}
                        startIcon={
                            <ArrowForwardIcon style={{ color: '#00A84E' }} />
                        }>
                        <TranslatableText
                            termSet={termSet}
                            termKey={AssetEntriesGridDefs.Toolbar_Actions}
                        />
                    </Button>
                ) : null}
                {canDownloadEntries ? (
                    <Button
                        variant="text"
                        size="small"
                        disabled={hasUnlockedSelected}
                        onClick={() => handleExportButton()}
                        startIcon={<FileDownloadIcon />}>
                        <TranslatableText
                            termSet={termSet}
                            termKey={
                                AssetEntriesGridDefs.Toolbar_Download_Entries
                            }
                        />
                    </Button>
                ) : null}
            </React.Fragment>
        );
    };

    return (
        <React.Fragment>
            <AssetEntryOptionsModal
                open={showOptionsModal}
                onAssetEntriesLoaded={(entries) => setAssetEntryList(entries)}
                onClose={() => setShowOptionsModal(false)}
            />

            <AssetEntryActionsModal
                open={showActionsModal}
                onClose={() => setShowActionsModal(false)}
                selected={selectedEntries}
            />

            <AssetEntryExportModal
                open={showExportModal}
                onClose={() => setShowExportModal(false)}
                selected={exportedEntries}
            />

            <BaseFormGrid
                title={currentTitle}
                defaultColDef={{ flex: 1 }}
                displayGrid={canViewEntries}
                isLoading={false}
                rowData={mainEntryRows}
                columnDefs={assetEntriesColumnDefs}
                gridClass="full-size-item"
                displayToolbar={true}
                rowSelection={'multiple'}
                masterDetail={true}
                suppressRowClickSelection={true}
                detailCellRendererParams={detailCellRendererParams}
                detailRowAutoHeight={true}
                onSelectionChanged={onSelectionChanged}
                onGridReady={onGridReady}
                toolbarChildren={mainGridToolbar()}
                frameworkComponents={{
                    mainAccountNoCellRenderer: mainAccountColumn,
                    mainDescriptionCellRenderer: mainDescriptionColumn,
                    mainCreditCellRenderer: mainCreditColumn,
                    mainDebitCellRenderer: mainDebitColumn,
                    assetTypeCellRenderer: assetTypeColumn,
                    entryDateCellRenderer: entryDateColumn,
                }}
            />
        </React.Fragment>
    );
};
export default AssetEntriesGrid;
