import {
    FA_Asset,
    FA_AssetDisposalEntry,
    FA_DepreciationEntry,
} from '../../../../../types/FixedAsset.types';
import { isNilOrEmpty } from '../../../../../utils/objectUtils';
import { Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { LoadingButton } from '@mui/lab';
import DeleteIcon from '@mui/icons-material/Delete';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import React, { useContext, useEffect, useState } from 'react';
import { DisposalEntryProps } from '../DisposalEntryGrid';
import {
    useDeleteFixedAssetDisposalEntriesMutation,
    useLockFixedAssetDisposalEntriesMutation,
    useUnlockFixedAssetDisposalEntriesMutation,
} from '../../../../../services/fixedAssets/fixedAssets.service';
import { PermissionsUtil } from '../../../../../utils/permissions/permissionsUtil';
import { PERMISSIONS } from '../../../../../constants/permissions/Permissions.constants';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../store';
import { dateTimeFormatter } from '../../../../../utils/formattingUtils';
import SettingsContext from '../../../../../contexts/settings.context';
import { useGetTermSetQuery } from '../../../../../services/i18n/i18n.service';
import { ACCOUNTING_DEFS } from '../../../../../constants/i18n/translations/termSetDefinitions/accounting';
import { skipToken } from '@reduxjs/toolkit/query';

const useDisposalEntryGrid = (props: DisposalEntryProps) => {
    const { settings } = useContext(SettingsContext);
    const { data: termSet } = useGetTermSetQuery(
        settings?.userSettings
            ? {
                  languageId: settings?.userSettings?.languageId,
                  code: ACCOUNTING_DEFS.FIXED_ASSET_REGISTER_FORM,
              }
            : skipToken
    );
    const user = useSelector((state: RootState) => state.user);
    const [disposalEntries, setDisposalEntries] = useState<
        FA_AssetDisposalEntry[]
    >([]);
    const [disposalIsLocked, setDisposalIsLocked] = useState<boolean>(false);
    const [showCreateDisposalModal, setShowCreateDisposalModal] =
        useState<boolean>(false);

    const [lockDisposalEntries, { isLoading: isLockingDisposalEntries }] =
        useLockFixedAssetDisposalEntriesMutation();
    const [unlockDisposalEntries, { isLoading: isUnlockingDisposalEntries }] =
        useUnlockFixedAssetDisposalEntriesMutation();
    const [deleteDisposalEntries, { isLoading: isDeletingDisposalEntries }] =
        useDeleteFixedAssetDisposalEntriesMutation();

    const canCreateDisposalEntry = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.FIXED_ASSETS.DISPOSAL_ENTRY.CREATE
    );
    const canLockDisposalEntry = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.FIXED_ASSETS.DISPOSAL_ENTRY.LOCK
    );
    const canDeleteDisposalEntry = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.FIXED_ASSETS.DISPOSAL_ENTRY.DELETE
    );

    useEffect(() => {
        setNewListOfDisposalEntries(props.disposalEntries);
    }, [props.disposalEntries]);

    const handleDisposalUpdated = (asset: FA_Asset) => {
        setNewListOfDisposalEntries(asset.disposalEntries);
    };

    const setNewListOfDisposalEntries = (entries: FA_AssetDisposalEntry[]) => {
        const newList: FA_AssetDisposalEntry[] = entries || [];

        setDisposalEntries(newList);
        setDisposalIsLocked(
            newList.length > 0 && newList.some((entry) => entry.locked)
        );
    };

    const handleDeleteDisposalEntries = async (event: any) => {
        event.preventDefault();

        try {
            const response: any = await deleteDisposalEntries({
                fixedAssetId: props.assetId,
            });
            handleDisposalUpdated(response.data);
        } catch (e) {
            console.log(e);
        }
    };

    const handleLockDisposalEntries = async (event: any) => {
        event.preventDefault();

        try {
            const response: any = await lockDisposalEntries({
                ids: disposalEntries.map((entry) => entry.id),
            });
            setNewListOfDisposalEntries(response.data);
        } catch (e) {
            console.log(e);
        }
    };

    const handleUnlockDisposalEntries = async (event: any) => {
        event.preventDefault();

        try {
            const response: any = await unlockDisposalEntries({
                ids: disposalEntries.map((entry) => entry.id),
            });
            setNewListOfDisposalEntries(response.data);
        } catch (e) {
            console.log(e);
        }
    };

    const disposalToolbar = () => {
        return (
            <>
                {isNilOrEmpty(disposalEntries) &&
                    (canCreateDisposalEntry ? (
                        <Button
                            variant="text"
                            size="small"
                            onClick={() => setShowCreateDisposalModal(true)}
                            startIcon={
                                <AddIcon style={{ color: '#00A84E' }} />
                            }>
                            Add Disposal Entry
                        </Button>
                    ) : null)}
                {!isNilOrEmpty(disposalEntries) &&
                    !disposalIsLocked &&
                    (canDeleteDisposalEntry ? (
                        <LoadingButton
                            variant="text"
                            size="small"
                            loading={isDeletingDisposalEntries}
                            onClick={handleDeleteDisposalEntries}
                            startIcon={
                                <DeleteIcon style={{ color: '#FF0000' }} />
                            }>
                            Delete Disposal Entry
                        </LoadingButton>
                    ) : null)}
                {!isNilOrEmpty(disposalEntries) &&
                    !disposalIsLocked &&
                    (canLockDisposalEntry ? (
                        <LoadingButton
                            variant="text"
                            size="small"
                            loading={isLockingDisposalEntries}
                            onClick={handleLockDisposalEntries}
                            startIcon={
                                <LockIcon style={{ color: '#FFD700' }} />
                            }>
                            Lock Disposal Entry
                        </LoadingButton>
                    ) : null)}
                {!isNilOrEmpty(disposalEntries) &&
                    disposalIsLocked &&
                    (canLockDisposalEntry ? (
                        <LoadingButton
                            variant="text"
                            size="small"
                            loading={isUnlockingDisposalEntries}
                            onClick={handleUnlockDisposalEntries}
                            startIcon={
                                <LockOpenIcon style={{ color: '#FFD700' }} />
                            }>
                            Unlock Disposal Entry
                        </LoadingButton>
                    ) : null)}
            </>
        );
    };

    const disposalEntriesPinnedBottomRowData =
        props.disposalEntries?.length > 0
            ? [
                  {
                      debit: props.disposalEntriesTotals?.debit,
                      credit: props.disposalEntriesTotals?.credit,
                  },
              ]
            : [];

    const lockedScheduleInformation = (params: any) => {
        const entry: FA_DepreciationEntry = params.data;
        return entry.locked
            ? `${dateTimeFormatter(entry.lockedDate)} (${entry.lockedUserName})`
            : '';
    };

    return {
        disposalEntries,
        disposalToolbar,
        disposalEntriesPinnedBottomRowData,
        showCreateDisposalModal,
        setShowCreateDisposalModal,
        handleDisposalUpdated,
        lockedScheduleInformation,
        termSet,
    };
};
export default useDisposalEntryGrid;
