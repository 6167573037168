import React from 'react';
import { Grid } from '@mui/material';
import RenderDefinition from './RenderDefinition';

const RenderUnappliedDefinitions = ({
    activeTemplates,
    fields,
    handleFieldChange,
    attrValues,
    setAttrValues,
    formMethods,
    attrFieldsDisabled,
    setIsAttributeValid,
}: any) => {
    return (
        <>
            {activeTemplates.map((template: any) => {
                if (template.id === fields?.attrTemplateId) {
                    return (
                        <React.Fragment key={template.id}>
                            {template.attrDefinitions.map((def: any) => {
                                return (
                                    <Grid item xs={6} key={def.id}>
                                        <RenderDefinition
                                            key={def.id}
                                            definition={def}
                                            fields={fields}
                                            handleFieldChange={
                                                handleFieldChange
                                            }
                                            attrValues={attrValues}
                                            setAttrValues={setAttrValues}
                                            formMethods={formMethods}
                                            disabled={attrFieldsDisabled}
                                            setIsAttributeValid={
                                                setIsAttributeValid
                                            }
                                        />
                                    </Grid>
                                );
                            })}
                        </React.Fragment>
                    );
                }
            })}
        </>
    );
};

export default RenderUnappliedDefinitions;
