import { useNavigate } from 'react-router-dom';
import {
    useGetAllDepreciationMethodStatusesQuery,
    useUpdateListDepreciationMethodStatusesMutation,
} from '../../../services/fixedAssets/fixedAssets.service';
import { PermissionsUtil } from '../../../utils/permissions/permissionsUtil';
import { PERMISSIONS } from '../../../constants/permissions/Permissions.constants';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { RoutingValues } from '../../../types/Shared.types';
import { FA_DepreciationMethodStatus } from '../../../types/FixedAsset.types';
import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { getMaxSortOrderValue } from '../../../store/fixedAssets';
import { isNilOrEmpty } from '../../../utils/objectUtils';
import useTranslation from '../../../components/i18n/hooks/useTranslation';
import { ACCOUNTING_DEFS } from '../../../constants/i18n/translations/termSetDefinitions/accounting';
import { ColDef } from 'ag-grid-community';
import DefaultColumnTypes from '../../../components/grids/columns/Column.constants';
import CustomHeader from '../../../components/grids/CustomHeader';
import { DepreciationMethodStatusesGridDefs } from '../../../constants/i18n/translations/termDefinitions/accounting';

const UseFixedAssetDepreciationMethodStatusGrid = () => {
    const dispatch = useDispatch();
    const user = useSelector((state: RootState) => state.user);
    const navigate = useNavigate();

    const { data: statusList, isLoading: isLoadingStatuses } =
        useGetAllDepreciationMethodStatusesQuery();

    const [updateDepreciationMethodStatuses] =
        useUpdateListDepreciationMethodStatusesMutation();

    const [colDefs, setColDefs] = useState([]);
    const [rowData, setRowData] = useState([]);

    const canCreateDepreciationMethodStatus =
        PermissionsUtil.isPermissionEnabled(
            user.permissions,
            PERMISSIONS.FIXED_ASSETS.DEPRECIATION_METHOD_STATUSES.CREATE
        );
    const canViewDepreciationMethodStatus = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.FIXED_ASSETS.DEPRECIATION_METHOD_STATUSES.VIEW
    );
    const canUpdate = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.FIXED_ASSETS.DEPRECIATION_METHOD_STATUSES.EDIT
    );

    const { termSet } = useTranslation(
        ACCOUNTING_DEFS.DEPRECIATION_METHOD_STATUSES_GRID
    );

    useEffect(() => {
        if (!isNilOrEmpty(statusList)) {
            setRowData(statusList);
        }
    }, [statusList]);

    const handleGridEdits = (editedRows: FA_DepreciationMethodStatus[]) => {
        setRowData(editedRows);
        updateDepreciationMethodStatuses(editedRows);
    };

    const redirectToForm = (row: any) => {
        if (row.id) {
            navigate(
                `/accounting/fixedAssets/depreciationMethodStatus/${row.id}`
            );
        } else {
            navigate(
                `/accounting/fixedAssets/depreciationMethodStatus/${RoutingValues.newId}`
            );
        }
    };

    useEffect(() => {
        let maxValue = 1;
        if (statusList && statusList.length > 0) {
            const data = _.maxBy(
                statusList,
                (depreciationStatus: FA_DepreciationMethodStatus) =>
                    depreciationStatus.sortOrder
            );
            maxValue = data.sortOrder + 1;
        }
        dispatch(
            getMaxSortOrderValue({ isLoading: false, maxValue: maxValue })
        );
    }, [statusList]);

    useEffect(() => {
        if (!isNilOrEmpty(rowData) && isNilOrEmpty(colDefs)) {
            const statusColumnDefs: ColDef[] = [
                {
                    field: 'code',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={
                                    DepreciationMethodStatusesGridDefs.Code
                                }
                            />
                        );
                    },
                    ...DefaultColumnTypes.ShortText,
                },
                {
                    field: 'description',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={
                                    DepreciationMethodStatusesGridDefs.Description
                                }
                            />
                        );
                    },
                    ...DefaultColumnTypes.LongText,
                },
                {
                    field: 'isActive',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={
                                    DepreciationMethodStatusesGridDefs.Active
                                }
                            />
                        );
                    },
                    ...DefaultColumnTypes.Boolean,
                },
            ];
            setColDefs(statusColumnDefs);
        }
    }, [rowData]);

    return {
        statusList,
        redirectToForm,
        isLoadingStatuses,
        canCreateDepreciationMethodStatus,
        canViewDepreciationMethodStatus,
        canUpdate,
        handleGridEdits,
        colDefs,
        rowData,
        termSet,
    };
};

export default UseFixedAssetDepreciationMethodStatusGrid;
