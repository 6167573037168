import React from 'react';
import { useParams } from 'react-router-dom';
import { Grid } from '@mui/material';
import TextInput from '../../components/form/formInputs/TextInput/TextInput';
import FormPartial from '../../components/form/formContainer/FormPartial';
import useRoleForm from './hooks/useRoleForm';
import SelectInput from '../../components/form/formInputs/SelectInput/SelectInput';
import BaseForm from '../../components/form/baseForm/BaseForm';
import TranslatableText from '../../components/i18n/TranslatableText';
import { RolesFormDefs } from '../../constants/i18n/translations/termDefinitions/platform';

type ParamTypes = { id: string };

const RoleForm = () => {
    const { id } = useParams<ParamTypes>();
    const {
        fields,
        user,
        users,
        permissions,
        setValues,
        handleClose,
        handleFieldChange,
        handleUpdateRole,
        handleCreateRole,
        businessEntityOptions,
        isLoading,
        formMethods,
        userPermissions,
        termSet,
    } = useRoleForm(id);

    return (
        <BaseForm
            title={
                <>
                    {id ? (
                        fields.name + ` (${fields?.businessEntity?.name})`
                    ) : (
                        <>
                            <TranslatableText
                                termKey={RolesFormDefs.Create_New_Role}
                                termSet={termSet}
                            />{' '}
                            ({fields?.businessEntity?.name})
                        </>
                    )}
                </>
            }
            formMethods={formMethods}
            isLoading={isLoading}
            isNew={!id}
            onCreate={handleCreateRole}
            onUpdate={handleUpdateRole}
            onCancel={handleClose}
            displayCreateButton={true}
            displayUpdateButton={true}
            displayDeleteButton={false}>
            <FormPartial>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <TextInput
                            fullWidth
                            label={
                                <TranslatableText
                                    termKey={RolesFormDefs.Name}
                                    termSet={termSet}
                                />
                            }
                            id="name"
                            value={fields.name}
                            onChange={handleFieldChange}
                            formMethods={formMethods}
                            required={true}
                            maxLength={90}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextInput
                            fullWidth
                            label={
                                <TranslatableText
                                    termKey={RolesFormDefs.Description}
                                    termSet={termSet}
                                />
                            }
                            id="description"
                            value={fields.description}
                            onChange={handleFieldChange}
                            formMethods={formMethods}
                            maxLength={90}
                        />
                    </Grid>
                    {!!id && (
                        <>
                            <Grid item xs={6}>
                                <SelectInput
                                    fullWidth
                                    SelectProps={{
                                        multiple: true,
                                    }}
                                    label={
                                        <TranslatableText
                                            termKey={RolesFormDefs.Permissions}
                                            termSet={termSet}
                                        />
                                    }
                                    id="permissions"
                                    value={fields?.permissions}
                                    onChange={handleFieldChange}
                                    options={userPermissions}
                                    formMethods={formMethods}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <SelectInput
                                    fullWidth
                                    SelectProps={{
                                        multiple: true,
                                    }}
                                    label={
                                        <TranslatableText
                                            termKey={RolesFormDefs.Users}
                                            termSet={termSet}
                                        />
                                    }
                                    id="users"
                                    value={fields?.users}
                                    onChange={handleFieldChange}
                                    options={users}
                                    formMethods={formMethods}
                                />
                            </Grid>
                        </>
                    )}
                </Grid>
            </FormPartial>
        </BaseForm>
    );
};

export default RoleForm;
