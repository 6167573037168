import {
    useLockDepreciationSchedulesMutation,
    useUnlockDepreciationSchedulesMutation,
} from '../../../../../services/fixedAssets/fixedAssets.service';
import { ICellRendererParams } from 'ag-grid-community';
import { FA_DepreciationSchedule } from '../../../../../types/FixedAsset.types';
import { LoadingButton } from '@mui/lab';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import LockIcon from '@mui/icons-material/Lock';
import React, { useContext } from 'react';
import { DepreciationScheduleGridProps } from '../DepreciationScheduleGrid';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../store';
import { PermissionsUtil } from '../../../../../utils/permissions/permissionsUtil';
import { PERMISSIONS } from '../../../../../constants/permissions/Permissions.constants';
import { useGetTermSetQuery } from '../../../../../services/i18n/i18n.service';
import { ACCOUNTING_DEFS } from '../../../../../constants/i18n/translations/termSetDefinitions/accounting';
import { skipToken } from '@reduxjs/toolkit/query';
import SettingsContext from '../../../../../contexts/settings.context';

export const useDepreciationScheduleGrid = (
    props: DepreciationScheduleGridProps
) => {
    const user = useSelector((state: RootState) => state.user);

    const [lockSchedules, { isLoading: isLockingSchedules }] =
        useLockDepreciationSchedulesMutation();
    const [unlockSchedules, { isLoading: isUnlockingSchedules }] =
        useUnlockDepreciationSchedulesMutation();
    const { settings } = useContext(SettingsContext);
    const { data: termSet } = useGetTermSetQuery(
        settings?.userSettings
            ? {
                  languageId: settings?.userSettings?.languageId,
                  code: ACCOUNTING_DEFS.FIXED_ASSET_REGISTER_FORM,
              }
            : skipToken
    );
    const canLockDepreciationSchedules = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.FIXED_ASSETS.DEPRECIATION_SCHEDULES.LOCK
    );

    const lockUnlockColumn = (params: ICellRendererParams) => {
        const depreciationSchedule: FA_DepreciationSchedule = params.data;

        // @ts-ignore
        const scheduleList = params.scheduleList;

        return canLockDepreciationSchedules &&
            depreciationSchedule.id !== null ? (
            depreciationSchedule.locked ? (
                <LoadingButton
                    variant="outlined"
                    color="error"
                    size={'small'}
                    style={{ maxHeight: '20px', display: 'flex' }}
                    startIcon={<LockIcon />}
                    loading={isUnlockingSchedules}
                    onClick={async () =>
                        await handleUnlockSchedule(
                            depreciationSchedule,
                            scheduleList
                        )
                    }>
                    Locked
                </LoadingButton>
            ) : (
                <LoadingButton
                    variant="outlined"
                    color="success"
                    size={'small'}
                    style={{ maxHeight: '20px', display: 'flex' }}
                    startIcon={<LockOpenIcon />}
                    loading={isLockingSchedules}
                    onClick={async () =>
                        await handleLockSchedule(
                            depreciationSchedule,
                            scheduleList
                        )
                    }>
                    Unlocked
                </LoadingButton>
            )
        ) : null;
    };

    const handleUnlockSchedule = async (
        schedule: FA_DepreciationSchedule,
        list: FA_DepreciationSchedule[]
    ) => {
        try {
            const response: any = await unlockSchedules({ ids: [schedule.id] });
            _getUpdatedSchedule(list, response.data);
        } catch (e) {
            console.log(e);
        }
    };

    const handleLockSchedule = async (
        schedule: FA_DepreciationSchedule,
        list: FA_DepreciationSchedule[]
    ) => {
        try {
            const response: any = await lockSchedules({ ids: [schedule.id] });
            _getUpdatedSchedule(list, response.data);
        } catch (e) {
            console.log(e);
        }
    };

    const _getUpdatedSchedule = (
        originalList: FA_DepreciationSchedule[],
        updatedList: FA_DepreciationSchedule[]
    ) => {
        const newList: FA_DepreciationSchedule[] = originalList.map(
            (schedule) =>
                updatedList.find((dSchedule) => schedule.id === dSchedule.id) ||
                schedule
        );
    };

    const pinnedBottomRowData =
        props.depreciationSchedule?.length > 0
            ? [
                  {
                      percentage: props.totalPercentage,
                      id: null,
                  } as any,
              ]
            : [];

    return {
        lockUnlockColumn,
        handleUnlockSchedule,
        handleLockSchedule,
        pinnedBottomRowData,
        termSet,
    };
};

export default useDepreciationScheduleGrid;
