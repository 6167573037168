import React from 'react';
import SafetyDataSheetSectionHeaderContainer from './SafetyDataSheetSectionHeaderContainer';

const SafetyDataSheetSectionHeaderShowForm = () => {
    return (
        <>
            <SafetyDataSheetSectionHeaderContainer showForm={true} />
        </>
    );
};

export default SafetyDataSheetSectionHeaderShowForm;
