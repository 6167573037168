import { CasSpeciesClass } from '../../../../types/formulation';
import { datacorAppApi } from '../../../datacorAppApi';

const serviceUrl: string = 'cas-species-class';

export const casSpeciesClassApi = datacorAppApi.injectEndpoints({
    endpoints: (build) => ({
        listSpeciesClassDropdown: build.query<
            { label: string; value: bigint }[],
            void
        >({
            query: () => serviceUrl,
            transformResponse: (response: CasSpeciesClass[]) => {
                return response.map((casSpeciesClass) => {
                    return {
                        label: casSpeciesClass.type,
                        value: casSpeciesClass.id,
                    };
                });
            },
            providesTags: ['CasSpeciesClass'],
        }),
    }),
});

export const { useListSpeciesClassDropdownQuery } = casSpeciesClassApi;
