import {
    BaseCreateEntityArgs,
    BaseUpdateEntityArgs,
} from '../../../serviceInterfaces';
import { SdsBlob, SdsBlobDefinition } from '../../../../types/formulation';
import { datacorAppApi } from '../../../datacorAppApi';

const serviceGetUrl: string = 'sds/sdsBlobDefinition';
const serviceUrl: string = 'sds/sdsBlob';

export const sdsBlobApi = datacorAppApi.injectEndpoints({
    endpoints: (build) => ({
        createSdsBlob: build.mutation<any, BaseCreateEntityArgs<SdsBlob>>({
            query: (args) => ({
                url: serviceUrl,
                method: 'POST',
                body: args.postBody,
            }),
            invalidatesTags: ['SdsBlob', 'ActiveSdsBlob'],
        }),
        updateSdsBlob: build.mutation<any, BaseUpdateEntityArgs<SdsBlob>>({
            query: (args) => ({
                url: `${serviceUrl}/${args.id}`,
                method: 'PUT',
                body: args.postBody,
            }),
            invalidatesTags: ['SdsBlob', 'ActiveSdsBlob'],
        }),
        getSdsBlob: build.query<SdsBlobDefinition, any>({
            query: (args) => {
                return {
                    url: `${serviceGetUrl}`,
                    params: {
                        sdsId: args.sdsId ? args.sdsId : '',
                        sectionNumber: args.sectionNumber,
                        subSectionNumber: args.subSectionNumber,
                    },
                };
            },
            providesTags: ['ActiveSdsBlob'],
        }),
    }),
});

export const {
    useGetSdsBlobQuery,
    useCreateSdsBlobMutation,
    useUpdateSdsBlobMutation,
} = sdsBlobApi;
