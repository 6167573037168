import React from 'react';
import { GridOptions } from '../../../components/grids/Grid.constants';
import useItemMasterGrid from './hooks/useItemMasterGrid';
import { ColDef, ValueGetterParams } from 'ag-grid-community';
import DefaultColumnTypes from '../../../components/grids/columns/Column.constants';
import BaseFormGrid from '../../../components/grids/BaseFormGrid';
import CustomHeader from '../../../components/grids/CustomHeader';
import { ItemMasterGridDefs } from '../../../constants/i18n/translations/termDefinitions/formulation';
import TranslatableText from '../../../components/i18n/TranslatableText';

const ItemMasterGrid = () => {
    const {
        itemMasterList,
        isLoadingItemMasterList,
        getUOMDescryId,
        redirectToForm,
        canViewItemMaster,
        canCreateItemMaster,
        termSet,
    } = useItemMasterGrid();

    const columnDefs: ColDef[] = [
        {
            field: 'itemId',
            headerComponentFramework: (props: any) => {
                return (
                    <CustomHeader
                        {...props}
                        termSet={termSet}
                        termKey={ItemMasterGridDefs.Item_ID}
                    />
                );
            },
            ...DefaultColumnTypes.ShortText,
        },
        {
            field: 'itemName',
            headerComponentFramework: (props: any) => {
                return (
                    <CustomHeader
                        {...props}
                        termSet={termSet}
                        termKey={ItemMasterGridDefs.Item_Name}
                    />
                );
            },
            ...DefaultColumnTypes.MediumText,
        },
        {
            field: 'version',
            headerComponentFramework: (props: any) => {
                return (
                    <CustomHeader
                        {...props}
                        termSet={termSet}
                        termKey={ItemMasterGridDefs.Version}
                    />
                );
            },
            ...DefaultColumnTypes.NumberOnly,
            minWidth: 150,
        },
        {
            field: 'description1',
            headerComponentFramework: (props: any) => {
                return (
                    <CustomHeader
                        {...props}
                        termSet={termSet}
                        termKey={ItemMasterGridDefs.Description_1}
                    />
                );
            },
            ...DefaultColumnTypes.MediumText,
        },
        {
            field: 'description2',
            headerComponentFramework: (props: any) => {
                return (
                    <CustomHeader
                        {...props}
                        termSet={termSet}
                        termKey={ItemMasterGridDefs.Description_2}
                    />
                );
            },
            ...DefaultColumnTypes.MediumText,
        },
        {
            field: 'itemStatus.status',
            headerComponentFramework: (props: any) => {
                return (
                    <CustomHeader
                        {...props}
                        termSet={termSet}
                        termKey={ItemMasterGridDefs.Status}
                    />
                );
            },
            ...DefaultColumnTypes.ShortText,
        },
        {
            field: 'inventoryType.type',
            headerComponentFramework: (props: any) => {
                return (
                    <CustomHeader
                        {...props}
                        termSet={termSet}
                        termKey={ItemMasterGridDefs.Inventory_Type}
                    />
                );
            },
            ...DefaultColumnTypes.MediumText,
        },
        {
            field: 'upc',
            headerComponentFramework: (props: any) => {
                return (
                    <CustomHeader
                        {...props}
                        termSet={termSet}
                        termKey={ItemMasterGridDefs.UPC}
                    />
                );
            },
            ...DefaultColumnTypes.ShortText,
        },
        {
            field: 'stockUnitId',
            headerComponentFramework: (props: any) => {
                return (
                    <CustomHeader
                        {...props}
                        termSet={termSet}
                        termKey={ItemMasterGridDefs.Stock_Unit}
                    />
                );
            },
            valueGetter: (params) => getUOMDescryId(params.data.stockUnitId),
            filterParams: {
                valueGetter: (params: ValueGetterParams) =>
                    getUOMDescryId(params.data.stockUnitId),
            },
            ...DefaultColumnTypes.ShortText,
        },
        {
            field: 'cost',
            headerComponentFramework: (props: any) => {
                return (
                    <CustomHeader
                        {...props}
                        termSet={termSet}
                        termKey={ItemMasterGridDefs.Cost}
                    />
                );
            },
            ...DefaultColumnTypes.Currency,
        },
    ];

    const defaultColumnDefinitionOptions = {
        ...GridOptions.sortFilterAndWrapColumns,
        floatingFilter: true,
    };

    return (
        <BaseFormGrid
            title={
                <>
                    <TranslatableText
                        termKey={ItemMasterGridDefs.Title}
                        termSet={termSet}
                    />
                </>
            }
            displayGrid={canViewItemMaster}
            isLoading={isLoadingItemMasterList}
            rowData={itemMasterList}
            columnDefs={columnDefs}
            gridClass="full-size-item"
            dataKey={'id'}
            displayToolbar={true}
            displayCreateNewButton={canCreateItemMaster}
            displayExportDataButton={false}
            displayResetStateButton={false}
            onCreateNew={() => redirectToForm(null)}
            onRowClicked={({ data }) => redirectToForm(data)}
            defaultColDef={defaultColumnDefinitionOptions}
            sizeColumnsToFit={true}></BaseFormGrid>
    );
};

export default ItemMasterGrid;
