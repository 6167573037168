import { datacorAppApi } from '../datacorAppApi';

export const equationsApi = datacorAppApi.injectEndpoints({
    endpoints: (build) => ({
        calculateFormulaValues: build.mutation<any, any>({
            query: (args) => ({
                url: `setup/calculateFormulaValues`,
                method: 'PUT',
                body: args.postBody,
            }),
            invalidatesTags: ['ConvFactor'],
        }),
    }),
});

export const { useCalculateFormulaValuesMutation } = equationsApi;
