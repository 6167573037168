import {
    useCreateSupplierAddressByMutation,
    useDeleteSupplierAddressByMutation,
    useDropdownSupplierAddressStatusQuery,
    useDropdownSupplierAddressTypeQuery,
    useGetSupplierAddressQuery,
    useUpdateSupplierAddressByMutation,
} from '../../../services/supplier/supplier.service';
import { SupplierAddress } from '../../../types/Supplier.types';
import useBaseForm from '../../form/hooks/useBaseForm';
import { SUPPLY_CHAIN_DEFS } from '../../../constants/i18n/translations/termSetDefinitions/supply-chain';
import useTranslation from '../../../components/i18n/hooks/useTranslation';
import { useContext, useEffect, useState } from 'react';
import NavFormContext from '../../../contexts/navForm.context';
import {
    useGetCountriesForDropdownQuery,
    useGetStatesQuery,
} from '../../../services/organizations/organizations.service';
import { RoutingValues } from '../../../types/Shared.types';
import SettingsContext from '../../../contexts/settings.context';
import { isNilOrEmpty } from '../../../utils/objectUtils';
import { skipToken } from '@reduxjs/toolkit/query';
import { PermissionsUtil } from '../../../utils/permissions/permissionsUtil';
import { PERMISSIONS } from '../../../constants/permissions/Permissions.constants';

const useSupplierAddressForm = (id: string) => {
    //#region Params
    const { supplierNav } = useContext(NavFormContext);
    const { settings } = useContext(SettingsContext);
    const { businessEntitySettings } = settings;
    //#endregion

    //#region Translation
    const { termSet, isLoadingTermSet } = useTranslation(
        SUPPLY_CHAIN_DEFS.SUPPLIER_ADDRESS_FORM
    );
    //#endregion

    //#region Permissions
    const canCreate = PermissionsUtil.hasPermission(
        PERMISSIONS.SUPPLIERS.SUPPLIER.CREATE
    );
    const canUpdate = PermissionsUtil.hasPermission(
        PERMISSIONS.SUPPLIERS.SUPPLIER.EDIT
    );
    const canDelete = PermissionsUtil.hasPermission(
        PERMISSIONS.SUPPLIERS.SUPPLIER.DELETE
    );
    //#endregion

    //#region Form and Data
    const [createMutation] = useCreateSupplierAddressByMutation();
    const [updateMutation] = useUpdateSupplierAddressByMutation();
    const [deleteMutation] = useDeleteSupplierAddressByMutation();
    const { data: statusOptions, isLoading: isLoadingStatuses } =
        useDropdownSupplierAddressStatusQuery();
    const { data: typeOptions, isLoading: isLoadingTypes } =
        useDropdownSupplierAddressTypeQuery();
    const { data: countryOptions, isLoading: isLoadingCountries } =
        useGetCountriesForDropdownQuery();
    const { data: stateOptions, isLoading: isLoadingStates } =
        useGetStatesQuery();
    const [stateSelectOptions, setStateSelectOptions] = useState([]);
    const { data: entity, isLoading: isLoadingEntity } =
        useGetSupplierAddressQuery(id === RoutingValues.newId ? skipToken : id);

    const blankEntity: SupplierAddress = {
        id: null,
        code: '',
        businessEntityId: null,
        supplierId: supplierNav ? supplierNav.supplierId : null,
        addressStatusId: null,
        addressTypeId: null,
        countryCodeId: null,
        stateCodeId: null,
        name: '',
        street: '',
        city: '',
        county: '',
        postalCode: '',
        phone: '',
        email: '',
        createdAt: null,
        createdBy: null,
        updatedAt: null,
        updatedBy: null,
    };

    const {
        fields,
        formMethods,
        onCreate,
        onUpdate,
        onDelete,
        closeForm,
        copyForm,
        handleFieldChange,
        setValues,
        auditableFields,
        formType,
    } = useBaseForm({
        closePath: `/suppliers/supplier/${supplierNav.supplierId}`,
        copyPath: `/suppliers/supplierAddress/${RoutingValues.newId}`,
        blankEntity: blankEntity,
        activeEntity: entity,
        getDescription: () => {
            return `Supplier Address ${fields.code}`;
        },
        createEntity: () => {
            return createMutation({ postBody: fields });
        },
        updateEntity: () => {
            return updateMutation({
                id: id as unknown as number,
                postBody: fields,
            });
        },
        deleteEntity: () => {
            return deleteMutation(id);
        },
    });
    //#endregion

    const onCountryCodeChanged = (event: any) => {
        handleFieldChange(event);

        if (!stateOptions && !event.target.value) {
            return;
        }

        handleCountrySelection(event.target.value);
    };

    const handleSetDefaultCountryAndState = () => {
        if (id === RoutingValues.newId) {
            const defaultId = countryOptions?.find(
                (country: any) =>
                    country.code2 === businessEntitySettings?.defaultCountry
            )?.id;

            setValues({
                countryCodeId: defaultId || '',
                stateCodeId: businessEntitySettings?.defaultState || '',
            });
        }
    };

    const handleCountrySelection = (countryId: number) => {
        const statesFilteredByCountry = stateOptions
            ?.filter((state: any) => {
                return state.countryCodeId === countryId;
            })
            .map((state: any) => {
                return {
                    value: state.id,
                    label: state.code + ' - ' + state.name,
                };
            });

        setStateSelectOptions(statesFilteredByCountry);
    };

    //#region Effects
    useEffect(() => {
        if (
            !isNilOrEmpty(countryOptions) &&
            !isNilOrEmpty(stateOptions) &&
            businessEntitySettings?.defaultCountry &&
            id === RoutingValues.newId
        ) {
            handleSetDefaultCountryAndState();
        }
    }, [countryOptions, stateOptions, businessEntitySettings]);

    useEffect(() => {
        if (!isNilOrEmpty(entity)) {
            handleCountrySelection(entity.countryCodeId);
        }
    }, [entity]);

    useEffect(() => {
        if (formType === RoutingValues.copy) {
            setValues({
                id: null,
                uuid: undefined,
                code: `${fields.code}-copy`,
            });
        }
    }, [formType]);
    //#endregion

    /**
     * Check if it is loading the row data and the term set definition
     */
    const isLoadingData = (): boolean => isLoadingEntity || isLoadingTermSet;

    return {
        fields,
        termSet,
        auditableFields,
        formMethods,
        statusOptions,
        typeOptions,
        countryOptions,
        stateSelectOptions,
        formType,
        onCreate,
        onUpdate,
        onDelete,
        closeForm,
        copyForm,
        handleFieldChange,
        onCountryCodeChanged,
        isLoadingData,
        isLoadingStatuses,
        isLoadingTypes,
        isLoadingCountries,
        isLoadingStates,
        canCreate,
        canUpdate,
        canDelete,
    };
};

export default useSupplierAddressForm;
