import * as _ from 'lodash';

const REVISION_KEYS = {
    GROUPS: 'groups',
    FROM_GROUPS: 'groupsChange',
    ROLES: 'roles',
    FROM_ROLES: 'rolesChange',
    SUPERVISOR: 'supervisor',
    DEPARTMENT_CODE: 'departmentCodeId',
    DATACOR_ADMIN: 'isDatacorAdmin',
    APP_STREAM: 'isAppStreamUser',
    ZONE: 'zone',
    PERMISSIONS: 'permissions',
    SUBSCRIBERS: 'subscribers',
    BUSINESS_ENTITIES: 'businessEntities',
    ATTRIBUTE_DEFINITIONS: 'attrDefinitions',
    OPTIONS: 'options',
    DEFAULT_VALUE: 'defaultValue',
};

/* for an object array in the delta to be shown
 the values you want to show needs to be joined/formatted however needed
  for your usecase */
export default class RevisionValueFormatter {
    //the key you want to get from object array on + the full delta object
    format(key: any, delta: any) {
        //group formatter
        let beforeGroupNames: any = [];
        let afterGroupNames: any = [];

        //role formatter
        let beforeRoleNames: any = [];
        let afterRoleNames: any = [];

        //zone formatter
        let deltaBeforeZoneString = '';
        let deltaAfterZoneString = '';

        let deltaBeforeSubscribers: any = [];
        let deltaAfterSubscribers: any = [];

        let deltaBeforeBusinessEnts: any = [];
        let deltaAfterBusinessEnts: any = [];

        let deltaBeforeDefinitions: any = [];
        let deltaAfterDefinitions: any = [];

        switch (key) {
            // you can add your own value formatter for your usecase by
            // adding your key to the revision keys constant
            case REVISION_KEYS.GROUPS:
                delta.groups.before.forEach((group: any) => {
                    beforeGroupNames.push(`${group.group.name}`);
                });

                delta.groups.after.forEach((group: any) => {
                    afterGroupNames.push(`${group.group.name}`);
                });

                return {
                    before: beforeGroupNames.join(', '),
                    after: afterGroupNames.join(', '),
                };
            case REVISION_KEYS.FROM_GROUPS:
                delta.groupsChange.before.forEach((group: any) => {
                    beforeGroupNames.push(`${group.group.name}`);
                });

                delta.groupsChange.after.forEach((group: any) => {
                    afterGroupNames.push(`${group.group.name}`);
                });

                if (delta.groupsChange.before > delta.groupsChange.after) {
                    return {
                        before: beforeGroupNames[0],
                        after: '',
                    };
                } else {
                    return {
                        before: '',
                        after: afterGroupNames[0],
                    };
                }
            case REVISION_KEYS.ROLES:
                delta.roles.before.forEach((role: any) => {
                    beforeRoleNames.push(`${role.role.name}`);
                });

                delta.roles.after.forEach((role: any) => {
                    afterRoleNames.push(`${role.role.name}`);
                });

                return {
                    before: beforeRoleNames.join(', '),
                    after: afterRoleNames.join(', '),
                };
            case REVISION_KEYS.FROM_ROLES:
                delta.rolesChange.before.forEach((role: any) => {
                    beforeRoleNames.push(`${role.role.name}`);
                });

                delta.rolesChange.after.forEach((role: any) => {
                    afterRoleNames.push(`${role.role.name}`);
                });

                if (delta.rolesChange.before > delta.rolesChange.after) {
                    return {
                        before: beforeRoleNames[0],
                        after: '',
                    };
                } else {
                    return {
                        before: '',
                        after: afterRoleNames[0],
                    };
                }
            case REVISION_KEYS.SUPERVISOR:
                return {
                    before: `${delta?.supervisor?.before[0]?.firstName || ''} ${
                        delta?.supervisor?.before[0]?.lastName || ''
                    }`,
                    after: `${delta?.supervisor?.after[0]?.firstName} ${delta?.supervisor?.after[0]?.lastName}  `,
                };
            case REVISION_KEYS.APP_STREAM:
                return {
                    before: delta?.isAppStreamUser?.before ? 'Yes' : 'No',
                    after: delta?.isAppStreamUser?.after ? 'Yes' : 'No',
                };
            case REVISION_KEYS.DATACOR_ADMIN:
                return {
                    before: delta?.isDatacorAdmin?.before ? 'Yes' : 'No',
                    after: delta?.isDatacorAdmin?.after ? 'Yes' : 'No',
                };
            case REVISION_KEYS.ZONE:
                //check if each of the appropiate delta fields are different
                //if so show them. Since we're getting back the whole zone object
                if (
                    !_.isEqual(
                        delta?.zone?.before[0]?.platform,
                        delta?.zone?.after[0]?.platform
                    )
                ) {
                    deltaBeforeZoneString += `Name: ${delta?.zone?.before[0]?.platform} \n`;
                    deltaAfterZoneString += `Name: ${delta?.zone?.after[0]?.platform} \n`;
                }

                if (
                    !_.isEqual(
                        delta?.zone?.before[0]?.url,
                        delta?.zone?.after[0]?.url
                    )
                ) {
                    deltaBeforeZoneString += `URL: ${delta?.zone?.before[0]?.url} \n`;
                    deltaAfterZoneString += `URL: ${delta?.zone?.after[0]?.url} \n`;
                }

                if (
                    !_.isEqual(
                        delta?.zone?.before[0]?.port,
                        delta?.zone?.after[0]?.port
                    )
                ) {
                    deltaBeforeZoneString += `Port: ${delta?.zone?.before[0]?.port} \n`;
                    deltaAfterZoneString += `Port: ${delta?.zone?.after[0]?.port} \n`;
                }

                return {
                    before: deltaBeforeZoneString,
                    after: deltaAfterZoneString,
                };
            case REVISION_KEYS.SUBSCRIBERS:
                delta.subscribers.before.forEach((subscriber: any) => {
                    deltaBeforeSubscribers.push(subscriber.subscriber.name);
                });

                delta.subscribers.after.forEach((subscriber: any) => {
                    deltaAfterSubscribers.push(subscriber.subscriber.name);
                });

                return {
                    before: deltaBeforeSubscribers.join(', '),
                    after: deltaAfterSubscribers.join(', '),
                };
            case REVISION_KEYS.BUSINESS_ENTITIES:
                delta.businessEntities.before.forEach((businessEnt: any) => {
                    deltaBeforeBusinessEnts.push(
                        businessEnt.businessEntity.name
                    );
                });

                delta.businessEntities.after.forEach((businessEnt: any) => {
                    deltaAfterBusinessEnts.push(
                        businessEnt.businessEntity.name
                    );
                });

                return {
                    before: deltaBeforeBusinessEnts.join(', '),
                    after: deltaAfterBusinessEnts.join(', '),
                };
            case REVISION_KEYS.ATTRIBUTE_DEFINITIONS:
                delta.attrDefinitions.before.forEach((definition: any) => {
                    deltaBeforeDefinitions.push(definition.name);
                });

                delta.attrDefinitions.after.forEach((definition: any) => {
                    deltaAfterDefinitions.push(definition.name);
                });

                return {
                    before: deltaBeforeDefinitions.join(', '),
                    after: deltaAfterDefinitions.join(', '),
                };
            case REVISION_KEYS.OPTIONS:
                JSON.parse(delta.options.before).forEach((option: any) => {
                    deltaBeforeDefinitions.push(option.option);
                });

                JSON.parse(delta.options.after).forEach((option: any) => {
                    deltaAfterDefinitions.push(option.option);
                });

                return {
                    before: deltaBeforeDefinitions.join(', '),
                    after: deltaAfterDefinitions.join(', '),
                };
            case REVISION_KEYS.DEFAULT_VALUE:
                try {
                    JSON.parse(delta.defaultValue.before);
                    JSON.parse(delta.defaultValue.before);
                    return {
                        before: '',
                        after: 'Pick List Selections Changed...',
                    };
                } catch (e) {
                    return {
                        before: delta[key].before,
                        after: delta[key].after,
                    };
                }
            // add any additional formatters based on key in object array here
            // the default format is just returning the before and after
            default:
                return { before: delta[key].before, after: delta[key].after };
        }
    }
}
