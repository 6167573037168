import { datacorAppApi } from '../../datacorAppApi';
import { CasCompositionOperator } from '../../../types/formulation';
import { SelectionOption } from '../../../types/Shared.types';

const serviceUrl: string = 'cas-operators';

export const casCompositionOperatorService = datacorAppApi.injectEndpoints({
    endpoints: (build) => ({
        getCasCompositionOperatorsForDropdown: build.query<
            SelectionOption[],
            number
        >({
            query: (operatorType) => {
                return {
                    url: serviceUrl,
                    params: {
                        operatorType: operatorType,
                    },
                };
            },
            transformResponse: (response: CasCompositionOperator[]) => {
                return response.map((operator: CasCompositionOperator) => {
                    return {
                        label: operator.operator,
                        value: operator.id,
                    };
                });
            },
            providesTags: ['CasCompositionOperators'],
        }),
    }),
});

export const { useGetCasCompositionOperatorsForDropdownQuery } =
    casCompositionOperatorService;
