import React, { useContext, useEffect, useState } from 'react';
import { RootStateOrAny, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
    GhsHazardPhraseLang,
    GhsHazardPhrase,
} from '../../../../../types/formulation';
import { Language } from '../../../../../services/organizations/organizations.service';
import { SelectionOption } from '../../../../../types/Shared.types';
import { PermissionsUtil } from '../../../../../utils/permissions/permissionsUtil';
import { PERMISSIONS } from '../../../../../constants/permissions/Permissions.constants';
import _ from 'lodash';
import { useListGhsHazardPhrasesQuery } from '../../../../../services/formulation/ghsHazardPhrases/ghsHazardPhrases.service';
import SettingsContext from '../../../../../contexts/settings.context';
import { useGetTermSetQuery } from '../../../../../services/i18n/i18n.service';
import { FORMULATION_DEFS } from '../../../../../constants/i18n/translations/termSetDefinitions/formulation';
import { skipToken } from '@reduxjs/toolkit/query';

export interface HazardFormContainerProps {
    phraseId: string;
    defaultLangId: bigint;
    languageList: Array<Language>;
    phraseIdList: Array<string>;
    handleSetIsFormDirty(formDirty: boolean): void;
    handlePromptFormDirty(onConfirm: () => void, onCancel?: () => void): void;
}

const useGhsHazardPhraseFormContainer = ({
    phraseId,
    defaultLangId,
    languageList,
    handlePromptFormDirty,
}: HazardFormContainerProps) => {
    const navigate = useNavigate();
    const user = useSelector((state: RootStateOrAny) => state.user);

    const canCreateGhsHazardPhrase = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.FORMULATION.GHS_HAZARD_PHRASE.CREATE
    );

    const [phraseLangList, setPhraseLangList] = useState(
        null as Array<GhsHazardPhraseLang>
    );
    const [langTabValue, setLangTabValue] = useState(0);
    const [languageOptionList, setLanguageOptionList] = useState(
        null as SelectionOption[]
    );
    const [showModal, setShowModal] = useState(false);
    const [disableLangButton, setDisableLangButton] = useState(false);

    const { data: rawHazardPhraseList, isFetching: isLoadingHazardPhraseList } =
        useListGhsHazardPhrasesQuery();

    const hazardPhraseList = _.filter(rawHazardPhraseList, {
        phraseId: phraseId,
    });

    const { settings } = useContext(SettingsContext);
    const { data: termSet } = useGetTermSetQuery(
        settings?.userSettings
            ? {
                  languageId: settings?.userSettings?.languageId,
                  code: FORMULATION_DEFS.GHS_HAZARD_PHRASES_FORM,
              }
            : skipToken
    );

    useEffect(() => {
        if (phraseId && !isLoadingHazardPhraseList && hazardPhraseList) {
            if (hazardPhraseList.length > 0) {
                setPhraseLangListData(hazardPhraseList);
                buildLanguageSelectionOption();
            }
        } else if (!phraseId && defaultLangId) {
            const newPhrase: GhsHazardPhrase = {
                id: undefined,
                phraseId: null,
                langId: defaultLangId,
                businessEntityId: user?.businessEntity?.id,
                hazardTypeId: null,
            };
            setPhraseLangListData([newPhrase]);
        }
    }, [rawHazardPhraseList, phraseId, languageList, defaultLangId]);

    useEffect(() => {
        setLangTabValue(0);
    }, [phraseId]);

    const setPhraseLangListData = (
        hazardPhraseList: Array<GhsHazardPhrase>
    ) => {
        const phraseLangList: Array<GhsHazardPhraseLang> = [];
        hazardPhraseList.map((hazardPhrase) => {
            const currentPhraseLang: GhsHazardPhraseLang =
                createPhrecautionaryPhraseLang(hazardPhrase);
            if (currentPhraseLang.langId === defaultLangId) {
                phraseLangList.unshift(currentPhraseLang);
            } else {
                phraseLangList.push(currentPhraseLang);
            }
        });
        setPhraseLangList(phraseLangList);
    };

    const resetTabData = () => {
        const newPhrase = phraseLangList?.find((phrase) => !phrase.id);
        if (hazardPhraseList && newPhrase) {
            setPhraseLangListData(hazardPhraseList);
        }
    };

    const createPhrecautionaryPhraseLang = (
        hazardPhrase: GhsHazardPhrase
    ): GhsHazardPhraseLang => {
        const hazardPhraseLang: GhsHazardPhraseLang = {
            id: hazardPhrase.id,
            phraseId: hazardPhrase.phraseId,
            langId: hazardPhrase.langId,
            companySpecific: hazardPhrase.companySpecific,
            hazardTypeId: hazardPhrase.hazardTypeId,
            langText: languageList?.find(
                (lang) => lang.id === hazardPhrase.langId
            )?.name,
        };
        return hazardPhraseLang;
    };

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        const onConfirm = () => {
            resetTabData();
            setLangTabValue(newValue);
        };
        handlePromptFormDirty(onConfirm);
    };

    const buildLanguageSelectionOption = () => {
        if (languageList) {
            const langOptionList: SelectionOption[] = [];
            languageList.forEach((currentLang) => {
                const phrase = hazardPhraseList.find(
                    (currentPhrase) => currentPhrase.langId === currentLang.id
                );
                if (!phrase) {
                    langOptionList.push({
                        value: currentLang.id,
                        label: currentLang.name,
                    });
                }
            });
            setLanguageOptionList(langOptionList);
        }
    };

    const addNewLanguage = (selectedLangId: bigint) => {
        if (selectedLangId) {
            const defaultPhrase = hazardPhraseList.find(
                (current) => current.langId === defaultLangId
            );
            const newPhrase: GhsHazardPhrase = {
                id: undefined,
                phraseId: defaultPhrase.phraseId,
                langId: selectedLangId,
                businessEntityId: user?.businessEntity?.id,
                hazardTypeId: defaultPhrase.hazardTypeId,
                companySpecific: defaultPhrase.companySpecific,
            };
            const phraseList = Array.from(hazardPhraseList);
            phraseList.push(newPhrase);
            setPhraseLangListData(phraseList);
            setLangTabValue(phraseList.length - 1);
        }
        setShowModal(false);
    };

    const handleOpenModal = () => {
        setShowModal(true);
    };

    const handleSetPreviousTab = () => {
        if (langTabValue > 0) {
            setLangTabValue(langTabValue - 1);
        }
    };

    const cancelNewLanguage = (closeForm: boolean) => {
        handlePromptFormDirty(() => {
            resetTabData();
            if (closeForm) {
                navigate('/formulation/ghsHazardPhrases');
            } else {
                setLangTabValue(0);
            }
        });
    };

    const handleSetDisableLangButton = (disableButton: boolean) => {
        if (disableButton !== disableLangButton) {
            setDisableLangButton(disableButton);
        }
    };

    return {
        isLoadingHazardPhraseList,
        phraseLangList,
        langTabValue,
        languageOptionList,
        showModal,
        disableLangButton,
        handleTabChange,
        addNewLanguage,
        handleOpenModal,
        handleSetPreviousTab,
        cancelNewLanguage,
        handleSetDisableLangButton,
        canCreateGhsHazardPhrase,
        termSet,
    };
};

export default useGhsHazardPhraseFormContainer;
