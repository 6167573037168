import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { RootStateOrAny, useSelector } from 'react-redux';
import { PermissionsUtil } from '../../../../utils/permissions/permissionsUtil';
import { PERMISSIONS } from '../../../../constants/permissions/Permissions.constants';
import { useListToxicSpeciesQuery } from '../../../../services/formulation/toxicSpecies/toxicSpecies.service';
import SettingsContext from '../../../../contexts/settings.context';
import { useGetTermSetQuery } from '../../../../services/i18n/i18n.service';
import { FORMULATION_DEFS } from '../../../../constants/i18n/translations/termSetDefinitions/formulation';
import { skipToken } from '@reduxjs/toolkit/query';

const useToxicSpeciesGrid = () => {
    const navigate = useNavigate();
    const user = useSelector((state: RootStateOrAny) => state.user);

    const { isLoading, data: toxicSpeciesList } = useListToxicSpeciesQuery();

    const canCreateToxicSpecies = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.FORMULATION.TOXIC_SPECIES.CREATE
    );
    const canViewToxicSpecies = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.FORMULATION.TOXIC_SPECIES.VIEW
    );

    const { settings } = useContext(SettingsContext);
    const { data: termSet } = useGetTermSetQuery(
        settings?.userSettings
            ? {
                  languageId: settings?.userSettings?.languageId,
                  code: FORMULATION_DEFS.TOXIC_SPECIES_GRID,
              }
            : skipToken
    );

    const redirectToForm = (row: any) => {
        if (row && row.id) {
            navigate(`/formulation/toxicSpecies/${row.id}`);
        } else {
            navigate('/formulation/toxicSpecies/new');
        }
    };

    return {
        isLoading,
        toxicSpeciesList,
        redirectToForm,
        canCreateToxicSpecies,
        canViewToxicSpecies,
        termSet,
    };
};

export default useToxicSpeciesGrid;
