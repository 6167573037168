import React from 'react';
import GhsHazardPhraseContainer from './GhsHazardPhraseContainer';

const GhsHazardPhraseShowForm = () => {
    return (
        <>
            <GhsHazardPhraseContainer showForm={true} />
        </>
    );
};

export default GhsHazardPhraseShowForm;
