import {
    CasMaster,
    CasRegulation,
    Regulation,
} from '../../../../../../../types/formulation';
import React, { useContext, useEffect, useState } from 'react';
import { PermissionsUtil } from '../../../../../../../utils/permissions/permissionsUtil';
import { PERMISSIONS } from '../../../../../../../constants/permissions/Permissions.constants';
import { GridOptions } from '../../../../../../../components/grids/Grid.constants';
import { isNilOrEmpty } from '../../../../../../../utils/objectUtils';
import { RowStatus } from '../../../../../../../components/grids/hooks/useBaseGridEditable';
import _, { cloneDeep } from 'lodash';
import {
    CountryCode,
    useGetCountriesQuery,
    useGetStatesQuery,
} from '../../../../../../../services/organizations/organizations.service';
import DefaultColumnTypes from '../../../../../../../components/grids/columns/Column.constants';
import { editableGridCellSelect } from '../../../../../../../components/grids/columns/editableGridCellSelect';
import { ColumnMaxValueValidator } from '../../../../../../grid/validators/columnMaxValue.validator';
import { ColumnNumericValueValidator } from '../../../../../../grid/validators/columnNumericValue.validator';
import applyEditableGridValidations from '../../../../../../grid/utils/applyEditableGridValidations';
import { ColumnRequiredValueValidator } from '../../../../../../grid/validators/columnRequiredValue.validator';
import { ColumnMinValueValidator } from '../../../../../../grid/validators/columnMinValue.validator';
import { useListRegulationsQuery } from '../../../../../../../services/formulation/regulations/regulations.service';
import { useGetRegulationSectionsForDropDownQuery } from '../../../../../../../services/formulation/regulations/section/regulationSection.service';
import { useGetRegulationBodiesForDropDownQuery } from '../../../../../../../services/formulation/regulations/body/regulationBody.service';
import {
    SelectedDropdownOption,
    SelectionOption,
} from '../../../../../../../types/Shared.types';
import { ChildEditableGridWithCopyFunctionProps } from '../../../../../../grid/utils/editableGrid/ChildEditableGridWithCopyFunctionProps';
import {
    getStateCodeById,
    StateCodeDisplay,
} from '../../../../../shared/utilities/formulation.utils';
import SettingsContext from '../../../../../../../contexts/settings.context';
import { useGetTermSetQuery } from '../../../../../../../services/i18n/i18n.service';
import { FORMULATION_DEFS } from '../../../../../../../constants/i18n/translations/termSetDefinitions/formulation';
import { skipToken } from '@reduxjs/toolkit/query';
import CustomHeader from '../../../../../../../components/grids/CustomHeader';
import { CasMasterFormDefs } from '../../../../../../../constants/i18n/translations/termDefinitions/formulation';

const useCasRegulationsGrid = (
    props: ChildEditableGridWithCopyFunctionProps<CasMaster, CasRegulation>
) => {
    const { currentParentRecord, user } = props.parentData;

    const { showDeleteButton, isGridEditable } = props.displayGridButtons;

    const { handleChildrenRecords } = props.helpers;

    const { rowsData, setRowsData } = props.copyMethods;

    const [maxRowId, setMaxRowId] = useState(0);
    const onListYes = { label: 'Yes', value: 100 as unknown as bigint };
    const onListNo = { label: 'No', value: 200 as unknown as bigint };
    const onListOptionsList = [onListYes, onListNo];

    const {
        data: regulationOptionsList,
        isLoading: isLoadingRegulationOptionsList,
    } = useListRegulationsQuery();

    const [showMultiSelectGridModal, setShowMultiSelectGridModal] =
        useState(false);

    const [regulationList, setRegulationList] = useState([] as Regulation[]);

    const [casRegulationsColumnDefs, setCasRegulationsColumnDefs] =
        useState(null);

    const [statesColDefs, setStatesColDefs] = useState(null);
    const [countryColDefs, setCountryColDefs] = useState(null);

    const canViewRegulations = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.FORMULATION.REGULATIONS.VIEW
    );

    const colDefOptions = {
        ...GridOptions.sortFilterAndWrapColumns,
        floatingFilter: true,
    };

    const { isLoading: isLoadingStates, data: statesList } =
        useGetStatesQuery();

    const { isLoading: isLoadingCountries, data: countryList } =
        useGetCountriesQuery();

    const {
        data: regulationSectionOptions,
        isLoading: isLoadingRegulationSectionOptions,
    } = useGetRegulationSectionsForDropDownQuery();

    const {
        data: regulationBodyOptions,
        isLoading: isLoadingRegulationBodiesOptions,
    } = useGetRegulationBodiesForDropDownQuery();

    const { settings } = useContext(SettingsContext);
    const { data: termSet } = useGetTermSetQuery(
        settings?.userSettings
            ? {
                  languageId: settings?.userSettings?.languageId,
                  code: FORMULATION_DEFS.CAS_MASTER_FORM,
              }
            : skipToken
    );

    /**
     * sorts records in casRegulations grid according to sdsSortOrder, then code
     * @param casRegulations
     */
    const sortAndSetCasRegulationRecords = (
        casRegulations: CasRegulation[]
    ) => {
        const newList = cloneDeep(
            casRegulations.filter(
                (current) => current.rowStatus !== RowStatus.DELETED
            )
        );
        let currentRowId = maxRowId;
        newList.forEach((current) => {
            current.rowId = ++currentRowId;
        });
        setMaxRowId(currentRowId);
        setRowsData(_.orderBy(newList, ['sortBy', 'code'], ['asc']));
    };

    /**
     * finds matching StateCode according to given param
     * @param stateCodeId
     */
    const getStateById = (stateCodeId: bigint): StateCodeDisplay => {
        return getStateCodeById(stateCodeId, statesList);
    };

    /**
     * used to manage multiselect modal state
     * filters hazard phrase list here to guarantee the list in the modal is accurate
     * @param showModal
     */
    const handleSetShowMultiSelectGridModal = (showModal: boolean) => {
        if (showModal) {
            setFilteredRegulationsList();
        }
        setShowMultiSelectGridModal(showModal);
    };

    /**
     * finds matching CountryCode according to given param
     * if no match is found, returns empty object
     * @param countryCodeId
     */
    const getCountryById = (countryCodeId: bigint): SelectedDropdownOption => {
        let countryCode: SelectedDropdownOption = {
            id: null,
            name: '',
            display: '',
        };
        if (!isLoadingCountries && countryList) {
            (countryList as CountryCode[]).find((country) => {
                if (country.id === countryCodeId) {
                    countryCode = {
                        id: country.id,
                        name: country.name,
                        //display field mandatory for use with select modal
                        display: country.name,
                    };
                }
            });
        }
        return countryCode;
    };

    /**
     * filters out records found in casRegulations grid for multiselect modal
     * then sets them for the multiselect modal to use
     */
    const setFilteredRegulationsList = () => {
        if (canViewRegulations) {
            const filteredRegulationOptionsList =
                removeCasRegulationsFromRegulationsList();
            setRegulationList(filteredRegulationOptionsList);
        } else {
            setRegulationList([]);
        }
    };

    /**
     * removes select options from multiselect modal data
     * if phrase is already present in casRegulations grid
     */
    const removeCasRegulationsFromRegulationsList = (): Regulation[] => {
        let regulationsListWithoutCas: Regulation[] = regulationOptionsList;
        // identifies regulation options that are in casRegulations grid
        // finds their matching record in list used for multiselect
        _.forEach(rowsData, function (casRegulation: CasRegulation) {
            const matchingCasRegulations = _.find(
                regulationOptionsList,
                (currentRegulation: Regulation) => {
                    // rows that are marked deleted we don't filter out
                    // so that they can be added again via multiselect
                    return (
                        currentRegulation.code === casRegulation.code &&
                        casRegulation.rowStatus !== RowStatus.DELETED
                    );
                }
            );

            // if there are matching records, we remove them from filtered list
            if (matchingCasRegulations) {
                regulationsListWithoutCas = _.without(
                    regulationsListWithoutCas,
                    matchingCasRegulations
                );
            }
        });
        // returns regulationOptionsList sorted and filtered
        return _.sortBy(regulationsListWithoutCas, ['code']);
    };

    /**
     * converts regulations selected from multiselect to valid casRegulations
     * @param selectedRecords
     */
    const handleSelectedRecords = (selectedRecords: Regulation[]) => {
        const newRows: CasRegulation[] = [];
        let index = maxRowId;
        selectedRecords?.forEach((record: Regulation) => {
            index = index + 1;
            setMaxRowId((maxRowId) => maxRowId + 1);
            const updatedRecord: CasRegulation = { ...record };
            updatedRecord.rowId = index;
            updatedRecord.rowStatus = RowStatus.ADDED;
            updatedRecord.stateCodeId = record.stateCodeId;
            updatedRecord.stateCode = getStateById(record.stateCodeId);
            updatedRecord.stateCodeName = getStateById(record.stateCodeId).name;
            updatedRecord.countryCode = { id: null, name: '' };
            updatedRecord.countryCodeId = null;
            updatedRecord.onList = false;
            updatedRecord.onListDisplay = onListNo.value;
            updatedRecord.importManufactureLimit = null;
            updatedRecord.threshold = null;
            updatedRecord.notes = null;
            updatedRecord.sortBy = null;
            delete updatedRecord.id;
            newRows.push(updatedRecord);
        });

        updateCasRegulationsRecords(newRows);
        handleRowValidations(newRows);
        handleSaveGridEdits(newRows);
    };

    /**
     * Concatenates any non-deleted rows with the new added casRegulations list
     * @param newCasRegulations
     */
    const updateCasRegulationsRecords = (
        newCasRegulations: CasRegulation[]
    ) => {
        const filteredList = rowsData.filter(
            (current: CasRegulation) => current.rowStatus !== RowStatus.DELETED
        );
        setRowsData(
            filteredList
                ? filteredList.concat(newCasRegulations)
                : newCasRegulations
        );
    };

    /**
     * sets validations on each of row fields in casRegulations grid
     * @param editedRows
     */
    const handleRowValidations = (editedRows: CasRegulation[]) => {
        editedRows?.forEach((row: CasRegulation) => {
            row.validationRules = [
                ColumnRequiredValueValidator('Code', row.code),
                ColumnMaxValueValidator('Code', 25, row.code),
                ColumnRequiredValueValidator('Title', row.title),
                ColumnMaxValueValidator('Title', 50, row.title),
                ColumnMaxValueValidator('Threshold', 250, row.threshold),
                ColumnMaxValueValidator('Notes', 250, row.notes),
                ColumnNumericValueValidator(
                    'Import/Manufacture Limit',
                    0,
                    row.importManufactureLimit
                ),
                ColumnMinValueValidator(
                    'Import/Manufacture Limit',
                    0,
                    Number(row.importManufactureLimit)
                ),
                ColumnMaxValueValidator(
                    'Import/Manufacture Limit',
                    999999999,
                    Number(row.importManufactureLimit)
                ),
                ColumnNumericValueValidator('SDS Sort Order', 0, row.sortBy),
                ColumnMinValueValidator(
                    'SDS Sort Order',
                    0,
                    Number(row.sortBy)
                ),
            ];
            applyEditableGridValidations(row, `Regulation ${row.code}`);
        });
        return editedRows;
    };

    /**
     * takes onListValue and converts to boolean for db usage
     * @param onListValue
     */
    const getOnListBoolean = (onListValue: bigint) => {
        return onListValue === onListYes.value;
    };

    /**
     * takes onList boolean and converts to onList object for use in select
     * @param onList
     */
    const setOnListDisplay = (onList: boolean): SelectionOption => {
        return onList ? onListYes : onListNo;
    };

    /**
     * when save edits button is clicked
     * converts data from selects and multiselect grid options in rows
     * into a format that can be saved to the parent cas record
     * @param editedRows
     */
    const handleSaveGridEdits = (editedRows: CasRegulation[]) => {
        const updatedFields: CasRegulation[] = [...rowsData];
        editedRows?.forEach((casRegulation: CasRegulation) => {
            const index = rowsData?.findIndex(
                (row: any) =>
                    row.code === casRegulation.code &&
                    row.rowId === casRegulation.rowId
            );

            if (
                !isNilOrEmpty(casRegulation.stateCode) &&
                !isNilOrEmpty(casRegulation.stateCode.id)
            ) {
                casRegulation.stateCodeId = casRegulation.stateCode.id;
            }

            if (
                !isNilOrEmpty(casRegulation.countryCode) &&
                !isNilOrEmpty(casRegulation.countryCode.id)
            ) {
                casRegulation.countryCodeId = casRegulation.countryCode.id;
            }

            if (!isNilOrEmpty(casRegulation.onListDisplay)) {
                casRegulation.onList = getOnListBoolean(
                    casRegulation.onListDisplay
                );
            }
            if (index > -1) {
                updatedFields[index] = casRegulation;
            } else {
                updatedFields.push(casRegulation);
            }
        });
        sortAndSetCasRegulationRecords(updatedFields);
        handleChildrenRecords('casRegulations', updatedFields);
    };

    /**
     * when data is ready, sets each casRegulation record with data that fits
     * the proper formatting for display in the casRegulations grid
     * then sorts and sets the data to be consumned by the grid
     */
    useEffect(() => {
        const includeEditedRows = currentParentRecord?.casRegulations
            ? !isNilOrEmpty(
                  currentParentRecord.casRegulations.find(
                      (current) => current.rowStatus
                  )
              )
            : false;
        if (
            currentParentRecord?.casRegulations &&
            countryList &&
            statesList &&
            !includeEditedRows
        ) {
            const regulations: CasRegulation[] = [];
            let index = maxRowId;

            currentParentRecord?.casRegulations.forEach(
                (casRegulation: CasRegulation) => {
                    index = index + 1;
                    setMaxRowId((maxRowId) => maxRowId + 1);
                    if (casRegulation.rowStatus !== RowStatus.DELETED) {
                        const newCasRegulation: CasRegulation = {
                            ...casRegulation,
                            stateCode: getStateById(casRegulation.stateCodeId),
                            countryCode: getCountryById(
                                casRegulation.countryCodeId
                            ),
                            onListDisplay: setOnListDisplay(
                                casRegulation.onList
                            ).value,
                            rowId: casRegulation.rowId
                                ? casRegulation.rowId
                                : index,
                        };
                        regulations.push(newCasRegulation);
                    }
                }
            );
            sortAndSetCasRegulationRecords(regulations);
        }
    }, [
        currentParentRecord?.casRegulations,
        isLoadingCountries,
        isLoadingStates,
        statesList,
        countryList,
    ]);

    /**
     * builds column definitions for state select pop out modal
     */
    useEffect(() => {
        if (!isLoadingStates && !isNilOrEmpty(statesList)) {
            setStatesColDefs([
                {
                    field: 'code',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={
                                    CasMasterFormDefs.Regulations_State_Form_UOM
                                }
                            />
                        );
                    },
                    cellClass: 'ag-left-aligned-cell',
                },
                {
                    field: 'name',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={
                                    CasMasterFormDefs.Regulations_State_Form_Description
                                }
                            />
                        );
                    },
                    cellClass: 'ag-left-aligned-cell',
                },
            ]);
        }
    }, [isLoadingStates, statesList]);

    /**
     * builds column definitions for country select pop out modal
     */
    useEffect(() => {
        if (!isLoadingCountries && !isNilOrEmpty(countryList)) {
            setCountryColDefs([
                {
                    field: 'name',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={
                                    CasMasterFormDefs.Regulations_Country_Form_Name
                                }
                            />
                        );
                    },
                    cellClass: 'ag-left-aligned-cell',
                },
            ]);
        }
    }, [isLoadingCountries, countryList]);

    /**
     * builds column definitions for cas Regulations grid
     */
    useEffect(() => {
        if (
            !isLoadingRegulationOptionsList &&
            !isNilOrEmpty(regulationOptionsList) &&
            !isNilOrEmpty(countryColDefs) &&
            !isNilOrEmpty(statesColDefs) &&
            !isLoadingRegulationBodiesOptions &&
            !isNilOrEmpty(regulationBodyOptions) &&
            !isLoadingRegulationSectionOptions &&
            !isNilOrEmpty(regulationSectionOptions)
        ) {
            setCasRegulationsColumnDefs([
                {
                    field: 'deleteColumn',
                    minWidth: 50,
                    hide: !showDeleteButton || !isGridEditable,
                    filter: false,
                },
                {
                    field: 'code',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={CasMasterFormDefs.Regulations_Code}
                            />
                        );
                    },
                    editable: false,
                    minWidth: 95,
                    filter: 'agTextColumnFilter',
                },
                {
                    field: 'title',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={CasMasterFormDefs.Regulations_Title}
                            />
                        );
                    },
                    editable: isGridEditable,
                    minWidth: 100,
                    filter: 'agTextColumnFilter',
                },
                {
                    field: 'onListDisplay',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={CasMasterFormDefs.Regulations_On_List}
                            />
                        );
                    },
                    minWidth: 115,
                    filter: 'agTextColumnFilter',
                    ...editableGridCellSelect(onListOptionsList),
                    editable: isGridEditable,
                },
                {
                    field: 'importManufactureLimit',
                    minWidth: 225,
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={
                                    CasMasterFormDefs.Regulations_Import_Manufacture_Limit
                                }
                            />
                        );
                    },
                    filter: 'agNumberColumnFilter',
                    editable: isGridEditable,
                },
                {
                    field: 'threshold',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={
                                    CasMasterFormDefs.Regulations_Threshold
                                }
                            />
                        );
                    },
                    filter: 'agTextColumnFilter',
                    editable: isGridEditable,
                    ...DefaultColumnTypes.LargeTextEditor,
                    minWidth: 125,
                },
                {
                    field: 'notes',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={CasMasterFormDefs.Regulations_Notes}
                            />
                        );
                    },
                    filter: 'agTextColumnFilter',
                    editable: isGridEditable,
                    ...DefaultColumnTypes.LargeTextEditor,
                    minWidth: 100,
                },
                {
                    field: 'regulationBodyId',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={
                                    CasMasterFormDefs.Regulations_Regulation_Body
                                }
                            />
                        );
                    },
                    ...editableGridCellSelect(regulationBodyOptions),
                    editable: isGridEditable,
                },
                {
                    field: 'regulationSectionId',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={CasMasterFormDefs.Regulations_Section}
                            />
                        );
                    },
                    minWidth: 115,
                    ...editableGridCellSelect(regulationSectionOptions),
                    editable: isGridEditable,
                },
                {
                    field: 'stateCode',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={
                                    CasMasterFormDefs.Regulations_State_Province
                                }
                            />
                        );
                    },
                    ...DefaultColumnTypes.MediumText,
                    cellRenderer: (params: any) => params.value.display,
                    editable: canViewRegulations && isGridEditable,
                    useGridPopup: true,
                    minWidth: 140,
                    gridPopupParameters: {
                        isLoading: isLoadingStates,
                        displayGrid: true,
                        rowData: statesList,
                        defaultColDef: colDefOptions,
                        columnDefs: statesColDefs,
                        displayField: 'name',
                    },
                },
                {
                    field: 'countryCode',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={CasMasterFormDefs.Regulations_Country}
                            />
                        );
                    },
                    cellRenderer: (params: any) => params.value.display,
                    editable: isGridEditable,
                    useGridPopup: true,
                    minWidth: 115,
                    gridPopupParameters: {
                        isLoading: isLoadingCountries,
                        displayGrid: true,
                        rowData: countryList,
                        defaultColDef: colDefOptions,
                        columnDefs: countryColDefs,
                        displayField: 'name',
                    },
                },
            ]);
        }
    }, [
        statesColDefs,
        countryColDefs,
        isLoadingRegulationOptionsList,
        regulationOptionsList,
        regulationBodyOptions,
        regulationSectionOptions,
        isLoadingRegulationBodiesOptions,
        isLoadingRegulationSectionOptions,
    ]);

    /**
     * makes sure that when a new item is added/deleted from casRegulations
     * that deleted rows will be available again in multiselect
     */
    useEffect(() => {
        setFilteredRegulationsList();
    }, [rowsData]);

    /**
     * filters and sets regulationOptions for multiselect for the first page load
     * also sort and set casRegulations
     */
    useEffect(() => {
        if (!isLoadingRegulationOptionsList && isNilOrEmpty(regulationList)) {
            setFilteredRegulationsList();
        }
    }, [isLoadingRegulationOptionsList]);

    return {
        handleSaveGridEdits,
        rowsData,
        regulationList,
        showMultiSelectGridModal,
        handleSetShowMultiSelectGridModal,
        handleSelectedRecords,
        casRegulationsColumnDefs,
        handleRowValidations,
        colDefOptions,
        getStateById,
        termSet,
    };
};

export default useCasRegulationsGrid;
