import { CopyFormProps } from '../../../../form/helpers/CopyFormProps';
import {
    RDFormula,
    RDFormulaCasComposition,
    RDFormulaComponent,
    RDFormulaHazardClassificationMapping,
    RDFormulaTotal,
} from '../../../../../types/formulation';
import { useEffect, useState } from 'react';
import { RoutingValues } from '../../../../../types/Shared.types';
import { RowStatus } from '../../../../../components/grids/hooks/useBaseGridEditable';
import { v4 as uuidv4 } from 'uuid';

const useCopyRDFormulaForm = (props: CopyFormProps<RDFormula>) => {
    const { fields, dataFromQuery, setValues, formType } = props;

    const [rdFormulaCasCompositions, setRDFormulaCasCompositions] = useState(
        [] as RDFormulaCasComposition[]
    );
    const [rdFormulaComponents, setRDFormulaComponents] = useState(
        [] as RDFormulaComponent[]
    );

    const [
        rdFormulaHazardClassificationMappings,
        setRdFormulaHazardClassificationMappings,
    ] = useState<RDFormulaHazardClassificationMapping[]>([]);

    const createRDFormulaComponentsCopy = (
        today: Date
    ): RDFormulaComponent[] => {
        const rdFormulaComponentsCopy: RDFormulaComponent[] =
            rdFormulaComponents?.map((rdFormulaComps: any, index: number) => {
                const newRecord = {
                    ...rdFormulaComps,
                    rowStatus: RowStatus.ADDED,
                    rowId: index + 1,
                    createdAt: today,
                    updatedAt: today,
                };
                delete newRecord.id;
                delete newRecord.formulaId;

                return newRecord;
            });
        setRDFormulaComponents(rdFormulaComponentsCopy);
        return rdFormulaComponentsCopy;
    };

    const createRDFormulaCasCompositionsCopy = (
        today: Date
    ): RDFormulaCasComposition[] => {
        const rdFormulaCasCompositionsCopy: RDFormulaCasComposition[] =
            rdFormulaCasCompositions?.map(
                (rdFormulaCasComps: any, index: number) => {
                    const newRecord = {
                        ...rdFormulaCasComps,
                        rowStatus: RowStatus.ADDED,
                        rowId: index + 1,
                        createdAt: today,
                        updatedAt: today,
                    } as RDFormulaCasComposition;
                    delete newRecord.id;
                    delete newRecord.formulaId;

                    return newRecord;
                }
            );
        setRDFormulaCasCompositions(rdFormulaCasCompositionsCopy);
        return rdFormulaCasCompositionsCopy;
    };

    const createRDFormulaTotalsCopy = (today: Date): RDFormulaTotal => {
        const newRecord = {
            ...fields?.formulaTotals,
            createdAt: today,
            updatedAt: today,
        };
        delete newRecord.id;
        delete newRecord.formulaId;

        return newRecord;
    };

    const createRdFormulaHazardClassificationMappingsCopy = (
        today: Date
    ): RDFormulaHazardClassificationMapping[] => {
        const rdFormulaHazardClassificationMappingCopy: RDFormulaHazardClassificationMapping[] =
            rdFormulaHazardClassificationMappings?.map(
                (rdFormulaHazardClassificationMapping: any, index: number) => {
                    const newRecord = {
                        ...rdFormulaHazardClassificationMapping,
                        rowStatus: RowStatus.ADDED,
                        rowId: index + 1,
                        createdAt: today,
                        updatedAt: today,
                    } as RDFormulaHazardClassificationMapping;
                    delete newRecord.id;
                    delete newRecord.formulaId;
                    delete newRecord.additionalInformationId;

                    return newRecord;
                }
            );
        setRdFormulaHazardClassificationMappings(
            rdFormulaHazardClassificationMappingCopy
        );
        return rdFormulaHazardClassificationMappingCopy;
    };

    useEffect(() => {
        if (formType === RoutingValues.copy) {
            const today: Date = new Date(new Date().getTime());

            const rdFormulaCasCompositionsCopy: RDFormulaCasComposition[] =
                createRDFormulaCasCompositionsCopy(today);

            const rdFormulaComponentsCopy: RDFormulaComponent[] =
                createRDFormulaComponentsCopy(today);

            const rdFormulaTotalCopy: RDFormulaTotal =
                createRDFormulaTotalsCopy(today);

            const rdFormulaHazardClassificationMappingCopy: RDFormulaHazardClassificationMapping[] =
                createRdFormulaHazardClassificationMappingsCopy(today);

            setValues({
                ...dataFromQuery,
                id: null,
                formulaCode: '',
                components:
                    rdFormulaComponentsCopy || ([] as RDFormulaComponent[]),
                casCompositions:
                    rdFormulaCasCompositionsCopy ||
                    ([] as RDFormulaCasComposition[]),
                formulaTotals: rdFormulaTotalCopy || (null as RDFormulaTotal),
                hazardClassificationMaps:
                    rdFormulaHazardClassificationMappingCopy ||
                    (null as RDFormulaHazardClassificationMapping[]),
                createdAt: today,
                updatedAt: today,
                uuid: uuidv4(),
            });
        }
    }, [formType, dataFromQuery]);
    return {
        copyMethods: {
            rdFormulaCasCompositions,
            rdFormulaComponents,
            rdFormulaHazardClassificationMappings,
            setRDFormulaCasCompositions,
            setRDFormulaComponents,
            setRdFormulaHazardClassificationMappings,
        },
    };
};

export default useCopyRDFormulaForm;
