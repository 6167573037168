import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import useOptionsMenuList, { OptionItem } from './hooks/useOptionsMenuList';

export type OptionsMenuListProps = {
    iconMenu: any;
    listOptions: OptionItem[];
    onClickOption: (event: any) => void;
};

const OptionsMenuList = (props: OptionsMenuListProps) => {
    const { handleClose, handleClick, onClick, anchorEl, open } =
        useOptionsMenuList(props);

    return (
        <React.Fragment>
            <Button
                id="option-menu"
                aria-controls={open ? 'option-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                variant="text"
                disableElevation
                onClick={handleClick}
                startIcon={props.iconMenu}
                endIcon={<KeyboardArrowDownIcon />}>
                Schedules and Entries
            </Button>
            <Menu
                id="option-menu-list"
                MenuListProps={{ 'aria-labelledby': 'option-menu' }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}>
                {props.listOptions.map((mode: any) =>
                    mode.canDisplay ? (
                        <MenuItem
                            disableRipple
                            id={mode.id}
                            key={mode.id}
                            onClick={onClick}>
                            {mode.labelOption}
                        </MenuItem>
                    ) : null
                )}
            </Menu>
        </React.Fragment>
    );
};

export default OptionsMenuList;
