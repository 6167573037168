import React from 'react';
import { useParams } from 'react-router-dom';
import { Checkbox, FormControlLabel, Grid } from '@mui/material';
import BaseForm from '../../../../components/form/baseForm/BaseForm';
import FormPartial from '../../../../components/form/formContainer/FormPartial';
import TextInput from '../../../../components/form/formInputs/TextInput/TextInput';
import SelectInput from '../../../../components/form/formInputs/SelectInput/SelectInput';
import NumericInput from '../../../../components/form/formInputs/NumericInput/NumericInput';
import useFormulaPropertyForm from './hooks/useFormulaPropertyForm';
import { FormulaPropertiesFormDefs } from '../../../../constants/i18n/translations/termDefinitions/formulation';
import TranslatableText from '../../../../components/i18n/TranslatableText';

type ParamTypes = { id: string };

const FormulaPropertyForm = () => {
    const { id } = useParams<ParamTypes>();

    const {
        fields,
        handleFieldChange,
        formMethods,
        onCreate,
        onUpdate,
        onDelete,
        closeForm,
        isLoadingFormulaProperty,
        isLoadingPropertyTypes,
        propertyTypeOptions,
        canCreateFormulaProperty,
        canUpdateFormulaProperty,
        canDeleteFormulaProperty,
        termSet,
        hasEntityChanges,
    } = useFormulaPropertyForm(id);

    return (
        <BaseForm
            title={
                <TranslatableText
                    termSet={termSet}
                    termKey={FormulaPropertiesFormDefs.Formula_Properties}
                />
            }
            formMethods={formMethods}
            isLoading={isLoadingFormulaProperty}
            isNew={!id}
            onCreate={onCreate}
            onUpdate={onUpdate}
            onDelete={onDelete}
            onCancel={closeForm}
            hasEntityChanges={hasEntityChanges}
            displayCreateButton={canCreateFormulaProperty}
            displayUpdateButton={canUpdateFormulaProperty}
            displayDeleteButton={canDeleteFormulaProperty}
            readOnly={id && !canUpdateFormulaProperty}>
            <FormPartial>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <TextInput
                            fullWidth
                            label={
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={
                                        FormulaPropertiesFormDefs.Property_Code
                                    }
                                />
                            }
                            id="propertyCode"
                            formMethods={formMethods}
                            value={fields.propertyCode}
                            onChange={handleFieldChange}
                            required={true}
                            maxLength={30}
                            disabled={!!id}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextInput
                            fullWidth
                            label={
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={
                                        FormulaPropertiesFormDefs.Description
                                    }
                                />
                            }
                            id="description"
                            formMethods={formMethods}
                            value={fields.description}
                            onChange={handleFieldChange}
                            required={true}
                            maxLength={50}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <NumericInput
                            fullWidth
                            label={
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={
                                        FormulaPropertiesFormDefs.Sort_Order
                                    }
                                />
                            }
                            id="sortOrder"
                            formMethods={formMethods}
                            value={fields.sortOrder}
                            onChange={handleFieldChange}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextInput
                            fullWidth
                            label={
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={
                                        FormulaPropertiesFormDefs.Default_Value
                                    }
                                />
                            }
                            id="defaultValue"
                            formMethods={formMethods}
                            value={fields.defaultValue}
                            onChange={handleFieldChange}
                            maxLength={25}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <SelectInput
                            fullWidth
                            label={
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={
                                        FormulaPropertiesFormDefs.Property_Type
                                    }
                                />
                            }
                            SelectProps={{
                                showClearButton: id
                                    ? canUpdateFormulaProperty
                                    : true,
                                readOnly: id && !canUpdateFormulaProperty,
                            }}
                            id="propertyTypeId"
                            formMethods={formMethods}
                            value={fields.propertyTypeId}
                            onChange={handleFieldChange}
                            options={propertyTypeOptions}
                            disabled={isLoadingPropertyTypes}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <FormControlLabel
                            label={
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={
                                        FormulaPropertiesFormDefs.Show_On_SDS
                                    }
                                />
                            }
                            control={
                                <Checkbox
                                    id="showOnSds"
                                    checked={fields.showOnSds}
                                    onChange={handleFieldChange}
                                />
                            }
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <FormControlLabel
                            label={
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={
                                        FormulaPropertiesFormDefs.SDS_Generation
                                    }
                                />
                            }
                            control={
                                <Checkbox
                                    id="sdsGeneration"
                                    checked={fields.sdsGeneration}
                                    onChange={handleFieldChange}
                                />
                            }
                            disabled={!!id}
                        />
                    </Grid>
                </Grid>
            </FormPartial>
        </BaseForm>
    );
};

export default FormulaPropertyForm;
