import React from 'react';
import EntitySettingsDrawer from '../entitySettings/entitySettingsDrawer';
import GridDrawer from './gridDrawer/GridDrawer';
import BaseGridSortModal from './BaseGridSortModal';

const GridPopovers = (props: any) => {
    const { gridRef, useRowDrag, parentProps } = props;

    /**
     * popup grid drawer props
     * **/
    const {
        openGridDrawer,
        setOpenGridDrawer,
        displayDrawer,
        title,
        detailText,
        grid,
    } = props.popoutGrid ?? {};

    /**
     * entity settings props
     * **/
    const { displayEntitySettings, openSettings, setOpenSettings, settingKey } =
        props.entitySettings ?? {};

    const { openSortGrid, setOpenSortGrid } = props.sortGrid ?? {};

    return (
        <>
            {displayEntitySettings && (
                <>
                    <EntitySettingsDrawer
                        open={openSettings}
                        setOpen={setOpenSettings}
                        settingKey={settingKey}
                        settingValue={gridRef}
                        type={'Grid'}
                        saveNewText={'Save New Grid Layout'}
                        currentSelectionText={'Your Current Grid Layout'}
                    />
                </>
            )}
            {displayDrawer && (
                <>
                    <GridDrawer
                        open={openGridDrawer}
                        title={title}
                        detailText={detailText}
                        setOpen={setOpenGridDrawer}
                        grid={grid}
                        saveNewText={'Save New Grid Layout'}
                        currentSelectionText={'Your Current Grid Layout'}
                    />
                </>
            )}

            {props.useRowDrag && openSortGrid && (
                <BaseGridSortModal
                    parentProps={parentProps}
                    open={openSortGrid}
                    gridRef={gridRef}
                    setOpenSortGrid={setOpenSortGrid}
                />
            )}
        </>
    );
};
export default GridPopovers;
