import React from 'react';
import { GridOptions } from '../../../components/grids/Grid.constants';
import { ColDef } from 'ag-grid-community';
import DefaultColumnTypes from '../../../components/grids/columns/Column.constants';
import BaseFormGrid from '../../../components/grids/BaseFormGrid';
import useFormulaPropertyGrid from './hooks/useFormulaPropertyGrid';
import CustomHeader from '../../../components/grids/CustomHeader';
import { FormulaPropertiesGridDefs } from '../../../constants/i18n/translations/termDefinitions/formulation';
import TranslatableText from '../../../components/i18n/TranslatableText';

const FormulaPropertyGrid = () => {
    const {
        formulaPropertyList,
        redirectToForm,
        isLoadingFormulaProperties,
        canCreateFormulaProperty,
        canViewFormulaProperty,
        termSet,
    } = useFormulaPropertyGrid();

    const formulaPropertyColumnDefs: ColDef[] = [
        {
            field: 'propertyCode',
            headerComponentFramework: (props: any) => {
                return (
                    <CustomHeader
                        {...props}
                        termSet={termSet}
                        termKey={FormulaPropertiesGridDefs.Property_Code}
                    />
                );
            },
            ...DefaultColumnTypes.ShortText,
        },
        {
            field: 'description',
            headerComponentFramework: (props: any) => {
                return (
                    <CustomHeader
                        {...props}
                        termSet={termSet}
                        termKey={FormulaPropertiesGridDefs.Description}
                    />
                );
            },
            ...DefaultColumnTypes.MediumText,
        },
        {
            field: 'sortOrder',
            minWidth: 20,
            headerComponentFramework: (props: any) => {
                return (
                    <CustomHeader
                        {...props}
                        termSet={termSet}
                        termKey={FormulaPropertiesGridDefs.Sort_Order}
                    />
                );
            },
            filter: 'agTextColumnFilter',
        },
        {
            field: 'defaultValue',
            headerComponentFramework: (props: any) => {
                return (
                    <CustomHeader
                        {...props}
                        termSet={termSet}
                        termKey={FormulaPropertiesGridDefs.Default_Value}
                    />
                );
            },
            ...DefaultColumnTypes.ShortText,
        },
        {
            field: 'propertyType.type',
            minWidth: 50,
            headerComponentFramework: (props: any) => {
                return (
                    <CustomHeader
                        {...props}
                        termSet={termSet}
                        termKey={FormulaPropertiesGridDefs.Property_Type}
                    />
                );
            },
            filter: 'agTextColumnFilter',
        },
        {
            field: 'showOnSds',
            minWidth: 50,
            headerComponentFramework: (props: any) => {
                return (
                    <CustomHeader
                        {...props}
                        termSet={termSet}
                        termKey={FormulaPropertiesGridDefs.Show_On_SDS}
                    />
                );
            },
            filter: 'agTextColumnFilter',
        },
        {
            field: 'sdsGeneration',
            minWidth: 50,
            headerComponentFramework: (props: any) => {
                return (
                    <CustomHeader
                        {...props}
                        termSet={termSet}
                        termKey={FormulaPropertiesGridDefs.SDS_Generation}
                    />
                );
            },
            filter: 'agTextColumnFilter',
        },
    ];

    const defaultColumnDefinitionOptions = {
        ...GridOptions.sortFilterAndWrapColumns,
        floatingFilter: true,
    };
    return (
        <BaseFormGrid
            title={
                <>
                    <TranslatableText
                        termKey={FormulaPropertiesGridDefs.Title}
                        termSet={termSet}
                    />
                </>
            }
            displayGrid={canViewFormulaProperty}
            isLoading={isLoadingFormulaProperties}
            rowData={formulaPropertyList}
            columnDefs={formulaPropertyColumnDefs}
            gridClass="full-size-item"
            displayToolbar={true}
            displayCreateNewButton={canCreateFormulaProperty}
            displayExportDataButton={false}
            displayResetStateButton={false}
            onCreateNew={redirectToForm}
            onRowClicked={({ data }) => redirectToForm(data)}
            defaultColDef={defaultColumnDefinitionOptions}
            sizeColumnsToFit={true}></BaseFormGrid>
    );
};

export default FormulaPropertyGrid;
