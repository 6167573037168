import ApAutomationRoutes from './ApAutomationRoutes';
import WorkflowRoutes from './WorkflowRoutes';
import FixedAssetsRoutes from './FixedAssetsRoutes';
import AccountingRoutes from './AccountingRoutes';
import GeneralRoutes from './GeneralRoutes';
import SettingsRoutes from './SettingsRoutes';
import AnalyticsRoutes from './AnalyticsRoutes';
import PricingAnalysisRoutes from './PricingAnalysisRoutes';
import LoginRoutes from './LoginRoutes';
import FormulationRoutes from './FormulationRoutes';
import AdministrationRoutes from './AdministrationRoutes';
import SetupRoutes from './SetupRoutes';
import SupplierRoutes from './SupplierRoutes';

export interface RouteProps {
    path: string;
    component: any;
    featureFlag?: string;
    permissionKey?: string;
    datacorAdminOnly?: boolean;
}

export const AUTHENTICATED_ROUTES = [
    ...ApAutomationRoutes,
    ...AnalyticsRoutes,
    ...WorkflowRoutes,
    ...GeneralRoutes,
    ...PricingAnalysisRoutes,
    ...SettingsRoutes,
    ...FixedAssetsRoutes,
    ...AccountingRoutes,
    ...SupplierRoutes,
    ...FormulationRoutes,
    ...AdministrationRoutes,
    ...SetupRoutes,
    ...SupplierRoutes,
];

export const UNAUTHENTICATED_ROUTES = [...LoginRoutes];
