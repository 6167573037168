import { useNavigate } from 'react-router-dom';
import { useGetAllGeneralLedgerAccountsQuery } from '../../../services/accounting/accounting.service';
import { PermissionsUtil } from '../../../utils/permissions/permissionsUtil';
import { PERMISSIONS } from '../../../constants/permissions/Permissions.constants';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { RoutingValues } from '../../../types/Shared.types';
import useTranslation from '../../../components/i18n/hooks/useTranslation';
import { ACCOUNTING_DEFS } from '../../../constants/i18n/translations/termSetDefinitions/accounting';

export const UseGeneralLedgerAccountGrid = () => {
    const user = useSelector((state: RootState) => state.user);
    const navigate = useNavigate();
    const { data: generalLedgerAccountList, isLoading } =
        useGetAllGeneralLedgerAccountsQuery();

    const canCreateChartOfAccounts = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.FIXED_ASSETS.CHART_OF_ACCOUNTS.CREATE
    );

    const canViewChartOfAccounts = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.FIXED_ASSETS.CHART_OF_ACCOUNTS.VIEW
    );

    const { termSet } = useTranslation(
        ACCOUNTING_DEFS.GENERAL_LEDGER_ACCOUNTS_GRID
    );

    const redirectToForm = (row: any) => {
        if (row.id) {
            navigate(`/accounting/generalLedgerAccount/${row.id}`);
        } else {
            navigate(`/accounting/generalLedgerAccount/${RoutingValues.newId}`);
        }
    };

    return {
        generalLedgerAccountList,
        isLoading,
        redirectToForm,
        canCreateChartOfAccounts,
        canViewChartOfAccounts,
        termSet,
    };
};
