import React, { useContext, useEffect, useState } from 'react';
import {
    SdsHeader,
    SdsIdentification,
} from '../../../../../../../types/formulation';
import { useGetUsersWithEmailForDropdownQuery } from '../../../../../../../services/organizations/organizations.service';
import { skipToken } from '@reduxjs/toolkit/query';
import DefaultColumnTypes from '../../../../../../../components/grids/columns/Column.constants';
import { useListCasForPopupQuery } from '../../../../../../../services/formulation/casMaster/cas/casMaster.service';
import { useListFormulaForPopupQuery } from '../../../../../../../services/formulation/rdFormula/rdFormulaHeader/rdFormula.service';
import { useForm } from '../../../../../../../libs/hooksLib';
import { PermissionsUtil } from '../../../../../../../utils/permissions/permissionsUtil';
import { PERMISSIONS } from '../../../../../../../constants/permissions/Permissions.constants';
import { ChildFormProps } from '../../../../../../form/helpers/ChildFormProps';
import SettingsContext from '../../../../../../../contexts/settings.context';
import { useGetTermSetQuery } from '../../../../../../../services/i18n/i18n.service';
import { FORMULATION_DEFS } from '../../../../../../../constants/i18n/translations/termSetDefinitions/formulation';
import CustomHeader from '../../../../../../../components/grids/CustomHeader';
import { SDSFormDefs } from '../../../../../../../constants/i18n/translations/termDefinitions/formulation';

const useSdsIdentificationForm = (props: ChildFormProps<SdsHeader>) => {
    const { currentParentRecord, isParentLoading, user, dataFromQuery } =
        props.parentData;

    const { handleChildRecord, registerChildForm } = props.helpers;

    const blankSdsIdentification: SdsIdentification = {
        casId: null,
        productName: null,
        registrationNumber: '',
        regulatoryAddress: null,
        additionalCode: null,
        synonyms: null,
        productUseDescription: null,
        certified: null,
        certificationDate: null,
        certificationUserId: null,
        formulaId: null,
        contactName: null,
        contactPhone1: null,
        contactPhoneDescription1: null,
        contactPhone2: null,
        contactPhoneDescription2: null,
    };

    const userTextPopupColDefs = [
        {
            field: 'label',
            headerComponentFramework: (props: any) => {
                return (
                    <CustomHeader
                        {...props}
                        termSet={termSet}
                        termKey={SDSFormDefs.Certification_User_Popup_User}
                    />
                );
            },
            ...DefaultColumnTypes.MediumText,
        },
        {
            field: 'email',
            headerComponentFramework: (props: any) => {
                return (
                    <CustomHeader
                        {...props}
                        termSet={termSet}
                        termKey={SDSFormDefs.Certification_User_Popup_Email}
                    />
                );
            },
            ...DefaultColumnTypes.LongText,
        },
    ];

    const casTextPopupColDefs = [
        {
            field: 'label',
            headerComponentFramework: (props: any) => {
                return (
                    <CustomHeader
                        {...props}
                        termSet={termSet}
                        termKey={SDSFormDefs.CAS_No_Popup_CAS_No}
                    />
                );
            },
            ...DefaultColumnTypes.MediumText,
        },
        {
            field: 'chemicalName',
            headerComponentFramework: (props: any) => {
                return (
                    <CustomHeader
                        {...props}
                        termSet={termSet}
                        termKey={SDSFormDefs.CAS_No_Popup_Chemical_Name}
                    />
                );
            },
            ...DefaultColumnTypes.LongText,
        },
    ];

    const formulaTextPopupColDefs = [
        {
            field: 'label',
            headerComponentFramework: (props: any) => {
                return (
                    <CustomHeader
                        {...props}
                        termSet={termSet}
                        termKey={SDSFormDefs.Formula_Code_Popup_Formula_Code}
                    />
                );
            },
            ...DefaultColumnTypes.MediumText,
        },
        {
            field: 'formulaDescription',
            headerComponentFramework: (props: any) => {
                return (
                    <CustomHeader
                        {...props}
                        termSet={termSet}
                        termKey={
                            SDSFormDefs.Formula_Code_Popup_Formula_Description
                        }
                    />
                );
            },
            ...DefaultColumnTypes.LongText,
        },
    ];

    const initialState: any = {
        selectedCertifiedUser: null,
    };

    const [dropdownOptions, setDropdownOptions] = useState(initialState);

    const { fields, handleFieldChange, setValues, verifyChanges, formMethods } =
        useForm(blankSdsIdentification, true);

    const { childForm } = verifyChanges;

    const { setOriginalEntity, clearChangesHistory, hasCurrentRecordChanged } =
        childForm;

    const { data: userList, isLoading: isLoadingUserOptions } =
        useGetUsersWithEmailForDropdownQuery(
            user?.businessEntity?.id
                ? user?.businessEntity?.id?.toString()
                : skipToken
        );

    const { data: casList, isLoading: isLoadingCasOptions } =
        useListCasForPopupQuery();

    const { data: formulaList, isLoading: isLoadingFormulaOptions } =
        useListFormulaForPopupQuery();

    const headerPermissions = {
        canViewCasMaster: PermissionsUtil.isPermissionEnabled(
            user.permissions,
            PERMISSIONS.FORMULATION.CAS_MASTER.VIEW
        ),
        canViewFormula: PermissionsUtil.isPermissionEnabled(
            user.permissions,
            PERMISSIONS.FORMULATION.RD_FORMULA.VIEW
        ),
        canViewUsers: PermissionsUtil.isPermissionEnabled(
            user.permissions,
            PERMISSIONS.ADMINISTRATION.USERS.VIEW
        ),
    };

    const { settings } = useContext(SettingsContext);
    const { data: termSet } = useGetTermSetQuery(
        settings?.userSettings
            ? {
                  languageId: settings?.userSettings?.languageId,
                  code: FORMULATION_DEFS.SDS_FORM,
              }
            : skipToken
    );

    const handlePropertyChanged = (event: any) => {
        handleFieldChange(event);
        handleChildRecord('sdsIdentification', event);
    };

    useEffect(() => {
        if (!isParentLoading) {
            setOriginalEntity(
                dataFromQuery?.sdsIdentification || blankSdsIdentification
            );
            setValues(
                currentParentRecord?.sdsIdentification || blankSdsIdentification
            );
        }
    }, [
        currentParentRecord?.sdsIdentification,
        isParentLoading,
        dataFromQuery,
    ]);

    useEffect(() => {
        if (!isParentLoading) {
            registerChildForm('sdsIdentification', {
                formMethods,
                clearChangesHistory,
                hasEntityChanges: hasCurrentRecordChanged,
            });
        }
    }, [fields, isParentLoading]);

    /**
     * Set the labels on all the dropdowns
     */
    useEffect(() => {
        if (
            !isParentLoading &&
            !isLoadingUserOptions &&
            userList &&
            !isLoadingCasOptions &&
            casList &&
            !isLoadingFormulaOptions &&
            formulaList
        ) {
            setDropdownOptions((previousValues: any) => ({
                ...previousValues,
                selectedCertifiedUser: userList?.find(
                    (current: any) =>
                        current.value === fields.certificationUserId
                ),
                selectedCas: casList?.find(
                    (current: any) => current.value === fields.casId
                ),
                selectedFormula: formulaList?.find(
                    (current: any) => current.value === fields.formulaId
                ),
            }));
        }
    }, [
        isParentLoading,
        fields,
        isLoadingUserOptions,
        userList,
        isLoadingCasOptions,
        casList,
        isLoadingFormulaOptions,
        formulaList,
    ]);

    return {
        fields,
        handlePropertyChanged,
        dropdownOptions,
        formMethods,
        userList,
        casList,
        formulaList,
        userTextPopupColDefs,
        casTextPopupColDefs,
        formulaTextPopupColDefs,
        headerPermissions,
        termSet,
    };
};
export default useSdsIdentificationForm;
