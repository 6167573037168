import React from 'react';
import './SectionHeader.css';
import NormalMenu from './menus/NormalMenu';
import MegaMenuButton from './menus/MegaMenu';

const SectionHeader = (props: any) => {
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
        null
    );

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleGoToSection = (link: string) => {
        const element = document.getElementById(link);
        element?.scrollIntoView();
        handleClose();
    };

    return (
        <>
            {props.sections?.length > 6 ? (
                <MegaMenuButton
                    handleClose={handleClose}
                    handleGoToSection={handleGoToSection}
                    anchorEl={anchorEl}
                    setAnchorEl={setAnchorEl}
                />
            ) : (
                <NormalMenu
                    sections={props.sections}
                    handleGoToSection={handleGoToSection}
                />
            )}
        </>
    );
};

export default SectionHeader;
