import { useParams } from 'react-router-dom';
import useAccountFunctionCodeForm from './hooks/useAccountFunctionCodeForm';
import { Grid } from '@mui/material';
import DatacorLogoSpinner from '../../components/datacorLogoSpinner/DatacorLogoSpinner';
import FormPartial from '../../components/form/formContainer/FormPartial';
import TextInput from '../../components/form/formInputs/TextInput/TextInput';
import BaseGrid from '../../components/grids/BaseGrid';
import { AgGridColumn } from 'ag-grid-react';
import React from 'react';
import BaseForm, { ParamTypes } from '../../components/form/baseForm/BaseForm';
import DefaultColumnTypes from '../../components/grids/columns/Column.constants';
import { FunctionGroupValues } from '../../types/Shared.types';
import { ACCOUNT_FUNCTION_CODE_FIELDS } from '../../constants/revisionHistory/revisionHistory.constants';
import TranslatableText from '../../components/i18n/TranslatableText';
import { FunctionCodesFormDefs } from '../../constants/i18n/translations/termDefinitions/accounting';
import CustomHeader from '../../components/grids/CustomHeader';

const AccountFunctionCodeForm = () => {
    const { id, group } = useParams<ParamTypes>();
    const {
        activeFunctionCode,
        fields,
        formMethods,
        onUpdate,
        onGridReady,
        closeForm,
        isLoading,
        isLoadingSelectedAccounts,
        canUpdateFunctionCode,
        termSet,
    } = useAccountFunctionCodeForm(id, group);

    return (
        <BaseForm
            title={
                group === FunctionGroupValues.accounting ? (
                    <TranslatableText
                        termSet={termSet}
                        termKey={FunctionCodesFormDefs.Accounting_Function_Code}
                    />
                ) : (
                    <TranslatableText
                        termSet={termSet}
                        termKey={
                            FunctionCodesFormDefs.Fixed_Asset_Function_Code
                        }
                    />
                )
            }
            formMethods={formMethods}
            isLoading={isLoading}
            isNew={false}
            onUpdate={onUpdate}
            onCancel={closeForm}
            showOnRevision={true}
            fieldMappings={ACCOUNT_FUNCTION_CODE_FIELDS}
            entityUuid={fields?.uuid}
            displayCreateButton={false}
            displayUpdateButton={canUpdateFunctionCode}
            displayDeleteButton={false}>
            <FormPartial>
                <Grid container spacing={1}>
                    <Grid item xs={3}>
                        <TextInput
                            fullWidth
                            label={
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={
                                        FunctionCodesFormDefs.Function_Code
                                    }
                                />
                            }
                            id="code"
                            value={fields.code}
                            variant={'standard'}
                            inputProps={{
                                readOnly: true,
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextInput
                            fullWidth
                            label={
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={FunctionCodesFormDefs.Description}
                                />
                            }
                            id="description"
                            value={fields.description}
                            multiline={true}
                            variant={'standard'}
                            inputProps={{
                                readOnly: true,
                            }}
                        />
                    </Grid>
                </Grid>
            </FormPartial>
            <FormPartial
                title={
                    (
                        <TranslatableText
                            termSet={termSet}
                            termKey={FunctionCodesFormDefs.Accounts}
                        />
                    ) as unknown as string
                }>
                {isLoadingSelectedAccounts ? (
                    <DatacorLogoSpinner />
                ) : (
                    <BaseGrid
                        rowData={activeFunctionCode?.availableAccounts || []}
                        displayGrid={true}
                        onGridReady={onGridReady}
                        rowMultiSelectWithClick={true}
                        rowStyle={{ cursor: 'pointer' }}
                        rowSelection={'multiple'}
                        defaultColDef={{
                            resizable: true,
                            sortable: true,
                            unSortIcon: true,
                            filter: true,
                            wrapText: true,
                            floatingFilter: true,
                        }}>
                        <AgGridColumn
                            headerComponentFramework={(props: any) => {
                                return (
                                    <CustomHeader
                                        {...props}
                                        termSet={termSet}
                                        termKey={FunctionCodesFormDefs.Selected}
                                    />
                                );
                            }}
                            {...DefaultColumnTypes.CheckboxSelection}
                        />
                        <AgGridColumn
                            field="number"
                            headerComponentFramework={(props: any) => {
                                return (
                                    <CustomHeader
                                        {...props}
                                        termSet={termSet}
                                        termKey={FunctionCodesFormDefs.Number}
                                    />
                                );
                            }}
                            {...DefaultColumnTypes.ShortText}
                        />
                        <AgGridColumn
                            minWidth={200}
                            field="name"
                            headerComponentFramework={(props: any) => {
                                return (
                                    <CustomHeader
                                        {...props}
                                        termSet={termSet}
                                        termKey={FunctionCodesFormDefs.Name}
                                    />
                                );
                            }}
                            {...DefaultColumnTypes.MediumText}
                        />
                    </BaseGrid>
                )}
            </FormPartial>
        </BaseForm>
    );
};

export default AccountFunctionCodeForm;
