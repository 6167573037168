import React, { useContext, useState } from 'react';
import {
    useGetSearchParamsQuery,
    useGetUsersForDropdownQuery,
    useSearchMutation,
} from '../../../services/organizations/organizations.service';
import { IconButton, Tooltip } from '@mui/material';
import LaunchIcon from '@mui/icons-material/Launch';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { skipToken } from '@reduxjs/toolkit/query';
import { useNavigate } from 'react-router-dom';
import { updateGridModels } from '../../../store/grids';
import { GridReadyEvent } from 'ag-grid-community';
import SettingsContext from '../../../contexts/settings.context';
import { useGetTermSetQuery } from '../../../services/i18n/i18n.service';
import { PLATFORM_DEFS } from '../../../constants/i18n/translations/termSetDefinitions/platform';
import TranslatableText from '../../../components/i18n/TranslatableText';
import { RevisionHistoryFormDefs } from '../../../constants/i18n/translations/termDefinitions/platform';

const useRevisionSearch = () => {
    const dispatch = useDispatch();
    const { administration } = useSelector((state: RootState) => state.grids);
    const navigate = useNavigate();
    const today = new Date(new Date().getTime());
    const oneWeekAgo = new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000);
    const [selectedItem, setSelectedItem] = useState(null);
    const [open, setOpen] = useState(false);
    const user = useSelector((state: RootState) => state.user);
    const { data: searchParams, isLoading: isLoadingSearchParams } =
        useGetSearchParamsQuery();
    const { data: users, isLoading: isLoadingUsers } =
        useGetUsersForDropdownQuery();
    const [search] = useSearchMutation();
    const [isSearching, setIsSearching] = useState(false);
    const [results, setResults] = useState(null);
    const [gridApi, setGridApi] = useState(null);
    const [gridColumnApi, setGridColumnApi] = useState(null);
    const [filterOptions, setFilterOptions] = useState({
        users: [],
        dates: [
            {
                startDate: oneWeekAgo,
                endDate: today,
            },
        ],
        actions: [],
        sources: [],
        modules: [],
    });
    const { settings } = useContext(SettingsContext);
    const { data: termSet } = useGetTermSetQuery(
        settings?.userSettings
            ? {
                  languageId: settings?.userSettings?.languageId,
                  code: PLATFORM_DEFS.REVISION_HISTORY_FORM,
              }
            : skipToken
    );

    /*
    TODO: this is the logic for auto-searching after each
          filter change, commenting out until we decide which to use
    useEffect(() => {
        handleSearch();
    }, [filterOptions])

    */
    const handleSearch = async () => {
        setIsSearching(true);
        const body: any = {
            ...filterOptions,
            modules: [...filterOptions?.modules].flat(),
        };
        const searchResults = await search(body).unwrap();
        setResults(searchResults);
        setIsSearching(false);
    };

    const redirectToItem = (item: any, event: any) => {
        event.stopPropagation();
        navigate(`/${item.revisionEntityType.path}/${item.entityId}`);
    };

    const handleDateChange = (type: any, date: any) => {
        const dateToSet =
            type === 'startDate' ? date.startOf('day') : date.endOf('day');
        setFilterOptions((prev) => ({
            ...prev,
            dates: [{ ...prev.dates[0], [type]: dateToSet }],
        }));
    };

    const handleOpenDetails = (data: any) => {
        setSelectedItem(data);
        setOpen(true);
    };

    const handleChange = (selected: any) => {
        setFilterOptions((prev) => ({
            ...prev,
            [selected.target.id]: selected.target.value.map((item: any) => {
                return item.value;
            }),
        }));
    };

    const goToItem = (row: any) => {
        return (
            <Tooltip
                title={
                    <TranslatableText
                        termKey={RevisionHistoryFormDefs.View_Items}
                        termSet={termSet}
                    />
                }
                placement="top">
                <IconButton
                    size="small"
                    color="primary"
                    ref={(ref) => {
                        if (!ref) {
                            return;
                        }

                        ref.onclick = (event) => {
                            redirectToItem(row.data, event);
                        };
                    }}>
                    <LaunchIcon />
                </IconButton>
            </Tooltip>
        );
    };

    const onGridReady = (params: GridReadyEvent) => {
        setGridApi(params.api);
        setGridColumnApi(params.columnApi);
    };

    const onFirstDataRendered = () => {
        gridColumnApi?.applyColumnState({
            state: administration.revisionSearchList.column,
            applyOrder: true,
        });

        gridApi?.setFilterModel(administration.revisionSearchList.filter);
    };

    const onSortChanged = () => {
        dispatch(
            updateGridModels({
                gridLocation: 'administration',
                gridName: 'revisionSearchList',
                type: 'column',
                model: gridColumnApi.getColumnState(),
            })
        );
    };

    return {
        searchParams,
        filterOptions,
        users,
        isLoadingUsers,
        isLoadingSearchParams,
        isSearching,
        results,
        handleSearch,
        handleChange,
        handleDateChange,
        goToItem,
        handleOpenDetails,
        open,
        setOpen,
        selectedItem,
        onGridReady,
        onSortChanged,
        onFirstDataRendered,
        termSet,
    };
};

export default useRevisionSearch;
