import { useNavigate } from 'react-router-dom';
import { PermissionsUtil } from '../../../utils/permissions/permissionsUtil';
import { PERMISSIONS } from '../../../constants/permissions/Permissions.constants';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { RoutingValues } from '../../../types/Shared.types';
import {
    useListSupplierAddressStatusQuery,
    useUpdateListSupplierAddressStatusMutation,
} from '../../../services/supplier/supplier.service';
import { SupplierAddressStatus } from '../../../types/Supplier.types';
import { useGetTermSetQuery } from '../../../services/i18n/i18n.service';
import { skipToken } from '@reduxjs/toolkit/query';
import React, { useContext, useEffect, useState } from 'react';
import SettingsContext from '../../../contexts/settings.context';
import { SUPPLY_CHAIN_DEFS } from '../../../constants/i18n/translations/termSetDefinitions/supply-chain';
import { isNilOrEmpty } from '../../../utils/objectUtils';
import { ColDef } from 'ag-grid-community';
import DefaultColumnTypes from '../../../components/grids/columns/Column.constants';
import CustomHeader from '../../../components/grids/CustomHeader';
import { SupplierAddressStatusGridDefs } from '../../../constants/i18n/translations/termDefinitions/supply-chain';
import _ from 'lodash';
import { getMaxSortOrderValue } from '../../../store/suppliers';
import { auditableGridColumns } from '../../grid/utils/auditableGrid/auditableGridColumns';

const useSupplierAddressStatusGrid = () => {
    const dispatch = useDispatch();
    const user = useSelector((state: RootState) => state.user);
    const navigate = useNavigate();
    const { data: entityList, isLoading } = useListSupplierAddressStatusQuery();
    const [updateList] = useUpdateListSupplierAddressStatusMutation();
    const [colDefs, setColDefs] = useState([]);
    const [rowData, setRowData] = useState([]);

    const { settings } = useContext(SettingsContext);
    const { data: termSet } = useGetTermSetQuery(
        settings?.userSettings
            ? {
                  languageId: settings?.userSettings?.languageId,
                  code: SUPPLY_CHAIN_DEFS.SUPPLIER_ADDRESS_STATUS_GRID,
              }
            : skipToken
    );
    const [hiddenIdColumns, auditableColumns] = auditableGridColumns();

    const canView = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.SUPPLIERS.SETUP.SUPPLIER_ADDRESS_STATUSES.VIEW
    );
    const canCreate = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.SUPPLIERS.SETUP.SUPPLIER_ADDRESS_STATUSES.CREATE
    );
    const canUpdate = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.SUPPLIERS.SETUP.SUPPLIER_ADDRESS_STATUSES.EDIT
    );

    const handleGridEdits = (editedRows: SupplierAddressStatus[]) => {
        updateList(editedRows);
    };

    const redirectToForm = (row: any) => {
        const menuRoot = '/suppliers/setup/addressStatus';
        if (row.id) {
            navigate(`${menuRoot}/${row.id}`);
        } else {
            navigate(`${menuRoot}/${RoutingValues.newId}`);
        }
    };

    useEffect(() => {
        if (!isNilOrEmpty(termSet)) {
            const columnDefs: ColDef[] = [
                ...hiddenIdColumns,
                {
                    field: 'sortOrder',
                    ...DefaultColumnTypes.NumberOnly,
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={
                                    SupplierAddressStatusGridDefs.SortOrder
                                }
                            />
                        );
                    },
                },
                {
                    field: 'code',
                    ...DefaultColumnTypes.ShortText,
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={SupplierAddressStatusGridDefs.Code}
                            />
                        );
                    },
                },
                {
                    field: 'description',
                    ...DefaultColumnTypes.LongText,
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={
                                    SupplierAddressStatusGridDefs.Description
                                }
                            />
                        );
                    },
                },
                ...auditableColumns,
            ];
            setColDefs(columnDefs);
        }
    }, [termSet]);

    useEffect(() => {
        if (!isNilOrEmpty(entityList)) {
            setRowData(entityList);
        }
    }, [entityList]);

    useEffect(() => {
        let maxValue = 1;
        if (entityList && entityList.length > 0) {
            const list = _.maxBy(
                entityList,
                (typeObj: SupplierAddressStatus) => typeObj.sortOrder
            );
            maxValue = list.sortOrder + 1;
        }
        dispatch(
            getMaxSortOrderValue({ isLoading: false, maxValue: maxValue })
        );
    }, entityList);

    return {
        rowData,
        colDefs,
        isLoading,
        canView,
        canCreate,
        canUpdate,
        redirectToForm,
        handleGridEdits,
        termSet,
    };
};

export default useSupplierAddressStatusGrid;
