import React, { useContext, useEffect, useState } from 'react';
import { useGetAllFixedAssetDepreciationSchedulesQuery } from '../../../services/fixedAssets/fixedAssets.service';
import {
    FA_DepreciationSchedule,
    FixedAssetScheduleListParams,
    FA_AssetType,
} from '../../../types/FixedAsset.types';
import _ from 'lodash';
import {
    GridReadyEvent,
    ICellRendererParams,
    RowNode,
    SelectionChangedEvent,
    ValueGetterParams,
} from 'ag-grid-community';
import {
    dateFormatter,
    dateTimeFormatter,
} from '../../../utils/formattingUtils';
import { Button, Link } from '@mui/material';
import ListIcon from '@mui/icons-material/List';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { DetailGridInfo, GridApi } from 'ag-grid-community/dist/lib/gridApi';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { skipToken } from '@reduxjs/toolkit/query';
import { isNilOrEmpty } from '../../../utils/objectUtils';
import { PermissionsUtil } from '../../../utils/permissions/permissionsUtil';
import { PERMISSIONS } from '../../../constants/permissions/Permissions.constants';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import SettingsContext from '../../../contexts/settings.context';
import { useGetTermSetQuery } from '../../../services/i18n/i18n.service';
import { ACCOUNTING_DEFS } from '../../../constants/i18n/translations/termSetDefinitions/accounting';

export enum AssetScheduleGridMode {
    Default = '0',
    ByDate = '1',
    ByAssetType = '2',
}

export class FixedAssetScheduleRow {
    public readonly id: string;
    public readonly assetType: FA_AssetType;
    public readonly scheduleDate: Date;
    public readonly schedules: FA_DepreciationSchedule[];

    constructor(schedule: FA_DepreciationSchedule) {
        this.id = `${schedule.assetTypeId}|${schedule.intervalDate}`;
        this.assetType = schedule.assetType;
        this.scheduleDate = schedule.intervalDate;
        this.schedules = [schedule];
    }

    public get assetValue(): number {
        return _.sumBy(this.schedules, (schedule) =>
            Number(schedule.assetCost)
        );
    }

    public get accumulatedDepreciation(): number {
        return _.sumBy(this.schedules, (schedule) =>
            Number(schedule.accumulatedDepreciation)
        );
    }

    public get remainingValue(): number {
        return this.assetValue - this.accumulatedDepreciation;
    }

    public get lockedScheduleSummary(): string {
        const totalLocked: number = this.schedules.filter(
            (schedule) => schedule.locked
        ).length;
        return `${totalLocked} of ${this.schedules.length}`;
    }
}

const useFixedAssetSchedulesGrid = () => {
    const { settings } = useContext(SettingsContext);
    const { data: termSet } = useGetTermSetQuery(
        settings?.userSettings
            ? {
                  languageId: settings?.userSettings?.languageId,
                  code: ACCOUNTING_DEFS.FIXED_ASSET_SCHEDULE_GRID,
              }
            : skipToken
    );
    const user = useSelector((state: RootState) => state.user);

    const canViewSchedules = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.FIXED_ASSETS.DEPRECIATION_SCHEDULES.VIEW
    );

    const [currentGridMode, setCurrentGridMode] =
        useState<AssetScheduleGridMode>(AssetScheduleGridMode.Default);
    const [currentTab, setCurrentTab] = useState<string>(
        AssetScheduleGridMode.Default
    );
    const [currentTabTitle, setCurrentTabTitle] = useState<string>(
        'Fixed Asset Schedules'
    );
    const [showOptionsModal, setShowOptionsModal] = useState<boolean>(true);
    const [showActionsModal, setShowActionsModal] = useState<boolean>(false);

    const [gridApi, setGridApi] = useState<GridApi>(null);
    const [hasSelectedRows, setHasSelectedRows] = useState<boolean>(false);

    const [depreciationScheduleList, setDepreciationScheduleList] = useState<
        FA_DepreciationSchedule[]
    >([]);
    const [scheduleList, setScheduleList] = useState<FixedAssetScheduleRow[]>(
        []
    );
    const [selectedSchedules, setSelectedSchedules] = useState<
        FA_DepreciationSchedule[]
    >([]);

    const [subGridParams, setSubGridParams] =
        useState<FixedAssetScheduleListParams>(null);
    const { data: subGridScheduleList, isFetching: isLoadingSubGrid } =
        useGetAllFixedAssetDepreciationSchedulesQuery(
            subGridParams ? subGridParams : skipToken
        );
    const [subGridList, setSubGridList] = useState<FixedAssetScheduleRow[]>([]);
    const [subGridApi, setSubGridApi] = useState<GridApi>(null);
    const [subGridHasSelectedRows, setSubGridHasSelectedRows] =
        useState<boolean>(false);

    useEffect(() => {
        setScheduleList(_createRowsFromList(depreciationScheduleList));
    }, [depreciationScheduleList]);

    useEffect(() => {
        setSubGridList(_createRowsFromList(subGridScheduleList));
    }, [subGridScheduleList]);

    useEffect(() => {
        if (currentGridMode === AssetScheduleGridMode.Default) {
            setCurrentTab('0');
            setSubGridApi(null);
        } else {
            setCurrentTab('1');
            setGridApi(null);
        }
    }, [currentGridMode]);

    const _createRowsFromList = (
        list: FA_DepreciationSchedule[]
    ): FixedAssetScheduleRow[] => {
        const rowMap: Map<string, FixedAssetScheduleRow> = new Map<
            string,
            FixedAssetScheduleRow
        >();

        _.orderBy(list, ['intervalDate'])?.forEach(
            (schedule: FA_DepreciationSchedule) => {
                //establish the row key for uniqueness
                const rowKey: string = `${schedule.assetTypeId}|${schedule.intervalDate}`;

                if (!rowMap.has(rowKey)) {
                    rowMap.set(rowKey, new FixedAssetScheduleRow(schedule));
                } else {
                    rowMap.get(rowKey).schedules.push(schedule);
                }
            }
        );

        return Array.from(rowMap.values());
    };

    const onGridReady = (event: GridReadyEvent) => {
        setGridApi(event.api);
    };

    const onSelectionChanged = (event: SelectionChangedEvent) => {
        setHasSelectedRows(event.api.getSelectedNodes().length > 0);
    };

    const lockedScheduleValueGetter = (params: ValueGetterParams) => {
        const schedule: FA_DepreciationSchedule = params.data;
        return schedule.locked
            ? `${dateTimeFormatter(schedule.lockedDate)} (${
                  schedule.lockedUserName
              })`
            : '';
    };

    const scheduleToolbar = () => {
        return (
            <>
                <Button
                    variant="text"
                    size="small"
                    onClick={() => setShowOptionsModal(true)}
                    startIcon={<ListIcon />}>
                    Options
                </Button>
                <Button
                    variant="text"
                    size="small"
                    disabled={!hasSelectedRows}
                    onClick={() => _handleActionsButtonClicked()}
                    startIcon={
                        <ArrowForwardIcon style={{ color: '#00A84E' }} />
                    }>
                    Actions
                </Button>
            </>
        );
    };

    const _handleActionsButtonClicked = () => {
        let selectedSchedules: FA_DepreciationSchedule[] = [];

        gridApi.getSelectedRows().forEach((row: FixedAssetScheduleRow) => {
            row.schedules.forEach((schedule) =>
                selectedSchedules.push(schedule)
            );
        });

        setSelectedSchedules(selectedSchedules);
        setShowActionsModal(true);
    };

    const handleUpdatedScheduleList = (
        schedules: FA_DepreciationSchedule[]
    ) => {
        _handleUpdateSchedules(schedules, scheduleList, gridApi);
        _handleUpdateSchedules(schedules, subGridList, subGridApi);
    };

    const _handleUpdateSchedules = (
        updatedList: FA_DepreciationSchedule[],
        originalList: FixedAssetScheduleRow[],
        api: GridApi
    ) => {
        if (isNilOrEmpty(originalList)) {
            return;
        }

        updatedList.forEach((schedule) => {
            const row: FixedAssetScheduleRow = originalList.find(
                (row) =>
                    row.assetType.id === schedule.assetTypeId &&
                    row.scheduleDate === schedule.intervalDate
            );

            if (row) {
                const scheduleIndex: number = _.findIndex(
                    row.schedules,
                    (rowSchedule) => rowSchedule.id === schedule.id
                );
                row.schedules[scheduleIndex] = schedule;

                if (api) {
                    const rowNode: RowNode = api.getRowNode(row.id);
                    rowNode.updateData(row);

                    const detailGridInfo: DetailGridInfo =
                        api.getDetailGridInfo(`detail_${row.id}`);
                    if (detailGridInfo) {
                        const detailRowNode: RowNode =
                            detailGridInfo.api.getRowNode(
                                schedule.id.toString()
                            );
                        detailRowNode && detailRowNode.updateData(schedule);
                    }
                }
            }
        });
    };

    const dateColumnRenderer = (params: ICellRendererParams) => {
        return (
            <Link
                component="button"
                underline="none"
                onClick={() => _handleDateLinkClicked(params.data)}>
                {dateFormatter(params.value)}
            </Link>
        );
    };

    const _handleDateLinkClicked = (row: FixedAssetScheduleRow) => {
        setSubGridParams({
            startDate: row.scheduleDate,
            endDate: row.scheduleDate,
        });
        setCurrentTabTitle(
            `Depreciation Schedules For ${dateFormatter(row.scheduleDate)}`
        );
        setCurrentGridMode(AssetScheduleGridMode.ByDate);
    };

    const assetTypeRenderer = (params: ICellRendererParams) => {
        return (
            <Link
                component="button"
                underline="none"
                onClick={() => _handleAssetTypeLinkClicked(params.data)}>
                {params.value}
            </Link>
        );
    };

    const _handleAssetTypeLinkClicked = (row: FixedAssetScheduleRow) => {
        setSubGridParams({ assetTypeIds: [row.assetType.id] });
        setCurrentTabTitle(`Depreciation Schedules For ${row.assetType.code}`);
        setCurrentGridMode(AssetScheduleGridMode.ByAssetType);
    };

    const subGridToolbar = () => {
        return (
            <>
                <Button
                    variant="text"
                    size="small"
                    onClick={() => _handleReturnToMainGrid()}
                    startIcon={<ArrowBackIcon />}>
                    Back
                </Button>
                <Button
                    variant="text"
                    size="small"
                    disabled={!subGridHasSelectedRows}
                    onClick={() => _handleSubGridActionsButtonClicked()}
                    startIcon={
                        <ArrowForwardIcon style={{ color: '#00A84E' }} />
                    }>
                    Actions
                </Button>
            </>
        );
    };

    const _handleReturnToMainGrid = () => {
        setSubGridParams(null);
        setCurrentTabTitle('Fixed Asset Schedules');
        setCurrentGridMode(AssetScheduleGridMode.Default);
    };

    const onSubGridReady = (event: GridReadyEvent) => {
        setSubGridApi(event.api);
    };

    const onSubGridSelectionChanged = () => {
        let hasSelected: boolean = false;

        subGridApi.forEachDetailGridInfo((detailGrid) => {
            if (detailGrid.api.getSelectedNodes().length > 0) {
                hasSelected = true;
            }
        });

        setSubGridHasSelectedRows(hasSelected);
    };

    const _handleSubGridActionsButtonClicked = () => {
        let selectedSchedules: FA_DepreciationSchedule[] = [];

        subGridApi.forEachDetailGridInfo((detailGrid) => {
            detailGrid.api
                .getSelectedRows()
                .forEach((schedule: FA_DepreciationSchedule) => {
                    selectedSchedules.push(schedule);
                });
        });

        setSelectedSchedules(selectedSchedules);
        setShowActionsModal(true);
    };

    return {
        scheduleList,
        selectedSchedules,
        onGridReady,
        onSubGridReady,
        onSelectionChanged,
        onSubGridSelectionChanged,
        lockedScheduleValueGetter,
        scheduleToolbar,
        showOptionsModal,
        setShowOptionsModal,
        setDepreciationScheduleList,
        showActionsModal,
        setShowActionsModal,
        handleUpdatedScheduleList,
        dateColumnRenderer,
        assetTypeRenderer,
        currentGridMode,
        currentTab,
        currentTabTitle,
        subGridToolbar,
        subGridList,
        isLoadingSubGrid,
        subGridParams,
        _handleDateLinkClicked,
        _handleAssetTypeLinkClicked,
        _handleReturnToMainGrid,
        canViewSchedules,
        termSet,
    };
};

export default useFixedAssetSchedulesGrid;
