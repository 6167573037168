import { isNilOrEmpty } from '../../../utils/objectUtils';

export const ColumnMaxValueValidator = (
    columnLabel: string,
    maxValue: number,
    columnData: number | string
) => {
    let isValid = true;
    let getErrorMessage = '';

    if (
        !isNilOrEmpty(columnLabel) &&
        !isNilOrEmpty(maxValue) &&
        !isNilOrEmpty(columnData)
    ) {
        if (typeof columnData === 'number') {
            isValid = !isNaN(columnData);

            if (isValid) {
                if (!Number.isInteger(columnData)) {
                    const numberPair = columnData.toString().split('.');
                    isValid = parseFloat(numberPair[0]) <= maxValue;
                } else {
                    isValid = columnData <= maxValue;
                }
            }
        }

        if (!isValid) {
            getErrorMessage = `${columnLabel} has a maximum value of ${maxValue}`;
        } else {
            if (columnData.toString().length > maxValue) {
                getErrorMessage = `${columnLabel} has a maximum length of ${maxValue} characters`;
                isValid = false;
            }
        }
    }

    return {
        getErrorMessage,
        isValid,
    };
};
