import React from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    Box,
    IconButton,
    Grid,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import SelectInput from '../../../../components/form/formInputs/SelectInput/SelectInput';
import FormContainer from '../../../../components/form/formContainer/FormContainer';
import LoaderButton from '../../../../components/LoaderButton';
import useLanguageSelectionModal, {
    LanguageSelectionModalProps,
} from './hooks/useLanguageSelectionModal';
import { FormulationSharedComponentsDefs } from '../../../../constants/i18n/translations/termDefinitions/formulation';
import TranslatableText from '../../../../components/i18n/TranslatableText';

const LanguageSelectionModal = ({
    showModal,
    languageList,
    handleModalClose,
}: LanguageSelectionModalProps) => {
    const { fields, formMethods, handleFieldChange, onSubmit, termSet } =
        useLanguageSelectionModal({
            showModal,
            languageList,
            handleModalClose,
        });

    return (
        <Dialog open={showModal} onClose={() => handleModalClose(null)}>
            <DialogTitle>
                <Box display="flex" alignItems="center">
                    <Box flexGrow={1}>
                        <TranslatableText
                            termSet={termSet}
                            termKey={
                                FormulationSharedComponentsDefs.Select_Language
                            }
                        />
                    </Box>
                    <Box>
                        <IconButton onClick={() => handleModalClose(null)}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </Box>
            </DialogTitle>
            <DialogContent style={{ paddingTop: '10px' }}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <FormContainer
                            onSubmit={() => {
                                formMethods.setIsFormSubmitted(true);
                            }}>
                            <SelectInput
                                fullWidth
                                label={
                                    <TranslatableText
                                        termSet={termSet}
                                        termKey={
                                            FormulationSharedComponentsDefs.Language
                                        }
                                    />
                                }
                                id="langId"
                                value={fields.langId}
                                onChange={handleFieldChange}
                                options={languageList}
                                formMethods={formMethods}
                                required={true}
                            />
                        </FormContainer>
                    </Grid>
                    <Grid item xs={12}>
                        <LoaderButton
                            onClick={onSubmit}
                            fullWidth
                            variant="contained"
                            type="submit"
                            data-testid="createCompanyBtn"
                            disabled={!fields.langId}>
                            <TranslatableText
                                termSet={termSet}
                                termKey={
                                    FormulationSharedComponentsDefs.Add_Language
                                }
                            />
                        </LoaderButton>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
};

export default LanguageSelectionModal;
