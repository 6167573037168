import {
    useGetBuildingsQuery,
    useGetDepartmentCodesQuery,
    useGetGroupsQuery,
    useGetPermissionsQuery,
    useGetRolesQuery,
    useGetSubscribersQuery,
    useGetUsersQuery,
} from '../../../services/organizations/organizations.service';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { skipToken } from '@reduxjs/toolkit/query';
import { useNavigate } from 'react-router-dom';
import useGetBusinessEntities from './useGetBusinessEntities';

const useAdministrationPortal = () => {
    const user = useSelector((state: RootState) => state.user);
    const navigate = useNavigate();
    const { data: subscribers, isLoading: isLoadingSubscribers } =
        useGetSubscribersQuery();
    const { businessEntities, isLoading: isLoadingBusinessEnts } =
        useGetBusinessEntities({
            businessEntityId: user?.activeBusinessEntityId?.toString(),
            subscriberId: user?.subscriber?.id?.toString(),
        });
    const { data: buildings, isLoading: isLoadingBuildings } =
        useGetBuildingsQuery();

    const { data: users, isLoading: isLoadingUsers } = useGetUsersQuery();

    const { data: groups, isLoading: isLoadingGroups } = useGetGroupsQuery();

    const { data: roles, isLoading: isLoadingRoles } = useGetRolesQuery();

    const { data: departmentCodes, isLoading: isLoadingDepartmentCodes } =
        useGetDepartmentCodesQuery(
            user?.subscriber?.id ? user?.subscriber?.id?.toString() : skipToken
        );

    const { data: permissions, isLoading: isLoadingPermissions } =
        useGetPermissionsQuery();

    const shouldLoad =
        isLoadingBusinessEnts ||
        isLoadingSubscribers ||
        isLoadingBuildings ||
        isLoadingUsers ||
        isLoadingGroups ||
        isLoadingRoles ||
        isLoadingDepartmentCodes ||
        isLoadingPermissions;

    return {
        user,
        navigate,
        subscribers,
        businessEntities,
        buildings,
        users,
        groups,
        roles,
        departmentCodes,
        permissions,
        shouldLoad,
    };
};

export default useAdministrationPortal;
