import { useDispatch, useSelector } from 'react-redux';
import { useGetZonesQuery } from '../../../services/users/users.service';
import React, { useState } from 'react';
import { ColumnResizedEvent, GridReadyEvent } from 'ag-grid-community';
import { RootState } from '../../../store';
import TruncatedTooltip from '../../../components/truncatedTooltip/TruncatedTooltip';
import { openSnackbarBasicWithMessage } from '../../../store/uiElements';

const useZonesGrid = () => {
    const dispatch = useDispatch();
    const user = useSelector((state: RootState) => state.user);
    const { data: zones, isLoading: isLoadingZones } = useGetZonesQuery();
    const [showCreateZoneModal, setShowCreateZoneModal] = useState(false);
    const [gridApi, setGridApi] = useState(null);
    const [gridColumnApi, setGridColumnApi] = useState(null);

    const handleCreateZoneModalClose = (updateZones: boolean = false) => {
        setShowCreateZoneModal(false);
        if (updateZones) {
            dispatch(
                openSnackbarBasicWithMessage({
                    message: `You'll need to add this zone to the user in the Users Tab.`,
                })
            );
        }
    };

    const handleCreateZoneModalOpen = () => {
        setShowCreateZoneModal(true);
    };

    const onGridReady = (params: GridReadyEvent) => {
        setGridApi(params.api);
        setGridColumnApi(params.columnApi);

        params.api.sizeColumnsToFit();
    };

    const TruncatedTooltipColumn = (props: any) => {
        return (
            <TruncatedTooltip
                text={props.value}
                maxWidth={props.column.actualWidth}>
                <span>{props.value}</span>
            </TruncatedTooltip>
        );
    };

    const onColumnResize = (params: ColumnResizedEvent) => {
        params.api.refreshCells({ force: true, columns: params.columns });
    };

    return {
        onGridReady,
        handleCreateZoneModalOpen,
        handleCreateZoneModalClose,
        showCreateZoneModal,
        zones,
        isLoadingZones,
        user,
        TruncatedTooltipColumn,
        onColumnResize,
    };
};

export default useZonesGrid;
