import { datacorAppApi } from '../../datacorAppApi';
import { HazardClassificationRule } from '../../../types/formulation';
import {
    BaseCreateEntityArgs,
    BaseUpdateEntityArgs,
} from '../../serviceInterfaces';

const serviceUrl: string = 'hazardClassificationRule';

export const hazardClassificationRuleApi = datacorAppApi.injectEndpoints({
    endpoints: (build) => ({
        listHazardClassificationRules: build.query<
            HazardClassificationRule[],
            void
        >({
            query: () => {
                return {
                    url: serviceUrl,
                };
            },
            providesTags: ['HazardClassificationRule'],
        }),
        createHazardClassificationRules: build.mutation<
            any,
            BaseCreateEntityArgs<HazardClassificationRule>
        >({
            query: (args) => ({
                url: serviceUrl,
                method: 'POST',
                body: args.postBody,
            }),
            invalidatesTags: ['HazardClassificationRule'],
        }),
        updateHazardClassificationRules: build.mutation<
            any,
            BaseUpdateEntityArgs<HazardClassificationRule>
        >({
            query: (args) => ({
                url: `${serviceUrl}/${args.id}`,
                method: 'PUT',
                body: args.postBody,
            }),
            invalidatesTags: [
                'HazardClassificationRule',
                'ActiveHazardClassificationRule',
            ],
        }),
        deleteHazardClassificationRules: build.mutation<
            HazardClassificationRule,
            string
        >({
            query: (id) => ({
                url: `${serviceUrl}/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['HazardClassificationRule'],
        }),
        getHazardClassificationRules: build.query<any, string>({
            query: (id) => {
                return {
                    url: `${serviceUrl}/${id}`,
                };
            },
            providesTags: ['ActiveHazardClassificationRule'],
        }),
    }),
});

export const {
    useListHazardClassificationRulesQuery,
    useCreateHazardClassificationRulesMutation,
    useDeleteHazardClassificationRulesMutation,
    useGetHazardClassificationRulesQuery,
    useUpdateHazardClassificationRulesMutation,
} = hazardClassificationRuleApi;
