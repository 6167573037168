import { datacorAppApi } from '../../services/datacorAppApi';
import { refreshTags } from '../../services/tagTypes';

const tagsToInvalidate = {
    datacorAppApi: [...refreshTags],
};

const apiMappings = {
    datacorAppApi: datacorAppApi,
};

//helper to manually invalidate tags outside the given service
export const handleInvalidateTags = async (
    dispatch: any,
    api: any = null,
    tags: string[] = null,
    fullReset = false
) => {
    if (!tags || !api) {
        Object.keys(tagsToInvalidate).forEach((key) => {
            dispatch(
                //@ts-ignore
                apiMappings[key].util.invalidateTags(tagsToInvalidate[key])
            );
        });
        //TODO: resetting the api state is causing all sorts of issues
        //easier to invalidate manually and come back to later.
        if (fullReset) {
            Object.keys(apiMappings).forEach((key) => {
                //@ts-ignore
                dispatch(apiMappings[key].util.resetApiState());
            });
        }
    } else {
        dispatch(api.util.invalidateTags(tags));
    }
};
