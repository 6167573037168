import SubscribersGrid from '../containers/administration/SubscribersGrid';
import SubscriberForm from '../containers/administration/SubscriberForm';
import BusinessEntityGrid from '../containers/administration/BusinessEntityGrid';
import BusinessEntityForm from '../containers/administration/BusinessEntityForm';
import UsersGrid from '../containers/administration/UsersGrid';
import UserForm from '../containers/administration/UserForm';
import GroupsGrid from '../containers/administration/GroupsGrid';
import GroupsForm from '../containers/administration/GroupsForm';
import AdministrationRolesGrid from '../containers/administration/RolesGrid';
import RoleForm from '../containers/administration/RoleForm';
import DepartmentCodesGrid from '../containers/administration/DepartmentCodesGrid';
import DepartmentCodeForm from '../containers/administration/DepartmentCodeForm';
import BuildingsGrid from '../containers/administration/BuildingsGrid';
import BuildingsForm from '../containers/administration/BuildingsForm';
import { RouteProps } from './routesConfig';
import PermissionRoleGrid from '../containers/administration/PermissionRoleGrid';
import PermissionTree from '../containers/administration/PermissionTree';
import AdministrationPortal from '../containers/administration/AdministrationPortal';
import LanguagesGrid from '../containers/administration/LanguagesGrid';
import RevisionSearch from '../containers/administration/RevisionSearch';
import FeatureFlagsGrid from '../containers/administration/FeatureFlagsGrid';
import { PERMISSIONS } from '../constants/permissions/Permissions.constants';

const AdministrationRoutes: RouteProps[] = [
    {
        path: '/administration',
        component: AdministrationPortal,
        featureFlag: 'dashboard.nav.apps.administration',
        permissionKey: PERMISSIONS.ADMINISTRATION.VIEW,
    },
    {
        path: '/administration/organizations/subscribers',
        component: SubscribersGrid,
        featureFlag: 'dashboard.nav.apps.administration',
        permissionKey: PERMISSIONS.ADMINISTRATION.VIEW,
    },
    {
        path: '/administration/organizations/subscribers/new',
        component: SubscriberForm,
        featureFlag: 'dashboard.nav.apps.administration',
        permissionKey: PERMISSIONS.ADMINISTRATION.VIEW,
    },
    {
        path: '/administration/organizations/subscribers/:id',
        component: SubscriberForm,
        featureFlag: 'dashboard.nav.apps.administration',
        permissionKey: PERMISSIONS.ADMINISTRATION.VIEW,
    },
    {
        path: '/administration/organizations/business-entities',
        component: BusinessEntityGrid,
        featureFlag: 'dashboard.nav.apps.administration',
        permissionKey: PERMISSIONS.ADMINISTRATION.BUSINESS_ENTITIES.VIEW,
    },
    {
        path: '/administration/organizations/business-entities/new',
        component: BusinessEntityForm,
        featureFlag: 'dashboard.nav.apps.administration',
        permissionKey: PERMISSIONS.ADMINISTRATION.BUSINESS_ENTITIES.CREATE,
    },
    {
        path: '/administration/organizations/business-entities/:id',
        component: BusinessEntityForm,
        featureFlag: 'dashboard.nav.apps.administration',
        permissionKey: PERMISSIONS.ADMINISTRATION.BUSINESS_ENTITIES.VIEW,
    },
    {
        path: '/administration/organizations/users',
        component: UsersGrid,
        featureFlag: 'dashboard.nav.apps.administration',
        permissionKey: PERMISSIONS.ADMINISTRATION.USERS.VIEW,
    },
    {
        path: '/administration/organizations/users/new',
        component: UserForm,
        featureFlag: 'dashboard.nav.apps.administration',
        permissionKey: PERMISSIONS.ADMINISTRATION.USERS.CREATE,
    },
    {
        path: '/administration/organizations/users/:id',
        component: UserForm,
        featureFlag: 'dashboard.nav.apps.administration',
        permissionKey: PERMISSIONS.ADMINISTRATION.USERS.VIEW,
    },
    {
        path: '/administration/organizations/groups',
        component: GroupsGrid,
        featureFlag: 'dashboard.nav.apps.administration',
        permissionKey: PERMISSIONS.ADMINISTRATION.VIEW,
    },
    {
        path: '/administration/organizations/groups/new',
        component: GroupsForm,
        featureFlag: 'dashboard.nav.apps.administration',
        permissionKey: PERMISSIONS.ADMINISTRATION.VIEW,
    },
    {
        path: '/administration/organizations/groups/:id',
        component: GroupsForm,
        featureFlag: 'dashboard.nav.apps.administration',
        permissionKey: PERMISSIONS.ADMINISTRATION.VIEW,
    },
    {
        path: '/administration/organizations/roles',
        component: AdministrationRolesGrid,
        featureFlag: 'dashboard.nav.apps.administration',
        permissionKey: PERMISSIONS.ADMINISTRATION.VIEW,
    },
    {
        path: '/administration/organizations/roles/new',
        component: RoleForm,
        featureFlag: 'dashboard.nav.apps.administration',
        permissionKey: PERMISSIONS.ADMINISTRATION.VIEW,
    },
    {
        path: '/administration/organizations/roles/:id',
        component: RoleForm,
        featureFlag: 'dashboard.nav.apps.administration',
        permissionKey: PERMISSIONS.ADMINISTRATION.VIEW,
    },
    {
        path: '/administration/organizations/permissions',
        component: PermissionRoleGrid,
        featureFlag: 'dashboard.nav.apps.administration',
        permissionKey: PERMISSIONS.ADMINISTRATION.VIEW,
    },
    {
        path: '/administration/organizations/permissions/:id',
        component: PermissionTree,
        featureFlag: 'dashboard.nav.apps.administration',
        permissionKey: PERMISSIONS.ADMINISTRATION.VIEW,
    },
    {
        path: '/administration/organizations/departmentCodes',
        component: DepartmentCodesGrid,
        featureFlag: 'dashboard.nav.apps.administration',
        permissionKey: PERMISSIONS.ADMINISTRATION.VIEW,
    },
    {
        path: '/administration/organizations/departmentCodes/new',
        component: DepartmentCodeForm,
        featureFlag: 'dashboard.nav.apps.administration',
        permissionKey: PERMISSIONS.ADMINISTRATION.VIEW,
    },
    {
        path: '/administration/organizations/departmentCodes/:id',
        component: DepartmentCodeForm,
        featureFlag: 'dashboard.nav.apps.administration',
        permissionKey: PERMISSIONS.ADMINISTRATION.VIEW,
    },
    {
        path: '/administration/organizations/buildings',
        component: BuildingsGrid,
        featureFlag: 'dashboard.nav.apps.administration',
        permissionKey: PERMISSIONS.ADMINISTRATION.VIEW,
    },
    {
        path: '/administration/organizations/buildings/new',
        component: BuildingsForm,
        featureFlag: 'dashboard.nav.apps.administration',
        permissionKey: PERMISSIONS.ADMINISTRATION.VIEW,
    },
    {
        path: '/administration/organizations/buildings/:id',
        component: BuildingsForm,
        featureFlag: 'dashboard.nav.apps.administration',
        permissionKey: PERMISSIONS.ADMINISTRATION.VIEW,
    },
    {
        path: '/administration/setup/languages',
        component: LanguagesGrid,
        featureFlag: 'dashboard.nav.apps.administration',
        permissionKey: PERMISSIONS.ADMINISTRATION.VIEW,
    },
    {
        path: '/administration/revisions',
        component: RevisionSearch,
        featureFlag: 'dashboard.nav.apps.administration',
        permissionKey: PERMISSIONS.ADMINISTRATION.VIEW,
    },
    {
        path: '/administration/feature-flags',
        component: FeatureFlagsGrid,
        datacorAdminOnly: true,
        featureFlag: 'dashboard.nav.apps.administration',
        permissionKey: PERMISSIONS.ADMINISTRATION.VIEW,
    },
];

export default AdministrationRoutes;
