import React from 'react';
import { Grid } from '@mui/material';
import { useParams } from 'react-router-dom';
import useSupplierAddressForm from './hooks/useSupplierAddressForm';
import FormPartial from '../../components/form/formContainer/FormPartial';
import TextInput from '../../components/form/formInputs/TextInput/TextInput';
import BaseForm, { ParamTypes } from '../../components/form/baseForm/BaseForm';
import { RoutingValues } from '../../types/Shared.types';
import TranslatableText from '../../components/i18n/TranslatableText';
import { SupplierAddressFormDefs } from '../../constants/i18n/translations/termDefinitions/supply-chain';
import { SUPPLIER_ADDRESS_FIELDS } from '../../constants/revisionHistory/supplierApplicationRevisionHistory';
import SelectInput from '../../components/form/formInputs/SelectInput/SelectInput';

const SupplierAddressForm = () => {
    const { id } = useParams<ParamTypes>();
    const {
        fields,
        termSet,
        auditableFields,
        formMethods,
        statusOptions,
        typeOptions,
        countryOptions,
        stateSelectOptions,
        formType,
        onCreate,
        onUpdate,
        onDelete,
        closeForm,
        copyForm,
        handleFieldChange,
        onCountryCodeChanged,
        isLoadingData,
        isLoadingStatuses,
        isLoadingTypes,
        isLoadingCountries,
        isLoadingStates,
        canCreate,
        canUpdate,
        canDelete,
    } = useSupplierAddressForm(id);

    return (
        <BaseForm
            title={
                <TranslatableText
                    termSet={termSet}
                    termKey={SupplierAddressFormDefs.Title}
                />
            }
            formMethods={formMethods}
            isLoading={isLoadingData()}
            isNew={
                id === RoutingValues.newId || formType === RoutingValues.copy
            }
            onCreate={onCreate}
            onUpdate={onUpdate}
            onDelete={onDelete}
            onCopy={copyForm}
            onCancel={closeForm}
            displayCreateButton={canCreate}
            displayUpdateButton={canUpdate}
            displayDeleteButton={canDelete}
            displayCopyButton={canCreate}
            showOnRevision={true}
            fieldMappings={SUPPLIER_ADDRESS_FIELDS}
            entityUuid={fields?.uuid}
            auditableFields={auditableFields}>
            <FormPartial>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <TextInput
                            fullWidth
                            label={
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={SupplierAddressFormDefs.Code}
                                />
                            }
                            id="code"
                            value={fields.code}
                            onChange={handleFieldChange}
                            formMethods={formMethods}
                            required={true}
                            maxLength={30}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextInput
                            fullWidth
                            label={
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={SupplierAddressFormDefs.Name}
                                />
                            }
                            id="name"
                            value={fields.name}
                            onChange={handleFieldChange}
                            formMethods={formMethods}
                            maxLength={60}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <SelectInput
                            fullWidth
                            label={
                                <TranslatableText
                                    termKey={SupplierAddressFormDefs.Status}
                                    termSet={termSet}
                                />
                            }
                            id="addressStatusId"
                            value={fields.addressStatusId}
                            onChange={handleFieldChange}
                            formMethods={formMethods}
                            options={statusOptions}
                            disabled={isLoadingStatuses}
                            required={true}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <SelectInput
                            fullWidth
                            label={
                                <TranslatableText
                                    termKey={SupplierAddressFormDefs.Type}
                                    termSet={termSet}
                                />
                            }
                            id="addressTypeId"
                            value={fields.addressTypeId}
                            onChange={handleFieldChange}
                            formMethods={formMethods}
                            options={typeOptions}
                            disabled={isLoadingTypes}
                            required={true}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <SelectInput
                            fullWidth
                            label={
                                <TranslatableText
                                    termKey={SupplierAddressFormDefs.Country}
                                    termSet={termSet}
                                />
                            }
                            id="countryCodeId"
                            value={fields.countryCodeId}
                            onChange={onCountryCodeChanged}
                            formMethods={formMethods}
                            options={countryOptions}
                            disabled={isLoadingCountries}
                            required={true}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextInput
                            fullWidth
                            label={
                                <TranslatableText
                                    termKey={SupplierAddressFormDefs.PostalCode}
                                    termSet={termSet}
                                />
                            }
                            id="postalCode"
                            value={fields.postalCode}
                            onChange={handleFieldChange}
                            formMethods={formMethods}
                            maxLength={30}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <SelectInput
                            fullWidth
                            label={
                                <TranslatableText
                                    termKey={SupplierAddressFormDefs.State}
                                    termSet={termSet}
                                />
                            }
                            id="stateCodeId"
                            value={fields.stateCodeId}
                            onChange={handleFieldChange}
                            formMethods={formMethods}
                            options={stateSelectOptions}
                            disabled={isLoadingStates}
                            required={true}
                        />
                        <div style={{ padding: '10px' }} />
                        <TextInput
                            fullWidth
                            label={
                                <TranslatableText
                                    termKey={SupplierAddressFormDefs.County}
                                    termSet={termSet}
                                />
                            }
                            id="county"
                            value={fields.county}
                            formMethods={formMethods}
                            onChange={handleFieldChange}
                            maxLength={60}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextInput
                            fullWidth
                            label={
                                <TranslatableText
                                    termKey={SupplierAddressFormDefs.Street}
                                    termSet={termSet}
                                />
                            }
                            id="street"
                            value={fields.street}
                            formMethods={formMethods}
                            onChange={handleFieldChange}
                            multiline
                            rows={4}
                            maxLength={255}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextInput
                            fullWidth
                            label={
                                <TranslatableText
                                    termKey={SupplierAddressFormDefs.City}
                                    termSet={termSet}
                                />
                            }
                            id="city"
                            value={fields.city}
                            formMethods={formMethods}
                            onChange={handleFieldChange}
                            maxLength={60}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextInput
                            fullWidth
                            label={
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={SupplierAddressFormDefs.Phone}
                                />
                            }
                            id="phone"
                            value={fields.phone}
                            onChange={handleFieldChange}
                            formMethods={formMethods}
                            maxLength={30}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextInput
                            fullWidth
                            label={
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={SupplierAddressFormDefs.Email}
                                />
                            }
                            id="email"
                            value={fields.email}
                            onChange={handleFieldChange}
                            formMethods={formMethods}
                            maxLength={30}
                        />
                    </Grid>
                </Grid>
            </FormPartial>
        </BaseForm>
    );
};

export default SupplierAddressForm;
