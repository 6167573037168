import { PermissionsUtil } from '../../../../utils/permissions/permissionsUtil';
import { PERMISSIONS } from '../../../../constants/permissions/Permissions.constants';
import { ACCOUNTING_DEFS } from '../../../../constants/i18n/translations/termSetDefinitions/accounting';
import { skipToken } from '@reduxjs/toolkit/query';
import { RoutingValues } from '../../../../types/Shared.types';
import {
    useCreateFiscalYearMutation,
    useDeleteFiscalYearMutation,
    useDropdownFiscalYearStatusQuery,
    useGetFiscalYearQuery,
    useUpdateFiscalYearMutation,
} from '../../../../services/accounting/accounting.service';
import { FiscalYear } from '../../../../types/Accounting.types';
import useBaseForm from '../../../form/hooks/useBaseForm';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import useTranslation from '../../../../components/i18n/hooks/useTranslation';
import { useEffect, useState } from 'react';

const useFiscalYearForm = (id: string) => {
    const user = useSelector((state: RootState) => state.user);
    const nextFiscalYear = useSelector((state: RootState) => state.accounting);
    const [canCreate, canUpdate, canDelete] =
        PermissionsUtil.checkPermissionGroup(
            user.permissions,
            PERMISSIONS.ACCOUNTING.FISCAL_YEARS
        );

    const { termSet, isLoadingTermSet } = useTranslation(
        ACCOUNTING_DEFS.FISCAL_YEAR_FORM
    );

    const { data, isLoading } = useGetFiscalYearQuery(
        id === RoutingValues.newId ? skipToken : id
    );

    const [createMutation] = useCreateFiscalYearMutation();
    const [updateMutation] = useUpdateFiscalYearMutation();
    const [deleteMutation] = useDeleteFiscalYearMutation();

    const { data: statusOptions, isLoading: isLoadingStatuses } =
        useDropdownFiscalYearStatusQuery();

    // In addition to the deletion permissions, we need to check if the fiscal year:
    // 1. Is not closed
    // 2. Is not followed by another fiscal year
    const [showDeleteButton, setShowDeleteButton] = useState(false);
    useEffect(() => {
        if (data) {
            const isClosed = data.fiscalYearStatus?.closed === true;
            const isFollowedByAnotherFiscalYear =
                data.number + 1 < nextFiscalYear.fiscalYear.nextFiscalYear;
            setShowDeleteButton(
                canDelete && !isClosed && !isFollowedByAnotherFiscalYear
            );
        }
    }, [data]);

    // In addition to the create permissions, we need to check if the fiscal year is the last one before we allow COPY
    const [showCopyButton, setShowCopyButton] = useState(false);
    useEffect(() => {
        if (data) {
            const isFollowedByAnotherFiscalYear =
                data.number + 1 < nextFiscalYear.fiscalYear.nextFiscalYear;
            setShowCopyButton(canCreate && !isFollowedByAnotherFiscalYear);
        }
    }, [data]);

    const blankFiscalYear: FiscalYear = {
        id: null,
        businessEntityId: null,
        number: nextFiscalYear.fiscalYear.nextFiscalYear,
        code: nextFiscalYear.fiscalYear.nextFiscalYear.toString(),
        fiscalYearStatus: null,
        fiscalYearStatusId: null,
        startDate: nextFiscalYear.fiscalYear.nextStartDate,
        endDate: nextFiscalYear.fiscalYear.nextEndDate,
        createdAt: null,
        createdBy: null,
        updatedAt: null,
        updatedBy: null,
    };

    const {
        fields,
        handleFieldChange,
        formMethods,
        onCreate,
        onUpdate,
        onDelete,
        copyForm,
        closeForm,
        auditableFields,
        formType,
        setValues,
    } = useBaseForm({
        closePath: '/accounting/setup/fiscalYears',
        copyPath: `/accounting/setup/fiscalYears/${RoutingValues.newId}`,
        blankEntity: blankFiscalYear,
        activeEntity: data,
        getDescription: () => {
            return `Fiscal Year ${fields.code}`;
        },
        createEntity: () => {
            return createMutation({ postBody: fields });
        },
        updateEntity: () => {
            return updateMutation({
                id: id as unknown as number,
                postBody: fields,
            });
        },
        deleteEntity: () => {
            return deleteMutation(id);
        },
    });

    useEffect(() => {
        if (formType === RoutingValues.copy) {
            setValues({
                id: null,
                businessEntityId: null,
                number: nextFiscalYear.fiscalYear.nextFiscalYear,
                code: nextFiscalYear.fiscalYear.nextFiscalYear.toString(),
                startDate: nextFiscalYear.fiscalYear.nextStartDate,
                endDate: nextFiscalYear.fiscalYear.nextEndDate,
                createdAt: null,
                createdBy: null,
                updatedAt: null,
                updatedBy: null,
            });
        }
    }, [formType]);

    return {
        fields,
        handleFieldChange,
        formMethods,
        onCreate,
        onUpdate,
        onDelete,
        copyForm,
        closeForm,
        isLoading,
        isLoadingTermSet,
        termSet,
        canCreate,
        canUpdate,
        canDelete: showDeleteButton,
        showCopyButton,
        data,
        auditableFields,
        statusOptions,
        isLoadingStatuses,
        canAdjustStartDate: nextFiscalYear.fiscalYear.canAdjustStartDate,
    };
};

export default useFiscalYearForm;
