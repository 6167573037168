import { SdsHeader } from '../../../../../../../../types/formulation';
import React from 'react';
import DatacorLogoSpinner from '../../../../../../../../components/datacorLogoSpinner/DatacorLogoSpinner';
import FormPartial from '../../../../../../../../components/form/formContainer/FormPartial';
import SdsPictogramForm from './sdsPictogram/SdsPictogramForm';
import SdsGhsHazardPhrasesGrid from './sdsGhsHazardPhrases/SdsGhsHazardPhrasesGrid';
import SdsGhsPrecautionaryPhrasesGrid from './sdsGhsPrecautionaryPhrases/SdsGhsPrecautionaryPhrasesGrid';
import { ChildEditableGridProps } from '../../../../../../../grid/utils/editableGrid/ChildEditableGridProps';
import { ChildFormProps } from '../../../../../../../form/helpers/ChildFormProps';
import TranslatableText from '../../../../../../../../components/i18n/TranslatableText';
import { SDSFormDefs } from '../../../../../../../../constants/i18n/translations/termDefinitions/formulation';
import { SdsHazardInformationFormCopyProps } from '../../SdsHazardInformationForm';

const SubSection2_2Form = ({
    parentData,
    displayGridButtons,
    helpers,
    permissions,
    termSet,
    copyMethods,
}: ChildEditableGridProps<SdsHeader> &
    ChildFormProps<SdsHeader> &
    SdsHazardInformationFormCopyProps) => {
    const { isParentLoading } = parentData;

    return isParentLoading ? (
        <DatacorLogoSpinner />
    ) : (
        <FormPartial
            title={
                (
                    <TranslatableText
                        termSet={termSet}
                        termKey={SDSFormDefs.Label_Elements}
                    />
                ) as unknown as string
            }>
            <FormPartial
                title={
                    (
                        <TranslatableText
                            termSet={termSet}
                            termKey={SDSFormDefs.GHS_Pictogram}
                        />
                    ) as unknown as string
                }>
                <SdsPictogramForm
                    termSet={termSet}
                    parentData={parentData}
                    helpers={helpers}
                    permissions={permissions}
                />
            </FormPartial>
            <FormPartial
                title={
                    (
                        <TranslatableText
                            termSet={termSet}
                            termKey={SDSFormDefs.GHS_Hazard_Phrases}
                        />
                    ) as unknown as string
                }>
                <SdsGhsHazardPhrasesGrid
                    parentData={parentData}
                    displayGridButtons={displayGridButtons}
                    helpers={helpers}
                    copyMethods={{
                        rowsData: copyMethods.sdsGhsHazardPhraseList,
                        setRowsData: copyMethods.setSdsGhsHazardPhraseList,
                    }}
                />
            </FormPartial>
            <FormPartial
                title={
                    (
                        <TranslatableText
                            termSet={termSet}
                            termKey={SDSFormDefs.GHS_Precautionary_Phrases}
                        />
                    ) as unknown as string
                }>
                <SdsGhsPrecautionaryPhrasesGrid
                    parentData={parentData}
                    displayGridButtons={displayGridButtons}
                    helpers={helpers}
                    copyMethods={{
                        rowsData: copyMethods.sdsGhsPrecautionaryPhraseList,
                        setRowsData:
                            copyMethods.setSdsGhsPrecautionaryPhraseList,
                    }}
                />
            </FormPartial>
        </FormPartial>
    );
};

export default SubSection2_2Form;
