import { Grid } from '@mui/material';
import React from 'react';
import TextInput from '../../../../../../../../components/form/formInputs/TextInput/TextInput';
import {
    BlobData,
    SdsBlobSectionFormProps,
} from '../../../shared/blobs/hooks/SdsBlobSectionFormProps';
import {
    SdsHeader,
    SdsSubSection4_1,
} from '../../../../../../../../types/formulation';
import useSdsBlobSectionForm from '../../../shared/blobs/hooks/useSdsBlobSectionForm';
import SdsSubSectionForm from '../../../shared/blobs/components/SdsSubSectionForm';
import TranslatableText from '../../../../../../../../components/i18n/TranslatableText';
import { SDSFormDefs } from '../../../../../../../../constants/i18n/translations/termDefinitions/formulation';
const SubSection4_1Form = (props: SdsBlobSectionFormProps<SdsHeader>) => {
    const { isParentLoading } = props.parentData;

    const { disabledField } = props.permissions;

    const blobData: BlobData<SdsSubSection4_1> = {
        sectionNumber: 4,
        subSectionNumber: 1,
        blankBlobEntity: {
            afterEyeContact: '',
            afterSkinContact: '',
            afterIngestion: '',
            afterInhalation: '',
            firstAidMeasuresGeneral: '',
            selfProtectionOfFirstAider: '',
        },
    };

    const { fields, isBlobLoading, formMethods, handleFieldChange } =
        useSdsBlobSectionForm<SdsHeader, SdsSubSection4_1>({
            ...props,
            blobData,
        });
    return (
        <SdsSubSectionForm
            formTitle={
                (
                    <TranslatableText
                        termSet={props.termSet}
                        termKey={SDSFormDefs.Description_Of_First_Aid_Measures}
                    />
                ) as unknown as string
            }
            isFormLoading={isParentLoading || isBlobLoading}
            content={
                <>
                    <Grid item xs={12}>
                        <TextInput
                            fullWidth
                            label={
                                <TranslatableText
                                    termSet={props.termSet}
                                    termKey={
                                        SDSFormDefs.First_Aid_Measures_General
                                    }
                                />
                            }
                            id="firstAidMeasuresGeneral"
                            disabled={disabledField}
                            formMethods={formMethods}
                            value={fields.firstAidMeasuresGeneral}
                            onChange={handleFieldChange}
                            maxLength={2000}
                            multiline={true}
                            maxRows={15}
                            minRows={5}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextInput
                            fullWidth
                            label={
                                <TranslatableText
                                    termSet={props.termSet}
                                    termKey={SDSFormDefs.After_Inhalation}
                                />
                            }
                            id="afterInhalation"
                            disabled={disabledField}
                            formMethods={formMethods}
                            value={fields.afterInhalation}
                            onChange={handleFieldChange}
                            maxLength={2000}
                            multiline={true}
                            maxRows={15}
                            minRows={5}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextInput
                            fullWidth
                            label={
                                <TranslatableText
                                    termSet={props.termSet}
                                    termKey={SDSFormDefs.After_Skin_Contact}
                                />
                            }
                            id="afterSkinContact"
                            disabled={disabledField}
                            formMethods={formMethods}
                            value={fields.afterSkinContact}
                            onChange={handleFieldChange}
                            maxLength={2000}
                            multiline={true}
                            maxRows={15}
                            minRows={5}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextInput
                            fullWidth
                            label={
                                <TranslatableText
                                    termSet={props.termSet}
                                    termKey={SDSFormDefs.After_Eye_Contact}
                                />
                            }
                            id="afterEyeContact"
                            disabled={disabledField}
                            formMethods={formMethods}
                            value={fields.afterEyeContact}
                            onChange={handleFieldChange}
                            maxLength={2000}
                            multiline={true}
                            maxRows={15}
                            minRows={5}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextInput
                            fullWidth
                            label={
                                <TranslatableText
                                    termSet={props.termSet}
                                    termKey={SDSFormDefs.After_Ingestion}
                                />
                            }
                            id="afterIngestion"
                            disabled={disabledField}
                            formMethods={formMethods}
                            value={fields.afterIngestion}
                            onChange={handleFieldChange}
                            maxLength={2000}
                            multiline={true}
                            maxRows={15}
                            minRows={5}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextInput
                            fullWidth
                            label={
                                <TranslatableText
                                    termSet={props.termSet}
                                    termKey={
                                        SDSFormDefs.Self_Protection_First_Aider
                                    }
                                />
                            }
                            id="selfProtectionOfFirstAider"
                            disabled={disabledField}
                            formMethods={formMethods}
                            value={fields.selfProtectionOfFirstAider}
                            onChange={handleFieldChange}
                            maxLength={2000}
                            multiline={true}
                            maxRows={15}
                            minRows={5}
                        />
                    </Grid>
                </>
            }
        />
    );
};
export default SubSection4_1Form;
