import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { PermissionsUtil } from '../../../utils/permissions/permissionsUtil';
import { PERMISSIONS } from '../../../constants/permissions/Permissions.constants';
import { useContext } from 'react';
import SettingsContext from '../../../contexts/settings.context';
import { useGetTermSetQuery } from '../../../services/i18n/i18n.service';
import { SUPPLY_CHAIN_DEFS } from '../../../constants/i18n/translations/termSetDefinitions/supply-chain';
import { skipToken } from '@reduxjs/toolkit/query';
import { RoutingValues } from '../../../types/Shared.types';
import {
    useCreateSupplierAddressTypeMutation,
    useDeleteSupplierAddressTypeMutation,
    useGetSupplierAddressTypeQuery,
    useUpdateSupplierAddressTypeMutation,
} from '../../../services/supplier/supplier.service';
import { SupplierAddressType } from '../../../types/Supplier.types';
import useBaseForm from '../../form/hooks/useBaseForm';

export const useSupplierAddressTypeForm = (id: string) => {
    const sortOrderState = useSelector((state: RootState) => state.suppliers);

    const canCreate = PermissionsUtil.hasPermission(
        PERMISSIONS.SUPPLIERS.SETUP.SUPPLIER_ADDRESS_TYPES.CREATE
    );

    const canUpdate = PermissionsUtil.hasPermission(
        PERMISSIONS.SUPPLIERS.SETUP.SUPPLIER_ADDRESS_TYPES.EDIT
    );

    const canDelete = PermissionsUtil.hasPermission(
        PERMISSIONS.SUPPLIERS.SETUP.SUPPLIER_ADDRESS_TYPES.DELETE
    );

    const { settings } = useContext(SettingsContext);
    const { data: termSet } = useGetTermSetQuery(
        settings?.userSettings
            ? {
                  languageId: settings?.userSettings?.languageId,
                  code: SUPPLY_CHAIN_DEFS.SUPPLIER_ADDRESS_TYPE_FORM,
              }
            : skipToken
    );

    const { data, isLoading } = useGetSupplierAddressTypeQuery(
        id === RoutingValues.newId ? skipToken : id
    );

    const [createMutation] = useCreateSupplierAddressTypeMutation();
    const [updateMutation] = useUpdateSupplierAddressTypeMutation();
    const [deleteMutation] = useDeleteSupplierAddressTypeMutation();

    const blankSupplierAddressType: SupplierAddressType = {
        id: null,
        businessEntityId: null,
        typeActive: true,
        default: false,
        main: false,
        remitPayment: false,
        createdAt: null,
        createdBy: null,
        updatedAt: null,
        updatedBy: null,
        sortOrder: sortOrderState.sortOrderSet.maxValue,
        code: '',
        description: '',
    };

    const {
        fields,
        handleFieldChange,
        formMethods,
        onCreate,
        onUpdate,
        onDelete,
        closeForm,
        auditableFields,
    } = useBaseForm({
        closePath: '/suppliers/setup/addressType',
        blankEntity: blankSupplierAddressType,
        activeEntity: data,
        getDescription: () => {
            return `Supplier Address Type ${fields.code}`;
        },
        createEntity: () => {
            return createMutation({ postBody: fields });
        },
        updateEntity: () => {
            return updateMutation({
                id: id as unknown as number,
                postBody: fields,
            });
        },
        deleteEntity: () => {
            return deleteMutation(id);
        },
    });

    return {
        fields,
        handleFieldChange,
        formMethods,
        onCreate,
        onUpdate,
        onDelete,
        closeForm,
        isLoading,
        termSet,
        canCreate,
        canUpdate,
        canDelete,
        data,
        auditableFields,
    };
};
