import React, { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import BuildIcon from '@mui/icons-material/Build';
import { alpha, styled } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import SettingsMenu from './menuIconMenus/SettingsMenu';
import { RootState } from '../../store';
// @ts-ignore
const MenuIconsWrapper = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    color: theme.palette.headerNavIcons.main,
    marginLeft: 0,
    textAlign: 'right',
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(1),
        width: 'auto',
    },
}));

/* These icons will need to be added back at some point so leaving them here might be good
    <IconButton>
        <NotificationsIcon color="headerNavIcons" />
    </IconButton>
    <IconButton>
        <ArticleIcon color="headerNavIcons" />
    </IconButton>
    <IconButton>
        <SendIcon color="headerNavIcons" />
    </IconButton>
    <IconButton>
             <HelpIcon color="headerNavIcons" />
    </IconButton>
  */

const MenuIcons = () => {
    const user = useSelector((state: RootState) => state.user);
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    return (
        <MenuIconsWrapper>
            <IconButton onClick={handleClick}>
                {/*@ts-ignore*/}
                <BuildIcon color="headerNavIcons" />
            </IconButton>
            <SettingsMenu
                user={user}
                anchorEl={anchorEl}
                setAnchorEl={setAnchorEl}
            />
        </MenuIconsWrapper>
    );
};

export default MenuIcons;
