import { BaseCreateEntityArgs } from '../../serviceInterfaces';
import { datacorAppApi } from '../../datacorAppApi';
import { HazardClassificationMapping } from '../../../types/formulation';

const serviceUrl: string = 'formulation/hazardClassMapping';

export const hazardClassMappingApi = datacorAppApi.injectEndpoints({
    endpoints: (build) => ({
        updatehazardClassMappings: build.mutation<
            HazardClassificationMapping,
            BaseCreateEntityArgs<HazardClassificationMapping[]>
        >({
            query: (args) => ({
                url: serviceUrl,
                method: 'POST',
                body: args.postBody,
            }),
            invalidatesTags: ['hazardClassMappings'],
        }),
        listHazardClassMappings: build.query<
            HazardClassificationMapping[],
            void
        >({
            query: () => {
                return {
                    url: serviceUrl,
                };
            },
            providesTags: ['hazardClassMappings'],
        }),
    }),
});

export const {
    useListHazardClassMappingsQuery,
    useUpdatehazardClassMappingsMutation,
} = hazardClassMappingApi;
