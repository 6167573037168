import React from 'react';
import useContractTypeGrid from './hooks/useContractTypeGrid';
import BaseFormGrid from '../../components/grids/BaseFormGrid';
import { cloneDeep } from 'lodash';
import { ContractTypesGridDefs } from '../../constants/i18n/translations/termDefinitions/accounting';
import TranslatableText from '../../components/i18n/TranslatableText';
import { isNilOrEmpty } from '../../utils/objectUtils';

const ContractTypeGrid = () => {
    const {
        contractTypeList,
        redirectToForm,
        isLoadingContractTypes,
        canCreateContractType,
        canViewContractType,
        canUpdate,
        handleGridEdits,
        termSet,
        colDefs,
    } = useContractTypeGrid();

    return (
        <BaseFormGrid
            title={
                <TranslatableText
                    termSet={termSet}
                    termKey={ContractTypesGridDefs.Contract_Types}
                />
            }
            displayGrid={canViewContractType}
            isLoading={isNilOrEmpty(colDefs)}
            rowData={cloneDeep(contractTypeList)}
            useRowDrag={canUpdate}
            sortKey={'sortOrder'}
            columnDefs={colDefs}
            gridClass="full-size-item"
            displayToolbar={true}
            displayCreateNewButton={canCreateContractType}
            displayExportDataButton={true}
            displayResetStateButton={true}
            onCreateNew={redirectToForm}
            onRowClicked={({ data }) => redirectToForm(data)}
            onHandleGridEdits={handleGridEdits}
        />
    );
};

export default ContractTypeGrid;
