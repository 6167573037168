import { Grid } from '@mui/material';
import SelectInput from '../../../../../../components/form/formInputs/SelectInput/SelectInput';
import Button from '@mui/material/Button';
import React from 'react';

const RenderUnappliedTemplateHeader = ({
    id,
    canUpdateItemMaster,
    fields,
    handleFieldChange,
    formMethods,
    activeTemplates,
    isLoadingTemplates,
    attrFieldsDisabled,
    handleApplyTemplate,
}: any) => {
    return (
        <Grid container spacing={2}>
            <Grid item xs={4}>
                <SelectInput
                    fullWidth
                    label="Attribute Template"
                    id="attrTemplateId"
                    value={fields?.attrTemplateId}
                    onChange={handleFieldChange}
                    formMethods={formMethods}
                    options={activeTemplates?.map((item: any) => {
                        return {
                            value: item.id,
                            label: item.name,
                        };
                    })}
                    disabled={isLoadingTemplates || !attrFieldsDisabled}
                    required={false}
                    SelectProps={{
                        readOnly: id && !canUpdateItemMaster,
                    }}
                />
            </Grid>
            <Grid item xs={4}>
                <Button
                    onClick={handleApplyTemplate}
                    variant={
                        !fields?.attrTemplateId || !attrFieldsDisabled
                            ? 'outlined'
                            : 'contained'
                    }
                    disabled={!attrFieldsDisabled || !fields?.attrTemplateId}>
                    Apply Template
                </Button>
            </Grid>
        </Grid>
    );
};

export default RenderUnappliedTemplateHeader;
