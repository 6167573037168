import DefaultColumnTypes from '../../../../components/grids/columns/Column.constants';
import CustomHeader from '../../../../components/grids/CustomHeader';
import React, { useContext } from 'react';
import SharedTranslationsContext from '../../../../contexts/translations.context';
import { SharedComponentsDefs } from '../../../../constants/i18n/translations/termDefinitions/platform';

export function auditableGridColumns() {
    const { sharedTranslations } = useContext(SharedTranslationsContext);
    const hiddenIdColumns = [
        {
            field: 'businessEntityId',
            hide: true,
            ...DefaultColumnTypes.NumberOnly,
            headerComponentFramework: (props: any) => {
                return (
                    <CustomHeader
                        {...props}
                        termSet={sharedTranslations}
                        termKey={SharedComponentsDefs.Business_Entity_Id}
                    />
                );
            },
        },
        {
            field: 'id',
            hide: true,
            ...DefaultColumnTypes.NumberOnly,
            headerComponentFramework: (props: any) => {
                return (
                    <CustomHeader
                        {...props}
                        termSet={sharedTranslations}
                        termKey={SharedComponentsDefs.Id}
                    />
                );
            },
        },
    ];

    const auditableColumns = [
        {
            field: 'createdAt',
            ...DefaultColumnTypes.DateTime,
            headerComponentFramework: (props: any) => {
                return (
                    <CustomHeader
                        {...props}
                        termSet={sharedTranslations}
                        termKey={SharedComponentsDefs.Created_At}
                    />
                );
            },
        },
        {
            field: 'createdByUser.email',
            ...DefaultColumnTypes.MediumText,
            headerComponentFramework: (props: any) => {
                return (
                    <CustomHeader
                        {...props}
                        termSet={sharedTranslations}
                        termKey={SharedComponentsDefs.Created_By}
                    />
                );
            },
        },
        {
            field: 'updatedAt',
            ...DefaultColumnTypes.DateTime,
            headerComponentFramework: (props: any) => {
                return (
                    <CustomHeader
                        {...props}
                        termSet={sharedTranslations}
                        termKey={SharedComponentsDefs.Updated_At}
                    />
                );
            },
        },
        {
            field: 'updatedByUser.email',
            ...DefaultColumnTypes.MediumText,
            headerComponentFramework: (props: any) => {
                return (
                    <CustomHeader
                        {...props}
                        termSet={sharedTranslations}
                        termKey={SharedComponentsDefs.Updated_By}
                    />
                );
            },
        },
    ];

    return [hiddenIdColumns, auditableColumns];
}
