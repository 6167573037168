import { Button } from '@mui/material';
import useDisposalsGrid from './hooks/useDisposalsGrid';
import React from 'react';
import ListIcon from '@mui/icons-material/List';
import BaseFormGrid from '../../../components/grids/BaseFormGrid';
import DisposalOptionsModal from './DisposalOptionsModal';
import { ColDef } from 'ag-grid-community';
import DefaultColumnTypes from '../../../components/grids/columns/Column.constants';
import CustomHeader from '../../../components/grids/CustomHeader';
import { DisposalsGridDefs } from '../../../constants/i18n/translations/termDefinitions/accounting';
import TranslatableText from '../../../components/i18n/TranslatableText';

const DisposalsGrid = () => {
    const {
        disposalRows,
        setAssetList,
        setShowOptionsModal,
        showOptionsModal,
        disposalsSummaryRow,
        canViewDisposalsReport,
        termSet,
    } = useDisposalsGrid();

    const reportColumnDefs: ColDef[] = [
        {
            field: 'number',
            headerComponentFramework: (props: any) => {
                return (
                    <CustomHeader
                        {...props}
                        termSet={termSet}
                        termKey={DisposalsGridDefs.Asset_No}
                    />
                );
            },
            cellRenderer: 'agGroupCellRenderer',
            ...DefaultColumnTypes.ShortText,
        },
        {
            field: 'code',
            headerComponentFramework: (props: any) => {
                return (
                    <CustomHeader
                        {...props}
                        termSet={termSet}
                        termKey={DisposalsGridDefs.Asset_Code}
                    />
                );
            },
            ...DefaultColumnTypes.MediumText,
        },
        {
            field: 'name',
            headerComponentFramework: (props: any) => {
                return (
                    <CustomHeader
                        {...props}
                        termSet={termSet}
                        termKey={DisposalsGridDefs.Asset_Name}
                    />
                );
            },
            ...DefaultColumnTypes.MediumText,
        },
        {
            field: 'acquireDate',
            headerComponentFramework: (props: any) => {
                return (
                    <CustomHeader
                        {...props}
                        termSet={termSet}
                        termKey={DisposalsGridDefs.Acquire_Date}
                    />
                );
            },
            ...DefaultColumnTypes.DateOnly,
        },
        {
            field: 'inServiceDate',
            headerComponentFramework: (props: any) => {
                return (
                    <CustomHeader
                        {...props}
                        termSet={termSet}
                        termKey={DisposalsGridDefs.In_Service_Date}
                    />
                );
            },
            ...DefaultColumnTypes.DateOnly,
        },
        {
            field: 'disposalDate',
            headerComponentFramework: (props: any) => {
                return (
                    <CustomHeader
                        {...props}
                        termSet={termSet}
                        termKey={DisposalsGridDefs.Disposal_Date}
                    />
                );
            },
            ...DefaultColumnTypes.DateOnly,
        },
        {
            field: 'totalCost',
            headerComponentFramework: (props: any) => {
                return (
                    <CustomHeader
                        {...props}
                        termSet={termSet}
                        termKey={DisposalsGridDefs.Asset_Cost}
                    />
                );
            },
            ...DefaultColumnTypes.Currency,
        },
        {
            field: 'totalDepreciation',
            headerComponentFramework: (props: any) => {
                return (
                    <CustomHeader
                        {...props}
                        termSet={termSet}
                        termKey={DisposalsGridDefs.Total_Depreciation}
                    />
                );
            },
            ...DefaultColumnTypes.Currency,
            minWidth: 140,
        },
        {
            field: 'remainingValue',
            headerComponentFramework: (props: any) => {
                return (
                    <CustomHeader
                        {...props}
                        termSet={termSet}
                        termKey={DisposalsGridDefs.Asset_Value}
                    />
                );
            },
            ...DefaultColumnTypes.Currency,
            minWidth: 130,
        },
        {
            field: 'settlement',
            headerComponentFramework: (props: any) => {
                return (
                    <CustomHeader
                        {...props}
                        termSet={termSet}
                        termKey={DisposalsGridDefs.Settlement}
                    />
                );
            },
            ...DefaultColumnTypes.Currency,
            minWidth: 130,
        },
        {
            field: 'disposalGainLoss',
            headerComponentFramework: (props: any) => {
                return (
                    <CustomHeader
                        {...props}
                        termSet={termSet}
                        termKey={DisposalsGridDefs.Disposal_Gain_Loss}
                    />
                );
            },
            resizable: false,
            ...DefaultColumnTypes.AccountingCurrency,
        },
    ];

    return (
        <React.Fragment>
            <BaseFormGrid
                title={
                    <TranslatableText
                        termSet={termSet}
                        termKey={DisposalsGridDefs.Disposal_Report}
                    />
                }
                displayGrid={canViewDisposalsReport}
                isLoading={false}
                rowData={disposalRows}
                columnDefs={reportColumnDefs}
                gridClass="full-size-item"
                displaySummaryRow={true}
                pinnedBottomRowData={disposalsSummaryRow}
                displayToolbar={true}
                displayExportDataButton={true}
                toolbarChildren={
                    <Button
                        variant="text"
                        size="small"
                        onClick={() => setShowOptionsModal(true)}
                        startIcon={<ListIcon />}>
                        Options
                    </Button>
                }></BaseFormGrid>
            <DisposalOptionsModal
                termSet={termSet}
                open={showOptionsModal}
                onAssetDisposalsLoaded={(assets) => setAssetList(assets)}
                onClose={() => setShowOptionsModal(false)}
            />
        </React.Fragment>
    );
};
export default DisposalsGrid;
