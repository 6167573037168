import React, { useEffect, useState } from 'react';
import { ColDef, ValueGetterParams } from 'ag-grid-community';
import {
    AttributeTemplate,
    useGetAttributeDefinitionsQuery,
} from '../../../services/attributes/attributes.service';
import { skipToken } from '@reduxjs/toolkit/query';
import { useLocation } from 'react-router-dom';
import { isNilOrEmpty } from '../../../utils/objectUtils';
import { RowStatus } from '../../../components/grids/hooks/useBaseGridEditable';
import { cloneDeep } from 'lodash';

export interface AttributeTemplateDefinitionsGridProps {
    attributeTemplate: AttributeTemplate;
    updateChildList: (childName: string, childList: any[]) => void;
    isLoadingTemplate: boolean;
    canManageAttribute: boolean;
}

const useAttributeTemplateDefinitionsGrid = ({
    attributeTemplate,
    updateChildList,
    isLoadingTemplate,
}: AttributeTemplateDefinitionsGridProps) => {
    let location = useLocation();

    const [attributeDefintionsColDef, setAttributeDefintionsColDef] = useState(
        null as ColDef[]
    );
    const [filteredRowData, setFilteredRowData] = useState([]);
    const [gridOptions, setGridOptions] = useState([]);
    const [parentDefinitionsColDef, setParentDefinitionsColDefs] = useState(
        null as ColDef[]
    );
    const [maxRowId, setMaxRowId] = useState(0);
    const [showMultiSelectGridModal, setShowMultiSelectGridModal] =
        useState(false);

    const { data: attributeDefinitions, isLoading: isLoadingDefinitions } =
        useGetAttributeDefinitionsQuery(
            location?.state?.attrEntityId
                ? location?.state?.attrEntityId
                : skipToken
        );

    //when save edits is clicked
    const handleGridEdits = (records: any) => {
        const updatedFields = [...gridOptions];

        records?.forEach((field: any) => {
            const index = gridOptions?.findIndex((row: any) => {
                return row.rowId === field.rowId;
            });

            if (index > -1) {
                updatedFields[index] = field;
            } else {
                updatedFields.push(field);
            }
        });

        updateChildList(
            'attrDefinitions',
            updatedFields.filter((row) => row.rowStatus !== RowStatus.DELETED)
        );
    };

    //handle the selected data in the multiselectmodalgrid
    const handleSelected = (records: any) => {
        if (records) {
            let index = maxRowId;
            const selectedAttrDefs = records.map((current: any) => {
                const newRecord: any = {
                    ...current,
                    rowStatus: RowStatus.ADDED,
                    rowId: ++index,
                };
                return newRecord;
            });
            handleGridEdits(selectedAttrDefs);
            const updated: any = [...gridOptions, ...selectedAttrDefs];
            setMaxRowId(index);
            setGridOptions(updated);
        }
    };

    //value setter for setting the description in the grid
    const findFieldDescription = (params: any, options: any) => {
        let description: any = null;

        options?.find((current: any) => {
            if (current.id === params.data.id) {
                description = current.name;
            }
        });

        return description;
    };

    //value setter for finding the attrValueType name
    const findFieldType = (params: any, options: any) => {
        let type: any = null;

        options?.find((current: any) => {
            if (current.id === params.data.id) {
                type = current.attrValueType.name;
            }
        });

        return type;
    };

    const setAttributeDefRowId = (attributeDefinitions: any[]) => {
        let index = maxRowId;
        attributeDefinitions.forEach((current) => {
            if (!current.rowId) {
                current.rowId = ++index;
            }
        });
        setMaxRowId(index);
    };

    //setting the gridoptions each time the attrDefinitions get updated
    useEffect(() => {
        if (!isNilOrEmpty(attributeTemplate.attrDefinitions)) {
            const attributeDefList = cloneDeep(
                attributeTemplate.attrDefinitions
            );
            setAttributeDefRowId(attributeDefList);
            setGridOptions(attributeDefList);
        }
    }, [attributeTemplate.attrDefinitions]);

    //setting the modal colDefs
    useEffect(() => {
        if (!isLoadingDefinitions && !isLoadingTemplate) {
            setAttributeDefintionsColDef([
                {
                    field: 'code',
                    headerName: 'Attribute Code',
                    filter: 'agTextColumnFilter',
                    floatingFilter: true,
                },
                {
                    field: 'attrValueType.name',
                    headerName: 'Attribute Type',
                    filter: 'agTextColumnFilter',
                    floatingFilter: true,
                },
                {
                    field: 'name',
                    headerName: 'Name',
                    filter: 'agTextColumnFilter',
                    floatingFilter: true,
                },
            ]);
        }
    }, [isLoadingTemplate, isLoadingDefinitions]);

    //setting the parent colDefs
    useEffect(() => {
        if (
            !isNilOrEmpty(attributeDefintionsColDef) &&
            !isLoadingDefinitions &&
            !isLoadingTemplate
        ) {
            setParentDefinitionsColDefs([
                {
                    field: 'deleteColumn',
                    minWidth: 50,
                    hide: attributeTemplate.locked,
                },
                {
                    field: 'code',
                    headerName: 'Attribute Code',
                    editable: false,
                    filter: 'agTextColumnFilter',
                    floatingFilter: true,
                },
                {
                    field: 'name',
                    headerName: 'Name',
                    valueGetter: (params: ValueGetterParams) => {
                        params.data.name = findFieldDescription(
                            params,
                            attributeDefinitions
                        );

                        return params.data.name;
                    },
                    editable: false,
                    filter: 'agTextColumnFilter',
                    floatingFilter: true,
                },
                {
                    field: 'attrValueTypeId',
                    headerName: 'Type',
                    valueGetter: (params: ValueGetterParams) => {
                        params.data.attrValueTypeId = findFieldType(
                            params,
                            attributeDefinitions
                        );

                        return params.data.attrValueTypeId;
                    },
                    editable: false,
                    filter: 'agTextColumnFilter',
                    floatingFilter: true,
                },
            ]);
        }
    }, [
        attributeDefintionsColDef,
        attributeDefinitions,
        isLoadingDefinitions,
        isLoadingTemplate,
    ]);

    //set the rows in multiselect each time grid options changes
    useEffect(() => {
        if (!isNilOrEmpty(attributeDefinitions)) {
            const filter = attributeDefinitions.filter(
                (def: any) =>
                    !gridOptions.map((def: any) => def.code).includes(def.code)
            );
            setFilteredRowData(filter);
        }
    }, [gridOptions, attributeDefinitions]);

    return {
        handleGridEdits,
        handleSelected,
        setShowMultiSelectGridModal,
        filteredRowData,
        attributeDefintionsColDef,
        parentDefinitionsColDef,
        gridOptions,
        showMultiSelectGridModal,
    };
};

export default useAttributeTemplateDefinitionsGrid;
