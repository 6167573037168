import React from 'react';
import useStatusGrid from './hooks/useStatusGrid';
import BaseFormGrid from '../../components/grids/BaseFormGrid';
import { cloneDeep } from 'lodash';
import { isNilOrEmpty } from '../../utils/objectUtils';

const SupplierStatusGrid = () => {
    const {
        rowData,
        colDefs,
        canView,
        canCreate,
        canUpdate,
        redirectToForm,
        handleGridEdits,
    } = useStatusGrid();

    return (
        <BaseFormGrid
            title="Supplier Statuses"
            displayGrid={canView}
            isLoading={isNilOrEmpty(colDefs)}
            rowData={cloneDeep(rowData)}
            useRowDrag={canUpdate}
            sortKey={'sortOrder'}
            columnDefs={colDefs}
            gridClass="full-size-item"
            displayToolbar={true}
            displayCreateNewButton={canCreate}
            displayResetStateButton={true}
            onCreateNew={redirectToForm}
            displayExportDataButton={true}
            onRowClicked={({ data }) => redirectToForm(data)}
            onHandleGridEdits={handleGridEdits}
        />
    );
};

export default SupplierStatusGrid;
