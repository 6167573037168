import React, { useEffect, useRef } from 'react';
import {
    useListDocDataSourcesForDropdownQuery,
    useGetDocTemplateQuery,
    useUpdateDocTemplateMutation,
    useCreateDocTemplateMutation,
    useDeleteDocTemplateMutation,
} from '../../../services/documentDesigner/documentDesigner.service';
import { skipToken } from '@reduxjs/toolkit/query';
import { isNilOrEmpty } from '../../../utils/objectUtils';
import useBaseForm from '../../form/hooks/useBaseForm';
import useTranslation from '../../../components/i18n/hooks/useTranslation';
import { PLATFORM_DEFS } from '../../../constants/i18n/translations/termSetDefinitions/platform';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { PermissionsUtil } from '../../../utils/permissions/permissionsUtil';
import { PERMISSIONS } from '../../../constants/permissions/Permissions.constants';

const useNewDocumentTemplate = (id: string) => {
    const user = useSelector((state: RootState) => state.user);
    const designerRef = useRef(null);

    const { data: dataSources, isLoading: isLoadingDataSources } =
        useListDocDataSourcesForDropdownQuery();

    const { termSet, isLoadingTermSet } = useTranslation(
        PLATFORM_DEFS.DOC_TEMPLATE_FORM
    );
    const [updateDocumentTemplate] = useUpdateDocTemplateMutation();
    const [createDocumentTemplate] = useCreateDocTemplateMutation();
    const [deleteDocumentTemplate] = useDeleteDocTemplateMutation();

    const { data: documentTemplate, isLoading: isLoadingDocumentTemplate } =
        useGetDocTemplateQuery(id ? id : skipToken);

    const canEditDocumentTemplate = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.DOCUMENT_DESIGNER.DOC_TEMPLATE.EDIT
    );

    const canDeleteDocumentTemplate = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.DOCUMENT_DESIGNER.DOC_TEMPLATE.DELETE
    );

    const canCreateDocumentTemplate = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.DOCUMENT_DESIGNER.DOC_TEMPLATE.CREATE
    );

    const blankRecord = {
        documentType: '',
        docDataSourceId: '',
        code: '',
        name: '',
        locked: false,
        default: false,
        outputFileName: '',
    };

    const {
        fields,
        handleFieldChange,
        formMethods,
        setValues,
        onLock,
        onCreate,
        onUpdate,
        onDelete,
        closeForm,
    } = useBaseForm({
        closePath: `/setup/document-designer/templates`,
        blankEntity: blankRecord,
        activeEntity: documentTemplate,
        getDescription: () => {
            return `Document Template: ${fields.name}`;
        },
        createEntity: null as any,
        updateEntity: null as any,
        deleteEntity: () => {
            return deleteDocumentTemplate(id);
        },
        lockEntity: () => {
            return updateDocumentTemplate({
                id: id,
                postBody: {
                    locked: true,
                },
            });
        },
        reroute: true,
    });

    /**
     * if we have a document template we need to set the values
     * or else we use the blank record
     */
    useEffect(() => {
        if (!isNilOrEmpty(documentTemplate)) {
            setValues(documentTemplate);
        }
    }, [documentTemplate]);

    useEffect(() => {
        if (designerRef.current) {
            overrideSaveReport();
        }
    }, [designerRef, fields, designerRef.current?.report]);

    /**
     * handles hooking into the save button on the designer
     * to save the record to the db
     */

    const handleSave = () => {
        designerRef.current!.jsObject.SendCommandSaveReport();

        if (!id) {
            onCreate();
            closeForm();
        } else {
            onUpdate();
        }
    };
    const overrideSaveReport = () => {
        designerRef.current.onSaveReport = (args: any) => {
            //args.fileName = documents.find((document: any) => document.id === selecedReport)?.reportName;

            args.preventDefault = true;
            args.report.reportName = fields.name;

            const json = args.report.saveToJsonString();

            const params = {
                name: fields.name,
                code: fields.code,
                locked: fields.locked,
                docDataSourceId: fields.docDataSourceId,
                default: fields.default,
                outputFileName: fields.outputFileName,
                templateData: json,
            };

            if (!id) {
                createDocumentTemplate(params);
            } else {
                const data = {
                    id: id,
                    postBody: {
                        name: fields.name,
                        code: fields.code,
                        locked: fields.locked,
                        docDataSourceId: fields.docDataSourceId,
                        default: fields.default,
                        outputFileName: fields.outputFileName,
                        templateData: json,
                    },
                };
                updateDocumentTemplate(data);
            }
        };
    };

    return {
        dataSources,
        fields,
        handleFieldChange,
        formMethods,
        closeForm,
        designerRef,
        onCreate,
        onUpdate,
        onDelete,
        isLoadingDataSources,
        onLock,
        isLoadingDocumentTemplate,
        handleSave,
        termSet,
        isLoadingTermSet,
        canCreateDocumentTemplate,
        canEditDocumentTemplate,
        canDeleteDocumentTemplate,
    };
};

export default useNewDocumentTemplate;
