import React, { useEffect } from 'react';
import { isJsonString, isNilOrEmpty } from '../../utils/objectUtils';
import RenderDefaultValue from './RenderDefaultValue';

const VALUE_TYPE_CODES = {
    text: '1',
    num: '2',
    bool: '3',
    list: '4',
    range: '5',
    tolerance: '6',
};
const RenderAttribute = ({
    definition,
    attrValues,
    setAttrValues,
    formMethods,
    disabled,
    setIsAttributeValid,
    entityAttributes,
}: any) => {
    useEffect(() => {
        if (!isNilOrEmpty(entityAttributes)) {
            const attrObj = {};
            for (const attr of entityAttributes) {
                //@ts-ignore
                attrObj[attr.attrDefinitionId] = isJsonString(attr.data)
                    ? JSON.parse(attr.data)
                    : attr.data;
            }
            setAttrValues(attrObj);
        }
    }, [definition]);

    return (
        <RenderDefaultValue
            handleFieldChange={(event: any) =>
                setAttrValues((prev: any) => ({
                    ...prev,
                    [definition.id]:
                        event.target.type === 'checkbox'
                            ? event.target.checked
                            : event.target.value,
                }))
            }
            fieldLabel={definition.name}
            formMethods={formMethods}
            value={attrValues[definition.id]}
            //@ts-ignore
            attrValueTypeId={VALUE_TYPE_CODES[definition.attrValueType.code]}
            optionsForDropdown={
                isJsonString(definition.options)
                    ? JSON.parse(definition.options).map(
                          (currentAttribute: any) => {
                              return {
                                  label: currentAttribute.option,
                                  value: currentAttribute.id,
                              };
                          }
                      )
                    : []
            }
            isNew={false}
            disabled={disabled}
            setIsAttributeValid={setIsAttributeValid}
        />
    );
};

export default RenderAttribute;
