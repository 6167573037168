import { DateInputProps } from '../DateInput';
import moment from 'moment/moment';
import { dateFormatter } from '../../../../../utils/formattingUtils';
import { useEffect } from 'react';
import { DateValidator } from '../../../../../validators/date.validator';

const useDateInput = (props: DateInputProps) => {
    const handleOnChange = (date?: unknown, keyboardInputValue?: string) => {
        const momentDate = date as moment.Moment;
        props.onChange({
            target: {
                id: props.id,
                value: date ? dateFormatter(momentDate?.format(), true) : null,
            },
        });
    };

    useEffect(() => {
        props.formMethods?.addValidator(
            props.id,
            props.label.toString(),
            DateValidator
        );
    }, []);

    return {
        handleOnChange,
    };
};

export default useDateInput;
