import {
    SdsHeader,
    ItemMaster,
    SdsCasComposition,
    CasMaster,
} from '../../../../../types/formulation';
import { EntityBase } from '../../../../../types/Shared.types';
import { useDispatch } from 'react-redux';
import { itemMasterApi } from '../../../../../services/formulation/itemMaster/item/itemMaster.service';
import { HandleFieldChangedEvent } from '../../../../../libs/hooksLib';
import { openModalConfirmBasicWithMessage } from '../../../../../store/uiElements';
import { isNil } from '../../../../../utils/objectUtils';
import { RowStatus } from '../../../../../components/grids/hooks/useBaseGridEditable';
import useAutoPopulateSectionsWithCasDetails from '../sections/sdsCasComposition/hooks/useAutoPopulateSectionsWithCasDetails';
import { casMasterApi } from '../../../../../services/formulation/casMaster/cas/casMaster.service';

export interface AutoPopulateItemDataProps {
    currentSdsHeader: SdsHeader;
    helpers: {
        handleParentFieldChanged: (event: HandleFieldChangedEvent) => void;
        /**
         *  @param childrenNodeName Data node in the parent JSON object to store the new data.
         *  @param childrenRecords List of children records to be saved in the parent JSON object.
         *  **/
        handleChildRecord?: (
            childNodeName: string,
            event: HandleFieldChangedEvent
        ) => void;
        handleChildrenRecords: <TChild extends EntityBase>(
            childrenNodeName: string,
            childrenRecords: TChild[]
        ) => void;
    };
}

const useAutoPopulateItemData = (props: AutoPopulateItemDataProps) => {
    const { currentSdsHeader, helpers } = props;
    const dispatch = useDispatch();
    let itemRecord: ItemMaster;

    const {
        handleParentFieldChanged,
        handleChildRecord,
        handleChildrenRecords,
    } = helpers;

    const { autoPopulateSections } = useAutoPopulateSectionsWithCasDetails({
        currentSdsHeader,
        helpers: {
            handleChildrenRecords,
        },
    });

    const createHandleFieldEvent = (
        id: string,
        value: any
    ): HandleFieldChangedEvent => {
        return {
            target: {
                id: id,
                value: value,
            },
        };
    };

    const populateSdsHeader = (): void => {
        const sdsDescriptionEvent = createHandleFieldEvent(
            'sdsDescription',
            itemRecord.itemName
        );
        handleParentFieldChanged(sdsDescriptionEvent);
    };

    const populateSdsIdentification = (): void => {
        const productNameEvent = createHandleFieldEvent(
            'productName',
            itemRecord.itemName
        );
        handleChildRecord('sdsIdentification', productNameEvent);
    };

    const getCasRecord = async (casMasterId: string): Promise<CasMaster> => {
        const result: any = await dispatch(
            casMasterApi.endpoints.getCas.initiate(casMasterId)
        );
        const { data } = result;

        return data as CasMaster;
    };

    const createSdsCasCompositions = (): void => {
        Promise.all(
            itemRecord?.chemicalComposition.map(
                async (itemChemicalComposition) => {
                    const casRecord = await getCasRecord(
                        itemChemicalComposition.casId.toString()
                    );
                    return {
                        sdsId: currentSdsHeader.id,
                        casId: itemChemicalComposition.casId,
                        casValue: {
                            id: itemChemicalComposition.casId,
                            display: itemChemicalComposition.casIdentifier,
                        },
                        chemicalName: casRecord.chemicalName,
                        itemId: itemChemicalComposition.itemId,
                        itemValue: {
                            id: itemRecord.id,
                            display: itemRecord.itemId,
                        },
                        classification: casRecord.classification,
                        lowerPercentage: itemChemicalComposition.lowerValue,
                        lowerOperatorId: 3,
                        upperPercentage: itemChemicalComposition.upperValue,
                        upperOperatorId: 1,
                        ecIdentifier: casRecord.ecIdentifier,
                        reachNumber: casRecord.reach,
                        sortOrder: Number(itemChemicalComposition.sortOrder),
                        rowStatus: RowStatus.ADDED,
                        autoPopulated: false,
                        isAutoPopulateByItemProcess: true,
                        businessEntityId:
                            itemChemicalComposition.businessEntityId,
                    } as SdsCasComposition;
                }
            )
        ).then((sdsCasCompositions) => {
            handleChildrenRecords('sdsCasComposition', sdsCasCompositions);

            sdsCasCompositions?.forEach((value) => {
                autoPopulateSections(value).then();
            });
        });
    };

    const _showConfirmModal = (message: string, onConfirm: any) => {
        dispatch(
            openModalConfirmBasicWithMessage({
                message,
                title: '',
                onConfirm,
            })
        );
    };

    const _confirmSdsPopulation = () => {
        const confirmationMessage = `Please confirm the desired action is to replace the SDS Description and the Product Name fields with the selected Item data.`;
        _showConfirmModal(confirmationMessage, _afterConfirmSdsPopulation);
    };

    const _afterConfirmSdsPopulation = () => {
        populateSdsHeader();
        populateSdsIdentification();
        createSdsCasCompositions();
    };

    const autoPopulateSectionsWithItemData = async (
        itemCodeId: bigint
    ): Promise<void> => {
        if (itemCodeId) {
            const result: any = await dispatch(
                itemMasterApi.endpoints.getItemMaster.initiate(
                    itemCodeId.toString()
                )
            );
            const { data } = result;
            itemRecord = data as ItemMaster;

            if (
                !isNil(currentSdsHeader?.sdsDescription) ||
                !isNil(currentSdsHeader?.sdsIdentification?.productName)
            ) {
                if (
                    currentSdsHeader?.sdsDescription !== itemRecord.itemName ||
                    currentSdsHeader?.sdsIdentification?.productName !==
                        itemRecord.itemName
                ) {
                    _confirmSdsPopulation();
                }
            } else {
                populateSdsHeader();
                populateSdsIdentification();
                createSdsCasCompositions();
            }
        }
    };

    return {
        autoPopulateSectionsWithItemData,
    };
};

export default useAutoPopulateItemData;
