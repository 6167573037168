import usePeriodQuartersGrid from './hook/usePeriodQuartersGrid';
import React from 'react';
import { PeriodQuartersGridDefs } from '../../../../constants/i18n/translations/termDefinitions/accounting';
import { cloneDeep } from 'lodash';
import BaseFormGrid from '../../../../components/grids/BaseFormGrid';
import TranslatableText from '../../../../components/i18n/TranslatableText';

const PeriodQuartersGrid = () => {
    const {
        periodQuartersList,
        termSet,
        isLoading,
        permissions,
        colDefs,
        redirectToForm,
        handleGridEdits,
    } = usePeriodQuartersGrid();

    return (
        <BaseFormGrid
            autoSize={true}
            title={
                <>
                    <TranslatableText
                        termKey={PeriodQuartersGridDefs.Title}
                        termSet={termSet}
                    />
                </>
            }
            displayGrid={permissions.canView}
            isLoading={isLoading}
            rowData={cloneDeep(periodQuartersList)}
            useRowDrag={permissions.canUpdate}
            sortKey={'sortOrder'}
            columnDefs={colDefs}
            gridClass="full-size-item"
            displayToolbar={true}
            displayCreateNewButton={permissions.displayCreateNewButton}
            displayExportDataButton={true}
            displayResetStateButton={true}
            onCreateNew={redirectToForm}
            onRowClicked={({ data }) => redirectToForm(data)}
            onHandleGridEdits={handleGridEdits}
        />
    );
};

export default PeriodQuartersGrid;
