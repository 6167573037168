import React from 'react';
import { useParams } from 'react-router-dom';
import useItemMasterForm from './hooks/useItemMasterForm';
import BaseForm from '../../../../components/form/baseForm/BaseForm';
import '../../../../components/grids/style.css';
import ItemMasterPropertiesGrid from './sections/itemMasterProperties/ItemMasterPropertiesGrid';
import ItemChemicalCompositionGrid from './sections/itemChemicalComposition/ItemChemicalCompositionGrid';
import ItemAttributesSection from './sections/itemAttributes/ItemAttributesSection';
import ItemProperShippingNameForm from './sections/itemProperShippingName/ItemProperShippingNameForm';
import ItemUnitsOfMeasureForm from './sections/itemUnitsOfMeasure/ItemUnitsOfMeasureForm';
import ItemHeaderForm from './sections/itemHeader/itemHeaderForm';
import ItemCostForm from './sections/itemCost/itemCostForm';
import { ItemMasterFormDefs } from '../../../../constants/i18n/translations/termDefinitions/formulation';
import TranslatableText from '../../../../components/i18n/TranslatableText';
import { ITEM_FIELDS } from '../../../../constants/revisionHistory/revisionHistory.constants';

type ParamTypes = { id: string };

const ItemMasterForm = () => {
    const { id } = useParams<ParamTypes>();

    const {
        fields,
        hasEntityChanges,
        formMethods,
        childForms,
        statusOptions,
        inventoryTypesOptions,
        closeForm,
        onCreate,
        onUpdate,
        onDelete,
        copyForm,
        handleFieldChange,
        isLoadingItemMaster,
        isLoadingStatuses,
        isLoadingInventoryTypes,
        activeTemplates,
        isLoadingTemplates,
        itemAttrValues,
        setItemAttrValues,
        handleApplyTemplate,
        attrFieldsDisabled,
        formItemDefs,
        setIsAttributeValid,
        allFormMethods,
        display,
        children,
        copyMethods,
        unitOfMeasureOptions,
        isLoadingUnitOfMeasure,
        termSet,
    } = useItemMasterForm(id);

    const { canCreateItemMaster, canUpdateItemMaster, canDeleteItemMaster } =
        display;

    const { editableGridProps, childFormProps, extendedFormProps } = children;

    return (
        <BaseForm
            title={
                <TranslatableText
                    termSet={termSet}
                    termKey={ItemMasterFormDefs.Item_Master_Detail}
                />
            }
            formMethods={allFormMethods}
            isLoading={
                isLoadingItemMaster ||
                isLoadingInventoryTypes ||
                isLoadingStatuses
            }
            isNew={!id}
            hasEntityChanges={hasEntityChanges}
            onCreate={onCreate}
            onUpdate={onUpdate}
            onDelete={onDelete}
            onCancel={closeForm}
            onCopy={copyForm}
            displayCreateButton={canCreateItemMaster}
            displayUpdateButton={canUpdateItemMaster}
            displayDeleteButton={canDeleteItemMaster}
            displayCopyButton={canCreateItemMaster}
            readOnly={id && !canUpdateItemMaster}
            showOnRevision={true}
            fieldMappings={ITEM_FIELDS}
            entityUuid={fields?.uuid}
            childForms={childForms}>
            {/** Primary Information **/}
            <ItemHeaderForm
                {...extendedFormProps}
                termSet={termSet}
                statusOptions={statusOptions}
                isLoadingStatuses={isLoadingStatuses}
                inventoryTypesOptions={inventoryTypesOptions}
                isLoadingInventoryTypes={isLoadingInventoryTypes}
            />
            {/** Item Unit Of Measures Section **/}
            <ItemUnitsOfMeasureForm {...childFormProps} />
            {/** Item Cost Section **/}
            <ItemCostForm {...extendedFormProps} termSet={termSet} />
            {/** Item Properties Section **/}
            <ItemMasterPropertiesGrid
                {...editableGridProps}
                copyMethods={{
                    rowsData: copyMethods.itemProperties,
                    setRowsData: copyMethods.setItemProperties,
                }}
                unitOfMeasureOptions={unitOfMeasureOptions}
                isLoadingUnitOfMeasure={isLoadingUnitOfMeasure}
            />
            {/** Item Chemical Composition Section **/}
            <ItemChemicalCompositionGrid
                {...editableGridProps}
                copyMethods={{
                    rowsData: copyMethods.chemicalComposition,
                    setRowsData: copyMethods.setChemicalComposition,
                }}
            />
            {/** Item Proper Shipping Name Section **/}
            <ItemProperShippingNameForm {...childFormProps} />
            {/** Item Attributes Section **/}
            <ItemAttributesSection
                id={id}
                fields={fields}
                handleFieldChange={handleFieldChange}
                formMethods={formMethods}
                activeTemplates={activeTemplates}
                isLoadingTemplates={isLoadingTemplates}
                attrFieldsDisabled={attrFieldsDisabled}
                canUpdateItemMaster={canUpdateItemMaster}
                handleApplyTemplate={handleApplyTemplate}
                formItemDefs={formItemDefs}
                itemAttrValues={itemAttrValues}
                setItemAttrValues={setItemAttrValues}
                setIsAttributeValid={setIsAttributeValid}
            />
        </BaseForm>
    );
};

export default ItemMasterForm;
