import React from 'react';
import BaseFormGrid from '../../../components/grids/BaseFormGrid';
import TranslatableText from '../../../components/i18n/TranslatableText';
import useFiscalYearGrid from './hooks/useFiscalYearGrid';
import booleanCellRenderer from '../../../components/grids/cellRenderers/booleanCell.renderer';
import { FiscalYearGridDefs } from '../../../constants/i18n/translations/termDefinitions/accounting';

const FiscalYearGrid = () => {
    const {
        canView,
        canCreate,
        termSet,
        rowData,
        colDefs,
        isLoading,
        isLoadingTermSet,
        redirectToForm,
    } = useFiscalYearGrid();

    return (
        <BaseFormGrid
            title={
                (
                    <TranslatableText
                        termKey={FiscalYearGridDefs.Title}
                        termSet={termSet}
                    />
                ) as unknown as string
            }
            displayGrid={canView}
            isLoading={isLoading && isLoadingTermSet}
            rowData={rowData}
            useRowDrag={false}
            sortKey={'number'}
            columnDefs={colDefs}
            gridClass="full-size-item"
            displayToolbar={true}
            displayCreateNewButton={canCreate}
            displayExportDataButton={canView}
            displayResetStateButton={true}
            onCreateNew={redirectToForm}
            onRowClicked={({ data }) => redirectToForm(data)}
            frameworkComponents={{
                booleanCellRenderer: booleanCellRenderer,
            }}
        />
    );
};

export default FiscalYearGrid;
