import React from 'react';
import BoilerPlateNoteGrid from './pages/BoilerPlateNoteGrid';

const BoilerPlateNote = () => {
    return (
        <>
            <BoilerPlateNoteGrid />
        </>
    );
};

export default BoilerPlateNote;
