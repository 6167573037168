import React from 'react';
import { useParams } from 'react-router-dom';
import BaseForm, {
    ParamTypes,
} from '../../../components/form/baseForm/BaseForm';
import TranslatableText from '../../../components/i18n/TranslatableText';
import { RoutingValues } from '../../../types/Shared.types';
import FormPartial from '../../../components/form/formContainer/FormPartial';
import { Grid } from '@mui/material';
import useFiscalYearForm from './hooks/useFiscalYearForm';
import { FiscalYearFormDefs } from '../../../constants/i18n/translations/termDefinitions/accounting';
import TextInput from '../../../components/form/formInputs/TextInput/TextInput';
import SelectInput from '../../../components/form/formInputs/SelectInput/SelectInput';
import { FiscalYearRevisionHistoryFields } from '../../../constants/revisionHistory/accountingAppRevisionHistory';
import DateInput from '../../../components/form/formInputs/DateInput/DateInput';

const FiscalYearForm = () => {
    const { id } = useParams<ParamTypes>();
    const {
        fields,
        handleFieldChange,
        formMethods,
        onCreate,
        onUpdate,
        onDelete,
        copyForm,
        closeForm,
        isLoading,
        isLoadingTermSet,
        termSet,
        canCreate,
        canUpdate,
        canDelete,
        showCopyButton,
        auditableFields,
        statusOptions,
        isLoadingStatuses,
        canAdjustStartDate,
    } = useFiscalYearForm(id);

    return (
        <BaseForm
            title={
                <>
                    <TranslatableText
                        termKey={FiscalYearFormDefs.Title}
                        termSet={termSet}
                    />{' '}
                    {fields?.code}
                </>
            }
            formMethods={formMethods}
            isLoading={isLoading && isLoadingTermSet}
            isNew={id === RoutingValues.newId}
            onCreate={onCreate}
            onUpdate={onUpdate}
            onDelete={onDelete}
            onCopy={copyForm}
            onCancel={closeForm}
            showOnRevision={true}
            fieldMappings={FiscalYearRevisionHistoryFields}
            entityUuid={fields?.uuid}
            displayCreateButton={canCreate}
            displayUpdateButton={canUpdate}
            displayDeleteButton={canDelete}
            displayCopyButton={showCopyButton}
            auditableFields={auditableFields}>
            <FormPartial>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <TextInput
                            fullWidth
                            label={
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={FiscalYearFormDefs.Number}
                                />
                            }
                            id="number"
                            value={fields.number}
                            onChange={handleFieldChange}
                            formMethods={formMethods}
                            required={true}
                            maxLength={30}
                            disabled={id !== RoutingValues.newId}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextInput
                            fullWidth
                            label={
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={FiscalYearFormDefs.Code}
                                />
                            }
                            id="code"
                            value={fields.code}
                            onChange={handleFieldChange}
                            formMethods={formMethods}
                            required={true}
                            maxLength={30}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <SelectInput
                            fullWidth
                            label={
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={FiscalYearFormDefs.Status}
                                />
                            }
                            id="fiscalYearStatusId"
                            value={fields.fiscalYearStatusId}
                            onChange={handleFieldChange}
                            formMethods={formMethods}
                            options={statusOptions}
                            disabled={isLoadingStatuses}
                            required={true}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <DateInput
                            label={
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={FiscalYearFormDefs.StartDate}
                                />
                            }
                            id="startDate"
                            value={fields.startDate}
                            onChange={handleFieldChange}
                            formMethods={formMethods}
                            required={true}
                            disabled={!canAdjustStartDate}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <DateInput
                            label={
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={FiscalYearFormDefs.EndDate}
                                />
                            }
                            id="endDate"
                            value={fields.endDate}
                            onChange={handleFieldChange}
                            formMethods={formMethods}
                            minDate={fields.startDate}
                            disabled={id !== RoutingValues.newId}
                        />
                    </Grid>
                </Grid>
            </FormPartial>
        </BaseForm>
    );
};

export default FiscalYearForm;
