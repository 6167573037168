import React from 'react';
import {
    Regulation,
    SdsHeader,
    SdsRegulation,
} from '../../../../../../../../types/formulation';
import { isNilOrEmpty } from '../../../../../../../../utils/objectUtils';
import DatacorLogoSpinner from '../../../../../../../../components/datacorLogoSpinner/DatacorLogoSpinner';
import BaseGrid from '../../../../../../../../components/grids/BaseGrid';
import useSdsRegulationsGrid from './hooks/useSdsRegulationsGrid';
import FormPartial from '../../../../../../../../components/form/formContainer/FormPartial';
import RegulationsSelectionModal from '../../../../../../shared/multiSelectModals/regulations/RegulationsSelectionModal';
import TranslatableText from '../../../../../../../../components/i18n/TranslatableText';
import { SDSFormDefs } from '../../../../../../../../constants/i18n/translations/termDefinitions/formulation';
import { ChildEditableGridWithCopyFunctionProps } from '../../../../../../../grid/utils/editableGrid/ChildEditableGridWithCopyFunctionProps';

const SdsRegulationsGrid = (
    props: ChildEditableGridWithCopyFunctionProps<SdsHeader, SdsRegulation>
) => {
    const {
        handleSaveGridEdits,
        rowsData,
        regulationList,
        showMultiSelectGridModal,
        handleSelectedRecords,
        getStateById,
        sdsRegulationsColumnDefs,
        handleRowValidations,
        colDefOptions,
        handleSetShowMultiSelectGridModal,
        termSet,
    } = useSdsRegulationsGrid(props);

    const { isParentLoading } = props.parentData;

    const { showAddButton, showDeleteButton, showSortButton, isGridEditable } =
        props.displayGridButtons;

    return (
        <React.Fragment>
            {isParentLoading ? (
                <DatacorLogoSpinner />
            ) : !isParentLoading && isNilOrEmpty(sdsRegulationsColumnDefs) ? (
                <div className="spinnerContainer">Setup Data Unavailable</div>
            ) : (
                <>
                    <FormPartial
                        title={
                            (
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={
                                        SDSFormDefs.Safety_Health_Environmental
                                    }
                                />
                            ) as unknown as string
                        }>
                        <RegulationsSelectionModal
                            openModal={showMultiSelectGridModal}
                            onClose={() =>
                                handleSetShowMultiSelectGridModal(false)
                            }
                            onSelected={(selectedRecords: Regulation[]) =>
                                handleSelectedRecords(selectedRecords)
                            }
                            getStateCode={getStateById}
                            regulationList={regulationList || []}
                        />
                        <BaseGrid
                            autoSize={true}
                            displayGrid={true}
                            displayCreateNewButton={showAddButton}
                            useRowDrag={showSortButton}
                            displaySortButton={showSortButton}
                            sortKey={'sortBy'}
                            rowData={rowsData || ([] as SdsRegulation[])}
                            dataKey={'rowId'}
                            gridClass="full-width-grid"
                            displayToolbar={true}
                            sizeColumnsToFit={true}
                            onHandleGridEdits={handleSaveGridEdits}
                            onCreateNew={() =>
                                handleSetShowMultiSelectGridModal(true)
                            }
                            isEditable={isGridEditable}
                            columnDefs={sdsRegulationsColumnDefs}
                            displayDeleteButton={showDeleteButton}
                            gridButtonTitle={'Regulations'}
                            createRow={false}
                            onHandleRowValidations={handleRowValidations}
                            newRowPlaceholderValues={{
                                casNumber: 'Enter a CAS Number...',
                                exposureAgencyCode: 'Enter an Agency Code...',
                                exposureValue: 'Enter an Exposure Value...',
                                exposureForm: 'Enter an Exposure Form...',
                                notes: 'Enter Exposure Notes...',
                            }}
                            defaultColDef={colDefOptions}></BaseGrid>
                    </FormPartial>
                </>
            )}
        </React.Fragment>
    );
};

export default SdsRegulationsGrid;
