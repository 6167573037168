import { useState } from 'react';
import {
    useCreateFixedAssetDepreciationEntriesMutation,
    useUpdateDepreciationEntriesMutation,
} from '../../../../../services/fixedAssets/fixedAssets.service';
import { CreateDepreciationEntriesProps } from '../FixedAssetCreateDepreciationEntriesModal';
import { PermissionsUtil } from '../../../../../utils/permissions/permissionsUtil';
import { PERMISSIONS } from '../../../../../constants/permissions/Permissions.constants';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../store';
import { FA_Asset } from '../../../../../types/FixedAsset.types';

const useFixedAssetCreateDepreciationEntriesModal = (
    props: CreateDepreciationEntriesProps
) => {
    const user = useSelector((state: RootState) => state.user);

    const [lockEntries, setLockEntries] = useState<boolean>(false);

    const [
        createDepreciationEntries,
        { isLoading: isCreatingDepreciationEntries },
    ] = useCreateFixedAssetDepreciationEntriesMutation();

    const [
        updateDepreciationEntries,
        { isLoading: isUpdatingDepreciationEntries },
    ] = useUpdateDepreciationEntriesMutation();

    const canLockDepreciationEntries = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.FIXED_ASSETS.DEPRECIATION_ENTRIES.LOCK
    );

    const handleGenerateEntries = async (event: any) => {
        event.preventDefault();

        try {
            let response: any;
            if (props.isUpdate) {
                response = await updateDepreciationEntries({
                    ids: props.ids,
                    lockIntervals: lockEntries,
                });
            } else {
                response = await createDepreciationEntries({
                    ids: props.ids,
                    lockIntervals: lockEntries,
                });
            }

            const responseAsset: FA_Asset = response.data[0];
            props.onEntriesCreated(responseAsset.depreciationEntries);

            props.onClose();
        } catch (e) {
            console.log(e);
        }
    };

    const isLoading = () =>
        props.isUpdate
            ? isUpdatingDepreciationEntries
            : isCreatingDepreciationEntries;

    return {
        lockEntries,
        setLockEntries,
        isLoading,
        handleGenerateEntries,
        canLockDepreciationEntries,
    };
};

export default useFixedAssetCreateDepreciationEntriesModal;
