import { PropertyType } from '../../../../types/formulation';
import { datacorAppApi } from '../../../datacorAppApi';

const serviceUrl: string = 'propertyType';

export const propertyTypeApi = datacorAppApi.injectEndpoints({
    endpoints: (build) => ({
        getPropertyTypesForDropdown: build.query<
            { label: string; value: bigint }[],
            void
        >({
            query: () => serviceUrl,
            transformResponse: (response: PropertyType[]) => {
                return response.map((propType: PropertyType) => {
                    return {
                        label: propType.type,
                        value: propType.id,
                    };
                });
            },
            providesTags: ['PropertyType'],
        }),
    }),
});

export const { useGetPropertyTypesForDropdownQuery } = propertyTypeApi;
