import React, { useContext, useEffect, useState } from 'react';
import SettingsContext from '../../../../../../contexts/settings.context';
import { useGetTermSetQuery } from '../../../../../../services/i18n/i18n.service';
import { skipToken } from '@reduxjs/toolkit/query';
import { useForm } from '../../../../../../libs/hooksLib';
import {
    ItemChemicalComposition,
    ItemMaster,
    RDFormula,
} from '../../../../../../types/formulation';
import {
    useCreateItemMasterMutation,
    useGetItemMasterQuery,
    useListItemsMasterDropdownQuery,
    useUpdateItemMasterMutation,
} from '../../../../../../services/formulation/itemMaster/item/itemMaster.service';
import { FORMULATION_DEFS } from '../../../../../../constants/i18n/translations/termSetDefinitions/formulation';
import { useGetItemStatusesForDropdownQuery } from '../../../../../../services/formulation/itemMaster/itemStatus/itemStatus.service';
import {
    openModalConfirmBasicWithMessage,
    openSnackbarBasicWithMessage,
} from '../../../../../../store/uiElements';
import { useDispatch } from 'react-redux';
import { useGetInventoryTypesForDropdownQuery } from '../../../../../../services/formulation/itemMaster/inventoryType/inventoryType.services';
import {
    MutationError,
    RoutingValues,
    SelectionOption,
} from '../../../../../../types/Shared.types';
import { AlertColor } from '@mui/material';
import _ from 'lodash';
import { useUpdateFormulaMutation } from '../../../../../../services/formulation/rdFormula/rdFormulaHeader/rdFormula.service';
import TranslatableText from '../../../../../../components/i18n/TranslatableText';
import { RDFormulasFormDefs } from '../../../../../../constants/i18n/translations/termDefinitions/formulation';

const useRDFormulaPublishToProductModal = (
    onClose: () => void,
    rdFormulaRecord: RDFormula
) => {
    const initialForm: any = {
        newItem: true,
        itemCodeId: null,
        itemStatusId: null,
        itemName: null,
        inventoryTypeId: null,
        newItemCode: null,
        specificGravity: true,
        chemicalIdentifiers: true,
        productProperties: true,
        cost: true,
        calcSpecificGravity: null,
        totalNetCost: null,
        uomId: null,
    };

    const initialState: any = {
        selectedItem: null,
    };

    const dispatch = useDispatch();
    const [createItemMaster] = useCreateItemMasterMutation();
    const [updateItemMaster] = useUpdateItemMasterMutation();
    const [updateFormula] = useUpdateFormulaMutation();
    const [itemMasterCodeList, setItemMasterCodeList] = useState([]);
    const [selectedItemMasterId, setSelectedItemMasterId] = useState(null);
    const [dropdownOptions, setDropdownOptions] = useState(initialState);

    const [isSavingItemRecord, setIsSavingItemRecord] = useState(false);

    const { data: itemMasterOptions, isLoading: isLoadingItemMasterOptions } =
        useListItemsMasterDropdownQuery();

    const { data: statusOptions, isLoading: isLoadingStatuses } =
        useGetItemStatusesForDropdownQuery();

    const { data: inventoryTypesOptions, isLoading: isLoadingInventoryTypes } =
        useGetInventoryTypesForDropdownQuery();

    const { data: itemMasterRecord, isLoading: isLoadingItemMaster } =
        useGetItemMasterQuery(
            selectedItemMasterId === RoutingValues.newId
                ? skipToken
                : selectedItemMasterId
        );

    const { settings } = useContext(SettingsContext);
    const { data: termSet } = useGetTermSetQuery(
        settings?.userSettings
            ? {
                  languageId: settings?.userSettings?.languageId,
                  code: FORMULATION_DEFS.RD_FORMULAS_FORM,
              }
            : skipToken
    );

    const { fields, handleFieldChange, formMethods, setValues } =
        useForm(initialForm);

    const buildItemChemicalCompositions = (): ItemChemicalComposition[] => {
        const newCasCompositions: ItemChemicalComposition[] = [];
        _.forEach(rdFormulaRecord.casCompositions, (rdCasComp) => {
            const casComposition: ItemChemicalComposition = {
                casId: rdCasComp.casId,
                lowerValue: rdCasComp.lowerPercentage,
                upperValue: rdCasComp.upperPercentage,
                sortOrder: rdCasComp.sortOrder,
                chemicalName: rdCasComp.chemicalName,
                classification: rdCasComp.classification,
                ecIdentifier: rdCasComp.ecIdentifier,
                reach: rdCasComp.reachNumber,
            };
            newCasCompositions.push(casComposition);
        });
        return newCasCompositions;
    };

    const buildUpdatedItemRecord = (): ItemMaster => {
        let updatedItemRecord: ItemMaster = _.cloneDeep(itemMasterRecord);

        if (updatedItemRecord.itemUnitOfMeasure) {
            updatedItemRecord.itemUnitOfMeasure.stockUnitId = fields.uomId;
        } else {
            updatedItemRecord.itemUnitOfMeasure = {
                stockUnitId: fields.uomId,
            };
        }

        if (fields.specificGravity) {
            updatedItemRecord.itemUnitOfMeasure.specificGravity =
                fields.calcSpecificGravity;
        }

        if (fields.chemicalIdentifiers) {
            updatedItemRecord.chemicalComposition =
                buildItemChemicalCompositions();
        }

        if (fields.cost) {
            updatedItemRecord.cost = fields.totalNetCost;
        }
        return updatedItemRecord;
    };

    const buildNewItemRecord = (): ItemMaster => {
        const newItemRecord: ItemMaster = {
            itemId: fields.newItemCode,
            itemStatusId: fields.itemStatusId,
            inventoryTypeId: fields.inventoryTypeId,
            itemName: fields.itemName,
            itemUnitOfMeasure: {
                stockUnitId: fields.uomId,
                specificGravity: null,
            },
        };

        if (fields.specificGravity) {
            newItemRecord.itemUnitOfMeasure.specificGravity =
                fields.calcSpecificGravity;
        }

        if (fields.chemicalIdentifiers) {
            newItemRecord.chemicalComposition = buildItemChemicalCompositions();
        }

        if (fields.cost) {
            newItemRecord.cost = fields.totalNetCost;
        }
        return newItemRecord;
    };

    const createItemRecord = (updateRdFormulaItem: boolean) => {
        setIsSavingItemRecord(true);
        const newItemRecord: ItemMaster = buildNewItemRecord();
        createItemMaster({ postBody: newItemRecord })
            .unwrap()
            .then((response) => {
                showNotification(
                    `${fields.newItemCode} was successfully added`,
                    'success'
                );
                updateRdFormula(response, updateRdFormulaItem);
                closeModal();
            })
            .catch((error: MutationError) => {
                handleError(error);
                closeModal();
            });
    };

    const updateRdFormula = (
        response: ItemMaster,
        updateRdFormulaItem: boolean
    ) => {
        if (updateRdFormulaItem) {
            const updatedRdFormula = _.cloneDeep(rdFormulaRecord);
            updatedRdFormula.itemId = response.id;
            updateFormula({
                id: updatedRdFormula.id as unknown as number,
                postBody: updatedRdFormula,
            })
                .unwrap()
                .then((response) => {
                    showNotification(
                        `${response.formulaCode} was successfully updated`,
                        'success'
                    );
                })
                .catch((error: MutationError) => {
                    handleError(error);
                });
        }
    };

    const updateItemRecord = (updateRdFormulaItem: boolean) => {
        setIsSavingItemRecord(true);
        const updatedItemRecord: ItemMaster = buildUpdatedItemRecord();
        updateItemMaster({
            id: fields.itemCodeId as unknown as number,
            postBody: updatedItemRecord,
        })
            .unwrap()
            .then((response) => {
                showNotification(
                    `${updatedItemRecord.itemId} was successfully updated`,
                    'success'
                );
                updateRdFormula(response, updateRdFormulaItem);
                closeModal();
            })
            .catch((error: MutationError) => {
                handleError(error);
                closeModal();
            });
    };

    const handleError = (error: MutationError) => {
        if (error.status === 400) {
            const errorMessage = error.data
                .map((error: any) => {
                    return error.message;
                })
                .join('\r\n');
            showNotification(errorMessage, 'error');
        } else {
            showNotification(
                `An unexpected error occurred: ${error.data?.toString()}`,
                'error'
            );
        }
    };

    const showNotification = (message: string, severity: AlertColor) => {
        dispatch(
            openSnackbarBasicWithMessage({
                message: message,
                severity: severity,
            })
        );
    };

    const resetForm = () => {
        if (rdFormulaRecord.itemId) {
            setValues({
                ...initialForm,
                newItem: false,
                itemCodeId: rdFormulaRecord.itemId,
                itemName: rdFormulaRecord.formulaDescription,
                calcSpecificGravity:
                    rdFormulaRecord?.formulaTotals?.calcSpecificGravity,
                totalNetCost: rdFormulaRecord?.formulaTotals?.totalNetCost,
                uomId: rdFormulaRecord?.formulaTotals?.formulaUomId,
            });
        } else {
            setValues({
                ...initialForm,
                itemCodeId: rdFormulaRecord.itemId,
                itemName: rdFormulaRecord.formulaDescription,
                calcSpecificGravity:
                    rdFormulaRecord?.formulaTotals?.calcSpecificGravity,
                totalNetCost: rdFormulaRecord?.formulaTotals?.totalNetCost,
                uomId: rdFormulaRecord?.formulaTotals?.formulaUomId,
            });
        }
        formMethods.setIsFormSubmitted(false);
        formMethods.isFormValid = true;
    };

    const closeModal = () => {
        onClose();
        resetForm();
        setIsSavingItemRecord(false);
    };

    const submitForm = (event: any) => {
        formMethods.setIsFormSubmitted(true);
        if (formMethods.isFormValid) {
            dispatch(
                openModalConfirmBasicWithMessage({
                    message: (
                        <TranslatableText
                            termSet={termSet}
                            termKey={
                                RDFormulasFormDefs.Publish_To_Product_Modal
                            }
                        />
                    ) as unknown as string,
                    title: '',
                    onConfirm: () => {
                        fields.newItem
                            ? createItemRecord(true)
                            : updateItemRecord(true);
                    },
                    onCancel: () => {
                        fields.newItem
                            ? createItemRecord(false)
                            : updateItemRecord(false);
                    },
                    confirmButtonText: (
                        <TranslatableText
                            termSet={termSet}
                            termKey={RDFormulasFormDefs.Publish_To_Product_Yes}
                        />
                    ) as unknown as string,
                    declineButtonText: (
                        <TranslatableText
                            termSet={termSet}
                            termKey={RDFormulasFormDefs.Publish_To_Product_No}
                        />
                    ) as unknown as string,
                })
            );
        }
    };

    /**
     * when user changes selected item set the selectedItemMasterId
     * this triggers a new itemMasterRecord to be fetched
     */
    useEffect(() => {
        if (fields.itemCodeId !== selectedItemMasterId) {
            setSelectedItemMasterId(fields.itemCodeId);
        }
    }, [fields.itemCodeId]);

    /**
     * If itemId exists already, set the newItem to false
     * sets values from rdFormula to fields
     */
    useEffect(() => {
        if (rdFormulaRecord.itemId) {
            setSelectedItemMasterId(rdFormulaRecord.itemId);
            setValues({
                newItem: false,
                itemCodeId: rdFormulaRecord.itemId,
                itemName: rdFormulaRecord.formulaDescription,
                calcSpecificGravity:
                    rdFormulaRecord?.formulaTotals?.calcSpecificGravity,
                totalNetCost: rdFormulaRecord?.formulaTotals?.totalNetCost,
                uomId: rdFormulaRecord?.formulaTotals?.formulaUomId,
            });
        } else {
            setValues({
                itemName: rdFormulaRecord.formulaDescription,
                calcSpecificGravity:
                    rdFormulaRecord?.formulaTotals?.calcSpecificGravity,
                totalNetCost: rdFormulaRecord?.formulaTotals?.totalNetCost,
                uomId: rdFormulaRecord?.formulaTotals?.formulaUomId,
            });
        }
    }, [rdFormulaRecord.itemId, rdFormulaRecord.formulaDescription]);

    /**
     * take the itemMasterOptions and create a list of just the item codes
     * used for validation of new item code
     */
    useEffect(() => {
        if (itemMasterOptions) {
            let newItemMasterCodeList: string[] = [];
            itemMasterOptions.forEach((item: SelectionOption) => {
                newItemMasterCodeList.push(item.label);
            });
            setItemMasterCodeList(newItemMasterCodeList);
        }
    }, [itemMasterOptions]);

    /**
     * Set the labels on all the dropdowns
     */
    useEffect(() => {
        if (!isLoadingItemMasterOptions && itemMasterOptions) {
            setDropdownOptions((previousValues: any) => ({
                ...previousValues,
                selectedItem: itemMasterOptions?.find(
                    (current: any) => current.value === fields.itemCodeId
                ),
            }));
        }
    }, [itemMasterOptions, isLoadingItemMasterOptions, fields]);

    return {
        fields,
        handleFieldChange,
        submitForm,
        termSet,
        formMethods,
        itemMasterOptions,
        dropdownOptions,
        statusOptions,
        inventoryTypesOptions,
        itemMasterCodeList,
        closeModal,
        isSavingItemRecord,
    };
};

export default useRDFormulaPublishToProductModal;
