import { FixedAssetCreateScheduleProps } from '../FixedAssetCreateScheduleStepper';
import React, { useEffect, useState } from 'react';
import {
    useCreateFixedAssetScheduleMutation,
    useUpdateDepreciationSchedulesMutation,
} from '../../../../../services/fixedAssets/fixedAssets.service';
import { useForm } from '../../../../../libs/hooksLib';
import { PermissionsUtil } from '../../../../../utils/permissions/permissionsUtil';
import { PERMISSIONS } from '../../../../../constants/permissions/Permissions.constants';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../store';
import { FA_Asset } from '../../../../../types/FixedAsset.types';

export const useFixedAssetCreateScheduleStepper = (
    props: FixedAssetCreateScheduleProps
) => {
    const user = useSelector((state: RootState) => state.user);

    const [activeStep, setActiveStep] = useState<number>(0);
    const [
        createDepreciationSchedules,
        { isLoading: isCreatingDepreciationSchedules },
    ] = useCreateFixedAssetScheduleMutation();
    const [
        updateDepreciationSchedules,
        { isLoading: isUpdatingDepreciationSchedules },
    ] = useUpdateDepreciationSchedulesMutation();

    const canLockDepreciationSchedules = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.FIXED_ASSETS.DEPRECIATION_SCHEDULES.LOCK
    );

    const initialFields: any = {
        ledgerSchedule: false,
        taxSchedule: false,
        startDate: null,
        lockIntervals: false,
    };

    useEffect(() => {
        setValues({ startDate: props.startDate });
    }, [props.startDate]);

    const { fields, handleFieldChange, setValues } = useForm(initialFields);

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handlePrevious = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleGenerateSchedules = async (
        event: React.ChangeEvent<HTMLFormElement>
    ) => {
        event.preventDefault();

        try {
            let response: any;
            if (props.isUpdate) {
                response = await updateDepreciationSchedules({
                    ids: props.ids,
                    book: fields.ledgerSchedule,
                    tax: fields.taxSchedule,
                    startDate: fields.startDate,
                    lockIntervals: fields.lockIntervals,
                });
            } else {
                response = await createDepreciationSchedules({
                    ids: props.ids,
                    book: fields.ledgerSchedule,
                    tax: fields.taxSchedule,
                    startDate: fields.startDate,
                    lockIntervals: fields.lockIntervals,
                });
            }

            const responseAsset: FA_Asset = response.data[0];

            fields.ledgerSchedule &&
                props.onLedgerScheduleUpdated(
                    responseAsset.ledgerDepreciationSchedule
                );
            fields.taxSchedule &&
                props.onTaxScheduleUpdated(
                    responseAsset.taxDepreciationSchedule
                );
            props.onClose();
        } catch (e) {
            console.log(e);
        }
    };

    const isLoading = () =>
        props.isUpdate
            ? isUpdatingDepreciationSchedules
            : isCreatingDepreciationSchedules;

    return {
        activeStep,
        fields,
        handleFieldChange,
        handleNext,
        handlePrevious,
        isLoading,
        handleGenerateSchedules,
        canLockDepreciationSchedules,
    };
};
