import { useContext, useEffect, useState } from 'react';
import { RootStateOrAny, useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { skipToken } from '@reduxjs/toolkit/query';
import { GridReadyEvent, GridApi, ColumnApi } from 'ag-grid-community';
import { RootState } from '../../../../../store';
import { updateGridModels } from '../../../../../store/grids';
import { GhsPrecautionaryPhrase } from '../../../../../types/formulation';
import { PermissionsUtil } from '../../../../../utils/permissions/permissionsUtil';
import { PERMISSIONS } from '../../../../../constants/permissions/Permissions.constants';
import { useGetPrecautionaryPhrasesByLangIdQuery } from '../../../../../services/formulation/precautionaryPhrases/precautionaryPhrases.service';
import SettingsContext from '../../../../../contexts/settings.context';
import { useGetTermSetQuery } from '../../../../../services/i18n/i18n.service';
import { FORMULATION_DEFS } from '../../../../../constants/i18n/translations/termSetDefinitions/formulation';

export interface PrecautionaryGridProps {
    handleSetPhraseList(phraseList: Array<GhsPrecautionaryPhrase>): void;
    defaultLanguageId: bigint;
    currentPhraseId: string;
    handlePromptFormDirty(onConfirm: () => void, onCancel?: () => void): void;
}

const useGhsPrecautionaryPhraseGrid = ({
    handleSetPhraseList,
    defaultLanguageId,
    currentPhraseId,
    handlePromptFormDirty,
}: PrecautionaryGridProps) => {
    const user = useSelector((state: RootStateOrAny) => state.user);
    const { formulation } = useSelector((state: RootState) => state.grids);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isLoadingData, setIsLoadingData] = useState(true);
    const [gridApi, setGridApi] = useState(null as GridApi);
    const [gridColumnApi, setGridColumnApi] = useState(null as ColumnApi);

    const canViewGhsPrecautionaryPhrase = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.FORMULATION.GHS_PRECAUTIONARY_PHRASE.VIEW
    );

    const canCreateGhsPrecautionaryPhrase = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.FORMULATION.GHS_PRECAUTIONARY_PHRASE.CREATE
    );

    const {
        data: precautionaryPhraseList,
        isLoading: isLoadingPrecautionaryPhraseList,
    } = useGetPrecautionaryPhrasesByLangIdQuery(
        defaultLanguageId
            ? {
                  langId: defaultLanguageId.toString(),
              }
            : skipToken
    );

    const { settings } = useContext(SettingsContext);
    const { data: termSet } = useGetTermSetQuery(
        settings?.userSettings
            ? {
                  languageId: settings?.userSettings?.languageId,
                  code: FORMULATION_DEFS.GHS_PRECAUTIONARY_PHRASES_GRID,
              }
            : skipToken
    );

    useEffect(() => {
        setIsLoadingData(isLoadingPrecautionaryPhraseList);
        if (precautionaryPhraseList) {
            handleSetPhraseList(precautionaryPhraseList);
        }
    }, [precautionaryPhraseList, isLoadingPrecautionaryPhraseList]);

    useEffect(() => {
        selectRowNode(currentPhraseId);
    }, [currentPhraseId, gridApi]);

    const selectRowNode = (phraseId: string) => {
        if (gridApi && phraseId) {
            const node = gridApi.getRowNode(phraseId);
            if (node && !node.isSelected()) {
                node.setSelected(true);
            }
        }
    };

    const handleSelectedRowChange = (
        selectedPhrase: GhsPrecautionaryPhrase
    ) => {
        if (selectedPhrase) {
            handlePromptFormDirty(
                () => {
                    navigate(
                        `/formulation/ghsPrecautionaryPhrases/${selectedPhrase.phraseId}`
                    );
                },
                () => {
                    if (currentPhraseId) {
                        selectRowNode(currentPhraseId);
                    } else if (gridApi) {
                        gridApi.deselectAll();
                    }
                }
            );
        }
    };

    const handleAddNewPhrase = () => {
        handlePromptFormDirty(() => {
            navigate('/formulation/ghsPrecautionaryPhrases/new');
            if (gridApi) {
                gridApi.deselectAll();
            }
        });
    };

    const onGridReady = (params: GridReadyEvent) => {
        setGridApi(params.api);
        setGridColumnApi(params.columnApi);
    };

    const handleRowDataChanged = () => {
        selectRowNode(currentPhraseId);
    };

    const onFirstDataRendered = () => {
        applyGridState();
    };

    const applyGridState = () => {
        if (formulation.ghsPrecautionaryPhrases.column && gridColumnApi) {
            gridColumnApi.applyColumnState({
                state: formulation.ghsPrecautionaryPhrases.column,
                applyOrder: true,
            });
        }
        if (formulation.ghsPrecautionaryPhrases.filter && gridApi) {
            gridApi.setFilterModel(formulation.ghsPrecautionaryPhrases.filter);
        }
        if (currentPhraseId) {
            selectRowNode(currentPhraseId);
        }
    };

    const onSortChanged = () => {
        dispatch(
            updateGridModels({
                gridLocation: 'formulation',
                gridName: 'ghsPrecautionaryPhrases',
                type: 'column',
                model: gridColumnApi.getColumnState(),
            })
        );
    };

    const onFilterChanged = () => {
        dispatch(
            updateGridModels({
                gridLocation: 'formulation',
                gridName: 'ghsPrecautionaryPhrases',
                type: 'filter',
                model: gridApi.getFilterModel(),
            })
        );
    };

    return {
        precautionaryPhraseList,
        isLoadingData,
        handleSelectedRowChange,
        handleAddNewPhrase,
        onGridReady,
        onFirstDataRendered,
        handleRowDataChanged,
        onFilterChanged,
        onSortChanged,
        canViewGhsPrecautionaryPhrase,
        canCreateGhsPrecautionaryPhrase,
        termSet,
    };
};

export default useGhsPrecautionaryPhraseGrid;
