import React, { useEffect, useMemo, useRef, useState } from 'react';
import { FA_DepreciationEntry } from '../../../../types/FixedAsset.types';
import DefaultColumnTypes from '../../../../components/grids/columns/Column.constants';
import { ColDef } from 'ag-grid-community';
import {
    currencyFormatter,
    dateFormatter,
} from '../../../../utils/formattingUtils';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import { PermissionsUtil } from '../../../../utils/permissions/permissionsUtil';
import { PERMISSIONS } from '../../../../constants/permissions/Permissions.constants';
import useTranslation from '../../../../components/i18n/hooks/useTranslation';
import { ACCOUNTING_DEFS } from '../../../../constants/i18n/translations/termSetDefinitions/accounting';
import { DepreciationDetailGridDefs } from '../../../../constants/i18n/translations/termDefinitions/accounting';
import TranslatableText from '../../../../components/i18n/TranslatableText';

export class DepreciationEntryRow {
    public readonly id: string;
    public readonly number: number;
    public readonly code: string;
    public readonly name: string;
    public readonly depreciationMethod: string;
    public readonly entries: FA_DepreciationEntry[] = [];

    constructor(entry: FA_DepreciationEntry) {
        this.id = `${entry.fixedAsset.id}`;
        this.number = entry.fixedAsset.number;
        this.code = entry.fixedAsset.code;
        this.name = entry.fixedAsset.name;
        this.depreciationMethod = entry.assetType.depreciationMethod.code;
        this.addEntry(entry);
    }

    public addEntry = (entry: FA_DepreciationEntry) => {
        this.entries.push(entry);
    };

    public get totalExpenses(): string {
        return currencyFormatter(
            this.entries.reduce((total, obj) => total + Number(obj.debit), 0)
        );
    }

    public get totalNumbers(): number {
        return this.entries.reduce(
            (total, obj) => total + Number(obj.debit),
            0
        );
    }
}

const useDepreciationDetailGrid = () => {
    const user = useSelector((state: RootState) => state.user);
    const canViewDepreciationDetailReport = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.FIXED_ASSETS.REPORTS.DEPRECIATION_DETAIL.VIEW
    );

    const { termSet } = useTranslation(
        ACCOUNTING_DEFS.DEPRECIATION_DETAIL_GRID
    );

    const [columnDef, setColumnDef] = useState<ColDef[]>([]);
    const [showOptionsModal, setShowOptionsModal] = useState<boolean>(true);
    const [depreciationEntryList, setDepreciationEntryList] = useState<
        FA_DepreciationEntry[]
    >([]);
    const [depreciationDetailRows, setDepreciationDetailRows] = useState<any[]>(
        []
    );
    const intervalColumns = useRef([]);

    const reportColumnDef: ColDef[] = [
        {
            field: 'number',
            headerComponentFramework: (props: any) => {
                return (
                    <TranslatableText
                        termSet={termSet}
                        termKey={DepreciationDetailGridDefs.Asset_No}
                    />
                );
            },
            ...DefaultColumnTypes.ShortText,
        },
        {
            field: 'code',
            headerComponentFramework: (props: any) => {
                return (
                    <TranslatableText
                        termSet={termSet}
                        termKey={DepreciationDetailGridDefs.Asset_Code}
                    />
                );
            },
            ...DefaultColumnTypes.MediumText,
        },
        {
            field: 'name',
            headerComponentFramework: (props: any) => {
                return (
                    <TranslatableText
                        termSet={termSet}
                        termKey={DepreciationDetailGridDefs.Asset_Name}
                    />
                );
            },
            ...DefaultColumnTypes.MediumText,
        },
        {
            field: 'depreciationMethod',
            headerComponentFramework: (props: any) => {
                return (
                    <TranslatableText
                        termSet={termSet}
                        termKey={DepreciationDetailGridDefs.Depreciation_Method}
                    />
                );
            },
            ...DefaultColumnTypes.LongText,
        },
    ];

    const getYearFormat = (intervalDate: Date) =>
        intervalDate
            .getFullYear()
            .toString()
            .match(/\d{2}$/);

    const getIntervalDate = (intervalDate: any) =>
        new Date(dateFormatter(intervalDate));

    const getRecoveryColumn = (unit: string, intervalDate: Date) => {
        let recoveryColumn: string = '';
        intervalDate = getIntervalDate(intervalDate);
        switch (unit) {
            case 'Months':
                recoveryColumn = `${intervalDate.toLocaleString('default', {
                    month: 'short',
                })}-${getYearFormat(intervalDate)}`;
                break;
            case 'Quarters':
                recoveryColumn = `Q${Math.ceil(
                    (intervalDate.getMonth() + 1) / 3
                )}-${getYearFormat(intervalDate)}`;
                break;
            case 'Years':
                recoveryColumn = `${intervalDate.getFullYear()}`;
                break;
            default:
                break;
        }
        return recoveryColumn;
    };

    const dynamicColumns = (list: FA_DepreciationEntry[]) => {
        let columns: any[] = reportColumnDef;
        let intervals: any[] = [];
        if (list?.length > 0) {
            let recoveryEntries = list.map((entry) => {
                return {
                    ...entry,
                    orderDate: getIntervalDate(entry.intervalDate),
                };
            });
            recoveryEntries
                .sort((a, b) => Number(a.orderDate) - Number(b.orderDate))
                .forEach((entry) => {
                    const recoveryColumn = getRecoveryColumn(
                        entry.assetType?.recoveryUnit?.unit,
                        entry.intervalDate
                    );

                    if (
                        !columns.find(
                            (col) => col.headerName === recoveryColumn
                        )
                    ) {
                        const colDef = {
                            field: recoveryColumn,
                            headerName: recoveryColumn,
                            ...DefaultColumnTypes.Currency,
                        };
                        columns.push(colDef);
                        intervals.push(colDef);
                    }
                });

            columns.push({
                field: 'totalExpenses',
                headerComponentFramework: (props: any) => {
                    return (
                        <TranslatableText
                            termSet={termSet}
                            termKey={DepreciationDetailGridDefs.Total}
                        />
                    );
                },
                ...DefaultColumnTypes.Currency,
            });
        }
        intervalColumns.current = intervals;
        setColumnDef(columns);
    };

    const buildField = (field: string, value: any) => {
        return `"${field}":${value}`;
    };

    const buildColumns = (entries: FA_DepreciationEntry[]) => {
        return intervalColumns.current.map((intervalCol) => {
            let recoveryEntries = entries.map((entry) => {
                return {
                    ...entry,
                    intervalField: getRecoveryColumn(
                        entry.assetType?.recoveryUnit?.unit,
                        entry.intervalDate
                    ),
                };
            });
            let debitField = recoveryEntries.find(
                (col) => col.intervalField === intervalCol.field
            )?.debit;
            return buildField(intervalCol.field, debitField ?? 0);
        });
    };

    const _createRowsFromList = (list: FA_DepreciationEntry[]): any[] => {
        const rowMap: Map<string, DepreciationEntryRow> = new Map<
            string,
            DepreciationEntryRow
        >();

        list?.forEach((entry) => {
            //establish the row key for uniqueness
            const rowKey: string = `${entry.fixedAsset.id}`;

            if (!rowMap.has(rowKey)) {
                rowMap.set(rowKey, new DepreciationEntryRow(entry));
            } else {
                rowMap.get(rowKey).addEntry(entry);
            }
        });

        return Array.from(rowMap.values()).map((row) => ({
            ...row,
            ...JSON.parse(`{ ${buildColumns(row.entries).join(',')} }`),
            totalExpenses: row.totalExpenses,
            totalNumbers: row.totalNumbers,
        }));
    };

    const bottomRowData = useMemo(() => {
        let row: any = {};
        if (depreciationDetailRows.length > 0) {
            let total = depreciationDetailRows.reduce(
                (prev, curr) => prev + curr.totalNumbers,
                0
            );

            const totalIntervals = intervalColumns.current.map(
                (column: any) => {
                    const sum: Number = depreciationDetailRows.reduce(
                        (prev, curr) => prev + curr[column.field] || 0,
                        0
                    );
                    return buildField(column.field, sum);
                }
            );

            const totals = `{ ${totalIntervals.join(',')} }`;
            return [{ ...row, ...JSON.parse(totals), totalExpenses: total }];
        }
        return [row];
    }, [depreciationDetailRows]);

    useEffect(() => {
        if (depreciationEntryList) {
            dynamicColumns(depreciationEntryList);
            setDepreciationDetailRows(
                _createRowsFromList(depreciationEntryList)
            );
        }
    }, [depreciationEntryList]);

    return {
        depreciationDetailRows,
        columnDef,
        setDepreciationEntryList,
        setShowOptionsModal,
        showOptionsModal,
        bottomRowData,
        canViewDepreciationDetailReport,
        termSet,
    };
};

export default useDepreciationDetailGrid;
