import React, { useContext, useState } from 'react';
import SdsSequences from './sequenceComponents/SdsSequences';
import { Card, CardContent, CardHeader } from '@mui/material';
import SelectDropdown from '../../../components/form/selectDropdown/SelectDropdown';
import RDFormulaSequences from './sequenceComponents/RDFormulaSequences';
import SettingsContext from '../../../contexts/settings.context';
import { useGetTermSetQuery } from '../../../services/i18n/i18n.service';
import { FORMULATION_DEFS } from '../../../constants/i18n/translations/termSetDefinitions/formulation';
import { skipToken } from '@reduxjs/toolkit/query';
import { SequencesFormDefs } from '../../../constants/i18n/translations/termDefinitions/formulation';
import TranslatableText from '../../../components/i18n/TranslatableText';

const Sequences = () => {
    const [selectedSequence, setSelectedSequence] = useState('0');

    const { settings } = useContext(SettingsContext);
    const { data: termSet } = useGetTermSetQuery(
        settings?.userSettings
            ? {
                  languageId: settings?.userSettings?.languageId,
                  code: FORMULATION_DEFS.SEQUENCES_FORM,
              }
            : skipToken
    );

    //here we have our dropdown selections of all of our sequence implementations
    const SEQUENCES = [
        {
            label: (
                <TranslatableText
                    termSet={termSet}
                    termKey={SequencesFormDefs.Safety_Data_Sheet}
                />
            ),
            value: '0',
            component: <SdsSequences termSet={termSet} />,
        },
        {
            label: (
                <TranslatableText
                    termSet={termSet}
                    termKey={SequencesFormDefs.RD_Formula}
                />
            ),
            value: '1',
            component: <RDFormulaSequences termSet={termSet} />,
        },
    ];

    const RenderSelectedSequence = ({ selectedSequence }: any) => {
        return SEQUENCES.find(
            (sequence: any) => sequence.value === selectedSequence
        ).component;
    };

    return (
        <Card>
            <CardHeader
                title={
                    <SelectDropdown
                        placeholder={
                            <TranslatableText
                                termSet={termSet}
                                termKey={SequencesFormDefs.Sequence_Select}
                            />
                        }
                        value={selectedSequence}
                        options={SEQUENCES}
                        onChange={(value: any) => setSelectedSequence(value)}
                    />
                }
            />
            <CardContent>
                <RenderSelectedSequence selectedSequence={selectedSequence} />
            </CardContent>
        </Card>
    );
};

export default Sequences;
