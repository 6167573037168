import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { PermissionsUtil } from '../../../utils/permissions/permissionsUtil';
import { PERMISSIONS } from '../../../constants/permissions/Permissions.constants';
import { useContext } from 'react';
import SettingsContext from '../../../contexts/settings.context';
import { useGetTermSetQuery } from '../../../services/i18n/i18n.service';
import { SUPPLY_CHAIN_DEFS } from '../../../constants/i18n/translations/termSetDefinitions/supply-chain';
import { skipToken } from '@reduxjs/toolkit/query';
import { RoutingValues } from '../../../types/Shared.types';
import { SupplierAddressStatus } from '../../../types/Supplier.types';
import useBaseForm from '../../form/hooks/useBaseForm';
import {
    useCreateSupplierAddressStatusMutation,
    useDeleteSupplierAddressStatusMutation,
    useGetSupplierAddressStatusQuery,
    useUpdateSupplierAddressStatusMutation,
} from '../../../services/supplier/supplier.service';

const useSupplierAddressStatusForm = (id: string) => {
    const sortOrderState = useSelector((state: RootState) => state.suppliers);

    const canCreate = PermissionsUtil.hasPermission(
        PERMISSIONS.SUPPLIERS.SETUP.SUPPLIER_ADDRESS_STATUSES.CREATE
    );

    const canUpdate = PermissionsUtil.hasPermission(
        PERMISSIONS.SUPPLIERS.SETUP.SUPPLIER_ADDRESS_STATUSES.EDIT
    );

    const canDelete = PermissionsUtil.hasPermission(
        PERMISSIONS.SUPPLIERS.SETUP.SUPPLIER_ADDRESS_STATUSES.DELETE
    );

    const { settings } = useContext(SettingsContext);
    const { data: termSet } = useGetTermSetQuery(
        settings?.userSettings
            ? {
                  languageId: settings?.userSettings?.languageId,
                  code: SUPPLY_CHAIN_DEFS.SUPPLIER_ADDRESS_STATUS_FORM,
              }
            : skipToken
    );

    const { data, isLoading } = useGetSupplierAddressStatusQuery(
        id === RoutingValues.newId ? skipToken : id
    );

    const [createMutation] = useCreateSupplierAddressStatusMutation();
    const [updateMutation] = useUpdateSupplierAddressStatusMutation();
    const [deleteMutation] = useDeleteSupplierAddressStatusMutation();

    const blankEntity: SupplierAddressStatus = {
        id: null,
        code: '',
        description: '',
        sortOrder: sortOrderState.sortOrderSet.maxValue,
        businessEntityId: null,
        statusActive: false,
        addressActive: false,
        default: false,
        createdAt: null,
        createdBy: null,
        updatedAt: null,
        updatedBy: null,
    };

    const {
        fields,
        handleFieldChange,
        formMethods,
        onCreate,
        onUpdate,
        onDelete,
        closeForm,
        auditableFields,
    } = useBaseForm({
        closePath: '/suppliers/setup/addressStatus',
        blankEntity: blankEntity,
        activeEntity: data,
        getDescription: () => {
            return `Supplier Address Status ${fields.code}`;
        },
        createEntity: () => {
            return createMutation({ postBody: fields });
        },
        updateEntity: () => {
            return updateMutation({
                id: id as unknown as number,
                postBody: fields,
            });
        },
        deleteEntity: () => {
            return deleteMutation(id);
        },
    });

    return {
        fields,
        handleFieldChange,
        formMethods,
        onCreate,
        onUpdate,
        onDelete,
        closeForm,
        isLoading,
        termSet,
        canCreate,
        canUpdate,
        canDelete,
        data,
        auditableFields,
    };
};

export default useSupplierAddressStatusForm;
