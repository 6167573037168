import { StateCode } from '../../../../services/organizations/organizations.service';

export interface StateCodeDisplay extends StateCode {
    display: string;
}

/**
 * finds matching StateCode according to given param
 * if no match is found, returns empty object
 * @param stateCodeId
 * @param stateList
 */
export const getStateCodeById = (
    stateCodeId: bigint,
    statesList: StateCode[]
): StateCodeDisplay => {
    let stateCode: StateCodeDisplay = {
        id: null,
        name: '',
        display: '',
        category: '',
    };
    if (statesList) {
        (statesList as StateCode[]).find((state) => {
            if (state.id === stateCodeId) {
                stateCode = {
                    id: state.id,
                    name: state.name,
                    //display field mandatory for use with select modal
                    display: state.name,
                    category: '',
                };
            }
        });
    }
    return stateCode;
};
