import { RDFormulaHazardClassificationSource } from '../../../../types/formulation';
import { datacorAppApi } from '../../../datacorAppApi';

const serviceUrl: string = 'rdFormula/rdFormulaHazardClassificationSource';

export const rdFormulaHazardClassificationSourceApi =
    datacorAppApi.injectEndpoints({
        endpoints: (build) => ({
            getHazardClassificationSourcesForDropdown: build.query<
                { label: string; value: bigint }[],
                void
            >({
                query: () => serviceUrl,
                transformResponse: (
                    response: RDFormulaHazardClassificationSource[]
                ) => {
                    return response.map(
                        (propType: RDFormulaHazardClassificationSource) => {
                            return {
                                label: propType.name,
                                value: propType.id,
                            };
                        }
                    );
                },
                providesTags: ['RDFormulaHazardClassificationSource'],
            }),
        }),
    });

export const { useGetHazardClassificationSourcesForDropdownQuery } =
    rdFormulaHazardClassificationSourceApi;
