import React from 'react';
import HazardClassificationRuleGrid from './HazardClassificationRuleGrid';

const HazardClassificationRule = () => {
    return (
        <>
            <HazardClassificationRuleGrid />
        </>
    );
};

export default HazardClassificationRule;
