import { useNavigate } from 'react-router-dom';
import { PermissionsUtil } from '../../../utils/permissions/permissionsUtil';
import { PERMISSIONS } from '../../../constants/permissions/Permissions.constants';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { RoutingValues } from '../../../types/Shared.types';
import React, { useContext, useEffect, useState } from 'react';
import _ from 'lodash';
import {
    SupplierAddressType,
    SupplierType,
} from '../../../types/Supplier.types';
import { getMaxSortOrderValue } from '../../../store/suppliers';
import { useGetTermSetQuery } from '../../../services/i18n/i18n.service';
import { SUPPLY_CHAIN_DEFS } from '../../../constants/i18n/translations/termSetDefinitions/supply-chain';
import { skipToken } from '@reduxjs/toolkit/query';
import SettingsContext from '../../../contexts/settings.context';
import { ColDef } from 'ag-grid-community';
import DefaultColumnTypes from '../../../components/grids/columns/Column.constants';
import CustomHeader from '../../../components/grids/CustomHeader';
import { isNilOrEmpty } from '../../../utils/objectUtils';
import { SupplierAddressTypeGridDefs } from '../../../constants/i18n/translations/termDefinitions/supply-chain';
import {
    useListSupplierAddressTypeQuery,
    useUpdateListSupplierAddressTypeMutation,
} from '../../../services/supplier/supplier.service';
import { auditableGridColumns } from '../../grid/utils/auditableGrid/auditableGridColumns';

const UseSupplierAddressTypeGrid = () => {
    const dispatch = useDispatch();
    const user = useSelector((state: RootState) => state.user);
    const navigate = useNavigate();

    const { data, isLoading } = useListSupplierAddressTypeQuery();
    const [updateList] = useUpdateListSupplierAddressTypeMutation();

    const [colDefs, setColDefs] = useState([]);
    const [rowData, setRowData] = useState([]);

    const { settings } = useContext(SettingsContext);
    const { data: termSet, isLoading: isLoadingTermSet } = useGetTermSetQuery(
        settings?.userSettings
            ? {
                  languageId: settings?.userSettings?.languageId,
                  code: SUPPLY_CHAIN_DEFS.SUPPLIER_ADDRESS_TYPE_GRID,
              }
            : skipToken
    );
    const [hiddenIdColumns, auditableColumns] = auditableGridColumns();

    const canCreate = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.SUPPLIERS.SETUP.SUPPLIER_ADDRESS_TYPES.CREATE
    );
    const canView = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.SUPPLIERS.SETUP.SUPPLIER_ADDRESS_TYPES.VIEW
    );
    const canUpdate = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.SUPPLIERS.SETUP.SUPPLIER_ADDRESS_TYPES.EDIT
    );

    const handleGridEdits = (editedRows: SupplierType[]) => {
        updateList(editedRows);
    };

    const redirectToForm = (row: any) => {
        if (row.id) {
            navigate(`/suppliers/setup/addressType/${row.id}`);
        } else {
            navigate(`/suppliers/setup/addressType/${RoutingValues.newId}`);
        }
    };

    useEffect(() => {
        let maxValue = 1;
        if (data && data.length > 0) {
            const list = _.maxBy(
                data,
                (typeObj: SupplierAddressType) => typeObj.sortOrder
            );
            maxValue = list.sortOrder + 1;
        }
        dispatch(
            getMaxSortOrderValue({ isLoading: false, maxValue: maxValue })
        );
    }, data);

    useEffect(() => {
        if (!isNilOrEmpty(termSet)) {
            const typeColumnDefs: ColDef[] = [
                ...hiddenIdColumns,
                {
                    field: 'sortOrder',
                    sort: 'asc',
                    ...DefaultColumnTypes.NumberOnly,
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={SupplierAddressTypeGridDefs.SortOrder}
                            />
                        );
                    },
                },
                {
                    field: 'code',
                    ...DefaultColumnTypes.ShortText,
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={SupplierAddressTypeGridDefs.Code}
                            />
                        );
                    },
                },
                {
                    field: 'typeActive',
                    ...DefaultColumnTypes.Boolean,
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={SupplierAddressTypeGridDefs.TypeActive}
                            />
                        );
                    },
                },
                {
                    field: 'default',
                    ...DefaultColumnTypes.Boolean,
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={SupplierAddressTypeGridDefs.Default}
                            />
                        );
                    },
                },
                {
                    field: 'main',
                    ...DefaultColumnTypes.Boolean,
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={SupplierAddressTypeGridDefs.Main}
                            />
                        );
                    },
                },
                {
                    field: 'remitPayment',
                    ...DefaultColumnTypes.Boolean,
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={
                                    SupplierAddressTypeGridDefs.RemitPayment
                                }
                            />
                        );
                    },
                },
                {
                    field: 'description',
                    ...DefaultColumnTypes.LongText,
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={
                                    SupplierAddressTypeGridDefs.Description
                                }
                            />
                        );
                    },
                },
                ...auditableColumns,
            ];
            setColDefs(typeColumnDefs);
        }
    }, [termSet]);

    useEffect(() => {
        if (!isNilOrEmpty(data)) {
            setRowData(data);
        }
    }, [data]);

    return {
        rowData,
        isLoading,
        canCreate,
        canView,
        canUpdate,
        redirectToForm,
        handleGridEdits,
        termSet,
        colDefs,
        isLoadingTermSet,
    };
};

export default UseSupplierAddressTypeGrid;
