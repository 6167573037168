import React, { useContext } from 'react';
import { useForm } from '../../../../../libs/hooksLib';
import { SelectionOption } from '../../../../../types/Shared.types';
import SettingsContext from '../../../../../contexts/settings.context';
import { useGetTermSetQuery } from '../../../../../services/i18n/i18n.service';
import { FORMULATION_DEFS } from '../../../../../constants/i18n/translations/termSetDefinitions/formulation';
import { skipToken } from '@reduxjs/toolkit/query';

export interface LanguageSelectionModalProps {
    showModal: boolean;
    handleModalClose: (selectedLangId: bigint) => void;
    languageList: SelectionOption[];
}

const useLanguageSelectionModal = ({
    handleModalClose,
}: LanguageSelectionModalProps) => {
    const blankEntity = {
        langId: null as bigint,
    };

    const { fields, handleFieldChange, formMethods, setValues } =
        useForm(blankEntity);

    const onSubmit = () => {
        handleModalClose(fields.langId);
        setValues(blankEntity);
    };

    const { settings } = useContext(SettingsContext);
    const { data: termSet } = useGetTermSetQuery(
        settings?.userSettings
            ? {
                  languageId: settings?.userSettings?.languageId,
                  code: FORMULATION_DEFS.FORMULATION_SHARED_COMPONENTS,
              }
            : skipToken
    );

    return {
        fields,
        formMethods,
        handleFieldChange,
        onSubmit,
        termSet,
    };
};

export default useLanguageSelectionModal;
