import { RootStateOrAny, useSelector } from 'react-redux';
import {
    useCreateBoilerPlateNoteMutation,
    useDeleteBoilerPlateNoteMutation,
    useGetBoilerPlateNoteQuery,
    useUpdateBoilerPlateNoteMutation,
} from '../../../services/organizations/organizations.service';
import { skipToken } from '@reduxjs/toolkit/query';
import { PermissionsUtil } from '../../../utils/permissions/permissionsUtil';
import { PERMISSIONS } from '../../../constants/permissions/Permissions.constants';
import { BoilerPlateNote } from '../../../types/Setup.types';
import useBaseForm from '../../form/hooks/useBaseForm';

const UseBoilerPlateNoteForm = (id: string) => {
    const user = useSelector((state: RootStateOrAny) => state.user);

    const canCreateBoilerPlateNote = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.SETUP.BOILER_PLATE_NOTE.CREATE
    );

    const canUpdateBoilerPlateNote = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.SETUP.BOILER_PLATE_NOTE.EDIT
    );

    const canDeleteBoilerPlateNote = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.SETUP.BOILER_PLATE_NOTE.DELETE
    );

    const { data: activeBoilerPlateNote, isLoading: isLoadingBoilerPlateNote } =
        useGetBoilerPlateNoteQuery(id ? id : skipToken);

    const [createBoilerPlateNote] = useCreateBoilerPlateNoteMutation();

    const [updateBoilerPlateNote] = useUpdateBoilerPlateNoteMutation();

    const [deleteBoilerPlateNote] = useDeleteBoilerPlateNoteMutation();

    const blankBoilerPlateNote: BoilerPlateNote = {
        code: '',
        description: '',
        note: null,
        businessEntityId: user?.businessEntity?.id,
    };

    const {
        fields,
        handleFieldChange,
        formMethods,
        onCreate,
        onUpdate,
        onDelete,
        closeForm,
    } = useBaseForm({
        closePath: '/setup/boilerPlateNote',
        reroute: true,
        blankEntity: blankBoilerPlateNote,
        activeEntity: activeBoilerPlateNote,
        getDescription: () => {
            return `Boiler Plate Note ${fields.description}`;
        },
        createEntity: () => {
            return createBoilerPlateNote({
                postBody: fields,
            });
        },
        updateEntity: () => {
            return updateBoilerPlateNote({
                id: id as unknown as number,
                postBody: fields,
            });
        },
        deleteEntity: () => {
            return deleteBoilerPlateNote(id);
        },
    });

    return {
        fields,
        handleFieldChange,
        formMethods,
        onCreate,
        onUpdate,
        onDelete,
        closeForm,
        isLoadingBoilerPlateNote,
        canCreateBoilerPlateNote,
        canUpdateBoilerPlateNote,
        canDeleteBoilerPlateNote,
    };
};

export default UseBoilerPlateNoteForm;
