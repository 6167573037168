import React from 'react';
import { Checkbox, FormControlLabel, FormGroup, Grid } from '@mui/material';
import { useParams } from 'react-router-dom';
import useFixedAssetsStatusForm from './hooks/useFixedAssetsStatusForm';
import FormPartial from '../../components/form/formContainer/FormPartial';
import TextInput from '../../components/form/formInputs/TextInput/TextInput';
import NumericInput from '../../components/form/formInputs/NumericInput/NumericInput';
import BaseForm, { ParamTypes } from '../../components/form/baseForm/BaseForm';
import { NumberMaxValues, RoutingValues } from '../../types/Shared.types';
import { ASSET_STATUS_FIELDS } from '../../constants/revisionHistory/revisionHistory.constants';
import TranslatableText from '../../components/i18n/TranslatableText';
import { AssetStatusesFormDefs } from '../../constants/i18n/translations/termDefinitions/accounting';

const FixedAssetsStatusForm = () => {
    const { id } = useParams<ParamTypes>();
    const {
        fields,
        handleFieldChange,
        handleInServiceChange,
        handleDisposalChange,
        formMethods,
        onCreate,
        onUpdate,
        onDelete,
        closeForm,
        isLoadingStatus,
        canCreateAssetStatus,
        canUpdateAssetStatus,
        canDeleteAssetStatus,
        termSet,
    } = useFixedAssetsStatusForm(id);

    return (
        <BaseForm
            title={
                <TranslatableText
                    termSet={termSet}
                    termKey={AssetStatusesFormDefs.Fixed_Asset_Status}
                />
            }
            formMethods={formMethods}
            isLoading={isLoadingStatus}
            isNew={id === RoutingValues.newId}
            onCreate={onCreate}
            onUpdate={onUpdate}
            onDelete={onDelete}
            onCancel={closeForm}
            displayCreateButton={canCreateAssetStatus}
            displayUpdateButton={canUpdateAssetStatus}
            displayDeleteButton={canDeleteAssetStatus}
            showOnRevision={true}
            fieldMappings={ASSET_STATUS_FIELDS}
            entityUuid={fields?.uuid}>
            <FormPartial>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <TextInput
                            fullWidth
                            label={
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={AssetStatusesFormDefs.Status_Code}
                                />
                            }
                            id="code"
                            value={fields.code}
                            onChange={handleFieldChange}
                            formMethods={formMethods}
                            required={true}
                            maxLength={30}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <NumericInput
                            fullWidth
                            label={
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={AssetStatusesFormDefs.Sort_Order}
                                />
                            }
                            id="sortOrder"
                            value={fields.sortOrder}
                            onChange={handleFieldChange}
                            formMethods={formMethods}
                            required={true}
                            maxValue={NumberMaxValues.smallint}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <FormGroup
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                            }}>
                            <FormControlLabel
                                label={
                                    <TranslatableText
                                        termSet={termSet}
                                        termKey={
                                            AssetStatusesFormDefs.In_Service
                                        }
                                    />
                                }
                                control={
                                    <Checkbox
                                        id="isInService"
                                        checked={fields.isInService}
                                        onChange={handleInServiceChange}
                                    />
                                }
                            />
                            <FormControlLabel
                                label={
                                    <TranslatableText
                                        termSet={termSet}
                                        termKey={AssetStatusesFormDefs.Disposal}
                                    />
                                }
                                control={
                                    <Checkbox
                                        id="isDisposal"
                                        checked={fields.isDisposal}
                                        onChange={handleDisposalChange}
                                    />
                                }
                            />
                        </FormGroup>
                    </Grid>
                    <Grid item xs={12}>
                        <TextInput
                            fullWidth
                            label={
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={AssetStatusesFormDefs.Description}
                                />
                            }
                            id="description"
                            value={fields.description}
                            onChange={handleFieldChange}
                            formMethods={formMethods}
                            maxLength={255}
                        />
                    </Grid>
                </Grid>
            </FormPartial>
        </BaseForm>
    );
};

export default FixedAssetsStatusForm;
