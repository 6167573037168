import React from 'react';
import { isNilOrEmpty } from '../../../../../../utils/objectUtils';
import DatacorLogoSpinner from '../../../../../../components/datacorLogoSpinner/DatacorLogoSpinner';
import BaseGrid from '../../../../../../components/grids/BaseGrid';
import useCasEcoToxicityGrid from './hooks/useCasEcoToxicityGrid';
import FormPartial from '../../../../../../components/form/formContainer/FormPartial';
import { ChildEditableGridWithCopyFunctionProps } from '../../../../../grid/utils/editableGrid/ChildEditableGridWithCopyFunctionProps';
import { CasEcoToxicity, CasMaster } from '../../../../../../types/formulation';
import TranslatableText from '../../../../../../components/i18n/TranslatableText';
import { CasMasterFormDefs } from '../../../../../../constants/i18n/translations/termDefinitions/formulation';

const CasEcoToxicityGrid = (
    props: ChildEditableGridWithCopyFunctionProps<CasMaster, CasEcoToxicity>
) => {
    const {
        isLoadingToxicTests,
        isLoadingToxicSpecies,
        ecoToxicityEntityColumnDefs,
        handleGridEdits,
        handleRowValidations,
        colDefOptions,
        termSet,
    } = useCasEcoToxicityGrid(props);

    const { isParentLoading } = props.parentData;

    const { showAddButton, showDeleteButton, showSortButton, isGridEditable } =
        props.displayGridButtons;

    const { rowsData } = props.copyMethods;

    return isParentLoading ||
        isLoadingToxicTests ||
        isLoadingToxicSpecies ||
        isNilOrEmpty(ecoToxicityEntityColumnDefs) ? (
        <DatacorLogoSpinner />
    ) : (
        <FormPartial
            title={
                (
                    <TranslatableText
                        termSet={termSet}
                        termKey={CasMasterFormDefs.Ecotoxicity_Text}
                    />
                ) as unknown as string
            }>
            <BaseGrid
                autoSize={true}
                displayGrid={!isParentLoading}
                displayCreateNewButton={showAddButton}
                displayDeleteButton={showDeleteButton}
                useRowDrag={showSortButton}
                displaySortButton={showSortButton}
                sortKey={'sortOrder'}
                rowData={rowsData || []}
                dataKey={'rowId'}
                defaultColDef={colDefOptions}
                gridClass="full-size-item"
                displayToolbar={true}
                sizeColumnsToFit={true}
                onHandleGridEdits={handleGridEdits}
                isEditable={isGridEditable}
                onHandleRowValidations={handleRowValidations}
                columnDefs={ecoToxicityEntityColumnDefs}
                //TODO: determine best way to translate
                newRowPlaceholderValues={{
                    toxicTestCode: 'Enter a Test Type...',
                    toxicSpeciesCode: 'Enter a Toxic Species...',
                    effectiveDose: 'Enter an Effective Dose...',
                    exposureTime: 'Enter an Exposure Time...',
                    notes: 'Enter Notes...',
                    source: 'Enter a Source....',
                    method: 'Enter a Method...',
                    speciesClassId: 'Enter a Species Class...',
                }}></BaseGrid>
        </FormPartial>
    );
};
export default CasEcoToxicityGrid;
