import React, { useContext } from 'react';
import {
    Dialog,
    DialogContent,
    DialogContentText,
    DialogActions,
} from '@mui/material';
import Button from '@mui/material/Button';
import { FormulationSharedComponentsDefs } from '../../../../constants/i18n/translations/termDefinitions/formulation';
import TranslatableText from '../../../../components/i18n/TranslatableText';
import SettingsContext from '../../../../contexts/settings.context';
import { useGetTermSetQuery } from '../../../../services/i18n/i18n.service';
import { FORMULATION_DEFS } from '../../../../constants/i18n/translations/termSetDefinitions/formulation';
import { skipToken } from '@reduxjs/toolkit/query';

interface PhraseVerbiageMessageModalProps {
    showModal: boolean;
    handleClose: () => void;
    messageText: string;
}

const PhraseVerbiageMessageModal = ({
    showModal,
    handleClose,
    messageText,
}: PhraseVerbiageMessageModalProps) => {
    const { settings } = useContext(SettingsContext);
    const { data: termSet } = useGetTermSetQuery(
        settings?.userSettings
            ? {
                  languageId: settings?.userSettings?.languageId,
                  code: FORMULATION_DEFS.FORMULATION_SHARED_COMPONENTS,
              }
            : skipToken
    );
    return (
        <Dialog open={showModal} onClose={handleClose} maxWidth={'lg'}>
            <DialogContent>
                <DialogContentText
                    id="alert-dialog-description"
                    style={{ color: 'black' }}>
                    {messageText}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} autoFocus>
                    <TranslatableText
                        termSet={termSet}
                        termKey={FormulationSharedComponentsDefs.Phrase_Ok}
                    />
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default PhraseVerbiageMessageModal;
