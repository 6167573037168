import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { PLATFORM_DEFS } from '../../../constants/i18n/translations/termSetDefinitions/platform';
import { skipToken } from '@reduxjs/toolkit/query';
import {
    useCreateDocTemplateMutation,
    useGetDocDataSourceQuery,
    useGetDocTemplateQuery,
    useUpdateDocTemplateMutation,
} from '../../../services/documentDesigner/documentDesigner.service';
import useTranslation from '../../i18n/hooks/useTranslation';
import { isNilOrEmpty } from '../../../utils/objectUtils';

//import { Stimulsoft } from 'stimulsoft-reports-js/Scripts/stimulsoft.blockly.editor';
//TODO: add tests
const useDocumentDesigner = (
    documentId: string,
    dataSourceId: any,
    designerRef: any
) => {
    let navigate = useNavigate();
    const { termSet } = useTranslation(PLATFORM_DEFS.SHARED_COMPONENTS);
    const [createDocumentTemplate] = useCreateDocTemplateMutation();
    const [updateDocumentTemplate] = useUpdateDocTemplateMutation();
    const { data: documentTemplate } = useGetDocTemplateQuery(
        documentId ? documentId : skipToken
    );

    const { data: dataSource } = useGetDocDataSourceQuery(
        dataSourceId ? dataSourceId : skipToken
    );

    useEffect(() => {
        setDesignerRef();
        //when the datasource changes we need to add the data to the report
        if (
            designerRef.current &&
            !isNilOrEmpty(dataSource) &&
            isNilOrEmpty(documentTemplate)
        ) {
            handleSetupNewDocument();
        }
        if (designerRef.current && !isNilOrEmpty(documentTemplate)) {
            handleLoadReport();
        }
    }, [dataSource, dataSourceId, designerRef, documentTemplate]);

    const handleSetupNewDocument = () => {
        const dataSet = new (window as any).Stimulsoft.System.Data.DataSet(
            dataSource.name
        );
        const report = new (window as any).Stimulsoft.Report.StiReport();
        dataSet.readJson(JSON.stringify(dataSource?.data));
        report.regData(dataSet.dataSetName, '', dataSet);

        report.dictionary.synchronize();

        designerRef.current.report = report;
        designerRef.current!.renderHtml('designerContainer');
    };

    const setDesignerRef = () => {
        // we can supply the designer with overrideable stylings
        const options = new (
            window as any
        ).Stimulsoft.Designer.StiDesignerOptions();
        options.appearance.fullScreenMode = false;
        options.appearance.showSaveDialog = false;
        options.toolbar.visible = false;

        //the actual designer class..
        const designer = new (window as any).Stimulsoft.Designer.StiDesigner(
            options,
            'StiDesigner',
            false
        );

        // Create designer instance
        designerRef.current = designer;
    };

    const handleLoadReport = () => {
        const report = new (window as any).Stimulsoft.Report.StiReport();

        report.load(documentTemplate?.templateData);

        designerRef.current!.report = report;

        designerRef.current!.renderHtml('designerContainer');
    };

    return {
        navigate,
        termSet,
    };
};

export default useDocumentDesigner;
