import { datacorAppApi } from '../datacorAppApi';
import {
    Currency,
    CurrencyExchangeRate,
    CurrencyCode,
    CurrencyExchangeRateByTypeParams,
    CurrencyExchangeRateListParams,
    CurrencyRateType,
    CurrencyRateTypeCode,
} from '../../types/platform/i18n/Currency.type';
import {
    BaseCreateEntityArgs,
    BaseUpdateEntityArgs,
} from '../serviceInterfaces';
import { SelectionOption } from '../../types/Shared.types';

export const i18nApi = datacorAppApi.injectEndpoints({
    endpoints: (build) => ({
        //region TermSet
        getTermSet: build.query<any, any>({
            query: (args) =>
                `i18n/translation/getTermSet/${args.languageId}/${args.code}`,
            providesTags: ['TermSet'],
        }),
        //endregion TermSet

        //region Currency
        listCurrencyCodes: build.query<any, void>({
            query: () => `i18n/currencyCode`,
            transformResponse: (response: CurrencyCode[]) => {
                return response.map((currencyCode: CurrencyCode) => {
                    return {
                        value: currencyCode.code,
                        label: currencyCode.code,
                        object: currencyCode,
                    };
                });
            },
            providesTags: ['CurrencyCodes'],
        }),
        listCurrency: build.query<any, void>({
            query: () => `i18n/currency`,
            providesTags: ['Currencies'],
        }),
        getCurrency: build.query<any, string>({
            query: (id) => `i18n/currency/${id}`,
            providesTags: ['ActiveCurrency'],
        }),
        createCurrency: build.mutation<any, BaseCreateEntityArgs<Currency>>({
            query: (args) => ({
                url: 'i18n/currency',
                method: 'POST',
                body: args.postBody,
            }),
            invalidatesTags: ['Currencies', 'ActiveCurrency'],
        }),
        updateCurrency: build.mutation<any, BaseUpdateEntityArgs<Currency>>({
            query: (args) => ({
                url: `i18n/currency/${args.id}`,
                method: 'PATCH',
                body: args.postBody,
            }),
            invalidatesTags: ['Currencies', 'ActiveCurrency'],
        }),
        deleteCurrency: build.mutation<any, string>({
            query: (id) => ({
                url: `i18n/currency/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Currencies'],
        }),
        updateListCurrency: build.mutation<boolean, Currency[]>({
            query: (args) => ({
                url: `i18n/currency/updateList`,
                method: 'POST',
                body: args,
            }),
            invalidatesTags: ['Currencies', 'ActiveCurrency'],
        }),
        //endregion Currency

        //region Currency Rate Types
        listCurrencyRateTypes: build.query<any, void>({
            query: () => `i18n/currencyRateType`,
            providesTags: ['CurrencyRateTypes'],
        }),
        listCurrencyRateTypesForDropdown: build.query<any, void>({
            query: () => `i18n/currencyRateType`,
            transformResponse: (response: CurrencyRateType[]) => {
                return response.map((type: CurrencyRateType) => {
                    return {
                        value: type.id,
                        label: type.code,
                        object: type,
                    };
                });
            },
            providesTags: ['CurrencyRateTypes'],
        }),
        updateListCurrencyRateTypes: build.mutation<
            boolean,
            CurrencyRateType[]
        >({
            query: (args) => ({
                url: `i18n/currencyRateTypes/updateList`,
                method: 'POST',
                body: args,
            }),
            invalidatesTags: ['CurrencyRateTypes'],
        }),
        createCurrencyRateType: build.mutation<
            any,
            BaseCreateEntityArgs<CurrencyRateType>
        >({
            query: (args) => ({
                url: 'i18n/currencyRateType',
                method: 'POST',
                body: args.postBody,
            }),
            invalidatesTags: ['CurrencyRateTypes'],
        }),
        getCurrencyRateType: build.query<any, string>({
            query: (id) => `i18n/currencyRateType/${id}`,
            providesTags: ['ActiveCurrencyRateType'],
        }),
        updateCurrencyRateType: build.mutation<
            any,
            BaseUpdateEntityArgs<CurrencyRateType>
        >({
            query: (args) => ({
                url: `i18n/currencyRateType/${args.id}`,
                method: 'PATCH',
                body: args.postBody,
            }),
            invalidatesTags: ['CurrencyRateTypes', 'ActiveCurrencyRateType'],
        }),
        deleteCurrencyRateType: build.mutation<any, string>({
            query: (id) => ({
                url: `i18n/currencyRateType/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['CurrencyRateTypes'],
        }),
        //endregion Currency Rate Types

        //region Currency Rate Type Codes
        listCurrencyRateTypeCodes: build.query<SelectionOption[], void>({
            query: () => `i18n/currencyRateTypeCode`,
            transformResponse: (response: CurrencyRateTypeCode[]) => {
                return response.map((rateTypeCode: CurrencyRateTypeCode) => {
                    return {
                        value: rateTypeCode.code,
                        label: rateTypeCode.code,
                        object: rateTypeCode,
                    };
                });
            },
            providesTags: ['CurrencyRateTypeCodes'],
        }),
        //endregion

        //region Currency Exchange Rates
        listCurrencyExchangeRates: build.query<
            any,
            CurrencyExchangeRateListParams
        >({
            query: (params: CurrencyExchangeRateListParams) => {
                return {
                    url: `i18n/currencyExchangeRate`,
                    params: params,
                };
            },
            providesTags: ['CurrencyExchangeRates'],
        }),
        listCurrencyExchangeRatesByType: build.query<
            any,
            CurrencyExchangeRateByTypeParams
        >({
            query: (params: CurrencyExchangeRateByTypeParams) => {
                return {
                    url: `i18n/currencyExchangeRateByType`,
                    params: params,
                };
            },
            providesTags: ['CurrencyExchangeRates'],
        }),
        createCurrencyExchangeRate: build.mutation<
            any,
            BaseCreateEntityArgs<CurrencyExchangeRate>
        >({
            query: (args) => ({
                url: 'i18n/currencyExchangeRate',
                method: 'POST',
                body: args.postBody,
            }),
            invalidatesTags: ['CurrencyExchangeRates'],
        }),
        getCurrencyExchangeRate: build.query<any, string>({
            query: (id) => `i18n/currencyExchangeRate/${id}`,
            providesTags: ['ActiveCurrencyExchangeRate'],
        }),
        updateCurrencyExchangeRate: build.mutation<
            any,
            BaseUpdateEntityArgs<CurrencyExchangeRate>
        >({
            query: (args) => ({
                url: `i18n/currencyExchangeRate/${args.id}`,
                method: 'PATCH',
                body: args.postBody,
            }),
            invalidatesTags: [
                'CurrencyExchangeRates',
                'ActiveCurrencyExchangeRate',
            ],
        }),
        //endregion
    }),
});

export const {
    useGetTermSetQuery,
    useListCurrencyCodesQuery,
    useListCurrencyQuery,
    useGetCurrencyQuery,
    useCreateCurrencyMutation,
    useUpdateCurrencyMutation,
    useDeleteCurrencyMutation,
    useUpdateListCurrencyMutation,
    useListCurrencyRateTypesQuery,
    useListCurrencyRateTypesForDropdownQuery,
    useUpdateListCurrencyRateTypesMutation,
    useCreateCurrencyRateTypeMutation,
    useListCurrencyRateTypeCodesQuery,
    useGetCurrencyRateTypeQuery,
    useUpdateCurrencyRateTypeMutation,
    useDeleteCurrencyRateTypeMutation,
    useListCurrencyExchangeRatesQuery,
    useListCurrencyExchangeRatesByTypeQuery,
    useCreateCurrencyExchangeRateMutation,
    useGetCurrencyExchangeRateQuery,
    useUpdateCurrencyExchangeRateMutation,
} = i18nApi;
