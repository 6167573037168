import { useContext, useEffect, useState } from 'react';
import {
    BusinessEntity,
    useCreateBusinessEntityMutation,
    useGetBusinessEntityFullQuery,
    useGetBusinessEntityTypesForDropdownQuery,
    useUpdateBusinessEntityMutation,
    Zone,
} from '../../../services/organizations/organizations.service';
import { skipToken } from '@reduxjs/toolkit/query';
import { useNavigate } from 'react-router-dom';
import { useForm } from '../../../libs/hooksLib';
import {
    BaseCreateEntityArgs,
    BaseUpdateEntityArgs,
} from '../../../services/serviceInterfaces';
import { RootState } from '../../../store';
import { useDispatch, useSelector } from 'react-redux';
import useGetBusinessEntities from './useGetBusinessEntities';
import config from '../../../config';
import * as _ from 'lodash';
import { PermissionsUtil } from '../../../utils/permissions/permissionsUtil';
import { PERMISSIONS } from '../../../constants/permissions/Permissions.constants';
import { showNotificationError } from '../../../libs/errorLib';
import { useGetTermSetQuery } from '../../../services/i18n/i18n.service';
import { PLATFORM_DEFS } from '../../../constants/i18n/translations/termSetDefinitions/platform';
import SettingsContext from '../../../contexts/settings.context';

const useBusinessEntityForm = (id: string) => {
    const dispatch = useDispatch();
    const user = useSelector((state: RootState) => state.user);
    const navigate = useNavigate();
    const [appClientId, setAppClientId] = useState('');
    const [appClientSecret, setAppClientSecret] = useState('');
    const [showAuthDialog, setShowAuthDialog] = useState(false);

    const {
        fields,
        handleFieldChange,
        setValues,
        formMethods,
        isFormValid,
        updateField,
    } = useForm({});
    const { data: businessEntity, isLoading: isLoading } =
        useGetBusinessEntityFullQuery(id ? id : skipToken);
    const {
        data: businessEntityTypeOptions,
        isLoading: isLoadingBusinessEntityTypeOptions,
    } = useGetBusinessEntityTypesForDropdownQuery();

    const { settings } = useContext(SettingsContext);
    const { data: termSet } = useGetTermSetQuery(
        settings?.userSettings
            ? {
                  languageId: settings?.userSettings?.languageId,
                  code: PLATFORM_DEFS.BUSINESS_ENTITY_FORM,
              }
            : skipToken
    );

    const {
        businessEntities: businessEntityOptions,
        isLoading: isLoadingBusinessEnts,
    } = useGetBusinessEntities({
        businessEntityId: user?.activeBusinessEntityId?.toString(),
        subscriberId: user?.subscriber?.id.toString(),
    });

    const {
        fields: zoneFields,
        handleFieldChange: handleZoneFieldChange,
        setValues: setZoneValues,
        formMethods: zoneFormMethods,
        isFormValid: isZoneFormValid,
    } = useForm({});

    const [createBusinessEntity] = useCreateBusinessEntityMutation();
    const [updateBusinessEntity] = useUpdateBusinessEntityMutation();

    const defaultBusinessEntity: any = {
        subscriberId: user?.subscriber?.id || '',
        parentId: user?.businessEntity?.parentId || null,
        name: '',
        description: '',
        businessEntityTypeId: '',
        externalAuthorization: false,
        userPoolId: config.cognito.USER_POOL_ID,
    };

    const defaultZone: Zone = {
        platform: '',
        url: '',
        port: '',
    };

    const canEditBusinessEntity = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.ADMINISTRATION.BUSINESS_ENTITIES.EDIT
    );

    const canDeleteBusinessEntity = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.ADMINISTRATION.BUSINESS_ENTITIES.DELETE
    );

    const canCreateBusinessEntity = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.ADMINISTRATION.BUSINESS_ENTITIES.CREATE
    );

    useEffect(() => {
        const hasExternalAuth = !!businessEntity?.appClients?.some(
            (appClient: any) => !appClient.isDefaultClient
        );
        const entity = businessEntity || defaultBusinessEntity;
        setValues({ ...entity, externalAuthorization: hasExternalAuth });
        setZoneValues(businessEntity?.zone || defaultZone);
    }, [businessEntity]);

    const handleCreateBusinessEntity = async () => {
        if (!isFormValid || !isZoneFormValid) {
            return;
        }

        const body: BaseCreateEntityArgs<BusinessEntity> = {
            postBody: {
                ...fields,
                zone: !_.isEqual(zoneFields, defaultZone) ? zoneFields : null,
            },
        };

        try {
            const response = await createBusinessEntity(body);
            if ((response as any)?.data?.externalAuthDetails) {
                setAppClientId(
                    (response as any).data.externalAuthDetails.appClientId
                );
                setAppClientSecret(
                    (response as any).data.externalAuthDetails.appClientSecret
                );
                setShowAuthDialog(true);
            } else {
                handleClose();
            }
        } catch (e) {
            return showNotificationError(e.message, 'error', dispatch);
        }
    };

    const handleUpdateBusinessEntity = async () => {
        if (!isFormValid || !isZoneFormValid) {
            return;
        }

        const body: BaseUpdateEntityArgs<BusinessEntity> = {
            id: id as unknown as number,
            postBody: { ...fields, zone: zoneFields },
        };

        try {
            await updateBusinessEntity(body);
            handleClose();
        } catch (e) {
            return showNotificationError(e.message, 'error', dispatch);
        }
    };

    const handleClose = () => {
        navigate('/administration/organizations/business-entities');
    };

    return {
        fields,
        zoneFields,
        businessEntity,
        businessEntityOptions,
        businessEntityTypeOptions,
        isLoadingBusinessEntityTypeOptions,
        isLoading,
        handleCreateBusinessEntity: handleCreateBusinessEntity,
        handleUpdateBusinessEntity: handleUpdateBusinessEntity,
        handleFieldChange,
        handleZoneFieldChange,
        handleClose,
        setValues,
        formMethods,
        zoneFormMethods,
        showAuthDialog,
        appClientId,
        appClientSecret,
        canEditBusinessEntity,
        canDeleteBusinessEntity,
        canCreateBusinessEntity,
        termSet,
    };
};

export default useBusinessEntityForm;
