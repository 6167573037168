import { useLocation, useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { isNilOrEmpty } from '../../../utils/objectUtils';
import { useGetAttributeEntitiesForDropdownQuery } from '../../../services/attributes/attributes.service';

const useAttributesView = () => {
    const { data: attributeEntities, isLoading: isLoadingEntities } =
        useGetAttributeEntitiesForDropdownQuery();

    const ROUTES = {
        '/setup/attributes/definitions': 0,
        '/setup/attributes/templates': 1,
    };
    let navigate = useNavigate();
    let location = useLocation();
    const [value, setValue] = useState((ROUTES as any)[location.pathname] || 0);
    const [entitySelectValue, setEntitySelectValue] = useState(
        //this replace allows the input field to persist across grid/form loads
        location?.search ? location?.search?.replace(/\D/g, '') : ''
    );

    // if we enter via the sidebar we want to default redirect to /definitions
    useEffect(() => {
        if (location.pathname === '/setup/attributes') {
            navigate('/setup/attributes/definitions');
        }
    }, [value]);

    //if we have a value selected in the dropdown we want to add it as a query param to filter the grid
    useEffect(() => {
        if (!isNilOrEmpty(entitySelectValue)) {
            navigate({
                pathname: location.pathname,
                search: entitySelectValue.toString(),
            });
        }
    }, [entitySelectValue]);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        navigate({
            pathname: Object.keys(ROUTES).find(
                (key) => (ROUTES as any)[key] === newValue
            ),
            search: entitySelectValue.toString(),
        });
        setValue(newValue);
    };

    return {
        handleChange,
        value,
        entitySelectValue,
        setEntitySelectValue,
        attributeEntities,
        isLoadingEntities,
    };
};

export default useAttributesView;
