import useSectionExchangeRateGrid, {
    ExchangeRateGridProps,
} from './hooks/useSectionExchangeRateGrid';
import React from 'react';
import BaseGrid from '../../../components/grids/BaseGrid';
import { Currency } from '../../../types/platform/i18n/Currency.type';
import DatacorLogoSpinner from '../../../components/datacorLogoSpinner/DatacorLogoSpinner';
import { isNilOrEmpty } from '../../../utils/objectUtils';

const SectionExchangeRateGrid = (props: ExchangeRateGridProps<Currency>) => {
    const {
        rowData,
        canViewExchangeRate,
        canCreateExchangeRate,
        colDefs,
        isLoadingExchangeRates,
        redirectToForm,
        defaultColumnDefinitionOptions,
    } = useSectionExchangeRateGrid(props);

    const { isCurrentEntityLoading } = props;

    return (
        <>
            {isCurrentEntityLoading ||
            isLoadingExchangeRates ||
            isNilOrEmpty(colDefs) ? (
                <DatacorLogoSpinner />
            ) : (
                <BaseGrid
                    autoSize={true}
                    displayGrid={canViewExchangeRate}
                    rowData={rowData}
                    columnDefs={colDefs}
                    gridClass="full-size-item"
                    onCreateNew={redirectToForm}
                    onRowClicked={({ data }) => redirectToForm(data)}
                    displayToolbar={true}
                    displayCreateNewButton={canCreateExchangeRate}
                    displayExportDataButton={false}
                    displayResetStateButton={false}
                    defaultColDef={defaultColumnDefinitionOptions}
                />
            )}
        </>
    );
};

export default SectionExchangeRateGrid;
