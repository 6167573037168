import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import React, { useState, useEffect, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { GridReadyEvent } from 'ag-grid-community';
import LaunchIcon from '@mui/icons-material/Launch';
import { Tooltip, IconButton } from '@mui/material';
import {
    BusinessEntity,
    useGetBusinessEntityChildrenQuery,
    useGetSubscriberQuery,
} from '../../../services/organizations/organizations.service';
import { skipToken } from '@reduxjs/toolkit/query';
import * as _ from 'lodash';
import useGetBusinessEntities from './useGetBusinessEntities';
import { updateGridModels } from '../../../store/grids';
import { RootState } from '../../../store';
import { PermissionsUtil } from '../../../utils/permissions/permissionsUtil';
import { PERMISSIONS } from '../../../constants/permissions/Permissions.constants';
import { useGetTermSetQuery } from '../../../services/i18n/i18n.service';

import SettingsContext from '../../../contexts/settings.context';
import { BusinessEntityGridDefs } from '../../../constants/i18n/translations/termDefinitions/platform';
import TranslatableText from '../../../components/i18n/TranslatableText';
import { PLATFORM_DEFS } from '../../../constants/i18n/translations/termSetDefinitions/platform';
import { isNilOrEmpty } from '../../../utils/objectUtils';

export interface BusinessEntityBreadcrumb {
    id: string;
    type: 'SUBSCRIBER' | 'BUSINESS_ENTITY';
    name: string;
}

const useBusinessEntityGrid = () => {
    const dispatch = useDispatch();
    const { administration } = useSelector((state: RootState) => state.grids);
    const { search } = useLocation();
    const navigate = useNavigate();
    const user = useSelector((state: RootStateOrAny) => state.user);
    const [rowData, setRowData] = useState(null);
    const [gridApi, setGridApi] = useState(null);
    const [businessEntityId, setBusinessEntityId] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const {
        data: businessEntityChildren,
        isLoading: isLoadingBusinessEntityChildren,
        refetch: refetchEntityChildren,
    } = useGetBusinessEntityChildrenQuery(
        businessEntityId ? businessEntityId : skipToken
    );
    const { settings } = useContext(SettingsContext);
    const { data: termSet } = useGetTermSetQuery(
        settings
            ? {
                  languageId: settings?.userSettings?.languageId,
                  code: PLATFORM_DEFS.BUSINESS_ENTITY_GRID,
              }
            : skipToken
    );
    const {
        businessEntities: subscriberBusinessEntities,
        isLoading: isLoadingSubscriberBusinessEntities,
    } = useGetBusinessEntities({
        businessEntityId: user?.activeBusinessEntityId,
        subscriberId: search
            ? search.split('=')[1]
            : user?.subscriber?.id.toString(),
    });

    const { data: subscriberInfo, isLoading: isLoadingSubscriberInfo } =
        useGetSubscriberQuery(search ? search.split('=')[1] : skipToken);
    //TODO: hook up to grid buttons
    const canEditBusinessEntity = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.ADMINISTRATION.BUSINESS_ENTITIES.EDIT
    );

    const canViewBusinessEntities = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.ADMINISTRATION.BUSINESS_ENTITIES.VIEW
    );

    const canCreateBusinessEntities = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.ADMINISTRATION.BUSINESS_ENTITIES.CREATE
    );

    const [gridColumnApi, setGridColumnApi] = useState(null);
    const [breadcrumbs, setBreadcrumbs] = useState([]);

    const onGridReady = (params: GridReadyEvent) => {
        setGridApi(params.api);
        setGridColumnApi(params.columnApi);
    };

    useEffect(() => {
        if (!isLoadingSubscriberInfo && subscriberInfo?.id) {
            setBreadcrumbs([
                {
                    id: subscriberInfo?.id,
                    name: subscriberInfo?.name,
                    type: 'SUBSCRIBER',
                },
            ]);
        }
    }, [subscriberInfo]);

    useEffect(() => {
        businessEntityId
            ? refetchEntityChildren()
            : setRowData(subscriberBusinessEntities);
    }, [businessEntityId, subscriberBusinessEntities]);

    useEffect(() => {
        setRowData(
            businessEntityId
                ? businessEntityChildren
                : subscriberBusinessEntities
        );
        setIsLoading(
            businessEntityId
                ? isLoadingBusinessEntityChildren
                : isLoadingSubscriberBusinessEntities
        );
    }, [subscriberBusinessEntities, businessEntityChildren, businessEntityId]);

    const redirectToForm = (row: any) => {
        if (row.id) {
            navigate(
                `/administration/organizations/business-entities/${row.id}`
            );
        } else {
            navigate('/administration/organizations/business-entities/new');
        }
    };

    /**
     * shows the view business entity button
     * we shouldn't show it if the BE has no children
     * @param row
     */
    const viewEntitiesColumn = (row: any) => {
        return (
            <Tooltip
                title={
                    <TranslatableText
                        termKey={BusinessEntityGridDefs.View_Business_Entities}
                        termSet={termSet}
                    />
                }
                placement="top">
                {!isNilOrEmpty(row?.data?.children) ? (
                    <IconButton
                        size="small"
                        color="primary"
                        ref={(ref) => {
                            if (!ref) {
                                return;
                            }

                            ref.onclick = (event) => {
                                redirectToBusinessEntities(row.data, event);
                            };
                        }}>
                        <LaunchIcon />
                    </IconButton>
                ) : (
                    <></>
                )}
            </Tooltip>
        );
    };

    const navigateToPreviousEntity = (entity: BusinessEntityBreadcrumb) => {
        if (entity.type === 'SUBSCRIBER') {
            setBreadcrumbs([breadcrumbs[0]]);
            setBusinessEntityId(null);
            return;
        }

        const index = _.findIndex(breadcrumbs, {
            id: entity.id,
            type: 'BUSINESS_ENTITY',
        });

        const crumbs = [...breadcrumbs];
        // remove all breadcrumbs starting from 1 after the one we clicked
        crumbs.splice(index + 1, Infinity);
        setBreadcrumbs(crumbs);

        setBusinessEntityId(entity.id);
    };

    const redirectToBusinessEntities = (
        businessEntity: BusinessEntity,
        event: any
    ) => {
        event.stopPropagation();
        setBusinessEntityId(businessEntity.id);
        setBreadcrumbs((prev) => [
            ...prev,
            {
                id: businessEntity.id,
                name: businessEntity.name,
                type: 'BUSINESS_ENTITY',
            },
        ]);
    };

    const onFirstDataRendered = () => {
        gridColumnApi?.applyColumnState({
            state: administration.businessEntityList.column,
            applyOrder: true,
        });

        gridApi?.setFilterModel(administration.businessEntityList.filter);
    };

    const onSortChanged = () => {
        dispatch(
            updateGridModels({
                gridLocation: 'administration',
                gridName: 'businessEntityList',
                type: 'column',
                model: gridColumnApi.getColumnState(),
            })
        );
    };

    return {
        breadcrumbs,
        onGridReady,
        rowData,
        isLoading,
        redirectToForm,
        viewEntitiesColumn,
        navigateToPreviousEntity,
        onFirstDataRendered,
        onSortChanged,
        canCreateBusinessEntities,
        termSet,
    };
};

export default useBusinessEntityGrid;
