import React from 'react';
import RDFormulaGrid from './grid/RDFormulaGrid';

const RDFormula = () => {
    return (
        <>
            <RDFormulaGrid />
        </>
    );
};

export default RDFormula;
