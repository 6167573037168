import React from 'react';
import BaseFormGrid from '../../components/grids/BaseFormGrid';
import useAttributeDefinitionsGrid from './hooks/useAttributeDefinitionsGrid';
import { isNilOrEmpty } from '../../utils/objectUtils';

interface AttributeDefinitionsGridProps {
    attrEntityId: string;
}
const AttributeDefinitionsGrid = ({
    attrEntityId,
}: AttributeDefinitionsGridProps) => {
    const {
        redirectToForm,
        attributeTypesColDef,
        canManageAttribute,
        attributeDefinitions,
        isLoadingDefinitions,
        frameworkComponents,
    } = useAttributeDefinitionsGrid(attrEntityId);

    return (
        <>
            {!isNilOrEmpty(attrEntityId) ? (
                <BaseFormGrid
                    displayGrid={!isNilOrEmpty(attrEntityId)}
                    isLoading={isLoadingDefinitions}
                    rowData={attributeDefinitions}
                    columnDefs={attributeTypesColDef}
                    frameworkComponents={frameworkComponents}
                    gridClass="full-size-item"
                    displayToolbar={true}
                    displayCreateNewButton={canManageAttribute}
                    onCreateNew={redirectToForm}
                    onRowClicked={({ data }) => redirectToForm(data)}
                />
            ) : (
                <div>Please select an Entity from the dropdown above.</div>
            )}
        </>
    );
};

export default AttributeDefinitionsGrid;
