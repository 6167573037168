import React, { useContext, useEffect, useState } from 'react';
import { useForm } from '../../../../libs/hooksLib';
import {
    useGetAllFixedAssetDepreciationEntriesQuery,
    useGetDepreciationMethodTypesForEntriesQuery,
    useGetTypesForDropdownQuery,
} from '../../../../services/fixedAssets/fixedAssets.service';
import { skipToken } from '@reduxjs/toolkit/query';
import {
    FA_DepreciationEntry,
    FixedAssetDepreciationEntryListParams,
} from '../../../../types/FixedAsset.types';
import { SelectionOption } from '../../../../types/Shared.types';
import { isNil, isNilOrEmpty } from '../../../../utils/objectUtils';
import SettingsContext from '../../../../contexts/settings.context';
import { useGetTermSetQuery } from '../../../../services/i18n/i18n.service';
import { ACCOUNTING_DEFS } from '../../../../constants/i18n/translations/termSetDefinitions/accounting';

const useFixedAssetDepreciationEntriesOptionsModal = (
    onEntriesLoaded: (entries: FA_DepreciationEntry[]) => void,
    onClose: () => void
) => {
    const { settings } = useContext(SettingsContext);
    const { data: termSet } = useGetTermSetQuery(
        settings?.userSettings
            ? {
                  languageId: settings?.userSettings?.languageId,
                  code: ACCOUNTING_DEFS.DEPRECIATION_ENTRIES_GRID,
              }
            : skipToken
    );
    const { data: methodTypeOptions, isLoading: loadingMethodTypes } =
        useGetDepreciationMethodTypesForEntriesQuery();
    const { data: assetTypeOptions, isLoading: loadingAssetTypes } =
        useGetTypesForDropdownQuery();

    const [queryParams, setQueryParams] =
        useState<FixedAssetDepreciationEntryListParams>(null);
    const { data: depreciationEntryList, isFetching: isLoadingEntries } =
        useGetAllFixedAssetDepreciationEntriesQuery(
            queryParams ? queryParams : skipToken
        );

    const { fields, handleFieldChange } = useForm({
        methodTypes: [],
        startDate: null,
        endDate: null,
        assetTypes: [],
    });

    const handleGetEntries = (event: any) => {
        event.preventDefault();

        const listParams: FixedAssetDepreciationEntryListParams = {};

        if (!isNilOrEmpty(fields.methodTypes)) {
            listParams['methodTypeIds'] = fields.methodTypes.map(
                (option: SelectionOption) => option.value
            );
        }

        if (!isNilOrEmpty(fields.assetTypes)) {
            listParams['assetTypeIds'] = fields.assetTypes.map(
                (option: SelectionOption) => option.value
            );
        }

        if (!isNil(fields.startDate)) {
            listParams['startDate'] = fields.startDate;
        }

        if (!isNil(fields.endDate)) {
            listParams['endDate'] = fields.endDate;
        }

        setQueryParams(listParams);
    };

    useEffect(() => {
        if (depreciationEntryList) {
            onEntriesLoaded(depreciationEntryList);
            onClose();
        }
    }, [depreciationEntryList]);

    return {
        queryParams,
        fields,
        handleFieldChange,
        methodTypeOptions,
        loadingMethodTypes,
        assetTypeOptions,
        loadingAssetTypes,
        handleGetEntries,
        isLoadingEntries,
        termSet,
    };
};

export default useFixedAssetDepreciationEntriesOptionsModal;
