import { StatementType } from '../../../../types/formulation';
import { datacorAppApi } from '../../../datacorAppApi';

const serviceUrl: string = 'statement-types';

export const statementTypesApi = datacorAppApi.injectEndpoints({
    endpoints: (build) => ({
        getAllStatementTypes: build.query<StatementType[], void>({
            query: () => serviceUrl,
            providesTags: ['StatementTypes'],
        }),
        getStatementTypesForDropdown: build.query<
            { label: string; value: bigint }[],
            void
        >({
            query: () => serviceUrl,
            transformResponse: (response: StatementType[]) => {
                return response.map((statementType) => {
                    return {
                        label: statementType.description,
                        value: statementType.id,
                    };
                });
            },
            providesTags: ['StatementTypes'],
        }),
    }),
});

export const {
    useGetAllStatementTypesQuery,
    useGetStatementTypesForDropdownQuery,
} = statementTypesApi;
