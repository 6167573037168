import React, { useMemo } from 'react';
import { ColDef, GetDetailRowDataParams } from 'ag-grid-community';
import DefaultColumnTypes from '../../../components/grids/columns/Column.constants';
import useFixedAssetDisposalEntriesGrid, {
    EntryGridMode,
} from './hooks/useFixedAssetDisposalEntriesGrid';
import FixedAssetDisposalEntriesOptionsModal from './FixedAssetDisposalEntriesOptionsModal';
import BaseFormGrid from '../../../components/grids/BaseFormGrid';
import { Button } from '@mui/material';
import ListIcon from '@mui/icons-material/List';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import FixedAssetDisposalEntriesActionsModal from './FixedAssetDisposalEntriesActionsModal';
import FixedAssetDisposalEntriesExportModal from './FixedAssetDisposalEntriesExportModal';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { DisposalEntriesGridDefs } from '../../../constants/i18n/translations/termDefinitions/accounting';
import TranslatableText from '../../../components/i18n/TranslatableText';

const FixedAssetDisposalEntriesGrid = () => {
    const {
        showOptionsModal,
        setShowOptionsModal,
        showActionsModal,
        setShowActionsModal,
        showExportModal,
        setShowExportModal,
        setAssetDisposalEntryList,
        mainEntryRows,
        mainDisposalDateColumnRenderer,
        mainAssetTypeColumnRenderer,
        mainAccountNumberColumnRenderer,
        mainAccountNameColumnRenderer,
        mainDebitColumnRenderer,
        mainCreditColumnRenderer,
        detailAccountNumberColumnRenderer,
        detailAccountNameColumnRenderer,
        detailDebitColumnRenderer,
        detailCreditColumnRenderer,
        canViewDisposalEntries,
        canLockDisposalEntries,
        canDownloadDisposalEntries,
        handleActionsButton,
        handleExportButton,
        handleReturnToMainGrid,
        selectedEntries,
        exportedEntries,
        hasSelectedRows,
        hasUnlockedSelected,
        onSelectionChanged,
        onGridReady,
        currentGridMode,
        currentTitle,
        termSet,
    } = useFixedAssetDisposalEntriesGrid();

    const disposalEntryColumnDefs: ColDef[] = [
        {
            headerComponentFramework: (props: any) => {
                return (
                    <TranslatableText
                        termSet={termSet}
                        termKey={DisposalEntriesGridDefs.Selected}
                    />
                );
            },
            headerCheckboxSelection: true,
            hide: currentGridMode !== EntryGridMode.Default,
            ...DefaultColumnTypes.CheckboxSelection,
        },
        {
            field: 'disposalEntry.disposalDate',
            headerComponentFramework: (props: any) => {
                return (
                    <TranslatableText
                        termSet={termSet}
                        termKey={DisposalEntriesGridDefs.Disposal_Date}
                    />
                );
            },
            sort: 'asc',
            cellRenderer: 'agGroupCellRenderer',
            cellRendererParams: {
                innerRenderer: 'disposalDateColumnRenderer',
            },
            hide: currentGridMode !== EntryGridMode.Default,
            ...DefaultColumnTypes.DateOnly,
        },
        {
            field: 'ledgerAssetType.code',
            headerComponentFramework: (props: any) => {
                return (
                    <TranslatableText
                        termSet={termSet}
                        termKey={DisposalEntriesGridDefs.Fixed_Asset_Type}
                    />
                );
            },
            cellRenderer: 'assetTypeColumnRenderer',
            hide: currentGridMode !== EntryGridMode.Default,
            ...DefaultColumnTypes.MediumText,
        },
        {
            field: 'disposalEntry.disposalDate',
            headerComponentFramework: (props: any) => {
                return (
                    <TranslatableText
                        termSet={termSet}
                        termKey={DisposalEntriesGridDefs.Disposal_Date}
                    />
                );
            },
            cellRenderer: 'agGroupCellRenderer',
            hide: currentGridMode !== EntryGridMode.ByAssetType,
            ...DefaultColumnTypes.DateOnly,
        },
        {
            field: 'ledgerAssetType.code',
            headerComponentFramework: (props: any) => {
                return (
                    <TranslatableText
                        termSet={termSet}
                        termKey={DisposalEntriesGridDefs.Fixed_Asset_Type}
                    />
                );
            },
            cellRenderer: 'agGroupCellRenderer',
            hide: currentGridMode !== EntryGridMode.ByDate,
            minWidth: 150,
            maxWidth: 150,
            ...DefaultColumnTypes.ShortText,
        },
        {
            field: 'mainLockedSummary',
            headerComponentFramework: (props: any) => {
                return (
                    <TranslatableText
                        termSet={termSet}
                        termKey={DisposalEntriesGridDefs.Locked}
                    />
                );
            },
            minWidth: 100,
            maxWidth: 100,
            ...DefaultColumnTypes.StackedColumn,
        },
        {
            field: 'mainAccountNumberList',
            headerComponentFramework: (props: any) => {
                return (
                    <TranslatableText
                        termSet={termSet}
                        termKey={DisposalEntriesGridDefs.Account_Number}
                    />
                );
            },
            cellRenderer: 'accountNumberCellRender',
            ...DefaultColumnTypes.MediumText,
        },
        {
            field: 'mainAccountNameList',
            headerComponentFramework: (props: any) => {
                return (
                    <TranslatableText
                        termSet={termSet}
                        termKey={DisposalEntriesGridDefs.Entry_Description}
                    />
                );
            },
            cellRenderer: 'accountNameCellRender',
            ...DefaultColumnTypes.LongText,
        },

        {
            field: 'mainDebitList',
            headerComponentFramework: (props: any) => {
                return (
                    <TranslatableText
                        termSet={termSet}
                        termKey={DisposalEntriesGridDefs.Debit}
                    />
                );
            },
            cellRenderer: 'debitCellRender',
            ...DefaultColumnTypes.Currency,
        },
        {
            field: 'mainCreditList',
            headerComponentFramework: (props: any) => {
                return (
                    <TranslatableText
                        termSet={termSet}
                        termKey={DisposalEntriesGridDefs.Credit}
                    />
                );
            },
            cellRenderer: 'creditCellRender',
            ...DefaultColumnTypes.Currency,
        },
    ];

    const detailCellRendererParams = useMemo(() => {
        return {
            detailGridOptions: {
                frameworkComponents: {
                    detailAccountNoCellRenderer:
                        detailAccountNumberColumnRenderer,
                    detailAccountNameCellRenderer:
                        detailAccountNameColumnRenderer,
                    detailDebitCellRenderer: detailDebitColumnRenderer,
                    detailCreditCellRenderer: detailCreditColumnRenderer,
                },
                columnDefs: [
                    {
                        field: 'disposalEntry.disposalDate',
                        headerComponentFramework: (props: any) => {
                            return (
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={
                                        DisposalEntriesGridDefs.Disposal_Date
                                    }
                                />
                            );
                        },
                        ...DefaultColumnTypes.DateOnly,
                    },
                    {
                        field: 'fixedAsset.code',
                        headerComponentFramework: (props: any) => {
                            return (
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={DisposalEntriesGridDefs.Asset_Code}
                                />
                            );
                        },
                        ...DefaultColumnTypes.MediumText,
                    },
                    {
                        field: 'lockedSummary',
                        headerComponentFramework: (props: any) => {
                            return (
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={DisposalEntriesGridDefs.Locked}
                                />
                            );
                        },
                        ...DefaultColumnTypes.LockedInformationColumn,
                    },
                    {
                        field: 'detailAccountNumberList',
                        headerComponentFramework: (props: any) => {
                            return (
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={
                                        DisposalEntriesGridDefs.Account_Number
                                    }
                                />
                            );
                        },
                        cellRenderer: 'detailAccountNoCellRenderer',
                        ...DefaultColumnTypes.LongText,
                    },
                    {
                        field: 'detailAccountNameList',
                        headerComponentFramework: (props: any) => {
                            return (
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={
                                        DisposalEntriesGridDefs.Entry_Description
                                    }
                                />
                            );
                        },
                        cellRenderer: 'detailAccountNameCellRenderer',
                        ...DefaultColumnTypes.LongText,
                    },
                    {
                        field: 'detailDebitList',
                        headerComponentFramework: (props: any) => {
                            return (
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={DisposalEntriesGridDefs.Debit}
                                />
                            );
                        },
                        cellRenderer: 'detailDebitCellRenderer',
                        ...DefaultColumnTypes.Currency,
                    },
                    {
                        field: 'detailCreditList',
                        headerComponentFramework: (props: any) => {
                            return (
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={DisposalEntriesGridDefs.Credit}
                                />
                            );
                        },
                        cellRenderer: 'detailCreditCellRenderer',
                        ...DefaultColumnTypes.Currency,
                    },
                ],
            },
            getDetailRowData: (params: GetDetailRowDataParams) => {
                params.successCallback(params.data.detailDisposalEntries);
            },
        };
    }, [termSet]);

    const mainGridToolbar = () => {
        return (
            <React.Fragment>
                {currentGridMode !== EntryGridMode.Default ? (
                    <Button
                        variant="text"
                        size="small"
                        onClick={() => handleReturnToMainGrid()}
                        startIcon={<ArrowBackIcon />}>
                        <TranslatableText
                            termSet={termSet}
                            termKey={DisposalEntriesGridDefs.Back}
                        />
                    </Button>
                ) : null}
                {currentGridMode === EntryGridMode.Default ? (
                    <Button
                        variant="text"
                        size="small"
                        onClick={() => setShowOptionsModal(true)}
                        startIcon={<ListIcon />}>
                        <TranslatableText
                            termSet={termSet}
                            termKey={DisposalEntriesGridDefs.Toolbar_Options}
                        />
                    </Button>
                ) : null}
                {canLockDisposalEntries ? (
                    <Button
                        variant="text"
                        size="small"
                        disabled={!hasSelectedRows}
                        onClick={() => handleActionsButton()}
                        startIcon={
                            <ArrowForwardIcon style={{ color: '#00A84E' }} />
                        }>
                        <TranslatableText
                            termSet={termSet}
                            termKey={DisposalEntriesGridDefs.Toolbar_Actions}
                        />
                    </Button>
                ) : null}
                {canDownloadDisposalEntries ? (
                    <Button
                        variant="text"
                        size="small"
                        disabled={hasUnlockedSelected}
                        onClick={() => handleExportButton()}
                        startIcon={<FileDownloadIcon />}>
                        <TranslatableText
                            termSet={termSet}
                            termKey={
                                DisposalEntriesGridDefs.Toolbar_Download_Entries
                            }
                        />
                    </Button>
                ) : null}
            </React.Fragment>
        );
    };

    return (
        <React.Fragment>
            <FixedAssetDisposalEntriesOptionsModal
                open={showOptionsModal}
                onDisposalEntriesLoaded={(assetDisposal) =>
                    setAssetDisposalEntryList(assetDisposal)
                }
                onClose={() => setShowOptionsModal(false)}
            />

            <FixedAssetDisposalEntriesExportModal
                open={showExportModal}
                selected={exportedEntries}
                onClose={() => setShowExportModal(false)}
            />

            <FixedAssetDisposalEntriesActionsModal
                open={showActionsModal}
                selected={selectedEntries}
                onClose={() => setShowActionsModal(false)}
            />

            <BaseFormGrid
                title={currentTitle}
                defaultColDef={{ flex: 1 }}
                displayGrid={canViewDisposalEntries}
                isLoading={false}
                rowData={mainEntryRows}
                columnDefs={disposalEntryColumnDefs}
                gridClass="full-size-item"
                displayToolbar={true}
                rowSelection={'multiple'}
                masterDetail={true}
                suppressRowClickSelection={true}
                detailRowAutoHeight={true}
                detailCellRendererParams={detailCellRendererParams}
                toolbarChildren={mainGridToolbar()}
                onGridReady={onGridReady}
                onSelectionChanged={onSelectionChanged}
                frameworkComponents={{
                    disposalDateColumnRenderer: mainDisposalDateColumnRenderer,
                    assetTypeColumnRenderer: mainAssetTypeColumnRenderer,
                    accountNumberCellRender: mainAccountNumberColumnRenderer,
                    accountNameCellRender: mainAccountNameColumnRenderer,
                    debitCellRender: mainDebitColumnRenderer,
                    creditCellRender: mainCreditColumnRenderer,
                }}
            />
        </React.Fragment>
    );
};

export default FixedAssetDisposalEntriesGrid;
