import React, { useContext, useEffect, useState } from 'react';
import {
    CasGhsHazardPhrase,
    CasMaster,
    GhsHazardPhrase,
} from '../../../../../../../../../types/formulation';
import { isNilOrEmpty } from '../../../../../../../../../utils/objectUtils';
import { RowStatus } from '../../../../../../../../../components/grids/hooks/useBaseGridEditable';
import { ColDef } from 'ag-grid-community';
import { editableGridCellSelect } from '../../../../../../../../../components/grids/columns/editableGridCellSelect';
import {
    Language,
    useGetLanguagesQuery,
} from '../../../../../../../../../services/organizations/organizations.service';
import { ColumnRequiredValueValidator } from '../../../../../../../../grid/validators/columnRequiredValue.validator';
import { ColumnMaxValueValidator } from '../../../../../../../../grid/validators/columnMaxValue.validator';
import { ColumnNumericValueValidator } from '../../../../../../../../grid/validators/columnNumericValue.validator';
import applyEditableGridValidations from '../../../../../../../../grid/utils/applyEditableGridValidations';
import { ColumnMinValueValidator } from '../../../../../../../../grid/validators/columnMinValue.validator';
import { PermissionsUtil } from '../../../../../../../../../utils/permissions/permissionsUtil';
import _, { cloneDeep } from 'lodash';
import { PERMISSIONS } from '../../../../../../../../../constants/permissions/Permissions.constants';
import { useListHazardTypesQuery } from '../../../../../../../../../services/formulation/ghsHazardPhrases/hazardTypes/hazardTypes.service';
import { useListGhsHazardPhrasesQuery } from '../../../../../../../../../services/formulation/ghsHazardPhrases/ghsHazardPhrases.service';
import { ChildEditableGridWithCopyFunctionProps } from '../../../../../../../../grid/utils/editableGrid/ChildEditableGridWithCopyFunctionProps';
import SettingsContext from '../../../../../../../../../contexts/settings.context';
import { useGetTermSetQuery } from '../../../../../../../../../services/i18n/i18n.service';
import { FORMULATION_DEFS } from '../../../../../../../../../constants/i18n/translations/termSetDefinitions/formulation';
import { skipToken } from '@reduxjs/toolkit/query';
import CustomHeader from '../../../../../../../../../components/grids/CustomHeader';
import { CasMasterFormDefs } from '../../../../../../../../../constants/i18n/translations/termDefinitions/formulation';

const useCasGhsHazardPhrasesGrid = (
    props: ChildEditableGridWithCopyFunctionProps<CasMaster, CasGhsHazardPhrase>
) => {
    const { currentParentRecord, user } = props.parentData;

    const { showDeleteButton, isGridEditable } = props.displayGridButtons;

    const { handleChildrenRecords } = props.helpers;

    const { rowsData, setRowsData } = props.copyMethods;

    const [maxRowId, setMaxRowId] = useState(0);

    const canViewGhsHazardPhrase = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.FORMULATION.GHS_HAZARD_PHRASE.VIEW
    );

    const [showMultiSelectGridModal, setShowMultiSelectGridModal] =
        useState(false);

    const [hazardPhraseList, setHazardPhraseList] = useState(
        null as GhsHazardPhrase[]
    );

    const [columnDefs, setColumnDefs] = useState(null as ColDef[]);

    const [showVerbiageMessage, setShowVerbiageMessage] = useState(false);

    const { data: languagesList, isLoading: isLoadingLanguages } =
        useGetLanguagesQuery();

    const { data: hazardTypeOptionsList, isLoading: isLoadingHazardTypes } =
        useListHazardTypesQuery();

    const { data: rawHazardPhraseList, isLoading: isLoadingHazardPhraseList } =
        useListGhsHazardPhrasesQuery();

    let hazardPhraseListOnlyEnglish = _.filter(rawHazardPhraseList, {
        langId: 4 as unknown as bigint,
    });

    const { settings } = useContext(SettingsContext);
    const { data: termSet } = useGetTermSetQuery(
        settings?.userSettings
            ? {
                  languageId: settings?.userSettings?.languageId,
                  code: FORMULATION_DEFS.CAS_MASTER_FORM,
              }
            : skipToken
    );

    /**
     * sorts records in casGhsHazard grid according to sdsSortOrder, then phraseId
     * @param casGhsHazardPhraseRecords
     */
    const sortCasGhsHazardPhraseRecords = (
        casGhsHazardPhraseRecords: CasGhsHazardPhrase[]
    ) => {
        const filteredList = casGhsHazardPhraseRecords.filter(
            (current) => current.rowStatus !== RowStatus.DELETED
        );
        setRowsData(
            _.orderBy(filteredList, ['sdsSortOrder', 'phraseId'], ['asc'])
        );
    };

    /**
     * removes select options from multiselect modal data
     * if phrase is already present in CasGhsHazards grid
     */
    const removeCasGhsHazardsFromHazardsList = (): GhsHazardPhrase[] => {
        // identifies hazard phrases that are in casGhsHazard grid
        // finds their matching record in list used for multiselect
        _.forEach(rowsData, function (casGhsRecord: CasGhsHazardPhrase) {
            const matchingGhsRecord = _.find(
                hazardPhraseListOnlyEnglish,
                (current: GhsHazardPhrase) => {
                    // rows that are marked deleted we don't filter out
                    // so that they can be added again via multiselect
                    return (
                        current.phraseId === casGhsRecord.phraseId &&
                        casGhsRecord.rowStatus !== RowStatus.DELETED
                    );
                }
            );

            // if there are matching records, we remove them from filtered list
            if (matchingGhsRecord) {
                hazardPhraseListOnlyEnglish = _.without(
                    hazardPhraseListOnlyEnglish,
                    matchingGhsRecord
                );
            }
        });
        // returns hazardPhrase list sorted and filtered
        return _.sortBy(hazardPhraseListOnlyEnglish, ['phraseId']);
    };

    /**
     * filters out records found in casGhsHazards table for multiselect modal
     * then sets them for the multiselect modal to use
     */
    const setFilteredHazardPhraseList = () => {
        if (canViewGhsHazardPhrase) {
            const filteredGhsHazardsList = removeCasGhsHazardsFromHazardsList();
            setHazardPhraseList(filteredGhsHazardsList);
        } else {
            setHazardPhraseList([]);
        }
    };

    /**
     * Gets language code for display in casGhsHazard grid
     * @param langId
     */
    const getLanguageCodeById = (langId: bigint): string => {
        let language = languagesList
            ? languagesList.find((current: Language) => current.id === langId)
            : null;
        return language?.code;
    };

    /**
     * Builds list of selected Ghs Hazard Phrases in format
     * required for CAS Ghs Hazard Phrases grid
     * @param selectedRecords
     * @param index
     */
    const buildHazardListFromSelectedRecords = (
        selectedRecords: GhsHazardPhrase[],
        index: number
    ) => {
        return selectedRecords.map((current) => {
            index = index + 1;
            const newRecord: CasGhsHazardPhrase = {
                phraseId: current.phraseId,
                phraseText: current.phraseText,
                hazardTypeId: current.hazardTypeId,
                businessEntityId: current.businessEntityId,
                langId: current.langId,
                rowStatus: RowStatus.ADDED,
                rowId: index,
            };
            return newRecord;
        });
    };

    /**
     * Adds selected records to CAS GHS Hazard Phrases
     * @param selectedRecords
     */
    const handleSelectedRecords = (selectedRecords: GhsHazardPhrase[]) => {
        let index = maxRowId;
        //build hazard list from selected records and assign rowIds
        const casHazardList = buildHazardListFromSelectedRecords(
            selectedRecords,
            index
        );

        // get the new max row id from the hazard list
        index = _.maxBy(casHazardList, 'rowId').rowId;

        setMaxRowId((maxRowId) => maxRowId + index);

        //add hazard list to the existing CAS GHS hazard phrase records
        updateHazardPhraseRecords(casHazardList);

        //show verbiage message if user input is needed
        userInputNeededCheck(selectedRecords);
    };

    /**
     * Show the verbiage message if any of the selected records is company specific
     * @param selectedRecords
     */
    const userInputNeededCheck = (selectedRecords: GhsHazardPhrase[]) => {
        const userInputNeededRecord = selectedRecords?.find(
            (current) => current.companySpecific === true
        );
        if (userInputNeededRecord) {
            setShowVerbiageMessage(true);
        }
    };

    /**
     * Concatenates any non-deleted rows with the new added hazard list
     * @param hazardList
     */
    const updateHazardPhraseRecords = (hazardList: CasGhsHazardPhrase[]) => {
        handleRowValidations(hazardList);
        handleSaveGridEdits(hazardList);
    };

    /**
     * This triggers when 'save edits' is clicked
     * pushes casGhsHazardPhrase object to CAS master record
     * @param editedRows
     */
    const handleSaveGridEdits = (editedRows: CasGhsHazardPhrase[]) => {
        const updatedFields: CasGhsHazardPhrase[] = [...rowsData];
        editedRows?.forEach((casGhsHazardPhrase) => {
            // finds record index
            const index = rowsData?.findIndex(
                (row) => row.rowId === casGhsHazardPhrase.rowId
            );

            if (isNilOrEmpty(casGhsHazardPhrase.sdsSortOrder)) {
                casGhsHazardPhrase.sdsSortOrder = null;
            }

            // if index is greater than -1 sets updatedFields index to index
            if (index > -1) {
                updatedFields[index] = casGhsHazardPhrase;
                // if no index, adds record to updatedFields
            } else {
                updatedFields.push(casGhsHazardPhrase);
            }
        });

        handleChildrenRecords('casGhsHazardPhrases', updatedFields);
        sortCasGhsHazardPhraseRecords(updatedFields);
    };

    /**
     * used for displaying message when record is selected from multiselect
     * message will display when record has 'companySpecific' marked true
     * @param showMessage
     */
    const handleShowVerbiageMessage = (showMessage: boolean) => {
        setShowVerbiageMessage(showMessage);
    };

    /**
     * consumes casGhsHazardPhraseList from parent casRecord
     * adds rowId to each row based on index if none is found
     * sets maxRowId based on last items index
     * @param currentList
     */
    const setCasHazardPhraseListData = (currentList: CasGhsHazardPhrase[]) => {
        const newList = cloneDeep(
            currentList.filter(
                (current) => current.rowStatus !== RowStatus.DELETED
            )
        );
        let currentRowId = maxRowId;
        newList.forEach((current) => {
            if (!current.rowId) {
                current.rowId = ++currentRowId;
            }
        });
        setMaxRowId(currentRowId);
        return newList;
    };

    /**
     * sets validations on each of row fields in CasGhsHazardPhrase grid
     * @param editedRows
     */
    const handleRowValidations = (editedRows: CasGhsHazardPhrase[]) => {
        editedRows?.forEach((current) => {
            current.validationRules = [
                ColumnRequiredValueValidator('Phrase ID', current.phraseId),
                ColumnRequiredValueValidator('Phrase Text', current.phraseText),
                ColumnMaxValueValidator('Phrase Text', 255, current.phraseText),
                ColumnNumericValueValidator(
                    'SDS Sort Order',
                    0,
                    current.sdsSortOrder
                ),
                ColumnMinValueValidator(
                    'SDS Sort Order',
                    0,
                    Number(current.sdsSortOrder)
                ),
            ];
            applyEditableGridValidations(current, `Phrase ${current.phraseId}`);
        });
        return editedRows;
    };

    /**
     * used to manage multiselect modal state
     * filters hazard phrase list here to guarantee the list in the modal is accurate
     * @param showModal
     */
    const handleSetShowMultiSelectGridModal = (showModal: boolean) => {
        if (showModal) {
            setFilteredHazardPhraseList();
        }
        setShowMultiSelectGridModal(showModal);
    };

    /**
     * triggers on load of hazard phrase list
     * filters hazardPhraseList for multiselect
     * conditionally sets casGhsHazardPhraseRecords and casHazardPhraseListData
     */
    useEffect(() => {
        if (isLoadingHazardPhraseList === false) {
            if (isNilOrEmpty(hazardPhraseList)) {
                setFilteredHazardPhraseList();

                const includeEditedRows =
                    currentParentRecord?.casGhsHazardPhrases
                        ? !isNilOrEmpty(
                              currentParentRecord.casGhsHazardPhrases.find(
                                  (current) => current.rowStatus
                              )
                          )
                        : false;

                if (
                    currentParentRecord &&
                    currentParentRecord.casGhsHazardPhrases &&
                    !includeEditedRows
                ) {
                    sortCasGhsHazardPhraseRecords(
                        setCasHazardPhraseListData(
                            currentParentRecord.casGhsHazardPhrases
                        )
                    );
                }
            }
        }
    }, [isLoadingHazardPhraseList]);

    /**
     * triggers when casGhsHazardPhraseRecords is set/altered
     * sets filtered Hazard Phrase List, used in multiselect modal
     */
    useEffect(() => {
        setFilteredHazardPhraseList();
    }, [rowsData]);

    /**
     * triggers when cas record with data is present/altered
     * sorts and sets records from parent CAS record to casGhsHazards grid
     */
    useEffect(() => {
        const includeEditedRows = currentParentRecord?.casGhsHazardPhrases
            ? !isNilOrEmpty(
                  currentParentRecord.casGhsHazardPhrases.find(
                      (current) => current.rowStatus
                  )
              )
            : false;
        if (
            currentParentRecord &&
            currentParentRecord.id &&
            currentParentRecord.casGhsHazardPhrases &&
            !includeEditedRows
        ) {
            sortCasGhsHazardPhraseRecords(
                setCasHazardPhraseListData(
                    currentParentRecord.casGhsHazardPhrases
                )
            );
        }
    }, [currentParentRecord?.casGhsHazardPhrases]);

    /**
     * triggers when data is loaded, sets columns for casGhsHazards grid
     */
    useEffect(() => {
        if (hazardTypeOptionsList && !isLoadingHazardTypes && languagesList) {
            const currentColDefList: ColDef[] = [
                {
                    field: 'deleteColumn',
                    minWidth: 50,
                    hide: !showDeleteButton || !isGridEditable,
                    filter: false,
                },
                {
                    field: 'phraseId',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={
                                    CasMasterFormDefs.Ghs_Hazard_Phrases_Phrase_ID
                                }
                            />
                        );
                    },
                    minWidth: 250,
                    filter: 'agTextColumnFilter',
                    editable: false,
                },
                {
                    field: 'phraseText',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={
                                    CasMasterFormDefs.Ghs_Hazard_Phrases_Phrase_text
                                }
                            />
                        );
                    },
                    minWidth: 500,
                    filter: 'agTextColumnFilter',
                    editable: true,
                },
                {
                    field: 'hazardTypeId',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={
                                    CasMasterFormDefs.Ghs_Hazard_Phrases_Hazard_Type
                                }
                            />
                        );
                    },
                    minWidth: 300,
                    ...editableGridCellSelect(hazardTypeOptionsList),
                    editable: isGridEditable,
                },
                {
                    field: 'langId',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={
                                    CasMasterFormDefs.Ghs_Hazard_Phrases_Language
                                }
                            />
                        );
                    },
                    minWidth: 100,
                    filter: 'agTextColumnFilter',
                    valueGetter: (params) =>
                        getLanguageCodeById(params.data.langId),
                    filterParams: {
                        valueGetter: (params: any) =>
                            getLanguageCodeById(params.data['langId']),
                    },
                },
            ];
            setColumnDefs(currentColDefList);
        }
    }, [languagesList, hazardTypeOptionsList]);

    return {
        rowsData,
        hazardPhraseList,
        showMultiSelectGridModal,
        columnDefs,
        showVerbiageMessage,
        handleSetShowMultiSelectGridModal,
        handleSelectedRecords,
        handleSaveGridEdits,
        handleShowVerbiageMessage,
        handleRowValidations,
        termSet,
    };
};

export default useCasGhsHazardPhrasesGrid;
