import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useFormFields } from '../../../libs/hooksLib';
import { isNilOrEmpty } from '../../../utils/objectUtils';
import { Auth } from 'aws-amplify';
import { showNotificationError } from '../../../libs/errorLib';
import { useDispatch } from 'react-redux';
import { useUpdateUserEmailMutation } from '../../../services/organizations/organizations.service';
import { refreshToken } from '../../../store/user';

type ParamTypes = { email: string };

const useChangeEmailConfirmation = () => {
    const { email } = useParams<ParamTypes>();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [fields, handleFieldChange] = useFormFields({
        code: '',
    });

    const [updateUserEmail] = useUpdateUserEmailMutation();

    const [isConfirming, setIsConfirming] = useState(false);

    function validateConfirmForm() {
        return !isNilOrEmpty(fields.code);
    }

    async function handleConfirmClick(
        event: React.MouseEvent<HTMLButtonElement>
    ) {
        event.preventDefault();
        setIsConfirming(true);

        try {
            await Auth.verifyCurrentUserAttributeSubmit('email', fields.code);
            await updateUserEmail(email.toLowerCase());
            await dispatch(refreshToken());

            navigate('/myApps');
        } catch (error) {
            showNotificationError(error, 'error', dispatch);
            setIsConfirming(false);
        }
    }

    return {
        fields,
        handleFieldChange,
        handleConfirmClick,
        isConfirming,
        validateConfirmForm,
    };
};

export default useChangeEmailConfirmation;
