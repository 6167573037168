import React from 'react';
import useFixedAssetsCostCodeGrid from './hooks/useFixedAssetsCostCodeGrid';
import BaseFormGrid from '../../components/grids/BaseFormGrid';
import { cloneDeep } from 'lodash';
import TranslatableText from '../../components/i18n/TranslatableText';
import { AssetCostCodesGridDefs } from '../../constants/i18n/translations/termDefinitions/accounting';
import { isNilOrEmpty } from '../../utils/objectUtils';

const FixedAssetCostCodeGrid = () => {
    const {
        costCodeList,
        isLoadingCostCodes,
        canCreateAssetCostCode,
        canViewAssetCostCode,
        canUpdate,
        redirectToForm,
        handleGridEdits,
        rowData,
        colDefs,
        termSet,
    } = useFixedAssetsCostCodeGrid();

    return (
        <BaseFormGrid
            title={
                (
                    <TranslatableText
                        termSet={termSet}
                        termKey={AssetCostCodesGridDefs.Asset_Cost_Codes}
                    />
                ) as unknown as string
            }
            displayGrid={canViewAssetCostCode}
            isLoading={isNilOrEmpty(colDefs)}
            rowData={cloneDeep(rowData)}
            useRowDrag={canUpdate}
            sortKey={'sortOrder'}
            columnDefs={colDefs}
            gridClass="full-size-item"
            displayToolbar={true}
            displayCreateNewButton={canCreateAssetCostCode}
            displayExportDataButton={true}
            displayResetStateButton={true}
            onCreateNew={redirectToForm}
            onRowClicked={({ data }) => redirectToForm(data)}
            onHandleGridEdits={handleGridEdits}
        />
    );
};

export default FixedAssetCostCodeGrid;
