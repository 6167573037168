import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React from 'react';
import SectionHeader from './SectionHeader';

export interface FormContainerProps {
    headerTitle?: any;
    children?: React.ReactNode;
    headerComponent?: React.ReactNode;
    onSubmit?: (event: any) => void;
    sections?: any[];
}

const FormContainer = (props: FormContainerProps) => {
    const submit = (event: any) => {
        event.preventDefault();
        if (props.onSubmit) {
            props.onSubmit(event);
        }
    };

    return (
        <Box
            component="div"
            sx={{ backgroundColor: '#EDEFF0', width: '100%', height: '100%' }}>
            <form onSubmit={(event) => submit(event)}>
                <Box component="div">{props.headerComponent}</Box>
                {props.headerTitle && (
                    <Box
                        component="div"
                        sx={{
                            height: '40px',
                            backgroundColor: '#DCDFE2',
                            width: '100%',
                        }}>
                        <Typography
                            sx={{
                                font: 'normal normal 600 16px/24px Noto Sans',
                                paddingLeft: '15px',
                                paddingTop: '10px',
                            }}>
                            {props.headerTitle}
                        </Typography>
                        {props?.sections && (
                            <div
                                style={{
                                    marginBottom: '10px',
                                    height: '100%',
                                    backgroundColor: '#DCDFE2',
                                    width: '100%',
                                    overflowX: 'auto',
                                    overflowY: 'hidden',
                                    whiteSpace: 'nowrap',
                                }}>
                                <SectionHeader sections={props.sections} />
                            </div>
                        )}
                    </Box>
                )}
            </form>

            <Box
                component="div"
                sx={{
                    padding: '25px',
                    paddingTop: props?.sections ? '45px' : '10px',
                }}>
                {props.children}
            </Box>
        </Box>
    );
};

export default FormContainer;
