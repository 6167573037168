import React from 'react';
import { ParameterizedValidator, Validator } from './validator.interface';
import _ from 'lodash';
import moment from 'moment';
import { dateFormatter } from '../utils/formattingUtils';

export const GreaterThanDateValidator: ParameterizedValidator = (
    greaterThan: Date
) => {
    return {
        key: 'greaterThanDate',
        getErrorMessage: (fieldLabel: string) => {
            if (_.isObject(fieldLabel)) {
                return (
                    <>
                        {fieldLabel} must be greater than or equal to{' '}
                        {dateFormatter(greaterThan)}
                    </>
                );
            }
            return `${fieldLabel} must be greater than or equal to ${dateFormatter(
                greaterThan
            )}`;
        },
        isValid: (value: any) => {
            // return isNil(value) || moment(value).isAfter(greaterThan);
            return moment(value).isAfter(moment(greaterThan));
        },
    } as Validator;
};
