import React from 'react';
import { GridOptions } from '../../../../components/grids/Grid.constants';
import { ColDef } from 'ag-grid-community';
import DefaultColumnTypes from '../../../../components/grids/columns/Column.constants';
import BaseFormGrid from '../../../../components/grids/BaseFormGrid';
import useSafetyDataSheetSectionHeaderGrid, {
    SafetyDataSheetHeaderGridProps,
} from './hooks/useSafetyDataSheetSectionHeaderGrid';
import { SdsSectionHeadersGridDefs } from '../../../../constants/i18n/translations/termDefinitions/formulation';
import TranslatableText from '../../../../components/i18n/TranslatableText';
import CustomHeader from '../../../../components/grids/CustomHeader';

const SafetyDataSheetSectionHeaderGrid = ({
    currentheaderIdLocaleGrpId,
    defaultLanguageId,
    handleSetHeaderKeyList,
    handlePromptFormDirty,
}: SafetyDataSheetHeaderGridProps) => {
    const {
        isLoadingSafetyDataSheetHeaderList,
        safetyDataSheetHeaderList,
        onGridReady,
        handleSelectedRowChange,
        handleRowDataChanged,
        onFirstDataRendered,
        onSortChanged,
        onFilterChanged,
        handleAddNewRecord,
        canViewSafetyDataSheetHeader,
        canCreateSafetyDataSheetHeader,
        termSet,
    } = useSafetyDataSheetSectionHeaderGrid({
        currentheaderIdLocaleGrpId,
        defaultLanguageId,
        handleSetHeaderKeyList,
        handlePromptFormDirty,
    });

    const methodsColumnDefs: ColDef[] = [
        {
            field: 'headerId',
            headerComponentFramework: (props: any) => {
                return (
                    <CustomHeader
                        {...props}
                        termSet={termSet}
                        termKey={SdsSectionHeadersGridDefs.Header_ID}
                    />
                );
            },
            filter: 'agTextColumnFilter',
            ...DefaultColumnTypes.MediumText,
        },
        {
            field: 'sectionText',
            headerComponentFramework: (props: any) => {
                return (
                    <CustomHeader
                        {...props}
                        termSet={termSet}
                        termKey={SdsSectionHeadersGridDefs.Section_Text}
                    />
                );
            },
            filter: 'agTextColumnFilter',
            ...DefaultColumnTypes.LongText,
        },
        {
            field: 'localeGroup.groupId',
            headerComponentFramework: (props: any) => {
                return (
                    <CustomHeader
                        {...props}
                        termSet={termSet}
                        termKey={SdsSectionHeadersGridDefs.Locale_Group}
                    />
                );
            },
            filter: 'agTextColumnFilter',
            ...DefaultColumnTypes.MediumText,
        },
    ];

    const colDefOptions = {
        ...GridOptions.sortFilterAndWrapColumns,
        floatingFilter: true,
    };

    return (
        <BaseFormGrid
            title={
                (
                    <TranslatableText
                        termSet={termSet}
                        termKey={
                            SdsSectionHeadersGridDefs.SDS_Section_Header_Library
                        }
                    />
                ) as unknown as string
            }
            isLoading={isLoadingSafetyDataSheetHeaderList}
            displayGrid={canViewSafetyDataSheetHeader}
            rowData={safetyDataSheetHeaderList}
            columnDefs={methodsColumnDefs}
            dataKey={'headerIdLocaleGrpId'}
            gridStyle={{
                maxHeight: '93%',
            }}
            gridClass="full-size-item"
            displayToolbar={true}
            displayCreateNewButton={canCreateSafetyDataSheetHeader}
            displayExportDataButton={false}
            displayResetStateButton={false}
            defaultColDef={colDefOptions}
            sizeColumnsToFit={true}
            onCreateNew={handleAddNewRecord}
            onRowClicked={({ data }) => handleSelectedRowChange(data)}
            onFirstDataRendered={onFirstDataRendered}
            onRowDataChanged={handleRowDataChanged}
            onGridReady={onGridReady}
            onFilterChanged={onFilterChanged}
            onSortChanged={onSortChanged}
            rowSelection={'single'}></BaseFormGrid>
    );
};

export default SafetyDataSheetSectionHeaderGrid;
