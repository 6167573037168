import React from 'react';
import { Grid } from '@mui/material';
import { useParams } from 'react-router-dom';
import useGeneralLedgerAccountForm from './hooks/useGeneralLedgerAccountForm';
import FormPartial from '../../components/form/formContainer/FormPartial';
import TextInput from '../../components/form/formInputs/TextInput/TextInput';
import SelectInput from '../../components/form/formInputs/SelectInput/SelectInput';
import { AgGridColumn } from 'ag-grid-react';
import BaseForm, { ParamTypes } from '../../components/form/baseForm/BaseForm';
import BaseGrid from '../../components/grids/BaseGrid';
import DefaultColumnTypes from '../../components/grids/columns/Column.constants';
import { RoutingValues } from '../../types/Shared.types';
import { GENERAL_LEDGER_ACCOUNT_FIELDS } from '../../constants/revisionHistory/revisionHistory.constants';
import { GeneralLedgerAccountsFormDefs } from '../../constants/i18n/translations/termDefinitions/accounting';
import TranslatableText from '../../components/i18n/TranslatableText';

const GeneralLedgerAccountForm = () => {
    const { id } = useParams<ParamTypes>();
    const {
        fields,
        formMethods,
        accountStatusOptions,
        balanceTypeOptions,
        accountTypeOptions,
        onCreate,
        onUpdate,
        onDelete,
        closeForm,
        handleFieldChange,
        isLoadingAccount,
        activeAccount,
        hasFunctions,
        handleAccountTypeChange,
        canCreateChartOfAccount,
        canUpdateChartOfAccount,
        canDeleteChartOfAccount,
        termSet,
    } = useGeneralLedgerAccountForm(id);

    return (
        <BaseForm
            title={
                <TranslatableText
                    termSet={termSet}
                    termKey={
                        GeneralLedgerAccountsFormDefs.General_Ledger_Account
                    }
                />
            }
            formMethods={formMethods}
            isLoading={isLoadingAccount}
            isNew={id === RoutingValues.newId}
            onCreate={onCreate}
            onUpdate={onUpdate}
            onDelete={onDelete}
            onCancel={closeForm}
            showOnRevision={true}
            fieldMappings={GENERAL_LEDGER_ACCOUNT_FIELDS}
            entityUuid={fields?.uuid}
            displayCreateButton={canCreateChartOfAccount}
            displayUpdateButton={canUpdateChartOfAccount}
            displayDeleteButton={canDeleteChartOfAccount}>
            <FormPartial>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <TextInput
                            fullWidth
                            label={
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={
                                        GeneralLedgerAccountsFormDefs.Number
                                    }
                                />
                            }
                            id="number"
                            value={fields.number}
                            onChange={handleFieldChange}
                            formMethods={formMethods}
                            required={true}
                            maxLength={30}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextInput
                            fullWidth
                            label={
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={GeneralLedgerAccountsFormDefs.Name}
                                />
                            }
                            id="name"
                            value={fields.name}
                            onChange={handleFieldChange}
                            formMethods={formMethods}
                            required={true}
                            maxLength={90}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextInput
                            fullWidth
                            label={
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={
                                        GeneralLedgerAccountsFormDefs.Description
                                    }
                                />
                            }
                            id="description"
                            value={fields.description}
                            onChange={handleFieldChange}
                            formMethods={formMethods}
                            maxLength={255}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <SelectInput
                            fullWidth
                            label={
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={
                                        GeneralLedgerAccountsFormDefs.Status
                                    }
                                />
                            }
                            id="accountStatusId"
                            value={fields.accountStatusId}
                            onChange={handleFieldChange}
                            options={accountStatusOptions}
                            formMethods={formMethods}
                            required={true}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <SelectInput
                            fullWidth
                            label={
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={
                                        GeneralLedgerAccountsFormDefs.Account_Type
                                    }
                                />
                            }
                            id="accountTypeId"
                            value={fields.accountTypeId}
                            onChange={handleAccountTypeChange}
                            options={accountTypeOptions}
                            formMethods={formMethods}
                            required={true}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <SelectInput
                            fullWidth
                            label={
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={
                                        GeneralLedgerAccountsFormDefs.Balance_Type
                                    }
                                />
                            }
                            id="balanceTypeId"
                            value={fields.balanceTypeId}
                            onChange={handleFieldChange}
                            options={balanceTypeOptions}
                            formMethods={formMethods}
                            required={true}
                        />
                    </Grid>
                </Grid>
            </FormPartial>
            <FormPartial
                title={
                    (
                        <TranslatableText
                            termSet={termSet}
                            termKey={
                                GeneralLedgerAccountsFormDefs.Account_Functions
                            }
                        />
                    ) as unknown as string
                }
                isVisible={hasFunctions()}>
                <BaseGrid
                    rowData={activeAccount?.functions || []}
                    displayGrid={true}
                    defaultColDef={{
                        resizable: true,
                        wrapText: true,
                    }}>
                    <AgGridColumn
                        field="functionCode.code"
                        headerComponentFramework={(props: any) => {
                            return (
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={
                                        GeneralLedgerAccountsFormDefs.Function_Code
                                    }
                                />
                            );
                        }}
                        {...DefaultColumnTypes.ShortText}
                    />
                    <AgGridColumn
                        field="functionCode.description"
                        headerComponentFramework={(props: any) => {
                            return (
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={
                                        GeneralLedgerAccountsFormDefs.Description
                                    }
                                />
                            );
                        }}
                        {...DefaultColumnTypes.LongText}
                        maxWidth={1000}
                    />
                </BaseGrid>
            </FormPartial>
        </BaseForm>
    );
};

export default GeneralLedgerAccountForm;
