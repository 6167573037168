import { useNavigate } from 'react-router-dom';
import {
    useGetAllDepreciationMethodTypesQuery,
    useUpdateListDepreciationMethodTypesMutation,
} from '../../../services/fixedAssets/fixedAssets.service';
import { PermissionsUtil } from '../../../utils/permissions/permissionsUtil';
import { PERMISSIONS } from '../../../constants/permissions/Permissions.constants';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { RoutingValues } from '../../../types/Shared.types';
import { FA_DepreciationMethodType } from '../../../types/FixedAsset.types';
import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { getMaxSortOrderValue } from '../../../store/fixedAssets';
import { isNilOrEmpty } from '../../../utils/objectUtils';
import useTranslation from '../../../components/i18n/hooks/useTranslation';
import { ACCOUNTING_DEFS } from '../../../constants/i18n/translations/termSetDefinitions/accounting';
import { ColDef } from 'ag-grid-community';
import CustomHeader from '../../../components/grids/CustomHeader';
import { DepreciationMethodTypesGridDefs } from '../../../constants/i18n/translations/termDefinitions/accounting';
import DefaultColumnTypes from '../../../components/grids/columns/Column.constants';

const UseFixedAssetDepreciationMethodTypeGrid = () => {
    const dispatch = useDispatch();
    const user = useSelector((state: RootState) => state.user);
    const navigate = useNavigate();
    const { data: typeList, isLoading: isLoadingTypes } =
        useGetAllDepreciationMethodTypesQuery();
    const [rowData, setRowData] = useState([]);
    const [colDefs, setColDefs] = useState([]);

    const [updateDepreciationMethodTypes] =
        useUpdateListDepreciationMethodTypesMutation();

    const canCreateDepreciationMethodType = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.FIXED_ASSETS.DEPRECIATION_METHOD_TYPES.CREATE
    );
    const canViewDepreciationMethodType = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.FIXED_ASSETS.DEPRECIATION_METHOD_TYPES.VIEW
    );
    const canUpdate = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.FIXED_ASSETS.DEPRECIATION_METHOD_TYPES.EDIT
    );

    const { termSet } = useTranslation(
        ACCOUNTING_DEFS.DEPRECIATION_METHOD_TYPES_GRID
    );

    const handleGridEdits = (editedRows: FA_DepreciationMethodType[]) => {
        setRowData(editedRows);
        updateDepreciationMethodTypes(editedRows);
    };

    useEffect(() => {
        if (!isNilOrEmpty(typeList)) {
            setRowData(typeList);
        }
    }, [typeList]);

    const redirectToForm = (row: any) => {
        if (row.id) {
            navigate(
                `/accounting/fixedAssets/depreciationMethodType/${row.id}`
            );
        } else {
            navigate(
                `/accounting/fixedAssets/depreciationMethodType/${RoutingValues.newId}`
            );
        }
    };

    useEffect(() => {
        let maxValue = 1;
        if (typeList && typeList.length > 0) {
            const data = _.maxBy(
                typeList,
                (depreciationType: FA_DepreciationMethodType) =>
                    depreciationType.sortOrder
            );
            maxValue = data.sortOrder + 1;
        }
        dispatch(
            getMaxSortOrderValue({ isLoading: false, maxValue: maxValue })
        );
    }, [typeList]);

    useEffect(() => {
        if (!isNilOrEmpty(rowData) && isNilOrEmpty(colDefs)) {
            const statusColumnDefs: ColDef[] = [
                {
                    field: 'code',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={DepreciationMethodTypesGridDefs.Code}
                            />
                        );
                    },
                    ...DefaultColumnTypes.ShortText,
                },
                {
                    field: 'description',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={
                                    DepreciationMethodTypesGridDefs.Description
                                }
                            />
                        );
                    },
                    ...DefaultColumnTypes.LongText,
                },
                {
                    field: 'postsJournalEntries',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={
                                    DepreciationMethodTypesGridDefs.Journal_Posting
                                }
                            />
                        );
                    },
                    ...DefaultColumnTypes.Boolean,
                },
                {
                    field: 'predefinedSchedule',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={
                                    DepreciationMethodTypesGridDefs.Predefined_Schedule
                                }
                            />
                        );
                    },
                    ...DefaultColumnTypes.Boolean,
                },
            ];
            setColDefs(statusColumnDefs);
        }
    }, [rowData]);

    return {
        typeList,
        redirectToForm,
        isLoadingTypes,
        canCreateDepreciationMethodType,
        canViewDepreciationMethodType,
        canUpdate,
        handleGridEdits,
        rowData,
        colDefs,
        termSet,
    };
};

export default UseFixedAssetDepreciationMethodTypeGrid;
