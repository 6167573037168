import DatacorLogoSpinner from '../../../../../../components/datacorLogoSpinner/DatacorLogoSpinner';
import React from 'react';
import FormPartial from '../../../../../../components/form/formContainer/FormPartial';
import SubSection14_5Form from './subSections/subSection14_5/SubSection14_5Form';
import SubSection14_6Form from './subSections/subSection14_6/SubSection14_6Form';
import SubSection14_7Form from './subSections/subSection14_7/SubSection14_7Form';
import { SdsBlobSectionFormProps } from '../shared/blobs/hooks/SdsBlobSectionFormProps';
import { SdsHeader } from '../../../../../../types/formulation';
import TranslatableText from '../../../../../../components/i18n/TranslatableText';
import { SDSFormDefs } from '../../../../../../constants/i18n/translations/termDefinitions/formulation';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import Box from '@mui/material/Box';
import { Button, DialogTitle, FormHelperText, Grid, Tab } from '@mui/material';
import useSdsTransportForm from './hooks/useSdsTransportForm';
import TextInput from '../../../../../../components/form/formInputs/TextInput/TextInput';
import AutoCompleteInput from '../../../../../../components/form/formInputs/AutoCompleteInput/AutoCompleteInput';
import CheckBoxInput from '../../../../../../components/form/formInputs/CheckBoxInput/CheckBoxInput';
import TextInputPopup from '../../../../../../components/form/formInputs/TextInputPopup/TextInputPopup';

const SdsTransportForm = (props: SdsBlobSectionFormProps<SdsHeader>) => {
    const { parentData } = props;
    const { isParentLoading } = parentData;
    const { disabledField } = props.permissions;

    const {
        fields,
        handlePropertyChanged,
        dropdownOptions,
        formMethods,
        currentTab,
        setCurrentTab,
        setDropdownOptions,
        properShippingNameOptions,
        isLoadingProperShippingNameOptions,
        setFieldsOnProperShippingNameChange,
        onCopyFieldChange,
        isAirEnabled,
        isLandEnabled,
        isSeaEnabled,
        isCopyButtonEnabled,
        isDestinationFieldEnabled,
        onCheckboxToggle,
        transportationOptions,
        displayFields,
        copySourceOptions,
        copyDestinationOptions,
        setCopyDropdownOptions,
        copyDropdownOptions,
        copyFormMethods,
        performCopyAction,
        canViewProperShippingName,
        properShippingNameColDefs,
        tabValues,
    } = useSdsTransportForm(props);

    return (
        <>
            {isParentLoading ? (
                <DatacorLogoSpinner />
            ) : (
                <>
                    <FormPartial
                        link={props?.link}
                        title={
                            (
                                <TranslatableText
                                    termSet={props.termSet}
                                    termKey={SDSFormDefs.Transport}
                                />
                            ) as unknown as string
                        }>
                        <FormPartial title="14.1 - 14.4" isVisible={true}>
                            <FormHelperText>
                                <TranslatableText
                                    termSet={props.termSet}
                                    termKey={
                                        SDSFormDefs.Transportation_Active_Method
                                    }
                                />
                            </FormHelperText>
                            <Grid container spacing={2}>
                                <CheckBoxInput
                                    id="land"
                                    label={
                                        (
                                            <TranslatableText
                                                termSet={props.termSet}
                                                termKey={
                                                    SDSFormDefs.Transportation_Land
                                                }
                                            />
                                        ) as unknown as string
                                    }
                                    checked={isLandEnabled}
                                    onChange={() => {
                                        onCheckboxToggle(
                                            isLandEnabled,
                                            transportationOptions.land,
                                            transportationOptions.landPrefix
                                        );
                                    }}
                                    disabled={disabledField}
                                />
                                <CheckBoxInput
                                    id="air"
                                    label={
                                        (
                                            <TranslatableText
                                                termSet={props.termSet}
                                                termKey={
                                                    SDSFormDefs.Transportation_Air
                                                }
                                            />
                                        ) as unknown as string
                                    }
                                    checked={isAirEnabled}
                                    onChange={() => {
                                        onCheckboxToggle(
                                            isAirEnabled,
                                            transportationOptions.air,
                                            transportationOptions.airPrefix
                                        );
                                    }}
                                    disabled={disabledField}
                                />
                                <CheckBoxInput
                                    id="sea"
                                    label={
                                        (
                                            <TranslatableText
                                                termSet={props.termSet}
                                                termKey={
                                                    SDSFormDefs.Transportation_Sea
                                                }
                                            />
                                        ) as unknown as string
                                    }
                                    checked={isSeaEnabled}
                                    onChange={() => {
                                        onCheckboxToggle(
                                            isSeaEnabled,
                                            transportationOptions.sea,
                                            transportationOptions.seaPrefix
                                        );
                                    }}
                                    disabled={disabledField}
                                />
                                <Grid item xs={3}>
                                    <AutoCompleteInput
                                        id="copySourceId"
                                        inputFieldName="copySourceValue"
                                        label={
                                            <TranslatableText
                                                termSet={props.termSet}
                                                termKey={
                                                    SDSFormDefs.Transportation_Copy_From
                                                }
                                            />
                                        }
                                        onHandlePropertyChanged={
                                            onCopyFieldChange
                                        }
                                        options={copySourceOptions}
                                        setDropdownOptions={
                                            setCopyDropdownOptions
                                        }
                                        value={
                                            copyDropdownOptions?.selectedCopySource
                                        }
                                        inputValue={
                                            copyDropdownOptions?.copySourceValue
                                        }
                                        isLoading={false}
                                        formMethods={copyFormMethods}
                                        disabled={disabledField}
                                    />
                                </Grid>
                                <Grid item xs={1}>
                                    <DialogTitle>
                                        <TranslatableText
                                            termSet={props.termSet}
                                            termKey={
                                                SDSFormDefs.Transportation_To
                                            }
                                        />
                                    </DialogTitle>
                                </Grid>
                                <Grid item xs={3}>
                                    <AutoCompleteInput
                                        id="copyDestinationId"
                                        inputFieldName="copyDestinationValue"
                                        label={
                                            <TranslatableText
                                                termSet={props.termSet}
                                                termKey={
                                                    SDSFormDefs.Transportation_Copy_To
                                                }
                                            />
                                        }
                                        onHandlePropertyChanged={
                                            onCopyFieldChange
                                        }
                                        options={copyDestinationOptions}
                                        setDropdownOptions={
                                            setCopyDropdownOptions
                                        }
                                        value={
                                            copyDropdownOptions?.selectedCopyDestination
                                        }
                                        inputValue={
                                            copyDropdownOptions?.copyDestinationValue
                                        }
                                        isLoading={false}
                                        formMethods={copyFormMethods}
                                        disabled={
                                            disabledField ||
                                            !isDestinationFieldEnabled
                                        }
                                    />
                                </Grid>
                                <Grid item xs={1}>
                                    <Button
                                        onClick={performCopyAction}
                                        disabled={!isCopyButtonEnabled}>
                                        <TranslatableText
                                            termSet={props.termSet}
                                            termKey={
                                                SDSFormDefs.Transportation_Copy
                                            }
                                        />
                                    </Button>
                                </Grid>
                            </Grid>
                            <TabContext value={currentTab}>
                                <Box
                                    sx={{
                                        borderBottom: 1,
                                        borderColor: 'divider',
                                    }}>
                                    <TabList
                                        onChange={(event, value: string) =>
                                            setCurrentTab(value)
                                        }>
                                        <Tab
                                            label={
                                                <TranslatableText
                                                    termSet={props.termSet}
                                                    termKey={
                                                        SDSFormDefs.Transportation_Tab_Land
                                                    }
                                                />
                                            }
                                            disabled={!isLandEnabled}
                                            value={tabValues.landTab}
                                        />
                                        <Tab
                                            label={
                                                <TranslatableText
                                                    termSet={props.termSet}
                                                    termKey={
                                                        SDSFormDefs.Transportation_Tab_Air
                                                    }
                                                />
                                            }
                                            value={tabValues.airTab}
                                            disabled={!isAirEnabled}
                                        />
                                        <Tab
                                            label={
                                                <TranslatableText
                                                    termSet={props.termSet}
                                                    termKey={
                                                        SDSFormDefs.Transportation_Tab_Sea
                                                    }
                                                />
                                            }
                                            value={tabValues.seaTab}
                                            disabled={!isSeaEnabled}
                                        />
                                    </TabList>
                                </Box>
                                <TabPanel value="0">
                                    <Grid container spacing={2}>
                                        <Grid item xs={3}>
                                            <TextInputPopup
                                                fullWidth
                                                id="landProperShippingNameId"
                                                onChange={(event) =>
                                                    setFieldsOnProperShippingNameChange(
                                                        event,
                                                        transportationOptions.landPrefix
                                                    )
                                                }
                                                label={
                                                    <TranslatableText
                                                        termSet={props.termSet}
                                                        termKey={
                                                            SDSFormDefs.Transportation_Land_Proper_Shipping_Code
                                                        }
                                                    />
                                                }
                                                formMethods={formMethods}
                                                popupOptions={
                                                    canViewProperShippingName
                                                        ? properShippingNameOptions
                                                        : []
                                                }
                                                colDefs={
                                                    properShippingNameColDefs
                                                }
                                                value={
                                                    fields.landProperShippingNameId
                                                }
                                                valueFormatter={{
                                                    optionLabel: 'label',
                                                    value: 'value',
                                                }}
                                                optionLabel={
                                                    dropdownOptions
                                                        ?.selectedProperShippingNameLand
                                                        ?.label
                                                }
                                                disabled={
                                                    !isLandEnabled ||
                                                    disabledField
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <TextInput
                                                fullWidth
                                                id="landClass"
                                                value={displayFields.landClass}
                                                label={
                                                    <TranslatableText
                                                        termSet={props.termSet}
                                                        termKey={
                                                            SDSFormDefs.Transportation_Class
                                                        }
                                                    />
                                                }
                                                disabled={true}
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <TextInput
                                                fullWidth
                                                id="landPackaging"
                                                value={
                                                    displayFields.landPackaging
                                                }
                                                label={
                                                    <TranslatableText
                                                        termSet={props.termSet}
                                                        termKey={
                                                            SDSFormDefs.Transportation_Packaging
                                                        }
                                                    />
                                                }
                                                disabled={true}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextInput
                                                fullWidth
                                                id="landProperShippingNameDescription"
                                                value={
                                                    fields.landProperShippingNameDescription
                                                }
                                                label={
                                                    <TranslatableText
                                                        termSet={props.termSet}
                                                        termKey={
                                                            SDSFormDefs.Transportation_Proper_Shipping_Name
                                                        }
                                                    />
                                                }
                                                maxLength={250}
                                                formMethods={formMethods}
                                                required={false}
                                                onChange={handlePropertyChanged}
                                                disabled={
                                                    !isLandEnabled ||
                                                    disabledField
                                                }
                                            />
                                        </Grid>
                                    </Grid>
                                </TabPanel>
                                <TabPanel value="1">
                                    <Grid container spacing={2}>
                                        <Grid item xs={3}>
                                            <TextInputPopup
                                                fullWidth
                                                id="airProperShippingNameId"
                                                onChange={(event) =>
                                                    setFieldsOnProperShippingNameChange(
                                                        event,
                                                        transportationOptions.airPrefix
                                                    )
                                                }
                                                label={
                                                    <TranslatableText
                                                        termSet={props.termSet}
                                                        termKey={
                                                            SDSFormDefs.Transportation_Air_Proper_Shipping_Code
                                                        }
                                                    />
                                                }
                                                formMethods={formMethods}
                                                popupOptions={
                                                    canViewProperShippingName
                                                        ? properShippingNameOptions
                                                        : []
                                                }
                                                colDefs={
                                                    properShippingNameColDefs
                                                }
                                                value={
                                                    fields.airProperShippingNameId
                                                }
                                                valueFormatter={{
                                                    optionLabel: 'label',
                                                    value: 'value',
                                                }}
                                                optionLabel={
                                                    dropdownOptions
                                                        ?.selectedProperShippingNameAir
                                                        ?.label
                                                }
                                                disabled={
                                                    !isAirEnabled ||
                                                    disabledField
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <TextInput
                                                fullWidth
                                                id="airClass"
                                                value={displayFields.airClass}
                                                label={
                                                    <TranslatableText
                                                        termSet={props.termSet}
                                                        termKey={
                                                            SDSFormDefs.Transportation_Class
                                                        }
                                                    />
                                                }
                                                disabled={true}
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <TextInput
                                                fullWidth
                                                id="airPackaging"
                                                value={
                                                    displayFields.airPackaging
                                                }
                                                label={
                                                    <TranslatableText
                                                        termSet={props.termSet}
                                                        termKey={
                                                            SDSFormDefs.Transportation_Packaging
                                                        }
                                                    />
                                                }
                                                disabled={true}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextInput
                                                fullWidth
                                                id="airProperShippingNameDescription"
                                                value={
                                                    fields.airProperShippingNameDescription
                                                }
                                                label={
                                                    <TranslatableText
                                                        termSet={props.termSet}
                                                        termKey={
                                                            SDSFormDefs.Transportation_Proper_Shipping_Name
                                                        }
                                                    />
                                                }
                                                maxLength={250}
                                                formMethods={formMethods}
                                                required={false}
                                                onChange={handlePropertyChanged}
                                                disabled={
                                                    !isAirEnabled ||
                                                    disabledField
                                                }
                                            />
                                        </Grid>
                                    </Grid>
                                </TabPanel>
                                <TabPanel value="2">
                                    <Grid container spacing={2}>
                                        <Grid item xs={3}>
                                            <TextInputPopup
                                                fullWidth
                                                id="seaProperShippingNameId"
                                                onChange={(event) =>
                                                    setFieldsOnProperShippingNameChange(
                                                        event,
                                                        transportationOptions.seaPrefix
                                                    )
                                                }
                                                label={
                                                    <TranslatableText
                                                        termSet={props.termSet}
                                                        termKey={
                                                            SDSFormDefs.Transportation_Sea_Proper_Shipping_Code
                                                        }
                                                    />
                                                }
                                                formMethods={formMethods}
                                                popupOptions={
                                                    canViewProperShippingName
                                                        ? properShippingNameOptions
                                                        : []
                                                }
                                                colDefs={
                                                    properShippingNameColDefs
                                                }
                                                value={
                                                    fields.seaProperShippingNameId
                                                }
                                                valueFormatter={{
                                                    optionLabel: 'label',
                                                    value: 'value',
                                                }}
                                                optionLabel={
                                                    dropdownOptions
                                                        ?.selectedProperShippingNameSea
                                                        ?.label
                                                }
                                                disabled={
                                                    !isSeaEnabled ||
                                                    disabledField
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <TextInput
                                                fullWidth
                                                id="seaClass"
                                                value={displayFields.seaClass}
                                                label={
                                                    <TranslatableText
                                                        termSet={props.termSet}
                                                        termKey={
                                                            SDSFormDefs.Transportation_Class
                                                        }
                                                    />
                                                }
                                                disabled={true}
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <TextInput
                                                fullWidth
                                                id="seaPackaging"
                                                value={
                                                    displayFields.seaPackaging
                                                }
                                                label={
                                                    <TranslatableText
                                                        termSet={props.termSet}
                                                        termKey={
                                                            SDSFormDefs.Transportation_Packaging
                                                        }
                                                    />
                                                }
                                                disabled={true}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextInput
                                                fullWidth
                                                id="seaProperShippingNameDescription"
                                                value={
                                                    fields.seaProperShippingNameDescription
                                                }
                                                label={
                                                    <TranslatableText
                                                        termSet={props.termSet}
                                                        termKey={
                                                            SDSFormDefs.Transportation_Proper_Shipping_Name
                                                        }
                                                    />
                                                }
                                                maxLength={250}
                                                formMethods={formMethods}
                                                required={false}
                                                onChange={handlePropertyChanged}
                                                disabled={
                                                    !isSeaEnabled ||
                                                    disabledField
                                                }
                                            />
                                        </Grid>
                                    </Grid>
                                </TabPanel>
                            </TabContext>
                        </FormPartial>
                        <SubSection14_5Form
                            {...props}
                            termSet={props.termSet}
                        />
                        <SubSection14_6Form
                            {...props}
                            termSet={props.termSet}
                        />
                        <SubSection14_7Form
                            {...props}
                            termSet={props.termSet}
                        />
                    </FormPartial>
                </>
            )}
        </>
    );
};

export default SdsTransportForm;
