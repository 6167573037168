import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { useNavigate } from 'react-router-dom';
import { useForm } from '../../../libs/hooksLib';
import {
    Building,
    useCreateBuildingMutation,
    useUpdateBuildingMutation,
    useGetBuildingQuery,
    useGetCountriesForDropdownQuery,
    useGetStatesQuery,
} from '../../../services/organizations/organizations.service';
import { skipToken } from '@reduxjs/toolkit/query';
import {
    BaseCreateEntityArgs,
    BaseUpdateEntityArgs,
} from '../../../services/serviceInterfaces';
import { showNotificationError } from '../../../libs/errorLib';
import SettingsContext from '../../../contexts/settings.context';
import { useGetTermSetQuery } from '../../../services/i18n/i18n.service';
import { PLATFORM_DEFS } from '../../../constants/i18n/translations/termSetDefinitions/platform';
const useBuildingsForm = (id: string) => {
    const dispatch = useDispatch();
    const user = useSelector((state: RootState) => state.user);
    const navigate = useNavigate();
    const { fields, handleFieldChange, setValues, formMethods, isFormValid } =
        useForm({});
    const {
        fields: addressFields,
        handleFieldChange: handleAddressFieldChange,
        setValues: setAddressValues,
        formMethods: addressFormMethods,
        isFormValid: isAddressFormValid,
    } = useForm({});
    const { data: building, isLoading: isLoadingBuilding } =
        useGetBuildingQuery(id ? id : skipToken);
    const { data: countryOptions, isLoading: isLoadingCountries } =
        useGetCountriesForDropdownQuery();
    const { data: states, isLoading: isLoadingStates } = useGetStatesQuery();
    const [stateSelectOptions, setStateSelectOptions] = useState([]);

    const [createBuilding] = useCreateBuildingMutation();
    const [updateBuilding] = useUpdateBuildingMutation();

    const { settings } = useContext(SettingsContext);
    const { data: termSet } = useGetTermSetQuery(
        settings?.userSettings
            ? {
                  languageId: settings?.userSettings?.languageId,
                  code: PLATFORM_DEFS.BUILDINGS_FORM,
              }
            : skipToken
    );

    const defaultBuilding: any = {
        code: '',
        name: '',
        supplierId: '',
        customerId: '',
        note: '',
        businessEntityId: user?.businessEntity?.id,
    };

    const defaultAddress: any = {
        street: '',
        city: '',
        county: '',
        postalCode: '',
        countryCodeId: '',
        stateCodeId: '',
    };

    useEffect(() => {
        setValues(building || defaultBuilding);
        setAddressValues(building?.address || defaultAddress);
    }, [building]);

    const handleCreateBuilding = async () => {
        if (!isFormValid || !isAddressFormValid) {
            return;
        }

        const body: BaseCreateEntityArgs<Building> = {
            postBody: {
                ...fields,
                address: {
                    ...fields?.address,
                    street: addressFields?.street,
                    city: addressFields?.city,
                    county: addressFields?.county,
                    postalCode: addressFields?.postalCode,
                    countryCodeId: addressFields?.countryCodeId,
                    stateCodeId: addressFields?.stateCodeId,
                },
            },
        };

        try {
            await createBuilding(body).unwrap();
            handleClose();
        } catch (e) {
            return showNotificationError(
                (e.data && e?.data[0]?.message) || 'An Error has Occurred.',
                'error',
                dispatch
            );
        }
    };

    const handleUpdateBuilding = async () => {
        if (!isFormValid || !isAddressFormValid) {
            return;
        }

        const body: BaseUpdateEntityArgs<Building> = {
            id: id as unknown as number,
            postBody: {
                ...fields,
                address: {
                    ...fields?.address,
                    street: addressFields?.street,
                    city: addressFields?.city,
                    county: addressFields?.county,
                    postalCode: addressFields?.postalCode,
                    countryCodeId: addressFields?.countryCodeId,
                    stateCodeId: addressFields?.stateCodeId,
                },
            },
        };

        try {
            await updateBuilding(body);
            handleClose();
        } catch (e) {
            return showNotificationError(e.message, 'error', dispatch);
        }
    };

    const handleClose = () => {
        navigate('/administration/organizations/buildings');
    };

    return {
        fields,
        addressFields,
        isLoadingBuilding,
        handleCreateBuilding,
        handleUpdateBuilding,
        handleFieldChange,
        handleAddressFieldChange,
        handleClose,
        formMethods,
        addressFormMethods,
        countryOptions,
        stateSelectOptions,
        termSet,
        setAddressValues,
    };
};

export default useBuildingsForm;
