import { CasMaster } from '../../../../../../types/formulation';
import FormPartial from '../../../../../../components/form/formContainer/FormPartial';
import DatacorLogoSpinner from '../../../../../../components/datacorLogoSpinner/DatacorLogoSpinner';
import { Checkbox, FormControlLabel, Grid } from '@mui/material';
import TextInput from '../../../../../../components/form/formInputs/TextInput/TextInput';
import React, { useContext } from 'react';
import NumericInput from '../../../../../../components/form/formInputs/NumericInput/NumericInput';
import { ExtendedFormProps } from '../../../../../form/helpers/ExtendedFormProps';
import { CasMasterFormDefs } from '../../../../../../constants/i18n/translations/termDefinitions/formulation';
import TranslatableText from '../../../../../../components/i18n/TranslatableText';
import SettingsContext from '../../../../../../contexts/settings.context';
import { useGetTermSetQuery } from '../../../../../../services/i18n/i18n.service';
import { FORMULATION_DEFS } from '../../../../../../constants/i18n/translations/termSetDefinitions/formulation';
import { skipToken } from '@reduxjs/toolkit/query';

const CasAdditionalIdentificationForm = (
    props: ExtendedFormProps<CasMaster>
) => {
    const { currentParentRecord, isParentLoading } = props.parentData;

    const { formMethods, handleFieldChange } = props.helpers;

    const { settings } = useContext(SettingsContext);
    const { data: termSet } = useGetTermSetQuery(
        settings?.userSettings
            ? {
                  languageId: settings?.userSettings?.languageId,
                  code: FORMULATION_DEFS.CAS_MASTER_FORM,
              }
            : skipToken
    );

    return (
        <FormPartial
            title={
                (
                    <TranslatableText
                        termKey={CasMasterFormDefs.Additional_Identification}
                        termSet={termSet}
                    />
                ) as unknown as string
            }>
            {isParentLoading ? (
                <DatacorLogoSpinner />
            ) : (
                <>
                    <Grid container spacing={2}>
                        <Grid item xs={4}>
                            <TextInput
                                fullWidth
                                id="index"
                                value={currentParentRecord.index}
                                label={
                                    <TranslatableText
                                        termKey={CasMasterFormDefs.Index_No}
                                        termSet={termSet}
                                    />
                                }
                                maxLength={15}
                                formMethods={formMethods}
                                onChange={handleFieldChange}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <TextInput
                                fullWidth
                                id="ecIdentifier"
                                value={currentParentRecord.ecIdentifier}
                                label={
                                    <TranslatableText
                                        termKey={CasMasterFormDefs.Ec_No}
                                        termSet={termSet}
                                    />
                                }
                                maxLength={10}
                                formMethods={formMethods}
                                onChange={handleFieldChange}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <TextInput
                                fullWidth
                                id="reach"
                                value={currentParentRecord.reach}
                                label={
                                    <TranslatableText
                                        termKey={CasMasterFormDefs.Reach_No}
                                        termSet={termSet}
                                    />
                                }
                                maxLength={25}
                                formMethods={formMethods}
                                onChange={handleFieldChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextInput
                                fullWidth
                                id="classification"
                                value={currentParentRecord.classification}
                                label={
                                    <TranslatableText
                                        termKey={
                                            CasMasterFormDefs.Classification
                                        }
                                        termSet={termSet}
                                    />
                                }
                                maxLength={250}
                                multiline={true}
                                rows={3}
                                formMethods={formMethods}
                                onChange={handleFieldChange}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextInput
                                fullWidth
                                id="molecularFormula"
                                value={currentParentRecord.molecularFormula}
                                label={
                                    <TranslatableText
                                        termKey={
                                            CasMasterFormDefs.Molecular_Formula
                                        }
                                        termSet={termSet}
                                    />
                                }
                                maxLength={50}
                                formMethods={formMethods}
                                onChange={handleFieldChange}
                            />
                        </Grid>
                        <Grid item xs={6}></Grid>
                        <Grid item xs={2}>
                            <FormControlLabel
                                label={
                                    <TranslatableText
                                        termKey={CasMasterFormDefs.Show_On_SDS}
                                        termSet={termSet}
                                    />
                                }
                                control={
                                    <Checkbox
                                        id="displayOnSds"
                                        checked={
                                            currentParentRecord.displayOnSds
                                        }
                                        onChange={handleFieldChange}
                                    />
                                }
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <NumericInput
                                fullWidth
                                id="weightThreshold"
                                label={
                                    <TranslatableText
                                        termKey={
                                            CasMasterFormDefs.If_Weight_Is_Greater_Than
                                        }
                                        termSet={termSet}
                                    />
                                }
                                value={
                                    currentParentRecord.weightThreshold == null
                                        ? ''
                                        : currentParentRecord.weightThreshold
                                }
                                onChange={handleFieldChange}
                                formMethods={formMethods}
                                numDecimals={2}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <FormControlLabel
                                label={
                                    <TranslatableText
                                        termKey={CasMasterFormDefs.Trade_Secret}
                                        termSet={termSet}
                                    />
                                }
                                control={
                                    <Checkbox
                                        id="tradeSecret"
                                        checked={
                                            currentParentRecord.tradeSecret
                                        }
                                        onChange={handleFieldChange}
                                    />
                                }
                            />
                        </Grid>
                    </Grid>
                </>
            )}
        </FormPartial>
    );
};

export default CasAdditionalIdentificationForm;
