import { CompanyRole } from '../../types/User.types';
import { Permission } from '../../types/Settings.types';
import { datacorAppApi } from '../datacorAppApi';

export const permissionsAndPreferencesAPI = datacorAppApi.injectEndpoints({
    endpoints: (build) => ({
        getRolesForCompany: build.query<CompanyRole, any>({
            query: () => `/permissionsPreferences/getAllCompanyRoles`,
            providesTags: ['Role'],
        }),
        getRoleForCompanyId: build.query<CompanyRole, string>({
            query: (companyId) =>
                `/permissionsPreferences/getRolesByCompanyId/${companyId}`,
            providesTags: ['Role'],
        }),
        getRolesByBatchCompanies: build.query<any, any>({
            query: (companies) => ({
                url: `/permissionsPreferences/getRolesByBatchCompanies`,
                method: 'POST',
                body: { companies: companies },
            }),
            providesTags: ['Role'],
        }),
        createRoleForCompany: build.mutation<any, CompanyRole>({
            query: (role) => ({
                url: '/permissionsPreferences/createRoleForCompany',
                method: 'POST',
                body: role,
            }),
            invalidatesTags: ['Role'],
        }),
        deleteRole: build.mutation<any, { roleId: string; companyId: string }>({
            query: (role) => ({
                url: '/permissionsPreferences/deleteRole',
                method: 'POST',
                body: role,
            }),
            invalidatesTags: ['Role'],
        }),
        updateRolePermissions: build.mutation<
            any,
            { roleId: string; actions: any }
        >({
            query: (role) => ({
                url: '/permissionsPreferences/updateRolePermissions',
                method: 'POST',
                body: role,
            }),
            invalidatesTags: ['Role'],
        }),
        getRoleDetails: build.query<CompanyRole, string>({
            query: (roleId) =>
                `/permissionsPreferences/getRoleDetails/${roleId}`,
            providesTags: ['Role'],
        }),
        getAllPermissions: build.query<Permission[], void>({
            query: () => `/permissionsPreferences/getAllPermissions`,
            providesTags: ['Permissions'],
        }),
    }),
});

export const {
    useGetRolesForCompanyQuery,
    useGetRoleForCompanyIdQuery,
    useGetRolesByBatchCompaniesQuery,
    useCreateRoleForCompanyMutation,
    useDeleteRoleMutation,
    useUpdateRolePermissionsMutation,
    useGetRoleDetailsQuery,
    useGetAllPermissionsQuery,
} = permissionsAndPreferencesAPI;
