import { useEffect, useState } from 'react';
import { EditableBaseFormGridProps } from '../EditableBaseFormGrid';
import { EditableGridEntity } from '../../../types/Shared.types';
import useVerificationChangesHelper from '../../../containers/form/hooks/verification/useVerificationChangesHelper';
import { cloneDeep } from 'lodash';
import { usePrompt } from '../../form/baseForm/usePrompt';

const useEditableBaseFormGrid = <TModel extends EditableGridEntity>(
    props: EditableBaseFormGridProps<TModel>
) => {
    const { onSave, rowData, isLoading, handleRowValidations } = props;

    const [currentRowData, setCurrentRowData] = useState<TModel[]>(null);

    const { verifyChanges } = useVerificationChangesHelper({
        blankEntity: rowData,
        executeVerification: true,
        keyField: props.dataKey,
    });

    const { notificationMethods, editableBaseFormGrid } = verifyChanges;

    const { hasCurrentRecordChanged, clearChangesHistory, setOriginalEntity } =
        editableBaseFormGrid;

    const { notifyEditableBaseFormGridChanges } = notificationMethods;

    const [showConfirm, setShowConfirm] = useState(true);

    usePrompt(
        'Unsaved Changes will be lost. Do you wish to proceed?',
        hasCurrentRecordChanged ? hasCurrentRecordChanged() : false,
        showConfirm
    );

    const handleGridEdits = (rows: TModel[]) => {
        const currentRows = rows?.filter(
            (row) => row.rowStatus && !row.hasError
        );
        notifyEditableBaseFormGridChanges(currentRows);
        setCurrentRowData(currentRows);
        setShowConfirm(hasCurrentRecordChanged());
    };

    const refreshData = () => {
        const updatedFields = [...rowData];

        currentRowData?.forEach((row) => {
            const index = rowData.findIndex(
                (oldRow) => oldRow.rowId === row.rowId
            );

            if (index > -1) {
                updatedFields[index] = row;
            } else {
                updatedFields.push(row);
            }
        });

        setOriginalEntity(updatedFields);
    };

    const handleSaveButtonClick = () => {
        onSave(currentRowData);
        refreshData();
        clearChangesHistory();
    };

    const onHandleRowValidations = (editedRows: TModel[]) => {
        const currentRows = editedRows?.filter((row) => row.rowStatus);
        return handleRowValidations ? handleRowValidations(currentRows) : null;
    };

    useEffect(() => {
        if (!isLoading && rowData) {
            setOriginalEntity(cloneDeep(rowData));
        }
    }, [isLoading && rowData]);

    return {
        hasChanges: hasCurrentRecordChanged(),
        handleGridEdits,
        handleSaveButtonClick,
        onHandleRowValidations,
    };
};

export default useEditableBaseFormGrid;
