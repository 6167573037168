import { Grid } from '@mui/material';
import React from 'react';
import TextInput from '../../../../../../../../components/form/formInputs/TextInput/TextInput';
import {
    BlobData,
    SdsBlobSectionFormProps,
} from '../../../shared/blobs/hooks/SdsBlobSectionFormProps';
import {
    SdsHeader,
    SdsSubSection14_7,
} from '../../../../../../../../types/formulation';
import useSdsBlobSectionForm from '../../../shared/blobs/hooks/useSdsBlobSectionForm';
import SdsSubSectionForm from '../../../shared/blobs/components/SdsSubSectionForm';
import TranslatableText from '../../../../../../../../components/i18n/TranslatableText';
import { SDSFormDefs } from '../../../../../../../../constants/i18n/translations/termDefinitions/formulation';
const SubSection14_7Form = (props: SdsBlobSectionFormProps<SdsHeader>) => {
    const { isParentLoading } = props.parentData;

    const { disabledField } = props.permissions;

    const blobData: BlobData<SdsSubSection14_7> = {
        sectionNumber: 14,
        subSectionNumber: 7,
        blankBlobEntity: {
            transportInBulk: '',
            additionalText: '',
        },
    };

    const { fields, isBlobLoading, formMethods, handleFieldChange } =
        useSdsBlobSectionForm<SdsHeader, SdsSubSection14_7>({
            ...props,
            blobData,
        });

    return (
        <SdsSubSectionForm
            formTitle={
                (
                    <TranslatableText
                        termSet={props.termSet}
                        termKey={SDSFormDefs.Transport_In_Bulk_Text}
                    />
                ) as unknown as string
            }
            isFormLoading={isParentLoading || isBlobLoading}
            content={
                <>
                    <Grid item xs={12}>
                        <TextInput
                            fullWidth
                            label={
                                <TranslatableText
                                    termSet={props.termSet}
                                    termKey={SDSFormDefs.Transport_In_Bulk}
                                />
                            }
                            id="transportInBulk"
                            disabled={disabledField}
                            formMethods={formMethods}
                            value={fields.transportInBulk}
                            onChange={handleFieldChange}
                            maxLength={2000}
                            multiline={true}
                            maxRows={15}
                            minRows={5}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextInput
                            fullWidth
                            label={
                                <TranslatableText
                                    termSet={props.termSet}
                                    termKey={
                                        SDSFormDefs.Additional_Text_Fourteen
                                    }
                                />
                            }
                            id="additionalText"
                            disabled={disabledField}
                            formMethods={formMethods}
                            value={fields.additionalText}
                            onChange={handleFieldChange}
                            maxLength={2000}
                            multiline={true}
                            maxRows={15}
                            minRows={5}
                        />
                    </Grid>
                </>
            }
        />
    );
};
export default SubSection14_7Form;
