import React from 'react';
import TreeNode from './TreeNode';
import './TreeView.css';
import { isNilOrEmpty } from '../../utils/objectUtils';
import { Node } from '../../types/Settings.types';

interface TreeViewProps {
    caption?: string;
    dataSource?: Array<any>;
    onCheckChange?: (node: Node) => void;
    type?: string;
    businessEntity?: boolean;
}

const TreeView = (props: TreeViewProps) => {
    const getTreeNode = (node: Node, index: number) => {
        if (props.businessEntity) {
            return (
                <TreeNode
                    businessEntity
                    checked={true}
                    onCheckChange={props.onCheckChange}
                    label={node.name}
                    value={node.id.toString()}
                    nestedChildren={node?.children}
                    isTopLevelNode={true}
                    hasChildren={!isNilOrEmpty(node?.children)}
                    key={node.id}
                />
            );
        }
        return (
            <TreeNode
                label={node.label}
                value={node.value}
                checked={node.checked}
                onCheckChange={props.onCheckChange}
                hasChildren={!isNilOrEmpty(node?.children)}
                nestedChildren={node?.children}
                isTopLevelNode={true}
                key={node.value ? node.value : index}
            />
        );
    };
    return (
        <>
            <div className="treeview w-20 border">
                <h5
                    className="pt-2 text-center"
                    style={{ height: 50, color: '#007cbb', margin: 0 }}>
                    {props?.caption}
                </h5>
                {props?.dataSource?.map((node: Node, index: number) =>
                    getTreeNode(node, index)
                )}
            </div>
        </>
    );
};

export default TreeView;
