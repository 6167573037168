import {
    Box,
    Dialog,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import React from 'react';
import DateInput from '../../../components/form/formInputs/DateInput/DateInput';
import { LoadingButton } from '@mui/lab';
import useAcquisitionOptionsModal from './hooks/useAcquisitionOptionsModal';
import { FA_Asset } from '../../../types/FixedAsset.types';
import { validateDateRange } from '../../../utils/comparisonUtils';
import TranslatableText from '../../../components/i18n/TranslatableText';
import { AcquisitionsGridDefs } from '../../../constants/i18n/translations/termDefinitions/accounting';

export interface ReportAcquisitionProps {
    open: boolean;
    onAcquisitionsLoaded: (contracts: FA_Asset[]) => void;
    onClose: () => void;
    termSet?: any;
}

const AcquisitionOptionsModal = (props: ReportAcquisitionProps) => {
    const {
        fields,
        handleFieldChange,
        handleGetAcquisitions,
        isLoadingAcquisitions,
    } = useAcquisitionOptionsModal(props);

    return (
        <Dialog
            onClose={props.onClose}
            open={props.open}
            fullWidth
            maxWidth={'sm'}>
            <DialogTitle>
                <Box display="flex" alignItems="center">
                    <Box flexGrow={1}>
                        <TranslatableText
                            termSet={props.termSet}
                            termKey={AcquisitionsGridDefs.Acquisition_Options}
                        />
                    </Box>
                    <Box>
                        <IconButton onClick={props.onClose}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </Box>
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <DateInput
                            label={
                                <TranslatableText
                                    termSet={props.termSet}
                                    termKey={
                                        AcquisitionsGridDefs.Acquire_Date_Begin
                                    }
                                />
                            }
                            id="acquireBeginDate"
                            value={fields.acquireBeginDate}
                            onChange={handleFieldChange}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <DateInput
                            label={
                                <TranslatableText
                                    termSet={props.termSet}
                                    termKey={
                                        AcquisitionsGridDefs.Acquire_Date_End
                                    }
                                />
                            }
                            id="acquireEndDate"
                            value={fields.acquireEndDate}
                            onChange={handleFieldChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <LoadingButton
                            fullWidth
                            variant="contained"
                            type="submit"
                            disabled={
                                !validateDateRange(
                                    fields.acquireBeginDate,
                                    fields.acquireEndDate,
                                    true
                                )
                            }
                            loading={isLoadingAcquisitions}
                            onClick={handleGetAcquisitions}>
                            <TranslatableText
                                termSet={props.termSet}
                                termKey={AcquisitionsGridDefs.Get_Acquisitions}
                            />
                        </LoadingButton>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
};

export default AcquisitionOptionsModal;
