import { GridDefaults } from './Grid.constants';
import { AgGridReact } from 'ag-grid-react';
import React from 'react';
import './style.css';
import SelectablePopupGrid, {
    RenderCellValue,
} from './cellRenderers/selectablePopupGrid.render';
import DeleteColumn from './cellRenderers/deleteRow.render';
import EditableGridErrors from './EditableGridErrors';
import BaseGridToolbar from './BaseGridToolbar';
import GridPopovers from './GridPopovers';
import { BaseGridProps } from './Grids.propTypes';
import useBaseGrid from './hooks/useBaseGrid';
import { RowNode } from 'ag-grid-enterprise';

const BaseGrid = (props: BaseGridProps) => {
    const {
        gridRef,
        display,
        helpers,
        helperMethods,
        editableGrid,
        popoutGrid,
        entitySettings,
        children,
        sortGrid,
    } = useBaseGrid(props);

    /**
     * Gets all AgGrid rows
     */
    const getAllRows = (): any[] => {
        let rowData: any = [];
        gridRef?.current?.api?.forEachNode((node: RowNode) =>
            rowData.push(node.data)
        );
        return rowData;
    };

    return (
        <>
            {display.displayGrid && (
                <>
                    <EditableGridErrors editableGridProps={editableGrid} />
                    <div
                        className={
                            'ag-theme-alpine ' + (helpers.gridClass || '')
                        }
                        style={helpers.gridStyle}
                        data-testid={helpers.testId}>
                        {display.displayToolbar && (
                            <BaseGridToolbar
                                displayProps={display}
                                helperMethods={helperMethods}
                                editableGrid={editableGrid}
                                popoutGrid={popoutGrid}
                                entitySettings={entitySettings}
                                childrenProps={children}
                                useRowDrag={props.useRowDrag}
                                sortGrid={sortGrid}
                                getAllRows={getAllRows}
                                rowData={props.rowData}
                                helpers={helpers}
                            />
                        )}
                        <AgGridReact
                            {...props}
                            rowData={
                                props.rowData?.filter(
                                    (row: any) => row.rowStatus !== 'deleted'
                                ) || []
                            }
                            animateRows={props.useRowDrag}
                            onRowDragMove={
                                !props.onRowDragEnd &&
                                helperMethods.handleRowDrag
                            }
                            onRowDragEnd={
                                props.onRowDragEnd || helperMethods.onRowDragEnd
                            }
                            onGridSizeChanged={helperMethods.onGridSizeChanged}
                            onCellKeyDown={helperMethods.onCellKeyDown}
                            onRowDataChanged={(params: any) => {
                                props.isEditable &&
                                    editableGrid.handleValidationOnDataChange(
                                        params
                                    );
                                if (props?.onRowDataChanged) {
                                    props?.onRowDataChanged(params);
                                }

                                if (
                                    gridRef.current?.api.paginationGetTotalPages() >
                                        1 &&
                                    props.useRowDrag
                                ) {
                                    gridRef.current?.api.setColumnDefs(
                                        gridRef.current?.api
                                            .getColumnDefs()
                                            .filter((col: any) => {
                                                return col.field !== 'dragRow';
                                            })
                                    );
                                }
                            }}
                            frameworkComponents={{
                                cellEditor: (params: any) => (
                                    <SelectablePopupGrid
                                        params={params}
                                        context={helpers.context}
                                        applyFilter={
                                            editableGrid.singleSelectApplyFilter !==
                                            false
                                        }
                                    />
                                ),
                                cellRender: RenderCellValue,
                                deleteColumn: DeleteColumn,
                                ...helpers.frameworkComponents,
                            }}
                            domLayout={helpers.domLayout}
                            accentedSort={GridDefaults.accentedSort}
                            ref={gridRef}
                            immutableData={helpers.hasImmutableData}
                            getRowNodeId={
                                helpers.dataKey
                                    ? (data) => data[helpers.dataKey]
                                    : null
                            }
                            tooltipShowDelay={helpers.tooltipShowDelay}
                            pagination={helpers.pagination}
                            paginationPageSize={helpers.paginationPageSize}
                            pinnedBottomRowData={
                                helpers.displaySummaryRow
                                    ? helpers.pinnedBottomRowData
                                    : []
                            }
                            onGridReady={helperMethods.onGridReady}
                            onFirstDataRendered={
                                helperMethods.onFirstDataRendered
                            }>
                            {children.children}
                        </AgGridReact>
                        <GridPopovers
                            popoutGrid={popoutGrid}
                            entitySettings={entitySettings}
                            display={display}
                            sortGrid={sortGrid}
                            gridRef={gridRef}
                            useRowDrag={props.useRowDrag}
                            parentProps={props}
                        />
                    </div>
                </>
            )}
        </>
    );
};

BaseGrid.defaultProps = {
    domLayout: GridDefaults.domLayout,
    tooltipShowDelay: GridDefaults.tooltipShowDelay,
    pagination: GridDefaults.pagination,
    paginationPageSize: GridDefaults.paginationPageSize,
    sizeColumnsToFit: GridDefaults.sizeColumnsToFit,
    immutableData: GridDefaults.immutableData,
    displayToolbar: GridDefaults.displayToolbar,
};

export default BaseGrid;
