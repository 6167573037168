import {
    BaseCreateEntityArgs,
    BaseUpdateEntityArgs,
} from '../../../serviceInterfaces';
import { ItemMaster } from '../../../../types/formulation';
import { datacorAppApi } from '../../../datacorAppApi';

const serviceUrl: string = 'item-master';
const templateServiceUrl: string = 'item-attributes/apply-template';
const updateItemAttributesServiceUrl: string = 'item-attributes/update';

export const itemMasterApi = datacorAppApi.injectEndpoints({
    endpoints: (build) => ({
        createItemMaster: build.mutation<
            ItemMaster,
            BaseCreateEntityArgs<ItemMaster>
        >({
            query: (args) => ({
                url: serviceUrl,
                method: 'POST',
                body: args.postBody,
            }),
            invalidatesTags: ['ItemMaster'],
        }),
        updateItemMaster: build.mutation<
            ItemMaster,
            BaseUpdateEntityArgs<ItemMaster>
        >({
            query: (args) => ({
                url: `${serviceUrl}/${args.id}`,
                method: 'PUT',
                body: args.postBody,
            }),
            invalidatesTags: ['ItemMaster', 'ActiveItemMaster'],
        }),
        deleteItemMaster: build.mutation<ItemMaster, string>({
            query: (id) => ({
                url: `${serviceUrl}/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['ItemMaster'],
        }),
        getItemMaster: build.query<ItemMaster, string>({
            query: (id) => {
                return {
                    url: `${serviceUrl}/${id}`,
                    params: { full: 'true' },
                };
            },
            providesTags: ['ActiveItemMaster'],
        }),
        getAllItemsMaster: build.query<ItemMaster[], void>({
            query: () => {
                return {
                    url: serviceUrl,
                };
            },
            providesTags: ['ItemMaster'],
        }),
        listItemsMasterDropdown: build.query<
            { label: string; value: bigint }[],
            void
        >({
            query: () => {
                return {
                    url: serviceUrl,
                };
            },
            transformResponse: (response: ItemMaster[]) => {
                return response.map((itemMaster: ItemMaster) => {
                    return {
                        label: itemMaster.itemId,
                        value: itemMaster.id,
                        description: itemMaster.itemName,
                    };
                });
            },
            providesTags: ['ItemMaster'],
        }),
        applyTemplate: build.mutation<any, any>({
            query: (args) => ({
                url: `${templateServiceUrl}/${args.id}`,
                method: 'POST',
                body: args.postBody,
            }),
            invalidatesTags: ['ItemMaster', 'ActiveItemMaster'],
        }),
        updateItemAttribute: build.mutation<any, any>({
            query: (args) => ({
                url: `${updateItemAttributesServiceUrl}/${args.id}`,
                method: 'POST',
                body: args.postBody,
            }),
            invalidatesTags: ['ItemMaster', 'ActiveItemMaster'],
        }),
        getAllUsableItemsMaster: build.query<ItemMaster[], void>({
            query: () => {
                return {
                    url: serviceUrl,
                };
            },
            transformResponse: (response: any, meta: any, arg: any) => {
                return response.filter(
                    (item: ItemMaster) =>
                        item?.itemStatus?.status !== 'Obsolete'
                );
            },
            providesTags: ['ItemMaster'],
        }),
    }),
});

export const {
    useCreateItemMasterMutation,
    useUpdateItemMasterMutation,
    useDeleteItemMasterMutation,
    useGetItemMasterQuery,
    useGetAllItemsMasterQuery,
    useListItemsMasterDropdownQuery,
    useApplyTemplateMutation,
    useUpdateItemAttributeMutation,
    useGetAllUsableItemsMasterQuery,
} = itemMasterApi;
