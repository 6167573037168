import { EntityBase } from '../../../../../../../types/Shared.types';
import {
    CasMaster,
    SdsCasComposition,
    SdsEcoToxicity,
    SdsExposure,
    SdsHeader,
    SdsRegulation,
} from '../../../../../../../types/formulation';
import { useDispatch } from 'react-redux';
import { RowStatus } from '../../../../../../../components/grids/hooks/useBaseGridEditable';
import { casMasterApi } from '../../../../../../../services/formulation/casMaster/cas/casMaster.service';

export interface AutoPopulateSectionsWithCasDetails {
    currentSdsHeader: SdsHeader;
    helpers: {
        /**
         *  @param childrenNodeName Data node in the parent JSON object to store the new data.
         *  @param childrenRecords List of children records to be saved in the parent JSON object.
         *  **/
        handleChildrenRecords: <TChild extends EntityBase>(
            childrenNodeName: string,
            childrenRecords: TChild[]
        ) => void;
    };
}

const useAutoPopulateSectionsWithCasDetails = (
    props: AutoPopulateSectionsWithCasDetails
) => {
    const { currentSdsHeader, helpers } = props;

    const { handleChildrenRecords } = helpers;

    const dispatch = useDispatch();

    const createSdsExposures = (casRecord: CasMaster): void => {
        const sdsExposures: SdsExposure[] = casRecord?.casExposures.map(
            (casExposure) => {
                return {
                    sdsId: currentSdsHeader.id,
                    casId: casExposure.casId,
                    exposureAgencyId: casExposure.exposureAgencyId,
                    exposureValue: casExposure.exposureValue,
                    exposureForm: casExposure.exposureForm,
                    notes: casExposure.notes,
                    businessEntityId: casExposure.businessEntityId,
                    rowStatus: RowStatus.ADDED,
                    autoPopulated: true,
                } as SdsExposure;
            }
        );
        handleChildrenRecords('sdsExposures', [
            ...currentSdsHeader?.sdsExposures,
            ...sdsExposures,
        ]);
    };

    const createSdsEcoToxicities = (casRecord: CasMaster): void => {
        const sdsEcoToxicities: SdsEcoToxicity[] =
            casRecord?.casEcoToxicities.map((casEcoToxicity) => {
                return {
                    sdsId: currentSdsHeader.id,
                    casId: casEcoToxicity.casId,
                    toxicTestId: casEcoToxicity.toxicTestId,
                    toxicSpeciesId: casEcoToxicity.toxicSpeciesId,
                    speciesClassId: casEcoToxicity.speciesClassId,
                    effectiveDose: casEcoToxicity.effectiveDose,
                    exposureTime: casEcoToxicity.exposureTime,
                    notes: casEcoToxicity.notes,
                    source: casEcoToxicity.source,
                    businessEntityId: casEcoToxicity.businessEntityId,
                    rowStatus: RowStatus.ADDED,
                    autoPopulated: true,
                } as SdsEcoToxicity;
            });
        handleChildrenRecords('sdsEcoToxicities', [
            ...currentSdsHeader?.sdsEcoToxicities,
            ...sdsEcoToxicities,
        ]);
    };

    const createSdsRegulations = (casRecord: CasMaster): void => {
        const sdsRegulations: SdsRegulation[] = casRecord?.casRegulations.map(
            (casRegulation) => {
                return {
                    sdsId: currentSdsHeader.id,
                    casId: casRegulation.casId,
                    code: casRegulation.code,
                    title: casRegulation.title,
                    threshold: casRegulation.threshold,
                    importManufactureLimit:
                        casRegulation.importManufactureLimit,
                    regulationBodyId: casRegulation.regulationBodyId,
                    regulationSectionId: casRegulation.regulationSectionId,
                    regulationId: casRegulation.regulationId,
                    notes: casRegulation.notes,
                    onList: casRegulation.onList,
                    onListDisplay: casRegulation.onListDisplay,
                    stateCodeId: casRegulation.stateCodeId,
                    stateCodeName: casRegulation.stateCodeName,
                    countryCodeId: casRegulation.countryCodeId,
                    businessEntityId: casRegulation.businessEntityId,
                    rowStatus: RowStatus.ADDED,
                    autoPopulated: true,
                } as SdsRegulation;
            }
        );
        handleChildrenRecords('sdsRegulations', [
            ...currentSdsHeader?.sdsRegulations,
            ...sdsRegulations,
        ]);
    };

    const createRecordsByCasMaster = (casRecord: CasMaster): void => {
        if (casRecord) {
            if (casRecord?.casExposures && casRecord?.casExposures.length > 0) {
                createSdsExposures(casRecord);
            }

            if (
                casRecord?.casEcoToxicities &&
                casRecord?.casEcoToxicities.length > 0
            ) {
                createSdsEcoToxicities(casRecord);
            }

            if (
                casRecord?.casRegulations &&
                casRecord?.casRegulations.length > 0
            ) {
                createSdsRegulations(casRecord);
            }
        }
    };

    const autoPopulateSections = async (
        row: SdsCasComposition
    ): Promise<void> => {
        if (row.rowStatus === RowStatus.ADDED && !row.autoPopulated) {
            const casMasterId = row.casValue.id.toString();
            const result: any = await dispatch(
                casMasterApi.endpoints.getCas.initiate(casMasterId)
            );
            const { data } = result;
            createRecordsByCasMaster(data as CasMaster);
            row.autoPopulated = true;
        }
    };

    return {
        autoPopulateSections,
    };
};

export default useAutoPopulateSectionsWithCasDetails;
