import React from 'react';
import BaseGrid from '../../../../../../../../components/grids/BaseGrid';
import useCasGhsHazardPhrasesGrid from './hooks/useCasGhsHazardPhrasesGrid';
import {
    CasGhsHazardPhrase,
    CasMaster,
    GhsHazardPhrase,
} from '../../../../../../../../types/formulation';
import { GridOptions } from '../../../../../../../../components/grids/Grid.constants';
import DatacorLogoSpinner from '../../../../../../../../components/datacorLogoSpinner/DatacorLogoSpinner';
import PhraseVerbiageMessageModal from '../../../../../../shared/phraseVerbiageMessageModal/PhraseVerbiageMessageModal';
import FormPartial from '../../../../../../../../components/form/formContainer/FormPartial';
import { ChildEditableGridWithCopyFunctionProps } from '../../../../../../../grid/utils/editableGrid/ChildEditableGridWithCopyFunctionProps';
import GhsHazardPhrasesSelectionModal from '../../../../../../shared/multiSelectModals/ghsHazardPhrases/GhsHazardPhrasesSelectionModal';
import { CasMasterFormDefs } from '../../../../../../../../constants/i18n/translations/termDefinitions/formulation';
import TranslatableText from '../../../../../../../../components/i18n/TranslatableText';
import CustomHeader from '../../../../../../../../components/grids/CustomHeader';

const CasGhsHazardPhrasesGrid = (
    props: ChildEditableGridWithCopyFunctionProps<CasMaster, CasGhsHazardPhrase>
) => {
    const {
        hazardPhraseList,
        showMultiSelectGridModal,
        columnDefs,
        showVerbiageMessage,
        handleSetShowMultiSelectGridModal,
        handleSelectedRecords,
        handleSaveGridEdits,
        handleShowVerbiageMessage,
        handleRowValidations,
        termSet,
    } = useCasGhsHazardPhrasesGrid(props);

    const { isParentLoading } = props.parentData;

    const { showAddButton, showDeleteButton, showSortButton, isGridEditable } =
        props.displayGridButtons;

    const { rowsData } = props.copyMethods;

    const colDefOptions = {
        ...GridOptions.sortFilterAndWrapColumns,
        floatingFilter: true,
    };

    return !columnDefs ? (
        <DatacorLogoSpinner />
    ) : (
        <React.Fragment>
            <FormPartial
                title={
                    (
                        <TranslatableText
                            termKey={CasMasterFormDefs.Ghs_Hazard_Phrases}
                            termSet={termSet}
                        />
                    ) as unknown as string
                }>
                <GhsHazardPhrasesSelectionModal
                    openModal={showMultiSelectGridModal}
                    onClose={() => handleSetShowMultiSelectGridModal(false)}
                    onSelected={(selectedRecords: GhsHazardPhrase[]) =>
                        handleSelectedRecords(selectedRecords)
                    }
                    hazardPhraseList={hazardPhraseList}
                />
                <BaseGrid
                    autoSize={true}
                    displayGrid={!isParentLoading}
                    rowData={rowsData}
                    useRowDrag={showSortButton}
                    displaySortButton={showSortButton}
                    sortKey={'sdsSortOrder'}
                    dataKey={'phraseId'}
                    columnDefs={columnDefs}
                    defaultColDef={colDefOptions}
                    gridClass="full-size-item"
                    displayToolbar={true}
                    displayCreateNewButton={showAddButton}
                    displayExportDataButton={false}
                    displayResetStateButton={false}
                    displayDeleteButton={showDeleteButton}
                    sizeColumnsToFit={true}
                    onCreateNew={() => handleSetShowMultiSelectGridModal(true)}
                    onHandleRowValidations={handleRowValidations}
                    onHandleGridEdits={(records) =>
                        handleSaveGridEdits(records)
                    }
                    isEditable={isGridEditable}
                    gridButtonTitle={'GHS Hazard Phrases'}
                    newRowPlaceholderValues={{
                        phraseId: 'Enter Phrase ID...',
                        phraseText: 'Enter Phrase Text...',
                        hazardTypeId: 'Select a value',
                        sdsSortOrder: 'Enter a Sort Order Value',
                    }}
                    createRow={false}></BaseGrid>
                <PhraseVerbiageMessageModal
                    showModal={showVerbiageMessage}
                    handleClose={() => handleShowVerbiageMessage(false)}
                    messageText={
                        (
                            <TranslatableText
                                termSet={termSet}
                                termKey={
                                    CasMasterFormDefs.Ghs_Hazard_Phrases_Product_Specific_Verbiage
                                }
                            />
                        ) as unknown as string
                    }
                />
            </FormPartial>
        </React.Fragment>
    );
};

export default CasGhsHazardPhrasesGrid;
