import { useContext, useEffect, useState } from 'react';
import { FA_DepreciationEntry } from '../../../../types/FixedAsset.types';
import { isNilOrEmpty } from '../../../../utils/objectUtils';
import {
    useLockDepreciationEntriesMutation,
    useUnlockDepreciationEntriesMutation,
} from '../../../../services/fixedAssets/fixedAssets.service';
import { PermissionsUtil } from '../../../../utils/permissions/permissionsUtil';
import { PERMISSIONS } from '../../../../constants/permissions/Permissions.constants';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import SettingsContext from '../../../../contexts/settings.context';
import { useGetTermSetQuery } from '../../../../services/i18n/i18n.service';
import { ACCOUNTING_DEFS } from '../../../../constants/i18n/translations/termSetDefinitions/accounting';
import { skipToken } from '@reduxjs/toolkit/query';

const useFixedAssetDepreciationEntriesActionsModal = (
    selectedEntries: FA_DepreciationEntry[],
    onEntriesUpdated: (entries: FA_DepreciationEntry[]) => void,
    onClose: () => void
) => {
    const user = useSelector((state: RootState) => state.user);
    const { settings } = useContext(SettingsContext);
    const { data: termSet } = useGetTermSetQuery(
        settings?.userSettings
            ? {
                  languageId: settings?.userSettings?.languageId,
                  code: ACCOUNTING_DEFS.DEPRECIATION_ENTRIES_GRID,
              }
            : skipToken
    );
    const [shouldLockEntries, setShouldLockEntries] = useState<boolean>(false);
    const [canToggleLockUnlock, setCanToggleLockUnlock] =
        useState<boolean>(false);

    const [lockEntries, { isLoading: isLockingEntries }] =
        useLockDepreciationEntriesMutation();
    const [unlockEntries, { isLoading: isUnlockingEntries }] =
        useUnlockDepreciationEntriesMutation();

    const canLockDepreciationEntries = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.FIXED_ASSETS.DEPRECIATION_ENTRIES.LOCK
    );

    useEffect(() => {
        if (!isNilOrEmpty(selectedEntries)) {
            const hasUnlockedEntries: boolean = selectedEntries.some(
                (entry) => !entry.locked
            );
            const hasLockedEntries: boolean = selectedEntries.some(
                (entry) => entry.locked
            );

            setShouldLockEntries(hasUnlockedEntries);
            setCanToggleLockUnlock(hasUnlockedEntries && hasLockedEntries);
        } else {
            setShouldLockEntries(false);
            setCanToggleLockUnlock(false);
        }
    }, [selectedEntries]);

    const handleUpdateEntries = async (event: any) => {
        event.preventDefault();

        if (shouldLockEntries) {
            const idList: bigint[] = selectedEntries
                .filter((entry) => !entry.locked)
                .map((entry) => entry.id);

            try {
                const response: any = await lockEntries({ ids: idList });
                onEntriesUpdated(response.data);
            } catch (e) {
                console.log(e);
            }
        } else {
            const idList: bigint[] = selectedEntries
                .filter((entry) => entry.locked)
                .map((entry) => entry.id);

            try {
                const response: any = await unlockEntries({ ids: idList });
                onEntriesUpdated(response.data);
            } catch (e) {
                console.log(e);
            }
        }

        onClose();
    };

    return {
        shouldLockEntries,
        setShouldLockEntries,
        canToggleLockUnlock,
        isLockingEntries,
        isUnlockingEntries,
        handleUpdateEntries,
        canLockDepreciationEntries,
        termSet,
    };
};

export default useFixedAssetDepreciationEntriesActionsModal;
