import { useContext, useEffect, useState } from 'react';
import { RootStateOrAny, useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { skipToken } from '@reduxjs/toolkit/query';
import { GridReadyEvent, GridApi, ColumnApi } from 'ag-grid-community';
import { RootState } from '../../../../../store';
import { updateGridModels } from '../../../../../store/grids';
import { SafetyDataSheetSectionHeader } from '../../../../../types/formulation';
import { PermissionsUtil } from '../../../../../utils/permissions/permissionsUtil';
import { PERMISSIONS } from '../../../../../constants/permissions/Permissions.constants';
import { useListSafetyDataSheetSectionHeadersByLangIdQuery } from '../../../../../services/formulation/safetyDataSheetSectionHeaders/safetyDataSheetSectionHeaders.service';
import SettingsContext from '../../../../../contexts/settings.context';
import { useGetTermSetQuery } from '../../../../../services/i18n/i18n.service';
import { FORMULATION_DEFS } from '../../../../../constants/i18n/translations/termSetDefinitions/formulation';

export interface SafetyDataSheetHeaderGridProps {
    defaultLanguageId: bigint;
    currentheaderIdLocaleGrpId: string;
    handleSetHeaderKeyList(
        headerList: Array<SafetyDataSheetSectionHeader>
    ): void;
    handlePromptFormDirty(onConfirm: () => void, onCancel?: () => void): void;
}

const useSafetyDataSheetSectionHeaderGrid = ({
    currentheaderIdLocaleGrpId,
    defaultLanguageId,
    handleSetHeaderKeyList,
    handlePromptFormDirty,
}: SafetyDataSheetHeaderGridProps) => {
    const user = useSelector((state: RootStateOrAny) => state.user);
    const { formulation } = useSelector((state: RootState) => state.grids);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [gridApi, setGridApi] = useState(null as GridApi);
    const [gridColumnApi, setGridColumnApi] = useState(null as ColumnApi);

    const {
        data: safetyDataSheetHeaderList,
        isLoading: isLoadingSafetyDataSheetHeaderList,
    } = useListSafetyDataSheetSectionHeadersByLangIdQuery(
        defaultLanguageId
            ? {
                  langId: defaultLanguageId.toString(),
              }
            : skipToken
    );

    const canViewSafetyDataSheetHeader = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.FORMULATION.SAFETY_DATA_SHEET_SECTION_HEADER.VIEW
    );

    const canCreateSafetyDataSheetHeader = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.FORMULATION.SAFETY_DATA_SHEET_SECTION_HEADER.CREATE
    );

    const { settings } = useContext(SettingsContext);
    const { data: termSet } = useGetTermSetQuery(
        settings?.userSettings
            ? {
                  languageId: settings?.userSettings?.languageId,
                  code: FORMULATION_DEFS.SDS_SECTION_HEADERS_GRID,
              }
            : skipToken
    );

    useEffect(() => {
        selectRowNode(currentheaderIdLocaleGrpId);
    }, [currentheaderIdLocaleGrpId, gridApi]);

    useEffect(() => {
        if (safetyDataSheetHeaderList) {
            handleSetHeaderKeyList(safetyDataSheetHeaderList);
        }
    }, [safetyDataSheetHeaderList, isLoadingSafetyDataSheetHeaderList]);

    const selectRowNode = (headerIdLocaleGrpId: string) => {
        if (gridApi) {
            if (headerIdLocaleGrpId) {
                const node = gridApi.getRowNode(headerIdLocaleGrpId);
                if (node && !node.isSelected()) {
                    node.setSelected(true);
                }
            } else {
                gridApi.deselectAll();
            }
        }
    };

    const handleRowDataChanged = () => {
        selectRowNode(currentheaderIdLocaleGrpId);
    };

    const handleSelectedRowChange = (
        selectedHeader: SafetyDataSheetSectionHeader
    ) => {
        if (selectedHeader) {
            const onConfirm = () => {
                navigate(
                    `/formulation/sdsSectionHeader/${selectedHeader.headerId}/${selectedHeader.localeGroupId}`
                );
            };
            const onCancel = () => {
                if (currentheaderIdLocaleGrpId) {
                    selectRowNode(currentheaderIdLocaleGrpId);
                } else if (gridApi) {
                    gridApi.deselectAll();
                }
            };

            handlePromptFormDirty(onConfirm, onCancel);
        }
    };

    const onGridReady = (params: GridReadyEvent) => {
        setGridApi(params.api);
        setGridColumnApi(params.columnApi);
    };

    const onFirstDataRendered = () => {
        applyGridState();
    };

    const applyGridState = () => {
        if (formulation.sdsSectionHeader.column && gridColumnApi) {
            gridColumnApi.applyColumnState({
                state: formulation.sdsSectionHeader.column,
                applyOrder: true,
            });
        }
        if (formulation.sdsSectionHeader.filter && gridApi) {
            gridApi.setFilterModel(formulation.sdsSectionHeader.filter);
        }
        if (currentheaderIdLocaleGrpId) {
            selectRowNode(currentheaderIdLocaleGrpId);
        }
    };

    const handleAddNewRecord = () => {
        handlePromptFormDirty(() => {
            navigate('/formulation/sdsSectionHeader/new');
            if (gridApi) {
                gridApi.deselectAll();
            }
        });
    };

    const onSortChanged = () => {
        dispatch(
            updateGridModels({
                gridLocation: 'formulation',
                gridName: 'sdsSectionHeader',
                type: 'column',
                model: gridColumnApi.getColumnState(),
            })
        );
    };

    const onFilterChanged = () => {
        dispatch(
            updateGridModels({
                gridLocation: 'formulation',
                gridName: 'sdsSectionHeader',
                type: 'filter',
                model: gridApi.getFilterModel(),
            })
        );
    };

    return {
        isLoadingSafetyDataSheetHeaderList,
        canViewSafetyDataSheetHeader,
        canCreateSafetyDataSheetHeader,
        safetyDataSheetHeaderList,
        onGridReady,
        handleSelectedRowChange,
        handleRowDataChanged,
        handleAddNewRecord,
        onFirstDataRendered,
        onSortChanged,
        onFilterChanged,
        termSet,
    };
};

export default useSafetyDataSheetSectionHeaderGrid;
