import { Grid } from '@mui/material';
import React from 'react';
import TextInput from '../../../../../../../../components/form/formInputs/TextInput/TextInput';
import {
    BlobData,
    SdsBlobSectionFormProps,
} from '../../../shared/blobs/hooks/SdsBlobSectionFormProps';
import {
    SdsHeader,
    SdsSubSection10_1,
} from '../../../../../../../../types/formulation';
import useSdsBlobSectionForm from '../../../shared/blobs/hooks/useSdsBlobSectionForm';
import SdsSubSectionForm from '../../../shared/blobs/components/SdsSubSectionForm';
import TranslatableText from '../../../../../../../../components/i18n/TranslatableText';
import { SDSFormDefs } from '../../../../../../../../constants/i18n/translations/termDefinitions/formulation';
const SubSection10_1Form = (props: SdsBlobSectionFormProps<SdsHeader>) => {
    const { isParentLoading } = props.parentData;

    const { disabledField } = props.permissions;

    const blobData: BlobData<SdsSubSection10_1> = {
        sectionNumber: 10,
        subSectionNumber: 1,
        blankBlobEntity: {
            reactivity: '',
        },
    };

    const { fields, isBlobLoading, formMethods, handleFieldChange } =
        useSdsBlobSectionForm<SdsHeader, SdsSubSection10_1>({
            ...props,
            blobData,
        });

    return (
        <SdsSubSectionForm
            formTitle={
                (
                    <TranslatableText
                        termSet={props.termSet}
                        termKey={SDSFormDefs.Reactivity_Text}
                    />
                ) as unknown as string
            }
            isFormLoading={isParentLoading || isBlobLoading}
            content={
                <Grid item xs={12}>
                    <TextInput
                        fullWidth
                        label={
                            <TranslatableText
                                termSet={props.termSet}
                                termKey={SDSFormDefs.Reactivity}
                            />
                        }
                        id="reactivity"
                        disabled={disabledField}
                        formMethods={formMethods}
                        value={fields.reactivity}
                        onChange={handleFieldChange}
                        maxLength={2000}
                        multiline={true}
                        maxRows={15}
                        minRows={5}
                    />
                </Grid>
            }
        />
    );
};
export default SubSection10_1Form;
