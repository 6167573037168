export const ACCOUNTING_DEFS = {
    FIXED_ASSET_REGISTER_GRID: 'fixed-asset-register-grid',
    FIXED_ASSET_SCHEDULE_GRID: 'fixed-asset-schedule-grid',
    DEPRECIATION_ENTRIES_GRID: 'depreciation-entries-grid',
    FIXED_ASSET_REGISTER_FORM: 'fixed-asset-register-form',
    DISPOSAL_ENTRIES_GRID: 'disposal-entries-grid',
    CONTRACTS_FORM: 'contracts-form',
    ASSET_ENTRIES_GRID: 'asset-entries-grid',
    CONTRACTS_GRID: 'contracts-grid',
    CONTRACT_COVERAGE_GRID: 'contract-coverage-grid',
    FUNCTION_CODES_GRID: 'function-codes-grid',
    CURRENCY_GRID: 'currency-grid',
    CURRENCY_FORM: 'currency-form',
    CURRENCY_RATE_TYPE_GRID: 'currency-rate-types-grid',
    CURRENCY_RATE_TYPE_FORM: 'currency-rate-types-form',
    ACCOUNTING_SETTINGS: 'accounting-settings',
    CURRENCY_EXCHANGE_RATE_GRID: 'currency-exchange-rates-grid',
    FISCAL_YEAR_STATUS_FORM: 'fiscal-year-status-form',
    FISCAL_YEAR_STATUS_GRID: 'fiscal-year-status-grid',
    FISCAL_YEAR_FORM: 'fiscal-year-form',
    FISCAL_YEAR_GRID: 'fiscal-year-grid',
    PERIOD_STATUS_FORM: 'period-status-form',
    PERIOD_STATUS_GRID: 'period-status-grid',
    CURRENCY_EXCHANGE_RATE_FILTERS: 'currency-exchange-rates-filters',
    CURRENCY_EXCHANGE_RATE_FORM: 'currency-exchange-rates-form',
    FUNCTION_CODES_FORM: 'function-codes-form',
    ASSET_COST_CODES_GRID: 'asset-cost-codes-grid',
    ASSET_COST_CODES_FORM: 'asset-cost-codes-form',
    DEPRECIATION_METHOD_STATUSES_GRID: 'depreciation-method-statuses-grid',
    DEPRECIATION_METHOD_STATUSES_FORM: 'depreciation-method-statuses-form',
    DEPRECIATION_METHOD_TYPES_GRID: 'depreciation-method-types-grid',
    DEPRECIATION_METHOD_TYPES_FORM: 'depreciation-method-types-form',
    DEPRECIATION_METHODS_GRID: 'depreciation-methods-grid',
    DEPRECIATION_METHODS_FORM: 'depreciation-methods-form',
    ASSET_STATUSES_GRID: 'asset-statuses-grid',
    ASSET_STATUSES_FORM: 'asset-statuses-form',
    ASSET_TYPES_GRID: 'asset-types-grid',
    ASSET_TYPES_FORM: 'asset-types-form',
    CONTRACT_TYPES_GRID: 'contract-types-grid',
    CONTRACT_TYPES_FORM: 'contract-types-form',
    GENERAL_LEDGER_ACCOUNTS_GRID: 'general-ledger-accounts-grid',
    GENERAL_LEDGER_ACCOUNTS_FORM: 'general-ledger-accounts-form',
    PERIOD_QUARTERS_GRID: 'period-quarter-grid',
    PERIOD_QUARTER_FORM: 'period-quarter-form',
    ACQUISITIONS_GRID: 'acquisitions-grid',
    DEPRECIATION_SUMMARY_GRID: 'depreciation-summary-grid',
    DEPRECIATION_DETAIL_GRID: 'depreciation-detail-grid',
    DISPOSALS_GRID: 'disposals-grid',
};
