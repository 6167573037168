import React from 'react';
import {
    useGetGeneralLedgerAccountQuery,
    useCreateGeneralLedgerAccountMutation,
    useUpdateGeneralLedgerAccountMutation,
    useDeleteGeneralLedgerAccountMutation,
    useGetBalanceTypesForDropdownQuery,
    useGetAccountTypesForDropdownQuery,
    useGetAccountStatusesForDropdownQuery,
} from '../../../services/accounting/accounting.service';
import { skipToken } from '@reduxjs/toolkit/query';
import { RoutingValues, SelectionOption } from '../../../types/Shared.types';
import { GL_Account } from '../../../types/Accounting.types';
import useBaseForm from '../../form/hooks/useBaseForm';
import { isNilOrEmpty } from '../../../utils/objectUtils';
import { PermissionsUtil } from '../../../utils/permissions/permissionsUtil';
import { PERMISSIONS } from '../../../constants/permissions/Permissions.constants';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import useTranslation from '../../../components/i18n/hooks/useTranslation';
import { ACCOUNTING_DEFS } from '../../../constants/i18n/translations/termSetDefinitions/accounting';

const UseGeneralLedgerAccountForm = (id: string) => {
    const user = useSelector((state: RootState) => state.user);
    const { data: activeAccount, isLoading: isLoadingAccount } =
        useGetGeneralLedgerAccountQuery(
            id === RoutingValues.newId ? skipToken : id
        );
    const { data: accountStatusOptions, isLoading: isLoadingAccountStatuses } =
        useGetAccountStatusesForDropdownQuery();
    const { data: accountTypeOptions, isLoading: isLoadingAccountTypes } =
        useGetAccountTypesForDropdownQuery();
    const { data: balanceTypeOptions, isLoading: isLoadingBalanceTypes } =
        useGetBalanceTypesForDropdownQuery();
    const [createGeneralLedgerAccount] =
        useCreateGeneralLedgerAccountMutation();
    const [updateGeneralLedgerAccount] =
        useUpdateGeneralLedgerAccountMutation();
    const [deleteGeneralLedgerAccount] =
        useDeleteGeneralLedgerAccountMutation();
    const canCreateChartOfAccount = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.FIXED_ASSETS.CHART_OF_ACCOUNTS.CREATE
    );
    const canUpdateChartOfAccount = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.FIXED_ASSETS.CHART_OF_ACCOUNTS.EDIT
    );
    const canDeleteChartOfAccount = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.FIXED_ASSETS.CHART_OF_ACCOUNTS.DELETE
    );

    const blankAccount: GL_Account = {
        number: '',
        name: '',
        description: '',
        accountStatusId: null,
        balanceTypeId: null,
        accountTypeId: null,
    };

    const { termSet } = useTranslation(
        ACCOUNTING_DEFS.GENERAL_LEDGER_ACCOUNTS_FORM
    );

    const hasFunctions = (): boolean => {
        return !isNilOrEmpty(activeAccount?.functions);
    };

    const {
        fields,
        handleFieldChange,
        formMethods,
        onCreate,
        onUpdate,
        onDelete,
        closeForm,
    } = useBaseForm({
        closePath: '/accounting/generalLedgerAccount',
        blankEntity: blankAccount,
        activeEntity: activeAccount,
        getDescription: () => {
            return `GL Account ${fields.number}`;
        },
        createEntity: () => {
            return createGeneralLedgerAccount({ postBody: fields });
        },
        updateEntity: () => {
            return updateGeneralLedgerAccount({
                id: id as unknown as number,
                postBody: fields,
            });
        },
        deleteEntity: () => {
            return deleteGeneralLedgerAccount(id);
        },
    });

    const handleAccountTypeChange = (event: any) => {
        //Needed to update the changed value
        fields.accountTypeId = event.target.value;
        formMethods.updateField('accountTypeId', fields.accountTypeId);
        //Assign default balance type based on selected account type (FAD-487)
        if (balanceTypeOptions && accountTypeOptions && fields.accountTypeId) {
            const account: SelectionOption = accountTypeOptions.find(
                (x: SelectionOption) => x.value === fields.accountTypeId
            );

            if (account) {
                let balanceTypeDeftOption: string = null;

                switch (account.label.toLowerCase()) {
                    case 'asset':
                    case 'expense':
                        balanceTypeDeftOption = 'debit';
                        break;
                    case 'equity':
                    case 'liability':
                    case 'revenue':
                        balanceTypeDeftOption = 'credit';
                        break;
                    default:
                        break;
                }

                if (balanceTypeDeftOption) {
                    const balanceTypeOption: SelectionOption =
                        balanceTypeOptions.find(
                            (x: SelectionOption) =>
                                x.label.toLowerCase() === balanceTypeDeftOption
                        );

                    if (balanceTypeOption) {
                        fields.balanceTypeId = balanceTypeOption.value;
                        formMethods.updateField(
                            'balanceTypeId',
                            fields.balanceTypeId
                        );
                    }
                }
            }
        }
    };

    return {
        fields,
        formMethods,
        accountStatusOptions,
        isLoadingAccountStatuses,
        accountTypeOptions,
        isLoadingAccountTypes,
        balanceTypeOptions,
        isLoadingBalanceTypes,
        onCreate,
        onUpdate,
        onDelete,
        closeForm,
        handleFieldChange,
        isLoadingAccount,
        activeAccount,
        hasFunctions,
        handleAccountTypeChange,
        canCreateChartOfAccount,
        canUpdateChartOfAccount,
        canDeleteChartOfAccount,
        termSet,
    };
};

export default UseGeneralLedgerAccountForm;
