import React from 'react';
import { GridOptions } from '../../../components/grids/Grid.constants';
import { ColDef } from 'ag-grid-community';
import DefaultColumnTypes from '../../../components/grids/columns/Column.constants';
import BaseFormGrid from '../../../components/grids/BaseFormGrid';
import useToxicSpeciesGrid from './hooks/useToxicSpeciesGrid';
import CustomHeader from '../../../components/grids/CustomHeader';
import { ToxicSpeciesGridDefs } from '../../../constants/i18n/translations/termDefinitions/formulation';
import TranslatableText from '../../../components/i18n/TranslatableText';

const ToxicSpeciesGrid = () => {
    const {
        isLoading,
        toxicSpeciesList,
        redirectToForm,
        canCreateToxicSpecies,
        canViewToxicSpecies,
        termSet,
    } = useToxicSpeciesGrid();

    const columnDefs: ColDef[] = [
        {
            field: 'code',
            headerComponentFramework: (props: any) => {
                return (
                    <CustomHeader
                        {...props}
                        termSet={termSet}
                        termKey={ToxicSpeciesGridDefs.Species_Code}
                    />
                );
            },
            ...DefaultColumnTypes.LongText,
        },
        {
            field: 'description',
            headerComponentFramework: (props: any) => {
                return (
                    <CustomHeader
                        {...props}
                        termSet={termSet}
                        termKey={ToxicSpeciesGridDefs.Description}
                    />
                );
            },
            minWidth: DefaultColumnTypes.LongText.minWidth,
            filter: DefaultColumnTypes.LongText.filter,
            ...DefaultColumnTypes.AutoHeight,
        },
    ];

    const defaultColumnDefinitionOptions = {
        ...GridOptions.sortFilterAndWrapColumns,
        floatingFilter: true,
    };

    return (
        <BaseFormGrid
            title={
                <>
                    <TranslatableText
                        termKey={ToxicSpeciesGridDefs.Title}
                        termSet={termSet}
                    />
                </>
            }
            displayGrid={canViewToxicSpecies}
            isLoading={isLoading}
            rowData={toxicSpeciesList}
            columnDefs={columnDefs}
            gridClass="full-size-item"
            displayToolbar={true}
            displayCreateNewButton={canCreateToxicSpecies}
            displayExportDataButton={false}
            displayResetStateButton={false}
            onCreateNew={() => redirectToForm(null)}
            onRowClicked={({ data }) => redirectToForm(data)}
            defaultColDef={defaultColumnDefinitionOptions}
            sizeColumnsToFit={true}></BaseFormGrid>
    );
};

export default ToxicSpeciesGrid;
