import React, {
    useContext,
    useEffect,
    useImperativeHandle,
    useState,
} from 'react';
import { ContractProps } from '../ContractGrid';
import { Contract } from '../../../../../types/Accounting.types';
import { useGetAllContractsQuery } from '../../../../../services/accounting/accounting.service';
import SettingsContext from '../../../../../contexts/settings.context';
import { useGetTermSetQuery } from '../../../../../services/i18n/i18n.service';
import { ACCOUNTING_DEFS } from '../../../../../constants/i18n/translations/termSetDefinitions/accounting';
import { skipToken } from '@reduxjs/toolkit/query';

const useContractGrid = (props: ContractProps, ref: any) => {
    const [selectedContracts, setSelectedContracts] = useState<Contract[]>([]);
    const { settings } = useContext(SettingsContext);
    const { data: termSet } = useGetTermSetQuery(
        settings?.userSettings
            ? {
                  languageId: settings?.userSettings?.languageId,
                  code: ACCOUNTING_DEFS.FIXED_ASSET_REGISTER_FORM,
              }
            : skipToken
    );
    const [showContractSelectionModal, setShowContractSelectionModal] =
        useState(false);

    const { data: contractList, isLoading: isLoadingContracts } =
        useGetAllContractsQuery();

    useEffect(() => {
        setSelectedContracts(props.contracts);
    }, [props.contracts]);

    //called from Fixed Asset Form
    useImperativeHandle(ref, () => ({
        getContracts() {
            return selectedContracts?.map((contract: Contract) => {
                return { contractId: contract.id };
            });
        },
    }));

    return {
        selectedContracts,
        setSelectedContracts,
        showContractSelectionModal,
        setShowContractSelectionModal,
        contractList,
        isLoadingContracts,
        termSet,
    };
};

export default useContractGrid;
