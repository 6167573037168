import { DISPLAY_FORM_SECTIONS } from '../../../../constants/menus/sdsSections.constants';
import { Button, Grid, Popover } from '@mui/material';
import Typography from '@mui/material/Typography';
import React from 'react';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';

//if there are more than 6 sections, display the mega menu
const MegaMenu = (props: any) => {
    const { handleGoToSection } = props;
    return (
        <>
            {DISPLAY_FORM_SECTIONS.map((row: any, index: number) => (
                <>
                    {row.map((section: any) => (
                        <Grid item xl={3} xs={12} md={6} key={section?.link}>
                            {section.id && (
                                <div className={'section'}>
                                    <Typography
                                        style={{
                                            fontWeight: 500,
                                            fontSize: 16,
                                        }}
                                        color={'secondary'}
                                        onClick={() =>
                                            handleGoToSection(section.link)
                                        }>
                                        {section?.id + '. ' + section.name}
                                    </Typography>
                                    {section?.subSections &&
                                        section?.subSections?.map(
                                            (sub: any, subIndex: number) => (
                                                <Typography
                                                    key={sub?.link}
                                                    variant={'body1'}
                                                    onClick={() =>
                                                        handleGoToSection(
                                                            section.link
                                                        )
                                                    }
                                                    className={
                                                        'sub-section-header'
                                                    }>
                                                    <span
                                                        style={{
                                                            textDecoration:
                                                                'underline',
                                                        }}>
                                                        {`${section?.id}.${
                                                            subIndex + 1
                                                        }`}
                                                    </span>
                                                    {` ${sub.name}`}
                                                </Typography>
                                            )
                                        )}
                                </div>
                            )}
                        </Grid>
                    ))}
                </>
            ))}
            ;
        </>
    );
};

//the button that handles opening the mega menu
const MegaMenuButton = (props: any) => {
    const { handleClose, handleGoToSection, anchorEl, setAnchorEl } = props;

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    return (
        <div>
            <Button
                startIcon={<MenuOpenIcon />}
                aria-describedby={id}
                variant="text"
                color="secondary"
                onClick={handleClick}>
                Form Navigation
            </Button>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                style={{ width: '75%' }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}>
                <Grid
                    container
                    spacing={2}
                    style={{
                        padding: '10px',
                    }}>
                    <MegaMenu handleGoToSection={handleGoToSection} />
                </Grid>
            </Popover>
        </div>
    );
};

export default MegaMenuButton;
