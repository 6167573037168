// @ts-nocheck
import {
    Supplier,
    SupplierAddress,
    SupplierAddressByParams,
    SupplierAddressStatus,
    SupplierAddressType,
    SupplierStatus,
    SupplierType,
} from '../../types/Supplier.types';
import { simpleEndpoints } from '../../utils/endpoints/simpleEndpoints';
import { datacorAppApi } from '../datacorAppApi';
import {
    BaseCreateEntityArgs,
    BaseUpdateEntityArgs,
} from '../serviceInterfaces';

simpleEndpoints<SupplierStatus>(
    'SupplierStatus',
    'suppliers/setup/status',
    'code'
);

simpleEndpoints<SupplierType>(
    'SupplierType',
    'suppliers/setup/type',
    'code',
    'typeActive=true'
);

simpleEndpoints<SupplierAddressStatus>(
    'SupplierAddressStatus',
    'suppliers/setup/addressStatus',
    'code'
);

simpleEndpoints<SupplierAddressType>(
    'SupplierAddressType',
    'suppliers/setup/addressType',
    'code'
);

simpleEndpoints<Supplier>('Supplier', 'suppliers/supplier', 'code');

simpleEndpoints<SupplierAddress>(
    'SupplierAddress',
    'suppliers/supplierAddress'
);

datacorAppApi.enhanceEndpoints({
    addTagTypes: [`Active_Supplier`, 'SupplierAddresses'],
});

datacorAppApi.injectEndpoints({
    endpoints: (build) => ({
        applySupplierTemplate: build.mutation<any, any>({
            query: (args) => ({
                url: `supplier/attributes/apply-template/${args.id}`,
                method: 'POST',
                body: args.postBody,
            }),
            invalidatesTags: ['Active_Supplier'],
        }),
        updateSupplierAttribute: build.mutation<any, any>({
            query: (args) => ({
                url: `supplier/attributes/update/${args.id}`,
                method: 'POST',
                body: args.postBody,
            }),
            invalidatesTags: ['Active_Supplier'],
        }),

        //#region Supplier Address
        createSupplierAddressBy: build.mutation<
            any,
            BaseCreateEntityArgs<SupplierAddress>
        >({
            query: (args) => ({
                url: 'suppliers/supplierAddress',
                method: 'POST',
                body: args.postBody,
            }),
            invalidatesTags: ['SupplierAddresses'],
        }),
        updateSupplierAddressBy: build.mutation<
            any,
            BaseUpdateEntityArgs<SupplierAddress>
        >({
            query: (args) => ({
                url: `suppliers/supplierAddress/${args.id}`,
                method: 'PATCH',
                body: args.postBody,
            }),
            invalidatesTags: ['SupplierAddresses', 'Active_SupplierAddress'],
        }),
        deleteSupplierAddressBy: build.mutation<any, string>({
            query: (id) => ({
                url: `suppliers/supplierAddress/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['SupplierAddresses'],
        }),
        listSupplierAddressBy: build.query<any, SupplierAddressByParams>({
            query: (params: SupplierAddressByParams) => {
                return {
                    url: `suppliers/supplierAddress`,
                    params: params,
                };
            },
            providesTags: ['SupplierAddresses'],
        }),
        //#endregion
    }),
});

export const {
    // SupplierAddress
    useCreateSupplierAddressByMutation,
    useListSupplierAddressByQuery,
    useGetSupplierAddressQuery,
    useUpdateSupplierAddressByMutation,
    useDeleteSupplierAddressByMutation,
    // SupplierType
    useListSupplierTypeQuery,
    useUpdateListSupplierTypeMutation,
    useGetSupplierTypeQuery,
    useCreateSupplierTypeMutation,
    useUpdateSupplierTypeMutation,
    useDeleteSupplierTypeMutation,
    useDropdownSupplierTypeQuery,
    // SupplierStatus
    useCreateSupplierStatusMutation,
    useUpdateSupplierStatusMutation,
    useDeleteSupplierStatusMutation,
    useGetSupplierStatusQuery,
    useListSupplierStatusQuery,
    useUpdateListSupplierStatusMutation,
    useDropdownSupplierStatusQuery,
    // SupplierAddressStatus
    useListSupplierAddressStatusQuery,
    useUpdateListSupplierAddressStatusMutation,
    useGetSupplierAddressStatusQuery,
    useCreateSupplierAddressStatusMutation,
    useUpdateSupplierAddressStatusMutation,
    useDeleteSupplierAddressStatusMutation,
    useDropdownSupplierAddressStatusQuery,
    // SupplierAddressType
    useListSupplierAddressTypeQuery,
    useUpdateListSupplierAddressTypeMutation,
    useGetSupplierAddressTypeQuery,
    useCreateSupplierAddressTypeMutation,
    useUpdateSupplierAddressTypeMutation,
    useDeleteSupplierAddressTypeMutation,
    useDropdownSupplierAddressTypeQuery,
    // Supplier
    useListSupplierQuery,
    useGetSupplierQuery,
    useCreateSupplierMutation,
    useUpdateSupplierMutation,
    useDeleteSupplierMutation,
    useApplySupplierTemplateMutation,
    useUpdateSupplierAttributeMutation,
} = datacorAppApi;
