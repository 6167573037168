import { useForm } from '../../../../libs/hooksLib';
import { isNil } from '../../../../utils/objectUtils';
import { skipToken } from '@reduxjs/toolkit/query';
import { useEffect, useState } from 'react';
import { ReportDisposalProps } from '../DisposalOptionsModal';
import { DisposalsParams } from '../../../../types/FixedAsset.types';
import { useDisposalReportQuery } from '../../../../services/fixedAssets/fixedAssets.service';

const useDiposalOptionsModal = (props: ReportDisposalProps) => {
    const inicialFields: any = {
        disposalBeginDate: null,
        disposalEndDate: null,
    };

    const { fields, handleFieldChange } = useForm(inicialFields);
    const [queryParams, setQueryParams] = useState<DisposalsParams>(null);

    const { data: assetDisposalsList, isFetching: isLoadingDisposals } =
        useDisposalReportQuery(queryParams ? queryParams : skipToken);

    const handleGetAssetDisposals = (event: any) => {
        event.preventDefault();

        const listParams: DisposalsParams = {};

        if (!isNil(fields.disposalBeginDate)) {
            listParams['disposalBeginDate'] = fields.disposalBeginDate;
        }
        if (!isNil(fields.disposalEndDate)) {
            listParams['disposalEndDate'] = fields.disposalEndDate;
        }

        setQueryParams(listParams);
    };

    useEffect(() => {
        if (assetDisposalsList) {
            props.onAssetDisposalsLoaded(assetDisposalsList);
            setQueryParams(null);
            props.onClose();
        }
    }, [assetDisposalsList]);

    return {
        queryParams,
        fields,
        handleFieldChange,
        handleGetAssetDisposals,
        isLoadingDisposals,
    };
};

export default useDiposalOptionsModal;
