import React from 'react';
import { useParams } from 'react-router-dom';
import { Checkbox, FormControlLabel, Grid } from '@mui/material';
import BaseForm from '../../../../components/form/baseForm/BaseForm';
import FormPartial from '../../../../components/form/formContainer/FormPartial';
import TextInput from '../../../../components/form/formInputs/TextInput/TextInput';
import useLocaleGroupForm from './hooks/useLocaleGroupForm';
import { LocaleGroupsFormDefs } from '../../../../constants/i18n/translations/termDefinitions/formulation';
import TranslatableText from '../../../../components/i18n/TranslatableText';

type ParamTypes = { id: string };

const LocaleGroupForm = () => {
    const { id } = useParams<ParamTypes>();

    const {
        isLoading,
        fields,
        formMethods,
        handleFieldChange,
        onCreate,
        onUpdate,
        onDelete,
        closeForm,
        canCreateLocaleGroup,
        canUpdateLocaleGroup,
        canDeleteLocaleGroup,
        termSet,
        hasEntityChanges,
    } = useLocaleGroupForm(id);

    return (
        <BaseForm
            title={
                <TranslatableText
                    termSet={termSet}
                    termKey={LocaleGroupsFormDefs.Locale_Groups}
                />
            }
            formMethods={formMethods}
            isLoading={isLoading}
            isNew={!id}
            onCreate={onCreate}
            onUpdate={onUpdate}
            onDelete={onDelete}
            onCancel={closeForm}
            displayCreateButton={canCreateLocaleGroup}
            displayUpdateButton={canUpdateLocaleGroup}
            displayDeleteButton={canDeleteLocaleGroup}
            readOnly={id && !canUpdateLocaleGroup}
            hasEntityChanges={hasEntityChanges}>
            <FormPartial>
                <Grid container spacing={2}>
                    <Grid item xs={4}>
                        <TextInput
                            fullWidth
                            label={
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={
                                        LocaleGroupsFormDefs.Locale_Group_ID
                                    }
                                />
                            }
                            id="groupId"
                            formMethods={formMethods}
                            value={fields.groupId}
                            onChange={handleFieldChange}
                            required={true}
                            maxLength={50}
                            disabled={!!id}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <FormControlLabel
                            label={
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={LocaleGroupsFormDefs.Active}
                                />
                            }
                            control={
                                <Checkbox
                                    id="active"
                                    checked={fields.active}
                                    onChange={handleFieldChange}
                                />
                            }
                            disabled={true}
                        />
                    </Grid>
                    <Grid item xs={8}>
                        <TextInput
                            fullWidth
                            label={
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={LocaleGroupsFormDefs.Description}
                                />
                            }
                            id="description"
                            formMethods={formMethods}
                            value={fields.description}
                            onChange={handleFieldChange}
                            required={true}
                            maxLength={100}
                        />
                    </Grid>
                </Grid>
            </FormPartial>
        </BaseForm>
    );
};

export default LocaleGroupForm;
