import moment, { Moment } from 'moment';
import { isNil } from './objectUtils';

export const numberAsStringComparator = (value1: string, value2: string) => {
    return Number(value1) > Number(value2) ? 1 : -1;
};

/**
 * This date comparator was currently working only in fixed assets
 * it was broken in formulation and admin module
 * I switched all grids to use the new dateValueComparator
 * all is working in formulation/admin/fixed assets
 * **/
export const dateComparator = (filterDate: Date, cellValue: string) => {
    const tryMoment: Moment = moment(cellValue);

    if (!tryMoment.isValid()) {
        return -1;
    }

    const momentAsDate = tryMoment.toDate();

    if (filterDate.getTime() === momentAsDate.getTime()) {
        return 0;
    } else if (momentAsDate < filterDate) {
        return -1;
    } else {
        return 1;
    }
};

/**
 * New comparator that modules are using
 * is working normally when the other comparator fails
 * **/
export const dateValueComparator = (
    filterLocalDateAtMidnight: any,
    cellValue: any
) => {
    return {
        comparator: (filterLocalDateAtMidnight: any, cellValue: any) => {
            const dateAsString = cellValue;

            if (dateAsString == null) {
                return 0;
            }

            const dateParts = dateAsString.split('-');

            const year = Number(dateParts[0]);
            const month = Number(dateParts[1]) - 1;
            const day = Number(dateParts[2].slice(0, 2));
            const cellDate = new Date(year, month, day);

            // Now that both parameters are Date objects, we can compare
            if (cellDate < filterLocalDateAtMidnight) {
                return -1;
            } else if (cellDate > filterLocalDateAtMidnight) {
                return 1;
            }
            return 0;
        },
    };
};

/**
 *
 * @param startDate
 * @param endDate
 * @param required - optional
 * Validates a range of dates, a null date can be valid if it is not defined as required.
 */

export const validateDateRange = (
    startDate: Date,
    endDate: Date,
    required: boolean = false
) => {
    const startDateValid = required
        ? !isNil(startDate) && moment(startDate).isValid()
        : isNil(startDate) || moment(startDate).isValid();
    const endDateValid = required
        ? !isNil(endDate) && moment(endDate).isValid()
        : isNil(endDate) || moment(endDate).isValid();
    const validDates =
        !isNil(startDate) && !isNil(endDate)
            ? moment(startDate).isSameOrBefore(endDate)
            : true;

    return startDateValid && endDateValid && validDates;
};
