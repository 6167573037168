import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ColDef, ICellRendererParams } from 'ag-grid-community';
import { GL_AccountFunction } from '../../../types/Accounting.types';
import { useListFunctionCodeByGroupQuery } from '../../../services/accounting/accounting.service';
import { isNilOrEmpty } from '../../../utils/objectUtils';
import { PermissionsUtil } from '../../../utils/permissions/permissionsUtil';
import { PERMISSIONS } from '../../../constants/permissions/Permissions.constants';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { FunctionGroupValues } from '../../../types/Shared.types';
import { useGetTermSetQuery } from '../../../services/i18n/i18n.service';
import { ACCOUNTING_DEFS } from '../../../constants/i18n/translations/termSetDefinitions/accounting';
import { skipToken } from '@reduxjs/toolkit/query';
import SettingsContext from '../../../contexts/settings.context';
import DefaultColumnTypes from '../../../components/grids/columns/Column.constants';
import CustomHeader from '../../../components/grids/CustomHeader';
import { FunctionCodesGridDefs } from '../../../constants/i18n/translations/termDefinitions/accounting';

const useAccountFunctionCodeGrid = (group: string) => {
    const user = useSelector((state: RootState) => state.user);
    const navigate = useNavigate();
    const { data: entityList, isLoading: isLoadingData } =
        useListFunctionCodeByGroupQuery(group);
    const [colDefs, setColDefs] = useState([]);
    const [rowData, setRowData] = useState([]);
    const [isLoadingGrid, setIsLoadingGrid] = useState(true);

    const { settings } = useContext(SettingsContext);
    const { data: termSet, isLoading: isLoadingTermSet } = useGetTermSetQuery(
        settings?.userSettings
            ? {
                  languageId: settings?.userSettings?.languageId,
                  code: ACCOUNTING_DEFS.FUNCTION_CODES_GRID,
              }
            : skipToken
    );

    const canView = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        group === FunctionGroupValues.accounting
            ? PERMISSIONS.ACCOUNTING.FUNCTION_CODES.VIEW
            : PERMISSIONS.FIXED_ASSETS.FUNCTION_CODES.VIEW
    );

    const redirectToForm = (id: bigint) => {
        navigate(
            group === FunctionGroupValues.accounting
                ? `/accounting/functionCode/${group}/${id}`
                : `/accounting/fixedAssets/functionCode/${group}/${id}`
        );
    };

    const getAccountNumberValue = (accountFunctions: GL_AccountFunction[]) => {
        return isNilOrEmpty(accountFunctions)
            ? ''
            : accountFunctions.map(
                  (aFunction: GL_AccountFunction) => aFunction.account?.number
              );
    };

    const AccountNumberColumn = (params: ICellRendererParams) => {
        return (
            <div>
                {params.data.functions.map((aFunction: GL_AccountFunction) => {
                    return (
                        <div key={aFunction.account?.number}>
                            {aFunction.account?.number}
                        </div>
                    );
                })}
            </div>
        );
    };

    const getAccountNameValue = (accountFunctions: GL_AccountFunction[]) => {
        return isNilOrEmpty(accountFunctions)
            ? ''
            : accountFunctions.map(
                  (aFunction: GL_AccountFunction) => aFunction.account?.name
              );
    };

    const AccountNameColumn = (params: ICellRendererParams) => {
        return (
            <div>
                {params.data.functions.map((aFunction: GL_AccountFunction) => {
                    return (
                        <div key={aFunction.account?.number}>
                            {aFunction.account?.name}
                        </div>
                    );
                })}
            </div>
        );
    };

    useEffect(() => {
        if (!isNilOrEmpty(termSet)) {
            const columnDefs: ColDef[] = [
                {
                    field: 'code',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={FunctionCodesGridDefs.Function_Code}
                            />
                        );
                    },
                    ...DefaultColumnTypes.MediumText,
                },
                {
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={FunctionCodesGridDefs.Number}
                            />
                        );
                    },
                    ...DefaultColumnTypes.MediumText,
                    ...DefaultColumnTypes.AutoHeight,
                    cellRenderer: 'accountNumberColumn',
                    valueGetter: (params) => {
                        return getAccountNumberValue(params.data.functions);
                    },
                },
                {
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={FunctionCodesGridDefs.Name}
                            />
                        );
                    },
                    ...DefaultColumnTypes.LongText,
                    cellRenderer: 'accountNameColumn',
                    valueGetter: (params) => {
                        return getAccountNameValue(params.data.functions);
                    },
                },
            ];
            setColDefs(columnDefs);
        }
    }, [termSet]);

    useEffect(() => {
        if (!isLoadingData && !isLoadingTermSet) {
            setIsLoadingGrid(false);
        }
    }, [entityList, termSet]);

    useEffect(() => {
        if (!isNilOrEmpty(entityList)) {
            setRowData(entityList);
        }
    }, [entityList]);

    return {
        rowData,
        colDefs,
        isLoadingGrid,
        canView,
        redirectToForm,
        AccountNumberColumn,
        AccountNameColumn,
    };
};

export default useAccountFunctionCodeGrid;
