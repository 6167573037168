import React from 'react';
import UseFixedAssetDepreciationMethodTypeGrid from './hooks/useFixedAssetDepreciationMethodTypeGrid';
import BaseFormGrid from '../../components/grids/BaseFormGrid';
import { cloneDeep } from 'lodash';
import { DepreciationMethodTypesGridDefs } from '../../constants/i18n/translations/termDefinitions/accounting';
import TranslatableText from '../../components/i18n/TranslatableText';
import { isNilOrEmpty } from '../../utils/objectUtils';

const FixedAssetDepreciationMethodTypeGrid = () => {
    const {
        typeList,
        redirectToForm,
        isLoadingTypes,
        canCreateDepreciationMethodType,
        canViewDepreciationMethodType,
        canUpdate,
        handleGridEdits,
        rowData,
        termSet,
        colDefs,
    } = UseFixedAssetDepreciationMethodTypeGrid();

    return (
        <BaseFormGrid
            title={
                <TranslatableText
                    termSet={termSet}
                    termKey={
                        DepreciationMethodTypesGridDefs.Depreciation_Method_Types
                    }
                />
            }
            displayGrid={canViewDepreciationMethodType}
            isLoading={isNilOrEmpty(colDefs)}
            rowData={cloneDeep(rowData)}
            useRowDrag={canUpdate}
            sortKey={'sortOrder'}
            columnDefs={colDefs}
            gridClass="full-size-item"
            displayToolbar={true}
            displayCreateNewButton={canCreateDepreciationMethodType}
            displayExportDataButton={true}
            displayResetStateButton={true}
            onCreateNew={redirectToForm}
            onRowClicked={({ data }) => redirectToForm(data)}
            onHandleGridEdits={handleGridEdits}
        />
    );
};

export default FixedAssetDepreciationMethodTypeGrid;
