import React from 'react';
import { useParams } from 'react-router-dom';
import BaseForm, {
    ParamTypes,
} from '../../../../components/form/baseForm/BaseForm';
import { RoutingValues } from '../../../../types/Shared.types';
import { PERIOD_QUARTER_FIELDS } from '../../../../constants/revisionHistory/revisionHistory.constants';
import { Grid } from '@mui/material';
import FormPartial from '../../../../components/form/formContainer/FormPartial';
import TextInput from '../../../../components/form/formInputs/TextInput/TextInput';
import NumericInput from '../../../../components/form/formInputs/NumericInput/NumericInput';
import usePeriodQuarterForm from './hook/usePeriodQuarterForm';
import { PeriodQuarterFormDefs } from '../../../../constants/i18n/translations/termDefinitions/accounting';
import TranslatableText from '../../../../components/i18n/TranslatableText';

const PeriodQuarterForm = () => {
    const { id } = useParams<ParamTypes>();

    const {
        fields,
        handleFieldChange,
        formMethods,
        auditableFields,
        onCreate,
        onUpdate,
        onDelete,
        closeForm,
        termSet,
        isLoading,
        permissions,
    } = usePeriodQuarterForm(id);

    return (
        <BaseForm
            title={
                <TranslatableText
                    termSet={termSet}
                    termKey={PeriodQuarterFormDefs.Title}
                />
            }
            isNew={id === RoutingValues.newId}
            isLoading={isLoading}
            formMethods={formMethods}
            onCreate={onCreate}
            onUpdate={onUpdate}
            onDelete={onDelete}
            onCancel={closeForm}
            displayCreateButton={permissions.displayCreateNewButton}
            displayUpdateButton={permissions.displayUpdateButton}
            displayDeleteButton={permissions.displayDeleteButton}
            entityUuid={fields.uuid}
            showOnRevision={true}
            fieldMappings={PERIOD_QUARTER_FIELDS}
            auditableFields={auditableFields}>
            <FormPartial>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <TextInput
                            fullWidth
                            id="code"
                            label={
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={
                                        PeriodQuarterFormDefs.Period_Quarter_Code
                                    }
                                />
                            }
                            value={fields.code}
                            onChange={handleFieldChange}
                            formMethods={formMethods}
                            required={true}
                            maxLength={30}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextInput
                            fullWidth
                            id="description"
                            label={
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={PeriodQuarterFormDefs.Description}
                                />
                            }
                            value={fields.description}
                            onChange={handleFieldChange}
                            formMethods={formMethods}
                            maxLength={255}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <NumericInput
                            fullWidth
                            id="sortOrder"
                            label={
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={PeriodQuarterFormDefs.Sort_Order}
                                />
                            }
                            value={fields.sortOrder}
                            onChange={handleFieldChange}
                            formMethods={formMethods}
                            required={true}
                            disabled={true}
                        />
                    </Grid>
                </Grid>
            </FormPartial>
        </BaseForm>
    );
};

export default PeriodQuarterForm;
