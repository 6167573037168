import React from 'react';
import useFixedAssetSchedulesGrid, {
    AssetScheduleGridMode,
} from './hooks/useFixedAssetSchedulesGrid';
import DefaultColumnTypes from '../../components/grids/columns/Column.constants';
import { GetDetailRowDataParams } from 'ag-grid-community';
import { Card, CardContent, CardHeader } from '@mui/material';
import FixedAssetSchedulesOptionsModal from './fixedAssetSchedules/FixedAssetSchedulesOptionsModal';
import FixedAssetSchedulesActionsModal from './fixedAssetSchedules/FixedAssetSchedulesActionsModal';
import { TabContext, TabPanel } from '@mui/lab';
import BaseGrid from '../../components/grids/BaseGrid';
import DatacorLogoSpinner from '../../components/datacorLogoSpinner/DatacorLogoSpinner';
import { AgGridColumn } from 'ag-grid-react';
import { FixedAssetScheduleGridDefs } from '../../constants/i18n/translations/termDefinitions/accounting';
import TranslatableText from '../../components/i18n/TranslatableText';
import CustomHeader from '../../components/grids/CustomHeader';

const FixedAssetSchedulesGrid = () => {
    const {
        scheduleList,
        selectedSchedules,
        onGridReady,
        onSubGridReady,
        onSelectionChanged,
        onSubGridSelectionChanged,
        lockedScheduleValueGetter,
        scheduleToolbar,
        showOptionsModal,
        setShowOptionsModal,
        setDepreciationScheduleList,
        showActionsModal,
        setShowActionsModal,
        handleUpdatedScheduleList,
        dateColumnRenderer,
        assetTypeRenderer,
        currentGridMode,
        currentTab,
        currentTabTitle,
        subGridToolbar,
        subGridList,
        isLoadingSubGrid,
        canViewSchedules,
        termSet,
    } = useFixedAssetSchedulesGrid();

    const getDetailRowId = (data: any) => {
        return data.id;
    };

    const detailCellRendererParams = {
        detailGridOptions: {
            immutableData: true,
            getRowNodeId: getDetailRowId,
            defaultColDef: {
                resizable: true,
            },
            //Depreciation Method Type Dropdown
            columnDefs: [
                {
                    field: 'fixedAsset.code',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={
                                    FixedAssetScheduleGridDefs.DepreciationMethodTypeAssetCode
                                }
                            />
                        );
                    },
                    ...DefaultColumnTypes.ShortText,
                },
                {
                    field: 'interval',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={
                                    FixedAssetScheduleGridDefs.DepreciationMethodTypeRecoveryInterval
                                }
                            />
                        );
                    },
                    ...DefaultColumnTypes.NumberOnly,
                },
                {
                    field: 'assetCost',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={
                                    FixedAssetScheduleGridDefs.DepreciationMethodTypeAssetCost
                                }
                            />
                        );
                    },
                    ...DefaultColumnTypes.Currency,
                },
                {
                    field: 'accumulatedDepreciation',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={
                                    FixedAssetScheduleGridDefs.DepreciationMethodTypeAccumulatedDepreciation
                                }
                            />
                        );
                    },
                    ...DefaultColumnTypes.Currency,
                },
                {
                    field: 'remainingValue',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={
                                    FixedAssetScheduleGridDefs.DepreciationMethodTypeRemainingValue
                                }
                            />
                        );
                    },
                    ...DefaultColumnTypes.Currency,
                },
                {
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={
                                    FixedAssetScheduleGridDefs.DepreciationMethodTypeLocked
                                }
                            />
                        );
                    },
                    valueGetter: lockedScheduleValueGetter,
                    ...DefaultColumnTypes.LockedInformationColumn,
                },
            ],
        },
        getDetailRowData: (params: GetDetailRowDataParams) => {
            params.successCallback(params.data.schedules);
        },
    };

    const subGridDetailCellRendererParams = {
        detailGridOptions: {
            immutableData: true,
            getRowNodeId: getDetailRowId,
            defaultColDef: {
                resizable: true,
            },
            rowSelection: 'multiple',
            rowMultiSelectWithClick: true,
            onSelectionChanged: onSubGridSelectionChanged,
            //MultiGrid Dropdown
            columnDefs: [
                {
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={
                                    FixedAssetScheduleGridDefs.MultiGridSelected
                                }
                            />
                        );
                    },
                    ...DefaultColumnTypes.CheckboxSelection,
                },
                {
                    field: 'fixedAsset.code',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={
                                    FixedAssetScheduleGridDefs.MultiGridAssetCode
                                }
                            />
                        );
                    },
                    ...DefaultColumnTypes.ShortText,
                },
                {
                    field: 'interval',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={
                                    FixedAssetScheduleGridDefs.MultiGridRecoveryInterval
                                }
                            />
                        );
                    },
                    ...DefaultColumnTypes.NumberOnly,
                },
                {
                    field: 'assetCost',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={
                                    FixedAssetScheduleGridDefs.MultiGridCost
                                }
                            />
                        );
                    },
                    ...DefaultColumnTypes.Currency,
                },
                {
                    field: 'accumulatedDepreciation',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={
                                    FixedAssetScheduleGridDefs.MultiGridAccumulatedDepreciation
                                }
                            />
                        );
                    },
                    ...DefaultColumnTypes.Currency,
                },
                {
                    field: 'remainingValue',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={
                                    FixedAssetScheduleGridDefs.MultiGridRemainingValue
                                }
                            />
                        );
                    },
                    ...DefaultColumnTypes.Currency,
                },
                {
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={
                                    FixedAssetScheduleGridDefs.MultiGridLocked
                                }
                            />
                        );
                    },
                    valueGetter: lockedScheduleValueGetter,
                    ...DefaultColumnTypes.LockedInformationColumn,
                },
            ],
        },
        getDetailRowData: (params: GetDetailRowDataParams) => {
            params.successCallback(params.data.schedules);
        },
    };

    return (
        <>
            {canViewSchedules ? (
                <Card>
                    <CardHeader title={currentTabTitle} />
                    <CardContent className={'full-size-item'}>
                        <FixedAssetSchedulesOptionsModal
                            open={showOptionsModal}
                            onSchedulesLoaded={(schedules) =>
                                setDepreciationScheduleList(schedules)
                            }
                            onClose={() => setShowOptionsModal(false)}
                        />
                        <FixedAssetSchedulesActionsModal
                            open={showActionsModal}
                            onClose={() => setShowActionsModal(false)}
                            selectedSchedules={selectedSchedules}
                            onSchedulesUpdated={handleUpdatedScheduleList}
                        />
                        <TabContext value={currentTab}>
                            <TabPanel value="0">
                                <BaseGrid
                                    paginationPageSize={30}
                                    dataKey="id"
                                    immutableData={true}
                                    rowHeight={30}
                                    rowData={scheduleList}
                                    displayGrid={true}
                                    rowSelection="multiple"
                                    suppressRowClickSelection={true}
                                    defaultColDef={{
                                        resizable: true,
                                        sortable: true,
                                        unSortIcon: true,
                                        filter: true,
                                        wrapText: true,
                                        floatingFilter: true,
                                    }}
                                    onGridReady={onGridReady}
                                    onSelectionChanged={onSelectionChanged}
                                    masterDetail={true}
                                    detailCellRendererParams={
                                        detailCellRendererParams
                                    }
                                    detailRowAutoHeight={true}
                                    frameworkComponents={{
                                        DateColumnRenderer: dateColumnRenderer,
                                        AssetTypeRenderer: assetTypeRenderer,
                                    }}
                                    displayToolbar={true}
                                    toolbarChildren={scheduleToolbar()}>
                                    <AgGridColumn
                                        headerComponentFramework={(
                                            props: any
                                        ) => {
                                            return (
                                                <CustomHeader
                                                    {...props}
                                                    termSet={termSet}
                                                    termKey={
                                                        FixedAssetScheduleGridDefs.Selected
                                                    }
                                                />
                                            );
                                        }}
                                        headerCheckboxSelection={true}
                                        {...DefaultColumnTypes.CheckboxSelection}
                                    />
                                    <AgGridColumn
                                        field="assetType.depreciationMethod.depreciationMethodType.code"
                                        headerComponentFramework={(
                                            props: any
                                        ) => {
                                            return (
                                                <CustomHeader
                                                    {...props}
                                                    termSet={termSet}
                                                    termKey={
                                                        FixedAssetScheduleGridDefs.DepreciationMethodType
                                                    }
                                                />
                                            );
                                        }}
                                        cellRenderer="agGroupCellRenderer"
                                        {...DefaultColumnTypes.ShortText}
                                    />
                                    <AgGridColumn
                                        field="assetType.code"
                                        headerComponentFramework={(
                                            props: any
                                        ) => {
                                            return (
                                                <CustomHeader
                                                    {...props}
                                                    termSet={termSet}
                                                    termKey={
                                                        FixedAssetScheduleGridDefs.FixedAssetType
                                                    }
                                                />
                                            );
                                        }}
                                        cellRenderer="AssetTypeRenderer"
                                        {...DefaultColumnTypes.ShortText}
                                    />
                                    <AgGridColumn
                                        field="scheduleDate"
                                        headerComponentFramework={(
                                            props: any
                                        ) => {
                                            return (
                                                <CustomHeader
                                                    {...props}
                                                    termSet={termSet}
                                                    termKey={
                                                        FixedAssetScheduleGridDefs.Date
                                                    }
                                                />
                                            );
                                        }}
                                        cellRenderer="DateColumnRenderer"
                                        {...DefaultColumnTypes.DateOnly}
                                    />
                                    <AgGridColumn
                                        width={110}
                                        minWidth={110}
                                        maxWidth={110}
                                        field="lockedScheduleSummary"
                                        headerComponentFramework={(
                                            props: any
                                        ) => {
                                            return (
                                                <CustomHeader
                                                    {...props}
                                                    termSet={termSet}
                                                    termKey={
                                                        FixedAssetScheduleGridDefs.Locked
                                                    }
                                                />
                                            );
                                        }}
                                        {...DefaultColumnTypes.StackedColumn}
                                    />
                                    <AgGridColumn
                                        field="assetValue"
                                        headerComponentFramework={(
                                            props: any
                                        ) => {
                                            return (
                                                <CustomHeader
                                                    {...props}
                                                    termSet={termSet}
                                                    termKey={
                                                        FixedAssetScheduleGridDefs.AssetCost
                                                    }
                                                />
                                            );
                                        }}
                                        {...DefaultColumnTypes.Currency}
                                    />
                                    <AgGridColumn
                                        field="accumulatedDepreciation"
                                        headerComponentFramework={(
                                            props: any
                                        ) => {
                                            return (
                                                <CustomHeader
                                                    {...props}
                                                    termSet={termSet}
                                                    termKey={
                                                        FixedAssetScheduleGridDefs.AccumulatedDepreciation
                                                    }
                                                />
                                            );
                                        }}
                                        {...DefaultColumnTypes.Currency}
                                    />
                                    <AgGridColumn
                                        field="remainingValue"
                                        headerComponentFramework={(
                                            props: any
                                        ) => {
                                            return (
                                                <CustomHeader
                                                    {...props}
                                                    termSet={termSet}
                                                    termKey={
                                                        FixedAssetScheduleGridDefs.RemainingValue
                                                    }
                                                />
                                            );
                                        }}
                                        {...DefaultColumnTypes.Currency}
                                    />
                                </BaseGrid>
                            </TabPanel>
                            <TabPanel value="1">
                                {isLoadingSubGrid ? (
                                    <DatacorLogoSpinner />
                                ) : (
                                    <BaseGrid
                                        paginationPageSize={30}
                                        dataKey="id"
                                        immutableData={true}
                                        rowHeight={30}
                                        rowData={subGridList}
                                        displayGrid={true}
                                        defaultColDef={{
                                            resizable: true,
                                            sortable: true,
                                            unSortIcon: true,
                                            filter: true,
                                            wrapText: true,
                                            floatingFilter: true,
                                        }}
                                        onGridReady={onSubGridReady}
                                        masterDetail={true}
                                        detailCellRendererParams={
                                            subGridDetailCellRendererParams
                                        }
                                        detailRowAutoHeight={true}
                                        displayToolbar={true}
                                        toolbarChildren={subGridToolbar()}>
                                        <AgGridColumn
                                            //Date Grid
                                            field="assetType.code"
                                            headerComponentFramework={(
                                                props: any
                                            ) => {
                                                return (
                                                    <CustomHeader
                                                        {...props}
                                                        termSet={termSet}
                                                        termKey={
                                                            FixedAssetScheduleGridDefs.DateFixedAssetType
                                                        }
                                                    />
                                                );
                                            }}
                                            cellRenderer="agGroupCellRenderer"
                                            hide={
                                                currentGridMode ===
                                                AssetScheduleGridMode.ByAssetType
                                            }
                                            {...DefaultColumnTypes.ShortText}
                                        />

                                        <AgGridColumn
                                            //Fixed Asset Type Grid
                                            field="scheduleDate"
                                            headerComponentFramework={(
                                                props: any
                                            ) => {
                                                return (
                                                    <CustomHeader
                                                        {...props}
                                                        termSet={termSet}
                                                        termKey={
                                                            FixedAssetScheduleGridDefs.FixedAssetTypeDate
                                                        }
                                                    />
                                                );
                                            }}
                                            cellRenderer="agGroupCellRenderer"
                                            hide={
                                                currentGridMode ===
                                                AssetScheduleGridMode.ByDate
                                            }
                                            {...DefaultColumnTypes.DateOnly}
                                        />
                                        <AgGridColumn
                                            //From here on out, date and fixed asset type grids are shared components
                                            width={110}
                                            minWidth={110}
                                            maxWidth={110}
                                            field="lockedScheduleSummary"
                                            headerComponentFramework={(
                                                props: any
                                            ) => {
                                                return (
                                                    <CustomHeader
                                                        {...props}
                                                        termSet={termSet}
                                                        termKey={
                                                            FixedAssetScheduleGridDefs.DateLocked
                                                        }
                                                    />
                                                );
                                            }}
                                            {...DefaultColumnTypes.StackedColumn}
                                        />
                                        <AgGridColumn
                                            field="assetValue"
                                            headerComponentFramework={(
                                                props: any
                                            ) => {
                                                return (
                                                    <CustomHeader
                                                        {...props}
                                                        termSet={termSet}
                                                        termKey={
                                                            FixedAssetScheduleGridDefs.DateAssetCost
                                                        }
                                                    />
                                                );
                                            }}
                                            {...DefaultColumnTypes.Currency}
                                        />
                                        <AgGridColumn
                                            field="accumulatedDepreciation"
                                            headerComponentFramework={(
                                                props: any
                                            ) => {
                                                return (
                                                    <CustomHeader
                                                        {...props}
                                                        termSet={termSet}
                                                        termKey={
                                                            FixedAssetScheduleGridDefs.DateAccumulatedDepreciation
                                                        }
                                                    />
                                                );
                                            }}
                                            {...DefaultColumnTypes.Currency}
                                        />
                                        <AgGridColumn
                                            field="remainingValue"
                                            headerComponentFramework={(
                                                props: any
                                            ) => {
                                                return (
                                                    <CustomHeader
                                                        {...props}
                                                        termSet={termSet}
                                                        termKey={
                                                            FixedAssetScheduleGridDefs.FixedAssetTypeRemainingValue
                                                        }
                                                    />
                                                );
                                            }}
                                            {...DefaultColumnTypes.Currency}
                                        />
                                    </BaseGrid>
                                )}
                            </TabPanel>
                        </TabContext>
                    </CardContent>
                </Card>
            ) : null}
        </>
    );
};

export default FixedAssetSchedulesGrid;
