import React from 'react';
import Button from '@mui/material/Button';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import RestorePageIcon from '@mui/icons-material/RestorePage';
import SettingsIcon from '@mui/icons-material/Settings';
import CallToActionOutlinedIcon from '@mui/icons-material/CallToActionOutlined';
import SortIcon from '@mui/icons-material/Sort';
import TranslatableText from '../i18n/TranslatableText';
import { SharedComponentsDefs } from '../../constants/i18n/translations/termDefinitions/platform';

interface BaseGridToolbarProps {
    displayProps: any;
    helperMethods: any;
    editableGrid: any;
    popoutGrid: any;
    entitySettings: any;
    childrenProps: any;
    useRowDrag: any;
    sortGrid: any;
    rowData: any;
    helpers: any;
    termSet?: any;
    getAllRows?: any;
}
const BaseGridToolbar = (props: BaseGridToolbarProps) => {
    const {
        displayCreateNewButton,
        displayExportDataButton,
        displayImportDataButton,
        displayEnableDisableButton,
        displayResetStateButton,
        displayEntitySettings,
        displayDrawer,
        displaySortButton,
    } = props.displayProps;

    const {
        onCreateNew,
        exportDataAsExcel,
        onImportData,
        resetState,
        onEnableDisable,
        termSet,
    } = props.helperMethods;

    const { useRowDrag } = props;

    const { setOpenSortGrid, openSortGrid } = props.sortGrid;

    const { isEditable, createNewEditableRow } = props.editableGrid;

    const { gridButtonTitle, setOpenGridDrawer, title } = props.popoutGrid;

    const { setOpenSettings } = props.entitySettings;

    const { toolbarChildren } = props.childrenProps;

    const onClick = (event: any) => {
        setOpenSettings(true);
    };

    /**
     * when determining if we should show inline or modal sort
     * we need to use the non-deleted rows
     * **/
    const removeDeleted = props
        .getAllRows()
        ?.filter((row: any) => row.rowStatus !== 'deleted');

    const removeDeletedProps = props.rowData?.filter(
        (row: any) => row.rowStatus !== 'deleted'
    );

    return (
        <div className="action-bar">
            {displayCreateNewButton && !isEditable && (
                <Button
                    variant="text"
                    size="small"
                    onClick={onCreateNew}
                    data-testid="create-new-btn"
                    startIcon={<AddCircleIcon />}>
                    <TranslatableText
                        termSet={termSet}
                        termKey={SharedComponentsDefs.Add_New}
                    />
                </Button>
            )}
            {isEditable && (
                <>
                    {displayCreateNewButton && (
                        <Button
                            variant="text"
                            size="small"
                            onClick={
                                onCreateNew ? onCreateNew : createNewEditableRow
                            }
                            data-testid="create-new-btn"
                            startIcon={<AddCircleIcon />}>
                            {'Add ' + (gridButtonTitle || 'New')}
                        </Button>
                    )}
                </>
            )}
            {displayExportDataButton && (
                <Button
                    variant="text"
                    size="small"
                    onClick={exportDataAsExcel}
                    data-testid="bulk-download-btn"
                    startIcon={<FileDownloadIcon />}>
                    <TranslatableText
                        termSet={termSet}
                        termKey={SharedComponentsDefs.Bulk_Download}
                    />
                </Button>
            )}
            {displayImportDataButton && (
                <Button
                    variant="text"
                    size="small"
                    onClick={onImportData}
                    data-testid="bulk-upload-btn"
                    startIcon={<FileUploadIcon />}>
                    <TranslatableText
                        termSet={termSet}
                        termKey={SharedComponentsDefs.Bulk_Upload}
                    />
                </Button>
            )}
            {displayResetStateButton && (
                <Button
                    variant="text"
                    size="small"
                    onClick={resetState}
                    data-testid="reset-state-btn"
                    startIcon={<RestorePageIcon />}>
                    <TranslatableText
                        termSet={termSet}
                        termKey={SharedComponentsDefs.Reset_View}
                    />
                </Button>
            )}
            {displayEnableDisableButton && (
                <Button
                    variant="text"
                    size="small"
                    onClick={onEnableDisable}
                    data-testid="enable-disable-btn">
                    <TranslatableText
                        termSet={termSet}
                        termKey={SharedComponentsDefs.Enable_Disable_Selected}
                    />
                </Button>
            )}
            {displayEntitySettings && (
                <Button
                    variant="text"
                    size="small"
                    className="grid-create-new"
                    onClick={onClick}
                    startIcon={<SettingsIcon />}>
                    <TranslatableText
                        termSet={termSet}
                        termKey={SharedComponentsDefs.Settings}
                    />
                </Button>
            )}
            {displayDrawer && (
                <Button
                    variant="text"
                    size="small"
                    className="grid-create-new"
                    onClick={() => setOpenGridDrawer(true)}
                    startIcon={<CallToActionOutlinedIcon />}>
                    <TranslatableText
                        termSet={termSet}
                        termKey={SharedComponentsDefs.Dock_Title}
                    />
                </Button>
            )}

            {displaySortButton &&
                (props.helpers.paginationPageSize < removeDeleted.length ||
                    props.helpers.paginationPageSize <
                        removeDeletedProps.length) && (
                    <Button
                        variant="text"
                        size="small"
                        className="grid-create-new"
                        onClick={() => setOpenSortGrid(true)}
                        startIcon={<SortIcon />}>
                        <TranslatableText
                            termSet={termSet}
                            termKey={SharedComponentsDefs.Sort}
                        />
                    </Button>
                )}
            {toolbarChildren}
        </div>
    );
};

export default BaseGridToolbar;
