import { Button } from '@mui/material';
import useAcquisitionGrid from './hooks/useAcquisitionsGrid';
import React from 'react';
import ListIcon from '@mui/icons-material/List';
import AcquisitionOptionsModal from './AcquisitionOptionsModal';
import BaseFormGrid from '../../../components/grids/BaseFormGrid';
import { AcquisitionsGridDefs } from '../../../constants/i18n/translations/termDefinitions/accounting';
import TranslatableText from '../../../components/i18n/TranslatableText';

const AcquisitionsGrid = () => {
    const {
        acquisitionRows,
        setAssetList,
        columnDefs,
        setShowOptionsModal,
        showOptionsModal,
        loadingCostCodeList,
        acquisitionsSummaryRow,
        canViewAcquisitionReport,
        termSet,
    } = useAcquisitionGrid();

    return (
        <React.Fragment>
            <BaseFormGrid
                title={
                    <TranslatableText
                        termSet={termSet}
                        termKey={AcquisitionsGridDefs.Acquisition_Report}
                    />
                }
                displayGrid={canViewAcquisitionReport}
                isLoading={loadingCostCodeList}
                rowData={acquisitionRows}
                columnDefs={columnDefs}
                gridClass="full-size-item"
                displaySummaryRow={true}
                pinnedBottomRowData={acquisitionsSummaryRow}
                displayToolbar={true}
                displayExportDataButton={true}
                toolbarChildren={
                    <Button
                        variant="text"
                        size="small"
                        onClick={() => setShowOptionsModal(true)}
                        startIcon={<ListIcon />}>
                        Options
                    </Button>
                }></BaseFormGrid>
            <AcquisitionOptionsModal
                termSet={termSet}
                open={showOptionsModal}
                onAcquisitionsLoaded={(assets) => setAssetList(assets)}
                onClose={() => setShowOptionsModal(false)}
            />
        </React.Fragment>
    );
};
export default AcquisitionsGrid;
