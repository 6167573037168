import { INavForm } from '../../types/Shared.types';
import { AccountingNavValues } from '../accounting/Accounting.constants';
import { SupplierNavValues } from '../supplyChain/Supplier.constants';
import { FormulationNavValues } from '../formulation/Formulation.constants';

export const NavValues: INavForm = {
    sharedNav: {
        isCloseFormValid: true,
    },
    ...AccountingNavValues,
    ...SupplierNavValues,
    ...FormulationNavValues,
};
