import React, { useContext } from 'react';
import { ColDef } from 'ag-grid-community';
import DefaultColumnTypes from '../../../../../components/grids/columns/Column.constants';
import { GridOptions } from '../../../../../components/grids/Grid.constants';
import MultiSelectGridModal from '../../../../../components/modals/MultiSelectGridModal';
import { GhsHazardPhrase } from '../../../../../types/formulation';
import CustomHeader from '../../../../../components/grids/CustomHeader';
import { CasMasterFormDefs } from '../../../../../constants/i18n/translations/termDefinitions/formulation';
import TranslatableText from '../../../../../components/i18n/TranslatableText';
import SettingsContext from '../../../../../contexts/settings.context';
import { useGetTermSetQuery } from '../../../../../services/i18n/i18n.service';
import { FORMULATION_DEFS } from '../../../../../constants/i18n/translations/termSetDefinitions/formulation';
import { skipToken } from '@reduxjs/toolkit/query';

interface GhsHazardPhrasesSelectionModalProps {
    openModal: boolean;
    onClose: () => void;
    onSelected: (selectedRows: GhsHazardPhrase[]) => void;
    hazardPhraseList: GhsHazardPhrase[];
}

const GhsHazardPhrasesSelectionModal = (
    props: GhsHazardPhrasesSelectionModalProps
) => {
    const { settings } = useContext(SettingsContext);
    const { data: termSet } = useGetTermSetQuery(
        settings?.userSettings
            ? {
                  languageId: settings?.userSettings?.languageId,
                  code: FORMULATION_DEFS.CAS_MASTER_FORM,
              }
            : skipToken
    );

    const columnDefs: ColDef[] = [
        {
            field: 'phraseId',
            headerComponentFramework: (props: any) => {
                return (
                    <CustomHeader
                        {...props}
                        termSet={termSet}
                        termKey={
                            CasMasterFormDefs.Ghs_Hazard_Phrases_Phrases_Selection_Phrase_ID
                        }
                    />
                );
            },
            filter: 'agTextColumnFilter',
            ...DefaultColumnTypes.ShortText,
            ...GridOptions.sortFilterAndWrapColumns,
            floatingFilter: true,
            maxWidth: 250,
        },
        {
            field: 'phraseText',
            headerComponentFramework: (props: any) => {
                return (
                    <CustomHeader
                        {...props}
                        termSet={termSet}
                        termKey={
                            CasMasterFormDefs.Ghs_Hazard_Phrases_Phrases_Selection_text
                        }
                    />
                );
            },
            filter: 'agTextColumnFilter',
            minWidth: 200,
            ...DefaultColumnTypes.LongText,
            ...GridOptions.sortFilterAndWrapColumns,
            floatingFilter: true,
            maxWidth: 1000,
        },
    ];

    return (
        <MultiSelectGridModal
            title={
                (
                    <TranslatableText
                        termSet={termSet}
                        termKey={
                            CasMasterFormDefs.Ghs_Hazard_Phrases_Phrase_Selection
                        }
                    />
                ) as unknown as string
            }
            columnDefs={columnDefs}
            open={props.openModal}
            onClose={props.onClose}
            onSelected={props.onSelected}
            rowData={props.hazardPhraseList}
            preSelectedIds={() => []}
        />
    );
};

export default GhsHazardPhrasesSelectionModal;
