import React, { CSSProperties } from 'react';
import { Card, CardHeader, Grid } from '@mui/material';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Button from '@mui/material/Button';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import DatacorLogoSpinner from '../../../../components/datacorLogoSpinner/DatacorLogoSpinner';
import useSafetyDataSheetSectionHeaderFormContainer, {
    SafetyDataSheetHeaderFormContainerProps,
} from './hooks/useSafetyDataSheetSectionHeaderFormContainer';
import SafetyDataSheetSectionHeaderForm from './SafetyDataSheetSectionHeaderForm';
import './SafetyDataSheetSectionHeaderFormContainer.css';
import LanguageSelectionModal from '../../shared/languageSelectionModal/LanguageSelectionModal';
import { SdsSectionHeadersFormDefs } from '../../../../constants/i18n/translations/termDefinitions/formulation';
import TranslatableText from '../../../../components/i18n/TranslatableText';

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
    style?: CSSProperties;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
    return (
        <div
            style={props.style}
            role="tabpanel"
            className={'header-tabpanel'}
            hidden={value !== index}
            id={`header-tabpanel-${index}`}
            aria-labelledby={`header-tab-${index}`}
            {...other}>
            {value === index && <Box sx={{ p: 0 }}>{children}</Box>}
        </div>
    );
}

const SafetyDataSheetSectionHeaderFormContainer = ({
    headerId,
    localeGroupId,
    languageList,
    defaultLangId,
    safetyDataSheetSectionHeaderKeyList,
    handleSetIsFormDirty,
    handlePromptFormDirty,
}: SafetyDataSheetHeaderFormContainerProps) => {
    const {
        safetyDataSheetHeaderLangList,
        isLoadingSafetyDataSheetHeaderList,
        langTabValue,
        localeGroupOptions,
        showModal,
        disableLangButton,
        languageOptionList,
        handleTabChange,
        addNewLanguage,
        handleOpenModal,
        handleSetPreviousTab,
        cancelNewLanguage,
        handleSetDisableLangButton,
        canCreateSafetyDataSheetHeader,
        termSet,
    } = useSafetyDataSheetSectionHeaderFormContainer({
        headerId,
        localeGroupId,
        defaultLangId,
        languageList,
        safetyDataSheetSectionHeaderKeyList,
        handleSetIsFormDirty,
        handlePromptFormDirty,
    });

    const headerIdLocaleGrpId =
        headerId && localeGroupId ? `${headerId}${localeGroupId}` : undefined;

    return (
        <>
            {isLoadingSafetyDataSheetHeaderList ? (
                <DatacorLogoSpinner />
            ) : (
                <Card sx={{ height: '100%' }}>
                    <CardHeader
                        sx={{ paddingBottom: 0 }}
                        title={
                            <Grid container flexDirection="row" spacing={2}>
                                <Grid item xs={6} style={{ paddingTop: '0px' }}>
                                    <p>
                                        <TranslatableText
                                            termSet={termSet}
                                            termKey={
                                                !headerIdLocaleGrpId
                                                    ? SdsSectionHeadersFormDefs.Create_SDS_Section_Header
                                                    : SdsSectionHeadersFormDefs.Edit_SDS_Section_Header
                                            }
                                        />
                                    </p>
                                </Grid>
                                <Grid
                                    item
                                    xs={6}
                                    container
                                    justifyContent="flex-end"
                                    style={{ paddingTop: '0px' }}>
                                    <Button
                                        variant="text"
                                        size="small"
                                        onClick={() => handleOpenModal()}
                                        data-testid="create-new-btn"
                                        endIcon={<ManageSearchIcon />}
                                        sx={{
                                            marginBottom: '5%',
                                            marginTop: '5%',
                                        }}
                                        disabled={
                                            !headerIdLocaleGrpId ||
                                            !canCreateSafetyDataSheetHeader ||
                                            disableLangButton
                                        }>
                                        <TranslatableText
                                            termSet={termSet}
                                            termKey={
                                                SdsSectionHeadersFormDefs.Add_Language
                                            }
                                        />
                                    </Button>
                                </Grid>
                            </Grid>
                        }
                    />
                    <Grid
                        container
                        flexDirection="row"
                        spacing={1}
                        height={'100%'}>
                        <Grid item xs={2}>
                            <Tabs
                                orientation="vertical"
                                variant="scrollable"
                                value={langTabValue}
                                onChange={handleTabChange}
                                textColor="inherit"
                                indicatorColor="secondary"
                                sx={{
                                    borderRight: 1,
                                    borderColor: 'divider',
                                    height: {
                                        xl: '90%',
                                        md: '85%',
                                        sm: '70%',
                                    },
                                    width: '100%',
                                }}>
                                {safetyDataSheetHeaderLangList &&
                                    safetyDataSheetHeaderLangList.map(
                                        (safetyDataSheetHeaderLang) => {
                                            return (
                                                <Tab
                                                    key={safetyDataSheetHeaderLang.langId.toString()}
                                                    label={
                                                        safetyDataSheetHeaderLang.langText
                                                    }
                                                />
                                            );
                                        }
                                    )}
                            </Tabs>
                        </Grid>
                        <Grid item xs={10}>
                            {safetyDataSheetHeaderLangList &&
                                safetyDataSheetHeaderLangList.map(
                                    (safetyDataSheetHeaderLang, index) => {
                                        return (
                                            <TabPanel
                                                key={safetyDataSheetHeaderLang.langId.toString()}
                                                value={langTabValue}
                                                index={index}
                                                style={{ height: '90%' }}>
                                                <SafetyDataSheetSectionHeaderForm
                                                    id={
                                                        safetyDataSheetHeaderLang.id
                                                    }
                                                    headerId={
                                                        safetyDataSheetHeaderLang.headerId
                                                    }
                                                    localeGroupId={
                                                        safetyDataSheetHeaderLang.localeGroupId
                                                    }
                                                    currentLangId={
                                                        safetyDataSheetHeaderLang.langId
                                                    }
                                                    defaultLangId={
                                                        defaultLangId
                                                    }
                                                    showOnSds={
                                                        safetyDataSheetHeaderLang.showOnSds
                                                    }
                                                    section={
                                                        safetyDataSheetHeaderLang.section
                                                    }
                                                    subSection={
                                                        safetyDataSheetHeaderLang.subSection
                                                    }
                                                    subSectionLetter={
                                                        safetyDataSheetHeaderLang.subSectionLetter
                                                    }
                                                    localeGroupOptions={
                                                        localeGroupOptions
                                                    }
                                                    allowDelete={
                                                        safetyDataSheetHeaderLangList.length ===
                                                            1 ||
                                                        (safetyDataSheetHeaderLangList.length >
                                                            1 &&
                                                            !safetyDataSheetHeaderLang.isDefaultLangId)
                                                    }
                                                    safetyDataSheetSectionHeaderKeyList={
                                                        safetyDataSheetSectionHeaderKeyList
                                                    }
                                                    handleSetPreviousTab={
                                                        handleSetPreviousTab
                                                    }
                                                    handleSetIsFormDirty={
                                                        handleSetIsFormDirty
                                                    }
                                                    cancelNewLanguage={
                                                        cancelNewLanguage
                                                    }
                                                    setDisableLangButton={
                                                        handleSetDisableLangButton
                                                    }
                                                />
                                            </TabPanel>
                                        );
                                    }
                                )}
                        </Grid>
                    </Grid>
                    <LanguageSelectionModal
                        showModal={showModal}
                        handleModalClose={addNewLanguage}
                        languageList={languageOptionList}
                    />
                </Card>
            )}
        </>
    );
};

export default SafetyDataSheetSectionHeaderFormContainer;
