import { useParams } from 'react-router-dom';
import BaseForm, {
    ParamTypes,
} from '../../../components/form/baseForm/BaseForm';
import TranslatableText from '../../../components/i18n/TranslatableText';
import { CurrencyRateTypeFormDefs } from '../../../constants/i18n/translations/termDefinitions/accounting';
import { NumberMaxValues, RoutingValues } from '../../../types/Shared.types';
import { CURRENCY_RATE_TYPE } from '../../../constants/revisionHistory/revisionHistory.constants';
import FormPartial from '../../../components/form/formContainer/FormPartial';
import { Grid } from '@mui/material';
import SelectInput from '../../../components/form/formInputs/SelectInput/SelectInput';
import NumericInput from '../../../components/form/formInputs/NumericInput/NumericInput';
import TextInput from '../../../components/form/formInputs/TextInput/TextInput';
import React from 'react';
import { useCurrencyRateTypeForm } from './hooks/useCurrencyRateTypeForm';

const CurrencyRateTypeForm = () => {
    const { id } = useParams<ParamTypes>();
    const {
        fields,
        handleFieldChange,
        formMethods,
        onCreate,
        onUpdate,
        onDelete,
        closeForm,
        isLoading,
        termSet,
        canCreateRateType,
        canUpdateRateType,
        canDeleteRateType,
        rateTypeCodes,
        isLoadingRateTypeCodes,
        auditableFields,
    } = useCurrencyRateTypeForm(id);

    return (
        <BaseForm
            title="Exchange Rate Type"
            formMethods={formMethods}
            isNew={id === RoutingValues.newId}
            isLoading={isLoading}
            onCreate={onCreate}
            onUpdate={onUpdate}
            onDelete={onDelete}
            onCancel={closeForm}
            showOnRevision={true}
            fieldMappings={CURRENCY_RATE_TYPE}
            entityUuid={fields?.uuid}
            displayCreateButton={canCreateRateType}
            displayUpdateButton={canUpdateRateType}
            displayDeleteButton={canDeleteRateType}
            auditableFields={auditableFields}>
            <FormPartial>
                <Grid container flexDirection="row" spacing={2}>
                    <Grid item xs={6}>
                        <SelectInput
                            fullWidth
                            label={
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={
                                        CurrencyRateTypeFormDefs.CurrencyRateTypeCode
                                    }
                                />
                            }
                            id="currencyRateTypeCode"
                            value={fields.currencyRateTypeCode}
                            onChange={handleFieldChange}
                            formMethods={formMethods}
                            options={rateTypeCodes}
                            disabled={false}
                            required={true}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextInput
                            fullWidth
                            label={
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={CurrencyRateTypeFormDefs.Code}
                                />
                            }
                            id="code"
                            value={fields.code}
                            onChange={handleFieldChange}
                            formMethods={formMethods}
                            required={true}
                            maxLength={30}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextInput
                            fullWidth
                            label={
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={
                                        CurrencyRateTypeFormDefs.Description
                                    }
                                />
                            }
                            id="description"
                            value={fields.description}
                            onChange={handleFieldChange}
                            formMethods={formMethods}
                            required={false}
                            maxLength={255}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <NumericInput
                            fullWidth
                            label={
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={CurrencyRateTypeFormDefs.SortOrder}
                                />
                            }
                            id="sortOrder"
                            value={fields.sortOrder}
                            onChange={handleFieldChange}
                            formMethods={formMethods}
                            required={true}
                            disabled={true}
                            maxValue={NumberMaxValues.smallint}
                        />
                    </Grid>
                </Grid>
            </FormPartial>
        </BaseForm>
    );
};

export default CurrencyRateTypeForm;
