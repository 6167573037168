import React from 'react';
import DatacorLogoSpinner from '../../components/datacorLogoSpinner/DatacorLogoSpinner';
import useFeatureFlagsGrid from './hooks/useFeatureFlagsGrid';
import BaseFormGrid from '../../components/grids/BaseFormGrid';
import { Box, Tabs, Tab } from '@mui/material';
import { FeatureFlagsGridDefs } from '../../constants/i18n/translations/termDefinitions/platform';
import TranslatableText from '../../components/i18n/TranslatableText';

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

const TabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}>
            {value === index && <Box sx={{ height: '80vh' }}>{children}</Box>}
        </div>
    );
};

const FeatureFlagsGrid = () => {
    const {
        onGridReady,
        rowData,
        globalRowData,
        isLoading,
        isLoadingGlobal,
        columns,
        frameworkComponents,
        onSortChanged,
        onFirstDataRendered,
        selectedTab,
        handleChange,
        handleUpdateFeatureFlags,
        onGlobalGridReady,
        canUpdateFeatureFlags,
        showUpdateWillImpactAllConfirmModal,
        termSet,
    } = useFeatureFlagsGrid();

    return (
        <>
            {!isLoading ? (
                <>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs
                            value={selectedTab}
                            onChange={handleChange}
                            aria-label="basic tabs example">
                            <Tab
                                label={
                                    <TranslatableText
                                        termSet={termSet}
                                        termKey={
                                            FeatureFlagsGridDefs.Business_Entity
                                        }
                                    />
                                }
                            />
                            <Tab
                                label={
                                    <TranslatableText
                                        termSet={termSet}
                                        termKey={FeatureFlagsGridDefs.Global}
                                    />
                                }
                            />
                        </Tabs>
                    </Box>
                    <TabPanel value={selectedTab} index={0}>
                        <BaseFormGrid
                            testId="featureFlags-list-grid"
                            dataKey="id"
                            displayGrid={true}
                            gridClass="full-size-item"
                            rowSelection={'multiple'}
                            setInitialRowSelected={true}
                            frameworkComponents={frameworkComponents}
                            isLoading={isLoading}
                            immutableData={true}
                            rowData={rowData}
                            columnDefs={columns}
                            displayToolbar={true}
                            displayEnableDisableButton={canUpdateFeatureFlags}
                            onEnableDisable={handleUpdateFeatureFlags}
                            onFirstDataRendered={onFirstDataRendered}
                            suppressRowClickSelection={true}
                            onSortChanged={onSortChanged}
                            onGridReady={onGridReady}></BaseFormGrid>
                    </TabPanel>
                    <TabPanel value={selectedTab} index={1}>
                        <BaseFormGrid
                            testId="featureFlags-list-grid"
                            dataKey="id"
                            defaultColDef={{
                                floatingFilter: true,
                            }}
                            gridClass="full-size-item"
                            isLoading={isLoadingGlobal}
                            rowSelection={'multiple'}
                            setInitialRowSelected={true}
                            suppressRowClickSelection={true}
                            immutableData={true}
                            displayGrid={true}
                            rowData={globalRowData}
                            columnDefs={columns}
                            displayToolbar={true}
                            displayEnableDisableButton
                            frameworkComponents={frameworkComponents}
                            onEnableDisable={
                                showUpdateWillImpactAllConfirmModal
                            }
                            onFirstDataRendered={onFirstDataRendered}
                            onSortChanged={onSortChanged}
                            onGridReady={onGlobalGridReady}></BaseFormGrid>
                    </TabPanel>
                </>
            ) : (
                <DatacorLogoSpinner />
            )}
        </>
    );
};

export default FeatureFlagsGrid;
