import { useForm } from '../../../../libs/hooksLib';
import {
    useDepreciationDetailReportQuery,
    useGetTypesForDropdownQuery,
} from '../../../../services/fixedAssets/fixedAssets.service';
import { DepreciationDetailParams } from '../../../../types/FixedAsset.types';
import { isNil, isNilOrEmpty } from '../../../../utils/objectUtils';
import { skipToken } from '@reduxjs/toolkit/query';
import { useEffect, useState } from 'react';
import { ReportDepreciationDetailsProps } from '../DepreciationDetailOptionsModal';

const useDepreciationDetailOptionsModal = (
    props: ReportDepreciationDetailsProps
) => {
    const initialFields: any = {
        assetTypeId: null,
        startDate: null,
        endDate: null,
    };

    const { fields, handleFieldChange } = useForm(initialFields);
    const [queryParams, setQueryParams] =
        useState<DepreciationDetailParams>(null);

    const { data: assetTypeOptions, isLoading: loadingAssetTypes } =
        useGetTypesForDropdownQuery();

    const {
        data: depreciationDetailList,
        isFetching: isLoadingDepreciationDetails,
    } = useDepreciationDetailReportQuery(queryParams ? queryParams : skipToken);

    const handleGetDepreciationDetails = (event: any) => {
        event.preventDefault();

        const listParams: DepreciationDetailParams = {};

        if (!isNilOrEmpty(fields.assetTypeId)) {
            listParams['assetTypeId'] = fields.assetTypeId;
        }
        if (!isNil(fields.startDate)) {
            listParams['startDate'] = fields.startDate;
        }
        if (!isNil(fields.endDate)) {
            listParams['endDate'] = fields.endDate;
        }

        setQueryParams(listParams);
    };

    useEffect(() => {
        if (depreciationDetailList) {
            props.onDepreciationDetailsLoaded(depreciationDetailList);
            props.onClose();
        }
    }, [depreciationDetailList]);

    return {
        queryParams,
        fields,
        assetTypeOptions,
        loadingAssetTypes,
        handleFieldChange,
        handleGetDepreciationDetails,
        isLoadingDepreciationDetails,
    };
};

export default useDepreciationDetailOptionsModal;
