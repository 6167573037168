import React, { useState, useEffect } from 'react';
import TreeView from '@mui/lab/TreeView';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import TreeItem from '@mui/lab/TreeItem';
import { Checkbox, FormControlLabel } from '@mui/material';

import _ from 'lodash';
import { ExpandableTreeViewProps } from '../../components/form/expandableTreeView/ExpandableTreeView';
import { ExpandableTreeNode } from '../../components/form/expandableDropdown/ExpandableDropdown';
import { isNilOrEmpty } from '../../utils/objectUtils';

export interface BusinessEntityTreeViewProps extends ExpandableTreeViewProps {
    defaultBusinessEntityId: number | bigint;
}

const BusinessEntityTreeViewSelect = (props: BusinessEntityTreeViewProps) => {
    const [selected, setSelected] = useState([]);
    const [nodes, setNodes] = useState(new Map());
    const [rootNodes, setRootNodes] = useState([]);
    const [unselectableIds, setUnselectableIds] = useState([]);

    let nodeList = new Map();
    let rootNodesList: ExpandableTreeNode[] = [];
    let defaultsSet = false;

    useEffect(() => {
        if (props.idKey && props.labelKey && props.items) {
            const recursiveMap: any = (item: any, parentId: number) => {
                const itemNode = new ExpandableTreeNode(
                    item[props.idKey],
                    item[props.labelKey],
                    parentId,
                    item.children?.map((child: any) => child[props.idKey])
                );

                if (parentId === null) {
                    rootNodesList.push(itemNode);
                }
                nodeList.set(itemNode.id, itemNode);
                item.children?.map((child: any) =>
                    recursiveMap(child, itemNode.id)
                );
            };

            nodeList = new Map();
            rootNodesList = [];

            props.items.forEach((item: any) => recursiveMap(item, null));
            setRootNodes(rootNodesList);
            setNodes(new Map(nodeList));
        }
    }, [props.items, props.idKey, props.labelKey]);

    useEffect(() => {
        if (props.defaultValue && !defaultsSet) {
            handleSetupNodes();
            defaultsSet = true;
        }
    }, [props.defaultValue, nodes]);

    useEffect(() => {
        if (props.defaultBusinessEntityId && nodes.size !== 0 && !defaultsSet) {
            const children = getAllChildrenRecursive(
                nodes.get(props.defaultBusinessEntityId)
            );
            setUnselectableIds(children);

            setSelected(children.map((childId) => nodes.get(childId)));

            if (props.valueSelected) {
                props.valueSelected(
                    children.map((childId) => nodes.get(childId))
                );
            }
        }
    }, [props.defaultBusinessEntityId, nodes, defaultsSet]);

    const handleSetupNodes = async () => {
        if (props.defaultValue && Array.from(nodes).length > 0) {
            const node = nodes.get(props.defaultValue);
            let nodeArr: any = [];
            //@ts-ignore
            if (props.defaultValue?.length > 0) {
                //@ts-ignore
                for (const item of props.defaultValue) {
                    if (!isNilOrEmpty(item?.id)) {
                        await nodeArr.push(nodes.get(item?.id));
                    }
                }
            } else {
                const defaultValue = props.defaultValue;
                //@ts-ignore
                if (defaultValue.length > 0) {
                    nodeArr.push(nodes.get(defaultValue));
                } else if (!isNilOrEmpty(defaultValue)) {
                    //@ts-ignore
                    nodeArr.push(nodes.get(defaultValue?.id));
                }
            }
            setSelected(
                nodeArr.map((nodeItem: ExpandableTreeNode) => nodeItem?.id)
            );
        }
    };

    function getOnChange(checked: boolean, node: ExpandableTreeNode) {
        let childrenIds = getAllChildrenRecursive(node);
        const defaultBusinessEntityIdsAndChildren = getAllChildrenRecursive(
            nodes.get(props.defaultBusinessEntityId)
        );

        let unselectedTemp = [];
        if (props.disableChildrenOnParentSelect && checked) {
            unselectedTemp = _.union(
                unselectableIds,
                childrenIds.filter((childrenId) => childrenId !== node.id)
            );
            setUnselectableIds(unselectedTemp);
        } else if (props.disableChildrenOnParentSelect && !checked) {
            unselectedTemp = _.difference(
                unselectableIds,
                childrenIds.filter((childrenId) => childrenId !== node.id)
            );
            unselectedTemp = _.union(unselectedTemp);
            setUnselectableIds(unselectedTemp);
        }

        let newItems = checked
            ? _.union(childrenIds, selected)
            : _.difference(selected, childrenIds);
        newItems = _.union(newItems, unselectedTemp);

        setSelected(newItems);
        if (props.valueSelected) {
            props.valueSelected(newItems.map((itemId) => nodes.get(itemId)));
        }
    }

    const getAllChildrenRecursive = (
        node: ExpandableTreeNode,
        idList: number[] = []
    ) => {
        idList.push(+node.id);
        node.childrenIds?.forEach((childrenId: number) => {
            const childNode = nodes.get(childrenId);
            getAllChildrenRecursive(childNode, idList);
        });

        return idList;
    };

    const renderTree = (renderNode: ExpandableTreeNode) => {
        let treeNode = renderNode;

        return (
            <TreeItem
                key={treeNode.id}
                nodeId={treeNode.id.toString()}
                label={
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={selected.some(
                                    (item) => item === treeNode.id
                                )}
                                onChange={(event) =>
                                    getOnChange(
                                        event.currentTarget.checked,
                                        treeNode
                                    )
                                }
                                disabled={_.some(
                                    unselectableIds,
                                    (itemId) => itemId === treeNode.id
                                )}
                                onClick={(e) => e.stopPropagation()}
                            />
                        }
                        label={<>{treeNode.label}</>}
                        key={treeNode.id}
                    />
                }>
                {Array.isArray(treeNode.childrenIds)
                    ? treeNode.childrenIds.map((node: any) =>
                          renderTree(nodes.get(node))
                      )
                    : null}
            </TreeItem>
        );
    };

    return (
        <TreeView
            defaultCollapseIcon={<ExpandMoreIcon />}
            defaultExpandIcon={<ChevronRightIcon />}>
            {rootNodes
                ? rootNodes.map((rootNode) => renderTree(rootNode))
                : null}
        </TreeView>
    );
};

export default BusinessEntityTreeViewSelect;
