import { useNavigate } from 'react-router-dom';
import {
    useGetAllDepreciationMethodsQuery,
    useUpdateListDepreciationMethodsMutation,
} from '../../../services/fixedAssets/fixedAssets.service';
import React, { useEffect, useState } from 'react';
import { ColDef, ICellRendererParams } from 'ag-grid-community';
import {
    FA_DepreciationMethod,
    FA_DepreciationMethodCost,
} from '../../../types/FixedAsset.types';
import { isNilOrEmpty } from '../../../utils/objectUtils';
import { PermissionsUtil } from '../../../utils/permissions/permissionsUtil';
import { PERMISSIONS } from '../../../constants/permissions/Permissions.constants';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { RoutingValues } from '../../../types/Shared.types';
import _ from 'lodash';
import { getMaxSortOrderValue } from '../../../store/fixedAssets';
import DefaultColumnTypes from '../../../components/grids/columns/Column.constants';
import { openSnackbarBasicWithMessage } from '../../../store/uiElements';
import CustomHeader from '../../../components/grids/CustomHeader';
import { DepreciationMethodsGridDefs } from '../../../constants/i18n/translations/termDefinitions/accounting';
import useTranslation from '../../../components/i18n/hooks/useTranslation';
import { ACCOUNTING_DEFS } from '../../../constants/i18n/translations/termSetDefinitions/accounting';

const useFixedAssetDepreciationMethodGrid = () => {
    const user = useSelector((state: RootState) => state.user);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    let {
        data: methodList,
        isLoading,
        error,
    } = useGetAllDepreciationMethodsQuery();
    const [updateList] = useUpdateListDepreciationMethodsMutation();
    const [colDefs, setColDefs] = useState([]);
    const [rowData, setRowData] = useState([]);

    const canCreate = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.FIXED_ASSETS.DEPRECIATION_METHODS.CREATE
    );
    const canView = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.FIXED_ASSETS.DEPRECIATION_METHODS.VIEW
    );
    const canUpdate = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.FIXED_ASSETS.DEPRECIATION_METHODS.EDIT
    );

    const { termSet } = useTranslation(
        ACCOUNTING_DEFS.DEPRECIATION_METHODS_GRID
    );

    useEffect(() => {
        if (!isNilOrEmpty(error) && 'data' in error) {
            methodList = [];
            setRowData([]);
            dispatch(
                openSnackbarBasicWithMessage({
                    message: _.get(error, 'data.error', 'Unable to load data.'),
                    severity: 'error',
                })
            );
        }
    }, [error]);

    useEffect(() => {
        if (!isNilOrEmpty(methodList)) {
            setRowData(methodList);
        }
    }, [methodList]);

    const handleGridEdits = (editedRows: FA_DepreciationMethod[]) => {
        setRowData(editedRows);
        updateList(editedRows);
    };

    const redirectToForm = (row: any) => {
        if (row.id) {
            navigate(`/accounting/fixedAssets/depreciationMethod/${row.id}`);
        } else {
            navigate(
                `/accounting/fixedAssets/depreciationMethod/${RoutingValues.newId}`
            );
        }
    };

    const getCostCodeValue = (methodCosts: FA_DepreciationMethodCost[]) => {
        return isNilOrEmpty(methodCosts)
            ? ''
            : methodCosts
                  .map(
                      (dmCost: FA_DepreciationMethodCost) =>
                          dmCost.costCode.code
                  )
                  .join(', ');
    };

    const CostCodeColumn = (params: ICellRendererParams) => {
        return <span>{params.value}</span>;
    };

    useEffect(() => {
        let maxValue = 1;
        if (methodList && methodList.length > 0) {
            const data = _.maxBy(
                methodList,
                (depreciationMethod: FA_DepreciationMethod) =>
                    depreciationMethod.sortOrder
            );
            maxValue = data.sortOrder + 1;
        }

        dispatch(
            getMaxSortOrderValue({ isLoading: false, maxValue: maxValue })
        );
    }, [methodList]);

    useEffect(() => {
        if (!isNilOrEmpty(rowData) && isNilOrEmpty(colDefs)) {
            const methodsColumnDefs: ColDef[] = [
                {
                    field: 'code',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={DepreciationMethodsGridDefs.Code}
                            />
                        );
                    },
                    ...DefaultColumnTypes.ShortText,
                },
                {
                    field: 'name',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={DepreciationMethodsGridDefs.Name}
                            />
                        );
                    },
                    ...DefaultColumnTypes.MediumText,
                },
                {
                    field: 'description',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={
                                    DepreciationMethodsGridDefs.Description
                                }
                            />
                        );
                    },
                    ...DefaultColumnTypes.LongText,
                },
                {
                    field: 'depreciationMethodStatus.code',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={DepreciationMethodsGridDefs.Status}
                            />
                        );
                    },
                    ...DefaultColumnTypes.ShortText,
                },
                {
                    field: 'depreciationMethodType.code',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={DepreciationMethodsGridDefs.Type}
                            />
                        );
                    },
                    ...DefaultColumnTypes.ShortText,
                },
                {
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={DepreciationMethodsGridDefs.Cost_Code}
                            />
                        );
                    },
                    ...DefaultColumnTypes.LongText,
                    cellRenderer: 'costCodeColumn',
                    valueGetter: (params) => {
                        return getCostCodeValue(params.data.costs);
                    },
                },
            ];
            setColDefs(methodsColumnDefs);
        }
    }, [rowData]);

    return {
        rowData,
        colDefs,
        isLoading,
        canCreate,
        canView,
        canUpdate,
        redirectToForm,
        CostCodeColumn,
        handleGridEdits,
        termSet,
    };
};

export default useFixedAssetDepreciationMethodGrid;
