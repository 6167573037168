import { ColDef } from 'ag-grid-community';
import DefaultColumnTypes from '../../../../components/grids/columns/Column.constants';
import React from 'react';
import BaseGrid from '../../../../components/grids/BaseGrid';
import { Contract } from '../../../../types/Accounting.types';
import useContractGrid from './hooks/useContractGrid';
import MultiSelectGridModal from '../../../../components/modals/MultiSelectGridModal';
import { FixedAssetRegisterFormDefs } from '../../../../constants/i18n/translations/termDefinitions/accounting';
import TranslatableText from '../../../../components/i18n/TranslatableText';
import CustomHeader from '../../../../components/grids/CustomHeader';
export interface ContractProps {
    contracts: Contract[];
}
const ContractGrid = React.forwardRef((props: ContractProps, ref: any) => {
    const {
        selectedContracts,
        setSelectedContracts,
        showContractSelectionModal,
        setShowContractSelectionModal,
        contractList,
        isLoadingContracts,
        termSet,
    } = useContractGrid(props, ref);

    const columnDefs: ColDef[] = [
        {
            field: 'number',
            headerComponentFramework: (props: any) => {
                return (
                    <TranslatableText
                        termSet={termSet}
                        termKey={FixedAssetRegisterFormDefs.ContractNumber}
                    />
                );
            },
            ...DefaultColumnTypes.MediumText,
        },
        {
            field: 'name',
            headerComponentFramework: (props: any) => {
                return (
                    <TranslatableText
                        termSet={termSet}
                        termKey={FixedAssetRegisterFormDefs.ContractName}
                    />
                );
            },
            ...DefaultColumnTypes.MediumText,
        },
        {
            field: 'description',
            headerComponentFramework: (props: any) => {
                return (
                    <TranslatableText
                        termSet={termSet}
                        termKey={FixedAssetRegisterFormDefs.ContractDescription}
                    />
                );
            },
            ...DefaultColumnTypes.LongText,
        },
        {
            field: 'contractType.code',
            headerComponentFramework: (props: any) => {
                return (
                    <TranslatableText
                        termSet={termSet}
                        termKey={FixedAssetRegisterFormDefs.ContractType}
                    />
                );
            },
            ...DefaultColumnTypes.ShortText,
        },
    ];

    const ContractSelectionModal = () => {
        return (
            <MultiSelectGridModal
                title={
                    (
                        <TranslatableText
                            termSet={termSet}
                            termKey={
                                FixedAssetRegisterFormDefs.Contract_Selection
                            }
                        />
                    ) as unknown as string
                }
                open={showContractSelectionModal}
                onClose={() => setShowContractSelectionModal(false)}
                onSelected={(contractList: Contract[]) =>
                    setSelectedContracts(contractList)
                }
                rowData={contractList || []}
                columnDefs={[
                    {
                        field: 'number',
                        headerComponentFramework: (props: any) => {
                            return (
                                <CustomHeader
                                    {...props}
                                    termSet={termSet}
                                    termKey={
                                        FixedAssetRegisterFormDefs.Contract_Selection_Number
                                    }
                                />
                            );
                        },
                        ...DefaultColumnTypes.ShortText,
                    },
                    {
                        field: 'name',
                        headerComponentFramework: (props: any) => {
                            return (
                                <CustomHeader
                                    {...props}
                                    termSet={termSet}
                                    termKey={
                                        FixedAssetRegisterFormDefs.Contract_Selection_Name
                                    }
                                />
                            );
                        },
                        minWidth: 200,
                        ...DefaultColumnTypes.MediumText,
                    },
                    {
                        field: 'contractType.code',
                        headerComponentFramework: (props: any) => {
                            return (
                                <CustomHeader
                                    {...props}
                                    termSet={termSet}
                                    termKey={
                                        FixedAssetRegisterFormDefs.Contract_Selection_Type
                                    }
                                />
                            );
                        },
                        ...DefaultColumnTypes.ShortText,
                    },
                ]}
                preSelectedIds={() => {
                    return selectedContracts?.map(
                        (contract: Contract) => contract.id
                    );
                }}
            />
        );
    };

    return (
        <React.Fragment>
            <BaseGrid
                rowData={selectedContracts}
                displayGrid={true}
                displayToolbar={true}
                columnDefs={columnDefs}
                displayCreateNewButton={!isLoadingContracts}
                onCreateNew={() => setShowContractSelectionModal(true)}
                defaultColDef={{
                    resizable: true,
                    wrapText: true,
                }}></BaseGrid>

            <ContractSelectionModal />
        </React.Fragment>
    );
});
export default ContractGrid;
