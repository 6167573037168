import React from 'react';
import { isNil } from '../utils/objectUtils';
import { ParameterizedValidator, Validator } from './validator.interface';
import { PhoneNumberData } from '../types/Shared.types';
import _ from 'lodash';

export const MinPhoneDigitsValidator: ParameterizedValidator = (
    minPhoneDigits: number
) => {
    return {
        key: 'minPhoneDigits',
        getErrorMessage: (fieldLabel: string) => {
            if (_.isObject(fieldLabel)) {
                return (
                    <>
                        {fieldLabel} has a minimum length of {minPhoneDigits}{' '}
                        digits
                    </>
                );
            }
            return `${fieldLabel} has a minimum length of ${minPhoneDigits} digits`;
        },
        isValid: (value: PhoneNumberData) => {
            if (isNil(value)) {
                return true;
            } else {
                // strips out all characters except digits
                var digitString: string = value.phoneNumber.replace(
                    /[^0-9]/g,
                    ''
                );
                // the ===1 check is necessary due to country code always being present
                return (
                    digitString.length === 1 ||
                    digitString.length >= minPhoneDigits
                );
            }
        },
    } as Validator;
};
