import React, { useEffect, useState } from 'react';
import InputBase, { InputBaseProps } from '../InputBase/InputBase';
import { MaxLengthValidator } from '../../../../validators/maxLength.validator';
import { MinLengthValidator } from '../../../../validators/minLength.validator';
import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import BaseGrid from '../../../grids/BaseGrid';
import SearchIcon from '@mui/icons-material/Search';

export type TextInputPopupProps = {
    minLength?: number;
    maxLength?: number;
    popupOptions?: any[];
    colDefs?: any[];
    valueFormatter?: { optionLabel: string; value: string };
    optionLabel?: string;
} & InputBaseProps;
const TextInputPopup = ({
    onFocus,
    popupOptions,
    valueFormatter,
    ...props
}: TextInputPopupProps) => {
    const [open, setOpen] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);

    useEffect(() => {
        if (props.formMethods) {
            addValidators({ onFocus, ...props });
        }
    }, []);

    const handleOnClick = (event: any) => {
        if (!props.disabled) {
            setOpen(true);
        }
    };

    const handleClick = (row: any) => {
        const event: any = {
            target: { id: props.id, value: row[valueFormatter.value] },
        };
        props.onChange(event);
        setOpen(false);
        setSelectedRow(row);
    };

    const handleClose = async (event: any) => {
        setOpen(false);
    };

    const getValue = () => {
        if (selectedRow) {
            return selectedRow[valueFormatter.optionLabel];
        }
        return props.optionLabel || '';
    };

    return (
        <>
            <Dialog open={open} onClose={handleClose} fullWidth>
                <DialogTitle>Select {props.label}</DialogTitle>
                <DialogContent>
                    <BaseGrid
                        columnDefs={props.colDefs}
                        testId="input-popup-grid"
                        displayGrid={true}
                        rowData={popupOptions}
                        onRowClicked={(row: any) => handleClick(row.data)}
                        displayCreateNewButton={false}
                        defaultColDef={{
                            resizable: true,
                            sortable: true,
                            unSortIcon: true,
                            filter: true,
                            wrapText: true,
                            floatingFilter: true,
                        }}></BaseGrid>
                </DialogContent>
            </Dialog>
            <InputBase
                InputProps={{
                    endAdornment: <SearchIcon />,
                }}
                onClick={handleOnClick}
                {...props}
                value={getValue()}
            />
        </>
    );
};

const addValidators = (props: TextInputPopupProps) => {
    if (props.maxLength) {
        props.formMethods?.addValidator(
            props.id,
            props.label.toString(),
            MaxLengthValidator(props.maxLength)
        );
    }

    if (props.minLength) {
        props.formMethods?.addValidator(
            props.id,
            props.label.toString(),
            MinLengthValidator(props.minLength)
        );
    }
};

export default TextInputPopup;
