import React from 'react';
import { GridOptions } from '../../../components/grids/Grid.constants';
import useHazardRatingGrid from './hooks/useHazardRatingGrid';
import EditableBaseFormGrid from '../../../components/grids/EditableBaseFormGrid';
import { HazardRating } from '../../../types/formulation';
import { isNilOrEmpty } from '../../../utils/objectUtils';
import DatacorLogoSpinner from '../../../components/datacorLogoSpinner/DatacorLogoSpinner';
import { HazardRatingsGridDefs } from '../../../constants/i18n/translations/termDefinitions/formulation';
import TranslatableText from '../../../components/i18n/TranslatableText';

const defaultColumnDefinitionOptions = {
    ...GridOptions.sortFilterAndWrapColumns,
    floatingFilter: true,
};

const HazardRatingGrid = () => {
    const {
        columnDefs,
        hazardRatingList,
        handleUpdate,
        handleRowValidations,
        termSet,
        placeHolders,
        canViewHazardRatings,
        canCreateHazardRatings,
        canUpdateHazardRatings,
        isLoading,
        isLoadingUnitOfMeasure,
    } = useHazardRatingGrid();

    return (
        <>
            {isNilOrEmpty(columnDefs) && canViewHazardRatings ? (
                <DatacorLogoSpinner />
            ) : (
                <EditableBaseFormGrid<HazardRating>
                    autoSize={true}
                    displayGrid={canViewHazardRatings}
                    dataKey={'rowId'}
                    isLoading={
                        columnDefs == null ||
                        isLoading ||
                        isLoadingUnitOfMeasure
                    }
                    rowData={hazardRatingList}
                    columnDefs={columnDefs}
                    gridClass="full-size-item"
                    title={
                        (
                            <TranslatableText
                                termSet={termSet}
                                termKey={
                                    HazardRatingsGridDefs.Hazard_Ratings_Title
                                }
                            />
                        ) as unknown as string
                    }
                    displayToolbar={canCreateHazardRatings}
                    displayCreateNewButton={canCreateHazardRatings}
                    displayExportDataButton={false}
                    displayResetStateButton={false}
                    useRowDrag={canUpdateHazardRatings}
                    displaySortButton={canUpdateHazardRatings}
                    sortKey={'sortOrder'}
                    defaultColDef={defaultColumnDefinitionOptions}
                    sizeColumnsToFit={true}
                    isEditable={
                        canCreateHazardRatings || canUpdateHazardRatings
                    }
                    onSave={(hazardRatings) => handleUpdate(hazardRatings)}
                    handleRowValidations={handleRowValidations}
                    newRowPlaceholderValues={placeHolders}
                />
            )}
        </>
    );
};

export default HazardRatingGrid;
