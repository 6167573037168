import React from 'react';
import ToxicSpeciesGrid from './ToxicSpeciesGrid';

const ToxicSpecies = () => {
    return (
        <>
            <ToxicSpeciesGrid />
        </>
    );
};

export default ToxicSpecies;
