import React from 'react';
import BaseGrid from '../../components/grids/BaseGrid';
import DatacorLogoSpinner from '../../components/datacorLogoSpinner/DatacorLogoSpinner';
import useAttributeDefinitionsListOptionsGrid, {
    AttributeDefinitionsListOptionsGridProps,
} from './hooks/useAttributeDefinitionsListOptionsGrid';

const AttributeDefinitionsListOptionsGrid = (
    props: AttributeDefinitionsListOptionsGridProps
) => {
    const { gridOptions, pickListColDefs, handleGridEdits } =
        useAttributeDefinitionsListOptionsGrid({
            attributeDefinition: props.attributeDefinition,
            updateOptionList: props.updateOptionList,
        });

    return !pickListColDefs ? (
        <DatacorLogoSpinner />
    ) : (
        <>
            <BaseGrid
                isEditable={true}
                displayToolbar={true}
                displayCreateNewButton={true}
                createRow={gridOptions && gridOptions.length === 0}
                displayGrid={true}
                rowData={gridOptions}
                columnDefs={pickListColDefs}
                onHandleGridEdits={(records: any) => handleGridEdits(records)}
                newRowPlaceholderValues={{
                    option: 'Enter Option...',
                }}
            />
        </>
    );
};

export default AttributeDefinitionsListOptionsGrid;
