import useSdsPropertyGrid from './hooks/useSdsPropertyGrid';
import React from 'react';
import MultiSelectGridModal from '../../../../../../../../components/modals/MultiSelectGridModal';
import {
    FormulaProperty,
    SdsHeader,
    SdsProperty,
} from '../../../../../../../../types/formulation';
import { isNilOrEmpty } from '../../../../../../../../utils/objectUtils';
import DatacorLogoSpinner from '../../../../../../../../components/datacorLogoSpinner/DatacorLogoSpinner';
import BaseGrid from '../../../../../../../../components/grids/BaseGrid';
import FormPartial from '../../../../../../../../components/form/formContainer/FormPartial';
import TranslatableText from '../../../../../../../../components/i18n/TranslatableText';
import { SDSFormDefs } from '../../../../../../../../constants/i18n/translations/termDefinitions/formulation';
import { ChildEditableGridWithCopyFunctionProps } from '../../../../../../../grid/utils/editableGrid/ChildEditableGridWithCopyFunctionProps';

const SdsPropertiesGrid = (
    props: ChildEditableGridWithCopyFunctionProps<SdsHeader, SdsProperty>
) => {
    const {
        handleGridEdits,
        rowsData,
        formulaPropertyList,
        showMultiSelectGridModal,
        setShowMultiSelectGridModal,
        handleSelectedRecords,
        sdsPropertiesColDef,
        formulaPropertyColumnDefs,
        handleRowValidations,
        colDefOptions,
        setFilteredFormulaPropertyList,
        termSet,
    } = useSdsPropertyGrid(props);

    const { isParentLoading } = props.parentData;

    const { showAddButton, showDeleteButton, showSortButton, isGridEditable } =
        props.displayGridButtons;

    const MultiSelectModal = () => {
        return (
            <React.Fragment>
                <MultiSelectGridModal
                    title={
                        (
                            <TranslatableText
                                termSet={termSet}
                                termKey={SDSFormDefs.Properties_Selection_Text}
                            />
                        ) as unknown as string
                    }
                    open={showMultiSelectGridModal}
                    onClose={() => setShowMultiSelectGridModal(false)}
                    onSelected={(selectedRecords: FormulaProperty[]) =>
                        handleSelectedRecords(selectedRecords)
                    }
                    rowData={formulaPropertyList || []}
                    columnDefs={formulaPropertyColumnDefs}
                    preSelectedIds={() => {
                        return rowsData?.map(
                            (propertyRecords: any) =>
                                propertyRecords.propertyCode
                        );
                    }}
                />
            </React.Fragment>
        );
    };

    return (
        <React.Fragment>
            {isParentLoading ||
            isNilOrEmpty(sdsPropertiesColDef) ||
            isNilOrEmpty(formulaPropertyColumnDefs) ? (
                <DatacorLogoSpinner />
            ) : (
                <>
                    <FormPartial
                        title={
                            (
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={
                                        SDSFormDefs.Information_On_Basic_Physical
                                    }
                                />
                            ) as unknown as string
                        }>
                        <MultiSelectModal />
                        <BaseGrid
                            autoSize={true}
                            displayGrid={true}
                            displayCreateNewButton={showAddButton}
                            useRowDrag={showSortButton}
                            displaySortButton={showSortButton}
                            sortKey={'sortBy'}
                            rowData={rowsData || []}
                            dataKey={'rowId'}
                            gridClass="full-width-grid"
                            displayToolbar={true}
                            sizeColumnsToFit={true}
                            onHandleGridEdits={handleGridEdits}
                            onCreateNew={() => {
                                setFilteredFormulaPropertyList();
                                setShowMultiSelectGridModal(true);
                            }}
                            isEditable={isGridEditable}
                            columnDefs={sdsPropertiesColDef}
                            displayDeleteButton={showDeleteButton}
                            gridButtonTitle={'Properties'}
                            newRowPlaceholderValues={{
                                propertyValue: 'Enter Property Value...',
                                propertyCode: 'Enter Property Code...',
                                propertyUnitId: 'Enter UOM...',
                                sortBy: 'Enter Sort Order',
                            }}
                            createRow={false}
                            onHandleRowValidations={handleRowValidations}
                            defaultColDef={colDefOptions}></BaseGrid>
                    </FormPartial>
                </>
            )}
        </React.Fragment>
    );
};

export default SdsPropertiesGrid;
