import React from 'react';
import BaseFormGrid from '../../components/grids/BaseFormGrid';
import { cloneDeep } from 'lodash';
import useSupplierGrid from './hooks/useSupplierGrid';
import { isNilOrEmpty } from '../../utils/objectUtils';
import booleanCellRenderer from '../../components/grids/cellRenderers/booleanCell.renderer';

const SupplierGrid = () => {
    const { rowData, colDefs, canView, canCreate, redirectToForm } =
        useSupplierGrid();

    return (
        <BaseFormGrid
            title="Suppliers"
            displayGrid={canView}
            isLoading={isNilOrEmpty(colDefs)}
            rowData={cloneDeep(rowData)}
            useRowDrag={false}
            sortKey={'sortOrder'}
            columnDefs={colDefs}
            gridClass="full-size-item"
            displayToolbar={true}
            displayCreateNewButton={canCreate}
            displayExportDataButton={true}
            displayResetStateButton={true}
            onCreateNew={redirectToForm}
            onRowClicked={({ data }) => redirectToForm(data)}
            frameworkComponents={{
                booleanCellRenderer: booleanCellRenderer,
            }}
        />
    );
};

export default SupplierGrid;
