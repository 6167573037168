import { HazardClassification } from '../../../types/formulation';
import { datacorAppApi } from '../../datacorAppApi';

const serviceUtil: string = 'formulation/hazard-classification';

export const hazardClassificationApi = datacorAppApi.injectEndpoints({
    endpoints: (build) => ({
        getHazardClassification: build.query<HazardClassification, string>({
            query: (id) => {
                return {
                    url: `${serviceUtil}/${id}`,
                };
            },
            providesTags: ['HazardClassification'],
        }),
        listHazardClassifications: build.query<HazardClassification[], void>({
            query: () => serviceUtil,
            providesTags: ['HazardClassification'],
        }),
    }),
});

export const {
    useGetHazardClassificationQuery,
    useListHazardClassificationsQuery,
} = hazardClassificationApi;
