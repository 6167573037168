import React from 'react';
import CasGrid from './CasGrid';

const CasMaster = () => {
    return (
        <>
            <CasGrid />
        </>
    );
};

export default CasMaster;
