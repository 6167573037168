import { useEffect, useState } from 'react';
import { VALUE_TYPES } from '../../../constants/attributes/attributes.constants';
import { isJsonString, isNilOrEmpty } from '../../../utils/objectUtils';

const CLEAN_FIELDS: any = {
    TEXT: '',
    NUM: '',
    BOOL: false,
    LIST: [],
    RANGE: { low: '0', high: '1000' },
    TOL: { input: '0', lowT: '0', highT: '1000' },
    DEFAULT: null,
};

const useRenderDefaultValue = (props: any) => {
    const { value, attrValueTypeId, setIsAttributeValid } = props;
    const [inputValue, setInputValue] = useState(null);

    useEffect(() => {
        handleCleanFields();
    }, [attrValueTypeId]);

    useEffect(() => {
        handleSetFieldValues();
    }, [value]);

    const [errorMessage, setErrorMessage] = useState('');

    //RANGE VALIDATOR
    useEffect(() => {
        const numValueLow = parseInt(inputValue?.low);
        const numValueHigh = parseInt(inputValue?.high);

        handleSetErrorMessage(numValueLow, numValueHigh, VALUE_TYPES.RANGE);
    }, [inputValue?.low, inputValue?.high]);

    //TOL VALIDATOR
    useEffect(() => {
        const numValueLow = parseInt(inputValue?.lowT);
        const numValueHigh = parseInt(inputValue?.highT);

        handleSetErrorMessage(numValueLow, numValueHigh, VALUE_TYPES.TOL);
    }, [inputValue?.lowT, inputValue?.highT]);

    const handleSetErrorMessage = (
        numLow: number,
        numHigh: number,
        type: string
    ) => {
        if (attrValueTypeId === type && numLow >= numHigh) {
            setErrorMessage('Low range cannot exceed High range.');
            if (setIsAttributeValid) {
                props.formMethods.setIsFormValid(false);
                setIsAttributeValid(false);
            }
        } else {
            setErrorMessage(null);
            if (setIsAttributeValid) {
                if (
                    isNilOrEmpty(props.fieldValidationErrors) ||
                    props.fieldValidationErrors.size === 0
                ) {
                    props.formMethods.setIsFormValid(true);
                }
                setIsAttributeValid(true);
            }
        }
    };

    // handles cleaning the fields when switching between different value types
    const handleCleanFields = () => {
        switch (attrValueTypeId) {
            case VALUE_TYPES.TEXT:
                setInputValue(CLEAN_FIELDS.TEXT);
                break;
            case VALUE_TYPES.NUM:
                setInputValue(CLEAN_FIELDS.NUM);
                break;
            case VALUE_TYPES.BOOL:
                setInputValue(CLEAN_FIELDS.BOOL);
                break;
            case VALUE_TYPES.LIST:
                setInputValue(CLEAN_FIELDS.LIST);
                break;
            case VALUE_TYPES.RANGE:
                setInputValue(CLEAN_FIELDS.RANGE);
                break;
            case VALUE_TYPES.TOL:
                setInputValue(CLEAN_FIELDS.TOL);
                break;
            default:
                setInputValue(CLEAN_FIELDS.DEFAULT);
        }
    };
    //ensure the values are formatted properly when loading the fields
    const handleSetFieldValues = () => {
        switch (attrValueTypeId) {
            case VALUE_TYPES.TEXT:
                setInputValue(value);
                break;
            case VALUE_TYPES.NUM:
                setInputValue(value);
                break;
            case VALUE_TYPES.BOOL:
                setInputValue(value);
                break;
            case VALUE_TYPES.LIST:
                if (value === 0 || !isJsonString(JSON.stringify(value))) {
                    setInputValue([]);
                } else {
                    const setListDefaults =
                        isJsonString(value) && value
                            ? JSON.parse(value)
                            : value;
                    setInputValue(setListDefaults);
                }

                break;
            case VALUE_TYPES.RANGE:
                setInputValue(isJsonString(value) ? JSON.parse(value) : value);
                break;
            case VALUE_TYPES.TOL:
                setInputValue(isJsonString(value) ? JSON.parse(value) : value);
                break;
            default:
                setInputValue('');
        }
    };

    return { inputValue, errorMessage, setErrorMessage };
};

export default useRenderDefaultValue;
