import React from 'react';
import TreeView from '../../../components/treeView/TreeView';
import LoaderButton from '../../../components/LoaderButton';
import { isNil } from '../../../utils/objectUtils';
import './PermissionTree.css';
import DatacorLogoSpinner from '../../../components/datacorLogoSpinner/DatacorLogoSpinner';
import { Card, CardContent } from '@mui/material';
import usePermissionTree from '../hooks/usePermissionTree';

interface PermissionTreeProps {
    roleId: string;
}

const PermissionTree = (props: PermissionTreeProps) => {
    const {
        roleDetails,
        treeData,
        onCheckChange,
        savingPermissionChanges,
        handleSubmit,
        onSubmit,
        isRoleSelected,
    } = usePermissionTree(props);
    return (
        <>
            {roleDetails ? (
                <Card>
                    <CardContent>
                        <div className="permissionTreePanel">
                            <TreeView
                                caption="Permissions"
                                dataSource={treeData}
                                onCheckChange={onCheckChange}
                                data-testid="permissionTree"
                            />
                        </div>
                        <LoaderButton
                            type="submit"
                            size="large"
                            variant="contained"
                            color="primary"
                            isLoading={savingPermissionChanges}
                            onClick={handleSubmit(onSubmit)}
                            disabled={isNil(treeData)}>
                            Update
                        </LoaderButton>
                    </CardContent>
                </Card>
            ) : (
                <>{isRoleSelected && <DatacorLogoSpinner />}</>
            )}
        </>
    );
};

export default PermissionTree;
