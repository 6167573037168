import React from 'react';
import ToxicTestGrid from './ToxicTestGrid';

const ToxicTest = () => {
    return (
        <>
            <ToxicTestGrid />
        </>
    );
};

export default ToxicTest;
