import React, { useContext } from 'react';
import { ColDef } from 'ag-grid-community';
import MultiSelectGridModal from '../../../../../components/modals/MultiSelectGridModal';
import { FormulaProperty } from '../../../../../types/formulation';
import CustomHeader from '../../../../../components/grids/CustomHeader';
import { CasMasterFormDefs } from '../../../../../constants/i18n/translations/termDefinitions/formulation';
import TranslatableText from '../../../../../components/i18n/TranslatableText';
import SettingsContext from '../../../../../contexts/settings.context';
import { useGetTermSetQuery } from '../../../../../services/i18n/i18n.service';
import { FORMULATION_DEFS } from '../../../../../constants/i18n/translations/termSetDefinitions/formulation';
import { skipToken } from '@reduxjs/toolkit/query';

interface FormulaPropertiesSelectionModalProps {
    openModal: boolean;
    onClose: () => void;
    onSelected: (selectedRows: FormulaProperty[]) => void;
    formulaPropertyList: FormulaProperty[];
}

const FormulaPropertiesSelectionModal = (
    props: FormulaPropertiesSelectionModalProps
) => {
    const { settings } = useContext(SettingsContext);

    const { data: termSet } = useGetTermSetQuery(
        settings?.userSettings
            ? {
                  languageId: settings?.userSettings?.languageId,
                  code: FORMULATION_DEFS.CAS_MASTER_FORM,
              }
            : skipToken
    );
    const columnDefs: ColDef[] = [
        {
            field: 'propertyCode',
            headerComponentFramework: (props: any) => {
                return (
                    <TranslatableText
                        termSet={termSet}
                        termKey={
                            CasMasterFormDefs.Property_Selection_Property_Code
                        }
                    />
                );
            },
            cellClass: 'ag-left-aligned-cell',
        },
        {
            field: 'description',
            headerComponentFramework: (props: any) => {
                return (
                    <TranslatableText
                        termSet={termSet}
                        termKey={
                            CasMasterFormDefs.Property_Selection_Property_Name
                        }
                    />
                );
            },
            cellClass: 'ag-left-aligned-cell',
        },
        {
            field: 'defaultValue',
            headerComponentFramework: (props: any) => {
                return (
                    <TranslatableText
                        termSet={termSet}
                        termKey={
                            CasMasterFormDefs.Property_Selection_Default_Value
                        }
                    />
                );
            },
            cellClass: 'ag-left-aligned-cell',
        },
    ];

    return (
        <MultiSelectGridModal
            title={
                (
                    <TranslatableText
                        termSet={termSet}
                        termKey={
                            CasMasterFormDefs.Property_Properties_Selection
                        }
                    />
                ) as unknown as string
            }
            columnDefs={columnDefs}
            open={props.openModal}
            onClose={props.onClose}
            onSelected={props.onSelected}
            rowData={props.formulaPropertyList}
            preSelectedIds={() => []}
        />
    );
};

export default FormulaPropertiesSelectionModal;
