import React, { useEffect, useState } from 'react';
import useBaseForm from '../../form/hooks/useBaseForm';
import {
    AttributeTemplate,
    useCreateAttributeTemplateMutation,
    useDeleteAttributeTemplateMutation,
    useGetAttributeEntitiesForDropdownQuery,
    useGetAttributeTemplateQuery,
    useLockAttributeTemplateMutation,
    useUpdateAttributeTemplateMutation,
} from '../../../services/attributes/attributes.service';
import { skipToken } from '@reduxjs/toolkit/query';
import { cloneDeep, isEqual } from 'lodash';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { useLocation } from 'react-router-dom';
import { isNilOrEmpty } from '../../../utils/objectUtils';
import { PermissionsUtil } from '../../../utils/permissions/permissionsUtil';
import { PERMISSIONS } from '../../../constants/permissions/Permissions.constants';

const useAttributeTemplatesForm = (id: string) => {
    const user = useSelector((state: RootState) => state.user);

    let location = useLocation();

    const { data: attributeTemplate, isLoading: isLoadingTemplate } =
        useGetAttributeTemplateQuery(id ? id : skipToken);

    const { data: attrEntities, isLoading: isLoadingEntities } =
        useGetAttributeEntitiesForDropdownQuery();

    const [createAttributeTemplate] = useCreateAttributeTemplateMutation();
    const [updateAttributeTemplate] = useUpdateAttributeTemplateMutation();
    const [deleteAttributeTemplate] = useDeleteAttributeTemplateMutation();

    const [lockAttributeTemplate] = useLockAttributeTemplateMutation();

    const blankAttributeTemplate: AttributeTemplate = {
        code: '',
        name: '',
        locked: false,
        businessEntityId: user?.businessEntity?.id,
        attrEntityId: location?.state?.attrEntityId,
        attrDefinitions: [],
    };

    const canManageAttribute = PermissionsUtil.isPermissionEnabled(
        user?.permissions,
        PERMISSIONS.SETUP.ATTRIBUTES.MANAGE
    );

    const {
        fields,
        handleFieldChange,
        handleChildrenRecords,
        formMethods,
        onCreate,
        onUpdate,
        onDelete,
        onLock,
        closeForm,
    } = useBaseForm({
        closePath: `/setup/attributes/templates?${
            attributeTemplate?.attrEntityId || location?.state?.attrEntityId
        }`,
        blankEntity: blankAttributeTemplate,
        activeEntity: attributeTemplate,
        getDescription: () => {
            return `Attribute ${fields.code}`;
        },
        createEntity: () => {
            const defsToAdd = fields.attrDefinitions.map((def: any) => {
                if (!isNilOrEmpty(def?.code?.id)) {
                    return def.code.id;
                } else {
                    return def.id;
                }
            });
            return createAttributeTemplate({
                postBody: { ...fields, attrDefinitions: defsToAdd },
            });
        },
        updateEntity: () => {
            let defsToAdd = null;
            if (
                !isEqual(
                    attributeTemplate.attrDefinitions,
                    fields.attrDefinitions
                )
            ) {
                const parsedDefs = fields.attrDefinitions.map((def: any) => {
                    if (!isNilOrEmpty(def?.code?.id)) {
                        return def.code.id;
                    } else {
                        return def.id;
                    }
                });

                defsToAdd = parsedDefs;
            }

            return updateAttributeTemplate({
                id: id as unknown as number,
                postBody: { ...fields, attrDefinitions: defsToAdd },
            });
        },
        deleteEntity: () => {
            return deleteAttributeTemplate(id);
        },
        lockEntity: () => {
            return lockAttributeTemplate(id);
        },
    });

    return {
        fields,
        handleChildrenRecords,
        handleFieldChange,
        formMethods,
        isLoadingTemplate,
        onCreate,
        onUpdate,
        onDelete,
        onLock,
        closeForm,
        attrEntities,
        isLoadingEntities,
        canManageAttribute,
    };
};

export default useAttributeTemplatesForm;
