import DatacorLogoSpinner from '../../../../../../components/datacorLogoSpinner/DatacorLogoSpinner';
import React from 'react';
import FormPartial from '../../../../../../components/form/formContainer/FormPartial';
import SubSection12_1Form from './subSections/subSection12_1/SubSection12_1Form';
import SubSection12_2Form from './subSections/subSection12_2/SubSection12_2Form';
import SubSection12_3Form from './subSections/subSection12_3/SubSection12_3Form';
import SubSection12_4Form from './subSections/subSection12_4/SubSection12_4Form';
import SubSection12_5Form from './subSections/subSection12_5/SubSection12_5Form';
import SubSection12_6Form from './subSections/subSection12_6/SubSection12_6Form';
import SubSection12_7Form from './subSections/subSection12_7/SubSection12_7Form';
import SdsEcoToxicityGrid from './subSections/subSection12_1/SdsEcoToxicityGrid';
import { SdsEcoToxicity, SdsHeader } from '../../../../../../types/formulation';
import { SdsBlobSectionFormProps } from '../shared/blobs/hooks/SdsBlobSectionFormProps';
import TranslatableText from '../../../../../../components/i18n/TranslatableText';
import { SDSFormDefs } from '../../../../../../constants/i18n/translations/termDefinitions/formulation';
import { ChildEditableGridWithCopyFunctionProps } from '../../../../../grid/utils/editableGrid/ChildEditableGridWithCopyFunctionProps';

const SdsEcologicalInformationForm = (
    props: ChildEditableGridWithCopyFunctionProps<SdsHeader, SdsEcoToxicity> &
        SdsBlobSectionFormProps<SdsHeader>
) => {
    const { isParentLoading } = props.parentData;
    return (
        <>
            {isParentLoading ? (
                <DatacorLogoSpinner />
            ) : (
                <>
                    <FormPartial
                        link={props?.link}
                        title={
                            (
                                <TranslatableText
                                    termSet={props.termSet}
                                    termKey={SDSFormDefs.Ecological_Information}
                                />
                            ) as unknown as string
                        }>
                        <SubSection12_1Form
                            {...props}
                            termSet={props.termSet}
                        />
                        <SdsEcoToxicityGrid {...props} />
                        <SubSection12_2Form
                            {...props}
                            termSet={props.termSet}
                        />
                        <SubSection12_3Form
                            {...props}
                            termSet={props.termSet}
                        />
                        <SubSection12_4Form
                            {...props}
                            termSet={props.termSet}
                        />
                        <SubSection12_5Form
                            {...props}
                            termSet={props.termSet}
                        />
                        <SubSection12_6Form
                            {...props}
                            termSet={props.termSet}
                        />
                        <SubSection12_7Form
                            {...props}
                            termSet={props.termSet}
                        />
                    </FormPartial>
                </>
            )}
        </>
    );
};

export default SdsEcologicalInformationForm;
