import { Grid } from '@mui/material';
import React from 'react';
import TextInput from '../../../../../../../../components/form/formInputs/TextInput/TextInput';
import {
    BlobData,
    SdsBlobSectionFormProps,
} from '../../../shared/blobs/hooks/SdsBlobSectionFormProps';
import {
    SdsHeader,
    SdsSubSection8_2,
} from '../../../../../../../../types/formulation';
import useSdsBlobSectionForm from '../../../shared/blobs/hooks/useSdsBlobSectionForm';
import SdsSubSectionForm from '../../../shared/blobs/components/SdsSubSectionForm';
import { SDSFormDefs } from '../../../../../../../../constants/i18n/translations/termDefinitions/formulation';
import TranslatableText from '../../../../../../../../components/i18n/TranslatableText';

const SubSection8_2Form = (props: SdsBlobSectionFormProps<SdsHeader>) => {
    const { isParentLoading } = props.parentData;

    const { disabledField } = props.permissions;

    const blobData: BlobData<SdsSubSection8_2> = {
        sectionNumber: 8,
        subSectionNumber: 2,
        blankBlobEntity: {
            engineeringControls: '',
            environmentalExposureControls: '',
        },
    };

    const { fields, isBlobLoading, formMethods, handleFieldChange } =
        useSdsBlobSectionForm<SdsHeader, SdsSubSection8_2>({
            ...props,
            blobData,
        });

    return (
        <SdsSubSectionForm
            formTitle={
                (
                    <TranslatableText
                        termSet={props.termSet}
                        termKey={SDSFormDefs.Engineering_Controls_Text}
                    />
                ) as unknown as string
            }
            isFormLoading={isParentLoading || isBlobLoading}
            content={
                <>
                    <Grid item xs={12}>
                        <TextInput
                            fullWidth
                            label={
                                <TranslatableText
                                    termSet={props.termSet}
                                    termKey={SDSFormDefs.Engineering_Controls}
                                />
                            }
                            id="engineeringControls"
                            disabled={disabledField}
                            formMethods={formMethods}
                            value={fields.engineeringControls}
                            onChange={handleFieldChange}
                            maxLength={2000}
                            multiline={true}
                            maxRows={15}
                            minRows={5}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextInput
                            fullWidth
                            label={
                                <TranslatableText
                                    termSet={props.termSet}
                                    termKey={
                                        SDSFormDefs.Environmental_Exposure_Controls
                                    }
                                />
                            }
                            id="environmentalExposureControls"
                            disabled={disabledField}
                            formMethods={formMethods}
                            value={fields.environmentalExposureControls}
                            onChange={handleFieldChange}
                            maxLength={2000}
                            multiline={true}
                            maxRows={15}
                            minRows={5}
                        />
                    </Grid>
                </>
            }
        />
    );
};
export default SubSection8_2Form;
