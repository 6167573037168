import React from 'react';
import useCasExposureGrid from './hooks/useCasExposureGrid';
import { isNilOrEmpty } from '../../../../../../utils/objectUtils';
import DatacorLogoSpinner from '../../../../../../components/datacorLogoSpinner/DatacorLogoSpinner';
import BaseGrid from '../../../../../../components/grids/BaseGrid';
import FormPartial from '../../../../../../components/form/formContainer/FormPartial';
import { ChildEditableGridWithCopyFunctionProps } from '../../../../../grid/utils/editableGrid/ChildEditableGridWithCopyFunctionProps';
import { CasExposure, CasMaster } from '../../../../../../types/formulation';
import { CasMasterFormDefs } from '../../../../../../constants/i18n/translations/termDefinitions/formulation';
import TranslatableText from '../../../../../../components/i18n/TranslatableText';

const CasExposureGrid = (
    props: ChildEditableGridWithCopyFunctionProps<CasMaster, CasExposure>
) => {
    const {
        isLoadingExposureAgencies,
        casExposuresColumnDefs,
        handleGridEdits,
        handleRowValidations,
        colDefOptions,
        termSet,
    } = useCasExposureGrid(props);

    const { isParentLoading } = props.parentData;

    const { showAddButton, showSortButton, isGridEditable } =
        props.displayGridButtons;

    const { rowsData } = props.copyMethods;

    return isLoadingExposureAgencies || isNilOrEmpty(casExposuresColumnDefs) ? (
        <DatacorLogoSpinner />
    ) : (
        <FormPartial
            title={
                (
                    <TranslatableText
                        termSet={termSet}
                        termKey={CasMasterFormDefs.Exposure_Limits}
                    />
                ) as unknown as string
            }>
            <BaseGrid
                autoSize={true}
                displayGrid={!isParentLoading}
                displayCreateNewButton={showAddButton}
                useRowDrag={showSortButton}
                displaySortButton={showSortButton}
                sortKey={'sortOrder'}
                rowData={rowsData || []}
                dataKey={'rowId'}
                defaultColDef={colDefOptions}
                gridClass="full-size-item"
                displayToolbar={true}
                sizeColumnsToFit={true}
                onHandleGridEdits={handleGridEdits}
                isEditable={isGridEditable}
                onHandleRowValidations={handleRowValidations}
                columnDefs={casExposuresColumnDefs}
                //TODO: determine best way to translate
                newRowPlaceholderValues={{
                    exposureAgencyCode: 'Enter an Agency Code...',
                    exposureValue: 'Enter an Exposure Value...',
                    exposureForm: 'Enter an Exposure Form...',
                    notes: 'Enter Notes...',
                    sortOrder: 'Enter Sort Order...',
                }}></BaseGrid>
        </FormPartial>
    );
};

export default CasExposureGrid;
