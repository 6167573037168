import { DepreciationSummaryProps } from '../DepreciationSummaryModal';
import { useForm } from '../../../../libs/hooksLib';
import { useEffect, useState } from 'react';
import { DepreciationSummaryReportParams } from '../../../../types/FixedAsset.types';
import {
    useDepreciationSummaryReportQuery,
    useGetTypesForDropdownQuery,
} from '../../../../services/fixedAssets/fixedAssets.service';
import { skipToken } from '@reduxjs/toolkit/query';
import { SelectionOption } from '../../../../types/Shared.types';
import { isNil, isNilOrEmpty } from '../../../../utils/objectUtils';

const useDepreciationSummaryModal = (props: DepreciationSummaryProps) => {
    const initialFields: any = {
        assetTypes: [],
        startDate: null,
        endDate: null,
    };

    const { fields, handleFieldChange } = useForm(initialFields);

    const [queryParams, setQueryParams] =
        useState<DepreciationSummaryReportParams>(null);

    const { data: assetTypeOptions, isLoading: isLoadingAssetTypes } =
        useGetTypesForDropdownQuery();

    const { data: reportEntryList, isFetching: isLoadingReportEntries } =
        useDepreciationSummaryReportQuery(
            queryParams ? queryParams : skipToken
        );

    const handleGetReportEntries = (event: any) => {
        event.preventDefault();

        const listParams: DepreciationSummaryReportParams = {};

        if (!isNilOrEmpty(fields.assetTypes)) {
            listParams['assetTypeIds'] = fields.assetTypes.map(
                (option: SelectionOption) => option.value
            );
        }

        if (!isNil(fields.startDate)) {
            listParams['startDate'] = fields.startDate;
        }

        if (!isNil(fields.endDate)) {
            listParams['endDate'] = fields.endDate;
        }

        setQueryParams(listParams);
    };

    useEffect(() => {
        if (reportEntryList) {
            props.onDepreciationSummaryLoaded(reportEntryList);
            props.onClose();
        }
    }, [reportEntryList]);

    return {
        fields,
        handleFieldChange,
        assetTypeOptions,
        isLoadingAssetTypes,
        handleGetReportEntries,
        isLoadingReportEntries,
    };
};

export default useDepreciationSummaryModal;
