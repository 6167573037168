import { useContext, useEffect, useState } from 'react';
import { useForm } from '../../../../../../../libs/hooksLib';
import {
    ItemMaster,
    ItemProperShippingName,
} from '../../../../../../../types/formulation';
import { ChildFormProps } from '../../../../../../form/helpers/ChildFormProps';
import {
    useListProperShippingNameDropdownQuery,
    useListUnitOfMeasureDropdownQuery,
} from '../../../../../../../services/organizations/organizations.service';
import { PermissionsUtil } from '../../../../../../../utils/permissions/permissionsUtil';
import { PERMISSIONS } from '../../../../../../../constants/permissions/Permissions.constants';
import SettingsContext from '../../../../../../../contexts/settings.context';
import { useGetTermSetQuery } from '../../../../../../../services/i18n/i18n.service';
import { FORMULATION_DEFS } from '../../../../../../../constants/i18n/translations/termSetDefinitions/formulation';
import { skipToken } from '@reduxjs/toolkit/query';

const useItemProperShippingNameForm = (props: ChildFormProps<ItemMaster>) => {
    const { currentParentRecord, isParentLoading, user, dataFromQuery } =
        props.parentData;

    const { handleChildRecord, registerChildForm } = props.helpers;

    const [properShippingNameDescription, setProperShippingNameDescription] =
        useState('');

    const { data: unitOfMeasureOptions, isLoading: isLoadingUnitOfMeasure } =
        useListUnitOfMeasureDropdownQuery();

    const {
        data: properShippingNameOptions,
        isLoading: isLoadingProperShippingName,
    } = useListProperShippingNameDropdownQuery();

    const canViewProperShippingName = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.SETUP.PROPER_SHIPPING_NAME.VIEW
    );

    const initialState: any = {
        properShippingNameInputValue: '',
        rqUomInputValue: '',
        properShippingNameOptions: [],
        unitOfMeasureOptions: [],
        selectedProperShippingName: null,
        selectedRqUom: null,
    };

    const [dropdownOptions, setDropdownOptions] = useState(initialState);

    const blankItemProperShippingName: ItemProperShippingName = {
        properShippingNameId: null as bigint,
        rqRequirement: null as number,
        rqUom: null as bigint,
        marinePollutant: false,
        freightClass: '',
        nmfc: '',
    };

    const { fields, handleFieldChange, setValues, formMethods, verifyChanges } =
        useForm(blankItemProperShippingName, true);

    const { settings } = useContext(SettingsContext);
    const { data: termSet } = useGetTermSetQuery(
        settings?.userSettings
            ? {
                  languageId: settings?.userSettings?.languageId,
                  code: FORMULATION_DEFS.ITEM_MASTER_FORM,
              }
            : skipToken
    );

    const handlePropertyChanged = (event: any) => {
        handleFieldChange(event);
        handleChildRecord('itemProperShippingName', event);
    };

    const { setOriginalEntity, clearChangesHistory, hasCurrentRecordChanged } =
        verifyChanges.childForm;

    const findProperShippingNameDescription = (
        properShippingNameId: bigint
    ) => {
        let description: any;
        properShippingNameOptions?.find((current: any) => {
            if (current.value === properShippingNameId) {
                description = current.description;
            }
        });
        return description;
    };

    useEffect(() => {
        if (!isParentLoading) {
            setOriginalEntity(
                dataFromQuery?.itemProperShippingName ||
                    blankItemProperShippingName
            );
            setValues(
                currentParentRecord?.itemProperShippingName ||
                    blankItemProperShippingName
            );
        }
    }, [currentParentRecord?.itemProperShippingName, isParentLoading]);

    useEffect(() => {
        if (!isParentLoading) {
            registerChildForm('itemProperShippingName', {
                formMethods,
                hasEntityChanges: hasCurrentRecordChanged,
                clearChangesHistory,
            });
        }
    }, [fields, isParentLoading]);

    useEffect(() => {
        if (
            !isLoadingUnitOfMeasure &&
            !isLoadingProperShippingName &&
            unitOfMeasureOptions &&
            properShippingNameOptions
        ) {
            setDropdownOptions((previousValues: any) => ({
                ...previousValues,
                selectedProperShippingName: properShippingNameOptions?.find(
                    (current: any) =>
                        current.value === fields.properShippingNameId
                ),
                selectedRqUom: unitOfMeasureOptions?.find(
                    (current: any) => current.value === fields.rqUom
                ),
            }));
            setProperShippingNameDescription(
                findProperShippingNameDescription(fields.properShippingNameId)
            );
        }
    }, [
        unitOfMeasureOptions,
        isLoadingUnitOfMeasure,
        isLoadingProperShippingName,
        properShippingNameOptions,
        fields,
    ]);

    return {
        fields,
        handlePropertyChanged,
        properShippingNameOptions,
        isLoadingProperShippingName,
        dropdownOptions,
        setDropdownOptions,
        properShippingNameDescription,
        unitOfMeasureOptions,
        isLoadingUnitOfMeasure,
        formMethods,
        canViewProperShippingName,
        termSet,
    };
};

export default useItemProperShippingNameForm;
