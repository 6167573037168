import React from 'react';
import UnitOfMeasureGrid from './pages/UnitOfMeasureGrid';

const UnitOfMeasure = () => {
    return (
        <>
            <UnitOfMeasureGrid />
        </>
    );
};

export default UnitOfMeasure;
