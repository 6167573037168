import { ExchangeRatesProps } from '../CurrencyExchangeRateFiltersModal';
import { useContext, useEffect, useState } from 'react';
import SettingsContext from '../../../../contexts/settings.context';
import {
    useGetTermSetQuery,
    useListCurrencyCodesQuery,
    useListCurrencyExchangeRatesQuery,
} from '../../../../services/i18n/i18n.service';
import { ACCOUNTING_DEFS } from '../../../../constants/i18n/translations/termSetDefinitions/accounting';
import { skipToken } from '@reduxjs/toolkit/query';
import { useForm } from '../../../../libs/hooksLib';
import { CurrencyExchangeRateListParams } from '../../../../types/platform/i18n/Currency.type';
import { isNil, isNilOrEmpty } from '../../../../utils/objectUtils';

const useCurrencyExchangeRateFiltersModal = (props: ExchangeRatesProps) => {
    const initialFields: any = {
        sourceCurrencyCode: null,
        targetCurrencyCode: null,
        startDate: null,
        endDate: null,
    };

    const { settings } = useContext(SettingsContext);
    const { data: termSet } = useGetTermSetQuery(
        settings?.userSettings
            ? {
                  languageId: settings?.userSettings?.languageId,
                  code: ACCOUNTING_DEFS.CURRENCY_EXCHANGE_RATE_FILTERS,
              }
            : skipToken
    );
    const { fields, handleFieldChange } = useForm(initialFields);
    const [queryParams, setQueryParams] =
        useState<CurrencyExchangeRateListParams>(null);

    const { data: currencyCodeOptions, isLoading: loadingCurrencyCodes } =
        useListCurrencyCodesQuery();

    const { data: exchangeRateList, isFetching: isLoadingExchangeRates } =
        useListCurrencyExchangeRatesQuery(
            queryParams ? queryParams : skipToken
        );

    const handleGetExchangeRates = (event: any) => {
        event.preventDefault();

        const listParams: CurrencyExchangeRateListParams = {};

        if (!isNilOrEmpty(fields.sourceCurrencyCode)) {
            listParams['sourceCurrencyCode'] = fields.sourceCurrencyCode;
        }
        if (!isNilOrEmpty(fields.targetCurrencyCode)) {
            listParams['targetCurrencyCode'] = fields.targetCurrencyCode;
        }
        if (!isNil(fields.startDate)) {
            listParams['startDate'] = fields.startDate;
        }
        if (!isNil(fields.endDate)) {
            listParams['endDate'] = fields.endDate;
        }

        setQueryParams(listParams);
    };

    useEffect(() => {
        if (exchangeRateList) {
            props.onExchangeRatesLoaded(exchangeRateList);
            props.onClose();
        }
    }, [exchangeRateList]);

    return {
        queryParams,
        fields,
        handleFieldChange,
        currencyCodeOptions,
        loadingCurrencyCodes,
        handleGetExchangeRates,
        isLoadingExchangeRates,
        termSet,
    };
};

export default useCurrencyExchangeRateFiltersModal;
