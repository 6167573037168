import useCurrencyExchangeRateGrid from './hooks/useCurrencyExchangeRateGrid';
import { isNilOrEmpty } from '../../../utils/objectUtils';
import BaseFormGrid from '../../../components/grids/BaseFormGrid';
import React from 'react';
import CurrencyExchangeRateFiltersModal from './CurrencyExchangeRateFiltersModal';
import { Button } from '@mui/material';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import TranslatableText from '../../../components/i18n/TranslatableText';
import { CurrencyExchangeRateGridDefs } from '../../../constants/i18n/translations/termDefinitions/accounting';

const CurrencyExchangeRateGrid = () => {
    const {
        rowData,
        showOptionsModal,
        setShowOptionsModal,
        canViewExchangeRate,
        colDefs,
        setCurrencyExchangeRateList,
        termSet,
    } = useCurrencyExchangeRateGrid();

    return (
        <React.Fragment>
            <CurrencyExchangeRateFiltersModal
                open={showOptionsModal}
                onExchangeRatesLoaded={(exchangeRates) =>
                    setCurrencyExchangeRateList(exchangeRates)
                }
                onClose={() => setShowOptionsModal(false)}
            />

            <BaseFormGrid
                title={
                    (
                        <TranslatableText
                            termKey={CurrencyExchangeRateGridDefs.Title}
                            termSet={termSet}
                        />
                    ) as unknown as string
                }
                displayGrid={canViewExchangeRate}
                isLoading={isNilOrEmpty(colDefs)}
                rowData={rowData}
                columnDefs={colDefs}
                gridClass="full-size-item"
                displayToolbar={true}
                displayExportDataButton={true}
                displayResetStateButton={true}
                toolbarChildren={
                    <Button
                        variant="text"
                        size="small"
                        onClick={() => setShowOptionsModal(true)}
                        startIcon={<FilterAltIcon />}>
                        Filters
                    </Button>
                }
            />
        </React.Fragment>
    );
};

export default CurrencyExchangeRateGrid;
