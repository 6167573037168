import React, { useState } from 'react';
import { Card, CardContent } from '@mui/material';
import MigrationManager from './MigrationManager';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

const DefaultDevArea = () => {
    const [value, setValue] = useState(0);

    const TabPanel = (props: TabPanelProps) => {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}>
                {value === index && (
                    <Box sx={{ p: 3, height: '65vh' }}>{children}</Box>
                )}
            </div>
        );
    };

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    return (
        <Card>
            <CardContent>
                <h3>Datacor Dev Area</h3>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    textColor="inherit"
                    indicatorColor="secondary">
                    <Tab label="View Migrations" />
                </Tabs>
                <TabPanel value={value} index={0}>
                    <MigrationManager />
                </TabPanel>
            </CardContent>
        </Card>
    );
};
//
// Creating this dev area for datacorAdmins only that we can put components
// easily into the UI if they don't exist/have a use-case yet.
//
const DevArea = () => {
    return <DefaultDevArea />;
};

export default DevArea;
