import React from 'react';
import LoaderButton from '../../components/LoaderButton';
import { Company } from '../../types/User.types';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    Box,
    IconButton,
    TextField,
    Grid,
    FormGroup,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import SelectDropdown from '../../components/form/selectDropdown/SelectDropdown';
import useCreateZone from './hooks/useCreateZone';

interface CreateZoneProps {
    show: boolean;
    handleClose: (updateZones: boolean) => void;
}

const CreateZone = ({ show = false, handleClose }: CreateZoneProps) => {
    const {
        fields,
        createZoneState,
        companyList,
        handleFieldChange,
        setCreateZoneState,
        validateForm,
        setZoneId,
    } = useCreateZone({ handleClose });

    const { selectedCompany, isLoading } = createZoneState;

    return (
        <Dialog
            open={show}
            onClose={handleClose}
            data-testid="create-new-zone-modal"
            className="stepInfoModal">
            <DialogTitle>
                <Box display="flex" alignItems="center">
                    <Box flexGrow={1}>Create New Zone</Box>
                    <Box>
                        <IconButton onClick={() => handleClose(false)}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </Box>
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={2} style={{ paddingTop: '10px' }}>
                    <Grid item xs={12}>
                        <TextField
                            size="small"
                            id="id"
                            fullWidth
                            label="Zone ID"
                            data-testid="zoneId"
                            autoFocus
                            value={fields.id}
                            onChange={(
                                event: React.ChangeEvent<HTMLInputElement>
                            ) => setZoneId(event)}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            size="small"
                            fullWidth
                            id="name"
                            label="Zone Name"
                            data-testid="name"
                            value={fields.name}
                            onChange={handleFieldChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormGroup>
                            <SelectDropdown
                                className="basic-select-company"
                                menuPlacement={'bottom'}
                                value={selectedCompany}
                                id="company-id"
                                options={companyList?.map(
                                    (company: Company) => {
                                        return {
                                            value: company.companyID,
                                            label: company.companyID,
                                        };
                                    }
                                )}
                                placeholder={'Company'}
                                onChange={(companyOption: any) => {
                                    setCreateZoneState((prevState) => ({
                                        ...prevState,
                                        companyId: companyOption,
                                        selectedCompany: companyOption,
                                    }));
                                }}
                            />
                        </FormGroup>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            size="small"
                            fullWidth
                            id="url"
                            label="URL"
                            variant="outlined"
                            data-testid="url"
                            value={fields.url}
                            onChange={handleFieldChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            size="small"
                            fullWidth
                            label="Port"
                            id="port"
                            variant="outlined"
                            data-testid="port"
                            value={fields.port}
                            onChange={handleFieldChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <LoaderButton
                            onClick={() =>
                                setCreateZoneState((prevState) => ({
                                    ...prevState,
                                    isSubmitting: true,
                                }))
                            }
                            fullWidth
                            variant="contained"
                            type="submit"
                            size="large"
                            data-testid="createZoneBtn"
                            disabled={!validateForm()}
                            isLoading={isLoading}>
                            Create Zone
                        </LoaderButton>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
};

export default CreateZone;
