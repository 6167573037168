import React, { useContext, useEffect, useState } from 'react';
import { RootStateOrAny, useSelector } from 'react-redux';
import { HazardClassificationMapping } from '../../../../types/formulation';
import { LocaleGroupMapping } from '../../../../types/formulation/shared/HazardClassificationMapping.type';
import DefaultColumnTypes from '../../../../components/grids/columns/Column.constants';
import { ColDef } from 'ag-grid-community';
import { useListLocaleGroupByActiveQuery } from '../../../../services/formulation/localeGroup/localeGroup.service';
import {
    useListHazardClassMappingsQuery,
    useUpdatehazardClassMappingsMutation,
} from '../../../../services/formulation/hazardClassificationMapping/hazardClassificationMapping.service';
import { cloneDeep } from 'lodash';
import useEditableBaseGrid from '../../../grid/hooks/useEditableBaseGrid';
import { FORMULATION_DEFS } from '../../../../constants/i18n/translations/termSetDefinitions/formulation';
import SettingsContext from '../../../../contexts/settings.context';
import { useGetTermSetQuery } from '../../../../services/i18n/i18n.service';
import { skipToken } from '@reduxjs/toolkit/query';
import CustomHeader from '../../../../components/grids/CustomHeader';
import { HazardClassMappingGridDefs } from '../../../../constants/i18n/translations/termDefinitions/formulation';
import { PermissionsUtil } from '../../../../utils/permissions/permissionsUtil';
import { PERMISSIONS } from '../../../../constants/permissions/Permissions.constants';
import applyEditableGridValidations from '../../../grid/utils/applyEditableGridValidations';
import { ColumnMaxValueValidator } from '../../../grid/validators/columnMaxValue.validator';

const useHazardClassificationMappingGrid = () => {
    const user = useSelector((state: RootStateOrAny) => state.user);

    const { isLoading: isLoadingLocaleGrps, data: localeGrpList } =
        useListLocaleGroupByActiveQuery(true);

    const {
        isLoading: isLoadingHazardClassMappings,
        data: hazardClassMappingData,
    } = useListHazardClassMappingsQuery();

    const [updateHazardClassMappings] = useUpdatehazardClassMappingsMutation();

    const { settings } = useContext(SettingsContext);
    const { data: termSet } = useGetTermSetQuery(
        settings?.userSettings
            ? {
                  languageId: settings?.userSettings?.languageId,
                  code: FORMULATION_DEFS.HAZARD_CLASS_MAPPING_GRID,
              }
            : skipToken
    );

    const [columnDefs, setColumnDefs] = useState<ColDef[]>(null);
    const [hazardClassMappingList, setHazardClassMappingList] =
        useState<HazardClassificationMapping[]>(null);

    const canViewHazardClassMappings = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.FORMULATION.HAZARD_CLASS_MAPPING.VIEW
    );

    const canUpdateHazardClassMappings = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.FORMULATION.HAZARD_CLASS_MAPPING.EDIT
    );

    const { onUpdate } = useEditableBaseGrid<HazardClassificationMapping[]>({
        getDescription: () => 'Hazard Class Mappings',
        updateEntity: (mappings) => {
            const postBody = mappings;
            return updateHazardClassMappings({ postBody });
        },
    });

    // Method to create dynamic columns based on active Locale Groups
    const buildLocaleGrpColumns = (): ColDef[] => {
        const localGrpColums: ColDef[] = [];
        localeGrpList
            .filter((current) => current.active === true)
            .forEach((current) => {
                const colDef: ColDef = {
                    field: `${current.groupId}${current.id}Value`, //This should match the respective value property in HazardClassificationMapping objects. Example USA19Value
                    headerName: current.groupId,
                    minWidth: DefaultColumnTypes.MediumText.minWidth,
                    filter: DefaultColumnTypes.MediumText.filter,
                    editable: canUpdateHazardClassMappings,
                };
                localGrpColums.push(colDef);
            });
        return localGrpColums;
    };

    const getGroupIdById = (id: bigint): string => {
        if (localeGrpList && id) {
            const localeGrp = localeGrpList.find(
                (current) => current.id === id
            );
            return localeGrp?.groupId;
        }
        return null;
    };

    const handleUpdate = (records: HazardClassificationMapping[]) => {
        if (records) {
            onUpdate(records);
        }
    };

    const handleRowValidations = (
        editedRows: HazardClassificationMapping[]
    ) => {
        editedRows.forEach((current) => {
            current.validationRules = [];
            current.localeGroupIdList.forEach((groupId) => {
                const valueProp = (current[groupId] as LocaleGroupMapping)
                    .valuePropName;
                const localeGrpId = (current[groupId] as LocaleGroupMapping)
                    .localeGroupId;
                const localeGrpCode = getGroupIdById(localeGrpId);
                current.validationRules.push(
                    ColumnMaxValueValidator(
                        localeGrpCode,
                        20,
                        current[valueProp] as string
                    )
                );
            });
            const message = `Hazard Code ${current.code}`;
            applyEditableGridValidations(current, message);
        });
        return editedRows;
    };

    useEffect(() => {
        if (localeGrpList) {
            const localeGrpColums = buildLocaleGrpColumns();
            setColumnDefs([...staticColumnDefs, ...localeGrpColums]);
        }
    }, [isLoadingLocaleGrps, termSet]);

    //Static colums, this will not change in the grid
    const staticColumnDefs = [
        {
            field: 'code',
            headerComponentFramework: (props: any) => {
                return (
                    <CustomHeader
                        {...props}
                        termSet={termSet}
                        termKey={HazardClassMappingGridDefs.Hazard_Code}
                    />
                );
            },
            minWidth: DefaultColumnTypes.ShortText.minWidth,
            filter: DefaultColumnTypes.ShortText.filter,
            ...DefaultColumnTypes.AutoHeight,
        },
        {
            field: 'description',
            headerComponentFramework: (props: any) => {
                return (
                    <CustomHeader
                        {...props}
                        termSet={termSet}
                        termKey={HazardClassMappingGridDefs.Description}
                    />
                );
            },
            minWidth: DefaultColumnTypes.MediumText.minWidth,
            filter: DefaultColumnTypes.MediumText.filter,
            ...DefaultColumnTypes.AutoHeight,
        },
    ];

    useEffect(() => {
        if (hazardClassMappingData) {
            const list = cloneDeep(hazardClassMappingData).map(
                (current, index) => {
                    current.rowId = index + 1;
                    return current;
                }
            );
            setHazardClassMappingList(list);
        }
    }, [hazardClassMappingData]);

    return {
        columnDefs,
        hazardClassMappingList,
        isLoadingLocaleGrps,
        isLoadingHazardClassMappings,
        termSet,
        canUpdateHazardClassMappings,
        canViewHazardClassMappings,
        handleUpdate,
        handleRowValidations,
    };
};

export default useHazardClassificationMappingGrid;
