import React from 'react';
import { Grid } from '@mui/material';
import { useParams } from 'react-router-dom';
import useContractTypeForm from './hooks/useContractTypeForm';
import FormPartial from '../../components/form/formContainer/FormPartial';
import TextInput from '../../components/form/formInputs/TextInput/TextInput';
import NumericInput from '../../components/form/formInputs/NumericInput/NumericInput';
import BaseForm, { ParamTypes } from '../../components/form/baseForm/BaseForm';
import { NumberMaxValues, RoutingValues } from '../../types/Shared.types';
import { CONTRACT_TYPE_FIELDS } from '../../constants/revisionHistory/revisionHistory.constants';
import { ContractTypesFormDefs } from '../../constants/i18n/translations/termDefinitions/accounting';
import TranslatableText from '../../components/i18n/TranslatableText';

const ContractTypeForm = () => {
    const { id } = useParams<ParamTypes>();
    const {
        fields,
        handleFieldChange,
        formMethods,
        onCreate,
        onUpdate,
        onDelete,
        closeForm,
        isLoadingContractType,
        canCreateContractType,
        canUpdateContractType,
        canDeleteContractType,
        termSet,
    } = useContractTypeForm(id);

    return (
        <BaseForm
            title={
                <TranslatableText
                    termSet={termSet}
                    termKey={ContractTypesFormDefs.Contract_Type}
                />
            }
            formMethods={formMethods}
            isLoading={isLoadingContractType}
            isNew={id === RoutingValues.newId}
            onCreate={onCreate}
            onUpdate={onUpdate}
            onDelete={onDelete}
            onCancel={closeForm}
            showOnRevision={true}
            fieldMappings={CONTRACT_TYPE_FIELDS}
            entityUuid={fields?.uuid}
            displayCreateButton={canCreateContractType}
            displayUpdateButton={canUpdateContractType}
            displayDeleteButton={canDeleteContractType}>
            <FormPartial>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <TextInput
                            fullWidth
                            label={
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={ContractTypesFormDefs.Code}
                                />
                            }
                            id="code"
                            value={fields.code}
                            onChange={handleFieldChange}
                            formMethods={formMethods}
                            required={true}
                            maxLength={30}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <NumericInput
                            fullWidth
                            label={
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={ContractTypesFormDefs.Sort_Order}
                                />
                            }
                            id="sortOrder"
                            value={fields.sortOrder}
                            onChange={handleFieldChange}
                            formMethods={formMethods}
                            required={true}
                            maxValue={NumberMaxValues.smallint}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextInput
                            fullWidth
                            label={
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={ContractTypesFormDefs.Description}
                                />
                            }
                            id="description"
                            value={fields.description}
                            onChange={handleFieldChange}
                            formMethods={formMethods}
                            maxLength={255}
                        />
                    </Grid>
                </Grid>
            </FormPartial>
        </BaseForm>
    );
};

export default ContractTypeForm;
