import { FormType } from '../../../../types/formulation';
import { datacorAppApi } from '../../../datacorAppApi';

const serviceUrl: string = 'formType';

export const formTypeApi = datacorAppApi.injectEndpoints({
    endpoints: (build) => ({
        getFormTypesForDropdown: build.query<
            { label: string; value: bigint }[],
            void
        >({
            query: () => serviceUrl,
            transformResponse: (response: FormType[]) => {
                return response.map((formType: FormType) => {
                    return {
                        label: formType.type,
                        value: formType.id,
                    };
                });
            },
            providesTags: ['FormType'],
        }),
    }),
});

export const { useGetFormTypesForDropdownQuery } = formTypeApi;
