import { useForm } from '../../../../libs/hooksLib';
import { ReportAcquisitionProps } from '../AcquisitionOptionsModal';
import { useAcquisitionReportQuery } from '../../../../services/fixedAssets/fixedAssets.service';
import { AcquisitionsParams } from '../../../../types/FixedAsset.types';
import { isNil } from '../../../../utils/objectUtils';
import { skipToken } from '@reduxjs/toolkit/query';
import { useEffect, useState } from 'react';

const useAcquisitionOptionsModal = (props: ReportAcquisitionProps) => {
    const inicialFields: any = {
        acquireBeginDate: null,
        acquireEndDate: null,
    };

    const { fields, handleFieldChange } = useForm(inicialFields);
    const [queryParams, setQueryParams] = useState<AcquisitionsParams>(null);

    const { data: assetAcquisitionList, isFetching: isLoadingAcquisitions } =
        useAcquisitionReportQuery(queryParams ? queryParams : skipToken);

    const handleGetAcquisitions = (event: any) => {
        event.preventDefault();

        const listParams: AcquisitionsParams = {};

        if (!isNil(fields.acquireBeginDate)) {
            listParams['acquireBeginDate'] = fields.acquireBeginDate;
        }
        if (!isNil(fields.acquireEndDate)) {
            listParams['acquireEndDate'] = fields.acquireEndDate;
        }

        setQueryParams(listParams);
    };

    useEffect(() => {
        if (assetAcquisitionList) {
            props.onAcquisitionsLoaded(assetAcquisitionList);
            setQueryParams(null);
            props.onClose();
        }
    }, [assetAcquisitionList]);

    return {
        queryParams,
        fields,
        handleFieldChange,
        handleGetAcquisitions,
        isLoadingAcquisitions,
    };
};

export default useAcquisitionOptionsModal;
