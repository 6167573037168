import React from 'react';
import useFixedAssetGrid, {
    FixedAssetGridMode,
} from './hooks/useFixedAssetGrid';
import BaseFormGrid from '../../../components/grids/BaseFormGrid';
import { ColDef, RowClickedEvent } from 'ag-grid-community';
import DefaultColumnTypes from '../../../components/grids/columns/Column.constants';
import FixedAssetCreateScheduleStepper from './modals/FixedAssetCreateScheduleStepper';
import FixedAssetCreateDepreciationEntriesModal from './modals/FixedAssetCreateDepreciationEntriesModal';
import UploadGridModal from '../../../components/modals/UploadGridModal';
import useFixedAssetImport from './hooks/useFixedAssetImport';
import FixedAssetCreateAssetEntriesModal from './modals/FixedAssetCreateAssetEntriesModal';
import FixedAssetCreateDisposalEntriesModal from './modals/FixedAssetCreateDisposalEntriesModal';
import { FixedAssetRegisterGridDefs } from '../../../constants/i18n/translations/termDefinitions/accounting';
import TranslatableText from '../../../components/i18n/TranslatableText';
import CustomHeader from '../../../components/grids/CustomHeader';

const FixedAssetGrid = () => {
    const {
        assetList,
        isLoadingAssets,
        redirectToForm,
        onSelectionChanged,
        assetToolbar,
        gridMode,
        handleCreated,
        selectedIdList,
        scheduleStartDate,
        showCreateScheduleModal,
        setShowCreateScheduleModal,
        showCreateEntryModal,
        setShowCreateEntryModal,
        showCreateDisposalModal,
        setShowCreateDisposalModal,
        showCreateAssetEntryModal,
        setShowCreateAssetEntryModal,
        canCreateAssetRecords,
        canViewAssetRecords,
        canImportAssetRecords,
        termSet,
    } = useFixedAssetGrid();

    const {
        importList,
        showImportDataModal,
        isImportingAssets,
        isValidatingAssets,
        handleModalOpen,
        handleModalClose,
        handleUploadImport,
        handleImportAssets,
        handleValidateAssets,
    } = useFixedAssetImport();

    const assetColumnDefs: ColDef[] = [
        {
            headerName: 'Selected',
            headerCheckboxSelection: true,
            hide: gridMode === FixedAssetGridMode.Normal,
            ...DefaultColumnTypes.CheckboxSelection,
        },
        {
            field: 'number',
            headerComponentFramework: (props: any) => {
                return (
                    <CustomHeader
                        {...props}
                        termSet={termSet}
                        termKey={FixedAssetRegisterGridDefs.AssetNumber}
                    />
                );
            },
            ...DefaultColumnTypes.NumberOnly,
        },
        {
            field: 'code',
            headerComponentFramework: (props: any) => {
                return (
                    <CustomHeader
                        {...props}
                        termSet={termSet}
                        termKey={FixedAssetRegisterGridDefs.AssetCode}
                    />
                );
            },
            ...DefaultColumnTypes.ShortText,
        },
        {
            field: 'name',
            headerComponentFramework: (props: any) => {
                return (
                    <CustomHeader
                        {...props}
                        termSet={termSet}
                        termKey={FixedAssetRegisterGridDefs.AssetName}
                    />
                );
            },
            ...DefaultColumnTypes.MediumText,
        },
        {
            field: 'description',
            headerComponentFramework: (props: any) => {
                return (
                    <CustomHeader
                        {...props}
                        termSet={termSet}
                        termKey={FixedAssetRegisterGridDefs.Description}
                    />
                );
            },
            ...DefaultColumnTypes.LongText,
        },
        {
            field: 'assetStatus.code',
            headerComponentFramework: (props: any) => {
                return (
                    <CustomHeader
                        {...props}
                        termSet={termSet}
                        termKey={FixedAssetRegisterGridDefs.AssetStatus}
                    />
                );
            },
            ...DefaultColumnTypes.ShortText,
        },
        {
            field: 'ledgerAssetType.code',
            headerComponentFramework: (props: any) => {
                return (
                    <CustomHeader
                        {...props}
                        termSet={termSet}
                        termKey={FixedAssetRegisterGridDefs.AssetTypeBook}
                    />
                );
            },
            ...DefaultColumnTypes.ShortText,
        },
        {
            field: 'taxAssetType.code',
            headerComponentFramework: (props: any) => {
                return (
                    <CustomHeader
                        {...props}
                        termSet={termSet}
                        termKey={FixedAssetRegisterGridDefs.AssetTypeTax}
                    />
                );
            },
            ...DefaultColumnTypes.ShortText,
        },
        {
            field: 'building.code',
            headerComponentFramework: (props: any) => {
                return (
                    <CustomHeader
                        {...props}
                        termSet={termSet}
                        termKey={FixedAssetRegisterGridDefs.Building}
                    />
                );
            },
            ...DefaultColumnTypes.MediumText,
        },
        {
            field: 'acquireDate',
            headerComponentFramework: (props: any) => {
                return (
                    <CustomHeader
                        {...props}
                        termSet={termSet}
                        termKey={FixedAssetRegisterGridDefs.AcquireDate}
                    />
                );
            },
            ...DefaultColumnTypes.DateOnly,
        },
        {
            field: 'inServiceDate',
            headerComponentFramework: (props: any) => {
                return (
                    <CustomHeader
                        {...props}
                        termSet={termSet}
                        termKey={FixedAssetRegisterGridDefs.InServiceDate}
                    />
                );
            },
            ...DefaultColumnTypes.DateOnly,
        },
        {
            field: 'totalCost',
            headerComponentFramework: (props: any) => {
                return (
                    <CustomHeader
                        {...props}
                        termSet={termSet}
                        termKey={FixedAssetRegisterGridDefs.TotalCost}
                    />
                );
            },
            ...DefaultColumnTypes.Currency,
        },
    ];

    const assetExportDefs: ColDef[] = [
        {
            field: 'code',
            headerName: 'Asset Code',
            headerTooltip: 'Text Field',
            cellRenderer: 'agGroupCellRenderer',
            ...DefaultColumnTypes.ShortText,
        },
        {
            field: 'name',
            headerName: 'Asset Name',
            headerTooltip: 'Text Field',
            ...DefaultColumnTypes.MediumText,
        },
        {
            field: 'description',
            headerName: 'Description',
            headerTooltip: 'Text Field',
            ...DefaultColumnTypes.LongText,
        },
        {
            field: 'assetStatus.code',
            headerName: 'Asset Status',
            headerTooltip: 'Text Field',
            colId: 'assetStatus',
            ...DefaultColumnTypes.ShortText,
        },
        {
            field: 'ledgerAssetType.code',
            headerName: 'Asset Type (Book)',
            headerTooltip: 'Text Field',
            colId: 'ledgerAssetType',
            ...DefaultColumnTypes.ShortText,
        },
        {
            field: 'taxAssetType.code',
            headerName: 'Asset Type (Tax)',
            headerTooltip: 'Text Field',
            colId: 'taxAssetType',
            ...DefaultColumnTypes.ShortText,
        },
        {
            field: 'acquireDate',
            headerName: 'Acquire Date',
            headerTooltip: 'Date Format YYYY-MM-DD',
            type: 'dateColumn',
            ...DefaultColumnTypes.DateOnly,
        },
        {
            field: 'inServiceDate',
            headerName: 'In Service Date',
            headerTooltip: 'Date Format YYYY-MM-DD',
            type: 'dateColumn',
            ...DefaultColumnTypes.DateOnly,
        },
        {
            field: 'buildingId',
            headerName: 'Building',
            headerTooltip: 'Text Field',
            ...DefaultColumnTypes.MediumText,
        },
        {
            field: 'manufacturerSerialNumber',
            headerName: 'Manufacturer Serial #',
            headerTooltip: 'Text Field',
            ...DefaultColumnTypes.MediumText,
        },
        {
            field: 'purchaseOrderNumber',
            headerName: 'Purchase Order #',
            headerTooltip: 'Text Field',
            ...DefaultColumnTypes.MediumText,
        },
        {
            field: 'supplierInvoiceNumber',
            headerName: 'Supplier Invoice #',
            headerTooltip: 'Text Field',
            ...DefaultColumnTypes.MediumText,
        },
        {
            field: 'capitalProjectNumber',
            headerName: 'Capital Project #',
            headerTooltip: 'Text Field',
            ...DefaultColumnTypes.MediumText,
        },
    ];

    const costCodeExportDefs: ColDef[] = [
        {
            field: 'code',
            headerName: 'Asset Code',
            headerTooltip: 'Text Field',
        },
        {
            field: 'costCode.code',
            headerName: 'Cost Code',
            headerTooltip: 'Text Field',
            colId: 'costCode',
        },
        {
            field: 'amount',
            headerName: 'Amount',
            headerTooltip: 'Numeric Field',
        },
    ];

    return (
        <React.Fragment>
            <BaseFormGrid
                displayGrid={canViewAssetRecords}
                isLoading={isLoadingAssets}
                rowData={assetList}
                columnDefs={assetColumnDefs}
                gridClass="full-size-item"
                displayToolbar={true}
                displayCreateNewButton={
                    gridMode === FixedAssetGridMode.Normal &&
                    canCreateAssetRecords
                }
                displayExportDataButton={true}
                displayImportDataButton={
                    gridMode === FixedAssetGridMode.Normal &&
                    canImportAssetRecords
                }
                displayResetStateButton={true}
                onCreateNew={redirectToForm}
                onImportData={handleModalOpen}
                rowSelection={
                    gridMode !== FixedAssetGridMode.Normal
                        ? 'multiple'
                        : 'single'
                }
                rowMultiSelectWithClick={gridMode !== FixedAssetGridMode.Normal}
                onSelectionChanged={onSelectionChanged}
                onRowClicked={(row: RowClickedEvent) =>
                    gridMode === FixedAssetGridMode.Normal &&
                    redirectToForm(row.data)
                }
                toolbarChildren={assetToolbar()}
            />
            <UploadGridModal
                title="Bulk Upload"
                open={showImportDataModal}
                onClose={handleModalClose}
                rowData={importList}
                mainExportSchema={assetExportDefs}
                detailExportSchema={costCodeExportDefs}
                onImportReview={handleValidateAssets}
                onImportSave={handleImportAssets}
                onUploadImport={handleUploadImport}
                isImporting={isImportingAssets}
                isValidating={isValidatingAssets}
            />
            <FixedAssetCreateScheduleStepper
                open={showCreateScheduleModal}
                onClose={() => setShowCreateScheduleModal(false)}
                ids={selectedIdList}
                startDate={scheduleStartDate}
                isUpdate={false}
                onLedgerScheduleUpdated={handleCreated}
                onTaxScheduleUpdated={handleCreated}
            />
            <FixedAssetCreateDepreciationEntriesModal
                open={showCreateEntryModal}
                onClose={() => setShowCreateEntryModal(false)}
                ids={selectedIdList}
                isUpdate={false}
                onEntriesCreated={handleCreated}
            />
            <FixedAssetCreateAssetEntriesModal
                open={showCreateAssetEntryModal}
                onClose={() => setShowCreateAssetEntryModal(false)}
                ids={selectedIdList}
                singleRecord={false}
                onAssetEntriesCreated={handleCreated}
            />
            <FixedAssetCreateDisposalEntriesModal
                open={showCreateDisposalModal}
                onClose={() => setShowCreateDisposalModal(false)}
                ids={selectedIdList}
                singleRecord={false}
                onDisposalCreated={handleCreated}
            />
        </React.Fragment>
    );
};

export default FixedAssetGrid;
