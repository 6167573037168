import React from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import PersonIcon from '@mui/icons-material/Person';
import { Attachment } from '../../../services/organizations/organizations.service';
import { DialogContent, Grid, IconButton, Typography } from '@mui/material';
import DatacorLogoSpinner from '../../datacorLogoSpinner/DatacorLogoSpinner';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import NotesIcon from '@mui/icons-material/Notes';
import AttachFileIcon from '@mui/icons-material/AttachFile';

interface AttachmentItemModalProps {
    item: Attachment;
    onClose: () => void;
    open: boolean;
}

const AttachmentItemModal = ({
    item,
    onClose,
    open,
}: AttachmentItemModalProps) => {
    const handleClose = () => {
        onClose();
    };

    return (
        <Dialog onClose={handleClose} open={open} fullWidth maxWidth={'lg'}>
            <DialogTitle>
                Attachment Details -{' '}
                {item && new Date(item?.createdAt).toLocaleString()}
            </DialogTitle>
            <DialogContent>
                {item ? (
                    <Grid container spacing={1}>
                        <Grid item xs={6}>
                            <List
                                sx={{
                                    width: '100%',
                                    bgcolor: 'background.paper',
                                }}>
                                <ListItem>
                                    <ListItemAvatar>
                                        <Avatar>
                                            <PersonIcon />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={'Created By'}
                                        secondary={item.createdBy}
                                    />
                                </ListItem>
                                <ListItem>
                                    <ListItemAvatar>
                                        <Avatar>
                                            <AttachFileIcon />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={`Name: ${item.name}`}
                                        secondary={item.extension}
                                    />
                                </ListItem>
                                <ListItem>
                                    <ListItemAvatar>
                                        <Avatar>
                                            <NotesIcon />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary="Notes"
                                        secondary={item.notes}
                                    />
                                </ListItem>

                                <ListItem>
                                    <ListItemAvatar>
                                        <Avatar>
                                            {/* TODO: implement download onClick */}
                                            <IconButton color={'primary'}>
                                                <FileDownloadIcon />
                                            </IconButton>
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText primary="Download" />
                                </ListItem>
                            </List>
                        </Grid>
                        <Grid item xs={6}>
                            {/*TODO: implement preview of attachment here*/}
                            Maybe show a preview of the attachment here?
                        </Grid>
                    </Grid>
                ) : (
                    <DatacorLogoSpinner />
                )}
            </DialogContent>
        </Dialog>
    );
};

export default AttachmentItemModal;
