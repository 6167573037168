import { useEffect, useState } from 'react';
import { FA_DepreciationSchedule } from '../../../../types/FixedAsset.types';
import { isNilOrEmpty } from '../../../../utils/objectUtils';
import {
    useCreateFixedAssetDepreciationEntriesMutation,
    useLockDepreciationSchedulesMutation,
    useUnlockDepreciationSchedulesMutation,
} from '../../../../services/fixedAssets/fixedAssets.service';
import _ from 'lodash';
import { PermissionsUtil } from '../../../../utils/permissions/permissionsUtil';
import { PERMISSIONS } from '../../../../constants/permissions/Permissions.constants';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';

const useFixedAssetSchedulesActionsModal = (
    selectedSchedules: FA_DepreciationSchedule[],
    onSchedulesUpdated: (schedules: FA_DepreciationSchedule[]) => void,
    onClose: () => void
) => {
    const user = useSelector((state: RootState) => state.user);

    const canCreateSchedules = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.FIXED_ASSETS.DEPRECIATION_SCHEDULES.CREATE
    );
    const canLockSchedules = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.FIXED_ASSETS.DEPRECIATION_SCHEDULES.LOCK
    );

    const [shouldLockSchedules, setShouldLockSchedules] =
        useState<boolean>(false);
    const [canToggleLockUnlock, setCanToggleLockUnlock] =
        useState<boolean>(false);

    const [shouldCreateEntries, setShouldCreateEntries] =
        useState<boolean>(false);
    const [canCreateEntries, setCanCreateEntries] = useState<boolean>(false);

    const [isSavingSchedules, setIsSavingSchedules] = useState<boolean>(false);
    const [lockSchedules] = useLockDepreciationSchedulesMutation();
    const [unlockSchedules] = useUnlockDepreciationSchedulesMutation();
    const [createDepreciationEntries] =
        useCreateFixedAssetDepreciationEntriesMutation();

    useEffect(() => {
        if (!isNilOrEmpty(selectedSchedules)) {
            const hasUnlockedSchedules: boolean = selectedSchedules.some(
                (schedule) => !schedule.locked
            );
            const hasLockedSchedules: boolean = selectedSchedules.some(
                (schedule) => schedule.locked
            );
            const hasPostingType: boolean = selectedSchedules.some(
                (schedule) =>
                    schedule.assetType.depreciationMethod.depreciationMethodType
                        .postsJournalEntries
            );

            setShouldLockSchedules(hasUnlockedSchedules);
            setCanToggleLockUnlock(hasUnlockedSchedules && hasLockedSchedules);
            setShouldCreateEntries(false);
            setCanCreateEntries(hasPostingType);
        } else {
            setShouldLockSchedules(true);
            setCanToggleLockUnlock(false);
            setShouldCreateEntries(false);
            setCanCreateEntries(false);
        }
    }, [selectedSchedules]);

    const handleUpdateSchedules = async (event: any) => {
        event.preventDefault();

        setIsSavingSchedules(true);

        if (shouldLockSchedules) {
            const idList: bigint[] = selectedSchedules
                .filter((dSchedule) => !dSchedule.locked)
                .map((dSchedule) => dSchedule.id);

            try {
                const response: any = await lockSchedules({ ids: idList });
                onSchedulesUpdated(response.data);
            } catch (e) {
                console.log(e);
            }
        } else {
            const idList: bigint[] = selectedSchedules
                .filter((dSchedule) => dSchedule.locked)
                .map((dSchedule) => dSchedule.id);

            try {
                const response: any = await unlockSchedules({ ids: idList });
                onSchedulesUpdated(response.data);
            } catch (e) {
                console.log(e);
            }
        }

        if (shouldCreateEntries) {
            const fixedAssetIdList: bigint[] = _.uniq(
                selectedSchedules.map((dSchedule) => dSchedule.fixedAssetId)
            );

            try {
                await createDepreciationEntries({
                    ids: fixedAssetIdList,
                    lockIntervals: false,
                });
            } catch (e) {
                console.log(e);
            }
        }

        setIsSavingSchedules(false);
        onClose();
    };

    return {
        shouldLockSchedules,
        setShouldLockSchedules,
        canToggleLockUnlock,
        shouldCreateEntries,
        setShouldCreateEntries,
        canCreateEntries,
        isSavingSchedules,
        handleUpdateSchedules,
        canCreateSchedules,
        canLockSchedules,
    };
};

export default useFixedAssetSchedulesActionsModal;
