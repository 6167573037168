import { FA_AssetDisposalEntry } from '../../../types/FixedAsset.types';
import useFixedAssetDisposalEntriesActionsModal from './hooks/useFixedAssetDisposalEntriesActionsModal';
import {
    Box,
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    Stack,
    Switch,
    Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { LoadingButton } from '@mui/lab';
import React from 'react';
import { DisposalEntriesGridDefs } from '../../../constants/i18n/translations/termDefinitions/accounting';
import TranslatableText from '../../../components/i18n/TranslatableText';

export interface DisposalEntriesActionsProps {
    open: boolean;
    selected: FA_AssetDisposalEntry[];
    onClose: () => void;
}

const FixedAssetDisposalEntriesActionsModal = (
    props: DisposalEntriesActionsProps
) => {
    const {
        shouldLockEntries,
        setShouldLockEntries,
        canToggleLockUnlock,
        isLockingEntries,
        isUnlockingEntries,
        handleUpdateEntries,
        canLockDisposalEntries,
        termSet,
    } = useFixedAssetDisposalEntriesActionsModal(props);

    return (
        <Dialog
            open={props.open}
            onClose={props.onClose}
            fullWidth
            maxWidth={'sm'}>
            <DialogTitle>
                <Box display="flex" alignItems="center">
                    <Box flexGrow={1}>
                        <TranslatableText
                            termSet={termSet}
                            termKey={DisposalEntriesGridDefs.Actions_Title}
                        />
                    </Box>
                    <Box>
                        <IconButton onClick={props.onClose}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </Box>
            </DialogTitle>
            <DialogContent>
                <Stack spacing={2} alignItems={'center'}>
                    {canLockDisposalEntries ? (
                        <Stack direction="row" spacing={1} alignItems="center">
                            <Typography>
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={
                                        DisposalEntriesGridDefs.Actions_Unlock_Entries
                                    }
                                />
                            </Typography>
                            <Switch
                                checked={shouldLockEntries}
                                disabled={!canToggleLockUnlock}
                                onChange={(event) =>
                                    setShouldLockEntries(event.target.checked)
                                }
                            />
                            <Typography>
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={
                                        DisposalEntriesGridDefs.Actions_Lock_Entries
                                    }
                                />
                            </Typography>
                        </Stack>
                    ) : null}
                    <LoadingButton
                        fullWidth
                        variant="contained"
                        type="submit"
                        loading={isLockingEntries || isUnlockingEntries}
                        onClick={handleUpdateEntries}>
                        <TranslatableText
                            termSet={termSet}
                            termKey={
                                DisposalEntriesGridDefs.Actions_Process_Changes
                            }
                        />
                    </LoadingButton>
                </Stack>
            </DialogContent>
        </Dialog>
    );
};

export default FixedAssetDisposalEntriesActionsModal;
