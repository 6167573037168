import React from 'react';
import BaseFormGrid from '../../components/grids/BaseFormGrid';
import { cloneDeep } from 'lodash';
import { isNilOrEmpty } from '../../utils/objectUtils';
import TranslatableText from '../../components/i18n/TranslatableText';
import { SupplierAddressStatusGridDefs } from '../../constants/i18n/translations/termDefinitions/supply-chain';
import useSupplierAddressStatusGrid from './hooks/useSupplierAddressStatusGrid';

const SupplierAddressStatusGrid = () => {
    const {
        rowData,
        colDefs,
        canView,
        canCreate,
        canUpdate,
        redirectToForm,
        handleGridEdits,
        termSet,
    } = useSupplierAddressStatusGrid();

    return (
        <BaseFormGrid
            title={
                (
                    <TranslatableText
                        termKey={SupplierAddressStatusGridDefs.Title}
                        termSet={termSet}
                    />
                ) as unknown as string
            }
            displayGrid={canView}
            isLoading={isNilOrEmpty(colDefs)}
            rowData={cloneDeep(rowData)}
            useRowDrag={canUpdate}
            sortKey={'sortOrder'}
            columnDefs={colDefs}
            gridClass="full-size-item"
            displayToolbar={true}
            displayCreateNewButton={canCreate}
            displayExportDataButton={true}
            displayResetStateButton={true}
            onCreateNew={redirectToForm}
            onRowClicked={({ data }) => redirectToForm(data)}
            onHandleGridEdits={handleGridEdits}
        />
    );
};

export default SupplierAddressStatusGrid;
