import {
    SdsEcoToxicity,
    SdsHeader,
} from '../../../../../../../../../types/formulation';
import { PermissionsUtil } from '../../../../../../../../../utils/permissions/permissionsUtil';
import { PERMISSIONS } from '../../../../../../../../../constants/permissions/Permissions.constants';
import React, { useContext, useEffect, useState } from 'react';
import { GridOptions } from '../../../../../../../../../components/grids/Grid.constants';
import { isNilOrEmpty } from '../../../../../../../../../utils/objectUtils';
import { editableGridCellSelect } from '../../../../../../../../../components/grids/columns/editableGridCellSelect';
import { ValueGetterParams } from 'ag-grid-community';
import DefaultColumnTypes from '../../../../../../../../../components/grids/columns/Column.constants';
import { RowStatus } from '../../../../../../../../../components/grids/hooks/useBaseGridEditable';
import { useListSpeciesClassDropdownQuery } from '../../../../../../../../../services/formulation/casMaster/casSpeciesClass/casSpeciesClass.service';
import { useGetToxicTestDropdownQuery } from '../../../../../../../../../services/formulation/toxicTest/toxicTest.service';
import { useGetToxicSpeciesDropdownQuery } from '../../../../../../../../../services/formulation/toxicSpecies/toxicSpecies.service';
import { useListCasQuery } from '../../../../../../../../../services/formulation/casMaster/cas/casMaster.service';
import { SelectedDropdownOption } from '../../../../../../../../../types/Shared.types';
import { ColumnMaxValueValidator } from '../../../../../../../../grid/validators/columnMaxValue.validator';
import applyEditableGridValidations from '../../../../../../../../grid/utils/applyEditableGridValidations';
import _ from 'lodash';
import CustomHeader from '../../../../../../../../../components/grids/CustomHeader';
import { SDSFormDefs } from '../../../../../../../../../constants/i18n/translations/termDefinitions/formulation';
import SettingsContext from '../../../../../../../../../contexts/settings.context';
import { useGetTermSetQuery } from '../../../../../../../../../services/i18n/i18n.service';
import { FORMULATION_DEFS } from '../../../../../../../../../constants/i18n/translations/termSetDefinitions/formulation';
import { skipToken } from '@reduxjs/toolkit/query';
import { ChildEditableGridWithCopyFunctionProps } from '../../../../../../../../grid/utils/editableGrid/ChildEditableGridWithCopyFunctionProps';

const useSdsEcoToxicityGrid = (
    props: ChildEditableGridWithCopyFunctionProps<SdsHeader, SdsEcoToxicity>
) => {
    const { currentParentRecord, user } = props.parentData;

    const { showDeleteButton, isGridEditable } = props.displayGridButtons;

    const { handleChildrenRecords } = props.helpers;

    const [maxRowId, setMaxRowId] = useState(0);

    const canViewCas = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.FORMULATION.CAS_MASTER.VIEW
    );

    const { data: casOptions, isLoading: isLoadingCas } = useListCasQuery(
        user?.businessEntity?.id.toString()
    );

    const canViewToxicTest = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.FORMULATION.TOXIC_TEST.VIEW
    );

    const canViewToxicSpecies = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.FORMULATION.TOXIC_SPECIES.VIEW
    );

    const { data: casSpeciesClassOptions, isLoading: isLoadingSpeciesClasses } =
        useListSpeciesClassDropdownQuery();

    const { data: toxicTestOptions, isLoading: isLoadingToxicTests } =
        useGetToxicTestDropdownQuery();

    const { data: toxicSpeciesOptions, isLoading: isLoadingToxicSpecies } =
        useGetToxicSpeciesDropdownQuery();

    const [ecoToxicityEntityColumnDefs, setEcoToxicityEntityColumnDefs] =
        useState(null);
    const { rowsData, setRowsData } = props.copyMethods;
    const [toxicTestColumnDefs, setToxicTestColumnDefs] = useState(null);
    const [toxicSpeciesColumnDefs, setToxicSpeciesColumnDefs] = useState(null);
    const [casColumnDefs, setCasColumnDefs] = useState(null);

    const colDefOptions = {
        ...GridOptions.sortFilterAndWrapColumns,
        floatingFilter: true,
    };

    const { settings } = useContext(SettingsContext);
    const { data: termSet } = useGetTermSetQuery(
        settings?.userSettings
            ? {
                  languageId: settings?.userSettings?.languageId,
                  code: FORMULATION_DEFS.SDS_FORM,
              }
            : skipToken
    );

    const getToxicTestCode = (toxicTestId: bigint): SelectedDropdownOption => {
        let toxicTestCode: SelectedDropdownOption = { id: null, display: '' };
        if (!isNilOrEmpty(toxicTestId)) {
            toxicTestOptions?.find((current: any) => {
                if (current.id === toxicTestId) {
                    toxicTestCode = {
                        id: current.id,
                        display: current.toxicTestCode,
                    };
                }
            });
        }
        return toxicTestCode;
    };

    const getToxicSpeciesCode = (
        toxicSpeciesId: bigint
    ): SelectedDropdownOption => {
        let toxicSpeciesCode: SelectedDropdownOption = {
            id: null,
            display: '',
        };
        if (!isNilOrEmpty(toxicSpeciesId)) {
            toxicSpeciesOptions?.find((current: any) => {
                if (current.id === toxicSpeciesId) {
                    toxicSpeciesCode = {
                        id: current.id,
                        display: current.toxicSpeciesCode,
                    };
                }
            });
        }
        return toxicSpeciesCode;
    };

    const findFieldDescription = (id: bigint, options: any) => {
        let description: any;
        options?.find((current: any) => {
            if (current.id === id) {
                description = current.description;
            }
        });
        return description;
    };

    const getCasDetails = (id: bigint, options: any) => {
        let detailRecord: any;
        options?.find((current: any) => {
            if (current.id === id) {
                detailRecord = current;
            }
        });
        return detailRecord;
    };

    const getCasNumber = (casId: bigint): SelectedDropdownOption => {
        let casNumber: SelectedDropdownOption = { id: null, display: '' };
        if (!isNilOrEmpty(casId)) {
            casOptions?.find((current: any) => {
                if (current.id === casId) {
                    casNumber = {
                        id: current.id,
                        display: current.identifier,
                    };
                }
            });
        }
        return casNumber;
    };

    const sortSdsEcoToxicities = (sdsEcoToxicityRecords: SdsEcoToxicity[]) => {
        setRowsData(
            _.orderBy(
                sdsEcoToxicityRecords,
                ['sortOrder', 'casNumber.display'],
                ['asc']
            )
        );
    };

    useEffect(() => {
        if (!isLoadingCas) {
            setCasColumnDefs([
                {
                    field: 'identifier',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={
                                    SDSFormDefs.Toxicity_CAS_Selection_CAS_No
                                }
                            />
                        );
                    },
                    cellClass: 'ag-left-aligned-cell',
                    filter: 'agTextColumnFilter',
                },
                {
                    field: 'chemicalName',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={
                                    SDSFormDefs.Toxicity_CAS_Selection_Chemical_Name
                                }
                            />
                        );
                    },
                    cellClass: 'ag-left-aligned-cell',
                    filter: 'agTextColumnFilter',
                },
                {
                    field: 'ecIdentifier',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={
                                    SDSFormDefs.Toxicity_CAS_Selection_EC_No
                                }
                            />
                        );
                    },
                    cellClass: 'ag-left-aligned-cell',
                    filter: 'agTextColumnFilter',
                },
                {
                    field: 'reach',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={
                                    SDSFormDefs.Toxicity_CAS_Selection_Reach_No
                                }
                            />
                        );
                    },
                    cellClass: 'ag-left-aligned-cell',
                    filter: 'agTextColumnFilter',
                },
                {
                    field: 'classification',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={
                                    SDSFormDefs.Toxicity_CAS_Selection_Classification
                                }
                            />
                        );
                    },
                    cellClass: 'ag-left-aligned-cell',
                    filter: 'agTextColumnFilter',
                },
                {
                    field: 'molecularFormula',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={
                                    SDSFormDefs.Toxicity_CAS_Selection_Molecular_Formula
                                }
                            />
                        );
                    },
                    cellClass: 'ag-left-aligned-cell',
                    filter: 'agTextColumnFilter',
                },
            ]);
        }
    }, [isLoadingCas, casOptions]);

    useEffect(() => {
        const includeEditedRows = currentParentRecord?.sdsEcoToxicities
            ? !isNilOrEmpty(
                  currentParentRecord.sdsEcoToxicities.find(
                      (current) =>
                          current.rowStatus &&
                          (current?.autoPopulated
                              ? !current?.autoPopulated
                              : true)
                  )
              )
            : false;

        if (
            currentParentRecord?.sdsEcoToxicities &&
            !isNilOrEmpty(toxicTestOptions) &&
            !isNilOrEmpty(toxicSpeciesOptions) &&
            !includeEditedRows
        ) {
            const sdsEcoTox: SdsEcoToxicity[] = [];
            let index = maxRowId;
            currentParentRecord?.sdsEcoToxicities.forEach(
                (field: SdsEcoToxicity) => {
                    index = index + 1;
                    setMaxRowId((maxRowId) => maxRowId + 1);
                    if (field.rowStatus !== RowStatus.DELETED) {
                        const sdsEcoToxicity = {
                            ...field,
                            casNumber: getCasNumber(field.casId),
                            toxicTestCode: getToxicTestCode(field.toxicTestId),
                            toxicSpeciesCode: getToxicSpeciesCode(
                                field.toxicSpeciesId
                            ),
                            speciesClassId:
                                field.speciesClassId > 0
                                    ? field.speciesClassId
                                    : null,
                            rowId: field.rowId ? field.rowId : index,
                        };
                        sdsEcoTox.push(sdsEcoToxicity);
                    }
                }
            );
            sortSdsEcoToxicities(sdsEcoTox);
        }
    }, [
        currentParentRecord?.sdsEcoToxicities,
        toxicTestOptions,
        toxicSpeciesOptions,
        casOptions,
    ]);

    useEffect(() => {
        if (!isLoadingToxicTests && !isNilOrEmpty(toxicTestOptions)) {
            setToxicTestColumnDefs([
                {
                    field: 'toxicTestCode',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={
                                    SDSFormDefs.Toxicity_Test_Selection_Toxic_Test_Code
                                }
                            />
                        );
                    },
                    cellClass: 'ag-left-aligned-cell',
                },
                {
                    field: 'description',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={
                                    SDSFormDefs.Toxicity_Test_Selection_Description
                                }
                            />
                        );
                    },
                    cellClass: 'ag-left-aligned-cell',
                },
            ]);
        }
    }, [isLoadingToxicTests, toxicTestOptions]);

    useEffect(() => {
        if (!isLoadingToxicSpecies && !isNilOrEmpty(toxicSpeciesOptions)) {
            setToxicSpeciesColumnDefs([
                {
                    field: 'toxicSpeciesCode',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={
                                    SDSFormDefs.Toxicity_Species_Selection_Toxic_Species_Code
                                }
                            />
                        );
                    },
                    cellClass: 'ag-left-aligned-cell',
                },
                {
                    field: 'description',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={
                                    SDSFormDefs.Toxicity_Species_Selection_Description
                                }
                            />
                        );
                    },
                    cellClass: 'ag-left-aligned-cell',
                },
            ]);
        }
    }, [isLoadingToxicSpecies, toxicSpeciesOptions]);

    useEffect(() => {
        if (
            !isLoadingToxicTests &&
            !isNilOrEmpty(toxicTestOptions) &&
            !isNilOrEmpty(toxicTestColumnDefs) &&
            !isLoadingToxicSpecies &&
            !isNilOrEmpty(toxicSpeciesOptions) &&
            !isNilOrEmpty(toxicSpeciesColumnDefs) &&
            !isLoadingSpeciesClasses &&
            !isNilOrEmpty(casSpeciesClassOptions) &&
            isNilOrEmpty(ecoToxicityEntityColumnDefs) &&
            !isLoadingCas &&
            !isNilOrEmpty(casColumnDefs)
        ) {
            setEcoToxicityEntityColumnDefs([
                {
                    field: 'deleteColumn',
                    minWidth: 50,
                    hide: !showDeleteButton || !isGridEditable,
                    filter: false,
                },
                {
                    field: 'casNumber',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={SDSFormDefs.Toxicity_CAS_No}
                            />
                        );
                    },
                    minWidth: 200,
                    editable: canViewCas && isGridEditable,
                    cellRenderer: (params: any) => params.value.display,
                    useGridPopup: true,
                    filter: 'agTextColumnFilter',
                    gridPopupParameters: {
                        isLoading: isLoadingCas,
                        displayGrid: true,
                        rowData: canViewCas ? casOptions : [],
                        defaultColDef: colDefOptions,
                        columnDefs: casColumnDefs,
                        displayField: 'identifier',
                    },
                },
                {
                    field: 'chemicalName',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={SDSFormDefs.Toxicity_Chemical_Name}
                            />
                        );
                    },
                    editable: false,
                    minWidth: 200,
                    filter: 'agTextColumnFilter',
                    valueGetter: (params: ValueGetterParams) => {
                        const details = getCasDetails(
                            params.data.casNumber?.id,
                            casOptions
                        );
                        return details?.chemicalName;
                    },
                },
                {
                    field: 'toxicTestCode',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={SDSFormDefs.Toxicity_Test_Type}
                            />
                        );
                    },
                    minWidth: 150,
                    editable: canViewToxicTest && isGridEditable,
                    cellRenderer: (params: any) => params.value.display,
                    useGridPopup: true,
                    gridPopupParameters: {
                        isLoading: isLoadingToxicTests,
                        displayGrid: true,
                        rowData: toxicTestOptions,
                        defaultColDef: colDefOptions,
                        columnDefs: toxicTestColumnDefs,
                        displayField: 'toxicTestCode',
                    },
                    ...GridOptions.sortFilterAndWrapColumns,
                },
                {
                    field: 'toxicTestDescription',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={SDSFormDefs.Toxicity_Test_Description}
                            />
                        );
                    },
                    minWidth: 200,
                    editable: false,
                    valueGetter: (params: ValueGetterParams) => {
                        params.data.description = findFieldDescription(
                            params.data.toxicTestCode?.id,
                            toxicTestOptions
                        );
                        return params.data.description;
                    },
                    ...GridOptions.sortFilterAndWrapColumns,
                    filter: 'agTextColumnFilter',
                },
                {
                    field: 'effectiveDose',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={SDSFormDefs.Toxicity_Effective_Dose}
                            />
                        );
                    },
                    minWidth: 200,
                    filter: 'agTextColumnFilter',
                    editable: isGridEditable,
                },
                {
                    field: 'exposureTime',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={SDSFormDefs.Toxicity_Exposure_Time}
                            />
                        );
                    },
                    minWidth: 190,
                    filter: 'agTextColumnFilter',
                    editable: isGridEditable,
                },
                {
                    field: 'toxicSpeciesCode',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={SDSFormDefs.Toxicity_Species}
                            />
                        );
                    },
                    minWidth: 200,
                    editable: canViewToxicSpecies && isGridEditable,
                    cellRenderer: (params: any) => params.value.display,
                    useGridPopup: true,
                    gridPopupParameters: {
                        isLoading: isLoadingToxicSpecies,
                        displayGrid: true,
                        rowData: toxicSpeciesOptions,
                        defaultColDef: colDefOptions,
                        columnDefs: toxicSpeciesColumnDefs,
                        displayField: 'toxicSpeciesCode',
                    },
                    ...GridOptions.sortFilterAndWrapColumns,
                },
                {
                    field: 'toxicSpeciesDescription',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={
                                    SDSFormDefs.Toxicity_Species_Description
                                }
                            />
                        );
                    },
                    minWidth: 200,
                    editable: false,
                    valueGetter: (params: ValueGetterParams) => {
                        params.data.description = findFieldDescription(
                            params.data.toxicSpeciesCode?.id,
                            toxicSpeciesOptions
                        );
                        return params.data.description;
                    },
                    ...GridOptions.sortFilterAndWrapColumns,
                    filter: 'agTextColumnFilter',
                },
                {
                    field: 'speciesClassId',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={SDSFormDefs.Toxicity_Class_Of_Species}
                            />
                        );
                    },
                    minWidth: 100,
                    filter: 'agTextColumnFilter',
                    ...editableGridCellSelect(casSpeciesClassOptions),
                    editable: isGridEditable,
                },
                {
                    field: 'notes',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={SDSFormDefs.Toxicity_Note}
                            />
                        );
                    },
                    filter: 'agTextColumnFilter',
                    editable: isGridEditable,
                    ...DefaultColumnTypes.LargeTextEditor,
                    minWidth: 200,
                    cellEditorParams: {
                        ...DefaultColumnTypes.LargeTextEditor.cellEditorParams,
                        maxLength: 30,
                    },
                },
                {
                    field: 'source',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={SDSFormDefs.Toxicity_Source}
                            />
                        );
                    },
                    minWidth: 150,
                    filter: 'agTextColumnFilter',
                    editable: isGridEditable,
                },
            ]);
        }
    }, [
        isLoadingToxicTests,
        toxicTestOptions,
        toxicTestColumnDefs,
        isLoadingToxicSpecies,
        toxicSpeciesOptions,
        toxicSpeciesColumnDefs,
        isLoadingSpeciesClasses,
        casSpeciesClassOptions,
        ecoToxicityEntityColumnDefs,
        isLoadingCas,
        casColumnDefs,
    ]);

    const handleRowValidations = (editedRows: SdsEcoToxicity[]) => {
        editedRows?.forEach((row: SdsEcoToxicity) => {
            row.validationRules = [
                ColumnMaxValueValidator(
                    'Effective Dose',
                    50,
                    row.effectiveDose
                ),
                ColumnMaxValueValidator('Exposure Time', 50, row.exposureTime),
                ColumnMaxValueValidator('Notes', 30, row.notes),
                ColumnMaxValueValidator('Source', 50, row.source),
            ];
            const additionalMessage = row.toxicTestCode?.display
                ? `Eco Toxicity ${row.toxicTestCode?.display}`
                : null;
            applyEditableGridValidations(row, additionalMessage);
        });
        return editedRows;
    };

    const handleGridEdits = (editedRows: SdsEcoToxicity[]) => {
        const updatedFields: SdsEcoToxicity[] = [...rowsData];

        editedRows?.forEach((row: any) => {
            if (
                !isNilOrEmpty(row.casNumber) &&
                !isNilOrEmpty(row.casNumber?.id)
            ) {
                row.casId = row.casNumber?.id;
                row.chemicalName =
                    getCasDetails(row.casNumber?.id, casOptions)
                        ?.chemicalName || '';
            }

            if (
                !isNilOrEmpty(row.toxicTestCode) &&
                !isNilOrEmpty(row.toxicTestCode?.id)
            ) {
                row.toxicTestId = row.toxicTestCode?.id;
            }

            if (
                !isNilOrEmpty(row.toxicSpeciesCode) &&
                !isNilOrEmpty(row.toxicSpeciesCode?.id)
            ) {
                row.toxicSpeciesId = row.toxicSpeciesCode?.id;
            }

            const index = rowsData.findIndex(
                (sdsEcoTox: SdsEcoToxicity) => sdsEcoTox.rowId === row.rowId
            );

            if (index > -1) {
                updatedFields[index] = row;
            } else {
                updatedFields.push(row);
            }
            sortSdsEcoToxicities(updatedFields);
            handleChildrenRecords('sdsEcoToxicities', updatedFields);
        });
    };

    return {
        isLoadingToxicTests,
        isLoadingToxicSpecies,
        isLoadingSpeciesClasses,
        isLoadingCas,
        ecoToxicityEntityColumnDefs,
        rowsData,
        handleGridEdits,
        handleRowValidations,
        colDefOptions,
        termSet,
    };
};

export default useSdsEcoToxicityGrid;
