import { Button } from '@mui/material';
import React from 'react';
import ListIcon from '@mui/icons-material/List';
import BaseFormGrid from '../../../components/grids/BaseFormGrid';
import useDepreciationDetailGrid from './hooks/useDepreciationDetailGrid';
import DepreciationDetailOptionsModal from './DepreciationDetailOptionsModal';
import { DepreciationDetailGridDefs } from '../../../constants/i18n/translations/termDefinitions/accounting';
import TranslatableText from '../../../components/i18n/TranslatableText';

const DepreciationDetailGrid = () => {
    const {
        depreciationDetailRows,
        columnDef,
        setDepreciationEntryList,
        setShowOptionsModal,
        showOptionsModal,
        bottomRowData,
        canViewDepreciationDetailReport,
        termSet,
    } = useDepreciationDetailGrid();

    return (
        <React.Fragment>
            <DepreciationDetailOptionsModal
                termSet={termSet}
                open={showOptionsModal}
                onDepreciationDetailsLoaded={(entries) =>
                    setDepreciationEntryList(entries)
                }
                onClose={() => setShowOptionsModal(false)}
            />

            <BaseFormGrid
                title={
                    <TranslatableText
                        termSet={termSet}
                        termKey={
                            DepreciationDetailGridDefs.Depreciation_Detail_Report
                        }
                    />
                }
                defaultColDef={{ flex: 1 }}
                displayGrid={canViewDepreciationDetailReport}
                isLoading={false}
                rowData={depreciationDetailRows}
                columnDefs={columnDef}
                gridClass="full-size-item"
                displayToolbar={true}
                displaySummaryRow={true}
                pinnedBottomRowData={bottomRowData}
                toolbarChildren={
                    <Button
                        variant="text"
                        size="small"
                        onClick={() => setShowOptionsModal(true)}
                        startIcon={<ListIcon />}>
                        Options
                    </Button>
                }
                displayExportDataButton={true}
            />
        </React.Fragment>
    );
};
export default DepreciationDetailGrid;
