import { useNavigate } from 'react-router-dom';
import { RootStateOrAny, useSelector } from 'react-redux';
import { PERMISSIONS } from '../../../../constants/permissions/Permissions.constants';
import { PermissionsUtil } from '../../../../utils/permissions/permissionsUtil';
import { SdsHeader } from '../../../../types/formulation';
import {
    Language,
    useGetLanguagesQuery,
} from '../../../../services/organizations/organizations.service';
import { isNilOrEmpty } from '../../../../utils/objectUtils';
import {
    useGetSdsHeaderStatusForDropdownQuery,
    useListSdsHeaderQuery,
} from '../../../../services/formulation/sds/header/sdsHeader.service';
import { useListItemsMasterDropdownQuery } from '../../../../services/formulation/itemMaster/item/itemMaster.service';
import { useContext } from 'react';
import SettingsContext from '../../../../contexts/settings.context';
import { useGetTermSetQuery } from '../../../../services/i18n/i18n.service';
import { FORMULATION_DEFS } from '../../../../constants/i18n/translations/termSetDefinitions/formulation';
import { skipToken } from '@reduxjs/toolkit/query';

const useSdsGrid = () => {
    const navigate = useNavigate();

    const user = useSelector((state: RootStateOrAny) => state.user);

    const { data: sdsHeaderList, isLoading: isLoadingSdsHeaderList } =
        useListSdsHeaderQuery();

    const { data: languages, isLoading: isLoadingLanguages } =
        useGetLanguagesQuery();

    const { data: sdsHeaderStatusList, isLoading: isLoadingSdsHeaderStatus } =
        useGetSdsHeaderStatusForDropdownQuery();

    const { data: itemMasterOptions, isLoading: isLoadingItemMasterOptions } =
        useListItemsMasterDropdownQuery();

    const canCreateSafetyDataSheet = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.FORMULATION.SDS_HEADER.CREATE
    );

    const canViewSafetyDataSheet = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.FORMULATION.SDS_HEADER.VIEW
    );

    const { settings } = useContext(SettingsContext);
    const { data: termSet } = useGetTermSetQuery(
        settings?.userSettings
            ? {
                  languageId: settings?.userSettings?.languageId,
                  code: FORMULATION_DEFS.SAFETY_DATA_SHEET_GRID,
              }
            : skipToken
    );

    const redirectToForm = (data: SdsHeader) => {
        if (data && data?.id) {
            navigate(`/formulation/sds/${data.id}`);
        } else {
            navigate('/formulation/sds/new');
        }
    };

    const getLanguageDescriptionById = (langId: bigint) => {
        if (!isLoadingLanguages && languages && langId) {
            const language: Language = languages.find(
                (currentLanguage: Language) => currentLanguage.id === langId
            );
            return `${language?.code} - ${language.name}`;
        }
        return '';
    };

    const getStatusDescriptionById = (status: bigint) => {
        if (
            !isLoadingSdsHeaderStatus &&
            !isNilOrEmpty(sdsHeaderStatusList) &&
            !isNilOrEmpty(status)
        ) {
            const sdsHeaderStatus: any = sdsHeaderStatusList?.find(
                (currentStatus: any) => currentStatus.value === status
            );
            return sdsHeaderStatus?.label;
        }
        return '';
    };

    const getItemCodeById = (itemId: bigint) => {
        if (!isLoadingItemMasterOptions && itemMasterOptions && itemId) {
            const itemOption: any = itemMasterOptions.find(
                (currentItemMaster: any) => currentItemMaster.value === itemId
            );
            return itemOption?.label;
        }
        return '';
    };

    return {
        isLoadingSdsHeaderList,
        sdsHeaderList,
        canCreateSafetyDataSheet,
        canViewSafetyDataSheet,
        redirectToForm,
        getLanguageDescriptionById,
        getStatusDescriptionById,
        getItemCodeById,
        termSet,
    };
};

export default useSdsGrid;
