import { useNavigate } from 'react-router-dom';
import { RootStateOrAny, useSelector } from 'react-redux';
import { PermissionsUtil } from '../../../../utils/permissions/permissionsUtil';
import { PERMISSIONS } from '../../../../constants/permissions/Permissions.constants';
import React, { useContext } from 'react';
import SettingsContext from '../../../../contexts/settings.context';
import { useGetTermSetQuery } from '../../../../services/i18n/i18n.service';
import { FORMULATION_DEFS } from '../../../../constants/i18n/translations/termSetDefinitions/formulation';
import { skipToken } from '@reduxjs/toolkit/query';
import { useListHazardClassificationRulesQuery } from '../../../../services/formulation/hazardClassificationRules/hazardClassificationRules.service';
import { GridOptions } from '../../../../components/grids/Grid.constants';
import { ColDef } from 'ag-grid-community';
import DefaultColumnTypes from '../../../../components/grids/columns/Column.constants';
import CustomHeader from '../../../../components/grids/CustomHeader';
import { HazardClassRulesGridDefs } from '../../../../constants/i18n/translations/termDefinitions/formulation';

const useHazardClassificationRuleGrid = () => {
    const navigate = useNavigate();
    const user = useSelector((state: RootStateOrAny) => state.user);

    const { data: hazardClassRulesList, isLoading: isLoadingHazardClassRules } =
        useListHazardClassificationRulesQuery();

    const canCreateHazardClassRules = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.FORMULATION.HAZARD_CLASS_RULES.CREATE
    );
    const canViewHazardClassRules = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.FORMULATION.HAZARD_CLASS_RULES.VIEW
    );

    const defaultColumnDefinitionOptions = {
        ...GridOptions.sortFilterAndWrapColumns,
        floatingFilter: true,
    };

    const { settings } = useContext(SettingsContext);
    const { data: termSet } = useGetTermSetQuery(
        settings?.userSettings
            ? {
                  languageId: settings?.userSettings?.languageId,
                  code: FORMULATION_DEFS.HAZARD_CLASS_RULES_GRID,
              }
            : skipToken
    );

    const columnDefs: ColDef[] = [
        {
            field: 'hazardClass.code',
            headerComponentFramework: (props: any) => {
                return (
                    <CustomHeader
                        {...props}
                        termSet={termSet}
                        termKey={HazardClassRulesGridDefs.Hazard_Class}
                    />
                );
            },
            ...DefaultColumnTypes.ShortText,
        },
        {
            field: 'hazardClass.description',
            headerComponentFramework: (props: any) => {
                return (
                    <CustomHeader
                        {...props}
                        termSet={termSet}
                        termKey={
                            HazardClassRulesGridDefs.Hazard_Class_Description
                        }
                    />
                );
            },
            ...DefaultColumnTypes.MediumText,
        },
        {
            field: 'localeGroup.groupId',
            headerComponentFramework: (props: any) => {
                return (
                    <CustomHeader
                        {...props}
                        termSet={termSet}
                        termKey={HazardClassRulesGridDefs.Locale_Group}
                    />
                );
            },
            ...DefaultColumnTypes.ShortText,
        },
        {
            field: 'sequence',
            headerComponentFramework: (props: any) => {
                return (
                    <CustomHeader
                        {...props}
                        termSet={termSet}
                        termKey={HazardClassRulesGridDefs.Sequence}
                    />
                );
            },
            ...DefaultColumnTypes.ShortText,
        },
        {
            field: 'notes',
            headerComponentFramework: (props: any) => {
                return (
                    <CustomHeader
                        {...props}
                        termSet={termSet}
                        termKey={HazardClassRulesGridDefs.Notes}
                    />
                );
            },
            ...DefaultColumnTypes.MediumText,
        },
        {
            field: 'category',
            headerComponentFramework: (props: any) => {
                return (
                    <CustomHeader
                        {...props}
                        termSet={termSet}
                        termKey={HazardClassRulesGridDefs.Category}
                    />
                );
            },
            ...DefaultColumnTypes.MediumText,
        },
        {
            field: 'basisCategory',
            headerComponentFramework: (props: any) => {
                return (
                    <CustomHeader
                        {...props}
                        termSet={termSet}
                        termKey={HazardClassRulesGridDefs.Basis_Category}
                    />
                );
            },
            ...DefaultColumnTypes.MediumText,
        },
        {
            field: 'formType.type',
            headerComponentFramework: (props: any) => {
                return (
                    <CustomHeader
                        {...props}
                        termSet={termSet}
                        termKey={HazardClassRulesGridDefs.Form_Type}
                    />
                );
            },
            ...DefaultColumnTypes.ShortText,
        },
        {
            field: 'individualOperator.operator',
            headerComponentFramework: (props: any) => {
                return (
                    <CustomHeader
                        {...props}
                        termSet={termSet}
                        termKey={HazardClassRulesGridDefs.Individual_Operator}
                    />
                );
            },
            ...DefaultColumnTypes.MediumText,
        },
        {
            field: 'individualPercent',
            headerComponentFramework: (props: any) => {
                return (
                    <CustomHeader
                        {...props}
                        termSet={termSet}
                        termKey={HazardClassRulesGridDefs.Individual_Percent}
                    />
                );
            },
            ...DefaultColumnTypes.ShortText,
        },
        {
            field: 'nextStep.code',
            headerComponentFramework: (props: any) => {
                return (
                    <CustomHeader
                        {...props}
                        termSet={termSet}
                        termKey={HazardClassRulesGridDefs.Next_Steps}
                    />
                );
            },
            ...DefaultColumnTypes.ShortText,
        },
        {
            field: 'lowerOperator.operator',
            headerComponentFramework: (props: any) => {
                return (
                    <CustomHeader
                        {...props}
                        termSet={termSet}
                        termKey={HazardClassRulesGridDefs.Lower_Operator}
                    />
                );
            },
            ...DefaultColumnTypes.ShortText,
        },
        {
            field: 'lowerValue',
            headerComponentFramework: (props: any) => {
                return (
                    <CustomHeader
                        {...props}
                        termSet={termSet}
                        termKey={HazardClassRulesGridDefs.Lower_Value}
                    />
                );
            },
            ...DefaultColumnTypes.ShortText,
        },
        {
            field: 'upperOperator.operator',
            headerComponentFramework: (props: any) => {
                return (
                    <CustomHeader
                        {...props}
                        termSet={termSet}
                        termKey={HazardClassRulesGridDefs.Upper_Operator}
                    />
                );
            },
            ...DefaultColumnTypes.ShortText,
        },
        {
            field: 'upperValue',
            headerComponentFramework: (props: any) => {
                return (
                    <CustomHeader
                        {...props}
                        termSet={termSet}
                        termKey={HazardClassRulesGridDefs.Upper_Value}
                    />
                );
            },
            ...DefaultColumnTypes.ShortText,
        },
    ];

    const redirectToForm = (row: any) => {
        if (row.id) {
            navigate(`/formulation/hazardClassificationRules/${row.id}`);
        } else {
            navigate('/formulation/hazardClassificationRules/new');
        }
    };

    return {
        isLoadingHazardClassRules,
        columnDefs,
        redirectToForm,
        defaultColumnDefinitionOptions,
        hazardClassRulesList,
        canCreateHazardClassRules,
        canViewHazardClassRules,
        termSet,
    };
};

export default useHazardClassificationRuleGrid;
