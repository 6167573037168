import useDepreciationSummaryGrid from './hooks/useDepreciationSummaryGrid';
import { ColDef } from 'ag-grid-community';
import DefaultColumnTypes from '../../../components/grids/columns/Column.constants';
import BaseFormGrid from '../../../components/grids/BaseFormGrid';
import React from 'react';
import DepreciationSummaryModal from './DepreciationSummaryModal';
import { Button } from '@mui/material';
import ListIcon from '@mui/icons-material/List';
import { DepreciationSummaryGridDefs } from '../../../constants/i18n/translations/termDefinitions/accounting';
import TranslatableText from '../../../components/i18n/TranslatableText';

const DepreciationSummaryGrid = () => {
    const {
        showOptionsModal,
        setShowOptionsModal,
        setAssetEntryList,
        mainEntryRows,
        canViewDepreciationSummaryReport,
        termSet,
    } = useDepreciationSummaryGrid();

    const reportEntryColumnDefs: ColDef[] = [
        {
            field: 'asset.ledgerAssetType.code',
            headerComponentFramework: (props: any) => {
                return (
                    <TranslatableText
                        termSet={termSet}
                        termKey={DepreciationSummaryGridDefs.Asset_Type}
                    />
                );
            },
            ...DefaultColumnTypes.ShortText,
        },
        {
            field: 'asset.number',
            headerComponentFramework: (props: any) => {
                return (
                    <TranslatableText
                        termSet={termSet}
                        termKey={DepreciationSummaryGridDefs.Asset_No}
                    />
                );
            },
            sort: 'asc',
            ...DefaultColumnTypes.ShortText,
        },
        {
            field: 'asset.code',
            headerComponentFramework: (props: any) => {
                return (
                    <TranslatableText
                        termSet={termSet}
                        termKey={DepreciationSummaryGridDefs.Asset_Code}
                    />
                );
            },
            ...DefaultColumnTypes.ShortText,
        },
        {
            field: 'asset.name',
            headerComponentFramework: (props: any) => {
                return (
                    <TranslatableText
                        termSet={termSet}
                        termKey={DepreciationSummaryGridDefs.Asset_Name}
                    />
                );
            },
            ...DefaultColumnTypes.ShortText,
        },
        {
            field: 'asset.inServiceDate',
            headerComponentFramework: (props: any) => {
                return (
                    <TranslatableText
                        termSet={termSet}
                        termKey={DepreciationSummaryGridDefs.In_Service_Date}
                    />
                );
            },
            ...DefaultColumnTypes.DateOnly,
        },
        {
            field: 'asset.totalCost',
            headerComponentFramework: (props: any) => {
                return (
                    <TranslatableText
                        termSet={termSet}
                        termKey={DepreciationSummaryGridDefs.Total_Cost}
                    />
                );
            },
            ...DefaultColumnTypes.Currency,
        },
        {
            field: 'asset.remainingValue',
            headerComponentFramework: (props: any) => {
                return (
                    <TranslatableText
                        termSet={termSet}
                        termKey={DepreciationSummaryGridDefs.Beginning_Value}
                    />
                );
            },
            ...DefaultColumnTypes.Currency,
        },
        {
            field: 'asset.depreciationValue',
            headerComponentFramework: (props: any) => {
                return (
                    <TranslatableText
                        termSet={termSet}
                        termKey={DepreciationSummaryGridDefs.Depreciation}
                    />
                );
            },
            ...DefaultColumnTypes.Currency,
        },
        {
            field: 'asset.endingValue',
            headerComponentFramework: (props: any) => {
                return (
                    <TranslatableText
                        termSet={termSet}
                        termKey={DepreciationSummaryGridDefs.Ending_Value}
                    />
                );
            },
            ...DefaultColumnTypes.Currency,
        },
    ];

    return (
        <React.Fragment>
            <DepreciationSummaryModal
                termSet={termSet}
                open={showOptionsModal}
                onDepreciationSummaryLoaded={(assetDisposal) =>
                    setAssetEntryList(assetDisposal)
                }
                onClose={() => setShowOptionsModal(false)}
            />

            <BaseFormGrid
                title={
                    <TranslatableText
                        termSet={termSet}
                        termKey={
                            DepreciationSummaryGridDefs.Depreciation_Summary_Report
                        }
                    />
                }
                defaultColDef={{ flex: 1 }}
                displayGrid={canViewDepreciationSummaryReport}
                isLoading={false}
                rowData={mainEntryRows}
                columnDefs={reportEntryColumnDefs}
                gridClass="full-size-item"
                displayToolbar={true}
                toolbarChildren={
                    <Button
                        variant="text"
                        size="small"
                        onClick={() => setShowOptionsModal(true)}
                        startIcon={<ListIcon />}>
                        Options
                    </Button>
                }
                displayExportDataButton={true}
            />
        </React.Fragment>
    );
};

export default DepreciationSummaryGrid;
