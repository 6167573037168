import { ExtendedFormProps } from '../../../../../form/helpers/ExtendedFormProps';
import { ItemMaster } from '../../../../../../types/formulation';
import { Grid } from '@mui/material';
import CurrencyInput from '../../../../../../components/form/formInputs/CurrencyInput/CurrencyInput';
import { NumberMaxValues } from '../../../../../../types/Shared.types';
import FormPartial from '../../../../../../components/form/formContainer/FormPartial';
import React from 'react';
import DatacorLogoSpinner from '../../../../../../components/datacorLogoSpinner/DatacorLogoSpinner';
import TranslatableText from '../../../../../../components/i18n/TranslatableText';
import { ItemMasterFormDefs } from '../../../../../../constants/i18n/translations/termDefinitions/formulation';

export interface ItemCostFormProps extends ExtendedFormProps<ItemMaster> {
    termSet?: any;
}

const ItemCostForm = (props: ItemCostFormProps) => {
    const { currentParentRecord, isParentLoading } = props.parentData;
    const { formMethods, handleFieldChange } = props.helpers;
    const { readOnly } = props.permissions;
    const { termSet } = props;

    return (
        <FormPartial
            title={
                (
                    <TranslatableText
                        termSet={termSet}
                        termKey={ItemMasterFormDefs.Cost}
                    />
                ) as unknown as string
            }>
            {isParentLoading ? (
                <DatacorLogoSpinner />
            ) : (
                <>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <CurrencyInput
                                fullWidth
                                label={
                                    <TranslatableText
                                        termSet={termSet}
                                        termKey={ItemMasterFormDefs.Cost}
                                    />
                                }
                                id="cost"
                                formMethods={formMethods}
                                value={currentParentRecord.cost}
                                onChange={handleFieldChange}
                                maxValue={NumberMaxValues.decimal206}
                                numDecimals={6}
                                greaterThan={0}
                                disabled={readOnly}
                            />
                        </Grid>
                        <Grid item xs={3}></Grid>
                    </Grid>
                </>
            )}
        </FormPartial>
    );
};
export default ItemCostForm;
