import React from 'react';
import BaseFormGrid from '../../../components/grids/BaseFormGrid';
import useCurrencyGrid from './hooks/useCurrencyGrid';
import { CurrencyGridDefs } from '../../../constants/i18n/translations/termDefinitions/accounting';
import TranslatableText from '../../../components/i18n/TranslatableText';
import { isNilOrEmpty } from '../../../utils/objectUtils';
import { cloneDeep } from 'lodash';

const CurrencyGrid = () => {
    const {
        canViewCurrencies,
        canCreateCurrencies,
        canUpdateCurrencies,
        termSet,
        currencyList,
        isLoadingCurrencies,
        redirectToForm,
        handleGridEdits,
        colDefs,
    } = useCurrencyGrid();

    return (
        !isNilOrEmpty(colDefs) && (
            <BaseFormGrid
                title={
                    (
                        <TranslatableText
                            termKey={CurrencyGridDefs.Title}
                            termSet={termSet}
                        />
                    ) as unknown as string
                }
                displayGrid={canViewCurrencies}
                isLoading={isLoadingCurrencies}
                rowData={cloneDeep(currencyList)}
                useRowDrag={canUpdateCurrencies}
                sortKey={'sortOrder'}
                columnDefs={colDefs}
                gridClass="full-size-item"
                displayToolbar={true}
                displayCreateNewButton={canCreateCurrencies}
                displayExportDataButton={true}
                displayResetStateButton={true}
                onCreateNew={redirectToForm}
                onRowClicked={({ data }) => redirectToForm(data)}
                onHandleGridEdits={handleGridEdits}
            />
        )
    );
};

export default CurrencyGrid;
