import { useForm } from '../../../../../libs/hooksLib';
import { useCreateFixedAssetDisposalEntriesMutation } from '../../../../../services/fixedAssets/fixedAssets.service';
import moment from 'moment';
import { isNil } from 'lodash';
import { CreateDisposalEntriesProps } from '../FixedAssetCreateDisposalEntriesModal';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../store';
import { PermissionsUtil } from '../../../../../utils/permissions/permissionsUtil';
import { PERMISSIONS } from '../../../../../constants/permissions/Permissions.constants';
import { isNilOrEmpty } from '../../../../../utils/objectUtils';

const useFixedAssetCreateDisposalEntriesModal = (
    props: CreateDisposalEntriesProps
) => {
    const user = useSelector((state: RootState) => state.user);

    const [createDisposalEntries, { isLoading: isCreatingDisposalEntries }] =
        useCreateFixedAssetDisposalEntriesMutation();

    const canLockDisposalEntry = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.FIXED_ASSETS.ASSET_ENTRY.LOCK
    );

    const initialFields: any = {
        cashReceived: null,
        disposalDate: new Date(),
        lockEntries: false,
    };

    const { fields, handleFieldChange } = useForm(initialFields);

    const canSubmitEntry = (): boolean => {
        const isValid = !isNilOrEmpty(fields.cashReceived)
            ? isFinite(fields.cashReceived) && fields.cashReceived >= 0
            : true;
        return (
            isValid &&
            !isNil(fields.disposalDate) &&
            moment(fields.disposalDate).isValid()
        );
    };

    const onCreateDisposalEntries = async (event: any) => {
        event.preventDefault();

        try {
            const response: any = await createDisposalEntries({
                ids: props.ids,
                settlementAmount: fields.cashReceived,
                disposalDate: fields.disposalDate,
                lockEntries: fields.lockEntries,
            });

            props.onDisposalCreated(response.data);
            props.onClose();
        } catch (e) {
            console.log(e);
        }
    };

    return {
        fields,
        handleFieldChange,
        canSubmitEntry,
        isCreatingDisposalEntries,
        onCreateDisposalEntries,
        canLockDisposalEntry,
    };
};

export default useFixedAssetCreateDisposalEntriesModal;
