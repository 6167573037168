import React from 'react';
import { useParams } from 'react-router-dom';
import { Grid } from '@mui/material';
import BaseForm from '../../../components/form/baseForm/BaseForm';
import FormPartial from '../../../components/form/formContainer/FormPartial';
import useProperShippingNameForm from '../hooks/useProperShippingNameForm';
import TextInput from '../../../components/form/formInputs/TextInput/TextInput';

type ParamTypes = { id: string };

const ProperShippingNameForm = () => {
    const { id } = useParams<ParamTypes>();
    const {
        fields,
        handleFieldChange,
        formMethods,
        onCreate,
        onUpdate,
        onDelete,
        closeForm,
        isLoadingProperShippingName,
        canCreateProperShippingName,
        canUpdateProperShippingName,
        canDeleteProperShippingName,
    } = useProperShippingNameForm(id);

    return (
        <BaseForm
            title={'DOT Proper Shipping Name'}
            formMethods={formMethods}
            isLoading={isLoadingProperShippingName}
            isNew={!id}
            onCreate={onCreate}
            onUpdate={onUpdate}
            onDelete={onDelete}
            onCancel={closeForm}
            displayCreateButton={canCreateProperShippingName}
            displayUpdateButton={canUpdateProperShippingName}
            displayDeleteButton={canDeleteProperShippingName}
            readOnly={id && !canUpdateProperShippingName}>
            <FormPartial>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <TextInput
                            fullWidth
                            label="Code"
                            id="code"
                            formMethods={formMethods}
                            value={fields.code}
                            onChange={handleFieldChange}
                            required={true}
                            maxLength={10}
                            disabled={!!id}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextInput
                            fullWidth
                            label="Proper Shipping Name"
                            id="properShippingName"
                            formMethods={formMethods}
                            value={fields.properShippingName}
                            onChange={handleFieldChange}
                            required={true}
                            maxLength={250}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextInput
                            fullWidth
                            label="Symbol"
                            id="symbol"
                            formMethods={formMethods}
                            value={fields.symbol}
                            onChange={handleFieldChange}
                            maxLength={8}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextInput
                            fullWidth
                            label="Hazard Class"
                            id="hazardClass"
                            formMethods={formMethods}
                            value={fields.hazardClass}
                            onChange={handleFieldChange}
                            maxLength={20}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextInput
                            fullWidth
                            label="DOT UN/NA#"
                            id="unNumber"
                            formMethods={formMethods}
                            value={fields.unNumber}
                            onChange={handleFieldChange}
                            maxLength={6}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextInput
                            fullWidth
                            label="Packaging Group"
                            id="packagingGroup"
                            formMethods={formMethods}
                            value={fields.packagingGroup}
                            onChange={handleFieldChange}
                            maxLength={6}
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <TextInput
                            fullWidth
                            label="Label Code"
                            id="labelCode"
                            formMethods={formMethods}
                            value={fields.labelCode}
                            onChange={handleFieldChange}
                            maxLength={15}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextInput
                            fullWidth
                            label="Freight Class"
                            id="freightClass"
                            formMethods={formMethods}
                            value={fields.freightClass}
                            onChange={handleFieldChange}
                            maxLength={6}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextInput
                            fullWidth
                            label="NMFC Number"
                            id="nmfcNumber"
                            formMethods={formMethods}
                            value={fields.nmfcNumber}
                            onChange={handleFieldChange}
                            maxLength={6}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextInput
                            fullWidth
                            label="Special Provisions"
                            id="specialProvisions"
                            formMethods={formMethods}
                            value={fields.specialProvisions}
                            onChange={handleFieldChange}
                            maxLength={100}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextInput
                            fullWidth
                            label="Technical Name"
                            id="technicalName"
                            formMethods={formMethods}
                            value={fields.technicalName}
                            onChange={handleFieldChange}
                            maxLength={20}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextInput
                            fullWidth
                            label="Packaging Exceptions"
                            id="packagingExceptions"
                            formMethods={formMethods}
                            value={fields.packagingExceptions}
                            onChange={handleFieldChange}
                            maxLength={20}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextInput
                            fullWidth
                            label="Packaging Non-Bulk"
                            id="packagingNonBulk"
                            formMethods={formMethods}
                            value={fields.packagingNonBulk}
                            onChange={handleFieldChange}
                            maxLength={20}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextInput
                            fullWidth
                            label="Packaging Bulk"
                            id="packagingBulk"
                            formMethods={formMethods}
                            value={fields.packagingBulk}
                            onChange={handleFieldChange}
                            maxLength={20}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextInput
                            fullWidth
                            label="Quantity Passenger Air/Rail"
                            id="quantityPassenger"
                            formMethods={formMethods}
                            value={fields.quantityPassenger}
                            onChange={handleFieldChange}
                            maxLength={20}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextInput
                            fullWidth
                            label="Quantity Cargo Air Only"
                            id="quantityCargo"
                            formMethods={formMethods}
                            value={fields.quantityCargo}
                            onChange={handleFieldChange}
                            maxLength={20}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextInput
                            fullWidth
                            label="Vessel Stowage LOC"
                            id="vesselStowageLoc"
                            formMethods={formMethods}
                            value={fields.vesselStowageLoc}
                            onChange={handleFieldChange}
                            maxLength={20}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextInput
                            fullWidth
                            label="Vessel Stowage Other"
                            id="vesselStowageOther"
                            formMethods={formMethods}
                            value={fields.vesselStowageOther}
                            onChange={handleFieldChange}
                            maxLength={65}
                        />
                    </Grid>
                </Grid>
            </FormPartial>
        </BaseForm>
    );
};

export default ProperShippingNameForm;
