import { RegulationBody } from '../../../../types/formulation';
import { SelectionOption } from '../../../../types/Shared.types';
import { datacorAppApi } from '../../../datacorAppApi';

const serviceUrl: string = 'formulation/regulation-bodies';

export const regulationBodyApi = datacorAppApi.injectEndpoints({
    endpoints: (build) => ({
        getRegulationBodiesForDropDown: build.query<SelectionOption[], void>({
            query: () => serviceUrl,
            transformResponse: (response: RegulationBody[]) => {
                return response.map((regulationBody: RegulationBody) => {
                    return {
                        label: regulationBody.description,
                        value: regulationBody.id,
                    };
                });
            },
            providesTags: ['RegulationBody'],
        }),
    }),
});

export const { useGetRegulationBodiesForDropDownQuery } = regulationBodyApi;
