import { useForm } from '../../../libs/hooksLib';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    User,
    useCreateUserMutation,
    useUpdateUserMutation,
    useDeleteUserMutation,
    useGetUserQuery,
    useGetMessagingPreferencesForDropdownQuery,
    useGetDeparmentCodesForDropdownQuery,
    useGetUsersForDropdownQuery,
    Employee,
    useGetSubscribersForDropdownQuery,
    BusinessEntity,
    Subscriber,
    useGetRolesByBusinessEntitiesForDropdownQuery,
    useGetGroupsByBusinessEntitiesForDropdownQuery,
    useResendUserTempCredentialsMutation,
    UserTrial,
    organizationsApi,
} from '../../../services/organizations/organizations.service';
import {
    BaseCreateEntityArgs,
    BaseUpdateEntityArgs,
} from '../../../services/serviceInterfaces';
import { skipToken } from '@reduxjs/toolkit/query';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store';
import config from '../../../config';
import {
    openModalConfirmBasicWithMessage,
    openSnackbarBasicWithMessage,
} from '../../../store/uiElements';
import useGetBusinessEntities from './useGetBusinessEntities';
import { isEqual, isNull } from 'lodash';
import { isNilOrEmpty } from '../../../utils/objectUtils';
import { PermissionsUtil } from '../../../utils/permissions/permissionsUtil';
import { PERMISSIONS } from '../../../constants/permissions/Permissions.constants';
import { showNotificationError } from '../../../libs/errorLib';
import LoaderButton from '../../../components/LoaderButton';
import EventRepeatIcon from '@mui/icons-material/EventRepeat';
import { useGetTermSetQuery } from '../../../services/i18n/i18n.service';
import SettingsContext from '../../../contexts/settings.context';
import { PLATFORM_DEFS } from '../../../constants/i18n/translations/termSetDefinitions/platform';

export enum UserStatusTypes {
    CONFIRMED = 'CONFIRMED',
    FORCE_CHANGE_PASSWORD = 'FORCE_CHANGE_PASSWORD',
}

export const useUserForm = (id: string) => {
    const dispatch = useDispatch();
    const user = useSelector((state: RootState) => state.user);
    const navigate = useNavigate();
    const [
        defaultSelectedBusinessEntities,
        setDefaultSelectedBusinessEntities,
    ] = useState(!id ? [user?.businessEntity?.id] : []);
    const [apaOptions, setApaOptions] = useState(null);
    const { fields, handleFieldChange, setValues, formMethods, isFormValid } =
        useForm({});
    const {
        fields: employeeFields,
        handleFieldChange: handleEmployeeFieldChange,
        setValues: setEmployeeValues,
        formMethods: employeeFormMethods,
        isFormValid: isEmployeeFormValid,
    } = useForm({});
    const {
        fields: userTrialFields,
        handleFieldChange: handleUserTrialFieldChange,
        setValues: setUserTrialValues,
        formMethods: userTrialFormMethods,
        isFormValid: isUserTrialFormValid,
    } = useForm({});

    const { data: userData, isLoading } = useGetUserQuery(
        id ? { userId: id, full: true } : skipToken
    );
    const { data: messagingPreferenceOptions } =
        useGetMessagingPreferencesForDropdownQuery();

    const { settings } = useContext(SettingsContext);
    const { data: termSet } = useGetTermSetQuery(
        settings?.userSettings
            ? {
                  languageId: settings?.userSettings?.languageId,
                  code: PLATFORM_DEFS.USER_FORM,
              }
            : skipToken
    );

    const { data: groupsOptions } =
        useGetGroupsByBusinessEntitiesForDropdownQuery(
            fields.businessEntities
                ? fields.businessEntities.map(
                      (businessEntity: BusinessEntity) => businessEntity?.id
                  )
                : skipToken
        );
    const { data: rolesOptions } =
        useGetRolesByBusinessEntitiesForDropdownQuery(
            fields.businessEntities
                ? fields.businessEntities.map(
                      (businessEntity: BusinessEntity) => businessEntity?.id
                  )
                : skipToken
        );
    const {
        businessEntities: businessEntityOptions,
        isLoading: isLoadingBusinessEnts,
    } = useGetBusinessEntities({
        businessEntityId: user?.activeBusinessEntityId?.toString(),
        subscriberId: user?.subscriber?.id?.toString(),
    });

    const { data: subscriberOptions } = useGetSubscribersForDropdownQuery();

    const { data: departmentCodeOptions } =
        useGetDeparmentCodesForDropdownQuery(
            user?.subscriber?.id
                ? {
                      id: user?.subscriber?.id?.toString(),
                  }
                : skipToken
        );
    const { data: usersDropdownOptions } = useGetUsersForDropdownQuery();
    const [createUser] = useCreateUserMutation();
    const [updateUser] = useUpdateUserMutation();
    const [deleteUser] = useDeleteUserMutation();

    const [resendUserCredentials, { isLoading: isResending }] =
        useResendUserTempCredentialsMutation();

    const canEditUser = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.ADMINISTRATION.USERS.EDIT
    );

    const canCreateUser = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.ADMINISTRATION.USERS.CREATE
    );

    const canDeleteUser = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.ADMINISTRATION.USERS.DELETE
    );

    const userHasTempPassword =
        userData?.cognitoUserStatus === UserStatusTypes.FORCE_CHANGE_PASSWORD;

    const defaultUser: User = {
        active: true,
        firstName: '',
        lastName: '',
        middleName: '',
        mobilePhone: '',
        email: '',
        businessEntityId: user?.businessEntity?.id,
        messagingPrefId: null,
        groups: [],
        roles: [],
        isDatacorAdmin: false,
        isAppStreamUser: false,
        externalUser: '',
        apAutomationInvoiceTargetBusinessEntityId: null,
        userPoolId: config.cognito.USER_POOL_ID,
        //adding the default BE and subscriber to user form
        businessEntities: [user?.businessEntity],
        subscribers: [user?.subscriber?.id],
    };

    const defaultEmployee: Employee = {
        departmentCodeId: null,
        supervisorId: null,
        userId: null,
    };

    const defaultUserTrial: UserTrial = {
        isTrialUser: null,
        trialStartDate: null,
        trialEndDate: null,
    };

    useEffect(() => {
        if (
            fields?.isDatacorAdmin ||
            (!isNilOrEmpty(fields.subscribers) && fields.subscribers[0])
        ) {
            handleGetApaInvoiceTargets();
        }
    }, [
        user,
        userData,
        businessEntityOptions,
        fields.subscribers,
        fields.isDatacorAdmin,
        fields.businessEntities,
    ]);

    const handleGetApaInvoiceTargets = async () => {
        if (!isNilOrEmpty(subscriberOptions)) {
            let allBusinessEntities = [];
            const subOptions = fields?.isDatacorAdmin
                ? subscriberOptions
                : fields?.subscribers;
            for (const subscriber of subOptions) {
                const businessEntity = await dispatch(
                    organizationsApi.endpoints.getBusinessEntitiesForDropdown.initiate(
                        fields.isDatacorAdmin
                            ? subscriber?.value?.toString()
                            : subscriber.toString()
                    )
                );
                //@ts-ignore
                allBusinessEntities.push(...businessEntity.data);
            }

            if (!user?.subscriber?.isDatacor) {
                //filter out non selected business entities
                allBusinessEntities = allBusinessEntities.filter(
                    (businessEntity: any) =>
                        fields.businessEntities.some(
                            (be: any) => be.id === businessEntity.id
                        )
                );
            }

            setApaOptions(allBusinessEntities);
        }
    };

    useEffect(() => {
        setValues(
            userData
                ? {
                      ...userData,
                      active: userData?.active ? 'true' : 'false',
                      // need to get the ids only
                      subscribers: userData?.subscribers?.map(
                          (subscriber: Subscriber) => subscriber.id
                      ),
                  }
                : defaultUser
        );
        setEmployeeValues(userData?.employee || defaultEmployee);
        setUserTrialValues(userData?.userTrial || defaultUserTrial);
        if (userData) {
            setDefaultSelectedBusinessEntities(
                userData?.businessEntities?.map(
                    (businessEntity: BusinessEntity) => businessEntity.id
                ) || []
            );
        }
    }, [userData, user, subscriberOptions]);

    useEffect(() => {
        if (!isNilOrEmpty(fields.isDatacorAdmin)) {
            if (fields.isDatacorAdmin) {
                setValues({
                    businessEntities: null,
                    subscribers: null,
                    roles: [],
                });
            }

            if (!fields.isDatacorAdmin) {
                setValues({
                    subscribers: [user?.subscriber?.id],
                });
            }
        }
    }, [fields.isDatacorAdmin]);

    const handleDeleteUser = async () => {
        try {
            await deleteUser(fields.id).unwrap();
            handleClose();
        } catch (e) {
            return showNotificationError(
                'Unable to delete this user right now.',
                'error',
                dispatch
            );
        }
    };

    const showDeleteConfirmModal = async () => {
        const message = `Are you sure you want to delete ${fields.firstName} ${fields.lastName}?`;

        dispatch(
            openModalConfirmBasicWithMessage({
                message: message,
                title: '',
                onConfirm: handleDeleteUser,
            })
        );
    };

    const showIsDatacorCompanyConfirmModal = async () => {
        if (user?.subscriber?.isDatacor) {
            const message = `You are adding ${fields.firstName} ${fields.lastName} to ${user?.subscriber?.name}. 
            This will give them Admin access to selected subscribers. Are you sure you want to proceed?`;

            dispatch(
                openModalConfirmBasicWithMessage({
                    message: message,
                    title: '',
                    onConfirm: handleCreateUser,
                })
            );
        } else {
            await handleCreateUser();
        }
    };

    const handleCreateUser = async () => {
        if (!isFormValid || !isEmployeeFormValid || !isUserTrialFormValid) {
            return;
        }

        let businessEntitiesToUpdate = null;

        if (fields?.businessEntities !== userData?.businessEntities) {
            businessEntitiesToUpdate =
                fields?.businessEntities?.length > 0
                    ? fields.businessEntities.map(
                          (businessEntity: BusinessEntity) => businessEntity.id
                      )
                    : [user?.businessEntity?.id];
        }

        if (isNull(userTrialFields?.isTrialUser)) {
            userTrialFields.isTrialUser = false;
        }

        const body: BaseCreateEntityArgs<User> = {
            postBody: {
                ...fields,
                email: fields.email.trim(),
                businessEntities: businessEntitiesToUpdate,
                userPoolId: config.cognito.USER_POOL_ID,
                employee: { ...employeeFields },
                userTrial: { ...userTrialFields },
            },
        };

        try {
            await createUser(body).unwrap();
            handleClose();
        } catch (e) {
            return showNotificationError(
                'Unable to create this user right now.',
                'error',
                dispatch
            );
        }
    };

    const handleUpdateUser = async () => {
        if (!isFormValid || !isEmployeeFormValid || !isUserTrialFormValid) {
            return;
        }

        if (fields.groups === userData.groups) {
            fields.groups = null;
        }

        if (fields.roles === userData.roles) {
            fields.roles = null;
        }

        let businessEntitiesToUpdate = null;

        if (
            !isEqual(fields.businessEntities, userData.businessEntities) &&
            fields.businessEntities
        ) {
            businessEntitiesToUpdate =
                fields?.businessEntities?.length > 0
                    ? fields.businessEntities.map(
                          (businessEntity: BusinessEntity) => businessEntity?.id
                      )
                    : [fields?.businessEntities?.id];
        }

        if (
            isEqual(
                fields.subscribers,
                userData.subscribers.map(
                    (subscriber: Subscriber) => subscriber.id
                )
            )
        ) {
            fields.subscribers = null;
        }

        if (isNull(userTrialFields?.isTrialUser)) {
            userTrialFields.isTrialUser = false;
        }

        const body: BaseUpdateEntityArgs<User> = {
            id: id as unknown as number,
            postBody: {
                ...fields,
                active: fields?.active === 'true',
                email: fields.email.trim(),
                businessEntities: businessEntitiesToUpdate,
                employee: { ...employeeFields },
                userTrial: { ...userTrialFields },
                userPoolId: config.cognito.USER_POOL_ID,
            },
        };

        try {
            await updateUser(body).unwrap();
            handleClose();
        } catch (e) {
            return showNotificationError(
                'Unable to update this user right now.',
                'error',
                dispatch
            );
        }
    };

    const handleClose = () => {
        navigate('/administration/organizations/users');
    };

    const handleResendTempCredentials = async () => {
        try {
            await resendUserCredentials({
                loginName: userData.email,
                userPoolId: config.cognito.USER_POOL_ID,
            });

            dispatch(
                openSnackbarBasicWithMessage({
                    message: `Temp credentials have been resent to: ${userData.email}`,
                    severity: 'success',
                })
            );
        } catch (e) {
            return showNotificationError(e.message, 'error', dispatch);
        }
    };

    const additionalToolbarButtons = () => {
        return (
            <>
                {userHasTempPassword && (
                    <>
                        <LoaderButton
                            className="form-header-button"
                            variant="text"
                            data-testid="resendButton"
                            onClick={handleResendTempCredentials}
                            isLoading={isResending}>
                            <EventRepeatIcon />
                            <div className="form-header-button-text">
                                Resend <br />
                                Credentials
                            </div>
                        </LoaderButton>
                    </>
                )}
            </>
        );
    };

    return {
        fields,
        user,
        employeeFields,
        userTrialFields,
        userData,
        isLoading,
        businessEntityOptions,
        subscriberOptions,
        messagingPreferenceOptions,
        groupsOptions,
        rolesOptions,
        departmentCodeOptions,
        usersDropdownOptions,
        handleCreateUser,
        handleUpdateUser,
        handleFieldChange,
        handleEmployeeFieldChange,
        handleUserTrialFieldChange,
        handleClose,
        setValues,
        employeeFormMethods,
        userTrialFormMethods,
        formMethods,
        defaultSelectedBusinessEntities,
        canCreateUser,
        canEditUser,
        canDeleteUser,
        additionalToolbarButtons,
        showIsDatacorCompanyConfirmModal,
        apaOptions,
        showDeleteConfirmModal,
        termSet,
    };
};
