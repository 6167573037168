import React from 'react';
import BaseGrid from '../../../../../../../../../components/grids/BaseGrid';
import useSdsGhsHazardPhrasesGrid from './hooks/useSdsGhsHazardPhrasesGrid';
import {
    GhsHazardPhrase,
    SdsGhsHazardPhrase,
    SdsHeader,
} from '../../../../../../../../../types/formulation';
import { GridOptions } from '../../../../../../../../../components/grids/Grid.constants';
import DatacorLogoSpinner from '../../../../../../../../../components/datacorLogoSpinner/DatacorLogoSpinner';
import PhraseVerbiageMessageModal from '../../../../../../../shared/phraseVerbiageMessageModal/PhraseVerbiageMessageModal';
import GhsHazardPhrasesSelectionModal from '../../../../../../../shared/multiSelectModals/ghsHazardPhrases/GhsHazardPhrasesSelectionModal';
import TranslatableText from '../../../../../../../../../components/i18n/TranslatableText';
import { SDSFormDefs } from '../../../../../../../../../constants/i18n/translations/termDefinitions/formulation';
import { ChildEditableGridWithCopyFunctionProps } from '../../../../../../../../grid/utils/editableGrid/ChildEditableGridWithCopyFunctionProps';

const SdsGhsHazardPhrasesGrid = (
    props: ChildEditableGridWithCopyFunctionProps<SdsHeader, SdsGhsHazardPhrase>
) => {
    const {
        rowsData,
        hazardPhraseList,
        showMultiSelectGridModal,
        columnDefs,
        handleSetShowMultiSelectGridModal,
        handleSelectedRecords,
        handleSaveGridEdits,
        handleRowValidations,
        showVerbiageMessage,
        handleShowVerbiageMessage,
        termSet,
    } = useSdsGhsHazardPhrasesGrid(props);

    const { isParentLoading } = props.parentData;

    const { showAddButton, showDeleteButton, showSortButton, isGridEditable } =
        props.displayGridButtons;

    const colDefOptions = {
        ...GridOptions.sortFilterAndWrapColumns,
        floatingFilter: true,
    };

    return !columnDefs ? (
        <DatacorLogoSpinner />
    ) : (
        <React.Fragment>
            <GhsHazardPhrasesSelectionModal
                openModal={showMultiSelectGridModal}
                onClose={() => handleSetShowMultiSelectGridModal(false)}
                onSelected={(selectedRecords: GhsHazardPhrase[]) =>
                    handleSelectedRecords(selectedRecords)
                }
                hazardPhraseList={hazardPhraseList}
            />
            <BaseGrid
                autoSize={true}
                displayGrid={!isParentLoading}
                useRowDrag={showSortButton}
                displaySortButton={showSortButton}
                sortKey={'sdsSortOrder'}
                rowData={rowsData}
                dataKey={'phraseId'}
                columnDefs={columnDefs}
                defaultColDef={colDefOptions}
                gridClass="full-size-item"
                displayToolbar={true}
                displayCreateNewButton={showAddButton}
                displayExportDataButton={false}
                displayResetStateButton={false}
                displayDeleteButton={showDeleteButton}
                sizeColumnsToFit={true}
                onCreateNew={() => handleSetShowMultiSelectGridModal(true)}
                onHandleRowValidations={handleRowValidations}
                onHandleGridEdits={(records) => handleSaveGridEdits(records)}
                isEditable={isGridEditable}
                gridButtonTitle={'GHS Hazard Phrases'}
                newRowPlaceholderValues={{
                    phraseId: 'Enter Phrase ID...',
                    phraseText: 'Enter Phrase Text...',
                    hazardTypeId: 'Select a value',
                    sdsSortOrder: 'Enter a Sort Order Value',
                }}
                createRow={false}></BaseGrid>
            <PhraseVerbiageMessageModal
                showModal={showVerbiageMessage}
                handleClose={() => handleShowVerbiageMessage(false)}
                messageText={
                    (
                        <TranslatableText
                            termSet={termSet}
                            termKey={
                                SDSFormDefs.GHS_Hazard_Phrases_Selection_Product_Specific_Verbiage
                            }
                        />
                    ) as unknown as string
                }
            />
        </React.Fragment>
    );
};

export default SdsGhsHazardPhrasesGrid;
