import { HazardClassificationStep } from '../../../../types/formulation';
import { datacorAppApi } from '../../../datacorAppApi';

const serviceUrl: string = 'hazardClassificationStep';

export const hazardClassificationStepApi = datacorAppApi.injectEndpoints({
    endpoints: (build) => ({
        getHazardClassificationStepForDropdown: build.query<
            { label: string; value: bigint }[],
            void
        >({
            query: () => serviceUrl,
            transformResponse: (response: HazardClassificationStep[]) => {
                return response.map((step: HazardClassificationStep) => {
                    return {
                        label: step.code,
                        value: step.id,
                    };
                });
            },
            providesTags: ['HazardClassificationStep'],
        }),
    }),
});

export const { useGetHazardClassificationStepForDropdownQuery } =
    hazardClassificationStepApi;
