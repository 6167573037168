import React from 'react';
import { useParams } from 'react-router-dom';
import { Grid } from '@mui/material';
import TextInput from '../../components/form/formInputs/TextInput/TextInput';
import FormPartial from '../../components/form/formContainer/FormPartial';
import useDepartmentCodeForm from './hooks/useDepartmentCodeForm';
import BaseForm from '../../components/form/baseForm/BaseForm';
import { DepartmentCodesFormDefs } from '../../constants/i18n/translations/termDefinitions/platform';
import TranslatableText from '../../components/i18n/TranslatableText';

type ParamTypes = { id: string };

const DepartmentCodeForm = () => {
    const { id } = useParams<ParamTypes>();
    const {
        fields,
        handleClose,
        handleFieldChange,
        handleUpdateDepartmentCode,
        handleCreateDepartmentCode,
        isLoading,
        formMethods,
        termSet,
    } = useDepartmentCodeForm(id);

    return (
        <BaseForm
            title={
                id ? (
                    fields.name
                ) : (
                    <TranslatableText
                        termKey={
                            DepartmentCodesFormDefs.Create_New_Department_Code
                        }
                        termSet={termSet}
                    />
                )
            }
            formMethods={formMethods}
            isLoading={isLoading}
            isNew={!id}
            onCancel={handleClose}
            onCreate={handleCreateDepartmentCode}
            onUpdate={handleUpdateDepartmentCode}
            displayDeleteButton={false}
            displayUpdateButton={true}
            displayCreateButton={true}>
            <FormPartial>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <TextInput
                            fullWidth
                            label={
                                <TranslatableText
                                    termKey={DepartmentCodesFormDefs.Name}
                                    termSet={termSet}
                                />
                            }
                            id="name"
                            value={fields.name}
                            onChange={handleFieldChange}
                            required={true}
                            maxLength={90}
                            formMethods={formMethods}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextInput
                            fullWidth
                            label={
                                <TranslatableText
                                    termKey={DepartmentCodesFormDefs.Code}
                                    termSet={termSet}
                                />
                            }
                            id="code"
                            value={fields.code}
                            onChange={handleFieldChange}
                            maxLength={90}
                            formMethods={formMethods}
                        />
                    </Grid>
                </Grid>
            </FormPartial>
        </BaseForm>
    );
};

export default DepartmentCodeForm;
