/*
In order to authenticate through the DatacorId user pool we need to use that user pools USER_POOL_ID and APP_CLIENT_ID as
this gives us the necessary jwt with customer IP address and port info needed to access on-prem databased.
The user pool created by this serverless app has the following info (someday we should make the necessary changes to use this again).
To do that we need to pull the lambda functions used by DatacorId user pool as triggers into our serverless app so that they get built in
the Development and Production AWS accounts
Dev:
USER_POOL_ID: "us-east-2_VSP1gZOEp",
APP_CLIENT_ID: "1m1p25pa94je3vlc2kjljci3ps",

Prod:
USER_POOL_ID: "us-east-2_945sx8Me3",
APP_CLIENT_ID: "2ddlpdcq7fid3ld026u15erk6t",
 */

/* istanbul ignore file */

const dev = {
    s3: {
        REGION: 'us-east-2',
        BUCKET: 'datacor-app-attachments-dev-s3bucket-i3psryfp5oy5',
    },
    apiGateway: {
        REGION: 'us-east-2',
        URL: 'https://4frc4zrjxe.execute-api.us-east-2.amazonaws.com/dev',
    },
    cognito: {
        REGION: 'us-east-2',
        USER_POOL_ID: 'us-east-2_VSP1gZOEp',
        APP_CLIENT_ID: '1m1p25pa94je3vlc2kjljci3ps',
        IDENTITY_POOL_ID: 'us-east-2:dcee0ead-4014-4f4f-89ca-8e9cbb120551',
    },
};

const aweiss = {
    s3: {
        REGION: 'us-east-2',
        BUCKET: 'datacor-app-attachments-aweiss-s3bucket-6tvhtkpghl9',
    },
    apiGateway: {
        REGION: 'us-east-2',
        URL: 'https://bygfw658pl.execute-api.us-east-2.amazonaws.com/aweiss',
    },
    cognito: {
        REGION: 'us-east-2',
        USER_POOL_ID: 'us-east-2_JmHFZBy6q',
        APP_CLIENT_ID: 'hjhhhh0gc2htmdoucf3rgl4k3',
        IDENTITY_POOL_ID: 'us-east-2:4a49391a-1a25-4522-98d1-5a25a4a82a39',
    },
};

const zduford = {
    s3: {
        REGION: 'us-east-2',
        BUCKET: 'datacor-app-attachments-zduford-s3bucket-11e0r3vp0mpjt',
    },
    apiGateway: {
        REGION: 'us-east-2',
        URL: 'https://9yjkssydyh.execute-api.us-east-2.amazonaws.com/zduford',
    },
    cognito: {
        REGION: 'us-east-2',
        USER_POOL_ID: 'us-east-2_Xlo6eaMl5',
        APP_CLIENT_ID: '6l2jhturb55lhmv01dkms6dmft',
        IDENTITY_POOL_ID: 'us-east-2:c52778a9-2c36-427d-92db-62f6437a4cd6',
    },
};

const tperez = {
    s3: {
        REGION: 'us-east-2',
        BUCKET: 'datacor-app-attachments-tperez-s3bucket-1ib1sq64w5hl9',
    },
    apiGateway: {
        REGION: 'us-east-2',
        URL: 'https://hrl4itl2mc.execute-api.us-east-2.amazonaws.com/tperez',
    },
    cognito: {
        REGION: 'us-east-2',
        USER_POOL_ID: 'us-east-2_IcH5KsCtt',
        APP_CLIENT_ID: '1qpgldcfd8at9h0s65n6ie1e8d',
        IDENTITY_POOL_ID: 'us-east-2:716a20a0-c7da-4608-b5ec-c0053272c68f',
    },
};

const glazo = {
    s3: {
        REGION: 'us-east-2',
        BUCKET: 'datacor-app-attachments-glazo-s3bucket-8o2dceoalmty',
    },
    apiGateway: {
        REGION: 'us-east-2',
        URL: 'https://or9flet4f9.execute-api.us-east-2.amazonaws.com/glazo',
    },
    cognito: {
        REGION: 'us-east-2',
        USER_POOL_ID: 'us-east-2_TSLhxjpiW',
        APP_CLIENT_ID: '2grivkht3v5ns3lbnqrerfctf',
        IDENTITY_POOL_ID: 'us-east-2:cc9d4490-5a83-49d1-8e10-0a7361f1e33b',
    },
};

const mthompson = {
    s3: {
        REGION: 'us-east-2',
        BUCKET: 'datacor-app-attachments-mthompson-s3bucket-1dntwt8pj618o',
    },
    apiGateway: {
        REGION: 'us-east-2',
        URL: 'https://o8uaq1u4vc.execute-api.us-east-2.amazonaws.com/mthompson',
    },
    cognito: {
        REGION: 'us-east-2',
        USER_POOL_ID: 'us-east-2_GdZgj9Dnt',
        APP_CLIENT_ID: '6lknmftkm5uantcgha0523dteg',
        IDENTITY_POOL_ID: 'us-east-2:f128bd42-0af6-4f2f-a12d-166288433783',
    },
};

const playground = {
    s3: {
        REGION: 'us-east-2',
        BUCKET: 'datacor-app-attachments-playground-s3bucket-tysgmkqvtl8h',
    },
    apiGateway: {
        REGION: 'us-east-2',
        URL: 'https://mocqg5hgxg.execute-api.us-east-2.amazonaws.com/playground',
    },
    cognito: {
        REGION: 'us-east-2',
        USER_POOL_ID: 'us-east-2_tuxZiNQ1i',
        APP_CLIENT_ID: '4n43r47d40lmkkvvhv2d9q7rk4',
        IDENTITY_POOL_ID: 'us-east-2:32e0e74c-0b34-4b59-89b0-e43803351036',
    },
};

const prod = {
    s3: {
        REGION: 'us-east-2',
        BUCKET: 'datacor-app-attachments-prod-s3bucket-r5n4yco2kcim',
    },
    apiGateway: {
        REGION: 'us-east-2',
        URL: 'https://4u81sdy7a9.execute-api.us-east-2.amazonaws.com/prod',
    },
    cognito: {
        REGION: 'us-east-2',
        USER_POOL_ID: 'us-east-2_945sx8Me3',
        APP_CLIENT_ID: '2ddlpdcq7fid3ld026u15erk6t',
        IDENTITY_POOL_ID: 'us-east-2:949d2b89-de1e-407f-8f69-464f1f305d5e',
    },
};

const sales = {
    s3: {
        REGION: 'us-east-2',
        BUCKET: 'datacor-app-attachments-sales-s3bucket-7tm8nji86e7q',
    },
    apiGateway: {
        REGION: 'us-east-2',
        URL: 'https://8z8yzydvmg.execute-api.us-east-2.amazonaws.com/sales',
    },
    cognito: {
        REGION: 'us-east-2',
        USER_POOL_ID: 'us-east-2_EODmgPGYA',
        APP_CLIENT_ID: '3n6c6g35b942vqcu120ffcgrah',
        IDENTITY_POOL_ID: 'us-east-2:297110b6-101f-473b-923b-49136ad1aa8a',
    },
};

const sharedenv1 = {
    s3: {
        REGION: 'us-east-2',
        BUCKET: 'datacor-app-attachments-sharedenv1-s3bucket-zk85jv6v6yde',
    },
    apiGateway: {
        REGION: 'us-east-2',
        URL: 'https://cmr40i6oy0.execute-api.us-east-2.amazonaws.com/sharedenv1',
    },
    cognito: {
        REGION: 'us-east-2',
        USER_POOL_ID: 'us-east-2_kib7zcUJE',
        APP_CLIENT_ID: '16mngsdpshnr9ckcerfd7ojaf5',
        IDENTITY_POOL_ID: 'us-east-2:992a8f3e-26cf-48c4-8681-9315270dc15b',
    },
};

const ecrane = {
    s3: {
        REGION: 'us-east-2',
        BUCKET: 'datacor-app-attachments-ecrane-s3bucket-1mt0k8tl3inz',
    },
    apiGateway: {
        REGION: 'us-east-2',
        URL: 'https://db5oda2bld.execute-api.us-east-2.amazonaws.com/ecrane',
    },
    cognito: {
        REGION: 'us-east-2',
        USER_POOL_ID: 'us-east-2_eCtxv0KFX',
        APP_CLIENT_ID: '40cu3hq36qe85a8jfjrtm0j6rc',
        IDENTITY_POOL_ID: 'us-east-2:f32b2956-319a-45f8-9d49-24856c300bd2',
    },
};

const staging = {
    s3: {
        REGION: 'us-east-2',
        BUCKET: 'datacor-app-attachments-staging-s3bucket-16s7qgu4a1wrj',
    },
    apiGateway: {
        REGION: 'us-east-2',
        URL: 'https://j2zmudyg2i.execute-api.us-east-2.amazonaws.com/staging',
    },
    cognito: {
        REGION: 'us-east-2',
        USER_POOL_ID: 'us-east-2_euHmobJAr',
        APP_CLIENT_ID: '1iofvp081dijpcaqkqa46tsprb',
        IDENTITY_POOL_ID: 'us-east-2:086fb155-931c-4580-84e5-af568e9e8491',
    },
};

const bschreur = {
    s3: {
        REGION: 'us-east-2',
        BUCKET: 'datacor-app-attachments-bschreur-s3bucket-173c4subked9y',
    },
    apiGateway: {
        REGION: 'us-east-2',
        URL: 'https://p06fet64gg.execute-api.us-east-2.amazonaws.com/bschreur',
    },
    cognito: {
        REGION: 'us-east-2',
        USER_POOL_ID: 'us-east-2_mQEvFRlQb',
        APP_CLIENT_ID: '4kd98vr8bujll8dv3levn02mni',
        IDENTITY_POOL_ID: 'us-east-2:92c50510-8256-40c1-9520-b38ab05e4cc8',
    },
};

const cblair = {
    s3: {
        REGION: 'us-east-2',
        BUCKET: 'datacor-app-attachments-cblair-s3bucket-dkv4k60w9l7d',
    },
    apiGateway: {
        REGION: 'us-east-2',
        URL: 'https://839qeld99e.execute-api.us-east-2.amazonaws.com/cblair',
    },
    cognito: {
        REGION: 'us-east-2',
        USER_POOL_ID: 'us-east-2_84etjrfYK',
        APP_CLIENT_ID: '2sldk5nrnn994u3dlf0q7nnukk',
        IDENTITY_POOL_ID: 'us-east-2:1f663978-46f1-42fd-9303-5db34b5e1ec1',
    },
};

const jdepompeis = {
    s3: {
        REGION: 'us-east-2',
        BUCKET: 'datacor-app-attachments-jdepompeis-s3bucket-11yzjsui5us06',
    },
    apiGateway: {
        REGION: 'us-east-2',
        URL: 'https://mg65fbu9jc.execute-api.us-east-2.amazonaws.com/jdepompeis',
    },
    cognito: {
        REGION: 'us-east-2',
        USER_POOL_ID: 'us-east-2_ePrWJdva5',
        APP_CLIENT_ID: 'p341gq42e40104sp5sj1uf5dq',
        IDENTITY_POOL_ID: 'us-east-2:651cf15c-3936-45ed-845d-163cb6c140e3',
    },
};

const mdevani = {
    s3: {
        REGION: 'us-east-2',
        BUCKET: 'datacor-app-attachments-mdevani-s3bucket-1d0ba7kz2w9h8',
    },
    apiGateway: {
        REGION: 'us-east-2',
        URL: 'https://pxi3expi61.execute-api.us-east-2.amazonaws.com/mdevani',
    },
    cognito: {
        REGION: 'us-east-2',
        USER_POOL_ID: 'us-east-2_el9yYhc8R',
        APP_CLIENT_ID: '4jlu6iavh2dd36to6bdgm3k1s8',
        IDENTITY_POOL_ID: 'us-east-2:2b275ccd-b0bd-4b3d-8f21-83fd818c9385',
    },
};

const lbarrantes = {
    s3: {
        REGION: 'us-east-2',
        BUCKET: 'datacor-app-attachments-lbarrantes-s3bucket-1jhx10qkh9iyj',
    },
    apiGateway: {
        REGION: 'us-east-2',
        URL: 'https://po65m88s7k.execute-api.us-east-2.amazonaws.com/lbarrantes',
    },
    cognito: {
        REGION: 'us-east-2',
        USER_POOL_ID: 'us-east-2_ioXNv2lVN',
        APP_CLIENT_ID: '47q30rhs94ora7iqfs7tbup7jf',
        IDENTITY_POOL_ID: 'us-east-2:a9961a94-2216-440e-9966-8e925625b4b9',
    },
};

const lcastro = {
    s3: {
        REGION: 'us-east-2',
        BUCKET: 'datacor-app-attachments-lcastro-s3bucket-14uwbkraqkij1',
    },
    apiGateway: {
        REGION: 'us-east-2',
        URL: 'https://489rmfycfh.execute-api.us-east-2.amazonaws.com/lcastro',
    },
    cognito: {
        REGION: 'us-east-2',
        USER_POOL_ID: 'us-east-2_sX2f3AODG',
        APP_CLIENT_ID: '4i1dv9dj3lmmtdnff5e3qig1fs',
        IDENTITY_POOL_ID: 'us-east-2:f120956e-c78c-4997-ab51-6e3a9f024323',
    },
};

const mestrada = {
    s3: {
        REGION: 'us-east-2',
        BUCKET: 'datacor-app-attachments-mestrada-s3bucket-1vwckn8q4t6r4',
    },
    apiGateway: {
        REGION: 'us-east-2',
        URL: 'https://de3dnffnpd.execute-api.us-east-2.amazonaws.com/mestrada',
    },
    cognito: {
        REGION: 'us-east-2',
        USER_POOL_ID: 'us-east-2_NhealsPub',
        APP_CLIENT_ID: '72h5t3kaaagna7q4c3t1g2dq89',
        IDENTITY_POOL_ID: 'us-east-2:6a9a52c1-0bea-45dc-b601-191d49c7f314',
    },
};

const sgomez = {
    s3: {
        REGION: 'us-east-2',
        BUCKET: 'datacor-app-attachments-sgomez-s3bucket-1lxz5ahvw4vw1',
    },
    apiGateway: {
        REGION: 'us-east-2',
        URL: 'https://4qz3fiwva7.execute-api.us-east-2.amazonaws.com/sgomez',
    },
    cognito: {
        REGION: 'us-east-2',
        USER_POOL_ID: 'us-east-2_rEGDbuqBO',
        APP_CLIENT_ID: 'fgbptb0uvbr977jrtvsjafeio',
        IDENTITY_POOL_ID: 'us-east-2:f8c95881-d1f2-43da-b633-fcd96b2c5e60',
    },
};

const lsoto = {
    s3: {
        REGION: 'us-east-2',
        BUCKET: 'datacor-app-attachments-lsoto-s3bucket-17q655fexv9t1',
    },
    apiGateway: {
        REGION: 'us-east-2',
        URL: 'https://2q64yi04v7.execute-api.us-east-2.amazonaws.com/lsoto',
    },
    cognito: {
        REGION: 'us-east-2',
        USER_POOL_ID: 'us-east-2_I7eIH5KXz',
        APP_CLIENT_ID: '1d0llbo67j859uvkrri9g9e81r',
        IDENTITY_POOL_ID: 'us-east-2:a5d48fea-3eca-4a60-9b24-5dc1d21aa246',
    },
};

const orodriguez = {
    s3: {
        REGION: 'us-east-2',
        BUCKET: 'datacor-app-attachments-orodriguez-s3bucket-h8rnfc15ech7',
    },
    apiGateway: {
        REGION: 'us-east-2',
        URL: 'https://edf0bohiq8.execute-api.us-east-2.amazonaws.com/orodriguez',
    },
    cognito: {
        REGION: 'us-east-2',
        USER_POOL_ID: 'us-east-2_hF4kxpKWl',
        APP_CLIENT_ID: '42kn7si0774og0nh3oi7u7gv3f',
        IDENTITY_POOL_ID: 'us-east-2:beb3f874-cbbf-4a4f-8b35-29d2d84df0f2',
    },
};

const jarce = {
    s3: {
        REGION: 'us-east-2',
        BUCKET: 'datacor-app-attachments-jarce-s3bucket-1ur0hp4pbi024',
    },
    apiGateway: {
        REGION: 'us-east-2',
        URL: 'https://oyaqc10d6k.execute-api.us-east-2.amazonaws.com/jarce',
    },
    cognito: {
        REGION: 'us-east-2',
        USER_POOL_ID: 'us-east-2_FMw5jt5GJ',
        APP_CLIENT_ID: '636tro59p0b5dmbhfpl7teuok9',
        IDENTITY_POOL_ID: 'us-east-2:be270551-f896-49ad-b90e-9a345313e35a',
    },
};

const btorres = {
    s3: {
        REGION: 'us-east-2',
        BUCKET: 'datacor-app-attachments-btorres-s3bucket-151k67211g4fj',
    },
    apiGateway: {
        REGION: 'us-east-2',
        URL: 'https://j50sdways9.execute-api.us-east-2.amazonaws.com/btorres',
    },
    cognito: {
        REGION: 'us-east-2',
        USER_POOL_ID: 'us-east-2_c5PT21pXK',
        APP_CLIENT_ID: '5kbfeutmhb3kk16c5chcmslp8g',
        IDENTITY_POOL_ID: 'us-east-2:0c846c76-7172-4f1c-9015-6ac6bcca2ad5',
    },
};

const dsolano = {
    s3: {
        REGION: 'us-east-2',
        BUCKET: 'datacor-app-attachments-dsolano-s3bucket-1lh4tegnlvk91',
    },
    apiGateway: {
        REGION: 'us-east-2',
        URL: 'https://pxyppp55w4.execute-api.us-east-2.amazonaws.com/dsolano',
    },
    cognito: {
        REGION: 'us-east-2',
        USER_POOL_ID: 'us-east-2_bDybqPagk',
        APP_CLIENT_ID: '1v3egbbdoc3r2bnres3qefu0c0',
        IDENTITY_POOL_ID: 'us-east-2:4f6b5b27-8444-459d-b21d-632c7658c55e',
    },
};

const aromero = {
    s3: {
        REGION: 'us-east-2',
        BUCKET: 'datacor-app-attachments-aromero-s3bucket-14ydlda9yl0fd',
    },
    apiGateway: {
        REGION: 'us-east-2',
        URL: 'https://80iuydv7jk.execute-api.us-east-2.amazonaws.com/aromero',
    },
    cognito: {
        REGION: 'us-east-2',
        USER_POOL_ID: 'us-east-2_4nM1ggTMt',
        APP_CLIENT_ID: 'vn2ku7uks03kk0ah1bhh9nr95',
        IDENTITY_POOL_ID: 'us-east-2:3dd388b2-90ee-485d-b1da-abd586d06b29',
    },
};

const eblanco = {
    s3: {
        REGION: 'us-east-2',
        BUCKET: 'datacor-app-attachments-eblanco-s3bucket-10lp80i05a90',
    },
    apiGateway: {
        REGION: 'us-east-2',
        URL: 'https://8zsc5x6upc.execute-api.us-east-2.amazonaws.com/eblanco',
    },
    cognito: {
        REGION: 'us-east-2',
        USER_POOL_ID: 'us-east-2_6AEXuazaV',
        APP_CLIENT_ID: '18qssnq0860d34q1h9e54la6dl',
        IDENTITY_POOL_ID: 'us-east-2:0ce37b6a-3c78-4ea6-a4b7-2bb436dc7d53',
    },
};

const mrmedwith = {
    s3: {
        REGION: 'us-east-2',
        BUCKET: 'datacor-app-attachments-mrmedwith-s3bucket-uecegc3gtoq4',
    },
    apiGateway: {
        REGION: 'us-east-2',
        URL: 'https://1x9hcp0325.execute-api.us-east-2.amazonaws.com/mrmedwith',
    },
    cognito: {
        REGION: 'us-east-2',
        USER_POOL_ID: 'us-east-2_8p8CwpDG0',
        APP_CLIENT_ID: '59uojq1n96t510k61uskju8kdc',
        IDENTITY_POOL_ID: 'us-east-2:2772c4c1-5db8-4e81-9232-f51b78888c49',
    },
};

const bmatson = {
    s3: {
        REGION: 'us-east-2',
        BUCKET: 'datacor-app-attachments-bmatson-s3bucket-1l0potwn0ar2q',
    },
    apiGateway: {
        REGION: 'us-east-2',
        URL: 'https://6oh6p187kb.execute-api.us-east-2.amazonaws.com/bmatson',
    },
    cognito: {
        REGION: 'us-east-2',
        USER_POOL_ID: 'us-east-2_FJhpF43X4',
        APP_CLIENT_ID: '5ep4aai5i6c98c0fisnc6lh42j',
        IDENTITY_POOL_ID: 'us-east-2:fd266677-6a20-4fc5-9b3a-47fa1dbbfb08',
    },
};

const local = {
    s3: {
        REGION: 'us-east-2',
        BUCKET: 'datacor-app-attachments-dev-s3bucket-i3psryfp5oy5',
    },
    apiGateway: {
        REGION: 'us-east-2',
        URL: 'http://localhost:3001',
    },
    cognito: {
        REGION: 'us-east-2',
        USER_POOL_ID: 'us-east-2_VSP1gZOEp',
        APP_CLIENT_ID: '1m1p25pa94je3vlc2kjljci3ps',
        IDENTITY_POOL_ID: 'us-east-2:dcee0ead-4014-4f4f-89ca-8e9cbb120551',
    },
    isLocal: true,
};

// Default to dev if not set
let config;
let stage;

if (typeof process === 'undefined') {
    stage = 'dev';
} else {
    stage = process.env.REACT_APP_STAGE;
}

switch (stage) {
    case 'prod':
        config = prod;
        break;
    case 'playground':
        config = playground;
        break;
    case 'aweiss':
        config = aweiss;
        break;
    case 'zduford':
        config = zduford;
        break;
    case 'tperez':
        config = tperez;
        break;
    case 'glazo':
        config = glazo;
        break;
    case 'sales':
        config = sales;
        break;
    case 'sharedenv1':
        config = sharedenv1;
        break;
    case 'ecrane':
        config = ecrane;
        break;
    case 'staging':
        config = staging;
        break;
    case 'bschreur':
        config = bschreur;
        break;
    case 'cblair':
        config = cblair;
        break;
    case 'jdepompeis':
        config = jdepompeis;
        break;
    case 'mdevani':
        config = mdevani;
        break;
    case 'lbarrantes':
        config = lbarrantes;
        break;
    case 'lcastro':
        config = lcastro;
        break;
    case 'mestrada':
        config = mestrada;
        break;
    case 'sgomez':
        config = sgomez;
        break;
    case 'lsoto':
        config = lsoto;
        break;
    case 'orodriguez':
        config = orodriguez;
        break;
    case 'jarce':
        config = jarce;
        break;
    case 'btorres':
        config = btorres;
        break;
    case 'dsolano':
        config = dsolano;
        break;
    case 'aromero':
        config = aromero;
        break;
    case 'eblanco':
        config = eblanco;
        break;
    case 'mrmedwith':
        config = mrmedwith;
        break;
    case 'bmatson':
        config = bmatson;
        break;
    case 'mthompson':
        config = mthompson;
        break;
    case 'local':
        config = local;
        break;
    default:
        config = dev;
}

export default {
    // Add common config values here
    MAX_ATTACHMENT_SIZE: 5000000,
    ...config,
};
