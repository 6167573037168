import React, { useContext, useEffect, useState } from 'react';
import { useForm } from '../../../../libs/hooksLib';
import {
    useGetAllFixedAssetDepreciationSchedulesQuery,
    useGetDepreciationMethodTypesForDropdownQuery,
    useGetTypesForDropdownQuery,
} from '../../../../services/fixedAssets/fixedAssets.service';
import { skipToken } from '@reduxjs/toolkit/query';
import {
    FA_DepreciationSchedule,
    FixedAssetScheduleListParams,
} from '../../../../types/FixedAsset.types';
import { SelectionOption } from '../../../../types/Shared.types';
import { isNil, isNilOrEmpty } from '../../../../utils/objectUtils';
import SettingsContext from '../../../../contexts/settings.context';
import { useGetTermSetQuery } from '../../../../services/i18n/i18n.service';
import { ACCOUNTING_DEFS } from '../../../../constants/i18n/translations/termSetDefinitions/accounting';

const useFixedAssetSchedulesOptionsModal = (
    onSchedulesLoaded: (schedules: FA_DepreciationSchedule[]) => void,
    onClose: () => void
) => {
    const { settings } = useContext(SettingsContext);
    const { data: termSet } = useGetTermSetQuery(
        settings?.userSettings
            ? {
                  languageId: settings?.userSettings?.languageId,
                  code: ACCOUNTING_DEFS.FIXED_ASSET_SCHEDULE_GRID,
              }
            : skipToken
    );
    const [queryParams, setQueryParams] =
        useState<FixedAssetScheduleListParams>(null);

    const { data: methodTypeOptions, isLoading: loadingMethodTypes } =
        useGetDepreciationMethodTypesForDropdownQuery();
    const { data: assetTypeOptions, isLoading: loadingAssetTypes } =
        useGetTypesForDropdownQuery();
    const { data: depreciationScheduleList, isFetching: isLoadingSchedules } =
        useGetAllFixedAssetDepreciationSchedulesQuery(
            queryParams ? queryParams : skipToken
        );

    const { fields, handleFieldChange } = useForm({
        methodTypes: [],
        startDate: null,
        endDate: null,
        assetTypes: [],
    });

    const handleGetSchedules = (event: any) => {
        event.preventDefault();

        const listParams: FixedAssetScheduleListParams = {};

        if (!isNilOrEmpty(fields.methodTypes)) {
            listParams['methodTypeIds'] = fields.methodTypes.map(
                (option: SelectionOption) => option.value
            );
        }

        if (!isNilOrEmpty(fields.assetTypes)) {
            listParams['assetTypeIds'] = fields.assetTypes.map(
                (option: SelectionOption) => option.value
            );
        }

        if (!isNil(fields.startDate)) {
            listParams['startDate'] = fields.startDate;
        }

        if (!isNil(fields.endDate)) {
            listParams['endDate'] = fields.endDate;
        }

        setQueryParams(listParams);
    };

    useEffect(() => {
        if (depreciationScheduleList) {
            onSchedulesLoaded(depreciationScheduleList);
            setQueryParams(null);
            onClose();
        }
    }, [depreciationScheduleList]);

    return {
        queryParams,
        fields,
        handleFieldChange,
        methodTypeOptions,
        loadingMethodTypes,
        assetTypeOptions,
        loadingAssetTypes,
        handleGetSchedules,
        isLoadingSchedules,
        termSet,
    };
};

export default useFixedAssetSchedulesOptionsModal;
