import { MutationError } from '../../../../types/Shared.types';
import { useState } from 'react';
import {
    useImportFixedAssetsMutation,
    useValidateFixedAssetsMutation,
} from '../../../../services/fixedAssets/fixedAssets.service';
import { FA_Asset } from '../../../../types/FixedAsset.types';
import { AlertColor } from '@mui/material';
import { openSnackbarBasicWithMessage } from '../../../../store/uiElements';
import { useDispatch } from 'react-redux';

const useFixedAssetImport = () => {
    const dispatch = useDispatch();

    const initialImport: any = { code: null, detailRecords: [{ code: null }] };

    const [importList, setImportList] = useState<FA_Asset[]>([]);

    const [showImportDataModal, setShowImportDataModal] = useState(false);

    const [importAssets, { isLoading: isImportingAssets }] =
        useImportFixedAssetsMutation();

    const [validateAssets, { isLoading: isValidatingAssets }] =
        useValidateFixedAssetsMutation();

    const handleModalOpen = (event: any) => {
        event.preventDefault();

        setImportList([initialImport]);
        setShowImportDataModal(true);
    };

    const handleModalClose = (event: any, reason: string) => {
        event.preventDefault();

        if (
            reason &&
            (reason === 'backdropClick' || reason === 'escapeKeyDown')
        ) {
            return;
        }
        setShowImportDataModal(false);
        setImportList([]);
    };

    const handleUploadImport = (data: FA_Asset[]) => {
        setImportList(data);
    };

    const handleImportAssets = async (event: any, data: FA_Asset[]) => {
        event.preventDefault();

        try {
            const response: any = await importAssets(data);
            if (response.error) {
                handleError(response.error, data);
            }
            if (response.data) {
                _showNotification('Bulk Upload Completed', 'success');
                setShowImportDataModal(false);
            }
        } catch (error) {
            handleError(error, data);
        }
    };

    const handleValidateAssets = async (event: any, data: FA_Asset[]) => {
        event.preventDefault();

        try {
            const response: any = await validateAssets(data);
            if (response.error) {
                handleError(response.error, data);
            }
            if (response.data) {
                const validImportList = data.map((asset) => {
                    asset.isValid = true;
                    return asset;
                });
                _showNotification('Valid Upload Data', 'success');
                setImportList(validImportList);
            }
        } catch (error) {
            handleError(error, data);
        }
    };

    const handleError = (error: MutationError, data: FA_Asset[]) => {
        let invalidImportList: FA_Asset[] = [];
        if (error.status === 400) {
            for (const errorElement of error.data) {
                const errorMessage = errorElement
                    .map((error: any) => {
                        return error.message;
                    })
                    .join('\r\n');
                const errorCode = errorElement.map((item: any) => {
                    return item.instance.code;
                })[0];
                invalidImportList = data.map((asset) => {
                    if (asset.code === errorCode) {
                        asset.errorMessage = errorMessage;
                        asset.isValid = false;
                    }
                    return asset;
                });
            }
        } else {
            if (Array.isArray(error.data)) {
                for (const errorElement of error.data) {
                    invalidImportList = data.map((asset) => {
                        asset.errorMessage = `An unexpected error occurred: ${errorElement.toString()}`;
                        asset.isValid = false;
                        return asset;
                    });
                }
            } else {
                invalidImportList = data.map((asset) => {
                    asset.errorMessage = `An unexpected error occurred: ${error.data.toString()}`;
                    asset.isValid = false;
                    return asset;
                });
            }
        }
        _showNotification('Invalid Upload Data', 'error');
        setImportList(invalidImportList);
    };

    const _showNotification = (message: string, severity: AlertColor) => {
        dispatch(
            openSnackbarBasicWithMessage({
                message: message,
                severity: severity,
            })
        );
    };

    return {
        importList,
        showImportDataModal,
        isImportingAssets,
        isValidatingAssets,
        handleModalOpen,
        handleModalClose,
        handleUploadImport,
        handleImportAssets,
        handleValidateAssets,
    };
};

export default useFixedAssetImport;
