import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { GridReadyEvent } from 'ag-grid-community';
import { RootStateOrAny, useSelector } from 'react-redux';
import { PermissionsUtil } from '../../../../utils/permissions/permissionsUtil';
import { PERMISSIONS } from '../../../../constants/permissions/Permissions.constants';
import { useListCasQuery } from '../../../../services/formulation/casMaster/cas/casMaster.service';
import { CasMaster } from '../../../../types/formulation';
import SettingsContext from '../../../../contexts/settings.context';
import { useGetTermSetQuery } from '../../../../services/i18n/i18n.service';
import { FORMULATION_DEFS } from '../../../../constants/i18n/translations/termSetDefinitions/formulation';
import { skipToken } from '@reduxjs/toolkit/query';

const useCasGrid = () => {
    const navigate = useNavigate();
    const user = useSelector((state: RootStateOrAny) => state.user);

    const { data: rowData, isLoading: isLoading } = useListCasQuery();
    const [gridApi, setGridApi] = useState(null);
    const [gridColumnApi, setGridColumnApi] = useState(null);

    const canCreateCAS = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.FORMULATION.CAS_MASTER.CREATE
    );
    const canViewCAS = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.FORMULATION.CAS_MASTER.VIEW
    );

    const { settings } = useContext(SettingsContext);
    const { data: termSet } = useGetTermSetQuery(
        settings?.userSettings
            ? {
                  languageId: settings?.userSettings?.languageId,
                  code: FORMULATION_DEFS.CAS_MASTER_GRID,
              }
            : skipToken
    );

    const onGridReady = (params: GridReadyEvent) => {
        setGridApi(params.api);
        setGridColumnApi(params.columnApi);
    };
    const redirectToForm = (row: CasMaster) => {
        if (row && row.id) {
            navigate(`/formulation/casMaster/${row.id}`);
        } else {
            navigate('/formulation/casMaster/new');
        }
    };

    return {
        onGridReady,
        rowData,
        isLoading,
        redirectToForm,
        canCreateCAS,
        canViewCAS,
        termSet,
    };
};

export default useCasGrid;
