import React, { useContext, useEffect, useState } from 'react';
import {
    FA_Asset,
    FA_AssetEntry,
    FA_AssetType,
} from '../../../../types/FixedAsset.types';
import {
    currencyFormatter,
    dateFormatter,
} from '../../../../utils/formattingUtils';
import {
    GridReadyEvent,
    ICellRendererParams,
    SelectionChangedEvent,
} from 'ag-grid-community';
import { GridApi } from 'ag-grid-community/dist/lib/gridApi';
import { PermissionsUtil } from '../../../../utils/permissions/permissionsUtil';
import { PERMISSIONS } from '../../../../constants/permissions/Permissions.constants';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import { Link } from '@mui/material';
import { ACCOUNTING_DEFS } from '../../../../constants/i18n/translations/termSetDefinitions/accounting';
import SettingsContext from '../../../../contexts/settings.context';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useGetTermSetQuery } from '../../../../services/i18n/i18n.service';

export enum EntryGridMode {
    Default = '0',
    ByDate = '1',
    ByAssetType = '2',
}

export class MainEntryRow {
    public readonly id: string;
    public readonly entryDate: Date;
    public readonly ledgerAssetType: FA_AssetType;
    public readonly assetEntries: FA_AssetEntry[] = [];
    public readonly detailAssetEntries: DetailEntryRow[] = [];
    public readonly exportEntries: ExportEntryRow[] = [];

    constructor(assetEntry: FA_AssetEntry) {
        this.id = `${assetEntry.entryDate}|${assetEntry.fixedAsset.ledgerTypeId}`;
        this.entryDate = assetEntry.entryDate;
        this.ledgerAssetType = assetEntry.fixedAsset.ledgerAssetType;
        this.addEntry(assetEntry);
    }

    public addEntry = (entry: FA_AssetEntry) => {
        this.assetEntries.push(entry);

        const existingEntry = this.detailAssetEntries.find(
            (row) => row.fixedAsset.id === entry.fixedAssetId
        );

        if (existingEntry) {
            existingEntry.entries.push(entry);
        } else {
            this.detailAssetEntries.push(new DetailEntryRow(entry));
        }

        //establish the row key for uniqueness
        const exportKey: string = `${entry.entryDate}|${entry.accountId}`;
        const existingExport = this.exportEntries.find(
            (row) => row.id === exportKey
        );

        if (existingExport) {
            existingExport.entries.push(entry);
        } else {
            this.exportEntries.push(new ExportEntryRow(entry, exportKey));
        }
    };

    public get groupedEntries(): any {
        return this.assetEntries
            .sort((a, b) => Number(a.accountId) - Number(b.accountId))
            .reduce((acc: any, obj) => {
                const key = obj.accountId.toString();
                if (!acc[key]) {
                    acc[key] = [];
                }
                acc[key].push(obj);
                return acc;
            }, {});
    }

    public get mainAccountNo(): string {
        return [
            ...new Set(
                this.assetEntries
                    .sort((a, b) => Number(a.accountId) - Number(b.accountId))
                    .map((row) => row.account.number)
            ),
        ].join('\r\n');
    }

    public get mainDescription(): string {
        return [
            ...new Set(
                this.assetEntries
                    .sort((a, b) => Number(a.accountId) - Number(b.accountId))
                    .map(
                        (row) =>
                            `${row.account.name} - ${this.ledgerAssetType.code}`
                    )
            ),
        ].join('\r\n');
    }

    public get mainDebit(): string {
        return Object.keys(this.groupedEntries)
            .reduce((acc, key) => {
                const debitSum = this.groupedEntries[key].reduce(
                    (sum: any, obj: FA_AssetEntry) => sum + Number(obj.debit),
                    0
                );
                acc.push({ debit: debitSum });
                return acc;
            }, [])
            .map((row) => currencyFormatter(row.debit))
            .join('\r\n');
    }

    public get mainCredit(): string {
        return Object.keys(this.groupedEntries)
            .reduce((acc, key) => {
                const creditSum = this.groupedEntries[key].reduce(
                    (sum: any, obj: FA_AssetEntry) => sum + Number(obj.credit),
                    0
                );
                acc.push({ credit: creditSum });
                return acc;
            }, [])
            .map((row) => currencyFormatter(row.credit))
            .join('\r\n');
    }

    public get mainLockedSummary(): string {
        const totalLocked: number = this.detailAssetEntries.filter(
            (row) => row.locked
        ).length;
        return `${totalLocked} of ${this.detailAssetEntries.length}`;
    }

    public get hasUnlocked(): boolean {
        return this.assetEntries.some((entry) => !entry.locked);
    }
}

export class DetailEntryRow {
    public readonly id: string;
    public readonly fixedAsset: FA_Asset;
    public readonly entryDate: Date;
    public readonly locked: Boolean;
    public readonly lockedSummary: string;
    public readonly entries: FA_AssetEntry[] = [];

    constructor(entry: FA_AssetEntry) {
        //grouping for the debit and credit for a given fixed asset
        this.id = entry.fixedAssetId.toString();
        this.fixedAsset = entry.fixedAsset;
        this.entryDate = entry.entryDate;
        this.locked = entry.locked;
        this.lockedSummary = entry.locked
            ? `${dateFormatter(entry.lockedDate)} (${entry.lockedUserName})`
            : '';
        this.entries.push(entry);
    }

    public get detailAccountNumber(): string {
        return this.entries
            .sort((a, b) => Number(a.accountId) - Number(b.accountId))
            .map((row) => row.account.number)
            .join('\r\n');
    }

    public get detailAccountName(): string {
        return this.entries
            .sort((a, b) => Number(a.accountId) - Number(b.accountId))
            .map((row) => row.account.name)
            .join('\r\n');
    }

    public get detailDebit(): string {
        return this.entries
            .sort((a, b) => Number(a.accountId) - Number(b.accountId))
            .map((row) => currencyFormatter(row.debit))
            .join('\r\n');
    }

    public get detailCredit(): string {
        return this.entries
            .sort((a, b) => Number(a.accountId) - Number(b.accountId))
            .map((row) => currencyFormatter(row.credit))
            .join('\r\n');
    }
}

export class ExportEntryRow {
    public readonly id: string;
    public readonly entryDate: Date;
    public readonly accountNo: string;
    public readonly accountName: string;
    public readonly entries: FA_AssetEntry[] = [];

    constructor(entry: FA_AssetEntry, id: string) {
        //grouping for the debit and credit for a given fixed asset
        this.id = id;
        this.entryDate = entry.entryDate;
        this.accountNo = entry.account.number;
        this.accountName = `${entry.account.name} - ${entry.fixedAsset.ledgerAssetType.code}`;
        this.entries.push(entry);
    }

    public get debit(): string {
        return currencyFormatter(
            this.entries.reduce((total, obj) => total + Number(obj.debit), 0)
        );
    }

    public get credit(): string {
        return currencyFormatter(
            this.entries.reduce((total, obj) => total + Number(obj.credit), 0)
        );
    }
}

const useAssetEntriesGrid = () => {
    const user = useSelector((state: RootState) => state.user);
    const { settings } = useContext(SettingsContext);
    const { data: termSet } = useGetTermSetQuery(
        settings?.userSettings
            ? {
                  languageId: settings?.userSettings?.languageId,
                  code: ACCOUNTING_DEFS.ASSET_ENTRIES_GRID,
              }
            : skipToken
    );
    const [showOptionsModal, setShowOptionsModal] = useState<boolean>(true);
    const [showActionsModal, setShowActionsModal] = useState<boolean>(false);
    const [showExportModal, setShowExportModal] = useState<boolean>(false);
    const [assetEntryList, setAssetEntryList] = useState<FA_AssetEntry[]>([]);
    const [mainEntryRows, setMainEntryRows] = useState<MainEntryRow[]>([]);
    const [hasSelectedRows, setHasSelectedRows] = useState<boolean>(false);
    const [hasUnlockedSelected, setHasUnlockedSelected] =
        useState<boolean>(true);
    const [selectedEntries, setSelectedEntries] = useState<FA_AssetEntry[]>([]);
    const [exportedEntries, setExportedEntries] = useState<ExportEntryRow[]>(
        []
    );
    const [gridApi, setGridApi] = useState<GridApi>(null);
    const [currentGridMode, setCurrentGridMode] = useState<EntryGridMode>(
        EntryGridMode.Default
    );
    const [currentTitle, setCurrentTitle] = useState<string>('Asset Entries');

    const canLockEntries = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.FIXED_ASSETS.ASSET_ENTRY.LOCK
    );

    const canViewEntries = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.FIXED_ASSETS.ASSET_ENTRY.VIEW
    );

    const canDownloadEntries = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.FIXED_ASSETS.ASSET_ENTRY.DOWNLOAD
    );

    const _createRowsFromList = (list: FA_AssetEntry[]): MainEntryRow[] => {
        const rowMap: Map<string, MainEntryRow> = new Map<
            string,
            MainEntryRow
        >();

        list?.forEach((entry) => {
            //establish the row key for uniqueness
            const rowKey: string = `${entry.entryDate}|${entry.fixedAsset.ledgerTypeId}`;

            if (!rowMap.has(rowKey)) {
                rowMap.set(rowKey, new MainEntryRow(entry));
            } else {
                rowMap.get(rowKey).addEntry(entry);
            }
        });

        return Array.from(rowMap.values());
    };

    const mainAccountColumn = (params: ICellRendererParams) => {
        const row: MainEntryRow = params.data;

        return (
            <div style={{ whiteSpace: 'pre-line' }}>
                {row.mainAccountNo ?? ''}
            </div>
        );
    };

    const mainDescriptionColumn = (params: ICellRendererParams) => {
        const row: MainEntryRow = params.data;

        return (
            <div style={{ whiteSpace: 'pre-line' }}>
                {row.mainDescription ?? ''}
            </div>
        );
    };

    const mainCreditColumn = (params: ICellRendererParams) => {
        const row: MainEntryRow = params.data;

        return (
            <div style={{ whiteSpace: 'pre-line' }}>{row.mainCredit ?? ''}</div>
        );
    };

    const mainDebitColumn = (params: ICellRendererParams) => {
        const row: MainEntryRow = params.data;

        return (
            <div style={{ whiteSpace: 'pre-line' }}>{row.mainDebit ?? ''}</div>
        );
    };

    const detailAccountNoColumn = (params: ICellRendererParams) => {
        const row: DetailEntryRow = params.data;

        return (
            <div style={{ whiteSpace: 'pre-line' }}>
                {row.detailAccountNumber ?? ''}
            </div>
        );
    };

    const detailAccountNameColumn = (params: ICellRendererParams) => {
        const row: DetailEntryRow = params.data;

        return (
            <div style={{ whiteSpace: 'pre-line' }}>
                {row.detailAccountName ?? ''}
            </div>
        );
    };

    const detailCreditColumn = (params: ICellRendererParams) => {
        const row: DetailEntryRow = params.data;

        return (
            <div style={{ whiteSpace: 'pre-line' }}>
                {row.detailCredit ?? ''}
            </div>
        );
    };

    const detailDebitColumn = (params: ICellRendererParams) => {
        const row: DetailEntryRow = params.data;

        return (
            <div style={{ whiteSpace: 'pre-line' }}>
                {row.detailDebit ?? ''}
            </div>
        );
    };

    const assetTypeColumn = (params: ICellRendererParams) => {
        return (
            <Link
                component="button"
                underline="none"
                onClick={() => handleAssetTypeLink(params)}>
                {params.value}
            </Link>
        );
    };

    const entryDateColumn = (params: ICellRendererParams) => {
        return (
            <Link
                component="button"
                underline="none"
                onClick={() => handleEntryDateLink(params)}>
                {dateFormatter(params.value)}
            </Link>
        );
    };

    const onSelectionChanged = (event: SelectionChangedEvent) => {
        const selectedRows = event.api.getSelectedRows() as MainEntryRow[];
        setHasSelectedRows(selectedRows.length > 0);
        setHasUnlockedSelected(
            selectedRows.length === 0 ||
                selectedRows.some((row) => row.hasUnlocked)
        );
    };

    const handleActionsButton = () => {
        let selectedEntries: FA_AssetEntry[] = [];

        gridApi.getSelectedRows().map((row: MainEntryRow) => {
            row.assetEntries.map((entry) => selectedEntries.push(entry));
        });

        setSelectedEntries(selectedEntries);
        setShowActionsModal(true);
    };

    const handleExportButton = () => {
        let exportedEntries: ExportEntryRow[] = [];

        gridApi.getSelectedRows().map((row: MainEntryRow) => {
            row.exportEntries.map((entry) => exportedEntries.push(entry));
        });

        setExportedEntries(exportedEntries);
        setShowExportModal(true);
    };

    const handleAssetTypeLink = (params: ICellRendererParams) => {
        params.api.deselectAll();
        const row: MainEntryRow = params.data;
        const filterInstance = params.api.getFilterInstance(
            'ledgerAssetType.code'
        );

        filterInstance.setModel({
            filterType: 'text',
            type: 'equals',
            filter: row.ledgerAssetType.code,
        });

        params.api.onFilterChanged();
        setCurrentTitle(`Asset Entries For ${row.ledgerAssetType.code}`);
        setCurrentGridMode(EntryGridMode.ByAssetType);
    };

    const handleEntryDateLink = (params: ICellRendererParams) => {
        params.api.deselectAll();
        const row: MainEntryRow = params.data;
        const filterInstance = params.api.getFilterInstance('entryDate');

        filterInstance.setModel({
            filterType: 'date',
            type: 'equals',
            dateFrom: row.entryDate,
        });

        params.api.onFilterChanged();
        setCurrentTitle(`Asset Entries For ${dateFormatter(row.entryDate)}`);
        setCurrentGridMode(EntryGridMode.ByDate);
    };

    const handleReturnToMainGrid = () => {
        gridApi.setFilterModel(null);
        setCurrentTitle('Asset Entries');
        setCurrentGridMode(EntryGridMode.Default);
    };

    const onGridReady = (event: GridReadyEvent) => {
        setGridApi(event.api);
    };

    useEffect(() => {
        setMainEntryRows(_createRowsFromList(assetEntryList));
    }, [assetEntryList]);

    return {
        showOptionsModal,
        showActionsModal,
        showExportModal,
        setShowOptionsModal,
        setShowActionsModal,
        setShowExportModal,
        mainEntryRows,
        hasSelectedRows,
        hasUnlockedSelected,
        selectedEntries,
        exportedEntries,
        canLockEntries,
        canViewEntries,
        canDownloadEntries,
        currentGridMode,
        currentTitle,
        setAssetEntryList,
        mainAccountColumn,
        mainDescriptionColumn,
        mainCreditColumn,
        mainDebitColumn,
        detailAccountNoColumn,
        detailAccountNameColumn,
        detailCreditColumn,
        detailDebitColumn,
        assetTypeColumn,
        entryDateColumn,
        onSelectionChanged,
        handleActionsButton,
        handleExportButton,
        handleReturnToMainGrid,
        onGridReady,
        termSet,
    };
};

export default useAssetEntriesGrid;
