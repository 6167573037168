import React from 'react';
import LocaleGroupGrid from './LocaleGroupGrid';

const LocaleGroup = () => {
    return (
        <>
            <LocaleGroupGrid />
        </>
    );
};

export default LocaleGroup;
