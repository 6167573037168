import React from 'react';
import { useParams } from 'react-router-dom';
import DocumentTemplateForm from './DocumentTemplateForm';

const DocumentTemplate = () => {
    const { id } = useParams();
    return <DocumentTemplateForm documentId={id} />;
};

export default DocumentTemplate;
