import {
    ContractsForm,
    ContractsGrid,
    ContractCoverageGrid,
    DepreciationEntriesGrid,
    DisposalEntriesGrid,
    FixedAssetRegisterForm,
    FixedAssetRegisterGrid,
    FixedAssetScheduleGrid,
    AssetEntriesGrid,
    FunctionCodesGrid,
    CurrencyGrid,
    CurrencyForm,
    CurrencyRateTypeGrid,
    CurrencyRateTypeForm,
    CurrencyExchangeRateGrid,
    FiscalYearStatus,
    AccountingSettings,
    CurrencyExchangeRateFilter,
    CurrencyExchangeRateForm,
    FunctionCodesForm,
    AssetCostCodesGrid,
    AssetCostCodesForm,
    DepreciationMethodStatusesGrid,
    DepreciationMethodStatusesForm,
    DepreciationMethodTypesGrid,
    DepreciationMethodTypesForm,
    DepreciationMethodsGrid,
    DepreciationMethodsForm,
    FiscalYear,
    PeriodStatus,
    AssetStatusesGrid,
    AssetStatusesForm,
    AssetTypesGrid,
    AssetTypesForm,
    ContractTypesGrid,
    ContractTypesForm,
    GeneralLedgerAccountsGrid,
    GeneralLedgerAccountsForm,
    PeriodQuartersGrid,
    PeriodQuarterForm,
    AcquisitionsGrid,
    DepreciationSummaryGrid,
    DepreciationDetailGrid,
    DisposalsGrid,
} from './termDefs.types';

export const FixedAssetRegisterGridDefs: FixedAssetRegisterGrid = {
    AssetNumber: 'fixed-asset-register-asset-number',
    AssetCode: 'fixed-asset-register-asset-code',
    AssetName: 'fixed-asset-register-asset-name',
    Description: 'fixed-asset-register-description',
    AssetStatus: 'fixed-asset-register-asset-status',
    AssetTypeBook: 'fixed-asset-register-asset-type-book',
    AssetTypeTax: 'fixed-asset-register-asset-type-tax',
    Building: 'fixed-asset-register-building',
    AcquireDate: 'fixed-asset-register-acquire-date',
    InServiceDate: 'fixed-asset-register-in-service-date',
    TotalCost: 'fixed-asset-register-total-cost',
};

export const FixedAssetScheduleGridDefs: FixedAssetScheduleGrid = {
    Selected: 'fixed-asset-schedule-grid-selected',
    DepreciationMethodType:
        'fixed-asset-schedule-grid-depreciation-method-type',
    FixedAssetType: 'fixed-asset-schedule-fixed-asset-type',
    Date: 'fixed-asset-schedule-grid-date',
    Locked: 'fixed-asset-schedule-grid-locked',
    AssetCost: 'fixed-asset-schedule-grid-asset-cost',
    AccumulatedDepreciation:
        'fixed-asset-schedule-grid-accumulated-depreciation',
    RemainingValue: 'fixed-asset-schedule-grid-remaining-value',
    PopUpDepreciationMethodTypes: 'schedule-options-depreciation-method-types',
    IntervalDateBegin: 'schedule-options-interval-date-begin',
    IntervalDateEnd: 'schedule-options-interval-date-end',
    AssetTypes: 'schedule-options-asset-types',
    GetSchedules: 'schedule-options-get-schedules',
    Title: 'schedule-options-title',
    DepreciationMethodTypeAssetCode: 'depreciation-method-type-asset-code',
    DepreciationMethodTypeRecoveryInterval:
        'depreciation-method-type-recovery-interval',
    DepreciationMethodTypeAssetCost: 'depreciation-method-type-asset-cost',
    DepreciationMethodTypeAccumulatedDepreciation:
        'depreciation-method-type-accumulated-depreciation',
    DepreciationMethodTypeRemainingValue:
        'depreciation-method-type-remaining-value',
    DepreciationMethodTypeLocked: 'depreciation-method-type-locked',
    FixedAssetTypeDate: 'fixed-asset-type-date',
    FixedAssetTypeLocked: 'fixed-asset-type-locked',
    FixedAssetTypeAssetCost: 'fixed-asset-type-asset-cost',
    FixedAssetTypeAccumulatedDepreciation:
        'fixed-asset-type-accumulated-depreciation',
    FixedAssetTypeRemainingValue: 'fixed-asset-type-remaining-value',
    DateFixedAssetType: 'date-fixed-asset-type',
    DateLocked: 'date-locked',
    DateAssetCost: 'date-asset-cost',
    DateAccumulatedDepreciation: 'date-accumulated-depreciation',
    MultiGridSelected: 'multi-grid-selected',
    MultiGridAssetCode: 'multi-grid-asset-code',
    MultiGridRecoveryInterval: 'multi-grid-recovery-interval',
    MultiGridCost: 'multi-grid-cost',
    MultiGridAccumulatedDepreciation: 'multi-grid-accumulated-depreciation',
    MultiGridRemainingValue: 'multi-grid-remaining-value',
    MultiGridLocked: 'multi-grid-locked',
};

export const DepreciationEntriesGridDefs: DepreciationEntriesGrid = {
    Selected: 'depreciation-entries-main-grid-selected',
    Depreciation_Method_Type:
        'depreciation-entries-main-grid-depreciation-method-type',
    Fixed_Asset_Type: 'depreciation-entries-main-grid-fixed-asset-type',
    Date: 'depreciation-entries-main-grid-date',
    Locked: 'depreciation-entries-main-grid-locked',
    Account_Number: 'depreciation-entries-main-grid-account-number',
    Entry_Description: 'depreciation-entries-main-grid-entry-description',
    Debit: 'depreciation-entries-main-grid-debit',
    Credit: 'depreciation-entries-main-grid-credit',
    Options_Title: 'depreciation-entries-options-title',
    Options_Depreciation_Method_Type:
        'depreciation-entries-options-depreciation-method-type',
    Options_Interval_Date_Begin:
        'depreciation-entries-options-interval-date-begin',
    Options_Interval_Date_End: 'depreciation-entries-options-interval-date-end',
    Options_Asset_Type: 'depreciation-entries-options-asset-type',
    Options_Get_Depreciation_Entries:
        'depreciation-entries-options-get-depreciation-entries',
    Actions_Title: 'depreciation-entries-actions-title',
    Actions_Unlock_Entries: 'depreciation-entries-actions-unlock-entries',
    Actions_Lock_Entries: 'depreciation-entries-actions-lock-entries',
    Actions_Process_Changes: 'depreciation-entries-actions-process-changes',
    Toolbar_Actions: 'depreciation-entries-toolbar-actions',
    Toolbar_Options: 'depreciation-entries-toolbar-options',
    Toolbar_Download_Entries: 'depreciation-entries-toolbar-download-entries',
    Depreciation_Method_Type_Asset_Code:
        'depreciation-entries-depreciation-method-type-asset-code',
    Depreciation_Method_Type_Interval:
        'depreciation-entries-depreciation-method-type-interval',
    Depreciation_Method_Type_Locked:
        'depreciation-entries-depreciation-method-type-locked',
    Depreciation_Method_Type_Account_Number:
        'depreciation-entries-depreciation-method-type-account-number',
    Depreciation_Method_Type_Account_Name:
        'depreciation-entries-depreciation-method-type-account-name',
    Depreciation_Method_Type_Debit:
        'depreciation-entries-depreciation-method-type-debit',
    Depreciation_Method_Type_Credit:
        'depreciation-entries-depreciation-method-type-credit',
    Fixed_Asset_Type_Date: 'depreciation-entries-fixed-asset-type-date',
    Fixed_Asset_Type_Locked: 'depreciation-entries-fixed-asset-type-locked',
    Fixed_Asset_Type_Account_Number:
        'depreciation-entries-fixed-asset-type-account-number',
    Fixed_Asset_Type_Entry_Description:
        'depreciation-entries-fixed-asset-type-entry-description',
    Fixed_Asset_Type_Debit: 'depreciation-entries-fixed-asset-type-debit',
    Fixed_Asset_Type_Credit: 'depreciation-entries-fixed-asset-type-credit',
    Date_Fixed_Asset_Type: 'depreciation-entries-date-fixed-asset-type',
    Multi_Grid_Selected: 'depreciation-entries-multi-grid-selected',
    Multi_Grid_Asset_Code: 'depreciation-entries-multi-grid-asset-code',
    Multi_Grid_Interval: 'depreciation-entries-multi-grid-interval',
    Multi_Grid_Locked: 'depreciation-entries-multi-grid-locked',
    Multi_Grid_Account_Number: 'depreciation-entries-multi-grid-account-number',
    Multi_Grid_Account_Name: 'depreciation-entries-multi-grid-account-name',
    Multi_Grid_Debit: 'depreciation-entries-multi-grid-debit',
    Multi_Grid_Credit: 'depreciation-entries-multi-grid-credit',
    Download_Entries_Title: 'download-entries-title',
    Download_Entries_Date: 'download-entries-date',
    Download_Entries_Account_Number: 'download-entries-account-number',
    Download_Entries_Entry_Description: 'download-entries-entry-description',
    Download_Entries_Debit: 'download-entries-debit',
    Download_Entries_Credit: 'download-entries-credit',
};

export const FixedAssetRegisterFormDefs: FixedAssetRegisterForm = {
    Code: 'fixed-asset-register-form-code',
    Name: 'fixed-asset-register-form-name',
    Description: 'fixed-asset-register-form-description',
    Status: 'fixed-asset-register-form-status',
    AcquireDate: 'fixed-asset-register-form-acquire-date',
    InServiceDate: 'fixed-asset-register-form-in-service-date',
    DisposalDate: 'fixed-asset-register-form-disposal-date',
    AssetTypeBook: 'fixed-asset-register-form-asset-type-book',
    AssetTypeTax: 'fixed-asset-register-form-asset-type-tax',
    References: 'fixed-asset-register-form-references',
    Building: 'fixed-asset-register-form-building',
    ManufacturerSerial: 'fixed-asset-register-form-manufacturer-serial',
    PurchaseOrder: 'fixed-asset-register-form-purchase-order',
    SupplierInvoice: 'fixed-asset-register-form-supplier-invoice',
    CapitalProject: 'fixed-asset-register-form-capital-project',
    Costs: 'fixed-asset-register-form-costs',
    Contracts: 'fixed-asset-register-form-contracts',
    SchedulesAndEntries: 'fixed-asset-register-form-schedules-entries',
    DepreciationSchedules: 'fixed-asset-register-form-depreciation-schedules',
    DepreciationEntries: 'fixed-asset-register-form-depreciation-entries',
    DisposalEntry: 'fixed-asset-register-form-disposal-entry',
    AssetEntry: 'fixed-asset-register-form-asset-entry',
    FixedAssetCode: 'fixed-asset-register-fixed-asset-code',
    ContractNumber: 'contracts-grid-number',
    ContractName: 'contracts-grid-name',
    ContractDescription: 'contracts-grid-description',
    ContractType: 'contracts-grid-type',
    CostsCode: 'costs-grid-code',
    CostsAmount: 'costs-grid-amount',
    DepreciationSchedulesRecoveryInterval:
        'depreciation-schedules-grid-recovery-interval',
    DepreciationSchedulesDate: 'depreciation-schedules-grid-date',
    DepreciationSchedulesPercentage: 'depreciation-schedules-grid-percentage',
    DepreciationSchedulesAssetCost: 'depreciation-schedules-grid-asset-cost',
    DepreciationSchedulesAccumulatedDepreciation:
        'depreciation-schedules-grid-accumulated-depreciation',
    DepreciationSchedulesRemainingValue:
        'depreciation-schedules-grid-remaining-value',
    DepreciationSchedulesLocked: 'depreciation-schedules-grid-locked',
    DepreciationEntriesRecoveryInterval:
        'depreciation-entries-grid-recovery-interval',
    DepreciationEntriesDate: 'depreciation-entries-grid-date',
    DepreciationEntriesAccountNumber:
        'depreciation-entries-grid-account-number',
    DepreciationEntriesDebit: 'depreciation-entries-grid-debit',
    DepreciationEntriesCredit: 'depreciation-entries-grid-credit',
    DepreciationEntriesLocked: 'depreciation-entries-grid-locked',
    DisposalEntryDisposalDate: 'disposal-entry-grid-disposal-date',
    DisposalEntryAccountNumber: 'disposal-entry-grid-account-number',
    DisposalEntryEntryDescription: 'disposal-entry-grid-entry-description',
    DisposalEntryDebit: 'disposal-entry-grid-debit',
    DisposalEntryCredit: 'disposal-entry-grid-credit',
    DisposalEntryLocked: 'disposal-entry-grid-locked',
    AssetEntryDate: 'asset-entry-grid-entry-date',
    AssetEntryAccountNumber: 'asset-entry-grid-account-number',
    AssetEntryDescription: 'asset-entry-grid-entry-description',
    AssetEntryDebit: 'asset-entry-grid-debit',
    AssetEntryCredit: 'asset-entry-grid-credit',
    AssetEntryLocked: 'asset-entry-grid-locked',
    DepreciationEntriesEntryDescription:
        'depreciation-entries-grid-entry-description',
    Contract_Selection: 'contracts-selection-contract-selection',
    Contract_Selection_Number: 'contracts-selection-number',
    Contract_Selection_Name: 'contracts-selection-name',
    Contract_Selection_Type: 'contracts-selection-type',
};
export const ContractsFormDefs: ContractsForm = {
    Number: 'contracts-form-number',
    Name: 'contracts-form-name',
    Description: 'contracts-form-description',
    Provider: 'contracts-form-provider',
    Type: 'contracts-form-contract-type',
    Start_Date: 'contracts-form-start-date',
    End_Date: 'contracts-form-end-date',
    Amount: 'contracts-form-contract-amount',
    Title: 'contracts-form-title',
};

export const DisposalEntriesGridDefs: DisposalEntriesGrid = {
    Selected: 'disposal-entries-grid-selected',
    Disposal_Date: 'disposal-entries-grid-disposal-date',
    Fixed_Asset_Type: 'disposal-entries-grid-fixed-asset-type',
    Locked: 'disposal-entries-grid-locked',
    Account_Number: 'disposal-entries-grid-account-number',
    Entry_Description: 'disposal-entries-grid-entry-description',
    Debit: 'disposal-entries-grid-debit',
    Credit: 'disposal-entries-grid-credit',
    Asset_Code: 'disposal-entries-grid-asset-code',
    Date: 'disposal-entries-grid-date',
    Toolbar_Options: 'disposal-entries-grid-toolbar-options',
    Toolbar_Actions: 'disposal-entries-grid-toolbar-actions',
    Toolbar_Download_Entries: 'disposal-entries-grid-toolbar-download-entries',
    Actions_Title: 'disposal-entries-grid-actions-title',
    Actions_Unlock_Entries: 'disposal-entries-grid-actions-unlock-entries',
    Actions_Lock_Entries: 'disposal-entries-grid-actions-lock-entries',
    Actions_Process_Changes: 'disposal-entries-grid-actions-changes',
    Options_Title: 'disposal-entries-grid-options-title',
    Options_Asset_Types: 'disposal-entries-grid-options-asset-types',
    Options_Disposal_Date_Begin:
        'disposal-entries-grid-options-disposal-date-begin',
    Options_Disposal_Date_End:
        'disposal-entries-grid-options-disposal-date-end',
    Options_Get_Disposal_Entries:
        'disposal-entries-grid-options-get-disposal-entries',
    Download_Entries_Title: 'disposal-entries-grid-download-entries-title',
    Back: 'disposal-entries-grid-toolbar-back',
};
export const AssetEntriesGridDefs: AssetEntriesGrid = {
    Selected: 'asset-entries-grid-selected',
    Date: 'asset-entries-grid-date',
    Fixed_Asset_Type: 'asset-entries-grid-fixed-asset-type',
    Locked: 'asset-entries-grid-locked',
    Account_Number: 'asset-entries-grid-account-number',
    Entry_Description: 'asset-entries-grid-entry-description',
    Debit: 'asset-entries-grid-debit',
    Credit: 'asset-entries-grid-credit',
    Asset_Code: 'asset-entries-grid-asset-code',
    Account_Name: 'asset-entries-grid-account-name',
    Asset_Type: 'asset-entries-grid-asset-type',
    Entry_Date: 'asset-entries-grid-entry-date',
    Toolbar_Back: 'asset-entries-grid-toolbar-Back',
    Toolbar_Actions: 'asset-entries-grid-toolbar-actions',
    Toolbar_Options: 'asset-entries-grid-toolbar-options',
    Toolbar_Download_Entries: 'asset-entries-grid-toolbar-download-entries',
    Actions_Title: 'asset-entries-grid-actions-title',
    Actions_Unlock_Entries: 'asset-entries-grid-actions-unlock-entries',
    Actions_Lock_Entries: 'asset-entries-grid-actions-lock-entries',
    Actions_Changes: 'asset-entries-grid-actions-changes',
    Options_Title: 'asset-entries-grid-options-title',
    Options_Asset_Types: 'asset-entries-grid-options-asset-types',
    Options_Interval_Date_Begin:
        'asset-entries-grid-options-disposal-date-begin',
    Options_Interval_Date_End: 'asset-entries-grid-options-disposal-date-end',
    Options_Get_Disposal_Entries:
        'asset-entries-grid-options-get-disposal-entries',
    Download_Entries_Title: 'asset-entries-grid-download-entries-title',
};
export const ContractsGridDefs: ContractsGrid = {
    Number: 'fixed-asset-contracts-grid-number',
    Name: 'fixed-asset-contracts-grid-name',
    Description: 'fixed-asset-contracts-grid-description',
    Provider: 'fixed-asset-contracts-grid-provider',
    Type: 'fixed-asset-contracts-grid-type',
    Start_Date: 'fixed-asset-contracts-grid-start-date',
    End_Date: 'fixed-asset-contracts-grid-end-date',
    Amount: 'fixed-asset-contracts-grid-amount',
    Created_At: 'fixed-asset-contracts-grid-created-at',
    Created_By: 'fixed-asset-contracts-grid-created-by',
    Updated_At: 'fixed-asset-contracts-grid-updated-at',
    Updated_By: 'fixed-asset-contracts-grid-updated-by',
};
export const ContractCoverageGridDefs: ContractCoverageGrid = {
    Contract_Type: 'contract-coverage-contract-type',
    Contract_Number: 'contract-coverage-contract-number',
    Contract_Name: 'contract-coverage-contract-name',
    Provider: 'contract-coverage-provider',
    Start_Date: 'contract-coverage-start-date',
    End_Date: 'contract-coverage-end-date',
    Contract_Amount: 'contract-coverage-contract-amount',
    Asset_Cost: 'contract-coverage-asset-cost',
    Remaining_Value: 'contract-coverage-remaining-value',
    Asset_Code: 'contract-coverage-asset-code',
    Asset_Name: 'contract-coverage-asset-name',
    Asset_Status: 'contract-coverage-asset-status',
    Asset_Type: 'contract-coverage-asset-type',
    Acquire_Date: 'contract-coverage-acquire-date',
    In_Service_Date: 'contract-coverage-in-service-date',
    Disposal_Date: 'contract-coverage-disposal-date',
    Options_Contract_Types: 'contract-coverage-options-contract-types',
    Options_Contract_Date_Begin:
        'contract-coverage-options-contract-date-begin',
    Options_Contract_Date_End: 'contract-coverage-options-contract-date-end',
    Options_Remaining: 'contract-coverage-options-remaining',
    Options_Get_Contracts: 'contract-coverage-options-get-contracts',
    Options_Title: 'contract-coverage-options-title',
    Toolbar_Options: 'contract-coverage-toolbar-options',
};
export const FunctionCodesGridDefs: FunctionCodesGrid = {
    Function_Code: 'function-codes-grid-function-code',
    Number: 'function-codes-grid-account-number',
    Name: 'function-codes-grid-account-name',
};
export const CurrencyGridDefs: CurrencyGrid = {
    Title: 'currency-grid-title',
    Id: 'currency-grid-id',
    BusinessEntity: 'currency-grid-business-entity',
    SortOrder: 'currency-grid-sort-order',
    CurrencyCode: 'currency-grid-code',
    Number: 'currency-grid-number',
    FullName: 'currency-grid-full-name',
    ShortName: 'currency-grid-short-name',
    Symbol: 'currency-grid-symbol',
    DecimalDisplay: 'currency-grid-decimal-display',
    CreatedAt: 'currency-grid-created-date',
    CreatedBy: 'currency-grid-created-by',
    UpdatedAt: 'currency-grid-updated-date',
    UpdatedBy: 'currency-grid-updated-by',
};
export const CurrencyFormDefs: CurrencyForm = {
    Title: 'currency-form-title',
    CurrencyCode: 'currency-form-code',
    Number: 'currency-form-number',
    FullName: 'currency-form-full-name',
    ShortName: 'currency-form-short-name',
    ShortHandSymbol: 'currency-form-short-hand-symbol',
    DecimalDisplay: 'currency-form-decimal-display',
    SortOrder: 'currency-form-sort-order',
    CreatedAt: 'currency-form-created-date',
    CreatedBy: 'currency-form-created-by',
    UpdatedAt: 'currency-form-updated-date',
    UpdatedBy: 'currency-form-updated-by',
    ExchangeRateSection: 'currency-form-exchange-rate-section',
};

export const CurrencyRateTypeGridDefs: CurrencyRateTypeGrid = {
    Id: 'currency-rate-types-grid-id',
    BusinessEntityId: 'currency-rate-types-grid-businessEntityId',
    SortOrder: 'currency-rate-types-grid-sort-order',
    CurrencyRateTypeCode: 'currency-rate-types-grid-currency-rate-type-code',
    Code: 'currency-rate-types-grid-code',
    Description: 'currency-rate-types-grid-description',
    CreatedAt: 'currency-rate-types-grid-createAt',
    CreatedBy: 'currency-rate-types-grid-createdBy',
    UpdatedAt: 'currency-rate-types-grid-updatedAt',
    UpdatedBy: 'currency-rate-types-grid-updatedBy',
};

export const CurrencyRateTypeFormDefs: CurrencyRateTypeForm = {
    SortOrder: 'currency-rate-types-form-sort-order',
    CurrencyRateTypeCode: 'currency-rate-types-form-currency-rate-type-code',
    Code: 'currency-rate-types-form-code',
    Description: 'currency-rate-types-form-description',
    CreatedAt: 'currency-rate-types-form-createAt',
    CreatedBy: 'currency-rate-types-form-createdBy',
    UpdatedAt: 'currency-rate-types-form-updatedAt',
    UpdatedBy: 'currency-rate-types-form-updatedBy',
};

export const CurrencyExchangeRateGridDefs: CurrencyExchangeRateGrid = {
    Title: 'currency-exchange-rates-grid-title',
    Id: 'currency-exchange-rates-grid-id',
    BusinessEntityId: 'currency-exchange-rates-grid-business-entity-id',
    SourceCurrencyCode: 'currency-exchange-rates-grid-source-currency-code',
    TargetCurrencyCode: 'currency-exchange-rates-grid-target-currency-code',
    CurrencyRateTypeId: 'currency-exchange-rates-grid-currency-rate-type-id',
    CurrencyRateTypeCode:
        'currency-exchange-rates-grid-currency-rate-type-code',
    ExchangeRate: 'currency-exchange-rates-grid-exchange-rate',
    EffectiveAt: 'currency-exchange-rates-grid-effective-at',
    CreatedAt: 'currency-exchange-rates-grid-created-at',
    CreatedBy: 'currency-exchange-rates-grid-created-by',
    UpdatedAt: 'currency-exchange-rates-grid-updated-at',
    UpdatedBy: 'currency-exchange-rates-grid-updated-by',
};

export const CurrencyExchangeRateFilterDefs: CurrencyExchangeRateFilter = {
    Title: 'currency-exchange-rates-filters-title',
    SourceCurrencyCode: 'currency-exchange-rates-filters-source-currency-code',
    TargetCurrencyCode: 'currency-exchange-rates-filters-target-currency-code',
    EffectiveDateBegin: 'currency-exchange-rates-filters-effective-date-begin',
    EffectiveDateEnd: 'currency-exchange-rates-filters-effective-date-end',
    TextButton: 'currency-exchange-rates-filters-text-button',
};

export const FiscalYearStatusGridDefs: FiscalYearStatus = {
    Title: 'fiscal-year-status-grid-title',
    Code: 'fiscal-year-status-grid-code',
    SortOrder: 'fiscal-year-status-grid-sortOrder',
    Description: 'fiscal-year-status-grid-description',
    Closed: 'fiscal-year-status-grid-closed',
};

export const FiscalYearStatusFormDefs: FiscalYearStatus = {
    Title: 'fiscal-year-status-form-title',
    Code: 'fiscal-year-status-form-code',
    SortOrder: 'fiscal-year-status-form-sortOrder',
    Description: 'fiscal-year-status-form-description',
    Closed: 'fiscal-year-status-form-closed',
};

export const FiscalYearGridDefs: FiscalYear = {
    Title: 'fiscal-year-grid-title',
    Code: 'fiscal-year-grid-code',
    Number: 'fiscal-year-grid-number',
    Status: 'fiscal-year-grid-status',
    StartDate: 'fiscal-year-grid-start-date',
    EndDate: 'fiscal-year-grid-end-date',
};

export const FiscalYearFormDefs: FiscalYear = {
    Title: 'fiscal-year-form-title',
    Code: 'fiscal-year-form-code',
    Number: 'fiscal-year-form-number',
    Status: 'fiscal-year-form-status',
    StartDate: 'fiscal-year-form-start-date',
    EndDate: 'fiscal-year-form-end-date',
};

export const AccountingSettingDefs: AccountingSettings = {
    NegativeNumberFormat: 'accounting-settings-negative-number-format',
    Title: 'accounting-settings-title',
};
export const FunctionCodesFormDefs: FunctionCodesForm = {
    Fixed_Asset_Function_Code: 'function-codes-form-fixed-assets-function-code',
    Accounting_Function_Code: 'function-codes-form-accounting-function-code',
    Function_Code: 'function-codes-form-function-code',
    Description: 'function-codes-form-description',
    Accounts: 'function-codes-form-accounts',
    Selected: 'function-codes-form-selected',
    Number: 'function-codes-form-number',
    Name: 'function-codes-form-name',
};

export const AssetCostCodesGridDefs: AssetCostCodesGrid = {
    Asset_Cost_Codes: 'asset-cost-codes-grid-asset-cost-codes',
    Code: 'asset-cost-codes-grid-code',
    Is_Add: 'asset-cost-codes-grid-is-add',
};

export const AssetCostCodesFormDefs: AssetCostCodesForm = {
    Fixed_Asset_Cost_Code: 'asset-cost-codes-form-fixed-asset-cost-code',
    Cost_Code: 'asset-cost-codes-form-cost-code',
    Sort_Order: 'asset-cost-codes-form-sort-order',
    Is_Add: 'asset-cost-codes-form-is-add',
};

export const DepreciationMethodStatusesGridDefs: DepreciationMethodStatusesGrid =
    {
        Depreciation_Method_Statuses:
            'depreciation-method-statuses-grid-depreciation-method-statuses',
        Code: 'depreciation-method-statuses-grid-code',
        Description: 'depreciation-method-statuses-grid-description',
        Active: 'depreciation-method-statuses-grid-active',
    };

export const DepreciationMethodStatusesFormDefs: DepreciationMethodStatusesForm =
    {
        Fixed_Asset_Depreciation_Method_Statuses:
            'depreciation-method-statuses-form-fixed-asset-depreciation-method-status',
        Code: 'depreciation-method-statuses-form-code',
        Description: 'depreciation-method-statuses-form-description',
        Sort_Order: 'depreciation-method-statuses-form-sort-order',
        Active: 'depreciation-method-statuses-form-active',
    };

export const CurrencyExchangeRateFormDefs: CurrencyExchangeRateForm = {
    Title: 'currency-exchange-rates-form-title',
    SourceCurrencyCode: 'currency-exchange-rates-form-source-currency-code',
    TargetCurrencyCode: 'currency-exchange-rates-form-target-currency-code',
    CurrencyRateTypeId: 'currency-exchange-rates-form-currency-rate-type-id',
    ExchangeRate: 'currency-exchange-rates-form-exchange-rate',
    EffectiveAt: 'currency-exchange-rates-form-effective-at',
};

export const DepreciationMethodTypesGridDefs: DepreciationMethodTypesGrid = {
    Depreciation_Method_Types:
        'depreciation-method-types-grid-depreciation-method-types',
    Code: 'depreciation-method-types-grid-code',
    Description: 'depreciation-method-types-grid-description',
    Journal_Posting: 'depreciation-method-types-grid-journal-posting',
    Predefined_Schedule: 'depreciation-method-types-grid-predefined-schedule',
};

export const DepreciationMethodTypesFormDefs: DepreciationMethodTypesForm = {
    Fixed_Asset_Depreciation_Method_Type:
        'depreciation-method-types-form-fixed-asset-depreciation-method-type',
    Depreciation_Method_Type_Code:
        'depreciation-method-types-form-depreciation-method-type-code',
    Sort_Order: 'depreciation-method-types-form-sort-order',
    Description: 'depreciation-method-types-form-description',
    Journal_Posting: 'depreciation-method-types-form-journal-posting',
    Predefined_Schedule: 'depreciation-method-types-form-predefined-schedule',
};

export const DepreciationMethodsGridDefs: DepreciationMethodsGrid = {
    Depreciation_Methods: 'depreciation-methods-grid-depreciation-methods',
    Code: 'depreciation-methods-grid-code',
    Name: 'depreciation-methods-grid-name',
    Description: 'depreciation-methods-grid-description',
    Status: 'depreciation-methods-grid-status',
    Type: 'depreciation-methods-grid-type',
    Cost_Code: 'depreciation-methods-grid-cost-code',
};

export const DepreciationMethodsFormDefs: DepreciationMethodsForm = {
    Fixed_Asset_Depreciation_Methods:
        'depreciation-methods-form-fixed-asset-depreciation-method',
    Depreciation_Method_Code:
        'depreciation-methods-form-depreciation-method-code',
    Depreciation_Method_Name:
        'depreciation-methods-form-depreciation-method-name',
    Sort_Order: 'depreciation-methods-form-sort-order',
    Description: 'depreciation-methods-form-description',
    Depreciation_Method_Status:
        'depreciation-methods-form-depreciation-method-status',
    Depreciation_Method_Type:
        'depreciation-methods-form-depreciation-method-type',
    Cost_Codes_Title: 'depreciation-methods-form-cost-codes-title',
    Selected: 'depreciation-methods-form-selected',
    Cost_Code: 'depreciation-methods-form-cost-code',
    Total_Amount: 'depreciation-methods-form-total-amount',
    Depreciation_Basis: 'depreciation-methods-form-depreciation-basis',
    Depreciation_Option: 'depreciation-methods-form-depreciation-option',
    Number_Of_Recovery_Intervals:
        'depreciation-methods-form-number-of-recovery-intervals',
    Recovery_Interval: 'depreciation-methods-form-recovery-interval',
    Percentage: 'depreciation-methods-form-percentage',
    Straight_Line: 'depreciation-methods-form-straight-line',
    Double_Declining: 'depreciation-methods-form-double-declining',
    Sum_Of_Years_Digits: 'depreciation-methods-form-sum-of-years-digits',
};

export const AssetStatusesGridDefs: AssetStatusesGrid = {
    Asset_Statuses: 'asset-statuses-grid-asset-statuses',
    Code: 'asset-statuses-grid-code',
    Description: 'asset-statuses-grid-description',
    In_Service: 'asset-statuses-grid-in-service',
    Disposal: 'asset-statuses-grid-disposal',
};

export const AssetStatusesFormDefs: AssetStatusesForm = {
    Fixed_Asset_Status: 'asset-statuses-form-fixed-asset-status',
    Status_Code: 'asset-statuses-form-status-code',
    Description: 'asset-statuses-form-description',
    Sort_Order: 'asset-statuses-form-sort-order',
    In_Service: 'asset-statuses-form-in-service',
    Disposal: 'asset-statuses-form-disposal',
};

export const AssetTypesGridDefs: AssetTypesGrid = {
    Asset_Types: 'asset-types-grid-asset-types',
    Code: 'asset-types-grid-code',
    Description: 'asset-types-grid-description',
    Recovery_Term: 'asset-types-grid-recovery-term',
    Recovery_Unit: 'asset-types-grid-recovery-unit',
    Depreciation_Method: 'asset-types-grid-depreciation-method',
};

export const AssetTypesFormDefs: AssetTypesForm = {
    Fixed_Asset_Type: 'asset-types-form-fixed-asset-type',
    Code: 'asset-types-form-code',
    Sort_Order: 'asset-types-form-sort-order',
    Description: 'asset-types-form-description',
    Recovery_Term: 'asset-types-form-recovery-term',
    Recovery_Unit: 'asset-types-form-recovery-unit',
    Depreciation_Method: 'asset-types-form-depreciation-method',
    Accounts: 'asset-types-form-accounts',
    Depreciation_Expense_Account:
        'asset-types-form-depreciation-expense-account',
    Accumulated_Depreciation_Account:
        'asset-types-form-accumulated-depreciation-account',
    Asset_Account: 'asset-types-form-asset-account',
    Asset_Control_Account: 'asset-types-form-asset-control-account',
    Disposal_Gain_Account: 'asset-types-form-disposal-gain-account',
    Disposal_Loss_Account: 'asset-types-form-disposal-loss-account',
    Disposal_Settlement_Account: 'asset-types-form-disposal-settlement-account',
};

export const PeriodStatusGridDefs: PeriodStatus = {
    Title: 'period-status-grid-title',
    Code: 'period-status-grid-code',
    SortOrder: 'period-status-grid-sortOrder',
    Description: 'period-status-grid-description',
    Closed: 'period-status-grid-closed',
};

export const PeriodStatusFormDefs: PeriodStatus = {
    Title: 'period-status-form-title',
    Code: 'period-status-form-code',
    SortOrder: 'period-status-form-sortOrder',
    Description: 'period-status-form-description',
    Closed: 'period-status-form-closed',
};

export const ContractTypesGridDefs: ContractTypesGrid = {
    Contract_Types: 'contract-types-grid-contract-types',
    Code: 'contract-types-grid-code',
    Description: 'contract-types-grid-description',
};

export const ContractTypesFormDefs: ContractTypesForm = {
    Contract_Type: 'contract-types-form-contract-type',
    Code: 'contract-types-form-code',
    Sort_Order: 'contract-types-form-sort-order',
    Description: 'contract-types-form-description',
};

export const GeneralLedgerAccountsGridDefs: GeneralLedgerAccountsGrid = {
    Account_Number: 'general-ledger-accounts-grid-account-number',
    Account_Name: 'general-ledger-accounts-grid-account-name',
    Description: 'general-ledger-accounts-grid-description',
    Account_Status: 'general-ledger-accounts-grid-account-status',
    Balance_Type: 'general-ledger-accounts-grid-balance-type',
    Account_Type: 'general-ledger-accounts-grid-account-type',
};

export const GeneralLedgerAccountsFormDefs: GeneralLedgerAccountsForm = {
    General_Ledger_Account:
        'general-ledger-accounts-form-general-ledger-account',
    Number: 'general-ledger-accounts-form-number',
    Name: 'general-ledger-accounts-form-name',
    Description: 'general-ledger-accounts-form-description',
    Status: 'general-ledger-accounts-form-account-status',
    Balance_Type: 'general-ledger-accounts-form-balance-type',
    Account_Type: 'general-ledger-accounts-form-account-type',
    Account_Functions: 'general-ledger-accounts-form-account-functions',
    Function_Code: 'general-ledger-accounts-form-function-code',
    Account_Functions_Description:
        'general-ledger-accounts-form-account-functions-description',
};

export const PeriodQuartersGridDefs: PeriodQuartersGrid = {
    Title: 'period-quarter-grid-title',
    Period_Quarter_Code: 'period-quarter-grid-code',
    Description: 'period-quarter-grid-description',
    Sort_Order: 'period-quarter-grid-sortOrder',
};
export const PeriodQuarterFormDefs: PeriodQuarterForm = {
    Title: 'period-quarter-form-title',
    Period_Quarter_Code: 'period-quarter-form-code',
    Description: 'period-quarter-form-description',
    Sort_Order: 'period-quarter-form-sortOrder',
};

export const AcquisitionsGridDefs: AcquisitionsGrid = {
    Acquisition_Report: 'acquisitions-grid-acquisition-report',
    Asset_No: 'acquisitions-grid-asset-no',
    Asset_Code: 'acquisitions-grid-asset-code',
    Asset_Name: 'acquisitions-grid-asset-name',
    Acquire_Date: 'acquisitions-grid-acquire-date',
    In_Service_Date: 'acquisitions-grid-in-service-date',
    Total: 'acquisitions-grid-total',
    Acquisition_Options: 'acquisitions-grid-acquisition-options',
    Acquire_Date_Begin: 'acquisitions-grid-acquire-date-begin',
    Acquire_Date_End: 'acquisitions-grid-acquire-date-end',
    Get_Acquisitions: 'acquisitions-grid-get-acquisitions',
};

export const DepreciationSummaryGridDefs: DepreciationSummaryGrid = {
    Depreciation_Summary_Report:
        'depreciation-summary-grid-depreciation-summary-report',
    Asset_Type: 'depreciation-summary-grid-asset-type',
    Asset_No: 'depreciation-summary-grid-asset-no',
    Asset_Code: 'depreciation-summary-grid-asset-code',
    Asset_Name: 'depreciation-summary-grid-asset-name',
    In_Service_Date: 'depreciation-summary-grid-in-service-date',
    Total_Cost: 'depreciation-summary-grid-total-cost',
    Beginning_Value: 'depreciation-summary-grid-beginning-value',
    Depreciation: 'depreciation-summary-grid-depreciation',
    Ending_Value: 'depreciation-summary-grid-ending-value',
    Depreciation_Summary_Options:
        'depreciation-summary-grid-depreciation-summary-options',
    Selection_Asset_Types: 'depreciation-summary-grid-selection-asset-types',
    Depreciation_Date_Begin:
        'depreciation-summary-grid-depreciation-date-begin',
    Depreciation_Date_End: 'depreciation-summary-grid-depreciation-date-end',
    Get_Depreciation_Summary:
        'depreciation-summary-grid-get-depreciation-summary',
};

export const DepreciationDetailGridDefs: DepreciationDetailGrid = {
    Depreciation_Detail_Report:
        'depreciation-detail-grid-depreciation-detail-report',
    Asset_No: 'depreciation-detail-grid-asset-no',
    Asset_Code: 'depreciation-detail-grid-asset-code',
    Asset_Name: 'depreciation-detail-grid-asset-name',
    Depreciation_Method: 'depreciation-detail-grid-depreciation-method',
    Total: 'depreciation-detail-grid-total',
    Depreciation_Detail_Options:
        'depreciation-detail-grid-depreciation-detail-options',
    Asset_Type: 'depreciation-detail-grid-asset-type',
    Depreciation_Date_Begin: 'depreciation-detail-grid-depreciation-date-begin',
    Depreciation_Date_End: 'depreciation-detail-grid-depreciation-date-end',
    Get_Depreciation_Details:
        'depreciation-detail-grid-get-depreciation-details',
};

export const DisposalsGridDefs: DisposalsGrid = {
    Disposal_Report: 'disposals-grid-disposal-report',
    Asset_No: 'disposals-grid-asset-no',
    Asset_Code: 'disposals-grid-asset-code',
    Asset_Name: 'disposals-grid-asset-name',
    Acquire_Date: 'disposals-grid-acquire-date',
    In_Service_Date: 'disposals-grid-in-service-date',
    Disposal_Date: 'disposals-grid-disposal-date',
    Asset_Cost: 'disposals-grid-asset-cost',
    Total_Depreciation: 'disposals-grid-total-depreciation',
    Asset_Value: 'disposals-grid-asset-value',
    Settlement: 'disposals-grid-settlement',
    Disposal_Gain_Loss: 'disposals-grid-disposal-gain-loss',
    Disposal_Options: 'disposals-grid-disposal-options',
    Disposal_Date_Begin: 'disposals-grid-disposal-date-begin',
    Disposal_Date_End: 'disposals-grid-disposal-date-end',
    Get_Disposals: 'disposals-grid-get-disposals',
};
