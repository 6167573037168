import React from 'react';
import { useParams } from 'react-router-dom';
import {
    Checkbox,
    FormControlLabel,
    FormGroup,
    Grid,
    TextField,
} from '@mui/material';
import TextInput from '../../components/form/formInputs/TextInput/TextInput';
import FormPartial from '../../components/form/formContainer/FormPartial';
import { useUserForm } from './hooks/useUserForm';
import SelectInput from '../../components/form/formInputs/SelectInput/SelectInput';
import { USER_FIELDS } from '../../constants/revisionHistory/revisionHistory.constants';
import BusinessEntityTreeViewSelect from './BusinessEntityTreeViewSelect';
import ExpandableDropdown from '../../components/form/expandableDropdown/ExpandableDropdown';
import { isFeatureFlagEnabled } from '../../utils/featureFlag/featureFlagUtil';
import BaseForm from '../../components/form/baseForm/BaseForm';
import DateInput from '../../components/form/formInputs/DateInput/DateInput';
import TranslatableText from '../../components/i18n/TranslatableText';
import { UserFormDefs } from '../../constants/i18n/translations/termDefinitions/platform';
import NameInput from '../../components/form/formInputs/NameInput/NameInput';

type ParamTypes = { id: string };

const UserForm = () => {
    const { id } = useParams<ParamTypes>();
    const {
        fields,
        user,
        employeeFields,
        userTrialFields,
        userData,
        isLoading,
        businessEntityOptions,
        subscriberOptions,
        messagingPreferenceOptions,
        groupsOptions,
        rolesOptions,
        departmentCodeOptions,
        usersDropdownOptions,
        handleUpdateUser,
        handleFieldChange,
        handleEmployeeFieldChange,
        handleUserTrialFieldChange,
        handleClose,
        setValues,
        employeeFormMethods,
        userTrialFormMethods,
        formMethods,
        defaultSelectedBusinessEntities,
        canCreateUser,
        canEditUser,
        canDeleteUser,
        showDeleteConfirmModal,
        additionalToolbarButtons,
        showIsDatacorCompanyConfirmModal,
        apaOptions,
        termSet,
    } = useUserForm(id);

    const shouldShowEmployeeInfo = !user?.subscriber?.isDatacor;

    const handleRenderSelectDropdown = () => {
        if (fields.isDatacorAdmin) {
            return null;
        }

        if (user?.subscriber?.isDatacor) {
            return (
                <SelectInput
                    fullWidth
                    SelectProps={{
                        multiple: true,
                    }}
                    label={
                        <TranslatableText
                            termKey={UserFormDefs.Subscribers}
                            termSet={termSet}
                        />
                    }
                    id="subscribers"
                    value={fields.subscribers}
                    onChange={handleFieldChange}
                    options={subscriberOptions}
                    disabledOptions={[user?.subscriber?.id]}
                    formMethods={formMethods}
                />
            );
        }
        return (
            <>
                <BusinessEntityTreeViewSelect
                    items={businessEntityOptions}
                    idKey="id"
                    labelKey="name"
                    valueSelected={(values: any) => {
                        setValues({
                            businessEntities: values,
                        });
                    }}
                    defaultBusinessEntityId={
                        id
                            ? userData?.businessEntity?.id
                            : user?.businessEntity?.id
                    }
                    disableChildrenOnParentSelect={true}
                    disabledItems={defaultSelectedBusinessEntities}
                    defaultValue={
                        fields?.businessEntities || []
                    }></BusinessEntityTreeViewSelect>
            </>
        );
    };

    return (
        <BaseForm
            title={
                <>
                    {id ? (
                        `${fields.firstName} ${fields.lastName}`
                    ) : (
                        <>
                            <TranslatableText
                                termKey={UserFormDefs.Create_New_User}
                                termSet={termSet}
                            />{' '}
                            (
                            {userData?.businessEntity?.name ||
                                user?.businessEntity?.name}
                            )
                        </>
                    )}
                </>
            }
            formMethods={[
                formMethods,
                employeeFormMethods,
                userTrialFormMethods,
            ]}
            isLoading={isLoading}
            isNew={!id}
            termSet={termSet}
            onCancel={handleClose}
            onCreate={showIsDatacorCompanyConfirmModal}
            onUpdate={handleUpdateUser}
            onDelete={showDeleteConfirmModal}
            displayCreateButton={canCreateUser}
            displayUpdateButton={canEditUser}
            displayDeleteButton={canDeleteUser}
            showOnRevision={true}
            fieldMappings={USER_FIELDS}
            entityUuid={userData?.uuid}
            readOnly={id && !canEditUser}
            customToolbarButtons={additionalToolbarButtons()}>
            <FormPartial>
                <Grid container spacing={2}>
                    {id && (
                        <Grid item xs={6}>
                            <SelectInput
                                fullWidth
                                label={
                                    <TranslatableText
                                        termKey={UserFormDefs.Status}
                                        termSet={termSet}
                                    />
                                }
                                id="active"
                                value={fields.active}
                                onChange={handleFieldChange}
                                options={[
                                    { label: 'Active', value: 'true' },
                                    { label: 'Inactive', value: 'false' },
                                ]}
                                formMethods={formMethods}
                                required={true}
                            />
                        </Grid>
                    )}
                    <Grid item xs={6}>
                        <TextInput
                            fullWidth
                            label={
                                <TranslatableText
                                    termKey={UserFormDefs.First_Name}
                                    termSet={termSet}
                                />
                            }
                            id="firstName"
                            value={fields.firstName}
                            formMethods={formMethods}
                            onChange={handleFieldChange}
                            required={true}
                            maxLength={90}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextInput
                            fullWidth
                            label={
                                <TranslatableText
                                    termKey={UserFormDefs.Middle_Name}
                                    termSet={termSet}
                                />
                            }
                            id="middleName"
                            value={fields.middleName}
                            formMethods={formMethods}
                            onChange={handleFieldChange}
                            maxLength={90}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextInput
                            fullWidth
                            label={
                                <TranslatableText
                                    termKey={UserFormDefs.Last_Name}
                                    termSet={termSet}
                                />
                            }
                            id="lastName"
                            value={fields.lastName}
                            formMethods={formMethods}
                            onChange={handleFieldChange}
                            required={true}
                            maxLength={90}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextInput
                            fullWidth
                            label={
                                <TranslatableText
                                    termKey={UserFormDefs.Email}
                                    termSet={termSet}
                                />
                            }
                            id="email"
                            value={fields.email}
                            required={true}
                            formMethods={formMethods}
                            onChange={handleFieldChange}
                            maxLength={300}
                            disabled={!!id}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextInput
                            fullWidth
                            label={
                                <TranslatableText
                                    termKey={UserFormDefs.Phone}
                                    termSet={termSet}
                                />
                            }
                            id="mobilePhone"
                            value={fields.mobilePhone}
                            formMethods={formMethods}
                            onChange={handleFieldChange}
                            maxLength={300}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <SelectInput
                            fullWidth
                            label={
                                <TranslatableText
                                    termKey={UserFormDefs.Messaging_Preference}
                                    termSet={termSet}
                                />
                            }
                            id="messagingPrefId"
                            value={fields.messagingPrefId}
                            onChange={handleFieldChange}
                            options={messagingPreferenceOptions}
                            formMethods={formMethods}
                            required={true}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextInput
                            fullWidth
                            label={
                                <TranslatableText
                                    termKey={UserFormDefs.External_Username}
                                    termSet={termSet}
                                />
                            }
                            id="externalUser"
                            value={fields.externalUser}
                            formMethods={formMethods}
                            onChange={handleFieldChange}
                            maxLength={300}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <FormGroup
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                            }}>
                            {user?.isDatacorAdmin &&
                                user?.subscriber?.isDatacor && (
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                key={Math.random()}
                                                id="isDatacorAdmin"
                                                checked={fields.isDatacorAdmin}
                                                onChange={handleFieldChange}
                                            />
                                        }
                                        label={
                                            <TranslatableText
                                                termKey={
                                                    UserFormDefs.Datacor_Admin
                                                }
                                                termSet={termSet}
                                            />
                                        }
                                    />
                                )}
                            {isFeatureFlagEnabled(
                                user?.enabledFeatureFlags,
                                'feature.apps.userPortal.createAppStreamUsers'
                            ) && (
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            key={Math.random()}
                                            id="isAppStreamUser"
                                            checked={fields.isAppStreamUser}
                                            onChange={handleFieldChange}
                                        />
                                    }
                                    label={
                                        <TranslatableText
                                            termKey={UserFormDefs.App_Stream}
                                            termSet={termSet}
                                        />
                                    }
                                />
                            )}
                        </FormGroup>
                    </Grid>
                </Grid>
            </FormPartial>
            {isFeatureFlagEnabled(
                user?.enabledFeatureFlags,
                'dashboard.nav.apps.apAutomation'
            ) &&
                apaOptions && (
                    <FormPartial
                        title={
                            (
                                <TranslatableText
                                    termKey={UserFormDefs.AP_Automation}
                                    termSet={termSet}
                                />
                            ) as unknown as string
                        }>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <ExpandableDropdown
                                    valueSelected={(value) =>
                                        setValues({
                                            apAutomationInvoiceTargetBusinessEntityId:
                                                value,
                                        })
                                    }
                                    defaultValue={
                                        fields.apAutomationInvoiceTargetBusinessEntityId
                                    }
                                    items={apaOptions}
                                    idKey="id"
                                    labelKey="name"
                                    label={
                                        (
                                            <TranslatableText
                                                termKey={
                                                    UserFormDefs.Invoice_Target
                                                }
                                                termSet={termSet}
                                            />
                                        ) as unknown as string
                                    }
                                    id="apAutomationInvoiceTargetBusinessEntityId"
                                    formMethods={formMethods}
                                />
                            </Grid>
                        </Grid>
                    </FormPartial>
                )}
            {/*TODO: add isDatacorUser check*/}
            {!fields.isDatacorAdmin ? (
                <FormPartial
                    title={
                        user?.subscriber?.isDatacor
                            ? ((
                                  <TranslatableText
                                      termKey={UserFormDefs.Subscribers_Text}
                                      termSet={termSet}
                                  />
                              ) as unknown as string)
                            : ((
                                  <TranslatableText
                                      termKey={UserFormDefs.Business_Entity}
                                      termSet={termSet}
                                  />
                              ) as unknown as string)
                    }>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            {handleRenderSelectDropdown()}
                        </Grid>
                    </Grid>
                </FormPartial>
            ) : null}
            <FormPartial
                title={
                    (
                        <TranslatableText
                            termKey={UserFormDefs.Groups_and_Roles}
                            termSet={termSet}
                        />
                    ) as unknown as string
                }>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <SelectInput
                            fullWidth
                            SelectProps={{
                                multiple: true,
                            }}
                            label={
                                <TranslatableText
                                    termKey={UserFormDefs.Groups}
                                    termSet={termSet}
                                />
                            }
                            id="groups"
                            value={fields.groups}
                            onChange={handleFieldChange}
                            options={groupsOptions}
                            formMethods={formMethods}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <SelectInput
                            fullWidth
                            disabled={
                                fields.isDatacorAdmin ||
                                user?.businessEntity?.subscriber?.isDatacor
                            }
                            SelectProps={{
                                multiple: true,
                            }}
                            label={
                                <TranslatableText
                                    termKey={UserFormDefs.Roles}
                                    termSet={termSet}
                                />
                            }
                            id="roles"
                            value={fields.roles}
                            onChange={handleFieldChange}
                            options={rolesOptions}
                            formMethods={formMethods}
                        />
                    </Grid>
                </Grid>
            </FormPartial>
            <FormPartial
                title={
                    (
                        <TranslatableText
                            termKey={UserFormDefs.User_Trial}
                            termSet={termSet}
                        />
                    ) as unknown as string
                }>
                <Grid container spacing={2} data-testid="trialUserDatesSection">
                    <Grid item xs={2}>
                        <FormControlLabel
                            label={
                                <TranslatableText
                                    termKey={
                                        UserFormDefs.Set_Up_User_With_Trial_Period
                                    }
                                    termSet={termSet}
                                />
                            }
                            control={
                                <Checkbox
                                    key={Math.random()}
                                    id="isTrialUser"
                                    checked={userTrialFields.isTrialUser}
                                    onChange={handleUserTrialFieldChange}
                                />
                            }
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <DateInput
                            id={'trialStartDate'}
                            label={
                                <TranslatableText
                                    termKey={UserFormDefs.Start_Date}
                                    termSet={termSet}
                                />
                            }
                            inputFormat="MM/DD/yyyy"
                            formMethods={userTrialFormMethods}
                            value={
                                userTrialFields.isTrialUser
                                    ? userTrialFields.trialStartDate
                                    : null
                            }
                            onChange={handleUserTrialFieldChange}
                            disabled={!userTrialFields.isTrialUser}
                            required={userTrialFields.isTrialUser}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <DateInput
                            id="trialEndDate"
                            label={
                                <TranslatableText
                                    termKey={UserFormDefs.End_Date}
                                    termSet={termSet}
                                />
                            }
                            inputFormat="MM/DD/yyyy"
                            formMethods={userTrialFormMethods}
                            value={
                                userTrialFields.isTrialUser
                                    ? userTrialFields.trialEndDate
                                    : null
                            }
                            onChange={handleUserTrialFieldChange}
                            disabled={!userTrialFields.isTrialUser}
                            required={userTrialFields.isTrialUser}
                        />
                    </Grid>
                </Grid>
            </FormPartial>
            {shouldShowEmployeeInfo && (
                <FormPartial title="Employee Information">
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <SelectInput
                                fullWidth
                                label={
                                    <TranslatableText
                                        termKey={
                                            UserFormDefs.Department_Code_ID
                                        }
                                        termSet={termSet}
                                    />
                                }
                                id="departmentCodeId"
                                value={employeeFields.departmentCodeId}
                                onChange={handleEmployeeFieldChange}
                                options={departmentCodeOptions}
                                formMethods={employeeFormMethods}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <SelectInput
                                fullWidth
                                label={
                                    <TranslatableText
                                        termKey={UserFormDefs.Supervisor_ID}
                                        termSet={termSet}
                                    />
                                }
                                id="supervisorId"
                                value={employeeFields.supervisorId}
                                onChange={handleEmployeeFieldChange}
                                options={usersDropdownOptions}
                                formMethods={employeeFormMethods}
                            />
                        </Grid>
                    </Grid>
                </FormPartial>
            )}
        </BaseForm>
    );
};

export default UserForm;
