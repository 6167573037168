import { CurrencyExchangeRate } from '../../../types/platform/i18n/Currency.type';
import useCurrencyExchangeRateFiltersModal from './hooks/useCurrencyExchangeRateFiltersModal';
import {
    Box,
    Dialog,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
} from '@mui/material';
import TranslatableText from '../../../components/i18n/TranslatableText';
import CloseIcon from '@mui/icons-material/Close';
import DateInput from '../../../components/form/formInputs/DateInput/DateInput';
import { LoadingButton } from '@mui/lab';
import { validateDateRange } from '../../../utils/comparisonUtils';
import React from 'react';
import SelectInput from '../../../components/form/formInputs/SelectInput/SelectInput';
import { CurrencyExchangeRateFilterDefs } from '../../../constants/i18n/translations/termDefinitions/accounting';

export interface ExchangeRatesProps {
    open: boolean;
    onExchangeRatesLoaded: (exchangeRates: CurrencyExchangeRate[]) => void;
    onClose: () => void;
}

const CurrencyExchangeRateFiltersModal = (props: ExchangeRatesProps) => {
    const {
        fields,
        handleFieldChange,
        currencyCodeOptions,
        loadingCurrencyCodes,
        handleGetExchangeRates,
        isLoadingExchangeRates,
        termSet,
    } = useCurrencyExchangeRateFiltersModal(props);

    return (
        <Dialog
            onClose={props.onClose}
            open={props.open}
            fullWidth
            maxWidth={'sm'}>
            <DialogTitle>
                <Box display="flex" alignItems="center">
                    <Box flexGrow={1}>
                        {
                            (
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={
                                        CurrencyExchangeRateFilterDefs.Title
                                    }
                                />
                            ) as unknown as string
                        }
                    </Box>
                    <Box>
                        <IconButton onClick={props.onClose}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </Box>
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <SelectInput
                            label={
                                (
                                    <TranslatableText
                                        termSet={termSet}
                                        termKey={
                                            CurrencyExchangeRateFilterDefs.SourceCurrencyCode
                                        }
                                    />
                                ) as unknown as string
                            }
                            id="sourceCurrencyCode"
                            fullWidth
                            value={fields.sourceCurrencyCode}
                            onChange={handleFieldChange}
                            disabled={loadingCurrencyCodes}
                            options={currencyCodeOptions}
                            SelectProps={{
                                showClearButton: true,
                            }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <SelectInput
                            label={
                                (
                                    <TranslatableText
                                        termSet={termSet}
                                        termKey={
                                            CurrencyExchangeRateFilterDefs.TargetCurrencyCode
                                        }
                                    />
                                ) as unknown as string
                            }
                            id="targetCurrencyCode"
                            fullWidth
                            value={fields.targetCurrencyCode}
                            onChange={handleFieldChange}
                            disabled={loadingCurrencyCodes}
                            options={currencyCodeOptions}
                            SelectProps={{
                                showClearButton: true,
                            }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <DateInput
                            label={
                                (
                                    <TranslatableText
                                        termSet={termSet}
                                        termKey={
                                            CurrencyExchangeRateFilterDefs.EffectiveDateBegin
                                        }
                                    />
                                ) as unknown as string
                            }
                            id="startDate"
                            value={fields.startDate}
                            onChange={handleFieldChange}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <DateInput
                            label={
                                (
                                    <TranslatableText
                                        termSet={termSet}
                                        termKey={
                                            CurrencyExchangeRateFilterDefs.EffectiveDateEnd
                                        }
                                    />
                                ) as unknown as string
                            }
                            id="endDate"
                            value={fields.endDate}
                            onChange={handleFieldChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <LoadingButton
                            fullWidth
                            variant="contained"
                            type="submit"
                            disabled={
                                !validateDateRange(
                                    fields.startDate,
                                    fields.endDate
                                )
                            }
                            loading={isLoadingExchangeRates}
                            onClick={handleGetExchangeRates}>
                            <TranslatableText
                                termSet={termSet}
                                termKey={
                                    CurrencyExchangeRateFilterDefs.TextButton
                                }
                            />
                        </LoadingButton>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
};

export default CurrencyExchangeRateFiltersModal;
