import AccountFunctionCodeGrid from '../containers/accounting/AccountFunctionCodeGrid';
import AccountFunctionCodeForm from '../containers/accounting/AccountFunctionCodeForm';
import CurrencyGrid from '../containers/accounting/currencies/CurrencyGrid';
import CurrencyForm from '../containers/accounting/currencies/CurrencyForm';
import CurrencyRateTypeGrid from '../containers/accounting/currencyRateTypes/CurrencyRateTypeGrid';
import CurrencyRateTypeForm from '../containers/accounting/currencyRateTypes/CurrencyRateTypeForm';
import { RouteProps } from './routesConfig';
import { PERMISSIONS } from '../constants/permissions/Permissions.constants';
import CurrencyExchangeRateGrid from '../containers/accounting/currencyExchangeRates/CurrencyExchangeRateGrid';
import CurrencyExchangeRateForm from '../containers/accounting/currencyExchangeRates/CurrencyExchangeRateForm';
import FiscalYearStatusGrid from '../containers/accounting/fiscalYearStatuses/FiscalYearStatusGrid';
import FiscalYearGrid from '../containers/accounting/fiscalYears/FiscalYearGrid';
import FiscalYearStatusForm from '../containers/accounting/fiscalYearStatuses/FiscalYearStatusForm';
import FiscalYearForm from '../containers/accounting/fiscalYears/FiscalYearForm';
import PeriodStatusGrid from '../containers/accounting/periodStatuses/PeriodStatusGrid';
import PeriodStatusForm from '../containers/accounting/periodStatuses/PeriodStatusForm';
import PeriodQuarterForm from '../containers/accounting/periodQuarters/form/PeriodQuarterForm';
import PeriodQuartersGrid from '../containers/accounting/periodQuarters/grid/PeriodQuartersGrid';
import Accounting from '../containers/accounting/Accounting';

const AccountingRoutes: RouteProps[] = [
    {
        path: '/accounting/',
        component: Accounting,
        featureFlag: 'dashboard.nav.apps.accounting',
    },
    {
        path: '/accounting/functionCode/:group',
        component: AccountFunctionCodeGrid,
        permissionKey: PERMISSIONS.ACCOUNTING.FUNCTION_CODES.VIEW,
        featureFlag: 'dashboard.nav.apps.accounting',
    },
    {
        path: '/accounting/functionCode/:group/:id',
        component: AccountFunctionCodeForm,
        permissionKey: PERMISSIONS.ACCOUNTING.FUNCTION_CODES.VIEW,
        featureFlag: 'dashboard.nav.apps.accounting',
    },
    {
        path: '/accounting/currency',
        component: CurrencyGrid,
        permissionKey: PERMISSIONS.ACCOUNTING.CURRENCIES.VIEW,
        featureFlag: 'dashboard.nav.apps.accounting',
    },
    {
        path: '/accounting/currency/:id',
        component: CurrencyForm,
        permissionKey: PERMISSIONS.ACCOUNTING.CURRENCIES.VIEW,
        featureFlag: 'dashboard.nav.apps.accounting',
    },
    {
        path: '/accounting/currencyRateType',
        component: CurrencyRateTypeGrid,
        permissionKey: PERMISSIONS.ACCOUNTING.CURRENCY_RATE_TYPES.VIEW,
        featureFlag: 'dashboard.nav.apps.accounting',
    },
    {
        path: '/accounting/currencyRateType/:id',
        component: CurrencyRateTypeForm,
        permissionKey: PERMISSIONS.ACCOUNTING.CURRENCY_RATE_TYPES.VIEW,
        featureFlag: 'dashboard.nav.apps.accounting',
    },
    {
        path: '/accounting/currencyExchangeRate',
        component: CurrencyExchangeRateGrid,
        permissionKey: PERMISSIONS.ACCOUNTING.CURRENCY_EXCHANGE_RATES.VIEW,
        featureFlag: 'dashboard.nav.apps.accounting',
    },
    {
        path: '/accounting/currencyExchangeRate/:id',
        component: CurrencyExchangeRateForm,
        permissionKey: PERMISSIONS.ACCOUNTING.CURRENCY_EXCHANGE_RATES.VIEW,
        featureFlag: 'dashboard.nav.apps.accounting',
    },
    {
        path: '/accounting/setup/fiscalYearStatus',
        component: FiscalYearStatusGrid,
        permissionKey: PERMISSIONS.ACCOUNTING.FISCAL_YEAR_STATUSES.VIEW,
        featureFlag: 'dashboard.nav.apps.accounting',
    },
    {
        path: '/accounting/setup/fiscalYearStatus/:id',
        component: FiscalYearStatusForm,
        permissionKey: PERMISSIONS.ACCOUNTING.FISCAL_YEAR_STATUSES.VIEW,
        featureFlag: 'dashboard.nav.apps.accounting',
    },
    {
        path: '/accounting/setup/fiscalYears',
        component: FiscalYearGrid,
        permissionKey: PERMISSIONS.ACCOUNTING.FISCAL_YEARS.VIEW,
        featureFlag: 'dashboard.nav.apps.accounting',
    },
    {
        path: '/accounting/setup/fiscalYears/:id',
        component: FiscalYearForm,
        permissionKey: PERMISSIONS.ACCOUNTING.FISCAL_YEARS.VIEW,
        featureFlag: 'dashboard.nav.apps.accounting',
    },
    {
        path: '/accounting/setup/periodStatus',
        component: PeriodStatusGrid,
        permissionKey: PERMISSIONS.ACCOUNTING.PERIOD_STATUSES.VIEW,
        featureFlag: 'dashboard.nav.apps.accounting',
    },
    {
        path: '/accounting/setup/periodStatus/:id',
        component: PeriodStatusForm,
        permissionKey: PERMISSIONS.ACCOUNTING.PERIOD_STATUSES.VIEW,
        featureFlag: 'dashboard.nav.apps.accounting',
    },
    {
        path: '/accounting/setup/periodQuarters',
        component: PeriodQuartersGrid,
        permissionKey: PERMISSIONS.ACCOUNTING.PERIOD_QUARTER.VIEW,
        featureFlag: 'dashboard.nav.apps.accounting',
    },
    {
        path: '/accounting/setup/periodQuarters/:id',
        component: PeriodQuarterForm,
        permissionKey: PERMISSIONS.ACCOUNTING.PERIOD_QUARTER.VIEW,
        featureFlag: 'dashboard.nav.apps.accounting',
    },
];

export default AccountingRoutes;
