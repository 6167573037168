import React, { useContext, useEffect, useState } from 'react';
import { RootStateOrAny, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { skipToken } from '@reduxjs/toolkit/query';
import {
    GhsPrecautionaryPhraseLang,
    GhsPrecautionaryPhrase,
} from '../../../../../types/formulation';
import { Language } from '../../../../../services/organizations/organizations.service';
import { SelectionOption } from '../../../../../types/Shared.types';
import { PermissionsUtil } from '../../../../../utils/permissions/permissionsUtil';
import { PERMISSIONS } from '../../../../../constants/permissions/Permissions.constants';
import { useGetPrecautionaryPhrasesByPhraseIdQuery } from '../../../../../services/formulation/precautionaryPhrases/precautionaryPhrases.service';
import SettingsContext from '../../../../../contexts/settings.context';
import { useGetTermSetQuery } from '../../../../../services/i18n/i18n.service';
import { FORMULATION_DEFS } from '../../../../../constants/i18n/translations/termSetDefinitions/formulation';

export interface PrecautionaryFormContainerProps {
    phraseId: string;
    defaultLangId: bigint;
    languageList: Array<Language>;
    phraseIdList: Array<string>;
    handleSetIsFormDirty(formDirty: boolean): void;
    handlePromptFormDirty(onConfirm: () => void, onCancel?: () => void): void;
}

const useGhsPrecautionaryPhraseFormContainer = ({
    phraseId,
    defaultLangId,
    languageList,
    handlePromptFormDirty,
}: PrecautionaryFormContainerProps) => {
    const navigate = useNavigate();
    const user = useSelector((state: RootStateOrAny) => state.user);

    const canCreateGhsPrecautionaryPhrase = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.FORMULATION.GHS_PRECAUTIONARY_PHRASE.CREATE
    );

    const [phraseLangList, setPhraseLangList] = useState(
        null as Array<GhsPrecautionaryPhraseLang>
    );

    const [langTabValue, setLangTabValue] = useState(0);
    const [languageOptionList, setLanguageOptionList] = useState(
        null as SelectionOption[]
    );
    const [showModal, setShowModal] = useState(false);
    const [disableLangButton, setDisableLangButton] = useState(false);

    const {
        data: precautionaryPhraseList,
        isFetching: isLoadingPrecautionaryPhraseList,
    } = useGetPrecautionaryPhrasesByPhraseIdQuery(
        phraseId
            ? {
                  phraseId: phraseId,
              }
            : skipToken
    );

    const { settings } = useContext(SettingsContext);
    const { data: termSet } = useGetTermSetQuery(
        settings?.userSettings
            ? {
                  languageId: settings?.userSettings?.languageId,
                  code: FORMULATION_DEFS.GHS_PRECAUTIONARY_PHRASES_FORM,
              }
            : skipToken
    );

    useEffect(() => {
        if (
            phraseId &&
            !isLoadingPrecautionaryPhraseList &&
            precautionaryPhraseList
        ) {
            if (precautionaryPhraseList.length > 0) {
                setPhraseLangListData(precautionaryPhraseList);
                buildLanguageSelectionOption();
            }
        } else if (!phraseId && defaultLangId) {
            const newPhrase: GhsPrecautionaryPhrase = {
                id: undefined,
                phraseId: null,
                langId: defaultLangId,
                businessEntityId: user?.businessEntity?.id,
                statementTypeId: null,
            };
            setPhraseLangListData([newPhrase]);
        }
    }, [precautionaryPhraseList, phraseId, languageList, defaultLangId]);

    useEffect(() => {
        setLangTabValue(0);
    }, [phraseId]);

    const setPhraseLangListData = (
        precautionaryPhraseList: Array<GhsPrecautionaryPhrase>
    ) => {
        const phraseLangList: Array<GhsPrecautionaryPhraseLang> = [];
        precautionaryPhraseList.map((precautionaryPhrase) => {
            const currentPhraseLang: GhsPrecautionaryPhraseLang =
                createPhrecautionaryPhraseLang(precautionaryPhrase);
            if (currentPhraseLang.langId === defaultLangId) {
                phraseLangList.unshift(currentPhraseLang);
            } else {
                phraseLangList.push(currentPhraseLang);
            }
        });
        setPhraseLangList(phraseLangList);
    };

    const resetTabData = () => {
        const newPhrase = phraseLangList?.find((phrase) => !phrase.id);
        if (precautionaryPhraseList && newPhrase) {
            setPhraseLangListData(precautionaryPhraseList);
        }
    };

    const createPhrecautionaryPhraseLang = (
        precautionaryPhrase: GhsPrecautionaryPhrase
    ): GhsPrecautionaryPhraseLang => {
        const precautionaryPhraseLang: GhsPrecautionaryPhraseLang = {
            id: precautionaryPhrase.id,
            phraseId: precautionaryPhrase.phraseId,
            langId: precautionaryPhrase.langId,
            statmentType: precautionaryPhrase.statementTypeId,
            userInputNeeded: precautionaryPhrase.userInputNeeded,
            langText: languageList?.find(
                (lang) => lang.id === precautionaryPhrase.langId
            )?.name,
        };
        return precautionaryPhraseLang;
    };

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        handlePromptFormDirty(() => {
            resetTabData();
            setLangTabValue(newValue);
        });
    };

    const buildLanguageSelectionOption = () => {
        if (languageList) {
            const langOptionList: SelectionOption[] = [];
            languageList.forEach((currentLang) => {
                const phrase = precautionaryPhraseList.find(
                    (currentPhrase) => currentPhrase.langId === currentLang.id
                );
                if (!phrase) {
                    langOptionList.push({
                        value: currentLang.id,
                        label: currentLang.name,
                    });
                }
            });
            setLanguageOptionList(langOptionList);
        }
    };

    const addNewLanguage = (selectedLangId: bigint) => {
        if (selectedLangId) {
            const defaultPhrase = precautionaryPhraseList.find(
                (current) => current.langId === defaultLangId
            );
            const newPhrase: GhsPrecautionaryPhrase = {
                id: undefined,
                phraseId: defaultPhrase.phraseId,
                langId: selectedLangId,
                businessEntityId: user?.businessEntity?.id,
                statementTypeId: defaultPhrase.statementTypeId,
                userInputNeeded: defaultPhrase.userInputNeeded,
            };
            const phraseList = Array.from(precautionaryPhraseList);
            phraseList.push(newPhrase);
            setPhraseLangListData(phraseList);
            setLangTabValue(phraseList.length - 1);
        }
        setShowModal(false);
    };

    const handleOpenModal = () => {
        setShowModal(true);
    };

    const handleSetPreviousTab = () => {
        if (langTabValue > 0) {
            setLangTabValue(langTabValue - 1);
        }
    };

    const cancelNewLanguage = (closeForm: boolean) => {
        handlePromptFormDirty(() => {
            resetTabData();
            if (closeForm) {
                navigate('/formulation/ghsPrecautionaryPhrases');
            } else {
                setLangTabValue(0);
            }
        });
    };

    const handleSetDisableLangButton = (disableButton: boolean) => {
        if (disableButton !== disableLangButton) {
            setDisableLangButton(disableButton);
        }
    };

    return {
        isLoadingPrecautionaryPhraseList,
        phraseLangList,
        langTabValue,
        languageOptionList,
        showModal,
        disableLangButton,
        handleTabChange,
        addNewLanguage,
        handleOpenModal,
        handleSetPreviousTab,
        cancelNewLanguage,
        handleSetDisableLangButton,
        canCreateGhsPrecautionaryPhrase,
        termSet,
    };
};

export default useGhsPrecautionaryPhraseFormContainer;
