import { Grid } from '@mui/material';
import React, { useContext } from 'react';
import FormPartial from '../../../components/form/formContainer/FormPartial';
import SelectInput from '../../../components/form/formInputs/SelectInput/SelectInput';
import SettingsContext from '../../../contexts/settings.context';
import TextInput from '../../../components/form/formInputs/TextInput/TextInput';
import { SettingSectionProps } from './AccountingSettings';
import useSettings from '../hooks/useSettings';
import { FORMULATION_DEFS } from '../../../constants/i18n/translations/termSetDefinitions/formulation';
import TranslatableText from '../../../components/i18n/TranslatableText';
import { FormulationSettingDefs } from '../../../constants/i18n/translations/termDefinitions/formulation';

const FormulationSettings = ({ onChange }: SettingSectionProps) => {
    const { settings } = useContext(SettingsContext);

    const { termSet } = useSettings({
        termSetCode: FORMULATION_DEFS.FORMULATION_SETTINGS,
    });
    return (
        <FormPartial
            title={
                (
                    <TranslatableText
                        termKey={FormulationSettingDefs.TITLE}
                        termSet={termSet}
                    />
                ) as unknown as string
            }>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <TextInput
                        id={'h20Density'}
                        onChange={(event: any) =>
                            onChange(event, 'formulationSettings')
                        }
                        fullWidth
                        value={settings.formulationSettings?.h20Density}
                        label={
                            <TranslatableText
                                termKey={FormulationSettingDefs.H20_DENSITY}
                                termSet={termSet}
                            />
                        }
                    />
                </Grid>
                <Grid item xs={6}>
                    <SelectInput
                        id={'densityUnit'}
                        onChange={(event: any) =>
                            onChange(event, 'formulationSettings')
                        }
                        fullWidth
                        value={settings.formulationSettings?.densityUnit}
                        label={
                            <TranslatableText
                                termKey={FormulationSettingDefs.DENSITY_UNIT}
                                termSet={termSet}
                            />
                        }
                        options={[
                            { label: 'lbs/gal', value: 'lbs/gal' },
                            { label: 'kg/m3', value: 'kg/m3' },
                            { label: 'g/ml', value: 'g/ml' },
                        ]}
                    />
                </Grid>
                <Grid item xs={6}>
                    <SelectInput
                        id={'itemWeight'}
                        onChange={(event: any) =>
                            onChange(event, 'formulationSettings')
                        }
                        fullWidth
                        value={settings.formulationSettings?.itemWeight}
                        label={
                            <TranslatableText
                                termKey={
                                    FormulationSettingDefs.ITEM_WEIGHT_TYPE
                                }
                                termSet={termSet}
                            />
                        }
                        options={[
                            { label: 'Net', value: 'net' },
                            { label: 'Gross', value: 'gross' },
                        ]}
                    />
                </Grid>
                <Grid item xs={6}>
                    <SelectInput
                        id={'equationControls'}
                        onChange={(event: any) =>
                            onChange(event, 'formulationSettings')
                        }
                        fullWidth
                        value={settings.formulationSettings?.equationControls}
                        label={
                            <TranslatableText
                                termKey={
                                    FormulationSettingDefs.EQUATION_CONTROLS
                                }
                                termSet={termSet}
                            />
                        }
                        options={[
                            { label: 'Immediate', value: 'immediate' },
                            { label: 'On Demand', value: 'ondemand' },
                        ]}
                    />
                </Grid>
                <Grid item xs={6}>
                    <SelectInput
                        id={'revisionDigits'}
                        onChange={(event: any) =>
                            onChange(event, 'formulationSettings')
                        }
                        fullWidth
                        value={settings.formulationSettings?.revisionDigits}
                        label={
                            <TranslatableText
                                termKey={FormulationSettingDefs.REVISION_DIGITS}
                                termSet={termSet}
                            />
                        }
                        options={[
                            { label: '1', value: '1' },
                            { label: '2', value: '2' },
                            { label: '3', value: '3' },
                            { label: '4', value: '4' },
                            { label: '5', value: '5' },
                        ]}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextInput
                        id={'revisionPrefix'}
                        onChange={(event: any) =>
                            onChange(event, 'formulationSettings')
                        }
                        fullWidth
                        value={settings.formulationSettings?.revisionPrefix}
                        label={
                            <TranslatableText
                                termKey={FormulationSettingDefs.REVISION_PREFIX}
                                termSet={termSet}
                            />
                        }
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextInput
                        id={'componentStepNo'}
                        onChange={(event: any) =>
                            onChange(event, 'formulationSettings')
                        }
                        fullWidth
                        value={settings.formulationSettings?.componentStepNo}
                        label={
                            <TranslatableText
                                termKey={
                                    FormulationSettingDefs.COMPONENT_STEP_NO
                                }
                                termSet={termSet}
                            />
                        }
                    />
                </Grid>
            </Grid>
        </FormPartial>
    );
};

export default FormulationSettings;
