import React, { useEffect } from 'react';
import {
    DesktopDatePicker,
    DesktopDatePickerProps,
} from '@mui/x-date-pickers/DesktopDatePicker';
import { TextFieldProps } from '@mui/material';
import InputBase from '../InputBase/InputBase';
import { FormMethods } from '../../../../libs/hooksLib';
import { dateFormatter } from '../../../../utils/formattingUtils';
import useDateInput from './hook/useDateInput';
import _ from 'lodash';
import { GreaterThanDateValidator } from '../../../../validators/greaterThanDate.validator';

export type DateInputProps = Omit<
    DesktopDatePickerProps<Date, Date>,
    'onChange' | 'renderInput'
> & {
    id: string;
    onChange: (event: any) => void;
    formMethods?: FormMethods;
    required?: boolean;
    minDate?: Date;
    maxDate?: Date;
};

const DateInput = (props: DateInputProps) => {
    const { handleOnChange } = useDateInput(props);

    useEffect(() => {
        if (props.minDate) {
            props.formMethods.replaceValidator(
                props.id,
                _.isObject(props.label) ? (
                    <>{props.label}</>
                ) : (
                    props.label.toString()
                ),
                GreaterThanDateValidator(props.minDate)
            );
        }
    }, [props.minDate]);

    return (
        <DesktopDatePicker
            {...props}
            value={props.value ? dateFormatter(props.value) : null}
            onChange={handleOnChange}
            renderInput={(params: TextFieldProps) => (
                <InputBase
                    fullWidth
                    {...params}
                    id={props.id}
                    formMethods={props.formMethods}
                    required={props.required}
                />
            )}
        />
    );
};

export default DateInput;
