import { Checkbox, FormControlLabel } from '@mui/material';
import React from 'react';
import { HandleFieldChangedEvent } from '../../../../libs/hooksLib';

export interface CheckBoxInputProps {
    id: string;
    label: string;
    checked: boolean;
    onChange: (event: HandleFieldChangedEvent) => void;
    disabled?: boolean;
}
const CheckBoxInput = (props: CheckBoxInputProps) => {
    return (
        <FormControlLabel
            label={props.label}
            control={
                <Checkbox
                    id={props.id}
                    checked={props.checked || false}
                    onChange={props.onChange}
                    disabled={props.disabled || false}
                />
            }
        />
    );
};

export default CheckBoxInput;
