import {
    BaseCreateEntityArgs,
    BaseUpdateEntityArgs,
} from '../../serviceInterfaces';
import { FormulaProperty } from '../../../types/formulation';
import { datacorAppApi } from '../../datacorAppApi';

const serviceUrl: string = 'formulaProperty';

export const formulaPropertyApi = datacorAppApi.injectEndpoints({
    endpoints: (build) => ({
        createFormulaProperty: build.mutation<
            any,
            BaseCreateEntityArgs<FormulaProperty>
        >({
            query: (args) => ({
                url: serviceUrl,
                method: 'POST',
                body: args.postBody,
            }),
            invalidatesTags: ['FormulaProperty'],
        }),
        updateFormulaProperty: build.mutation<
            any,
            BaseUpdateEntityArgs<FormulaProperty>
        >({
            query: (args) => ({
                url: `${serviceUrl}/${args.id}`,
                method: 'PUT',
                body: args.postBody,
            }),
            invalidatesTags: ['FormulaProperty', 'ActiveFormulaProperty'],
        }),
        deleteFormulaProperty: build.mutation<FormulaProperty, string>({
            query: (id) => ({
                url: `${serviceUrl}/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['FormulaProperty'],
        }),
        getFormulaProperty: build.query<any, string>({
            query: (id) => {
                return {
                    url: `${serviceUrl}/${id}`,
                };
            },
            providesTags: ['ActiveFormulaProperty'],
        }),
        listFormulaProperty: build.query<any, void>({
            query: () => {
                return {
                    url: serviceUrl,
                };
            },
            providesTags: ['FormulaProperty'],
        }),
    }),
});

export const {
    useCreateFormulaPropertyMutation,
    useUpdateFormulaPropertyMutation,
    useDeleteFormulaPropertyMutation,
    useGetFormulaPropertyQuery,
    useListFormulaPropertyQuery,
} = formulaPropertyApi;
