import { PermissionsUtil } from '../../../utils/permissions/permissionsUtil';
import { PERMISSIONS } from '../../../constants/permissions/Permissions.constants';
import { useContext, useEffect, useState } from 'react';
import SettingsContext from '../../../contexts/settings.context';
import { useGetTermSetQuery } from '../../../services/i18n/i18n.service';
import { SUPPLY_CHAIN_DEFS } from '../../../constants/i18n/translations/termSetDefinitions/supply-chain';
import { skipToken } from '@reduxjs/toolkit/query';
import { RoutingValues } from '../../../types/Shared.types';
import {
    useApplySupplierTemplateMutation,
    useCreateSupplierMutation,
    useDeleteSupplierMutation,
    useDropdownSupplierStatusQuery,
    useDropdownSupplierTypeQuery,
    useGetSupplierQuery,
    useUpdateSupplierAttributeMutation,
    useUpdateSupplierMutation,
} from '../../../services/supplier/supplier.service';
import { Supplier } from '../../../types/Supplier.types';
import useBaseForm from '../../form/hooks/useBaseForm';
import { useGetActiveAttributeTemplatesQuery } from '../../../services/attributes/attributes.service';
import { isNilOrEmpty } from '../../../utils/objectUtils';
import { useDropdownGL_AccountQuery } from '../../../services/accounting/accounting.service';

const useSupplierForm = (id: string) => {
    const canCreate = PermissionsUtil.hasPermission(
        PERMISSIONS.SUPPLIERS.SUPPLIER.CREATE
    );

    const canUpdate = PermissionsUtil.hasPermission(
        PERMISSIONS.SUPPLIERS.SUPPLIER.EDIT
    );

    const canDelete = PermissionsUtil.hasPermission(
        PERMISSIONS.SUPPLIERS.SUPPLIER.DELETE
    );

    const { settings } = useContext(SettingsContext);
    const { data: termSet } = useGetTermSetQuery(
        settings?.userSettings
            ? {
                  languageId: settings?.userSettings?.languageId,
                  code: SUPPLY_CHAIN_DEFS.SUPPLIER_FORM,
              }
            : skipToken
    );

    const { data, isLoading } = useGetSupplierQuery(
        id === RoutingValues.newId ? skipToken : id
    );

    const [isAttributeValid, setIsAttributeValid] = useState(null);
    const [attrValues, setAttrValues] = useState({});
    const [attrFieldsDisabled, setAttrFieldsDisabled] = useState(true);
    const [formDefs, setFormDefs] = useState(null);
    const [updatedRecord, setUpdatedRecord] = useState(null);

    const { data: activeTemplates, isLoading: isLoadingTemplates } =
        useGetActiveAttributeTemplatesQuery('48');

    const [createMutation] = useCreateSupplierMutation();
    const [updateMutation] = useUpdateSupplierMutation();
    const [deleteMutation] = useDeleteSupplierMutation();
    const [applyTemplate] = useApplySupplierTemplateMutation();
    const [updateAttribute] = useUpdateSupplierAttributeMutation();

    const { data: statusOptions, isLoading: isLoadingStatuses } =
        useDropdownSupplierStatusQuery();

    const { data: typeOptions, isLoading: isLoadingTypes } =
        useDropdownSupplierTypeQuery();

    const { data: accountOptions, isLoading: isLoadingAccounts } =
        useDropdownGL_AccountQuery();

    const blankSupplier: Supplier = {
        id: null,
        businessEntityId: null,
        supplierStatus: null,
        supplierStatusId: null,
        supplierType: null,
        supplierTypeId: null,
        code: null,
        name: null,
        taxNumber: null,
        website: null,
        currencyCode: null,
        expenseAccountId: null,
        createdAt: null,
        createdBy: null,
        updatedAt: null,
        updatedBy: null,
    };

    const createAttributesVerificationCopy = () => {
        let index = 1;
        if (data?.supplierAttributes) {
            return data?.supplierAttributes?.map((attribute: any) => {
                const attributeValue = Number(attribute.data);
                return {
                    [index++]: !Number.isNaN(attributeValue)
                        ? attributeValue
                        : attribute.data,
                };
            });
        }
        return null;
    };

    useEffect(() => {
        if (!isNilOrEmpty(data)) {
            // if the item record has an item profile
            // and we are not creating a new item
            // then a template has already been applied
            // we can enable the fields and disable the template selection
            if (!isNilOrEmpty(data?.supplierProfiles)) {
                setAttrFieldsDisabled(false);
            }
            setUpdatedRecord({
                ...data,
                attrTemplateId: data?.supplierProfiles
                    ? data?.supplierProfiles[0]?.attrTemplateId
                    : null,
                verifyAttributes: createAttributesVerificationCopy(),
            });
            //if we already have a template applied
            if (!isNilOrEmpty(data?.supplierProfiles)) {
                const formDefs =
                    data?.supplierProfiles[0]?.attrTemplate?.attrDefinitions;
                setFormDefs(formDefs);
            }
        }
    }, [data]);

    const {
        fields,
        handleFieldChange,
        formMethods,
        onCreate,
        onUpdate,
        onDelete,
        closeForm,
        auditableFields,
    } = useBaseForm({
        closePath: '/suppliers/supplier',
        blankEntity: blankSupplier,
        activeEntity: updatedRecord,
        getDescription: () => {
            return `Supplier ${fields.code}`;
        },
        createEntity: () => {
            return createMutation({ postBody: fields });
        },
        afterCreate: async (response: any) => {
            if (fields?.supplierAttributes) {
                for (const attr of fields?.supplierAttributes) {
                    //@ts-ignore
                    attr.data = attrValues[attr.attrDefinitionId];
                }
            }

            if (fields?.attrTemplateId && !attrFieldsDisabled) {
                const args = {
                    id: fields?.attrTemplateId,
                    postBody: {
                        supplierProfile: {
                            supplierId: response?.id,
                            attrTemplateId: fields?.attrTemplateId,
                        },
                        supplierAttributes:
                            fields?.supplierAttributes || attrValues,
                    },
                };

                await applyTemplate(args);
            }
        },
        updateEntity: () => {
            const args = {
                id: id,
                postBody: {
                    businessEntityId: data.businessEntityId,
                    supplierProfileId: data?.supplierProfiles[0]?.id,
                    supplierAttributes: attrValues,
                },
            };
            updateAttribute(args);
            return updateMutation({
                id: id as unknown as number,
                postBody: fields,
            });
        },
        deleteEntity: () => {
            return deleteMutation(id);
        },
    });

    const handleApplyTemplate = async () => {
        setAttrFieldsDisabled(false);
        //if we're in an update
        if (!isNilOrEmpty(id) && id !== RoutingValues.newId) {
            //we have a template selected
            if (fields?.attrTemplateId) {
                const args = {
                    id: fields?.attrTemplateId,
                    postBody: {
                        supplierProfile: {
                            supplierId: id,
                            attrTemplateId: fields?.attrTemplateId,
                        },
                        supplierAttributes: attrValues,
                    },
                };

                const { attrDefinitions } = await applyTemplate(args);
                setFormDefs(attrDefinitions);
            }
        }
    };

    return {
        fields,
        handleFieldChange,
        formMethods,
        onCreate,
        onUpdate,
        onDelete,
        closeForm,
        isLoading,
        termSet,
        canCreate,
        canUpdate,
        canDelete,
        data,
        auditableFields,
        statusOptions,
        isLoadingStatuses,
        typeOptions,
        isLoadingTypes,
        accountOptions,
        isLoadingAccounts,
        activeTemplates,
        isLoadingTemplates,
        attrFieldsDisabled,
        handleApplyTemplate,
        formDefs,
        attrValues,
        setAttrValues,
        setIsAttributeValid,
    };
};

export default useSupplierForm;
