import React, { useEffect } from 'react';
import { isJsonString, isNilOrEmpty } from '../../../utils/objectUtils';
import RenderDefaultValue from '../../attributes/RenderDefaultValue';

const VALUE_TYPE_CODES = {
    text: '1',
    num: '2',
    bool: '3',
    list: '4',
    range: '5',
    tolerance: '6',
};
const RenderItemAttribute = (props: any) => {
    const def = props.definition;

    useEffect(() => {
        if (!isNilOrEmpty(props.fields?.itemAttributes)) {
            const itemAttrObj = {};
            for (const attr of props.fields?.itemAttributes) {
                //@ts-ignore
                itemAttrObj[attr.attrDefinitionId] = isJsonString(attr.data)
                    ? JSON.parse(attr.data)
                    : attr.data;
            }
            props.setItemAttrValues(itemAttrObj);
        }
    }, [def]);

    return (
        <RenderDefaultValue
            handleFieldChange={(event: any) =>
                props.setItemAttrValues((prev: any) => ({
                    ...prev,
                    [def.id]:
                        event.target.type === 'checkbox'
                            ? event.target.checked
                            : event.target.value,
                }))
            }
            fieldLabel={def.name}
            formMethods={props.formMethods}
            value={props.itemAttrValues[def.id]}
            //@ts-ignore
            attrValueTypeId={VALUE_TYPE_CODES[def.attrValueTypeCode]}
            optionsForDropdown={
                isJsonString(def.options)
                    ? JSON.parse(def.options).map((item: any) => {
                          return { label: item.option, value: item.id };
                      })
                    : []
            }
            isNew={false}
            disabled={props.disabled}
            setIsAttributeValid={props.setIsAttributeValid}
        />
    );
};

export default RenderItemAttribute;
