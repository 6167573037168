import { useNavigate } from 'react-router-dom';
import { RootStateOrAny, useSelector } from 'react-redux';
import { PermissionsUtil } from '../../../../utils/permissions/permissionsUtil';
import { PERMISSIONS } from '../../../../constants/permissions/Permissions.constants';
import { useListFormulaPropertyQuery } from '../../../../services/formulation/formulaProperty/formulaProperty.service';
import { useContext } from 'react';
import SettingsContext from '../../../../contexts/settings.context';
import { useGetTermSetQuery } from '../../../../services/i18n/i18n.service';
import { FORMULATION_DEFS } from '../../../../constants/i18n/translations/termSetDefinitions/formulation';
import { skipToken } from '@reduxjs/toolkit/query';

const useFormulaPropertyGrid = () => {
    const navigate = useNavigate();
    const user = useSelector((state: RootStateOrAny) => state.user);

    const { data: formulaPropertyList, isLoading: isLoadingFormulaProperties } =
        useListFormulaPropertyQuery();

    const canCreateFormulaProperty = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.FORMULATION.FORMULA_PROPERTIES.CREATE
    );
    const canViewFormulaProperty = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.FORMULATION.FORMULA_PROPERTIES.VIEW
    );

    const { settings } = useContext(SettingsContext);
    const { data: termSet } = useGetTermSetQuery(
        settings?.userSettings
            ? {
                  languageId: settings?.userSettings?.languageId,
                  code: FORMULATION_DEFS.FORMULA_PROPERTIES_GRID,
              }
            : skipToken
    );

    const redirectToForm = (row: any) => {
        if (row.id) {
            navigate(`/formulation/formulaProperties/${row.id}`);
        } else {
            navigate('/formulation/formulaProperties/new');
        }
    };

    return {
        formulaPropertyList,
        redirectToForm,
        isLoadingFormulaProperties,
        canCreateFormulaProperty,
        canViewFormulaProperty,
        termSet,
    };
};

export default useFormulaPropertyGrid;
