import DeleteColumn from '../cellRenderers/deleteRow.render';

export const editableGridDeleteRow = (params: any) => {
    return {
        maxWidth: 50,
        filter: false,
        resizable: false,
        sortable: false,
        field: 'deleteColumn',
        headerName: '',
        cellRendererFramework: {
            cellRender: () => DeleteColumn(params),
        },
        cellRenderer: 'deleteColumn',
    };
};
