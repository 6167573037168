import React from 'react';
import {
    ISetFilterParams,
    KeyCreatorParams,
    ValueFormatterParams,
} from 'ag-grid-community';

export const editableGridCellSelect = (
    options: { label: string; value: any }[]
) => {
    const cellKeyCreator = (params: KeyCreatorParams) => {
        return lookupValue(options, params.value);
    };

    return {
        minWidth: 200,
        filter: 'agSetColumnFilter',
        cellEditor: 'agSelectCellEditor',
        editable: true,
        keyCreator: cellKeyCreator,
        filterParams: {
            valueFormatter: (params: ValueFormatterParams) => {
                return lookupValue(options, params.value);
            },
        } as ISetFilterParams,
        cellEditorParams: {
            values: extractValues(options),
        },
        valueFormatter: (params: any) => {
            return lookupValue(options, params.value);
        },
    };
};

const extractValues = (mappings: { label: string; value: any }[]) => {
    return mappings && mappings.map((option: any) => option.value);
};

const lookupValue = (mappings: { label: string; value: any }[], key: any) => {
    let label = null;
    if (mappings && key) {
        const object = mappings.find((option: any) => option.value === key);
        label = object ? object.label : null;
    }
    return label;
};
