import ChangePassword from '../containers/login/ChangePassword';
import ChangeEmail from '../containers/login/ChangeEmail';
import RolesGrid from '../containers/settings/roles/RolesGrid';
import FeatureFlags from '../containers/settings/featureFlags/FeatureFlags';
import ChangeEmailConfirmation from '../containers/login/ChangeEmailConfirmation';

import { RouteProps } from './routesConfig';
import BusinessEntitySettings from '../containers/settings/entitySettings/BusinessEntitySettings';
import UserSettings from '../containers/settings/entitySettings/UserSettings';
import ModuleSettings from '../containers/settings/entitySettings/ModuleSettings';

const SettingsRoutes: RouteProps[] = [
    {
        path: '/settings',
        component: UserSettings,
        featureFlag: 'dashboard.nav.apps.settings',
    },
    {
        path: '/settings/user',
        component: UserSettings,
        featureFlag: 'dashboard.nav.apps.settings',
    },
    {
        path: '/settings/business-entity',
        component: BusinessEntitySettings,
        featureFlag: 'dashboard.nav.apps.settings',
    },
    {
        path: '/settings/module',
        component: ModuleSettings,
        featureFlag: 'dashboard.nav.apps.settings',
    },
    {
        path: '/settings/password',
        component: ChangePassword,
    },
    {
        path: '/settings/email',
        component: ChangeEmail,
    },
    {
        path: '/settings/roles',
        component: RolesGrid,
        featureFlag: 'settings.roles',
    },
    {
        path: '/settings/featureFlags',
        component: FeatureFlags,
        featureFlag: 'settings.viewFeatureFlags',
    },
    {
        path: '/settings/emailConfirmation/:email',
        component: ChangeEmailConfirmation,
    },
];

export default SettingsRoutes;
