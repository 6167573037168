import React from 'react';
import { useParams } from 'react-router-dom';
import BaseForm, { ParamTypes } from '../../components/form/baseForm/BaseForm';
import TranslatableText from '../../components/i18n/TranslatableText';
import { RoutingValues } from '../../types/Shared.types';
import FormPartial from '../../components/form/formContainer/FormPartial';
import { Checkbox, FormControlLabel, Grid } from '@mui/material';
import { SupplierAddressStatusFormDefs } from '../../constants/i18n/translations/termDefinitions/supply-chain';
import NumericInput from '../../components/form/formInputs/NumericInput/NumericInput';
import TextInput from '../../components/form/formInputs/TextInput/TextInput';
import useSupplierAddressStatusForm from './hooks/useSupplierAddressStatusForm';
import { SupplierAddressStatusRevisionHistoryFields } from '../../constants/revisionHistory/supplierApplicationRevisionHistory';

const SupplierAddressStatusForm = () => {
    const { id } = useParams<ParamTypes>();
    const {
        fields,
        handleFieldChange,
        formMethods,
        onCreate,
        onUpdate,
        onDelete,
        closeForm,
        isLoading,
        termSet,
        canCreate,
        canUpdate,
        canDelete,
        auditableFields,
    } = useSupplierAddressStatusForm(id);

    return (
        <BaseForm
            title={
                <>
                    <TranslatableText
                        termKey={SupplierAddressStatusFormDefs.Title}
                        termSet={termSet}
                    />{' '}
                    {fields?.code}
                </>
            }
            formMethods={formMethods}
            isLoading={isLoading}
            isNew={id === RoutingValues.newId}
            onCreate={onCreate}
            onUpdate={onUpdate}
            onDelete={onDelete}
            onCancel={closeForm}
            showOnRevision={true}
            fieldMappings={SupplierAddressStatusRevisionHistoryFields}
            entityUuid={fields?.uuid}
            displayCreateButton={canCreate}
            displayUpdateButton={canUpdate}
            displayDeleteButton={canDelete}
            auditableFields={auditableFields}>
            <FormPartial>
                <Grid container flexDirection="row" spacing={2}>
                    <Grid item xs={6}>
                        <TextInput
                            fullWidth
                            label={
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={SupplierAddressStatusFormDefs.Code}
                                />
                            }
                            id="code"
                            value={fields.code}
                            onChange={handleFieldChange}
                            formMethods={formMethods}
                            required={true}
                            maxLength={30}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <NumericInput
                            fullWidth
                            label={
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={
                                        SupplierAddressStatusFormDefs.SortOrder
                                    }
                                />
                            }
                            id="sortOrder"
                            value={fields.sortOrder}
                            onChange={handleFieldChange}
                            formMethods={formMethods}
                            required={true}
                            disabled={true}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextInput
                            fullWidth
                            label={
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={
                                        SupplierAddressStatusFormDefs.Description
                                    }
                                />
                            }
                            id="description"
                            value={fields.description}
                            onChange={handleFieldChange}
                            formMethods={formMethods}
                            maxLength={255}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <FormControlLabel
                            label={
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={
                                        SupplierAddressStatusFormDefs.StatusActive
                                    }
                                />
                            }
                            control={
                                <Checkbox
                                    id="statusActive"
                                    checked={fields.statusActive}
                                    onChange={handleFieldChange}
                                />
                            }
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <FormControlLabel
                            label={
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={
                                        SupplierAddressStatusFormDefs.AddressActive
                                    }
                                />
                            }
                            control={
                                <Checkbox
                                    id="addressActive"
                                    checked={fields.addressActive}
                                    onChange={handleFieldChange}
                                />
                            }
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <FormControlLabel
                            label={
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={
                                        SupplierAddressStatusFormDefs.Default
                                    }
                                />
                            }
                            control={
                                <Checkbox
                                    id="default"
                                    checked={fields.default}
                                    onChange={handleFieldChange}
                                />
                            }
                        />
                    </Grid>
                </Grid>
            </FormPartial>
        </BaseForm>
    );
};

export default SupplierAddressStatusForm;
