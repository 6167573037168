import AgGrid from '../../components/grids/Grid';
import { AgGridColumn } from 'ag-grid-react';
import CustomHeader from '../../components/grids/CustomHeader';
import { DocDefinitionGridDefs } from '../../constants/i18n/translations/termDefinitions/platform';
import { dateValueComparator } from '../../utils/comparisonUtils';
import { dateFormatter } from '../../utils/formattingUtils';
import DatacorLogoSpinner from '../../components/datacorLogoSpinner/DatacorLogoSpinner';
import React from 'react';
import useDocDefinitionGrid from './hooks/useDocDefinitionGrid';

const DocDefinitionGrid = () => {
    const {
        onGridReady,
        rowData,
        isLoading,
        redirectToForm,
        onSortChanged,
        onFirstDataRendered,
        termSet,
        canCreateDocDefinition,
        getApplicationNameByApplicationId,
    } = useDocDefinitionGrid();

    return (
        <>
            {!isLoading ? (
                <AgGrid
                    testId="docDefinition-list-grid"
                    dataKey="id"
                    immutableData={true}
                    rowData={rowData}
                    displayToolbar={true}
                    onCreateNew={(row: any) => redirectToForm(row)}
                    displayCreateNewButton={canCreateDocDefinition}
                    onRowClicked={({ data }: any) => redirectToForm(data)}
                    onFirstDataRendered={onFirstDataRendered}
                    onSortChanged={onSortChanged}
                    onGridReady={onGridReady}
                    defaultColDef={{
                        resizable: true,
                        sortable: true,
                        unSortIcon: true,
                        floatingFilter: true,
                        filter: true,
                        wrapText: true,
                    }}>
                    <AgGridColumn
                        flex={1}
                        minWidth={250}
                        filter={'agTextColumnFilter'}
                        field="name"
                        headerComponentFramework={(props: any) => {
                            return (
                                <CustomHeader
                                    {...props}
                                    termSet={termSet}
                                    termKey={DocDefinitionGridDefs.Name}
                                />
                            );
                        }}
                    />
                    <AgGridColumn
                        minWidth={200}
                        field="code"
                        filter={'agTextColumnFilter'}
                        headerComponentFramework={(props: any) => {
                            return (
                                <CustomHeader
                                    {...props}
                                    termSet={termSet}
                                    termKey={DocDefinitionGridDefs.Code}
                                />
                            );
                        }}
                    />
                    <AgGridColumn
                        minWidth={200}
                        field="applicationId"
                        filter={'agTextColumnFilter'}
                        valueGetter={getApplicationNameByApplicationId}
                        headerComponentFramework={(props: any) => {
                            return (
                                <CustomHeader
                                    {...props}
                                    termSet={termSet}
                                    termKey={
                                        DocDefinitionGridDefs.Application_Name
                                    }
                                />
                            );
                        }}
                    />
                    <AgGridColumn
                        minWidth={200}
                        filter={'agDateColumnFilter'}
                        filterParams={dateValueComparator}
                        field="createdAt"
                        headerComponentFramework={(props: any) => {
                            return (
                                <CustomHeader
                                    {...props}
                                    termSet={termSet}
                                    termKey={DocDefinitionGridDefs.Date_Created}
                                />
                            );
                        }}
                        valueFormatter={dateFormatter}
                    />
                    <AgGridColumn
                        minWidth={200}
                        filter={'agDateColumnFilter'}
                        filterParams={dateValueComparator}
                        field="updatedAt"
                        headerComponentFramework={(props: any) => {
                            return (
                                <CustomHeader
                                    {...props}
                                    termSet={termSet}
                                    termKey={
                                        DocDefinitionGridDefs.Last_Update_Date
                                    }
                                />
                            );
                        }}
                        valueFormatter={dateFormatter}
                    />
                </AgGrid>
            ) : (
                <DatacorLogoSpinner />
            )}
        </>
    );
};

export default DocDefinitionGrid;
