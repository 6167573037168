import React from 'react';
import { GridOptions } from '../../../components/grids/Grid.constants';
import { ColDef } from 'ag-grid-community';
import DefaultColumnTypes from '../../../components/grids/columns/Column.constants';
import BaseFormGrid from '../../../components/grids/BaseFormGrid';
import useLocaleGroupGrid from './hooks/useLocaleGroupGrid';
import CustomHeader from '../../../components/grids/CustomHeader';
import { LocaleGroupsGridDefs } from '../../../constants/i18n/translations/termDefinitions/formulation';
import TranslatableText from '../../../components/i18n/TranslatableText';

const LocaleGroupGrid = () => {
    const {
        isLoading,
        localeGroupList,
        redirectToForm,
        onFirstDataRendered,
        handleUpdateLocaleGroups,
        updateSelectedRows,
        canCreateLocaleGroup,
        canViewLocaleGroup,
        canUpdateLocaleGroup,
        onGridReady,
        onSortChanged,
        onFilterChanged,
        termSet,
    } = useLocaleGroupGrid();

    const columnDefs: ColDef[] = [
        {
            field: 'enabled',
            headerComponentFramework: (props: any) => {
                return (
                    <CustomHeader
                        {...props}
                        termSet={termSet}
                        termKey={LocaleGroupsGridDefs.Enabled_Active}
                    />
                );
            },
            ...DefaultColumnTypes.CheckboxSelection,
            hide: !canUpdateLocaleGroup,
        },
        {
            field: 'disabledActive',
            headerComponentFramework: (props: any) => {
                return (
                    <CustomHeader
                        {...props}
                        termSet={termSet}
                        termKey={LocaleGroupsGridDefs.Disabled_Active}
                    />
                );
            },
            hide: canUpdateLocaleGroup,
            cellRenderer: (params) => {
                return `<input type='checkbox' disabled="disabled"  ${
                    params.data.active ? 'checked' : ''
                } />`;
            },
        },
        {
            field: 'groupId',
            headerComponentFramework: (props: any) => {
                return (
                    <CustomHeader
                        {...props}
                        termSet={termSet}
                        termKey={LocaleGroupsGridDefs.Locale_Group_Id}
                    />
                );
            },
            ...DefaultColumnTypes.LongText,
        },
        {
            field: 'description',
            headerComponentFramework: (props: any) => {
                return (
                    <CustomHeader
                        {...props}
                        termSet={termSet}
                        termKey={LocaleGroupsGridDefs.Description}
                    />
                );
            },
            minWidth: DefaultColumnTypes.LongText.minWidth,
            filter: DefaultColumnTypes.LongText.filter,
            ...DefaultColumnTypes.AutoHeight,
        },
    ];

    const defaultColumnDefinitionOptions = {
        ...GridOptions.sortFilterAndWrapColumns,
        floatingFilter: true,
    };

    return (
        <BaseFormGrid
            title={
                <>
                    <TranslatableText
                        termKey={LocaleGroupsGridDefs.Title}
                        termSet={termSet}
                    />
                </>
            }
            displayGrid={canViewLocaleGroup}
            isLoading={isLoading}
            rowData={localeGroupList}
            immutableData={true}
            dataKey="id"
            columnDefs={columnDefs}
            gridClass="full-size-item"
            displayToolbar={true}
            displayCreateNewButton={canCreateLocaleGroup}
            displayExportDataButton={false}
            displayResetStateButton={false}
            displayEnableDisableButton={canUpdateLocaleGroup}
            onEnableDisable={handleUpdateLocaleGroups}
            onCreateNew={() => redirectToForm(null)}
            onRowClicked={({ data }) => redirectToForm(data)}
            onFirstDataRendered={onFirstDataRendered}
            defaultColDef={defaultColumnDefinitionOptions}
            rowSelection={'multiple'}
            onSelectionChanged={updateSelectedRows}
            onGridReady={onGridReady}
            onFilterChanged={onFilterChanged}
            onSortChanged={onSortChanged}
            setInitialRowSelected={true}
            sizeColumnsToFit={true}></BaseFormGrid>
    );
};

export default LocaleGroupGrid;
