import React, { useContext, useEffect, useState } from 'react';
import { skipToken } from '@reduxjs/toolkit/query';
import _, { cloneDeep, forEach } from 'lodash';
import { isNilOrEmpty } from '../../../../../../../../../utils/objectUtils';
import { RowStatus } from '../../../../../../../../../components/grids/hooks/useBaseGridEditable';
import { ColDef } from 'ag-grid-community';
import { editableGridCellSelect } from '../../../../../../../../../components/grids/columns/editableGridCellSelect';
import {
    Language,
    useGetLanguagesQuery,
} from '../../../../../../../../../services/organizations/organizations.service';
import {
    CasMaster,
    CasGhsPrecautionaryPhrase,
    GhsPrecautionaryPhrase,
} from '../../../../../../../../../types/formulation';
import applyEditableGridValidations from '../../../../../../../../grid/utils/applyEditableGridValidations';
import { ColumnRequiredValueValidator } from '../../../../../../../../grid/validators/columnRequiredValue.validator';
import { ColumnMaxValueValidator } from '../../../../../../../../grid/validators/columnMaxValue.validator';
import { ColumnNumericValueValidator } from '../../../../../../../../grid/validators/columnNumericValue.validator';
import { ColumnMinValueValidator } from '../../../../../../../../grid/validators/columnMinValue.validator';
import { useGetStatementTypesForDropdownQuery } from '../../../../../../../../../services/formulation/precautionaryPhrases/statementTypes/statementTypes.service';
import { useGetPrecautionaryPhrasesByLangIdQuery } from '../../../../../../../../../services/formulation/precautionaryPhrases/precautionaryPhrases.service';
import { PermissionsUtil } from '../../../../../../../../../utils/permissions/permissionsUtil';
import { PERMISSIONS } from '../../../../../../../../../constants/permissions/Permissions.constants';
import { ChildEditableGridWithCopyFunctionProps } from '../../../../../../../../grid/utils/editableGrid/ChildEditableGridWithCopyFunctionProps';
import SettingsContext from '../../../../../../../../../contexts/settings.context';
import { useGetTermSetQuery } from '../../../../../../../../../services/i18n/i18n.service';
import { FORMULATION_DEFS } from '../../../../../../../../../constants/i18n/translations/termSetDefinitions/formulation';
import CustomHeader from '../../../../../../../../../components/grids/CustomHeader';
import { CasMasterFormDefs } from '../../../../../../../../../constants/i18n/translations/termDefinitions/formulation';

const useCasGhsPrecautionaryPhrasesGrid = (
    props: ChildEditableGridWithCopyFunctionProps<
        CasMaster,
        CasGhsPrecautionaryPhrase
    >
) => {
    const { currentParentRecord, user } = props.parentData;

    const { showDeleteButton, isGridEditable } = props.displayGridButtons;

    const { handleChildrenRecords } = props.helpers;

    const { rowsData, setRowsData } = props.copyMethods;

    const [ghsPrecautionaryPhraseList, setGhsPrecautionaryPhraseList] =
        useState(null as GhsPrecautionaryPhrase[]);

    const [columnDefs, setColumnDefs] = useState(null as ColDef[]);

    const [showMultiSelectGridModal, setShowMultiSelectGridModal] =
        useState(false);

    const [maxRowId, setMaxRowId] = useState(0);

    const [showVerbiageMessage, setShowVerbiageMessage] = useState(false);

    const { data: languagesList, isLoading: isLoadingLanguages } =
        useGetLanguagesQuery();

    const defaultLangId = languagesList?.find(
        (lang: Language) => lang.code === 'ENG'
    )?.id;

    const {
        data: statementTypeOptionsList,
        isLoading: isLoadingStatementTypes,
    } = useGetStatementTypesForDropdownQuery();

    const {
        data: precautionaryPhraseList,
        isLoading: isLoadingPrecautionaryPhraseList,
    } = useGetPrecautionaryPhrasesByLangIdQuery(
        defaultLangId
            ? {
                  langId: defaultLangId.toString(),
              }
            : skipToken
    );

    const canViewGhsPrecautionaryPhrase = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.FORMULATION.GHS_PRECAUTIONARY_PHRASE.VIEW
    );

    const { settings } = useContext(SettingsContext);
    const { data: termSet } = useGetTermSetQuery(
        settings?.userSettings
            ? {
                  languageId: settings?.userSettings?.languageId,
                  code: FORMULATION_DEFS.CAS_MASTER_FORM,
              }
            : skipToken
    );

    /**
     * sorts records in casGhsPrecautionaryPhrases grid according to sdsSortOrder, then phraseId
     * @param casGhsPrecautionaryPhraseRecords
     */
    const sortCasGhsPrecautionaryPhraseRecords = (
        casGhsPrecautionaryPhraseRecords: CasGhsPrecautionaryPhrase[]
    ) => {
        const filteredList = casGhsPrecautionaryPhraseRecords.filter(
            (current) => current.rowStatus !== RowStatus.DELETED
        );
        setRowsData(
            _.orderBy(filteredList, ['sdsSortOrder', 'phraseId'], ['asc'])
        );
    };

    /**
     * consumes currentList from parent casRecord or elsewhere
     * adds rowId to each row based on index if none is found
     * sets maxRowId based on last items index
     * @param currentList
     */
    const setCasGhsPrecautionaryPhraseData = (
        currentList: CasGhsPrecautionaryPhrase[]
    ): CasGhsPrecautionaryPhrase[] => {
        const newList = cloneDeep(
            currentList.filter(
                (current) => current.rowStatus !== RowStatus.DELETED
            )
        );
        let currentRowId = maxRowId;
        newList.forEach((current) => {
            if (!current.rowId) {
                current.rowId = ++currentRowId;
            }
        });
        setMaxRowId(currentRowId);
        return newList;
    };

    /**
     * Gets language code for display in casPrecautionaryPhrases grid
     * @param langId
     */
    const getLanguageCodeById = (langId: bigint) => {
        let language = languagesList
            ? languagesList.find((current: Language) => current.id === langId)
            : null;
        return language?.code;
    };

    /**
     * filters out records found in casGhsPrecautionaryPhrase grid for multiselect modal
     * then sets them for the multiselect modal to use when opened
     */
    const setFilteredPrecautionaryPhraseList = () => {
        if (
            precautionaryPhraseList &&
            rowsData &&
            canViewGhsPrecautionaryPhrase
        ) {
            const filteredList = precautionaryPhraseList.filter((current) => {
                return isNilOrEmpty(
                    rowsData.find(
                        (casGhsRecord) =>
                            casGhsRecord.phraseId === current.phraseId &&
                            casGhsRecord.rowStatus !== RowStatus.DELETED
                    )
                );
            });
            setGhsPrecautionaryPhraseList(filteredList || []);
        } else {
            setGhsPrecautionaryPhraseList([]);
        }
    };

    /**
     * used to manage multiselect modal state
     * filters phrase list here to guarantee the list in the modal is accurate
     * @param showModal
     */
    const handleSetShowMultiSelectGridModal = (showModal: boolean) => {
        if (showModal) {
            setFilteredPrecautionaryPhraseList();
        }
        setShowMultiSelectGridModal(showModal);
    };

    /**
     * Builds list of selected Ghs Precautionary Phrases in format
     * required for CAS Ghs Precautionary Phrases grid
     * @param selectedRecords
     * @param index
     */
    const buildPrecautionaryListFromSelectedRecords = (
        selectedRecords: GhsPrecautionaryPhrase[],
        index: number
    ) => {
        return selectedRecords.map((current) => {
            index = index + 1;
            const newRecord: CasGhsPrecautionaryPhrase = {
                phraseId: current.phraseId,
                phraseText: current.phraseText,
                statementTypeId: current.statementTypeId,
                businessEntityId: current.businessEntityId,
                langId: current.langId,
                rowStatus: RowStatus.ADDED,
                rowId: index,
            };
            return newRecord;
        });
    };

    /**
     * Adds selected records from multiselect to CAS GHS Precautionary Phrases
     * @param selectedRecords
     */
    const handleSelectedRecords = (
        selectedRecords: GhsPrecautionaryPhrase[]
    ) => {
        let index = maxRowId;
        //build precautionary list from selected records and assign rowIds
        const casPrecautionaryList = buildPrecautionaryListFromSelectedRecords(
            selectedRecords,
            index
        );

        // get the new max row id from the precautionary list
        index = _.maxBy(casPrecautionaryList, 'rowId').rowId;

        setMaxRowId((maxRowId) => maxRowId + index);

        //add precautionary list to the existing CAS GHS precautionary phrase records
        updatePrecautionaryPhraseRecords(casPrecautionaryList);

        //show verbiage message if user input is needed
        userInputNeededCheck(selectedRecords);
    };

    /**
     * Show the verbiage message if any of the selected records has userInputNeeded
     * @param selectedRecords
     */
    const userInputNeededCheck = (
        selectedRecords: GhsPrecautionaryPhrase[]
    ) => {
        const userInputNeededRecord = selectedRecords?.find(
            (current) => current.userInputNeeded === true
        );
        if (userInputNeededRecord) {
            setShowVerbiageMessage(true);
        }
    };

    /**
     * Concatenates any non-deleted rows with the new added precautionary list
     * @param precautionaryList
     */
    const updatePrecautionaryPhraseRecords = (
        precautionaryList: CasGhsPrecautionaryPhrase[]
    ) => {
        handleRowValidations(precautionaryList);
        handleSaveGridEdits(precautionaryList);
    };

    /**
     * This triggers when 'save edits' is clicked
     * pushes casGhsPrecautionaryPhrase object to CAS master record
     * @param editedRows
     */
    const handleSaveGridEdits = (editedRows: CasGhsPrecautionaryPhrase[]) => {
        if (editedRows) {
            const updatedList = rowsData?.filter((current) => {
                return isNilOrEmpty(
                    editedRows.find(
                        (editedRecord) => editedRecord.rowId === current.rowId
                    )
                );
            });

            const newCasGhsPrecautionaryPhraseList = updatedList
                ? updatedList.concat(editedRows)
                : editedRows;

            forEach(
                newCasGhsPrecautionaryPhraseList,
                (updatedRecord: CasGhsPrecautionaryPhrase) => {
                    if (isNilOrEmpty(updatedRecord.sdsSortOrder)) {
                        updatedRecord.sdsSortOrder = null;
                    }
                }
            );

            //pushes updated rows to parent CAS master record
            handleChildrenRecords(
                'casGhsPrecautionaryPhrases',
                newCasGhsPrecautionaryPhraseList
            );
            sortCasGhsPrecautionaryPhraseRecords(
                newCasGhsPrecautionaryPhraseList
            );
        }
    };

    /**
     * sets validations on each of row fields in CasGhsPrecautionaryPhrase grid
     * @param editedRows
     */
    const handleRowValidations = (editedRows: CasGhsPrecautionaryPhrase[]) => {
        editedRows?.forEach((current) => {
            current.validationRules = [
                ColumnRequiredValueValidator('Phrase ID', current.phraseId),
                ColumnRequiredValueValidator('Phrase Text', current.phraseText),
                ColumnMaxValueValidator('Phrase Text', 255, current.phraseText),
                ColumnRequiredValueValidator('Language', current.langId),
                ColumnRequiredValueValidator(
                    'Statement Type',
                    current.statementTypeId
                ),
                ColumnNumericValueValidator(
                    'SDS Sort Order',
                    0,
                    current.sdsSortOrder
                ),
                ColumnMinValueValidator(
                    'SDS Sort Order',
                    0,
                    Number(current.sdsSortOrder)
                ),
            ];
            applyEditableGridValidations(current, `Phrase ${current.phraseId}`);
        });
        return editedRows;
    };

    /**
     * used for displaying message when record is selected from multiselect
     * message will display when record has 'isInputNeeded' marked true
     * @param showMessage
     */
    const handleShowVerbiageMessage = (showMessage: boolean) => {
        setShowVerbiageMessage(showMessage);
    };

    /**
     * triggers when cas record with data is present/altered
     * sorts and sets records from parent CAS record to casGhsPrecautionaryPhrases grid
     */
    useEffect(() => {
        const includeEditedRows =
            currentParentRecord?.casGhsPrecautionaryPhrases
                ? !isNilOrEmpty(
                      currentParentRecord.casGhsPrecautionaryPhrases.find(
                          (current) => current.rowStatus
                      )
                  )
                : false;
        if (
            currentParentRecord &&
            currentParentRecord.id &&
            currentParentRecord.casGhsPrecautionaryPhrases &&
            !includeEditedRows
        ) {
            sortCasGhsPrecautionaryPhraseRecords(
                setCasGhsPrecautionaryPhraseData(
                    currentParentRecord.casGhsPrecautionaryPhrases
                )
            );
        }
    }, [currentParentRecord?.casGhsPrecautionaryPhrases]);

    /**
     * triggers when data is loaded, sets columns for casGhsPrecautionary grid
     */
    useEffect(() => {
        if (languagesList && statementTypeOptionsList) {
            const colDefList: ColDef[] = [
                {
                    field: 'deleteColumn',
                    minWidth: 50,
                    hide: !showDeleteButton || !isGridEditable,
                    filter: false,
                },
                {
                    field: 'phraseId',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={
                                    CasMasterFormDefs.Ghs_Precautionary_Code
                                }
                            />
                        );
                    },
                    minWidth: 250,
                    filter: 'agTextColumnFilter',
                    editable: false,
                },
                {
                    field: 'phraseText',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={
                                    CasMasterFormDefs.Ghs_Precautionary_Phrase_Text
                                }
                            />
                        );
                    },
                    minWidth: 500,
                    filter: 'agTextColumnFilter',
                    editable: true,
                },
                {
                    field: 'statementTypeId',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={
                                    CasMasterFormDefs.Ghs_Precautionary_Statement_Type
                                }
                            />
                        );
                    },
                    minWidth: 300,
                    ...editableGridCellSelect(statementTypeOptionsList),
                    editable: isGridEditable,
                },
                {
                    field: 'langId',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={
                                    CasMasterFormDefs.Ghs_Precautionary_Language
                                }
                            />
                        );
                    },
                    minWidth: 100,
                    filter: 'agTextColumnFilter',
                    valueGetter: (params) =>
                        getLanguageCodeById(params.data.langId),
                    filterParams: {
                        valueGetter: (params: any) =>
                            getLanguageCodeById(params.data['langId']),
                    },
                },
            ];
            setColumnDefs(colDefList);
        }
    }, [languagesList, statementTypeOptionsList]);

    return {
        columnDefs,
        rowsData,
        ghsPrecautionaryPhraseList,
        showMultiSelectGridModal,
        isLoadingPrecautionaryPhraseList,
        showVerbiageMessage,
        handleSetShowMultiSelectGridModal,
        handleSelectedRecords,
        handleSaveGridEdits,
        handleRowValidations,
        handleShowVerbiageMessage,
        termSet,
    };
};

export default useCasGhsPrecautionaryPhrasesGrid;
