import React, { useContext } from 'react';
import FormPartial from '../../../components/form/formContainer/FormPartial';
import { Grid } from '@mui/material';
import SettingsContext from '../../../contexts/settings.context';
import TextInput from '../../../components/form/formInputs/TextInput/TextInput';
import useSettings from '../hooks/useSettings';
import { ACCOUNTING_DEFS } from '../../../constants/i18n/translations/termSetDefinitions/accounting';
import TranslatableText from '../../../components/i18n/TranslatableText';
import { AccountingSettingDefs } from '../../../constants/i18n/translations/termDefinitions/accounting';

export interface SettingSectionProps {
    onChange: (event: any, settingKey: string) => void;
}
const AccountingSettings = ({ onChange }: SettingSectionProps) => {
    const { settings } = useContext(SettingsContext);

    const { termSet } = useSettings({
        termSetCode: ACCOUNTING_DEFS.ACCOUNTING_SETTINGS,
    });

    return (
        <FormPartial
            title={
                (
                    <TranslatableText
                        termKey={AccountingSettingDefs.Title}
                        termSet={termSet}
                    />
                ) as unknown as string
            }>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <TextInput
                        id={'negativeNumberFormat'}
                        onChange={(event: any) =>
                            onChange(event, 'accountingSettings')
                        }
                        fullWidth
                        value={
                            settings.accountingSettings?.negativeNumberFormat
                        }
                        label={
                            <TranslatableText
                                termKey={
                                    AccountingSettingDefs.NegativeNumberFormat
                                }
                                termSet={termSet}
                            />
                        }
                    />
                </Grid>
            </Grid>
        </FormPartial>
    );
};

export default AccountingSettings;
