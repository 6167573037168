import React from 'react';
import { useParams } from 'react-router-dom';
import { Checkbox, FormControlLabel, Grid } from '@mui/material';
import { PeriodStatusFormDefs } from '../../../constants/i18n/translations/termDefinitions/accounting';
import BaseForm, {
    ParamTypes,
} from '../../../components/form/baseForm/BaseForm';
import TranslatableText from '../../../components/i18n/TranslatableText';
import { RoutingValues } from '../../../types/Shared.types';
import FormPartial from '../../../components/form/formContainer/FormPartial';
import TextInput from '../../../components/form/formInputs/TextInput/TextInput';
import NumericInput from '../../../components/form/formInputs/NumericInput/NumericInput';
import { PeriodStatusRevisionHistoryFields } from '../../../constants/revisionHistory/accountingAppRevisionHistory';
import usePeriodStatusForm from './hooks/usePeriodStatusForm';

const PeriodStatusForm = () => {
    const { id } = useParams<ParamTypes>();
    const {
        fields,
        formMethods,
        handleFieldChange,
        onCreate,
        onUpdate,
        onDelete,
        closeForm,
        isLoadingData,
        termSet,
        canCreate,
        canUpdate,
        canDelete,
        auditableFields,
    } = usePeriodStatusForm(id);

    return (
        <BaseForm
            title={
                <>
                    <TranslatableText
                        termKey={PeriodStatusFormDefs.Title}
                        termSet={termSet}
                    />{' '}
                    {fields?.code}
                </>
            }
            formMethods={formMethods}
            isLoading={isLoadingData}
            isNew={id === RoutingValues.newId}
            onCreate={onCreate}
            onUpdate={onUpdate}
            onDelete={onDelete}
            onCancel={closeForm}
            showOnRevision={true}
            fieldMappings={PeriodStatusRevisionHistoryFields}
            entityUuid={fields?.uuid}
            displayCreateButton={canCreate}
            displayUpdateButton={canUpdate}
            displayDeleteButton={canDelete}
            auditableFields={auditableFields}>
            <FormPartial>
                <Grid container flexDirection="row" spacing={2}>
                    <Grid item xs={5}>
                        <TextInput
                            fullWidth
                            label={
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={PeriodStatusFormDefs.Code}
                                />
                            }
                            id="code"
                            value={fields.code}
                            onChange={handleFieldChange}
                            formMethods={formMethods}
                            required={true}
                            maxLength={30}
                        />
                    </Grid>
                    <Grid item xs={5}>
                        <NumericInput
                            fullWidth
                            label={
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={PeriodStatusFormDefs.SortOrder}
                                />
                            }
                            id="sortOrder"
                            value={fields.sortOrder}
                            onChange={handleFieldChange}
                            formMethods={formMethods}
                            required={true}
                            disabled={true}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <FormControlLabel
                            label={
                                <TranslatableText
                                    termKey={PeriodStatusFormDefs.Closed}
                                    termSet={termSet}
                                />
                            }
                            control={
                                <Checkbox
                                    id="closed"
                                    checked={fields.closed}
                                    onChange={handleFieldChange}
                                    required={true}
                                    disabled={id !== RoutingValues.newId}
                                />
                            }
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextInput
                            fullWidth
                            label={
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={PeriodStatusFormDefs.Description}
                                />
                            }
                            id="description"
                            value={fields.description}
                            onChange={handleFieldChange}
                            formMethods={formMethods}
                            maxLength={255}
                        />
                    </Grid>
                </Grid>
            </FormPartial>
        </BaseForm>
    );
};

export default PeriodStatusForm;
