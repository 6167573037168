import React from 'react';
import { Card, CardContent, TextField } from '@mui/material';
import Grid from '@mui/material/Grid';
import LoaderButton from '../../components/LoaderButton';
import useChangeEmail from './hooks/useChangeEmail';

const ChangeEmail = () => {
    const {
        fields,
        handleFieldChange,
        handleUpdateClick,
        isSendingCode,
        validateEmailForm,
    } = useChangeEmail();

    return (
        <Grid container spacing={2}>
            <Grid xs={3} />
            <Grid xs={12} sm={12} md={6} lg={6} xl={6}>
                <Card>
                    <CardContent>
                        <Grid container spacing={2} style={{ padding: '10px' }}>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    required
                                    variant="outlined"
                                    label="Email"
                                    value={fields.email}
                                    id="email"
                                    onChange={handleFieldChange}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <LoaderButton
                                    fullWidth
                                    onClick={handleUpdateClick}
                                    type="submit"
                                    isLoading={isSendingCode}
                                    variant="contained"
                                    disabled={!validateEmailForm()}>
                                    Update Email
                                </LoaderButton>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
            <Grid xs={3} />
        </Grid>
    );
};

export default ChangeEmail;
