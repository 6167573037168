import React from 'react';
import { useParams } from 'react-router-dom';
import { Grid } from '@mui/material';
import BaseForm from '../../../../components/form/baseForm/BaseForm';
import FormPartial from '../../../../components/form/formContainer/FormPartial';
import TextInput from '../../../../components/form/formInputs/TextInput/TextInput';
import SelectInput from '../../../../components/form/formInputs/SelectInput/SelectInput';
import NumericInput from '../../../../components/form/formInputs/NumericInput/NumericInput';
import { HazardClassRulesFormDefs } from '../../../../constants/i18n/translations/termDefinitions/formulation';
import TranslatableText from '../../../../components/i18n/TranslatableText';
import useHazardClassificationRuleForm from './hooks/useHazardClassificationRuleForm';
import TextInputPopup from '../../../../components/form/formInputs/TextInputPopup/TextInputPopup';

type ParamTypes = { id: string };

const HazardClassificationRuleForm = () => {
    const { id } = useParams<ParamTypes>();

    const {
        fields,
        handleFieldChange,
        formMethods,
        onCreate,
        onUpdate,
        onDelete,
        closeForm,
        isLoadingHazardClassificationRule,
        isLoadingFormTypes,
        isLoadingHazardClassificationSteps,
        isLoadingLowerOperators,
        isLoadingUpperOperators,
        isLoadingHazardClassification,
        isLoadingLocaleGroups,
        formTypeOptions,
        hazardClassificationSteps,
        lowerOperatorsOptions,
        upperOperatorsOptions,
        localeGroupOptions,
        hazardClassOptions,
        canCreateHazardClassificationRule,
        canUpdateHazardClassificationRule,
        canDeleteHazardClassificationRule,
        canViewLocaleGroup,
        localeGroupTextPopupColDefs,
        hazardClassTextPopupColDefs,
        termSet,
        dropdownOptions,
    } = useHazardClassificationRuleForm(id);

    return (
        <BaseForm
            title={
                <TranslatableText
                    termSet={termSet}
                    termKey={HazardClassRulesFormDefs.Hazard_Class_Rules}
                />
            }
            formMethods={formMethods}
            isLoading={
                isLoadingHazardClassificationRule ||
                isLoadingFormTypes ||
                isLoadingHazardClassificationSteps ||
                isLoadingLowerOperators ||
                isLoadingUpperOperators ||
                isLoadingHazardClassification ||
                isLoadingLocaleGroups
            }
            isNew={!id}
            onCreate={onCreate}
            onUpdate={onUpdate}
            onDelete={onDelete}
            onCancel={closeForm}
            displayCreateButton={canCreateHazardClassificationRule}
            displayUpdateButton={canUpdateHazardClassificationRule}
            displayDeleteButton={canDeleteHazardClassificationRule}
            readOnly={id && !canUpdateHazardClassificationRule}>
            <FormPartial>
                <Grid container spacing={2}>
                    <Grid item xs={3}>
                        <TextInputPopup
                            fullWidth
                            id="hazardClassId"
                            onChange={handleFieldChange}
                            label={
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={
                                        HazardClassRulesFormDefs.Hazard_Class
                                    }
                                />
                            }
                            formMethods={formMethods}
                            popupOptions={hazardClassOptions}
                            colDefs={hazardClassTextPopupColDefs}
                            value={fields.hazardClassId}
                            valueFormatter={{
                                optionLabel: 'code',
                                value: 'id',
                            }}
                            required={true}
                            optionLabel={
                                dropdownOptions?.selectedHazardClass?.code
                            }
                            disabled={!!id}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextInput
                            fullWidth
                            label={
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={
                                        HazardClassRulesFormDefs.Hazard_Class_Description
                                    }
                                />
                            }
                            id="description"
                            formMethods={formMethods}
                            value={
                                dropdownOptions?.selectedHazardClass
                                    ?.description
                            }
                            onChange={handleFieldChange}
                            maxLength={100}
                            disabled={true}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextInputPopup
                            fullWidth
                            id="localeGroupId"
                            onChange={handleFieldChange}
                            label={
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={
                                        HazardClassRulesFormDefs.Locale_Group
                                    }
                                />
                            }
                            formMethods={formMethods}
                            popupOptions={
                                canViewLocaleGroup
                                    ? localeGroupOptions || []
                                    : []
                            }
                            colDefs={localeGroupTextPopupColDefs}
                            value={fields.localeGroupId}
                            valueFormatter={{
                                optionLabel: 'label',
                                value: 'value',
                            }}
                            required={false}
                            optionLabel={
                                dropdownOptions?.selectedLocaleGroup?.label
                            }
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <NumericInput
                            fullWidth
                            label={
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={HazardClassRulesFormDefs.Sequence}
                                />
                            }
                            id="sequence"
                            formMethods={formMethods}
                            value={fields.sequence}
                            onChange={handleFieldChange}
                            required={true}
                            maxValue={99999999}
                            greaterThan={1}
                        />
                    </Grid>
                    <Grid item xs={9}>
                        <TextInput
                            fullWidth
                            label={
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={HazardClassRulesFormDefs.Notes}
                                />
                            }
                            id="notes"
                            formMethods={formMethods}
                            value={fields.notes}
                            onChange={handleFieldChange}
                            maxLength={100}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextInput
                            fullWidth
                            label={
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={HazardClassRulesFormDefs.Category}
                                />
                            }
                            id="category"
                            formMethods={formMethods}
                            value={fields.category}
                            onChange={handleFieldChange}
                            required={true}
                            maxLength={30}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextInput
                            fullWidth
                            label={
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={
                                        HazardClassRulesFormDefs.Basis_Category
                                    }
                                />
                            }
                            id="basisCategory"
                            formMethods={formMethods}
                            value={fields.basisCategory}
                            onChange={handleFieldChange}
                            required={true}
                            maxLength={30}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <SelectInput
                            fullWidth
                            label={
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={HazardClassRulesFormDefs.Form_Type}
                                />
                            }
                            SelectProps={{
                                showClearButton: id
                                    ? canUpdateHazardClassificationRule
                                    : true,
                                readOnly:
                                    id && !canUpdateHazardClassificationRule,
                            }}
                            id="formId"
                            formMethods={formMethods}
                            value={fields.formId}
                            onChange={handleFieldChange}
                            options={formTypeOptions}
                            disabled={isLoadingFormTypes}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <SelectInput
                            fullWidth
                            label={
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={
                                        HazardClassRulesFormDefs.Next_Steps
                                    }
                                />
                            }
                            SelectProps={{
                                showClearButton: id
                                    ? canUpdateHazardClassificationRule
                                    : true,
                                readOnly:
                                    id && !canUpdateHazardClassificationRule,
                            }}
                            id="nextStepId"
                            formMethods={formMethods}
                            value={fields.nextStepId}
                            onChange={handleFieldChange}
                            options={hazardClassificationSteps}
                            disabled={isLoadingHazardClassificationSteps}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <SelectInput
                            fullWidth
                            label={
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={
                                        HazardClassRulesFormDefs.Individual_Operator
                                    }
                                />
                            }
                            SelectProps={{
                                showClearButton: id
                                    ? canUpdateHazardClassificationRule
                                    : true,
                                readOnly:
                                    id && !canUpdateHazardClassificationRule,
                            }}
                            id="individualOperatorId"
                            formMethods={formMethods}
                            value={fields.individualOperatorId}
                            onChange={handleFieldChange}
                            options={lowerOperatorsOptions}
                            disabled={isLoadingLowerOperators}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <NumericInput
                            fullWidth
                            id="individualPercent"
                            label={
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={
                                        HazardClassRulesFormDefs.Individual_Percent
                                    }
                                />
                            }
                            value={
                                fields.individualPercent == null
                                    ? ''
                                    : fields.individualPercent
                            }
                            onChange={handleFieldChange}
                            formMethods={formMethods}
                            numDecimals={4}
                            greaterThan={0}
                            maxValue={100}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <SelectInput
                            fullWidth
                            label={
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={
                                        HazardClassRulesFormDefs.Lower_Operator
                                    }
                                />
                            }
                            SelectProps={{
                                showClearButton: id
                                    ? canUpdateHazardClassificationRule
                                    : true,
                                readOnly:
                                    id && !canUpdateHazardClassificationRule,
                            }}
                            id="lowerOperatorId"
                            formMethods={formMethods}
                            value={fields.lowerOperatorId}
                            onChange={handleFieldChange}
                            options={lowerOperatorsOptions}
                            disabled={isLoadingLowerOperators}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <NumericInput
                            fullWidth
                            id="lowerValue"
                            label={
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={
                                        HazardClassRulesFormDefs.Lower_Value
                                    }
                                />
                            }
                            value={
                                fields.lowerValue == null
                                    ? ''
                                    : fields.lowerValue
                            }
                            onChange={handleFieldChange}
                            formMethods={formMethods}
                            numDecimals={4}
                            maxValue={999999999.9999}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <SelectInput
                            fullWidth
                            label={
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={
                                        HazardClassRulesFormDefs.Upper_Operator
                                    }
                                />
                            }
                            SelectProps={{
                                showClearButton: id
                                    ? canUpdateHazardClassificationRule
                                    : true,
                                readOnly:
                                    id && !canUpdateHazardClassificationRule,
                            }}
                            id="upperOperatorId"
                            formMethods={formMethods}
                            value={fields.upperOperatorId}
                            onChange={handleFieldChange}
                            options={upperOperatorsOptions}
                            disabled={isLoadingUpperOperators}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <NumericInput
                            fullWidth
                            id="upperValue"
                            label={
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={
                                        HazardClassRulesFormDefs.Upper_Value
                                    }
                                />
                            }
                            value={
                                fields.upperValue == null
                                    ? ''
                                    : fields.upperValue
                            }
                            onChange={handleFieldChange}
                            formMethods={formMethods}
                            numDecimals={4}
                            maxValue={999999999.9999}
                        />
                    </Grid>
                </Grid>
            </FormPartial>
        </BaseForm>
    );
};

export default HazardClassificationRuleForm;
