import { ExposureAgency } from '../../../types/formulation';
import {
    BaseCreateEntityArgs,
    BaseUpdateEntityArgs,
} from '../../serviceInterfaces';
import { datacorAppApi } from '../../datacorAppApi';

const serviceUrl: string = 'formulation/exposureAgency';

export const exposureAgencyApi = datacorAppApi.injectEndpoints({
    endpoints: (build) => ({
        createExposureAgency: build.mutation<
            ExposureAgency,
            BaseCreateEntityArgs<ExposureAgency>
        >({
            query: (args) => ({
                url: serviceUrl,
                method: 'POST',
                body: args.postBody,
            }),
            invalidatesTags: ['ExposureAgency'],
        }),
        updateExposureAgency: build.mutation<
            ExposureAgency,
            BaseUpdateEntityArgs<ExposureAgency>
        >({
            query: (args) => ({
                url: `${serviceUrl}/${args.id}`,
                method: 'PUT',
                body: args.postBody,
            }),
            invalidatesTags: ['ExposureAgency', 'ActiveExposureAgency'],
        }),
        deleteExposureAgency: build.mutation<ExposureAgency, string>({
            query: (id) => ({
                url: `${serviceUrl}/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['ExposureAgency'],
        }),
        getExposureAgency: build.query<ExposureAgency, string>({
            query: (id) => {
                return {
                    url: `${serviceUrl}/${id}`,
                };
            },
            providesTags: ['ActiveExposureAgency'],
        }),
        listExposureAgency: build.query<ExposureAgency[], void>({
            query: () => {
                return {
                    url: serviceUrl,
                };
            },
            providesTags: ['ExposureAgency'],
        }),
    }),
});

export const {
    useCreateExposureAgencyMutation,
    useUpdateExposureAgencyMutation,
    useDeleteExposureAgencyMutation,
    useGetExposureAgencyQuery,
    useListExposureAgencyQuery,
} = exposureAgencyApi;
