import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useCreateCompanyMutation } from '../../../services/users/users.service';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import config from '../../../config';
import { RootState } from '../../../store';
import { showNotificationError } from '../../../libs/errorLib';

interface CreateCompanyForm {
    companyName: string;
    companyId: string;
    platform: string;
    setupThirdPartyAuth: boolean;
}

const useCreateCompany = ({ handleClose }: any) => {
    const dispatch = useDispatch();
    const user = useSelector((state: RootState) => state.user);
    const [createCompany] = useCreateCompanyMutation();
    const schema = yup
        .object()
        .shape({
            companyName: yup.string().required(),
            companyId: yup.string().required(),
            platform: yup.string().required(),
            setupThirdPartyAuth: yup.boolean().required(),
        })
        .required();

    const { control, register, handleSubmit, formState, setValue, getValues } =
        useForm({
            resolver: yupResolver(schema),
            mode: 'all',
            defaultValues: {
                companyName: '',
                companyId: '',
                platform: '',
                setupThirdPartyAuth: false,
            },
        });

    register('setupThirdPartyAuth');

    const [isLoading, setIsLoading] = useState(false);

    const onSubmit = async (form: CreateCompanyForm) => {
        setIsLoading(true);

        const body = {
            companyName: form.companyName,
            companyId: form.companyId,
            platform: form.platform,
            setupThirdPartyAuth: form.setupThirdPartyAuth,
            userPoolId: config.cognito.USER_POOL_ID,
            userId: user.userId,
        };

        try {
            const createCompanyResponse = await createCompany(body);
            setIsLoading(false);
            setValue('setupThirdPartyAuth', false, { shouldValidate: true });
            handleClose(createCompanyResponse);
        } catch (e) {
            setIsLoading(false);
            return showNotificationError(e.message, 'error', dispatch);
        }
    };

    const isFormValid = () => {
        return formState.isValid;
    };

    const setChecked = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue('setupThirdPartyAuth', event.target.checked, {
            shouldValidate: true,
        });
    };

    return {
        control,
        setChecked,
        isFormValid,
        onSubmit,
        isLoading,
        handleSubmit,
    };
};

export default useCreateCompany;
