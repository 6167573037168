import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    DocDefinition,
    useCreateDocDefinitionMutation,
    useGetDocDefinitionQuery,
    useUpdateDocDefinitionMutation,
    useDeleteDocDefinitionMutation,
} from '../../../services/documentDesigner/documentDesigner.service';
import {
    Application,
    useListApplicationsQuery,
} from '../../../services/system/application.service';
import {
    EntityType,
    useListEntityTypesQuery,
} from '../../../services/system/entityType.service';
import {
    BaseCreateEntityArgs,
    BaseUpdateEntityArgs,
} from '../../../services/serviceInterfaces';
import { skipToken } from '@reduxjs/toolkit/query';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { showNotificationError } from '../../../libs/errorLib';
import SettingsContext from '../../../contexts/settings.context';
import { useGetTermSetQuery } from '../../../services/i18n/i18n.service';
import { PLATFORM_DEFS } from '../../../constants/i18n/translations/termSetDefinitions/platform';
import { PermissionsUtil } from '../../../utils/permissions/permissionsUtil';
import { PERMISSIONS } from '../../../constants/permissions/Permissions.constants';
import { openModalConfirmBasicWithMessage } from '../../../store/uiElements';
import { useForm } from '../../../libs/hooksLib';

const useDocDefinitionForm = (id: string) => {
    const dispatch = useDispatch();
    const user = useSelector((state: RootState) => state.user);
    const navigate = useNavigate();
    const { fields, handleFieldChange, setValues, formMethods, isFormValid } =
        useForm({});
    const { data: docDefinition, isLoading: isLoadingDocDefinition } =
        useGetDocDefinitionQuery(id ? { id: id, full: true } : skipToken);
    const { data: applications, isLoading: isLoadingApplications } =
        useListApplicationsQuery();
    const { data: entityTypes, isLoading: isLoadingEntityTypes } =
        useListEntityTypesQuery();
    const { settings } = useContext(SettingsContext);
    const { data: termSet } = useGetTermSetQuery(
        settings?.userSettings
            ? {
                  languageId: settings?.userSettings?.languageId,
                  code: PLATFORM_DEFS.DOC_DEFINITION_FORM,
              }
            : skipToken
    );

    const [createDocDefinition] = useCreateDocDefinitionMutation();
    const [updateDocDefinition] = useUpdateDocDefinitionMutation();
    const [deleteDocDefinition] = useDeleteDocDefinitionMutation();

    const defaultDocDefinition: DocDefinition = {
        applicationId: null,
        entityTypeId: null,
        code: '',
        name: '',
        enablePreview: false,
        enableSaveAttachment: false,
        purgeHours: null,
        purgeYears: null,
        keepAllCopies: false,
        checkForDuplicates: false,
        checkForStaleData: false,
    };

    useEffect(() => {
        setValues(docDefinition || defaultDocDefinition);
    }, [docDefinition, user]);

    const handleCreateDocDefinition = async () => {
        if (!isFormValid) {
            return;
        }

        const body: BaseCreateEntityArgs<DocDefinition> = {
            postBody: fields,
        };

        try {
            const response: { data: any } | { error: any } =
                await createDocDefinition(body);
            if ('error' in response) {
                throw new Error(
                    response.error.data?.[0].message || 'An Error has Occurred.'
                );
            }
            handleClose();
        } catch (e) {
            return showNotificationError(e.message, 'error', dispatch);
        }
    };

    const handleUpdateDocDefinition = async () => {
        if (!isFormValid) {
            return;
        }

        const body: BaseUpdateEntityArgs<DocDefinition> = {
            id: id as unknown as number,
            postBody: {
                ...fields,
            },
        };

        try {
            const response: { data: any } | { error: any } =
                await updateDocDefinition(body);
            if ('error' in response) {
                throw new Error(
                    response.error.data?.[0].message || 'An Error has Occurred.'
                );
            }
            handleClose();
        } catch (e) {
            return showNotificationError(e.message, 'error', dispatch);
        }
    };

    const handleDeleteDocDefinition = async () => {
        try {
            await deleteDocDefinition({ id: fields.id }).unwrap();
            handleClose();
        } catch (e) {
            return showNotificationError(
                'Unable to delete this DocDefinition.',
                'error',
                dispatch
            );
        }
    };

    const showDeleteConfirmModal = async () => {
        const message = `Are you sure you want to delete ${fields.name}?`;

        dispatch(
            openModalConfirmBasicWithMessage({
                message: message,
                title: '',
                onConfirm: handleDeleteDocDefinition,
            })
        );
    };

    const handleClose = () => {
        navigate('/document-designer/doc-definitions');
    };

    const isLoading =
        isLoadingDocDefinition || isLoadingApplications || isLoadingEntityTypes;

    const applicationsForSelect = applications?.map((app: Application) => ({
        label: app.name,
        value: app.id,
    }));

    const entityTypesForSelect = entityTypes?.map((et: EntityType) => ({
        label: et.name,
        value: et.id,
    }));

    const canEditDocDefinition = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.DOCUMENT_DESIGNER.DOC_DEFINITION.EDIT
    );

    const canCreateDocDefinition = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.DOCUMENT_DESIGNER.DOC_DEFINITION.CREATE
    );

    const canDeleteDocDefinition = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.DOCUMENT_DESIGNER.DOC_DEFINITION.DELETE
    );

    return {
        fields,
        user,
        docDefinition,
        applicationsForSelect,
        entityTypesForSelect,
        isLoading,
        handleCreateDocDefinition,
        handleUpdateDocDefinition,
        handleDeleteDocDefinition,
        handleFieldChange,
        handleClose,
        setValues,
        formMethods,
        termSet,
        canEditDocDefinition,
        canCreateDocDefinition,
        canDeleteDocDefinition,
        showDeleteConfirmModal,
    };
};

export default useDocDefinitionForm;
