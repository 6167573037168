import React from 'react';
import {
    useCreateContractTypeMutation,
    useDeleteContractTypeMutation,
    useGetContractTypeQuery,
    useUpdateContractTypeMutation,
} from '../../../services/accounting/accounting.service';
import { skipToken } from '@reduxjs/toolkit/query';
import { ContractType } from '../../../types/Accounting.types';
import useBaseForm from '../../form/hooks/useBaseForm';
import { PermissionsUtil } from '../../../utils/permissions/permissionsUtil';
import { PERMISSIONS } from '../../../constants/permissions/Permissions.constants';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { RoutingValues } from '../../../types/Shared.types';
import useTranslation from '../../../components/i18n/hooks/useTranslation';
import { ACCOUNTING_DEFS } from '../../../constants/i18n/translations/termSetDefinitions/accounting';

const UseContractTypeForm = (id: string) => {
    const user = useSelector((state: RootState) => state.user);
    const contractType = useSelector((state: RootState) => state.fixedAssets);
    const { data: activeContractType, isLoading: isLoadingContractType } =
        useGetContractTypeQuery(id === RoutingValues.newId ? skipToken : id);
    const [createContractType] = useCreateContractTypeMutation();
    const [updateContractType] = useUpdateContractTypeMutation();
    const [deleteContractType] = useDeleteContractTypeMutation();

    const canCreateContractType = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.FIXED_ASSETS.CONTRACT_TYPES.CREATE
    );
    const canUpdateContractType = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.FIXED_ASSETS.CONTRACT_TYPES.EDIT
    );
    const canDeleteContractType = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.FIXED_ASSETS.CONTRACT_TYPES.DELETE
    );

    const blankContractType: ContractType = {
        code: '',
        description: '',
        sortOrder: contractType.sortOrderSet.maxValue,
    };

    const { termSet } = useTranslation(ACCOUNTING_DEFS.CONTRACT_TYPES_FORM);

    const {
        fields,
        handleFieldChange,
        formMethods,
        onCreate,
        onUpdate,
        onDelete,
        closeForm,
    } = useBaseForm({
        closePath: '/accounting/contractType',
        blankEntity: blankContractType,
        activeEntity: activeContractType,
        getDescription: () => {
            return `Contract Type ${fields.code}`;
        },
        createEntity: () => {
            return createContractType({ postBody: fields });
        },
        updateEntity: () => {
            return updateContractType({
                id: id as unknown as number,
                postBody: fields,
            });
        },
        deleteEntity: () => {
            return deleteContractType(id);
        },
    });

    return {
        fields,
        handleFieldChange,
        formMethods,
        onCreate,
        onUpdate,
        onDelete,
        closeForm,
        isLoadingContractType,
        canCreateContractType,
        canUpdateContractType,
        canDeleteContractType,
        termSet,
    };
};

export default UseContractTypeForm;
