import React, { useContext, useEffect, useState } from 'react';
import { GhsHazardPhrase } from '../../../../types/formulation';
import {
    Language,
    useGetLanguagesQuery,
} from '../../../../services/organizations/organizations.service';
import { PermissionsUtil } from '../../../../utils/permissions/permissionsUtil';
import { PERMISSIONS } from '../../../../constants/permissions/Permissions.constants';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { openModalConfirmBasicWithMessage } from '../../../../store/uiElements';
import SettingsContext from '../../../../contexts/settings.context';
import { useGetTermSetQuery } from '../../../../services/i18n/i18n.service';
import { FORMULATION_DEFS } from '../../../../constants/i18n/translations/termSetDefinitions/formulation';
import { skipToken } from '@reduxjs/toolkit/query';
import { GhsHazardPhrasesFormDefs } from '../../../../constants/i18n/translations/termDefinitions/formulation';
import TranslatableText from '../../../../components/i18n/TranslatableText';

const useGhsHazardPhraseContainer = (phraseId: string) => {
    const dispatch = useDispatch();
    const user = useSelector((state: RootStateOrAny) => state.user);
    const [phraseIdList, setPhraseIdList] = useState(null as Array<string>);
    const [defaultLangId, setDefaultLangId] = useState(null as bigint);
    const [isFormDirty, setIsFormDirty] = useState(false);

    const { data: languagesList, isLoading: isLoadingLanguages } =
        useGetLanguagesQuery();

    const canViewGhsHazardPhrase = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.FORMULATION.GHS_HAZARD_PHRASE.VIEW
    );

    const { settings } = useContext(SettingsContext);
    const { data: termSet } = useGetTermSetQuery(
        settings?.userSettings
            ? {
                  languageId: settings?.userSettings?.languageId,
                  code: FORMULATION_DEFS.GHS_HAZARD_PHRASES_FORM,
              }
            : skipToken
    );

    useEffect(() => {
        if (languagesList && !defaultLangId) {
            const defaultLangId = languagesList?.find(
                (lang: Language) => lang.code === 'ENG'
            )?.id;
            setDefaultLangId(defaultLangId);
        }
    }, [languagesList]);

    const handleSetPhraseList = (phraseList: Array<GhsHazardPhrase>) => {
        if (phraseList) {
            const list = phraseList.map((phrase) => phrase.phraseId);
            setPhraseIdList(list);
        }
    };

    const handleSetIsFormDirty = (formDirty: boolean) => {
        if (formDirty !== isFormDirty) {
            setIsFormDirty(formDirty);
        }
    };

    const handlePromptFormDirty = (
        onConfirm: () => void,
        onCancel?: () => void
    ) => {
        if (isFormDirty) {
            const message = (
                <TranslatableText
                    termSet={termSet}
                    termKey={GhsHazardPhrasesFormDefs.Changes_Not_Saved}
                />
            );
            dispatch(
                openModalConfirmBasicWithMessage({
                    message: message,
                    title: '',
                    onConfirm: () => {
                        handleSetIsFormDirty(false);
                        onConfirm();
                    },
                    onCancel: onCancel,
                })
            );
        } else {
            onConfirm();
        }
    };

    return {
        handleSetPhraseList,
        handleSetIsFormDirty,
        handlePromptFormDirty,
        languagesList,
        defaultLangId,
        phraseIdList,
        isLoadingLanguages,
        canViewGhsHazardPhrase,
        termSet,
    };
};

export default useGhsHazardPhraseContainer;
