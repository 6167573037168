import React from 'react';
import {
    Card,
    CardHeader,
    CardContent,
    CardActions,
    Grid,
    Button,
    IconButton,
    List,
    ListItem,
} from '@mui/material';
import useAdministrationPortal from './hooks/useAdministrationPortal';
import DatacorLogoSpinner from '../../components/datacorLogoSpinner/DatacorLogoSpinner';
import TreeView from '../../components/treeView/TreeView';
import LaunchIcon from '@mui/icons-material/Launch';

const cardStyle: any = {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
};

const cardActionStyle = {
    marginTop: 'auto',
};

const CARD_BUTTON_PATHS = {
    subscribers: {
        new: '/administration/organizations/subscribers/new',
        view: '/administration/organizations/subscribers',
    },
    businessEntities: {
        new: '/administration/organizations/business-entities/new',
        view: '/administration/organizations/business-entities',
    },
    buildings: {
        new: '/administration/organizations/buildings/new',
        view: '/administration/organizations/buildings',
    },
    users: {
        new: '/administration/organizations/users/new',
        view: '/administration/organizations/users',
    },
    groups: {
        new: '/administration/organizations/groups/new',
        view: '/administration/organizations/groups',
    },
    roles: {
        new: '/administration/organizations/roles/new',
        view: '/administration/organizations/roles',
    },
    departmentCodes: {
        new: '/administration/organizations/department-codes/new',
        view: '/administration/organizations/department-codes',
    },
    permissions: {
        new: '/administration/organizations/permissions/new',
        view: '/administration/organizations/permissions',
    },
};
const AdministrationPortal = () => {
    const {
        user,
        navigate,
        subscribers,
        businessEntities,
        buildings,
        users,
        groups,
        roles,
        departmentCodes,
        permissions,
        shouldLoad,
    } = useAdministrationPortal();

    const CardInfo = ({ item, value, pathType }: any) => {
        return (
            <List>
                {[...item]
                    .sort(
                        (a: any, b: any) =>
                            Number(new Date(b.createdAt)) -
                            Number(new Date(a.createdAt))
                    )
                    .splice(0, 5)
                    .map((itemInfo: any) => (
                        <ListItem key={itemInfo.id}>
                            <IconButton
                                size="small"
                                color="primary"
                                ref={(ref) => {
                                    if (!ref) {
                                        return;
                                    }
                                    ref.onclick = (event) => {
                                        navigate(
                                            `/administration/organizations/${pathType}/${itemInfo.id}`
                                        );
                                    };
                                }}>
                                <LaunchIcon />
                            </IconButton>
                            {itemInfo[value]}
                        </ListItem>
                    ))}
            </List>
        );
    };

    const handleRedirect = (module: string) => {
        navigate(module);
    };

    return (
        <Grid container spacing={2}>
            {!shouldLoad ? (
                <>
                    {user?.isDatacorAdmin && (
                        <Grid item xs={4}>
                            <Card style={cardStyle}>
                                <CardHeader
                                    title={
                                        <>
                                            Subscribers{' '}
                                            <Button
                                                onClick={() =>
                                                    handleRedirect(
                                                        CARD_BUTTON_PATHS
                                                            .subscribers.new
                                                    )
                                                }>
                                                Add New
                                            </Button>
                                        </>
                                    }
                                />
                                <CardContent>
                                    Total Number: {subscribers?.length}
                                    <CardInfo
                                        item={subscribers}
                                        value="name"
                                        pathType="subscribers"
                                    />
                                </CardContent>
                                <CardActions style={cardActionStyle}>
                                    <Button
                                        onClick={() =>
                                            handleRedirect(
                                                CARD_BUTTON_PATHS.subscribers
                                                    .view
                                            )
                                        }>
                                        View Subscribers
                                    </Button>
                                </CardActions>
                            </Card>
                        </Grid>
                    )}
                    <Grid item xs={4}>
                        <Card style={cardStyle}>
                            <CardHeader
                                title={
                                    <>
                                        Business Entities
                                        <Button
                                            onClick={() =>
                                                handleRedirect(
                                                    CARD_BUTTON_PATHS
                                                        .businessEntities.new
                                                )
                                            }>
                                            Add New
                                        </Button>
                                    </>
                                }
                            />
                            <CardContent>
                                Total Number:
                                {
                                    //@ts-ignore
                                    businessEntities?.length
                                }
                                <TreeView
                                    dataSource={businessEntities}
                                    businessEntity
                                />
                            </CardContent>
                            <CardActions style={cardActionStyle}>
                                <Button
                                    onClick={() =>
                                        handleRedirect(
                                            CARD_BUTTON_PATHS.businessEntities
                                                .view
                                        )
                                    }>
                                    View Business Entities
                                </Button>
                            </CardActions>
                        </Card>
                    </Grid>
                    <Grid item xs={4}>
                        <Card style={cardStyle}>
                            <CardHeader
                                title={
                                    <>
                                        Buildings
                                        <Button
                                            onClick={() =>
                                                handleRedirect(
                                                    CARD_BUTTON_PATHS.buildings
                                                        .new
                                                )
                                            }>
                                            Add New
                                        </Button>
                                    </>
                                }
                            />
                            <CardContent>
                                Total Number: {buildings?.length}
                                <CardInfo
                                    item={buildings}
                                    value="name"
                                    pathType="buildings"
                                />
                            </CardContent>
                            <CardActions style={cardActionStyle}>
                                <Button
                                    onClick={() =>
                                        handleRedirect(
                                            CARD_BUTTON_PATHS.buildings.view
                                        )
                                    }>
                                    View Buildings
                                </Button>
                            </CardActions>
                        </Card>
                    </Grid>
                    <Grid item xs={4}>
                        <Card style={cardStyle}>
                            <CardHeader
                                title={
                                    <>
                                        Users
                                        <Button
                                            onClick={() =>
                                                handleRedirect(
                                                    CARD_BUTTON_PATHS.users.new
                                                )
                                            }>
                                            Add New
                                        </Button>
                                    </>
                                }
                            />
                            <CardContent>
                                Total Number: {users?.length}
                                <CardInfo
                                    item={users}
                                    value="firstName"
                                    pathType="users"
                                />
                            </CardContent>
                            <CardActions style={cardActionStyle}>
                                <Button
                                    onClick={() =>
                                        handleRedirect(
                                            CARD_BUTTON_PATHS.users.view
                                        )
                                    }>
                                    View Users
                                </Button>
                            </CardActions>
                        </Card>
                    </Grid>
                    <Grid item xs={4}>
                        <Card style={cardStyle}>
                            <CardHeader
                                title={
                                    <>
                                        Groups
                                        <Button
                                            onClick={() =>
                                                handleRedirect(
                                                    CARD_BUTTON_PATHS.groups.new
                                                )
                                            }>
                                            Add New
                                        </Button>
                                    </>
                                }
                            />
                            <CardContent>
                                Total Number: {groups?.length}
                                <CardInfo
                                    item={groups}
                                    value="name"
                                    pathType="groups"
                                />
                            </CardContent>
                            <CardActions style={cardActionStyle}>
                                <Button
                                    onClick={() =>
                                        handleRedirect(
                                            CARD_BUTTON_PATHS.groups.view
                                        )
                                    }>
                                    View Groups
                                </Button>
                            </CardActions>
                        </Card>
                    </Grid>
                    <Grid item xs={4}>
                        <Card style={cardStyle}>
                            <CardHeader
                                title={
                                    <>
                                        Roles
                                        <Button
                                            onClick={() =>
                                                handleRedirect(
                                                    CARD_BUTTON_PATHS.roles.new
                                                )
                                            }>
                                            Add New
                                        </Button>
                                    </>
                                }
                            />
                            <CardContent>
                                Total Number: {roles?.length}
                                <CardInfo
                                    item={roles}
                                    value="name"
                                    pathType="roles"
                                />
                            </CardContent>
                            <CardActions style={cardActionStyle}>
                                <Button
                                    onClick={() =>
                                        handleRedirect(
                                            CARD_BUTTON_PATHS.roles.view
                                        )
                                    }>
                                    View Roles
                                </Button>
                            </CardActions>
                        </Card>
                    </Grid>
                    <Grid item xs={4}>
                        <Card style={cardStyle}>
                            <CardHeader
                                title={
                                    <>
                                        Department Codes
                                        <Button
                                            onClick={() =>
                                                handleRedirect(
                                                    CARD_BUTTON_PATHS
                                                        .departmentCodes.new
                                                )
                                            }>
                                            Add New
                                        </Button>
                                    </>
                                }
                            />
                            <CardContent>
                                Total Number: {departmentCodes?.length}
                                <CardInfo
                                    item={departmentCodes}
                                    value="name"
                                    pathType="department-codes"
                                />
                            </CardContent>
                            <CardActions style={cardActionStyle}>
                                <Button
                                    onClick={() =>
                                        handleRedirect(
                                            CARD_BUTTON_PATHS.departmentCodes
                                                .view
                                        )
                                    }>
                                    View Department Codes
                                </Button>
                            </CardActions>
                        </Card>
                    </Grid>
                    <Grid item xs={4}>
                        <Card style={cardStyle}>
                            <CardHeader
                                title={
                                    <>
                                        Permissions
                                        <Button
                                            onClick={() =>
                                                handleRedirect(
                                                    CARD_BUTTON_PATHS
                                                        .permissions.new
                                                )
                                            }>
                                            Add New
                                        </Button>
                                    </>
                                }
                            />
                            <CardContent>
                                Total Number: {permissions?.length}
                                <TreeView dataSource={permissions} type="org" />
                            </CardContent>
                            <CardActions style={cardActionStyle}>
                                <Button
                                    onClick={() =>
                                        handleRedirect(
                                            CARD_BUTTON_PATHS.permissions.view
                                        )
                                    }>
                                    View Permissions
                                </Button>
                            </CardActions>
                        </Card>
                    </Grid>
                </>
            ) : (
                <DatacorLogoSpinner />
            )}
        </Grid>
    );
};

export default AdministrationPortal;
