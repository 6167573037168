import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import { PermissionsUtil } from '../../../../utils/permissions/permissionsUtil';
import { PERMISSIONS } from '../../../../constants/permissions/Permissions.constants';
import {
    useCreateCurrencyMutation,
    useListCurrencyCodesQuery,
    useGetCurrencyQuery,
    useGetTermSetQuery,
    useUpdateCurrencyMutation,
    useDeleteCurrencyMutation,
    useListCurrencyRateTypeCodesQuery,
} from '../../../../services/i18n/i18n.service';
import { ACCOUNTING_DEFS } from '../../../../constants/i18n/translations/termSetDefinitions/accounting';
import { skipToken } from '@reduxjs/toolkit/query';
import { useContext, useEffect, useState } from 'react';
import SettingsContext from '../../../../contexts/settings.context';
import useBaseForm from '../../../form/hooks/useBaseForm';
import { RoutingValues, SelectionOption } from '../../../../types/Shared.types';
import {
    Currency,
    CurrencyCode,
} from '../../../../types/platform/i18n/Currency.type';
import { isNil } from '../../../../utils/objectUtils';
import NavFormContext from '../../../../contexts/navForm.context';

export const useCurrencyGrid = (id: string) => {
    const user = useSelector((state: RootState) => state.user);
    const currencyState = useSelector((state: RootState) => state.accounting);
    const { accountingNav } = useContext(NavFormContext);

    const canCreateCurrency = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.ACCOUNTING.CURRENCIES.CREATE
    );
    const canUpdateCurrency = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.ACCOUNTING.CURRENCIES.EDIT
    );
    const canDeleteCurrency = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.ACCOUNTING.CURRENCIES.DELETE
    );

    const { settings } = useContext(SettingsContext);
    const { data: termSet, isLoading: isLoadingTermSet } = useGetTermSetQuery(
        settings?.userSettings
            ? {
                  languageId: settings?.userSettings?.languageId,
                  code: ACCOUNTING_DEFS.CURRENCY_FORM,
              }
            : skipToken
    );

    const { data: activeCurrency, isLoading: isLoading } = useGetCurrencyQuery(
        id === RoutingValues.newId ? skipToken : id
    );

    const { data: currencyCodes, isLoading: isLoadingCurrencyCodes } =
        useListCurrencyCodesQuery();

    const { data: rateTypeCodes, isLoading: isLoadingRateTypeCodes } =
        useListCurrencyRateTypeCodesQuery();

    const [createCurrency] = useCreateCurrencyMutation();
    const [updateCurrency] = useUpdateCurrencyMutation();
    const [deleteCurrency] = useDeleteCurrencyMutation();

    const blankCurrency: Currency = {
        id: null,
        businessEntityId: null,
        createdAt: null,
        createdBy: null,
        updatedAt: null,
        updatedBy: null,
        currencyCode: '',
        sortOrder: currencyState.sortOrderSet.maxValue,
        fullName: '',
        shortName: '',
        symbol: '',
        decimalDisplay: null,
    };

    const isLoadingData = () =>
        (isLoading || isLoadingRateTypeCodes || isLoadingCurrencyCodes) &&
        isLoadingTermSet;

    const {
        fields,
        handleFieldChange,
        formMethods,
        setValues,
        onCreate,
        onUpdate,
        onDelete,
        closeForm,
        hasEntityChanges,
    } = useBaseForm({
        closePath: '/accounting/currency',
        blankEntity: blankCurrency,
        activeEntity: activeCurrency,
        getDescription: () => {
            return `Currency ${fields.currencyCode}`;
        },
        createEntity: () => {
            return createCurrency({ postBody: fields });
        },
        updateEntity: () => {
            return updateCurrency({
                id: id as unknown as number,
                postBody: fields,
            });
        },
        deleteEntity: () => {
            return deleteCurrency(id);
        },
        onlyUpdateEntityWithChanges: true,
    });

    useEffect(() => {
        if (isNil(fields.id) && fields.currencyCode) {
            const code: CurrencyCode = currencyCodes?.find(
                (optionList: SelectionOption) =>
                    optionList.value === fields.currencyCode
            )?.object;
            if (!isNil(code)) {
                setValues({
                    currentCurrencyCode: code,
                    fullName: code.fullName,
                    shortName: code.shortName,
                    symbol: code.symbol,
                    decimalDisplay: code.decimalDisplay,
                });
            }
        }
    }, [fields.currencyCode]);

    const getCurrentTabInitialState = () => {
        let tabIndex = '1';
        if (accountingNav && accountingNav.isFormRedirect) {
            tabIndex = accountingNav.tabIndex;
            accountingNav.isFormRedirect = false;
        }
        return tabIndex;
    };

    const [currentTab, setCurrentTab] = useState<string>(
        getCurrentTabInitialState()
    );

    const parentDataProps = {
        currentEntity: activeCurrency,
        isCurrentEntityLoading: isLoadingData(),
        user,
    };

    return {
        fields,
        handleFieldChange,
        formMethods,
        onCreate,
        onUpdate,
        onDelete,
        closeForm,
        termSet,
        canCreateCurrency,
        canUpdateCurrency,
        canDeleteCurrency,
        currencyCodes,
        isLoadingCurrencyCodes,
        currentTab,
        setCurrentTab,
        rateTypeCodes,
        hasEntityChanges,
        parentDataProps,
        isLoadingData,
    };
};

export default useCurrencyGrid;
