import React from 'react';
import { useParams } from 'react-router-dom';
import BaseForm, { ParamTypes } from '../../components/form/baseForm/BaseForm';
import TranslatableText from '../../components/i18n/TranslatableText';
import { RoutingValues } from '../../types/Shared.types';
import FormPartial from '../../components/form/formContainer/FormPartial';
import { Grid } from '@mui/material';
import useSupplierForm from './hooks/useSupplierForm';
import { SupplierFormDefs } from '../../constants/i18n/translations/termDefinitions/supply-chain';
import TextInput from '../../components/form/formInputs/TextInput/TextInput';
import SelectInput from '../../components/form/formInputs/SelectInput/SelectInput';
import EntityAttributesSection from '../../components/attributes/EntityAttributesSection';
import { SupplierRevisionHistoryFields } from '../../constants/revisionHistory/supplierApplicationRevisionHistory';
import SectionSupplierAddressGrid from './components/SectionSupplierAddressGrid';

const SupplierForm = () => {
    const { id } = useParams<ParamTypes>();
    const {
        fields,
        handleFieldChange,
        formMethods,
        onCreate,
        onUpdate,
        onDelete,
        closeForm,
        isLoading,
        termSet,
        canCreate,
        canUpdate,
        canDelete,
        auditableFields,
        statusOptions,
        isLoadingStatuses,
        typeOptions,
        isLoadingTypes,
        accountOptions,
        isLoadingAccounts,
        activeTemplates,
        isLoadingTemplates,
        attrFieldsDisabled,
        handleApplyTemplate,
        formDefs,
        attrValues,
        setAttrValues,
        setIsAttributeValid,
    } = useSupplierForm(id);

    return (
        <BaseForm
            title={
                <>
                    <TranslatableText
                        termKey={SupplierFormDefs.Title}
                        termSet={termSet}
                    />{' '}
                    {fields?.code}
                </>
            }
            formMethods={formMethods}
            isLoading={isLoading}
            isNew={id === RoutingValues.newId}
            onCreate={onCreate}
            onUpdate={onUpdate}
            onDelete={onDelete}
            onCancel={closeForm}
            showOnRevision={true}
            fieldMappings={SupplierRevisionHistoryFields}
            entityUuid={fields?.uuid}
            displayCreateButton={canCreate}
            displayUpdateButton={canUpdate}
            displayDeleteButton={canDelete}
            auditableFields={auditableFields}>
            <FormPartial>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <TextInput
                            fullWidth
                            label={
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={SupplierFormDefs.Code}
                                />
                            }
                            id="code"
                            value={fields.code}
                            onChange={handleFieldChange}
                            formMethods={formMethods}
                            required={true}
                            maxLength={30}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextInput
                            fullWidth
                            label={
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={SupplierFormDefs.Name}
                                />
                            }
                            id="name"
                            value={fields.name}
                            onChange={handleFieldChange}
                            formMethods={formMethods}
                            required={true}
                            maxLength={30}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <SelectInput
                            fullWidth
                            label={
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={SupplierFormDefs.Status}
                                />
                            }
                            id="supplierStatusId"
                            value={fields.supplierStatusId}
                            onChange={handleFieldChange}
                            formMethods={formMethods}
                            options={statusOptions}
                            disabled={isLoadingStatuses}
                            required={true}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <SelectInput
                            fullWidth
                            label={
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={SupplierFormDefs.Type}
                                />
                            }
                            id="supplierTypeId"
                            value={fields.supplierTypeId}
                            onChange={handleFieldChange}
                            formMethods={formMethods}
                            options={typeOptions}
                            disabled={isLoadingTypes}
                            required={true}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextInput
                            fullWidth
                            label={
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={SupplierFormDefs.TaxNumber}
                                />
                            }
                            id="taxNumber"
                            value={fields.taxNumber}
                            onChange={handleFieldChange}
                            formMethods={formMethods}
                            required={true}
                            maxLength={30}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextInput
                            fullWidth
                            label={
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={SupplierFormDefs.Website}
                                />
                            }
                            id="website"
                            value={fields.website}
                            onChange={handleFieldChange}
                            formMethods={formMethods}
                            required={true}
                            maxLength={30}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <SelectInput
                            fullWidth
                            label={
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={SupplierFormDefs.ExpenseAccount}
                                />
                            }
                            id="expenseAccountId"
                            value={fields.expenseAccountId}
                            onChange={handleFieldChange}
                            formMethods={formMethods}
                            options={accountOptions}
                            disabled={isLoadingAccounts}
                            required={false}
                        />
                    </Grid>
                </Grid>
            </FormPartial>
            <EntityAttributesSection
                id={id}
                fields={fields}
                handleFieldChange={handleFieldChange}
                formMethods={formMethods}
                activeTemplates={activeTemplates}
                isLoadingTemplates={isLoadingTemplates}
                attrFieldsDisabled={attrFieldsDisabled}
                canUpdateParent={canUpdate}
                handleApplyTemplate={handleApplyTemplate}
                formDefs={formDefs}
                attrValues={attrValues}
                setAttrValues={setAttrValues}
                setIsAttributeValid={setIsAttributeValid}
                entityAttributes={fields?.supplierAttributes}
                title="Supplier Attributes"
                profiles={fields?.supplierProfiles}
            />
            <FormPartial
                title={
                    (
                        <TranslatableText
                            termKey={SupplierFormDefs.SupplierAddressSection}
                            termSet={termSet}
                        />
                    ) as unknown as string
                }
                isVisible={id !== RoutingValues.newId}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <SectionSupplierAddressGrid
                            supplierId={id}
                            createPermission={canCreate}
                        />
                    </Grid>
                </Grid>
            </FormPartial>
        </BaseForm>
    );
};

export default SupplierForm;
