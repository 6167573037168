import { useSelector } from 'react-redux';
import { RootState } from '../../../../../store';
import { PermissionsUtil } from '../../../../../utils/permissions/permissionsUtil';
import { PERMISSIONS } from '../../../../../constants/permissions/Permissions.constants';
import useTranslation from '../../../../../components/i18n/hooks/useTranslation';
import { ACCOUNTING_DEFS } from '../../../../../constants/i18n/translations/termSetDefinitions/accounting';
import { PeriodQuarter } from '../../../../../types/Accounting.types';
import {
    useCreatePeriodQuarterMutation,
    useDeletePeriodQuarterMutation,
    useGetPeriodQuarterQuery,
    useUpdatePeriodQuarterMutation,
} from '../../../../../services/accounting/accounting.service';
import useBaseForm from '../../../../form/hooks/useBaseForm';
import { RoutingValues } from '../../../../../types/Shared.types';
import { skipToken } from '@reduxjs/toolkit/query';

const usePeriodQuarterForm = (id: string) => {
    const user = useSelector((state: RootState) => state.user);

    const periodQuarterState = useSelector(
        (state: RootState) => state.accounting
    );

    const displayCreateNewButton = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.ACCOUNTING.PERIOD_QUARTER.CREATE
    );

    const displayUpdateButton = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.ACCOUNTING.PERIOD_QUARTER.EDIT
    );

    const displayDeleteButton = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.ACCOUNTING.PERIOD_QUARTER.DELETE
    );

    const canViewPeriodQuarters = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.ACCOUNTING.PERIOD_QUARTER.VIEW
    );

    const { termSet, isLoadingTermSet } = useTranslation(
        ACCOUNTING_DEFS.PERIOD_QUARTER_FORM
    );

    const [createPeriodQuarter] = useCreatePeriodQuarterMutation();

    const [updatePeriodQuarter] = useUpdatePeriodQuarterMutation();

    const [deletePeriodQuarter] = useDeletePeriodQuarterMutation();

    const { data: currentPeriodQuarter, isLoading: isPeriodQuarterLoading } =
        useGetPeriodQuarterQuery(id === RoutingValues.newId ? skipToken : id);

    const blankPeriodQuarter: PeriodQuarter = {
        id: null,
        businessEntityId: null,
        code: '',
        description: '',
        sortOrder: periodQuarterState.sortOrderSet.maxValue,
        createdAt: null,
        createdBy: null,
        updatedAt: null,
        updatedBy: null,
    };

    const {
        fields,
        handleFieldChange,
        formMethods,
        onCreate,
        onUpdate,
        onDelete,
        closeForm,
        auditableFields,
    } = useBaseForm({
        closePath: '/accounting/setup/periodQuarters',
        blankEntity: blankPeriodQuarter,
        activeEntity: currentPeriodQuarter,
        getDescription: () => {
            return `Period Quarter ${fields.code}`;
        },
        createEntity: () => {
            return createPeriodQuarter({ postBody: fields });
        },
        updateEntity: () => {
            return updatePeriodQuarter({
                id: id as unknown as number,
                postBody: fields,
            });
        },
        deleteEntity: () => {
            return deletePeriodQuarter(id);
        },
    });

    return {
        fields,
        handleFieldChange,
        formMethods,
        auditableFields,
        onCreate,
        onUpdate,
        onDelete,
        closeForm,
        termSet,
        isLoading: isPeriodQuarterLoading || isLoadingTermSet,
        /** All display/permissions **/
        permissions: {
            canView: canViewPeriodQuarters,
            displayCreateNewButton,
            displayUpdateButton,
            displayDeleteButton,
            readOnly: id && !displayUpdateButton,
        },
    };
};

export default usePeriodQuarterForm;
