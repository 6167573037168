import React from 'react';
import {
    Autocomplete,
    AutocompleteProps,
    Checkbox,
    TextFieldProps,
} from '@mui/material';
import { SelectionOption } from '../../../../types/Shared.types';
import InputBase from '../InputBase/InputBase';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

export type MultiSelectInputProps = Omit<
    AutocompleteProps<any, any, any, any>,
    'id' | 'options' | 'onChange' | 'renderInput'
> & {
    id: string;
    label?: string;
    options: SelectionOption[];
    onChange: (event: any) => void;
};

const MultiSelectInput = (props: MultiSelectInputProps) => {
    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
    const checkedIcon = <CheckBoxIcon fontSize="small" />;

    const handleOnChange = (event: any, values: SelectionOption[]) => {
        props.onChange({ target: { id: props.id, value: values } });
    };

    return (
        <Autocomplete
            {...props}
            multiple
            options={props.options || []}
            value={props.value || undefined}
            getOptionLabel={(option: SelectionOption) => option.label}
            onChange={handleOnChange}
            renderOption={(props, option: SelectionOption, { selected }) => (
                <li {...props}>
                    <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                    />
                    {option.label}
                </li>
            )}
            renderInput={(params: TextFieldProps) => (
                <InputBase
                    fullWidth
                    {...params}
                    id={props.id}
                    label={props.label}
                />
            )}
        />
    );
};

export default MultiSelectInput;
