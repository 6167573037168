import React from 'react';
import GhsPrecautionaryPhraseContainer from './GhsPrecautionaryPhraseContainer';

const GhsPrecautionaryPhraseShowForm = () => {
    return (
        <>
            <GhsPrecautionaryPhraseContainer showForm={true} />
        </>
    );
};

export default GhsPrecautionaryPhraseShowForm;
