import { PermissionsUtil } from '../../../../utils/permissions/permissionsUtil';
import { PERMISSIONS } from '../../../../constants/permissions/Permissions.constants';
import { RootState } from '../../../../store';
import { useSelector } from 'react-redux';
import { FiscalYearStatus } from '../../../../types/accounting/common/Periodicity';
import useBaseForm from '../../../form/hooks/useBaseForm';
import useTranslation from '../../../../components/i18n/hooks/useTranslation';
import { ACCOUNTING_DEFS } from '../../../../constants/i18n/translations/termSetDefinitions/accounting';
import {
    useCreateFiscalYearStatusMutation,
    useDeleteFiscalYearStatusMutation,
    useGetFiscalYearStatusQuery,
    useUpdateFiscalYearStatusMutation,
} from '../../../../services/accounting/accounting.service';
import { RoutingValues } from '../../../../types/Shared.types';
import { skipToken } from '@reduxjs/toolkit/query';

const useFiscalYearStatusForm = (id: string) => {
    //translation
    const { termSet, isLoadingTermSet } = useTranslation(
        ACCOUNTING_DEFS.FISCAL_YEAR_STATUS_FORM
    );

    //permissions
    const canCreate = PermissionsUtil.hasPermission(
        PERMISSIONS.ACCOUNTING.FISCAL_YEAR_STATUSES.CREATE
    );
    const canUpdate = PermissionsUtil.hasPermission(
        PERMISSIONS.ACCOUNTING.FISCAL_YEAR_STATUSES.EDIT
    );
    const canDelete = PermissionsUtil.hasPermission(
        PERMISSIONS.ACCOUNTING.FISCAL_YEAR_STATUSES.DELETE
    );

    //request
    const { data, isLoading } = useGetFiscalYearStatusQuery(
        id === RoutingValues.newId ? skipToken : id
    );
    const [createMutation] = useCreateFiscalYearStatusMutation();
    const [updateMutation] = useUpdateFiscalYearStatusMutation();
    const [deleteMutation] = useDeleteFiscalYearStatusMutation();

    //set default values
    const accountingState = useSelector((state: RootState) => state.accounting);
    const blankEntity: FiscalYearStatus = {
        id: null,
        code: '',
        description: '',
        sortOrder: accountingState.sortOrderSet.maxValue,
        businessEntityId: null,
        closed: false,
        createdAt: null,
        createdBy: null,
        updatedAt: null,
        updatedBy: null,
    };

    const isLoadingData = () =>
        id === RoutingValues.newId
            ? isLoadingTermSet
            : isLoadingTermSet && isLoading;

    const {
        fields,
        handleFieldChange,
        formMethods,
        onCreate,
        onUpdate,
        onDelete,
        closeForm,
        auditableFields,
    } = useBaseForm({
        closePath: '/accounting/setup/fiscalYearStatus',
        blankEntity: blankEntity,
        activeEntity: data,
        getDescription: () => {
            return `Fiscal Year Status ${fields.code}`;
        },
        createEntity: () => {
            return createMutation({ postBody: fields });
        },
        updateEntity: () => {
            return updateMutation({
                id: id as unknown as number,
                postBody: fields,
            });
        },
        deleteEntity: () => {
            return deleteMutation(id);
        },
    });

    return {
        fields,
        formMethods,
        handleFieldChange,
        onCreate,
        onUpdate,
        onDelete,
        closeForm,
        isLoadingData,
        termSet,
        canCreate,
        canUpdate,
        canDelete,
        auditableFields,
    };
};

export default useFiscalYearStatusForm;
