import React, { useEffect, useState } from 'react';
import { ICellRendererParams, ValueGetterParams } from 'ag-grid-community';
import {
    RDFormula,
    RDFormulaComponent,
} from '../../../../../../../types/formulation';
import { GridOptions } from '../../../../../../../components/grids/Grid.constants';
import DefaultColumnTypes from '../../../../../../../components/grids/columns/Column.constants';
import { isNilOrEmpty } from '../../../../../../../utils/objectUtils';
import { editableGridCellSelect } from '../../../../../../../components/grids/columns/editableGridCellSelect';
import { RowStatus } from '../../../../../../../components/grids/hooks/useBaseGridEditable';
import { SelectionOption } from '../../../../../../../types/Shared.types';
import CustomHeader from '../../../../../../../components/grids/CustomHeader';
import { RDFormulasFormDefs } from '../../../../../../../constants/i18n/translations/termDefinitions/formulation';
import useRDFormulaComponentsFunctions from './useRDformulaComponentsFunctions';
import { ChildEditableGridWithCopyFunctionProps } from '../../../../../../grid/utils/editableGrid/ChildEditableGridWithCopyFunctionProps';

const yesOption: SelectionOption = {
    value: 1 as unknown as bigint,
    label: 'Yes',
};

const noOption: SelectionOption = {
    value: 2 as unknown as bigint,
    label: 'No',
};

const yesNoOptions = [yesOption, noOption];

const useRDFormulaComponentsGrid = (
    props: ChildEditableGridWithCopyFunctionProps<RDFormula, RDFormulaComponent>
) => {
    const { currentParentRecord } = props.parentData;

    const { showDeleteButton, isGridEditable } = props.displayGridButtons;

    const {
        getComponentCode,
        getComponentDetails,
        getBoilerPlateNoteCode,
        getUOMCode,
        enableDisableRowCell,
        termSet,
        canViewItem,
        canViewBoilerPlateNotes,
        canViewUOM,
        isLoadingItems,
        isLoadingBoilerPlateNotes,
        isLoadingUnitOfMeasure,
        isLoadingComponentTypes,
        componentTypeOptions,
        itemList,
        unitOfMeasureOptions,
        boilerPlateNoteOptions,
        formulaComponentColumnDefs,
        setFormulaComponentColumnDefs,
        rowsData,
        unitOfMeasureColumnDefs,
        setUnitOfMeasureColumnDefs,
        itemColumnDefs,
        setItemColumnDefs,
        boilerPlateColumnDefs,
        setBoilerPlateColumnDefs,
        handleGridEdits,
        handleRowValidations,
        handleColumnChanged,
        handleNewRow,
        sortFormulaComponents,
    } = useRDFormulaComponentsFunctions(props);

    const [maxRowId, setMaxRowId] = useState(0);

    const colDefOptions = {
        ...GridOptions.sortFilterAndWrapColumns,
        floatingFilter: true,
    };

    useEffect(() => {
        const includeEditedRows = currentParentRecord?.components
            ? !isNilOrEmpty(
                  currentParentRecord?.components.find(
                      (current) => current.rowStatus
                  )
              )
            : false;

        if (
            currentParentRecord?.components &&
            !isLoadingUnitOfMeasure &&
            !isNilOrEmpty(unitOfMeasureOptions) &&
            !isLoadingItems &&
            !isNilOrEmpty(itemList) &&
            !isNilOrEmpty(boilerPlateNoteOptions) &&
            !isLoadingBoilerPlateNotes &&
            !includeEditedRows
        ) {
            const componentRows: RDFormulaComponent[] = [];
            let index = maxRowId;
            currentParentRecord?.components.forEach(
                (component: RDFormulaComponent) => {
                    index = index + 1;
                    setMaxRowId((maxRowId) => maxRowId + 1);
                    if (component.rowStatus !== RowStatus.DELETED) {
                        const componentDetails = getComponentDetails(
                            component.componentId,
                            itemList
                        );

                        const componentRecord: any = {
                            ...component,
                            componentCode: getComponentCode(
                                component.componentId
                            ),
                            boilerPlateNoteCode: getBoilerPlateNoteCode(
                                component.boilerPlateNoteId
                            ),
                            stockUomCode: getUOMCode(component.stockUomId),
                            componentUomCode: getUOMCode(component.uomId),
                            lockQty: !isNilOrEmpty(component.lockQty)
                                ? component.lockQty === true
                                    ? yesOption.value
                                    : noOption.value
                                : noOption.value,
                            stockWeight:
                                componentDetails?.itemUnitOfMeasure
                                    ?.stockWeight || 0,
                            stockVolume:
                                componentDetails?.itemUnitOfMeasure
                                    ?.stockVolume || 0,
                            stockWeightUnit: getUOMCode(
                                componentDetails?.weightUnitId
                            ),
                            stockVolumeUnit: getUOMCode(
                                componentDetails?.volumeUnitId
                            ),
                            rowId: component.rowId ? component.rowId : index,
                        };
                        componentRows.push(componentRecord);
                    }
                }
            );
            sortFormulaComponents(componentRows);
        }
    }, [
        currentParentRecord?.components,
        isLoadingUnitOfMeasure,
        unitOfMeasureOptions,
        isLoadingItems,
        itemList,
        isLoadingBoilerPlateNotes,
        boilerPlateNoteOptions,
    ]);

    /**
     * Column Defs for Component Selection Popup
     */
    useEffect(() => {
        if (!isLoadingItems && !isNilOrEmpty(itemList)) {
            setItemColumnDefs([
                {
                    field: 'itemId',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={RDFormulasFormDefs.Component_Name}
                            />
                        );
                    },
                    cellClass: 'ag-left-aligned-cell',
                    filter: 'agTextColumnFilter',
                },
                {
                    field: 'itemName',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={
                                    RDFormulasFormDefs.Component_Selection_Description
                                }
                            />
                        );
                    },
                    cellClass: 'ag-left-aligned-cell',
                    filter: 'agTextColumnFilter',
                },
            ]);
        }
    }, [isLoadingItems, itemList]);

    /**
     * Column Defs for UOM and Stock UOM Selection Popups
     */
    useEffect(() => {
        if (!isLoadingUnitOfMeasure && !isNilOrEmpty(unitOfMeasureOptions)) {
            setUnitOfMeasureColumnDefs([
                {
                    field: 'unitCode',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={RDFormulasFormDefs.Component_Uom}
                            />
                        );
                    },
                    cellClass: 'ag-left-aligned-cell',
                    filter: 'agTextColumnFilter',
                },
                {
                    field: 'description',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={
                                    RDFormulasFormDefs.Component_Selection_Description
                                }
                            />
                        );
                    },
                    cellClass: 'ag-left-aligned-cell',
                    filter: 'agTextColumnFilter',
                },
            ]);
        }
    }, [isLoadingUnitOfMeasure, unitOfMeasureOptions]);

    /**
     * Column Defs for Boiler Plate Note Selection Popup
     */
    useEffect(() => {
        if (
            !isLoadingBoilerPlateNotes &&
            !isNilOrEmpty(boilerPlateNoteOptions)
        ) {
            setBoilerPlateColumnDefs([
                {
                    field: 'code',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={
                                    RDFormulasFormDefs.Component_Selection_Code
                                }
                            />
                        );
                    },
                    cellClass: 'ag-left-aligned-cell',
                    filter: 'agTextColumnFilter',
                },
                {
                    field: 'description',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={
                                    RDFormulasFormDefs.Component_Selection_Description
                                }
                            />
                        );
                    },
                    cellClass: 'ag-left-aligned-cell',
                    filter: 'agTextColumnFilter',
                },
            ]);
        }
    }, [isLoadingBoilerPlateNotes, boilerPlateNoteOptions]);

    /**
     * Column Defs for Components Grid
     */
    useEffect(() => {
        if (
            !isNilOrEmpty(itemList) &&
            !isLoadingItems &&
            !isNilOrEmpty(itemColumnDefs) &&
            !isLoadingUnitOfMeasure &&
            !isNilOrEmpty(unitOfMeasureOptions) &&
            !isNilOrEmpty(unitOfMeasureColumnDefs) &&
            !isLoadingComponentTypes &&
            !isNilOrEmpty(componentTypeOptions) &&
            !isLoadingBoilerPlateNotes &&
            !isNilOrEmpty(boilerPlateNoteOptions) &&
            !isNilOrEmpty(boilerPlateColumnDefs)
        ) {
            setFormulaComponentColumnDefs([
                {
                    field: 'deleteColumn',
                    minWidth: 50,
                    hide: !showDeleteButton || !isGridEditable,
                    filter: false,
                },
                {
                    field: 'stepNo',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={RDFormulasFormDefs.Component_Step}
                            />
                        );
                    },
                    minWidth: 150,
                    filter: 'agNumberColumnFilter',
                    editable: isGridEditable,
                },
                {
                    field: 'componentTypeId',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={RDFormulasFormDefs.Component_Type}
                            />
                        );
                    },
                    minWidth: 100,
                    filter: 'agTextColumnFilter',
                    ...editableGridCellSelect(componentTypeOptions),
                    editable: isGridEditable,
                },
                {
                    field: 'componentCode',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={RDFormulasFormDefs.Component_Name}
                            />
                        );
                    },
                    minWidth: 150,
                    editable:
                        canViewItem &&
                        isGridEditable &&
                        enableDisableRowCell.bind(this),
                    cellRenderer: (params: ICellRendererParams) =>
                        params.value.display,
                    useGridPopup: true,
                    filter: 'agTextColumnFilter',
                    gridPopupParameters: {
                        isLoading: isLoadingItems,
                        displayGrid: true,
                        rowData: canViewItem ? itemList : [],
                        defaultColDef: colDefOptions,
                        columnDefs: itemColumnDefs,
                        displayField: 'itemId',
                    },
                },
                {
                    field: 'componentDescription',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={
                                    RDFormulasFormDefs.Component_Selection_Description
                                }
                            />
                        );
                    },
                    ...DefaultColumnTypes.MediumText,
                    minWidth: 160,
                    editable: false,
                    valueGetter: (params: ValueGetterParams) => {
                        const details = getComponentDetails(
                            params.data.componentCode?.id,
                            itemList
                        );
                        return details?.itemName;
                    },
                },

                {
                    field: 'formulaQty',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={
                                    RDFormulasFormDefs.Component_Formula_Qty
                                }
                            />
                        );
                    },
                    minWidth: 150,
                    filter: 'agNumberColumnFilter',
                    editable: isGridEditable && enableDisableRowCell.bind(this),
                },
                {
                    field: 'componentUomCode',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={RDFormulasFormDefs.Component_Uom}
                            />
                        );
                    },
                    editable:
                        canViewUOM &&
                        isGridEditable &&
                        enableDisableRowCell.bind(this),
                    minWidth: 150,
                    cellRenderer: (params: ICellRendererParams) =>
                        params.value.display,
                    useGridPopup: true,
                    gridPopupParameters: {
                        isLoading: isLoadingUnitOfMeasure,
                        displayGrid: true,
                        rowData: canViewUOM ? unitOfMeasureOptions : [],
                        defaultColDef: colDefOptions,
                        columnDefs: unitOfMeasureColumnDefs,
                        displayField: 'unitCode',
                    },
                    filter: 'agTextColumnFilter',
                },
                {
                    field: 'uomCost',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={RDFormulasFormDefs.Component_Uom_Cost}
                            />
                        );
                    },
                    minWidth: 150,
                    filter: 'agNumberColumnFilter',
                    editable: isGridEditable && enableDisableRowCell.bind(this),
                },
                {
                    field: 'lockQty',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={RDFormulasFormDefs.Component_Lock_Qty}
                            />
                        );
                    },
                    minWidth: 50,
                    ...editableGridCellSelect(yesNoOptions),
                    editable: isGridEditable && enableDisableRowCell.bind(this),
                },
                {
                    field: 'scrapPercent',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={RDFormulasFormDefs.Component_Scrap_Pct}
                            />
                        );
                    },
                    minWidth: 200,
                    filter: 'agNumberColumnFilter',
                    editable: isGridEditable && enableDisableRowCell.bind(this),
                },
                {
                    field: 'weightPercent',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={
                                    RDFormulasFormDefs.Component_Weight_Pct
                                }
                            />
                        );
                    },
                    minWidth: 200,
                    filter: 'agNumberColumnFilter',
                    editable: false,
                },
                {
                    field: 'volumePercent',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={
                                    RDFormulasFormDefs.Component_Volume_Pct
                                }
                            />
                        );
                    },
                    minWidth: 200,
                    filter: 'agNumberColumnFilter',
                    editable: false,
                },
                {
                    field: 'unitCost',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={RDFormulasFormDefs.Component_Unit_Cost}
                            />
                        );
                    },
                    minWidth: 150,
                    filter: 'agNumberColumnFilter',
                    editable: false,
                },
                {
                    field: 'boilerPlateNoteCode',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={
                                    RDFormulasFormDefs.Component_Boilerplate_Note
                                }
                            />
                        );
                    },
                    editable:
                        canViewBoilerPlateNotes &&
                        isGridEditable &&
                        enableDisableRowCell.bind(this),
                    minWidth: 200,
                    cellRenderer: (params: any) => params.value.display,
                    useGridPopup: true,
                    gridPopupParameters: {
                        isLoading: isLoadingBoilerPlateNotes,
                        displayGrid: true,
                        rowData: canViewBoilerPlateNotes
                            ? boilerPlateNoteOptions
                            : [],
                        defaultColDef: colDefOptions,
                        columnDefs: boilerPlateColumnDefs,
                        displayField: 'code',
                    },
                    filter: 'agTextColumnFilter',
                },
                {
                    field: 'stockUomConversion',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={
                                    RDFormulasFormDefs.Component_Stock_Uom_Conv
                                }
                            />
                        );
                    },
                    minWidth: 250,
                    filter: 'agNumberColumnFilter',
                    editable: false,
                },
                {
                    field: 'stockUomCode',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={RDFormulasFormDefs.Component_Stock_Uom}
                            />
                        );
                    },
                    editable:
                        canViewUOM &&
                        isGridEditable &&
                        enableDisableRowCell.bind(this),
                    minWidth: 200,
                    cellRenderer: (params: any) => params.value.display,
                    useGridPopup: true,
                    gridPopupParameters: {
                        isLoading: isLoadingUnitOfMeasure,
                        displayGrid: true,
                        rowData: canViewUOM ? unitOfMeasureOptions : [],
                        defaultColDef: colDefOptions,
                        columnDefs: unitOfMeasureColumnDefs,
                        displayField: 'unitCode',
                    },
                    filter: 'agTextColumnFilter',
                },
                {
                    field: 'specificGravity',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={
                                    RDFormulasFormDefs.Component_Specific_Gravity
                                }
                            />
                        );
                    },
                    minWidth: 180,
                    filter: 'agNumberColumnFilter',
                    editable: false,
                },
                {
                    field: 'density',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={RDFormulasFormDefs.Component_Density}
                            />
                        );
                    },
                    minWidth: 150,
                    filter: 'agNumberColumnFilter',
                    editable: false,
                },
                {
                    field: 'stockCost',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={
                                    RDFormulasFormDefs.Component_Stock_Cost
                                }
                            />
                        );
                    },
                    minWidth: 150,
                    filter: 'agNumberColumnFilter',
                    editable: isGridEditable && enableDisableRowCell.bind(this),
                },
                {
                    field: 'extendedCost',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={
                                    RDFormulasFormDefs.Component_Extended_Cost
                                }
                            />
                        );
                    },
                    minWidth: 150,
                    filter: 'agNumberColumnFilter',
                    editable: false,
                },
                {
                    field: 'stockQty',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={RDFormulasFormDefs.Component_Stock_Qty}
                            />
                        );
                    },
                    minWidth: 150,
                    filter: 'agNumberColumnFilter',
                    editable: false,
                },
                {
                    field: 'weightKg',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={RDFormulasFormDefs.Component_Weight_Kg}
                            />
                        );
                    },
                    minWidth: 150,
                    filter: 'agNumberColumnFilter',
                    editable: false,
                },
                {
                    field: 'volumeLitres',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={
                                    RDFormulasFormDefs.Component_Volume_Litres
                                }
                            />
                        );
                    },
                    minWidth: 150,
                    filter: 'agNumberColumnFilter',
                    editable: false,
                },
                {
                    field: 'conversionMethod',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={
                                    RDFormulasFormDefs.Component_Conversion_Method
                                }
                            />
                        );
                    },
                    minWidth: 200,
                    filter: 'agTextColumnFilter',
                    editable: false,
                },
            ]);
        }
    }, [
        itemList,
        isLoadingItems,
        itemColumnDefs,
        isLoadingUnitOfMeasure,
        unitOfMeasureOptions,
        unitOfMeasureColumnDefs,
        isLoadingComponentTypes,
        componentTypeOptions,
        boilerPlateColumnDefs,
        isLoadingBoilerPlateNotes,
        boilerPlateNoteOptions,
    ]);

    return {
        isLoadingUnitOfMeasure,
        isLoadingComponentTypes,
        isLoadingBoilerPlateNotes,
        isLoadingItems,
        formulaComponentColumnDefs,
        rowsData,
        handleGridEdits,
        handleColumnChanged,
        handleRowValidations,
        colDefOptions,
        handleNewRow,
    };
};

export default useRDFormulaComponentsGrid;
