import DatacorLogoSpinner from '../../../../../../components/datacorLogoSpinner/DatacorLogoSpinner';
import React from 'react';
import FormPartial from '../../../../../../components/form/formContainer/FormPartial';
import SubSection11_1Form from './subSections/subSection11_1/SubSection11_1Form';
import SubSection11_2Form from './subSections/subSection11_2/SubSection11_2Form';
import SubSection11_3Form from './subSections/subSection11_3/SubSection11_3Form';
import SubSection11_5Form from './subSections/subSection11_5/SubSection11_5Form';
import { SdsHeader, SdsToxicity } from '../../../../../../types/formulation';
import SdsToxicityGrid from './subSections/subSection11_4/SdsToxicityGrid';
import { SdsBlobSectionFormProps } from '../shared/blobs/hooks/SdsBlobSectionFormProps';
import TranslatableText from '../../../../../../components/i18n/TranslatableText';
import { SDSFormDefs } from '../../../../../../constants/i18n/translations/termDefinitions/formulation';
import { ChildEditableGridWithCopyFunctionProps } from '../../../../../grid/utils/editableGrid/ChildEditableGridWithCopyFunctionProps';
const SdsToxicologicalForm = (
    props: SdsBlobSectionFormProps<SdsHeader> &
        ChildEditableGridWithCopyFunctionProps<SdsHeader, SdsToxicity>
) => {
    const { isParentLoading } = props.parentData;

    return (
        <>
            {isParentLoading ? (
                <DatacorLogoSpinner />
            ) : (
                <>
                    <FormPartial
                        link={props?.link}
                        title={
                            (
                                <TranslatableText
                                    termSet={props.termSet}
                                    termKey={SDSFormDefs.Toxicological}
                                />
                            ) as unknown as string
                        }>
                        <SubSection11_1Form
                            {...props}
                            termSet={props.termSet}
                        />
                        <SubSection11_2Form
                            {...props}
                            termSet={props.termSet}
                        />
                        <SubSection11_3Form
                            {...props}
                            termSet={props.termSet}
                        />
                        <SdsToxicityGrid {...props} />
                        <SubSection11_5Form
                            {...props}
                            termSet={props.termSet}
                        />
                    </FormPartial>
                </>
            )}
        </>
    );
};

export default SdsToxicologicalForm;
