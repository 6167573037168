import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useFormFields } from '../../../libs/hooksLib';
import { isNilOrEmpty } from '../../../utils/objectUtils';
import { login } from '../../../store/user';
import { RootState } from '../../../store';

const useLogin = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const user = useSelector((state: RootState) => state.user);
    const [fields, handleFieldChange] = useFormFields({
        email: '',
        password: '',
    });

    useEffect(() => {
        if (user.requirePasswordChange) {
            navigate('/login/newPasswordRequired');
        }
    }, [user.requirePasswordChange, history]);

    function validateForm() {
        return !isNilOrEmpty(fields.email) && !isNilOrEmpty(fields.password);
    }

    const loginAction = async (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        await dispatch(login(fields));
    };

    return { user, fields, handleFieldChange, loginAction, validateForm };
};

export default useLogin;
