import { RootStateOrAny, useSelector } from 'react-redux';
import React, { useContext, useEffect, useState } from 'react';
import { isNil, isNilOrEmpty } from '../../../../../../../utils/objectUtils';
import { ValueGetterParams } from 'ag-grid-community';
import {
    GridOptions,
    GridPopups,
} from '../../../../../../../components/grids/Grid.constants';
import { PermissionsUtil } from '../../../../../../../utils/permissions/permissionsUtil';
import { PERMISSIONS } from '../../../../../../../constants/permissions/Permissions.constants';
import {
    ItemMaster,
    ItemChemicalComposition,
    CasMaster,
} from '../../../../../../../types/formulation';
import _ from 'lodash';
import applyEditableGridValidations from '../../../../../../grid/utils/applyEditableGridValidations';
import { ColumnRequiredValueValidator } from '../../../../../../grid/validators/columnRequiredValue.validator';
import { ColumnNumericValueValidator } from '../../../../../../grid/validators/columnNumericValue.validator';
import { RowStatus } from '../../../../../../../components/grids/hooks/useBaseGridEditable';
import { ColumnMaxValueValidator } from '../../../../../../grid/validators/columnMaxValue.validator';
import { ColumnMinValueValidator } from '../../../../../../grid/validators/columnMinValue.validator';
import { useListCasQuery } from '../../../../../../../services/formulation/casMaster/cas/casMaster.service';
import SingleSelectPopupsContext from '../../../../../../../contexts/singleSelectPopups.context';
import { SelectedDropdownOption } from '../../../../../../../types/Shared.types';
import { ChildEditableGridWithCopyFunctionProps } from '../../../../../../grid/utils/editableGrid/ChildEditableGridWithCopyFunctionProps';
import SettingsContext from '../../../../../../../contexts/settings.context';
import { useGetTermSetQuery } from '../../../../../../../services/i18n/i18n.service';
import { FORMULATION_DEFS } from '../../../../../../../constants/i18n/translations/termSetDefinitions/formulation';
import { skipToken } from '@reduxjs/toolkit/query';
import CustomHeader from '../../../../../../../components/grids/CustomHeader';
import { ItemMasterFormDefs } from '../../../../../../../constants/i18n/translations/termDefinitions/formulation';

const placeholderValues = {
    casNumberPlaceholder: 'Enter a CAS Number...',
    lowerValuePlaceholder: 'Enter Lower Value...',
    upperValuePlaceholder: 'Enter Upper Value...',
};

const useItemChemicalCompositionGrid = (
    props: ChildEditableGridWithCopyFunctionProps<
        ItemMaster,
        ItemChemicalComposition
    >
) => {
    const { currentParentRecord: currentItemRecord, user } = props.parentData;

    const { showDeleteButton, isGridEditable } = props.displayGridButtons;

    const { handleChildrenRecords } = props.helpers;

    const {
        rowsData: chemicalCompositions,
        setRowsData: setChemicalComposition,
    } = props.copyMethods;

    const canViewCas = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.FORMULATION.CAS_MASTER.VIEW
    );

    const { data: casOptions, isLoading: isLoadingCas } = useListCasQuery();
    const [maxRowId, setMaxRowId] = useState(0);

    const [chemicalCompositionColumnDefs, setChemicalCompositionColumnDefs] =
        useState(null);
    const [casColumnDefs, setCasColumnDefs] = useState(null);
    const [casList, setCasList] = useState([] as CasMaster[]);
    const colDefOptions = {
        ...GridOptions.sortFilterAndWrapColumns,
        floatingFilter: true,
    };
    const [isLoadingChem, setIsLoadingChem] = useState(true);
    const { gridPopups, setGridPopups } = useContext(SingleSelectPopupsContext);

    const { settings } = useContext(SettingsContext);
    const { data: termSet } = useGetTermSetQuery(
        settings?.userSettings
            ? {
                  languageId: settings?.userSettings?.languageId,
                  code: FORMULATION_DEFS.ITEM_MASTER_FORM,
              }
            : skipToken
    );

    const getCasNumber = (casId: bigint): SelectedDropdownOption => {
        let casNumber: SelectedDropdownOption = { id: null, display: '' };
        if (!isNilOrEmpty(casId)) {
            casOptions?.find((current: any) => {
                if (current.id === casId) {
                    casNumber = {
                        id: current.id,
                        display: current.identifier,
                    };
                }
            });
        }
        return casNumber;
    };

    const getCasDetails = (id: bigint, options: CasMaster[]): CasMaster => {
        let detailRecord: CasMaster;
        options?.find((current: CasMaster) => {
            if (current.id === id) {
                detailRecord = current;
            }
        });
        return detailRecord;
    };

    const setFilteredCasList = (
        chemicalCompositionList: ItemChemicalComposition[]
    ) => {
        const listToFilter = chemicalCompositionList?.filter(
            (current) => current.rowStatus !== RowStatus.DELETED
        );
        if (listToFilter && casOptions) {
            const filteredList: CasMaster[] = (
                casOptions as CasMaster[]
            ).filter((currentCas) =>
                isNilOrEmpty(
                    listToFilter.find(
                        (current) => current.casId === currentCas.id
                    )
                )
            );
            setCasList(filteredList);
        }
    };

    const sortItemChemicalComposition = (
        itemChemicalComposition: ItemChemicalComposition[]
    ) => {
        setChemicalComposition(
            _.orderBy(
                itemChemicalComposition,
                ['sortOrder', 'casIdentifier'],
                ['asc']
            )
        );
    };

    //this updates the gridpopups with the data we get from the rtk query -- we can do any initial filtering here
    useEffect(() => {
        if (
            !isNilOrEmpty(casOptions) &&
            !isNilOrEmpty(casList) &&
            !isLoadingChem
        ) {
            let tempArr: any = [...gridPopups];
            const updateIndex = tempArr.findIndex(
                (grids: any) =>
                    grids.gridName ===
                        GridPopups['ITEM_CHEMICAL_COMPOSITION'].gridName &&
                    grids.fieldName ===
                        GridPopups['ITEM_CHEMICAL_COMPOSITION'].fieldName
            );

            if (updateIndex !== -1) {
                tempArr.splice(updateIndex, 1);

                tempArr.push({
                    gridName: GridPopups['ITEM_CHEMICAL_COMPOSITION'].gridName,
                    fieldName:
                        GridPopups['ITEM_CHEMICAL_COMPOSITION'].fieldName,
                    value: casList,
                });
            }

            setGridPopups(tempArr);
        }
    }, [casOptions, casList]);

    useEffect(() => {
        if (!isLoadingCas && !isLoadingChem && isNilOrEmpty(casList)) {
            setFilteredCasList(chemicalCompositions);
            setIsLoadingChem(false);
        }
    }, [isLoadingCas, casOptions, chemicalCompositions]);

    useEffect(() => {
        if (!isLoadingCas) {
            if (isNilOrEmpty(casOptions)) {
                setFilteredCasList(chemicalCompositions);
                if (
                    currentItemRecord &&
                    currentItemRecord?.chemicalComposition
                ) {
                    setChemicalComposition(
                        currentItemRecord?.chemicalComposition
                    );
                }
            }
            setIsLoadingChem(false);
        }
    }, [isLoadingCas, casOptions, currentItemRecord, chemicalCompositions]);

    useEffect(() => {
        const includeEditedRows = currentItemRecord?.chemicalComposition
            ? !isNilOrEmpty(
                  currentItemRecord.chemicalComposition.find(
                      (current) => current.rowStatus
                  )
              )
            : false;
        if (
            currentItemRecord?.chemicalComposition &&
            !isNilOrEmpty(casOptions) &&
            !isLoadingCas &&
            !includeEditedRows
        ) {
            const chemicalCompositionRows: ItemChemicalComposition[] = [];
            let index = maxRowId;

            currentItemRecord?.chemicalComposition.forEach(
                (record: ItemChemicalComposition) => {
                    index = index + 1;
                    setMaxRowId((maxRowId) => maxRowId + 1);

                    if (record.rowStatus !== RowStatus.DELETED) {
                        const casRecord: ItemChemicalComposition = {
                            ...record,
                            casNumber: getCasNumber(record.casId),
                            casIdentifier: getCasNumber(record.casId)?.display,
                            rowId: record.rowId ? record.rowId : index,
                        };
                        chemicalCompositionRows.push(casRecord);
                    }
                }
            );

            sortItemChemicalComposition(chemicalCompositionRows);
        }
    }, [currentItemRecord?.chemicalComposition, casOptions, !isLoadingCas]);

    useEffect(() => {
        if (!isLoadingCas && !isNilOrEmpty(casOptions)) {
            setCasColumnDefs([
                {
                    field: 'identifier',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={
                                    ItemMasterFormDefs.Chemical_Composition_Selection_CAS_Number
                                }
                            />
                        );
                    },
                    cellClass: 'ag-left-aligned-cell',
                    filter: 'agTextColumnFilter',
                },
                {
                    field: 'chemicalName',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={
                                    ItemMasterFormDefs.Chemical_Composition_Selection_CAS_Description
                                }
                            />
                        );
                    },
                    cellClass: 'ag-left-aligned-cell',
                    filter: 'agTextColumnFilter',
                },
                {
                    field: 'ecIdentifier',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={
                                    ItemMasterFormDefs.Chemical_Composition_Selection_EC_No
                                }
                            />
                        );
                    },
                    cellClass: 'ag-left-aligned-cell',
                    filter: 'agTextColumnFilter',
                },
                {
                    field: 'reach',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={
                                    ItemMasterFormDefs.Chemical_Composition_Selection_Reach_No
                                }
                            />
                        );
                    },
                    cellClass: 'ag-left-aligned-cell',
                    filter: 'agTextColumnFilter',
                },
                {
                    field: 'classification',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={
                                    ItemMasterFormDefs.Chemical_Composition_Selection_Classification
                                }
                            />
                        );
                    },
                    cellClass: 'ag-left-aligned-cell',
                    filter: 'agTextColumnFilter',
                },
                {
                    field: 'molecularFormula',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={
                                    ItemMasterFormDefs.Chemical_Composition_Selection_Molecular_Formula
                                }
                            />
                        );
                    },
                    cellClass: 'ag-left-aligned-cell',
                    filter: 'agTextColumnFilter',
                },
            ]);
        }
    }, [isLoadingCas, casOptions]);

    useEffect(() => {
        if (
            !isNilOrEmpty(casColumnDefs) &&
            !isLoadingCas &&
            !isNilOrEmpty(casOptions)
        ) {
            setChemicalCompositionColumnDefs([
                {
                    field: 'deleteColumn',
                    minWidth: 50,
                    hide: !showDeleteButton || !isGridEditable,
                    filter: false,
                },
                {
                    field: 'casNumber',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={
                                    ItemMasterFormDefs.Chemical_Composition_CAS_No
                                }
                            />
                        );
                    },
                    minWidth: 300,
                    editable: (params: any) =>
                        (params.data.casNumber ===
                            placeholderValues.casNumberPlaceholder ||
                            isNilOrEmpty(params.data.casNumber)) &&
                        canViewCas &&
                        isGridEditable,
                    cellRenderer: (params: any) => params.value.display,
                    useGridPopup: true,
                    filter: 'agTextColumnFilter',
                    gridPopupParameters: {
                        isLoading: isLoadingCas,
                        displayGrid: true,
                        rowData: canViewCas ? casOptions : [],
                        defaultColDef: colDefOptions,
                        columnDefs: casColumnDefs,
                        displayField: 'identifier',
                    },
                    valueGetter: (params: ValueGetterParams) => {
                        params.data.casNumber = isNilOrEmpty(
                            params.data.casNumber
                        )
                            ? placeholderValues.casNumberPlaceholder
                            : params.data.casNumber;
                        return params.data.casNumber;
                    },
                },
                {
                    field: 'casDescription',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={
                                    ItemMasterFormDefs.Chemical_Composition_CAS_Description
                                }
                            />
                        );
                    },
                    minWidth: 200,
                    filter: 'agTextColumnFilter',
                    editable: false,
                    valueGetter: (params: ValueGetterParams) => {
                        const details = getCasDetails(
                            params.data.casNumber?.id,
                            casOptions
                        );
                        params.data.chemicalName = details?.chemicalName;
                        params.data.ecIdentifier = details?.ecIdentifier;
                        params.data.molecularFormula =
                            details?.molecularFormula;
                        params.data.reach = details?.reach;
                        params.data.classification = details?.classification;

                        return params.data.chemicalName;
                    },
                },
                {
                    field: 'lowerValue',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={
                                    ItemMasterFormDefs.Chemical_Composition_Lower_Value
                                }
                            />
                        );
                    },
                    minWidth: 200,
                    editable: isGridEditable,
                    valueGetter: (params: ValueGetterParams) => {
                        params.data.lowerValue =
                            params.data.lowerValue ===
                            placeholderValues.lowerValuePlaceholder
                                ? 100
                                : params.data.lowerValue;
                        return params.data.lowerValue;
                    },
                    isNumeric: true,
                },
                {
                    field: 'upperValue',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={
                                    ItemMasterFormDefs.Chemical_Composition_Upper_Value
                                }
                            />
                        );
                    },
                    minWidth: 200,
                    editable: isGridEditable,
                    valueGetter: (params: ValueGetterParams) => {
                        params.data.upperValue =
                            params.data.upperValue ===
                            placeholderValues.upperValuePlaceholder
                                ? 0
                                : params.data.upperValue;
                        return params.data.upperValue;
                    },
                    isNumeric: true,
                },
                {
                    field: 'ecIdentifier',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={
                                    ItemMasterFormDefs.Chemical_Composition_EC_No
                                }
                            />
                        );
                    },
                    minWidth: 200,
                    filter: 'agTextColumnFilter',
                    editable: false,
                },
                {
                    field: 'reach',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={
                                    ItemMasterFormDefs.Chemical_Composition_Reach_No
                                }
                            />
                        );
                    },
                    minWidth: 200,
                    filter: 'agTextColumnFilter',
                    editable: false,
                },
                {
                    field: 'classification',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={
                                    ItemMasterFormDefs.Chemical_Composition_Classification
                                }
                            />
                        );
                    },
                    minWidth: 200,
                    filter: 'agTextColumnFilter',
                    editable: false,
                },
                {
                    field: 'molecularFormula',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={
                                    ItemMasterFormDefs.Chemical_Composition_Molecular_Formula
                                }
                            />
                        );
                    },
                    minWidth: 200,
                    filter: 'agTextColumnFilter',
                    editable: false,
                },
            ]);
        }
    }, [casColumnDefs, isLoadingCas, casOptions]);

    const handleRowValidations = (editedRows: ItemChemicalComposition[]) => {
        editedRows?.forEach((row: ItemChemicalComposition) => {
            let isValidRow = true;
            let getRowErrorMessage = '';
            if (
                !isNil(row.upperValue) &&
                Number(row.upperValue) > 0 &&
                Number(row.lowerValue) > Number(row.upperValue)
            ) {
                isValidRow = false;
                getRowErrorMessage = `Lower Value cannot be greater than Upper Value`;
            }

            row.validationRules = [
                ColumnRequiredValueValidator(
                    'Cas No.',
                    row.casNumber === placeholderValues.casNumberPlaceholder
                        ? null
                        : row.casNumber
                ),
                ColumnRequiredValueValidator('Lower Value', row.lowerValue),
                ColumnNumericValueValidator('Lower Value', 4, row.lowerValue),
                ColumnMaxValueValidator(
                    'Lower Value',
                    100,
                    Number(row.lowerValue)
                ),
                ColumnMinValueValidator(
                    'Lower Value',
                    0,
                    Number(row.lowerValue)
                ),
                ColumnNumericValueValidator('Upper Value', 4, row.upperValue),
                ColumnMaxValueValidator(
                    'Upper Value',
                    100,
                    Number(row.upperValue)
                ),
                ColumnMinValueValidator(
                    'Upper Value',
                    0,
                    Number(row.upperValue)
                ),
                { getErrorMessage: getRowErrorMessage, isValid: isValidRow },
            ];
            const additionalMessage = row.casNumber?.display
                ? `Chemical Composition ${row.casNumber.display}`
                : null;
            applyEditableGridValidations(row, additionalMessage);
        });

        return editedRows;
    };

    const handleGridEdits = (editedRows: ItemChemicalComposition[]) => {
        const updatedFields: ItemChemicalComposition[] = [
            ...chemicalCompositions,
        ];
        const changedCasRecord = editedRows.find(
            (current: any) =>
                current.casNumber && current.casNumber.id !== current.casId
        );
        const deletedRecords = editedRows.filter(
            (current: ItemChemicalComposition) =>
                current.rowStatus === RowStatus.DELETED
        );
        editedRows?.forEach((row: ItemChemicalComposition) => {
            if (
                !isNilOrEmpty(row.casNumber) &&
                !isNilOrEmpty(row.casNumber?.id)
            ) {
                row.casId = row.casNumber?.id;
            }
            const index = chemicalCompositions.findIndex(
                (casComposition: ItemChemicalComposition) =>
                    row.rowId === casComposition.rowId
            );
            if (index > -1) {
                updatedFields[index] = row;
            } else {
                updatedFields.push(row);
            }
        });

        if (changedCasRecord || deletedRecords) {
            setFilteredCasList(updatedFields);
        }
        sortItemChemicalComposition(updatedFields);
        handleChildrenRecords('chemicalComposition', updatedFields);
    };

    return {
        isLoadingCas,
        chemicalCompositionColumnDefs,
        chemicalCompositions,
        handleGridEdits,
        handleRowValidations,
        colDefOptions,
        placeholderValues,
        termSet,
    };
};

export default useItemChemicalCompositionGrid;
