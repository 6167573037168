import React from 'react';
import { useParams } from 'react-router-dom';
import { Grid } from '@mui/material';
import BaseForm from '../../../components/form/baseForm/BaseForm';
import useUnitOfMeasureForm from '../hooks/useUnitOfMeasureForm';
import FormPartial from '../../../components/form/formContainer/FormPartial';
import TextInput from '../../../components/form/formInputs/TextInput/TextInput';
import SelectInput from '../../../components/form/formInputs/SelectInput/SelectInput';
import NumericInput from '../../../components/form/formInputs/NumericInput/NumericInput';

type ParamTypes = { id: string };

const UnitOfMeasureForm = () => {
    const { id } = useParams<ParamTypes>();

    const {
        fields,
        handleFieldChange,
        formMethods,
        onCreate,
        onUpdate,
        onDelete,
        closeForm,
        isLoadingUnitOfMeasure,
        isLoadingUnitTypes,
        isLoadingGlobalUnitOfMeasureOptions,
        unitTypeOptions,
        canCreateUnitOfMeasure,
        canDeleteUnitOfMeasure,
        canUpdateUnitOfMeasure,
        globalUnitOfMeasureOptions,
    } = useUnitOfMeasureForm(id);

    return (
        <BaseForm
            title={'Units Of Measure'}
            formMethods={formMethods}
            isLoading={isLoadingUnitOfMeasure}
            isNew={!id}
            onCreate={onCreate}
            onUpdate={onUpdate}
            onDelete={onDelete}
            onCancel={closeForm}
            displayCreateButton={canCreateUnitOfMeasure}
            displayUpdateButton={canUpdateUnitOfMeasure}
            displayDeleteButton={canDeleteUnitOfMeasure}
            readOnly={id && !canUpdateUnitOfMeasure}>
            <FormPartial>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <TextInput
                            fullWidth
                            label="Unit Code"
                            id="unitCode"
                            formMethods={formMethods}
                            value={fields.unitCode}
                            onChange={handleFieldChange}
                            required={true}
                            maxLength={5}
                            disabled={!!id}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextInput
                            fullWidth
                            label="Description"
                            id="description"
                            formMethods={formMethods}
                            value={fields.description}
                            onChange={handleFieldChange}
                            required={true}
                            maxLength={50}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <NumericInput
                            fullWidth
                            label="Decimals"
                            id="decimals"
                            formMethods={formMethods}
                            value={fields.decimals}
                            onChange={handleFieldChange}
                            greaterThan={0}
                            maxValue={10}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <SelectInput
                            fullWidth
                            label="Unit Type"
                            id="unitTypeId"
                            formMethods={formMethods}
                            value={fields.unitTypeId}
                            onChange={handleFieldChange}
                            options={unitTypeOptions}
                            disabled={isLoadingUnitTypes || !!id}
                            required={true}
                            SelectProps={{
                                readOnly: id && !canUpdateUnitOfMeasure,
                            }}
                        />
                    </Grid>
                </Grid>
            </FormPartial>
            <FormPartial title="Global">
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <SelectInput
                            fullWidth
                            label="Global Unit of Measure"
                            id="unitOfMeasureGlobalId"
                            formMethods={formMethods}
                            value={fields.unitOfMeasureGlobalId}
                            onChange={handleFieldChange}
                            options={globalUnitOfMeasureOptions}
                            disabled={isLoadingGlobalUnitOfMeasureOptions}
                            required={true}
                            SelectProps={{
                                readOnly: id && !canUpdateUnitOfMeasure,
                            }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <NumericInput
                            fullWidth
                            label="Conversion Factor"
                            id="unitOfMeasureGlobalFactor"
                            formMethods={formMethods}
                            value={fields.unitOfMeasureGlobalFactor}
                            onChange={handleFieldChange}
                            required={true}
                        />
                    </Grid>
                </Grid>
            </FormPartial>
        </BaseForm>
    );
};

export default UnitOfMeasureForm;
