import React, { useContext, useEffect, useState } from 'react';
import {
    RDFormula,
    RDFormulaHazardClassificationMapping,
} from '../../../../../../../types/formulation';
import { useListLocaleGroupByActiveQuery } from '../../../../../../../services/formulation/localeGroup/localeGroup.service';
import { ColDef } from 'ag-grid-community';
import DefaultColumnTypes from '../../../../../../../components/grids/columns/Column.constants';
import { useGetHazardClassificationSourcesForDropdownQuery } from '../../../../../../../services/formulation/rdFormula/rdFormulaHazardClassificationSource/rdFormulaHazardClassificationSource.service';
import { LocaleGroupMapping } from '../../../../../../../types/formulation/shared/HazardClassificationMapping.type';
import { ColumnMaxValueValidator } from '../../../../../../grid/validators/columnMaxValue.validator';
import applyEditableGridValidations from '../../../../../../grid/utils/applyEditableGridValidations';
import { GridOptions } from '../../../../../../../components/grids/Grid.constants';
import { editableGridCellSelect } from '../../../../../../../components/grids/columns/editableGridCellSelect';
import { ColumnRequiredValueValidator } from '../../../../../../grid/validators/columnRequiredValue.validator';
import { cloneDeep } from 'lodash';
import { useGetDefaultHazardClassificationMappingQuery } from '../../../../../../../services/formulation/rdFormula/rdFormulaHazardClassificationMapping/rdFormulaHazardClassificationMapping.service';
import { skipToken } from '@reduxjs/toolkit/query';
import { FORMULATION_DEFS } from '../../../../../../../constants/i18n/translations/termSetDefinitions/formulation';
import { RDFormulaHazardClassificationMappingDefs } from '../../../../../../../constants/i18n/translations/termDefinitions/formulation';
import CustomHeader from '../../../../../../../components/grids/CustomHeader';
import { useGetTermSetQuery } from '../../../../../../../services/i18n/i18n.service';
import SettingsContext from '../../../../../../../contexts/settings.context';
import { ChildEditableGridWithCopyFunctionProps } from '../../../../../../grid/utils/editableGrid/ChildEditableGridWithCopyFunctionProps';

const useRDFormulaHazardClassificationMappingGrid = (
    props: ChildEditableGridWithCopyFunctionProps<
        RDFormula,
        RDFormulaHazardClassificationMapping
    >
) => {
    const { parentData, helpers, displayGridButtons } = props;

    const { currentParentRecord, isParentLoading } = parentData;

    const { handleChildrenRecords } = helpers;

    const { isGridEditable } = displayGridButtons;

    const { rowsData, setRowsData } = props.copyMethods;

    const yesOption = { label: 'Yes', value: 100 as unknown as bigint };
    const noOption = { label: 'No', value: 200 as unknown as bigint };
    const lockOptions = [yesOption, noOption];

    const {
        isLoading: isHazardClassificationSourceDropdownLoading,
        data: hazardClassificationSourceDropdown,
    } = useGetHazardClassificationSourcesForDropdownQuery();

    const { isLoading: isLocaleGroupsLoading, data: localeGroupList } =
        useListLocaleGroupByActiveQuery(true);

    const {
        isLoading: isDefaultHazardClassMapsLoading,
        data: defaultHazardClassMaps,
    } = useGetDefaultHazardClassificationMappingQuery(
        currentParentRecord.id ? skipToken : null
    );

    const [columnDefs, setColumnDefs] = useState<ColDef[]>(null);

    const { settings } = useContext(SettingsContext);

    const { data: termSet, isLoading: isLoadingTermSet } = useGetTermSetQuery(
        settings?.userSettings
            ? {
                  languageId: settings?.userSettings?.languageId,
                  code: FORMULATION_DEFS.RD_FORMULA_HAZARD_CLASSIFICATION_MAPS,
              }
            : skipToken
    );

    const buildStaticColumns = (): ColDef[] => {
        return [
            {
                field: 'sourceId',
                headerComponentFramework: (props: any) => {
                    return (
                        <CustomHeader
                            {...props}
                            termSet={termSet}
                            termKey={
                                RDFormulaHazardClassificationMappingDefs.sourceId
                            }
                        />
                    );
                },
                editable: isGridEditable,
                minWidth: DefaultColumnTypes.MediumText.minWidth,
                filter: DefaultColumnTypes.MediumText.filter,
                ...editableGridCellSelect(hazardClassificationSourceDropdown),
                ...DefaultColumnTypes.AutoHeight,
                ...GridOptions.sortFilterAndWrapColumns,
            },
            {
                field: 'lockDisplay',
                headerComponentFramework: (props: any) => {
                    return (
                        <CustomHeader
                            {...props}
                            termSet={termSet}
                            termKey={
                                RDFormulaHazardClassificationMappingDefs.lock
                            }
                        />
                    );
                },
                editable: isGridEditable,
                minWidth: DefaultColumnTypes.MediumText.minWidth,
                filter: DefaultColumnTypes.MediumText.filter,
                ...editableGridCellSelect(lockOptions),
                ...DefaultColumnTypes.AutoHeight,
                ...GridOptions.sortFilterAndWrapColumns,
            },
            {
                field: 'code',
                headerComponentFramework: (props: any) => {
                    return (
                        <CustomHeader
                            {...props}
                            termSet={termSet}
                            termKey={
                                RDFormulaHazardClassificationMappingDefs.code
                            }
                        />
                    );
                },
                minWidth: DefaultColumnTypes.ShortText.minWidth,
                filter: DefaultColumnTypes.ShortText.filter,
                ...DefaultColumnTypes.AutoHeight,
            },
            {
                field: 'description',
                headerComponentFramework: (props: any) => {
                    return (
                        <CustomHeader
                            {...props}
                            termSet={termSet}
                            termKey={
                                RDFormulaHazardClassificationMappingDefs.description
                            }
                        />
                    );
                },
                minWidth: DefaultColumnTypes.MediumText.minWidth,
                filter: DefaultColumnTypes.MediumText.filter,
                ...DefaultColumnTypes.AutoHeight,
            },
        ];
    };

    // Method to create dynamic columns based on active Locale Groups.
    const buildLocaleGroupColumns = (): ColDef[] => {
        const localeGroupColumns: ColDef[] = [];

        localeGroupList
            .filter((current) => current.active === true)
            .forEach((current) => {
                const colDef: ColDef = {
                    // This should match the respective value property in RDFormulaHazardClassificationMapping objects.
                    // Example: USA19Value.
                    field: `${current.groupId}${current.id}Value`,
                    headerName: current.groupId,
                    minWidth: DefaultColumnTypes.MediumText.minWidth,
                    filter: DefaultColumnTypes.MediumText.filter,
                    editable: isGridEditable,
                };
                localeGroupColumns.push(colDef);
            });

        return localeGroupColumns;
    };

    const getLocaleGroupIdById = (id: bigint): string => {
        if (localeGroupList && id) {
            const localeGroup = localeGroupList.find(
                (current) => current.id === id
            );
            return localeGroup?.groupId;
        }
        return null;
    };

    const handleRowValidations = (
        editedRows: RDFormulaHazardClassificationMapping[]
    ) => {
        editedRows.forEach((current) => {
            current.validationRules = [
                ColumnRequiredValueValidator('Source', current.sourceId),
                ColumnRequiredValueValidator('Lock', current.lockDisplay),
            ];
            current.localeGroupIdList.forEach((groupId) => {
                const valueProp = (current[groupId] as LocaleGroupMapping)
                    .valuePropName;
                const localeGrpId = (current[groupId] as LocaleGroupMapping)
                    .localeGroupId;
                const localeGrpCode = getLocaleGroupIdById(localeGrpId);
                current.validationRules.push(
                    ColumnMaxValueValidator(
                        localeGrpCode,
                        20,
                        current[valueProp] as string
                    )
                );
            });
            const message = `Hazard Code ${current.code}`;
            applyEditableGridValidations(current, message);
        });
        return editedRows;
    };

    /**
     * when save edits button is clicked
     * converts data from selects and multiselect grid options in rows
     * into a format that can be saved to the parent rd formula record
     * @param editedRows
     */
    const handleGridEdits = (
        editedRows: RDFormulaHazardClassificationMapping[]
    ) => {
        const updatedFields: RDFormulaHazardClassificationMapping[] = [
            ...rowsData,
        ];
        editedRows?.forEach((hazardClassificationMap) => {
            const index = rowsData?.findIndex(
                (row) =>
                    row.hazardClasificationId ===
                        hazardClassificationMap.hazardClasificationId &&
                    row.formulaId === hazardClassificationMap.formulaId &&
                    row.rowId === hazardClassificationMap.rowId
            );

            hazardClassificationMap.lock =
                hazardClassificationMap.lockDisplay === yesOption.value;

            if (index > -1) {
                updatedFields[index] = hazardClassificationMap;
            } else {
                updatedFields.push(hazardClassificationMap);
            }
        });
        handleChildrenRecords('hazardClassificationMaps', updatedFields);
    };

    useEffect(() => {
        if (
            !isLocaleGroupsLoading &&
            !isHazardClassificationSourceDropdownLoading &&
            !isLoadingTermSet &&
            hazardClassificationSourceDropdown &&
            localeGroupList
        ) {
            const staticColumns = buildStaticColumns();
            const localeGrpColumns = buildLocaleGroupColumns();
            setColumnDefs([...staticColumns, ...localeGrpColumns]);
        }
    }, [
        isLocaleGroupsLoading,
        isHazardClassificationSourceDropdownLoading,
        isLoadingTermSet,
    ]);

    useEffect(() => {
        if (
            !isParentLoading &&
            !isDefaultHazardClassMapsLoading &&
            defaultHazardClassMaps
        ) {
            currentParentRecord.hazardClassificationMaps =
                defaultHazardClassMaps;
        }
    }, [
        isParentLoading,
        defaultHazardClassMaps,
        isDefaultHazardClassMapsLoading,
    ]);

    useEffect(() => {
        if (!isParentLoading && currentParentRecord.hazardClassificationMaps) {
            const rdFormulaHazardClassificationMappings = cloneDeep(
                currentParentRecord.hazardClassificationMaps
            ).map((current, index) => {
                if (!current.rowId) {
                    current.rowId = index + 1;
                }
                current.lockDisplay = current.lock
                    ? yesOption.value
                    : noOption.value;

                return current;
            });
            setRowsData(rdFormulaHazardClassificationMappings);
        }
    }, [isParentLoading, currentParentRecord.hazardClassificationMaps]);

    return {
        columnDefs,
        rowsData,
        isLocaleGroupsLoading,
        isHazardClassificationSourceDropdownLoading,
        isDefaultHazardClassMapsLoading,
        handleRowValidations,
        handleGridEdits,
        termSet,
        isLoadingTermSet,
    };
};

export default useRDFormulaHazardClassificationMappingGrid;
