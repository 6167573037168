import DatacorLogoSpinner from '../../../../../../../components/datacorLogoSpinner/DatacorLogoSpinner';
import React from 'react';
import SubSection8_2Form from './subSection8_2/SubSection8_2Form';
import SubSection8_3Form from './subSection8_3/SubSection8_3Form';
import SdsExposureGrid from './subSection8_1/SdsExposureGrid';
import { SdsExposure, SdsHeader } from '../../../../../../../types/formulation';
import FormPartial from '../../../../../../../components/form/formContainer/FormPartial';
import { SdsBlobSectionFormProps } from '../../shared/blobs/hooks/SdsBlobSectionFormProps';
import TranslatableText from '../../../../../../../components/i18n/TranslatableText';
import { SDSFormDefs } from '../../../../../../../constants/i18n/translations/termDefinitions/formulation';
import { ChildEditableGridWithCopyFunctionProps } from '../../../../../../grid/utils/editableGrid/ChildEditableGridWithCopyFunctionProps';

const SdsExposureControlsForm = (
    props: SdsBlobSectionFormProps<SdsHeader> &
        ChildEditableGridWithCopyFunctionProps<SdsHeader, SdsExposure>
) => {
    const { isParentLoading } = props.parentData;

    return (
        <>
            {isParentLoading ? (
                <DatacorLogoSpinner />
            ) : (
                <>
                    <FormPartial
                        link={props?.link}
                        title={
                            (
                                <TranslatableText
                                    termSet={props.termSet}
                                    termKey={SDSFormDefs.Exposure_Controls}
                                />
                            ) as unknown as string
                        }>
                        <SdsExposureGrid {...props} />
                        <SubSection8_2Form {...props} termSet={props.termSet} />
                        <SubSection8_3Form {...props} termSet={props.termSet} />
                    </FormPartial>
                </>
            )}
        </>
    );
};

export default SdsExposureControlsForm;
