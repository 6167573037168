import React from 'react';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Card, CardContent, CardHeader } from '@mui/material';
import AttributeDefinitionsGrid from '../AttributeDefinitionsGrid';
import SelectDropdown from '../../../components/form/selectDropdown/SelectDropdown';
import AttributeTemplatesGrid from '../AttributeTemplatesGrid';
import useAttributesView from '../hooks/useAttributesView';

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

const TabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}>
            {value === index && (
                <Box sx={{ p: 3, height: '65vh' }}>{children}</Box>
            )}
        </div>
    );
};

const AttributesView = () => {
    const {
        handleChange,
        value,
        entitySelectValue,
        setEntitySelectValue,
        attributeEntities,
        isLoadingEntities,
    } = useAttributesView();

    return (
        <Card>
            <Box sx={{ width: '100%' }}>
                <CardHeader
                    title={
                        <SelectDropdown
                            placeholder="Entity Select..."
                            value={entitySelectValue}
                            options={attributeEntities}
                            isLoading={isLoadingEntities}
                            onChange={(value: any) =>
                                setEntitySelectValue(value)
                            }
                        />
                    }
                />

                <CardContent>
                    <Box
                        sx={{
                            borderBottom: 1,
                            borderColor: 'divider',
                        }}>
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            textColor="inherit"
                            indicatorColor="secondary">
                            <Tab label="Attribute Definitions" />
                            <Tab label="Attribute Templates" />
                        </Tabs>
                    </Box>
                </CardContent>
                <TabPanel value={value} index={0}>
                    <div style={{ height: '100%' }}>
                        <AttributeDefinitionsGrid
                            attrEntityId={entitySelectValue}
                        />
                    </div>
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <div style={{ height: '100%' }}>
                        <AttributeTemplatesGrid
                            attrEntityId={entitySelectValue}
                        />
                    </div>
                </TabPanel>
            </Box>
        </Card>
    );
};

export default AttributesView;
