import { createSlice } from '@reduxjs/toolkit';
import { Auth } from 'aws-amplify';
import { onError } from '../libs/errorLib';
import {
    LoginParams,
    SignupConfirmationParams,
    SignupParams,
    SliceState,
} from '../types/store/UserStore.types';
import { AppDispatch } from './index';
import {
    organizationsApi,
    User,
} from '../services/organizations/organizations.service';
import { accessAPI } from '../services/access/access.service';
import { handleInvalidateTags } from '../utils/services/invalidateTags';
import { openSnackbarBasicWithMessage } from './uiElements';
import moment from 'moment/moment';

const initialState: SliceState = {
    isLoading: false,
    isSignedIn: false,
    signInEmail: null,
    email: null,
    signInPassword: null,
    isTimedOut: false,
    role: null,
    userId: null,
    companyId: null,
    zoneId: null,
    isDatacorAdmin: false,
    isDatacorUser: false,
    requirePasswordChange: false,
    unauthenticatedUser: null,
    enabledFeatureFlags: [],
    permissions: [],
    subscriber: null,
    businessEntity: null,

    defaultSubscriber: null,
    defaultBusinessEntity: null,

    firstName: null,
    lastName: null,
    isLegacyUser: false,
    activeBusinessEntityId: null,
    hasInactiveTrial: false,
};

const slice = createSlice({
    name: 'user',
    initialState: initialState,
    reducers: {
        loginLoading: (state, action) => {
            state.isLoading = action.payload;
        },
        loginSuccess: (state, action) => {
            state.userId = action.payload.userId;
            state.isSignedIn = action.payload.isSignedIn;
            state.isLoading = action.payload.isLoading;
            state.hasInactiveTrial = false;
            if (action.payload.requirePasswordChange) {
                state.requirePasswordChange =
                    action.payload.requirePasswordChange;
                state.unauthenticatedUser = action.payload.user;
            } else {
                state.unauthenticatedUser = null;
                state.enabledFeatureFlags = action.payload.enabledFeatureFlags;
                state.activeBusinessEntityId =
                    action.payload.activeBusinessEntityId;
                state.permissions = action.payload.permissions;
                state.isDatacorAdmin = action.payload.isDatacorAdmin;
                state.isDatacorUser = action.payload.isDatacorUser;
                state.subscriber = action.payload.subscriber;
                state.businessEntity = action.payload.businessEntity;
                state.firstName = action.payload.firstName;
                state.lastName = action.payload.lastName;
                state.isLegacyUser = false;
            }
            state.signInEmail = action.payload.signInEmail;
            state.email = action.payload.email;
            //only want to store login password info in state while confirmation code is being sent
            state.signInPassword = null;
        },
        loginSuccessLegacy: (state, action) => {
            state.isSignedIn = action.payload.isSignedIn;
            state.isLoading = action.payload.isLoading;
            if (action.payload.requirePasswordChange) {
                state.requirePasswordChange =
                    action.payload.requirePasswordChange;
                state.unauthenticatedUser = action.payload.user;
            } else {
                state.role = action.payload.role;
                state.companyId = action.payload.companyId;
                state.unauthenticatedUser = null;
                state.userId = action.payload.userId;
                state.zoneId = action.payload.zoneId;
                state.enabledFeatureFlags = action.payload.enabledFeatureFlags;
                state.permissions = action.payload.permissions;
                state.isDatacorAdmin = action.payload.isDatacorAdmin === 'true';
                state.firstName = action.payload.firstName;
                state.lastName = action.payload.lastName;
                state.isLegacyUser = true;
            }
            state.signInEmail = action.payload.signInEmail;
            state.email = action.payload.email;
            //only want to store login password info in state while confirmation code is being sent
            state.signInPassword = null;
        },
        logoutSuccess: (state, action) => {
            state.isSignedIn = action.payload.isSignedIn;
            state.isLoading = action.payload.isLoading;
        },
        logoutWithInactiveTrialSuccess: (state, action) => {
            state.hasInactiveTrial = true;
            state.isSignedIn = action.payload.isSignedIn;
            state.isLoading = action.payload.isLoading;
        },
        signupSuccess: (state, action) => {
            state.signInEmail = action.payload.username;
            state.signInPassword = action.payload.password;
        },
        setIsTimedOutSuccess: (state, action) => {
            state.isTimedOut = action.payload;
        },
        setRequirePasswordChangeSuccess: (state) => {
            state.requirePasswordChange = false;
        },
        updateUserSubscriber: (state, action) => {
            state.subscriber = action.payload;
        },
        updateUserBusinessEntity: (state, action) => {
            state.businessEntity = action.payload;
            state.activeBusinessEntityId = action.payload.id;
        },
        updateFeatureFlags: (state, action) => {
            state.enabledFeatureFlags = action.payload.featureFlags;
        },
        resetPassword: (state, action) => {
            state.unauthenticatedUser = action.payload.user;
            state.isSignedIn = false;
            state.isLoading = false;
            state.requirePasswordChange = true;
        },
    },
});
export default slice.reducer;

// Actions
export const {
    loginSuccess,
    loginSuccessLegacy,
    logoutSuccess,
    logoutWithInactiveTrialSuccess,
    loginLoading,
    signupSuccess,
    setIsTimedOutSuccess,
    setRequirePasswordChangeSuccess,
    updateUserSubscriber,
    updateUserBusinessEntity,
    updateFeatureFlags,
    resetPassword,
} = slice.actions;

export const login =
    ({ email, password }: LoginParams) =>
    async (dispatch: any) => {
        try {
            dispatch(loginLoading(true));
            const user = await Auth.signIn(email.toLowerCase(), password);
            if (user.signInUserSession) {
                const userData = await dispatch(getUserInfo());
                const featureFlags = await dispatch(
                    getUserFeatureFlags(userData)
                );

                if (!userData.active) {
                    dispatch(logout(true));
                } else if (
                    userData.userTrial?.isTrialUser &&
                    !moment().isBetween(
                        userData.userTrial?.trialStartDate,
                        userData.userTrial?.trialEndDate
                    )
                ) {
                    dispatch(logout(false, true));
                } else {
                    dispatch(
                        setUserDetails(
                            userData,
                            user.signInUserSession,
                            featureFlags
                        )
                    );
                }
            } else {
                dispatch(
                    resetPassword({
                        isSignedIn: false,
                        isLoading: false,
                        requirePasswordChange: true,
                        user: user,
                    })
                );
            }
        } catch (e) {
            if (e.code === 'UsernameExistsException') {
                await Auth.resendSignUp(email);
                //TODO: this seems like a dead end.  What happens if successful??
            } else {
                dispatch(loginLoading(false));

                dispatch(
                    openSnackbarBasicWithMessage({
                        message: e?.message,
                        severity: 'error',
                    })
                );
            }
        }
    };

export const loginLegacy =
    ({ email, password }: LoginParams) =>
    async (dispatch: any) => {
        try {
            dispatch(loginLoading(true));
            const user = await Auth.signIn(email, password);
            if (user.signInUserSession) {
                dispatch(setUserDetailsLegacy(user.signInUserSession));
            } else {
                dispatch(
                    loginSuccessLegacy({
                        isSignedIn: false,
                        isLoading: false,
                        requirePasswordChange: true,
                        user: user,
                    })
                );
            }
        } catch (e) {
            if (e.code === 'UsernameExistsException') {
                await Auth.resendSignUp(email);
                //TODO: this seems like a dead end.  What happens if successful??
            } else {
                dispatch(loginLoading(false));
                dispatch(
                    openSnackbarBasicWithMessage({
                        message: e?.message,
                        severity: 'error',
                    })
                );
            }
        }
    };

export const setUserDetails =
    (user: User, session: any, enabledFeatureFlags?: string[]) =>
    async (dispatch: any) => {
        dispatch(
            loginSuccess({
                userId: user?.id,
                isSignedIn: true,
                isLoading: false,
                unauthenticatedUser: null,
                signInPassword: null,
                activeBusinessEntityId: user?.activeWebBusinessEntityId,

                businessEntity: getUserBusinessEntity(user),
                subscriber: getUserSubscriber(user),

                defaultBusinessEntity: getUserDefaultBusinessEntity(user),
                defaultSubscriber: getUserDefaultSubscriber(user),

                email: user?.email,
                signInEmail: session.idToken.payload['email'],
                isDatacorAdmin: user?.isDatacorAdmin,
                isDatacorUser:
                    session.idToken.payload['custom:isDatacorUser'] === 'true',
                enabledFeatureFlags: enabledFeatureFlags
                    ? enabledFeatureFlags
                    : [],
                permissions: user?.permissions,
                firstName: user?.firstName,
                lastName: user?.lastName,
            })
        );
    };

export const setUserDetailsLegacy = (session: any) => async (dispatch: any) => {
    dispatch(
        loginSuccessLegacy({
            isSignedIn: true,
            isLoading: false,
            role: session.idToken.payload['custom:role'],
            companyId: session.idToken.payload['custom:companyIdWeb'],
            signInEmail: session.idToken.payload['email'],
            email: session.idToken.payload['email'],
            userId: session.idToken.payload['sub'],
            zoneId: session.idToken.payload['custom:zoneWeb'],
            isDatacorAdmin: session.idToken.payload['custom:isDatacorAdmin'],
            isDatacorUser: session.idToken.payload['custom:isDatacorUser'],
            enabledFeatureFlags: [],
            permissions: JSON.parse(
                session.idToken.payload['custom:permissions']
            ),
            firstName: session.idToken.payload['given_name'],
            lastName: session.idToken.payload['family_name'],
        })
    );
};

export const getUserInfo = () => async (dispatch: any) => {
    const result = await dispatch(
        organizationsApi.endpoints.getUserDetails.initiate()
    );

    return result.data;
};

export const getUserFeatureFlags = (userData: any) => async (dispatch: any) => {
    const businessEntityId = userData.activeWebBusinessEntityId
        ? userData.activeWebBusinessEntityId
        : userData.businessEntityId;
    const result = await dispatch(
        accessAPI.endpoints.getEnabledBusinessEntityFeatureFlags.initiate(
            businessEntityId
        )
    );

    return result.data;
};

export const logout =
    (error: boolean = false, inactiveTrial: boolean = false) =>
    async (dispatch: AppDispatch) => {
        try {
            dispatch(loginLoading(true));
            await handleInvalidateTags(dispatch, null, null, true);
            await Auth.signOut();
            if (inactiveTrial) {
                await dispatch(
                    logoutWithInactiveTrialSuccess({
                        isSignedIn: false,
                        isLoading: false,
                    })
                );
            } else {
                await dispatch(
                    logoutSuccess({ isSignedIn: false, isLoading: false })
                );
            }
            if (error) {
                await dispatch(
                    openSnackbarBasicWithMessage({
                        message: 'Incorrect username or password.',
                        severity: 'error',
                    })
                );
            }
        } catch (e) {
            dispatch(loginLoading(false));
            await dispatch(
                openSnackbarBasicWithMessage({ message: e.message })
            );
        }
    };

export const handleUpdateUserSubscriber =
    (newSubscriber: any, newBusinessEntity: any) =>
    async (dispatch: AppDispatch) => {
        await dispatch(updateUserSubscriber(newSubscriber));
        await dispatch(updateUserBusinessEntity(newBusinessEntity || null));
    };

export const handleUpdateUserBusinessEntity =
    (newBusinessEntity: any) => async (dispatch: AppDispatch) => {
        await dispatch(updateUserBusinessEntity(newBusinessEntity || null));
    };

export const refreshToken = () => async () => {
    await Auth.currentAuthenticatedUser({ bypassCache: true }).catch((error) =>
        onError(error, false)
    );
};

export const handleUpdateUserFeatureFlags =
    (featureFlags: any) => async (dispatch: AppDispatch) => {
        await dispatch(updateFeatureFlags({ featureFlags: featureFlags }));
    };

export const signup =
    ({ email, password }: SignupParams) =>
    async (dispatch: AppDispatch) => {
        try {
            dispatch(loginLoading(true));
            await Auth.signUp({ username: email, password: password });
            dispatch(signupSuccess({ username: email, password: password }));
        } catch (e) {
            dispatch(loginLoading(false));
            return onError(e.message);
        }
    };

export const signupConfirmation =
    ({ email, password, confirmationCode }: SignupConfirmationParams) =>
    async (dispatch: AppDispatch) => {
        try {
            dispatch(loginLoading(true));
            await Auth.confirmSignUp(email, confirmationCode);
            loginLegacy({ email, password });
        } catch (e) {
            dispatch(loginLoading(false));
            return onError(e);
        }
    };

export const setRequirePasswordChange = () => async (dispatch: AppDispatch) => {
    dispatch(setRequirePasswordChangeSuccess());
};

export const setIsTimedOut =
    (isTimedOut: boolean) => async (dispatch: AppDispatch) => {
        dispatch(setIsTimedOutSuccess(isTimedOut));
    };

export const getUserBusinessEntity = (user: User) => {
    return user?.activeWebBusinessEntity
        ? user.activeWebBusinessEntity
        : user?.businessEntity || null;
};

export const getUserSubscriber = (user: User) => {
    return user?.activeWebBusinessEntity
        ? user.activeWebBusinessEntity.subscriber
        : user?.businessEntity?.subscriber || null;
};

export const getUserDefaultBusinessEntity = (user: User) => {
    return user?.businessEntity || null;
};

export const getUserDefaultSubscriber = (user: User) => {
    return user?.businessEntity.subscriber || null;
};
