import { useForm } from '../../../../libs/hooksLib';
import { AssetContractsOptionsProps } from '../AssetContractsOptionsModal';
import { useGetAllFixedAssetContractsQuery } from '../../../../services/fixedAssets/fixedAssets.service';
import { FixedAssetContractParams } from '../../../../types/FixedAsset.types';
import { isNil, isNilOrEmpty } from '../../../../utils/objectUtils';
import { SelectionOption } from '../../../../types/Shared.types';
import { skipToken } from '@reduxjs/toolkit/query';
import { useContext, useEffect, useState } from 'react';
import { useGetContractTypesForDropdownQuery } from '../../../../services/accounting/accounting.service';
import { dateFormatter } from '../../../../utils/formattingUtils';
import moment from 'moment';
import { validateDateRange } from '../../../../utils/comparisonUtils';
import SettingsContext from '../../../../contexts/settings.context';
import { useGetTermSetQuery } from '../../../../services/i18n/i18n.service';
import { ACCOUNTING_DEFS } from '../../../../constants/i18n/translations/termSetDefinitions/accounting';

const useAssetContractOptionsModal = (props: AssetContractsOptionsProps) => {
    const inicialFields: any = {
        contractTypes: [],
        startDate: null,
        endDate: null,
        remainingValueDate: dateFormatter(new Date()),
    };
    const { settings } = useContext(SettingsContext);
    const { data: termSet } = useGetTermSetQuery(
        settings?.userSettings
            ? {
                  languageId: settings?.userSettings?.languageId,
                  code: ACCOUNTING_DEFS.CONTRACT_COVERAGE_GRID,
              }
            : skipToken
    );
    const { fields, handleFieldChange } = useForm(inicialFields);
    const [queryParams, setQueryParams] =
        useState<FixedAssetContractParams>(null);

    const { data: contractTypeOptions, isLoading: loadingContractTypes } =
        useGetContractTypesForDropdownQuery();

    const { data: assetContractList, isFetching: isLoadingContracts } =
        useGetAllFixedAssetContractsQuery(
            queryParams ? queryParams : skipToken
        );
    const handleGetContracts = (event: any) => {
        event.preventDefault();

        const listParams: FixedAssetContractParams = {};

        if (!isNilOrEmpty(fields.contractTypes)) {
            listParams['contractTypeIds'] = fields.contractTypes.map(
                (option: SelectionOption) => option.value
            );
        }
        if (!isNil(fields.startDate)) {
            listParams['startDate'] = fields.startDate;
        }
        if (!isNil(fields.endDate)) {
            listParams['endDate'] = fields.endDate;
        }
        if (!isNil(fields.remainingValueDate)) {
            listParams['remainingValueDate'] = fields.remainingValueDate;
        }

        setQueryParams(listParams);
    };

    const disableButton = () => {
        return (
            isNil(fields.remainingValueDate) ||
            !moment(fields.remainingValueDate).isValid() ||
            !validateDateRange(fields.startDate, fields.endDate)
        );
    };

    useEffect(() => {
        if (assetContractList) {
            props.onContractsLoaded(assetContractList);
            setQueryParams(null);
            props.onClose();
        }
    }, [assetContractList]);

    return {
        queryParams,
        fields,
        handleFieldChange,
        contractTypeOptions,
        loadingContractTypes,
        handleGetContracts,
        isLoadingContracts,
        disableButton,
        termSet,
    };
};

export default useAssetContractOptionsModal;
