import React from 'react';
import { TruncatedTooltipProps } from '../TruncatedTooltip';

const useTruncatedTooltip = (props: TruncatedTooltipProps) => {
    let displayTooltip: boolean;
    if (props.text) {
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
        context.font = '16px ' + 'Noto Sans ' + 'sans-serif';
        const textLengthWithPadding =
            context.measureText(props.text).width + 20;
        displayTooltip = textLengthWithPadding > props.maxWidth;
    }
    return {
        displayTooltip,
    };
};

export default useTruncatedTooltip;
