import React from 'react';
import IdleTimer from 'react-idle-timer';
import Navbar from '../../components/navbar/Navbar';
import ErrorBoundary from '../../components/ErrorBoundary';
import Routes from '../../routes/Routes';
import SideMenu from './sideMenu/SideMenu';
import useLanding from './hooks/useLanding';
import SnackbarBasic from '../../components/snackbar/SnackbarBasic';
import ConfirmModal from '../../components/modals/ConfirmModal';

const Landing = () => {
    const { onIdle, onAction, user, idleTimer } = useLanding();

    return (
        <div
            className={`App ${!user.isSignedIn ? 'BlueBackground' : null}`}
            data-testid="appContainer">
            <IdleTimer
                key="idleTimer"
                startOnMount={true}
                //@ts-ignore
                ref={idleTimer}
                element={document}
                onActive={() => onAction()}
                onIdle={() => onIdle()}
                timeout={900000}
            />
            {user.isSignedIn ? (
                <React.Fragment>
                    <Navbar />
                    <SideMenu>
                        <ConfirmModal />
                        <ErrorBoundary>
                            <SnackbarBasic />
                            <Routes />
                        </ErrorBoundary>
                    </SideMenu>
                </React.Fragment>
            ) : (
                <React.Fragment>
                    <ConfirmModal />
                    <ErrorBoundary>
                        <SnackbarBasic />
                        <Routes />
                    </ErrorBoundary>
                </React.Fragment>
            )}
        </div>
    );
};

export default Landing;
