import { useNavigate } from 'react-router-dom';
import {
    useGetAllContractTypesQuery,
    useUpdateListContractTypesMutation,
} from '../../../services/accounting/accounting.service';
import { PermissionsUtil } from '../../../utils/permissions/permissionsUtil';
import { PERMISSIONS } from '../../../constants/permissions/Permissions.constants';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { RoutingValues } from '../../../types/Shared.types';
import { ContractType } from '../../../types/Accounting.types';
import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { getMaxSortOrderValue } from '../../../store/fixedAssets';
import useTranslation from '../../../components/i18n/hooks/useTranslation';
import { ACCOUNTING_DEFS } from '../../../constants/i18n/translations/termSetDefinitions/accounting';
import { isNilOrEmpty } from '../../../utils/objectUtils';
import { ColDef } from 'ag-grid-community';
import CustomHeader from '../../../components/grids/CustomHeader';
import { ContractTypesGridDefs } from '../../../constants/i18n/translations/termDefinitions/accounting';
import DefaultColumnTypes from '../../../components/grids/columns/Column.constants';

const UseContractTypeGrid = () => {
    const dispatch = useDispatch();
    const user = useSelector((state: RootState) => state.user);
    const navigate = useNavigate();
    const { data: contractTypeList, isLoading: isLoadingContractTypes } =
        useGetAllContractTypesQuery();
    const [updateContractTypes] = useUpdateListContractTypesMutation();
    const [colDefs, setColDefs] = useState([]);

    const canCreateContractType = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.FIXED_ASSETS.CONTRACT_TYPES.CREATE
    );
    const canViewContractType = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.FIXED_ASSETS.CONTRACT_TYPES.VIEW
    );
    const canUpdate = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.FIXED_ASSETS.CONTRACT_TYPES.EDIT
    );

    const { termSet } = useTranslation(ACCOUNTING_DEFS.CONTRACT_TYPES_GRID);

    const handleGridEdits = (editedRows: ContractType[]) => {
        updateContractTypes(editedRows);
    };

    const redirectToForm = (row: any) => {
        if (row.id) {
            navigate(`/accounting/contractType/${row.id}`);
        } else {
            navigate(`/accounting/contractType/${RoutingValues.newId}`);
        }
    };

    useEffect(() => {
        let maxValue = 1;
        if (contractTypeList) {
            const data = _.maxBy(
                contractTypeList,
                (contractType: ContractType) => contractType.sortOrder
            );
            maxValue = data.sortOrder + 1;
        }
        dispatch(
            getMaxSortOrderValue({ isLoading: false, maxValue: maxValue })
        );
    }, contractTypeList);

    useEffect(() => {
        if (!isNilOrEmpty(contractTypeList) && isNilOrEmpty(colDefs)) {
            const contractTypeColumnDefs: ColDef[] = [
                {
                    field: 'code',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={ContractTypesGridDefs.Code}
                            />
                        );
                    },
                    ...DefaultColumnTypes.ShortText,
                },
                {
                    field: 'description',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={ContractTypesGridDefs.Description}
                            />
                        );
                    },
                    ...DefaultColumnTypes.LongText,
                },
            ];
            setColDefs(contractTypeColumnDefs);
        }
    }, [contractTypeList]);

    return {
        contractTypeList,
        isLoadingContractTypes,
        canCreateContractType,
        canViewContractType,
        canUpdate,
        redirectToForm,
        handleGridEdits,
        termSet,
        colDefs,
    };
};
export default UseContractTypeGrid;
